import http from "./http-common";
import authHeader from "./authHeader";
//import { currentUserInfo } from "../utils/userHelpers";

class FileService {
  constructor() {
    this.logoCache = {};
  }

  // get list of Files
  getAll() {
    return http
      .get(`/file`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get files by component
  getAllByComponent(compId) {
    return http
      .get(`/file/component/${compId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // Get all program files by CompObjectId
  getAllByComponentObjectId(compId) {
    return http
      .get(`/file/componentObjectId/${compId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get File details by fileId
  getFileById(id) {
    return http
      .get(`/file/${id}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // Get component current active doc by version
  GetComponentCurrentActiveDocByVersion(comObjId,version) {
    return http
      .get(`/compObjectDoc/${comObjId}/version/${version}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  //  save ProgramDoc: this is currently not in use
  saveProgramDoc(programDoc) {
    return http
      .post(`/file/saveProgramDoc/`, { programDoc }, { headers: authHeader() })
      .then((response) => {
        return response;
      });
  }

  // download link for one file by id
  getFileDownload(id) {
    return http.get(`/file/${id}/download`, {
      responseType: "blob",
      timeout: 30000,
      headers: authHeader()
    });
  }

    // download portfolio report
  downloadPortfolioReport(reportId) {
    return http
      .get(`/file/portfolio/${reportId}/download`, {
        responseType: "blob",
        timeout: 30000,
        headers: authHeader()
      })
      .then((response) => {
        return response;
      });
  }

  // download link for one file by File_Ref
  downloadFileByRef(File_Ref) {
    //const user = currentUserInfo();
    return http.get(`/file/ref/${File_Ref}/download`, {
      responseType: "blob",
      timeout: 30000,
      headers: authHeader()
    });
  }

  // public builder doc or download link for one file by id
  builderDocDownload(id) {
    return http.get(`/file/builder/${id}/file`, {
      responseType: "blob",
      timeout: 30000,
      headers: authHeader()
    });
  }

  // getExcelTable
  getExcelTable(excelData) {
    return http
      .post(`/file/report/excelTable/`, { excelData }, {
        responseType: "blob",
        timeout: 30000,
        headers: authHeader()
      });
  }

  uploadTool(data) {
    return http.post(`/file/uploadTool`, data, { headers: authHeader() });
  }

  // Todo: Use separate endpoint or refactor
  upsertProgramDocument(data) {
    return http.post(`/file/programDoc/upsert`, data, { headers: authHeader() });
  }

  updateReviewDateForProgramDoc(programDoc) {
    return http
      .post(`/file/programDoc/reviewDate`, { programDoc }, { headers: authHeader() })
      .then((response) => {
        return response.data
      })
  }

  // upload logo
  async uploadLogo(data) {
    const response = await http.post(`/file/logo`, data, { headers: authHeader() });
    if (response.data.payload?.logoId) {
      delete this.logoCache[response.data.payload.logoId];
    }
    return response;
  }
  // get logo base64
  async getLogo(id) {
    const cachedLogo = this.logoCache[id];
    if (cachedLogo) {
      return Promise.resolve({ data: { payload: cachedLogo } });
    }
    const response = await http.get(`/file/${id}/logo`, { headers: authHeader() });
    this.logoCache[id] = response.data.payload;
    return response;
  }
}

export default new FileService();
