import { makeStyles } from "@material-ui/core";
import TableCell from "components/utils/tables/shared/tableCell.component";
import classNames from "classnames";
import { DCT_ELEMENT_TYPE_ICONS, DCT_ELEMENT_TYPE_ED, DCT_ELEMENT_TYPE_EP, DCT_ELEMENT_TYPE_DISPLAY } from "components/certification/utils/dct.utils";
import DisplayHTML from "components/utils/displayHTML.component";
import { memo, useCallback, useMemo } from "react";
import styleVariables from "styleVariables";
import { SUBLABEL_SHOW } from "../reconciliationTablePages.component";

const useStyles = makeStyles((theme) => ({
  cell: {
    borderCollapse: "collapse",
    borderRight: `1px solid ${theme.palette.border.main}`,
    borderLeft: `1px solid ${theme.palette.border.main}`,
    verticalAlign: "top"
  },
  contentContainer: {
    display: "flex",
    alignItems: "baseline",
    minWidth: 400,
  },
  noOrdinalContainer: {
    [theme.breakpoints.down("lg")]: {
      flexWrap: "wrap"
    }
  },
  displayHtml: {
    display: "inline",
    padding: 0,
    "& p": {
      marginTop: "0.5em",
      marginBottom: "0.5em"
    },
    "& > *:first-child": { marginTop: 0 },
    "& > *:first-child > *:first-child": { marginTop: 0 },
    "& > *:first-child > *:first-child > *:first-child": { marginTop: 0 },
    "& > *:last-child": { marginBottom: 0 },
    "& > *:last-child > *:last-child": { marginBottom: 0 },
    "& > *:last-child > *:last-child > *:last-child": { marginBottom: 0 }
  },
  labelContentContainer: {
    marginBottom: -8,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between"
  },
  categoryRow: {
    fontSize: styleVariables.fontMedium,
    [theme.breakpoints.down("md")]: {
      textIndent: 24,
      width: "100%"
    }
  },
  contentItem: {
    marginBottom: 8,
  },
  contentItemShort: {
    minWidth: "50%",
  },
  contentItemLong: {
    minWidth: "100%",
  },
  contentItemLabel: {
    fontWeight: 600
  },
  highOrdinalColumn: {
    minWidth: 32
  },
  lowOrdinalColumn: {
    minWidth: 24
  },
  ordinal: {
    flexShrink: 0,
    fontWeight: 600
  },
  scrollRefContainer: {
    position: "relative"
  },
  scrollRef: {
    position: "absolute",
    top: -90,
    width: 1,
    height: 1
  },
  typeIcon: {
    marginRight: 4,
    marginBottom: -4,
    marginLeft: -4,
    color: theme.palette.primary.main,
  },
  sublabel: {
    marginTop: 24,
    marginLeft: 24
  }
}));

const HTML_DETECTION_CHARACTER = "<";
const JSON_DETECTION_CHARACTER = "{";

const LONG_JSON_CHAR_COUNT = 70;

const DescriptionTableCell = memo(DescriptionTableCellUnmemoized);
export default DescriptionTableCell;

function DescriptionTableCellUnmemoized(props) {
  const classes = useStyles();
  const {
    categoriesLength, enumeration, index, item,
    responseCount, scrollRef, sublabelFilter
  } = props;

  const IconComponent = useMemo(() => (
    DCT_ELEMENT_TYPE_ICONS[item.type]
  ), [item.type]);

  const label = useMemo(() => (
    (index != null || !enumeration) ?
      item.label :
      `${enumeration} ${item.label}`
  ), [enumeration, index, item]);

  const ordinalColumnClass = useMemo(() => (
    categoriesLength >= 10 ? classes.highOrdinalColumn : classes.lowOrdinalColumn
  ), [categoriesLength, classes]);

  const SublabelComponent = useMemo(() => (
    item.sublabelComponent
  ), [item.sublabelComponent]);

  return (
    <TableCell
      rowSpan={responseCount}
      colSpan={3}
      size="medium"
      className={
        classNames(classes.cell, index == null && classes.categoryNameCell)
      }
    >
      <div
        className={
          classNames(
            classes.contentContainer,
            index == null && classes.noOrdinalContainer
          )
        }
      >
        {!!scrollRef && (
          <div
            className={classes.scrollRefContainer}
          >
            <div className={classes.scrollRef} ref={scrollRef} />
          </div>
        )}
        {index != null ? (
          <div className={classNames(classes.ordinal, ordinalColumnClass)}>
            {index + 1 || ""}.&nbsp;
          </div>
        ) : (
          <div className={classNames(classes.ordinal, ordinalColumnClass)}>
            <span>
              {item.type === DCT_ELEMENT_TYPE_ED && (
                <IconComponent className={classes.typeIcon} size={20} />
              )}
              {item.type === DCT_ELEMENT_TYPE_EP && (
                <IconComponent className={classes.typeIcon} size={20} />
              )}
              &nbsp;
            </span>
            {DCT_ELEMENT_TYPE_DISPLAY[item.type]}
            &nbsp;({item.specialty}):&nbsp;
          </div>
        )}
        <FlexibleLabelContent
          categoryRow={index == null}
          content={label}
        />
      </div>
      {sublabelFilter === SUBLABEL_SHOW && (
        <>
          {!!item.sublabel && (
            <div
              className={
                classNames(classes.contentContainer, classes.sublabel)
              }
            >
              <div className={ordinalColumnClass} />
              <FlexibleLabelContent content={item.sublabel} />
            </div>
          )}
          {!!SublabelComponent && (
            <div
              className={
                classNames(classes.contentContainer, classes.sublabel)
              }
            >
              <SublabelComponent {...item.sublabelComponentProps || {}} />
            </div>
          )}
        </>
      )}
    </TableCell>
  )
}

/*
 * Handles Label content from database which may be one of:
 * - HTML
 * - Dictionary Object
 * - Array of Object of { label, value }
 *   - value can be HTML
 * - JSON of any of the above
 * - String
 */

const FlexibleLabelContent = memo(
  function FlexibleLabelContentBase(props) {
    const classes = useStyles();
    const { categoryRow, content } = props;

    const objectToContentEntries = useCallback(object => {
      if (object && typeof object === "object") {
        if (Array.isArray(object)) {
          return object;
        } else {
          return Object.entries(object).map(([label, value]) => ({ label, value }));
        }
      }
      return null;
    }, []);

    const contentEntries = useMemo(() => {
      if (typeof content === "string" && content.includes(JSON_DETECTION_CHARACTER)) {
        try {
          return objectToContentEntries(JSON.parse(content));
        } catch(error) {
          return null;
        }
      } else if (typeof content === "object") {
        return objectToContentEntries(content);
      }
      return null;
    }, [content, objectToContentEntries]);

    if (!content) {
      return null;
    } else if (contentEntries) {
      return (
        <div
          className={
            classNames(
              classes.labelContentContainer,
              categoryRow && classes.categoryRow
            )
          }
        >
          {contentEntries.map(({ label, value }) => (
            <div
              className={classNames(
                classes.contentItem,
                value.length > LONG_JSON_CHAR_COUNT && classes.contentItemLong,
                value.length <= LONG_JSON_CHAR_COUNT && classes.contentItemShort
              )}
              key={label}
            >
              <span className={classes.contentItemLabel}>
                {label}:
              </span>
              &nbsp;
              {value.includes(HTML_DETECTION_CHARACTER) ? (
                <DisplayHTML
                  className={classes.displayHtml}
                  html={`<span>${value}</span>`}
                  unstyled
                />
              ) : (
                <span>{value}</span>
              )}
            </div>
          ))}
        </div>
      );
    } else if (
      typeof content === "string" &&
      content.includes(HTML_DETECTION_CHARACTER)
    ) {
      return (
        <div className={categoryRow ? classes.categoryRow : undefined}>
          <DisplayHTML
            className={classes.displayHtml}
            html={`<span>${content}</span>`}
            unstyled
          />
        </div>
      );
    }
    return (
      <div className={categoryRow ? classes.categoryRow : undefined}>
        {content}
      </div>
    );
  }
);
