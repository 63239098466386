import { makeStyles } from "@material-ui/core";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import { H5 } from "components/utils/headerV2.component";
import { memo, useMemo } from "react";

const useStyles = makeStyles(theme => ({
  container: {
    color: theme.palette.text.secondary
  },
  notesRow: {
    marginTop: 8,
    lineHeight: 1.25,
    color: theme.palette.text.primary
  }
}));

const DctQuestionSummaryInfo = memo(
  function DctQuestionSummaryInfoBase(props) {
    const classes = useStyles();
    const { question } = props;

    const referencesValue = useMemo(() => (
      question._associations?.DctReference
        ?.map?.(reference => reference.Reference)
        ?.join?.(", ")
    ), [question._associations?.DctReference]);

    const scopingAttribute = useMemo(() => (
      question._associations?.DctScopingAttribute?.[0]?.ScopingAttribute
    ), [question._associations?.DctScopingAttribute]);

    return (
      <div className={classes.container}>
        <GridContainer spacing={2}>
          {!!question._associations?.DctReference?.length && (
            <GridItem xs={12}>
              <InfoItem
                label="REFERENCES"
                value={referencesValue}
              />
            </GridItem>
          )}
          <GridItem xs={12}>
            <InfoItem
              label="Response Details"
              value={question.Response_Details}
            />
          </GridItem>
          {!!scopingAttribute && (
            <GridItem xs={12}>
              <InfoItem label="Scoping Attribute" value={scopingAttribute} />
            </GridItem>
          )}
          <GridItem xs={6}>
            <InfoItem
              label="Safety Attribute"
              value={question.Safety_Attribute}
            />
          </GridItem>
          <GridItem xs={6}>
            <InfoItem label="Question Type" value={question.Type} />
          </GridItem>
          <GridItem xs={6}>
            <InfoItem label="Revision" value={question.Revision} />
          </GridItem>
          <GridItem xs={6}>
            <InfoItem label="QID" value={question.QID} />
          </GridItem>
          <GridItem xs={6}>
            <InfoItem label="Status" value={question.Status} />
          </GridItem>
          <GridItem xs={12}>
            <InfoItem
              label="Response Details"
              value={question.Response_Details}
            />
          </GridItem>
          {!!question.Notes && (
            <GridItem xs={12}>
              <div className={classes.notesRow}>
                <InfoItem label="Note" value={question.Notes} highlight />
              </div>
            </GridItem>
          )}
        </GridContainer>
      </div>
    );
  }
);

export default DctQuestionSummaryInfo;

const InfoItem = memo(
  function InfoItemBase({ highlight, label, value }) {
    return (
      <div>
        <H5 color={highlight ? "black" : "tertiary"}>
          {label}:&nbsp;
        </H5>
        <span>{value || "--"}</span>
      </div>
    );
  }
);
