import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styleVariables from "styleVariables";
import Header from "components/utils/header.component";
import CustomSunEditor from "components/utils/sunEditor.component";
import DisplayHTML from "components/utils/displayHTML.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import CustomLink from "components/utils/link.component";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import DeleteIconButton from "components/utils/deleteIconButton.component";
import { Box } from "@material-ui/core";
import { ReportPageSection } from "./reportPageSection.component";
import ReorderListModalButton from "../reorderListModalButton.component";
import PortfolioService from "services/portfolio.service";
import { ACTION_CREATE_REPORT_SUBSECTION, ACTION_REPLACE_REPORT_SECTION } from "reducers/portfolioReports.reducer";
import { stripAssociationFields } from "utils/formHelpers";
import classNames from "classnames";
import { Paper } from "@material-ui/core";
import { H4 } from "components/utils/headerV2.component";

const useStyles = makeStyles((theme) => ({
  listContentContainer: {
    paddingBottom: 12,
  },
  readPadding: {
    padding: "8px 32px"
  },
  editablelistHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  listNumber: {
    marginBottom: 12,
    marginRight: 10,
  },
  readListContent: {
    padding: "0px 0px 5px 20px",
  },
  editableListContent: {
    padding: "8px 0px 16px 24px",
  },
  addSectionIcon: {
    fontSize: "1rem",
    marginRight: 6,
  },
  addLinkBar: {
    marginTop: 10,
  },
  noListItems: {
    textAlign: "center",
    padding: 10,
    fontSize: styleVariables.fontLarge,
    color: styleVariables.textSecondary
  },
}));

const OrderedListSection = ({
  dispatch,
  onSubsectionAutosave,
  onSubsectionDelete,
  readOnly = false,
  section,
}) => {
  const classes = useStyles();
  const subsections = section._associations.ReportSubSections;

  const [isReordering, setIsReordering] = useState(false);

  const saveReorderedList = async (newList) => {
    const listWithoutAssociations = stripAssociationFields(newList);
    setIsReordering(true);
    const updateRes = await PortfolioService.batchUpdateReportSubSections(
      newList[0].ReportSection_ID,
      listWithoutAssociations
    );
    dispatch({
      type: ACTION_REPLACE_REPORT_SECTION,
      payload: updateRes.payload,
    });
    setIsReordering(false);
  };

  return (
    <ReportPageSection title={section.Section_Header}>
      <div>
        {!subsections ? (
          <Paper elevation={1} className={classes.noListItems}>No List Items Present</Paper>
        ) : (
          subsections?.map((subsection, index) => {
            return (
              <ListContent
                subsection={subsection}
                readOnly={readOnly}
                onAutosave={onSubsectionAutosave}
                onDelete={onSubsectionDelete}
                listNumber={index + 1}
                key={subsection.ReportSubSection_ID}
              />
            );
          })
        )}
        {!readOnly && (
          <>
            {subsections?.length > 1 && (
              <ReorderListModalButton
                list={subsections}
                orderFieldName="Section_Order"
                displayFieldName="Section_Header"
                primaryKey="ReportSubSection_ID"
                saveFunction={saveReorderedList}
              // disabled={subsections?.length < 2}
                isReordering={isReordering}
                setIsReordering={setIsReordering}
                linkBar
              />
            )}
            <div className={classes.addLinkBar}>
              <NewSectionLinkBar dispatch={dispatch} section={section} />
            </div>
          </>
        )}
      </div>
    </ReportPageSection>
  );
};

const NewSectionLinkBar = ({ dispatch, section }) => {
  const classes = useStyles();

  const subsections = section._associations.ReportSubSections;

  const handleAddClick = useCallback(async () => {
    const response = await PortfolioService.createReportSubSection(
      section.ReportSection_ID,
      { Section_Order: subsections?.length ? subsections.length + 1 : undefined }
    );
    dispatch({ type: ACTION_CREATE_REPORT_SUBSECTION, payload: response.payload });
  }, [dispatch, section, subsections]);

  return (
    <CustomLink
      onClick={handleAddClick}
      variant="linkBar"
      startIcon={
        <AddCircleOutlineOutlinedIcon className={classes.addSectionIcon} />
      }
      test="AddSection"
    >
      Add List Item
    </CustomLink>
  );
};

const ListContent = (props) => {
  const classes = useStyles();
  const { subsection, readOnly, onAutosave, onDelete, listNumber, emptyList } = props;

  if (emptyList && readOnly) {
    //TODO: Remove this conditional + return after implemntation as it shouldn't be needed
    return "No List Items Present";
  }

  return (
    <div className={classNames(classes.listContentContainer, readOnly && classes.readPadding)}>
      <div>
        {readOnly ? (
          <div>
            <Header variant="h4SecondaryDark">
              {`${listNumber}) ${subsection.Section_Header || ""}`}
            </Header>
          </div>
        ) : (
          <div className={classes.editablelistHeader}>
            <H4 color="secondaryDark" className={classes.listNumber}>
              {listNumber}
            </H4>
            <LabelInput
              name={`ReportSubSections__Header__${subsection.ReportSubSection_ID}`}
              test={`listSection-Order-${subsection.Section_Order}`}
              defaultValue={subsection.Section_Header}
              variant="default"
              onAutosave={(_name, value) => (
                onAutosave("Section_Header", value, subsection)
              )}
            />
          </div>
        )}
      </div>

      <div
        className={
          !readOnly ? classes.editableListContent : classes.readListContent
        }
      >
        {readOnly ? (
          <DisplayHTML html={subsection.Section_Content} />
        ) : (
          <div data-cy={`list-sunEditor-wrapper-Order-${subsection.Section_Order}`}>
            <CustomSunEditor
              defaultValue={subsection.Section_Content}
              height="auto"
              name={`ReportSubSections__${subsection.ReportSubSection_ID}`}
              onAutosave={(_name, value) => (
                onAutosave(
                  "Section_Content",
                  value,
                  subsection
                )
              )}
            />
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <DeleteIconButton
                target={`List Item ${listNumber}`}
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure you want to delete this list item?"
                    )
                  ) {
                    onDelete(
                      subsection.ReportSection_ID,
                      subsection.ReportSubSection_ID
                    );
                  }
                }}
              />
            </Box>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderedListSection;
