import React, { useEffect, useState, useRef } from "react";
import { Box, Popover } from "@material-ui/core";
import { useLocation, useHistory, useParams } from "react-router-dom";
import CustomCard from "components/utils/card.component";
import CustomDataTable from "components/utils/tables/dataTable.component"
import CellItem from "components/utils/cellItem.component";
import CustomMatrixHeadCenter from "components/utils/matrixHeadCenter.component";
import variables from "styleVariables";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "components/utils/loader.components";
import LegalFrameworkService from "services/legalFramework.service";

const useStyles = makeStyles((theme) => ({
  matrixContainer: {
    marginTop: 40
  },
  paperRoot: {
    borderRadius: "0px 10px 10px 10px",
  },
  popOverWrapper: {
    maxWidth: 350,

    [theme.breakpoints.down('md')]: {
      maxWidth: 250,
    },
  },
  descriptionWrapper: {
    fontFamily: "Helvetica",
    paddingTop: 8,
    fontSize: variables.fontSmall,
    color: variables.textSecondary,
    lineHeight: "1.3rem",
  },
  cardTitleWrapper: {
    fontSize: variables.fontH5,
    color: variables.primaryMain,
    fontWeight: "bold"
  }
}));

// Todo: This table seems to be unused and replaced by "Requirements" ProgramComponent
const LegalFrameworkTable = () => {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const [regulation, setRegulation] = useState({});
  const [regulationSec, setRegulationSec] = useState({});
  const { programId } = useParams();
  const [matrixData, setMatrixData] = useState();
  const [matrixColumns, setMatrixColumns] = useState();
  //for popover
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  //for autoscroll
  const rowRef = useRef({});
  const [searchRowId, setSearchRowId] = useState();

  //options for the table
  const options = {
    filterType: "checkbox",
    fixedHeader: true,
    filter: false,
    fixedSelectColumn: true,
    pagination: false,
    selectableRowsHideCheckboxes: true,
    tableBodyMaxHeight: 'calc(100vh - 245px)',
    setRowProps: (row, dataIndex, rowIndex) => {
      if (row[0].props && row[0].props.id === searchRowId) {
        return {style: { backgroundColor: variables.chosenElement }}
      }
    },
  };

  const handleClose = () => {
    setAnchorEl(null);
    setRegulation({});
    setRegulationSec({});
  };

  // upon load, scrolls to specified row
  useEffect(() => {
    if (location.state) {
      setSearchRowId(location.state);
      if (rowRef && rowRef.current && searchRowId && matrixData) {
        rowRef.current[searchRowId].scrollIntoView({
          block: "center",
        });
        history.replace(); //clears out location.state
      }
    }
  }, [location.state, rowRef, matrixData, searchRowId, history]);

  useEffect(() => {
    LegalFrameworkService.getProgramRegulations(programId).then((res) => {
      const regulationsList = res.payload;
      if (!res?.payload?.length) {
        setMatrixColumns([]);
        setMatrixData([]);
        return;
      }
      const columns = [
        {
          name: "LabelName",
          label: "Principle",
          options: {
            filter: true,
            sort: false,
            setCellProps: () => ({
              style: {
                position: "sticky",
                left: "0px",
                background: "white",
                color: variables.textSecondary,
                fontSize: variables.fontSamll,
                zIndex: 100,
                textAlign: "left",
                boxShadow: "inset -1px 0px 0px 0px rgba(22, 76, 146, 0.22)",
              },
            }),
            setCellHeaderProps: () => ({
              style: {
                left: "0", //For sidescroll sticky header
                zIndex: 101,
              },
            }),
            customBodyRender: (value, tableMeta, updateValue) => {
              const princNum = value.split(" ")[0]
              return (
                <div id={princNum} ref={(el) => (rowRef.current[princNum] = el)}>
                  {value}
                </div>
              );
            },
          },
        },
        {
          name: "Description",
          label: "Description",
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({
              style: { minWidth: "500px", maxWidth: "900px" },
            }),
          },
        },
      ];

      regulationsList.forEach((row) => {
        const regName = row.Reg_Label
          .split(",").join("___").split("-").join("__")
          .split(" ").join("_");
        columns.push({
          name: regName,
          label: row.Reg_Label,
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({ style: { whiteSpace: "nowrap", textAlign: "center", } }),
            customHeadLabelRender: (value, tableMeta, updateValue) => {
              return (
                <CustomMatrixHeadCenter
                  onClick={(event) => regulationClick(row.name, event, row.description)}
                  id={`reg-${value.name}`}
                  label={value.label}
                />
              );
            },
            customBodyRender: (value) => {
              //value ie null or [{"id" :565, "RegSectionName": "522a. (o) Matching Agreements"},{"id" :567, "RegSectionName": "522a. (q) Sanctions"}]
              if (value && value.length) {
                const valArr = JSON.parse(value);
                return valArr.map((val, i) => {
                  if (valArr.length !== i + 1) {
                    const key = `${i}_${val.id}`;
                    return (
                      <CellItem
                        onClick={(event) => regSectionClick(event, val.id)}
                        item={`${val.RegSectionName}, `}
                        mapKey={key}
                        key={key}
                      />
                    );
                  } else {
                    const key = `1_${i}_${val.id}`;
                    return (
                      <CellItem
                        onClick={(event) => regSectionClick(event, val.id)}
                        item={`${val.RegSectionName}`}
                        mapKey={key}
                        key={key}
                      />
                    );
                  }
                });
              }
            },
          },
        });
      });
      setMatrixColumns(columns);
      LegalFrameworkService.getLegalFrameworkData(programId).then((response) => {
        setMatrixData(response.payload);
      });
    });
  }, [programId]);

  const regulationClick = (name, event, description) => {
    setAnchorEl(event.currentTarget);
    setRegulation({ name, description });
  };

  // from customBodyRender
  const regSectionClick = (event, regId) => {
    setAnchorEl(event.currentTarget);
    LegalFrameworkService.getRegSectionById(regId).then((res) => {
      setRegulationSec({
        name: res.payload.Section_Detail,
        contents: res.payload.Section_Contents,
      });
    });
  };

  if (matrixData) {
    return (
      <div className={classes.matrixContainer}>
        {!!matrixColumns && (
          <CustomDataTable
            data={matrixData}
            columns={matrixColumns}
            options={options}
            // EXCEL DOWNLOAD INFO
            excelColumns={matrixColumns}
            excelData={matrixData}
          />
        )}

        <Popover
          id={id}
          open={open}
          classes={{paper:classes.paperRoot}}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={
            {
              vertical: "bottom",
              horizontal: "right",
            }
          }
          transformOrigin={
            {
              vertical: "top",
              horizontal: "left",
            }
          }
        >
          {!!regulationSec && !!regulationSec.contents && (
            <div className={classes.popOverWrapper}>
              <CustomCard variant="plain">
                <Box>
                  <div className={classes.cardTitleWrapper}>
                    {regulationSec.name} acs
                  </div>

                  <div className={classes.descriptionWrapper}>
                    {regulationSec.contents}
                  </div>
                </Box>
              </CustomCard>
            </div>
          )}
          {!!regulation && !!regulation.description && (
            <div className={classes.popOverWrapper}>
              <CustomCard variant="plain">
                <Box>
                  <div className={classes.cardTitleWrapper}>
                    {regulation.name}
                  </div>
                  <div className={classes.descriptionWrapper}>
                    {regulation.description}
                  </div>
                </Box>
              </CustomCard>
            </div>
          )}
        </Popover>
      </div>
    );
  } else {
    return <Loader />;
  }
};

export default LegalFrameworkTable;
