import { H4 } from "components/utils/headerV2.component";
import Loader from "components/utils/loader.components";
import { useCallback } from "react";
import { ACTION_SET_UI_HIGHLIGHTED_ROW } from "reducers/runbook/oneRunbook.reducer";
import TaskChangesTable from "../shared/tables/taskChangesTable.component";


export default function TaskChangesPanel(props) {
  const { state, dispatch } = props;

  const handleTaskIdClick = useCallback(taskId => {
    dispatch({ type: ACTION_SET_UI_HIGHLIGHTED_ROW, payload: taskId });
  }, [dispatch]);

  if (!state.runbookTaskChanges) {
    return (
      <Loader />
    );
  }
  return (
    <div>
      <H4 color="primary" withMargin>Runbook Changes</H4>
      <TaskChangesTable
        onTaskIdClick={handleTaskIdClick}
        taskChanges={state.runbookTaskChanges}
      />
    </div>
  );
}
