import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import RadioInputGroup from "components/utils/form-elements/radioInputGroup.component";
import { useCallback, useEffect, useMemo, useState } from "react";

const useStyles = makeStyles(theme => ({
  radioGroup: {
    marginRight: -24,
    marginBottom: 8,
    minWidth: 160,
  },
  radioGroupWide: {
    [theme.breakpoints.up("lg")]: {
      minWidth: 264
    }
  },
  radioGroupReadOnly: {
    pointerEvents: "none"
  },
  radioLabel: {
    marginTop: 8
  },
  radioLabelText: {
    paddingRight: 0,
    color: theme.palette.text.primary,
    fontSize: "0.85rem",
  },
  radioLabelTextHorizontal: {
    paddingRight: 16,
  },
  radioLabelReadOnly: {
    color: theme.palette.text.secondary,
  },
}));

const WIDE_RADIO_CELL_CHAR_MIN = 40;

export default function TableCellRadioGroup(props) {
  const classes = useStyles();
  const { column, onAutosave, readOnly = false, response } = props;
  const savedResponseValue = response?.data?.[column.key] || "";

  const isWide = useMemo(() => (
    column.options.some(option => (
      option.label.length > WIDE_RADIO_CELL_CHAR_MIN
    ))
  ), [column.options]);

  const [controlledValue, setControlledValue] = useState(savedResponseValue);
  const [pendingSaveValue, setPendingSaveValue] = useState(null);

  const handleChange = useCallback(event => {
    if (readOnly) {
      event.preventDefault();
      return;
    }
    if (onAutosave) {
      setPendingSaveValue(event.target.value);
      return onAutosave(event);
    } else {
      setControlledValue(event.target.value);
    }
  }, [onAutosave, readOnly]);

  useEffect(() => {
    if (onAutosave && pendingSaveValue && controlledValue) {
      setPendingSaveValue(null);
    }
  }, [onAutosave, pendingSaveValue, controlledValue])

  return (
    <RadioInputGroup
      options={column.options}
      value={
        (onAutosave || readOnly) ?
          (pendingSaveValue || savedResponseValue) :
          controlledValue
      }
      radioGroupClass={
        classNames(
          classes.radioGroup,
          isWide && classes.radioGroupWide,
          readOnly && classes.radioGroupReadOnly
        )
      }
      radioLabelClass={
        classNames(classes.radioLabel, readOnly && classes.radioLabelReadOnly)
      }
      radioLabelTextClass={classNames(
        classes.radioLabelText, classes.radioLabelTextHorizontal
      )}
      onChange={handleChange}
      readOnly={readOnly}
      hideHelperText
    />
  );
}
