import React from 'react';
import { Checkmark } from "react-checkmark";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";

// https://www.npmjs.com/package/react-checkmark

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginRight: 8,
  },
}));
const AnimatedCheck = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Checkmark size={props.size ? props.size : "16px"} color={props.color ? props.color : variables.secondaryMain} />
    </div>

  )
}

export default AnimatedCheck;
