import React, { useMemo } from "react";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import variables from "styleVariables";
import { Button } from "@material-ui/core";
import classNames from "classnames";
import { hasReadOnlyAccess } from "utils/roles";
import styleVariables from "styleVariables";
import createTheme from "core/createTheme";

const useStyles = makeStyles((theme) => ({
  // BACKGROUND COLORS
  primaryBackground: {
    backgroundColor: variables.primaryMain,
    "&:hover": {
      backgroundColor: variables.primaryDark,
    },
  },
  secondaryBackground: {
    backgroundColor: variables.secondaryMain,
    "&:hover": {
      backgroundColor: variables.secondaryDark,
    },
  },
  tertiaryBackground: {
    backgroundColor: variables.tertiary2,
    "&:hover": {
      backgroundColor: variables.tertiary2Dark,
    },
  },
  warningBackground: {
    backgroundColor: variables.warningMain,
    color: "white",

    "&:hover": {
      backgroundColor: variables.warningLight,
    },
  },
  greyBackground: {
    backgroundColor: variables.grayPrimary,
    color: "white",

    "&:hover": {
      backgroundColor: "#727c83",
    },
  },
  whiteBackground: {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: variables.tertiary1Light,
    },
  },

  // LABEL COLORS
  primaryLabel: {
    color: variables.primaryMain,

    "&:hover": {
      color: variables.primaryDark,
    },
  },
  secondaryLabel: {
    color: variables.secondaryMain,

    "&:hover": {
      color: variables.secondaryDark,
    },
  },
  tertiaryLabel: {
    color: variables.tertiary1,

    "&:hover": {
      color: variables.tertiary1,
    },
  },
  warningLabel: {
    color: variables.warningMain,

    "&:hover": {
      color: variables.warningLight,
    },
  },
  whiteLabel: {
    color: "white",
  },

  // VARIANTS

  // Small Buttons
  buttonSmall: {
    fontWeight: "bold",
    fontSize: variables.fontXxs,
    borderRadius: 30,
    lineHeight: "normal",
    paddingTop: 8,
    paddingBottom: 8,
    alignItems: "center",
  },
  buttonLabelSmall: {
    textAlign: "left",
  },
  buttonIconSmall: {
    display: "flex",
    width: "fit-content",
    alignItems: "center",
    justifyContent: "center",
  },
  // Medium Buttons (Default)
  buttonMedium: {
    fontSize: variables.fontSmall,
    fontWeight: "bold",
    borderRadius: 30,
    alignItems: "center",
    lineHeight: "normal",
    minWidth: "unset",
    padding: "12px 24px",
    "& .MuiButton-label": {
      display: "flex",
      alignItems: "center",
    },
  },
  buttonMediumLabel: {
    fontSize: variables.fontSmall,
    alignItems: "center",
    lineHeight: "normal",
  },
  buttonMediumIcon: {
    display: "flex",
    width: "fit-content",
  },

  // Large Buttons
  buttonLarge: {
    fontSize: variables.fontMedium,
    fontWeight: "bold",
    borderRadius: 30,
    alignItems: "center",
    lineHeight: "normal",
    padding: "14px",
  },

  // iconSmall
  iconSmallButton: {
    position: "relative",
    backgroundColor: "transparent",
    margin: "-6px -8px",
    padding: "6px 8px",
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    minWidth: "unset",
    "&:active": {
      backgroundColor: "transparent",
    },
    "&:hover": {
      backgroundColor: "white",
    },
  },
  iconButtonIcon: {
    display: "flex",
    alignItems: "center",
    ".Mui-disabled &": {
      color: styleVariables.grayDisabledText
    }
  },
  iconButtonLabel: {
    position: "absolute",
    opacity: "0",
    fontSize: variables.fontXxs,
    fontWeight: "bold",
    lineHeight: ".7rem",
    backgroundColor: "transparent",
    textAlign: "center",
    paddingTop: 5,
    paddingBottom: 5,
    whiteSpace: "nowrap",
    "&:hover": {
      opacity: "1",
    },
    "&:hover + $iconButtonIcon": {
      opacity: 0,
      visibility: "hidden",
      zIndex: -1
    },
    ".Mui-disabled &": {
      color: styleVariables.grayDisabledText
    }
  },

  // PROP-BASED STYLES

  // Fixed
  fixedRight: {
    borderRadius: "10px 0px 0px 10px",
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 30,
    paddingRight: 10,
    maxWidth: 155,
    "&.MuiButton-contained.Mui-disabled": {
      background: styleVariables.grayDisabledBg,
      color: styleVariables.grayDisabledText,
    },
  },
  fixedRightSmall: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
  },
  fixedRightLabel: {
    maxWidth: 100,
    textAlign: "left",
    marginLeft: 10,
  },
}));

// REMOVES HOVER EFFECT FROM ICON HOVER BUTTONS
const IconHoverButton = createTheme({
  overrides: {
    MuiButton: {
      root: {
        padding: 6,
        "&:hover": {
          backgroundColor: "none",
        },
      },
    },
  }
});

const VARIANT_ICON_BUTTON_SMALL = "iconSmall";
const VARIANT_BUTTON_SMALL = "small";
const VARIANT_BUTTON_MEDIUM = "medium";
const VARIANT_BUTTON_LARGE = "large";

const ButtonDefault = (props) => {
  const classes = useStyles();

  const {
    children,
    className,
    background: backgroundProp,
    color: colorProp,
    disabled: disabledProp,
    disableReadOnlyUsers,
    fixedRight,
    startIcon,
    variant = VARIANT_BUTTON_MEDIUM,
    ...passthroughProps
  } = props;

  const readOnly = useMemo(() => {
    return hasReadOnlyAccess()
  }, []);

  const isDisabled = disabledProp || (!!disableReadOnlyUsers && readOnly);

  const background = useMemo(() => (
    !backgroundProp && !colorProp ? "secondary" : backgroundProp
  ), [backgroundProp, colorProp]);

  const color = useMemo(() => (
    !backgroundProp && !colorProp ? "white" : colorProp
  ), [backgroundProp, colorProp]);

  const backgroundClass = useMemo(() => {
    switch (background) {
      case "primary":
        return classes.primaryBackground;
      case "secondary":
        return classes.secondaryBackground;
      case "tertiary":
        return classes.tertiaryBackground;
      case "tertiary2":
        return classes.tertiary2Background;
      case "grey":
        return classes.greyBackground;
      case "red":
        return classes.warningBackground;
      case "white":
      default:
        return classes.whiteBackground;
    }
  }, [background, classes]);

  const labelClass = useMemo(() => {
    switch (color) {
      case "primary":
        return classes.primaryLabel;
      case "secondary":
        return classes.secondaryLabel;
      case "tertiary":
        return classes.tertiaryLabel;
      case "grey":
        return classes.greyLabel;
      case "red":
        return classes.warningLabel;
      case "white":
      default:
        return classes.whiteLabel;
    }
  }, [classes, color]);

  switch (variant) {
    case VARIANT_ICON_BUTTON_SMALL:
      if (background && background !== "white") {
        console.error(
          "ButtonDefault: `background` prop cannot be set for `iconSmall` variant.\n" +
          "Use `color` instead."
        );
      }
      return (
        <ThemeProvider theme={IconHoverButton}>
          <Button
            className={
              classNames(
                classes.iconSmallButton,
                className,
                fixedRight && classes.fixedRight
              )
            }
            startIcon=""
            disabled={isDisabled}
            disableTouchRipple
            disableRipple
            {...passthroughProps}
          >
            <span className={classNames(classes.iconButtonLabel, labelClass, fixedRight && classes.fixedRightLabel)}>
              {children}
            </span>
            <span className={classNames(classes.iconButtonIcon, labelClass)}>
              {startIcon}
            </span>
          </Button>
        </ThemeProvider>
      );
    case VARIANT_BUTTON_SMALL:
      return (
        <Button
          className={
            classNames(
              classes.buttonSmall,
              backgroundClass,
              className,
              fixedRight && classes.fixedRight,
              fixedRight && classes.fixedRightSmall
            )
          }
          variant="contained"
          disabled={isDisabled}
          {...passthroughProps}
          startIcon=""
        >
          <span className={classNames(classes.buttonIconSmall, labelClass)}>
            {startIcon}
          </span>
          <span className={classNames(classes.buttonLabelSmall, labelClass, fixedRight && classes.fixedRightLabel)}>
            {children}
          </span>
        </Button>
      );
    case VARIANT_BUTTON_LARGE:
      return (
        <Button
          className={
            classNames(
              classes.buttonLarge,
              backgroundClass,
              className,
              fixedRight && classes.fixedRight
            )
          }
          variant="contained"
          disabled={isDisabled}
          {...passthroughProps}
          startIcon=""
        >
          <span className={classNames(classes.buttonMediumIcon, labelClass)}>
            {startIcon}
          </span>
          <span
            className={
              classNames(
                classes.buttonMediumLabel,
                labelClass,
                fixedRight && classes.fixedRightLabel
              )
            }
          >
            {children}
          </span>
        </Button>
      );
    case VARIANT_BUTTON_MEDIUM:
    default:
      return (
        <Button
          className={
            classNames(
              classes.buttonMedium,
              backgroundClass,
              props.className,
              fixedRight && classes.fixedRight,
            )
          }
          variant="contained"
          disabled={isDisabled}
          {...passthroughProps}
          startIcon=""
        >
          <span className={classNames(classes.buttonMediumIcon, labelClass)}>
            {props.startIcon}
          </span>
          <span
            className={
              classNames(classes.buttonMediumLabel,
              labelClass,
              fixedRight && classes.fixedRightLabel)
            }
          >
            {props.children}
          </span>
        </Button>
      );
  }
};

export default ButtonDefault;
