import http from "./http-common";
import authHeader from "./authHeader";

class ImageService {
  constructor() {
    this.imageCache = {};
  }

  // get logo base64
  async getImage(id, beforeFetch = () => {}) {
    const cachedLogo = this.imageCache[id];
    if (cachedLogo) {
      return Promise.resolve({ data: { payload: cachedLogo } });
    }
    beforeFetch(id);
    const response = await http.get(`/image/${id}`, { headers: authHeader() });
    this.imageCache[id] = response.data.payload;
    return response;
  }

  isCached(id) {
    return !!this.imageCache[id];
  }

  clearCachedImage(id) {
    return delete this.imageCache[id];
  }
}

export default new ImageService();