import React, {
  useEffect,
  useMemo,
  useReducer,
  useState,
  useRef,
  useCallback,
} from "react";
import DataTable from "components/utils/tables/dataTable.component";
import EditIconButton from "components/utils/editIconButton.component";
import { Box } from "@material-ui/core";
import AddListItem from "components/utils/addListItem.component";
import CustomModal from "components/utils/modal.component";
import { makeStyles } from "@material-ui/core";
import Loader from "components/utils/loader.components";
import variables from "styleVariables";
import contactsReducer, {
  ACTION_REPLACE_CONTACT,
  ACTION_SET_CONTACT_TABLE,
  contactsInitialState,
} from "reducers/contacts.reducer";
import ContactForm from "./contactForm.component";
import certificationService from "services/certification.service";
import useNumericParams from "hooks/useNumericParams";
import UserRolePicker from "components/utils/form-elements/userRolePicker.component";
import { contactListRoleOptions } from "utils/certificationConstants";
import CollapsableAlert from "components/utils/collapsableAlert.component";
import useTargetTableRowViaLink from "hooks/useTargetTableRowViaLink";
import { HTTP_STATUS_CONTENT } from "services/http-common";
import { createNodeCellParams, createNodeHeaderParams, createValueHeaderParams } from "components/utils/tables/utils/dataTableHelpers";
import { mapObjectArrayByKey } from "utils/arrayOfObjectsHelpers";

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    paddingLeft: 5,
    paddingTop: 20,
    display: "flex",
  },
  picker: {
    margin: 0,
  },
}));


const roleLabels = mapObjectArrayByKey(contactListRoleOptions, "value");

const headers = [
  createNodeHeaderParams("UserInfo_ID", " ", {
    sort: false,
    setCellHeaderProps: () => ({ "data-cy": "contact-table-edit-header" }),
    setCellProps: () => ({ style: { width: "40px" } }),
  }),
  createValueHeaderParams("Last_Name", "Last Name", { filter: false }),
  createValueHeaderParams("First_Name", "First Name", { filter: false }),
  createValueHeaderParams("Organization", "Organization"),
  createValueHeaderParams("Position", "Position"),
  createValueHeaderParams("Email", "Email", { filter: false, sort: false }),
  createValueHeaderParams("Phone", "Phone", { filter: false, sort: false }),
  createNodeHeaderParams("Role", "Portal Access", { sort: false }),
];

const ContactList = () => {
  const classes = useStyles();
  const [formMode, setFormMode] = useState(null);
  const [userToEdit, setUserToEdit] = useState(null);
  const [emailMessage, setEmailMessage] = useState("");
  const [roleSelectError, setRoleSelectError] = useState();
  const [state, dispatch] = useReducer(contactsReducer, contactsInitialState);
  const rowRef = useRef({});
  const [targetedRowId, clearTargetedRow] = useTargetTableRowViaLink(rowRef, !!state.contacts?.length);
  const { programId } = useNumericParams();

  useEffect(() => {
    const getContactList = async () => {
      const response = await certificationService.getActiveContactsByProgramId(
        programId
      );
      dispatch({
        type: ACTION_SET_CONTACT_TABLE,
        payload: response.payload,
      });
    };
    getContactList();
  }, [programId]);

  const openForm = (editUser) => {
    if (editUser) {
      setFormMode("edit");
      setUserToEdit(editUser);
    } else {
      setFormMode("create");
    }
  };

  const closeForm = () => {
    setFormMode(null);
    setUserToEdit(null);
    clearTargetedRow()
  };

  const handleUpdateRole = useCallback(
    (event, user) =>
      (async function() {
        setEmailMessage();
        const userData = {
          User_ID: user.User_ID,
          Role_ID: event.target.value,
        };

        const userInfoData = {
          UserInfo_ID: user.UserInfo_ID,
        };

        const upsertUserRes = await certificationService
          .upsertContact({
            userData,
            userInfoData,
          })
          .catch((err) => {
            if (err.response && err.response.status === HTTP_STATUS_CONTENT) {
              setRoleSelectError(
                "Portal access cannot be granted becasue the associated email is not unique."
              );
            } else {
              setRoleSelectError("There was a problem updating portal access");
            }
          });

        if (!upsertUserRes) {
          return;
        }

        if (upsertUserRes.emailSent) {
          const userEmail = upsertUserRes.payload.Email;
          setEmailMessage(
            `A reset password email has been sent to ${userEmail}`
          );
        }
        dispatch({
          type: ACTION_REPLACE_CONTACT,
          payload: upsertUserRes.payload,
        });
      })(),
    [dispatch, setEmailMessage]
  );

  const tableCells = useMemo(() => {
    return (
      state.contacts?.map((row) => {
        return {
          UserInfo_ID: createNodeCellParams(null, null, (
            <div
              id={row.UserInfo_ID}
              ref={(el) => (rowRef.current[row.UserInfo_ID] = el)}
            >
              <EditIconButton onClick={() => openForm(row)} />
            </div>
          )),
          Last_Name: row.Last_Name,
          First_Name: row.First_Name,
          Position: row.Position,
          Organization: row.Organization,
          Email: row.Email,
          Phone: row.Phone,
          Role: createNodeCellParams(
            null,
            roleLabels[row.Role_ID]?.label || "No Access",
            (
              <Box marginTop={1}>
                <UserRolePicker
                  className={classes.picker}
                  hideLabel
                  disabled={!row.Email}
                  onChange={(event) => handleUpdateRole(event, row)}
                  value={row.Role_ID || "None"}
                  menuOptions={contactListRoleOptions}
                />
              </Box>
            )
          ),
        };
      }) || []
    );
  }, [state.contacts, classes.picker, handleUpdateRole]);

  if (!state.contacts) {
    return <Loader />;
  }

  return (
    <Box marginTop={4} marginBottom={2} data-cy="contacts-page-container">
      <Box marginBottom={1} marginTop={10}>
        <CollapsableAlert
          showAlert={emailMessage}
          closeClick={() => setEmailMessage(false)}
          message={emailMessage}
        />
        <CollapsableAlert
          showAlert={roleSelectError}
          closeClick={() => setRoleSelectError()}
          message={roleSelectError}
          severity="error"
        />
      </Box>
      <DataTable
        data={tableCells}
        columns={headers}
        options={{
          textLabels: {
            body: {
              noMatch: "No contacts",
            },
          },
          filterType: "checkbox",
          fixedHeader: true,
          fixedSelectColumn: true,
          pagination: false,
          selectableRowsHideCheckboxes: true,
          viewColumns: false,
          download: false,
          print: false,
          setRowProps: (row, dataIndex, rowIndex) => {
            if (row[0].props && row[0].props.id === targetedRowId) {
              return { style: { backgroundColor: variables.chosenElement } };
            }
          },
        }}
      />
      <Box className={classes.buttonWrapper}>
        <AddListItem onClick={() => openForm()} test="contact">Add Contact</AddListItem>
      </Box>
      <CustomModal
        open={formMode === "edit" || formMode === "create"}
        onClose={() => closeForm()}
      >
        <ContactForm
          formMode={formMode}
          user={userToEdit}
          closeForm={closeForm}
          dispatch={dispatch}
          setEmailMessage={setEmailMessage}
          programId={programId}
        />
      </CustomModal>
    </Box>
  );
};

export default ContactList;
