import React, { useState, useEffect, useContext, useCallback } from "react";
import { MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import moment from "moment";
import LabelInput from "components/utils/form-elements/labelInput.component";
import CustomSelect from "components/utils/form-elements/select.component";
import FormBanner from "components/utils/form-elements/formBanner.component";
import ProgramSelect from "components/utils/form-elements/programSelect.component";
import { currentUserInfo } from "utils/userHelpers";
import { updateOrCreateWorkpackage } from "utils/workpackageTaskHelpers";
import ProgramsContext from "contexts/programs.context";
import { setStateFetchEffect } from "utils/ajaxHelpers";
import { ACTION_REPLACE_PROGRAM_COMPONENTS } from "reducers/global/program.reducer";
import ProgramService from "services/program.service";
import { PROGRAM_COMPONENT_TYPE_DASHBOARD } from "utils/programConstants";
import WorkpackageTasksContext from "contexts/workpackageTasks.context";
import { ACTION_REPLACE_WORKPACKAGES } from "reducers/global/workpackageTasks.reducer";

const formModes = {
  CREATE: "create",
  EDIT: "edit"
}

const parentComponents = {
  APPLICATION_SCHEDULE: "ApplicationSchedule"
}

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 8,
    justifyContent: "center",
    paddingBottom: 40,
  },
  form: {
    padding: "40px 50px 50px 60px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingBottom: 0,
    alignItems: "center",
  },
  contents: {
    paddingBottom: 20,
  },
  inputWrapper: {
    paddingTop: 0,
    width: 300,
    paddingBottom: 10,
  },
}));

const WorkPackageForm = (props) => {
  const classes = useStyles();
  const { state: programState, dispatch: programDispatch } = useContext(
    ProgramsContext
  );
  const { dispatch: wpTaskDispatch } = useContext(WorkpackageTasksContext);
  const [selectedComponents, setSelectedComponents] = useState();
  const [readyToLoadComps, setReadyToLoadComps] = useState(false);
  const [formData, setFormData] = useState()

  const fetchSelectedComponents = useCallback(async programId => {
    let components = programState.programComponentsByProgram?.[programId];
    if (!components) {
      setReadyToLoadComps(false);
      components = await (
        ProgramService.getProgramComponentsByProgramId(programId)
      )?.payload;
      programDispatch({
        type: ACTION_REPLACE_PROGRAM_COMPONENTS,
        payload: components
      });
    }
    return components.filter(component => (
      component.Type !== PROGRAM_COMPONENT_TYPE_DASHBOARD
    ));
  }, [programDispatch, programState.programComponentsByProgram]);

  useEffect(() => {
    if (props.workpackageData && props.workpackageFormMode !== formModes.CREATE) {
      setFormData({
        chosenProgramId: props.programId,
        name: props.workpackageData.Name,
        description: props.workpackageData.Description,
        type: null,
        componentId: props.workpackageData.ProgramComponents_Component_ID,
      });
    } else {
      setFormData({
        chosenProgramId: props.programId,
        name: "",
        description: "",
        type: null,
        componentId: null,
      });
    }
    return setStateFetchEffect(
      fetchSelectedComponents(props.programId),
      ([components]) => {
        setReadyToLoadComps(true);
        setSelectedComponents(components);
      }
    );
  }, [
    fetchSelectedComponents, props.programId, props.fromOrchestrationLevel,
    props.workpackageData, props.workpackageFormMode
  ]);

  const handleChangeSelect = async (event) => {
    if (event.target.name === "type") {
      setFormData({
        ...formData,
        type: event.target.value,
      });
    }
    if (event.target.name === "chosenProgramId" && props.fromOrchestrationLevel) {
      const components = await fetchSelectedComponents(event.target.value);
      setReadyToLoadComps(true);
      setSelectedComponents(components);
      setFormData({
        ...formData,
        type: null,
        chosenProgramId: event.target.value,
      });
    }
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.currentTarget.id]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const user = currentUserInfo();
    const newWp = {
      Name: formData.name,
      Description: formData.description === "" ? null : formData.description,
      Status: "Active",
      Change_Date: moment(new Date()).format("YYYY-MM-DD"),
      Change_User: user.id,
      Program_Program_ID: props.fromOrchestrationLevel
        ? formData.chosenProgramId
        : props.programId,
      ProgramComponents_Component_ID:
        formData.type === "" || formData.type === "None" ? null : formData.type,
    };
    updateOrCreateWorkpackage(
      props.workpackageFormMode,
      newWp,
      props.workpackageData?.Workpackage_ID
    ).then(newWorkpackage => {
      wpTaskDispatch({
        type: ACTION_REPLACE_WORKPACKAGES,
        payload: [newWorkpackage]
      });
      if (props.onSuccess) {
        props.onSuccess(newWorkpackage.Workpackage_ID);
      }
    })
    props.setEditModal(false);
    props.setWpDashRefresh(new Date());
  };

  if(formData) {
    return (
      <div className={classes.formContainer}>
        <FormBanner>
          {props.workpackageFormMode === formModes.CREATE ? "Add New" : "Edit"} Workpackage
        </FormBanner>
        <form className={classes.form} data-cy="form-workpackage">
          <div className={classes.contents}>
            {!!props.fromOrchestrationLevel && (
              <div className={classes.inputWrapper}>
                <ProgramSelect
                  handleChange={handleChangeSelect}
                  formData={formData}
                />
              </div>
            )}
            <div className={classes.inputWrapper}>
              <LabelInput
                required
                error={formData.name.trim() === ""}
                variant="default"
                label="Name"
                onChange={handleChange}
                value={formData.name || ""}
                margin="dense"
                id="name"
                test="WorkpackageName"
              />
            </div>
            <div className={classes.inputWrapper}>
              <LabelInput
                variant="default"
                label="Description"
                multiline
                onChange={handleChange}
                value={formData.description || ""}
                id="description"
                test="WorkpackageDescription"
              />
            </div>
            {props.parentComponent !== parentComponents.APPLICATION_SCHEDULE && (
              <div className={classes.inputWrapper}>
                {!!readyToLoadComps && (
                  <CustomSelect
                    label="Program Component"
                    value={formData.type || ""}
                    onChange={handleChangeSelect}
                    name="type"
                    id="type"
                    test="wpForm-comps"
                  >
                    <MenuItem value="None" data-cy="menuItem-None">
                      None
                    </MenuItem>
                    {selectedComponents?.map?.((item) => (
                      <MenuItem
                        value={item.Component_ID}
                        name={item.Name}
                        key={item.Name}
                        id={item.Component_ID}
                      >
                        {item.Name}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                )}
              </div>
            )}
          </div>
          <DualFormButtons
            cancelOnClick={() => props.setEditModal(false)}
            saveOnClick={handleSubmit}
            disabled={formData.name.trim() === ""}
          />
        </form>
      </div>
    );
  } else {
    return "";
  }

};

export default WorkPackageForm;
