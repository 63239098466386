import { makeStyles, MobileStepper, Paper } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import ButtonDefault from "components/utils/buttonDefault.component";
import DisplayHTML from "components/utils/displayHTML.component";
import variables from "styleVariables";
import Stepper from "components/utils/stepper.component";
import React, { useMemo, useState, useCallback } from "react";
import Loader from "components/utils/loader.components";
import classNames from "classnames";
import { useEffect } from "react";
import { H3 } from "components/utils/headerV2.component";

const useStyles = makeStyles((theme) => ({
  nestedContent: {
    padding: 24,
    margin: 0,
    display: "flex",
    flexDirection: "column",
    color: variables.textSecondary,
    borderRadius: 5,
    '@media (max-height: 580px)': {
      padding: "0px 16px 10px 16px",
    }
  },
  stepper: {
    marginTop: "auto",
  },
  procedureContentContainer: {
    flex: 1,
    '@media (max-height: 700px)': {
      padding: "0px 10px 10px",
    }
  },
  contentWrapper: {
    padding: "14px 24px 0px 24px",
    height: "100%",
    maxHeight: "calc(100% - 52px)",
  },
  stepperTitleContainer: {
    marginLeft: 20,
    '@media (max-height: 700px)': {
      padding: "10px",
    }
  },
  slideHtml: {
    fontSize: variables.fontMedium,
    height: "fit-content",
  },
  arrowIcon: {
    fill: "white",
    fontSize: 20,
  },
  htmlWrapper: {
    overflowY: "scroll",
    paddingRight: 20,
    maxHeight: "inherit",
    '@media (max-height: 700px)': {
      "& > div > ol": {
        margin: 0,
      },
    }
  },
}));

const SlideNavButton = ({ direction, onClick, disabled }) => {
  const classes = useStyles();
  return (
    <ButtonDefault
      startIcon={
        direction === "Back" && <ArrowBackIos className={classes.arrowIcon} />
      }
      endIcon={
        direction === "Next" && (
          <ArrowForwardIos className={classes.arrowIcon} />
        )
      }
      onClick={onClick}
      disabled={disabled}
      data-cy={`btn-training-${direction}`}
      variant="small"
    >
      {direction}
    </ButtonDefault>
  );
};

const ChildTrainingModule = ({ slides }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [highestSavedStep, setHighestSavedStep] = useState(0);
  const stepperSlides = useMemo(() => {
    return slides.map((slide, index) => ({ stepNum: index, name: slide.Slide_Header }))
  }, [slides]);

  useEffect(() => {
    setActiveStep(0);
    setHighestSavedStep(0);
  }, [slides])


  const handleActiveStep = useCallback(async (direction) => {
    if (direction === "forward") {
      setActiveStep(activeStep + 1);
      if (activeStep + 1 > highestSavedStep) {
        setHighestSavedStep(activeStep + 1);
      }
    } else if (direction === "backward") {
      setActiveStep(activeStep - 1);
    }
  }, [activeStep, highestSavedStep]);

  const stepIconClick = useCallback(async (arg1, stepNumber) => {
    setActiveStep(stepNumber);
  }, [setActiveStep]);

  const activeSlide = useMemo(() => {
    return slides[activeStep];
  }, [activeStep, slides]);

  if (!activeSlide) {
    return <Loader />
  }

  return (
    <>
      <Paper
        className={
          classNames(classes.nestedContent, classes.procedureContentContainer)
        }
        elevation={1}
      >
        <div className={classes.contentWrapper}>
          {!!activeSlide.Slide_Header && (
            <H3 color="tertiary">
              {activeSlide?.Slide_Header}
            </H3>
          )}
          <div className={classes.htmlWrapper}>
            <DisplayHTML
              html={activeSlide.Slide_Text}
              className={classes.slideHtml}
              slideText
            />
          </div>
        </div>
        <MobileStepper
          variant="dots"
          steps={slides.length}
          position="static"
          activeStep={activeStep}
          className={classes.stepper}
          nextButton={
            <div className={classes.nextButtonWrapper}>
              <SlideNavButton
                direction="Next"
                onClick={() => handleActiveStep("forward")}
                disabled={activeStep === (slides.length - 1)}
              />
            </div>
          }
          backButton={
            <div className={classes.backButtonWrapper}>
              <SlideNavButton
                direction="Back"
                onClick={() => handleActiveStep("backward")}
                disabled={activeStep === 0}
              />
            </div>
          }
        />
      </Paper>
      <Paper
        className={
          classNames(classes.nestedContent, classes.stepperTitleContainer)
        }
        elevation={1}
      >
        <Stepper
          steps={stepperSlides}
          activeStep={activeStep - 1}
          highestSavedStep={highestSavedStep}
          stepIconClick={stepIconClick}
          orientation="vertical"
          noWrapLabel
          startingIndex={-1}
          allowScroll
        />
      </Paper>
    </>
  )
};

export default ChildTrainingModule;
