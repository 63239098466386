import React from "react";

/*
 * Values:
 * {
 *   dispatch: asyncDispatch,
 *   state: WorkpackageTasksReducer
 * }
 */
const WorkpackageTasksContext = React.createContext();

export default WorkpackageTasksContext;
