import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import Loader from "components/utils/loader.components";
import { useMemo } from "react";

const useStyles = makeStyles((theme) => ({
  checklistBody: {
    lineHeight: 1.41,
  },
  checklistEmpty: {
    color: theme.palette.text.secondary,
    marginTop: 0,
  },
  checklistList: {
    marginTop: 0,
    paddingLeft: 15,
    whiteSpace: "nowrap"
  },
  checklistItem: {
    color: theme.palette.text.secondary,
  },
}));

// Checklist items for an assessment item, shown on design/performance data collection pages
export default function DataCollectionChecklist({ assessmentItem, checklistItems, reviewTypeId }) {
  const classes = useStyles();

  const dataCollectionChecklistItems = useMemo(() => {
    if (!checklistItems) {
      return null;
    }
    return checklistItems.filter(item => (
      item.AssessmentItem_ID === assessmentItem.AssessmentItem_ID &&
      item.Review_Type === reviewTypeId
    ));
  }, [assessmentItem, checklistItems, reviewTypeId]);


  let content = null;
  if (!dataCollectionChecklistItems) {
    content = <Loader size={20} height="auto" />;
  } else if (!dataCollectionChecklistItems?.length) {
    content = (
      <p className={classNames(classes.checklistEmpty)}>
        None
      </p>
    );
  } else {
    content = (
      <ul className={classNames(classes.checklistList)}>
        {dataCollectionChecklistItems.map(item => (
          <li
            className={classNames(classes.checklistItem)}
            key={`item-${item.ChecklistItem_ID}`}
          >
            <span>{item.Checklist_Item}</span>
          </li>
        ))}
      </ul>
    );
  }
  return (
    <div className={classNames(classes.checklistBody)}>
      {content}
    </div>
  );
}
