import React from "react";

/*
 * Values:
 * {
 *   permittedPortfolioHolders: List of PortfolioHolder that current user has access to
 *   permittedPortCos: List of PortCos that current user has access to, regardless of current holderId
 * }
 */
const PortfolioProgramContext = React.createContext({
  permittedPortfolioHolders: null,
  permittedPortCos: null,
});

export default PortfolioProgramContext;
