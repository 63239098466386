import FormContext from "contexts/form.context";
import { cloneElement, memo, useCallback, useContext, useEffect, useRef, useState } from "react";
import { validateField } from "utils/formValidators";

const AUTOSAVE_INTERVAL = 1000 * 60;

/*
 * Adds autosave functionality to existing onFocus and onBlur event handlers.
 * Autosaves when the route is changed, also at a 1 minute interval if still unsaved.
 */
export default function FormElementWithAutosave(props) {
  const { children, onAutosave, ...otherProps } = props;
  if (!onAutosave) {
    return cloneElement(children, otherProps);
  }
  return (
    <FormElementWithAutosaveBase {...props} />
  );
}

function FormElementWithAutosaveBase(props) {
  const { name, value: controlledValue } = props;

  const context = useContext(FormContext);
  const { errors, publishFormError, validations, values: contextValues } = context;

  const publishInvalidField = useCallback((_fieldname, value, event) => {
    if (validations?.[name]) {
      const error = validateField(validations?.[name], value);
      if (error) {
        publishFormError(context, name, error, event);
        return true;
      }
    }
    if (errors?.[name]) {
      publishFormError(context, name, null, event);
    }
    return false;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, validations?.[name] ? [context, errors, name, validations] : []);

  const fieldValue = controlledValue ?? contextValues[name];
  const hasFormContext = Object.keys(contextValues || {}).length > 0;
  return (
    <FormElementWithAutosaveMemoized
      {...props}
      fieldValue={fieldValue}
      fieldValidations={validations?.[name]}
      hasFormContext={hasFormContext}
      publishInvalidField={publishInvalidField}
    />
  );
}

const FormElementWithAutosaveMemoized = memo(function(props) {
  const { children, onAutosave, onBlur, onFocus, id, name } = props;
  const {
    fieldValue,
    fieldValidations,
    hasFormContext,
    publishInvalidField,
    ...contextlessProps
  } = props;

  const [lastSavedValue, setLastSavedValue] = useState();

  const autosaveTimerRef = useRef(null);
  const isMountedRef = useRef(true);

  const handleAutosave = useCallback(async (event, clearInterval = true, overrideValue = null) => {
    const value = overrideValue ?? fieldValue;

    // Prevent 2 qualifying events fired at the same time from saving twice
    if (!autosaveTimerRef.current) {
      return;
    }
    // Avoid useless ajax request
    if (lastSavedValue === value) {
      return;
    }
    // Warn if component is neither 1) A child of <Form /> (which provides contextValues), 2) A Controlled input.
    if ((value === null || value === undefined) && !hasFormContext) {
      console.error(
        `Attempted to autosave input with name '${name}' but it was not set up correctly to do so.\n\n` +
        "Component must either be a child of 'form.component.js', or use a controlled value."
      );
    }

    if (clearInterval) {
      window.clearInterval(autosaveTimerRef.current);
      autosaveTimerRef.current = null;
    }
    const didPublishError = publishInvalidField(name, value, event);
    if (didPublishError) {
      return;
    }
    const response = await onAutosave(name || id, value, event);
    if (isMountedRef.current) {
      setLastSavedValue(value);
    }
    return response;
  }, [id, name, onAutosave, fieldValue, lastSavedValue, hasFormContext, publishInvalidField]);

  const handleAutosaveRef = useRef();

  // Reset the event handler if handler function has changed
  useEffect(() => {
    if (handleAutosaveRef.current && handleAutosaveRef.current !== handleAutosave) {
      window.removeEventListener("popstate", handleAutosaveRef.current, { capture: true });
      window.addEventListener("popstate", handleAutosave, { capture: true });
    }
    handleAutosaveRef.current = handleAutosave;
    return () => {
      isMountedRef.current = false;
    }
  }, [handleAutosave, fieldValidations]);

  const handleFocus = useCallback(event => {
    window.addEventListener("popstate", handleAutosaveRef.current, { capture: true });

    const timer = window.setInterval(
      () => handleAutosaveRef.current(null, false),
      AUTOSAVE_INTERVAL
    );
    autosaveTimerRef.current = timer;
    return onFocus?.(event)
  }, [onFocus]);

  const handleBlur = useCallback((event, forceValue = null) => {
    window.removeEventListener("popstate", handleAutosaveRef.current, { capture: true });

    handleAutosaveRef.current(event, true, forceValue);
    return onBlur?.(event);
  }, [onBlur]);

  return cloneElement(children, {
    ...contextlessProps,
    onBlur: handleBlur,
    onFocus: handleFocus,
  });
});

FormElementWithAutosaveMemoized.displayName = "FormElementWithAutosaveMemoized";
