import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import PulseLoader from "react-spinners/PulseLoader";
import MoonLoader from "react-spinners/MoonLoader";
import variables from "styleVariables";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  loadWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  height: {
    height: "calc(100vh - 240px)",
  }
}));

export const Loader = (props) => {
  const classes = useStyles();

  if (props.variant === "search") {
    return (
      <div className={classNames(classes.loadWrap, classes.height)}>
        <PulseLoader
          size={props.size ? props.size : 17}
          color={props.color ? props.color : variables.tertiary1}
        />
      </div>
    );
  } else {
    return (
      <Grid
        item xs={12}
        className={classNames(classes.loadWrap, classes.height)}
      >
        <div style={{ height: props.height }}>
          <MoonLoader
            size={props.size || 50}
            color={props.color || variables.primaryMain}
          />
        </div>
      </Grid>
    );
  }
};

export default Loader;
