import http from "./http-common";
import authHeader from "./authHeader";

class ProgramService {
  getAll() {
    return http
      .get(`/program/`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getProgramComponentsByProgramId(programId) {
    return http
      .get(`/program/programComponents/${programId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getProgramComponentObjectsByComponentId(componentId) {
    return http
      .get(`/program/programComponentObjects/${componentId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getComponentObjectById(componentObjectId) {
    return http
      .get(`/program/componentObjectById/${componentObjectId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getComponentObjectByProgramAndTypeValues(programId, type, typeRefPart) {
    const part = typeRefPart.replace(/Program(?=\w)/i, "");
    return http.get(
      `/program/${programId}/componentObjects/type/${type}/typeRefPart/${part}`,
      { headers: authHeader() }
    ).then(response => response.data);
  }

  getActiveProgramComponentIds() {
    return http.get(
      `/program/programComponents/active/index/ids`,
      { headers: authHeader() }
    ).then(response => response.data);
  }

  getActiveComponentObjectIds() {
    return http.get(
      `/program/componentObjects/active/index/ids`,
      { headers: authHeader() }
    ).then(response => response.data);
  }
}

export default new ProgramService();
