import http from "./http-common";
import authHeader from "./authHeader";

class RoleService {
  getAll() {
    return http.get(`/role/`, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }
}

export default new RoleService();
