import { recurseOneAssociation, validateSchema } from "./shared";

const deleteTargetOnMatch = (item, action, schema) => {
  if (schema.target) {
    validateSchema(schema.target);
    const targetAssociations = item._associations[schema.target.type];
    const payloadId = parseInt(action.payload?.[schema.target.id] || action.payload, 10);
    const targetIndex = targetAssociations.findIndex(potentialToDelete => (
      potentialToDelete[schema.target.id] === payloadId
    ));
    if (targetIndex > -1) {
      const resultItem = { ...item };
      resultItem._associations[schema.target.type].splice(targetIndex, 1);
      resultItem._associations[schema.target.type] = [
        ...resultItem._associations[schema.target.type]
      ];
      return resultItem;
    }
  }
  return null;
}

// Delete one item from source associations if a
//   match with action.payload is found based on schema.
export const deleteOneBySchema = (source, action, schema) => {
  validateSchema(schema);
  if (schema.target && source._associations?.[schema.target.type]) {
    const updatedItem = deleteTargetOnMatch(source, action, schema);
    if (updatedItem) {
      return updatedItem;
    }
  }
  if (schema.association) {
    return recurseOneAssociation(source, action, schema, deleteOneBySchema);
  }
  return source;
}

// Delete many associations from one item if a match is found.
export const deleteManyBySchema = (source, action, schema) => {
  let deleteOneResult;
  let changed = false;
  for (const deletedId of action.payload) {
    const reconstructedAction = { ...action, payload: deletedId };
    deleteOneResult = deleteOneBySchema(source, reconstructedAction, schema);
    if (deleteOneResult && deleteOneResult !== source) {
      changed = true
    }
  }
  if (changed) {
    return deleteOneResult
  }
}
