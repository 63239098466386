import { Box, makeStyles } from "@material-ui/core";
import { DashboardOutlined } from "@material-ui/icons";
import ButtonDefault from "components/utils/buttonDefault.component";
import Loader from "components/utils/loader.components";
import CustomModal from "components/utils/modal.component";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ACTION_SET_MANUALS_INDEX } from "reducers/certification/manuals.reducer";
import CertificationService from "services/certification.service";
import { isReadOnly } from "utils/roles";
import CreateManualForm from "./createManualForm.component";
import ManualInfoCard from "./manualInfoCard.component";
import { generatePath, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useNumericParams from "hooks/useNumericParams";

const useStyles = makeStyles(theme => ({
  fixedButtonContainer: {
    position: "absolute",
    right: 0,
    display: "flex",
    justifyContent: "end"
  }
}));

export default function ManualIndexPage(props) {
  const classes = useStyles();
  const { dispatch, state } = props;

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const params = useNumericParams();
  const history = useHistory();

  const readOnly = useMemo(isReadOnly, []);

  useEffect(() => (
    (async function fetchRequiredData() {
      const indexResponse = await CertificationService.getManualsIndex();
      dispatch({ type: ACTION_SET_MANUALS_INDEX, payload: indexResponse.payload });
    })()
  ), [dispatch]);

  const handleCreateClick = useCallback(() => {
    setIsCreateOpen(true);
  }, [setIsCreateOpen]);

  const handleCreateConfirm = useCallback(async data => {
    const newManual = {
      Title: data.title,
      Version: data.version
    }
    const createRes = await CertificationService.createManual(newManual)
    setIsCreateOpen(false);
    history.push(
      generatePath("/program/:programId/:programComponentId/manual/:manualId", {
        ...params,
        manualId: createRes.payload.Manual_ID
      })
    )
  }, [history, params]);

  if (!state.manualsIndex) {
    return (
      <Loader />
    );
  }
  return (
    <div>
      <div>
        <div className={classes.fixedButtonContainer}>
          <ButtonDefault
            variant="medium"
            background="tertiary"
            startIcon={
              <DashboardOutlined />
            }
            onClick={handleCreateClick}
            disabled={readOnly}
            fixedRight
            data-cy="btn-create-manual"
          >
            Create Manual
          </ButtonDefault>
        </div>
      </div>
      <CustomModal open={isCreateOpen} onClose={() => setIsCreateOpen(false)}>
        <CreateManualForm
          setEditModal={setIsCreateOpen}
          handleConfirm={handleCreateConfirm}
        />
      </CustomModal>
      <Box display="flex" justifyContent="start" flexWrap="wrap" marginTop={4}>
        {state.manualsIndex.map(manual => (
          <ManualInfoCard
            manual={manual}
            key={manual.Manual_ID}
            readOnly={readOnly}
          />
        ))}
      </Box>
    </div>
  );
}
