import { sortByFirstAndLastName, sortByStringKey } from "utils/sortingFuncs";

export const ACTION_SET_ANY = "any__set";
export const ACTION_RESET_UI_STATE = "ui__reset";
export const ACTION_ADD_PORTCO = "portCo__add";
export const ACTION_REPLACE_PORTCO = "portCo__replace";
export const ACTION_ADD_PORTCO_DEMO = "portCoDemo__add";
export const ACTION_REPLACE_PORTCO_DEMO = "portCoDemo__replace";
export const ACTION_DEMO_FORM_CHANGE = "portCoDemoForm__change";
export const ACTION_REPLACE_PORTCO_USER = "portCoUser__replace";
export const ACTION_ADD_PORTCO_USER = "portCoUser__add";
export const ACTION_CREATE_USER_DEMO = "portCoDemo__create";

const mergeReplace = (source, payload, primaryKey) => {
  let didReplace = false;
  const merged = [...source].map((current) => {
    if (current[primaryKey] === payload[primaryKey]) {
      didReplace = true;
      return payload;
    }
    return current;
  });
  if (!didReplace) {
    return [...merged, payload];
  }
  return merged;
};

export const demographyInitialState = {
  portCoDemos: [],
  userDemos: [],
  allUsers: null,
  portCoList: [],
  selectedPortCo: {},
  demoFormObj: null,
  demoFormErrors: {},
  createdUserToAddToList: null,
};

export default function portfolioDemosReducer(state, action) {
  switch (action.type) {
    case ACTION_SET_ANY:
      return {
        ...state,
        ...action.payload,
      };
    case ACTION_RESET_UI_STATE:
      return {
        ...demographyInitialState,
        allUsers: state.allUsers,
        portCoList: state.portCoList
      };
    case ACTION_ADD_PORTCO:
      return {
        ...state,
        portCoList: sortByStringKey(
          [...state.portCoList, action.payload],
          "Name"
        ),
        selectedPortCo: { ...action.payload },
        demoFormErrors: { ...state.demoFormErrors, Name: null },
      };
    case ACTION_REPLACE_PORTCO:
      const mergedPortCoList = mergeReplace(state.portCoList, action.payload, "PortCo_ID")
      return {
        ...state,
        portCoList: sortByStringKey(mergedPortCoList, "Name"),
        demoFormErrors: { ...state.demoFormErrors, Name: null },
      };
    case ACTION_REPLACE_PORTCO_DEMO:
      let portCoListState = state.portCoList
      if (action.payload.portCoDemos.Label === "Logo") { //Updated logo needs to be reflected in PortCoList for dashboard
        const portCoToUpdate = state.portCoList.find(portCo => portCo.PortCo_ID === action.payload.portCoDemos.PortCo_ID)
        portCoListState = mergeReplace(
          state.portCoList,
          {...portCoToUpdate, Logo: action.payload.portCoDemos.Title},
          "PortCo_ID"
        )
      }
      return {
        ...state,
        portCoDemos: mergeReplace(
          state.portCoDemos,
          action.payload.portCoDemos,
          "PortCoDemo_ID"
        ),
        demoFormObj: action.payload.demoFormObj,
        portCoList: portCoListState
      };
    case ACTION_DEMO_FORM_CHANGE:
      return {
        ...state,
        demoFormObj: { ...state.demoFormObj, ...action.payload.demoFormObj },
      };
    case ACTION_REPLACE_PORTCO_USER:
      return {
        ...state,
        userDemos: sortByFirstAndLastName(
          mergeReplace(
            state.userDemos,
            action.payload,
            "User_ID"
          )
        ),
        allUsers: mergeReplace(state.allUsers, action.payload, "User_ID")
      };
    case ACTION_ADD_PORTCO_USER:
      return {
        ...state,
        userDemos: sortByFirstAndLastName(
          state.userDemos.concat(action.payload)
        ),
        allUsers: mergeReplace(state.allUsers, action.payload, "User_ID")
      };

    default:
      throw new Error(`Unknown action '${action.type}'`);
  }
}
