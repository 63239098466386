import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import FormBanner from "components/utils/form-elements/formBanner.component";
import { Grid, makeStyles, MenuItem } from "@material-ui/core";
import { useCallback, useState } from "react";
import trainingService from "services/training.service";
import {
  ACTION_ADD_ASSIGNMENT,
  ACTION_REMOVE_ASSIGNMENT,
  ACTION_REPLACE_ASSIGNMENT
} from "reducers/trainingAssignments.reducer";
import CustomSelect from "components/utils/form-elements/select.component";
import DateInput from "components/utils/form-elements/dateInput.component";
import Loader from "components/utils/loader.components";
import moment from "moment";
import { trainingPrivilegeTypes } from "utils/trainingConstants";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    minWidth: 500,
  },
  formContent: {
    margin: "16px 32px 0px 32px",
  },
  formRow: {
    paddingBottom: 20,
  },
  dateInputWrapper: {
    paddingBottom: 15,
  }
}));

const validateFormErrors = (formState) => {
  const error = {};
  if (!formState.AssignedUser_ID) {
    error.AssignedUser_ID = "A user is required.";
  }

  if (!formState.TrainingModule_ID) {
    error.TrainingModule_ID = "A module is required.";
  }

  if (!formState.TrainingRole_ID) {
    error.TrainingRole_ID = "User privilege is required.";
  }

  if (!formState.CompleteBy_Date) {
    error.CompleteBy_Date = "'Complete By' Date is required."
  }
  return error;
};

const TrainingAssignmentForm = ({
  dispatch,
  closeForm,
  formMode,
  setEmailMessage,
  assignment,
  assignableUsers,
  assignableTrainingModules
}) => {
  const classes = useStyles();
  const createMode = !assignment;
  const [formData, setFormData] = useState({
    TrainingAssignment_ID: assignment?.TrainingAssignment_ID,
    AssignedUser_ID: assignment?.AssignedUser_ID,
    TrainingRole_ID: assignment?.TrainingRole_ID,
    CompleteBy_Date: assignment?.CompleteBy_Date,
    TrainingModule_ID: assignment?.TrainingModule_ID,
  });
  const [formErrors, setFormErrors] = useState({});
  const userPrivilegeRoles = trainingPrivilegeTypes;

  const removeAssignment = async () => {
    await trainingService.deleteAssignment(
      assignment.TrainingAssignment_ID
    );

    dispatch({
      type: ACTION_REMOVE_ASSIGNMENT,
      payload: assignment.TrainingAssignment_ID,
    });
    closeForm();
  };

  const handleSaveAssignment = useCallback(
    () =>
      (async function() {
        setEmailMessage();
        const formValidations = validateFormErrors(formData);
        setFormErrors(formValidations);
        if (
          Object.values(formValidations).filter((error) => error !== false)
            .length > 0
        ) {
          return;
        }
        if (formMode === "edit") {
          const assignmentData = {
            TrainingAssignment_ID: formData.TrainingAssignment_ID,
            AssignedUser_ID: formData.AssignedUser_ID,
            TrainingModule_ID: formData.TrainingModule_ID,
            TrainingRole_ID: formData.TrainingRole_ID,
            Open_Date: moment().format("YYYY-MM-DD HH:mm:ss"),
            CompleteBy_Date: formData.CompleteBy_Date,
          };

          const assignmentId = formData.TrainingAssignment_ID;

          const upsertAssignmentRes = await trainingService.updateTrainingAssignment(
            assignmentId,
            assignmentData,
          );

          if (upsertAssignmentRes.emailSent) {
            const assignedUser = `${upsertAssignmentRes.payload?.User_Name}`;
            setEmailMessage(
              `A notification email has been sent to ${assignedUser}`
            );
          }

          dispatch({
            type: ACTION_REPLACE_ASSIGNMENT,
            payload: upsertAssignmentRes.payload,
          });
        } else {
          const assignmentData = {
            AssignedUser_ID: formData.AssignedUser_ID,
            TrainingModule_ID: formData.TrainingModule_ID,
            TrainingRole_ID: formData.TrainingRole_ID,
            Open_Date: moment().format("YYYY-MM-DD HH:mm:ss"),
            CompleteBy_Date: formData.CompleteBy_Date,
          };

          const createAssignmentRes = await trainingService.createAssignment(
            assignmentData,
          );

          if (createAssignmentRes.emailSent) {
            const assignedUser = `${createAssignmentRes.payload?.User_Name}`;
            setEmailMessage(
              `A notification email has been sent to ${assignedUser}`
            );
          }

          dispatch({
            type: ACTION_ADD_ASSIGNMENT,
            payload: createAssignmentRes.payload,
          });
        }

        closeForm();
      })(),
    [dispatch, formData, setEmailMessage, closeForm, formMode]
  );

  const handleUpdateAssignee = useCallback((event) => {
    event.preventDefault();
    const userId = event.target.value;

    if (event.target.value) {
      setFormData({
        ...formData,
        AssignedUser_ID: userId,
      });
    }
  }, [formData]);

  const handleUpdateModule = useCallback((event) => {
    event.preventDefault();
    const moduleId = event.target.value;

    if (event.target.value) {
      setFormData({
        ...formData,
        TrainingModule_ID: moduleId,
      });
    }
  }, [formData]);

  const handleUpdatePrivilege = useCallback((event) => {
    event.preventDefault();
    const privilegeId = event.target.value;
    const privilege = userPrivilegeRoles.find((privilegeRole) => (privilegeRole.TrainingRole_ID === privilegeId));

    if (event.target.value) {
      setFormData({
        ...formData,
        TrainingRole_ID: privilege.TrainingRole_ID,
      })
    }
  }, [userPrivilegeRoles, formData]);

  const handleCompleteByDate = useCallback((event) => {
    event.preventDefault();
    if (event.target.value) {
      setFormData({
        ...formData,
        CompleteBy_Date: event.target.value,
      })
    }
  }, [formData]);

  if (!assignableUsers || !formData ) {
    return <Loader />;
  } else {
    return (
      <div className={classes.formContainer}>
        <FormBanner>{createMode ? "Create Assignment" : "Edit Assignment"}</FormBanner>
        <div className={classes.formContent}>
          <Grid className={classes.formRow} container spacing={2}>
            <CustomSelect
              label="Assigned User"
              value={formData?.AssignedUser_ID}
              onChange={handleUpdateAssignee}
              error={!!formErrors?.AssignedUser_ID}
              errorMessage={formErrors?.AssignedUser_ID}
            >
              {assignableUsers.map((userOption) => {
                return (
                  <MenuItem
                    key={userOption.User_ID}
                    value={userOption.User_ID}
                    id={userOption.User_ID}
                    data-cy={`assign-${userOption.User_Name}`}
                  >
                    {`${userOption.First_Name} ${userOption.Last_Name}`}
                  </MenuItem>
                )
              })}
            </CustomSelect>
          </Grid>
          <Grid className={classes.formRow} container spacing={2}>
            <CustomSelect
              label="Assigned Module"
              value={formData?.TrainingModule_ID}
              onChange={handleUpdateModule}
              error={!!formErrors?.TrainingModule_ID}
              errorMessage={formErrors?.TrainingModule_ID}
            >
              {assignableTrainingModules.map((moduleOption) => {
                return (
                  <MenuItem
                    key={moduleOption.TrainingModule_ID}
                    value={moduleOption.TrainingModule_ID}
                    id={`training-module-${moduleOption.TrainingModule_ID}`}
                    data-cy={`assign-module-${moduleOption.TrainingModule_ID}`}
                  >
                    {`${moduleOption.Module_Name}`}
                  </MenuItem>
                )
              } )}
            </CustomSelect>
          </Grid>
          <Grid className={classes.formRow} container spacing={2}>
            <CustomSelect
              label="User Privilege"
              value={formData?.TrainingRole_ID}
              onChange={handleUpdatePrivilege}
              error={!!formErrors?.TrainingRole_ID}
              errorMessage={formErrors?.TrainingRole_ID}
            >
              {userPrivilegeRoles.map((roleOption) => {
                return (
                  <MenuItem
                    key={roleOption.TrainingRole_ID}
                    value={roleOption.TrainingRole_ID}
                    id={`role-${roleOption.TrainingRole_ID}`}
                    data-cy={`role-${roleOption.TrainingRole_ID}`}
                  >
                    {roleOption.Role}
                  </MenuItem>
                );
              })}
            </CustomSelect>
          </Grid>
          <Grid className={classes.formRow} container spacing={2}>
            <div className={classes.dateInputWrapper}>
              <DateInput
                id="Complete By Date"
                label="Complete By Date"
                error={!!formErrors?.CompleteBy_Date}
                errorMessage={formErrors?.CompleteBy_Date}
                onChange={handleCompleteByDate}
                placeholder="yyyy-mm-dd" //For Safari
                value={formData?.CompleteBy_Date}
                test="training-assignments-complete-by-date"
                margin="dense"
              />
            </div>
          </Grid>
        </div>
        <DualFormButtons
          cancelOnClick={closeForm}
          deleteText="Remove"
          saveOnClick={handleSaveAssignment}
          deleteClick={formMode === "edit" ? removeAssignment : null}
        />
      </div>
    );
  }

};
export default TrainingAssignmentForm;
