import { makeStyles, MenuItem } from "@material-ui/core";
import classNames from "classnames";
import variables from "styleVariables";
import EditableText from "components/utils/form-elements/editableText.component";
import CustomSelect from "components/utils/form-elements/select.component";

const useStyles = makeStyles(theme => ({
  textColor: {
    color: variables.textSecondary,
    display: "flex",
    flex: 1,
    flexFlow: "column",
  },
  noAssessors: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  assessorRow: {
    paddingBottom: 3,
    paddingTop: 5,
    borderBottom: "1px solid #00a3b6",
  },
  selectWrapper: {
    paddingTop: 25,
    paddingBottom: 10,
    marginTop: "auto",
  },
}));

export default function AssignAssessmentsTable(props) {
  const {
    addText,
    assessors,
    assignee,
    assignableUsers,
    handleAddAssessor,
    handleRemoveAssessor,
  } = props;
  const classes = useStyles();
  return (
    <div
      className={classNames(
        classes.assessorDisplay,
        classes.textColor
      )}
    >
      {assessors.length === 0 && (
        <div className={classNames(classes.noAssessors)}>
          None
        </div>
      )}

      {assessors.length > 0 &&
        assessors.map((assessor, index) => {
          return (
            <span key={assessor.AssessmentAssignment_ID}>
              <div className={classNames(classes.assessorRow)}>
                <EditableText
                  cellItem={`${assessor.First_Name} ${assessor.Last_Name}`}
                  removeClick={() => handleRemoveAssessor(assessor)}
                />
              </div>
            </span>
          );
        })}
      <div className={classes.selectWrapper}>
        <CustomSelect
          label={addText}
          value={assignee}
          onChange={handleAddAssessor}
          name={`add-assessor--${addText.replace("-", "").toLowerCase()}`}
          formLabelPrimaryMain
          test={`${addText}`}
        >
          {assignableUsers.length > 0 &&
            assignableUsers.map((user) => {
              return (
                <MenuItem
                  key={user.User_ID}
                  value={user.User_ID}
                  id={user.User_ID}
                  data-cy={`assign-${user.First_Name} ${user.Last_Name}`}
                >
                  {`${user.First_Name} ${user.Last_Name}`}
                </MenuItem>
              );
            })}
        </CustomSelect>
      </div>
    </div>
  );
}
