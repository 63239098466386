import React, { useEffect, useMemo, useState } from "react";
import PortCoDemoForm from "components/portfolio/demographics/portCoDemoForm.component";
import { makeStyles } from "@material-ui/core/styles";
import PortFolioUserDemos from "components/portfolio/demographics/portFolioUserDemos.component";
import Header from "components/utils/header.component";
import { Divider } from "@material-ui/core";
import PortfolioService from "services/portfolio.service";
import {
  ACTION_SET_ANY,
} from "reducers/portfolioDemos.reducer";
import { makeDemoFormObject } from "utils/portfolioDemoHelpers";
import { isPortCoEdit, isPortCoRead, isHoldingRead } from "utils/roles";
import useNumericParams from "hooks/useNumericParams";
import { sortByFirstAndLastName } from "utils/sortingFuncs";
import PortCoPicker from "components/portfolio/shared/portCoPicker.component";
import variables from "styleVariables";
import PortCoIcon from "@material-ui/icons/Business";
import UsersIcon from "@material-ui/icons/PeopleAlt";
import { AddPortCoButton } from "./demographicsDashboard.component";
import { setStateFetchEffect } from "utils/ajaxHelpers";
import authService from "services/auth.service";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  portCoDemoWrapper: {
    paddingTop: 10,
    flex: 1,
    paddingRight: 30,
  },
  divider: {
    marginTop: 15,
    marginBottom: 15
  },
  headerRow: {
    display: "flex",
    alignItems: "center",
  },
  iconWrapper: {
    width: 30,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 6,
    padding: "2px 0px",
  },
  headerIcon: {
    fill: variables.primaryMain,
    height: "100%",
    width: "auto",
  },
  userDemoWrapper: {
    paddingTop: 10,
    flex: 1,
  },
  userDemoInnerWrapper: {
    maxWidth: 480,
  },
  controlsRow: {
    display: "flex",
    alignItems: "center",
    paddingTop: 20,
    width: 400,
    justifyContent: "space-between",
  },
  selectWrapperWidth: {
    minWidth: 240,
  },
}));

const DemographicsPage = (props) => {
  const classes = useStyles();
  const [userFormStatus, setUserFormStatus] = useState(null);
  const { dispatch, switchPortCoDemo } = props;
  const {
    portCoDemos,
    portCoList,
    demoFormObj,
    selectedPortCo,
    demoFormErrors,
    userDemos,
    allUsers,
    createdUserToAddToList,
  } = props.reducerState;
  const params = useNumericParams();
  const isHoldingReadUser = useMemo(isHoldingRead, []);
  const { holdingId, portCoId } = params;

  const assignableUsers = useMemo(() => {
    if (!allUsers) {
      return [];
    }
    const existingDemoUserIds = new Set(
      userDemos?.map?.(user => user.User_ID) || []
    );
    const remainingUsers = allUsers.filter(user => (
      !existingDemoUserIds.has(user.User_ID)
    ));
    return remainingUsers.filter(user => {
      switch (user.Role_ID) {
        case authService.Type.PORTCOEDIT:
        case authService.Type.PORTCOREAD:
          return true;
        case authService.Type.HOLDINGREAD:
          return user._associations?.HoldingGroupPermissions.every(
            permission => permission.PortfolioHolder_ID !== holdingId
          );
        default:
          return false;
      }
    });
  }, [allUsers, holdingId, userDemos]);

  useEffect(() => {
    const selectedPortfolioCo =
      portCoList.find((portCo) => {
        return portCo.PortCo_ID === portCoId;
      }) || {};
    dispatch({
      type: ACTION_SET_ANY,
      payload: {
        selectedPortCo: selectedPortfolioCo,
        demoFormErrors: {},
        demoFormObj: {}
      },
    });
  }, [dispatch, portCoId, portCoList]);

  const handleChange = (event) => {
    switchPortCoDemo(event.target.value);
  };

  useEffect(function fetchGlobalData() {
    return setStateFetchEffect(
      PortfolioService.getPortfolioUsers(holdingId),
      ([users]) => {
        dispatch({
          type: ACTION_SET_ANY,
          payload: { allUsers: users.payload }
        })
      }
    );
  }, [dispatch, holdingId]);

  useEffect(function fetchPortCoData() {
    if (selectedPortCo?.PortCo_ID && selectedPortCo?.PortCo_ID !== "new") {
      return setStateFetchEffect(
        [
          PortfolioService.getDemoDataByPortCoID(selectedPortCo.PortCo_ID),
          PortfolioService.getUsersByPortCoID(selectedPortCo.PortCo_ID)
        ],
        ([portCoDemoRes, userDemoRes]) => {
          setUserFormStatus(null);
          dispatch({
            type: ACTION_SET_ANY,
            payload: {
              portCoDemos: portCoDemoRes.payload,
              userDemos: sortByFirstAndLastName(userDemoRes.payload),
              demoFormObj: makeDemoFormObject(
                portCoDemoRes.payload,
                selectedPortCo.Name
              ),
            },
          });
        }
      );
    }
  }, [selectedPortCo, dispatch]);

  const showNewFormButton = () => {
    if (isHoldingReadUser) {
      return false;
    }
    return (
      (!selectedPortCo?.PortCo_ID && !demoFormObj) || selectedPortCo?.PortCo_ID
    );
  };

  if (isPortCoRead() || isPortCoEdit()) {
    return "";
  }

  return (
    <div className={classes.pageContainer}>
      <div className={classes.controlsRow}>
        <div className={classes.selectWrapperWidth}>
          <PortCoPicker
            selectedPortCoId={selectedPortCo?.PortCo_ID}
            portCoList={portCoList}
            handleChange={handleChange}
            ignoreReadOnly
            label={
              !isHoldingReadUser
                ? "Select a Portfolio Company to View and Edit"
                : "Select a Portfolio Company to View"
            }
          />
        </div>

        {showNewFormButton() && (
          <AddPortCoButton
            dispatch={dispatch}
            state={props.reducerState}
            resetUserFormStatus={() => {
              setUserFormStatus(null);
            }}
            switchPortCoDemo={switchPortCoDemo}
          />
        )}
      </div>
      <Divider className={classes.divider} />
      <div className={classes.contentContainer}>
        <div className={classes.portCoDemoWrapper}>
          <div className={classes.headerRow}>
            <div className={classes.iconWrapper}>
              <PortCoIcon className={classes.headerIcon} />
            </div>
            <Header variant="h3Primary">Portfolio Company</Header>
          </div>

          {(!isHoldingReadUser || !!selectedPortCo?.PortCo_ID) && !!demoFormObj && (
            <PortCoDemoForm
              portCoList={portCoList}
              selectedPortCo={selectedPortCo}
              portCoDemos={portCoDemos}
              demoFormObj={demoFormObj}
              holdingID={holdingId}
              dispatch={dispatch}
              demoFormErrors={demoFormErrors}
              switchPortCoDemo={switchPortCoDemo}
            />
          )}
        </div>
        <div className={classes.userDemoWrapper}>
          <div className={classes.headerRow}>
            <div className={classes.iconWrapper}>
              <UsersIcon className={classes.headerIcon} />
            </div>
            <Header variant="h3Primary">Users</Header>
          </div>
          <div className={classes.userDemoInnerWrapper} data-cy="demo-user-list">
            <PortFolioUserDemos
              selectedPortCo={selectedPortCo}
              holdingID={holdingId}
              dispatch={dispatch}
              userDemos={userDemos}
              assignableUsers={assignableUsers}
              createdUserToAddToList={createdUserToAddToList}
              formStatus={userFormStatus}
              setFormStatus={setUserFormStatus}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemographicsPage;
