import React, { useMemo, useState, useRef, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  reviewResponseTypes
} from "utils/assessmentConstants";
import HeaderPrimary from "components/utils/header.component";
import { Grid } from "@material-ui/core";
import variables from "styleVariables";
import ResponseSection from "components/assessment/review/responseSection.component";
import moment from "moment";
import { currentUserID } from "utils/userHelpers";
import LabelAndValue from "components/utils/labelAndValue.component";
import { getAssessmentItemName } from "utils/assessmentHelpers";
import classNames from "classnames";
import DownloadableDocLink from "components/utils/downloadableDocLink.component";
import RiskAssessmentService from "services/riskAssessment.service";
import { defaultChangeDate, formattedMomentCustomError } from "utils/dateHelpers";
import CustomLink from "components/utils/link.component";
import { GiPencilRuler, GiSpeedometer } from "react-icons/gi";
import {
  ContentRowWrapper,
  ReviewInfoSideColumnWrapper,
  ResponseOrBlankSideColumnWrapper
} from "components/assessment/shared/assessmentWrappers.component";
import Loader from "components/utils/loader.components";
import BottomControlsBar from "../shared/bottomControlsBar.component";
import { useParams, useHistory } from 'react-router-dom';
import Form from "components/utils/form-elements/form.component";

const useStyles = makeStyles((theme) => ({
  responsePageContainer: {
    display: "flex",
    flexDirection: "column",
  },
  summaryContainer: {
    display: "flex",
    flexDirection: "column",
  },
  summaryHeadingBlock: {
    width: "100%",
    backgroundColor: variables.tertiary1,
  },
  summaryHeadingWrapper: {
    maxWidth: 1700,
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 50,
    paddingRight: 50,
    margin: "0px auto",
  },
  assessmentItemsHeader: {
    paddingLeft: 50,
    maxWidth: 600,
  },
  summaryContentContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 50px",
    maxWidth: 1700,
    flexDirection: "row",
    margin: "0px auto",
    width: "100%",
  },
  summaryGroup1: {
    display: "flex",
    flexDirection: "row",
    paddingRight: 30,
  },
  summaryGroup2: {
    display: "flex",
    justifyContent: "flex-end",
    textAlign: "right",
  },
  summaryCell: {
    marginRight: 30,
    display: "flex",
    flexFlow: "column",
  },
  collectionCountWrapper: {
    display: "flex",
    flexFlow: "column",
    paddingLeft: 30,
  },
  contentSectionContainer: {
    marginTop: 10,
    marginBottom: 160,
  },
  innerContentWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 60px 40px 60px",
    maxWidth: 1675,
    margin: "0px auto",
  },
    // HEADER SECTION BAR
  headerSectionBar: {
    padding: "5px 0px",
    backgroundColor: variables.tertiary1,
  },
  sectionHeaderWrapper: {
    maxWidth: 1700,
    margin: "0px auto",
    paddingLeft: 50,
    paddingRight: 50,
  },
  sectionHeader: {
    fontSize: variables.fontXl,
    color: "white",
    fontWeight: "bold",
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: 8,
    fontSize: "1.3rem",
    fill: "white",
  },
  subHeadingIcon: {
    marginRight: 5,
    marginLeft: 10,
    fontSize: "1.5rem",
    fill: variables.primaryMain,
  },
    // LABELS WITH COUNT VALUES
  labelCountTextSize: {
    fontSize: variables.fontMedium,
    paddingBottom: 3,
    display: "inline-block",
  },
  nonLinkLabelCountText: {
    fontWeight: "bold",
    color: variables.grayDisabledText,
  },
    // DIVIDERS
  customFindingHeader: {
    width: "100%",
    paddingBottom: 15,
    display: "flex",
    alignItems: "center",
  },
}));

const findingAndRecommendationsCount = (reviewsArr = []) => {
  let count = 0;
  for (const rev of reviewsArr) {
    if (rev.Recommendation) {
      count = count + 1;
    }
    if (rev.Finding) {
      count = count + 1;
    }
  }
  return count;
};

const emptyEditorHTML = "<p></p>";

const sectionRefKeys = {
  SUMMARY: "Summary",
  DESIGN: "Design",
  PERFORMANCE: "Performance"
}

const updateReviews = (revs, modifiedRev, fieldToUpdate) => {
  const revToUpdate = revs.find(rev => rev.Finding_ID === modifiedRev.FindingRecommendation_ID || rev.Recommendation_ID === modifiedRev.FindingRecommendation_ID)
  const indexOfRevToUpdate = revs.indexOf(revToUpdate);
  const updatedRev = { ...revToUpdate, [fieldToUpdate]: modifiedRev.Response }
  const revsCopy = [...revs]
  revsCopy.splice(indexOfRevToUpdate, 1, updatedRev);
  return revsCopy
}

const updateSummaryRecs = (summRecs, modifiedSummary) => {
  const summRecToUpdate = summRecs.find(rec => rec.Recommendation_ID === modifiedSummary.Recommendation_ID)
  const indexOfSummRecToUpdate = summRecs.indexOf(summRecToUpdate)
  const updatedRecObj = { ...summRecToUpdate, Response: modifiedSummary.Response }
  summRecs.splice(indexOfSummRecToUpdate, 1, updatedRecObj);
  return summRecs;
}

const ReviewLabelAndValue = (props) => {
  return (
    <LabelAndValue
      verticalPositioning
      alignLeft
      labelHeaderVariant="h5Tertiary"
      paddingBottom={10}
      {...props}
    />
  )
};

const ReviewReferences = ({ review, idName, references }) => {
  const referenceRevIdName = idName === "Design_ID" ? "DesignDC_ID" : "PerformanceDC_ID"
  return references
    .filter((reference) => reference[referenceRevIdName] === review[idName])
    .map((reference) => {
      return (
        <DownloadableDocLink
          referenceName={reference.Reference_Name}
          fileRef={reference.File_Ref}
          referenceId={reference.Reference_ID}
          displayText={reference.Reference_Name}
          key={reference.Reference_ID}
        />
      );
    });
};

const SummaryResponsePage = ({
  builderInfo,
  assessmentItems,
  designItems,
  performanceItems,
  summaryRecommendations,
  references,
  setSummaryRecommendations,
  setDesignItems,
  setPerformanceItems,
  setReviewPageMode,
}) => {
  const classes = useStyles();
  const sectionHeaderRef = useRef({});
  const history = useHistory();

  const {programId, programComponentId, componentObjectId} = useParams();

  const designRevsWithFindingAndOrRec = useMemo(() => {
    return designItems?.filter?.((item) => (
      item.Recommendation || item.Finding
    ));
  }, [designItems]);
  const perfRevsWithFindingAndOrRec = useMemo(() => {
    return performanceItems?.filter?.((item) => (
      item.Recommendation || item.Finding
    ));
  }, [performanceItems]);
  const designFindingsAndRecsCount = useMemo(() => {
    return findingAndRecommendationsCount(designRevsWithFindingAndOrRec);
  }, [designRevsWithFindingAndOrRec]);
  const perfFindingsAndRecsCount = useMemo(() => {
    return findingAndRecommendationsCount(perfRevsWithFindingAndOrRec);
  }, [perfRevsWithFindingAndOrRec]);

  const [activeEditor, setActiveEditor] = useState(null);
  const [editorHTMLContents, setEditorHTMLContents] = useState(emptyEditorHTML);
  const [activeIdentifier, setActiveIdentifier] = useState(null);

  const scrollClick = (refKey) => {
    sectionHeaderRef.current[refKey].scrollIntoView({
      behavior: "smooth"
    });
  }

  const renderLabelAndCount = useCallback(({ itemCount, label, refKey }) => {
    return (
      <span>
        {refKey && itemCount > 0 ?
          <CustomLink
            onClick={() => scrollClick(refKey)}
            variant="underline"
            className={classes.labelCountTextSize}
          >
            {label}: {itemCount}
          </CustomLink>
          :
          <span className={classNames(classes.labelCountTextSize, classes.nonLinkLabelCountText)}>
            {`${label}: ${itemCount}`}
          </span>}
      </span>
    );
  }, [classes.labelCountTextSize, classes.nonLinkLabelCountText]);

  const activateEditor = (identifier, editorHTML) => {
    setEditorHTMLContents(editorHTML);
    setActiveEditor(identifier);
  };

  const handleEditorOnChange = (contents) => {
    setEditorHTMLContents(contents);
  };


  const handleSaveResponse = useCallback(async (reviewType, sourceID, responseType, initialEditorValue) => {
    // Make sure value has changed
    if (editorHTMLContents !== initialEditorValue) {
      // EDIT Summary Rec
      if (!responseType) {
        const updatedSummaryResponse = {
          Recommendation_ID: sourceID,
          Response: editorHTMLContents,
          Change_Date: defaultChangeDate(),
          Change_User: currentUserID(),
        };
        const updateSummaryResponse = await RiskAssessmentService.updateAssessmentSummaryRecommendationResponse(updatedSummaryResponse)

        const updatedSummary = updateSummaryResponse.payload;
        const updatedSummRecs = updateSummaryRecs([...summaryRecommendations], updatedSummary)
        setSummaryRecommendations(updatedSummRecs)
      // EDIT rev findings or recs
      } else {
        const updatedFields = {
          FindingRecommendation_ID: sourceID,
          Response: editorHTMLContents,
          Change_Date: defaultChangeDate(),
          Change_User: currentUserID(),
        };
        const updateResponse = await RiskAssessmentService.updateAssessmentItemResponse(updatedFields);

        const updatedObject = updateResponse.payload;
        let fieldToUpdate;

        if (responseType === reviewResponseTypes.RESP_TYPE_FINDING) {
          fieldToUpdate = "Finding_Response";
        } else {
          fieldToUpdate = "Recommendation_Response";
        }

        if (reviewType === "Design") {
          const updatedDesRevs = updateReviews([...designItems], updatedObject, fieldToUpdate);
          setDesignItems(updatedDesRevs);
        } else if (reviewType === "Performance") {
          const updatedPerfRevs = updateReviews([...performanceItems], updatedObject, fieldToUpdate);
          setPerformanceItems(updatedPerfRevs);
        }

      }

    }
  }, [editorHTMLContents, designItems, performanceItems, setDesignItems, setPerformanceItems, summaryRecommendations, setSummaryRecommendations]);


  const headerSectionBar = useCallback(({ text, refKey, icon }) => {
    return (
      <div
        className={classNames(classes.headerSectionBar)}
        ref={(el) => (sectionHeaderRef.current[refKey] = el)}
        data-cy={`response-header-${text}`}
      >
        <div className={classNames(classes.sectionHeaderWrapper)}>
          <div className={classNames(classes.sectionHeader)}>
            {icon}
            {text}
          </div>
        </div>
      </div>
    );
  }, [classes.headerSectionBar, classes.sectionHeaderWrapper, classes.sectionHeader]);

  const backButtonClick = () => {
    history.push(`/program/${programId}/${programComponentId}/${componentObjectId}/assessment/${builderInfo.Builder_ID}/review`)
  }

  if (!designItems || !performanceItems || !references) {
    return (
      <Loader />
    );
  }

  return (
    <div className={classes.responsePageContainer}>
      <div className={classNames(classes.summaryContainer)}>
        <div className={classNames(classes.summaryHeadingBlock)}>
          <div className={classNames(classes.summaryHeadingWrapper)}>
            <div className={classes.assessmentTitle}>
              <HeaderPrimary variant="h1White">
                {builderInfo.Title}
              </HeaderPrimary>
            </div>
            <div className={classes.assessmentItemsHeader} >
              <HeaderPrimary variant="h4White" test="summary-total-items">
                  Total assessment items: {assessmentItems.length}
              </HeaderPrimary>
            </div>
          </div>
        </div>

        <div className={classes.summaryContentContainer}>
          <div className={classes.summaryGroup1} data-cy="summary-group-1">
            <div className={classNames(classes.summaryCell)}>
              <LabelAndValue
                label="Start"
                value={formattedMomentCustomError(moment(builderInfo.Start_Date).format("M/D/YYYY"))}
              />
              <LabelAndValue
                label="End"
                value={formattedMomentCustomError(moment(builderInfo.End_Date).format("M/D/YYYY"))}
              />
            </div>
            <div className={classNames(classes.summaryCell, classes.descriptionCell)}>
              <LabelAndValue
                label="Description"
                value={builderInfo.Description || "None"}
                verticalPositioning
              />
            </div>
          </div>
          <div className={classes.summaryGroup2} data-cy="summary-group-2">
            <div className={classNames(classes.collectionCountWrapper)}>
              {renderLabelAndCount({
                label: "Summary Recommendations",
                itemCount: summaryRecommendations.length,
                refKey: sectionRefKeys.SUMMARY
              })}
              {renderLabelAndCount({
                label: "Design Findings & Recommendations",
                itemCount: designFindingsAndRecsCount,
                refKey: sectionRefKeys.DESIGN
              })}
              {renderLabelAndCount({
                label: "Performance Findings & Recommendations",
                itemCount: perfFindingsAndRecsCount,
                refKey: sectionRefKeys.PERFORMANCE
              })}
            </div>
          </div>
        </div>
      </div>
      <Form
        className={classes.contentSectionContainer}
        name="summary-responses"
      >
        {/* ====== SUMMARY SECTION ====== */}
        {summaryRecommendations.length > 0 && (
          <div className={classes.contentSection}>
            {headerSectionBar({
              text: "Summary Recommendations",
              refKey: sectionRefKeys.SUMMARY
            })}
            <div className={classes.innerContentWrapper}>

              {summaryRecommendations.map((rec, index) => {
                return (
                  <ResponseSection
                    key={rec.Recommendation_ID}
                    staticObj={rec}
                    index={index}
                    displayType="SUMMARY_RECOMMENDATION"
                    hideBottomDivider={index === summaryRecommendations.length - 1}
                    emptyEditorHTML={emptyEditorHTML}
                    editorHTMLContents={editorHTMLContents}
                    handleSaveResponse={handleSaveResponse}
                    setActiveEditor={setActiveEditor}
                    activeEditor={activeEditor}
                    activateEditor={activateEditor}
                    handleEditorOnChange={handleEditorOnChange} //FOR SUN EDITOR
                    builderInfo={builderInfo}
                    activeIdentifier={activeIdentifier}
                    setActiveIdentifier={setActiveIdentifier}
                  />
                );
              })}

            </div>
          </div>
        )}
        {/* ====== DESIGN SECTION ====== */}
        {designFindingsAndRecsCount > 0 && (
          <div className={classes.contentSection}>
            {headerSectionBar({
              text: "Design Findings and Recommendations",
              icon: (
                <GiPencilRuler className={classNames(classes.headingIcon)} />
              ),
              refKey: sectionRefKeys.DESIGN
            })}
            {designRevsWithFindingAndOrRec.map((review, index) => {
              const assessmentItem = assessmentItems.find((item) => (
                item.AssessmentItem_ID === review.AssessmentItem_ID
              ));
              return (
                <div className={classes.innerContentWrapper} key={review.Design_ID}>
                  <ContentRowWrapper test={`${getAssessmentItemName(assessmentItem)} - Design`}>
                    <HeaderPrimary variant="h3Primary" className={classes.customFindingHeader}>
                      {`${getAssessmentItemName(assessmentItem)}`}
                      <GiPencilRuler className={classNames(classes.subHeadingIcon)} />
                    </HeaderPrimary>
                    <ReviewInfoSideColumnWrapper>
                      <Grid direction="column" container>
                        <Grid item>
                          <ReviewLabelAndValue
                            label="Current State"
                            value={review.Current_State}
                            verticalPositioning={false}
                          />
                        </Grid>
                        <Grid item>
                          <ReviewLabelAndValue
                            label="Description"
                            value={review.Description}
                          />
                        </Grid>
                      </Grid>
                    </ReviewInfoSideColumnWrapper>
                    <ResponseOrBlankSideColumnWrapper>
                      <Grid container direction="column">
                        <Grid item >
                          <ReviewLabelAndValue
                            label="Implementation Type"
                            value={review.Implementation_Type}
                            verticalPositioning={false}
                          />
                        </Grid>
                        <Grid item>
                          <ReviewLabelAndValue
                            label="Validation"
                            value={review.Validation}
                          />
                        </Grid>
                      </Grid>
                    </ResponseOrBlankSideColumnWrapper>
                  </ContentRowWrapper>

                  <ResponseSection
                    staticObj={review}
                    index={index}
                    test={`${getAssessmentItemName(assessmentItem)}-Design-Finding`}
                    displayType="DESIGN_FINDING"
                    emptyEditorHTML={emptyEditorHTML}
                    editorHTMLContents={editorHTMLContents}
                    handleSaveResponse={handleSaveResponse}
                    setActiveEditor={setActiveEditor}
                    activeEditor={activeEditor}
                    activateEditor={activateEditor}
                    handleEditorOnChange={handleEditorOnChange}
                    builderInfo={builderInfo}
                    hideResponse={!review.Finding}
                    activeIdentifier={activeIdentifier}
                    setActiveIdentifier={setActiveIdentifier}
                  />
                  <ResponseSection
                    staticObj={review}
                    index={index}
                    test={`${getAssessmentItemName(assessmentItem)}-Design-Rec`}
                    displayType="DESIGN_REC"
                    emptyEditorHTML={emptyEditorHTML}
                    editorHTMLContents={editorHTMLContents}
                    handleSaveResponse={handleSaveResponse}
                    setActiveEditor={setActiveEditor}
                    activeEditor={activeEditor}
                    activateEditor={activateEditor}
                    handleEditorOnChange={handleEditorOnChange}
                    builderInfo={builderInfo}
                    hideResponse={!review.Recommendation}
                    activeIdentifier={activeIdentifier}
                    setActiveIdentifier={setActiveIdentifier}
                  />

                  <ContentRowWrapper>
                    <ReviewInfoSideColumnWrapper>
                      <ReviewLabelAndValue
                        label="References"
                        value={(
                          <ReviewReferences
                            review={review}
                            idName="Design_ID"
                            references={references}
                          />
                        )}
                      />
                    </ReviewInfoSideColumnWrapper>
                    <ResponseOrBlankSideColumnWrapper />
                  </ContentRowWrapper>
                </div>

              );
            })}

          </div>
        )}

        {/* ====== PERFORMANCE SECTION ====== */}
        {perfFindingsAndRecsCount > 0 && (
          <div className={classes.contentSection}>
            {headerSectionBar({
              text: "Performance Findings and Recommendations",
              icon: (
                <GiSpeedometer className={classNames(classes.headingIcon)} />
              ),
              refKey: sectionRefKeys.PERFORMANCE
            })}
            {perfRevsWithFindingAndOrRec.map((review, index) => {
              const assessmentItem = assessmentItems.find((item) => (
                item.AssessmentItem_ID === review.AssessmentItem_ID
              ));
              return (
                <div className={classes.innerContentWrapper} key={review.Performance_ID}>
                  <ContentRowWrapper test={`${getAssessmentItemName(assessmentItem)} - Performance`}>
                    <HeaderPrimary variant="h3Primary" className={classes.customFindingHeader}>
                      {`${getAssessmentItemName(assessmentItem)}`}
                      <GiSpeedometer className={classNames(classes.subHeadingIcon)} />
                    </HeaderPrimary>
                    <ReviewInfoSideColumnWrapper>
                      <Grid direction="column" container>
                        <Grid item >
                          <ReviewLabelAndValue
                            label="Observation Date"
                            value={review.Observation_Date}
                            verticalPositioning={false}
                          />
                        </Grid>
                        <Grid item>
                          <ReviewLabelAndValue
                            label="Systems/Applications Involved"
                            value={review.Systems_Involved}
                          />
                        </Grid>
                        <Grid item>
                          <ReviewLabelAndValue
                            label="Performance Description"
                            value={review.Description}
                          />
                        </Grid>
                      </Grid>
                    </ReviewInfoSideColumnWrapper>
                    <ResponseOrBlankSideColumnWrapper>
                      <Grid container direction="column">
                        <Grid item >
                          <ReviewLabelAndValue
                            label="Location"
                            value={review.Location}
                            verticalPositioning={false}
                          />
                        </Grid>
                        <Grid item>
                          <ReviewLabelAndValue
                            label="Individuals/Groups Involved"
                            value={review.Groups_Involved}
                          />
                        </Grid>
                        <Grid item>
                          <ReviewLabelAndValue
                            label="Action Taken Based on Observation"
                            value={review.Actions_Taken}
                          />
                        </Grid>
                      </Grid>
                    </ResponseOrBlankSideColumnWrapper>
                  </ContentRowWrapper>

                  <ResponseSection
                    staticObj={review}
                    index={index}
                    test={`${getAssessmentItemName(assessmentItem)}-Performance-Finding`}
                    displayType="PERFORMANCE_FINDING"
                    emptyEditorHTML={emptyEditorHTML}
                    editorHTMLContents={editorHTMLContents}
                    handleSaveResponse={handleSaveResponse}
                    setActiveEditor={setActiveEditor}
                    activeEditor={activeEditor}
                    activateEditor={activateEditor}
                    handleEditorOnChange={handleEditorOnChange}
                    builderInfo={builderInfo}
                    hideResponse={!review.Finding}
                    activeIdentifier={activeIdentifier}
                    setActiveIdentifier={setActiveIdentifier}
                  />
                  <ResponseSection
                    staticObj={review}
                    index={index}
                    test={
                      `${getAssessmentItemName(assessmentItem)}-Performance-Rec`
                    }
                    displayType="PERFORMANCE_REC"
                    emptyEditorHTML={emptyEditorHTML}
                    editorHTMLContents={editorHTMLContents}
                    handleSaveResponse={handleSaveResponse}
                    setActiveEditor={setActiveEditor}
                    activeEditor={activeEditor}
                    activateEditor={activateEditor}
                    handleEditorOnChange={handleEditorOnChange}
                    builderInfo={builderInfo}
                    hideResponse={!review.Recommendation}
                    activeIdentifier={activeIdentifier}
                    setActiveIdentifier={setActiveIdentifier}
                  />

                  <ContentRowWrapper>
                    <ReviewInfoSideColumnWrapper>
                      <ReviewLabelAndValue
                        label="References"
                        value={(
                          <ReviewReferences
                            review={review}
                            idName="Performance_ID"
                            references={references}
                          />
                        )}
                      />
                    </ReviewInfoSideColumnWrapper>
                    <ResponseOrBlankSideColumnWrapper />

                  </ContentRowWrapper>
                </div>
              );
            })}
          </div>
        )}
      </Form>
      <BottomControlsBar
        backClick={backButtonClick}
        doneClick={backButtonClick}
      />
    </div>
  );
};

export default SummaryResponsePage;
