import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styleVariables from "styleVariables";
import Header from "components/utils/header.component";
import CustomSelect from "components/utils/form-elements/select.component";
import { MenuItem } from "@material-ui/core";
import CustomSunEditor from "components/utils/sunEditor.component";
import DisplayHTML from "components/utils/displayHTML.component";
import { ReportPageSection } from "./reportPageSection.component";
import ScoreDefinitionsTable from "./methodology/scoreDefinitionsTable.component";
import { ACTION_REPLACE_REPORT_SCORE } from "reducers/portfolioReports.reducer";
import PortfolioService from "services/portfolio.service";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  scoreHeaderRow: {
    padding: "8px 32px",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: styleVariables.tertiary1,
    color: "white",
    alignItems: "center",
    marginLeft: -32, // This value should match sectionBody class in reportPageSection
    marginRight: -32,
  },
  scoreSelect: {
    width: 60,
    backgroundColor: "white",
    marginLeft: 10,
  },
  selectScoreAlign: {
    display: "flex",
    justifyContent: "center",
    fontSize: styleVariables.fontXl,
  },
  editableScore: {
    display: "flex",
    alignItems: "center",
  },
  scoreText: {
    padding: "10px 32px"
  },
  scoreEditText: {
    paddingTop: 25,
    paddingBottom: 13,
  },
  scoreDefinitionWrapper: {
    paddingBottom: 30,
  },
  avoidPageBreak: {
    "@media print": {
      pageBreakInside: "avoid"
    }
  },
  readOnlyScoreHeaderRow: {
    padding: "8px 58px",
  },
  scoreDisplay: {
    width: 80
  }
}));

const ScoresSection = ({
  dispatch,
  onSubsectionAutosave,
  readOnly = false,
  section,
}) => {
  const classes = useStyles();
  const subsections = section._associations.ReportSubSections;

  return (
    <ReportPageSection title={section.Section_Header}>
      {!readOnly && (
        <div className={classes.scoreDefinitionWrapper}>
          <ScoreDefinitionsTable editReferenceView />
        </div>
      )}
      {subsections.map((subsection) => {
        return (
          <ScoreCategory
            dispatch={dispatch}
            onAutosave={onSubsectionAutosave}
            readOnly={readOnly}
            subsection={subsection}
            key={subsection.ReportSubSection_ID}
          />
        );
      })}
    </ReportPageSection>
  );
};

const ScoreCategory = ({ dispatch, onAutosave, readOnly, subsection }) => {
  const classes = useStyles();

  const score = subsection._associations?.AssessmentCategoryScores?.[0];

  const handleScoreChange = useCallback(async event => {
    const scoreResponse = await PortfolioService.updateReportScores(
      score?.Score_ID,
      {ReportSubSection_ID: subsection?.ReportSubSection_ID, Score: event.target.value }
    );
    dispatch({ type: ACTION_REPLACE_REPORT_SCORE, payload: scoreResponse.payload[0] });
  }, [dispatch, score?.Score_ID, subsection?.ReportSubSection_ID]);

  return (
    <div className={classes.scoreCategoryContainer}>
      <div className={classNames(classes.scoreHeaderRow, classes.avoidPageBreak, readOnly ? classes.readOnlyScoreHeaderRow : null)}>
        <Header variant="h4White">{subsection.Section_Header}</Header>
        {readOnly ? (
          <Header className={classes.scoreDisplay} variant="h4White"> {`Score: ${score.Score ? score.Score : ""}`}</Header>
        ) : (
          <div className={classes.editableScore}>
            <Header variant="h4White">Score:</Header>
            <CustomSelect
              name={`score_${score?.Score_ID}_score`}
              className={classes.scoreSelect}
              height="auto"
              value={score?.Score || ""}
              onChange={handleScoreChange}
            >
              {["", 1, 2, 3, 4, 5].map((scoreValue) => (
                <MenuItem value={scoreValue} id={`score-${scoreValue}`} key={scoreValue}>
                  <div className={classes.selectScoreAlign}>{scoreValue}</div>
                </MenuItem>
              ))}
            </CustomSelect>
          </div>
        )}
      </div>


      {readOnly ? (
        <div className={classes.scoreText}>
          <DisplayHTML html={subsection.Section_Content} />
        </div>
      ) : (
        <div className={classes.scoreEditText}>
          <CustomSunEditor
            height="auto"
            defaultValue={subsection.Section_Content}
            name={`ReportSubSections__${subsection.ReportSubSection_ID}`}
            onAutosave={(_name, value) => (
              onAutosave("Section_Content", value, subsection)
            )}
          />
        </div>
      )}
    </div>
  );
};

export default ScoresSection;
