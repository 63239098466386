import React, { useState, useEffect, useCallback, useMemo, useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import BuilderService from "services/builder.service";
import RiskAssessmentService from "services/riskAssessment.service";
import FileService from "services/file.service";
import BuilderInfoCard from "components/builder/info-card/builderInfoCard.component";
import CustomContainer from "components/utils/container.component";
import CustomModal from "components/utils/modal.component";
import PolicyBuilderTempForm from "components/forms/policyBuilderTempForm.component";
import OrchestrationBuilderForm from "components/forms/orchestrationBuilderForm.components";
import ProcLandingTable from "components/builder/procLandingTable.component";
import BackToLink from "components/utils/backToLink.component";
import { replaceSubstring } from "utils/stringFuncs";
import BuilderLandingTopSection from "components/builder/builderLandingTopSection.component";
import NoPolicyProcLanding from "components/builder/noPolicyProcLanding.component";
import variables from "styleVariables";
import { Tabs, Tab, Box } from '@material-ui/core';
import CustomIcons from "components/utils/icons.component";
import PageHeader from "components/utils/pageHeader.component";
import AssessmentBuilderEntry from "components/assessment/assessmentBuilderEntry.component";
import { checkCompTypeHasHistory, compTypeCheck } from "utils/builderFunctions";
import classNames from "classnames";
import { isReadOnly } from "utils/roles";
import NotFound from "components/auth/notFound.component";
import Charterbuilder from "components/builder/charter/charterBuilder.component";
import PolicyBuilder from "components/builder/policy/policyBuilder.component";
import ProcedureBuilder from "components/builder/procedure/procedureBuilder.component";
import PlanBuilder from "components/builder/plan/planBuilder.component";
import { builderModes } from "utils/builderConstants";
import { PROGRAM_ID_ORCHESTRATION } from "utils/programConstants";
import useNumericParams from "hooks/useNumericParams";
import NoBuildersMessage from "components/builder/noBuildersMessage.component";
import Badge from "components/utils/badge.component";
import ProgramService from "services/program.service";
import { ACTION_PARTIAL_REPLACE_COMPONENT_OBJECTS } from "reducers/global/program.reducer";
import ProgramsContext from "contexts/programs.context";
import { setStateFetchEffect } from "utils/ajaxHelpers";
import { HTTP_STATUS_NO_CONTENT } from "services/http-common";

const useStyles = makeStyles((theme) => ({
  // FOR NON-TABULAR VIEW
  flexCardContainer: {
    maxWidth: 900,
    paddingTop: 40,
    [theme.breakpoints.down("md")]: {
      display: "inline-flex",
    },
  },
  // FOR PROCEDURE TABLE
  tableWrapper: {
    padding: "50px 20px 0px 20px"
  },
  procTableWrapper: {
    paddingTop: 50,
    paddingLeft: 15,
    paddingRight: 15,
  },
  emptyProcWrapper: {
    paddingTop: 50,
    paddingRight: 70,
  },
  procTabsHeight: {
    height: "calc(100vh - 220px)",
  },
  // TABULAR MENU
  tabsRoot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    paddingBottom: 0,
    height: "calc(100vh - 280px)",
    marginTop: 40,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
    overflow: "unset",
    minWidth: 120,
  },
  tab: {
    paddingTop: 25,
    paddingBottom: 25,
    paddingLeft: 17,
    paddingRight: 17,
    fontSize: variables.fontMedium,
    minWidth: 120,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
    [theme.breakpoints.down('md')]: {
      "& .MuiTab-wrapper": {
        maxWidth: 120,
      },
    },
  },
  labelIcon: {
    color: variables.textSecondary,
    fill: variables.textSecondary,
  },
  selectedTab: {
    backgroundColor: variables.chosenElement,
    fontWeight: "bold",
    color: variables.primaryMain,
    fill: variables.primaryMain,
  },
  tabIcon: {
    height: 30,
    position: "relative",
  },
  tabPanelsWrapper: {
    paddingTop: 5,
    maxHeight: "calc(100vh - 220px)",
    overflowX: "auto",
    marginLeft: 20,
    paddingRight: 30,
    display: "flex",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      overflowX: "auto",
    },
  },
  assessmentTabPanelsWrapper: {
    flex: "unset",
    [theme.breakpoints.down("md")]: {
      paddingRight: 0
    }
  },
  tabLabel: {
    lineHeight: "1.2rem",
    fontSize: variables.fontSmall,
  },
  headerTab: {
    fontWeight: "bold",
    fontSize: variables.fontMedium
  },
  badgeContainer: {
    position: "absolute",
    top: -9,
    right: 0,
  },
  indicator: {
    backgroundColor: variables.secondaryLight,
    width: 5,
    opacity: 1,
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    border: `1px solid white`,
    padding: "0 2px",
    fontSize: variables.fontXs,
    height: 20,
    fontWeight: "bold",
    borderRadius: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: variables.tertiary2,
  },
}))(Badge);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      // role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >

      {children}

    </div>
  );
}

const BuilderType = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    state: programState,
    dispatch: programDispatch
  } = useContext(ProgramsContext);
  const { programId, programComponentId, componentObjectId, builderId, mode } = useParams();
  const numericParams = useNumericParams()
  const readOnly = isReadOnly();
  const componentObject = props.componentObject;
  const compTypeRef = props.componentObject.TypeRef.split("|")[0];
  const hasHistory = checkCompTypeHasHistory(compTypeRef);
  const [prevBuilderId, setPrevBuilderId] = useState();
  const [builderBeingOverwrittenByCopy, setBuilderBeingOverwrittenByCopy] = useState();
  const [builderInfo, setBuilderInfo] = useState(); //header info
  const [builderMode, setBuilderMode] = useState(mode);
  const [currentBuilder, setCurrentBuilder] = useState({});
  const [loadingDownload, setLoadingDownload] = useState(null);
  const [policyFrameworks, setPolicyFrameworks] = useState([]);
  const [chosenProgram, setChosenProgram] = useState({ id: "", name: "" });
  const [policies, setPolicies] = useState();
  const [currentPolicy, setCurrentPolicy] = useState();
  const [activeProcBuilders, setActiveProcBuilders] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showIsSectionlessDialog, setShowIsSectionlessDialog] = useState();
  const [programTab, setProgramTab] = useState(1);
  const [nameProp, setNameProp] = useState("");
  const [programLoaded, setProgramLoaded] = useState(false);
  const [builderModeLoaded, setBuilderModeLoaded] = useState(false);
  const [currentBuilderLoaded, setCurrentBuilderLoaded] = useState(false);

  //MODAL
  const [editModal, setEditModal] = useState(false);

  const handleChange = (event, newValue) => {
    setProgramTab(newValue);
  };

  const openForm = () => {
    setEditModal(true);
  };

  const toggleForm = () => {
    setEditModal(!editModal);
  };

  const setMode = useCallback((buildermode = null, builder = null, pushHistory = true) => {
    if (hasHistory && pushHistory) {
      let path = `/program/${programId}/${programComponentId}/${componentObjectId}`;
      if (builder) {
        path += `/assessment/${builder.Builder_ID}`;
      }
      if (buildermode) {
        path += `/${buildermode}`;
      }
      history.push(path);
    }

    setBuilderMode(buildermode);
    if (!hasHistory || builder || !buildermode) {
      setCurrentBuilder(builder);
    }
  }, [programId, programComponentId, componentObjectId, hasHistory, history, setBuilderMode, setCurrentBuilder]);

  useEffect(() => {
    if (
      chosenProgram.compObjId &&
      !programState.componentObjects?.[chosenProgram.compObjId]
    ) {
      return setStateFetchEffect(
        ProgramService.getComponentObjectById(chosenProgram.compObjId),
        ([objectResponse]) => {
          programDispatch({
            type: ACTION_PARTIAL_REPLACE_COMPONENT_OBJECTS,
            payload: objectResponse.payload,
          });
          setProgramLoaded(true);
        }
      );
    } else {
      setProgramLoaded(true);
    }
  }, [chosenProgram, programDispatch, programState.componentObjects]);

  useEffect(() => {
    setBuilderModeLoaded(true);
  }, [builderMode]);

  useEffect(() => {
    setCurrentBuilderLoaded(true);
  }, [currentBuilder]);

  useEffect(() => {
    if (compTypeRef && !compTypeRef.includes("Assessment")) {
      window.onpopstate = () => {
        history.go(); //only runs if page is accessed via back button
      };
    }

    if (builderId && (!programLoaded && !builderModeLoaded && !currentBuilderLoaded)) {
      RiskAssessmentService.getAllCurrentAssessments()
        .then((res) => {
          if (res && res.payload && res.payload[0]) {
            return res.payload.find(builder => (
              builder.Builder_ID.toString() === builderId.toString()
            ));
          } else {
            return {};
          }
        })
        .then((builderFromParams) => {
          if (builderFromParams && builderFromParams.Program_ID && builderFromParams.Name) {
            setChosenProgram({
              id: builderFromParams.Program_ID,
              name: builderFromParams.Name,
            });
            setBuilderMode(mode || `review`);
            setCurrentBuilder(builderFromParams);
          } else {
            return (
              <NotFound />
            )
          }
        })
    }

    if (!builderId) {

      let builders = [];

      if (compTypeRef === "ProgramAssessment") {
        setNameProp("Assessments")
        RiskAssessmentService.getAllCurrentAssessments()
          .then((res) => {
            builders = res.payload;
            setBuilderInfo(builders)
          })
      } else if (compTypeRef === "Assessment") {
        RiskAssessmentService.getCurrentAssessmentsByProgID(programId)
          .then((res) => {
            builders = res.payload;
            setBuilderInfo(builders)
          })
      } else if (compTypeRef.includes("Program")) {
        const type = compTypeRef.replace("Program", "");
        setNameProp(type)
        BuilderService.getAllActiveBuilderByType(type)
          .then((res) => {
            builders = res.payload;
            return BuilderService.getPublishedVersions(type);
          })
          .then((res) => {
            builders.forEach(builder => {
              builder.previousVersions = res.payload.filter(responseBuilder => (
                builder.BuilderDoc_ID === responseBuilder.BuilderDoc_ID
              )).sort((b1, b2) => b2.Change_Date - b1.Change_Date);
            });
          })
          .then(() => {
            setBuilderInfo(builders);
          });
      } else {
        BuilderService.getCurrentCompObjectBuilder(
          componentObject.ComponentObject_ID
        )
          .then((res) => {
            builders = res.payload;
            return BuilderService.getListOfPreviousCompObjectBuilders(
              componentObject.ComponentObject_ID
            );
          })
          .then((res) => {
            builders.forEach(builder => {
              builder.previousVersions = res.payload.filter(responseBuilder => (
                responseBuilder.BuilderDoc_ID === builder.BuilderDoc_ID
              ));
            });
          })
          .then(() => {
            setBuilderInfo(builders);
          });
      }

      if (compTypeRef.includes("Procedure")) {
        const getProceduresAndAssociatedPolicies = async () => {
          const activePoliciesRes =
            await BuilderService.getAllActiveBuilderByType("Policy");
          if (compTypeRef === "ProgramProcedure") {
            setPolicies(activePoliciesRes.payload);
            const allActiveProceduRes =
              await BuilderService.getAllActiveBuilderByType("Procedure");
            setActiveProcBuilders(allActiveProceduRes.payload);
          }

          if (compTypeRef === "Procedure") {
            const scopedActivePolicies = activePoliciesRes.payload.filter(
              (policy) => parseInt(programId) === policy.Program_ID
            );
            setPolicies(scopedActivePolicies);

            const programLevelProcedures =
              await BuilderService.getCurrentCompObjectBuilder(
                componentObject.ComponentObject_ID
              );
            setActiveProcBuilders(programLevelProcedures.payload);
          }
        };
        getProceduresAndAssociatedPolicies();
      }
    }
  }, [
    componentObject.ComponentObject_ID,
    builderMode,
    componentObject.ProgramComponents_Component_ID,
    compTypeRef,
    history,
    programId,
    builderId,
    mode,
    builderModeLoaded,
    programLoaded,
    currentBuilderLoaded
  ])

  const downloadBuilderFile = (id, name) => {
    if(!id) {
      setShowError(true);
      setLoadingDownload(null);
      setShowIsSectionlessDialog(id);
      return;
    }
    setLoadingDownload(id);
    setShowError(false);
    // download file
    FileService.builderDocDownload(id)
      .then((blob) => {
        if (blob.status === HTTP_STATUS_NO_CONTENT) {
          setShowError(true);
          setLoadingDownload(null);
          setShowIsSectionlessDialog(id);

        } else {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${name}.pdf`);
          // Append to html page
          document.body.appendChild(link);
          // Force download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        }
      })
      .then(() => {
        setLoadingDownload(null);
      });
  };

  const previousVersionSelect = (selectedBuilderToCopyId, builder, level) => {
    if (level) {
      setChosenProgram({
        id: builder.Program_ID,
        name: builder.Name,
        compObjId: builder.ComponentObjects_ComponentObject_ID,
      });
    }
    setPrevBuilderId(selectedBuilderToCopyId);
    setBuilderMode(builderModes.COPY_PREV_BUILDER);
    setBuilderBeingOverwrittenByCopy(builder)
  };

  const builderInfoCardProps = useMemo(() => ({
    setShowIsSectionlessDialog,
    showIsSectionlessDialog,
    showError,
    loadingDownload,
    downloadBuilderFile,
    setChosenProgram,
    setMode,
    previousVersionSelect,
    compTypeRef,
    readOnly,
  }), [
    compTypeRef, loadingDownload, showIsSectionlessDialog, readOnly, setMode, showError
  ]);

  const titleText = () => {
    if (programId === "8") {
      return `${replaceSubstring(
        chosenProgram.name,
        "Orchestration",
        ""
      )} ${replaceSubstring(props.componentObject.Name, ` Builder`, "")}`;
    } else {
      return props.componentObject.Name;
    }
  };


  if (builderMode && builderMode !== "saveNewBuilder") {
    return (
      <>
        {!!compTypeRef && !compTypeCheck("Assessment", compTypeRef) && (
          <>
            <PageHeader title={titleText()} withTasks />
            <CustomContainer>
              <BuilderByType
                builder={currentBuilder}
                setBuilderMode={setBuilderMode}
                setBuilderInfo={setBuilderInfo}
                mode={builderMode}
                componentObject={componentObject}
                builderBeingOverwrittenByCopy={builderBeingOverwrittenByCopy}
                prevBuilderId={prevBuilderId}
                policyFrameworks={policyFrameworks}
                type={compTypeRef}
                policy={currentPolicy}
                chosenProgram={chosenProgram}
                readOnly={readOnly}
              />
            </CustomContainer>
          </>
        )}
        {compTypeCheck("Assessment", compTypeRef) && (
          <AssessmentBuilderEntry
            pageTitle={titleText()}
            builder={currentBuilder}
            setMode={setMode}
            mode={builderMode}
            type={compTypeRef}
            chosenProgram={chosenProgram}
            readOnly={readOnly}
          />
        )}
      </>
    );
  }

  if (builderInfo && !builderMode) {
    const programList = [...new Set (builderInfo.filter(b => b.Name !== "Orchestration").map(b => b.Name))]
    const enterprise = builderInfo.some(b => b.Name === "Orchestration") ? "Enterprise" : null;
    let policyProgramList;
    let enterprisePol;
    if (policies && policies?.length) {
      policyProgramList = [...new Set (policies.filter(b => b.Name !== "Orchestration").map(b => b.Name))]
      enterprisePol = policies.some(b => b.Name === "Orchestration") ? "Enterprise" : null;
    }

    return (
      <>
        <PageHeader title={props.componentObject.Name} withTasks />
        {props.parentComponent.Type !== "Redirect" ? (
          <BackToLink
            href={`/program/${programId}/${programComponentId}`}
            parentPage={props.parentComponent.Name}
          />
        ) : (
          <Box marginTop={5} />
        )}
        <CustomContainer>
          {/* ====== UPPER SECTION ====== */}

          <BuilderLandingTopSection
            compTypeRef={compTypeRef}
            openForm={openForm}
            setMode={setMode}
            openfForm={openForm}
            readOnly={readOnly}
          />

          {/* ============ MAIN SECTION ============ */}

          {/* Plans + Charter + Policy === MAIN SECTION */}
          {!compTypeRef.includes("Program") &&
            compTypeRef !== "Procedure" &&
            builderInfo.map((builder, index) => (
              <div className={classes.flexCardContainer} key={builder.Builder_ID}>
                <BuilderInfoCard
                  {...builderInfoCardProps}
                  builder={builder}
                />
              </div>
            ))}

          {/* ProgramPlans + ProgramCharter + ProgramPolicy ==== MAIN SECTION */}
          {compTypeRef.includes("Program") &&
            compTypeRef !== "ProgramProcedure" &&
            (
              <div className={classes.tabsRoot}>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={programTab}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  className={classes.tabs}
                  scrollButtons="off"
                  TabIndicatorProps={{ className: classes.indicator }}
                >
                  <Tab
                    label={<span className={classNames(classes.tabLabel, classes.headerTab)}>PROGRAMS</span>}
                    disabled
                  />
                  <Tab
                    label={<span className={classes.tabLabel}>Enterprise Level</span>}
                    // label={"Enterprise Level"}
                    className={classes.tab}
                    icon={
                      <div className={classes.tabIcon}>
                        <CustomIcons variant="Orchestration" />
                        <div className={classes.badgeContainer}>
                          <StyledBadge
                            alertCount={
                              builderInfo.filter(
                                (b) => b.Name === "Orchestration"
                              ).length
                            }
                            color="secondary"
                          />
                        </div>

                        {/* <StyledBadge alertCount={0} color="secondary" /> */}
                      </div>
                    }
                    classes={{
                      labelIcon: classes.labelIcon,
                      selected: classes.selectedTab,
                      label: classes.label,
                    }}
                  />

                  {programList.map((prog) => {
                    return (
                      <Tab
                        key={prog}
                        // label={prog}
                        label={<span className={classes.tabLabel}>{prog}</span>}
                        // {...a11yProps(0)}
                        className={classes.tab}
                        icon={
                          <div className={classes.tabIcon}>
                            <CustomIcons
                              variant={
                                builderInfo.find((b) => b.Name === prog)
                                  .Program_Icon
                              }
                            />
                            <div className={classes.badgeContainer}>
                              <StyledBadge
                                alertCount={
                                  builderInfo.filter((b) => b.Name === prog)
                                    .length
                                }
                                color="secondary"
                              />
                            </div>
                          </div>
                        }
                        classes={{
                          labelIcon: classes.labelIcon,
                          selected: classes.selectedTab,
                          label: classes.sectionTitle,
                        }}
                        data-cy={`verticalTab-program-${prog}`}
                      />
                    );
                  })}
                </Tabs>
                <div
                  className={classNames(
                    classes.tabPanelsWrapper,
                    compTypeCheck("Assessment", compTypeRef) && classes.assessmentTabPanelsWrapper
                  )}
                >
                  <TabPanel
                    value={programTab}
                    index={1}
                    className={classes.tabPanelWrapper}
                  >
                    {enterprise ? (
                      builderInfo
                        .filter((b) => b.Name === "Orchestration")
                        .map((builder) => (
                          <span key={builder.Builder_ID}>
                            <BuilderInfoCard
                              {...builderInfoCardProps}
                              builder={builder}
                              variant="listByProgram"
                            />
                          </span>
                        ))
                    ) : (
                      <NoBuildersMessage componentObject={componentObject} isEnterpriseBuilder />
                    )}
                  </TabPanel>

                  {programList.map((prog, index) => {
                    return (
                      <TabPanel
                        value={programTab}
                        index={index + 2}
                        key={prog}
                        className={classes.tabPanelWrapper}
                      >
                        {builderInfo
                          .filter((b) => b.Name === prog)
                          .map((builder) => (
                            <span key={builder.Builder_ID}>
                              <BuilderInfoCard
                                {...builderInfoCardProps}
                                builder={builder}
                                variant="listByProgram"
                              />
                            </span>
                          ))}
                      </TabPanel>
                    );
                  })}
                </div>
              </div>
            )}

          {/* Procedure ===  MAIN SECTION */}
          {
            compTypeRef === "Procedure" &&
            policies?.length > 0 &&
            !!activeProcBuilders && (
              <div className={classes.procTableWrapper}>
                <ProcLandingTable
                  policies={policies}
                  downloadBuilderFile={downloadBuilderFile}
                  activeProcBuilders={activeProcBuilders}
                  setCurrentPolicy={setCurrentPolicy}
                  setChosenProgram={setChosenProgram}
                  setMode={setMode}
                  loadingDownload={loadingDownload}
                />
              </div>
            )
          }
          {/* EMPTY Procedure and ProgramProcedure NO POLICIES */}
          {numericParams.programId !== PROGRAM_ID_ORCHESTRATION && compTypeRef.includes("Procedure") && policies.length === 0 && (
            <div className={classes.procTableWrapper}>
              <NoPolicyProcLanding />
            </div>
          )}
          {/* No Builder Cards on Program Level */}
          {numericParams.programId !== PROGRAM_ID_ORCHESTRATION && builderInfo?.length === 0 && !compTypeRef.includes("Procedure") && (
            <NoBuildersMessage
              compTypeRef={compTypeRef}
              componentObject={componentObject}
            />
          )}
          {/* ====== ORCEHSTRATION PROCEDURE TABLES ======= */}
          {compTypeRef === "ProgramProcedure" && (
            <div className={classNames(classes.tabsRoot, classes.procTabsHeight)}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={programTab}
                onChange={handleChange}
                className={classes.tabs}
              >
                <Tab
                  label={<span className={classNames(classes.tabLabel, classes.headerTab)}>PROGRAMS</span>}
                  disabled
                />
                <Tab
                  label="Enterprise Level"
                  className={classes.tab}
                  icon={
                    <div className={classes.tabIcon}>
                      <CustomIcons variant="Orchestration" />
                      <div className={classes.badgeContainer}>
                        <StyledBadge
                          alertCount={
                            builderInfo.filter(
                              (b) => b.Name === "Orchestration"
                            ).length
                          }
                          color="secondary"
                        />
                      </div>
                    </div>
                  }
                  classes={{
                    labelIcon: classes.labelIcon,
                    selected: classes.selectedTab,
                  }}
                />

                {!!policyProgramList && policyProgramList.map((prog) => {
                  return (
                    <Tab
                      key={prog}
                      label={prog}
                      className={classes.tab}
                      icon={
                        <div className={classes.tabIcon}>
                          <CustomIcons
                            variant={
                              policies.find((b) => b.Name === prog).Program_Icon
                            }
                          />
                          <div className={classes.badgeContainer}>
                            <StyledBadge
                              alertCount={
                                builderInfo.filter(
                                  (b) => b.Name === prog
                                ).length
                              }
                              color="secondary"
                            />
                          </div>
                        </div>
                      }
                      classes={{
                        labelIcon: classes.labelIcon,
                        selected: classes.selectedTab,
                      }}
                    />
                  );
                })}
              </Tabs>
              <div className={classes.tabPanelsWrapper}>
                <TabPanel
                  value={programTab}
                  index={1}
                  className={classes.tabPanelWrapper}
                >
                  {enterprisePol ? (
                    <div className={classes.tableWrapper}>
                      <ProcLandingTable
                        policies={policies.filter(
                          (builder) => builder.Name === "Orchestration"
                        )}
                        downloadBuilderFile={downloadBuilderFile}
                        activeProcBuilders={activeProcBuilders}
                        setCurrentPolicy={setCurrentPolicy}
                        setChosenProgram={setChosenProgram}
                        setMode={setMode}
                        loadingDownload={loadingDownload}
                      />
                    </div>
                  ) : (
                    <div className={classes.emptyProcWrapper}>
                      <NoPolicyProcLanding listByProgram />
                    </div>
                  )}
                </TabPanel>

                {!!policyProgramList && policyProgramList.map((prog, index) => {
                  return (
                    <TabPanel
                      value={programTab}
                      index={index + 2}
                      key={prog}
                      className={classes.tabPanelWrapper}
                    >
                      <div className={classes.tableWrapper}>
                        <ProcLandingTable
                          policies={policies.filter(
                            (builder) => builder.Name === prog
                          )}
                          downloadBuilderFile={downloadBuilderFile}
                          activeProcBuilders={activeProcBuilders}
                          setCurrentPolicy={setCurrentPolicy}
                          setMode={setMode}
                          loadingDownload={loadingDownload}
                          setChosenProgram={setChosenProgram}
                        />
                      </div>
                    </TabPanel>
                  );
                })}
              </div>
            </div>
          )}

          {/* ======= MODAL FORMS ====== */}
          <CustomModal open={editModal} onClose={toggleForm}>
            {(compTypeRef === "ProgramPlan" ||
              compTypeRef === "ProgramCharter" ||
              compTypeRef === "ProgramAssessment") && (
              <OrchestrationBuilderForm
                setEditModal={setEditModal}
                setMode={setMode}
                setChosenProgram={setChosenProgram}
                chosenProgram={chosenProgram}
                name={nameProp}
                builderInfo={builderInfo}
              />
            )}
            {(compTypeRef === "Policy" || compTypeRef === "ProgramPolicy") && (
              <PolicyBuilderTempForm
                setEditModal={setEditModal}
                setMode={setMode}
                setChosenProgram={setChosenProgram}
                chosenProgram={chosenProgram}
                setPolicyFrameworks={setPolicyFrameworks}
                policyType={compTypeRef}
              />
            )}
          </CustomModal>
        </CustomContainer>
      </>
    );
  } else {
    return "";
  }
};

export default BuilderType;

const BuilderByType = (props) => {
  if (compTypeCheck("Charter", props.type)) {
    return <Charterbuilder {...props} />;
  } else if (compTypeCheck("Policy", props.type)) {
    return <PolicyBuilder {...props} />;
  } else if (compTypeCheck("Procedure", props.type)) {
    return <ProcedureBuilder {...props} />;
  } else if (compTypeCheck("Plan", props.type)) {
    return <PlanBuilder {...props} />;
  } else {
    return null;
  }
};
