import { makeStyles } from "@material-ui/core";
import TableCell from "components/utils/tables/shared/tableCell.component";
import classNames from "classnames";
import TextFieldPlain from "components/utils/form-elements/textFieldPlain.component";
import { memo, useCallback, useMemo } from "react";
import TableCellRadioGroup from "./tableCellRadioGroup.component";
import { COLUMN_TYPE_EMPTY, COLUMN_TYPE_RADIOS, COLUMN_TYPE_TEXT, COLUMN_TYPE_USER } from "./constants/reconciliationTableConstants";

const useStyles = makeStyles((theme) => ({
  cell: {
    borderCollapse: "collapse",
    borderRight: `1px solid ${theme.palette.border.main}`,
    borderLeft: `1px solid ${theme.palette.border.main}`,
    verticalAlign: "top"
  },
  reconcileNameCell: {
    backgroundColor: "#92aad4",
    color: "#ffffff",
    fontWeight: 600
  },
  textCell: {
    paddingTop: 6,
    paddingBottom: 7,
    whiteSpace: "pre-line"
  },
  textCellInner: {
    margin: "2px 0 3px",
    paddingTop: 0,
    paddingBottom: 2,
    transition: ".05s ease-out border-bottom, .05s ease-out margin-bottom",
  },
  textCellInnerEditable: {
    borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
    "&:hover": {
      marginBottom: 2,
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
    "&:focus": {
      marginBottom: 2,
      borderBottom: `2px solid #164C9D`,
    }
  },
  textCellInnerReadOnly: {
    cursor: "text"
  },
  textCellField: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  textCellFieldInput: {
    minHeight: 24,
    paddingTop: 8,
    paddingBottom: 0,
    fontSize: 14
  },
}));

const EditableTableCell = memo(
  function EditableTableCellBase(props) {
    const classes = useStyles();
    const {
      column, isReconcile, onAutosave, onKeyDown, readOnly, response, usersById
    } = props;

    const savedValue = useMemo(() => {
      if (column.type === COLUMN_TYPE_USER && response?.data?.Is_Reconciled) {
        return null;
      }
      return response?.data?.[column.key];
    }, [column, response]);

    const userCellValue = useMemo(() => {
      if (column.type !== COLUMN_TYPE_USER) {
        return "";
      }

      if (savedValue && typeof savedValue === "string" && !parseInt(savedValue, 10)) {
        return savedValue;
      } else if (savedValue && usersById[savedValue]) {
        return `${usersById[savedValue].First_Name} ${usersById[savedValue].Last_Name}`;
      } else if (isReconcile) {
        return "Reconcile";
      }
      const currentUserInfo = usersById[undefined];
      return currentUserInfo.name;
    }, [column.type, isReconcile, savedValue, usersById]);

    const handleRadioAutosave = useCallback(event => (
      onAutosave(column.key, event.target.value)
    ), [column, onAutosave]);

    switch(column.type) {
      case COLUMN_TYPE_USER:
        return (
          <TableCell
            className={classNames(
              classes.cell,
              classes.nameCell,
              isReconcile && classes.reconcileNameCell
            )}
            colSpan={column.colSpan}
          >
            {userCellValue}
          </TableCell>
        );
      case COLUMN_TYPE_TEXT:
        return (
          <TableCell
            className={classNames(
              classes.cell,
              classes.textCell,
            )}
            colSpan={column.colSpan}
          >
            <TextFieldPlain
              id={`input-${response?.id}-${column.key}`}
              className={classes.textCellField}
              name={`input-${response?.id}-${column.key}`}
              defaultValue={savedValue || ""}
              onAutosave={(_name, value) => (
                onAutosave(column.key, value)
              )}
              onKeyDown={onKeyDown}
              margin="dense"
              variant="noLabel"
              inputProps={{
                className: classes.textCellFieldInput,
                classes: {
                  input: classNames(
                    classes.textCellInner,
                    readOnly && classes.textCellInnerReadOnly,
                    !readOnly && classes.textCellInnerEditable
                  )
                }
              }}
              readOnly={readOnly}
              fullWidth
              multiline
            />
          </TableCell>
        );
      case COLUMN_TYPE_RADIOS:
        return (
          <TableCell
            className={classes.cell}
            colSpan={column.colSpan}
            size="small"
          >
            <TableCellRadioGroup
              column={column}
              onAutosave={handleRadioAutosave}
              response={response}
            />
          </TableCell>
        );
      case COLUMN_TYPE_EMPTY:
        return (
          <TableCell
            className={classNames(classes.cell, classes.cellEmpty)}
            colSpan={column.colSpan}
            size="small"
          />
        );
      default:
        return null;
    }
  }
);

export default EditableTableCell;
