import React, { useMemo } from "react";
import { Card, CardContent, Divider } from "@material-ui/core";
import EditIcon from "components/utils/editIcon.component";
import ResetPasswordForm from "components/forms/resetPasswordForm.component";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import { H2, H5 } from "components/utils/headerV2.component";
import { mapObjectArrayByKey } from "utils/arrayOfObjectsHelpers";

const useStyles = makeStyles((theme) => ({
  profileTitle: {
    display: "flex",
    marginLeft: 10.5,
    alignItems: "center",
  },
  editIconWrapper: {
    marginTop: 2,
    paddingLeft: 12,
  },
  cardWrapper: {
    display: "flex",
  },
  userContent: {
    marginTop: 10,
  },
  contentWrapper: {
    display: "flex",
    paddingLeft: 10,
    paddingRight: 10,
  },
  infoContainer: {
    border: `2px solid ${variables.grayTint}`,
    paddingTop: 12,
    paddingLeft: 25,
    paddingRight: 25,
    borderRadius: 5,
    marginRight: 5,
    paddingBottom: 7,
  },
  controlsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `2px solid ${variables.grayTint}`,
    borderRadius: 5,
  },
  label: {
    padding: 0,
  },
  infoSection: {
    padding: "10px 0 0 0",
  },
  info: {
    paddingTop: 5,
    paddingRight: 30,
    paddingBottom: 5,
    paddingLeft: 10.5,
    fontSize: variables.fontSmall,
    color: variables.textSecondary,
  },
}));

const UserProfile = ({ user, openEditForm, roles }) => {
  const classes = useStyles();

  const roleDisplay = useMemo(() => {
    const rolesById = mapObjectArrayByKey(roles, "Role_ID");
    return rolesById?.[user.Role_ID]?.Name
  }, [roles, user]);

  return (
    <div className={classes.profileWrapper}>
      <div className={classes.profileTitle}>
        <H2>
          My profile
        </H2>
        <div className={classes.editIconWrapper}>
          <EditIcon
            variant="matrix"
            onClick={() => openEditForm(user.User_ID, "currentUser")}
            test="admin"
          />
        </div>
      </div>
      <div className={classes.cardWrapper}>
        <Card>
          <CardContent className={classes.userContent}>
            <div className={classes.contentWrapper}>
              <div className={classes.infoContainer}>
                <UserInformation label="First Name" value={user.First_Name} />
                <UserInformation label="Last Name" value={user.Last_Name} />
                <UserInformation label="Email" value={user.Email} />
                <UserInformation label="Phone" value={user.Phone} />
                <UserInformation label="Role" value={roleDisplay} />
              </div>
              <div className={classes.controlsContainer}>
                <ResetPasswordForm user={user} />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};


function UserInformation({ label, value }) {
  const classes = useStyles();
  return (
    <div className={classes.infoSection}>
      <H5 className={classes.label}>
        {label}
      </H5>
      <div className={classes.info}>
        {value || <>&nbsp;</>}
      </div>
      <Divider />
    </div>
  );
}

export default UserProfile;
