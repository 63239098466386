import React, { useCallback, useMemo, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styleVariables from "styleVariables";
import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Box,
  TableContainer,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import TableCell from "components/utils/tables/shared/tableCell.component";
import Checkbox from "components/utils/form-elements/checkbox.component";
import classNames from "classnames";
import Header from "components/utils/header.component";
import variables from "styleVariables"
import useTargetTableRowViaLink from "hooks/useTargetTableRowViaLink";
import useNumericParams from "hooks/useNumericParams";

//Note: This component is also used in the Application Schedule reference modal

const useStyles = makeStyles((theme) => ({
  emptyListRow: {
    color: styleVariables.textSecondary,
    display: "flex",
    justifyContent: "center",
  },
  //HEAD
  cellHeader: {
    backgroundColor: styleVariables.tertiary1,
    color: "white",
    fontWeight: "bold",
    fontSize: styleVariables.fontMedium,
    padding: "8px 16px",
    lineHeight: "normal",
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  checkboxHeader: {},
  //BODY
  cellBody: {
    //default cell body
    padding: "8px 16px",
    color: styleVariables.textSecondary,
    fontSize: styleVariables.fontSmall,
    borderLeft: "1px solid rgba(22, 76, 146, 0.22)",
    verticalAlign: "top",
  },
  checkboxBodyCell: {
    //default cell body
    textAlign: "center",
    width: 20,
    padding: 0,
  },
  wideCell: {
    minWidth: 240,
  },
  //ALIGN
  centerAlign: { textAlign: "center" },
  //HeaderSection
  headerSection: {
    display: "flex",
    flexDirection: "column",
    padding: "8px 16px",
  },
  title: {
    padding: 0,
  },
  countStats: {
    color: styleVariables.textSecondary,
  },
  cellDenseBody: {
    padding: 4,
    color: variables.textSecondary,
    fontFamily: "Helvetica",
    fontSize: variables.fontSmall,
    border: "1px solid rgba(22, 76, 146, 0.22)",
  },
  footerCell: {
    backgroundColor: variables.tertiary1,
    color: "white",
    fontWeight: 600,
    lineHeight: "normal",
  },
  stickyFooter: {
    position: "sticky",
    bottom: 0
  },
  paginationToolbarDense: {
    minHeight: 0
  },
  paginationButtonDense: {
    fill: "white",
    paddingTop: 4,
    paddingBottom: 4
  },
  paginationText: {
    fontSize: variables.fontSmall,
    fontWeight: 600
  },
}));

const cellTypes = {
  CHECKBOX: "checkbox",
};

const ListHeaderSection = ({ checklistInfo, countStats, isCompleted }) => {
  const classes = useStyles();
  const completed = countStats.completed || 0;
  return (
    <Box className={classes.headerSection}>
      <Header variant="h3Primary" className={classes.title}>
        {checklistInfo.Title}
      </Header>
      <div className={classes.countStats} data-cy="count-stats">
        {isCompleted ? (
          `All ${countStats.total} items completed`
        ) : (
          <span>
            {completed} of {countStats.total} items completed
          </span>
        )}
      </div>
    </Box>
  );
};

const Checklist = ({
  headers,
  tableData,
  pagination,
  denseBody,
  checkHandler,
  checklistInfo = {},
  countStats = {},
  primaryKey = "ChecklistItem_ID",
  hideListHeader,
  maxHeight,
  emptyMessage
}) => {

  const PAGINATION_DEFAULT_ROWS_PER_PAGE = 10;

  const classes = useStyles();
  const params = useNumericParams();
  const rowRef = useRef({});
  const [targetedRowId] = useTargetTableRowViaLink(rowRef, true);
  const [page, setPage] = useState(params?.page || 0);
  const [rowsPerPage, setRowsPerPage] = useState(
    pagination?.rowsPerPage ||
    pagination?.rowsPerPageOptions?.[0] ||
    PAGINATION_DEFAULT_ROWS_PER_PAGE
  );

  const displayRows = useMemo(() => {
    if (pagination) {
      const startIndex = page * rowsPerPage;
      const endIndex = (page + 1) * rowsPerPage;
      return tableData.slice(startIndex, endIndex);
    }
    return tableData;
  }, [page, rowsPerPage, tableData, pagination]);


  const handlePaginationRowsPerPageChange = useCallback(event => {
    const { value } = event.target;
    setRowsPerPage(value);
    const lastValidPage = Math.ceil(tableData.length / value) - 1;
    if (isNaN(lastValidPage)) {
      if (value > rowsPerPage) {
        setPage(0);
      }
      console.error("Error calculating last valid table page.");
    }
    if (page > lastValidPage) {
      setPage(lastValidPage);
    }
  }, [tableData.length, page, rowsPerPage]);

  return (
    <Paper>
      {!hideListHeader && (
        <ListHeaderSection
          checklistInfo={checklistInfo}
          countStats={countStats}
          isCompleted={countStats.total === countStats.completed}
        />
      )}

      <TableContainer style={{ maxHeight, minWidth: 500 }} data-cy="checklist-table-container">
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headers.map((head, rowIndex) => {
                const { type, display, fieldName, align } = head;

                return (
                  <TableCell
                    key={fieldName}
                    className={classNames(
                      classes.cellHeader,
                      type === cellTypes.CHECKBOX
                        ? classes.checkboxHeader
                        : undefined,
                      align === "center" && classes.centerAlign
                    )}
                  >
                    {display}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody className={classes.orderedList}>
            {displayRows.map((row) => {
              const idValue = row[primaryKey];
              return (
                <TableRow
                  key={idValue}
                  ref={(el) => (rowRef.current[idValue] = el)}
                  selected={targetedRowId === idValue}
                  data-cy={`checklist-tr-${idValue}`}
                >
                  {headers.map((head) => {
                    if (head.type === cellTypes.CHECKBOX) {
                      return (
                        <TableCell
                          key={head.fieldName}
                          className={classNames(
                            classes.cellBody,
                            classes.checkboxBodyCell,
                          )}
                        >
                          <Checkbox
                            checked={row[head.fieldName]}
                            onChange={(event) => checkHandler(event, idValue)}
                            name={head.fieldName}
                          />
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell
                          key={head.fieldName}
                          className={classNames(
                            classes.cellBody,
                            head.align === "center" && classes.centerAlign,
                            head.size === "large" && classes.wideCell,
                          )}
                        >
                          {row[head.fieldName]}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              );
            })}
            {tableData?.length === 0 && (
              <TableRow>
                <TableCell className={classes.tableCell} colSpan={headers.length}>
                  <div className={classes.emptyListRow}>{emptyMessage || "No data available"}</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {!!pagination && (
            <TableFooter>
              <TableRow hover={false}>
                <TablePagination
                  className={classNames(
                    classes.footerCell,
                    denseBody && classes.cellDenseBody,
                    classes.stickyFooter
                  )}
                  classes={{
                    caption: classes.paginationText,
                    select: classNames(
                      classes.paginationText,
                      classes.paginationButtonDense
                    ),
                    selectIcon: classes.paginationButtonDense,
                    toolbar: classNames(
                      denseBody && classes.paginationToolbarDense
                    )
                  }}
                  backIconButtonProps={{
                    className: denseBody && classes.paginationButtonDense
                  }}
                  nextIconButtonProps={{
                    className: denseBody && classes.paginationButtonDense
                  }}
                  size={denseBody ? "small" : "medium"}
                  count={tableData?.length || 0}
                  onPageChange={(_event, newPage) => setPage(newPage)}
                  onRowsPerPageChange={handlePaginationRowsPerPageChange}
                  {...(typeof pagination === "object" ? pagination : {})}
                  rowsPerPage={rowsPerPage}
                  page={page}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Paper>
  );
};
export default Checklist;
