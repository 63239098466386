import React, { useContext, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import variables from "styleVariables";
import { Typography } from "@material-ui/core";
import CustomIcons from "components/utils/icons.component";
import CustomLink from "components/utils/link.component";
import ProgramsContext from "contexts/programs.context";
import { COMPONENT_OBJECT_TYPE_BUILDER } from "utils/programConstants";

const useStyles = makeStyles((theme) => ({
  messageWrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    height: 180,
    flexDirection: "column",
    justifyContent: "center",
    color: variables.textSecondary,
  },
  orchMessageWrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    height: 100,
    flexDirection: "column",
    justifyContent: "center",
    color: variables.textSecondary,
  },
  linkWrapper: {
    paddingTop: 10,
    display: "flex",
  },
  iconWrapper: {
    fill: variables.secondaryMain,
    height: 18,
    paddingRight: 10,
    "&:hover": {
      fill: variables.primaryRight,
    },
  },
}));

export const NoPolicyProcLanding = (props) => {
  const classes = useStyles();
  const { state } = useContext(ProgramsContext);
  const { programId, programComponentId } = useParams();

  const compLink = useMemo(() => {
    const programComponentPath = `/program/${programId}/${programComponentId}`;
    const objects = state.componentObjectsByComponent?.[programComponentId];
    const policyBuilder = objects?.find?.(componentObject => (
      componentObject.Type === COMPONENT_OBJECT_TYPE_BUILDER &&
      componentObject.TypeRef.includes("Policy")
    ))
    if (policyBuilder) {
      return `${programComponentPath}/${policyBuilder.ComponentObject_ID}`;
    }
    return programComponentPath;
  }, [programId, programComponentId, state.componentObjectsByComponent]);

  if (props.listByProgram) {
    return (
      <div className={classes.orchMessageWrapper}>
        <Typography variant="h6">
          No Enterprise Level Procedures to display.
        </Typography>
        <Typography variant="h6">
          To create a Procedure, a corresponding Policy must be created first.
        </Typography>
        <CustomLink to={compLink} variant="routerLink">
          <span className={classes.linkWrapper}>
            <div className={classes.iconWrapper}>
              <CustomIcons variant="Policies-procedures" />
            </div>
            <Typography variant="body1">Go to Policy Builder</Typography>
          </span>
        </CustomLink>
      </div>
    );
  } else {
    return (
      <div className={classes.messageWrapper}>
        <Typography variant="h6">
          To create a Procedure, a corresponding Policy must be created first.
        </Typography>
        <CustomLink to={compLink} variant="routerLink">
          <span className={classes.linkWrapper}>
            <div className={classes.iconWrapper}>
              <CustomIcons variant="Policies-procedures" />
            </div>
            <Typography variant="body1">Go to Policy Builder</Typography>
          </span>
        </CustomLink>
      </div>
    );
  }
};

export default NoPolicyProcLanding;
