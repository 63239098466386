import http from "./http-common";
import authHeader from "./authHeader";


class SearchService {

  getByKeyword(keyword) {
    return http.get(
      `/search/search?keyword=${keyword}`,
      { headers: authHeader() }
    );
  }

  getTasksWorkpackagesByKeyword(keyword) {
    return http.get(
      `/search/searchTasksWorkpackages?keyword=${keyword}`,
      { headers: authHeader() }
    );
  }
}

export default new SearchService();
