import React from "react";
import variables from "styleVariables";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  matrixHeadCenter: {
    whiteSpace: "nowrap",
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      color: variables.primaryDark,
      cursor: "pointer",
    },
  },
}));
const MatrixHeadCenter = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.matrixHeadCenter} id={props.id} onClick={props.onClick}>
      {props.label}
    </div>
  );
};

export default MatrixHeadCenter;
