import { Box, makeStyles, Paper } from "@material-ui/core";
import { RemoveCircleOutline, Save, CheckCircleOutline, ErrorOutline } from "@material-ui/icons";
import CommentsSection from "components/certification/comments/commentsSection.component";
import ButtonDefault from "components/utils/buttonDefault.component";
import DialogPop from "components/utils/dialog.component";
import Form from "components/utils/form-elements/form.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import Header from "components/utils/header.component";
import Loader from "components/utils/loader.components";
import { useCallback, useState } from "react";
import variables from "styleVariables";
import classNames from "classnames";
import {
  ACTION_DELETE_MANUAL_SECTION,
  ACTION_REPLACE_MANUAL_CHAPTER,
} from "reducers/certification/manuals.reducer";
import certificationService from "services/certification.service";
import styleVariables from "styleVariables";
import AutoNumbering from "./autoNumbering.component";
import CfrReferenceSelection from "./sections/cfrReferenceSelection.component";

const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: 16,
  },
  form: {
    marginBottom: 8,
  },
  noChapterView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 64,
    marginBottom: 64,
    color: theme.palette.text.secondary,
  },
  paper: {
    padding: "24px 16px 32px 16px",
  },
  formMessage:{
    marginLeft: 16,
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
  successMessage: {
    color: variables.statusGreenDark,
  },
  errorMessage: {
    color: variables.warningMain
  },
  innerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  paperInner: {
    marginRight: 16,
    marginBottom: 16,
    width: "100%",
  },
  commentsWrapper: {
    width: 240,
    marginRight: 56,
  },
  sectionWrapper: {
    marginBottom: 24,
    borderRadius: 8,
    filter: styleVariables.filterBookTab,
    overflow: "hidden",
    position: "relative",
    zIndex: 1,
  },
  numberWrapper: {
    marginTop: 43,
  },
  inputWrapper: {
    width: "100%",
    maxWidth: 700,
  },
}));

const FORM_MESSAGE_UPDATED = "Updated";
const FORM_MESSAGE_ERROR = "Error";

export default function ChapterForm(props) {
  const classes = useStyles();
  const {
    children,
    chapter,
    chapterNumber,
    dispatch,
    setActiveChapterId,
    state,
  } = props;

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
  const [formMessage, setFormMessage] = useState("")

  const handleInputChange = useCallback(() => {
    setIsUnsavedChanges(true);
    setFormMessage("");
  }, []);

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        const sectionResponse = await certificationService.updateSection(
          chapter.ManualSection_ID,
          formData
        );
        setFormMessage(FORM_MESSAGE_UPDATED);
        setIsUnsavedChanges(false);
        dispatch({
          type: ACTION_REPLACE_MANUAL_CHAPTER,
          payload: sectionResponse.payload,
        });
      } catch (err) {
        console.log(err);
        setFormMessage(FORM_MESSAGE_ERROR);
      }
    },
    [dispatch, chapter]
  );

  const handleDelete = useCallback(async () => {
    const sectionResponse = await certificationService.deleteSection(
      chapter.ManualSection_ID
    );
    const chapters = state.manual._associations.ManualSection;
    const deletedIndex = chapters.indexOf(chapter);
    const prevChapter =
      chapters[deletedIndex - 1] || chapters[deletedIndex + 1] || null;
    setActiveChapterId(prevChapter?.ManualSection_ID);
    dispatch({
      type: ACTION_DELETE_MANUAL_SECTION,
      payload: sectionResponse.payload,
      meta: sectionResponse.meta,
    });
    setIsDeleteOpen(false);
  }, [dispatch, chapter, setActiveChapterId, setIsDeleteOpen, state.manual]);

  if (!state.manual) {
    return <Loader />;
  }
  return (
    <div className={classes.sectionWrapper} key={chapter?.ManualSection_ID} data-cy="man-section-wrapper">
      <Paper className={classes.paper} elevation={0}>
        {!chapter || state.manual._associations.ManualSection?.length === 0 ? (
          <div className={classes.noChapterView}>
            Add the first chapter by clicking the plus button above.
          </div>
        ) : (
          <>
            <Box display="flex">
              <div className={classes.numberWrapper}>
                <AutoNumbering
                  chapterNumber={chapterNumber}
                  isChapter
                  dispatch={dispatch}
                  section={chapter}
                />
              </div>
              <div className={classes.innerContainer}>
                <div className={classes.paperInner}>
                  <Header variant="h2Tertiary" className={classes.header}>
                    Chapter
                  </Header>
                  <Form
                    name={`chapter-form-${chapter.ManualSection_ID}`}
                    className={classes.form}
                    onSubmit={handleSubmit}
                    onChange={handleInputChange}
                  >
                    <div className={classes.inputWrapper}>
                      <LabelInput
                        name="Header"
                        label="Chapter Name"
                        defaultValue={chapter.Header || ""}
                        margin="dense"
                        variant="default"
                        test="chapter-name"
                      />
                    </div>

                    <Box marginTop={2} marginBottom={4}>
                      <CfrReferenceSelection
                        dispatch={dispatch}
                        manualSection={chapter}
                        state={state}
                      />
                    </Box>

                    <Box display="flex" justifyContent="space-between">
                      <ButtonDefault
                        background="primary"
                        type="submit"
                        variant="small"
                        startIcon={
                          <Box marginRight={1}>
                            <Save fontSize="small" />
                          </Box>
                        }
                        disableReadOnlyUsers
                        disabled={!isUnsavedChanges}
                        data-cy="btn-save-chapter"
                      >
                        Save
                      </ButtonDefault>
                      {!!formMessage && (
                        formMessage.includes(FORM_MESSAGE_ERROR) ? (
                          <div className={classNames(classes.formMessage, classes.errorMessage)}>
                            <ErrorOutline />
                            <Box marginLeft={1}>{formMessage}</Box>
                          </div>
                        ) : (
                          <div className={classNames(classes.formMessage, classes.successMessage)}>
                            <CheckCircleOutline />
                            <Box marginLeft={1}>{formMessage}</Box>
                          </div>
                        )
                      )}
                      <ButtonDefault
                        color="red"
                        variant="small"
                        onClick={() => setIsDeleteOpen(true)}
                        startIcon={
                          <Box marginRight={1}>
                            <RemoveCircleOutline fontSize="small" />
                          </Box>
                        }
                        disableReadOnlyUsers
                      >
                        Delete
                      </ButtonDefault>
                    </Box>
                    <DialogPop
                      openDialog={isDeleteOpen}
                      setOpenDialog={() => setIsDeleteOpen(false)}
                      confirm={handleDelete}
                    >
                      <Header variant="h3Primary">Delete Chapter?</Header>
                      <p>
                        All sections of this chapter will be deleted. This
                        cannot be undone.
                      </p>
                    </DialogPop>
                  </Form>
                </div>

                <div className={classes.commentsWrapper}>
                  <CommentsSection
                    section={chapter}
                    dispatch={dispatch}
                    comments={state.comments?.filter?.(
                      (comment) =>
                        comment.ManualSection_ID === chapter.ManualSection_ID &&
                        comment.Status === "Active"
                    )}
                  />
                </div>
              </div>
            </Box>
            {children}
          </>
        )}
      </Paper>
    </div>
  );
}
