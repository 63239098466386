import React, { useEffect, useState, useRef, useMemo } from "react";
import { Popover } from "@material-ui/core";
import { useLocation, useHistory, useParams } from "react-router-dom";
import CustomCard from "components/utils/card.component";
import CustomDataTable from "components/utils/tables/dataTable.component";
import ControlService from "services/control.service";
import variables from "styleVariables";
import CellItem from "components/utils/cellItem.component";
import CustomEditIcon from "components/utils/editIcon.component";
import { makeStyles } from "@material-ui/core/styles";
import invertObj from "utils/invertObj";
import PrivControlMatrixForm from "components/forms/privControlMatrixForm.component"
import CustomModal from "components/utils/modal.component";
import Loader from "components/utils/loader.components";
import { calculateMultipartSortValue, createHiddenHeaderParams, createNodeCellParams, createNodeHeaderParams, createValueHeaderParams } from "components/utils/tables/utils/dataTableHelpers";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  matrixContainer: {
    marginTop: 40
  },
  stickyCelledTable: {
    "& table": {
      borderCollapse: "separate"
    }
  },
  customHeaderLeft: {
    '& > span': {
      justifyContent: "left",
    },
  },
  customHeaderStickyLeft: {
    left: "0", //For sidescroll sticky header
    zIndex: 101,
    '& > span': {
      justifyContent: "left",
    },
  },
  longTextCell: {
    minWidth: 295
  },
  veryLongTextCell: {
    minWidth: 295
  },
  clickableCell: {
    minWidth: 200
  },
  highlightedCell: {
    backgroundColor: variables.chosenElement
  },
  principleCell: {
    position: "sticky",
    left: 0,
    margin: "-10px -20px",
    padding: "10px 20px",
    background: "white",
    color: variables.textSecondary,
    fontSize: variables.fontSamll,
    zIndex: 100,
    textAlign: "left",
    boxShadow: variables.shadowTableInset,
  }
}));

const hiddenColumns = new Set([
  "Matrix_ID",
  "Name",
  "Label",
  "Control_Text",
  "Status",
  "ControlDetail_Control_ID",
  "Program_Program_ID",
  "ManagementPrinciples_Mgmt_Principle_ID"
]);
const filterableHeaders = new Set(["Implementation_Type", "Current_State", "LabelName"]);
const longTextHeaders = new Set(["Control_Desc"]);
const longHeaders = new Set(["Implementation_Type", "Validation_Date"]);
const veryLongTextHeaders = new Set(["Roadmap_Notes"]);
const leftAlignedHeaders = new Set([
  ...Array.from(longTextHeaders), ...Array.from(veryLongTextHeaders)
]);
const nodeColumns = new Set(["Identifier"]);
const clickableColumn = {
  Control_Name: "Control_Text",
};

const REGEXP_PART_DELIMITER = /[-(]/;


const PrivControlMatrix = () => {
  const location = useLocation();
  const [matrixData, setMatrixData] = useState();
  const [matrixColumns, setMatrixColumns] = useState();
  const { programId } = useParams();
  const nameIndexObj = useRef(null);
  const rowRef = useRef({});
  const [searchRowId, setSearchRowId] = useState();
  const [controlText, setControlText] = useState({});
  const [refresh, setRefresh] = useState(true);
  const history = useHistory();
  //for popover
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const classes = useStyles()
  // for modal
  // const [editModal, setEditModal] = useState(true);
  const [editModal, setEditModal] = useState(false);
  const [rowInfo, setRowInfo] = useState();
  const [excelColumns, setExcelColumns] = useState();
  const [excelData, setExcelData] = useState();


  //options for the table
  const options = useMemo(() => ({
    filterType: "checkbox",
    fixedHeader: true,
    fixedSelectColumn: true,
    filter: true,
    pagination: false,
    selectableRowsHideCheckboxes: true,
    sortOrder: { name: "Matrix_ID" },
    tableBodyMaxHeight: 'calc(100vh - 245px)',
    setRowProps: !searchRowId ? undefined : row => {
      if (nameIndexObj.current?.LabelName) {
        const highlightedId = (
          `${row[nameIndexObj.current?.LabelName]?.node?.props?.["data-id"]}`
        );
        if (highlightedId === searchRowId) {
          return { className: classes.highlightedCell };
        }
      }
    },
  }), [classes, searchRowId]);

  const toggleForm = () => {
    setEditModal(!editModal);
  };

  // upon load, scrolls to specified row
  useEffect(() => {
    if (location.state) {
      setSearchRowId(location.state);
      if (rowRef && rowRef.current && searchRowId && matrixData ) {
        rowRef.current[searchRowId].scrollIntoView({
          block: "center",
        });
        history.replace(); //clears out location.state
      }
    }
  }, [location.state, rowRef, matrixData, searchRowId, history]);

  const openForm = (name) => {
    setRowInfo(name)
    setEditModal(true);
  }

  useEffect(() => {
    if(refresh) {
      ControlService.getPrivacyManagementControlMatrix(programId).then((res) => {
        if (!res?.payload?.length) {
          setMatrixColumns([]);
          setMatrixData([]);
          return;
        }
        const dataList = Object.keys(res.payload[0]);
        const shiftedDataList = ["blank", ...Object.keys(res.payload[0])] //makes up for first column
        nameIndexObj.current = invertObj(shiftedDataList);
        const columns = [];
      // edit column
        columns.push(createNodeHeaderParams("Matrix_ID", " ", {
          viewColumns: false,
          searchable: false,
          filter: false,
          sort: true,
          align: "center",
          setCellHeaderProps: () => ({
            className: classes.customHeaderStickyLeft
          })
        }));
        dataList.forEach(row => {
          const align = leftAlignedHeaders.has(row) ? "left" : "center";
          const filter = filterableHeaders.has(row);

          const columnLabel = row.split("_").join(" ");
          if (row === "LabelName") {
            columns.push(
            createNodeHeaderParams(row, "Principle", {
              align,
              filter,
              sort: true,
              setCellProps: () => ({
                classes: { root: classes.principleCell },
              }),
              setCellHeaderProps: () => ({
                className: classes.customHeaderStickyLeft
              }),
            })
            )
          } else if (hiddenColumns.has(row)) {
            columns.push(createHiddenHeaderParams(row, columnLabel));
          } else if (longHeaders.has(row)) {
            columns.push(createValueHeaderParams(row, columnLabel, {
              align,
              filter,
              sort: true,
            }));
          } else if (clickableColumn[row]) {
            columns.push(createNodeHeaderParams(row, columnLabel, {
              align,
              filter,
              sort: true,
              setCellProps: () => ({
                className: classes.clickableCell
              })
            }));
          } else if (nodeColumns.has(row)) {
            columns.push(createNodeHeaderParams(row, columnLabel, {
              align,
              filter,
              sort: true,
            }));
          } else {
            columns.push(createValueHeaderParams(row, columnLabel, {
              align,
              filter,
              sort: true,
              setCellProps: () => ({
                className: classNames(
                longTextHeaders.has(row) && classes.longTextCell,
                veryLongTextHeaders.has(row) && classes.veryLongTextCell
                )
              }),
              setCellHeaderProps: () => ({
                className: classNames(
                align === "left" && classes.customHeaderLeft
                )
              }),
            }));
          }
        });
        setMatrixColumns(columns);
        setMatrixData(res.payload);

      //REMOVES HIDDEN COLUMNS & DATA FOR EXCEL
        const columnCopy = columns;
        const matrixDataCopy = res.payload;
        columnCopy.splice(2,3)
        matrixDataCopy.splice(2,3)
        columnCopy.splice(-1)
        matrixDataCopy.splice(-1)
        setExcelColumns(columnCopy)
        setExcelData(matrixDataCopy)

      });
    }
    setRefresh(false);
  }, [programId, refresh, classes]);

  const tableRows = useMemo(() => (
    matrixData?.map?.(item => {
      const familyName = item.Family_Name;
      const clickableColumnEntries = Object.entries(clickableColumn)
        .filter(([_tableKey, dataKey]) => item[dataKey])
        .map(([tableKey, dataKey]) => ([tableKey, item[dataKey]]));

      return {
        ...item,
        Matrix_ID: createNodeCellParams(item.Matrix_ID, item.Matrix_ID, (
          <div
            id={familyName}
            ref={(el) => (rowRef.current[familyName] = el)}
            onClick={() => openForm(item.Matrix_ID)}
          >
            <CustomEditIcon
              variant="matrix"
              test={`editMatrixRow-${familyName}`}
            />
          </div>
        )),
        Identifier: createNodeCellParams(
          calculateMultipartSortValue(
            item.Identifier, "None", REGEXP_PART_DELIMITER, null
          ),
          item.Identifier
        ),
        LabelName: createNodeCellParams(item.LabelName, item.LabelName, (
          <div
            data-id={item.Matrix_ID}
            ref={el => (rowRef.current[item.Matrix_ID] = el)}
          >
            {item.LabelName}
          </div>
        )),
        ...Object.fromEntries(
          (clickableColumnEntries || []).map(([name, fullText]) => ([
            name,
            createNodeCellParams(item[name], item[name], (
              <CellItem
                variant="wordWrap"
                onClick={event => controlNameClick(event, item[name], fullText)}
                item={item[name]}
              />
            ))
          ]))
        )
      };
    })
  ), [matrixData]);

    // from customBodyRender
  const controlNameClick = (event, name, text) => {
    setAnchorEl(event.currentTarget);
    setControlText({name, text})
  };

  const handleClose = () => {
    setAnchorEl(null);
    setControlText({});
  };

  if (matrixData) {
    return (
      <div className={classes.matrixContainer}>
        {!!matrixColumns && (
          <CustomDataTable
            data={tableRows}
            columns={matrixColumns}
            options={options}
            className={classes.stickyCelledTable}
            // EXCEL DOWNLOAD INFO
            excelColumns={excelColumns}
            excelData={excelData}
          />
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {!!controlText && (
            <div style={{ width: 500 }} data-cy="matrix-popup">
              <CustomCard variant="plain" title={controlText.name}>
                {controlText.text}
              </CustomCard>
            </div>
          )}
        </Popover>
        <CustomModal open={editModal} onClose={toggleForm}>
          <PrivControlMatrixForm
            setEditModal={setEditModal}
            rowInfo={rowInfo}
            setRefresh={setRefresh}
          />
        </CustomModal>
      </div>
    );
  } else {
    return <Loader />
  }
};

export default PrivControlMatrix;
