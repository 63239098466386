import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// component imports
import loginImage from "images/login.jpg";


const useStyles = makeStyles((theme) => ({
  backgroundImage: {
    backgroundImage: "url(" + loginImage + ")",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "block",
    left: 0,
  },
}));

const BackgroundImage = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.backgroundImage}>
      {props.children}
    </div>
  );
};

export default BackgroundImage;
