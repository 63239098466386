import { useCallback, useRef, useState } from "react";
import { getLongCfrLabel } from "utils/cfrUtils";

const iconSvgInline = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="2 2 20 20" shape-rendering="crispedges">
    <path d="M5.2496 8.0688l2.83-2.8268 14.134 14.15-2.83 2.8268zM9.4857 3.8272l2.828-2.8288 5.6576 5.656-2.828 2.8288zM.9989 12.3147l2.8284-2.8284L9.484 15.143l-2.8284 2.8284zM1 21h12v2H1z">
    </path>
  </svg>
`;

const childNodeValidation = current => (
  current.childNodes.length === 0 || current.nodeType === 3
);

const CFR_ID_DATA_ATTRIBUTE = "data-section-cfr-id";
const CFR_LABEL_DATA_ATTRIBUTE = "data-section-cfr-label";

const CFR_COMMAND_UPDATE = "update";
const CFR_COMMAND_UNLINK = "unlink";
const CFR_COMMAND_DELETE = "delete";

const CHAR_ZERO_WIDTH_SPACE = "​";

const isTrimmedEqual = (string1, string2) => {
  if (!string1 || !string2) {
    return string1 === string2;
  }
  const trimmed1 = string1.replaceAll(CHAR_ZERO_WIDTH_SPACE, "").trim();
  const trimmed2 = string2.replaceAll(CHAR_ZERO_WIDTH_SPACE, "").trim();
  return trimmed1 === trimmed2;
};

export default function useCfrLinkPlugin(setIsOpenModal, setOpenModalCfrId, setOpenModalCfrText) {
  const instanceRef = useRef();

  const [hasBeenFocused, setHasBeenFocused] = useState(false);

  const handleSetModalOpen = useCallback(isOpen => {
    if (isOpen) {
      instanceRef.current.context.cfrLink.lockHighlightedButton = true;
    } else if (!isOpen) {
      instanceRef.current.context.cfrLink.highlightedButton = null;
      instanceRef.current.context.cfrLink.lockHighlightedButton = false;
    }
    setIsOpenModal(isOpen);
  }, [setIsOpenModal]);

  const createCfrElement = useCallback(
    (instanceReference, cfrId, flattenedItemsById, anchorText = null) => {
      const contextLink = instanceReference.current.context.cfrLink;
      const baseElement = (
        contextLink.highlightedButton ||
        instanceReference.current.util.createElement('a')
      );

      const cfrLabel = getLongCfrLabel(cfrId, flattenedItemsById);
      baseElement.textContent = anchorText || cfrLabel;
      baseElement.className = "se-content-cfrLink-button";
      /* eslint-disable-next-line no-script-url */
      baseElement.setAttribute("href", "javascript:void(0);");
      baseElement.setAttribute("role", "button");
      baseElement.setAttribute(CFR_ID_DATA_ATTRIBUTE, cfrId);
      baseElement.setAttribute(CFR_LABEL_DATA_ATTRIBUTE, cfrLabel);

      return baseElement;
    }, []
  );

  const afterSave = useCallback((cfrId, cfrText, flattenedItemsById) => {
    let element = null;
    const contextLink = instanceRef.current.context.cfrLink;

    const isCfrHighlighted = !!contextLink.highlightedButton;
    if (isCfrHighlighted) {
      element = createCfrElement(
        instanceRef, cfrId, flattenedItemsById, cfrText || undefined
      );

      instanceRef.current.util.changeElement(contextLink.highlightedButton, element);
      instanceRef.current.context.cfrLink.highlightedButton = element;
    } else {
      const selectedFormats = instanceRef.current.getSelectedElements();
      if (selectedFormats.length > 1) {
        element = createCfrElement(
          instanceRef, cfrId, flattenedItemsById, cfrText
        );
        const elementWrapper = instanceRef.current.util.createElement(
          selectedFormats[0].nodeName
        );
        elementWrapper.appendChild(element);
        instanceRef.current.insertNode(elementWrapper);
      } else {
        let buttonText = cfrText || undefined;
        if (!buttonText && selectedFormats.length === 1) {
          const selection = instanceRef.current.getSelection();
          const startIndex = Math.min(selection.anchorOffset, selection.focusOffset);
          const endIndex = Math.max(selection.anchorOffset, selection.focusOffset);
          if (!hasBeenFocused) {
            instanceRef.current.setRange(
              selection.anchorNode,
              endIndex,
              selection.anchorNode,
              endIndex
            );
          } else if (selection.anchorNode?.textContent) {
            buttonText = selection.anchorNode.textContent
              .slice(startIndex, endIndex)
              .replace(CHAR_ZERO_WIDTH_SPACE, "")
              .trim();
          }
        }
        element = createCfrElement(
          instanceRef, cfrId, flattenedItemsById, buttonText
        );
        instanceRef.current.insertNode(element);
      }
      contextLink.highlightedButton = null;
    }

    if (!element.textContent.startsWith(CHAR_ZERO_WIDTH_SPACE)) {
      element.textContent = `${CHAR_ZERO_WIDTH_SPACE}${element.textContent}`;
    }

    // Highlight button after creating it
    instanceRef.current.setRange(
      element.childNodes[0],
      0,
      element.childNodes[0],
      element.textContent.length
    );

    // history stack
    instanceRef.current.history.push(false);
  }, [createCfrElement, hasBeenFocused]);

  return [{
    name: 'cfrLink',
    display: 'dialog',
    title: 'Add a reference to the Code of Federal Regulations',
    innerHTML: iconSvgInline,

    // @Required
    // add function - It is called only once when the plugin is first run.
    // This function generates HTML to append and register the event.
    // arguments - (core : core object, targetElement : clicked button element)
    add(core) {

      // If you are using a module, you must register the module using the "addModule" method.
      // core.addModule([SUNEDITOR_MODULES.dialog]);

      // @Required
      // Registering a namespace for caching as a plugin name in the context object
      const link_controller = this.setController_LinkButton(core);
      const context = core.context;
      context.cfrLink = {
        focusElement: null, // This element has focus when the dialog is opened.
        targetSelect: null,
        linkAnchorText: null,
        linkController: link_controller,
        highlightedButton: null
      };

      /** add event listeners */
      link_controller.addEventListener('click', this.onClick_linkController.bind(core));

      /** append controller */
      context.element.relative.appendChild(link_controller);
    },

    // The 'controller' is the tooltip that appears when a CFR Link is highlighted
    setController_LinkButton(core) {
      const lang = core.lang;
      const icons = core.icons;
      const link_btn = core.util.createElement('div');

      link_btn.className = 'se-controller se-controller-link';
      link_btn.innerHTML = `
        <div class="se-arrow se-arrow-up"></div>
        <div class="link-content"><span><h5 class="se-controller-header"></h5></span>
          <div class="se-btn-group">
            <button type="a" data-command="${CFR_COMMAND_UPDATE}" tabindex="-1" class="se-tooltip">
              ${icons.edit}
              <span class="se-tooltip-inner">
                <span class="se-tooltip-text">
                  ${lang.controller.edit}
                </span>
              </span>
            </button>
            <button type="a" data-command="${CFR_COMMAND_UNLINK}" tabindex="-1" class="se-tooltip">
              ${icons.unlink}
              <span class="se-tooltip-inner">
                <span class="se-tooltip-text">
                  ${lang.controller.unlink}
                </span>
              </span>
            </button>
            <button type="a" data-command="${CFR_COMMAND_DELETE}" tabindex="-1" class="se-tooltip">
              ${icons.delete}
              <span class="se-tooltip-inner">
                <span class="se-tooltip-text">
                  ${lang.controller.remove}
                </span>
              </span>
            </button>
          </div>
        </div>
      `;

      return link_btn;
    },

    // @Required, @Override dialog
    // This method is called when the plugin button is clicked.
    // Open the modal window here.
    open() {
      instanceRef.current = this;

      const highlightedButton = this.context.cfrLink.highlightedButton;
      if (highlightedButton) {
        const highlightedCfrId = (
          parseInt(highlightedButton.getAttribute(CFR_ID_DATA_ATTRIBUTE), 10) ||
          null
        );
        setOpenModalCfrId(highlightedCfrId);
        const textAttribute = highlightedButton.getAttribute(CFR_LABEL_DATA_ATTRIBUTE);
        if (!isTrimmedEqual(textAttribute, highlightedButton.textContent)) {
          setOpenModalCfrText(highlightedButton.textContent);
        }
      } else {
        const selection = instanceRef.current.getSelection();
        if (selection.anchorNode) {
          const startIndex = Math.min(selection.anchorOffset, selection.focusOffset);
          const endIndex = Math.max(selection.anchorOffset, selection.focusOffset);
          const buttonText = (
            selection.anchorNode.textContent.slice(startIndex, endIndex) ||
            undefined
          );
          if (buttonText) {
            setOpenModalCfrText(buttonText);
          }
        }
      }
      handleSetModalOpen(true);
    },

    /* The button element needs to be surrounded by
     * ZeroWidthSpace characters to allow proper cursor navigation.
     *
     * If Element's ZeroWidthSpace character was deleted, restore it. */
    ensureButtonSpaceChars(activeElement) {
      const userSelection = window.getSelection();
      const buttonElement = this.plugins.cfrLink.getCfrElements(activeElement, false)[0];

      if (buttonElement) {
        if (!buttonElement.textContent.startsWith(CHAR_ZERO_WIDTH_SPACE)) {
          const cursorPosition = userSelection.anchorOffset;
          if (buttonElement.childNodes.length > 2) {
            buttonElement.innerHTML = buttonElement.textContent.replace(CHAR_ZERO_WIDTH_SPACE, "");
          }
          buttonElement.firstChild.before(CHAR_ZERO_WIDTH_SPACE);
          const isAfterNewSpace = cursorPosition === 0;
          if (isAfterNewSpace) {
            const stackIndex = this.history.stack.length - 1;
            const prevContents = this.history.stack[stackIndex - 1].contents;
            if (prevContents) {
              const currentStackContents = this.history.stack[stackIndex].contents;
              const didAddCharacters = currentStackContents.length > prevContents.length;
              if (!didAddCharacters) {
                userSelection.modify("move", "left", "character");
              }
            }
          }
        }
        const isNoSpaceBeforeButton = (
          !buttonElement.previousSibling ||
          buttonElement.previousSibling.getAttribute?.(CFR_ID_DATA_ATTRIBUTE)
        );
        const isNoSpaceAfterButton = (
          !buttonElement.nextSibling ||
          buttonElement.nextSibling.getAttribute?.(CFR_ID_DATA_ATTRIBUTE)
        );
        if (isNoSpaceBeforeButton) {
          buttonElement.before(CHAR_ZERO_WIDTH_SPACE);
        }
        if (isNoSpaceAfterButton) {
          buttonElement.after(CHAR_ZERO_WIDTH_SPACE);
        }
      }
    },

    highlightButton(element, wasHighlighted) {
      if (!wasHighlighted) {
        const startNode = this.util.getChildElement(element, childNodeValidation, false);
        const endNode = this.util.getChildElement(element, childNodeValidation, true);
        this.setRange(startNode, 0, endNode, endNode.textContent.length);
      }
    },

    getCfrElements(element, includeChildren) {
      const cfrElements = [];
      if (element) {
        if (element.getAttribute?.(CFR_ID_DATA_ATTRIBUTE)) {
          cfrElements.push(element);
        }
        if (includeChildren && element.childNodes?.length) {
          for (const child of element.childNodes) {
            cfrElements.push(...this.getCfrElements(child, includeChildren));
          }
        }
      }
      return cfrElements;
    },

    // @Override core
    // Plugins with active methods load immediately when the editor loads.
    // Called each time the selection is moved.
    active(element) {
      this.plugins.cfrLink.ensureButtonSpaceChars.call(this, element);

      setHasBeenFocused(true);

      const userSelection = window.getSelection();
      let matchingCfrElement = null;
      if (userSelection.type === "Range") {
        const cfrElements = this.plugins.cfrLink.getCfrElements(element, true);
        matchingCfrElement = cfrElements.find(cfr => (
          userSelection.containsNode(cfr, true)
        ));
      } else {
        matchingCfrElement = this.plugins.cfrLink.getCfrElements(element, false)[0];
      }

      const isControllerActive = this.controllerArray.includes(
        this.context.cfrLink.linkController
      );
      if (matchingCfrElement && !isControllerActive) {
        this.plugins.cfrLink.call_controller.call(this, matchingCfrElement);
        return true;
      } else if (!element && isControllerActive) {
        this.controllersOff();
      }

      if (!this.context.cfrLink.lockHighlightedButton) {
        this.context.cfrLink.highlightedButton = null;
      }
      return false;
    },

    // @Override dialog
    // This method is called just before the dialog opens.
    // If "update" argument is true, it is not a new call, but a call to modify an already created element.
    on(update) {
      const highlighted = this.context.cfrLink.highlightedButton;
      if (!update) {
        this.plugins.cfrLink.init.call(this);
        this.context.cfrLink.linkAnchorText.value = this.getSelection().toString();
      } else if (this.context.cfrLink.highlightedButton) {
        this.context.cfrLink.focusElement.value = highlighted.href;
        this.context.cfrLink.linkAnchorText.value = highlighted.textContent;
        this.context.cfrLink.targetSelect.value = highlighted.target || '';
      }
    },

    // @Override dialog
    // SunEditor automatically calls this
    call_controller(selectionATag) {
      this.editLink = selectionATag;
      this.context.cfrLink.highlightedButton = selectionATag;

      const linkBtn = this.context.cfrLink.linkController;
      const link = linkBtn.querySelector('h5');

      link.href = selectionATag.href;
      link.title = selectionATag.textContent;
      link.textContent = `CFR ${selectionATag.getAttribute(CFR_LABEL_DATA_ATTRIBUTE)}`;

      const offset = this.util.getOffset(selectionATag, this.context.element.wysiwygFrame);
      linkBtn.style.top = `${offset.top + selectionATag.offsetHeight + 10}px`;
      linkBtn.style.left = `${offset.left - this.context.element.wysiwygFrame.scrollLeft}px`;
      linkBtn.style.display = 'block';

      const overLeft = (
        this.context.element.wysiwygFrame.offsetWidth - (linkBtn.offsetLeft + linkBtn.offsetWidth)
      );
      if (overLeft < 0) {
        linkBtn.style.left = `${linkBtn.offsetLeft + overLeft}px`;
        linkBtn.firstElementChild.style.left = `${20 - overLeft}px`;
      } else {
        linkBtn.firstElementChild.style.left = '20px';
      }

      // Show controller at editor area
      // (controller elements, function, "controller target element(@Required)", "controller name(@Required)", etc..)
      this.controllersOn(linkBtn, selectionATag, 'cfrLink');
    },

    onClick_linkController(event) {
      event.stopPropagation();

      const command = event.target.getAttribute('data-command');
      if (!command) {
        return;
      }

      event.preventDefault();

      if (!instanceRef.current) {
        instanceRef.current = this;
      }

      const highlighted = this.context.cfrLink.highlightedButton;

      if (command === CFR_COMMAND_UPDATE) {
        const cfrId = highlighted.getAttribute(CFR_ID_DATA_ATTRIBUTE);
        setOpenModalCfrId(cfrId);
        const defaultText = highlighted.getAttribute(CFR_LABEL_DATA_ATTRIBUTE)?.trim?.();
        if (!isTrimmedEqual(defaultText, highlighted.textContent)) {
          setOpenModalCfrText(highlighted.textContent);
        }
        handleSetModalOpen(true);
      } else if (command === CFR_COMMAND_UNLINK) {
        this.util.changeTxt(highlighted, ` ${highlighted.textContent} `);
        const startNode = this.util.getChildElement(highlighted, childNodeValidation, false);
        const endNode = this.util.getChildElement(highlighted, childNodeValidation, true);
        this.setRange(startNode, 0, endNode, endNode.textContent.length);
        this.nodeChange(null, null, ['a'], false);
        this.context.cfrLink.highlightedButton = null;
      } else {
        /** delete */
        this.util.removeItem(highlighted);
        this.context.cfrLink.highlightedButton = null;
        this.focus();

        // history stack
        this.history.push(false);
      }

      this.controllersOff();
    },

    // @Required, @Override dialog
    // This method is called when the dialog window is closed.
    // Initialize the properties.
    init() {
      const contextLink = this.context.cfrLink;
      contextLink.linkController.style.display = "none";
      contextLink.highlightedButton = null;
      contextLink.focusElement.value = "";
      contextLink.linkAnchorText.value = "";
      contextLink.lockHighlightedButton = false;
      contextLink.targetSelect.selectedIndex = 0;
    }
  }, afterSave, handleSetModalOpen];
}
