import React from "react";
// import { useEffect } from "react";
// import { Grid, Link } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
// import { registry as Services } from "../../../services/registry.service";


const TableType = () => {

    // useEffect(() => {
    //     //IMP TOOLS DataScope
    //     Services["implementationGetGroupProducts"](1)
    //     .then((res) => console.log("IMP PRODUCTS >",res.payload))
    //     Services["implementationGetImplementationDataScope"](1)
    //     .then((res) => console.log("DATA SCOPE >",res.payload))
    //     Services["implementationGetImplementationSubProducts"]()
    //     .then((res) => console.log("SUB PRODUCTS >",res.payload))
    //     //IMP TOOLS mgmt Princ
    //     Services["implementationGetManagementPrinciples"](1)
    //     .then((res) => console.log("MGMT PRINC>",res.payload))
    // }, [])

  return (
    <div>
      TABLE CONTENT
    </div>
  )
}

export default TableType;
