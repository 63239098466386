import { makeStyles } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import classNames from "classnames";
import CustomLink from "components/utils/link.component";
import moment from "moment";
import { useCallback } from "react";
import { dataCollectionStatus, assessBuilderStatus } from "utils/assessmentConstants";
import variables from "styleVariables";

const useStyles = makeStyles(theme => ({
  cellCta: {
    minHeight: 35,
    padding: "4px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  cellCtaTop: {
    fontWeight: 600,
    whiteSpace: "nowrap",
  },
  cellCenter: {
    textAlign: "center"
  },
  startIcon: {
    height: 12,
    width: 12,
    marginRight: 2,
    verticalAlign: "-1px",
  },
  performanceLink: {
    color: variables.secondaryMain,
    "&:hover": {
      color: variables.secondaryDark,
    },
  },
  linkWrapper: {
    padding: 4,
    "&+ $linkWrapper": {
      marginTop: 4
    }
  },
}));


export default function PerformanceStatusCell(props) {
  const { row, setReviewPageMode } = props;
  const classes = useStyles();
  const disabledVariant = (props.builderInfo.Status === assessBuilderStatus.BUILDER_STATUS_ASSESSED || props.builderInfo.Status === assessBuilderStatus.BUILDER_STATUS_RESPONDED);

  const renderLink = useCallback((text, id) => (
    <CustomLink
      variant="customUnderline"
      className={classes.performanceLink}
      onClick={() => setReviewPageMode(
        "performanceDataCollection",
        row.assessmentItem.AssessmentItem_ID,
        id
      )}
      ignoreReadOnly={!!id}
    >
      {text}
    </CustomLink>
  ), [row, setReviewPageMode, classes.performanceLink]);

  const performanceList = row.performanceItems ? [].concat(row.performanceItems) : [];
  if (!performanceList.length) {
    return (
      <div className={classNames(classes.cellCta, classes.cellCenter)}>
        {!disabledVariant && renderLink("Start")}
      </div>
    );
  }

  const inProgressItems = performanceList.filter(item => item.Status === dataCollectionStatus.STATUS_IN_PROGRESS);
  const completedItems = performanceList.filter(item => item.Status === dataCollectionStatus.STATUS_COMPLETED);

  return (
    <div className={classNames(classes.cellCta, classes.cellCenter)}>
      {!!completedItems?.length && (
        <div className={classes.linkWrapper} data-cy="completed-items">
          <div className={classNames(classes.cellCtaTop)}>
            {dataCollectionStatus.STATUS_COMPLETED}
          </div>
          {completedItems.map(item => (
            <div key={`performance-item-completed-${item.Performance_ID}`}>
              {renderLink(
                moment(item.Observation_Date).format("MM/DD/YYYY"),
                item.Performance_ID
              )}
            </div>
          ))}
        </div>
      )}
      {!!inProgressItems?.length && (
        <div className={classes.linkWrapper} data-cy="in-progress-items">
          <div className={classNames(classes.cellCtaTop)}>
            {dataCollectionStatus.STATUS_IN_PROGRESS}
          </div>
          {inProgressItems.map(item => (
            <div key={`performance-item-in-progress-${item.Performance_ID}`}>
              {renderLink(
                moment(item.Observation_Date).format("MM/DD/YYYY"),
                item.Performance_ID
              )}
            </div>
          ))}
        </div>
      )}
      {!disabledVariant && (
        <div className={classNames(classes.linkWrapper)}>
          {renderLink(
            !completedItems.length && !inProgressItems.length ?
              "Start" : (
                <>
                  <AddCircleOutline className={classNames(classes.startIcon)} />
                  New
                </>
              )
          )}
        </div>
      )}
    </div>
  );
}