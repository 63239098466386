import moment from "moment";
import { Box, makeStyles, Typography, MenuItem } from "@material-ui/core";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import SessionParticipantsForm from "./sessionParticipantsForm.component";
import SessionFollowUpForm from "./sessionFollowUpForm.component";
import SessionDocumentsPanel from "./sessionDocumentsPanel.component";
import SessionNotesTable from "./sessionNotesTable.component";
import { useEffect, useMemo, useCallback } from "react";
import { currentUserID } from "utils/userHelpers";
import useNumericParams from "hooks/useNumericParams";
import { ACTION_RESET_ASSESSMENT_ITEMS, ACTION_SET_ASSESSMENT_ITEMS } from "reducers/portfolioAssessments.reducer";
import PortfolioService from "services/portfolio.service";
import {
  generatePath,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import Header from "components/utils/header.component";
import styleVariables from "styleVariables";
import CustomSelect from "components/utils/form-elements/select.component";
import RadioInputGroup from "components/utils/form-elements/radioInputGroup.component";
import { sessionTypes } from "utils/portfolioConstants";
import Loader from "components/utils/loader.components";
import PortCoLogoHeader from "components/portfolio/shared/portCoLogoHeader.component";

const useStyles = makeStyles((theme) => ({
  tableColumn: {
    [theme.breakpoints.only("md")]: {
      marginBottom: 32
    },
    [theme.breakpoints.down("md")]: {
      order: 2,
      width: "100%"
    },
  },
  headerWrapper: {
    display: "flex",
    alignItems: "end",
    justifyContent: "space-between",
    paddingBottom: 15,
    marginTop: 16,
  },
  filterRadioGroup: {
    marginBottom: -3,
    "& label": {
      margin: 0,
    },
    "& .MuiFormControlLabel-label": {
      fontSize: styleVariables.fontH5,
    },
  },
  sessionDropdown: {
    width: 300,
    maxWidth: "100%",
    marginTop: 32,
  },
  sessionSpecifier: {
    fontSize: styleVariables.fontMedium,
  },
  sessionColumn: {
    marginTop: 32,
    [theme.breakpoints.down("md")]: {
      order: 1,
    },
  },
}));

export default function SessionDataCollectionPage(props) {
  const classes = useStyles();
  const history = useHistory();

  const { dispatch, isReadOnly, noteFilter = "self", setNoteFilter, state } = props;
  const {
    assessmentItems,
    portfolioUsers,
    tableFilter,
    assessments,
    portfolioCompanies,
    documents
  } = state;

  const params = useNumericParams();
  const { assessmentId, sessionId, holdingId, portCoId } = params;

  useEffect(
    () =>
      (async function loadGlobalState() {
        dispatch({ type: ACTION_RESET_ASSESSMENT_ITEMS });
        const [assessmentItemsResponse] = await Promise.all([
          PortfolioService.getAssessmentItems(sessionId),
        ]);
        dispatch({
          type: ACTION_SET_ASSESSMENT_ITEMS,
          payload: assessmentItemsResponse.payload || []
        });
      })(),
    [dispatch, sessionId, holdingId, portCoId]
  );

  const assessment = useMemo(() => {
    const currentAssessment = assessments?.find?.(assessmentItem => assessmentItem.HolderAssessment_ID === assessmentId);
    if (!currentAssessment) {
      history.push("/notFound");
    } else {
      return currentAssessment;
    }
  }, [assessmentId, assessments, history]);

  const assessmentStatus = useMemo(() => {
    const currentAssessmentStatus = assessment?._associations?.AssessmentStatus?.find?.(status => status.HolderAssessment_ID === assessmentId && status.PortCo_ID === portCoId);
    if (!currentAssessmentStatus) {
      history.push("/notFound");
    } else {
      return currentAssessmentStatus;
    }
  }, [assessment, portCoId, assessmentId, history]);

  const session = useMemo(() => {
    const currentSession = assessmentStatus?._associations?.AssessmentSession?.find(sessionItem => sessionItem.Session_ID === sessionId);
    if (!currentSession) {
      history.push("/notFound");
    } else {
      return currentSession;
    }
  }, [assessmentStatus, sessionId, history]);

  const portfolioCompany = useMemo(() => {
    const currentPortfolioCompany = portfolioCompanies.find(portfolioCompanyItem => portfolioCompanyItem.PortCo_ID === portCoId && assessmentStatus?.PortCo_ID === portCoId);
    if (!currentPortfolioCompany) {
      history.push("/notFound");
    } else {
      return currentPortfolioCompany;
    }
  }, [portfolioCompanies, portCoId, assessmentStatus, history]);

  const {
    SessionAttendees: attendees,
    SessionFollowUps: followUps,
    SessionNotes: notes,
  } = session?._associations || {};

  const sessionDocuments = documents?.filter((document) => document.Session_ID === sessionId)

  const userId = useMemo(() => currentUserID(), []);

  const filteredNotes = useMemo(() => {
    if (tableFilter !== "user") {
      return notes;
    }
    return notes.filter((note) => note.AssessorUser_ID === userId);
  }, [notes, tableFilter, userId]);

  const filteredAssessmentItems = useMemo(() => {
    if (tableFilter !== "user") {
      return assessmentItems;
    }
    const userFilteredNoteIds = new Set(
      filteredNotes.map((note) => note.SessionNote_ID)
    );
    return assessmentItems.filter((item) =>
      userFilteredNoteIds.has(item.Session_ID)
    );
  }, [assessmentItems, filteredNotes, tableFilter]);

  const goToSession = useCallback(destinationSessionId => {
    history.push(
      generatePath(
        "/program/:programId/:programComponentId/holding/:holdingId/portCo/:portCoId/assessment/:assessmentId/session/:sessionId",
        { ...params, sessionId: destinationSessionId }
      )
    );
  }, [history, params]);

  return (
    <Box paddingBottom={4} paddingTop={4} className={classes.assessmentsWrapper}>
      <PortCoLogoHeader portCo={portfolioCompany} title="Session Notes" />
      <div className={classes.sessionDropdown}>
        <CustomSelect
          label="Assessment Session"
          value={session?.Session_ID}
          onChange={event => goToSession(event.target.value)}
        >
          {assessmentStatus?._associations?.AssessmentSession
            ?.filter(otherSession => otherSession.Type !== sessionTypes.RECONCILE)
            ?.map?.(otherSession => (
              <MenuItem
                key={otherSession.Session_ID}
                value={otherSession.Session_ID}
              >
                {moment(otherSession.Start).format("MM/DD/YYYY hh:mma")}
                  &nbsp;-&nbsp;
                {moment(otherSession.End).format("hh:mma")}
              </MenuItem>
            ))}
        </CustomSelect>
      </div>
      <GridContainer spacing={8}>
        <GridItem className={classes.tableColumn} xs={12} lg={8}>
          <div className={classes.headerWrapper}>
            <div>
              <Header variant="h3Primary">{assessment?.Assessment_Name}</Header>
              <Typography variant="body1" className={classes.sessionSpecifier}>
                {session?.Description}
              </Typography>
            </div>
            {!isReadOnly && (
              <RadioInputGroup
                value={noteFilter}
                groupLabel="All Participant Notes:"
                options={[
                  { value: "self", label: "Hide" },
                  { value: "all", label: "Show" }
                ]}
                onChange={event => setNoteFilter(event.target.value)}
                radioGroupClass={classes.filterRadioGroup}
                hideHelperText
                row
              />
            )}
          </div>
          {session ? (
            <SessionNotesTable
              assessmentItems={filteredAssessmentItems}
              dispatch={dispatch}
              isReadOnly={isReadOnly}
              session={session}
              noteFilter={noteFilter}
            />
          ) : (
            <Loader height="50vh" />
          )}
        </GridItem>
        <GridItem className={classes.sessionColumn} xs={12} lg={4}>
          <SessionParticipantsForm
            attendees={attendees}
            dispatch={dispatch}
            isReadOnly={isReadOnly}
            session={session}
            users={portfolioUsers}
          />
          <SessionFollowUpForm
            dispatch={dispatch}
            followUps={followUps}
            isReadOnly={isReadOnly}
            session={session}
          />
          <SessionDocumentsPanel
            dispatch={dispatch}
            documents={sessionDocuments}
            isReadOnly={isReadOnly}
            assessmentStatus={assessmentStatus}
            portfolioCompany={portfolioCompany}
            session={session}
          />
        </GridItem>
      </GridContainer>
    </Box>
  );
}
