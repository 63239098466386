import { useState, useEffect } from "react";
import { useLocation } from "react-router";
/**
 * Scrolls to and returns target id + clear fucntion
 * Use this custom hook when targeting an element via a link to highlight
 **/

const useTargetTableRowViaLink = (rowRef , readyToScroll = false) => {
  const [targetedRowId, setTargetedRowId] = useState();
  const location = useLocation();

  const clearTargetedRow = () => {
    setTargetedRowId();
  };

  // upon load, scrolls to specified rows
  useEffect(() => {
    if (location.state?.itemId) {
      setTargetedRowId(location.state.itemId);
      if (rowRef && rowRef.current[location.state.itemId] && readyToScroll) {
        rowRef.current[location.state.itemId].scrollIntoView({
          block: "center",
        });
      }
    }
  }, [location.state, readyToScroll, rowRef]);

  return [targetedRowId, clearTargetedRow];
};

export default useTargetTableRowViaLink;
