import { AppBar, Box, Dialog, makeStyles, RadioGroup } from "@material-ui/core";
import classNames from "classnames";
import { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import Checkbox from "components/utils/form-elements/checkbox.component";
import RadioInputGroup from "components/utils/form-elements/radioInputGroup.component";
import Header from "components/utils/header.component";
import CustomLink from "components/utils/link.component";
import Loader from "components/utils/loader.components";
import { CFR_TYPE_PARAGRAPH, CFR_TYPE_PART, CFR_TYPE_SECTION, CFR_TYPE_SUBPART } from "utils/cfrConstants";
import { filterCfrByType, getCfrContent, getCfrLabel, getParentCfrItems } from "utils/cfrUtils";
import Form from "components/utils/form-elements/form.component";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import RadioInput from "components/utils/form-elements/radioInput.component";


const DEFER_OPEN_RENDER_ITEM_COUNT = 50;
const DEFER_RADIO_RENDER_ITEM_COUNT = 200;

const CHECKBOX_INPUT_NAME_PREFIX = "cfr-";
const RADIO_INPUT_NAME = "cfr-radio-item";

const useStyles = makeStyles((theme) => ({
  checkboxRoot: {
    alignItems: "start"
  },
  checkboxLabel: {
    display: "inline-flex",
    alignItems: "center",
    minHeight: 24,
    marginTop: 8,
    marginBottom: 8,
    fontSize: "0.8rem"
  },
  form: {
    height: "100%"
  },
  modalHeader: {
    paddingTop: 16,
    paddingRight: 32,
    paddingLeft: 32,
  },
  modalPaper: {
    height: "100%"
  },
  modalSubmitBar: {
    top: "auto",
    bottom: 0
  },
  radioLabel: {
    marginRight: 40
  },
  visibleSection: {
    textDecoration: "underline"
  },
}));

const countCfrSections = cfrItem => {
  if (cfrItem.CFR_Type === CFR_TYPE_SECTION || !cfrItem._associations?.CFR?.length) {
    return 1;
  }
  return cfrItem._associations.CFR.reduce((sum, child) => (
    sum + countCfrSections(child)
  ), 1);
};

const hasAnyChildChecked = (cfrItem, checkedCfrIds) => {
  const hasCheckedLevel = item => (
    checkedCfrIds.includes(item.CFR_ID) ||
    item._associations?.CFR?.some?.(hasCheckedLevel)
  );
  return hasCheckedLevel(cfrItem);
};

const hasAnyChecked = (cfrItems, checkedCfrIds) => (
  cfrItems.some(item => (
    checkedCfrIds.includes(item.CFR_ID) ||
    hasAnyChildChecked(item, checkedCfrIds)
  ))
)

export default function CfrReferenceModal(props) {
  const classes = useStyles();
  const {
    checkedCfrIds,
    children,
    defaultActiveCfrItem,
    isRadiosMode,
    onClose,
    onChange,
    onSubmit,
    open,
    state
  } = props;

  const [activeCfrPartId, setActiveCfrPartId] = useState(null);
  const [isRenderQueued, setIsRenderQueued] = useState(null);
  const [selectedCfrRadioId, setSelectedCfrRadioId] = useState(null);

  useEffect(() => {
    if (isRadiosMode && !open) {
      setSelectedCfrRadioId(parseInt(checkedCfrIds[0], 10));
    }
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isRadiosMode, open, setSelectedCfrRadioId]);

  const isAddSingleCfr = useMemo(() => (
    isRadiosMode && !!defaultActiveCfrItem
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  ), [isRadiosMode, open]);

  // Array of CFR Items from lowest level (ex. Paragraph) to highest (ex. Part)
  const initialCfrItemTree = useMemo(() => (
    defaultActiveCfrItem ?
      getParentCfrItems(defaultActiveCfrItem, state.cfrItemsByIdFlattened) :
      [].concat(state.cfrItems?.[0])
  ), [defaultActiveCfrItem, state.cfrItems, state.cfrItemsByIdFlattened]);

  const partChildrenCounts = useMemo(() => {
    return state.cfrItems.reduce((accumulator, part) => ({
      ...accumulator,
      [part.CFR_ID]: countCfrSections(part)
    }));
  }, [state.cfrItems]);

  const setActiveCfrPartIdOptimized = useCallback((partId, deferItemCount) => {
    const shouldDeferRender = partChildrenCounts[partId] > deferItemCount;
    if (shouldDeferRender) {
      setIsRenderQueued(true);
      setActiveCfrPartId(partId);
      setTimeout(() => {
        setIsRenderQueued(false);
      }, 0);
    } else {
      setActiveCfrPartId(partId);
    }
  }, [partChildrenCounts, setActiveCfrPartId, setIsRenderQueued]);

  useLayoutEffect(function applyInitialCfrItemTree() {
    const cfrPart = initialCfrItemTree.find(item => (
      item.CFR_Type === CFR_TYPE_PART
    ));
    if (!cfrPart) {
      return;
    }
    setActiveCfrPartIdOptimized(cfrPart.CFR_ID, DEFER_OPEN_RENDER_ITEM_COUNT);
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [open]);

  const activeCfrItem = useMemo(() => (
    activeCfrPartId &&
    state.cfrItems?.find?.(item => item.CFR_ID === activeCfrPartId)
  ), [activeCfrPartId, state.cfrItems]);

  const activeCfrSubparts = useMemo(() => (
    filterCfrByType(activeCfrItem?._associations?.CFR, CFR_TYPE_SUBPART) || []
  ), [activeCfrItem?._associations?.CFR]);

  const isItemChecked = useMemo(() => (
    checkedCfrIds.includes(activeCfrItem?.CFR_ID)
  ), [activeCfrItem, checkedCfrIds]);

  const handlePartChange = useCallback(event => {
    setActiveCfrPartIdOptimized(
      parseInt(event.target.value, 10),
      DEFER_RADIO_RENDER_ITEM_COUNT
    );
  }, [setActiveCfrPartIdOptimized]);

  const handleRadioChange = useCallback(event => {
    if (onChange) {
      onChange(event, parseInt(event.target.value, 10));
    }
    if (event.target.checked) {
      setSelectedCfrRadioId(parseInt(event.target.value, 10));
    } else {
      setSelectedCfrRadioId(null);
    }
  }, [onChange, setSelectedCfrRadioId]);

  const handleSubmit = useCallback((data, formData, event) => {
    if (!onSubmit) {
      return;
    }
    const selectedIds = isRadiosMode ? (
      [data[RADIO_INPUT_NAME]]
    ) : (
      Object.keys(data).filter(checkboxName => (
        checkboxName.indexOf(CHECKBOX_INPUT_NAME_PREFIX) > -1
      )).map(checkboxName => (
        checkboxName.replace(new RegExp(`${CHECKBOX_INPUT_NAME_PREFIX}(\\d+)`), "$1")
      ))
    );
    return onSubmit(selectedIds.map(id => parseInt(id, 10)), data, event);
  }, [isRadiosMode, onSubmit]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        className: classes.modalPaper
      }}
    >
      <Box height="100%">
        {!state.cfrItems ? (
          <Loader />
        ) : (
          <Form name="cfr-references" onSubmit={handleSubmit} className={classes.form}>
            <Box display="flex" flexDirection="column" height="100%">
              <AppBar
                color="transparent"
                position="sticky"
                className={classes.modalHeader}
              >
                <Header variant="h3Primary">
                  {isAddSingleCfr ? "Manage" : "Add"}
                  &nbsp;CFR Reference&nbsp;
                  {isRadiosMode ? "Link" : "Links"}
                </Header>
                {!!children && (
                  <Box marginTop={1}>
                    {children}
                  </Box>
                )}
                <Box display="flex" justifyContent="space-between" marginTop={1}>
                  <RadioInputGroup
                    name="part"
                    options={state.cfrItems.map(part => ({
                      label: `Part ${getCfrLabel(part)}`,
                      value: part.CFR_ID
                    }))}
                    value={activeCfrPartId}
                    radioLabelClass={classes.radioLabel}
                    onChange={handlePartChange}
                    row
                  />
                </Box>
              </AppBar>
              {isRenderQueued ? (
                <Loader />
              ) : (
                <Box flexShrink="1" overflow="auto" padding="24px 32px" height="100%">
                  <FormInputsWrapper
                    defaultValue={defaultActiveCfrItem?.CFR_ID}
                    isRadiosMode={isRadiosMode}
                    onChange={handleRadioChange}
                    selectedCfrRadioId={selectedCfrRadioId}
                  >
                    {!!activeCfrItem && (
                      <CfrCategory
                        cfrItem={activeCfrItem}
                        checkedCfrIds={checkedCfrIds}
                        initialCfrItemTree={initialCfrItemTree}
                        isRadiosMode={isRadiosMode}
                        onChange={onChange}
                        state={state}
                      />
                    )}
                    {!!activeCfrSubparts.length && (
                      <Box marginTop={2}>
                        {activeCfrSubparts.map(subpart => (
                          <CfrCategory
                            cfrItem={subpart}
                            checkedCfrIds={checkedCfrIds}
                            initialCfrItemTree={initialCfrItemTree}
                            isParentChecked={isItemChecked}
                            isRadiosMode={isRadiosMode}
                            onChange={onChange}
                            state={state}
                            key={subpart.CFR_ID}
                          />
                        ))}
                      </Box>
                    )}
                  </FormInputsWrapper>
                </Box>
              )}
              {!!onSubmit && (
                <AppBar
                  color="transparent"
                  position="sticky"
                  className={classes.modalSubmitBar}
                >
                  <DualFormButtons
                    cancelOnClick={onClose}
                    disabled={!selectedCfrRadioId}
                  />
                </AppBar>
              )}
            </Box>
          </Form>
        )}
      </Box>
    </Dialog>
  )
}

// Distant parent element to input components, if applicable to input type
function FormInputsWrapper(props) {
  const {
    children, defaultValue, isRadiosMode, onChange, selectedCfrRadioId
  } = props;
  if (!isRadiosMode) {
    return children;
  }
  return (
    <RadioGroup
      defaultValue={defaultValue}
      name={RADIO_INPUT_NAME}
      value={selectedCfrRadioId}
      onChange={onChange}
    >
      {children}
    </RadioGroup>
  );
}

function FormInput(props) {
  const { cfrItem, isRadiosMode, label, onChange, ...childProps } = props;
  if (isRadiosMode) {
    return (
      <RadioInput
        {...childProps}
        label={label}
        value={cfrItem.CFR_ID}
        checked={undefined}
        onChange={undefined}
      />
    );
  }
  return (
    <Checkbox
      {...childProps}
      name={`${CHECKBOX_INPUT_NAME_PREFIX}${cfrItem.CFR_ID}`}
      label={label}
      value={cfrItem.CFR_ID}
      onChange={event => onChange?.(event, cfrItem.CFR_ID)}
    />
  );
}


function CfrCategory(props) {
  const {
    cfrItem /* Part or Subpart */,
    checkedCfrIds,
    initialCfrItemTree,
    isParentChecked,
    isRadiosMode,
    onChange,
  } = props;

  const cfrSections = useMemo(() => (
    filterCfrByType(cfrItem._associations.CFR, CFR_TYPE_SECTION) || []
  ), [cfrItem._associations.CFR]);

  const isTypePart = useMemo(() => cfrItem.CFR_Type === CFR_TYPE_PART, [cfrItem.CFR_Type]);

  const initialCfrSection = useMemo(() => (
    initialCfrItemTree?.find?.(item =>
      item.CFR_Type === CFR_TYPE_SECTION
    )
  ), [initialCfrItemTree]);

  const headerText = useMemo(() => {
    let text = `${cfrItem.CFR_Type} `;
    text += `${getCfrLabel(cfrItem)} `;
    text += getCfrContent(cfrItem);
    return text;
  }, [cfrItem]);

  const isItemChecked = useMemo(() => (
    isParentChecked || checkedCfrIds.includes(cfrItem.CFR_ID)
  ), [cfrItem, checkedCfrIds, isParentChecked]);

  const isAnySectionChecked = useMemo(() => (
    hasAnyChecked(cfrSections, checkedCfrIds)
  ), [cfrSections, checkedCfrIds]);

  return (
    <div>
      <FormInput
        cfrItem={cfrItem}
        label={(
          <Header variant={isTypePart ? "h4Tertiary" : "h5Tertiary"} noTransform>
            {headerText}
          </Header>
        )}
        checked={isItemChecked || isParentChecked}
        indeterminate={!isItemChecked && !!isAnySectionChecked && !isParentChecked}
        disabled={isParentChecked}
        isRadiosMode={isRadiosMode}
        onChange={onChange}
      />
      {cfrSections.map(cfrSection => (
        <CfrSection
          checkedCfrIds={checkedCfrIds}
          cfrSection={cfrSection}
          initialCfrSection={initialCfrSection}
          isParentChecked={isItemChecked}
          isRadiosMode={isRadiosMode}
          onChange={onChange}
          key={cfrSection.CFR_ID}
        />
      ))}
    </div>
  )
}

const CfrSection = memo(CfrSectionUnmemoized);

function CfrSectionUnmemoized(props) {
  const classes = useStyles();
  const {
    checkedCfrIds, cfrSection, initialCfrSection,
    isParentChecked, isRadiosMode, onChange
  } = props;

  const sectionRef = useRef();

  const [isExpanded, setIsExpanded] = useState(null);

  useLayoutEffect(function applyInitialCfrItemAndScroll() {
    if (initialCfrSection && initialCfrSection.CFR_ID === cfrSection.CFR_ID) {
      setIsExpanded(true);
      // If visible id after mount (useLayoutEffect?), scroll to relevant ref
      if (sectionRef.current) {
        sectionRef.current.scrollIntoView();
      } else {
        setTimeout(() => {
          if (sectionRef.current) {
            sectionRef.current.scrollIntoView();
          }
        }, 0);
      }
    }
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const cfrParagraphs = useMemo(() => (
    filterCfrByType(
      cfrSection._associations.CFR,
      CFR_TYPE_PARAGRAPH
    )
  ), [cfrSection._associations.CFR]);

  const isSectionChecked = useMemo(() => (
    checkedCfrIds.includes(cfrSection.CFR_ID)
  ), [cfrSection, checkedCfrIds]);

  const isAnyChildChecked = useMemo(() => (
    hasAnyChildChecked(cfrSection, checkedCfrIds)
  ), [cfrSection, checkedCfrIds]);

  const handleToggleExpanded = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded, setIsExpanded]);

  return (
    <div
      ref={element => {
        sectionRef.current = element;
      }}
    >
      <Box
        display="flex"
        marginLeft={!!cfrParagraphs?.length && !isRadiosMode ? 0.5 : 1.875}
        className={classNames(isExpanded && classes.visibleSection)}
      >
        <FormInput
          cfrItem={cfrSection}
          name={`section-${cfrSection.CFR_ID}`}
          label={!cfrParagraphs?.length && (
            <div className={classes.checkboxLabel}>
              §{getCfrLabel(cfrSection)} {getCfrContent(cfrSection)}
            </div>
          )}
          checked={isSectionChecked || isParentChecked}
          indeterminate={!isSectionChecked && !!isAnyChildChecked && !isParentChecked}
          disabled={isParentChecked}
          isRadiosMode={isRadiosMode}
          rootClass={classes.checkboxRoot}
          onChange={onChange}
        />
        {/* <Checkbox
          name={`section-${cfrSection.CFR_ID}`}
          indeterminate={!isSectionChecked && isAnyChildChecked && !isParentChecked}
          disabled={isParentChecked}
          value={cfrSection.CFR_ID}
          rootClass={classes.checkboxRoot}
          onChange={event => onChange(event, cfrSection.CFR_ID)}
        /> */}
        {!!cfrParagraphs?.length && (
          <CustomLink
            className={classes.checkboxLabel}
            variant="noHRef"
            onClick={handleToggleExpanded}
          >
            §{getCfrLabel(cfrSection)} {getCfrContent(cfrSection)}
          </CustomLink>
        )}
      </Box>
      {!!isExpanded && !!cfrParagraphs?.length && (
        <Box marginBottom={1} marginLeft={4}>
          {cfrParagraphs?.map?.(cfrParagraph => (
            <CFRParagraph
              checkedCfrIds={checkedCfrIds}
              isParentChecked={isSectionChecked || isParentChecked}
              isRadiosMode={isRadiosMode}
              onChange={onChange}
              paragraph={cfrParagraph}
              key={cfrParagraph.CFR_ID}
            />
          ))}
        </Box>
      )}
    </div>
  );
}

function CFRParagraph(props) {
  const classes = useStyles();
  const {
    checkedCfrIds, isParentChecked, isRadiosMode, onChange, paragraph
  } = props;

  const isChecked = useMemo(() => (
    isParentChecked || checkedCfrIds.includes(paragraph.CFR_ID)
  ), [checkedCfrIds, isParentChecked, paragraph.CFR_ID]);

  const isAnyChildChecked = useMemo(() => (
    hasAnyChildChecked(paragraph, checkedCfrIds)
  ), [paragraph, checkedCfrIds]);

  return (
    <Box
      display="flex"
      alignItems="start"
      key={paragraph.CFR_ID}
    >
      <div>
        <FormInput
          name={`paragraph-${paragraph.CFR_ID}`}
          label={(
            <div className={classes.checkboxLabel}>
              {getCfrLabel(paragraph)} {getCfrContent(paragraph)}
            </div>
          )}
          checked={isChecked}
          indeterminate={!isChecked && isAnyChildChecked}
          disabled={isParentChecked}
          cfrItem={paragraph}
          isRadiosMode={isRadiosMode}
          rootClass={classes.checkboxRoot}
          onChange={onChange}
        />
        <Box marginLeft={4}>
          {!!paragraph._associations?.CFR?.length &&
            paragraph._associations.CFR.map(cfrParagraph => (
              <CFRParagraph
                checkedCfrIds={checkedCfrIds}
                isParentChecked={isChecked}
                isRadiosMode={isRadiosMode}
                onChange={onChange}
                paragraph={cfrParagraph}
                key={cfrParagraph.CFR_ID}
              />
            ))}
        </Box>
      </div>
    </Box>
  )
}
