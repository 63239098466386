import React, { useState, useContext, useMemo, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { MenuItem, Grid } from "@material-ui/core";
import CustomSelect from "components/utils/form-elements/select.component";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import ProgramService from "services/program.service";
import FormBanner from "components/utils/form-elements/formBanner.component";
import Checkbox from "components/utils/form-elements/checkbox.component";
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import { COMPONENT_OBJECT_TYPE_BUILDER, PROGRAM_ID_ORCHESTRATION, PROGRAM_ID_PORTFOLIO, PROGRAM_ID_RUNBOOK } from "utils/programConstants";
import classNames from "classnames";
import ProgramsContext from "contexts/programs.context";
import { ACTION_PARTIAL_REPLACE_COMPONENT_OBJECTS } from "reducers/global/program.reducer";
import { findProgramBuilderComponent } from "utils/programUtils";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: 500,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: 20,
  },
  contentContainer: {
    padding: "30px 20px 0px 20px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  selectsContainer: {
    paddingTop: 10,
    alignSelf: "center",
    width: 300,
    overflowX: "hidden",
  },
  checkContainer: {
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
  },
  checkWrapper: {
    width: "fit-content",
    margin: "0px auto",
    paddingBottom: 20,
  },
  select: {
    marginBottom: 25,
    width: "100%",
  },
  continueIcon: {
    fill: "white",
    fontSize: "16px",
    marginRight: 8,
  }
}));

const excludedPrograms = [
  PROGRAM_ID_ORCHESTRATION,
  PROGRAM_ID_PORTFOLIO,
  PROGRAM_ID_RUNBOOK,
];

const isEligibleProgram = (program) => {
  return (
    program.Status === "Active" &&
    !excludedPrograms.includes(program.Program_ID)
  );
};

const OrchestrationBuilderForm = (props) => {
  const classes = useStyles();
  const { setChosenProgram } = props;
  const { state, dispatch } = useContext(ProgramsContext);
  const [mode, setMode] = useState("enterprise"); //"programLevel"
  const [errorMessage, setErrorMessage] = useState();
  const { componentObjectId } = useParams();

  const displayName = useMemo(() => (
    props.name?.replace?.(/s$/, "")
  ), [props.name]);

  const chosenProgramName = useMemo(() => {
    setErrorMessage();
    return state.programs[props.chosenProgram.id]?.Name
  }, [state.programs, props.chosenProgram.id])

  const displayNameAndArticle = useMemo(() => (
    displayName.match(/^[aeiou]/i) ? `an ${displayName}` : `a ${displayName}`
  ), [displayName]);

  const charterBuilderProgramSet = useMemo(() => (
    new Set(
      props.name !== "Charter" ?
        [] :
        props.builderInfo.map(builder => builder.Program_ID)
    )
  ), [props.builderInfo, props.name]);

  const eligiblePrograms = useMemo(() => (
    Object.values(state.programs).filter(program => (
      isEligibleProgram(program) &&
      !charterBuilderProgramSet.has(program.Program_ID)
    ))
  ), [state.programs, charterBuilderProgramSet]);

  const handleCheck = useCallback((event) => {
    setErrorMessage();
    if (event.target.checked === true) {
      setMode(event.target.name);
    } else {
      setMode("none");
    }
    setChosenProgram({ id: "", name: "" });
  }, [setChosenProgram]);

  const handleChange = (event) => {
    setChosenProgram({ id: event.target.value, name: event.currentTarget.id });
  };

  // need to call addNewSection function from Builder
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (mode === "enterprise") {
      props.setMode("loadTemplate", null);
      props.setEditModal(false);
      props.setChosenProgram({
        id: 8,
        name: "Enterprise Level",
        compObjId: componentObjectId,
      });
    } else {
      let typeRef = "";
      if (props.name === "Assessments") {
        typeRef = "Assessment";
      } else if (["Plan", "Charter"].includes(props.name)) {
        typeRef = props.name;
      } else {
        throw new Error(`Invalid builder name prop: ${props.name}`);
      }

      let componentObject = findProgramBuilderComponent(
        props.chosenProgram.id,
        typeRef,
        state.programComponentsByProgram,
        state.componentObjectsByComponent,
      );
      if (!componentObject) {
        componentObject = (
          await ProgramService.getComponentObjectByProgramAndTypeValues(
            props.chosenProgram.id, COMPONENT_OBJECT_TYPE_BUILDER, typeRef
          )
        )?.payload;
        if (!componentObject) {
          setErrorMessage(`${chosenProgramName} does not contain a ${displayName} Builder`)
          return;
        }
        dispatch({
          type: ACTION_PARTIAL_REPLACE_COMPONENT_OBJECTS,
          payload: componentObject,
        });
      }
      props.setChosenProgram({
        ...props.chosenProgram,
        compObjId: componentObject.ComponentObject_ID,
      });
      props.setMode("loadTemplate", null);
      props.setEditModal(false);
    }
  };

  const clearForm = useCallback(() => {
    setChosenProgram({ id: "", name: "" });
    setMode("none");
  }, [setChosenProgram]);

  return (
    <Grid className={classes.formContainer} data-cy="builder-form">
      <FormBanner
        closeErrorMessage={() => setErrorMessage()}
        errorMessage={errorMessage}
      >
        Create {displayName}
      </FormBanner>
      <div className={classes.contentContainer}>
        <div className={classes.checkWrapper}>
          <div className={classes.checkContainer}>
            <Checkbox
              checked={mode === "enterprise"}
              onChange={(event) => handleCheck(event)}
              name="enterprise"
              label={`Create ${displayNameAndArticle} at the enterprise level`}
            />
            {eligiblePrograms.length > 0 && (
              <Checkbox
                checked={mode === "programLevel"}
                onChange={(event) => handleCheck(event)}
                name="programLevel"
                label={`Create ${displayNameAndArticle} at the program level`}
                test="builder-programLevel"
              />
            )}
          </div>
        </div>

        {mode === "programLevel" && (
          <div className={classes.selectsContainer}>
            <CustomSelect
              className={classes.select}
              label="Select Program"
              value={props.chosenProgram.id}
              onChange={handleChange}
              name="program"
              test="builder-program"
            >
              {eligiblePrograms.map((item) => (
                <MenuItem
                  value={item.Program_ID}
                  key={item.Program_ID}
                  data-cy={`menu-item-program-${item.Name}`}
                >
                  {item.Name}
                </MenuItem>
              ))}
            </CustomSelect>
          </div>
        )}
      </div>

      <DualFormButtons
        variant="addFramework"
        startIcon={<CheckOutlinedIcon className={classNames(classes.continueIcon, classes.icon)} />}
        addText="Continue"
        cancelOnClick={() => {
          props.setEditModal(false);
          clearForm();
        }}
        saveOnClick={handleSubmit}
        disabled={
          (mode === "programLevel" && props.chosenProgram.id === "") ||
          mode === "none" ||
          !!errorMessage
        }
      />
    </Grid>
  );
};

export default OrchestrationBuilderForm;
