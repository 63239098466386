import React, { useState, useEffect, useMemo, useCallback } from "react";
import { MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LabelInput from "components/utils/form-elements/labelInput.component";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import CustomSelect from "components/utils/form-elements/select.component";
import FormBanner from "components/utils/form-elements/formBanner.component";
import FormLabel from "components/utils/form-elements/formLabel.component";
import variables from "styleVariables";
import { validEmail, validPhone } from "utils/validators";
import { onlyNumericString } from "utils/stringFuncs";
//Services
import UserService from "services/user.service";
import {
  ROLE_GROUPS
} from "utils/roles";
import { HTTP_STATUS_CONTENT } from "services/http-common";
import authService from "services/auth.service";
import { currentUserID } from "utils/userHelpers";
import Form from "components/utils/form-elements/form.component";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 10,
    paddingBottom: 20,
    width: 420,
  },
  form: {
    padding: "0px 35px 0px 35px",
    align: "center",
    display: "block",
    margin: "0px auto",
    width: "100%",
  },
  fieldsWrapper: {
    padding: "0px 40px",
  },
  formSection: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 10,
    paddingBottom: 10,
  },
  editEmailWrapper: {
    // margin: "10px 0 10px 0",
    padding: "10px 0 0 0",
    // backgroundColor: variables.grayTint,
    // borderRadius: 5
  },
  editEmail: {
    padding: 10.5,
    fontSize: variables.fontSmall,
  },
}));

const emailErrorMsg =
  "This email already exists in the system. Please enter a unique email address.";


const UserMgmtForm = ({
  mode,
  userToEdit,
  roles,
  setRefresh,
  setEmailSentMessage,
  editFormType,
  onClose,
}) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const currentUserId = useMemo(currentUserID, []);

  const availableRoles = useMemo(() => {
    const isGlobalRolesAvailable = (
      !userToEdit?.Role_ID ||
      ROLE_GROUPS.GLOBAL_USERS.includes(userToEdit?.Role_ID)
    );
    if (isGlobalRolesAvailable) {
      return roles.filter((role) => (
        ROLE_GROUPS.GLOBAL_USERS.includes(role.Role_ID)
      ));
    }
    return [].concat(
      roles.find(role => role.Role_ID === userToEdit?.Role_ID) || []
    );
  }, [roles, userToEdit]);

  const isRoleReadOnly = useMemo(() => (
    !availableRoles?.length ||
    (
      userToEdit &&
      currentUserId === userToEdit.User_ID &&
      authService.Type.ADMIN === userToEdit.Role_ID
    )
  ), [availableRoles, currentUserId, userToEdit]);

  useEffect(() => {
    if (mode === "edit") {
      setFormData({
        First_Name: userToEdit.First_Name,
        Last_Name: userToEdit.Last_Name,
        Email: userToEdit.Email,
        Phone: userToEdit.Phone,
        Role_ID: userToEdit.Role_ID,
      });
    }
  }, [mode, userToEdit]);

  const validateFormErrors = useCallback((data) => ({
    First_Name: !data.First_Name,
    Last_Name: !data.Last_Name,
    Email: !data.Email?.trim?.() || !validEmail(data.Email) || !!errorMessage,
    Phone: data.Phone?.trim?.() && !validPhone(data.Phone),
    Role_ID: !data.Role_ID
  }), [errorMessage]);

  const getHasErrors = useCallback((validations) => {
    return Object.values(validations).some(isError => isError)
  }, []);

  const hasErrors = useMemo(() => {
    return getHasErrors(formErrors)
  }, [getHasErrors, formErrors]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (errorMessage) {
      setErrorMessage("");
    }
    const newFormData = {
      ...formData,
      [name]: value
    };
    setFormData(newFormData);

    if (formErrors[name]) {
      const validationErrors = validateFormErrors(newFormData);
      setFormErrors(validationErrors);
    }
  };

  const reset = useCallback(() => {
    onClose();
    setRefresh(Date.now());
  }, [onClose, setRefresh]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateFormErrors(formData);
    if (getHasErrors(validationErrors)) {
      setFormErrors(validationErrors);
      return;
    }
    const { First_Name, Last_Name, Email, Phone, Role_ID } = formData;
    const userBody = {
      First_Name,
      Last_Name,
      Email,
      Phone: onlyNumericString(Phone),
      Role_ID,
    };
    if (mode === "create") {
      UserService.create(userBody)
        .then(() => {
          setEmailSentMessage(`A reset password email has been sent to ${Email}`)
          reset();
        })
        .catch((err) => {
          if (err.response?.status === HTTP_STATUS_CONTENT) {
            setErrorMessage(emailErrorMsg);
          }
        });
    } else if (mode === "edit") {
      UserService.update(userBody, userToEdit.User_ID).then((res) => {
        reset();
      });
    }
  };

  const formTitle = useMemo(() => {
    if (editFormType) {
      return "Edit My Profile";
    } else {
      return `${mode === "create" ? "Add" : "Edit"} User`;
    }
  }, [editFormType, mode]);

  return (
    <>
      <FormBanner>{formTitle}</FormBanner>
      <div className={classes.formContainer} data-cy="form-userMgmt">
        <Form name="user-info" className={classes.form}>
          <div className={classes.fieldsWrapper}>
            {/* FIRST NAME */}
            <div className={classes.formSection}>
              <LabelInput
                variant="default"
                label="First Name*"
                onChange={handleChange}
                value={formData.First_Name}
                id="First_Name"
                name="First_Name"
                margin="dense"
                error={formErrors.First_Name}
                hideHelperText
              />
            </div>
            {/* LAST NAME */}
            <div className={classes.formSection}>
              <LabelInput
                variant="default"
                label="Last Name*"
                onChange={handleChange}
                value={formData.Last_Name}
                id="Last_Name"
                name="Last_Name"
                margin="dense"
                error={formErrors.Last_Name}
                hideHelperText
              />
            </div>
            {/* EMAIL */}
            {mode === "create" ? (
              <div className={classes.formSection}>
                <LabelInput
                  variant="default"
                  label="Email*"
                  type="email"
                  onChange={handleChange}
                  value={formData.Email}
                  id="Email"
                  name="Email"
                  margin="dense"
                  errorMessage={errorMessage}
                  error={formErrors.Email}
                  hideHelperText
                />
              </div>
            ) : (
              <div className={classes.editEmailWrapper}>
                <FormLabel label="Email" variant="default" />
                <div className={classes.editEmail}>{formData.Email}</div>
              </div>
            )}
            {/* PHONE */}
            <div className={classes.formSection}>
              <LabelInput
                variant="default"
                label="Phone"
                type="tel"
                onChange={handleChange}
                value={formData.Phone}
                error={formErrors.Phone}
                id="Phone"
                name="Phone"
                margin="dense"
                hideHelperText
              />
            </div>
            {/* ROLE */}
            <div className={classes.formSection}>
              <CustomSelect
                label="Role*"
                value={formData.Role_ID}
                id="Role_ID"
                name="Role_ID"
                onChange={handleChange}
                readOnly={isRoleReadOnly}
                error={formErrors.Role_ID}
                hideHelperText
              >
                {availableRoles?.map?.((role) => (
                  <MenuItem value={role.Role_ID} key={role.Role_ID}>
                    {role.Name}
                  </MenuItem>
                ))}
              </CustomSelect>
            </div>
          </div>
          {/* BUTTONS */}
          <DualFormButtons
            variant="smallButton"
            saveOnClick={handleSubmit}
            cancelOnClick={onClose}
            disabled={!!hasErrors && !!Object.keys(formData).length}
          />
        </Form>
      </div>
    </>
  );
};

export default UserMgmtForm;
