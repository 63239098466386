import { builderSectionTypes } from "./builderConstants";

export const addProcStep = (allStepSections, newSection) => {
  const stepsWithUpdatedOrder = allStepSections.map((sec) => {
    if (stepSectionsNeedsReOrderingAfterAdd(sec, newSection)) {
      return { ...sec, Section_Order: sec.Section_Order + 1 };
    } else {
      return sec;
    }
  });
  return [...stepsWithUpdatedOrder, newSection].sort(
    (a, b) => a.Section_Order - b.Section_Order
  );
};

const stepSectionsNeedsReOrderingAfterAdd = (
  sectionFromList,
  sectionBeingAdded
) => {
  if (sectionBeingAdded?._meta?.parentClientId) {
    return (
      sectionFromList._meta.parentClientId ===
        sectionBeingAdded._meta.parentClientId &&
      sectionFromList.Section_Order >= sectionBeingAdded.Section_Order
    );
  } else {
    return (
      sectionFromList.RegRefs_Reg_Refs_ID ===
        sectionBeingAdded.RegRefs_Reg_Refs_ID &&
      sectionFromList.Section_Order >= sectionBeingAdded.Section_Order
    );
  }
};

export const deleteProcStep = (allStepSections, sectionToDelete) => {
  const stepsWithUpdatedOrder = allStepSections
    .filter((sec) => sec._meta.clientId !== sectionToDelete._meta.clientId)
    .map((sec) => {
      if (stepSectionsNeedsReOrderingAfterDelete(sec, sectionToDelete)) {
        return { ...sec, Section_Order: sec.Section_Order - 1 };
      } else {
        return sec;
      }
    });

  return stepsWithUpdatedOrder.sort((a, b) => a.Section_Order - b.Section_Order);
};

const stepSectionsNeedsReOrderingAfterDelete = (
  sectionFromList,
  sectionBeingDeleted
) => {
  if (sectionBeingDeleted?._meta?.parentClientId) {
    return (
      sectionFromList._meta.parentClientId ===
        sectionBeingDeleted._meta.parentClientId &&
      sectionFromList.Section_Order > sectionBeingDeleted.Section_Order
    );
  } else {
    return (
      sectionFromList.RegRefs_Reg_Refs_ID ===
        sectionBeingDeleted.RegRefs_Reg_Refs_ID &&
      sectionFromList.Section_Order > sectionBeingDeleted.Section_Order
    );
  }
};

export const itemizeBuilderSections = (sections = []) => {
  const builderSections = [];
  const procElementSections = [];
  const procSteps = [];
  for (const section of sections) {
    if (!section.Type) {
      builderSections.push(section);
    } else if (section.Type === builderSectionTypes.PROC_ELEMENT) {
      procElementSections.push(section);
    } else if (section.Type === builderSectionTypes.PROC_STEP) {
      procSteps.push(section);
    }
  }

  return {
    builderSections,
    procElementSections,
    procSteps,
  };
};

export const attachMetaToFrameworks = (frameworks, noPrimaryKey) => {
  return frameworks.map((frame, index) => {
    return {
      ...frame,
      Reg_Refs_ID: noPrimaryKey ? null : frame.Reg_Refs_ID, //TODO: "CHECK THIS"
      _meta: {
        clientId: `procEleRefId-${frame.GroupName}-${frame.RegFramework_ID}`,
      },
    };
  });
};
