import { Box } from "@material-ui/core";
import { useMemo } from "react";
import { groupObjectArrayByKey } from "utils/arrayOfObjectsHelpers";
import ReconciliationTable from "./table/reconciliationTable.component";


export default function ReconcileView(props) {
  const {
    categorizedItems, categoryRefs, columns, isReconcile, itemLabel,
    onCategoryAutosave, onItemAutosave, questionRefs, sublabelFilter,
    tableHeaderPanel, userFilter = "self", users
  } = props;

  const itemEntriesByEnumeration = useMemo(() => (
    Object.entries(
      groupObjectArrayByKey(categorizedItems, "enumeration")
    )
  ), [categorizedItems]);

  return (
    <Box
      paddingBottom={4}
      paddingTop={2}
      data-cy="reconciliation-wrapper"
    >
      {tableHeaderPanel}
      {itemEntriesByEnumeration.map(([enumeration, items]) => (
        <ReconciliationTable
          columns={columns}
          categorizedItems={items}
          itemLabel={itemLabel}
          sublabelFilter={sublabelFilter}
          userFilter={userFilter}
          users={users}
          onCategoryAutosave={onCategoryAutosave}
          onItemAutosave={onItemAutosave}
          isReconcile={isReconcile}
          categoryRefs={categoryRefs}
          questionRefs={questionRefs}
          key={enumeration}
        />
      ))}
    </Box>
  );
}
