import useNumericParams from "hooks/useNumericParams";
import { makeStyles } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import {
  generatePath,
  Route,
  Switch,
} from "react-router-dom/cjs/react-router-dom.min";
import portfolioAssessmentsReducer, {
  ACTION_SET_ASSESSMENTS,
  ACTION_SET_PORTCOS,
  ACTION_SET_PORTFOLIO_USERS,
  portfolioAssessmentInitialState,
} from "reducers/portfolioAssessments.reducer";
import PortfolioService from "services/portfolio.service";
import SessionsPages from "./assessment/sessionsPages.component";
import CustomCard from "components/utils/card.component";
import SelectAssessment from "./assessment/selectAssessment.component";
import CustomIcons from "components/utils/icons.component";
import styleVariables from "styleVariables";
import HeaderPrimary from "components/utils/header.component.js";
import PortfolioComponentObjectWrapper from "./shared/portfolioComponentObjectWrapper.component";
import { COMPONENT_ID_PORTFOLIO_ASSESSMENTS } from "utils/programConstants";
import useReducerAsync from "hooks/useReducerAsync";

const useStyles = makeStyles((theme) => ({
  portCardsContainer: {
    marginTop: 40,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "stretch",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(30),
      height: "",
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  dataCollectionIcon: {
    height: 16,
    marginRight: 7,
    fill: styleVariables.primaryMain,
  },
  descriptionText: {
    fontSize: styleVariables.fontSmall,
    color: styleVariables.textSecondary,
    paddingTop: 5,
  },
}));

const Assessments = () => {
  const params = useNumericParams();
  const classes = useStyles();
  const { portCoId, holdingId } = params;
  const [state, dispatch] = useReducerAsync(
    portfolioAssessmentsReducer,
    portfolioAssessmentInitialState
  );

  useEffect(
    () =>
      (async function loadGlobalState() {
        if (!holdingId || holdingId === "undefined") {
          return;
        }
        const [usersResponse, portCoResponse, assessmentResponse] =
          await Promise.all([
            PortfolioService.getPortfolioUsers(holdingId),
            PortfolioService.getAllPortCosByHoldingID(holdingId),
            PortfolioService.getHolderAssessments(),
          ]);
        dispatch({
          type: ACTION_SET_PORTFOLIO_USERS,
          payload: usersResponse.payload,
        });
        dispatch({ type: ACTION_SET_PORTCOS, payload: portCoResponse.payload });
        dispatch({
          type: ACTION_SET_ASSESSMENTS,
          payload: assessmentResponse.payload,
        });
      })(),
    [holdingId, dispatch]
  );

  const cardLinkPath = useMemo(() => {
    if (!holdingId) {
      return "/notFound";
    }
    const pathParams = { ...params, programComponentId: COMPONENT_ID_PORTFOLIO_ASSESSMENTS };
    if (portCoId) {
      return generatePath(
        `/program/:programId/:programComponentId/holding/:holdingId/portCo/:portCoId/select`,
        pathParams
      );
    }
    return generatePath(
      `/program/:programId/:programComponentId/holding/:holdingId/select`,
      pathParams
    );
  }, [params, holdingId, portCoId]);

  return (
    <PortfolioComponentObjectWrapper
      isLoading={
        (!state.portfolioUsers || !state.portfolioCompanies) &&
        holdingId
      }
    >
      <Switch>
        <Route path="/program/:programId/:programComponentId/holding/:holdingId/portCo/:portCoId/assessment/:assessmentId">
          <SessionsPages state={state} dispatch={dispatch} />
        </Route>
        <Route
          path={[
            "/program/:programId/:programComponentId/holding/:holdingId/select",
            "/program/:programId/:programComponentId/holding/:holdingId/portCo/:portCoId/select",
          ]}
        >
          <SelectAssessment state={state} dispatch={dispatch} />
        </Route>
        <Route path="/program/:programId/:programComponentId/holding/:holdingId">
          <div className={classes.portCardsContainer}>
            <CustomCard
              url={!!holdingId && cardLinkPath}
              type="Custom"
              variant="linkPlain"
            >
              <div className={classes.customCardWrapper}>
                <div className={classes.row}>
                  <CustomIcons
                    variant="Audit-data-collection"
                    className={classes.dataCollectionIcon}
                  />
                  <HeaderPrimary variant="h4Primary">
                    Data Collection
                  </HeaderPrimary>
                </div>
                <span className={classes.descriptionText}>
                  Collect data for an existing assessment.
                </span>
              </div>
            </CustomCard>

            <CustomCard
              title="Create a New Assessment"
              description="Create a new assessment."
              icon={null}
              // url={generatePath(
              //   `/program/:programId/:programComponentId/holding/:holdingId/new`,
              //   { ...params, programId: programId, holdingId: holdingId, programComponentId: COMPONENT_ID_PORTFOLIO_ASSESSMENTS }
              // )}
              type="Custom"
              variant="disabled"
            />
          </div>
        </Route>
      </Switch>
    </PortfolioComponentObjectWrapper>
  );
};

export default Assessments;
