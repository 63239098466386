import axios from "axios";

export default axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    "Content-type": "application/json",
  },
});

export const HTTP_STATUS_OK = 200;
export const HTTP_STATUS_CREATED = 201;
export const HTTP_STATUS_NO_CONTENT = 204;
export const HTTP_STATUS_NOT_AUTHORIZED = 401;
export const HTTP_STATUS_FORBIDDEN = 403;
export const HTTP_STATUS_CONTENT = 409;
export const HTTP_STATUS_TOO_LARGE = 413;
export const HTTP_STATUS_UNSUPPORTED_MEDIA_TYPE = 415;
export const HTTP_STATUS_INTERNAL_ERROR = 500;
export const HTTP_STATUS_VIRUS_DETECTED = 602;

export function getUploadHttpErrorMessage(status) {
  switch(status) {
    case HTTP_STATUS_TOO_LARGE:
      return (
        "File is too large. " +
        "Please reduce file size or choose a different file and try again."
      );
    case HTTP_STATUS_UNSUPPORTED_MEDIA_TYPE:
      return "Invalid file type. Please verify the file extension is correct.";
    case undefined:
    case null:
      return null;
    case HTTP_STATUS_VIRUS_DETECTED:
      return "Virus detected. Please inspect the file."
    default:
      return "An unknown error occured. Please refresh the page and try again.";
  }
}
