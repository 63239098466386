import { Box } from "@material-ui/core";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import Loader from "components/utils/loader.components";
import useNumericParams from "hooks/useNumericParams"
import { useEffect, useMemo, useState } from "react";
import { ACTION_SET_CFR_ITEMS, ACTION_SET_MANUAL, ACTION_SET_MANUAL_COMMENTS } from "reducers/certification/manuals.reducer";
import certificationService from "services/certification.service";
import { setStateFetchEffect } from "utils/ajaxHelpers";
import { makeSectionNumberingMap } from "utils/certificationHelpers";
import ChapterForm from "./chapterForm.component";
import ChapterTabs from "./chapterTabs.component";
import ManualMetaForm from "./manualMetaForm.component";
import ManualSectionsList from "./manualSectionsList.component";

export default function EditManualPage(props) {
  const { manualId } = useNumericParams();
  const { dispatch, state } = props;
  const { cfrItems, manual } = state;

  const [activeChapterId, setActiveChapterId] = useState();
  const [didFetchLazyLoaded, setDidFetchLazyLoaded] = useState(false);

  const activeChapter = useMemo(() => (
    manual?._associations?.ManualSection?.find?.(chapterItem =>
      chapterItem.ManualSection_ID === activeChapterId
    )
  ), [activeChapterId, manual]);

  useEffect(function fetchManual() {
    if (manual?.Manual_ID === manualId) {
      return;
    }
    return setStateFetchEffect(
      certificationService.getManual(manualId),
      ([manualResponse]) => {
        dispatch({ type: ACTION_SET_MANUAL, payload: manualResponse.payload });
        if (!activeChapterId) {
          const chapters = manualResponse.payload?._associations?.ManualSection;
          setActiveChapterId(chapters?.[0]?.ManualSection_ID);
        }
      }
    );
  }, [activeChapterId, dispatch, manual, manualId, setActiveChapterId]);

  useEffect(() => {
    let isMounted = true;
    async function fetchLazyLoaded() {
      if (cfrItems || didFetchLazyLoaded || !isMounted) {
        return;
      }
      setDidFetchLazyLoaded(true);
      const cfrResponse = await certificationService.getCfrItems();
      dispatch({ type: ACTION_SET_CFR_ITEMS, payload: cfrResponse.payload });
    }
    fetchLazyLoaded();
    return () => {
      isMounted = false;
    };
  }, [dispatch, cfrItems, didFetchLazyLoaded, setDidFetchLazyLoaded]);

  useEffect(() =>
    (async function fetchManualCommments() {
      if (!manualId) {
        return;
      }
      const commentsResponse =
        await certificationService.getManualCommentsByManualId(manualId);
      dispatch({
        type: ACTION_SET_MANUAL_COMMENTS,
        payload: commentsResponse.payload,
      });
    })(),
    [manualId, dispatch]
  );

  const chapterNumberMap = useMemo(() => {
    return makeSectionNumberingMap(state?.manual?._associations.ManualSection)
  }, [state?.manual?._associations.ManualSection]);

  if (!manual || manual.Manual_ID !== manualId) {
    return (
      <Loader />
    );
  }
  return (
    <GridContainer spacing={4}>
      <GridItem xs={12} xl={12}>
        <ManualMetaForm dispatch={dispatch} manual={manual} />
        <ChapterTabs
          activeChapterId={activeChapterId}
          dispatch={dispatch}
          manual={manual}
          setActiveChapterId={setActiveChapterId}
          state={state}
        />
        <Box marginTop={-1} key={activeChapterId}>
          <ChapterForm
            chapter={activeChapter}
            chapterNumber={chapterNumberMap[activeChapterId]}
            dispatch={dispatch}
            setActiveChapterId={setActiveChapterId}
            state={state}
          >
            {!!activeChapter && (
              <ManualSectionsList
                chapter={activeChapter}
                chapterNumber={chapterNumberMap[activeChapterId]}
                dispatch={dispatch}
                state={state}
              />
            )}
          </ChapterForm>
        </Box>
      </GridItem>
      <GridItem
        xs={12 /* Possibly make position: fixed and aligned to bottom of screen */}
        md={3}
        lg={2}
        xl={1}
      />
    </GridContainer>
  )
}
