import React, { useEffect, useReducer, useMemo, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import AlertsFeed from "components/alerts/alertsFeed.component";
import DashRunbookPrograms from "components/runbook/dashboard/dashRunbookPrograms.component";
import classNames from "classnames";
import { COMPONENT_ID_RUNBOOK_VIEW, PROGRAM_ID_RUNBOOK } from "utils/programConstants";
import RunbookService from "services/runbook.service";
import runbooksReducer, { runbooksInitialState, ACTION_SET_RUNBOOKS, ACTION_SET_ALL_RUNBOOK_PHASES } from "reducers/runbook/runbooks.reducer";
import UpcomingRunbookTasks from "components/runbook/dashboard/upcomingRunbookTasks.component";
import { generatePath } from "react-router-dom/cjs/react-router-dom.min";
import useNumericParams from "hooks/useNumericParams";
import { alertsFeedVariants } from "utils/alertsConstants";
import { setStateFetchEffect } from "utils/ajaxHelpers";

const useStyles = makeStyles((theme) => ({
  mainContentContainer: {
    marginTop: 5,
    justifyContent: "center",
    [theme.breakpoints.down("lg")]: {
      width: "calc(100% + 32px)",
    },
  },
  tileLayout: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "50px !important",
    paddingRight: "0px !important",
    [theme.breakpoints.down("lg")]: {
      paddingRight: "30px !important",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      paddingRight: "20px !important",
    },
  },
  leftColumn: {
    paddingRight: 50,
    [theme.breakpoints.down("lg")]: {
      paddingRight: 10,
    },
    [theme.breakpoints.down("md")]: {
      paddingRight: 0,
    },
  },
  rightColumn: {
    paddingLeft: 20,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 50,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      paddingBottom: 20,
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 0,
    },
  },
  componentWrapper: {
    paddingBottom: 40,
  },
  mediumTableContainer: {
    maxWidth: 600,
    marginLeft: "auto",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "unset",
      marginLeft: "unset",
    },
  },
}));

const RunbookDashboard = () => {
  const classes = useStyles();
  const [state, dispatch] = useReducer(runbooksReducer, runbooksInitialState);
  const params = useNumericParams();

  useEffect(() => (
    setStateFetchEffect(
      [
        RunbookService.getAllRunbooks(),
        RunbookService.getAllRunbookPhases()
      ],
      function fetchDashboardData([runbooksResponse, allPhasesResponse]) {
        dispatch({
          type: ACTION_SET_RUNBOOKS,
          payload: runbooksResponse.payload,
        });
        dispatch({
          type: ACTION_SET_ALL_RUNBOOK_PHASES,
          payload: allPhasesResponse.payload,
        });
      }
    )
  ), []);

  const runBoooksMapById = useMemo(() => {
    return state?.runbooks?.reduce((accum, rb) => {
      return{
        ...accum,
        [rb.Runbook_ID]: rb
      }
    }, {})
  }, [state?.runbooks])

  const makeRunbookTaskPath = useCallback((runbookId) => (
    generatePath(
      `/program/:programId/${COMPONENT_ID_RUNBOOK_VIEW}/runbook/:runbookId`,
      { ...params, runbookId }
    )
  ), [params]);

  return (
    <Grid container spacing={8} className={classes.mainContentContainer}>
      <Grid item sm={12} className={classes.tileLayout}>
        {/* ====== LEFT COLUMN ========*/}

        <Grid item sm={12} lg={8} className={classes.leftColumn}>
          {/* Runbooks */}
          <div className={classNames(classes.componentWrapper)}>
            <DashRunbookPrograms
              programId={PROGRAM_ID_RUNBOOK}
              state={state}
              dispatch={dispatch}
            />
          </div>
          <div className={classNames(classes.componentWrapper)}>
            <UpcomingRunbookTasks
              runBoooksMapById={runBoooksMapById}
              makeRunbookTaskPath={makeRunbookTaskPath}
            />
          </div>
        </Grid>

        {/* ======= RIGHT COLUMN ======= */}
        <Grid item sm={12} lg={4} className={classes.rightColumn}>
          {/* Alerts Section */}
          <div
            className={classNames(
              classes.componentWrapper,
              classes.mediumTableContainer
            )}
          >
            <AlertsFeed
              variant={alertsFeedVariants.DASHBOARD}
              programId={PROGRAM_ID_RUNBOOK}
              test="dashboard"
              testProp="dashboard"
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RunbookDashboard;