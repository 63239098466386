import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import ButtonDefault from "components/utils/buttonDefault.component";
import { makeStyles } from "@material-ui/core/styles";
import { isReadOnly } from "utils/roles";

const useStyles = makeStyles((theme) => ({
  iconSmall: {
    marginRight: 8,
    fontSize: 16,
    color: "white",
  },
}));

const ResetButton = ({ onClick, testProp, label, disableReadOnlyUsers, disabled }) => {
  const classes = useStyles();
  const readOnly = isReadOnly();
  return (
    <ButtonDefault
      variant="small"
      background="primary"
      startIcon={(
        <RotateLeftIcon className={classes.iconSmall} />
      )}
      onClick={onClick}
      data-cy={readOnly || !testProp ? "btn-Reset" : `btn-Reset-${testProp}`}
      disableReadOnlyUsers={disableReadOnlyUsers}
      disabled={disabled}
    >
      {label ? `Reset ${label}` : "Reset"}
    </ButtonDefault>
  );
};

export default ResetButton;
