import React, { useCallback, useMemo, useState } from "react";
import variables from "styleVariables";
import { makeStyles } from "@material-ui/core";
import AttachmentOutlinedIcon from '@material-ui/icons/AttachmentOutlined';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { downloadFileByRef } from "utils/downloadFile";
import classNames from "classnames";
import ButtonDefault from "../buttonDefault.component";
import CustomLink from "../link.component";
import { FaUndo } from "react-icons/fa";
import { WarningRounded } from "@material-ui/icons";
import { acceptableExtensionTypes } from "utils/fileConstants";

const useStyles = makeStyles((theme) => ({
  containerInline: {
    display: "flex",
    alignItems: "center"
  },
  //UPLOAD
  attachIcon: {
    color: "white",
    marginRight: 4,
    marginLeft: -1,
  },
  attachIconDefault: {
    fontSize: 18,
    height: 18,
  },
  attachIconInline: {
    fontSize: 12,
    height: 16,
    width: 16,
  },
  reattachIconDefault: {
    height: 18,
    marginRight: 6,
    fontSize: 12
  },
  reattachIconInline: {
    height: 16,
    marginRight: 6
  },
  attachmentIcon: {
    maxHeight: 20,
    marginRight: 8,
    display: "inline-block",
    fill: variables.secondaryDark,
    fontSize: "1.5rem",
  },
  errorIcon: {
    marginBottom: 2,
    fill: variables.warningMain,
  },
  attachmentTextContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 34,
    marginTop: 10
  },
  invisibleInput: {
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
    opacity: 0,
    height: 1
  },
  error: {
    color: variables.warningMain,
    fontWeight: 600
  },
  attachmentWrapperCentered: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginBottom: 4
  },
  attachmentWrapperInline: {
    marginRight: 16
  },
  button: {
    backgroundColor: variables.primaryMain,
  },
  longButton: {
    width: 600,
    maxWidth: "70vw",
  },
  attachmentText: {
    fontSize: variables.fontMedium,
  },
  attachmentTextDefault: {
    lineHeight: "normal",
  },
  fileName: {
    wordBreak: "break-all"
  }
}));

export const VARIANT_DEFAULT = "default";
export const VARIANT_INLINE = "inline";

export const FILE_INPUT_NAME_DEFAULT = "single-file-upload";

const SingleFileUpload = ({
  name = FILE_INPUT_NAME_DEFAULT,
  fileInputHandler,
  chosenFile,
  error,
  existingFile,
  renamedChosenFile,
  existingFileDisplayName,
  unsavedExistingFile,
  fileNameField = "File_Name",
  variant = VARIANT_DEFAULT
}) => {
  const classes = useStyles();
  const isFileAttached = !!chosenFile?.name || !!existingFile?.File_Ref;

  const [isDisabledOverride, setIsDisabledOverride] = useState(false);

  const href = useMemo(() => {
    if (!chosenFile && !unsavedExistingFile) {
      return undefined
    }
    return unsavedExistingFile ? URL.createObjectURL(unsavedExistingFile) : URL.createObjectURL(chosenFile)
  }, [unsavedExistingFile, chosenFile])

  const fileNameDisplay = useMemo(() => {
    if (chosenFile) {
      if (renamedChosenFile) {
        return renamedChosenFile;
      }
      return chosenFile.name;
    }
    if (existingFile) {
      return existingFileDisplayName || existingFile?.File_Ref;
    }
  }, [renamedChosenFile, chosenFile, existingFile, existingFileDisplayName])

  const handleButtonClick = useCallback(() => {
    if (existingFile) {
      setIsDisabledOverride(true);
    }
  }, [existingFile])

  const handleFileDownload = useCallback(() => {
    if (existingFile && !chosenFile) {
      const fileRef = existingFile.File_Ref
      return downloadFileByRef(
        fileRef,
        existingFileDisplayName || existingFile[fileNameField] || fileRef
      )
    }
  }, [chosenFile, existingFile, existingFileDisplayName, fileNameField]);

  const handleInputChange = useCallback(event => {
    setIsDisabledOverride(false);
    fileInputHandler(event);
  }, [fileInputHandler]);

  return (
    <div
      className={
        classNames(variant === VARIANT_INLINE && classes.containerInline)
      }
    >
      <div className={classNames(
        variant === VARIANT_DEFAULT && classes.attachmentWrapperCentered,
        variant === VARIANT_INLINE && classes.attachmentWrapperInline
      )}
      >
        <ButtonDefault
          component="label"
          className={classNames(
            classes.button,
            variant === VARIANT_DEFAULT && classes.longButton
          )}
          startIcon={isFileAttached ? (
            <FaUndo
              className={classNames(
                variant === VARIANT_DEFAULT && classes.reattachIconDefault,
                variant === VARIANT_INLINE && classes.reattachIconInline,
              )}
            />
          ) : (
            <AttachmentIcon
              className={classNames(
                classes.attachIcon,
                variant === VARIANT_DEFAULT && classes.attachIconDefault,
                variant === VARIANT_INLINE && classes.attachIconInline,
              )}
            />
          )}
          variant={variant === VARIANT_INLINE ? "small" : "default"}
          onClick={handleButtonClick}
          data-cy="btn-attach-file"
          disableReadOnlyUsers
        >
          {isFileAttached ? `Change File` : `Attach File`}
          <input
            type="file"
            name={name}
            accept={acceptableExtensionTypes.toString()}
            className={classes.invisibleInput}
            onChange={handleInputChange}
            disabled={!isDisabledOverride && !!existingFile}
            data-cy="fileInput-attach-file"
          />
        </ButtonDefault>
      </div>
      <div
        className={classes.attachmentTextContainer}
        data-cy="linkContentContainer"
      >
        {!!isFileAttached && !!error && (
          <>
            <WarningRounded
              className={classNames(classes.attachmentIcon, classes.errorIcon)}
            />
            <div
              className={classNames(
                classes.attachmentText,
                variant === VARIANT_DEFAULT && classes.attachmentTextDefault,
                classes.error
              )}
            >
              {error}
            </div>
          </>
        )}
        {!!isFileAttached && !error && (
          <CustomLink
            target="_blank"
            href={href}
            onClick={handleFileDownload}
            variant="iconBeforeLink"
            className={classNames(classes.attachmentText, classes.fileName)}
            containerClassName={classes.linkInnerContainer}
            startIcon={
              <AttachmentOutlinedIcon className={classes.attachmentIcon} />
            }
          >
            {fileNameDisplay}
          </CustomLink>
        )}
        {!isFileAttached && (
          <div
            className={classNames(
              classes.attachmentText,
              variant === VARIANT_DEFAULT && classes.attachmentTextDefault,
              error && classes.error
            )}
          >
            {error?.length ? error : "No File Selected"}
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleFileUpload;
