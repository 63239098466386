import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import ButtonDefault from "components/utils/buttonDefault.component";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import PostAddIcon from '@material-ui/icons/PostAdd';
import TrackChangesOutlinedIcon from "@material-ui/icons/TrackChangesOutlined";
import { replaceSubstring } from "utils/stringFuncs";
import variables from "styleVariables";

const useStyles = makeStyles((theme) => ({
  buttonContainerTop: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: 20,
    position: "absolute",
    right: 0,
    zIndex: 10,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
      marginBottom: 30,
    },
  },
  templateIcon: {
    fontSize: variables.fontH2,
    color: "white",
  },
}));

export const BuilderLandingTopSection = ({
  compTypeRef,
  setMode,
  openForm,
  readOnly
}) => {
  const classes = useStyles();

  const builderIconElement = useMemo(() => {
    switch(compTypeRef) {
      case "Charter":
        return <PostAddIcon className={classes.charterIcon} />;
      case "ProgramCharter":
        return <PostAddIcon className={classes.charterIcon} />;
      case "Plan":
        return <TrackChangesOutlinedIcon className={classes.planIcon} />;
      case "ProgramPlan":
        return <TrackChangesOutlinedIcon className={classes.planIcon} />;
      case "Policy":
        return <DashboardOutlinedIcon className={classes.templateIcon} />;
      case "ProgramPolicy":
        return <DashboardOutlinedIcon className={classes.templateIcon} />;
      default:
        return <DashboardOutlinedIcon className={classes.templateIcon} />;
    }
  }, [classes, compTypeRef]);

  const createClick = () => {
    if (compTypeRef === "Charter" || compTypeRef === "Assessment") {
      setMode("loadTemplate");
    } else if (compTypeRef === "Plan") {
      setMode("loadTemplate", null);
    } else {
      openForm();
    }
  };

  return (
    <Grid item className={classes.buttonContainerTop}>
      {!compTypeRef.includes("Procedure") && (
        <ButtonDefault
          variant="medium"
          background="tertiary"
          startIcon={builderIconElement}
          onClick={createClick}
          disabled={readOnly}
          fixedRight
          data-cy="btn-builder-create"
        >
          Create {replaceSubstring(compTypeRef, "Program", "")}
        </ButtonDefault>
      )}
    </Grid>
  );
};

export default BuilderLandingTopSection;

