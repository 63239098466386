import React, { useCallback } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import HeaderPrimary from "components/utils/header.component";
import LabelAndValue from 'components/utils/labelAndValue.component';
import variables from "styleVariables";
import { formattedMomentCustomError } from "utils/dateHelpers";
import classNames from 'classnames';
import moment from "moment"
import CustomLink from 'components/utils/link.component';
import { downloadFileByRef } from 'utils/downloadFile';
import { getAssessmentItemName } from 'utils/assessmentHelpers';
import { Grid } from '@material-ui/core';
import AttachmentOutlinedIcon from '@material-ui/icons/AttachmentOutlined';

const useStyles = makeStyles((theme) => ({
  reportItemContainer: {
    width: "100%",
    marginBottom: 25,
  },
  content: {
    padding: "15px 40px",
  },
  nameAndDate: {
    color: variables.textSecondary
  },
  list: {
    listStyle: "none",
    paddingLeft: 0
  },
  row: {
    width: "100%",
  },
  dualItemRow: {
    display: "flex",
  },
  label: {
    padding: 0,
  },
  referenceRow: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  referenceList: {
    paddingTop: 5,
  },
  referenceLinkWrapper: {
    paddingLeft: 10,
    paddingBottom: 3,
    paddingTop: 3,
    display: "flex",
    alignItems: "center",
    flexFlow: "row",
    "&:hover": {
      "& $attachmentIcon": {
        fill: variables.primaryLight,
      }
    }
  },
  attachmentIcon: {
    fontSize: ".9rem",
    fill: variables.secondaryDark,
    marginRight: 8,
  },
  printSource: {
    "@media print": {
      pageBreakInside: "avoid",
      fontSize: "12px",
    }
  },
  sideBySidePrintStyles: {
    "@media print": {
      pageBreakInside: "avoid",
      fontSize: "12px",
      marginRight: "60px"
    }
  },
  gridPrintStyles: {
    "@media print": {
      justifyContent: "flex-start",
      pageBreakInside: "avoid",
    }
  },
  sectionHeaderPrintStyles: {
    pageBreakInside: "avoid",
  },
  referenceLinkPrintStyles: {
    "@media print": {
      fontSize: "12px",
      lineHeight: 1.7,
      color: "black"
    }
  },
  referencesHeaderPrintStyles: {
    "@media print": {
      fontSize: "12px",
      lineHeight: 1.7,
    }
  },
}));

const headerVariant = "h5Tertiary"

const labelValPaddingBottom = "1em";


const ReportItem = ({ assessors, assessmentItem, itemReviews, references }) => {
  const classes = useStyles()
  const desRevs = itemReviews.filter(rev => rev.Design_ID)
  const perfRevs = itemReviews.filter(rev => rev.Performance_ID)
  const orderedRevs = [...desRevs, ...perfRevs]

  const findReviewReference = (reviewID, revTypeName) => {
    return references?.filter(reference => {
      return revTypeName === "Design" ? reference.DesignDC_ID === reviewID : reference.PerformanceDC_ID === reviewID
    })
  }

  return (
    <ul className={classNames(classes.list)}>
      {orderedRevs?.map(review => {
        const revTypeName = review.Design_ID ? "Design" : "Performance"
        const revTypeID = review.Design_ID ? "Design_ID" : "Performance_ID"
        const assessor = assessors.find((user) => user.User_ID === review.Change_User)
        const revID = review.Performance_ID || review.Design_ID

        return (
          <li className={classes.reportItemContainer} key={`${revTypeName}-${revID}`}>
            <div className={classes.sectionHeaderPrintStyles}>
              <HeaderPrimary variant="h4Primary">
                {getAssessmentItemName(assessmentItem)} - {revTypeName}
              </HeaderPrimary>
              <div className={classes.nameAndDate}>
                {assessor?.First_Name} {assessor?.Last_Name}&nbsp;
                {formattedMomentCustomError(moment(review.Change_Date).format("MM/DD/YYYY"))}
              </div>
            </div>

            <div className={classes.content}>
              {review.Design_ID ? (
                <div>
                  <Grid
                    container
                    justifyContent="space-between"
                    className={classNames(classes.row, classes.dualItemRow, classes.gridPrintStyles)}
                  >
                    <Grid sm={5} item>
                      <PaddedLabelAndValue label="Current State" sideBySide value={review.Current_State} />
                    </Grid>
                    <Grid sm={6} item>
                      <PaddedLabelAndValue
                        label="Implementation Type"
                        value={review.Implementation_Type}
                      />
                    </Grid>
                  </Grid>
                  <PaddedLabelAndValue label="Description" value={review.Description} verticalPositioning />
                  <PaddedLabelAndValue label="Finding" value={review.Finding} verticalPositioning />
                  <PaddedLabelAndValue label="Recommendation" value={review.Recommendation} verticalPositioning />
                  <div className={classes.referenceRow}>
                    <HeaderPrimary className={classes.label} variant={headerVariant}>
                      <span className={classes.referencesHeaderPrintStyles}>
                        Uploaded Reference Files:
                      </span>
                    </HeaderPrimary>

                    <div className={classes.referenceList}>
                      {findReviewReference(revID, revTypeID)?.map((reference, index) => {
                        return <ReferenceLinkItem reference={reference} key={reference.Reference_ID} />
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <Grid
                    container
                    justifyContent="space-between"
                    className={classNames(classes.row, classes.dualItemRow, classes.gridPrintStyles)}
                  >
                    <Grid sm={5} item>
                      <PaddedLabelAndValue label="Observation Date" value={review.Observation_Date} sideBySide />
                    </Grid>
                    <Grid sm={6} item>
                      <PaddedLabelAndValue label="Location" value={review.Location} />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    className={classNames(classes.row, classes.dualItemRow, classes.gridPrintStyles)}
                  >
                    <Grid sm={5} item>
                      <PaddedLabelAndValue
                        label="Individuals/Groups Involved"
                        value={review.Groups_Involved}
                        verticalPositioning
                        sideBySide
                      />
                    </Grid>
                    <Grid sm={6} item>
                      <PaddedLabelAndValue
                        label="Systems/Applications Involved"
                        value={review.Systems_Involved}
                        verticalPositioning
                      />
                    </Grid>
                  </Grid>
                  <PaddedLabelAndValue
                    label="Performance Description"
                    value={review.Description}
                    verticalPositioning
                  />
                  <PaddedLabelAndValue
                    label="Action Taken Based on Observation"
                    value={review.Actions_Taken}
                    verticalPositioning
                  />
                  <PaddedLabelAndValue label="Finding" value={review.Finding} verticalPositioning />
                  <PaddedLabelAndValue
                    label="Recommendation"
                    value={review.Recommendation}
                    verticalPositioning
                  />
                  <div className={classes.referenceRow}>
                    <HeaderPrimary className={classes.label} variant={headerVariant}>
                      <span className={classes.referencesHeaderPrintStyles}>
                        Uploaded Reference Files:
                      </span>
                    </HeaderPrimary>
                    <div className={classes.referenceList}>
                      {findReviewReference(revID, revTypeID)?.map((reference, index) => {
                        return <ReferenceLinkItem reference={reference} key={reference.Reference_ID} />
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </li>
        )
      })}
    </ul>
  );
}
export default ReportItem


const PaddedLabelAndValue = (props) => {
  const classes = useStyles();
  const { sideBySide } = props;
  return (
    <div
      className={
        sideBySide ? classes.sideBySidePrintStyles : classes.printSource
      }
    >
      <LabelAndValue
        {...props}
        paddingBottom={labelValPaddingBottom}
        labelHeaderVariant={headerVariant}
        alignLeft
      />
    </div>
  );
}

const ReferenceLinkItem = ({ reference }) => {
  const classes = useStyles();

  const handleClick = useCallback(() => (
    downloadFileByRef(reference.File_Ref, reference.Reference_Name)
  ), [reference]);

  return (
    <div className={classes.referenceLinkWrapper}>
      <AttachmentOutlinedIcon className={classes.attachmentIcon} />
      <CustomLink
        variant="underline"
        onClick={handleClick}
        test={reference.Reference_Name}
        key={reference.Reference_ID}
      >
        <span className={classes.referenceLinkPrintStyles}>
          {reference.Reference_Name}
        </span>
      </CustomLink>
    </div>
  )
}
