import { deleteManyBySchema, deleteOneBySchema } from "reducers/utils/schemaMergeUtils/schemaMergeDelete";
import { manualReferenceSchema, manualSectionAllLevelSchema } from "./schema/manuals.schema";
import { mapToDictionary, removeObjInArray } from "utils/arrayOfObjectsHelpers";
import { flattenAssociations, mergeAssociationDelete, mergeAssociationReplace, mergeNestedParentKeyAssociationReplace, mergeParentKeyAssociationReplace, mergeRecursiveReplace, sortNestedAssociations } from "../utils/payloadUtils";

export const ACTION_SET_CFR_ITEMS = "cfr_items__set";
export const ACTION_SET_MANUAL = "manual__set";
export const ACTION_SET_MANUALS_INDEX = "manuals_index__set";
export const ACTION_SET_USERS = "users__set";

export const ACTION_REPLACE_MANUAL = "manual__replace";

// SECTIONS
export const ACTION_CREATE_MANUAL_CHAPTER = "manual_chapter__create";
export const ACTION_CREATE_MANUAL_SECTION = "manual_section__create";
export const ACTION_CREATE_MANUAL_SUBSECTION = "manual_subsection__create";
export const ACTION_CREATE_MANUAL_NESTED_SUBSECTION = "manual_nested_subsection__create";
export const ACTION_REPLACE_MANUAL_CHAPTER = "manual_chapter__replace";
export const ACTION_REORDER_MANUAL_CHAPTERS = "manual_chapter__reorder";
export const ACTION_REPLACE_MANUAL_SECTION = "manual_section__replace";
export const ACTION_REPLACE_MANUAL_SUBSECTION = "manual_subsection__replace";
export const ACTION_REPLACE_MANUAL_NESTED_SUBSECTION = "manual_nested_subsection__replace";
export const ACTION_DELETE_MANUAL_SECTION = "manual_section__delete";
export const ACTION_DELETE_MANUAL_SUBSECTION = "manual_subsection__delete";
export const ACTION_DELETE_MANUAL_NESTED_SUBSECTION = "manual_nested_subsection__delete";

// REFERENCES
export const ACTION_REPLACE_MANUAL_SECTION_REFERENCE = "manual_section_reference__replace";
export const ACTION_DELETE_MANUAL_SECTION_REFERENCE = "manual_section_reference__delete";

// COMMENTS
export const ACTION_SET_MANUAL_COMMENTS = "manual_comments__set";
export const ACTION_ADD_SECTION_COMMENT = "section_comment__add";
export const ACTION_REPLACE_SECTION_COMMENT = "section_comment__replace";
export const ACTION_RESOLVE_SECTION_COMMENT = "section_comment__resolve";
export const ACTION_REMOVE_COMMENT_REPLY = "comment_reply__remove";

const reorderManualSections = (sections, action) => {
  const orderById = action.meta.siblings?.reduce?.((accumulator, section) => ({
    ...accumulator,
    [section.ManualSection_ID]: section.ManualSection_Order
  }), {});
  if (!orderById) {
    return sections;
  }
  return sections.map(section => ({
    ...section,
    ManualSection_Order: orderById[section.ManualSection_ID]
  })).sort((s1, s2) => (
    s1.ManualSection_Order - s2.ManualSection_Order
  ))
};

const replaceSectionComments = (comments, updatedSectionComments) => {
  const manualSectionId = updatedSectionComments[0].ManualSection_ID
  const filteredComments = comments.filter(comment => comment.ManualSection_ID !== manualSectionId)
  return [...filteredComments, ...updatedSectionComments]
}

export const manualsInitialState = {
  cfrItems: null,
  cfrItemsByIdFlattened: null,
  manual: null,
  manualsIndex: null,
  users: null,
  comments: null,
};


/*
 * Reducer for data specific to Certification's Manuals ProgramComponent.
 */
export default function certificationManualsReducer(state, action) {
  switch (action.type) {
    case ACTION_SET_CFR_ITEMS:
      return {
        ...state,
        cfrItems: action.payload,
        cfrItemsByIdFlattened: mapToDictionary(
          flattenAssociations(action.payload, "CFR"),
          "CFR_ID"
        )
      };
    case ACTION_SET_MANUAL:
    case ACTION_REPLACE_MANUAL:
      return {
        ...state,
        manual: {
          ...state.manual,
          ...action.payload
        }
      };
    case ACTION_CREATE_MANUAL_CHAPTER:
    case ACTION_REPLACE_MANUAL_CHAPTER:
      return {
        ...state,
        manual: mergeAssociationReplace(
          [state.manual],
          action.payload,
          "Manual_ID",
          "ManualSection",
          "ManualSection_ID"
        )[0]
      };
    case ACTION_REORDER_MANUAL_CHAPTERS:
      return {
        ...state,
        manual: {
          ...state.manual,
          _associations: {
            ...state.manual._associations,
            ManualSection: reorderManualSections(
              state.manual._associations?.ManualSection,
              action
            ),
          }
        }
      }
    case ACTION_CREATE_MANUAL_SECTION:
      return {
        ...state,
        manual: {
          ...state.manual,
          _associations: {
            ...state.manual._associations,
            ManualSection: mergeParentKeyAssociationReplace(
              state.manual._associations?.ManualSection,
              action.payload,
              "ManualSection_ID",
              "Parent_ManualSection_ID",
              "ManualSection",
              "ManualSection_ID"
            ).map(chapter => {
              if (chapter.ManualSection_ID === action.payload.Parent_ManualSection_ID) {
                chapter._associations.ManualSection = (
                  reorderManualSections(chapter._associations.ManualSection, action)
                );
              }
              return chapter;
            }),
          }
        }
      };
    case ACTION_REPLACE_MANUAL_SECTION:
      return {
        ...state,
        manual: {
          ...state.manual,
          _associations: {
            ...state.manual._associations,
            ManualSection: mergeParentKeyAssociationReplace(
              state.manual._associations?.ManualSection,
              action.payload,
              "ManualSection_ID",
              "Parent_ManualSection_ID",
              "ManualSection",
              "ManualSection_ID"
            )
          }
        }
      };
    case ACTION_CREATE_MANUAL_SUBSECTION:
      return {
        ...state,
        manual: {
          ...state.manual,
          _associations: {
            ...state.manual._associations,
            ManualSection:
              sortNestedAssociations(
                function(section) {
                  if (section.ManualSection_ID === action.payload.Parent_ManualSection_ID) {
                    section._associations.ManualSection = reorderManualSections(
                      section._associations.ManualSection,
                      action
                    )
                  }
                  return section;
                },
                mergeNestedParentKeyAssociationReplace(
                  state.manual._associations?.ManualSection,
                  action.payload,
                  "ManualSection_ID",
                  "Parent_ManualSection_ID",
                  "ManualSection_ID",
                  "ManualSection",
                  "ManualSection",
                ),
                "ManualSection",
                "ManualSection",
              )
          }
        }
      };
    case ACTION_REPLACE_MANUAL_SUBSECTION:
      return {
        ...state,
        manual: {
          ...state.manual,
          _associations: {
            ...state.manual._associations,
            ManualSection: mergeNestedParentKeyAssociationReplace(
              state.manual._associations?.ManualSection,
              action.payload,
              "ManualSection_ID",
              "Parent_ManualSection_ID",
              "ManualSection_ID",
              "ManualSection",
              "ManualSection",
            )
          }
        }
      };
    case ACTION_DELETE_MANUAL_SECTION:
    case ACTION_DELETE_MANUAL_SUBSECTION:
    case ACTION_DELETE_MANUAL_NESTED_SUBSECTION:
      return {
        ...state,
        manual: deleteOneBySchema(state.manual, action, manualSectionAllLevelSchema)
      };

    case ACTION_CREATE_MANUAL_NESTED_SUBSECTION:
      return {
        ...state,
        manual: {
          ...state.manual,
          _associations: {
            ...state.manual._associations,
            ManualSection:
              sortNestedAssociations(
                function(section) {
                  if (section.ManualSection_ID === action.payload.Parent_ManualSection_ID) {
                    section._associations.ManualSection = reorderManualSections(
                      section._associations.ManualSection,
                      action
                    )
                  }
                  return section;
                },
                mergeNestedParentKeyAssociationReplace(
                  state.manual._associations?.ManualSection,
                  action.payload,
                  "ManualSection_ID",
                  "Parent_ManualSection_ID",
                  "ManualSection_ID",
                  "ManualSection",
                  "ManualSection",
                  "ManualSection",
                ),
                "ManualSection",
                "ManualSection",
                "ManualSection",
              )
          }
        }
      };

    case ACTION_REPLACE_MANUAL_NESTED_SUBSECTION:
      return {
        ...state,
        manual: {
          ...state.manual,
          _associations: {
            ...state.manual._associations,
            ManualSection: mergeNestedParentKeyAssociationReplace(
              state.manual._associations?.ManualSection,
              action.payload,
              "ManualSection_ID",
              "Parent_ManualSection_ID",
              "ManualSection_ID",
              "ManualSection",
              "ManualSection",
              "ManualSection"
            )
          }
        }
      };

    case ACTION_REPLACE_MANUAL_SECTION_REFERENCE:
      const manualWithCreatedReferences = mergeRecursiveReplace(
        state.manual,
        action.payload,
        "ManualSection",
        "ManualSection_ID",
        "ManualSectionReference",
        "ManualSectionReference_ID"
      );
      return {
        ...state,
        manual: !action.meta?.deletedSiblingIds?.length ?
          manualWithCreatedReferences : (
            deleteManyBySchema(
            manualWithCreatedReferences,
            { payload: action.meta?.deletedSiblingIds },
            manualReferenceSchema
            )
          )
      };
    case ACTION_DELETE_MANUAL_SECTION_REFERENCE:
      return {
        ...state,
        manual: deleteManyBySchema(state.manual, action, manualReferenceSchema)
      };
    case ACTION_SET_MANUALS_INDEX:
      return {
        ...state,
        manualsIndex: action.payload
      };

    case ACTION_SET_MANUAL_COMMENTS:
      return {
        ...state,
        comments: action.payload,
      };

    case ACTION_SET_USERS:
      return {
        ...state,
        users: action.payload
      };

    case ACTION_ADD_SECTION_COMMENT:
    case ACTION_REPLACE_SECTION_COMMENT:
      return {
        ...state,
        comments: replaceSectionComments(state.comments, action.payload)
      };

    case ACTION_REMOVE_COMMENT_REPLY:
      return {
        ...state,
        comments: mergeAssociationDelete(
          state.comments,
          action.payload,
          "ManualSectionComment",
          "ManualSectionComment_ID"
        )
      };

    case ACTION_RESOLVE_SECTION_COMMENT:
      return {
        ...state,
        comments: removeObjInArray([...state.comments], "ManualSectionComment_ID", action.payload)
      };

    default:
      return state;
  }
}
