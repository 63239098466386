import React from "react";

/*
 * Values:
 * {
 *   dispatch: asyncDispatch,
 *   state: ProgramsReducer
 * }
 */
const ProgramsContext = React.createContext();

export default ProgramsContext;
