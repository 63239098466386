import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Toolbar } from "@material-ui/core";
import SearchResult from "./searchResult.component";
import PageHeader from "components/utils/pageHeader.component";
import { makeStyles } from "@material-ui/core/styles";
import CustomContainer from "components/utils/container.component";
import variables from "styleVariables";
import SearchService from "services/search.service";
import Loader from "components/utils/loader.components";
import TitleMuiIcon from "components/utils/titleMuiIcon.component";
import { isGlobalUser } from "utils/roles";
import CollapsableAlert from "components/utils/collapsableAlert.component";

const useStyles = makeStyles((theme) => ({
  searchResultsContainer: {
    paddingBottom: 5,
    paddingLeft: 35,
    paddingRight: 20,
    margin: "0px auto",
  },
  searchAlertContent: {
    marginTop: 24
  },
  searchStatus: {
    backgroundColor: variables.primaryMain,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 3,
    minHeight: 44
  },
  searchTitle: {
    display: "block",
    maxWidth: 1200,
    color: "white",
    paddingLeft: 35,
    paddingRight: 30,
    fontSize: variables.fontLarge,
    fontWeight: 800,
    width: "100%",
  },
}));

const SearchResultPage = () => {
  const [results, setResults] = useState();
  const classes = useStyles();
  const [message, setMessage] = useState("");
  const [isSearching, setIsSearching] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const query = new URLSearchParams(useLocation().search);
  const search = query.get("keyword");


  useEffect(() => {
    const querySearch = async () => {
      setErrorMessage();
      /* TODO: when Portfolio Program is searchable, we need to allow non-global users to retrieve results but refine them by the program scope */
      if (!isGlobalUser()) {
        setResults([]);
        setMessage(`No results for "${search}"`);
        setIsSearching(false);
        return;
      }
      try {
        setIsSearching(true)
        if (search) {
          const searchRes = await SearchService.getByKeyword(search);
          const searchPayload = searchRes.data.payload;
          setResults(searchPayload);
          if (searchPayload.length === 0) {
            setMessage(`No results for "${search}"`);
          } else {
            setMessage(`${searchPayload.length} results for "${search}":`);
          }
        } else if (!search || search.trim() === "") {
          setMessage("Enter Search Above");
          setResults(false);
        }
      } catch (error) {
        setErrorMessage("Search functionality is currently unavailable.");
        setMessage("");
      } finally {
        setIsSearching(false);
      }
    };

    querySearch();
  }, [search]);

  return (
    <CustomContainer className={classes.mainContentContainer}>
      <Toolbar />
      <PageHeader
        title="Search"
        titleOnly
        startIcon={<TitleMuiIcon variant="search" />}
      />
      <div className={classes.searchStatus}>
        <div className={classes.searchTitle} data-cy="search-result-message">
          {message}
        </div>
      </div>
      <CollapsableAlert
        showAlert={!!errorMessage}
        closeClick={setErrorMessage}
        message={errorMessage}
        contentClass={classes.searchAlertContent}
        severity="error"
      />
      {!!isSearching && (
        <Loader variant="search" size={14} />
      )}
      {results ? (
        <div
          className={classes.searchResultsContainer}
          data-cy="container-searchResults"
        >
          {results.map((result) => (
            <SearchResult result={result} key={result.id} searchTerm={search} />
          ))}
        </div>
      ) : (
        ""
      )}
    </CustomContainer>
  );
};

export default SearchResultPage;
