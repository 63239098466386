import { LinearProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const CustomLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    boxShadow: "inset 1px 1px 3px 1px rgba(22,76,146,0.42)",
  },
  colorPrimary: {
    backgroundColor: "rgba(119, 197, 253, 0.3)",
  },
  bar: {
    borderRadius: 5,
    background: "linear-gradient(180deg, rgba(64, 145, 206, 1) 10%, rgba(120,197,253,1) 60%, rgba(0, 122, 204, 1) 100%)",
  },
}))(LinearProgress);

export default CustomLinearProgress
