import React, { useMemo } from "react"
import { makeStyles } from "@material-ui/core/styles";
import styleVariables from "styleVariables";
import {
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
  Paper
} from "@material-ui/core";
import TableCell from "components/utils/tables/shared/tableCell.component";
import classNames from "classnames";
import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import WorkpackageService from "services/workpackage.service";
import Loader from "components/utils/loader.components";
import moment from "moment";
import CustomLink from "components/utils/link.component";
import CustomModal from "components/utils/modal.component";
import TaskForm from "components/forms/taskForm.component";
import AddListItem from "components/utils/addListItem.component";
import WorkPackagesForm from "components/forms/workPackageForm.component";
import { downloadFileByRef } from 'utils/downloadFile';
import ReferenceForm from "components/certification/applicationSchedule/referenceForm.component";
import { CERTIFICATION_PROGRAM_ID } from "utils/certificationConstants";
import { H6 } from "components/utils/headerV2.component";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: 50,
  },
  tableCell: {
    padding: 5,
  },
  emptyListRow: {
    color: styleVariables.textSecondary,
    display: "flex",
    justifyContent: "center",
  },
  //TABLE
  //HEAD
  cellHeader: {
    backgroundColor: styleVariables.tertiary1,
    color: "white",
    fontWeight: "bold",
    fontSize: styleVariables.fontMedium,
    padding: "8px 16px",
    lineHeight: "normal",
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  //BODY
  cellBody: {
    //default cell body
    padding: "8px 16px",
    color: styleVariables.textSecondary,
    fontFamily: "Helvetica",
    fontSize: styleVariables.fontSmall,
    borderLeft: "1px solid rgba(22, 76, 146, 0.22)",
    verticalAlign: "top",
  },
  //ALIGN
  centerAlign: { textAlign: "center" },
  bottomBorder: {
    borderBottom: `2px solid rgba(22, 76, 146, 0.33)`,
  },
  dateCell: {
    width: "7%"
  },
  notesCell: {
    width: "25%"
  },
  taskCell: {
    width: "15%"
  },
  workpackageCell: {
    width: "10%",
    backgroundColor: "white"
  },
  referencesCell: {
    width: "25%"
  },
  link: {
    marginTop: 15,
    marginLeft: 25
  },
  addReference: {
    marginTop: 10
  },
  linkWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 32,
  },
  maxHeightModal: {
    maxHeight: 400
  },
  referenceList: {
    paddingLeft: 15,
    marginTop: 0,
  },
  documentList: {
    paddingLeft: 15,
    marginTop: 0,
    minWidth: 200,
  }
}));

const CELL_TYPE_DATE = "date";
const CELL_TYPE_NOTES = "notes";
const CELL_TYPE_REFERENCES = "references";
const CELL_TYPE_TASK = "task";
const CELL_TYPE_WORKPACKAGE = "workpackage";

const tableHeaders = [
  {
    fieldName: "Workpackage_ID",
    display: "Workpackage",
    align: "left",
    type: CELL_TYPE_WORKPACKAGE
  },
  {
    fieldName: "Name",
    display: "Task",
    align: "left",
    type: CELL_TYPE_TASK
  },
  {
    fieldName: "Proposed_Start",
    display: "Proposed Start",
    align: "center",
    type: CELL_TYPE_DATE
  },
  {
    fieldName: "Proposed_Completion",
    display: "Proposed End",
    align: "center",
    type: CELL_TYPE_DATE
  },
  {
    fieldName: "Actual_Start",
    display: "Actual Start",
    align: "center",
    type: CELL_TYPE_DATE
  },
  {
    fieldName: "Actual_Completion",
    display: "Actual End",
    align: "center",
    type: CELL_TYPE_DATE
  },
  {
    fieldName: "Task_Notes",
    display: "Notes",
    align: "left",
    type: CELL_TYPE_NOTES
  },
  {
    fieldName: "_associations",
    display: "References",
    align: "left",
    type: CELL_TYPE_REFERENCES
  }
];

const ApplicationSchedule = ({
  headers = tableHeaders,
}) => {
  const classes = useStyles();
  const [workpackages, setWorkpackages] = useState();
  const [openTaskModal, setOpenTaskModal] = useState("");
  const [openWorkpackageModal, setOpenWorkpackageModal] = useState("");
  const [openReferenceModal, setOpenReferenceModal] = useState(false);
  const [taskData, setTaskData] = useState("");
  const [workpackageData, setWorkpackageData] = useState("");
  const [formMode, setFormMode] = useState("");
  const [workpackageFormMode, setWorkpackageFormMode] = useState("");
  const [wpDashRefresh, setWpDashRefresh] = useState(false);
  const { programId } = useParams();

  useEffect(() => {
    WorkpackageService.getWorkpackageAndTaskByProgramId(programId).then((res) => {
      setWorkpackages(res.payload);
    });
  }, [programId, wpDashRefresh]);

  const handleEditTaskSuccess = useCallback(async (newTask) => {
    WorkpackageService.getWorkpackageAndTasksById(newTask.Workpackages_Workpackage_ID).then((res) => {
      const changedIndex = workpackages.findIndex(possibleItem => (
        res.payload[0].Workpackage_ID === possibleItem.Workpackage_ID
      ));
      const newWorkpackages = [...workpackages]
      newWorkpackages.splice(changedIndex, 1, res.payload[0]);
      setOpenReferenceModal(false);
      setWorkpackages(newWorkpackages);
    })
  }, [workpackages, setWorkpackages]);

  const handleEditWorkpackageSuccess = useCallback(async () => {
    WorkpackageService.getWorkpackageAndTaskByProgramId(programId).then((res) => {
      setWorkpackages(res.payload);
    });
  }, [programId, setWorkpackages]);

  const openTaskForm = (task) => {
    setOpenTaskModal(true);
    if (task) {
      setTaskData(task);
      setFormMode("edit")
    } else {
      setTaskData();
      setFormMode("create")
    }
  };

  const openWorkpackageForm = (workpackage) => {
    setOpenWorkpackageModal(true);
    if (workpackage) {
      setWorkpackageData(workpackage);
      setWorkpackageFormMode("edit")
    } else {
      setWorkpackageData();
      setWorkpackageFormMode("create")
    }
  };

  const openReferenceForm = (task) => {
    setOpenReferenceModal(true)
    if (task) {
      setTaskData(task)
    }
  }

  if (workpackages) {
    return (
      <>
        <TableContainer
          component={Paper}
          className={classes.tableContainer}
          data-cy="schedule-table-container"
        >
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((head, index) => {
                  const { display, fieldName, align } = head;
                  return (
                    <TableCell
                      key={fieldName}
                      className={classNames(
                        classes.cellHeader,
                        align === "center" && classes.centerAlign
                      )}
                    >
                      {display}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody className={classes.orderedList}>
              {workpackages?.map((workpackage, wpkgIdx) => {
                const tasksByWp = workpackage._associations.Tasks
                if (tasksByWp.length === 0) {
                  return (
                    <TableRow
                      className={
                        classNames(
                          wpkgIdx !== (workpackages.length - 1) &&
                            classes.bottomBorder
                        )
                      }
                      key={workpackage.Workpackage_ID}
                    >
                      <TableCell className={classNames(classes.cellBody, classes.workpackageCell)}>
                        <CustomLink
                          variant="underline"
                          onClick={() => openWorkpackageForm(workpackage)}
                        >
                          {workpackage.Name}
                        </CustomLink>
                      </TableCell>
                      <TableCell className={classes.tableCell} colSpan={headers.length - 1}>
                        <div className={classes.emptyListRow}>No Tasks</div>
                      </TableCell>
                    </TableRow>
                  )
                }
                return tasksByWp.map((task, taskIdx) => {
                  return (
                    <TableRow key={task.Task_ID} data-cy={`tr-taskByWp-${task.Task_ID}`}>
                      {headers.map((head) => {
                        if (head.type === CELL_TYPE_WORKPACKAGE) {
                          return taskIdx === 0 ?
                            <TableCell
                              key={head.fieldName}
                              className={
                                classNames(
                                  classes.cellBody,
                                  classes.bottomBorder,
                                  classes.workpackageCell
                                )
                              }
                              rowSpan={tasksByWp.length}
                            >
                              <CustomLink
                                variant="underline"
                                onClick={() => openWorkpackageForm(workpackage)}
                              >
                                {workpackage.Name}
                              </CustomLink>
                            </TableCell> :
                            null
                        } else {
                          return (
                            <TableCell
                              key={head.fieldName}
                              className={classNames(
                                classes.cellBody,
                                head.align === "center" && classes.centerAlign,
                                head.type === CELL_TYPE_DATE && classes.dateCell,
                                head.type === CELL_TYPE_NOTES && classes.notesCell,
                                head.type === CELL_TYPE_TASK && classes.taskCell,
                                head.type === CELL_TYPE_REFERENCES && classes.referencesCell,
                                taskIdx === tasksByWp.length - 1 && classes.bottomBorder,
                              )}
                            >
                              <DisplayCellData
                                cellData={task}
                                cellType={head.type}
                                fieldName={head.fieldName}
                                onOpenTask={openTaskForm}
                                onOpenReferences={openReferenceForm}
                              />
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  )
                })
              })}
              {workpackages?.length === 0 && (
                <TableRow key="empty-workpackages-row">
                  <TableCell className={classes.tableCell} colSpan={headers.length}>
                    <div className={classes.emptyListRow}>No data available</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.linkWrapper}>
          <AddListItem
            className={classes.link}
            variant="underline"
            onClick={() => {
              openWorkpackageForm()
            }}
            test="new-wp"
          >
            Add Workpackage
          </AddListItem>
          <AddListItem
            className={classes.link}
            variant="underline"
            onClick={() => {
              openTaskForm()
            }}
            test="new-task"
          >
            Add Task
          </AddListItem>
        </div>
        <CustomModal open={openWorkpackageModal} onClose={() => setOpenWorkpackageModal(false)}>
          <WorkPackagesForm
            setEditModal={setOpenWorkpackageModal}
            programId={programId}
            setWpDashRefresh={setWpDashRefresh}
            wpDashRefresh={wpDashRefresh}
            workpackageData={workpackageData}
            workpackageFormMode={workpackageFormMode}
            parentComponent="ApplicationSchedule"
            onSuccess={handleEditWorkpackageSuccess}
          />
        </CustomModal>
        <CustomModal open={openTaskModal} onClose={() => setOpenTaskModal(false)}>
          <TaskForm
            setEditModal={setOpenTaskModal}
            formMode={formMode}
            formType={CELL_TYPE_TASK}
            taskData={taskData}
            setTaskData={setTaskData}
            parentComponent="ApplicationSchedule"
            onSuccess={handleEditTaskSuccess}
          />
        </CustomModal>
        <div className={classes.maxHeightModal}>
          <CustomModal open={openReferenceModal} onClose={() => setOpenReferenceModal(false)}>
            <ReferenceForm
              setEditModal={setOpenReferenceModal}
              parentComponent="ApplicationSchedule"
              task={taskData}
              onSuccess={handleEditTaskSuccess}
            />
          </CustomModal>
        </div>

      </>
    );
  } else {
    return <Loader />;
  }
};
export default ApplicationSchedule;


const DisplayCellData = props => {
  const classes = useStyles();
  const {
    cellData, cellType, fieldName, onOpenTask, onOpenReferences
  } = props;
  const taskReferences = useMemo(() => (
    !cellData?.[fieldName]?.TaskReferences ? [] : (
      cellData[fieldName].TaskReferences.filter(reference => (
        reference.Reference_Item
      )).map(reference => ({
        ...reference,
        _json: {
          Reference_Item: JSON.parse(reference.Reference_Item)
        }
      })) || []
    )
  ), [cellData, fieldName]);

  const programDocReferences = useMemo(() => (
    taskReferences.filter(reference => (
      reference._json.Reference_Item?.ProgramDoc_ID
    ))
  ), [taskReferences]);

  const linkReferences = useMemo(() => (
    taskReferences.filter(reference => (
      !reference._json.Reference_Item?.ProgramDoc_ID
    ))
  ), [taskReferences]);


  switch(cellType) {
    case CELL_TYPE_DATE:
      if (cellData[fieldName]) {
        return moment(cellData[fieldName]).format("MM-DD-YYYY");
      }
      return "No Date Provided";
    case CELL_TYPE_TASK:
      return (
        <CustomLink
          variant="underline"
          onClick={() => onOpenTask(cellData)}
        >
          {cellData.Name}
        </CustomLink>
      );
    case CELL_TYPE_REFERENCES:
      return (
        <>
          {taskReferences.length > 0 && (
            <div className={classes.referenceDiv} data-cy={`refs-wrapper-task-${cellData?.Task_ID}`}>
              {programDocReferences.length > 0 && (
                <div>
                  <H6 color="tertiary">Documents</H6>
                  <ul className={classes.documentList}>
                    {programDocReferences.map((ref) => (
                      <li key={ref.Reference_ID}>
                        <CustomLink
                          variant="underline"
                          onClick={() => (
                            downloadFileByRef(ref._json.Reference_Item.File_Ref)
                          )}
                        >
                          {ref._json.Reference_Item.displayValue}
                        </CustomLink>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {linkReferences.length > 0 && (
                <div>
                  <H6 color="tertiary">Links</H6>
                  <ul className={classes.referenceList}>
                    {linkReferences.map((ref) => {
                      const {
                        componentObjectId,
                        DctElement_ID,
                        displayValue,
                        linkId,
                        Manual_ID,
                        progCompId,
                        Program_Program_ID,
                        Task_ID,
                        type,
                        Workpackages_Workpackage_ID
                      } = ref._json.Reference_Item;

                      let path;
                      if (Task_ID && Workpackages_Workpackage_ID) {
                        path = `/wpTasks/${Workpackages_Workpackage_ID}/program/${Program_Program_ID}`
                      } else if (Task_ID && !Workpackages_Workpackage_ID) {
                        path = `programTasks/${Task_ID}/program/${Program_Program_ID}`
                      } else if (Manual_ID) {
                        path = `/program/${CERTIFICATION_PROGRAM_ID}/${progCompId}/manual/${Manual_ID}`
                      } else if (DctElement_ID) {
                        path = `/program/${CERTIFICATION_PROGRAM_ID}/${progCompId}/dct-category/${DctElement_ID}/personal`
                      } else if (componentObjectId) {
                        path = `/program/${CERTIFICATION_PROGRAM_ID}/${progCompId}/${componentObjectId}`
                      } else {
                        path = `/program/${CERTIFICATION_PROGRAM_ID}/${progCompId}`
                      }
                      return (
                        <li key={ref.Reference_ID}>
                          <CustomLink
                            variant="routerLink"
                            to={{
                              pathname: path,
                              //Used to highlight table rows on navigation
                              state: { itemId: linkId, taskID: Task_ID }
                            }}
                          >
                            {type}: {displayValue}
                          </CustomLink>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          )}
          <AddListItem
            className={taskReferences.length > 0 ? classes.addReference : null}
            variant="underline"
            onClick={() => {
              onOpenReferences(cellData)
            }}
            test="task-references"
          >
            {taskReferences.length > 0 ? "Update References" : "Add References"}
          </AddListItem>
        </>
      )
    default:
      return cellData[fieldName];
  }
}
