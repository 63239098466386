import useNumericParams from "hooks/useNumericParams";
import { PROGRAM_ID_ORCHESTRATION, PROGRAM_ID_PORTFOLIO } from "utils/programConstants";
import PortfolioContextProvider from "components/portfolio/shared/portfolioContextProvider.component";

/*
 * Context Providers for specific Programs.
 * Use for data shared across all ProgramComponents of a Program.
 */
export default function InnerProgramContextProviders({ children }) {
  const { programId } = useNumericParams(true);

  switch (programId) {
    case PROGRAM_ID_PORTFOLIO:
      return (
        <PortfolioContextProvider>
          {children}
        </PortfolioContextProvider>
      );
    case PROGRAM_ID_ORCHESTRATION:
    default:
      return children;
  }
}
