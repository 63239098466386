import React from "react";
import LabelInput from "components/utils/form-elements/labelInput.component";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import DateInput from "components/utils/form-elements/dateInput.component";

const useStyles = makeStyles((theme) => ({
  stepOneContainer: {
    maxWidth: 1200,
    width: "100%",
    margin: "0px auto",
  },
  textFieldWrapper: {
    marginTop: 10,
  },
  datePickerWrapper: {
    marginTop: 0,
    marginLeft: 20,
    "&:last-of-type": {
      marginRight: 0,
    },
  },
  fullWidth: {
    width: "100%",
  },
  row: {
    display: "flex",
  },
  medPaddingTop: {
    paddingTop: 20,
  },
  xsWidth: {
    minWidth: 200,
  },
  multilineContainer: {
    minHeight: 600,
  },
}));

const AssessmentBuilderBasicInfo = ({ setRecordFormData, recordFormData, formErrors, handleSave }) => {
  const classes = useStyles();

  const handleChange = (event) => {
    // setIsSaving(false)
    setRecordFormData({
      ...recordFormData,
      [event.currentTarget.id]: event.target.value,
    });
  };

  return (
    <div className={classes.stepOneContainer}>
      <div className={classNames(classes.fullWidth, classes.textFieldWrapper)}>
        <div className={classNames(classes.fullWidth, classes.row)}>
          <LabelInput
            required
            id="Title"
            onChange={(event) => handleChange(event)}
            onAutosave={handleSave}
            error={!!formErrors?.Title}
            errorMessage={formErrors?.Title}
            label="Title"
            variant="default"
            test="builder-Title"
            value={recordFormData.Title}
          />
          <div
            className={classNames(
              classes.xsWidth,
              classes.textFieldWrapper,
              classes.datePickerWrapper
            )}
          >
            <DateInput
              id="Start_Date"
              label="Start Date"
              error={!!formErrors?.Start_Date}
              errorMessage={formErrors?.Start_Date}
              onChange={(event) => handleChange(event)}
              onAutosave={handleSave}
              placeholder="yyyy-mm-dd" //For Safari
              value={recordFormData.Start_Date}
              test="builder-StartDate"
            />
          </div>
          <div
            className={classNames(
              classes.xsWidth,
              classes.textFieldWrapper,
              classes.datePickerWrapper
            )}
          >
            <DateInput
              id="End_Date"
              label="End Date"
              error={!!formErrors?.End_Date}
              errorMessage={formErrors?.End_Date}
              onChange={(event) => handleChange(event)}
              onAutosave={handleSave}
              placeholder="yyyy-mm-dd" //For Safari
              value={recordFormData.End_Date}
              test="builder-EndDate"
            />
          </div>
        </div>

        <div
          className={classNames(classes.fullWidth, classes.medPaddingTop, classes.multilineContainer)}
        >
          <LabelInput
            id="Description"
            onChange={(event) => handleChange(event)}
            onAutosave={handleSave}
            label="Description"
            variant="default"
            value={recordFormData.Description}
            multiline
            rows={4}
            test="builder-Description"
          />
        </div>
      </div>
    </div>
  );
};

export default AssessmentBuilderBasicInfo;
