import React, { useMemo, useEffect } from "react";
import PortCoCard from "../shared/portCo/portCoCard.component";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/utils/header.component";
import variables from "styleVariables";
import PortfolioService from "services/portfolio.service";
import { useState } from "react";
import { Typography } from "@material-ui/core";
import ButtonDefault from "components/utils/buttonDefault.component";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import PersonIcon from "@material-ui/icons/Person";
import { makeInitialDemoFormData } from "utils/portfolioDemoHelpers";
import {
  ACTION_RESET_UI_STATE,
  ACTION_SET_ANY,
  demographyInitialState,
} from "reducers/portfolioDemos.reducer";
import authService from "services/auth.service";
import { setStateFetchEffect } from "utils/ajaxHelpers";

const useStyles = makeStyles((theme) => ({
  portCardsContainer: {
    marginTop: 40,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "stretch",
  },
  row: {
    display: "inline-flex",
    alignItems: "center",
    marginTop: 5,
  },
  contents: {
    paddingBottom: 5,
    display: "flex",
    flexDirection: "column",
  },
  userIcon: {
    fill: variables.tertiary1,
    fontSize: "1.3rem",
    fontWeight: 500,
  },
  buttonContainerTop: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: 20,
    position: "absolute",
    right: 0,
    zIndex: 101, //PortCoCard is set at 100
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
      marginBottom: 30,
    },
  },
  userLabel: {
    fontSize: variables.fontMedium,
    color: variables.tertiary1,
    paddingLeft: 5,
    lineHeight: "normal",
  },
}));

const DemographicsDashboard = ({ reducerState, goToPortCoDemo, dispatch }) => {
  const classes = useStyles();
  const { portCoList } = reducerState;

  useEffect(() => {
    dispatch({ type: ACTION_RESET_UI_STATE });
  }, [dispatch, portCoList]);

  return (
    <div className={classes.portCardsContainer}>
      {portCoList.map((portCo, index) => {
        return (
          <DemoCard
            key={portCo.PortCo_ID}
            index={index}
            portCo={portCo}
            goToPortCoDemo={goToPortCoDemo}
          />
        );
      })}

      <AddPortCoButton
        dispatch={dispatch}
        state={reducerState}
        goToPortCoDemo={goToPortCoDemo}
      />
    </div>
  );
};

const makeUserCountText = (count) => {
  if (!count) {
    return "0 Users";
  } else if (count === 1) {
    return "1 User";
  } else {
    return `${count} Users`;
  }
};

const DemoCard = ({ index, portCo, goToPortCoDemo }) => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);

  useEffect(() => (
    //TODO Create getUsersByHolderID and call it in the Demographics component
    setStateFetchEffect(
      PortfolioService.getUsersByPortCoID(portCo.PortCo_ID),
      ([portCosResponse]) => setUsers(portCosResponse.payload)
    )
  ), [portCo]);

  return (
    <PortCoCard
      index={index}
      portCo={portCo}
      onClick={() => goToPortCoDemo(portCo.PortCo_ID)}
    >
      <div className={classes.contents}>
        <Header variant="h3Primary">{portCo.Name}</Header>
        <div className={classes.row}>
          <PersonIcon className={classes.userIcon} />
          <Typography className={classes.userLabel}>
            {makeUserCountText(users?.length)}
          </Typography>{" "}
        </div>
      </div>
    </PortCoCard>
  );
};

export const AddPortCoButton = ({
  dispatch,
  state,
  goToPortCoDemo,
  resetUserFormStatus,
  switchPortCoDemo,
}) => {
  const classes = useStyles();

  const { allUsers, demoFormObj, portCoList, selectedPortCo } = state;

  const isHoldingRead = useMemo(() => (
    authService.checkPermissions([authService.Type.HOLDINGREAD])
  ), []);

  const addNewClick = () => {
    dispatch({
      type: ACTION_SET_ANY,
      payload: {
        ...demographyInitialState,
        demoFormObj: makeInitialDemoFormData(),
        allUsers,
        portCoList,
      },
    });
    resetUserFormStatus?.();
    if (switchPortCoDemo) {
      switchPortCoDemo("new");
    } else {
      goToPortCoDemo("new");
    }
  };

  return (
    <div className={classes.buttonContainerTop}>
      <ButtonDefault
        variant="medium"
        background="tertiary"
        startIcon={<LibraryAddIcon />}
        onClick={addNewClick}
        disabled={isHoldingRead || (!selectedPortCo?.PortCo_ID && demoFormObj)}
        disableReadOnlyUsers
        fixedRight
      >
        Add Portfolio Company
      </ButtonDefault>
    </div>
  );
};

export default DemographicsDashboard;
