import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { H2 } from "components/utils/headerV2.component";
import { HistoryOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: 8,
  },
  headerIconWrapper: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 8,
    height: 30,
    width: 30,
    padding: 8,
    borderRadius: 50,
  },
  headerIcon: {
    height: 22,
    width: 22,
  },
}));

const IconH2 = ({
  IconComponent = HistoryOutlined,
  children,
  color = "primary",
  margin,
  marginTop,
  marginBottom,
}) => {
  const classes = useStyles();

  return (
    <Box marginTop={marginTop} marginBottom={marginBottom} margin={margin}>
      <H2
        className={classes.header}
        color={color}
        startIcon={
          <div className={classes.headerIconWrapper}>
            <IconComponent className={classes.headerIcon} />
          </div>
        }
      >
        {children}
      </H2>
    </Box>
  );
};

export default IconH2;
