import React, { useState, useEffect, useCallback, useReducer, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  isSectionHeaderMissing,
  makeSectionClientId,
  isMissingVersion,
} from "utils/builderFunctions";
import BuilderService from "services/builder.service";
import variables from "styleVariables";
import classNames from "classnames";
import Loader from "components/utils/loader.components";
import CustomLink from "components/utils/link.component";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import BuilderHeaderInfo from "components/builder/shared/builderHeaderInfo.component";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import SettingsApplicationsOutlinedIcon from "@material-ui/icons/SettingsApplicationsOutlined";
import DeleteIconButton from "components/utils/deleteIconButton.component";
import BuilderSectionHeader from "../shared/builderSectionHeader.component";
import BuilderSectionRTE from "../shared/builderSectionRTE.component";
import useNumericParams from "hooks/useNumericParams";
import {
  wpButtonSectionHeader,
  builderModes,
  wpButtonTypeClientIds,
  builderSectionTypes,
  firstWPButtonsSection,
} from "utils/builderConstants";
import builderReducer, {
  ACTION_SET_BUILDER_IS_SAVING,
  ACTION_SET_BUILDER_AFTER_SAVE,
  builderInitialState,
  ACTION_SET_BUILDER_FAILED_SAVE,
  ACTION_SET_MISSING_HEADER_MESSAGE,
  ACTION_SET_BUILDER_LOAD_TEMPLATE,
  ACTION_ADD_PLAN_BUILDER_SECTION,
  ACTION_SET_PLAN_WORKPACKAGES,
  ACTION_ADD_PLAN_SECTION_WP_BUTTON,
  ACTION_DELETE_WP_SECTION,
  ACTION_DELETE_PLAN_SECTION,
  ACTION_SET_BUILDER_EDIT_CURRENT,
  ACTION_SET_BUILDER_COPY_PREVIOUS,
  ACTION_SET_MISSING_WP_OR_TASK_NAME,
} from "reducers/builder.reducer";
import BuilderButtonssColumn from "../shared/builderButtonsColumn.component";
import BuilderHeader from "../shared/builderHeader.component";
import Header from "components/utils/header.component";
import BuilderWp from "./builderWp.component";
import CustomModal from "components/utils/modal.component";
import CreateOrExistingWpForm from "components/forms/createOrExistingWpForm";
import {
  attachMetaToPlanBuilderSections,
  attachMetaToLoadedTasks,
  attachMetaToLoadedWps,
  isWpOrTaskNameMissing,
} from "utils/planBuilderFunctions";
import { taskStatuses } from "utils/taskConstants";
const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  innerContentContainer: {
    paddingRight: 170,
    paddingBottom: 20,
  },
  sectionWrapper: {
    marginBottom: 40,
  },
  addWpContainer: {
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 10,
    maxWidth: 1200,
    marginLeft: "auto",
    position: "relative",
  },
  workpackageIcon: {
    fontSize: variables.fontLarge,
    marginRight: 5,
  },
  workpackageSectionIcon: {
    fontSize: variables.fontMedium,
    marginRight: 5,
  },
  workpackageSectionIconLarge: {
    fontSize: variables.fontH3,
    marginRight: 5,
  },
  wpSectionHeader: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 20,
  },
  addSectionWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "left",
    marginBottom: 50,
    marginTop: 20,
  },
  deleteSectionWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  addSectionIcon: {
    fontSize: variables.fontMedium,
    color: "white",
    marginRight: 6,
  },
}));

const isWpSection = (section) => {
  return section.Type === "workpackage";
};

const PlanBuilder = (props) => {
  const classes = useStyles();
  const componentObject = props.componentObject;
  const { programId } = useNumericParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [wpModal, setWpModal] = useState(false);
  const [addWpSection, setAddWpSection] = useState(); // Order passed in for adding new Wps
  const [state, dispatch] = useReducer(builderReducer, builderInitialState);
  const {
    builderInfo,
    builderSections,
    builderInfoErrors,
    activeForm,
    loadedVersion,
    statusMessage,
    workpackages,
    tasks,
    tasksToDeleteOnCreate,
    disableBuilder,
    previousVersions,
  } = state;

  useEffect(() => {
    if (props.mode === builderModes.LOAD_TEMPLATE) {
      const newBuilder = {
        ComponentObjects_ComponentObject_ID:
          props.chosenProgram.compObjId || componentObject.ComponentObject_ID,
        Title: "",
        Description: null,
        Version: "0.1",
        Approval_Date: moment(new Date()).format("YYYY-MM-DD"),
        Status: "Active",
        Type: props.type,
        ParentBuilderDoc_ID: null,
        BuilderDoc_ID: null,
        Show_Approval_Sections: 1,
      };

      const wpButtonSections = [
        firstWPButtonsSection,
        {
          Section_Order: 1,
          SectionContent: "",
          SectionHeader: wpButtonSectionHeader,
          Type: builderSectionTypes.WP_BUTTONS,
          _meta: {
            clientId: wpButtonTypeClientIds.WP,
          },
        },
        {
          Section_Order: 2,
          SectionContent: "",
          SectionHeader: "Add Section",
          Type: builderSectionTypes.WP_BUTTONS,
          _meta: {
            clientId: wpButtonTypeClientIds.LAST,
          },
        },
      ];

      dispatch({
        type: ACTION_SET_BUILDER_LOAD_TEMPLATE,
        payload: {
          builderInfo: newBuilder,
          builderSections: wpButtonSections,
          workpackages: [],
          tasks: [],
          tasksToDeleteOnCreate: [],
        },
      });
    }
  }, [
    componentObject.ComponentObject_ID,
    props.chosenProgram.compObjId,
    props.mode,
    props.type,
  ]);

  useEffect(() => {
    if (props.mode === builderModes.EDIT_CURRENT) {
      const getPolicyToEdit = async () => {
        const [
          builderDataRes,
          builderSectionDataRes,
          workpackageRefRes,
          builderTasksRes,
        ] = await Promise.all([
          BuilderService.getBuilderById(props.builder.Builder_ID),
          BuilderService.getBuilderSections(props.builder.Builder_ID),
          BuilderService.getBuilderRegRefs(props.builder.Builder_ID),
          BuilderService.getBuilderWpTasks(props.builder.Builder_ID),
        ]);

        const formattedBuilderSections = attachMetaToPlanBuilderSections(
          builderSectionDataRes.payload
        );

        const formattedWps = attachMetaToLoadedWps(workpackageRefRes.payload);
        const formattedTasks = attachMetaToLoadedTasks(builderTasksRes.payload);

        dispatch({
          type: ACTION_SET_BUILDER_EDIT_CURRENT,
          payload: {
            builderInfo: builderDataRes.payload,
            builderSections: formattedBuilderSections,
            workpackages: formattedWps,
            tasks: formattedTasks,
            builderInfoErrors: isMissingVersion(builderDataRes.payload.Version),
            previousVersions: props.builder.previousVersions,
          },
        });
      };
      getPolicyToEdit();
    }
  }, [
    props.builder?.previousVersions,
    props.builder?.Builder_ID,
    componentObject.ComponentObject_ID,
    props.chosenProgram.compObjId,
    props.mode,
    props.type,
  ]);

  useEffect(() => {
    if (props.mode === builderModes.COPY_PREV_BUILDER) {
      const getPlanToCopy = async () => {
        const [
          builderToCopyDataRes,
          builderSectionsToCopyRes,
          workpackagesToCopyRefRes,
          builderTasksToCopyRes,
        ] = await Promise.all([
          BuilderService.getBuilderById(props.prevBuilderId),
          BuilderService.getBuilderSections(props.prevBuilderId),
          BuilderService.getBuilderRegRefs(props.prevBuilderId),
          BuilderService.getBuilderWpTasks(props.prevBuilderId),
        ]);
        const currentBuilderId = props.builderBeingOverwrittenByCopy.Builder_ID;

        const newBuilder = {
          ...builderToCopyDataRes.payload,
          Builder_ID: currentBuilderId,
          Status: "Active",
          Version: null,
          Approval_Date: null,
          Show_Approval_Sections: 1,
        };
        // Builder_Builder_ID in sections get assigned value on backend
        const formattedBuilderSections = builderSectionsToCopyRes.payload.map(
          (sectionData, index) => ({
            ...sectionData,
            BuilderSection_ID: null,
            _meta: { clientId: makeSectionClientId(null, index) },
          })
        );

        const formattedWps = attachMetaToLoadedWps(workpackagesToCopyRefRes.payload, currentBuilderId);
        const formattedTasks = attachMetaToLoadedTasks(builderTasksToCopyRes.payload);

        dispatch({
          type: ACTION_SET_BUILDER_COPY_PREVIOUS,
          payload: {
            builderInfo: newBuilder,
            builderSections: formattedBuilderSections,
            workpackages: formattedWps,
            tasks: formattedTasks,
            loadedVersion: builderToCopyDataRes.payload.Version,
            previousVersions: props.builder.previousVersions,
          },
        });
      };
      getPlanToCopy();
    }
  }, [
    props.prevBuilderId,
    componentObject.ComponentObject_ID,
    props.chosenProgram.compObjId,
    props.mode,
    props.type,
    props.builderBeingOverwrittenByCopy,
    props.builder?.previousVersions,
  ]);

  const existingWorkpackageIdsInPlan = useMemo(() => {
    return workpackages?.filter(wp => wp.Workpackage_ID)
      ?.map((wp) => wp.Workpackage_ID) || [];
  }, [workpackages]);


  const addNewSection = (sectionOrder) => {
    const newSection = {
      Section_Order: sectionOrder + 1,
      SectionContent: "",
      SectionHeader: "",
      Type: null,
      _meta: {
        clientId: makeSectionClientId(),
      },
    };
    dispatch({
      type: ACTION_ADD_PLAN_BUILDER_SECTION,
      payload: newSection,
    });
  };

  const addSectionWpButtonClick = (section) => {
    dispatch({
      type: ACTION_ADD_PLAN_SECTION_WP_BUTTON,
      payload: section,
    });
  };

  const deleteWpSection = (sectionToDelete) => {
    dispatch({
      type: ACTION_DELETE_WP_SECTION,
      payload: sectionToDelete,
    });
  };

  const deleteSection = (section) => {
    dispatch({
      type: ACTION_DELETE_PLAN_SECTION,
      payload: section,
    });
  };
  // ================ onBLURS ================= \\

  const wpHandler = (event, tempWpId) => {
    event.preventDefault();
    const indexToUpdate = workpackages.findIndex(
      (wp) => wp._meta.clientId.toString() === tempWpId.toString()
    );
    const updateSections = [...workpackages];
    updateSections[indexToUpdate] = {
      ...updateSections[indexToUpdate],
      [event.target.name]: event.target.value,
    };
    dispatch({
      type: ACTION_SET_PLAN_WORKPACKAGES,
      payload: updateSections,
    });
  };


  // ===== UTILITY FUNCTIONS ==== \\

  const openForm = (form, wpOrder) => {
    if (form === "wpModal") {
      setWpModal(true);
      setAddWpSection(wpOrder);
    }
  };

  const submitNewPlan = useCallback(async () => {
    dispatch({
      type: ACTION_SET_BUILDER_IS_SAVING,
    });
    try {
      if (isSectionHeaderMissing(builderSections)) {
        dispatch({
          type: ACTION_SET_MISSING_HEADER_MESSAGE,
        });
        return;
      }
      if (isWpOrTaskNameMissing([...workpackages, ...tasks])) {
        dispatch({
          type: ACTION_SET_MISSING_WP_OR_TASK_NAME,
        });
        return;
      }
      const builderData = {
        ...builderInfo,
        Program_ID: props.chosenProgram.id ? props.chosenProgram.id : programId,
      };

      const tasksToDeleteList = tasksToDeleteOnCreate
        .filter((task) =>
          existingWorkpackageIdsInPlan.includes(task.Workpackages_Workpackage_ID)
        )
        .map((task) => {
          return {
            ...task,
            Status: taskStatuses.CLOSED,
          };
        });

      const workpackageList = workpackages.map((wp) => {
        return {
          Workpackage_ID: wp.Workpackage_ID,
          Name: wp.Name,
          Description: wp.Description,
          ProgramComponents_Component_ID: wp.ProgramComponents_Component_ID,
          Program_Program_ID: wp.Program_Program_ID,
          _meta: wp._meta,
        };
      });

      const taskList = tasks.map((task) => {
        return {
          Task_ID: task.Task_ID,
          Name: task.Name,
          Assigned_User: task.Assigned_User,
          Proposed_Completion: task.Proposed_Completion === "" ? null : task.Proposed_Completion,
          Proposed_Start: task.Proposed_Start === "" ? null : task.Proposed_Start,
          Description: task.Description,
          Type: task.Type,
          Order: task.Order,
          Workpackages_Workpackage_ID: task.Workpackages_Workpackage_ID,
          Parent_ID: task.Parent_ID,
          ProgramComponents_Component_ID: task.ProgramComponents_Component_ID,
          Program_Program_ID: task.Program_Program_ID,
          _meta: task._meta,
        };
      });

      const builderRes = await BuilderService.createPlanBuilder(
        builderData,
        builderSections,
        workpackageList,
        [...taskList, ...tasksToDeleteList]
      );

      // RE-ATTACH CLIENT ID AFTER SAVE
      const formattedBuilderSections = attachMetaToPlanBuilderSections(
        builderRes.payload.builderSectionData
      );
      const formattedWps = attachMetaToLoadedWps(
        builderRes.payload.workpackageData
      );
      const formattedTasks = attachMetaToLoadedTasks(
        builderRes.payload.taskData
      );
      dispatch({
        type: ACTION_SET_BUILDER_AFTER_SAVE,
        payload: {
          builderInfo: builderRes.payload.builderData,
          builderSections: formattedBuilderSections,
          workpackages: formattedWps,
          tasks: formattedTasks,
        },
      });
    } catch (error) {
      dispatch({
        type: ACTION_SET_BUILDER_FAILED_SAVE,
      });
    }
  }, [
    builderSections,
    builderInfo,
    programId,
    props.chosenProgram.id,
    workpackages,
    tasks,
    tasksToDeleteOnCreate,
    existingWorkpackageIdsInPlan
  ]);

  const submitUpdatedPlan = useCallback(async () => {
    dispatch({
      type: ACTION_SET_BUILDER_IS_SAVING,
    });
    try {
      if (isSectionHeaderMissing(builderSections)) {
        dispatch({
          type: ACTION_SET_MISSING_HEADER_MESSAGE,
        });
        return;
      }
      if (isWpOrTaskNameMissing([...workpackages, ...tasks])) {
        dispatch({
          type: ACTION_SET_MISSING_WP_OR_TASK_NAME,
        });
        return;
      }

      const workpackageList = workpackages.map((wp) => {
        return {
          Workpackage_ID: wp.Workpackage_ID,
          Name: wp.Name,
          Description: wp.Description,
          ProgramComponents_Component_ID: wp.ProgramComponents_Component_ID,
          Program_Program_ID: wp.Program_Program_ID,
          _meta: wp._meta,
        };
      });

      const taskList = tasks.map((task) => {
        return {
          Task_ID: task.Task_ID,
          Name: task.Name,
          Assigned_User: task.Assigned_User,
          Proposed_Completion: task.Proposed_Completion === "" ? null : task.Proposed_Completion,
          Proposed_Start: task.Proposed_Start === "" ? null : task.Proposed_Start,
          Description: task.Description,
          Type: task.Type,
          Order: task.Order,
          Workpackages_Workpackage_ID: task.Workpackages_Workpackage_ID,
          Parent_ID: task.Parent_ID,
          ProgramComponents_Component_ID: task.ProgramComponents_Component_ID,
          Program_Program_ID: task.Program_Program_ID,
          _meta: task._meta,
        };
      });

      const builderRes = await BuilderService.updatePlanBuilder(
        builderInfo.Builder_ID,
        builderInfo,
        builderSections,
        workpackageList,
        taskList,
      );

      const formattedBuilderSections = attachMetaToPlanBuilderSections(
        builderRes.payload.builderSectionData
      );
      const formattedWps = attachMetaToLoadedWps(
        builderRes.payload.workpackageData
      );
      const formattedTasks = attachMetaToLoadedTasks(
        builderRes.payload.taskData
      );

      dispatch({
        type: ACTION_SET_BUILDER_AFTER_SAVE,
        payload: {
          builderInfo: builderRes.payload.builderData,
          builderSections: formattedBuilderSections,
          workpackages: formattedWps,
          tasks: formattedTasks,
        },
      });
    } catch (error) {
      dispatch({
        type: ACTION_SET_BUILDER_FAILED_SAVE,
      });
    }
  }, [builderSections, builderInfo, workpackages, tasks]);

  // CHecks if at least one section with a header or content exists
  const sectionLess = () => {
    return (
      builderSections.length === 0 ||
      builderSections.filter(section => (
        section.Type === builderSectionTypes.WP_BUTTONS
      )).length === 3
    );
  };

  if (builderSections && builderInfo && workpackages && tasks) {
    return (
      <>
        <BuilderHeader
          readOnly={props.readOnly}
          mode={props.mode}
          type={props.type}
          setBuilderMode={props.setBuilderMode}
          activeForm={activeForm}
          setOpenDialog={setOpenDialog}
          statusMessage={statusMessage}
          dispatch={dispatch}
        />

        <div className={classes.innerContentContainer}>
          <BuilderHeaderInfo
            previousVersions={previousVersions}
            builderBeingOverwrittenByCopy={props.builderBeingOverwrittenByCopy}
            loadedVersion={loadedVersion}
            builderInfo={builderInfo}
            builderInfoErrors={builderInfoErrors}
            dispatch={dispatch}
            disableBuilder={disableBuilder}
          />
          <div>
            {/* ======== BUILDER SECTIONS ======== */}
            {builderSections.map((section) => {
              return (
                <div
                  key={`${section._meta.clientId}_${section.Section_Order}`}
                  className={classNames(classes.sectionWrapper)}
                >
                  {/* ======== WORKPACKAGE SECTION ======== */}
                  {isWpSection(section) && (
                    <div
                      className={classes.wpSectionHeader}
                      data-cy="builder-section-WP"
                    >
                      <Header
                        variant="h4Primary"
                        startIcon={
                          <SettingsOutlinedIcon
                            className={classes.workpackageSectionIconLarge}
                          />
                        }
                      >
                        Workpackage Section
                      </Header>
                      {!props.readOnly && (
                        <div>
                          <DeleteIconButton
                            target="Workpackage Section"
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Are you sure you want to remove the workpackage section as well as all associated tasks?"
                                )
                              ) {
                                deleteWpSection(section);
                              }
                            }}
                            disabled={disableBuilder}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {/* ======== SECTION HEADER ======== */}
                  {section.Type !== builderSectionTypes.WP_BUTTONS && (
                    <BuilderSectionHeader
                      section={section}
                      dispatch={dispatch}
                      test={section.Type === builderSectionTypes.WORKPACKAGE ? "wp-section-header" : null}
                      disableBuilder={disableBuilder}
                    />
                  )}

                  {/* Add Seciton button for plan builder */}
                  {section.Type === builderSectionTypes.WP_BUTTONS && (
                    <div className={classes.addSectionWrapper}>
                      {section.SectionHeader === "Add Section" && (
                        <CustomLink
                          onClick={() => {
                            addSectionWpButtonClick(section);
                          }}
                          variant="linkBar"
                          startIcon={
                            <AddCircleOutlineOutlinedIcon
                              className={classes.addSectionIcon}
                            />
                          }
                          test={`addSection-${section.Section_Order}`}
                          disableLinkBar={disableBuilder}
                        >
                          Add Section
                        </CustomLink>
                      )}

                      {section.SectionHeader !== "Add Section" && (
                        <CustomLink
                          onClick={() => addSectionWpButtonClick(section)}
                          variant="linkBarDark"
                          startIcon={
                            <SettingsOutlinedIcon
                              className={classes.workpackageSectionIcon}
                            />
                          }
                          test="AddWpSection"
                          disableLinkBar={disableBuilder}
                        >
                          Add Workpackages Section
                        </CustomLink>
                      )}
                    </div>
                  )}

                  {section.Type !== builderSectionTypes.WP_BUTTONS && (
                    <>
                      <BuilderSectionRTE
                        section={section}
                        dispatch={dispatch}
                        disableBuilder={disableBuilder}
                      />
                      {isWpSection(section) && (
                        <>
                          <div className={classes.addWpContainer}>
                            <CustomLink
                              onClick={() => openForm("wpModal", 0)}
                              variant="linkBarDark"
                              startIcon={
                                <SettingsApplicationsOutlinedIcon
                                  className={classes.workpackageIcon}
                                />
                              }
                              test="WPAndSupportingTasks"
                              disableLinkBar={disableBuilder}
                            >
                              Add a Workpackage and Supporting Tasks
                            </CustomLink>
                          </div>
                          {workpackages?.length > 0 &&
                            workpackages.sort((wp1, wp2) => wp1._meta.wp_order - wp2._meta.wp_order).map((wp) => (
                              <React.Fragment key={wp._meta.clientId}>
                                <BuilderWp
                                  chosenProgram={props.chosenProgram}
                                  programId={programId}
                                  wpClientId={wp._meta.clientId}
                                  workpackage={wp}
                                  tasks={tasks}
                                  workpackages={workpackages}
                                  openForm={openForm}
                                  deleteSection={deleteSection}
                                  wpHandler={wpHandler}
                                  wpSection={section}
                                  readOnly={props.readOnly}
                                  dispatch={dispatch}
                                  disableBuilder={disableBuilder}
                                />
                              </React.Fragment>
                            ))}
                        </>
                      )}
                    </>
                  )}

                  {!props.readOnly &&
                    section.Type !== builderSectionTypes.WP_BUTTONS &&
                    section.Type !== "workpackage" && (
                    <div className={classes.deleteSectionWrapper}>
                      <DeleteIconButton
                        target="Section"
                        onClick={() => {
                          const deleteNotice =
                              "Are you sure you want to delete this section?";
                          if (window.confirm(deleteNotice)) {
                            deleteSection(section);
                          }
                        }}
                        disabled={disableBuilder}
                      />
                    </div>
                  )}
                  {/* ADD BUTTON */}
                  {section.Type !== "wpButtons" &&
                    section.Type !== "workpackage" && (
                    <div className={classes.addSectionWrapper}>
                      <CustomLink
                        onClick={() => {
                          addNewSection(section.Section_Order);
                        }}
                        variant="linkBar"
                        startIcon={
                          <AddCircleOutlineOutlinedIcon
                            className={classes.addSectionIcon}
                          />
                        }
                        test="AddSection"
                        disableLinkBar={disableBuilder}
                      >
                          Add Section
                      </CustomLink>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        {/* SAVE & CANCEL BUTTONS */}
        <BuilderButtonssColumn
          readOnly={props.readOnly}
          state={state}
          setBuilderMode={props.setBuilderMode}
          sectionLess={sectionLess()}
          mode={props.mode}
          submitNewBuilder={submitNewPlan}
          submitUpdatedBuilder={submitUpdatedPlan}
          setOpenDialog={setOpenDialog}
          openDialog={openDialog}
          componentObject={componentObject}
          chosenProgram={props.chosenProgram}
          associatedProgramId={
            props.chosenProgram.id ? props.chosenProgram.id : programId
          }
          dispatch={dispatch}
          statusMessage={statusMessage}
          tasks={tasks}
          workpackages={workpackages}
        />
        <CustomModal open={wpModal} onClose={() => setWpModal(false)}>
          <CreateOrExistingWpForm
            setWpModal={setWpModal}
            workpackages={workpackages}
            tasks={tasks}
            addWpSection={addWpSection}
            builderSections={builderSections}
            builderProgramId={
              props.chosenProgram.id ? props.chosenProgram.id : programId
            }
            dispatch={dispatch}
            existingWorkpackageIdsInPlan={existingWorkpackageIdsInPlan}
          />
        </CustomModal>
      </>
    );
  } else {
    return <Loader />;
  }
};

export default PlanBuilder;
