import React from "react";
import { useParams } from "react-router-dom";
import HeaderPrimary from "components/utils/header.component.js";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Typography } from "@material-ui/core";
import FormatListNumberedOutlinedIcon from "@material-ui/icons/Info";
import ProgRunbooks from "components/runbook/shared/progRunbooks.component";
import classNames from "classnames";
import variables from "styleVariables";
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    marginBottom: 10,
    display: "flex",
  },
  icon: {
    fontSize: 20,
    fill: variables.primaryMain,
  },
  button: {
    "&:hover": {
      "& $icon": {
        fill: variables.secondaryMain,
      },
    },
  },
  popperWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 15px",
    maxWidth: 300,
    marginTop: 20,
  },
  popperHeader: {
    color: variables.primaryMain,
    fontSize: variables.fontSmall,
    paddingTop: 3,
  },
  popperBody: {
    display: "flex",
    padding: 5,
    flexDirection: "column",
  },
  statusRow: {
    padding: "3px 0px",
    display: "inline-flex",
    alignItems: "center",
  },
  statusDot: {
    height: 10,
    width: 10,
    borderRadius: 10,
    display: "inline-block",
    marginRight: 10,
    backgroundColor: variables.primaryMain,
  },
  statusLabel: {
    fontSize: variables.fontXs,
    fontWeight: "bold",
  },
  inProgress: {
    color: variables.secondaryDark,
    "& $statusDot": {
      backgroundColor: variables.secondaryMain,
    },
  },
  upcoming: {
    color: variables.tertiary2,
    "& $statusDot": {
      backgroundColor: variables.tertiary2,
    },
  },
  overdue: {
    color: variables.warningMain,
    "& $statusDot": {
      backgroundColor: variables.warningMain,
    },
  },
  inactive: {
    color: variables.grayDisabledText,
    "& $statusDot": {
      backgroundColor: variables.grayDisabledText,
    },
  },
}));

const DashRunbookPrograms = (props) => {
  const classes = useStyles();
  const { programId } = useParams();
  const { state, dispatch } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  return (
    <>
      <div className={classes.headerWrapper}>
        <HeaderPrimary variant="h3Primary">Active Runbooks</HeaderPrimary>
        <div className={classes.iconWrapper}>
          <IconButton
            className={classes.button}
            onClick={handleClick("right-start")}
          >
            <FormatListNumberedOutlinedIcon
              className={classNames(classes.icon)}
            />
          </IconButton>
        </div>
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement={placement}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper elevation={3} className={classes.popperWrapper}>
                <Typography variant="h6" className={classes.popperHeader}>
                  Runbook Status:
                </Typography>
                <div className={classes.popperBody}>
                  <div className={classNames(classes.statusRow, classes.inProgress)}>
                    <span className={classes.statusDot} />
                    <Typography variant="body1" className={classes.statusLabel}>
                      In Progress
                    </Typography>
                  </div>
                  <div className={classNames(classes.statusRow, classes.upcoming)}>
                    <span className={classes.statusDot} />
                    <Typography variant="body1" className={classes.statusLabel}>
                      Upcoming
                    </Typography>
                  </div>
                  <div className={classNames(classes.statusRow, classes.overdue)}>
                    <span className={classes.statusDot} />
                    <Typography variant="body1" className={classes.statusLabel}>
                      Overdue
                    </Typography>
                  </div>
                  <div className={classNames(classes.statusRow, classes.inactive)}>
                    <span className={classes.statusDot} />
                    <Typography variant="body1" className={classes.statusLabel}>
                      Inactive
                    </Typography>
                  </div>
                </div>
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
      <ProgRunbooks
        programId={programId}
        runbooks={state.runbooks?.filter((rb) => rb.Status === "Active")}
        allRunbooks={state.runbooks}
        allRunbookPhases={state.allRunbookPhases}
        fromDashboard
        dispatch={dispatch}
      />
    </>
  );
};

export default DashRunbookPrograms;
