import React, { useState, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CreateCustomAssessItems from "components/assessment/createCustomAssessItems.component";
import HeaderPrimary from "components/utils/header.component";
import { Paper } from "@material-ui/core";
import classNames from "classnames";
import {
  assessItemIsCustom,
} from "utils/assessmentHelpers";
import {
  assesssRefItemsWithTabDisplay,
} from "utils/assessmentConstants";
import CreateAssessmentItemsFromSources from "components/assessment/createAssessmentItemsFromSources.component";
import SelectedAccordionView from "components/assessment/selectedAccordionView.component";
import ProgramComponentLabelIcon from "components/utils/programComponentLabelIcon.component";
import CollapsableAlert from "components/utils/collapsableAlert.component";

const useStyles = makeStyles((theme) => ({
  alertContent: {
    marginTop: -4
  },
  container: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down('md')]: {
      flexFlow: "column-reverse",
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
  },
  leftContents: {
    flex: 2,
    marginRight: 30,
    [theme.breakpoints.down('lg')]: {
      minWidth: 200,
    },
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
      marginTop: 10,
    },
  },
  rightContents: {
    flex: 5,
    maxHeight: 600,
    position: "sticky",
    top: 0,
    marginLeft: 30,
    [theme.breakpoints.down('md')]: {
      position: "relative",
      marginLeft: 0,
      maxHeight: "unset",
    },
  },
  innerContentContainer: {
    marginTop: 30,
  },
  leftInnerContent: {
    marginLeft: 0,
    // Makes each panel scrollable beyond a certain Height.
    // Subtraction value is a rough sum of external elements (stickyHeader, stepperWrapper, appBar)
    maxHeight: "calc(100vh - 470px)",
    overflowY: "scroll",
    [theme.breakpoints.down('lg')]: {
      maxHeight: "calc(100vh - 500px)",
    },
  },
  rightInnerContent: {
    marginRight: 0,
  },
  headerContainer: {
    width: "100%",
    backgroundColor: "white",
    top: 0,
    [theme.breakpoints.down('md')]: {
      position: "relative",
    },
  },
  selectionContainer: {
    display: "flex",
    width: "fit-content",
    flex: 1,
    [theme.breakpoints.down('lg')]: {
      flexWrap: "wrap",
      flex: 3,
    },
  },
  selectionContainerCustom: {
    flex: 1,
    display: "flex",
    "& $categoryButton": {
      flex: 1,
    },
  },
  selectItemsContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 40,
    [theme.breakpoints.down('lg')]: {
      minWidth: 475,
      marginBottom: 40,
    },
  },
  buttonRow: {
    display: 'flex',
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  categoryButton: {
    borderRadius: 10,
    display: "flex",
    flex: 1,
  },
  buttonLabel: {
    borderBottom: "1px solid #bdbdbd",
    padding: 15,
    flex: 1,
    justifyContent: "center",
  },
}));

const SetAssessmentItems = ({
  builderId,
  assessItems,
  user,
  programID,
  programComponentList,
  assessItemIdsWithReviews,
  handleSave,
  isSavingAssessmentItems,
  builderInfo
}) => {
  const classes = useStyles();
  const [selectedProgComponent, setSelectedProgComponent] = useState({}); //i.e. "custom, controls, etc"


  const selectSource = (scope) => {
    setSelectedProgComponent({
      Component_ID: scope.Component_ID,
      Name: scope.Name,
      Nav_Icon: scope.Nav_Icon,
    });
  };

  const removeAssessItem = (itemToDelete) => {
    handleSave(2, null, [{...itemToDelete, isDelete: true}])
  };

  const showNoItemsAlert = useMemo(() => {
    return builderInfo.Step > 1 && assessItems.length === 0;
  }, [builderInfo.Step, assessItems.length]);

  if (programComponentList != null) {
    const customProgComp = programComponentList.find((programComponent => programComponent.Component_ID === "custom"))
    return (
      <>
        <CollapsableAlert
          showAlert={showNoItemsAlert}
          message="At least one assessment item is required."
          contentClass={classes.alertContent}
          severity="warning"
        />
        <div className={classes.container}>
          {/* ======== LEFT SIDE ======= */}
          <div
            className={classNames(classes.contentContainer, classes.leftContents)}
          >
            <div>
              <HeaderPrimary variant="h4Primary">
                Current Scope:
              </HeaderPrimary>
            </div>
            <div
              className={
                classNames(
                  classes.innerContentContainer,
                  classes.leftInnerContent
                )
              }
            >
              <SelectedAccordionView
                programID={programID}
                assessItems={assessItems}
                programComponentList={programComponentList}
              />
            </div>
          </div>

          {/* ======== RIGHT SIDE ======= */}
          <div
            className={
              classNames(classes.contentContainer, classes.rightContents)
            }
          >
            <div className={classes.headerContainer}>
              <HeaderPrimary variant="h4Primary" noTransform>
                Select Additional Items:
              </HeaderPrimary>
            </div>
            <div className={classNames(classes.innerContentContainer, classes.rightInnerContent)}>
              <div className={classes.buttonRow}>
                {/* REGULAR BUTTONS */}
                <Paper elevation={2} className={classes.selectionContainer}>
                  {programComponentList
                    .filter(progComp => progComp.Component_ID !== "custom")
                    .map((programComponent, index) => {
                      return (
                        <div
                          className={classes.categoryButton}
                          key={programComponent.Component_ID}
                        >
                          <ProgramComponentLabelIcon
                            className={classes.buttonLabel}
                            key={programComponent.Component_ID}
                            label={programComponent.Name}
                            programComponent={programComponent}
                            test={programComponent.Name}
                            onClick={() => selectSource(programComponent)}
                            active={selectedProgComponent.Component_ID === programComponent.Component_ID}
                          />
                        </div>
                      );
                    })}
                </Paper>
                {/* CUSTOM BUTTON */}
                <Paper elevation={2} className={classNames(classes.selectionContainer, classes.selectionContainerCustom)}>
                  <div className={classes.categoryButton}>
                    <ProgramComponentLabelIcon
                      className={classes.buttonLabel}
                      label={customProgComp.Name}
                      programComponent={customProgComp}
                      test={customProgComp.Name}
                      onClick={() => selectSource(customProgComp)}
                      active={selectedProgComponent.Component_ID === customProgComp.Component_ID}
                    />
                  </div>
                </Paper>
              </div>
              <div className={classes.selectItemsContainer}>
                {selectedProgComponent?.Component_ID === "custom" && (
                  <CreateCustomAssessItems
                    assessItemIdsWithReviews={assessItemIdsWithReviews}
                    createdItems={assessItems.filter((item) => (
                      assessItemIsCustom(item)
                    ))}
                    builderId={builderId}
                    removeAssessItem={removeAssessItem}
                    user={user}
                    handleSave={handleSave}
                    isSavingAssessmentItems={isSavingAssessmentItems}
                  />
                )}
                {selectedProgComponent.Component_ID !== "custom" && (
                  <CreateAssessmentItemsFromSources
                    assessItems={assessItems}
                    assessItemIdsWithReviews={assessItemIdsWithReviews}
                    builderId={builderId}
                    user={user}
                    programID={programID}
                    displayWithScopeTabs={
                      assesssRefItemsWithTabDisplay.includes(
                        selectedProgComponent.Name
                      )
                    }
                    selectedProgCompID={selectedProgComponent.Component_ID}
                    selectedProgCompName={selectedProgComponent.Name}
                    handleSave={handleSave}
                    isSavingAssessmentItems={isSavingAssessmentItems}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return "";
  }
};

export default SetAssessmentItems;
