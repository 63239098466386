import SingleFileUpload, { VARIANT_INLINE } from "components/utils/form-elements/singleFileUpload.component";
import { useCallback, useMemo, useState } from "react";
import csv from "papaparse";
import Form from "components/utils/form-elements/form.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import { Box, Card, Dialog, makeStyles, MenuItem } from "@material-ui/core";
import CustomSelect from "components/utils/form-elements/select.component";
import { H2, H5, H6 } from "components/utils/headerV2.component";
import AddListItem from "components/utils/addListItem.component";
import RemoveIcon from "components/utils/removeIcon.component";
import { AttachmentOutlined } from "@material-ui/icons";
import styleVariables from "styleVariables";
import ButtonDefault from "components/utils/buttonDefault.component";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import { Alert } from "@material-ui/lab";
import trainingService from "services/training.service";
import { hyphenatedToCamelcase } from "utils/stringFuncs";
import { ACTION_ADD_ASSIGNABLE_USERS } from "reducers/trainingAssignments.reducer";
import Tooltip from "components/utils/tooltip.component";
import classNames from "classnames";
import { HTTP_STATUS_CONTENT } from "services/http-common";


const useStyles = makeStyles(theme => ({
  dialogPaper: {
    color: theme.palette.text.secondary,
    maxHeight: "calc(100% - 64px)",
  },
  dialogForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxHeight: "100%"
  },
  deleteListColumn: {
    minWidth: 16,
    marginLeft: 8,
    paddingTop: 8
  },
  ordinalColumn: {
    cursor: "default"
  },
  ordinalColumnManual: {
    minWidth: 24,
    marginRight: 16,
  },
  ordinalColumnImported: {
    minWidth: 32,
    marginRight: 8,
  },
  ordinalColumnError: {
    color: theme.palette.text.error,
    fontWeight: 600,
  },
  importedUserIcon: {
    fontSize: 16,
    position: "relative",
    top: -4
  },
  removeImportsIcon: {
    width: 14,
    height: 14,
    marginRight: 6,
  },
  removeImportsText: {
    color: styleVariables.warningMain,
    fontWeight: 600,
  },
  select: {
    marginTop: 4
  },
  selectPlaceholder: {
    opacity: 0.4
  },
  narrowCardWrapper: {
    width: "50%",
    minWidth: 640,
    marginLeft: "auto",
    marginRight: "auto"
  },
  importCard: {
    padding: 24,
    color: theme.palette.text.secondary
  },
  importRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 24
  },
  alertErrorList: {
    paddingLeft: 12,
    margin: "8px 0 0",
    color: theme.palette.text.error
  },
  alertErrorListItem: {
    marginBottom: 8
  },
  userListSibling: {
    marginBottom: 16,
    paddingRight: 24,
    paddingLeft: 40
  },
}));

const DIALOG_TRANSITION_DURATION_MS = 200;

const CSV_COLUMN_FIRST_NAME = "firstName";
const CSV_COLUMN_LAST_NAME = "lastName";
const CSV_COLUMN_EMAIL = "email";
const CSV_COLUMN_ROLE = "role";
const CSV_COLUMNS = [
  CSV_COLUMN_EMAIL, CSV_COLUMN_FIRST_NAME, CSV_COLUMN_LAST_NAME, CSV_COLUMN_ROLE
];

const MAX_CSV_ROWS = 1000;

const IMPORT_ERROR_FORMAT = "fileFormat";
const IMPORT_ERROR_NO_COLUMNS = "noColumns";
const IMPORT_ERROR_NO_ROWS = "noRows";
const IMPORT_ERROR_MAX_ROWS = "maxRows";

const csvErrorMessages = {
  [IMPORT_ERROR_FORMAT]: "Attached file must be a .csv file",
  [IMPORT_ERROR_NO_COLUMNS]: "No relevant columns found in attached file.",
  [IMPORT_ERROR_NO_ROWS]: "No rows found in attached file",
  [IMPORT_ERROR_MAX_ROWS]: `Too many rows in attached file (${MAX_CSV_ROWS} Max).`
};

const EMAIL_REGEX = /^\S+@\S+\.\S+$/;

const FORM_ERROR_MISSING_EMAIL = "noEmail";
const FORM_ERROR_INVALID_EMAIL = "invalidEmail";
const FORM_ERROR_MISSING_FIRST_NAME = "noFirstName";
const FORM_ERROR_MISSING_LAST_NAME = "noLastName";

const SERVER_ERROR_TAKEN_EMAIL = "takenEmail";
const SERVER_ERROR_UNKNOWN = "requestFailed";

const USER_FIELD_DELIMITER = ".";

const generateClientId = (index, generatedAt) => (
  `${generatedAt}-pending-user-${index}`
);

function assignUserMetadata(users, resetId = false) {
  const timestamp = new Date().getTime();
  const assigned = ([].concat(users)).map((user, index) => {
    const meta = user._meta || {};
    if (!meta?.clientId || resetId) {
      meta.clientId = generateClientId(index, timestamp);
    }
    meta.index = index;
    user._meta = meta;
    return user;
  });
  if (!Array.isArray(users)) {
    return assigned[0];
  }
  return assigned;
}

function isRowWithColumns(rowColumns) {
  const columnSet = new Set(CSV_COLUMNS);
  return rowColumns.some(column => columnSet.has(column));
}


export default function ImportUsersModal(props) {
  const classes = useStyles();
  const { dispatch, open, onClose, setSuccessMessage, state } = props;

  const [csvAttached, setCsvAttached] = useState(null);
  const [csvError, setCsvError] = useState(null);
  const [invalidFormFields, setInvalidFormFields] = useState({});
  const [formError, setFormError] = useState(null);
  const [isAddDisabled, setIsAddDisabled] = useState(true);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [shouldStayMounted, setShouldStayMounted] = useState(true);
  const [usersToAdd, setUsersToAdd] = useState(() => assignUserMetadata([{}]));

  const alertErrorList = useMemo(() => {
    if (!Object.keys(invalidFormFields || {})?.length) {
      return null;
    }

    const indiciesForError = Object.values(invalidFormFields).reduce(
      (accumulator, { errors, index }) => {
        Object.values(errors).forEach(error => {
          accumulator[error] = (accumulator[error] || []).concat(index + 1);
        });
        return accumulator;
      },
      {}
    );
    return Object.entries(indiciesForError).map(([error, indicies]) => {
      const pluralism = indicies.length !== 1 ? "s" : "";
      const usersQualifier = `user${pluralism}: ${indicies.sort().join(", ")}`;
      switch (error) {
        case FORM_ERROR_MISSING_EMAIL:
          return `Missing email for ${usersQualifier}`;
        case FORM_ERROR_INVALID_EMAIL:
          return `Invalid email for ${usersQualifier}`;
        case FORM_ERROR_MISSING_FIRST_NAME:
          return `Missing first name for ${usersQualifier}`;
        case FORM_ERROR_MISSING_LAST_NAME:
          return `Missing last name for ${usersQualifier}`;
        case SERVER_ERROR_TAKEN_EMAIL:
          return `Email${pluralism} already taken for ${usersQualifier}`;
        default:
          console.log(`Form Error "${error}" has no error message defined?`);
          return "";
      }
    }).filter(message => message);
  }, [invalidFormFields]);

  const alertErrorHeading = useMemo(() => {
    if (formError) {
      if (formError === SERVER_ERROR_UNKNOWN) {
        return "Unknown server error. Please try again later.";
      }
    }
    if (alertErrorList?.length) {
      return "Please fix the following issues and try again:"
    }
  }, [alertErrorList, formError]);

  const rolesById = useMemo(() => (
    state.trainingRoles.reduce((accumulator, role) => ({
      ...accumulator,
      [parseInt(role.TrainingRole_ID, 10)]: role.Role
    }), {})
  ), [state.trainingRoles]);

  // Todo: Uncomment for Role import if it's ever needed
  // const roleIdsByLabel = useMemo(() => (
  //   Object.fromEntries(
  //     Object.entries(rolesById).map(([id, label]) => [label, id])
  //   )
  // ), [rolesById]);

  const resetErrors = useCallback(() => {
    setCsvError(null);
    setInvalidFormFields(null);
    setFormError(null);
  }, []);

  const populateInputsFromCsv = useCallback(csvResult => {
    if (!isRowWithColumns(csvResult.meta.fields)) {
      return setCsvError(IMPORT_ERROR_NO_COLUMNS);
    }
    if (!csvResult.data.length) {
      return setCsvError(IMPORT_ERROR_NO_ROWS);
    }
    if (!csvResult.data.length > MAX_CSV_ROWS) {
      return setCsvError(IMPORT_ERROR_MAX_ROWS);
    }
    const csvRows = [...csvResult.data];
    const nameFields = csvResult.meta.fields.filter(field => (
      field.toLowerCase().includes("name")
    ));
    if (nameFields.length === 1) {
      for (const row of csvRows) {
        const fullNameValueList = row[nameFields[0]].split(" ");
        row[CSV_COLUMN_LAST_NAME] = fullNameValueList[fullNameValueList.length - 1];
        if (fullNameValueList.length > 1) {
          row[CSV_COLUMN_FIRST_NAME] = fullNameValueList[0];
        }
      }
    }
    // Todo: Uncomment and fix issues with this if Role import is ever needed
    // if (csvResult.meta.fields.some(field => field === CSV_COLUMN_ROLE)) {
    //   for (const row of csvRows) {
    //     const roleNames = row.role.split(" ");
    //     row[CSV_COLUMN_ROLE] = roleNames
    //       .map(name => roleIdsByLabel[name])
    //       .filter(name => name);
    //   }
    // }
    const relevantCsvRows = csvRows.filter(row => (
      isRowWithColumns(Object.keys(row))
    )).map(row => ({
      ...row,
      _meta: { isImport: true }
    }));
    const manuallyAdded = usersToAdd.filter(user => !user._meta?.isImport);
    const newUsersToAdd = [...relevantCsvRows, ...manuallyAdded];
    setUsersToAdd(assignUserMetadata(newUsersToAdd));
    resetErrors(null);
    return true;
  }, [resetErrors, usersToAdd]);

  const requestCreateUsers = useCallback(async usersByClientId => {
    const newUsersList = Object.values(usersByClientId);
    try {
      setShouldStayMounted(false);
      const usersResponse = await trainingService.batchCreateUsers(newUsersList);
      dispatch({
        type: ACTION_ADD_ASSIGNABLE_USERS,
        payload: usersResponse.payload
      });
      onClose();
      const invitedEmails = usersResponse.payload?.map?.(user => user.Email);
      let emailListText = null;
      if (!invitedEmails?.length) {
        return;
      } else if (invitedEmails.length >= 1 && invitedEmails.length <= 2) {
        emailListText = invitedEmails.join(" and ");
      } else if (invitedEmails.length > 3) {
        const emailsJoined = invitedEmails.slice(0, 3).join(", ");
        emailListText = `${emailsJoined}, and ${invitedEmails.length - 3} more`;
      } else {
        const emailsJoined = invitedEmails.slice(0, -1).join(", ");
        emailListText = `${emailsJoined}, and ${invitedEmails.slice(-1)[0]}`;
      }
      setSuccessMessage(`Account setup emails have been sent to ${emailListText}.`);
      setTimeout(function cleanUpAfterTransition() {
        setCsvAttached(null);
        setIsAddDisabled(true);
        setIsSubmitDisabled(true);
        resetErrors();
        setUsersToAdd(assignUserMetadata([{}]));
        setShouldStayMounted(true);
      }, DIALOG_TRANSITION_DURATION_MS);
    } catch(error) {
      if (
        error.response?.status === HTTP_STATUS_CONTENT &&
        error.response.data.payload
      ) {
        const clientIdsByEmail = Object.fromEntries(
          Object.entries(usersByClientId).map(([id, data]) => (
            [data.email, id]
          ))
        );
        const clientIdIndicies = Object.fromEntries(
          usersToAdd.map(row => [row._meta.clientId, row._meta.index])
        );
        const takenEmailInvalidFields = Object.fromEntries(
          error.response.data.payload.map(user => ([
            clientIdsByEmail[user.Email],
            {
              errors: { email: SERVER_ERROR_TAKEN_EMAIL },
              index: clientIdIndicies[clientIdsByEmail[user.Email]]
            }
          ]))
        );
        setInvalidFormFields(takenEmailInvalidFields);
      } else {
        setFormError(SERVER_ERROR_UNKNOWN);
        setInvalidFormFields(null);
      }
    }
  }, [
    dispatch, onClose, resetErrors, setSuccessMessage, usersToAdd
  ]);

  const validateUserFields = useCallback(usersByClientId => (
    Object.entries(usersByClientId)
      .map(([clientId, data], index) => {
        const errors = {};
        if (!data.email) {
          errors.email = FORM_ERROR_MISSING_EMAIL;
        } else if (!data.email.match(EMAIL_REGEX)) {
          errors.email = FORM_ERROR_INVALID_EMAIL;
        }
        if (!data.firstName) {
          errors.firstName = FORM_ERROR_MISSING_FIRST_NAME;
        }
        if (!data.lastName) {
          errors.lastName = FORM_ERROR_MISSING_LAST_NAME;
        }
        return [clientId, { errors, index }];
      }).filter(([_key, data]) => (
        Object.keys(data.errors).length
      ))
  ), []);

  const handleAddUser = useCallback(() => {
    setUsersToAdd(assignUserMetadata([...usersToAdd, {}]));
    setIsAddDisabled(true);
  }, [usersToAdd]);

  const handleRemoveUser = useCallback(id => {
    const users = [...usersToAdd];
    const indexToRemove = users.findIndex(user => user._meta.clientId === id);
    if (indexToRemove >= 0) {
      const wasImport = users[indexToRemove]._meta?.isImport;
      users.splice(indexToRemove, 1);
      if (wasImport && users.every(user => !user._meta.isImport)) {
        setCsvAttached(null);
      }
      setUsersToAdd(assignUserMetadata(users));
    } else {
      console.error("Could not find item to remove?");
      setUsersToAdd(assignUserMetadata([{}]));
    }
    setIsAddDisabled(false);
  }, [usersToAdd]);

  const handleCsvHeaderTransforms = useCallback(rawHeader => {
    const header = rawHeader.toLowerCase();
    if (header.includes(CSV_COLUMN_EMAIL)) {
      return CSV_COLUMN_EMAIL;
    } else if (header.includes("name")) {
      if (header.includes("first")) {
        return CSV_COLUMN_FIRST_NAME;
      } else if (header.includes("last")) {
        return CSV_COLUMN_LAST_NAME;
      }
    } else if (header.includes(CSV_COLUMN_ROLE)) {
      return CSV_COLUMN_ROLE;
    }
    return header;
  }, []);

  const handleCsvParseComplete = useCallback((csvResult, file) => {
    const didPopulate = populateInputsFromCsv(csvResult);
    if (didPopulate) {
      setCsvAttached(file);
      resetErrors();
    }
  }, [populateInputsFromCsv, resetErrors]);

  const handleInputFile = useCallback(event => {
    const file = event.target.files[0] || null;
    if (!file.name.endsWith(".csv")) {
      return setCsvError(IMPORT_ERROR_FORMAT);
    }
    if (file) {
      csv.parse(event.target.files[0], {
        complete: result => handleCsvParseComplete(result, file),
        header: true,
        skipEmptyLines: "greedy",
        transformHeader: handleCsvHeaderTransforms
      });
    }
  }, [handleCsvParseComplete, handleCsvHeaderTransforms]);

  const handleRemoveFile = useCallback(() => {
    const nonAttachmentUsers = usersToAdd.filter(user => (
      !user._meta?.isImport
    ));
    if (!nonAttachmentUsers?.length) {
      nonAttachmentUsers.push({})
    }
    setUsersToAdd(assignUserMetadata(nonAttachmentUsers));
    setCsvAttached(null);
    resetErrors();
  }, [resetErrors, usersToAdd]);

  const handleFormChange = useCallback(event => {
    if (isAddDisabled) {
      setIsAddDisabled(false);
    }
    if (isSubmitDisabled) {
      setIsSubmitDisabled(false);
    }
  }, [isAddDisabled, isSubmitDisabled]);

  const handleSubmit = useCallback(async data => {
    if (isSubmitDisabled) {
      return;
    }
    const usersByClientId = Object.entries(data).reduce((accumulator, [key, value]) => {
      if (!value) {
        return accumulator;
      }
      const [clientId, fieldName] = key.split(USER_FIELD_DELIMITER);
      if (fieldName) {
        accumulator[clientId] = accumulator[clientId] || {};
        accumulator[clientId][hyphenatedToCamelcase(fieldName)] = value;
      }
      return accumulator;
    }, {});

    if (!Object.keys(usersByClientId)?.length) {
      return;
    }

    const invalidFields = validateUserFields(usersByClientId);
    if (invalidFields.length) {
      return setInvalidFormFields(Object.fromEntries(invalidFields));
    }

    return requestCreateUsers(usersByClientId);
  }, [isSubmitDisabled, requestCreateUsers, validateUserFields]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        className: classes.dialogPaper
      }}
      transitionDuration={DIALOG_TRANSITION_DURATION_MS}
      maxWidth="xl"
      fullWidth
      disableEscapeKeyDown
      keepMounted={shouldStayMounted}
    >
      <Box maxHeight="100%" padding={4}>
        <Form
          name="training-user-add"
          className={classes.dialogForm}
          onChange={handleFormChange}
          onSubmit={handleSubmit}
        >
          <Box display="flex" marginBottom={2}>
            <div className={classes.userListSibling}>
              <H2>
                Add Trainee Users
              </H2>
            </div>
          </Box>
          <Box minHeight="25vh" flex={1}>
            <UserRowHeaders />
            <div>
              {usersToAdd.map((userFields, index, all) => (
                <UserRow
                  clientId={userFields._meta.clientId}
                  email={userFields[CSV_COLUMN_EMAIL]}
                  index={index}
                  invalidFields={invalidFormFields?.[userFields._meta.clientId]}
                  firstName={userFields[CSV_COLUMN_FIRST_NAME]}
                  lastName={userFields[CSV_COLUMN_LAST_NAME]}
                  role={userFields[CSV_COLUMN_ROLE]}
                  rolesById={rolesById}
                  onRemove={() => handleRemoveUser(userFields._meta.clientId)}
                  isImport={userFields._meta.isImport}
                  isLastRow={all.length === 1}
                  key={userFields._meta.clientId}
                />
              ))}
            </div>
            <Box marginTop={2}>
              <div className={classes.userListSibling}>
                <AddListItem
                  onClick={handleAddUser}
                  disabled={isAddDisabled}
                >
                  New user row
                </AddListItem>
              </div>
            </Box>
            {!!(alertErrorList || alertErrorHeading) && (
              <div
                className={
                  classNames(classes.userListSibling, classes.narrowCardWrapper)
                }
              >
                <Alert
                  className={classes.alertBox}
                  severity="error"
                  onClose={() => {
                    setInvalidFormFields(null);
                    setFormError(null);
                  }}
                >
                  <H5 color="red">
                    {alertErrorHeading}
                  </H5>
                  {!!alertErrorList?.length && (
                    <ul className={classes.alertErrorList}>
                      {alertErrorList.map(message => (
                        <li className={classes.alertErrorListItem} key={message}>
                          {message}
                        </li>
                      ))}
                    </ul>
                  )}
                </Alert>
              </div>
            )}
          </Box>
          <Box className={classes.narrowCardWrapper} margin="32px auto 32px">
            <div className={classes.userListSibling}>
              <Card className={classes.importCard}>
                <H5>Import Users from .csv File</H5>
                <p>
                  Columns may include: Email, First Name, and/or Last Name.
                  Other columns will be ignored.
                  Multiple roles for the same user must be separated by spaces.
                </p>
                <div className={classes.importRow}>
                  <Box marginRight={4}>
                    <SingleFileUpload
                      chosenFile={csvAttached}
                      fileInputHandler={handleInputFile}
                      fileNameField="user-csv"
                      renamedChosenFile={csvAttached?.name}
                      variant={VARIANT_INLINE}
                      key={csvError}
                    />
                  </Box>
                  {!!csvError && (
                    <Box marginLeft="auto" marginRight={1}>
                      <H6 color="red">{csvErrorMessages[csvError]}</H6>
                    </Box>
                  )}
                  {!!csvAttached && (
                    <ButtonDefault
                      color="red"
                      className={classes.removeImportsText}
                      onClick={handleRemoveFile}
                      variant="small"
                      startIcon={(
                        <RemoveIcon className={classes.removeImportsIcon} />
                      )}
                    >
                      Remove Imported Users
                    </ButtonDefault>
                  )}
                </div>
              </Card>
            </div>
          </Box>
          <DualFormButtons
            cancelOnClick={onClose}
            disabled={isSubmitDisabled}
          />
        </Form>
      </Box>
    </Dialog>
  );
}

function UserRowHeaders() {
  const classes = useStyles();
  return (
    <Box marginBottom={2}>
      <GridContainer spacing={2}>
        <GridItem xs={6} sm={3}>
          <Box display="flex" alignItems="center">
            <div className={classes.ordinalColumnManual} />
            <H5 color="tertiary">Email*</H5>
          </Box>
        </GridItem>
        <GridItem xs={6} sm={3}>
          <H5 color="tertiary">First Name*</H5>
        </GridItem>
        <GridItem xs={6} sm={3}>
          <H5 color="tertiary">Last Name*</H5>
        </GridItem>
        <GridItem xs={6} sm={3}>
          <H5 color="tertiary">Roles</H5>
        </GridItem>
      </GridContainer>
    </Box>
  );
}

function UserRow(props) {
  const classes = useStyles();
  const {
    clientId, email, firstName, index, invalidFields = {}, isImport = false,
    isLastRow, lastName, roles = [], rolesById, onRemove
  } = props;

  const [selectedRoles, setSelectedRoles] = useState(roles);

  const isInvalid = useMemo(() => (
    invalidFields?.errors &&
    Object.keys(invalidFields.errors).length > 0
  ), [invalidFields]);

  const renderSelectValue = useCallback(values => {
    if (!values?.length) {
      return (
        <div className={classes.selectPlaceholder}>No Roles Selected</div>
      );
    }
    const roleNames = values.map(id => rolesById[parseInt(id, 10)]);
    return roleNames.join(", ");
  }, [classes, rolesById]);

  const handleRoleSelect = useCallback(event => {
    const newSelectedRoles = [...event.target.value];
    newSelectedRoles.sort();
    setSelectedRoles(newSelectedRoles);
  }, []);

  return (
    <GridContainer spacing={2} hasVerticalSpacing>
      <GridItem xs={12} sm={3}>
        <Box display="flex" alignItems="center">
          <Tooltip
            title={isImport ? "User imported from attached .csv file" : ""}
          >
            <div
              className={classNames(
                classes.ordinalColumn,
                isInvalid && classes.ordinalColumnError,
                isImport && classes.ordinalColumnImported,
                !isImport && classes.ordinalColumnManual
              )}
            >
              {index + 1}.
              {!!isImport && (
                <AttachmentOutlined className={classes.importedUserIcon} />
              )}
            </div>
          </Tooltip>
          <LabelInput
            name={`${clientId}${USER_FIELD_DELIMITER}email`}
            placeholder="Email"
            defaultValue={email}
            error={!!isInvalid && !!invalidFields?.errors?.email}
            margin="dense"
            variant="default"
            hideHelperText
          />
        </Box>
      </GridItem>
      <GridItem xs={12} sm={3}>
        <LabelInput
          name={`${clientId}${USER_FIELD_DELIMITER}first-name`}
          placeholder="First Name"
          defaultValue={firstName}
          error={!!isInvalid && !!invalidFields?.errors?.firstName}
          margin="dense"
          variant="default"
          hideHelperText
        />
      </GridItem>
      <GridItem xs={12} sm={3}>
        <LabelInput
          name={`${clientId}${USER_FIELD_DELIMITER}last-name`}
          placeholder="Last Name"
          defaultValue={lastName}
          error={!!isInvalid && !!invalidFields?.errors?.lastName}
          margin="dense"
          variant="default"
          hideHelperText
        />
      </GridItem>
      <GridItem xs={12} sm={3}>
        <Box display="flex">
          <CustomSelect
            name={`${clientId}${USER_FIELD_DELIMITER}roles`}
            placeholder="No roles selected"
            value={selectedRoles}
            error={!!isInvalid && !!invalidFields?.errors?.roles}
            onChange={handleRoleSelect}
            renderValue={renderSelectValue}
            className={classes.select}
            required={false}
            displayEmpty
            multiple
          >
            {Object.entries(rolesById).map(([id, role]) => (
              <MenuItem value={id} label={role} key={id}>
                {role}
              </MenuItem>
            ))}
          </CustomSelect>
          <div className={classes.deleteListColumn}>
            <RemoveIcon
              tooltipId={!isLastRow && `remove-user-${clientId}`}
              tooltipRemoveMessage={!isLastRow && "Remove user"}
              onClick={onRemove}
              disabled={isLastRow}
            />
          </div>
        </Box>
      </GridItem>
    </GridContainer>
  );
}