import { Box, makeStyles } from "@material-ui/core";
import RequirementsForm from "components/forms/requirementsForm";
import RequirementTable from "components/requirement/complianceRequirements/requirementTable.component";
import { useEffect } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom";
import RequirementService from "services/requirement.service";
import { useReducer, useCallback } from "react";
import requirementsReducer, {
  ACTION_SET_REQUIREMENTS,
  requirementsInitialState,
  ACTION_SET_ALL_REQUIREMENTS
} from "reducers/requirements.reducer";
import AddListItem from "components/utils/addListItem.component";

const useStyles = makeStyles((theme) => ({
  addWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "20px 15px",
  }
}));
export default function Requirements() {
  const { programId, programComponentId } = useParams();
  const [state, dispatch] = useReducer(
    requirementsReducer,
    requirementsInitialState,
  );
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  //TODO: Add delete reference(?), requirement(?), and link data functionality
  useEffect(
    () => (async function() {
      if (!programId || programId === "undefined") {
        return;
      }
      const [requirementResponse, allRequirementsResponse] =
        await Promise.all([
          RequirementService.getAllProgramRequirements(programId),
          RequirementService.getAllRequirements(),
        ]);
      dispatch({
        type: ACTION_SET_REQUIREMENTS,
        payload: requirementResponse.payload.requirements
      });
      dispatch({
        type: ACTION_SET_ALL_REQUIREMENTS,
        payload: allRequirementsResponse.payload.requirements,
      })
    })(), [programId]);

  const goToNewRequirementForm = useCallback(async () => {
    history.push(`/program/${programId}/${programComponentId}/requirementForm/new`)
  }, [history, programComponentId, programId]);

  if (location.pathname.startsWith(`/program/${programId}/${programComponentId}/requirementForm`)) {
    return (
      <Box>
        <RequirementsForm
          state={state}
          dispatch={dispatch}
        />
      </Box>
    )
  } else {
    return (
      <Box paddingTop={6} data-cy="requirements-table-wrapper">
        <RequirementTable
          state={state}
          dispatch={dispatch}
        />
        <div className={classes.addWrapper}>
          <AddListItem
            text="Add Requirement"
            className={classes.addItem}
            onClick={goToNewRequirementForm}
          />
        </div>
      </Box>
    );
  }
}
