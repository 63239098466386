import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  contentRowWrapper: {
    display: "flex",
    padding: 15,
  },
  contentColumnWrapper: {
    display: "flex",
    padding: 15,
    flexDirection: "column",
  },
  leftCell: {
    paddingRight: 50,
  },
  rightCell: {
    paddingLeft: 50,
    display: "flex",
  },
}));

const ContentColumnWrapper = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classNames(classes.contentColumnWrapper)}
    >
      {children}
    </Grid>
  );
};
const ContentRowWrapper = ({ children, test }) => {
  const classes = useStyles();
  return (
    <Grid
      data-cy={test}
      container
      className={classNames(classes.contentRowWrapper)}
    >
      {children}
    </Grid>
  );
};

// LEFT SIDE
const ReviewInfoSideColumnWrapper = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid item sm={6} xs={12} className={classes.leftCell}>
      {children}
    </Grid>
  );
};

// RIGHT SIDE
const ResponseOrBlankSideColumnWrapper = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid item sm={6} xs={12} className={classes.rightCell}>
      {children}
    </Grid>
  );
};

export {
  ContentRowWrapper,
  ContentColumnWrapper,
  ReviewInfoSideColumnWrapper,
  ResponseOrBlankSideColumnWrapper,
};
