import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CustomTable from "components/utils/tables/table.component";
import AssessmentService from "services/assessment.service";
import CustomEditIcon from "components/utils/editIcon.component";
import CustomModal from "components/utils/modal.component";
import AssessMgmtPrincForm from "components/forms/assessMgmtPrincForm";
import Loader from "components/utils/loader.components";
import { Box } from "@material-ui/core";

const MGMT_PRINC_HEADERS = ["#", "Principle", "Description"];

const DATA_CELL_ORDER = [
  "Label",
  "Level",
  "Name",
  ...MGMT_PRINC_HEADERS,
  // TODO: OR-765 Lifted from sp05_GetImpDataScope... This needs to done in a better way
  "Internal_Corporate___Enterprise",
  "Corp_Systems",
  "Corporate_File_System",
  "Identity_Guard",
  "Intrusta",
  "FigLeaf",
  "PrivacyMate",
  "Hotspot_Shield___VPN",
  "Pango_Platform",
];

export const ManagementPrinciples = () => {
  const [headers, setHeaders] = useState();
  const [subHeaders, setSubHeaders] = useState();
  const [alignments, setAlignments] = useState();
  const [headerCellStyles, setHeaderCellStyles] = useState();
  const [dataMap, setDataMap] = useState(null);

  //MODAL
  const [editModal, setEditModal] = useState(false);
  const [refresh, setRefresh] = useState(false)
  // const [columnName, setColumnName] = useState();
  const [product, setProduct] = useState();
  const { programId } = useParams();

  const openForm = (columnName, productId) => {
    setEditModal(true);
    // setColumnName(columnName)
    setProduct({id: productId, name: columnName})

  }

  useEffect(() => {
    setRefresh(false)
    const leftAlignmentColumn = ["Description"];
    const headerList = [{
      name: "Label",
      value: "#"
    }, {
      name: "Name",
      value: "Principle"
    }, {
      name: "Description",
      value: "Description"
    }];
    const staticHeaderCnt = headerList.length;
    const subHeader = [{
      label: "",
      parentLabel: "",
      colSpan: headerList.length,
    }]
    AssessmentService.getGroupProducts(programId).then(result => {
      result.payload
        .sort((p1, p2) => p1.GroupProduct_ID - p2.GroupProduct_ID)
        .forEach(({ GroupProduct_ID, Product_Name }) => {
          headerList.push({
            name: Product_Name,
            value: (
              <>
                {Product_Name}
                <CustomEditIcon
                  variant="header"
                  onClick={() => (
                    openForm(Product_Name, GroupProduct_ID)
                  )}
                />
              </>
            )
          });
          subHeader.push({
            label: "Current Status",
            parentLabel: Product_Name,
            alignment: "center",
          });
        });

      const headerCellStyle = headerList.map((_header, index) => ({
        minWidth: index >= staticHeaderCnt ? 120 : ""
      }));
      setHeaderCellStyles(headerCellStyle);
      setHeaders(headerList);
      setSubHeaders(subHeader);
      const alignment = headerList.map((item) => (
        leftAlignmentColumn.includes(item) ? "left" : "center"
      ));
      setAlignments(alignment);
    });
    AssessmentService.getPrivacyMgmtPrincAssessment(programId).then((res) => {
      setDataMap(res.payload);
    });
  }, [programId, refresh]);

  const toggleForm = () => {
    setEditModal(!editModal);
  };
  if (!headers || !subHeaders || !alignments || !headerCellStyles || !dataMap) {
    return (
      <Loader />
    )
  }
  return (
    <Box marginTop={4}>
      <CustomTable
        variant="rowSpanHeader"
        stickyHeader
        title="Management Principles"
        header={headers}
        headerCellStyles={headerCellStyles}
        data={dataMap}
        // TODO: Temporary fix for OR-765
        dataOrder={DATA_CELL_ORDER}
        maxHeight="calc(100vh - 200px)"
        alignment={alignments}
        subHeader={subHeaders}
        mgmtPrincHeaders={MGMT_PRINC_HEADERS}
        assessmentsMgmtPrinc
      />
      <CustomModal open={editModal} onClose={toggleForm}>
        <AssessMgmtPrincForm
          setEditModal={setEditModal}
          product={product}
          setRefresh={setRefresh}
        />
      </CustomModal>
    </Box>
  )
};

export default ManagementPrinciples;
