/* Loop through `source` arg's associations, ending recursion when
 *   a matching association is found and modified by `callerFn`.
 *
 * The `callerFn` arg should be the "parent" function that called this function.
 * A redeclared variable derived from `association` should be returned from
 *   `callerFn` when `association` matches with the reducer action and was modified.
 */
export const recurseOneAssociation = (source, action, schema, callerFn) => {
  validateSchema(schema.association);
  for (const association of source._associations[schema.association.type]) {
    const updated = callerFn(association, action, schema.association);
    if (updated !== association) {
      const matchIndex = source._associations[schema.association.type].indexOf(association);
      source._associations[schema.association.type].splice(matchIndex, 1, updated);
      source._associations[schema.association.type] = [
        ...source._associations[schema.association.type]
      ];
      return { ...source };
    }
  }
  return source;
}

/* Loop through all of `source` arg's associations.
 *
 * The `callerFn` arg should be the "parent" function that called this function.
 * A redeclared variable derived from `association` should be returned from
 *   `callerFn` when `association` matches with the reducer action and was modified.
 */
export const recurseManyAssociations = (source, action, schema, callerFn) => {
  validateSchema(schema.association);
  let didAnyChange = false;
  for (const association of source._associations[schema.association.type]) {
    const updated = callerFn(association, action, schema.association);
    if (updated !== association) {
      didAnyChange = true;
      const matchIndex = source._associations[schema.association.type].indexOf(association);
      source._associations[schema.association.type].splice(matchIndex, 1, updated);
      source._associations[schema.association.type] = [
        ...source._associations[schema.association.type]
      ];
    }
  }
  if (didAnyChange) {
    return { ...source };
  }
  return source;
}

export const validateSchema = schema => {
  if (!schema.type) {
    throw new Error(
      `Schema must have a type attribute.`
    );
  }
  if (!schema.id) {
    throw new Error(
      `Schema of type ${schema.type} must have an id defined.`
    );
  }
  return true;
}
