
import { useCallback, useEffect, useRef, useState } from "react";
import ProgramNavTrackerContext from "contexts/programNavTracker.context";
import ProgramContextProvider from "./programContextProvider.component";
import AlertContextProvider from "./alertContextProvider.component";
import WorkpackageTaskContextProvider from "./workpackageTaskContextProvider.component";

export default function AuthenticatedContextProviders(props) {
  const { children } = props;

  const isMountedRef = useRef(true);

  const [navProgId, setNavProgId] = useState("");

  useEffect(function flagWhenUnmounted() {
    return () => {
      isMountedRef.current = false;
    }
  }, []);

  const setNavProgIdWhenMounted = useCallback((...args) => {
    if (isMountedRef.current) {
      return setNavProgId(...args);
    }
  }, []);

  return (
    <ProgramContextProvider>
      <AlertContextProvider>
        <WorkpackageTaskContextProvider>
          <ProgramNavTrackerContext.Provider
            value={[navProgId, setNavProgIdWhenMounted]}
          >
            {children}
          </ProgramNavTrackerContext.Provider>
        </WorkpackageTaskContextProvider>
      </AlertContextProvider>
    </ProgramContextProvider>
  )
}
