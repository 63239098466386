import http from "./http-common";
import authHeader from "./authHeader";

class ControlService {

  getControlMatrixFrameworksByProgramId(programId) {
    return http
      .get(`/control/controlMatrix/frameworks/program/${programId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getControlMatrixRowsByProgramIdAndFrameworkId(programId, frameworkId) {
    return http
      .get(`/control/controlMatrix/rows/program/${programId}/framework/${frameworkId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getMappedFrameworkControlMatrixRows(programId, frameworkId, mappedFrameworkId) {
    return http
      .get(`/control/controlMatrix/rows/program/${programId}/framework/${frameworkId}/mappedFramework/${mappedFrameworkId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get List of Privacy Management Control Matrix
  getPrivacyManagementControlMatrix(programId) {
    return http
      .get(`/control/privacyManagementControlMatrix/${programId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get Privacy Management Control Matrix
  getPrivacyManagementControlMatrixById(matrixId) {
    return http
      .get(`/control/privacyManagementControlMatrix/matrixItem/${matrixId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get Control Matrix
  getControlMatrixByMatrixId(matrixId) {
    return http
      .get(`/control/${matrixId}/controlMatrix`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  updatePrivacyControlMatrix(matrixId, controlMatrix) {
    return http.put(
      `/control/privacyManagementControlMatrix/${matrixId}`,
      controlMatrix,
      { headers: authHeader() }
    )
      .then(response => response);
  }

  updateControlMatrix(matrixId, controlMatrix) {
    return http.put(
      `/control/controlMatrix/${matrixId}`,
      controlMatrix,
      { headers: authHeader() }
    )
      .then(response => response.data);
  }
}

export default new ControlService();
