import { useMemo } from "react";
import { manualSectionTypes } from "utils/certificationConstants";
import { makeSectionNumberingMap } from "utils/certificationHelpers";
import AddSectionButton from "./sections/addSectionButton.component";
import ManualSection from "./sections/manualSection.component";


export default function ManualSectionsList(props) {
  const { chapter, chapterNumber, dispatch, state } = props;

  const sectionNumberMap = useMemo(() => {
    return makeSectionNumberingMap(chapter?._associations.ManualSection)
  }, [chapter?._associations.ManualSection]);

  return (
    <div>
      {chapter._associations?.ManualSection?.length ? (
        chapter._associations.ManualSection.map(section => (
          <ManualSection
            section={section}
            chapterNumber={chapterNumber}
            sectionNumber={sectionNumberMap[section.ManualSection_ID]}
            parentSection={chapter}
            state={state}
            dispatch={dispatch}
            key={section.ManualSection_ID}
          />
        ))
      ) : (
        <AddSectionButton
          dispatch={dispatch}
          parentSection={chapter}
          prevOrder={0}
          sectionType={manualSectionTypes.MAIN_SECTION}
          test="add-main-section"
        />
      )}
    </div>
  );
}
