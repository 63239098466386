import { isDevelopmentEnv } from "core/environment";
import { mergeReplaceList } from "reducers/utils/payloadUtils";
import { groupObjectArrayByKey, removeObjInArray } from "utils/arrayOfObjectsHelpers";
import { mapToEmptyProgramIdArrays } from "./utils/globalReducer.utils";

export const ACTION_REPLACE_TASKS = "tasks__replace";
export const ACTION_REPLACE_WORKPACKAGES = "workpackages__replace";
export const ACTION_REPLACE_PROGRAM_WORKPACKAGES = "workpackages__program__replace";
export const ACTION_REMOVE_WORKPACKAGE = "workpackages__remove";
export const ACTION_DISABLE_TASKS_FOR_PROGRAMS = "tasks__programs__disable";
export const ACTION_DISABLE_WORKPACKAGES_FOR_PROGRAMS = "workpackages__programs__disable";

export const workpackageTasksInitialState = {
  isTasksDisabled: false,
  isWorkpackagesDisabled: false,
  tasks: null,
  tasksByProgramId: null,
  workpackages: null,
  workpackagesByProgramId: null
};

export default function workpackageTasksReducer(state, action) {
  let mergedTasks = null;
  let mergedWorkpackages = null;

  switch (action.type) {
    case ACTION_REPLACE_TASKS:
      mergedTasks = mergeReplaceList(
        state.tasks, action.payload, "Task_ID"
      );
      return {
        ...state,
        tasks: mergedTasks,
        tasksByProgramId: groupObjectArrayByKey(
          mergedTasks,
          "Program_Program_ID",
          mapToEmptyProgramIdArrays(action, state.tasksByProgramId)
        )
      };
    case ACTION_REPLACE_WORKPACKAGES:
      mergedWorkpackages = mergeReplaceList(
        state.workpackages, action.payload, "Workpackage_ID"
      );
      return {
        ...state,
        workpackages: mergedWorkpackages,
        workpackagesByProgramId: groupObjectArrayByKey(
          mergedWorkpackages,
          "Program_Program_ID",
          mapToEmptyProgramIdArrays(action, state.workpackagesByProgramId)
        )
      };
    case ACTION_REPLACE_PROGRAM_WORKPACKAGES:
      mergedWorkpackages = mergeReplaceList(
        state.workpackages, action.payload, "Workpackage_ID"
      );
      return {
        ...state,
        workpackages: mergedWorkpackages,
        workpackagesByProgramId: groupObjectArrayByKey(
          mergedWorkpackages,
          "Program_Program_ID",
          mapToEmptyProgramIdArrays(action, state.workpackagesByProgramId),
        )
      };
    case ACTION_REMOVE_WORKPACKAGE:
      mergedWorkpackages = removeObjInArray(
        state.workpackages,
        "Workpackage_ID",
        action.payload
      );
      return {
        ...state,
        workpackages: mergedWorkpackages,
        workpackagesByProgramId: groupObjectArrayByKey(
          mergedWorkpackages,
          "Program_Program_ID",
          mapToEmptyProgramIdArrays(action, state.workpackagesByProgramId)
        )
      };
    case ACTION_DISABLE_TASKS_FOR_PROGRAMS:
      return {
        ...state,
        isTasksDisabled: true,
        tasks: [],
        tasksByProgramId: action.payload.reduce((map, programOrId) => {
          const programId = programOrId?.Program_ID || programOrId;
          map[programId] = [];
          return map;
        }, {})
      };
    case ACTION_DISABLE_WORKPACKAGES_FOR_PROGRAMS:
      return {
        ...state,
        isWorkpackagesDisabled: true,
        workpackages: [],
        workpackagesByProgramId: action.payload.reduce((map, programOrId) => {
          const programId = programOrId?.Program_ID || programOrId;
          map[programId] = [];
          return map;
        }, {})
      };
    default:
      if (isDevelopmentEnv) {
        throw new Error(
          `Unrecognized action in WorkpackageTasks Reducer: "${action.type}"`
        )
      }
      return state;
  }
}
