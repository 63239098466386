import React, { useState, useEffect } from "react";
import { Grid, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LabelInput from "components/utils/form-elements/labelInput.component";
import HeaderPrimary from "components/utils/header.component";
import CustomSelect from "components/utils/form-elements/select.component";
import ControlService from "services/control.service";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import FormBanner from "components/utils/form-elements/formBanner.component";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: 800,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: 40,
  },
  form: {
    padding: "0px 60px 0px 60px",
    align: "center",
  },
  subHeaderWrapper: {
    padding: "30px 40px 30px 40px",
    display: "flex",
    justifyContent: "space-between",
  },
  subHeader: {
    display: "flex",
    flexDirection: "column",
  },
  headerStyle: {
    paddingTop: 1,
    paddingBottom: 1,
  },
  formSection: {
    padding: "15px 0px 15px 0px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  formSectionElement: {
    width: 300,
  },
}));

const currentStateItems = [
  {
    name: "Implemented",
  },
  {
    name: "Partial Implementation",
  },
  {
    name: "Planned",
  },
  {
    name: "N/A",
  },
];

const impTypes = [
  {
    name: "Hybrid",
  },
  {
    name: "Enterprise",
  },
  {
    name: "N/A",
  },
];

export const ControlMatrixForm = (props) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({});
  const [loadForm, setLoadForm] = useState(false);
  const [activeForm, setActiveForm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    ControlService.getControlMatrixByMatrixId(props.rowInfo)
      .then((res) => {
        setLoadForm(false);
        setFormData(res.payload);
      })
      .then(() => setLoadForm(true));
  }, [props.rowInfo]);

  const handleChange = (event) => {
    const { target } = event;
    if (!activeForm) {
      setActiveForm(true);
    }
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSaving(true);
    const newMatrix = {
      Current_State: formData.Current_State, // 'Implemented','Partial Implementation','Planned','N/A'
      Implementation_Type: formData.Implementation_Type, //'Hybrid','Enterprise','N/A'
      Implementation_Desc: formData.Implementation_Desc,
      Control_Validation: formData.Control_Validation,
      Validation_Date: formData.Validation_Date,
      Support_Tool: formData.Support_Tool,
    };
    const updatedRes = await ControlService.updateControlMatrix(
      formData.ControlMatrix_ID,
      newMatrix
    );
    const matrixRowToUpdate = props.matrixData.find(
      (row) => row.ControlMatrix_ID === formData.ControlMatrix_ID
    );

    const indexToUpdate = props.matrixData.findIndex(
      (row) => row.ControlMatrix_ID === formData.ControlMatrix_ID
    );

    const updatedMatrixData = [...props.matrixData];
    const replacementRow = { ...matrixRowToUpdate, ...updatedRes.payload };
    updatedMatrixData.splice(indexToUpdate, 1, replacementRow);
    props.setMatrixData(updatedMatrixData);

    props.setEditModal(false);
  };

  if (formData && loadForm) {
    return (
      <Grid container className={classes.formContainer}>
        <FormBanner>Control Matrix Update</FormBanner>
        <div className={classes.subHeaderWrapper}>
          <div className={classes.subHeader}>
            <HeaderPrimary
              variant="h4Tertiary"
              className={classes.headerStyle}
            >
              {formData.Framework_Name}
            </HeaderPrimary>
            <HeaderPrimary
              variant="h3SecondaryDark"
              className={classes.headerStyle}
            >
              {formData.Group_Name} - {formData.Identifier}
            </HeaderPrimary>
          </div>
          {/* <div className={classes.subHeader}>
            <HeaderPrimary
              variant="h4Tertiary"
              className={classes.headerStyle}
            >{`NIST 171`}</HeaderPrimary>
            <HeaderPrimary
              variant="h3SecondaryDark"
              className={classes.headerStyle}
            >{`${formData.Security_Req_Group} - ${formData["171_Identifier"]}`}</HeaderPrimary>
          </div> */}
        </div>
        <form className={classes.form} data-cy="form-controlMatrix">
          <div className={classes.formFieldsWrapper}>
            {/* CURRENT STATE SELECT */}
            <div className={classes.formSection}>
              <div className={classes.formSectionElement}>
                <CustomSelect
                  //   variant={"form"}
                  label="Current State"
                  labelId="Current State"
                  value={formData.Current_State}
                  onChange={handleChange}
                  name="Current_State"
                  shrink
                >
                  {currentStateItems.map((item) => (
                    <MenuItem
                      key={item.name}
                      value={item.name}
                      id={item.name}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </div>
              {/* IMPLEMENTATION TYPE SELECT */}
              <div className={classes.formSectionElement}>
                <CustomSelect
                  variant="form"
                  label="Implementation Type"
                  value={formData.Implementation_Type}
                  id="Implementation_Type"
                  name="Implementation_Type"
                  onChange={handleChange}
                  shrink
                >
                  {impTypes.map((item) => (
                    <MenuItem
                      value={item.name}
                      key={item.name}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </div>
            </div>
            <div className={classes.formSection}>
              <LabelInput
                label="Control Implementation Description"
                value={formData.Implementation_Desc}
                variant="default"
                multiline
                id="Implementation_Desc"
                name="Implementation_Desc"
                onChange={handleChange}
              />
            </div>
            <div className={classes.formSection}>
              <div className={classes.formSectionElement}>
                <LabelInput
                  id="Control_Validation"
                  name="Control_Validation"
                  label="Control Validation"
                  variant="default"
                  value={formData.Control_Validation}
                  onChange={handleChange}
                  inputProps={{maxLength: 45}}
                />
              </div>
              <div className={classes.formSectionElement}>
                <LabelInput
                  label="Validation Date"
                  variant="default"
                  value={formData.Validation_Date}
                  id="Validation_Date"
                  name="Validation_Date"
                  onChange={handleChange}
                  inputProps={{maxLength: 45}}
                />
              </div>
            </div>
            {/* ROADMAP NOTES */}
            <div className={classes.formSection}>
              <LabelInput
                label="Support Tooling"
                variant="default"
                value={formData.Support_Tool}
                multiline
                id="Support_Tool"
                name="Support_Tool"
                onChange={handleChange}
              />
            </div>
          </div>
          <DualFormButtons
            variant="smallButton"
            saveOnClick={handleSubmit}
            cancelOnClick={() => props.setEditModal(false)}
            disabled={!activeForm}
            isSaving={isSaving}
          />
        </form>
      </Grid>
    );
  } else {
    return "";
  }
};

export default ControlMatrixForm;
