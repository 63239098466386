import http from "./http-common";
import authHeader from "./authHeader";

class LegalFrameworkService {

  getProgramRegulations(programId) {
    return http
      .get(`/legalFramework/regulations/${programId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getLegalFrameworkData(programId) {
    return http
      .get(`/legalFramework/matrix/${programId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getRegSectionById(sectionId) {
    return http
      .get(`/legalFramework/regSection/${sectionId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

}

export default new LegalFrameworkService();
