import { makeStyles } from "@material-ui/core";
import { useCallback, useState } from "react";
import Checkbox from "components/utils/form-elements/checkbox.component";
import Form from "components/utils/form-elements/form.component";

const useStyles = makeStyles(theme => ({
  checkboxWrapper: {
    width: "100%",
    textAlign: "center"
  },
}));


export default function EditableCheckboxCell(props) {
  const classes = useStyles();
  const { name, defaultValue, onAutosave } = props;

  const [checked, setChecked] = useState(defaultValue);

  const handleChange = useCallback(event => {
    const checkedValue = event.target.checked ? 1 : 0;
    onAutosave(name, checkedValue);
    setChecked(checkedValue);
  }, [name, onAutosave]);

  return (
    <div className={classes.checkboxWrapper}>
      <Form name={`${name}-form`}>
        <Checkbox
          name={name}
          checked={!!checked}
          onChange={handleChange}
          checkBoxColor="teal"
          size="small"
        />
      </Form>
    </div>
  );
}
