import React from "react";

const Settings = () => {

  return (
    <div >
      Settings Component
    </div>
  );
};

export default Settings;