const { certProgCompIds, certTableMapIds, certCompObjIds } = require("./certificationConstants")

export const formatTableData = (references, programComponentId) => {
  return references.map((reference) => (
    formatTableRow(reference, programComponentId)
  ))
}

/*This function is used for formatting data pulled from the db
into what we need for the app sched reference objects.*/
const formatTableRow = (reference, programComponentId) => {
  let primaryKey;
  let formattedObject;
  if(programComponentId === certProgCompIds.PASI_CHECKLIST) {
    if (reference.ChecklistItem_ID || reference.Checklist_ID) {
      //Checklist/chk items from Pre-App checklist
      const isChecklistItem = reference.ChecklistItem_ID
      primaryKey = isChecklistItem ? `ChecklistItem_ID` : `Checklist_ID`
      formattedObject = {
        ...reference,
        tempId: makeTempId(reference, primaryKey),
        displayValue: reference.Label || reference.Title,
        type: isChecklistItem ? "Checklist Item" : "Checklist",
        selected: false,
        tableMapId: isChecklistItem ? certTableMapIds.CHECKLIST_ITEMS : certTableMapIds.CHECKLIST,
        refId: isChecklistItem ? reference.ChecklistItem_ID : reference.Checklist_ID,
        progCompId: programComponentId,
        componentObjectId: certCompObjIds.PRE_APPLICATION,
        linkId: isChecklistItem ? reference[primaryKey] : null,
      }
    } else {
      //PASI
      formattedObject = {...reference}
    }
  } else if(programComponentId === certProgCompIds.APPLICATION_SCHEDULE) {
    //Application schedule prog component
    primaryKey = "Task_ID"
    formattedObject = {
      ...reference,
      tempId: makeTempId(reference, primaryKey),
      displayValue: reference.Name,
      type: "Task",
      selected: false,
      tableMapId: certTableMapIds.TASKS,
      refId: reference.Task_ID,
      progCompId: programComponentId,
      componentObjectId: null,
      linkId: reference[primaryKey]
    }
  } else if(programComponentId === certProgCompIds.LETTER_OF_COMPLIANCE) {
    //letter of compliance prog component
    primaryKey = "LOC_ID"
    formattedObject = {
      ...reference,
      tempId: makeTempId(reference, primaryKey),
      displayValue: `Part ${reference.Part_Label || "--"}, Subpart ${reference.Subpart_Label || "--"}, Section ${reference.Section_Label || "--"}, Paragraph ${reference.Paragraph_Label || "--"}`,
      type: "CFR",
      selected: false,
      tableMapId: certTableMapIds.LETTER_OF_COMPLIANCE_ITEM,
      refId: reference.LOC_ID,
      progCompId: programComponentId,
      componentObjectId: null,
      linkId: reference[primaryKey]
    }
  } else if(programComponentId === certProgCompIds.FORMAL_APPLICATION) {
    //formal application prog component
    const isChecklistItem = reference.ChecklistItem_ID
    primaryKey = isChecklistItem ? `ChecklistItem_ID` : `Checklist_ID`
    formattedObject = {
      ...reference,
      tempId: makeTempId(reference, primaryKey),
      displayValue: reference.Label || reference.Title,
      type: isChecklistItem ? "Checklist Item" : "Checklist",
      selected: false,
      tableMapId: isChecklistItem ? certTableMapIds.CHECKLIST_ITEMS : certTableMapIds.CHECKLIST,
      refId: isChecklistItem ? reference.ChecklistItem_ID : reference.Checklist_ID,
      progCompId: programComponentId,
      componentObjectId: null,
      linkId: reference[primaryKey]
    }
  } else if(programComponentId === certProgCompIds.DCT) {
    //Personal notes for DCT category
    primaryKey = `DctCategory_ID`
    formattedObject = {
      ...reference,
      tempId: makeTempId(reference, primaryKey),
      displayValue: reference.Label,
      type: "DCT Category",
      selected: false,
      tableMapId: certTableMapIds.DCT_CATEGORY,
      refId: reference.DctCategory_ID,
      progCompId: programComponentId,
      componentObjectId: certCompObjIds.DCT,
      linkId: reference[primaryKey]
    }
  } else if(programComponentId === certProgCompIds.POLICY_PROCEDURE) {
    //doc download - Policy/Proc prog comp
    primaryKey = `ProgramDoc_ID`
    const type = reference.ComponentObjects_ComponentObject_ID === certCompObjIds.POLICIES ? "Policy" : "Procedure"
    formattedObject = {
      ...reference,
      tempId: makeTempId(reference, primaryKey),
      displayValue: reference.Title,
      type,
      selected: false,
      tableMapId: certTableMapIds.PROGRAM_DOCS,
      refId: reference.ProgramDoc_ID,
      progCompId: programComponentId,
      componentObjectId: reference.ComponentObjects_ComponentObject_ID,
      linkId: reference[primaryKey]
    }
  } else if(programComponentId === certProgCompIds.AUDIT_ASSESSMENT) {
    //assessment comp obj
    primaryKey = `Builder_ID`
    formattedObject = {
      ...reference,
      tempId: makeTempId(reference, primaryKey),
      displayValue: reference.Title,
      type: "Assessment",
      selected: false,
      tableMapId: certTableMapIds.ASSESSMENT_BUILDER,
      refId: reference.Builder_ID,
      progCompId: programComponentId,
      componentObjectId: certCompObjIds.ASSESSMENTS,
      linkId: reference[primaryKey]
    }
  } else if(programComponentId === certProgCompIds.AIRCRAFT) {
    //aircraft prog comp
    primaryKey = `Aircraft_ID`
    formattedObject = {
      ...reference,
      tempId: makeTempId(reference, primaryKey),
      displayValue: `${reference.N_Number} - ${reference.Make} ${reference.Model} ${reference.Series}`,
      type: 'Aircraft',
      selected: false,
      tableMapId: certTableMapIds.AIRCRAFTS,
      refId: reference.Aircraft_ID,
      progCompId: programComponentId,
      componentObjectId: null,
      linkId: reference[primaryKey]
    }
  } else if(programComponentId === certProgCompIds.MANUALS) {
    primaryKey = `Manual_ID`
    formattedObject = {
      ...reference,
      tempId: makeTempId(reference, primaryKey),
      displayValue: `${reference.Title}`,
      type: 'Manual',
      selected: false,
      tableMapId: certTableMapIds.MANUALS,
      refId: reference.Manual_ID,
      progCompId: programComponentId,
      componentObjectId: null,
      linkId: reference[primaryKey]
    }
  } else if (programComponentId === certProgCompIds.CONTACTS) {
    primaryKey = "UserInfo_ID"
    formattedObject = {
      ...reference,
      tempId: makeTempId(reference, primaryKey),
      displayValue: reference.Organization ? `${reference.First_Name} ${reference.Last_Name} - ${reference.Organization} ${reference.Position}` :
        `${reference.First_Name} ${reference.Last_Name}` ,
      type: 'Contact',
      selected: false,
      tableMapId: certTableMapIds.USER_INFO,
      refId: reference.UserInfo_ID,
      progCompId: programComponentId,
      componentObjectId: null,
      linkId: reference[primaryKey]
    }
  }
  return formattedObject
}

const makeTempId = (reference, primaryKey) => {
  return `${primaryKey}_${reference[primaryKey]}`
}

export const flattenParagraphs = (paragraphList, flattenedList = []) => {
  for (const paragraph of paragraphList) {
    flattenedList.push(paragraph);
    if (paragraph._associations.CFR.length) {
      flattenParagraphs(paragraph._associations.CFR, flattenedList)
    }
  }
  return flattenedList;
};

export const makeSectionNumberingMap = (manualSections) => {
  const numberingMap = {};
  let numbering = 1;
  if (manualSections?.length > 0) {
    for (const section of manualSections) {
      if (section.Auto_Number) {
        numberingMap[section.ManualSection_ID] = numbering;
        numbering = numbering + 1;
      }
    }
  }
  return numberingMap;
};
