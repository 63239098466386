import http from "./http-common";
import authHeader from "./authHeader";

class BuilderService {

  // get all active Builder by type
  getAllActiveBuilderByType(type) {
    return http
      .get(`/builder/allActiveBuilderByType/${type}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getPublishedVersions(type) {
    return http
      .get(`/builder/publishedVersions/${type}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get current ComponentObject Builder
  getCurrentCompObjectBuilder(compObjId) {
    return http
      .get(`/builder/currentCompObjBuilder/${compObjId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get Builder by Builder_ID
  getBuilderById(builderId) {
    return http
      .get(`/builder/${builderId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get list of Previous ComponentObject Builders
  getListOfPreviousCompObjectBuilders(compObjId) {
    return http
      .get(`/builder/previousCompObjBuilders/${compObjId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get list of Builder Sections
  getBuilderSections(builderId) {
    return http
      .get(`/builder/builderSections/${builderId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get Framework Group Reference
  getFrameworkGroupRefs(tableRef, groupName, tableName) {
    return http
      .get(`/builder/framework/${tableRef}/group/${groupName}/${tableName}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get list of RegFrameworks
  getRegFrameworks() {
    return http
      .get(`/builder/policy/regFrameworks`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  //add version as argument
  // get list of Policy Template Group
  getListPolicyTemplateGroup(objRef, version) {
    return http
      .get(`/builder/policy/templateGroup/${objRef}/${version}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get Builder RegRefereces
  getBuilderRegRefs = (builderId) => {
    return http
      .get(`/builder/${builderId}/regRefs/`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get Practice Builder RegRefereces
  getPracticeBuilderRegRefs = (builderId) => {
    return http
      .get(`/builder/${builderId}/practiceRegRefs/`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

 // get list of Programs that have workpackages available
  getWpPrograms = () => {
    return http
      .get(`/builder/wp/programs/`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // update a builder section
  updateBuilderSection(builderSection, builderSectionId) {
    return http
      .put(`/builder/builderSections/${builderSectionId}`, builderSection, { headers: authHeader() })
      .then((response) => {
        return response;
      });
  }

  createBuilder(builder, builderSections, regRefs) {
    return http
      .post(`/builder`, { builder, builderSections, regRefs }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  updateBuilder(builderId, builder, builderSections, regRefs) {
    return http
      .put(
          `/builder/${builderId}`,
          { builder, builderSections, regRefs },
          { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      });
  }

  createPlanBuilder(builderData, builderSections, workpackages, tasks) {
    return http
      .post(`/builder/plan`, { builderData, builderSections, workpackages, tasks }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  updatePlanBuilder(builderId, builderData, builderSections, workpackages, tasks) {
    return http
      .put(
          `/builder/plan/${builderId}`,
          { builderData, builderSections, workpackages, tasks },
          { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      });
  }

  // save new builder history
  saveNewBuilderHistory(builderHistory) {
    return http
      .post(`/builder/saveNewBuilderHistory/`, { builderHistory }, { headers: authHeader() })
      .then((response) => {
        return response;
      });
  }

  // publish builder
  publish(builder) {
    return http
      .post(`/builder/publish/`, { builder }, { headers: authHeader() })
      .then((response) => {
        return response;
      });
  }

  // get list of Builer WP Tasks
  getBuilderWpTasks(builderId) {
    return http
      .get(`/builder/wp/tasks/${builderId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

}

export default new BuilderService();
