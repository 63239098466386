import { mergeReplace } from "reducers/utils/payloadUtils";
import { removeObjInArray } from "utils/arrayOfObjectsHelpers";
export const ACTION_SET_ASSIGNMENTS = "assignments__set";
export const ACTION_REPLACE_ASSIGNMENT = "assignment__replace";
export const ACTION_ADD_ASSIGNMENT = "assignment__add";
export const ACTION_REMOVE_ASSIGNMENT = "assignment__remove";
export const ACTION_SET_ASSIGNABLE_USERS = "assignments_assignable_users__set";
export const ACTION_ADD_ASSIGNABLE_USERS = "assignments_assignable_users__add";
export const ACTION_SET_ASSIGNABLE_TRAINING_MODULES = "assignments_assignable_training_modules__set";
export const ACTION_SET_TRAINING_ROLES = "training_roles__set";

export const assignmentsInitialState = {
  assignments: null,
  assignableUsers: null,
  assignableTrainingModules: null,
  trainingRoles: null,
};

/*
 * Reducer for Training Assignemnts Table.
 */
export default function trainingAssignmentsReducer(state, action) {
  switch (action.type) {
    case ACTION_SET_ASSIGNMENTS:
      return {
        ...state,
        assignments: action.payload,
      };

    case ACTION_SET_ASSIGNABLE_USERS:
      return {
        ...state,
        assignableUsers: action.payload,
      };
    case ACTION_ADD_ASSIGNABLE_USERS:
      return {
        ...state,
        assignableUsers: [
          ...[].concat(state.assignableUsers),
          ...[].concat(action.payload)
        ],
      };

    case ACTION_SET_ASSIGNABLE_TRAINING_MODULES:
      return {
        ...state,
        assignableTrainingModules: action.payload,
      }

    case ACTION_SET_TRAINING_ROLES:
      return {
        ...state,
        trainingRoles: action.payload
      }

    case ACTION_ADD_ASSIGNMENT:
      return {
        ...state,
        assignments: [...state.assignments, action.payload],
      };
    case ACTION_REPLACE_ASSIGNMENT:
      return {
        ...state,
        assignments: mergeReplace(state.assignments, action.payload, "TrainingAssignment_ID"),
      };
    case ACTION_REMOVE_ASSIGNMENT: //FORMERLY DEACTIVATE in CONTACT
      return {
        ...state,
        assignments: removeObjInArray(
          state.assignments,
          "TrainingAssignment_ID",
          action.payload
        ),
      };
    default:
      return state;
  }
}
