import { mergeDeleteForFk, mergeReplace, mergeReplaceList, mergeSetForFk } from "reducers/utils/payloadUtils";
import { removeObjInArray } from "utils/arrayOfObjectsHelpers";
import { ACTION_SET_USERS } from "../shared/sharedActions";

export const ACTION_SET_RUNBOOK = "one_runbook__set";
export const ACTION_SET_RUNBOOK_INCIDENT = "runbook__runbook_incidents__set";
export const ACTION_SET_RUNBOOK_TASKS = "runbook__runbook_tasks__set";
export const ACTION_REPLACE_RUNBOOK_TASK = "runbook__runbook_task__replace";
export const ACTION_REMOVE_RUNBOOK_TASK = "runbook__runbook_task__remove";
export const ACTION_SET_RUNBOOK_TASK_CHANGES = "runbook__runbook_task_changes__set";
export const ACTION_ADD_RUNBOOK_TASK_CHANGES = "runbook__runbook_task_changes__add";
export const ACTION_SET_RUNBOOK_TASK_DEPENDENCIES = "runbook__runbook_task_dependencies__set";
export const ACTION_REPLACE_RUNBOOK_TASK_DEPENDENCIES = "runbook__runbook_task_dependencies__replace";
export const ACTION_REMOVE_RUNBOOK_TASK_DEPENDENCIES = "runbook__runbook_task_dependencies__remove";
export const ACTION_SET_RUNBOOK_PHASES = "runbook__runbook_phases__set";
export const ACTION_SET_WORKSTREAMS = "runbook__workstreams__set";
export const ACTION_SET_WORKSTREAM_MEMBERS = "runbook__workgroup_members__set";
export const ACTION_SET_WORKGROUPS = "workgroups__set";
export const ACTION_SET_WORKGROUP_MEMBERS = "workgroup_members__set";
export const ACTION_SET_UI_HIGHLIGHTED_ROW = "ui__highlighted_runbook_task_id__set";
export const ACTION_REMOVE_UI_HIGHLIGHTED_TASK = "ui__highlighted_runbook_task_id__remove"
export const ACTION_REPLACE_RUNBOOK_INCIDENT = "runbook__runbook_incident__replace"
export const ACTION_REMOVE_RUNBOOK_INCIDENT = "runbook_runbook_incidents_remove"

const appendTaskChange = (state, action) => {
  const stateChanges = state.runbookTaskChanges || [];
  if (!action.meta?.changes) {
    return stateChanges;
  }
  const actionChanges = [].concat(action.meta?.changes);
  return mergeReplaceList(stateChanges, actionChanges, "TaskChange_ID");
};

export const oneRunbookInitialState = {
  runbook: null,
  runbookIncidents: null,
  runbookPhases: null,
  runbookTasks: null,
  runbookTaskChanges: null,
  runbookTaskDependencies: null,
  users: null,
  workgroups: null,
  workgroupMembers: null,
  workstreams: null,
  workstreamMembers: null,
  uiHighlightedTask: null,
};

// Reducer for a page involving one reducer, where all data is in relation to `runbook`
export default function oneRunbookReducer(state, action) {
  switch (action.type) {
    case ACTION_SET_RUNBOOK:
      return {
        ...state,
        runbook: action.payload
      };
    case ACTION_SET_RUNBOOK_INCIDENT:
      return {
        ...state,
        runbookIncidents: action.payload
      };

    case ACTION_SET_RUNBOOK_TASKS:
      return {
        ...state,
        runbookTasks: action.payload
      };
    case ACTION_SET_RUNBOOK_TASK_CHANGES:
      return {
        ...state,
        runbookTaskChanges: action.payload
      };
    case ACTION_SET_RUNBOOK_TASK_DEPENDENCIES:
      return {
        ...state,
        runbookTaskDependencies: action.payload
      };
    case ACTION_SET_RUNBOOK_PHASES:
      return {
        ...state,
        runbookPhases: action.payload
      };
    case ACTION_SET_USERS:
      return {
        ...state,
        users: action.payload
      };
    case ACTION_SET_WORKSTREAMS:
      return {
        ...state,
        workstreams: action.payload
      };
    case ACTION_SET_WORKSTREAM_MEMBERS:
      return {
        ...state,
        workstreamMembers: action.payload
      };
    case ACTION_SET_WORKGROUPS:
      return {
        ...state,
        workgroups: action.payload
      };
    case ACTION_SET_WORKGROUP_MEMBERS:
      return {
        ...state,
        workgroupMembers: action.payload
      };
    case ACTION_SET_UI_HIGHLIGHTED_ROW:
      return {
        ...state,
        uiHighlightedTask: action.payload
      }
    case ACTION_REPLACE_RUNBOOK_TASK:
      return {
        ...state,
        runbookTasks: mergeReplace(
          state.runbookTasks,
          action.payload,
          "Task_ID"
        ),
        runbookTaskChanges: appendTaskChange(state, action),
      };
    case ACTION_REPLACE_RUNBOOK_INCIDENT:
      return {
        ...state,
        runbookIncidents: mergeReplace(
          state.runbookIncidents,
          action.payload,
          "Incident_ID"
        ),
        runbookTaskChanges: appendTaskChange(state, action),
      };
    case ACTION_REMOVE_RUNBOOK_INCIDENT:
      return {
        ...state,
        runbookIncidents: removeObjInArray(
          state.runbookIncidents,
          "Incident_ID",
          action.payload,
        ),
        runbookTaskChanges: appendTaskChange(state, action),
      };
    case ACTION_REPLACE_RUNBOOK_TASK_DEPENDENCIES:
      return {
        ...state,
        runbookTaskChanges: appendTaskChange(state, action),
        runbookTaskDependencies: mergeSetForFk(
          state.runbookTaskDependencies,
          action.payload,
          "Task_ID",
        )
      };
    case ACTION_REMOVE_RUNBOOK_TASK_DEPENDENCIES:
      return {
        ...state,
        runbookTaskChanges: appendTaskChange(state, action),
        runbookTaskDependencies: mergeDeleteForFk(
          state.runbookTaskDependencies,
          "Task_ID",
          action.payload,
        )
      };
    case ACTION_REMOVE_RUNBOOK_TASK:
      return {
        ...state,
        runbookTasks: removeObjInArray(
          state.runbookTasks,
          "Task_ID",
          action.payload,
        ),
        runbookTaskChanges: appendTaskChange(state, action),
        runbookTaskDependencies: mergeDeleteForFk(
          mergeDeleteForFk(
            state.runbookTaskDependencies,
            "Task_ID",
            action.payload,
          ),
          "Dependent_Task_ID",
          action.payload
        )
      };
    case ACTION_REMOVE_UI_HIGHLIGHTED_TASK:
      return {
        ...state,
        uiHighlightedTask: null
      }
    default:
      throw new Error(`Unrecognized action: ${action.type}`);
  }
}
