import http from "./http-common";
import authHeader from "./authHeader";

class RunbookServiceBase {

  //
  // Runbook Routes
  //
  getAllRunbooks() {
    return http
      .get(`/runbook/`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getActiveRunbooks() {
    return http
      .get(`/runbook/active/`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getRunbookIndex() {
    return http
      .get(`/runbook/index`, { headers: authHeader() })
      .then(response => response.data);
  }

  upsertRunbook(runbook) {
    return http
      .post(`/runbook/upsert`, runbook, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  deleteRunbook(runbookId) {
    return http
      .delete(`/runbook/${runbookId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  //
  //  Incident Routes
  //
  getAllIncidents() {
    return http
      .get(`/runbook/incidents/`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getAllIncidentsByRunbook(runbookId) {
    return http
      .get(`/runbook/${runbookId}/incidents`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getActiveIncidents() {
    return http
      .get(`/runbook/incidents/active`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getActiveIncidentsByRunbook(runbookId) {
    return http
      .get(`/runbook/${runbookId}/incidents/active`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  createIncident(runbookId, incident) {
    return http
      .post(`/runbook/${runbookId}/incident/upsert`, incident, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  updateIncident(runbookId, incident) {
    return http
      .post(`/runbook/${runbookId}/incident/upsert`, incident, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  deleteIncident(incidentId) {
    return http
      .delete(`/runbook/incidents/${incidentId}`, { headers: authHeader() })
      .then(response => response.data);
  }

  //
  // Phase Routes
  //
  getAllRunbookPhases() {
    return http
      .get(`/runbook/runbookPhases`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getPhasesByRunbook(runbookId) {
    return http
      .get(`/runbook/${runbookId}/runbookPhases`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  //
  // Task Routes
  //
  getTasksByRunbook(runbookId) {
    return http
      .get(`/runbook/${runbookId}/runbookTasks`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getOpenTasksByRunbook(runbookId) {
    return http
      .get(`/runbook/${runbookId}/runbookTasks/open`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getUpcomingTasksForCurrentUser() {
    return http
      .get(`/runbook/runbookTasks/currentUser/upcoming`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  createRunbookTask(runbookId, data) {
    return http
      .post(`/runbook/${runbookId}/runbookTask`, data, { headers: authHeader() })
      .then(response => response.data);
  }

  updateRunbookTask(taskId, data) {
    return http
      .put(`/runbook/runbookTask/${taskId}`, data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  deleteRunbookTask(taskId) {
    return http
      .delete(`/runbook/runbookTask/${taskId}`, { headers: authHeader() })
      .then(response => response.data);
  }

  //
  // RunbookTaskDependencies
  //

  getTaskDependenciesByRunbook(runbookId) {
    return http
      .get(`/runbook/${runbookId}/runbookTaskDependencies`, { headers: authHeader() })
      .then(response => response.data);
  }

  batchUpdateTaskDependencies(taskId, dependentTaskIds) {
    return http
      .post(
        `/runbook/runbookTask/${taskId}/runbookTaskDependencies`,
        dependentTaskIds,
        { headers: authHeader() }
      )
      .then((response) => response.data);
  }

  //
  // RunbookTaskChanges
  //
  getTaskChangesByRunbook(runbookId) {
    return http
      .get(`/runbook/${runbookId}/runbookTaskChanges`, { headers: authHeader() })
      .then(response => response.data);
  }


  //
  // Workgroup Routes
  //

  getAllWorkgroups() {
    return http
      .get(`/runbook/workgroups/`, { headers: authHeader() })
      .then(response => response.data);
  }

  upsertWorkgroup(workgroup) {
    return http
      .post(`/runbook/workgroup/upsert`, {workgroupData: workgroup}, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  deleteWorkgroup(workgroupId) {
    return http
      .delete(`/runbook/workgroup/${workgroupId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  batchUpdateWorkgroupMembers(workgroupId, userList) {
    return http
      .post(`/runbook/workgroup/${workgroupId}/members/batchUpdate`, userList, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  //
  // WorkgroupMember Routes
  //

  getWorkgroupMembersByRunbook(runbookId) {
    return http
      .get(`/runbook/${runbookId}/workgroupMembers`, { headers: authHeader() })
      .then(response => response.data);
  }

  //
  // Workstream Routes
  //

  getAllWorkstreams() {
    return http
      .get(`/runbook/workstreams/`, { headers: authHeader() })
      .then(response => response.data);
  }

  getWorkstreamsByRunbook(runbookId) {
    return http
      .get(`/runbook/${runbookId}/workstreams`, { headers: authHeader() })
      .then(response => response.data);
  }

  upsertWorkstream(workstream) {
    return http
      .post(`/runbook/workstream/upsert`, {workstreamData: workstream}, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }
  //
  // WorkstreamMember Routes
  //

  getWorkstreamMembersByRunbook(runbookId) {
    return http
      .get(`/runbook/${runbookId}/workstreamMembers`, { headers: authHeader() })
      .then(response => response.data);
  }


  deleteWorkstream(workstreamId) {
    return http
      .delete(`/runbook/workstream/${workstreamId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  batchUpdateWorkstreamMembers(workstreamId, userList) {
    return http
      .post(`/runbook/workstream/${workstreamId}/members/batchUpdate`, userList, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }
}

const RunbookService = new RunbookServiceBase();

export default RunbookService;
