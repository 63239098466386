import { useMemo, useCallback } from "react";
import moment from "moment";
import CustomLink from "components/utils/link.component";
import { downloadFileByRef } from "utils/downloadFile";
import { makeStyles } from "@material-ui/core";
import { REFERENCE_TYPE_FILE, REFERENCE_TYPE_URL } from "utils/requirementsConstants";
import { DescriptionOutlined, OpenInBrowser } from "@material-ui/icons";
import classNames from "classnames";
import { validHttpsUrl } from "utils/stringFuncs";

const useStyles = makeStyles(theme => ({
  referenceList: {
    listStyle: "none",
    padding: 0,
    marginTop: "0px !important",
    marginBottom: "0px !important",
  },
  referenceItem: {
    "&:not(:last-of-type)": {
      marginBottom: 8
    }
  },
  itemLink: {
    display: "flex",
    alignItems: "start"
  },
  icon: {
    marginTop: 2,
    marginRight: 4,
    fontSize: 14
  },
  documentIcon: {
    height: 13
  }
}));

const SERVER_FILENAME_SEPARATOR = "__";


const getFileDisplayName = reference => {
  const splitName = reference.Path_Value.split(SERVER_FILENAME_SEPARATOR);
  return splitName.length === 1 ? splitName[0] : splitName[1];
};

export function ReferenceTableCell({ references }) {
  const classes = useStyles();

  const handleReferenceNavigation = useCallback((reference) => {
    if (reference.Path_Type === REFERENCE_TYPE_URL) {
      return validHttpsUrl(reference.Path_Value);
    } else {
      return undefined;
    }
  }, []);

  const sortedReferences = useMemo(() => (
    ([ ...references ]).sort((r1, r2) => {
      if (r1.Path_Type !== r2.Path_Type) {
        return r1.Path_Type === "file" ? -1 : 1;
      }
      return moment(r2).get("milliseconds") - moment(r1).get("milliseconds")
    })
  ), [references]);

  return (
    <ul className={classes.referenceList}>
      {sortedReferences.map(reference => {
        const urlPathValue = handleReferenceNavigation(reference);
        return (
          <li
            className={classes.referenceItem}
            key={reference.Reference_ID}
          >
            <CustomLink
              variant="underline"
              target="_blank"
              href={urlPathValue}
              onClick={
                reference.Path_Type === REFERENCE_TYPE_FILE ?
                  () => downloadFileByRef(
                    reference.Path_Value,
                    getFileDisplayName(reference)
                  )
                  : undefined
              }
            >
              <div className={classes.itemLink}>
                {reference.Path_Type === "file" && (
                  <DescriptionOutlined
                    fontSize="small"
                    className={classNames(classes.icon, classes.documentIcon)}
                  />
                )}
                {reference.Path_Type === "url" && (
                  <OpenInBrowser fontSize="small" className={classes.icon} />
                )}
                <span>{reference.Description}</span>
              </div>
            </CustomLink>
          </li>
        );
      })}
    </ul>
  );
}
