import moment from "moment";

export const findHourMinuteDuration = (startTime, endTime) => {
  const duration = moment.duration(endTime.diff(startTime));
  const hours = parseInt(duration.asHours());
  const minutes = parseInt(duration.asMinutes()) % 60;

  if (!hours && minutes) {
    return `${minutes} minutes`;
  } else if (hours === 1 && !minutes) {
    return `${hours} hour`;
  } else if (hours && !minutes) {
    return `${hours} hours`;
  } else if (hours === 1 && minutes) {
    return `${hours} hour and ${minutes} minutes`;
  } else if (hours && minutes) {
    return `${hours} hours and ${minutes} minutes`;
  }
  return "";
};

export const findTimezoneShort = date => {
  if (!date) {
    return "";
  }

  const nativeDate = moment(date).toDate();

  // Fallback to timezone offset if not supported by browser
  if (!nativeDate.toLocaleDateString) {
    return moment(date).format("Z");
  }
  return nativeDate.toLocaleDateString('en-US', {
    day: '2-digit',
    timeZoneName: 'short',
  }).slice(4);
}

export const withinTimeDurationLimit = (
  startTime,
  endTime,
  minMinutes,
  maxMinutes
) => {
  const duration = moment.duration(endTime.diff(startTime));
  const minutes = parseInt(duration.asMinutes());

  return minutes >= minMinutes && minutes <= maxMinutes;
};
