// packages
import React, { useEffect, useState } from "react";
// services
import AuthService from "services/auth.service";
import { setStateFetchEffect } from "utils/ajaxHelpers";

const SetupMFA = () => {
  const [qrCode, setQrCode] = useState();

  useEffect(() => (
    setStateFetchEffect(
      AuthService.setupMFA(),
      ([response]) => setQrCode(response.data.qrData)
    )
  ), []);

  if (qrCode) {
    return (
      <>
        <p data-cy="mfa-setup-message">
          Scan this QR code using Microsoft Authenticator,
          Google Authenticator, Authy or any other authentication app
          to set up multi-factor authentication.
        </p>
        <img src={qrCode} alt="Authenticator QR Code" />
      </>
    );
  }
  return null;
};

export default SetupMFA;
