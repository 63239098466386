import http from "./http-common";
import authHeader from "./authHeader";

class RiskAssessmentService {

  getAllCurrentAssessments() {
    return http
      .get(`/riskAssessment/currentRiskAssessments`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  //get assessment builder by id

  getCurrentAssessmentsByProgID(programId) {
    return http
      .get(`/riskAssessment/currentRiskAssessmentsByProgID/${programId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  saveBasicAssessmentData(assessmentData) {
    return http
      .post(`/riskAssessment/saveBasicAssessmentData/`, { assessmentData }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  updateAssessmentData(assessmentData, assessmentDataId, tableMapId) {
    return http
      .put(`/riskAssessment/updateAssessmentData/${tableMapId}/${assessmentDataId}`, { assessmentData }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  getAssessmentItemsByBuilderID(builderId) {
    return http
      .get(`/riskAssessment/getAssessmentItems/${builderId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  createAssessmentItems(assessmentItems) {
    return http.post(
      `/riskAssessment/assessmentItem/list`,
      { assessmentItems },
      { headers: authHeader() }
    )
      .then((response) => response.data);
  }

  deleteAssessmentItems(assessmentItemIds) {
    return http.post(
      `/riskAssessment/assessmentItem/list/delete`,
      { assessmentItemIds },
      { headers: authHeader() }
    )
      .then(response => response.data);
  }

  batchReplaceAssessmentItems(assessmentItems, builderId, userId) {
    return http
      .post(`/riskAssessment/batchReplaceAssessmentItems/`, { assessmentItems, builderId, userId }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  getControlsByFrameworkId(regFrameworkID, programId) {
    return http
      .get(`/riskAssessment/program/${programId}/controls/${regFrameworkID}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  getRegFrameworksByProgID(programId) {
    return http
      .get(`/riskAssessment/getRegFrameworksByProgID/${programId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  getActivePrograms() {
    return http
      .get(`/riskAssessment/getActivePrograms/`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getCharterViewByComponentID(componentId) {
    return http
      .get(`/riskAssessment/getChartersFromView/${componentId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  getPolicyProcedureViewByComponentID(componentId) {
    return http
      .get(`/riskAssessment/getPoliciesAndProceduresFromView/${componentId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  getPlanRoadmapViewByComponentID(componentId) {
    return http
      .get(`/riskAssessment/getPlansAndRoadmapsFromView/${componentId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  getToolsViewByComponentID(componentId) {
    return http
      .get(`/riskAssessment/getToolsFromView/${componentId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  getAssessmentChecklistItemsByBuilderID(builderId) {
    return http
      .get(`/riskAssessment/getAssessmentChecklistItemsByBuilderId/${builderId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  createAssessmentChecklistItem(checklistItemData) {
    return http
      .post(`/riskAssessment/createAssessmentChecklistItem/`, { assessmentChecklistItem: checklistItemData }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  batchReplaceAssessmentChecklistItems(checklistItems, builderId, userId) {
    return http
      .post(`/riskAssessment/batchReplaceAssessmentChecklistItems/`, { checklistItems, builderId, userId }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  getAssessmentTypeByID(assessmentId) {
    return http
      .get(`/riskAssessment/getAssessmentTypeByID/${assessmentId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  getAssessorsByAssessmentID(assessmentId) {
    return http
      .get(`/riskAssessment/getAssessors/${assessmentId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  createAssessmentAssessor(assessor) {
    return http
      .post(`/riskAssessment/createAssessmentAssessor/`, { assessor }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  batchReplaceAssessmentAssessors(assessors, builderId, userId) {
    return http
      .post(`/riskAssessment/batchReplaceAssessmentAssignments/`, { assessors, builderId, userId }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getAssessmentDataCollectionByBuilderID(builderId) {
    return http
      .get(`/riskAssessment/getAssessmentReviewDataByBuilderID/${builderId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  upsertDesignDataCollection(formData) {
    return http
      .put(`/riskAssessment/assessmentDesignDC/`, formData, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  upsertPerformanceDataCollection(formData) {
    return http
      .put(`/riskAssessment/assessmentPerformanceDC/`, formData, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  readAssessmentReferenceListByBuilderID(builderId) {
    return http
      .get(`/riskAssessment/assessmentBuilder/${builderId}/assessmentReferences`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  getAssessmentSummaryRecommendationsByBuilderID(builderId) {
    return http
      .get(`/riskAssessment/getAssessmentSummaryRecommendationsByBuilderID/${builderId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  saveAssessmentSummaryRecommendations(assessmentSummaryRecommendation) {
    return http
      .post(`/riskAssessment/saveAssessmentSummaryRecommendations/`, { assessmentSummaryRecommendation}, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getAssessmentItemRecommendationsByBuilderID(builderId) {
    return http
      .get(`/riskAssessment/getAssessmentItemRecommendationsByBuilderID/${builderId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  updateAssessmentSummaryRecommendationResponse(assessmentSummaryResponse) {
    return http
      .put(`/riskAssessment/updateAssessmentSummaryResponse/`, { assessmentSummaryResponse }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  updateAssessmentItemResponse(assessmentItemResponse) {
    return http
      .put(`/riskAssessment/updateAssessmentItemResponse/`, { assessmentItemResponse }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getAssessmentRoadmapsByBuilderID(builderId) {
    return http
      .get(`/riskAssessment/getAssessmentRoadmapsByBuilderID/${builderId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  getAssessmentRoadmapView(roadmapId) {
    return http
      .get(`/riskAssessment/assessmentRoadmapView/${roadmapId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  initializeAssessmentRoadmapViewItem(viewItem) {
    return http
      .put(
        `/riskAssessment/initializeAssessmentRoadmapViewItem/`,
        { viewItem, builderId: viewItem.AssessmentBuilder_ID },
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      })
  }

  updateAssessmentRoadmapViewItem(roadmap, viewItem) {
    return http
      .put(
        `/riskAssessment/updateAssessmentRoadmapViewItem/`,
        { roadmap, viewItem },
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      })
  }

  createAssessmentRoadmapViewReference(roadmap, tableName, tableRowId) {
    return http
      .put(
        `/riskAssessment/assessmentRoadmapViewItem/roadmapReference/create`,
        { roadmap, tableName, tableRowId },
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      });
  }

  deleteAssessmentRoadmapViewSearchReference(roadmap, tableName, tableRowId, changeDate) {
    return http
      .put(
        `/riskAssessment/assessmentRoadmapViewItem/roadmapReference/delete`,
        { roadmap, tableName, tableRowId, changeDate },
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      });
  }
}


export default new RiskAssessmentService();
