import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Container } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ButtonDefault from "components/utils/buttonDefault.component";
import variables from "styleVariables";
import OrchLogo from "components/utils/orchLogo.component";
import AuthTextField from "components/utils/form-elements/authTextField.component";
import SetupMFA from "components/auth/setupMFA.component";
import { currentUserInfo } from "utils/userHelpers";
import AuthService from "services/auth.service";
import { HTTP_STATUS_OK } from "services/http-common";

const useStyles = makeStyles((theme) => ({
  linkWrapper: {
    marginTop: 30,
    textAlign: "center",
  },
  buttonWrapper: {
    marginTop: 10,
  },
}));

const CustomAlertMessage = withStyles({
  root: {
    color: "white",
    backgroundColor: variables.warningLight,
    padding: "0px 16px",
    marginBottom: 10,

    "& .MuiAlert-icon": {
      fill: "white",
      color: "white",
    },
  },
})(Alert);

const VerifyForm = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [message, setMessage] = useState();
  const [token, setToken] = useState("");

  // handle the form change
  const handleChange = (event) => {
    if (event.target.value.length <= 6) {
      setToken(event.target.value);
    }
  };

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    const [isLoggedInResponse] = await Promise.all([AuthService.verifyMFA(token)]);
    setIsLoggedIn(isLoggedInResponse.status === HTTP_STATUS_OK);
    if (isLoggedInResponse.status === HTTP_STATUS_OK) {
      if (sessionStorage.getItem("originalPath")) {
        history.push("/home", {
          requestLocation: sessionStorage.getItem("originalPath")
        })
      } else {
        history.push("/home");
      }
      sessionStorage.removeItem("originalPath");
      return "";
    } else {
      invalid();
    }
  }, [history, token]);

  const invalid = () => {
    setMessage("Invalid Token");
    setToken("");
  };

  if (isLoggedIn) {
    if (sessionStorage.getItem("originalPath")) {
      const requestLocation = sessionStorage.getItem("originalPath");
      history.push("/home", {
        requestLocation
      })
    } else {
      history.push("/home");
    }
    return "";
  } else {
    const user = currentUserInfo();

    return (
      <Container component="main" maxWidth="xs">
        <OrchLogo />
        {user.mfaEnabled === 0 && (
          <div className={classes.linkWrapper}>
            <SetupMFA />
          </div>
        )}
        {!!message && (
          <CustomAlertMessage
            severity="error"
            className={classes.alertMessage}
            data-cy="error-InvalidToken"
          >
            {message}
          </CustomAlertMessage>
        )}
        <form noValidate>
          <AuthTextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="6 Digit Token"
            name="token"
            autoFocus
            size="small"
            value={token}
            onChange={handleChange}
            test="MFA-code"
          />
          <div className={classes.buttonWrapper}>
            <ButtonDefault
              fullWidth
              variant="large"
              background="secondary"
              onClick={handleSubmit}
              data-cy="loginButton"
              type="submit"
              disabled={token.length !== 6}
            >
              Verify Token
            </ButtonDefault>
          </div>
        </form>
      </Container>
    );
  }
};

export default VerifyForm;