import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// component imports
import Content from "components/layouts/dynamic/content.component";
import ComponentObject from "components/layouts/dynamic/componentObject.component";
import MainLayout from "components/layouts/main/layout.component";
import EnteringPortal from "components/utils/enteringPortal.component";
//services
import usePortalEntry from "hooks/usePortalEntry";

import useNumericParams from "hooks/useNumericParams";
import InnerProgramContextProviders from "./contextProviders/innerProgramContextProviders.component";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

const ProgramPage = () => {
  const classes = useStyles();
  const { componentObjectId } = useNumericParams();

  const [enteringProgram, companyInfo] = usePortalEntry();

  return (
    <InnerProgramContextProviders>
      <div className={classes.root}>
        <MainLayout />
      </div>
      {!!enteringProgram && (
        <EnteringPortal companyInfo={companyInfo} />
      )}
      {!enteringProgram && componentObjectId === undefined && (
        <Content />
      )}
      {!enteringProgram && componentObjectId !== undefined && (
        <ComponentObject />
      )}
    </InnerProgramContextProviders>
  );
};

export default ProgramPage;
