import { makeStyles } from "@material-ui/core";
import TableCell from "components/utils/tables/shared/tableCell.component";
import classNames from "classnames";
import { memo, useMemo } from "react";
import styleVariables from "styleVariables";
import { COLUMN_TYPE_EMPTY, COLUMN_TYPE_RADIOS, COLUMN_TYPE_TEXT, COLUMN_TYPE_USER } from "./constants/reconciliationTableConstants";
import TableCellRadioGroup from "./tableCellRadioGroup.component";

const useStyles = makeStyles((theme) => ({
  cell: {
    backgroundColor: styleVariables.grayTint,
    borderCollapse: "collapse",
    borderRight: `1px solid ${theme.palette.border.main}`,
    borderLeft: `1px solid ${theme.palette.border.main}`,
    color: theme.palette.text.secondary,
    verticalAlign: "top"
  },
  nameCell: {
    backgroundColor: styleVariables.grayTint,
  },
  nameCellInner: {
    display: "flex",
    alignItems: "center",
    "&:not(:last-of-type)": {
      marginBottom: 4
    }
  },
  textCell: {
    lineHeight: "1.2",
  },
  textCellInner: {
    whiteSpace: "pre-line",

    // Needed for width to match width of editable LabelInput
    maxWidth: "fit-content",
    minWidth: "100%",
    wordBreak: "break-word"
  },
}));

const ReadOnlyTableCell = memo(
  function ReadOnlyTableCell(props) {
    const classes = useStyles();
    const { column, isReconcile, response, usersById } = props;

    const cellValue = useMemo(() => {
      if (column.type === COLUMN_TYPE_USER && response?.data?.Is_Reconciled) {
        return null;
      }
      return response?.data?.[column.key];
    }, [column, response]);

    const userCellValue = useMemo(() => {
      if (!column.type === COLUMN_TYPE_USER) {
        return "";
      }
      if (cellValue && typeof cellValue === "string" && !parseInt(cellValue, 10)) {
        return cellValue;
      } else if (cellValue && usersById[cellValue]) {
        return `${usersById[cellValue].First_Name} ${usersById[cellValue].Last_Name}`;
      } else if (isReconcile) {
        return "Reconcile";
      }
      const currentUserInfo = usersById[undefined];
      return currentUserInfo.name;
    }, [cellValue, column.type, isReconcile, usersById]);

    switch(column.type) {
      case COLUMN_TYPE_USER:
        return (
          <TableCell
            className={classNames(
              classes.cell,
              !isReconcile && classes.nameCell
            )}
            colSpan={column.colSpan}
          >
            <div className={classes.nameCellInner}>
              {userCellValue}
            </div>
          </TableCell>
        );
      case COLUMN_TYPE_TEXT:
        return (
          <TableCell
            className={
              classNames(classes.cell, classes.textCell)
            }
            colSpan={column.colSpan}
          >
            <div className={classes.textCellInner}>
              {cellValue}
            </div>
          </TableCell>
        );
      case COLUMN_TYPE_RADIOS:
        return (
          <TableCell
            className={classes.cell}
            colSpan={column.colSpan}
            size="small"
          >
            <TableCellRadioGroup
              column={column}
              response={response}
              readOnly
            />
          </TableCell>
        );
      case COLUMN_TYPE_EMPTY:
        return (
          <TableCell
            className={classes.cell}
            colSpan={column.colSpan}
            size="small"
          />
        );
      default:
        return null;
    }
  }
);

export default ReadOnlyTableCell;
