import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CustomTable from 'components/utils/tables/table.component';
import ImplementationService from "services/implementation.service";
import Loader from "components/utils/loader.components";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: 40
  },
}));

const header = [
  { value: "#", name: "Label" },
  { value: "Principle", name: "Name" },
  { value: "Description", name: "Description" }
];

const leftAlignmentColumn = ["Description"];

const PrivacyMgmtPrinc = () => {
  const classes = useStyles();
  const { programId } = useParams();
  const [tableData, setTableData] = useState();
  const mgmtPrincHeaders = ["#", "Principle", "Description"];

  useEffect(() => {
    const getImpMgmtPrincs = () => {
      ImplementationService.getManagementPrinciples(programId).then((response) => {
        if (response.payload) {
          setTableData(response.payload);
        }
      });
    }
    getImpMgmtPrincs();
  }, [programId]);

  const alignment = [];
  header.forEach((item) => {
    alignment.push((leftAlignmentColumn.includes(item)) ? "left" : "center");
  })

  if (tableData) {
    return (
      <div className={classes.tableContainer}>
        <CustomTable
          maxHeight="calc(100vh - 200px)"
          header={header}
          alignment={alignment}
          data={tableData}
          title="Management Principles"
          mgmtPrincHeaders={mgmtPrincHeaders}
        />
      </div>
    );
  } else {
    return <Loader />;
  }
};

export default PrivacyMgmtPrinc;
