import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import LabelInput from "components/utils/form-elements/labelInput.component";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import FormBanner from "components/utils/form-elements/formBanner.component";
import moment from "moment";
import Dialog from "components/utils/dialog.component";
import CommitteeService from "services/commitee.service";
import { emptyStringToNull } from "utils/stringFuncs";
import { isReadOnly } from "utils/roles";
import DateInput from "components/utils/form-elements/dateInput.component";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  form: {
    padding: "10px 30px 20px 30px",
    align: "center",
  },
  contents: {
    paddingTop: 15,
    paddingBottom: 15,
    overflowY: "scroll",
  },
  formSectionWrapper: {
    padding: "8px 0px 8px 0px",
    width: 590,
    display: "flex",
    justifyContent: "space-between",
  },
  customButtonsWrapper: {
    paddingTop: 30,
    width: 360,
  },
  smallSection: {
    width: 270,
  },
}));

export const CommitteeForm = (props) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({});
  const [loadForm, setLoadForm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { programId } = useParams();

  const [openDialog, setOpenDialog] = useState(false);
  const readOnly = isReadOnly();
  useEffect(() => {
    setLoadForm(false);
    if (props.formMode !== "Add") {
      CommitteeService.getCommitteeByCommitteeId(props.committeeIds.id)
        .then((res) => {
          setFormData(res.payload);
        })
        .then(() => setLoadForm(true));
    } else {
      setFormData({
        First_Name: "",
        Last_Name: "",
        Title: "",
        Group: "",
        Role: "",
        Effective_Date: null,
        Term_Date: null,
      });
      setLoadForm(true);
    }
  }, [props.formMode, props.committeeIds]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSaving(true);
    const currentDate = moment(new Date()).format("YYYY-MM-DD");

    if (props.formMode === "Edit") {
      const comData = {
        Committee_ID: props.committeeIds.id,
        Record_ID: props.committeeIds.record,
        Type: "Steering",
        First_Name: formData.First_Name,
        Last_Name: formData.Last_Name,
        Title: formData.Title,
        Group: formData.Group,
        Role: formData.Role,
        Effective_Date: emptyStringToNull(formData.Effective_Date),
        Status: "Active",
        Term_Date: emptyStringToNull(formData.Term_Date),
        Change_Date: currentDate,
        Program_Program_ID: programId,
      };

      CommitteeService.updateCommittee(comData, props.committeeIds.id).then((res) => {
        setIsSaving(false);
        resetForm();
      });
    }

    if (props.formMode === "Add") {
      const comData = {
        Committee_ID: null,
        Record_ID: null,
        Type: "Steering",
        First_Name: formData.First_Name,
        Last_Name: formData.Last_Name,
        Title: formData.Title,
        Group: formData.Group,
        Role: formData.Role,
        Effective_Date: emptyStringToNull(formData.Effective_Date),
        Status: "Active",
        Term_Date: emptyStringToNull(formData.Term_Date),
        Change_Date: currentDate,
        Program_Program_ID: programId,
      };
      CommitteeService.insertCommittee(comData).then((res) => {
        setIsSaving(false);
        resetForm();
      });
    }
  };

  const disableButton = () => {
    return (
      formData.First_Name.trim() === "" || formData.Last_Name.trim() === ""
    );
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.currentTarget.id]: event.target.value,
    });
  };

  const resetForm = () => {
    props.setEditModal(false);
    props.setRefresh(new Date());
  };

  const deleteRow = async () => {
    await CommitteeService.deleteCommittee(props.committeeIds.id);
    return resetForm();
  };

  if (formData && loadForm) {
    return (
      <div className={classes.formContainer}>
        <FormBanner>{readOnly ? "View" : props.formMode} Committee Member</FormBanner>
        <form className={classes.form}>
          <div className={classes.contents}>
            {/* NAMES */}
            <div className={classes.formSectionWrapper}>
              <div className={classes.smallSection}>
                {/* <FormLabel label={"First Name*"} htmlFor={"First_Name"} /> */}
                <LabelInput
                  onChange={handleChange}
                  value={formData.First_Name || ""}
                  error={formData.First_Name.trim() === ""}
                  margin="dense"
                  id="First_Name"
                  label="First Name"
                  variant="default"
                  required
                  test="First_Name"
                />
              </div>
              <div className={classes.smallSection}>
                {/* <FormLabel label={"Last Name*"} htmlFor={"Last_Name"} /> */}
                <LabelInput
                  onChange={handleChange}
                  value={formData.Last_Name || ""}
                  margin="dense"
                  error={formData.Last_Name.trim() === ""}
                  id="Last_Name"
                  label="Last Name"
                  variant="default"
                  required
                  test="Last_Name"
                />
              </div>
            </div>
            {/* TITLE / GROUP */}
            <div className={classes.formSectionWrapper}>
              <div className={classes.smallSection}>
                {/* <FormLabel label={"Title"} htmlFor={"Title"} /> */}
                <LabelInput
                  onChange={handleChange}
                  value={formData.Title || ""}
                  margin="dense"
                  id="Title"
                  label="Title"
                  variant="default"
                  test="Title"
                />
              </div>
              <div className={classes.smallSection}>
                {/* <FormLabel label={"Group"} htmlFor={"Group"} /> */}
                <LabelInput
                  onChange={handleChange}
                  value={formData.Group || ""}
                  margin="dense"
                  id="Group"
                  label="Group"
                  variant="default"
                  test="Group"
                />
              </div>
            </div>
            {/* ROLE */}
            <div className={classes.formSectionWrapper}>
              <div className={classes.smallSection}>
                {/* <FormLabel label={"Role"} htmlFor={"Role"} /> */}
                <LabelInput
                  onChange={handleChange}
                  value={formData.Role || ""}
                  margin="dense"
                  id="Role"
                  label="Role"
                  variant="default"
                  test="Role"
                />
              </div>
            </div>
            {/* DATES */}
            <div className={classes.formSectionWrapper}>
              <div className={classes.smallSection}>
                {/* <FormLabel
                  label={"Effective Date"}
                  htmlFor={"Effective_Date"}
                /> */}
                <DateInput
                  disabled={formData.type}
                  margin="dense"
                  value={formData.Effective_Date || ""}
                  id="Effective_Date"
                  placeholder="yyyy-mm-dd"
                  onChange={handleChange}
                  label="Effective Date"
                  test="Effective_Date"
                />
              </div>
              <div className={classes.smallSection}>
                {/* <FormLabel label={"Term Date"} htmlFor={"Term_Date"} /> */}
                <DateInput
                  margin="dense"
                  value={formData.Term_Date || ""}
                  placeholder="yyyy-mm-dd"
                  id="Term_Date"
                  onChange={handleChange}
                  label="Term Date"
                  test="Term_Date"
                />
              </div>
            </div>
          </div>
          <DualFormButtons
            variant="smallButton"
            saveOnClick={handleSubmit}
            cancelOnClick={() => props.setEditModal(false)}
            disabled={disableButton()}
            isSaving={isSaving}
            deleteClick={
              props.formMode === "Edit" ? () => setOpenDialog(true) : null
            }
            className={classes.customButtonsWrapper}
          />
        </form>

        <Dialog
          openDialog={openDialog}
          confirm={() => deleteRow()}
          setOpenDialog={setOpenDialog}
          prompt="Are you sure you would like to delete this member from the committee?"
        />
      </div>
    );
  } else {
    return "";
  }
};

export default CommitteeForm;
