import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styleVariables from "styleVariables";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    marginTop: 10,
    marginBottom: 10,
    color: theme.palette.text.secondary,
  },
  orderedList: {
    fontSize: styleVariables.fontMedium,
    color: theme.palette.text.secondary,
  },
  listItem: {
    paddingBottom: 10,
  },
}));

const InfrastructureSubsection = (props) => {
  const classes = useStyles();
  const { listSubSections } = props;

  return (
    <div className={classes.contentContainer}>
      {!listSubSections || listSubSections.length === 0 ? (
        <div>This list is currently empty.</div>
      ) : (
        <div className={classes.orderedList}>
          {listSubSections
            .filter(item => item.Section_Header)
            .map((item, index) => {
              return (
                <div
                  className={classes.listItem}
                  key={item.ReportSubSection_ID}
                >
                  {`${index + 1}. ${item.Section_Header}`}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default InfrastructureSubsection;
