import RiskAssessmentService from "services/riskAssessment.service";
import moment from "moment";
import { currentUserID } from "utils/userHelpers";
import { excludeNewTags } from "./idGenerators";
import { updateAssessmentMapping } from "./assessmentConstants";

// AssessmentItem

const normalizeAssessmentItem = (item, builderId) => ({
  AssessmentBuilder_ID: item.AssessmentBuilder_ID || builderId,
  AssessmentItem_ID: excludeNewTags(item.AssessmentItem_ID),
  ProgramComponent_ID: item.ProgramComponent_ID,
  RegFramework_ID: item.RegFramework_ID,
  Framework_Version: item.Framework_Version,
  Control_ID: item.Control_ID,
  AssessmentItem: item.AssessmentItem,
  Change_User: item.Change_User || currentUserID(),
  Change_Date: item.Change_Date || moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
});

async function batchReplaceAssessmentItems(itemList, builderId) {
  const normalizedItems = [];
  for (const item of itemList) {
    normalizedItems.push(normalizeAssessmentItem(item, builderId));
  }
  return RiskAssessmentService.batchReplaceAssessmentItems(normalizedItems, builderId, currentUserID());
}

// AssessmentChecklistItem

const normalizeChecklistItem = (item, builderId) => ({
  AssessmentBuilder_ID: item.AssessmentBuilder_ID || builderId,
  AssessmentItem_ID: item.AssessmentItem_ID,
  ChecklistItem_ID: excludeNewTags(item.ChecklistItem_ID),
  Checklist_Item: item.Checklist_Item,
  Review_Type: item.Review_Type,
  Change_User: currentUserID(),
  Change_Date: item.Change_Date || moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
  Item_Order: item.Item_Order
});

async function batchReplaceChecklistItems(itemList, builderId) {
  const normalizedItems = [];
  for (const item of itemList) {
    normalizedItems.push(normalizeChecklistItem(item, builderId));
  }
  return RiskAssessmentService.batchReplaceAssessmentChecklistItems(normalizedItems, builderId, currentUserID());
}

// AssessmentAssignment

const normalizeAssessmentAssignment = (assessor, builderId) => ({
  AssessmentAssignment_ID: excludeNewTags(assessor.AssessmentAssignment_ID),
  AssessmentBuilder_ID: assessor.AssessmentBuilder_ID || builderId,
  User_ID: assessor.User_ID,
  Assessment_Role: assessor.Assessment_Role,
  Date_Assigned: assessor.Date_Assigned,
  Change_User: currentUserID(),
  Change_Date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
});

const updateNormalizationMap = { // fields shuld match updateAssessmentMapping
  ASSESSMENT_ITEM: normalizeAssessmentItem,
  ASSESSMENT_CHECKLIST_ITEM: normalizeChecklistItem,
  ASSESSMENT_ASSIGNMENT: normalizeAssessmentAssignment,
}

async function batchReplaceAssessmentAssignments(assessors, builderId) {
  const normalizedAssessors = [];
  for (const assessor of assessors) {
    const assessorToAdd = normalizeAssessmentAssignment(assessor, builderId);
    normalizedAssessors.push(assessorToAdd);
  }
  return RiskAssessmentService.batchReplaceAssessmentAssessors(normalizedAssessors, builderId, currentUserID());
}

async function createAssessmentItems(arrayOfObjects) {
  const itemsToAdd = arrayOfObjects.map(normalizeAssessmentItem);
  return RiskAssessmentService.createAssessmentItems(itemsToAdd);
}

async function editAssessObjs(arrOfObj, assessmentTableInfo) {
  //USED TO EDIT ASSESS ITEMS AND CHECKLIST ITEMS
  const {tableID , idFieldName} = updateAssessmentMapping[assessmentTableInfo];
  return Promise.all(arrOfObj.map(obj => {
    const objToEdit = updateNormalizationMap[assessmentTableInfo](obj);
    objToEdit.isDelete = obj.isDelete;
    return RiskAssessmentService.updateAssessmentData(
      objToEdit,
      obj[idFieldName],
      tableID
    )
  }));
}

export {
  normalizeChecklistItem,
  normalizeAssessmentAssignment,
  batchReplaceAssessmentItems,
  batchReplaceChecklistItems,
  batchReplaceAssessmentAssignments,
  createAssessmentItems,
  editAssessObjs,
};
