import styleVariables from "styleVariables";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
const { Drawer, Button } = require("@material-ui/core");
const { ExpandMore } = require("@material-ui/icons")

const useStyles = makeStyles(() => ({
  expandButton: {
    color: "white",
    fontSize: styleVariables.fontH3,
    backgroundColor: styleVariables.secondaryDark,
    boxShadow: styleVariables.shadowAppbar,
    "&:hover": {
      backgroundColor: styleVariables.secondaryMain,
    }
  },
  expandButtonContents: {
    color: "white",
    fontSize: "24px",
    textShadow: `2px 2px ${styleVariables.primaryDark}`,
  },
}))

const CustomDrawer = ({open, setOpen, children, buttonText}) => {
  const classes = useStyles();
  const toggleDrawer = (isOpen) => {
    setOpen(isOpen);
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      variant="persistent"
    >
      <>
        <Button
          className={classNames(classes.expandButton)}
          color="primary"
          onClick={() => toggleDrawer(false)}
          endIcon={<ExpandMore className={classes.expandButtonContents} fontSize="large" />}
        >{buttonText}
        </Button>
        {children}
      </>
    </Drawer>
  )
}

export default CustomDrawer;