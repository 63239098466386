import React, { useState, useEffect } from "react";
import { Popover } from "@material-ui/core";
import CustomCard from "components/utils/card.component";
import CustomLink from "components/utils/link.component";
import CustomTable from "components/utils/tables/table.component";
import HeaderPrimary from "components/utils/header.component";
import { makeStyles } from "@material-ui/core/styles";
import RemoveIcon from "components/utils/removeIcon.component";
import ImplementationService from "services/implementation.service";
import variables from "styleVariables";
import AddListItem from "components/utils/addListItem.component";
import Dialog from "components/utils/dialog.component";
import LocationsForm from "components/forms/locationsForm.component";
import CustomModal from "components/utils/modal.component";
import { isReadOnly } from "utils/roles";

const useStyles = makeStyles({
  tableWrapper: {
    paddingTop: "10px",
  },
  cellContents: {
    display: "flex",
    width: 210,
    justifyContent: "space-between",
    fontSize: variables.fontSmall,
    color: variables.textSecondary,
  },
  addNewContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "20px 10px",
  },
  groupInfo: {
    padding: 0
  }
});


const header = [
  { name: "internal", value: "Internal" },
  { name: "external", value: "External" }
];
const alignment = ["center", "center"];

const Locations = (props) => {
  const classes = useStyles();
  const [allData, setAllData] = useState();
  const [tableData, setTableData] = useState([]);
  //for popover
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  //Dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [parameter, setParameter] = useState();
  //Modal
  const [editModal, setEditModal] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const readOnly = isReadOnly();

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(
    () => {
      if (props.productId && props.displayType === "table") {
        setRefresh(false)
        ImplementationService.getGroupProductLocations(props.productId).then(
          (res) => {
            setAllData(res.payload)
            const handleClickOpen = (locationName) => {
              const locationId = res.payload.filter(
                (location) => location.Description === locationName
              )[0].DataLocation_ID;
              setParameter({ id: locationId, name: locationName });
              setOpenDialog(true);
            };
            const dataArr = [];
            let length;
            const internal = res.payload
              .filter((obj) => obj.Type === "Internal")
              .map((obj) => obj.Description);
            const external = res.payload
              .filter((obj) => obj.Type === "External")
              .map((obj) => obj.Description);
            if (internal.length === external.length) {
              length = internal.length;
            } else {
              length =
                internal.length > external.length
                  ? internal.length
                  : external.length;
            }
            for (let index = 0; index < length; index++) {
              dataArr.push({
                internal: internal[index] ? (
                  <div className={classes.cellContents} data-cy={`cell-internal-${internal[index]}`}>
                    {internal[index]}
                    {!readOnly && (
                      <RemoveIcon
                        onClick={() => handleClickOpen(internal[index])}
                        test="internal"
                      />
                    )}
                  </div>
                ) : (
                  <div className={classes.cellContents} />
                ),
                external: external[index] ? (
                  <div className={classes.cellContents} data-cy={`cell-external-${external[index]}`}>
                    {external[index]}
                    {!readOnly && (
                      <RemoveIcon
                        onClick={() => handleClickOpen(external[index])}
                        test="external"
                      />
                    )}
                  </div>
                ) : (
                  <div className={classes.cellContents} />
                ),
              });
            }

            setTableData(dataArr);
          }
        );
      } else if (props.productId) {
        ImplementationService.getGroupProductLocations(props.productId).then(
          (res) => {
            const dataArr = [];
            let length;
            const internal = res.payload
              .filter((obj) => obj.Type === "Internal")
              .map((obj) => obj.Description);
            const external = res.payload
              .filter((obj) => obj.Type === "External")
              .map((obj) => obj.Description);
            if (internal.length === external.length) {
              length = internal.length;
            } else {
              length =
                internal.length > external.length
                  ? internal.length
                  : external.length;
            }
            for (let index = 0; index < length; index++) {
              dataArr.push({
                internal: internal[index] ? internal[index] : "",
                external: external[index] ? external[index] : "",
              });
            }
            setTableData(dataArr);
          }
        );
      }
    },
    [props.productId, classes.cellContents, props.displayType, refresh, readOnly]

  );

  const locationClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const toggleForm = () => {
    setEditModal(!editModal);
  };

  const openForm = () => {
    setEditModal(true);
  };

  const deleteLocation = async (locationId) => {
    await ImplementationService.deleteDataLocation(locationId);
    setOpenDialog(false);
    //reloading interface table
    setRefresh(true)
  };

  if (props.displayType === "table") {
    return (
      <>
        <div>
          <HeaderPrimary variant="h4Primary">{props.title}</HeaderPrimary>
        </div>
        <div className={classes.tableWrapper}>
          <CustomTable
            header={header}
            alignment={alignment}
            data={tableData || [{ internal: "No Data" }, { external: "No Data" }]}
            test="data-locations"
          />
        </div>
        <div className={classes.addNewContainer}>
          <AddListItem
            text={`Add New Location  `}
            onClick={() => openForm()}
            test="location"
          />
        </div>
        <CustomModal open={editModal} onClose={toggleForm}>
          <LocationsForm
            setEditModal={setEditModal}
            product={props.product}
            productId={props.productId}
            groupName={props.groupName}
            groupInfo={props.groupInfo}
            allData={allData}
            setRefresh={setRefresh}
          />
        </CustomModal>
        {!!parameter && (
          <Dialog
            openDialog={openDialog}
            confirm={() => deleteLocation(parameter.id)}
            setOpenDialog={setOpenDialog}
            prompt={`Are you sure you would like to remove ${parameter.name}?`}
          />
        )}
      </>
    );
  } else {
    return (
      <div>
        <CustomLink
          onClick={locationClick}
          variant={!!props.disabled && "disabled"}
          test="location"
        >
          Show System Data Locations
        </CustomLink>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <CustomCard
            variant="plain"
            title="System Data Locations"
            test="location-table"
          >
            <HeaderPrimary variant="h6Secondary" className={classes.groupInfo}>
              {`${props.groupName} - ${props.product}`}
            </HeaderPrimary>
            <div className={classes.tableWrapper} data-cy="wrapper-location-popover-table">
              <CustomTable
                header={header}
                alignment={alignment}
                data={tableData}
              />
            </div>
          </CustomCard>
        </Popover>
      </div>
    );
  }
};

export default Locations;
