import ClipLoader from "react-spinners/ClipLoader";
//https://www.npmjs.com/package/react-spinners

const ClipLoad = (props) => {
  return (
    <ClipLoader
      size={props.size ? props.size : 16}
      color={props.color ? props.color : "white"}
      css={props.css}
    />
  );
};

export default ClipLoad;
