import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/utils/header.component";
import CustomSunEditor from "components/utils/sunEditor.component";
import DisplayHTML from "components/utils/displayHTML.component";
import { makePossessive } from "utils/stringFuncs";
import InfrastructureSubsection from "./summary/infrastructureSubsection.component";
import ScoreCardTable from "./summary/scoreCardTable.component";
import { ReportPageSection } from "./reportPageSection.component";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  sectionHtmlItem: {
    alignItems: "stretch",
  },
  sectionCardItem: {
    alignItems: "stretch",
    padding: "16px 32px 24px",
  },
  headerWrapper: {
    paddingBottom: 10,
  },
  avoidPageBreak: {
    "@media print": {
      pageBreakInside: "avoid"
    }
  }
}));


const ExecutiveSummary = (props) => {
  const classes = useStyles();
  const { allSections, company, readOnly, onSubsectionAutosave, section } = props;

  // Todo: Fix seed data to avoid hack
  const subsections = useMemo(() => (
    section._associations.ReportSubSections.filter(subsection => (
      ![
        "Incident Response Scorecard",
        "Improvement Considerations"
      ].includes(subsection.Section_Header)
    ))
  ), [section]);

  const scoreSection = useMemo(() => (
    allSections.find(sectionItem => sectionItem.Type === "engagement")
  ), [allSections]);
  const listSection = useMemo(() => (
    allSections.find(sectionItem => sectionItem.Type === "improvements")
  ), [allSections]);

  return (
    <ReportPageSection title={section.Section_Header}>
      <div className={classes.contentContainer} data-cy={`${section.Section_Header}-content`}>
        {subsections.map(subsection => (
          <div className={classes.sectionHtmlItem} key={subsection.ReportSubSection_ID}>
            {readOnly ? (
              <DisplayHTML html={subsection.Section_Content} />
            ) : (
              <CustomSunEditor
                defaultValue={subsection.Section_Content}
                height="auto"
                label={subsection.Section_Header}
                name={`ReportSubSections__${subsection.ReportSubSection_ID}`}
                onAutosave={(_name, value) => (
                  onSubsectionAutosave(
                    "Section_Content",
                    value,
                    subsection
                  )
                )}
              />
            )}
          </div>
        ))}

        {!!readOnly && (
          <>
            <div className={classNames(classes.sectionCardItem, classes.avoidPageBreak)}>
              <Header variant="h3Primary" className={classes.headerWrapper}>
                {makePossessive(company?.Name)} {scoreSection.Section_Header}
              </Header>
              <ScoreCardTable
                company={company}
                scoreSubSections={scoreSection._associations.ReportSubSections}
              />
            </div>
            <div className={classNames(classes.sectionCardItem, classes.avoidPageBreak)}>
              <Header variant="h3Primary" className={classes.headerWrapper}>
                {listSection.Section_Header}
              </Header>
              <InfrastructureSubsection
                listSubSections={listSection._associations.ReportSubSections}
              />
            </div>
            &nbsp;
          </>
        )}
      </div>
    </ReportPageSection>
  );
};

export default ExecutiveSummary;
