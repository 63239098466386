import React from "react";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import FormElementBase from "./shared/formElementBase.component";
import styleVariables from "styleVariables";

const useStyles = makeStyles((theme) => ({
  input: {
    paddingLeft: 40,
    height: 17,
    color: variables.textSecondary,
  },
}));

const TextFieldPlain = props => (
  <FormElementBase {...props}>
    <TextFieldPlainBase {...props} />
  </FormElementBase>
);


const TextFieldPlainBase = ({
  id,
  className,
  onChange,
  name,
  defaultValue,
  value,
  autoFocus,
  onBlur,
  onFocus,
  onKeyDown,
  inputProps,
  maxLength,
  inputRef,
  disabled,
  readOnly,
  error,
  errorMessage,
  multiline,
  rows,
  test,
}) => {
  const classes = useStyles();
  return (
    <TextField
      id={id}
      className={className}
      onChange={onChange}
      defaultValue={defaultValue}
      value={value}
      autoFocus={autoFocus}
      onBlur={onBlur}
      onFocus={onFocus}
      style={{
        width: "100%",
        maxHeight: multiline ? undefined : 17,
        padding: 0,
        margin: 0
      }}
      name={name}
      onKeyDown={onKeyDown}
      inputRef={inputRef}
      disabled={disabled || readOnly}
      error={error}
      helperText={errorMessage}
      InputProps={{
        className: classes.input,
        disableUnderline: true,
        style: { color: readOnly ? styleVariables.textPrimary : undefined },
        ...inputProps //TODO: Check why this is not InputProps
      }}
      inputProps={{ maxLength }}
      multiline={multiline}
      rows={rows}
      data-cy={test}
    />
  );
};

export default TextFieldPlain;
