import React from "react";
import { MenuItem } from "@material-ui/core";
import CustomSelect from "components/utils/form-elements/select.component";

const UserRolePicker = (props) => {
  const { onChange, disabled, readOnly } = props;
  const {
    label,
    value,
    hideLabel,
    menuOptions = [],
    className,
    errorMessage,
  } = props;

  return (
    <CustomSelect
      label={!hideLabel && (label || "Role")}
      name="Role_ID"
      onChange={onChange}
      value={value}
      test="Role"
      className={className}
      disabled={disabled}
      readOnly={readOnly}
      errorMessage={errorMessage}
      MenuProps={{
        // eslint-disable-next-line
        ["data-cy"]: "roles-menu"
      }}
    >
      {menuOptions.map((option) => {
        return (
          <MenuItem
            key={option.value}
            value={option.value}
            disabled={option.disabled}
            placeholder={option.placeholder}
            data-cy={`option-${option.value}`}
          >
            {option.label}
          </MenuItem>
        );
      })}
    </CustomSelect>
  );
};

export default UserRolePicker;
