import React, { useMemo } from "react";
import { Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ButtonDefault from "components/utils/buttonDefault.component";
import HeaderPrimary from "components/utils/header.component.js";
import DeleteIcon from "@material-ui/icons/Delete";
import CustomLinearProgress from "components/utils/linearProgress.component";
import Link from "components/utils/link.component";
import { FaBoxOpen } from "react-icons/fa";
import moment from "moment";
import {
  truncate,
  progressValue,
} from "utils/workPackageHelpers";
import variables from "styleVariables";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  wp: {
    maxWidth: 280,
    width: "100%",
    position: "relative",
    "&:hover": {
      "& $deleteSectionWrapper": {
        opacity: 1,
      },
    },
  },
  wpIcon: {
    display: "inline-block",
    marginRight: 10,
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  boxIcon: {
    height: 40,
    width: 40,
  },
  iconDefaultColor: {
    fill: variables.secondaryMain,
  },
  wpLinkWrapper: {
    border: "1px solid transparent",
    "&:hover": {
      "& $wpName": {
        color: variables.secondaryMain,
      },
      "& $iconDefaultColor": {
        fill: variables.secondaryLight,
      },
    },
  },
  wpName: {
    color: variables.primaryMain,
  },
  wpTitleWrapper: {
    display: "flex",
    alignItems: "center",
    maxWidth: 200,
    minHeight: 60,
  },
  progressWrapper: {
    color: variables.textSecondary,
    display: "flex",
    flexFlow: "column",
    maxWidth: 150,
  },
  progressBarWrapper: {
    width: "100%",
    marginBottom: 5,
  },
  progressTextWrapper: {
    width: "100%",
    paddingTop: 5,
    textAlign: "right",
  },
  deleteIcon: {
    fontSize: variables.fontH4,
  },
  deleteSectionWrapper: {
    opacity: "0",
    position: "absolute",
    left: 20,
    bottom: 30,
  },
  deleteButton: {
    justifyContent: "flex-start",
  },
  warning: {
    color: variables.warningMain,
  },
  infoRow: {
    display: "flex",
    paddingBottom: 5,
    paddingLeft: 50,
    flexFlow: "column",
    textAlign: "left",
  },
}));

const linkPath = (wpData, progID, filter) => ({
  pathname: `/wpTasks/${wpData.Workpackage_ID}/program/${progID}`,
  state: {
    chosenWp: wpData,
    filterBy: filter
  },
});



const Workpackage = ({ data, checkDeletable, programId}) => {
  const classes = useStyles();
  const pathProgramID = programId

  const overdueTasks = useMemo(() => {
    const now = moment();
    return data.Tasks.filter(task => (
      task.Status !== "Completed" &&
      moment(task.Proposed_Completion).diff(now, "days")
    ));
  }, [data]);

  return (
    <Grid item xs={12} sm={6} className={classes.wp}>
      <div
        data-cy={`wrapper-wpID-${data.Workpackage_ID}`}
        className={classes.wpLinkWrapper}
      >
        <Link
          variant="routerLink"
          className={classes.wpTitleWrapper}
          to={linkPath(data, pathProgramID)}
          test={`dash-wp-${data.Name}`}
        >
          <span
            className={classNames(classes.wpIcon)}
            aria-labelledby="workpackage-initials"
          >
            <FaBoxOpen className={classNames(classes.boxIcon, overdueTasks.length > 0 ? classes.iconOverdueColor : classes.iconDefaultColor)} />
          </span>
          <HeaderPrimary variant="h4Custom" className={classes.wpName}>{truncate(data.Name)}</HeaderPrimary>
        </Link>
      </div>

      <div className={classes.progressWrapper} data-cy={`wpProgress-${data.Name}`}>
        <Box width="100%" mr={1} className={classes.progressBarWrapper}>
          <CustomLinearProgress
            variant="determinate"
            value={progressValue(data.completed, data.total)}
          />
        </Box>
        <Box width="100%" mr={1} className={classes.progressTextWrapper}>
          <span className={classes.infoRow}>
            <Link
              variant="routerLink"
              to={linkPath(data, pathProgramID, "Open")}
              test={`openTasks-${data.Name}`}
            >
              {data.total - data.completed} Open Tasks
            </Link>
            {overdueTasks.length > 0 && (
              <span className={classNames(classes.warning)}>
                - {overdueTasks.length} Past Due
              </span>
            )}
          </span>
          <span className={classes.infoRow}>
            <Link
              variant="routerLink"
              to={linkPath(data, pathProgramID, "Completed")}
              test={`completedTasks-${data.Name}`}
            >
              {data.completed} Complete
            </Link>
            <div className={classes.empty} />
          </span>
        </Box>
      </div>

      <div className={classes.deleteSectionWrapper}>
        <ButtonDefault
          className={classes.deleteButton}
          variant="iconSmall"
          color="red"
          startIcon={<DeleteIcon className={classes.deleteIcon} />}
          component="label"
          onClick={() => checkDeletable(data.Workpackage_ID, data.Name)}
          data-cy={`delete-wp-${data.Name}`}
          disableReadOnlyUsers
        >
          Delete
        </ButtonDefault>
      </div>
    </Grid>
  );
};

export default Workpackage;
