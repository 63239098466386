//Checks if the string passed in is a json object
function isJson(str) {
  try {
    JSON.parse(str);
  } catch (error) {
    return false;
  }
  return true;
}

//Parses a json string and returns the parsed value.
//If the string is not a json, returns null.
const parsedValue = (jsonString, field) => {
  if (isJson(jsonString)) {
    return JSON.parse(jsonString)[field];
  } else {
    return null;
  }
};

export { parsedValue, isJson };
