
import FileService from "services/file.service"

 // download file
const downloadFile = (id, File_Ref) => {
  // download file
  FileService.getFileDownload(id).then(blob => handleSuccess(blob, File_Ref));
};

// download file by File_Ref attribute
const downloadFileByRef = (File_Ref, Reference_Name = File_Ref) => {
  // download file
  FileService.downloadFileByRef(File_Ref).then(blob => handleSuccess(blob, Reference_Name));
};

const handleSuccess = (blob, name) => {
  // Create blob link to download
  const url = window.URL.createObjectURL(new Blob([blob.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${name}`);
  // Append to html page
  document.body.appendChild(link);
  // Force download
  link.click();
  // Clean up and remove the link
  link.parentNode.removeChild(link);
}

export { downloadFile, downloadFileByRef };
