import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip as MUITooltip } from "@material-ui/core";
import variables from "styleVariables";

const useStyles = makeStyles((theme) => ({
  disabledGray: {
    backgroundColor: variables.grayDisabled,
  },
}));

const Tooltip = (props) => {
  const classes = useStyles();
  if (!props.title) {
    return props.children;
  }
  if (props.disabled) {
    return (
      <MUITooltip
        id={props.id}
        title={props.title}
        placement={props.placement}
        classes={{ tooltip: classes.disabledGray }}
        disableFocusListener={props.disableFocusListener}
        enterDelay={props.enterDelay}
        enterNextDelay={props.enterNextDelay}
      >
        {props.children}
      </MUITooltip>
    );
  }
  return (
    <MUITooltip
      id={props.id}
      title={props.title}
      placement={props.placement}
      classes={props.classes}
      disableFocusListener={props.disableFocusListener}
      enterDelay={props.enterDelay}
      enterNextDelay={props.enterNextDelay}
    >
      {props.children}
    </MUITooltip>
  );
};

export default Tooltip;
