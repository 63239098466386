import React, { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { useEffect } from "react";
import imageService from "services/image.service";


const Image = (props) => {
  const [imageBase64, setImageBase64] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const {
    imageBase64: imageBase64Prop, // Required if imageId not passed
    imageId, // Required if image64Prop not passed
    logo64Prop: _logo64Prop,
    logoId: _logoId,
    className = null,
    alt = "",
    ...htmlProps
  } = props;

  useEffect(() => {
    if (imageBase64Prop !== undefined) {
      setImageBase64(imageBase64Prop);
    } else if (imageId) {
      imageService
        .getImage(imageId, () => setIsLoading(true))
        .then((response) => {
          setImageBase64(response.data.payload);
          setIsLoading(false);
        })
        .catch(() => {
          setImageBase64(null);
          setIsLoading(false);
        });
    } else {
      throw new Error(
        "Must pass either imageId or imageBase64 to Image component."
      );
    }
  }, [imageId, imageBase64, imageBase64Prop]);

  if (isLoading) {
    return <CircularProgress />;
  }
  return (
    <img
      src={imageBase64}
      className={className}
      alt={alt}
      {...htmlProps}
    />
  );
};

export default Image;
