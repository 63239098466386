import { Box } from "@material-ui/core";
import Loader from "components/utils/loader.components";
import useNumericParams from "hooks/useNumericParams";
import { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";
import RunbookService from "services/runbook.service";
import ProgRunbooks from "./shared/progRunbooks.component";
import RunbookViewTablePage from "./viewTablePage/runbookViewTablePage.component";


export default function RunbookViewProgramComponent() {
  const { programId } = useNumericParams();

  const [runbooks, setRunbooks] = useState(null);

  useEffect(() => (
    (async function fetchRequiredData() {
      const runbooksResponse = await RunbookService.getAllRunbooks();
      setRunbooks(runbooksResponse.payload);
    })()
  ), []);

  if (!runbooks) {
    return (
      <Loader />
    );
  }
  return (
    <Box marginTop={6}>
      <Switch>
        <Route
          path="/program/:programId/:programComponentId/runbook/:runbookId"
          exact
        >
          <RunbookViewTablePage
            runbooks={runbooks}
          />
        </Route>
        <Route path="/program/:programId/:programComponentId">
          <ProgRunbooks
            programId={programId}
            runbooks={runbooks}
          />
        </Route>
      </Switch>
    </Box>
  );
}
