// packages
import React from "react";
// component imports
import AuthFormWrapper from "components/auth/authFormWrapper.component";
import VerifyForm from "components/auth/verifyForm.component";
import BackgroundImage from "components/auth/backgroundImage.component";

const Verify = () => {

  return (
    <BackgroundImage>
      <AuthFormWrapper>
        <VerifyForm />
      </AuthFormWrapper>
    </BackgroundImage>
  );
};

export default Verify;
