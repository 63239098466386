import {
  builderSectionTypes,
  firstWPButtonsSection,
  wpButtonDefaultSectionHeader,
  wpButtonSectionHeader,
  wpButtonTypeClientIds,
} from "./builderConstants";
import { builderSectionsMatch, makeSectionClientId } from "./builderFunctions";

export const isFirstWpButtonTypeSection = (section) => {
  if (section._meta?.clientId) {
    return section._meta.clientId === wpButtonTypeClientIds.FIRST;
  }
  return (
    section.Type === builderSectionTypes.WP_BUTTONS &&
    section.SectionHeader === wpButtonDefaultSectionHeader &&
    section.Section_Order === 0
  );
};

export const isWpWpButtonTypeSection = (section) => {
  if (section._meta?.clientId) {
    return section._meta.clientId === wpButtonTypeClientIds.WP;
  }
  return (
    section.Type === builderSectionTypes.WP_BUTTONS &&
    section.SectionHeader !== wpButtonDefaultSectionHeader
  );
};

export const isLastWpButtonTypeSection = (section) => {
  if (section._meta?.clientId) {
    return section._meta.clientId === wpButtonTypeClientIds.LAST;
  }
  return (
    section.Type === builderSectionTypes.WP_BUTTONS &&
    section.SectionHeader === wpButtonDefaultSectionHeader &&
    section.Section_Order > 0
  );
};

export const isOuterWpButtonsSection = (section) => {
  return (
    isFirstWpButtonTypeSection(section) || isLastWpButtonTypeSection(section)
  );
};

export const attachMetaToPlanBuilderSections = (sections) => {
  return sections.map((section, index) => {
    if (isFirstWpButtonTypeSection(section)) {
      return {
        ...section,
        _meta: {
          clientId: wpButtonTypeClientIds.FIRST,
        },
      };
    }
    if (isWpWpButtonTypeSection(section)) {
      return {
        ...section,
        _meta: {
          clientId: wpButtonTypeClientIds.WP,
        },
      };
    }
    if (isLastWpButtonTypeSection(section)) {
      return {
        ...section,
        _meta: {
          clientId: wpButtonTypeClientIds.LAST,
        },
      };
    }
    return {
      ...section,
      _meta: { clientId: makeSectionClientId(section?.BuilderSection_ID, index) },
    };
  });
};

export const makeWpClientId = (existingId, index = "") => {
  return existingId || `${new Date().getTime()}-workpackage-${index}`;
};

export const makeTaskClientId = (existingId, index = "") => {
  return existingId || `${new Date().getTime()}-task-${index}`;
};

export const attachMetaToLoadedTasks = (tasks) => {
  return tasks.map((task) => ({
    ...task,
    Parent_ID: !task.Parent_ID ? null : task.Parent_ID,
    Workpackages_Workpackage_ID: task.Workpackages_Workpackage_ID,
    _meta: {
      clientId: task.Task_ID,
      parentClientId: task.Parent_ID
        ? task.Parent_ID
        : task.Workpackages_Workpackage_ID,
      associatedWpId: task.Workpackages_Workpackage_ID,
      type: task.Parent_ID ? "subTask" : "task",
      parentTaskId: task.Parent_ID
    },
  }));
};

export const attachMetaToLoadedWps = (wps, currentBuilderId) => {
  return wps.map((wp, index) => ({
    ...wp,
    Reg_Refs_ID: currentBuilderId ? null : wp.Reg_Refs_ID,
    Builder_Builder_ID: currentBuilderId || wp.Builder_Builder_ID,
    BuilderSection_ID: currentBuilderId ? null : wp.BuilderSection_ID,
    _meta: {
      clientId: wp.Workpackage_ID,
      wp_order: parseInt(wp.Source_Object)
        ? parseInt(wp.Source_Object)
        : index + 1, //storing order in Source_Object regref field
    },
  }));
};

/* Only used when adding a section via section with type wpButton */
export const reOrderedSectionsViaWpButtonClick = (sections, sectionClicked) => {
  let eligibleSections;
  let newSection;
  let updatedSections;
  if (isFirstWpButtonTypeSection(sectionClicked)) {
    eligibleSections = sections.filter(
      (section) => !isFirstWpButtonTypeSection(section)
    );

    newSection = {
      SectionContent: "",
      SectionHeader: "",
      Type: null,
      _meta: {
        clientId: makeSectionClientId(),
      },
    };

    updatedSections = [newSection, ...eligibleSections].map((section, index) => ({
      ...section,
      Section_Order: index + 1,
    }));
  }

  if (isLastWpButtonTypeSection(sectionClicked)) {
    const eligibleSectionList = sections.filter(
      (section) => !isLastWpButtonTypeSection(section)
    );

    newSection = {
      SectionContent: "",
      SectionHeader: "",
      Type: null,
      _meta: {
        clientId: makeSectionClientId(),
      },
    };

    updatedSections = [...eligibleSectionList, newSection].map((section, index) => ({
      ...section,
      Section_Order: index + 1,
    }));
  }

  if (isWpWpButtonTypeSection(sectionClicked)) {
    newSection = {
      Section_Order: sectionClicked.Section_Order,
      SectionContent: "",
      SectionHeader: "",
      Type: builderSectionTypes.WORKPACKAGE,
      _meta: {
        clientId: makeSectionClientId(),
      },
    };
    updatedSections = [...sections, newSection]
      .filter((sec) => !isWpWpButtonTypeSection(sec))
      .sort((a, b) => a.Section_Order - b.Section_Order);
  }
  if (!updatedSections) {
    console.error("Updated Section failed");
    return sections;
  }
  return updatedSections;
};

/* This will not be called if only wpButtons exist */
export const reOrderedSectionsWithAddedSectionPlan = (sections, newSection) => {
  const firstWpButtonSection = sections.find((section) =>
    isFirstWpButtonTypeSection(section)
  );
  const lastWpButtonsSection = sections.find((section) =>
    isLastWpButtonTypeSection(section)
  );
  let eligibleSections = [...sections];
  let formattedSections;

  //If one of the outer wpButtons exists, remove them from ordering
  if (firstWpButtonSection || lastWpButtonsSection) {
    eligibleSections = [...sections].filter(
      (section) => !isOuterWpButtonsSection(section)
    );
  }

  const eligibleSectionsWithUpdatedOrder = eligibleSections.map((sec, index) => {
    return {
      ...sec,
      Section_Order: sec.Section_Order >= newSection.Section_Order ? sec.Section_Order + 1 : sec.Section_Order,
    };
  });

  if (lastWpButtonsSection && !firstWpButtonSection) {
    const updatedWithNew = [
      ...eligibleSectionsWithUpdatedOrder,
      newSection,
    ].sort((a, b) => a.Section_Order - b.Section_Order);

    formattedSections = [...updatedWithNew, lastWpButtonsSection].map(
      (sec, index) => {
        return { ...sec, Section_Order: index + 1 };
      }
    );
  }

  if (firstWpButtonSection && !lastWpButtonsSection) {
    const updatedInnerSections = [...eligibleSectionsWithUpdatedOrder, newSection].sort(
      (a, b) => a.Section_Order - b.Section_Order
    );
    formattedSections = [firstWpButtonSection, ...updatedInnerSections]
  }

  if (!firstWpButtonSection && !lastWpButtonsSection) {
    formattedSections = [...eligibleSectionsWithUpdatedOrder, newSection].sort(
      (a, b) => a.Section_Order - b.Section_Order
    );
  }

  if (!formattedSections) {
    console.error("Updated Section failed");
    return sections;
  }
  return formattedSections;
};

export const sectionsAfterRemovingWpSection = (
  allSections,
  wpSectionToDelete
) => {
  const filteredSections = allSections.filter(
    (sec) => sec._meta.clientId !== wpSectionToDelete._meta.clientId
  );

  const newWpButtonSection = {
    Section_Order: wpSectionToDelete.Section_Order,
    SectionContent: "",
    SectionHeader: wpButtonSectionHeader,
    Type: builderSectionTypes.WP_BUTTONS,
    _meta: {
      clientId: wpButtonTypeClientIds.WP,
    },
  };

  return [...filteredSections, newWpButtonSection].sort(
    (a, b) => a.Section_Order - b.Section_Order
  );
};

export const sectionsAfterRemovingPlanSection = (
  allSections,
  sectionToDelete
) => {
  let updatedSections;

  const middleWPSection = allSections.find((sec) => {
    return (
      isWpWpButtonTypeSection(sec) ||
      sec.Type === builderSectionTypes.WORKPACKAGE
    );
  });

  const filteredSections = allSections.filter(
    (sec) => !builderSectionsMatch(sec, sectionToDelete)
  );

  // Deleting Section Before Middle WP SECTION
  if (sectionToDelete.Section_Order < middleWPSection.Section_Order) {
    const sectionsBeforeWpSection = allSections.filter(
      (sec) => sec.Section_Order < middleWPSection.Section_Order
    );
    if (sectionsBeforeWpSection.length === 1) {
      updatedSections = [firstWPButtonsSection, ...filteredSections];
    }

    if (sectionsBeforeWpSection.length > 1) {
      updatedSections = filteredSections
        .sort((a, b) => a.Section_Order - b.Section_Order)
        .map((sec, index) => {
          return { ...sec, Section_Order: index + 1 };
        });
    }
  }
  // Deleting Section After Middle WP SECTION
  if (sectionToDelete.Section_Order > middleWPSection.Section_Order) {
    const sectionsAfterWpSection = allSections.filter(
      (sec) => sec.Section_Order > middleWPSection.Section_Order
    );

    if (sectionsAfterWpSection.length === 1) {
      const lastWpButtonSection = {
        Section_Order: sectionToDelete.Section_Order,
        SectionContent: "",
        SectionHeader: "Add Section",
        Type: builderSectionTypes.WP_BUTTONS,
        _meta: {
          clientId: wpButtonTypeClientIds.LAST,
        },
      };
      updatedSections = [...filteredSections, lastWpButtonSection];
    }
    if (sectionsAfterWpSection.length > 1) {
      updatedSections = filteredSections.map((sec) => {
        if (sec.Section_Order > sectionToDelete.Section_Order) {
          return { ...sec, Section_Order: sec.Section_Order - 1 };
        } else {
          return sec;
        }
      });
    }
  }
  if (!updatedSections) {
    console.error("Deleting Plan Section failed");
    return allSections;
  }
  return updatedSections;
};


export const tasksAfterAdd = (allTasks, tasksByWp) => {
  const associatedWpId = tasksByWp[0]._meta.associatedWpId;
  const filteredTasks = allTasks.filter(task => task._meta.associatedWpId !== associatedWpId)

  return [...filteredTasks, ...tasksByWp]

}

export const isWpOrTaskNameMissing = (tasksAndWps) => {
  return tasksAndWps.some((taskOrWp) => {
    return !taskOrWp.Name || taskOrWp?.Name?.trim() === "";
  });
};
