import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { compTypeCheck } from "utils/builderFunctions";
import HeaderPrimary from "components/utils/header.component";
import variables from "styleVariables";
import RiskAssessmentService from "services/riskAssessment.service";
import classNames from "classnames";
import CustomLinearProgress from "components/utils/linearProgress.component";
import { getDateString } from "utils/formatters";
import { abSetupSteps, assessBuilderStatus } from "utils/assessmentConstants";
import { findUserNameByID } from "utils/userHelpers";
import { Typography, Box } from "@material-ui/core";
import { setStateFetchEffect } from "utils/ajaxHelpers";

const useStyles = makeStyles((theme) => ({
  subHeader: {
    paddingTop: 5,
  },
  bodyTextLarge: {
    fontSize: variables.fontLarge,
    color: variables.tertiary1,
    paddingLeft: 10,
  },
  row: {
    display: "inline-flex",
    alignItems: "center",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 5,
  },
  stepContainer: {
    display: "flex",
    flexDirection: "column"
  },
  completeAssessment: {
    display: "flex",
    flexDirection: "column"
  },
  closedBy: {
    fontSize: variables.fontMedium,
    color: variables.tertiary2Dark,
  },
  progressWrapper: {
    width: "100%",
    maxWidth: 300,
    marginBottom: 5,
  },
}));

const AssessmentCardDetail = ({
  builder,
  compTypeRef,
}) => {
  const classes = useStyles();
  const totalNumOfABSteps = abSetupSteps.length;
  const [scopeItemCount, setScopeItemCount] = useState("");
  const [reviewItemCount, setReviewItemCount] = useState("");
  const [userName, setUserName] = useState("");
  const setupSteps = 6;

  const compTypeChecker = (builderCompType) => {
    return compTypeRef === builderCompType || compTypeRef === "Program" + builderCompType
  }

  const isAssessment = compTypeChecker("Assessment")

  const isAssessmentPublished = ((builder.Status !== assessBuilderStatus.BUILDER_STATUS_DRAFT) && isAssessment);
  const isAssessmentCompleted = ((builder.Status === assessBuilderStatus.BUILDER_STATUS_ASSESSED) && isAssessment);

  useEffect(() => {
    if (compTypeCheck("Assessment", compTypeRef)) {
      return setStateFetchEffect([
        RiskAssessmentService.getAssessmentItemsByBuilderID(builder.Builder_ID),
        RiskAssessmentService.getAssessmentDataCollectionByBuilderID(
          builder.Builder_ID
        )
      ], ([itemsResponse, dataCollectionResponse]) => {
        const twoReviewTypes = builder.AssessmentReviewType_ID === 3;
        let assessItemsLength = itemsResponse.items.length;
        if (twoReviewTypes) {
          assessItemsLength *= 2
        }

        const designDc = dataCollectionResponse.payload.design;
        const performanceDc = dataCollectionResponse.payload.performance;
        const designsForItems = designDc.reduce((accumulator, review) => ({
          ...accumulator,
          [review.AssessmentItem_ID]: (accumulator[review.AssessmentItem_ID] || []).concat(review),
        }), {});
        const allReviewsForItems = performanceDc.reduce((accumulator, review) => ({
          ...accumulator,
          [review.AssessmentItem_ID]: (accumulator[review.AssessmentItem_ID] || []).concat(review),
        }), designsForItems);
        const completedItemCount = Object.values(allReviewsForItems).reduce((sum, itemReviewList) => (
          sum + (itemReviewList.every(({ Status }) => Status === "Completed") ? 1 : 0)
        ), 0)

        setScopeItemCount(assessItemsLength);
        setReviewItemCount(completedItemCount);
      });
    }
  }, [builder.Builder_ID, builder.AssessmentReviewType_ID, compTypeRef]);

  useEffect(() => {
    if (builder.Completion_User) {
      return setStateFetchEffect(
        findUserNameByID(builder.Completion_User),
        ([result]) => setUserName(result)
      );
    }
  }, [builder.Completion_User]);

  const stepDisplay = useMemo(() => {
    if (builder.Step === totalNumOfABSteps) {
      return builder.Step - 1
    }
    return builder.Step
  }, [builder.Step, totalNumOfABSteps]);

  const dcProgressValue = () => {
    if (reviewItemCount === 0) {
      return 0;
    } else {
      return reviewItemCount / scopeItemCount * 100;
    }
  }

  const setupProgressValue = () => {
    if (builder.Step) {
      return builder.Step / setupSteps * 100;
    } else {
      return 0;
    }
  }
  return (
    <div className={classes.stepContainer}>
      <div className={classNames(classes.row)}>
        {!!isAssessmentCompleted && (
          <div className={classes.completeAssessment}>
            <div className={classNames(classes.row)}>
              <HeaderPrimary
                variant="h4Tertiary"
                className={classes.subHeader}
              >
                Status:
              </HeaderPrimary>
              <Typography className={classes.bodyTextLarge}>
                Completed
              </Typography>
            </div>
            <div className={classNames(classes.row)}>
              <Typography className={classes.closedBy}>
                {`Closed on ${getDateString(builder.Completion_Date)} by ${userName}`}
              </Typography>
            </div>
          </div>
        )}
        {builder.Status === assessBuilderStatus.BUILDER_STATUS_DRAFT && (
          <div className={classes.column}>
            <Box width="100%" mr={1} className={classes.progressWrapper}>
              <CustomLinearProgress
                variant="determinate"
                value={setupProgressValue()}
              />
            </Box>
            <div className={classes.row}>
              <HeaderPrimary
                variant="h4Tertiary"
                className={classes.subHeader}
              >
                Assessment Setup:
              </HeaderPrimary>
              <Typography className={classes.bodyTextLarge}>
                {!isAssessmentPublished ?
                  `Completed Step ${stepDisplay} of ${totalNumOfABSteps}` :
                  `Completed`}
              </Typography>
            </div>
          </div>
        )}
      </div>
      {builder.Step === 6 && !isAssessmentCompleted && (
        <div className={classes.column}>
          <Box width="100%" mr={1} className={classes.progressWrapper}>
            <CustomLinearProgress
              variant="determinate"
              value={dcProgressValue()}
            />
          </Box>
          <div className={classNames(classes.row)}>
            <HeaderPrimary
              variant="h4Tertiary"
              className={classes.subHeader}
            >
              Review Progress:
            </HeaderPrimary>
            <Typography className={classes.bodyTextLarge}>
              {isAssessmentCompleted ? 'Completed' : `${reviewItemCount} of ${scopeItemCount} Items Reviewed`}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssessmentCardDetail;