import moment from "moment";
import { COMPONENT_ID_RUNBOOK_VIEW, PROGRAM_ID_RUNBOOK } from "./programConstants";

const alertDueMessage = (
  endDate, startDate = null, alertCount = null, fullDateThreshold = null
) => {
  let days = null;
  let message = "";
  let color = "";
  if (endDate) {
    days = moment(endDate).diff(startDate || moment(), "days");
    if (days > fullDateThreshold) {
      color = "assigned";
      message = `Due ${moment(endDate).format("MMMM DD, YYYY")}`;
    } else if (days > 0) {
      color = "assigned";
      message = `Due in ${days} ${Math.abs(days) === 1 ? "day" : "days"}`;
    } else if (days === 0) {
      color = "caution";
      message = `Due today`;
    } else {
      color = "warning";
      message = `Overdue by ${Math.abs(days)} ${
        Math.abs(days) === 1 ? "day" : "days"
      }`;
    }
  }
  if (alertCount) {
    message = `${alertCount} Alerts`;
  }
  return {
    color,
    message
  }
};

const alertPathFinder = (userAlert) => {
  const {
    Program_Program_ID,
    ProgramComponents_Component_ID,
    ComponentObjects_ComponentObject_ID,
  } = userAlert;
  const associatedTask = userAlert._associations.Tasks[0]
  const associatedRunbookTask = userAlert._associations.RunbookTasks[0]

  if (associatedRunbookTask) {
    return {
      pathname: `/program/${PROGRAM_ID_RUNBOOK}/${COMPONENT_ID_RUNBOOK_VIEW}/runbook/${associatedRunbookTask.Runbook_ID}`,
      state: { taskId: associatedRunbookTask.Task_ID },
    };
  } else if (associatedTask) { // For task alerts
    const taskId = associatedTask.Task_ID
    if (associatedTask.Workpackages_Workpackage_ID) {
      return {
        pathname: `/wpTasks/${associatedTask.Workpackages_Workpackage_ID}/program/${Program_Program_ID}`,
        state: { taskID: taskId },
      };
    } else {
      return {
        pathname: `/programTasks/${taskId}/program/${Program_Program_ID}`,
        state: { taskID: taskId }, //Task_Id
      };
    }
    // For non-task alerts
  } else if (ComponentObjects_ComponentObject_ID && ProgramComponents_Component_ID) {
    return {
      pathname: `/program/${Program_Program_ID}/${ProgramComponents_Component_ID}/${ComponentObjects_ComponentObject_ID}`,
    };
  } else if (ProgramComponents_Component_ID) {
    return {
      pathname: `/program/${Program_Program_ID}/${ProgramComponents_Component_ID}`,
    };
  } else {
    return { pathname: `/program/${Program_Program_ID}` };
  }
};

export {
  alertDueMessage,
  alertPathFinder,
};
