import React, { useEffect, useState, useCallback, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import ControlService from "services/control.service";
import variables from "styleVariables";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "components/utils/loader.components";
import useNumericParams from "hooks/useNumericParams";
import ControlMatrixTable from "./controlMatrix/controlMatrixTable.component";
import { Box } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import RadioInputGroup from "components/utils/form-elements/radioInputGroup.component";
import ProgramsContext from "contexts/programs.context";
import SortableTable from "components/utils/tables/sortableTable.component";

const useStyles = makeStyles((theme) => ({
  //TOGGLES
  toggleButtonGroup: {
    backgroundColor: "white",
  },
  toggleButton: {
    padding: "3px 10px 3px 10px",
    fontSize: variables.fontLarge,
    color: variables.textSecondary,
    "&.Mui-selected": {
      backgroundColor: variables.tertiary2Dark,
      color: "white",
      "&:hover": {
        backgroundColor: variables.tertiary2Dark,
        color: "white",
      },
    },
    "&:hover": {
      color: variables.tertiary2Dark,
      backgroundColor: "unset",
      borderColor: variables.tertiary2,
    },
  },
}));

const makeframeworkLabel = (framework) =>
  `${framework.Name} v.${framework.Version}`;

const NO_MAPPED_FRAMEWAORK_VALUE = "none";

const ControlMatrix = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { programId } = useNumericParams();
  const [frameworks, setFrameworks] = useState(false);
  const [selectedFramework, setSelectedFramework] = useState();
  const [selectedMappedFrameworkId, setSelectedMappedFrameworkId] = useState(
    NO_MAPPED_FRAMEWAORK_VALUE
  );
  const [isFetchingMatrixData, setIsFetchingMatrixData] = useState(false);
  const [isLoadingFrameworks, setIsLoadingFrameworks] = useState(false);
  const allPrograms = useContext(ProgramsContext);
  const programName = allPrograms.state.programs[programId].Name;

  useEffect(() => {
    const loadFrameworks = async () => {
      setIsLoadingFrameworks(true);
      const frameworkdRes =
        await ControlService.getControlMatrixFrameworksByProgramId(programId);
      setFrameworks(frameworkdRes.payload);
      if (location.state?.groupId) {
        const targetedFramework = frameworkdRes.payload.find(
          (framework) =>
            framework.RegFramework_id === Number(location.state.groupId)
        );
        setSelectedFramework(targetedFramework);
      } else if (frameworkdRes.payload?.length) {
        setSelectedFramework(frameworkdRes.payload[0]);
      }
      setIsLoadingFrameworks(false);
    };
    loadFrameworks();
  }, [programId, location.state?.groupId]);

  const selectFrameworkClickHandler = useCallback(
    (_event, value) => {
      if (!value) {
        return;
      }
      const frameworkToSelect = frameworks.find(fw => fw.RegFramework_id === Number(value))
      setSelectedFramework(frameworkToSelect);
      setSelectedMappedFrameworkId(NO_MAPPED_FRAMEWAORK_VALUE);
      if (location.state) {
        history.replace();
      }
    },
    [frameworks, history, location.state]
  );

  const selectMappedFrameworkOnChange = useCallback(
    (event) => {
      const value = Number(event.target.value) || NO_MAPPED_FRAMEWAORK_VALUE;
      setSelectedMappedFrameworkId(value);
      if (location.state) {
        history.replace();
      }
    },
    [history, location.state]
  );

  if (!isLoadingFrameworks) {
    return (
      <div
        className={classes.matrixContainer}
      >
        <Box marginTop={5}>
          <ToggleButtonGroup
            className={classes.toggleButtonGroup}
            value={selectedFramework?.RegFramework_id}
            onChange={selectFrameworkClickHandler}
            exclusive
          >
            {frameworks?.length > 0 &&
              frameworks.map((framework) => {
                return (
                  <ToggleButton
                    className={classes.toggleButton}
                    value={framework.RegFramework_id}
                    data-cy={`btn-${framework.Name}_Version_${framework.Version}`}
                    key={framework.RegFramework_id}
                    disabled={isFetchingMatrixData}
                  >
                    {makeframeworkLabel(framework)}
                  </ToggleButton>
                );
              })}
          </ToggleButtonGroup>
          <Box marginTop={2} marginBottom={1} data-cy="framework-mapping-options">
            {!!selectedFramework && (
              <RadioInputGroup
                value={selectedMappedFrameworkId}
                groupLabel="Include Mapped Framework:"
                options={[
                  {
                    value: NO_MAPPED_FRAMEWAORK_VALUE,
                    label: "None",
                    disabled: isFetchingMatrixData,
                    name: "none-option"
                  },
                  ...selectedFramework._associations.MappedFrameworks.map(
                    (mappedframework) => {
                      return {
                        value: mappedframework.RegFramework_id,
                        label: makeframeworkLabel(mappedframework),
                        disabled: isFetchingMatrixData,
                        name: `framework-${mappedframework.RegFramework_id}-option`
                      };
                    }
                  ),
                ]}
                onChange={selectMappedFrameworkOnChange}
                radioGroupClass={classes.filterRadioGroup}
                hideHelperText
                row
              />
            )}
          </Box>
        </Box>
        {!selectedFramework ? (
          <SortableTable
            headers={[{}]}
            data={[]}
            emptyTableValue={`There are no active controls for ${programName}`}
          />
        ) : (
          <ControlMatrixTable
            selectedFramework={selectedFramework}
            selectedMappedFramework={selectedFramework._associations.MappedFrameworks.find(
              (fw) => fw.RegFramework_id === Number(selectedMappedFrameworkId)
            )}
            isFetchingMatrixData={isFetchingMatrixData}
            setIsFetchingMatrixData={setIsFetchingMatrixData}
          />
        )}
      </div>
    );
  } else {
    return <Loader />;
  }
};

export default ControlMatrix;
