import { AppBar, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import ButtonDefault from "components/utils/buttonDefault.component";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import {
  abSetupSteps,
  assessmentBuilderModes,
} from "utils/assessmentConstants";
import {
  ArrowForwardRounded,
  CheckCircleOutlineRounded,
} from "@material-ui/icons";
import Tooltip from "components/utils/tooltip.component";
import Dialog from "components/utils/dialog.component";

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    height: theme.layout.height.appBar,
    padding: "10px 20px",
    borderLeft: `5px solid ${theme.palette.primary.main}`,
  },
  buttonWrapper: {
    display: "inline-block",
    paddingLeft: 12,
  },
  buttonIcon: {
    height: 24,
    width: 24,
    marginRight: 4,
    color: "white",
  },
  buttonSection: {
    flex: "1 0 auto",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  primaryButton: {
    width: 228, // Fits all button text on one line
    paddingLeft: 32,
  },
  saveMessage: {
    color: theme.palette.text.secondary,
    fontSize: 13,
    marginRight: 12,
  },
  titleSection: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginRight: 40,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

function PrimaryButton(props) {
  const {
    handlePublish,
    handleSaveAndContinue,
    isBuilderPublished,
    isContinueDisabled,
    isPublishStep,
    returnToSummary,
  } = props;
  const classes = useStyles();
  const toolTipMessage =
    "This will mark the assessment as open for data collection, and redirect you to the main data collection page.";

  if (isPublishStep) {
    if (isBuilderPublished) {
      return (
        <ButtonDefault
          variant="medium"
          background="secondary"
          className={classNames(classes.primaryButton)}
          onClick={returnToSummary}
          endIcon={<CheckCircleOutlineRounded className={classes.buttonIcon} />}
        >
          Return to Summary
        </ButtonDefault>
      );
    }
    return (
      <Tooltip title={toolTipMessage} placement="top">
        <div>
          <ButtonDefault
            variant="medium"
            background="primary"
            className={classNames(classes.primaryButton)}
            onClick={() => handlePublish(assessmentBuilderModes.MODE_REVIEW)}
            endIcon={<PublishOutlinedIcon className={classes.buttonIcon} />}
            data-cy="publish-and-proceed"
            disableReadOnlyUsers
          >
            Start Assessment
          </ButtonDefault>
        </div>
      </Tooltip>
    );
  }
  return (
    <ButtonDefault
      variant="medium"
      background="primary"
      className={classNames(classes.primaryButton)}
      disabled={isContinueDisabled}
      onClick={handleSaveAndContinue}
      endIcon={<ArrowForwardRounded className={classes.buttonIcon} />}
      data-cy="assessment-next-button"
      disableReadOnlyUsers
    >
      {/* {isSaveDisabled && !isContinueDisabled ? "Continue" : "Save and Continue"} */}
      Continue
    </ButtonDefault>
  );
}

function SecondaryButton({
  handlePublish,
  isBuilderPublished,
  activeStep,
}) {
  const classes = useStyles();
  const toolTipMessage =
    "This will mark the assessment as open for data collection, then return to the Assessment Builder Summary.";
  if (!isBuilderPublished && activeStep === 6) {
    return (
      <Tooltip title={toolTipMessage} placement="top">
        <div>
          <ButtonDefault
            variant="medium"
            background="secondary"
            onClick={() => handlePublish()}
            endIcon={
              <CheckCircleOutlineRounded className={classes.buttonIcon} />
            }
            data-cy="publish-and-return"
            disableReadOnlyUsers
          >
            Finish Setup & Return to Summary
          </ButtonDefault>
        </div>
      </Tooltip>
    );
  }
  return null;
}

export default function AssessmentBuilderSaveBar(props) {
  const {
    activeStep,
    builderInfo,
    isContinueDisabled,
    isSaveDisabled,
    publish,
    saveProgress,
    setActiveStep,
    setMode,
    numAsessItems,
  } = props;

  const classes = useStyles();
  const [activeStepAfterRender, setActiveStepAfterRender] = useState(null);
  const [requiredValueCheck, setRequiredValueCheck] = useState({Title: false, Review_Type: false, AssessItems: false});
  const [openDialog, setOpenDialog] = useState(false);


  // When a new builder is being created, the step needs to be set afterward
  useEffect(() => {
    if (activeStepAfterRender) {
      setActiveStep(activeStepAfterRender);
      setActiveStepAfterRender(null);
    }
  }, [activeStepAfterRender, setActiveStep])

  const stepDisplay = abSetupSteps[activeStep - 1].name;
  const isBuilderPublished = useMemo(
    () => builderInfo.Status === "Active",
    [builderInfo.Status]
  );
  const isPublishStep = useMemo(() => activeStep === 6, [activeStep]);

  const handleSaveAndContinue = async () => {
    const saved = isSaveDisabled || (await saveProgress(activeStep));
    if (saved && builderInfo.Builder_ID) { //builderInfo.Builder_ID needs to be checked because all steps after Step 1 should have builderID in params
      setActiveStepAfterRender(activeStep + 1);
    }
  };

  // Checks if the 3 required items are present before setting the assesssment to 'Active'.
  const checkRequired = () => {
    const requiredDataCheck = {};
    let checkFailed = false;
    if (numAsessItems < 1) {
      requiredDataCheck.AssessItems = true;
      checkFailed = true;
    }
    if (!builderInfo.Review_Type) {
      requiredDataCheck.Review_Type = true;
      checkFailed = true;
    }
    if (!builderInfo.Title) {
      requiredDataCheck.Title = true;
      checkFailed = true;
    }
    if (checkFailed) {
      setRequiredValueCheck((prev) => {
        return { ...prev, ...requiredDataCheck };
      });

      return false
    }
    return true
  }

  const handlePublish = async (mode) => {
    if (!checkRequired()) {
      setOpenDialog(true)
    } else {
      await publish();
      setMode(mode, builderInfo);
    }
  };

  return (
    <AppBar
      color="inherit"
      position="fixed"
      className={classNames(classes.appBar)}
    >
      <h3 className={classNames(classes.titleSection)} data-cy="step-title">
        Step {activeStep}: {stepDisplay}
      </h3>
      <div className={classes.buttonSection}>
        {!!builderInfo.Change_Date && (
          <span
            className={classes.saveMessage}
            data-cy="message-saved-banner"
          >
            Last Saved:{" "}
            {moment(builderInfo.Change_Date).format("MMMM D YYYY, h:mm a")}
          </span>
        )}
        <div className={classNames(classes.buttonWrapper)}>
          <SecondaryButton
            handlePublish={handlePublish}
            // handleSave={() => saveProgress(activeStep)}
            isPublishStep={isPublishStep}
            isSaveDisabled={isSaveDisabled}
            isBuilderPublished={isBuilderPublished}
            activeStep={activeStep}
          />
        </div>
        <div className={classNames(classes.buttonWrapper)}>
          <PrimaryButton
            handlePublish={handlePublish}
            handleSaveAndContinue={handleSaveAndContinue}
            isBuilderPublished={isBuilderPublished}
            isContinueDisabled={isContinueDisabled}
            isPublishStep={isPublishStep}
            isSaveDisabled={isSaveDisabled}
            returnToSummary={() => setMode()}
          />
        </div>
        {!!openDialog && (
          <Dialog
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            dialogTitle="Missing Required Fields"
            confirm={() => setOpenDialog(false)}
            prompt={
              <ul>
                {!!requiredValueCheck.Title && <li>{`Please enter a title in Step 1 - ${abSetupSteps[0].name}`}</li>}
                {!!requiredValueCheck.AssessItems && <li>{`Please select or create assessment items in Step 2 - ${abSetupSteps[1].name}`}</li>}
                {!!requiredValueCheck.Review_Type && <li>{`Please select an assessment type in Step 3 - ${abSetupSteps[2].name}`}</li>}
              </ul>
            }
            notification
            largeLabel
          />
        )}
      </div>
    </AppBar>
  );
}
