import React, { useMemo } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "react-spinners/PulseLoader";
import { flattenParagraphs } from "utils/certificationHelpers";
import {
  findPartInfo,
  findSubpartInfo,
  findSectionInfo,
  findParagraphInfo,
} from "./cfrHelpers";
import { H4, H6, H3 } from "components/utils/headerV2.component";
import classNames from "classnames";
import styleVariables from "styleVariables";
import CustomLink from "components/utils/link.component";

const paragraphLevelIndent = 24;

const useStyles = makeStyles((theme) => ({
  detailContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: styleVariables.fontLarge,
    color: styleVariables.grayPrimary,
    maxHeight: "40vh",
  },
  content: {
    width: "55vw",
    height: "30vh",
    overflowY: "auto",
    margin: 30,
    borderRadius: 8,
    border: `2px solid ${styleVariables.grayTint}`,
    padding: "16px 30px 30px 30px",
    boxShadow: styleVariables.shadowAppbar,
  },
  detail: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingTop: 10,
    paddingBottom: 0,
  },
  group: {
    marginTop: 16
  },
  contentHighlighted: {
    fontWeight: 600
  },
  pargraphsContainer: {
    marginLeft: -paragraphLevelIndent
  },
  paragraphBlock: {
    paddingLeft: paragraphLevelIndent,
    "&:last-of-type": {
      marginBottom: 16
    },
  },
  paragraphLine: {
    display: "flex",
    marginTop: 8,
  },
  paragraphContent: {
    marginLeft: 8,
  },
  subHeaderContainer: {
    padding: "0px 10px 35px 10px",
  },
  detailLink: {
    "&:hover": {
      color: styleVariables.secondaryMain,
    }
  },
  indentedDetailLevel: {
    marginLeft: "16px",
  }
}));

const MappedParagraphs = ({ isSingleParagraph, paragraphs }) => {
  const classes = useStyles();
  const isFinal = (
    isSingleParagraph &&
    !paragraphs[0]._associations.CFR?.length
  );
  return paragraphs?.map?.(paragraph => (
    <div className={classes.paragraphBlock} key={paragraph?.CFR_ID}>
      <div className={classes.paragraphLine}>
        <H6>
          {paragraph?.Paragraph_Label || `${paragraph?.CFR_Order}.` || "-"}
        </H6>
        <span
          className={
            classNames(
              classes.paragraphContent,
              isFinal && classes.contentHighlighted
            )
          }
        >
          {paragraph?.Paragraph_Content}
        </span>
      </div>
      {paragraph?._associations?.CFR?.map(subParagraphs => (
        <MappedParagraphs
          isSingleParagraph={isSingleParagraph}
          paragraphs={[subParagraphs]}
          key={subParagraphs.CFR_ID}
        />
      ))}
    </div>
  ));
};

const MappedSections = ({ modalData, sections, scopedParagraphTree, setModalData }) => {
  const classes = useStyles();
  return sections?.map((section) => {
    const applicableParagraphs = section?._associations?.CFR;
    const isHighlighted = modalData.CFR_ID === section.CFR_ID;
    return (
      <div key={section?.CFR_ID} className={classes.indentedDetailLevel}>
        <div className={classes.group}>
          <CustomLink
            variant="noHRef"
            onClick={() => setModalData(section)}
          >
            <H4 className={classes.detailLink}>Section § {section?.Section_Label || ""}</H4>
          </CustomLink>
          <div
            className={
              classNames(
                classes.sectionTitle,
                isHighlighted && classes.contentHighlighted
              )
            }
          >
            {section?.Section_Title}
          </div>
        </div>
        {(
          !["Part", "Subpart"].includes(modalData.CFR_Type) &&
          applicableParagraphs?.length > 0
        ) && (
          <div className={classes.indentedDetailLevel}>
            <div className={classes.group}>
              <H4 >Paragraph{scopedParagraphTree ? "" : "s"}</H4>
              <div className={classes.pargraphsContainer}>
                <MappedParagraphs
                  isSingleParagraph={!!scopedParagraphTree}
                  paragraphs={
                    [].concat(scopedParagraphTree || applicableParagraphs)
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  });
};

const MappedSubparts = props => {
  const { modalData, subparts, sections, scopedParagraphTree, setModalData } = props;
  const classes = useStyles();
  return subparts.map(subpart => (
    <div className={classes.group} key={subpart?.CFR_ID}>
      <CustomLink
        variant="noHRef"
        onClick={() => setModalData(subpart)}
      >
        <H4 className={classes.detailLink}>
          Subpart {subpart?.Subpart_Label}
        </H4>
      </CustomLink>
      <div
        className={
          classNames(
            modalData.CFR_ID === subpart.CFR_ID && classes.contentHighlighted
          )
        }
      >
        {subpart?.Subpart_Title}
      </div>
      {modalData.CFR_Type !== "Part" && (
        <MappedSections
          modalData={modalData}
          sections={sections || subpart?._associations?.CFR}
          scopedParagraphTree={scopedParagraphTree}
          setModalData={setModalData}
        />
      )}
    </div>
  ))
};

export const LetterOfComplianceDetail = (props) => {
  const classes = useStyles();
  const { modalData, cfrData, setModalData } = props;

  const flattenedParagraphs = useMemo(() => {
    return flattenParagraphs(cfrData.paragraphs);
  }, [cfrData.paragraphs]);

  const [
    displayPart,
    displaySubpart,
    displaySection,
    displayParagraphTree
  ] = useMemo(function findCfrInfoGroup() {
    const infoArgs = [modalData, cfrData, flattenedParagraphs];
    switch (modalData.CFR_Type) {
      case "Part":
        return findPartInfo(...infoArgs);
      case "Subpart":
        return findSubpartInfo(...infoArgs);
      case "Section":
        return findSectionInfo(...infoArgs);
      case "Paragraph":
        return findParagraphInfo(...infoArgs);
      default:
        return [];
    }
  }, [modalData, cfrData, flattenedParagraphs]);

  const isPartHighlighted = useMemo(() => (
    displayPart.CFR_ID === modalData.CFR_ID
  ), [displayPart, modalData]);

  if (!displayPart) {
    return <Loader />;
  }

  return (
    <Grid className={classes.detailContainer}>
      <div className={classes.content}>
        <div className={classes.subHeaderContainer}>
          <div className={classes.detail}>
            <CustomLink
              variant="noHRef"
              onClick={() => setModalData(displayPart)}
            >
              <H3 className={classes.detailLink}>Part {displayPart?.Part_Label}</H3>
            </CustomLink>
            <div
              className={
                classNames(isPartHighlighted && classes.contentHighlighted)
              }
            >
              {displayPart?.Part_Title}
            </div>
            {!!(
              displaySubpart ||
              displayPart._associations.CFR?.[0]?.CFR_Type === "Subpart"
            ) && (
              <MappedSubparts
                modalData={modalData}
                subparts={
                  displaySubpart
                    ? [displaySubpart]
                    : displayPart._associations.CFR
                }
                sections={!!displaySection && [displaySection]}
                scopedParagraphTree={displayParagraphTree}
                setModalData={setModalData}
              />
            )}
            {!!(
              (!displaySubpart && displaySection) ||
              displayPart._associations.CFR?.[0]?.CFR_Type === "Section"
            ) && (
              <MappedSections
                modalData={modalData}
                sections={
                  displaySection
                    ? [displaySection]
                    : displayPart._associations.CFR
                }
                scopedParagraphTree={displayParagraphTree}
                setModalData={setModalData}
              />
            )}
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default LetterOfComplianceDetail;
