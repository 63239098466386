import React, { useMemo, useState } from "react";
import AccordionView from "components/utils/accordionView.component";
import ProgramComponentLabelIcon from "components/utils/programComponentLabelIcon.component";
import SelectedAssessAccordionContent from "components/assessment/selectedAssessAccordionContent.component";
import { sortedItemTableDisplay } from "utils/assessmentHelpers";
import Loader from "components/utils/loader.components";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  accordionView: {
    overflow: "hidden"
  }
}));


const ENABLE_ACCORDION_TRANSITIONS_MAX = 100;

const SelectedAccordionView = ({
  assessItems,
  programComponentList,
  programID,
  checkListData,
  showChecklist,
  assessmentType,
  addChecklistItem,
  setCheckListData,
  removeCheckItem,
  editCheckItem,
  editObj,
  editCheckListHandler,
  activeInput,
  setActiveInput,
  formErrors,
  handleEnterKey,
  handleSave,
  isSavingChecklistItems,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState({});

  const sourceIDsWithItems = useMemo(() => {
    if (!assessItems || !programComponentList?.length) {
      return null;
    }
    const sourceIdsWithItems = new Set(
      assessItems.map(item => (
        item.ProgramComponent_ID ||
        "custom"
      ))
    );
    return programComponentList
      .filter((comp) => sourceIdsWithItems.has(comp.Component_ID))
      .map((compObj) => compObj.Component_ID);
  }, [assessItems, programComponentList]);

  const accordionHandler = (compID) => (_event, isExpanded) => (
    setExpanded((prev) => ({
      ...prev,
      [compID]: isExpanded
    }))
  );

  if (!sourceIDsWithItems) {
    return (
      <Loader />
    );
  } else if (sourceIDsWithItems.length === 0) {
    return (
      <AccordionView emptyMessage="No Selected Items" />
    );
  }
  return (
    <>
      {sourceIDsWithItems.map((sourceID) => {
        const sourceObj = programComponentList.find(
          (source) => source.Component_ID === sourceID
        );
        const sourceItems = sortedItemTableDisplay(assessItems, sourceObj);
        const isTransitionsDisabled = (
          sourceItems.length > ENABLE_ACCORDION_TRANSITIONS_MAX
        );
        return (
          <AccordionView
            key={sourceID}
            testProp={sourceObj.Name}
            mainLabel={
              <ProgramComponentLabelIcon
                accordion
                programComponent={sourceObj}
                count={sourceItems.length}
                test={sourceObj.Name}
              />
            }
            className={classes.accordionView}
            expanded={expanded[sourceID] ?? true}
            TransitionProps={{
              enter: !isTransitionsDisabled,
              exit: !isTransitionsDisabled,
            }}
            handleChange={accordionHandler(sourceID)}
            id={sourceID}
            elevation={0}
          >
            <SelectedAssessAccordionContent
              scopedAssessItems={sourceItems}
              sourceObj={sourceObj}
              programID={programID}
              expandedProgComp={expanded[sourceID] ?? true}
              assessmentType={assessmentType}
              checkListData={checkListData}
              showChecklist={showChecklist}
              addChecklistItem={addChecklistItem}
              setCheckListData={setCheckListData}
              removeCheckItem={removeCheckItem}
              editCheckItem={editCheckItem}
              editObj={editObj}
              editCheckListHandler={editCheckListHandler}
              activeInput={activeInput}
              setActiveInput={setActiveInput}
              formErrors={formErrors}
              handleEnterKey={handleEnterKey}
              handleSave={handleSave}
              isSavingChecklistItems={isSavingChecklistItems}
            />
          </AccordionView>
        );
      })}
    </>
  );
};

export default SelectedAccordionView;
