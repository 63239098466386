import useNumericParams from "hooks/useNumericParams";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { generatePath, Route, Switch, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import portfolioReportsReducer, { ACTION_SET_ASSESSMENTS, ACTION_SET_PORTCOS, ACTION_SET_PORTFOLIO_USERS, ACTION_SET_REPORTS, portfolioReportsInitialState } from "reducers/portfolioReports.reducer";
import PortfolioService from "services/portfolio.service";
import ManageReportPage from "./reports/manageReportPage.component";
import SelectReportPage from "./reports/selectReportPage.component";
import authService from "services/auth.service";
import { ROLE_GROUPS } from "utils/roles";
import PortfolioComponentObjectWrapper from "./shared/portfolioComponentObjectWrapper.component";
import useReducerAsync from "hooks/useReducerAsync";

const Reports = () => {
  const params = useNumericParams();
  const { programComponentId, holdingId } = params;
  const history = useHistory();
  const [ editReportMessage, setEditReportMessage ] = useState(
    "Additional notes added to this assessment will not be reflected in this report."
  )
  //TODO: Manage this state so it works even when navigating back to the report dashboard page

  const [state, dispatch] = useReducerAsync(
    portfolioReportsReducer,
    portfolioReportsInitialState
  );

  const isUserReadOnly = useMemo(() => (
    !authService.checkPermissions(ROLE_GROUPS.PORTCO_EDIT_USERS)
  ), []);

  useEffect(() => (
    async function loadGlobalState() {
      if (!holdingId || holdingId === "undefined") {
        return;
      }
      const [
        usersResponse,
        reportsResponse,
        portCoResponse,
        assessmentsResponse,
      ] = await Promise.all([
        PortfolioService.getPortfolioUsers(holdingId),
        PortfolioService.getReportsByHoldingId(holdingId),
        PortfolioService.getAllPortCosByHoldingID(holdingId),
        PortfolioService.getHolderAssessments(),
      ]);
      dispatch({ type: ACTION_SET_PORTFOLIO_USERS, payload: usersResponse.payload });
      dispatch({ type: ACTION_SET_REPORTS, payload: reportsResponse.payload });
      dispatch({ type: ACTION_SET_PORTCOS, payload: portCoResponse.payload });
      dispatch({ type: ACTION_SET_ASSESSMENTS, payload: assessmentsResponse.payload });
    }
  )(), [programComponentId, holdingId, dispatch]);

  const goToReport = useCallback(
    (portCoID, reportId, mode, fromPage) => {
      history.push(
        generatePath(
          `/program/:programId/:programComponentId/holding/:holdingId/portCo/:portCoId/report/:reportId/:mode`,
          { ...params, mode, portCoId: portCoID, reportId }
        ),
        {from: fromPage}
      );
    },
    [history, params]
  );

  return (
    <PortfolioComponentObjectWrapper
      isLoading={!state?.portfolioUsers && holdingId}
    >
      <Switch>
        <Route
          path="/program/:programId/:programComponentId/holding/:holdingId/portCo/:portCoId/report/:reportId/:mode"
        >
          <ManageReportPage
            state={state}
            dispatch={dispatch}
            userReadOnly={isUserReadOnly}
            editReportMessage={editReportMessage}
            setEditReportMessage={setEditReportMessage}
            goToReport={goToReport}
          />
        </Route>
        <Route
          path={[
            "/program/:programId/:programComponentId/holding/:holdingId/portCo/:portCoId",
            "/program/:programId/:programComponentId/holding/:holdingId"
          ]}
        >
          <SelectReportPage
            state={state}
            dispatch={dispatch}
            userReadOnly={isUserReadOnly}
            setEditReportMessage={setEditReportMessage}
            goToReport={goToReport}
          />
        </Route>
      </Switch>
    </PortfolioComponentObjectWrapper>
  );
};

export default Reports;
