import React, { useEffect, useState, useContext, useMemo } from "react";
import { Redirect, Link } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import CustomIcons from "components/utils/icons.component.js";
import variables from "styleVariables";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import ProgramNavTracker from "contexts/programNavTracker.context";
import { generatePath, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  isAdmin,
  isPortCoEdit,
  isPortCoRead,
  isHoldingRead,
  isTrainee,
  isRunbookAdmin,
  isGlobalUser
} from "utils/roles";
import { COMPONENT_ID_PORTFOLIO_DEMOGRAPHICS, COMPONENT_ID_TRAINEE, PROGRAM_ID_ORCHESTRATION, PROGRAM_ID_PORTFOLIO, COMPONENT_ID_RUNBOOK_ADMIN, PROGRAM_ID_RUNBOOK } from "utils/programConstants";
import useNumericParams from "hooks/useNumericParams";
import ProgramsContext from "contexts/programs.context";
import { removeHolderFromPath, removePortCoFromPath } from "components/portfolio/shared/utils/portfolioRouteHelpers";
import { PATH_PORTFOLIO_PROGRAM_COMPONENT, PATH_PORTFOLIO_PROGRAM_COMPONENT_PORTCO } from "../constants/routes.constants";
import PortfolioProgramContext from "contexts/portfolioProgram.context";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  drawer: {
    minWidth: drawerWidth,
    maxWidth: 300,
    width: "100%",
    flexShrink: 0,
    [theme.breakpoints.down('lg')]: {
      maxWidth: drawerWidth,
      width: "100%",
    },
  },
  drawerPaper: {
    minWidth: drawerWidth,
    maxWidth: 300,
    width: "100%",
    boxShadow: "0px 0px 7px 1px rgba(22,76,147,0.4)",
    [theme.breakpoints.down('lg')]: {
      maxWidth: drawerWidth,
      width: "100%",
    },
  },
  drawerContainer: {
    overflow: "auto",
  },
  listWrapper: {
    marginTop: 8,
  },
  customIconWrapper: {
    height: 18,
    justifyContent: "center",
    alignItems: "center",
    minWidth: 50,
  },
  bigIconWrapper: {
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    minWidth: 50,
  },
  icon: {
    fill: variables.textSecondary,
  },
  activeIcon: {
    fill: variables.primaryMain,
  },
  customListItem: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 40,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 20,
    },
  },
  listItemText: {
    color: variables.textSecondary,
    fontSize: variables.fontMedium,
    lineHeight: "normal",
  },
  activeListItemText: {
    color: variables.primaryMain,
  },
  activeListItem: {
    borderLeft: "5px solid #78C5FD",
    paddingLeft: 35,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 15,
    },
  },
  backToLink: {
    marginTop: 10,
  },
}));

const BACK_TYPE_PORTFOLIO_PORTCO = "portfolioPortCo";
const BACK_TYPE_PORTFOLIO_HOLDER = "portfolioHolder";
const BACK_TYPE_ORCHESTRATION = "orchestration";


const Menu = () => {
  const classes = useStyles();
  const params = useNumericParams();
  const { programId, programComponentId, portCoId, holdingId } = params;
  const { state } = useContext(ProgramsContext);
  const {
    permittedPortfolioHolders,
    permittedPortCos
  } = useContext(PortfolioProgramContext);
  const [activeProgramComponent, setActiveProgramComponent] = useState(programComponentId);
  const [listItems, setListItems] = useState();
  const [showError, setShowError] = useState(false);
  const [navProgId, setNavProgId] = useContext(ProgramNavTracker);
  const history = useHistory();
  const admin = useMemo(isAdmin, []);
  const isGlobalRole = useMemo(isGlobalUser, []);
  const portCoEdit = useMemo(isPortCoEdit, []);
  const portCoRead = useMemo(isPortCoRead, []);
  const holdingRead = useMemo(isHoldingRead, []);
  const traineeRole = useMemo(isTrainee, []);
  const runbookAdmin = useMemo(isRunbookAdmin, []);

  const orchProgram = useMemo(() => {
    const program = state.programs?.[PROGRAM_ID_ORCHESTRATION];
    return program?.Status === "Active" ? program : null;
  }, [state.programs]);

  const orchProgramDashboard = useMemo(() => {
    const components = state.programComponentsByProgram?.[
      PROGRAM_ID_ORCHESTRATION
    ];
    return components?.find?.(component => component.Type === "Dash");
  }, [state.programComponentsByProgram]);

  const backLinkType = useMemo(() => {
    const holderPortCos = (!permittedPortCos || !holdingId) ?
      null :
      permittedPortCos.filter(portCo => (
        portCo.PortfolioHolder_ID === holdingId
      ));

    if (portCoId && holderPortCos?.length > 1) {
      return BACK_TYPE_PORTFOLIO_PORTCO;
    } else if (
      holdingId &&
      permittedPortfolioHolders?.length > 1
    ) {
      return BACK_TYPE_PORTFOLIO_HOLDER;
    } else if (
      orchProgram &&
      orchProgramDashboard &&
      programId !== PROGRAM_ID_ORCHESTRATION &&
      isGlobalRole
    ) {
      return BACK_TYPE_ORCHESTRATION;
    }
    return null;
  }, [
    holdingId, isGlobalRole, orchProgram, orchProgramDashboard,
    portCoId, permittedPortCos, permittedPortfolioHolders, programId
  ]);

  useEffect(() => {
    if (navProgId !== programId) {
      setNavProgId(programId)
    }
  }, [programId, setNavProgId, listItems, navProgId, portCoId])

  // load menu
  useEffect(() => {
    const programComponents = state.programComponentsByProgram?.[programId];
    if (programComponents) {
      let activeId = activeProgramComponent
      if (programComponents.length <= 0) {
        setShowError(true);
      } else if (activeProgramComponent === undefined) {
        setActiveProgramComponent(programComponents[0].Component_ID)
        activeId = programComponents[0].Component_ID
      } else if(programComponentId !== undefined && activeProgramComponent !== programComponentId) {
        setActiveProgramComponent(programComponentId)
        activeId = programComponentId;
      }
      let menuProgramComponents = null;
      if ((programId === PROGRAM_ID_PORTFOLIO) && (portCoEdit || portCoRead)) {
        menuProgramComponents = programComponents.filter((item) => (
          item.Component_ID !== COMPONENT_ID_PORTFOLIO_DEMOGRAPHICS
        ));
      } else if (programId === PROGRAM_ID_ORCHESTRATION && traineeRole) {
        const trainingComponent = programComponents.find(item => (
          item.Component_ID === COMPONENT_ID_TRAINEE
        ));
        menuProgramComponents = trainingComponent ? [trainingComponent] : [];
      } else if (programId === PROGRAM_ID_RUNBOOK && !runbookAdmin && !admin) {
        menuProgramComponents = programComponents.filter((item) => (
          item.Component_ID !== COMPONENT_ID_RUNBOOK_ADMIN
        ));
      } else {
        menuProgramComponents = programComponents;
      }
      const menuListItems = menuProgramComponents.map(item => {
        let route = `/program/${programId}/${item.Component_ID}`;
        if (
          programId === PROGRAM_ID_PORTFOLIO &&
          holdingId && holdingId !== "undefined"
        ) {
          if ((portCoEdit || portCoRead) && portCoId) {
            route = generatePath(PATH_PORTFOLIO_PROGRAM_COMPONENT_PORTCO, {
              ...params,
              programComponentId: item.Component_ID
            });
          } else if (holdingRead || isGlobalRole) {
            route = generatePath(PATH_PORTFOLIO_PROGRAM_COMPONENT, {
              ...params,
              programComponentId: item.Component_ID
            });
          }
        }
        return {
          key: item.Component_ID,
          primary: item.Name,
          route,
          icon: item.Nav_Icon,
          active: item.Component_ID === parseInt(activeId),
          id: item.Component_ID,
          status: item.Status
        };
      });
      setListItems(menuListItems);
    }
  }, [
    history,
    state.programs,
    state.programComponentsByProgram,
    params,
    programId,
    programComponentId,
    activeProgramComponent,
    admin,
    isGlobalRole,
    portCoId,
    holdingId,
    portCoEdit,
    portCoRead,
    holdingRead,
    traineeRole,
    runbookAdmin
  ]);

  if(showError) {
    return (
      <Redirect to="/" />
    );
  }else{
    return (
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List className={classes.listWrapper} data-cy="menu-nav-list">
            {listItems?.map((item) => (
              item?.status === "Active" && (
                <ListItem
                  button
                  component={Link}
                  to={item.route}
                  key={item.key}
                  className={classNames(classes.customListItem, item.active && classes.activeListItem)} //highlight styling
                  selected={item.active} //highlight styling
                  onClick={() => {
                    setActiveProgramComponent(item.id)
                  }}
                  data-cy={`menu-nav-${item.primary}`}
                >
                  <ListItemIcon className={classes.customIconWrapper}>
                    <CustomIcons
                      variant={item.icon}
                      className={
                        item.active //highlight styling
                          ? classes.activeIcon
                          : classes.icon
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={item.primary}
                    className={
                      item.active //highlight styling
                        ? classes.activeListItemText
                        : classes.listItemText
                    }
                  />
                </ListItem>
              )
            ))}
            {backLinkType === BACK_TYPE_PORTFOLIO_HOLDER && (
              <ListItem
                className={classNames(classes.customListItem, classes.backToLink)}
                button
                component={Link}
                to={removeHolderFromPath(params)}
                key={0}
                data-cy="back-to-holding-navigation"
              >
                <ListItemIcon className={classes.bigIconWrapper}>
                  <CustomIcons
                    variant="Orchestration-filled"
                  />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  className={classes.listItemText}
                  primary={(
                    <span>Back to Holding&nbsp;Companies</span>
                  )}
                />
              </ListItem>
            )}
            {backLinkType === BACK_TYPE_PORTFOLIO_PORTCO && (
              <ListItem
                className={classNames(classes.customListItem, classes.backToLink)}
                button
                component={Link}
                to={removePortCoFromPath(params)}
                key={0}
                data-cy="back-to-portco-navigation"
              >
                <ListItemIcon className={classes.bigIconWrapper}>
                  <CustomIcons
                    variant="Orchestration-filled"
                  />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  className={classes.listItemText}
                  primary={(
                    <span>Back to Portfolio&nbsp;Companies</span>
                  )}
                />
              </ListItem>
            )}
            {backLinkType === BACK_TYPE_ORCHESTRATION && (
              <ListItem
                className={classNames(classes.customListItem, classes.backToLink)}
                button
                component={Link}
                to={`/program/${orchProgram.Program_ID}/${orchProgramDashboard.Component_ID}`}
                key="back-to-orchestration"
                data-cy="back-to-orchestration-navigation"
              >
                <ListItemIcon className={classes.bigIconWrapper}>
                  <CustomIcons
                    variant="Orchestration-filled"
                  />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  className={classes.listItemText}
                  primary={`Back to ${orchProgramDashboard.Name}`}
                />
              </ListItem>
            )}
          </List>
        </div>
      </Drawer>
    );
  }
};

export default Menu;
