import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Stepper as MUIStepper,
  Step,
  StepLabel,
  StepButton,
} from "@material-ui/core";
import classNames from "classnames";
import variables from "styleVariables";

const useStyles = makeStyles((theme) => ({
  stepperContainer: {
    width: "100%",
    maxWidth: 1600,
  },
  stepLabel: {
    textAlign: "left",
    "& .MuiStepLabel-active": {
      color: variables.secondaryMain,
      fontWeight: 600,
    },
  },
  stepperRoot: {
    '@media (max-height: 700px)': {
      padding: "12px 20px",
    }
  },
  current: {
    // CURRENT LABEL
    "& $stepLabel": {
      "& .MuiStepLabel-active": {
        color: variables.primaryMain,
        fontWeight: 600,
      }
    },
    // CURRENT ICON
    "& $iconRoot": {
      "&.MuiStepIcon-active": {
        color: "transparent",
        borderRadius: 50,
        background: "radial-gradient(ellipse farthest-side at 65% 38%, #175db5 14%, #164c93 58%, #11386c 93%, #0c294f 100%)",

      },
    }
  },
  unclickable: {
    cursor: "default",
    pointerEvents: "none"
  },
  iconRoot: {
    fontSize: "2rem",
    fontWeight: 600,
    "&.MuiStepIcon-root": {
      color: "transparent",
      borderRadius: 50,
      background: "radial-gradient(ellipse farthest-side at 65% 38%, #c8c8c8 14%, #a3a3a3 58%, #8a8a8a 95%, #818181 100%)",
    },
    "&.MuiStepIcon-active": {
      color: "transparent",
      borderRadius: 50,
      background: "radial-gradient(ellipse farthest-side at 65% 38%, #78c5fd 14%, #4e9eda 58%, #4091ce 93%, #348acb 100%)",
    },
  },
  allowScroll: {
    overflow: "scroll",
  }
}));

const Stepper = ({
  activeStep, //In assessBuilder, the prop sent activeStep - 1
  steps,
  highestSavedStep,
  completed,
  stepIconClick,
  orientation,
  startingIndex,
  allowScroll
}) => {
  const classes = useStyles();
  const clickable = highestSavedStep > (startingIndex || 1);

  return (
    <div className={allowScroll ? classNames(classes.stepperContainer, classes.allowScroll) : classes.stepperContainer}>
      <MUIStepper
        activeStep={activeStep}
        className={classes.stepperRoot}
        orientation={orientation || "horizontal"}
      >
        {steps.map((step) => {
          return (
            <Step
              key={step.stepNum}
              active={highestSavedStep === steps.length || step.stepNum <= highestSavedStep}
              completed={completed}
              data-cy={`stepper-item-${step.stepNum}`}
            >
              <StepButton
                className={classNames(
                  !clickable && classes.unclickable,
                  activeStep + 1 === step.stepNum && classes.current
                )}
                onClick={() => clickable && stepIconClick(activeStep + 1, step.stepNum)}
                disabled={step.stepNum > highestSavedStep}
                data-cy={`stepper-button-${step.stepNum}`}
              >
                <StepLabel
                  className={classes.stepLabel}
                  StepIconProps={{ classes: { root: classes.iconRoot } }}
                >
                  <span>
                    {step.name}
                  </span>
                </StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </MUIStepper>
      {/* {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button>
        </Paper>
      )} */}
    </div>
  );
};

export default Stepper;
