import React, { useEffect, useState, useCallback, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SortableTable from "components/utils/tables/sortableTable.component";
import UserService from "services/user.service";
import EditIcon from "components/utils/editIcon.component";
import HeaderPrimary from "components/utils/header.component";
import AddListItem from "components/utils/addListItem.component";
import CustomModal from "components/utils/modal.component";
import UserMgmtForm from "components/forms/userMgmtForm.component";
import UserProfile from "components/admin/userProfile.component";
import Alert from "@material-ui/lab/Alert";
import ResetButton from "components/utils/resetButton.component";
import Dialog from "components/utils/dialog.component";
import Loader from "components/utils/loader.components";
import AuthService from "services/auth.service";
import { currentUserInfo } from "utils/userHelpers";
import PeopleIcon from "@material-ui/icons/People";
import { setStateFetchEffect } from "utils/ajaxHelpers";
import roleService from "services/role.service";
import { mapObjectArrayByKey } from "utils/arrayOfObjectsHelpers";
import ButtonDefault from "components/utils/buttonDefault.component";
import BlockOutlinedIcon from "@material-ui/icons/BlockOutlined";
import { Redo } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  adminContentWrapper: {
    padding: "10px 20px 10px 20px",
  },
  userMgmtWrapper: {
    paddingTop: 20
  },
  tableTitle: {
    display: "flex",
    marginLeft: 10,
    alignItems: "center",
  },
  peopleIcon: {
    marginRight: 5,
  },
  addItem: {
    margin: "16px 8px 24px",
  },
  statusRow: {
    marginTop: -4,
    marginBottom: 4
  },
  statusIcon: {
    fontSize: "16px",
    marginRight: 8,
    color: "white",
  },
}));

const TABLE_HEADERS = [
  { name: "User_ID", display: "", alignment: "center" },
  { name: "First_Name", display: "First Name", sort: true },
  { name: "Last_Name", display: "Last Name", sort: true },
  { name: "Email", sort: true },
  { name: "Phone" },
  { name: "Role_Name", display: "Role", alignment: "center", sort: true },
  { name: "Reset_Button", display: "", alignment: "center" },
  { name: "Deactivate_Button", display: "Status", alignment: "center"}
];

const UserMgmtCentral = () => {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [formMode, setFormMode] = useState(""); //create & edit
  const [editFormType, setEditFormType] = useState(""); //currentUser
  const [userToEdit, setUserToEdit] = useState({});
  const [refresh, setRefresh] = useState();
  const [emailSentMessage, setEmailSentMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [resetPasswordUser, setResetPasswordUser] = useState({});
  const [currentUser, setCurrentUser] = useState();
  const [roles, setRoles] = useState(null);
  //MODAL
  const [editModal, setEditModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [userToChangeStatus, setUserToChangeStatus] = useState();

  const user = useMemo(currentUserInfo, []);

  const openAddForm = () => {
    setEditModal(true);
    setFormMode("create");
    setEmailSentMessage("");
  };

  const openEditForm = (userID, type) => {
    UserService.getUserById(userID).then((res) => {
      setUserToEdit(res.payload[0]);
      setEditFormType(type); //"" or "currentUser"
      setFormMode("edit");
      setEditModal(true);
      setEmailSentMessage("");
    });
  };

  const confirmChangeStatusModal = useCallback((currUser) => {
    setConfirmModal(true);
    setUserToChangeStatus(currUser);
  }, []);

  useEffect(() => {
    return setStateFetchEffect([
      UserService.getAll(),
      roleService.getAll(),
    ], ([usersResponse, rolesResponse]) => {
      setRoles(rolesResponse.payload);
      const rolesById = mapObjectArrayByKey(rolesResponse.payload, "Role_ID");
      const loggedInUser = usersResponse.payload.find(responseUser => (
        responseUser.User_ID === user.id
      ));
      setCurrentUser(loggedInUser);
      const users = usersResponse.payload.filter(userPayload => userPayload !== loggedInUser);
      const tableBody = users.map((userData) => {
        const userIsDeactivated = userData.Status === "Inactive";
        return {
          User_ID: {
            value: userData.User_ID,
            node: userIsDeactivated ? "" : (
              <EditIcon
                key={`edit-${userData.Email}-btn`}
                variant="matrix"
                onClick={() => openEditForm(userData.User_ID, "")}
                test="admin"
              />
            )
          },
          First_Name: userData.First_Name,
          Last_Name: userData.Last_Name,
          Email: userData.Email,
          Phone: userData.Phone,
          Role_Name: rolesById[userData.Role_ID]?.Name,
          Reset_Button: {
            node: (
              <ResetButton
                key={`reset-${userData.Email}-btn`}
                disabled={!userData.Role_ID || userIsDeactivated}
                label="password"
                onClick={() => {
                  setOpenDialog(true)
                  setResetPasswordUser(userData);
                }}
              />
            )
          },
          Deactivate_Button: {
            node: (
              <>
                <div className={classes.statusRow}>
                  {userData.Status}
                </div>
                <ButtonDefault
                  key={`deactivate-${userData.Email}-btn`}
                  variant="small"
                  background={userIsDeactivated ? "secondary" : "grey"}
                  disabled={userData.User_ID === user.id}
                  startIcon={
                    userIsDeactivated ?
                      <Redo className={classes.statusIcon} /> :
                      <BlockOutlinedIcon className={classes.statusIcon} />
                  }
                  onClick={() => confirmChangeStatusModal(userData)}
                >
                  {userIsDeactivated ? `Reactivate` : `Deactivate`}
                </ButtonDefault>
              </>
            )
          }
        };
      });
      setTableData(tableBody);
    });
  }, [refresh, classes, confirmChangeStatusModal, user]);

  const sendResetEmail = () => {
    //send reset email
    AuthService.forgot(resetPasswordUser.Email).then(() => {
      setEmailSentMessage(
        `A reset password email has been sent to ${resetPasswordUser.Email}`
      );
      setOpenDialog(false)
    });
  };

  const handleCloseModal = useCallback(() => {
    setEditModal(false);
    setUserToEdit({});
  }, []);

  const handleUserStatusChange = useCallback(async () => {
    if (userToChangeStatus.Status === "Active") {
      const deactivateUserResult = await AuthService.deactivateUser(userToChangeStatus.User_ID);
      if (deactivateUserResult.data) {
        setRefresh(Date.now())
      }
    } else {
      const reactivatedUserResult = await AuthService.reactivateUser(userToChangeStatus.User_ID);
      if (reactivatedUserResult.data) {
        setRefresh(Date.now())
      }
    }
  }, [userToChangeStatus])


  if (!currentUser) {
    return (
      <Loader />
    );
  }
  return (
    <>
      <div className={classes.adminContentWrapper}>
        <UserProfile
          user={currentUser}
          roles={roles}
          openEditForm={openEditForm}
        />
        {!!emailSentMessage && (
          <Alert
            onClose={() => setEmailSentMessage("")}
            severity="info"
            className={classes.alert}
            data-cy="alert-emailSent"
          >
            {emailSentMessage}
          </Alert>
        )}
        <div className={classes.userMgmtWrapper}>
          <div className={classes.tableTitle}>
            <HeaderPrimary
              variant="h2Primary"
              startIcon={<PeopleIcon className={classes.peopleIcon} />}
            >
              User Management
            </HeaderPrimary>
          </div>
          <div>
            <SortableTable
              headers={TABLE_HEADERS}
              data={tableData}
              defaultSortBy="First_Name"
              emptyTableValue="No Users"
            />
          </div>
          <div>
            <AddListItem
              text="Add User"
              className={classes.addItem}
              onClick={openAddForm}
            />
          </div>
        </div>
      </div>
      <CustomModal
        open={editModal}
        onClose={handleCloseModal}
      >
        <UserMgmtForm
          mode={formMode}
          userToEdit={userToEdit}
          roles={roles}
          setRefresh={setRefresh}
          setEmailSentMessage={setEmailSentMessage}
          editFormType={editFormType}
          onClose={handleCloseModal}
        />
      </CustomModal>
      <Dialog
        confirm={() => {
          setConfirmModal(!confirmModal);
          handleUserStatusChange();
        }}
        openDialog={confirmModal}
        setOpenDialog={setConfirmModal}
        prompt={`Are you sure you want to ${userToChangeStatus?.Status === "Active" ? "deactivate" : "reactivate"} ${userToChangeStatus?.First_Name} ${userToChangeStatus?.Last_Name}?`}
        continueAndCancel
      />
      <Dialog
        openDialog={openDialog}
        confirm={() => sendResetEmail()}
        setOpenDialog={setOpenDialog}
        prompt={`An email will be sent to ${resetPasswordUser.Email} with a link to reset their password.`}
        continueAndCancel
      />
    </>
  );
};

export default UserMgmtCentral;
