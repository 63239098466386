import moment from "moment";
import { makeStyles } from "@material-ui/core";
import DateInput, { TYPE_DATETIME } from "components/utils/form-elements/dateInput.component";
import EditableInputCellWrapper from "./editableInputCellWrapper.component";
import { useCallback, useMemo } from "react";
import classNames from "classnames";
import { DATETIME_DISPLAY_NUMERIC_LONG } from "utils/dateConstants";

const useStyles = makeStyles(theme => ({
  dateInput: {
    marginTop: 1,
    marginBottom: 0,
  },
  input: {
    width: "auto",
    padding: 8
  },
  labelInput: {
    margin: "-3px -8px 0",
    width: "calc(100% + 16px)",
    fontSize: "0.8rem",
    "& + p": {
      margin: "-3px  0 0 -8px",
      width: "calc(100% + 16px)",
    },
  },
  linkDisplay: {
    whiteSpace: "nowrap"
  }
}));


export default function EditableDatetimeCell(props) {
  const classes = useStyles();
  const {
    name, defaultValue, onAutosave, required, align, validations
  } = props;

  const defaultInputValue = useMemo(() => (
    defaultValue && moment(defaultValue).format("yyyy-MM-DDTHH:mm")
  ), [defaultValue]);

  const renderLinkDisplay = useCallback(value => (
    value && (
      <span className={classes.linkDisplay}>
        {moment(value).format(DATETIME_DISPLAY_NUMERIC_LONG)}
      </span>
    )
  ), [classes]);

  return (
    <EditableInputCellWrapper
      name={name}
      defaultValue={defaultInputValue}
      renderLinkDisplay={renderLinkDisplay}
      align={align}
      onAutosave={onAutosave}
      required={required}
      validations={validations}
    >
      {inputProps => (
        <DateInput
          {...inputProps}
          name={inputProps.name}
          value={inputProps.value}
          className={classNames(classes.dateInput, inputProps.className)}
          type={TYPE_DATETIME}
          InputProps={{
            classes: {
              root: classes.labelInput,
              input: classes.input
            }
          }}
          autoFocus
          hideHelperText
        />
      )}
    </EditableInputCellWrapper>
  );
}
