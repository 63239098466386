import React, { useState, useMemo, useCallback, useEffect, useRef } from "react";
import { Box, Divider, FormControl, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import moment from "moment";
import LabelInput from "components/utils/form-elements/labelInput.component";
import FormBanner from "components/utils/form-elements/formBanner.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import DateInput from "components/utils/form-elements/dateInput.component";
import styleVariables from "styleVariables";
import AddListItem from "components/utils/addListItem.component";
import Form from "components/utils/form-elements/form.component";
import { VALIDATION_AFTER_DATE, VALIDATION_REQUIRED } from "utils/formValidators";
import RemoveIcon from "components/utils/removeIcon.component";
import { Autocomplete } from "@material-ui/lab";
import { H5 } from "components/utils/headerV2.component";
import FormLabel from "components/utils/form-elements/formLabel.component";
import RunbookService from "services/runbook.service";
import { ACTION_CREATE_RUNBOOK, ACTION_REPLACE_RUNBOOK } from "reducers/runbook/runbooks.reducer";
import RadioInputGroup from "components/utils/form-elements/radioInputGroup.component";
import TimeInput from "components/utils/form-elements/timeInput.component";
import CollapsableAlert from "components/utils/collapsableAlert.component";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 8,
    justifyContent: "center",
    maxHeight: "100vh",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 8,
  },
  innerContainer: {
    justifyContent: "center",
    display: "flex",
    flexWrap: "wrap",
  },
  runbookSection: {
    margin: 16,
    padding: 8,
    flex: 1,
    minWidth: 200,
    maxHeight: "65vh",
    overflow: "auto",
  },
  phaseSection: {
    paddingLeft: 32,
    margin: 16,
    flex: 1,
  },
  phasesBox: {
    marginTop: 4,
    paddingRight: 4,
    maxHeight: "60vh",
    overflow: "auto",
  },
  phase: {
    marginBottom: 24,
    "&:last-of-type": {
      marginBottom: 8,
    },
  },
  phaseDivider: {
    backgroundColor: styleVariables.tertiary2,
  },
  addPhaseWrapper: {
    justifyContent: "center",
    display: "flex",
    padding: "30px 20px",
  },
  sectionlabel: {
    fontWeight: "bold",
  },
  optionInfo: {
    fontSize: styleVariables.fontMedium,
    color: styleVariables.textSecondary,
  },
  radioGroupClass: {
    marginLeft: 4,
    marginTop: 3,
  },
}));

const statusRadioOptions = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive"},
];

const importTooltipMessage =
  "All form field values will be overwritten when runbook is imported.";

const makeStateDates = (phases, dateFieldName) => {
  if (!phases || !phases.length) {
    return {};
  }
  return phases.reduce((accum, phase) => {
    if (phase[dateFieldName]) {
      accum[getPhaseFieldName(phase, dateFieldName)] = phase[dateFieldName];
    }
    return accum;
  }, {});
};

const RunbookForm = (props) => {
  const classes = useStyles();
  const {
    runbooks,
    allRunbookPhases,
    dispatch,
    setIsRunbookFormOpen,
    runbookToEdit,
    setRunbookToEdit
  } = props;
  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
  const [phases, setPhases] = useState(runbookToEdit?._associations?.RunbookPhases || []);
  const [runbookToImport, setRunbookToImport] = useState();
  const [editableRunbookData, setEditableRunbookData] = useState({
    Name: runbookToEdit?.Name,
    Description: runbookToEdit?.Description,
    Start_Date: runbookToEdit?.Start_Date,
    End_Date: runbookToEdit?.End_Date,
    Start_Time: runbookToEdit?.Start_Time,
    End_Time: runbookToEdit?.End_Time,
    Status: runbookToEdit?.Status || "Active"
  });
  const [isSaving, setIsSaving] = useState(false);
  const [phaseStartDateValues, setPhaseStartDateValues] = useState({})
  const [phaseEndDateValues, setPhaseEndDateValues] = useState({})

  useEffect(() => {
    if (runbookToEdit) {
      setPhaseStartDateValues(makeStateDates(runbookToEdit?._associations?.RunbookPhases, "Start_Date"))
      setPhaseEndDateValues(makeStateDates(runbookToEdit?._associations?.RunbookPhases, "End_Date"))
    }
  }, [runbookToEdit])

  const importableRunbooks = useMemo(() => {
    if (!runbookToEdit) {
      return runbooks;
    } else {
      return runbooks.filter(
        (rb) => rb.Runbook_ID !== runbookToEdit.Runbook_ID
      );
    }
  }, [runbooks, runbookToEdit]);

  const phasesByImportableRunbookId = useMemo(() => {
    return allRunbookPhases.reduce((accumulator, phase) => {
      if (accumulator[phase.Runbook_ID]) {
        accumulator[phase.Runbook_ID].push({ Name: phase.Name });
      } else {
        accumulator[phase.Runbook_ID] = [{ Name: phase.Name }];
      }
      return accumulator;
    }, {});
  }, [allRunbookPhases]);

  const handleInputChange = useCallback(() => {
    setIsUnsavedChanges(true);
  }, []);

  const formValidations = useMemo(
    () => ({
      Name: [{ type: VALIDATION_REQUIRED }],
      End_Date: [{
        type: VALIDATION_AFTER_DATE,
        argument: moment(editableRunbookData.Start_Date).format("YYYY-MM-DD"),
        message: `Must be after start date`,
      }],
      ...(phases || []).reduce(
        (accumulator, phase) => ({
          ...accumulator,
          [getPhaseFieldName(phase, "Name")]: [
            {
              type: VALIDATION_REQUIRED,
            },
          ],
          [getPhaseFieldName(phase, "End_Date")]:  [{
            type: VALIDATION_AFTER_DATE,
            argument: moment(phaseStartDateValues[getPhaseFieldName(phase, "Start_Date")]).format("YYYY-MM-DD"),
            message: `Must be after start date`,
          }],
        }),
        {}
      ),
    }),
    [phases, editableRunbookData.Start_Date, phaseStartDateValues]
  );

  const handleRemovePhase = useCallback(
    (removedId) => {
      const newPhases = [...(phases || [])];
      const index = newPhases.findIndex((item) => {
        const itemId = item._meta?.clientId || item.Phase_ID;
        return itemId === removedId;
      });
      newPhases.splice(index, 1);
      setPhases(newPhases);
      setPhaseStartDateValues(makeStateDates(newPhases, "Start_Date"))
      setPhaseEndDateValues(makeStateDates(newPhases, "End_Date"))
      handleInputChange();
    },
    [handleInputChange, phases]
  );

  const handleSubmitPhase = useCallback(
    (formData) => {
      const updatedPhase = { ...formData };
      updatedPhase._meta = {
        clientId:
          updatedPhase["_meta.clientId"] ||
          `${new Date().getTime()}-pending-phase`,
      };
      delete updatedPhase["_meta.clientId"];
      const newPhases = [...(phases || [])];
      if (formData._meta?.clientId) {
        const index = newPhases.findIndex(
          (item) =>
            item?._meta?.clientId === updatedPhase._meta.clientId ||
            item.Compliance_ID === parseInt(updatedPhase._meta.clientId, 10)
        );
        newPhases.splice(index, 1, updatedPhase);
      } else {
        newPhases.push(updatedPhase);
      }
      setPhases(newPhases);
      handleInputChange();
    },
    [handleInputChange, phases]
  );

  const handleSubmit = useCallback(
    async (data) => {
      setIsSaving(true);
      const requestData = {};
      const phaseFieldEntries = Object.entries(data).filter(([key]) => {
        return key.includes(FIELD_NAME_DELIMITER);
      });

      const phasesByClientId = phaseFieldEntries.reduce(
        (accumulator, [key, value]) => {
          if (value !== "") {
            const [clientId, fieldName] = key.split(FIELD_NAME_DELIMITER);
            if (!accumulator[clientId]) {
              accumulator[clientId] = {};
            }
            accumulator[clientId][fieldName] = value;
          }
          return accumulator;
        },
        {}
      );
      const phaseItems = Object.values(phasesByClientId);
      const runbookData = {
        Runbook_ID: runbookToEdit?.Runbook_ID,
        Name: data.Name,
        Description: data.Description,
        Start_Date: data.Start_Date === "" ? null : data.Start_Date,
        End_Date: data.End_Date === "" ? null : data.End_Date,
        Start_Time: data.Start_Time === "" ? null : data.Start_Time,
        End_Time: data.End_Time === "" ? null : data.End_Time,
        Status: data.Status
      }

      requestData.runbookData = runbookData;

      if (phaseItems) {
        requestData.phaseList = phaseItems;
      }
      const response = await RunbookService.upsertRunbook(requestData);
      setIsUnsavedChanges(false);
      if (!runbookToEdit?.Runbook_ID) {
        setIsRunbookFormOpen(false);
        dispatch({ type: ACTION_CREATE_RUNBOOK, payload: response.payload });
        setRunbookToEdit(null);
      } else {
        setIsRunbookFormOpen(false);
        dispatch({ type: ACTION_REPLACE_RUNBOOK, payload: response.payload })
        setRunbookToEdit(null);
      }
      setIsSaving(false);
    },
    [dispatch, setIsRunbookFormOpen, setRunbookToEdit, runbookToEdit?.Runbook_ID]
  );

  const handleImportChange = useCallback(
    (_event, newValue) => {
      _event.preventDefault()
      setRunbookToImport(newValue);
      const newPhases = phasesByImportableRunbookId[newValue.Runbook_ID] || [];
      const newPhasesWithClientId = newPhases.map((newPhase, index) => {
        return {
          ...newPhase,
          _meta: {
            clientId:
              newPhase["_meta.clientId"] ||
              `${new Date().getTime()}-${index}-pending-phase`,
          },
        };
      });
      setPhases(newPhasesWithClientId);
      setPhaseStartDateValues(makeStateDates(newPhasesWithClientId, "Start_Date"))
      setPhaseEndDateValues(makeStateDates(newPhasesWithClientId, "End_Date"))
      setEditableRunbookData({
        Name: newValue.Name,
        Description: newValue.Description,
        Start_Date: "",
        End_Date: "",
        Status: "Active"
      });
      handleInputChange();
    },
    [handleInputChange, phasesByImportableRunbookId]
  );
  const renderAutocompleteInput = useCallback((params) => {
    return <TextField ref={params.InputProps.ref} margin="dense" {...params} />;
  }, []);

  const renderAutocompleteOption = useCallback(
    (runbookOption) => {
      const phaseCount =
        phasesByImportableRunbookId[runbookOption.Runbook_ID]?.length || 0;
      const changeDate = runbookOption?.Change_Date
        ? moment(runbookOption.Change_Date).format("MM/DD/YYYY")
        : "No Date Available";

      return (
        <div data-cy="rb-option">
          <H5 color="tertiary" data-cy={`option-${runbookOption.Name}`}>
            {`${runbookOption.Name} - ${changeDate}`}
          </H5>
          <div className={classes.optionInfo}>
            {phaseCount} {phaseCount === 1 ? "Phase" : "Phases"}
          </div>
        </div>
      );
    },
    [classes, phasesByImportableRunbookId]
  );

  const handleChange = (event) => {
    const { name, value } = event.target
    if ((name === "Start_Date" || name === "End_Date") && value === "") {
      setEditableRunbookData((prev) => {
        return {
          ...prev,
          [name]: value,
          Start_Time: ""
        };
      });
    } else {
      setEditableRunbookData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };


  const earlierPhaseStartDateExists = useMemo(() => {
    if (!editableRunbookData.Start_Date) {
      return false;
    }
    return Object.values(phaseStartDateValues).some((phaseStartDate) => {
      return (
        phaseStartDate && phaseStartDate < editableRunbookData.Start_Date
      );
    });

  }, [editableRunbookData.Start_Date, phaseStartDateValues]);


  const laterPhaseEndDateExists = useMemo(() => {
    if (!editableRunbookData.End_Date) {
      return false;
    }
    return Object.values(phaseEndDateValues).some((phaseEndDate) => {
      return phaseEndDate && phaseEndDate > editableRunbookData.End_Date;
    });
  }, [editableRunbookData.End_Date, phaseEndDateValues]);


  return (
    <div className={classes.container}>
      <FormBanner>
        {runbookToEdit ? "Edit Runbook" : "Add New Runbook"}
      </FormBanner>
      <Form
        name="runbook-form"
        validations={formValidations}
        className={classes.form}
        onChange={handleInputChange}
        onSubmit={handleSubmit}
      >
        <div className={classes.innerContainer}>
          <div className={classes.runbookSection}>
            {!runbookToEdit && !!importableRunbooks && (
              <Box marginBottom={2}>
                <FormLabel
                  htmlFor="import-runbook"
                  label="Import a Runbook"
                  labelTooltip={importTooltipMessage}
                  tooltipPlacement="right"
                  variant="default"
                />
                <Autocomplete
                  id="import-runbook"
                  options={importableRunbooks}
                  value={runbookToImport || null}
                  getOptionLabel={(option) => option?.Name}
                  onChange={handleImportChange}
                  renderInput={renderAutocompleteInput}
                  renderOption={renderAutocompleteOption}
                  name="runbook-autocomplete"
                  data-cy="runbook-picker"
                />
              </Box>
            )}

            <GridContainer spacing={2} hasVerticalSpacing data-cy="runbook-form-inputs">
              <GridItem xs={12}>
                <LabelInput
                  name="Name"
                  label="Runbook Name*"
                  variant="default"
                  value={editableRunbookData.Name || ""}
                  onChange={handleChange}
                  margin="dense"
                  test="runbookName"
                />
              </GridItem>

              <GridItem xs={12}>
                <LabelInput
                  name="Description"
                  label="Description"
                  variant="default"
                  value={editableRunbookData.Description || ""}
                  onChange={handleChange}
                  multiline
                  test="runbookDescription"
                />
              </GridItem>

              {!!earlierPhaseStartDateExists && (
                <GridItem xs={12}>
                  <CollapsableAlert
                    showAlert={earlierPhaseStartDateExists}
                    severity="warning"
                    message="This Start Date begins after an existing Phase Start Date."
                    dense
                  />
                </GridItem>
              )}

              {!!laterPhaseEndDateExists && (
                <GridItem xs={12}>
                  <CollapsableAlert
                    showAlert={laterPhaseEndDateExists}
                    severity="warning"
                    message="This End Date falls before an existing Phase End Date."
                    dense
                  />
                </GridItem>
              )}

              <GridItem xs={12} md={6}>
                <DateInput
                  name="Start_Date"
                  label="Start Date"
                  variant="default"
                  value={editableRunbookData.Start_Date || ""}
                  onChange={handleChange}
                  margin="dense"
                  test="RunbookStartDate"
                  labelTooltip="Start Date will be overidden if an earlier Start Date is entered for an associated phase."
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TimeInput
                  name="Start_Time"
                  label="Start Time"
                  value={editableRunbookData.Start_Time || ""}
                  onChange={handleChange}
                  margin="dense"
                  test="runbook-start-time"
                  disabled={!editableRunbookData.Start_Date}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <DateInput
                  name="End_Date"
                  label="End Date"
                  variant="default"
                  value={editableRunbookData.End_Date || ""}
                  onChange={handleChange}
                  margin="dense"
                  test="RunbookEndDate"
                  labelTooltip="End Date will be overidden if a later End Date is entered for an associated phase."
                />
              </GridItem>

              <GridItem xs={12} md={6}>
                <TimeInput
                  name="End_Time"
                  label="End Time"
                  value={editableRunbookData.End_Time || ""}
                  onChange={handleChange}
                  margin="dense"
                  test="runbook-end-time"
                  disabled={!editableRunbookData.End_Date}
                />
              </GridItem>
              <GridItem xs={12}>
                <Box marginTop={2}>
                  <RadioInputGroup
                    row
                    name="Status"
                    value={editableRunbookData.Status || ""}
                    type="datetime-local"
                    onChange={handleChange}
                    options={statusRadioOptions}
                    size="small"
                    color="primary"
                    groupLabel="Status"
                    radioGroupClass={classes.radioGroupClass}
                  />
                </Box>
              </GridItem>
            </GridContainer>
          </div>

          <div className={classes.phaseSection} data-cy="phase-section">
            <div className={classes.sectionlabel}>
              {`Define Event Phases (Optional) - ${phases?.length || 0} phases`}
              <Divider />
            </div>
            {phases?.length > 0 && (
              <div className={classes.phasesBox} data-cy="phase-list">
                {phases?.map?.((phase, index) => (
                  <div
                    key={phase.Phase_ID || phase?._meta?.clientId || index}
                    className={classes.phase}
                    data-cy="phase-wrapper"
                  >
                    {index > 0 && <Divider className={classes.phaseDivider} />}
                    <PhaseFieldset
                      index={index}
                      phase={phase}
                      onChange={() => setIsUnsavedChanges(true)}
                      onRemove={handleRemovePhase}
                      runbookToImport={!!runbookToImport}
                      phaseStartDateValues={phaseStartDateValues}
                      phaseEndDateValues={phaseEndDateValues}
                      setEditableRunbookData={setEditableRunbookData}
                      setPhaseStartDateValues={setPhaseStartDateValues}
                      setPhaseEndDateValues={setPhaseEndDateValues}
                      editableRunbookData={editableRunbookData}
                    />
                  </div>
                ))}
              </div>
            )}
            <Box className={classes.addPhaseWrapper}>
              <AddListItem
                text={phases?.length > 0 ? "Add Another Phase" : "Add Phase"}
                onClick={() => handleSubmitPhase({})}
                test="phase"
              />
            </Box>
          </div>
        </div>
        <DualFormButtons
          cancelOnClick={() => {
            setIsRunbookFormOpen(false);
            setRunbookToEdit && setRunbookToEdit(null);
          }}
          disabled={!isUnsavedChanges || isSaving}
        />
      </Form>
    </div>
  );
};

export default RunbookForm;

const FIELD_NAME_DELIMITER = "|";

const getPhaseFieldName = (phase, columnName) => {
  const id = phase.Phase_ID || phase._meta?.clientId;
  return `${id}${FIELD_NAME_DELIMITER}${columnName}`;
};

const PhaseFieldset = (props) => {
  const classes = useStyles();
  const { index, phase, onChange, onRemove, runbookToImport} = props;
  const { setPhaseStartDateValues, setPhaseEndDateValues, phaseStartDateValues, phaseEndDateValues , setEditableRunbookData, editableRunbookData } = props;
  const clientId = useMemo(
    () => phase?.Phase_ID || phase?._meta?.clientId,
    [phase]
  );
  const startTimeInputRef = useRef();
  const endTimeInputRef = useRef();

  const handleDeletePhase = useCallback(() => {
    onRemove(clientId);
  }, [clientId, onRemove]);

  const defaultDates = useMemo(() => {
    if (runbookToImport) {
      return {start: "", end: ""}
    }
    const startDate = phase?.Start_Date ? phase?.Start_Date : "";
    const endDate = phase?.End_Date ? phase?.End_Date : "";
    return {start: startDate, end: endDate}
  }, [phase?.Start_Date, phase?.End_Date, runbookToImport]);

  const defaultTimes = useMemo(() => {
    if (runbookToImport) {
      return {start: "", end: ""}
    }
    const startTime = phase?.Start_Time ? phase?.Start_Time : "";
    const endTime = phase?.End_Time ? phase?.End_Time : "";
    return {start: startTime, end: endTime}
  }, [phase?.Start_Time, phase?.End_Time, runbookToImport]);

  const phaseStartDateOnChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      if (!value) {
        startTimeInputRef.current.value = ""
      }
      setPhaseStartDateValues((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (rbStartDateShouldUpdate(editableRunbookData.Start_Date, value)) {
        setEditableRunbookData((prev) => {
          return {
            ...prev,
            Start_Date: value,
            Start_Time: "",
          };
        });
      }
      // If RB End Date exists, but a later Phase Start_Date is inputted
      if ( !!editableRunbookData.End_Date && value > editableRunbookData.End_Date) {
        setEditableRunbookData((prev) => {
          return {
            ...prev,
            End_Date: value,
            End_Time: "",
          };
        });

      }
    },
    [editableRunbookData.Start_Date, setEditableRunbookData, setPhaseStartDateValues, editableRunbookData.End_Date]
  );

  const phaseEndDateOnChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      if (!value) {
        endTimeInputRef.current.value = ""
      }
      setPhaseEndDateValues((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (rbEndDateShouldUpdate(editableRunbookData.End_Date, value, )) {
        setEditableRunbookData((prev) => {
          return {
            ...prev,
            End_Date: event.target.value,
            End_Time: "",
          };
        });
      }
    },
    [editableRunbookData.End_Date, setEditableRunbookData, setPhaseEndDateValues]
  );

  return (
    <FormControl
      component="fieldset"
      name={`phase-${clientId}`}
      className={classes.phaseFieldset}
      onChange={onChange}
    >
      <Box
        fontSize="0.8rem"
        display="flex"
        justifyContent="space-between"
        flex={1}
        marginTop={1}
      >
        <GridContainer spacing={2} hasVerticalSpacing>
          <GridItem xs={12}>
            <LabelInput
              name={getPhaseFieldName(phase, "Name")}
              label={`Phase ${index + 1} Name*`}
              defaultValue={phase?.Name}
              margin="dense"
              variant="default"
              test="runbook-phase-name"
            />
          </GridItem>

          <GridItem xs={12} md={6}>
            <DateInput
              name={getPhaseFieldName(phase, "Start_Date")}
              label="Start Date"
              defaultValue={defaultDates.start}
              margin="dense"
              variant="default"
              test="runbook-phase-startDate"
              onChange={phaseStartDateOnChange}
            />
          </GridItem>

          <GridItem xs={12} md={6}>
            <TimeInput
              name={getPhaseFieldName(phase, "Start_Time")}
              label="Start Time"
              defaultValue={defaultTimes.start}
              margin="dense"
              test="runbook-phase-start-time"
              disabled={!phaseStartDateValues[getPhaseFieldName(phase, "Start_Date")]}
              inputRef={startTimeInputRef}
            />
          </GridItem>

          <GridItem xs={12} md={6}>
            <DateInput
              name={getPhaseFieldName(phase, "End_Date")}
              label="End Date"
              defaultValue={defaultDates.end}
              margin="dense"
              variant="default"
              test="runbook-phase-endDate"
              onChange={phaseEndDateOnChange}
            />
          </GridItem>

          <GridItem xs={12} md={6}>
            <TimeInput
              name={getPhaseFieldName(phase, "End_Time")}
              label="End Time"
              defaultValue={defaultTimes.end}
              margin="dense"
              test="runbook-phase-end-time"
              disabled={!phaseEndDateValues[getPhaseFieldName(phase, "End_Date")]}
              inputRef={endTimeInputRef}
            />
          </GridItem>
        </GridContainer>
        <RemoveIcon
          className={classes.phaseDelete}
          onClick={handleDeletePhase}
          test={phase?.Name}
        />
      </Box>
      <input
        type="hidden"
        name={getPhaseFieldName(phase, "Runbook_ID")}
        value={phase?.Runbook_ID}
      />
      <input
        type="hidden"
        name={getPhaseFieldName(phase, "Phase_ID")}
        value={phase?.Phase_ID}
      />
    </FormControl>
  );
};

const rbStartDateShouldUpdate = (rbStartDate, phaseStartDate) => {
  const phaseStartDateValue = phaseStartDate === "" ? null : phaseStartDate;
  const rbStartDateValue = rbStartDate === "" ? null : rbStartDate;
  if (!phaseStartDateValue || !phaseStartDateValue.startsWith("2")) {
    return false;
  }
  if (!rbStartDateValue) {
    return true;
  }
  if (phaseStartDateValue < rbStartDateValue) {
    return true;
  }
  return false;
};

const rbEndDateShouldUpdate = (rbEndDate, phaseEndDate) => {
  const phaseEndDateValue = phaseEndDate === "" ? null : phaseEndDate;
  const rbEndDateValue = rbEndDate === "" ? null : rbEndDate;
  if (!phaseEndDateValue || !phaseEndDateValue.startsWith("2")) {
    return false;
  }
  if (!rbEndDateValue) {
    return true;
  }
  if (phaseEndDateValue > rbEndDateValue) {
    return true;
  }
  return false;
};
