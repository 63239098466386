import { Grid, makeStyles } from "@material-ui/core";
import { PATH_COMPONENT_OBJECT } from "components/layouts/constants/routes.constants";
import CustomCard from "components/utils/card.component";
import Icons from "components/utils/icons.component";
import Loader from "components/utils/loader.components";
import ProgramsContext from "contexts/programs.context";
import useNumericParams from "hooks/useNumericParams";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { generatePath, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { currentUserInfo } from "utils/userHelpers";
import variables from "styleVariables";
import { getEnabledComponentObjects } from "./utils/trainingPageUtils";

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    marginTop: 0,
  },
  cardGridItem: {
    marginLeft: 40,
  },
  cardsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: "stretch",
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(30),
      height: "",
    },
  },
  groupHeader: {
    marginTop: 2,
    marginLeft: 10,
    color: variables.secondaryDark,
    fontSize: variables.fontH3,
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    left: 0,
  },
  iconWrapper: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
    height: 30,
    width: 30,
    padding: 7,
    borderRadius: 50,
    background: `radial-gradient(
      ellipse farthest-side at 65% 38%,
      rgb(12, 133, 215) 14%,
      rgb(0, 122, 204) 58%,
      rgb(1, 92, 153) 93%,
      rgb(2, 87, 145) 100%
    )`,
  },
  icon: {
    fill: "white",
  },
  page: {
    padding: "35px 0 50px 30px",
  },
}));


export default function TrainingProgramComponent() {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useContext(ProgramsContext);
  const params = useNumericParams();
  const { programComponentId } = params;

  const currentUser = useMemo(currentUserInfo, [])

  const componentObjects = useMemo(() => (
    state.componentObjectsByComponent?.[programComponentId]
  ), [state.componentObjectsByComponent, programComponentId]);

  const makeComponentObjectPath = useCallback(componentObjectId => (
    generatePath(PATH_COMPONENT_OBJECT, { ...params, componentObjectId })
  ), [params]);

  useEffect(function redirectOnlyOneComponentObject() {
    if (componentObjects) {
      const enabledComponentObjects = getEnabledComponentObjects(
        componentObjects,
        currentUser
      );
      if (enabledComponentObjects.length === 1) {
        history.push(
          makeComponentObjectPath(
            enabledComponentObjects[0].ComponentObject_ID
          )
        );
      }
    }
  }, [componentObjects, currentUser, history, makeComponentObjectPath]);

  if (!componentObjects) {
    return (
      <Loader />
    );
  }
  return (
    <div className={classes.page}>
      <div className={classes.groupHeader}>
        <div className={classes.iconWrapper}>
          <Icons variant="component-Custom" className={classes.icon} />
        </div>
        Data Management &amp; Tools
      </div>
      <Grid container spacing={8} className={classes.cardGrid}>
        <Grid item sm={12} className={classes.cardGridItem}>
          {componentObjects.length > 0 && (
            <div className={classes.cardsWrapper}>
              {componentObjects.map(card => (
                <CustomCard
                  variant={card.Status === "Active" ? "link" : "disabled"}
                  key={card.Name}
                  icon={card.Nav_Icon}
                  title={card.Name}
                  description={card.Description}
                  url={makeComponentObjectPath(card.ComponentObject_ID)}
                  type={card.Type}
                />
              ))}
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
