import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { CheckCircleOutline, Refresh } from "@material-ui/icons";
import classNames from "classnames";
import ButtonDefault from "components/utils/buttonDefault.component";
import moment from "moment";
import {
  assessBuilderStatus,
  dataCollectionStatus} from "utils/assessmentConstants";
import Dialog from "components/utils/dialog.component";
import BottomControlsBar from "components/assessment/shared/bottomControlsBar.component";
import { useParams, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  dateUpdated: {
    color: theme.palette.text.secondary,
    fontSize: 13,
    marginRight: 12,
  },
  incompleteIcon: {
    marginRight: 8,
    color: theme.palette.secondary.main,
  },
  saveIcon: {
    color: "white",
    marginRight: 8,
  },
  buttonWrapper: {
    marginLeft: 10,
  },
}));

const completedStatus = dataCollectionStatus.STATUS_COMPLETED;
const inProgressStatus = dataCollectionStatus.STATUS_IN_PROGRESS

// Wrapper for Assessment Data Collection form fields, handling common save functionality.
export default function DataCollectionForm(props) {
  const {
    builder,
    children,
    onSubmitTry,
    dataCollection,
    setMessage,
    setFormDataState,
    formDataState,
    validateFormErrors,
    setFormErrors,
    hasUnsavedReferenceChanges,
  } = props;
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const history = useHistory();

  const {programId, programComponentId, componentObjectId} = useParams();

  const isCompleted = dataCollection?.Status === completedStatus;

  const handleGoBack = () => {
    history.push(`/program/${programId}/${programComponentId}/${componentObjectId}/assessment/${builder.Builder_ID}/review`)
    setMessage("");
  };

  const handleSetInProgress = () => {
    onSubmitTry("Status", inProgressStatus)
    setFormDataState(prev => ({...prev, Status: inProgressStatus}))
  }

  const handleDialog = () => {
    setOpenDialog("There are unsaved References changes. Please save references before proceeding.")
  }

  const handleComplete = () => {
    const formValidationErrors = validateFormErrors(formDataState)
    if (Object.values(formValidationErrors).length) {
      setFormErrors(formValidationErrors);
      return false;
    }
    setFormErrors({});
    onSubmitTry("Status", completedStatus)
    setFormDataState(prev => ({...prev, Status: completedStatus}))
  }

  return (
    <div>
      {children}
      <BottomControlsBar backClick={handleGoBack}>
        {!!dataCollection?.Change_Date && (
          <span className={classNames(classes.dateUpdated)}>
            Last Updated: {moment(dataCollection?.Change_Date).format("MM/DD/YY")}
          </span>
        )}
        {(
          builder.Status !== assessBuilderStatus.BUILDER_STATUS_ASSESSED &&
          builder.Status !== assessBuilderStatus.BUILDER_STATUS_RESPONDED
        ) && (
          <>
            <div className={classes.buttonWrapper}>
              {dataCollection?.Status === "Completed" && (
                <ButtonDefault
                  variant="medium"
                  color="secondary"
                  startIcon={<Refresh className={classNames(classes.incompleteIcon)} />}
                  onClick={handleSetInProgress}
                  data-cy="undo-complete-data-collection"
                  disableReadOnlyUsers
                >
                  Mark Incomplete&nbsp;
                </ButtonDefault>
              )}
            </div>
            <div className={classes.buttonWrapper}>
              <ButtonDefault
                variant="medium"
                background="primary"
                startIcon={<CheckCircleOutline className={classNames(classes.saveIcon)} />}
                onClick={hasUnsavedReferenceChanges ? handleDialog : handleComplete}
                disabled={isCompleted}
                data-cy="btn-complete-data-collection"
                disableReadOnlyUsers
              >
                {isCompleted ? "Completed" : "Complete Review"}&nbsp;
              </ButtonDefault>
            </div>
          </>
        )}
        {!!openDialog && (
          <Dialog
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            prompt={openDialog}
            confirm={() => setOpenDialog(false)}
            className={classes.reportDialog}
            largeLabel
            notification
          />
        )}
      </BottomControlsBar>
    </div>
  );
}
