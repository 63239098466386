const findMaxByField = (array, key) => {
  return Math.max.apply(
    Math,
    array.map((ele) => ele[key])
  );
};

const findMaxValueByField = (arr, fieldName) => {
  if (!arr.length) {
    return;
  }
  let max = arr[0][fieldName];
  for (const val of arr) {
    const compareValue = val[fieldName];
    if (compareValue > max) {
      max = compareValue;
    }
  }
  return max;
};

export {
  findMaxByField,
  findMaxValueByField,
};
