import React from "react";
import { FormControl, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import FormLabel from "components/utils/form-elements/formLabel.component"
import CustomFormHelperText from "components/utils/form-elements/styled/formHelperText/customFormHelperText.component"
import CustomOutlinedInput from "components/utils/form-elements/styled/outlinedInput/customOutlinedInput.component"
import StaticLabelOutlinedInput from "components/utils/form-elements/styled/textField/staticLabelOutlinedInput.component"
import { isReadOnly } from "utils/roles";
import classNames from "classnames";
import FormElementBase from "./shared/formElementBase.component";

const useStyles = makeStyles((theme) => ({
  formControlRoot: {
    width: "100%",
  },
  helperFixedHeight: {
    color: variables.secondaryMain,
    fontSize: variables.fontXxs,
    fontWeight: "bold",
    textAlign: "right",
    backgroundColor: "white",
    borderRadius: "0px 0px 3px 3px",
    padding: "2px 5px 0px 0px",
    margin: "-2px 0px 0px 0px",
    height: 17,
    width: "100%",
  },
  root: {
    cssOutlinedInput: {
      borderColor: variables.secondaryMain,
      borderRadius: 44,
    },
  },
  readOnly: {
    '& .MuiInputBase-input.Mui-disabled': {
      color: "#000"
    }
  },

  borderHover: { //applied to non-disabled Select
    '&:hover fieldset': {
      borderColor: `${variables.primaryMain} !important;`
    }
  },
  selectDisabled: {
    color: variables.textPrimary,
  },
  transparentLabel: {
    opacity: 0,
  },
}));


const LabelInput = (props) => (
  <FormElementBase {...props}>
    <LabelInputBase {...props} />
  </FormElementBase>
);

const LabelInputBase = (props) => {
  const classes = useStyles();
  const readOnly = props.readOnly || isReadOnly();

  let value = props.value;
  if (!value && !props.select) {
    if ("defaultValue" in props) {
      value = undefined;
    } else if ("value" in props) {
      value = "";
    }
  }

  // const inputProps = useMemo(() => {
  //   const base = { ...props.inputProps };
  //   if (props.endAdornment) {
  //     base.endAdornment = props.endAdornment
  //   }
  // })

  //Checks to see if Select should have proper border styling
  const activeSelect = () => props.select && (!props.disabled && !readOnly)
  // DATE PICKER

  // No label - Only placeholder text
  if (props.variant === "noLabel") {
    return (
      <FormControl variant="outlined" className={classes.formControlRoot} >
        {props.margin !== "dense" && (
          <FormLabel
            htmlFor={props.id}
            label={props.label}
            variant="default"
            className={classes.transparentLabel}
          />
        )}
        <StaticLabelOutlinedInput
          className={
            classNames(classes.root, props.className, readOnly && classes.readOnly)
          }
          required={props.required}
          fullWidth
          id={props.id}
          name={props.name}
          value={value}
          select={props.select} // Turns into a selector with MenuItems being passed in as props.children
          label={props.inputLabel}
          error={props.error}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
          disabled={props.disabled || readOnly}
          autoFocus={props.autoFocus}
          onBeforeInput={props.onBeforeInput}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
          InputProps={props.inputProps}
          inputRef={props.inputRef}
          margin={props.margin}
          InputLabelProps={{ shrink: true, }}
          variant="outlined"
          multiline={props.multiline}
          minRows={props.rows}
          data-cy={`input-${props.test}`}
        >
          {props.children}
        </StaticLabelOutlinedInput>
        {!props.hideHelperText && (
          <CustomFormHelperText
            id={`helper-${props.id}`}
            className={props.fixedHeight ? classes.helperFixedHeight : undefined}
            error={props.error}
            data-cy={`input-helper-${props.test}`}
          >
            {props.errorMessage ? props.errorMessage : props.helperText}
          </CustomFormHelperText>
        )}
      </FormControl>
    );
  }
  if (props.variant === "defaultTextfield") { //used By Assign Select in Builder Task and SubTask forms
    return (
      <FormControl variant="outlined" className={classes.formControlRoot} >
        <FormLabel htmlFor={props.id} label={props.required ? `${props.label}*` : props.label} variant="default" error={props.error} />
        <StaticLabelOutlinedInput
          className={
            classNames(
              classes.root,
              activeSelect() && classes.borderHover,
              readOnly && classes.readOnly
            )
          }
          fullWidth
          required={props.required}
          value={value}
          id={props.id}
          name={props.name}
          select={props.select} // Turns into a selector with MenuItems being passed in as props.children
          SelectProps={{ classes: { disabled: classes.selectDisabled } }}
          error={props.error}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
          onBeforeInput={props.onBeforeInput}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
          InputProps={props.inputProps}
          inputRef={props.inputRef}
          variant="outlined"
          multiline={props.multiline}
          minRows={props.rows}
          disabled={props.disabled || readOnly}
          autoFocus={props.autoFocus}
          data-cy={`input-${props.test}`}
        >
          {props.children}
        </StaticLabelOutlinedInput>
        {!props.hideHelperText && (
          <CustomFormHelperText
            id={`helper-${props.id}`}
            className={props.fixedHeight ? classes.helperFixedHeight : undefined}
            error={props.error}
            data-cy={`input-helper-${props.test}`}
          >
            {props.errorMessage ? props.errorMessage : props.helperText}
          </CustomFormHelperText>
        )}
      </FormControl>
    );
  }

  if (props.variant === "default") {
    return (
      <FormControl variant="outlined" className={classes.formControlRoot} >
        <FormLabel
          htmlFor={props.id}
          label={<>{props.label}{!!props.required && "*"}</>}
          variant="default"
          error={!!props.error && !readOnly}
          labelTooltip={props.labelTooltip}
        />
        <CustomOutlinedInput
          className={classNames(classes.root, props.className, readOnly && classes.readOnly)}
          fullWidth
          required={props.required}
          value={value}
          id={props.id}
          name={props.name}
          error={props.error}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
          onBeforeInput={props.onBeforeInput}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          onKeyDown={props.onKeyDown}
          onKeyUp={props.onKeyUp}
          inputProps={props.inputProps}
          inputRef={props.inputRef}
          multiline={props.multiline}
          onChange={props.onChange}
          margin={props.margin}
          disabled={props.disabled || readOnly}
          autoFocus={props.autoFocus}
          type={props.type}
          endAdornment={props.endAdornment}
          data-cy={`input-${props.test}`}
          rows={props.rows}
          size={props.size}
        />
        {!props.hideHelperText && (
          <CustomFormHelperText
            id={`helper-${props.id}`}
            className={props.fixedHeight ? classes.helperFixedHeight : undefined}
            error={props.error}
            data-cy={`input-helper-${props.test}`}
          >
            {props.errorMessage ? props.errorMessage : props.helperText}
          </CustomFormHelperText>
        )}
      </FormControl>
    );
  }
  // STANDARD OUTLINED INPUT FIELD
  return (
    <FormControl variant="outlined" className={classes.formControlRoot} >
      <InputLabel htmlFor={props.id}>{props.inputLabel}</InputLabel>
      <CustomOutlinedInput
        className={classNames(classes.root, readOnly && classes.readOnly)}
        fullWidth
        required={props.required}
        value={value}
        id={props.id}
        name={props.name}
        label={props.inputLabel}
        error={props.error}
        defaultValue={props.defaultValue}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        inputProps={props.inputProps}
        inputRef={props.inputRef}
        multiline={props.multiline}
        rows={props.rows}
        onBeforeInput={props.onBeforeInput}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        margin={props.margin}
        autoComplete={props.autoComplete}
        autoFocus={props.autoFocus}
        endAdornment={props.endAdornment}
        data-cy={`input-${props.test}`}
        disabled={props.disabled || readOnly}
      />
      {!props.hideHelperText && (
        <CustomFormHelperText
          id={`helper-${props.id}`}
          className={props.fixedHeight ? classes.helperFixedHeight : undefined}
          error={props.error}
          data-cy={`input-helper-${props.test}`}
        >
          {props.errorMessage ? props.errorMessage : props.helperText}
        </CustomFormHelperText>
      )}
    </FormControl>
  );
};

export default LabelInput;
export { CustomFormHelperText }
