export const trainingPrivilegeTypes = [
  {
    TrainingRole_ID: 1,
    Role: "Standard"
  },
  {
    TrainingRole_ID: 2,
    Role: "Privileged"
  },
  {
    TrainingRole_ID: 3,
    Role: "Admin"
  },
]