import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import Link from "components/utils/link.component"
import DisplayHTML from "components/utils/displayHTML.component";
import { Typography, Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  resultBox: {
    padding: "10px 0px 10px 0px",
    width: "100%",
  },
  resultContent: {
    color: variables.textSecondary,
    margin: "10px 35px 25px",
    maxWidth: 1000,
  },
}));

const SearchResult = (props) => {
  const classes = useStyles();
  const { result: { data: result }} = props;

  const linkState = useMemo(() => {
    switch(result.contentType) {
      // Todo: These contentTypes are unused. They may be used in the future.
      //
      // case "Charter-Section-Builder":
      // case "Matrix-Table":
      //   return result.section;
      // case "Data-Inventory":
      //   return {
      //     refId: parseInt(result.section),
      //     productId: result.contentId
      //   };
      // case "Data-Interfaces":
      //   return { productId: result.contentId };
      case "Charter-Section-Builder":
      case "Matrix-Table":
      case "Data-Inventory":
      case "Data-Interfaces":
      case "Control-Matrix":
        return { groupId: result.frameworkId, itemId: result.contentId };
      default:
        return { itemId: result.contentId };
    }
  }, [result]);

  return (
    <div className={classes.resultBox} data-cy={`resultBox-${result.contentType}-${result.section}`}>
      <Link
        variant="routerLinkBold"
        to={{
          pathname: result.pageURL,
          state: linkState
        }}
        test={`search-result-${result.pageTitle}`}
      >
        <Typography variant="h5" >{result.pageTitle}</Typography>
      </Link>
      {!!result && (
        <div className={classes.resultContent}>
          <DisplayHTML
            html={result["sectionDetail-html"]}
            searchTerm={props.searchTerm}
          />
        </div>
      )}
      <Divider />
    </div>
  );
};

export default SearchResult;
