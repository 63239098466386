import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import AddListItem from "components/utils/addListItem.component";
import { isGlobalUser, isPortCoRead } from "utils/roles";

const useStyles = makeStyles(theme => ({
  cellCta: {
    minHeight: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cellCenter: {
    textAlign: "center"
  },
  presentCell: {
    fontWeight: 600
  }
}));

export default function PortfolioCompanyCell(props) {
  const { companyName, formModeClick, pastSessions, test } = props;
  const classes = useStyles();

  return (
    <div className={classes.cellCenter} data-cy={test}>
      {companyName ? (
        <div className={classes.presentCell}>
          {companyName}
        </div>
      ) : (
        <div className={classNames(classes.cellCta, classes.cellCenter)}>
          {!pastSessions && (
            <AddListItem onClick={formModeClick} disabled={isPortCoRead()}>
              {isGlobalUser() ? "Add" : "Book Session"}
            </AddListItem>
          )}
        </div>
      )}
    </div>
  );
}
