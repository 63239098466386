import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import AddRoadmapItemLink from "./shared/addRoadmapItemLink.component";

const useStyles = makeStyles(theme => ({
  cellCta: {
    minHeight: 35,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  cellCenter: {
    textAlign: "center"
  },
  linkWrapper: {
    padding: 5,
  },
}));


export default function RoadmapItemCell(props) {
  const { index, replaceRoadmapItem, row, setReviewPageMode } = props;
  const classes = useStyles();

  return (
    <div className={classNames(classes.cellCta, classes.cellCenter)}>
      <div className={classes.linkWrapper}>
        <AddRoadmapItemLink
          label={row?.RoadmapDescription}
          replaceRoadmapItem={replaceRoadmapItem}
          index={index}
          item={row}
          setReviewPageMode={setReviewPageMode}
        />
      </div>
    </div>
  );
}
