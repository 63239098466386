import React from "react";
import NavigateBeforeRoundedIcon from "@material-ui/icons/NavigateBeforeRounded";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import { Link } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  dashboardLinkWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
    fill: variables.secondaryDark,
    "&:hover": {
      fill: variables.primaryLight,
    },
  },
  dashboardLink: {
    color: variables.secondaryDark,
    fontSize: variables.fontMedium,
    lineHeight: "1rem",
    fontWeight: "bold",

    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
      color: variables.primaryLight,
    },
  },
  backToIcon: {
    fill: "inherit",
  },
  subIcon: {
    height: 22,
    width: 22,
  },
  subLinkWrapper: {
    display: "flex",
    alignItems: "center",
    paddingTop: 15,
    fill: variables.secondaryDark,
    "&:hover": {
      fill: variables.primaryLight,
    },
  },
  subLink: {
    color: variables.secondaryDark,
    fontSize: variables.fontSmall,
    fontWeight: "bold",

    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      color: variables.primaryLight,
    },
  },
  dense: {
    paddingTop: 5,
  }
}));

const BackToLink = (props) => {
  const { dense, href, onClick, parentPage, text, variant } = props;

  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    if (onClick) {
      return onClick();
    } else {
      history.push(href);
    }
  };

  if (variant === "subLink") {
    return (
      <div className={classNames(classes.subLinkWrapper, dense && classes.dense)}>
        <NavigateBeforeRoundedIcon
          className={classNames(classes.backToIcon, classes.subIcon)}
        />
        <Link
          className={classes.subLink}
          onClick={handleClick}
          data-cy="back-to-link"
        >
          {text}
        </Link>
      </div>
    );
  }
  return (
    <div className={classes.dashboardLinkWrapper}>
      <NavigateBeforeRoundedIcon className={classes.backToIcon} />
      <div
        className={classes.dashboardLink}
        onClick={handleClick}
        data-cy="back-to-link"
      >
        {parentPage ? `Back to ${parentPage}` : "Back"}
      </div>
    </div>
  );
};

export default BackToLink;
