import React, { useEffect, useMemo, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Divider } from "@material-ui/core";
import ReportItem from "components/assessment/review/reportItem.component";
import DataCollectionCount from "components/assessment/shared/dataCollectionCount.component";
import HeaderPrimary from "components/utils/header.component";
import classNames from "classnames";
import PrintIconButton from "components/utils/printIconButton.component";
import AssessBuilderInfoHeader from "components/assessment/shared/assessBuilderInfoHeader.component";
import DisplayHTML from "components/utils/displayHTML.component";
import PageHeader from "components/utils/pageHeader.component";
import Loader from "components/utils/loader.components";
import riskAssessmentService from "services/riskAssessment.service";

const useStyles = makeStyles((theme) => ({
  loaderWrapper: {
    height: "100%",
    width: "100%",
  },
  appBar: {
    padding: "0 30px 10px",
  },
  body: {
    maxHeight: "calc(100vh - 240px)",
    overflow: "auto",
    maxWidth: 1200,
  },
  container: {
    padding: "40px 60px 20px",
    maxHeight: "calc(100vh - 60px)",
    maxWidth: "100%",
    [theme.breakpoints.up("lg")]: {
      maxWidth: "1000",
    },
    "@media print": {
      paddingTop: 20,
    },
  },
  reportContentContainer: {
    padding: "20px 0px",
    paddingBottom: 50,
  },
  summarySection: {
    color: theme.palette.text.secondary,
  },
  itemsByStatus: {
    padding: "30px 0px",
  },
  summaryBody: {
    display: "flex",
    flexDirection: "column",
  },
  titleHeader: {
    padding: 0,
    marginBottom: 5,
  },
  titleHeaderExtraPaddingBottom: {
    paddingBottom: 8,
  },
  dataCollectionWrapper: {
    display: "flex",
  },
  printContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "-12px",
    padding: "15px 0 0 20px",
  },
  summaryRecommendations: {
    marginBottom: 36,
  },
  summaryRecommendationList: {
    marginTop: 8,
  },
  noRecommendation: {
    marginTop: "1em",
  },
  summaryPrintSource: {
    "@media print": {
      marginBottom: 0,
    },
  },
  printSource: {
    "@media print": {
      lineHeight: 1.2,
      fontSize: "12px",
    },
  },
}));

const assessItemIDsWIthReviewFinder = (desRevs, perfRevs) => {
  const itemsWithDesRevs = desRevs?.map((desRev) => desRev.AssessmentItem_ID);
  const itemsWithPerfRevs = perfRevs?.map((perfRev) => perfRev.AssessmentItem_ID);
  return [...new Set([...itemsWithDesRevs, ...itemsWithPerfRevs])];
};

const ReviewReport = ({
  assessmentItems,
  designItems,
  performanceItems,
  designSummary,
  performanceSummary,
  assessors,
  references,
  builderInfo,
  isPage,
}) => {
  const classes = useStyles();
  const [summaryRecommendations, setSummaryRecommendations] = useState([]);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: () =>
      `@page {margin-top: 0.5in; margin-left: 0in; margin-bottom: 0.5in; margin-right: 0in; !important;}`,
  });

  const isLoading =
    !assessmentItems || !designItems || !performanceItems || !references;

  useEffect(() => {
    (async function getSummaryRecommendations() {
      if (!builderInfo.Builder_ID) {
        return;
      }
      const summaryResponse =
        await riskAssessmentService.getAssessmentSummaryRecommendationsByBuilderID(
          builderInfo.Builder_ID
        );

      setSummaryRecommendations(summaryResponse.items || []);
    })();
  }, [builderInfo.Builder_ID, setSummaryRecommendations]);

  const assessItemIDsWithReviews = useMemo(
    () =>
      isLoading
        ? []
        : assessItemIDsWIthReviewFinder(designItems, performanceItems),
    [isLoading, designItems, performanceItems]
  );

  const allReviews = isLoading ? [] : [...designItems, ...performanceItems];

  return (
    <>
      <AppBar
        color="inherit"
        position="sticky"
        className={classNames(classes.appBar)}
      >
        <PageHeader
          title={isPage ? "Completed Assessment" : "Assessment Report"}
          titleOnly={!isPage}
          dashboardTitle={isPage}
        >
          <div className={classNames(classes.printContainer)}>
            <PrintIconButton fontSize="large" onClick={handlePrint} />
          </div>
        </PageHeader>
      </AppBar>
      <div className={classNames(classes.body)} data-cy="review-report-page">
        {isLoading ? (
          <div className={classNames(classes.loaderWrapper)}>
            <Loader />
          </div>
        ) : (
          <div
            ref={componentRef}
            className={classNames(classes.container, classes.printSource)}
          >
            <div className={classNames(classes.summaryBody)}>
              <div className={classes.summarySection}>
                <AssessBuilderInfoHeader
                  builderInfo={builderInfo}
                  headerVariant="h2Primary"
                />
              </div>
              <div
                className={classNames(
                  classes.summarySection,
                  classes.itemsByStatus
                )}
              >
                <HeaderPrimary
                  variant="h4Primary"
                  className={classNames(
                    classes.titleHeader,
                    classes.titleHeaderExtraPaddingBottom
                  )}
                >
                  Total assessment items: {assessmentItems.length}
                </HeaderPrimary>
                <div className={classes.dataCollectionWrapper}>
                  <DataCollectionCount
                    designSummary={designSummary}
                    performanceSummary={performanceSummary}
                  />
                </div>
              </div>
            </div>

            <div
              className={classNames(
                classes.summarySection,
                classes.summaryRecommendations,
                classes.summaryPrintSource
              )}
            >
              <HeaderPrimary
                variant="h4Primary"
                className={classNames(classes.titleHeader)}
              >
                Summary Recommendations
              </HeaderPrimary>
              {summaryRecommendations.length > 0 ? (
                <ol className={classes.summaryRecommendationList}>
                  {summaryRecommendations.map((item) => {
                    return (
                      <li key={item.Recommendation}>
                        <DisplayHTML html={item.Recommendation} />
                      </li>
                    );
                  })}
                </ol>
              ) : (
                <div className={classes.noRecommendation}>
                  No Recommendations
                </div>
              )}
            </div>

            <Divider />

            <div className={classes.reportContentContainer}>
              {assessItemIDsWithReviews.map((assessItemID, index) => {
                return (
                  <ReportItem
                    key={assessItemID}
                    assessors={assessors}
                    assessmentItem={assessmentItems.find(
                      (item) => item.AssessmentItem_ID === assessItemID
                    )}
                    itemReviews={allReviews.filter(
                      (rev) => rev.AssessmentItem_ID === assessItemID
                    )}
                    references={references}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ReviewReport;
