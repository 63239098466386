import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import ButtonDefault from "components/utils/buttonDefault.component";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import classNames from "classnames";
import NotInterestedOutlinedIcon from '@material-ui/icons/NotInterestedOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    paddingBottom: 20,
  },
  dialogPaper: {
    padding: "30px 30px 20px 30px",
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: 200,
    margin: "0px auto",
    width: "100%",
    paddingBottom: 20,
    paddingTop: 20,
  },
  singleActionContainer: {
    display: "flex",
    justifyContent: "center",
  },
  largeLabel: {
    fontSize: variables.fontXl,
    textAlign: "center",
  },
  icon: {
    fill: "white",
    fontSize: "16px",
    marginRight: 8,
  },
  approveIcon: {
    fontSize: "16px",
    marginRight: 8,
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "center",
    padding: 5,
    fontSize: variables.fontXl,
    color: variables.primaryMain,
    fontWeight: "bold"
  }
}));

export const DialogPop = (props) => {
  const classes = useStyles();


  const handleClose = () => {
    props.setOpenDialog(false);
  };

  if (props.notification) {
    return (
      <Dialog
        open={!!props.openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ classes: { root: classes.dialogPaper } }}
      >
        {!!props.dialogTitle && (
          <div id="alert-dialog-title" className={classes.dialogTitle}>
            {props.dialogTitle}
          </div>
        )}
        <DialogContent>
          {!!props.prompt && (
            <DialogContentText
              id="alert-dialog-description"
              data-cy="dialog-notification"
            >
              {props.prompt}
            </DialogContentText>
          )}
          {props.children}
        </DialogContent>
        <DialogActions className={classes.singleActionContainer}>
          <ButtonDefault
            variant="small"
            background="secondary"
            onClick={props.confirm}
            data-cy="dialog-ok"
          >
            OK
          </ButtonDefault>
        </DialogActions>
      </Dialog>
    );
  } else {
    return (
      <Dialog
        open={!!props.openDialog}
        onClose={handleClose}
        className={classNames(props.className)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ classes: { root: classes.dialogPaper } }}
      >
        {!!props.dialogTitle && (
          <div id="alert-dialog-title" className={classes.dialogTitle}>
            {props.dialogTitle}
          </div>
        )}
        <DialogContent className={classes.dialogContent}>
          <DialogContentText
            className={
              classNames(
                props.largeLabel ? classes.largeLabel : undefined,
                props.customLabelStyle ? props.customLabelStyle : undefined
              )
            }
            id="alert-dialog-description"
            data-cy="dialog-option"
          >
            {props.prompt}
          </DialogContentText>
          {props.children}
        </DialogContent>
        {!props.hideButtons && (
          <DialogActions className={classes.actionsContainer}>
            {!props.continueAndCancel ? (
              <>
                <ButtonDefault
                  variant="small"
                  background="secondary"
                  onClick={props.confirm}
                  data-cy="dialog-yes"
                  startIcon={(
                    <ThumbUpAltOutlinedIcon
                      className={classNames(classes.approveIcon, classes.icon)}
                    />
                  )}
                >
                  Yes
                </ButtonDefault>
                <ButtonDefault
                  variant="small"
                  background="red"
                  onClick={handleClose}
                  data-cy="dialog-no"
                  startIcon={(
                    <CloseOutlinedIcon
                      className={classNames(classes.cancelIcon, classes.icon)}
                    />
                  )}
                >
                  No
                </ButtonDefault>
              </>
            ) : (
              <>
                <ButtonDefault
                  variant="small"
                  background="secondary"
                  onClick={props.confirm}
                  data-cy="dialog-continue"
                  startIcon={(
                    <ThumbUpAltOutlinedIcon
                      className={classNames(classes.approveIcon, classes.icon)}
                    />
                  )}
                >
                  Continue
                </ButtonDefault>
                <ButtonDefault
                  variant="small"
                  background="grey"
                  onClick={handleClose}
                  data-cy="dialog-cancel"
                  startIcon={(
                    <NotInterestedOutlinedIcon
                      className={classNames(classes.cancelIcon, classes.icon)}
                    />
                  )}
                >
                  Cancel
                </ButtonDefault>{" "}
              </>
            )}
          </DialogActions>
        )}
      </Dialog>
    );
  }
};

export default DialogPop;
