import React, { useCallback, useState } from "react";
import {
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Table,
} from "@material-ui/core";
import TableCell from "components/utils/tables/shared/tableCell.component";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  updateEditableOrderFields,
} from "utils/arrayOfObjectsHelpers";
import { makeStyles } from "@material-ui/core/styles";
import EditableText from "components/utils/form-elements/editableText.component";
import variables from "styleVariables";
import classNames from "classnames";
import { sortIntByStringKey } from "utils/sortingFuncs";
import TextFieldPlain from "components/utils/form-elements/textFieldPlain.component";
import EditIcon from "@material-ui/icons/Edit";
import { assessmentTypeIds } from "utils/assessmentConstants";
import { getAssessmentItemName } from "utils/assessmentHelpers";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    paddingTop: 8,
  },
  table: {
    borderCollapse: "separate"
  },
  subHeaderCell: {
    color: "white",
    fontWeight: "bold",
    fill: variables.textSecondary,
    fontSize: variables.fontMedium,
    width: 400,
    padding: "7px 10px",
    lineHeight: "normal",
    [theme.breakpoints.down("md")]: {
      fontSize: variables.fontMedium,
    },
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
  design: {
    backgroundColor: variables.primaryLight,
    borderColor: variables.primaryLight,
  },
  performance: {
    borderBottom: `1px solid ${variables.secondaryMain}`,
    backgroundColor: variables.secondaryMain,
    borderColor: variables.secondaryMain,
  },
  tableBodyCell: {
    padding: 0,
    color: variables.textSecondary,
    fontSize: variables.fontSmall,
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    width: 400,
    verticalAlign: "top",
    [theme.breakpoints.down("md")]: {
      fontSize: variables.fontXs,
    },
    "& ul": {
      padding: 0,
    },
    "& li": {
      listStyleType: "none",
      padding: "5px 5px 5px 10px",
      margin: 0,
      borderBottom: `2px solid ${variables.tertiary2}`,
    }
  },
  emptyList: {
    borderBottom: "none !important",
  },
  borderRight: {
    borderRight: "1px solid rgba(224, 224, 224, 1)",
  },
  leftAlign: {
    textAlign: "left",
  },
  checkItemWrapper: {
    borderBottom: "2px solid #00a3b6",
    paddingTop: 5,
    paddingBottom: 5,
    "&:hover": {
      backgroundColor: variables.rowHover,
    },
  },
  createSectionWrapper: {
    paddingTop: 5,
    paddingBottom: 5,
    borderBottom: "2px solid #ffffff",
    fontSize: 10,
    "&:hover": {
      borderColor: variables.tertiary2,
      "& $editIcon": {
        fill: variables.tertiary2,
      },
      "& $createText": {
        color: "transparent",
      },
    },
  },
  createLabel: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  createText: {
    fontSize: variables.fontSmall,
    height: 17,
  },
  editIcon: {
    height: 15,
    marginLeft: 5,
    marginRight: 5,
  },
}));

const designTypeID = assessmentTypeIds.ASSESS_TYPE_DESIGN;
const perfTypeID = assessmentTypeIds.ASSESS_TYPE_PERFORMANCE;
const INPUT_CHAR_LIMIT = 45;

const CheckListTable = ({
  assessItem,
  addItem,
  checkListData,
  removeCheckItem,
  editCheckItem,
  editObj,
  editCheckListHandler,
  assessmentType,
  checkItems,
  activeInput,
  setActiveInput,
  formErrors,
  handleEnterKey,
  readOnlyView, //For step 6
  onSave,
  isSavingChecklistItems,
}) => {
  const classes = useStyles();
  const assessItemId = assessItem.AssessmentItem_ID;
  const [newCheckItemValue, setNewCheckItemValue] = useState("");
  const desCheckListItems = checkItems.filter(
    (item) => item.Review_Type === designTypeID
  );
  const perfCheckListItems = checkItems.filter(
    (item) => item.Review_Type === perfTypeID
  );

  const handleChange = (event) => {
    setNewCheckItemValue(event.target.value)
  }

  const handleSave = useCallback((...args) => {
    onSave(...args);
    setNewCheckItemValue("");
  }, [onSave]);

  function handleOnDragEnd(result, type, assessItemID) {
    if (!result.destination || isSavingChecklistItems) {
      return;
    }
    // 1. Grab previous and new position of moved item
    const oldOrder = result.source.index + 1;
    const newOrder = result.destination.index + 1;

    if (oldOrder !== newOrder) {
      // 2. Get list of all items in the Drag area of moved item
      const itemsInDragBox = checkListData
        .filter((item) => {
          return item.AssessmentItem_ID === assessItemID;
        })
        .filter((item) => item.Review_Type === type);

      // 3. Returns all affected objects with Item_Order field updated
      const objsWithNewOrder = updateEditableOrderFields(
        itemsInDragBox,
        oldOrder,
        newOrder,
        "Item_Order"
      );
      handleSave(4, null, null, objsWithNewOrder)
    }
  }

  const activeInputClick = (inputID) => {
    if (!isSavingChecklistItems) {
      setActiveInput(inputID);
    }
    setNewCheckItemValue("");
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead>
          {/* ========== DESIGN HEADER - only shows if both types are showing ======== */}
          <TableRow data-cy="assessmentType-header" hover={false}>
            {assessmentType !== perfTypeID && (
              <TableCell
                className={classNames(
                  classes.subHeaderCell,
                  classes.leftAlign,
                  classes.design,
                  assessmentType === designTypeID
                    ? classes.borderRight
                    : undefined
                )}
              >
                Design Checklist Items
              </TableCell>
            )}
            {/* ========== PERFORMANCE HEADER - only shows if both types are showing ======== */}
            {assessmentType !== designTypeID && (
              <TableCell
                className={classNames(
                  classes.subHeaderCell,
                  classes.leftAlign,
                  classes.performance
                )}
              >
                Performance Checklist Items
              </TableCell>
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow hover={false}>
            {/* ========== DESIGN CHECKLIST - EDITABLE ======== */}
            {!readOnlyView && assessmentType !== perfTypeID && (
              <TableCell
                className={classNames(
                  classes.tableBodyCell,
                  assessmentType === designTypeID && classes.borderRight,
                  classes.leftAlign
                )}
                style={{ overflow: "auto" }}
              >
                <DragDropContext
                  onDragEnd={(event) =>
                    handleOnDragEnd(event, designTypeID, assessItemId)}
                >
                  <Droppable droppableId="droppable-design-list">
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {sortIntByStringKey(
                          desCheckListItems,
                          "Item_Order"
                        ).map((item, index) => {
                          const checkItemId = item.ChecklistItem_ID;
                          const isActive = (
                            editObj.ChecklistItem_ID === checkItemId
                          );
                          return (
                            <Draggable
                              draggableId={`${checkItemId}`}
                              isDragDisabled={isActive}
                              index={index}
                              key={checkItemId}
                            >
                              {(providedInfo, snapshot) => (
                                <div
                                  className={classes.checkItemWrapper}
                                  data-cy={`editable-text-${item.Checklist_Item}`}
                                >
                                  <EditableText
                                    provided={providedInfo}
                                    key={checkItemId}
                                    cellItem={item.Checklist_Item}
                                    removeClick={() =>
                                      removeCheckItem(
                                        item,
                                        assessItemId
                                      )}
                                    mode={isActive ? "edit" : "view"}
                                    textFieldId={`checkItem_${checkItemId}`}
                                    editItemId={checkItemId}
                                    onAutosave={editCheckListHandler}
                                    editClick={() => editCheckItem(item)}
                                    initialEditValue={item.Checklist_Item}
                                    error={!!formErrors[`checklist-item-${checkItemId}`]}
                                    hoverHighlight
                                    dragArea={null}
                                    index={index}
                                    onKeyDown={handleEnterKey}
                                    disableRemove={isSavingChecklistItems}
                                    maxLength={INPUT_CHAR_LIMIT}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                        <div
                          className={classNames(
                            classes.createSectionWrapper
                          )}
                        >
                          {activeInput !== `${assessItemId}_Design` ? (
                            <ClickableCreateLabel
                              onClick={() => (
                                activeInputClick(`${assessItemId}_Design`)
                              )}
                              test={`${getAssessmentItemName(assessItem)}-create-design-checklist-item`}
                            />
                          ) : (
                            <div>
                              <TextFieldPlain
                                // inputRef={inputRef}
                                onAutosave={() => (
                                  addItem(
                                    designTypeID,
                                    assessItem,
                                    newCheckItemValue
                                  )
                                )}
                                onKeyDown={(event) => (
                                  handleEnterKey(
                                    event,
                                    designTypeID,
                                    assessItem,
                                    newCheckItemValue
                                  )
                                )}
                                autoFocus
                                name="design-checklist-item"
                                test={`input-${getAssessmentItemName(assessItem)}-create-design-checklist-item`}
                                value={newCheckItemValue}
                                onChange={handleChange}
                                maxLength={INPUT_CHAR_LIMIT}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </TableCell>
            )}
            {/* ========== PERFORMANCE CHECKLIST  - EDITABLE ======== */}
            {!readOnlyView && assessmentType !== designTypeID && (
              <TableCell
                className={classNames(
                  classes.tableBodyCell,
                  classes.borderRight,
                  classes.leftAlign
                )}
              >
                <DragDropContext
                  onDragEnd={(event) => (
                    handleOnDragEnd(event, perfTypeID, assessItemId)
                  )}
                >
                  <Droppable droppableId="droppable-performance-list">
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {sortIntByStringKey(
                          perfCheckListItems,
                          "Item_Order"
                        ).map((item, index) => {
                          const checkItemId = item.ChecklistItem_ID;
                          const isActive = (
                            editObj.ChecklistItem_ID === checkItemId
                          );
                          return (
                            <Draggable
                              draggableId={`${checkItemId}`}
                              isDragDisabled={isActive}
                              index={index}
                              key={checkItemId}
                            >
                              {(providedInfo, snapshot) => (
                                <div className={classes.checkItemWrapper}
                                  data-cy={`editable-text-${item.Checklist_Item}`}
                                >
                                  <EditableText
                                    provided={providedInfo}
                                    key={checkItemId}
                                    cellItem={item.Checklist_Item}
                                    removeClick={() => (
                                      removeCheckItem(
                                          item,
                                          assessItemId
                                      )
                                    )}
                                    mode={isActive ? "edit" : "view"}
                                    textFieldId={`checkItem_${checkItemId}`}
                                    editItemId={checkItemId}
                                    onAutosave={editCheckListHandler}
                                    editClick={() => editCheckItem(item)}
                                    initialEditValue={item.Checklist_Item}
                                    error={!!formErrors[`checklist-item-${checkItemId}`]}
                                    index={index}
                                    onKeyDown={handleEnterKey}
                                    disableRemove={isSavingChecklistItems}
                                    maxLength={INPUT_CHAR_LIMIT}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                        <div
                          className={classNames(
                              classes.createSectionWrapper
                          )}
                        >
                          {activeInput !== `${assessItemId}_Performance` ? (
                            <ClickableCreateLabel
                              onClick={() => (
                                activeInputClick(`${assessItemId}_Performance`)
                              )}
                              test={`${getAssessmentItemName(assessItem)}-create-performance-checklist-item`}
                            />
                          ) : (
                            <div>
                              <TextFieldPlain
                                onAutosave={() => (
                                  addItem(
                                    perfTypeID,
                                    assessItem,
                                    newCheckItemValue
                                  )
                                )}
                                onKeyDown={(event) => (
                                  handleEnterKey(
                                    event,
                                    perfTypeID,
                                    assessItem,
                                    newCheckItemValue
                                  )
                                )}
                                autoFocus
                                name="performance-checklist-item"
                                test={`input-${getAssessmentItemName(assessItem)}-create-performance-checklist-item`}
                                value={newCheckItemValue}
                                onChange={handleChange}
                                maxLength={INPUT_CHAR_LIMIT}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </TableCell>
            )}
            {/* ========== DESIGN CHECKLIST - readOnlyView ======== */}
            {!!readOnlyView && assessmentType !== perfTypeID && (
              <TableCell
                className={classNames(
                  classes.tableBodyCell,
                  classes.borderRight,
                  classes.leftAlign
                )}
              >
                <ul>
                  {desCheckListItems.length === 0 && (
                    <li className={classes.emptyList}>None</li>
                  )}
                  {sortIntByStringKey(desCheckListItems, "Item_Order").map(
                    (item, index) => (
                      <li key={item.ChecklistItem_ID}>{item.Checklist_Item}</li>
                    )
                  )}
                </ul>
              </TableCell>
            )}
            {/* ========== PERFORMANCE CHECKLIST - readOnlyView ======== */}
            {!!readOnlyView && assessmentType !== designTypeID && (
              <TableCell
                className={classNames(
                  classes.tableBodyCell,
                  classes.borderRight,
                  classes.leftAlign
                )}
              >
                <ul>
                  {perfCheckListItems.length === 0 && (
                    <li className={classes.emptyList}>None</li>
                  )}
                  {sortIntByStringKey(perfCheckListItems, "Item_Order").map(
                    item => (
                      <li key={item.ChecklistItem_ID}>
                        {item.Checklist_Item}
                      </li>
                    )
                  )}
                </ul>
              </TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CheckListTable;


const ClickableCreateLabel = ({ onClick, test }) => {
  const classes = useStyles();
  return (
    <div onClick={onClick} className={classes.createLabel} data-cy={test}>
      <EditIcon className={classes.editIcon} />
      <span className={classes.createText}>
        Type a Checklist Item
      </span>
    </div>
  );
};
