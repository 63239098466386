import { H4 } from "components/utils/headerV2.component";
import Loader from "components/utils/loader.components";
import IncidentsTable from "../shared/tables/incidentsTable.component";
import { makeStyles } from "@material-ui/core/styles";
import useNumericParams from "hooks/useNumericParams";
import { useState, useCallback } from "react";
import { ACTION_SET_UI_HIGHLIGHTED_ROW } from "reducers/runbook/oneRunbook.reducer";
import IncidentModal from "./modals/incidentModal.component";

const useStyles = makeStyles((theme) => ({
  addIncidentWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "flex-end",
    padding: "0px 8px",
  },
}));

export default function IncidentsPanel(props) {
  const { state, dispatch } = props;
  const params = useNumericParams();
  const classes = useStyles();
  const { runbookId } = params;
  const [incident, setIncident] = useState();

  const handleTaskClick = useCallback(task => {
    dispatch({ type: ACTION_SET_UI_HIGHLIGHTED_ROW, payload: task.Task_ID });
  }, [dispatch]);

  if (!state.runbookIncidents) {
    return (
      <Loader height={200} />
    );
  }
  return (
    <div>
      <H4 color="primary" withMargin>Event Incidents</H4>
      <IncidentsTable
        onIncidentDescriptionClick={setIncident}
        incidents={state.runbookIncidents}
        onTaskClick={handleTaskClick}
      />
      <div className={classes.addIncidentWrapper}>
        <IncidentModal
          state={state}
          dispatch={dispatch}
          runbookId={runbookId}
          incident={incident}
          setIncident={setIncident}
        />
      </div>
    </div>
  );
}
