import { Box, Divider, makeStyles, Paper } from "@material-ui/core";
import { RemoveCircleOutline, Save, CheckCircleOutline, ErrorOutline } from "@material-ui/icons";
import CommentsSection from "components/certification/comments/commentsSection.component";
import ButtonDefault from "components/utils/buttonDefault.component";
import Form from "components/utils/form-elements/form.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import Header from "components/utils/header.component";
import Loader from "components/utils/loader.components";
import { useCallback, useMemo, useState } from "react";
import {
  ACTION_DELETE_MANUAL_SECTION,
  ACTION_DELETE_MANUAL_SUBSECTION,
  ACTION_DELETE_MANUAL_NESTED_SUBSECTION,
  ACTION_REPLACE_MANUAL_SECTION,
  ACTION_REPLACE_MANUAL_SUBSECTION,
  ACTION_REPLACE_MANUAL_NESTED_SUBSECTION
} from "reducers/certification/manuals.reducer";
import certificationService from "services/certification.service";
import { makeSectionNumberingMap } from "utils/certificationHelpers";
import { manualSectionTypes } from "utils/certificationConstants";
import AutoNumbering from "../autoNumbering.component";
import AddSectionButton from "./addSectionButton.component";
import CfrReferenceSelection from "./cfrReferenceSelection.component";
import classNames from "classnames";
import SectionSunEditor from "./sectionSunEditor.component";
import styleVariables from "styleVariables";

const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: 16,
  },
  form: {
    marginBottom: 24,
    flex: 1,
  },
  paper: {
    padding: "8px 32px 8px 0px",
  },
  formMessage:{
    marginLeft: 16,
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
  successMessage: {
    color: styleVariables.statusGreenDark,
  },
  errorMessage: {
    color: styleVariables.warningMain
  },
  sectionFormWrapper: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
  },
  sectionCommentsContainer: {
    width: 240,
    marginRight: 60,
    marginLeft: 8,
  },
  subsectionCommentsContainer: {
    width: 240,
    marginRight: 68,
    marginLeft: 8,
  },
  nestedSubsectionCommentsContainer: {
    width: 240,
    marginTop: 24,
    marginRight: 44,
    marginLeft: 8,
    position: "fixed",
    right: 32,
  },
  subsectionWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: 8,
    width: "100%",
  },
  section: {
    marginRight: 0,
  },
  subSection: {
    marginRight: 56,
  },
  hoverSection: {
    "&:hover": {
      boxShadow: `1px 1px 8px 2px ${styleVariables.secondaryMain}`,
    },
  },
  hoverSubsection: {
    "&:hover": {
      boxShadow: `1px 1px 8px 2px ${styleVariables.tertiary2}`,
    },
  },
  hoverNestedSubsection: {
    "&:hover": {
      boxShadow: `1px 1px 8px 2px ${styleVariables.tertiary1}`,
    },
  },
  inputWrapper: {
    maxWidth: 700,
    width: "100%",
  },
  numberWrapper: {
    marginTop: 40,
    marginLeft: 8
  },
}));

const replaceReducerBySectionType = {
  [manualSectionTypes.MAIN_SECTION]: ACTION_REPLACE_MANUAL_SECTION,
  [manualSectionTypes.SUBSECTION]: ACTION_REPLACE_MANUAL_SUBSECTION,
  [manualSectionTypes.NESTED_SUBSECTION]: ACTION_REPLACE_MANUAL_NESTED_SUBSECTION,
};

const deleteReducerBySectionType = {
  [manualSectionTypes.MAIN_SECTION]: ACTION_DELETE_MANUAL_SECTION,
  [manualSectionTypes.SUBSECTION]: ACTION_DELETE_MANUAL_SUBSECTION,
  [manualSectionTypes.NESTED_SUBSECTION]: ACTION_DELETE_MANUAL_NESTED_SUBSECTION,
};

const SUN_EDITOR_OPTIONS = {
  minHeight: 150,
  maxHeight: "50vh",
};

const FORM_MESSAGE_UPDATED = "Updated";
const FORM_MESSAGE_ERROR = "Error";

export default function ManualSection(props) {
  const classes = useStyles();
  const { dispatch, isSubsection, isNestedSubsection, parentSection, section, state } = props;
  const { chapterNumber, sectionNumber, subSectionNumber, nestedSubSectionNumber, } = props;
  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
  const [formMessage, setFormMessage] = useState("")
  const isMainSection = !isSubsection && !isNestedSubsection;

  const sectionType = useMemo(() => {
    if (isSubsection) {
      return manualSectionTypes.SUBSECTION;
    }
    if (isNestedSubsection) {
      return manualSectionTypes.NESTED_SUBSECTION;
    }
    return manualSectionTypes.MAIN_SECTION;
  }, [isSubsection, isNestedSubsection]);

  const headerClass = useMemo(() => {
    switch(sectionType) {
      case "Section":
        return classes.hoverSection;
      case "Subsection":
        return classes.hoverSubsection;
      case "NestedSubsection":
        return classes.hoverNestedSubsection;
      default:
        return "";
    }
  }, [classes, sectionType]);

  const subSectionNumberMap = useMemo(() => {
    if (isMainSection) {
      return makeSectionNumberingMap(section?._associations.ManualSection);
    }
  }, [section?._associations.ManualSection, isMainSection]);

  const nestedSubSectionNumberMap = useMemo(() => {
    if (isSubsection) {
      return makeSectionNumberingMap(section?._associations.ManualSection);
    }
  }, [section?._associations.ManualSection, isSubsection]);

  const handleInputChange = useCallback(() => {
    setIsUnsavedChanges(true);
    setFormMessage("");
  }, []);

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        const sectionResponse = await certificationService.updateSection(
          section.ManualSection_ID,
          formData
        );
        setFormMessage(FORM_MESSAGE_UPDATED);
        setIsUnsavedChanges(false);
        dispatch({
          type: replaceReducerBySectionType[sectionType],
          payload: sectionResponse.payload,
        });
      } catch(err) {
        console.log(err);
        setFormMessage(FORM_MESSAGE_ERROR);
      }
    },
    [dispatch, section, sectionType]
  );

  const handleDelete = useCallback(async () => {
    const sectionResponse = await certificationService.deleteSection(
      section.ManualSection_ID
    );
    dispatch({
      type: deleteReducerBySectionType[sectionType],
      payload: sectionResponse.payload,
      meta: sectionResponse.meta,
    });
  }, [dispatch, section, sectionType]);

  const WrapperComponent = useMemo(() => (
    isMainSection ? SectionWrapper : SubsectionWrapper
  ), [isMainSection]);

  if (!section) {
    return <Loader />;
  }
  return (
    <div
      className={classNames(isMainSection ? classes.section : classes.subSection)}
      data-cy={`section-wrapper-${section?.Header}`}
    >
      <div className={headerClass}>
        <WrapperComponent>
          <div className={classes.sectionFormWrapper}>
            <Box display="flex" width="100%">
              <div className={classes.numberWrapper} data-cy={`number-wrapper-${section?.Header}`}>
                <AutoNumbering
                  chapterNumber={chapterNumber}
                  sectionNumber={sectionNumber}
                  subSectionNumber={subSectionNumber}
                  nestedSubSectionNumber={nestedSubSectionNumber}
                  isSubsection={isSubsection}
                  sectionType={sectionType}
                  section={section}
                  dispatch={dispatch}
                />
              </div>
              <div className={classes.form}>
                <Header variant="h3Tertiary" className={classes.header}>
                  {sectionType === manualSectionTypes.MAIN_SECTION
                    ? "Section"
                    : "Subsection"}
                </Header>
                <Form
                  name={`section-form-${section.ManualSection_ID}`}
                  onSubmit={handleSubmit}
                  onChange={handleInputChange}
                >
                  <div className={classes.inputWrapper}>
                    <LabelInput
                      name="Header"
                      label={`${
                        isMainSection ? "Section" : "Subsection"
                      } Header`}
                      defaultValue={section.Header}
                      margin="dense"
                      variant="default"
                    />
                  </div>

                  <SectionSunEditor
                    name="Content"
                    label="Content"
                    defaultValue={section.Content}
                    height="auto"
                    setOptions={SUN_EDITOR_OPTIONS}
                    section={section}
                    state={state}
                    dispatch={dispatch}
                    onChange={handleInputChange}
                  />
                  <Box marginTop={2} marginBottom={4}>
                    <CfrReferenceSelection
                      manualSection={section}
                      state={state}
                      dispatch={dispatch}
                    />
                  </Box>
                  <Box display="flex" justifyContent="space-between" data-cy={`btn-wrapper-${section?.Header}`}>
                    <ButtonDefault
                      background="primary"
                      type="submit"
                      variant="small"
                      startIcon={
                        <Box marginRight={1}>
                          <Save fontSize="small" />
                        </Box>
                      }
                      disableReadOnlyUsers
                      disabled={!isUnsavedChanges}
                      data-cy={`btn-save-section-${section?.Header}`}
                    >
                      Save
                    </ButtonDefault>
                    {!!formMessage && (
                      formMessage.includes(FORM_MESSAGE_ERROR) ? (
                        <div className={classNames(classes.formMessage, classes.errorMessage)}>
                          <ErrorOutline />
                          <Box marginLeft={1}>{formMessage}</Box>
                        </div>
                      ) : (
                        <div className={classNames(classes.formMessage, classes.successMessage)} data-cy="success">
                          <CheckCircleOutline />
                          <Box marginLeft={1}>{formMessage}</Box>
                        </div>
                      )
                    )}
                    <ButtonDefault
                      color="red"
                      variant="small"
                      onClick={handleDelete}
                      startIcon={
                        <Box marginRight={1}>
                          <RemoveCircleOutline fontSize="small" />
                        </Box>
                      }
                      disableReadOnlyUsers
                      data-cy={`btn-del-section-${section?.Header}`}
                    >
                      Delete
                    </ButtonDefault>
                  </Box>
                </Form>
                {/* ===== FOR ADDING FIRST SUBSECTION TO A SECTION ===== */}
                {!!isMainSection && section._associations?.ManualSection?.length === 0 && (
                  <Box marginTop={4}>
                    <AddSectionButton
                      dispatch={dispatch}
                      parentSection={section}
                      prevOrder={0}
                      sectionType={manualSectionTypes.SUBSECTION}
                      test="first-subsection"
                    />
                  </Box>
                )}

                {/* ===== FOR ADDING THE FIRST NESTED SUBSECTION TO A SUBSECTION ===== */}
                {!!isSubsection && section._associations?.ManualSection?.length === 0 && (
                  <Box marginTop={4}>
                    <AddSectionButton
                      dispatch={dispatch}
                      parentSection={section}
                      prevOrder={0}
                      sectionType={manualSectionTypes.NESTED_SUBSECTION}
                      test="first-nested-subsection"
                    />
                  </Box>
                )}
              </div>
              {!!isMainSection && (
                <div className={classes.sectionCommentsContainer}>
                  <CommentsSection
                    section={section}
                    dispatch={dispatch}
                    comments={state.comments?.filter((comment) => (
                      comment.ManualSection_ID === section.ManualSection_ID &&
                      comment.Status === "Active"
                    ))}
                  />
                </div>
              )}
            </Box>
          </div>
          {/* SUBSECTIONS */}
          {!!isMainSection &&
            section?._associations?.ManualSection?.map?.((subsection) => (
              <div
                className={classes.subsectionWrapper}
                key={subsection.ManualSection_ID}
              >
                <Box width="100%">
                  <ManualSection
                    parentSection={section}
                    section={subsection}
                    chapterNumber={chapterNumber}
                    sectionNumber={sectionNumber}
                    subSectionNumber={
                      subSectionNumberMap[subsection.ManualSection_ID]
                    }
                    state={state}
                    dispatch={dispatch}
                    isSubsection
                  />
                </Box>
                <div className={classes.subsectionCommentsContainer}>
                  <CommentsSection
                    limitedHeight
                    section={subsection}
                    dispatch={dispatch}
                    comments={state.comments?.filter(comment => (
                      comment.ManualSection_ID === subsection.ManualSection_ID &&
                      comment.Status === "Active"
                    ))}
                  />
                </div>
              </div>
            ))}
          {/* NESTED SUBSECTIONS */}
          {!!isSubsection && section._associations?.ManualSection?.length > 0 && (
            <>
              {section._associations.ManualSection.map((subsection) => (
                <div
                  className={classes.subsectionWrapper}
                  key={subsection.ManualSection_ID}
                  data-cy="nested-subsection-div"
                >
                  <Box width="100%">
                    <ManualSection
                      parentSection={section}
                      section={subsection}
                      chapterNumber={chapterNumber}
                      sectionNumber={sectionNumber}
                      subSectionNumber={subSectionNumber}
                      nestedSubSectionNumber={
                        nestedSubSectionNumberMap?.[subsection.ManualSection_ID]
                      }
                      state={state}
                      dispatch={dispatch}
                      isNestedSubsection
                    />
                  </Box>
                  <div className={classes.nestedSubsectionCommentsContainer}>
                    <CommentsSection
                      limitedHeight
                      section={subsection}
                      dispatch={dispatch}
                      comments={state.comments?.filter((comment) => (
                        comment.ManualSection_ID === subsection.ManualSection_ID &&
                        comment.Status === "Active"
                      ))}
                    />
                  </div>
                </div>
              ))}
            </>
          )}
        </WrapperComponent>
      </div>
      {/* ===== FOR ADDING ANY SECTION ===== */}
      <Box marginTop={2} marginBottom={2} marginLeft={0.25}>
        <AddSectionButton
          dispatch={dispatch}
          parentSection={parentSection}
          prevOrder={section.ManualSection_Order}
          sectionType={sectionType}
        />
      </Box>
    </div>
  );
}

function SubsectionWrapper(props) {
  const classes = useStyles();
  const { children } = props;

  return (
    <Paper className={classes.paper} elevation={2}>
      {children}
    </Paper>
  );
}

function SectionWrapper(props) {
  const { children } = props;

  return (
    <div>
      <Box marginTop={4} marginBottom={3}>
        <Divider />
      </Box>
      {children}
    </div>
  );
}
