import {
  mergeAssociationDelete,
  mergeAssociationReplace,
  mergeReplace,
} from "reducers/utils/payloadUtils";
export const ACTION_SET_CHECKLIST_TABLE = "checklist_table__set";
export const ACTION_SET_CHECKLIST_INFO = "checklist_info__set";
export const ACTION_SET_CHECKLIST_ITEMS = "checklist_items__set";
export const ACTION_REPLACE_CHECKLIST_ITEM = "checklist_item__replace";

export const ACTION_ADD_CLI_REFERENCE = "cli_reference__add";
export const ACTION_DELETE_CLI_REFERENCE = "cli_reference__delete";

export const ACTION_SET_POTENTIAL_ASSIGNEES = "potential_assignees__set";
export const ACTION_REPLACE_CLI_ASSIGNMENT = "cli_assignment__replace";
export const ACTION_ADD_CLI_ASSIGNMENT = "cli_assignment__add";
export const ACTION_DELETE_CLI_ASSIGNMENT = "cli_assignment__delete";

export const checklistInitialState = {
  checklistInfo: null,
  checklistItems: null,
  user: null,
};

/*
 * Reducer for Checklist Table.
 */
export default function checklistReducer(state, action) {
  switch (action.type) {
    case ACTION_SET_CHECKLIST_INFO:
      return {
        ...state,
        checklistInfo: action.payload,
      };
    case ACTION_SET_POTENTIAL_ASSIGNEES:
      return {
        ...state,
        users: action.payload,
      };
    case ACTION_SET_CHECKLIST_TABLE:
      return {
        ...state,
        checklistInfo: action.payload.checklistInfo,
        checklistItems: action.payload.checklistItems,
      };
    case ACTION_SET_CHECKLIST_ITEMS:
      return {
        ...state,
        checklistItems: action.payload,
      };
    case ACTION_REPLACE_CHECKLIST_ITEM:
      return {
        ...state,
        checklistItems: mergeReplace(
          state.checklistItems,
          action.payload,
          "ChecklistItem_ID"
        ),
      };
    case ACTION_ADD_CLI_ASSIGNMENT:
    case ACTION_REPLACE_CLI_ASSIGNMENT:
      const updatedChecklist = mergeAssociationReplace(
        state.checklistItems,
        action.payload,
        "ChecklistItem_ID",
        "Assignments",
        "CLItemAssign_ID"
      );
      return {
        ...state,
        checklistItems: updatedChecklist,
      };
    case ACTION_DELETE_CLI_ASSIGNMENT:
      const updatedList = mergeAssociationDelete(
        state.checklistItems,
        action.payload,
        "Assignments",
        "CLItemAssign_ID"
      );
      return {
        ...state,
        checklistItems: updatedList,
      };
    case ACTION_ADD_CLI_REFERENCE:
      return {
        ...state,
        checklistItems: mergeAssociationReplace(
          state.checklistItems,
          action.payload,
          "ChecklistItem_ID",
          "References",
          "CLItemRef_ID"
        ),
      };
    case ACTION_DELETE_CLI_REFERENCE:
      return {
        ...state,
        checklistItems:  mergeAssociationDelete(
          state.checklistItems,
          action.payload,
          "References",
          "CLItemRef_ID"
        )
      };
    default:
      return state;
  }
}
