import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  objectives: {
    padding: "0px 0px 0px 12px",
    marginTop: 0,
    marginBottom: 0,
  },
}));

export default function ObjectivesCell({ objectives }) {
  const classes = useStyles();

  return (
    <ol className={classes.objectives}>
      {objectives.map((objective) => (
        <li key={objective.RequirementObjective_ID}>
          {objective.Objective}
        </li>
      ))}
    </ol>
  );
}
