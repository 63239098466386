import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ButtonDefault from "components/utils/buttonDefault.component";
import { Box, Paper, Grid } from "@material-ui/core";
import {
  generatePath,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import useNumericParams from "hooks/useNumericParams";
import ScoresSection from "./sections/scoresSection.component";
import OrderedListSection from "components/portfolio/reports/sections/orderedListSection.component";
import DefaultSection from "./sections/defaultSection.component";
import MethodologySection from "./sections/methodologySection.component";
import ExecutiveSummary from "./sections/executiveSummary.component";
import Header from "components/utils/header.component";
import PortfolioService from "services/portfolio.service";
import {
  ACTION_REPLACE_REPORT,
  ACTION_REPLACE_REPORT_SECTION,
  ACTION_SET_REPORT_DOCS,
} from "reducers/portfolioReports.reducer";
import Form from "components/utils/form-elements/form.component";
import Logo from "components/utils/logo.component";
import { formattedMomentCustomError } from "utils/dateHelpers";
import SupportingDocsSubsection from "./sections/methodology/supportingDocsSubsection.component";
import SessionsSubsection from "./sections/methodology/sessionsSubsection.component";
import styleVariables from "styleVariables";
import { makePossessive } from "utils/stringFuncs";
import PrintIconButton from "components/utils/printIconButton.component";
import { useReactToPrint } from "react-to-print";
import classNames from "classnames";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import { ArrowBack, CloudUploadOutlined, UndoOutlined } from "@material-ui/icons";
import { isPortCoGroupUser } from "utils/roles";
import CollapsableAlert from "components/utils/collapsableAlert.component";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { sessionTypes } from "utils/portfolioConstants";
import ReportDocumentsModal from "./reportDocumentsModal.component";

const useStyles = makeStyles((theme) => ({
  editPageContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    marginTop: 12,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  editSection: {
    flex: 3,
    paddingRight: 30,
    maxWidth: 1600,
    [theme.breakpoints.down("lg")]: {
      flex: 2,
    },
  },
  editAlertContent: {
    marginTop: 40
  },
  panelSection: {
    flex: 1,
  },
  panelContent: {
    position: "fixed",
    padding: "32px 24px 0 24px",
    overflow: "auto",
    maxHeight: "calc(100vh - 334px)",
    [theme.breakpoints.down("lg")]: {
      flex: 2,
    },
    [theme.breakpoints.down("md")]: {
      maxHeight: "none",
      padding: "32px 0px 0px 0px",
      position: "initial",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      overflow: "visible",
      flexFlow: "column",

    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  referenceCard: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  sessionCard: {
    order: 0,
    paddingBottom: 20,
    [theme.breakpoints.down("md")]: {
      flex: 1,
    },
  },
  artifactCard: {
    order: 10,
  },
  referenceCardContent: {
    alignItems: "stretch",
    marginBottom: 8,
    minHeight: 100,
    maxHeight: "calc(50vh - 320px)",
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      maxHeight: "26vh",
    }
  },
  reportContainer: {
    marginTop: 32,
    overflow: "hidden",
    "@media print": {
      boxShadow: "none",
    },
  },
  reportHeaderWrapper: {
    marginTop: 60,
    display: "flex",
    alignItems: "center",
    "@media print": {
      marginTop: 0,
    },
  },
  reportHeaderButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    width: 200,
    marginBottom: 8,
    marginLeft: "auto",
    [theme.breakpoints.up("lg")]: {
      marginTop: 56,
      marginBottom: 24,
    }
  },
  reportInfo: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 20,
    alignSelf: "felx-end",
  },
  reportTitle: {
    lineHeight: "normal",
  },
  reportSubInfo: {
    display: "flex",
    paddingTop: 5,
    marginBottom: "-10px",
  },
  infoLabelWrapper: {
    paddingRight: 20,
    "&:last-of-type": {
      paddingRight: 20,
    },
  },
  rowHeading: {
    padding: 0,
  },
  referenceHeadingTab: {
    padding: "6px 16px",
    background: styleVariables.tertiary1,
    textAlign: "center",
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  referenceHeaderText: {
    color: "white",
    fontWeight: "bold",
    fontSize: styleVariables.fontLarge,
    [theme.breakpoints.down("lg")]: {
      fontSize: styleVariables.fontMedium,
    },
  },
  printContainer: {
    float: "right",
  },
  buttonWrapper: {
    margin: "10px 0px",
    [theme.breakpoints.down("lg")]: {
      margin: "0px 0px 10px 0px",
    },
  },
  bottomButtons: {
    flexWrap: "wrap",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
    "@media print": {
      display: "none",
    },
  },
  button: {
    width: "auto",
    minWidth: 200,
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 8,
    },
  },
  avoidPageBreak: {
    "@media print": {
      pageBreakInside: "avoid",
    },
  },
  buttonIcon: {
    fontSize: styleVariables.fontMedium,
    marginRight: 4,
  },
  builderButtonsWrapper: {
    width: 200,
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    position: "fixed",
    bottom: "3%",
    right: 25,
    zIndex: 200,
    [theme.breakpoints.down("md")]: {
      order: 20,
      position: "static",
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      width: "100%",
      marginTop: 16,
      marginBottom: 16,
    },
    [theme.breakpoints.down("xs")]: {
      bottom: "unset",
      top: 90,
    },
  },
  readOnlyReview: {
    maxWidth: 1200,
    margin: "0px auto",
    marginBottom: 50,
  },
}));

const MODE_EDIT = "edit";
const MODE_PREVIEW = "preview";

const NEW_DOCUMENT = "new";


export default function ManageReportPage(props) {
  const classes = useStyles();
  const history = useHistory();
  const params = useNumericParams();
  const componentRef = useRef();
  const { mode, portCoId, reportId } = params;

  const [defaultModalFollowUpId, setDefaultModalFollowUpId] = useState(null);
  const [openModalDocumentId, setOpenModalDocumentId] = useState(null);

  const {
    dispatch, state, userReadOnly,
    editReportMessage, setEditReportMessage, goToReport
  } = props;
  const { assessments, portfolioCompanies, reports, reportDocs } = state;

  const isEditMode = mode === MODE_EDIT;

  useEffect(() => (
    async function fetchReportSpecificData() {
      const response = await PortfolioService.getReportSupportingDocs(reportId);
      dispatch({ type: ACTION_SET_REPORT_DOCS, payload: response.payload });
    }
  )(), [dispatch, reportId]);

  const report = useMemo(() => (
    reports?.find?.(potentialReport => potentialReport.Report_ID === reportId)
  ), [reports, reportId]);

  const reportTemplate = useMemo(() => (
    report?._associations?.ReportTemplates?.[0] || null
  ), [report]);

  const company = useMemo(
    () =>
      portfolioCompanies &&
      portfolioCompanies.find((currentCompany) => currentCompany.PortCo_ID === portCoId),
    [portfolioCompanies, portCoId]
  );

  const assessment = useMemo(() => (
    reportTemplate &&
    assessments?.find?.(({ HolderAssessment_ID }) => (
      HolderAssessment_ID === reportTemplate.HolderAssessment_ID
    ))
  ), [assessments, reportTemplate]);

  const assessmentStatus = useMemo(() => (
    assessment?._associations?.AssessmentStatus?.find?.(status => (
      status.PortCo_ID === company.PortCo_ID
    ))
  ), [assessment, company]);

  const [dcSessions, reconciledSession] = useMemo(() => (
    !assessmentStatus ?
      [[], null] :
      assessmentStatus._associations.AssessmentSession.reduce(
        (accumulator, session) => {
          if (session.Type === sessionTypes.RECONCILE) {
            accumulator[1] = session;
          } else {
            accumulator[0].push(session);
          }
          return accumulator;
        }, [[], null]
      )
  ), [assessmentStatus]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    //This pageStyle needs to be here in order to include page margins but keep all other styling
    pageStyle: () =>
      "@media print { body { -webkit-print-color-adjust: exact; } }",
  });

  const handleGoBack = useCallback(() => {
    const pathName = isPortCoGroupUser() ? (
      generatePath(
        "/program/:programId/:programComponentId/holding/:holdingId/portCo/:portCoId",
        params
      )
    ) : (
      generatePath(
        "/program/:programId/:programComponentId/holding/:holdingId",
        params
      )
    );
    history.push(pathName);
  }, [history, params]);

  const handleSessionFollowUpClick = useCallback(followUp => {
    setDefaultModalFollowUpId(followUp.FollowUp_ID);
    setOpenModalDocumentId(NEW_DOCUMENT);
  }, []);

  const handleStatusToggle = useCallback(async () => {
    const didPublish = report.Status === "Draft";
    const data = {
      Status: didPublish ? "Published" : "Draft",
      Published_Date: didPublish ? moment().format("YYYY-MM-DD") : undefined,
    };
    const response = await PortfolioService.updateReport(
      report.Report_ID,
      data
    );
    dispatch({ type: ACTION_REPLACE_REPORT, payload: response.payload[0] });
  }, [dispatch, report]);

  const handleSubsectionAutosave = useCallback(
    async (name, value, subsection) => {
      const data = {
        ReportSection_ID: subsection.ReportSection_ID,
        [name]: value,
      };
      const response = await PortfolioService.updateReportSubsection(
        subsection.ReportSubSection_ID,
        data
      );
      dispatch({
        type: ACTION_REPLACE_REPORT_SECTION,
        payload: response.payload,
      });
    },
    [dispatch]
  );

  const handleSubsectionDelete = useCallback(
    async (sectionId, subsectionId) => {
      const response = await PortfolioService.deleteReportSubsection(
        sectionId,
        subsectionId
      );
      dispatch({
        type: ACTION_REPLACE_REPORT_SECTION,
        payload: response.payload,
      });
    },
    [dispatch]
  );

  if (!reports) {
    return null;
  }

  return (
    <div
      className={
        classNames(classes.reviewReport, !isEditMode && classes.readOnlyReview)
      }
    >
      {!isEditMode ? (
        <div className={classNames(classes.printContainer)}>
          {history.location.state.from === "manageReport" && (
            <ModeButton
              onClick={() => goToReport(portCoId, reportId, MODE_EDIT)}
              mode={mode}
              test="preview-btn"
            />
          )}
          <PrintIconButton fontSize="large" onClick={handlePrint} />
        </div>
      ) : (
        <CollapsableAlert
          showAlert={editReportMessage !== false}
          closeClick={() => setEditReportMessage(false)}
          message={editReportMessage}
          contentClass={classes.editAlertContent}
        />
      )}
      <div ref={componentRef}>
        <div className={isEditMode ? classes.editPageContainer : undefined}>
          <div className={classes.panelSection}>
            {!!isEditMode && (
              <div className={classes.panelContent}>
                <div
                  className={classNames(
                    classes.referenceCard,
                    classes.artifactCard
                  )}
                  data-cy="artifactCard"
                >
                  <div className={classes.reportHeaderButtons}>
                    <ButtonDefault
                      color="primary"
                      className={classes.button}
                      startIcon={(
                        <CloudUploadOutlined className={classes.buttonIcon} />
                      )}
                      onClick={() => setOpenModalDocumentId(NEW_DOCUMENT)}
                    >
                      Upload Artifact
                    </ButtonDefault>
                    <ReportDocumentsModal
                      assessmentStatus={assessmentStatus}
                      dispatch={dispatch}
                      defaultFollowUpId={defaultModalFollowUpId}
                      documents={reportDocs}
                      openModalDocumentId={openModalDocumentId}
                      portfolioCompanies={portfolioCompanies}
                      setOpenModalDocumentId={setOpenModalDocumentId}
                      session={reconciledSession}
                    />
                  </div>
                  <div className={classes.referenceHeadingTab}>
                    <div className={classes.referenceHeaderText}>
                      {makePossessive(company?.Name)} Artifacts Reviewed
                    </div>
                  </div>
                  <Paper className={classes.referenceCardContent}>
                    <SupportingDocsSubsection
                      company={company}
                      dispatch={dispatch}
                      documents={reportDocs}
                      sessions={
                        assessmentStatus?._associations?.AssessmentSession
                      }
                      editReferenceView
                    />
                  </Paper>
                </div>
                <div
                  data-cy="sessionCard"
                  className={classNames(
                    classes.referenceCard,
                    classes.sessionCard
                  )}
                >
                  <div className={classes.referenceHeadingTab}>
                    <div className={classes.referenceHeaderText}>
                      {makePossessive(company?.Name)} Sessions Conducted
                    </div>
                  </div>
                  <Paper className={classes.referenceCardContent}>
                    <SessionsSubsection
                      company={company}
                      onFollowUpClick={handleSessionFollowUpClick}
                      sessions={dcSessions}
                      editReferenceView
                    />
                  </Paper>
                </div>

                {userReadOnly ? (
                  <Grid
                    container
                    item
                    xs={12}
                    className={classNames(classes.builderButtonsWrapper)}
                  >
                    <div className={classes.buttonWrapper}>
                      <ButtonDefault
                        color="secondary"
                        onClick={handleGoBack}
                        className={classes.button}
                      >
                        Back
                      </ButtonDefault>
                    </div>
                  </Grid>
                ) : (
                  <Grid
                    container
                    item
                    xs={12}
                    className={classNames(classes.builderButtonsWrapper)}
                  >
                    <div className={classes.buttonWrapper}>
                      <DraftButton
                        onClick={handleStatusToggle}
                        className={classNames(
                          classes.button,
                          classes.draftButton
                        )}
                        report={report}
                        test="publish-btn"
                      />
                    </div>
                    <div className={classes.buttonWrapper}>
                      <ModeButton
                        onClick={() => (
                          goToReport(
                            portCoId,
                            reportId,
                            MODE_PREVIEW,
                            "manageReport"
                          )
                        )}
                        className={classNames(
                          classes.button,
                          classes.printPreview
                        )}
                        mode={mode}
                        test="preview-btn"
                      />
                    </div>
                    <div className={classes.buttonWrapper}>
                      <ButtonDefault
                        startIcon={<ArrowBack className={classes.buttonIcon} />}
                        color="secondary"
                        onClick={handleGoBack}
                        className={classes.button}
                      >
                        Back
                      </ButtonDefault>
                    </div>
                  </Grid>
                )}
              </div>
            )}
          </div>
          <div className={isEditMode ? classes.editSection : undefined}>
            <div className={classes.reportHeaderWrapper}>
              <Logo
                variant="picker"
                logoId={company?.Logo || null}
                alt={`${company?.Name} logo`}
              />
              <div className={classes.reportInfo}>
                <Header
                  variant="h2Primary"
                  noTransform
                  className={classes.reportTitle}
                >
                  {report.Title}
                </Header>
                {!!isEditMode && (
                  <div className={classes.reportSubInfo}>
                    <InfoLabelValue
                      label="Draft Created"
                      value={formattedMomentCustomError(
                        moment(report.Creation_Date).format("MM/D/YYYY")
                      )}
                    />
                    <InfoLabelValue
                      label="Last Updated"
                      value={formattedMomentCustomError(
                        moment(report.Change_Date).format("MM/D/YYYY")
                      )}
                    />
                    <InfoLabelValue
                      label="Status"
                      value={report.Status}
                    />
                  </div>
                )}
              </div>
            </div>
            <Form name={`report-${report.Report_ID}`}>
              <Paper
                className={classes.reportContainer}
                spacing={4}
                padding={4}
              >
                {report._associations.ReportSections.map(
                  (section, _index, all) => {
                    switch (section.Type) {
                      case "summary":
                        return (
                          <ExecutiveSummary
                            allSections={all}
                            company={company}
                            onSubsectionAutosave={handleSubsectionAutosave}
                            readOnly={!isEditMode}
                            section={section}
                            key={section.ReportSection_ID}
                          />
                        );
                      case "method":
                        return (
                          <div className={classes.avoidPageBreak} key={section.ReportSection_ID}>
                            <MethodologySection
                              company={company}
                              documents={reportDocs}
                              onSubsectionAutosave={handleSubsectionAutosave}
                              readOnly={!isEditMode}
                              report={report}
                              section={section}
                              sessions={
                                assessmentStatus?._associations?.AssessmentSession
                              }
                            />
                          </div>
                        );
                      case "profile":
                        return (
                          <div className={classes.avoidPageBreak} key={section.ReportSection_ID}>
                            <DefaultSection
                              onSubsectionAutosave={handleSubsectionAutosave}
                              readOnly={!isEditMode}
                              section={section}
                            />
                          </div>

                        );
                      case "engagement":
                        return (
                          <div className={classes.avoidPageBreak} key={section.ReportSection_ID}>
                            <ScoresSection
                              dispatch={dispatch}
                              onSubsectionAutosave={handleSubsectionAutosave}
                              readOnly={!isEditMode}
                              section={section}
                            />
                          </div>

                        );
                      case "improvements":
                        return (
                          <OrderedListSection
                            dispatch={dispatch}
                            onSubsectionAutosave={handleSubsectionAutosave}
                            onSubsectionDelete={handleSubsectionDelete}
                            readOnly={!isEditMode}
                            section={section}
                            key={section.ReportSection_ID}
                          />
                        );
                      default:
                        return null;
                      // throw new Error(`Unrecognized report section type: ${section.Type}`);
                    }
                  }
                )}
              </Paper>
              <Box
                className={classes.bottomButtons}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginTop={4}
                marginBottom={2}
              >
                <ButtonDefault
                  startIcon={<ArrowBack className={classes.buttonIcon} />}
                  color="secondary"
                  onClick={handleGoBack}
                  className={classes.button}
                >
                  Back
                </ButtonDefault>
                {!userReadOnly && (
                  <>
                    <DraftButton
                      onClick={handleStatusToggle}
                      className={classNames(
                        classes.button,
                        classes.draftButton
                      )}
                      report={report}
                      horizontalStatus
                    />

                    <ModeButton
                      onClick={() => (
                        goToReport(
                          portCoId,
                          reportId,
                          !isEditMode ? MODE_EDIT : MODE_PREVIEW,
                          "manageReport"
                        )
                      )}
                      className={classNames(
                        classes.button,
                        classes.printPreview
                      )}
                      mode={mode}
                    />
                  </>
                )}
              </Box>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

const DraftButton = ({ onClick, className, horizontalStatus, report, test }) => {
  const classes = useStyles();
  const content = (
    <ButtonDefault
      background="primary"
      onClick={onClick}
      className={className}
      startIcon={
        report.Status === "Draft" ? (
          <PublishOutlinedIcon className={classes.buttonIcon} />
        ) : (
          <UndoOutlined className={classes.buttonIcon} />
        )
      }
      data-cy={test}
    >
      {report.Status === "Draft" ? "Publish" : "Make Draft"}
    </ButtonDefault>
  );
  if (horizontalStatus) {
    return (
      <Box display={horizontalStatus ? "flex" : "block"} alignItems="center">
        {content}
      </Box>
    );
  }
  return content;
};

const ModeButton = ({ onClick, className, mode, test }) => {
  const classes = useStyles();
  return (
    <ButtonDefault
      startIcon={
        mode === MODE_EDIT ? (
          <VisibilityOutlinedIcon className={classes.buttonIcon} />
        ) : (
          <EditOutlinedIcon className={classes.buttonIcon} />
        )
      }
      background="secondary"
      onClick={onClick}
      className={className}
      data-cy={test}
    >
      {mode !== MODE_EDIT ? "Back To Edit" : "Preview"}
    </ButtonDefault>
  );
};

const InfoLabelValue = ({ label, value }) => {
  const classes = useStyles();
  return (
    <Box alignItems="start" display="flex" className={classes.infoLabelWrapper}>
      <Header className={classes.rowHeading} variant="h5Primary">
        {label}:
      </Header>
      <Box marginLeft={1}>{value}</Box>
    </Box>
  );
};
