import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CustomIcons from "components/utils/icons.component.js";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import variables from "styleVariables";
import classNames from "classnames";
import Badge from "components/utils/badge.component";
import { PROGRAM_COMPONENT_TYPE_CUSTOM } from "utils/programConstants";

const useStyles = makeStyles((theme) => ({
  labelWrapper: {
    paddingLeft: 10,
  },
  defaultLabelWrapper: {
    borderRight: `1px solid ${variables.grayDisabled}`,
    padding: 10,
    whiteSpace: "nowrap",
    "&:hover": {
      color: variables.secondaryMain,
      fill: variables.secondaryMain,
      cursor: "pointer",
    },
  },
  icon: {
    height: 22,
  },
  labelWrapperAccordion: {
    paddingLeft: 25,
    fontSize: variables.fontLarge,
  },
  iconLabel: {
    display: "flex",
    alignItems: "center",
    fill: variables.textSecondary,
    color: variables.textSecondary,
  },
  badge: {
    backgroundColor: variables.tertiary2,
  },
  activeLabel: {
    color: "white",
    fill: "white",
    backgroundColor: variables.secondaryDark,
    "&:hover": {
      color: "white",
      fill: "white",
      backgroundColor: variables.secondaryDark,
    },
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -2,
    top: -5,
    border: `1px solid white`,
    fontSize: variables.fontXs,
    fontWeight: "bold",
    borderRadius: 40,
    backgroundColor: "inherit",
    color: "white",
    minHeight: 20,
    minWidth: 20,
    maxWidth: "fit-content",
    whiteSpace: "nowrap",
  },
}))(Badge);


const ProgramComponentLabelIcon = ({
  label,
  programComponent,
  accordion,
  test,
  count,
  onClick,
  active,
  className
}) => {
  const classes = useStyles();

  return !accordion ? (
    <div
      data-cy={`programComponent-${test}`}
      className={
        classNames(
          classes.iconLabel,
          classes.defaultLabelWrapper,
          active && classes.activeLabel,
          className
        )
      }
      onClick={onClick}
    >
      <IconDisplay programComponent={programComponent} />

      <div className={classes.labelWrapper}>{label}</div>
    </div>
  ) : (
    <div className={classes.iconLabel}>
      <IconDisplay programComponent={programComponent} />

      <StyledBadge
        alertCount={count}
        color="secondary"
        data-cy={`item-count-${test}`}
        max={999}
        className={classes.badge}
      />

      <div className={classes.labelWrapperAccordion}>
        {programComponent.Name}
      </div>
    </div>
  );
};

export default ProgramComponentLabelIcon;


const IconDisplay = ({ programComponent }) => {
  const classes = useStyles();

  if (programComponent.Type === PROGRAM_COMPONENT_TYPE_CUSTOM) {
    return (
      <CreateOutlinedIcon className={classes.icon} />
    )
  }
  return (
    <CustomIcons
      variant={programComponent.Nav_Icon}
      className={classes.icon}
    />
  );
};
