import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import CustomLink from "components/utils/link.component";
import variables from "styleVariables";
import { assessBuilderStatus } from "utils/assessmentConstants";

const useStyles = makeStyles(theme => ({
  cellCta: {
    minHeight: 35,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  cellCenter: {
    textAlign: "center"
  },
  designLink: {
    color: variables.primaryLight,
    "&:hover": {
      color: variables.primaryDark,
    },
  },
  linkWrapper: {
    padding: 5,
  },
}));


export default function DesignStatusCell(props) {
  const { row, setReviewPageMode } = props;
  const classes = useStyles();
  const disabledVariant = (props.builderInfo.Status === assessBuilderStatus.BUILDER_STATUS_ASSESSED || props.builderInfo.Status === assessBuilderStatus.BUILDER_STATUS_RESPONDED);

  return (
    <div className={classNames(classes.cellCta, classes.cellCenter)}>
      {(disabledVariant && !row.designItem?.Status) ? (
        <div>--</div>
      ) : (
        <div className={classes.linkWrapper}>
          <CustomLink
            // variant={disabledVariant ? "disabled" : ""}
            variant={(disabledVariant && row.designItem && !row.designItem.Status) ? "disabled" : "customUnderline"}
            className={classes.designLink}
            onClick={() =>
              setReviewPageMode(
                "designDataCollection",
                row.assessmentItem.AssessmentItem_ID,
                row.designItem?.Design_ID
              )}
            ignoreReadOnly={!!row.designItem?.Status}
          > {disabledVariant ?
              ((row.designItem && row.designItem.Status) ?
                row.designItem.Status
                : "--")
              :
              row.designItem?.Status || "Start"}
          </CustomLink>
        </div>
      )}
    </div>
  );
}
