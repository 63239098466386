import Loader from "components/utils/loader.components";
import PortfolioProgramContext from "contexts/portfolioProgram.context";
import ProgramsContext from "contexts/programs.context";
import useNumericParams from "hooks/useNumericParams";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PROGRAM_COMPONENT_TYPE_DASHBOARD } from "utils/programConstants";
import { isPortCoGroupUser } from "utils/roles";
import SelectPortCo from "./portCo/selectPortCo.component";
import SelectPortfolioHolder from "./portfolioHolder/selectPortfolioHolder.component";
import { addHolderToPath, addPortCoToPath, removeHolderFromPath } from "./utils/portfolioRouteHelpers";

const PortfolioComponentObjectWrapper = ({ children, isLoading }) => {
  const {
    permittedPortfolioHolders,
    permittedPortCos
  } = useContext(PortfolioProgramContext);
  const { state } = useContext(ProgramsContext);
  const params = useNumericParams();
  const history = useHistory();

  const [isHolderCoSelect, setIsHolderCoSelect] = useState(null);
  const [isPortCoSelect, setIsPortCoSelect] = useState(false);

  const isPortCoOnlyRole = useMemo(isPortCoGroupUser, []);

  const isDashboardPage = useMemo(() => {
    const type = state.programComponents?.[params.programComponentId]?.Type;
    return type === PROGRAM_COMPONENT_TYPE_DASHBOARD;
  }, [state.programComponents, params.programComponentId]);

  const navigablePortCos = useMemo(() => (
    !params.holdingId ?
      permittedPortCos :
      permittedPortCos?.filter?.(portCo => (
        portCo.PortfolioHolder_ID === params.holdingId
      ))
  ), [params.holdingId, permittedPortCos]);

  const navigablePortCoIds = useMemo(() => (
    !navigablePortCos?.length ?
      [] :
      navigablePortCos.map(portCo => portCo.PortCo_ID) || []
  ), [navigablePortCos]);

  useEffect(function redirectForHoldingId() {
    if (params.holdingId === "undefined") {
      history.replace(removeHolderFromPath(params));
    } else if (typeof params.holdingId === "string") {
      history.replace("/notFound");
    } else if (!params.holdingId) {
      if (history.location.pathname.includes("holding")) {
        history.replace(removeHolderFromPath(params));
      } else if (!permittedPortfolioHolders) {
        setIsHolderCoSelect(null);
      } else if (permittedPortfolioHolders.length === 1) {
        const onlyHolderId = permittedPortfolioHolders[0].PortfolioHolder_ID;
        history.replace(addHolderToPath(params, state, onlyHolderId));
      } else {
        setIsHolderCoSelect(true);
      }
    } else {
      setIsHolderCoSelect(false);
    }
  }, [history, params, permittedPortfolioHolders, state]);

  useEffect(function redirectForPortCoId() {
    const shouldWaitForHolder = isHolderCoSelect === null;
    if (shouldWaitForHolder || !navigablePortCoIds.length) {
      return;
    }

    const isPortCoActive = params.portCoId && params.portCoId !== "undefined";
    if (isPortCoActive && !navigablePortCoIds.includes(params.portCoId)) {
      return history.replace("/notFound");
    }
    if (isPortCoOnlyRole && !isPortCoActive) {
      if (navigablePortCoIds.length > 1) {
        setIsPortCoSelect(!isDashboardPage);
      } else {
        const redirectToId = navigablePortCoIds[0];
        history.push(addPortCoToPath(params, state, redirectToId));
      }
    } else {
      setIsPortCoSelect(false);
    }
  }, [
    history, isDashboardPage, isHolderCoSelect, isPortCoOnlyRole,
    params, navigablePortCoIds, state
  ]);

  const handlePortCoClick = useCallback(portCo => {
    history.push(addPortCoToPath(params, state, portCo.PortCo_ID));
  }, [history, params, state])

  if (isLoading || isHolderCoSelect === null || !permittedPortfolioHolders) {
    return <Loader />;
  } else if (isHolderCoSelect) {
    return (
      <SelectPortfolioHolder
        portfolioHolders={permittedPortfolioHolders}
      />
    );
  } else if (isPortCoSelect) {
    return (
      <SelectPortCo
        onClick={handlePortCoClick}
      />
    );
  }
  return children;
};

export default PortfolioComponentObjectWrapper;
