import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import { SelectSearchBar } from "components/search/searchBar.component";
import CustomLink from "components/utils/link.component";
import { Popover } from "@material-ui/core";
import SelectSearchResult from "components/search/selectResults/selectSearchResult.component";
import SearchIcon from "@material-ui/icons/Search";
import PulseLoader from "react-spinners/PulseLoader";
import { searchScopeTypes } from "utils/searchHelpers";

import SearchService from "services/search.service";
import { H5 } from "components/utils/headerV2.component";
import styleVariables from "styleVariables";
const searchServices = {
  [searchScopeTypes.SEARCH_TASKS_WORKPACKAGES]:
    SearchService.getTasksWorkpackagesByKeyword,
};

const useStyles = makeStyles((theme) => ({
  selectSearchContainer: {
    padding: 10,
    width: 400,
    display: "flex",
    flexDirection: "column",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  infoBar: {
    display: "flex",
    justifyContent: "space-between",
    padding: "4px 4px",
    boxShadow: variables.bottomBoxShadow,
  },
  validCount: {
    color: styleVariables.tertiary2Dark,
  },
  searchResultsBox: {
    height: 320,
    overflow: "scroll",
  },
  noResultsWrapper: {
    paddingTop: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  noResultsRow: {
    display: "flex",
  },
  searchingText: {
    paddingRight: 12,
    marginBottom: 55,
    color: variables.secondaryDark,
  },
  noResultsText: {
    color: variables.textSecondary,
  },
  linkWrapper: {
    width: "fit-content"
  }
}));

const NoResultsDisplay = ({ isSearching }) => {
  const classes = useStyles();
  return (
    <div className={classes.noResultsWrapper}>
      <div className={classes.noResultsRow}>
        {isSearching ? (
          <>
            <div className={classes.searchingText}>Searching</div>
            <PulseLoader size={7} color={variables.secondaryDark} />
          </>
        ) : (
          <div className={classes.noResultsText}>
            No search results to display
          </div>
        )}
      </div>
    </div>
  );
};

const SelectSearchResultsDialog = ({
  linkMessage,
  title,
  searchScopeType,
  onChange,
  selectedResults,
  disabledResults,
  hideResultsIds,
  resultPrimaryKey
}) => {
  const linkMessageRef = useRef(null);
  const [alertAnchorEl, setAlertAnchorEl] = useState(null);
  const classes = useStyles();

  const [textToSearch, setTextToSearch] = useState(""); // TEXT BEING SEARCHED
  const [searchInput, setSearchInput] = useState(""); // TEXT INPUTTED INTO SEARCH BAR
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const noResults = searchResults.length === 0;

  useEffect(() => {
    if (alertAnchorEl) {
      if (searchInput.length > 0) {
        const delay = 750;
        const delayFn = setTimeout(async () => {
          setIsSearching(true);
          setTextToSearch(searchInput);
          const searchResp = await searchServices[searchScopeType](searchInput);
          let searchResponse = searchResp.data.payload;
          if (hideResultsIds?.length && resultPrimaryKey) {
            const filteredSearchResponse = searchResponse.filter((searchItem) => (!hideResultsIds.includes(searchItem.data[`${resultPrimaryKey}`])));
            searchResponse = filteredSearchResponse;
          }
          setSearchResults(searchResponse);
          setIsSearching(false);
        }, delay);
        return () => clearTimeout(delayFn);
      } else {
        setSearchResults([]);
        setTextToSearch("")
      }
    }
  }, [searchInput, searchScopeType, alertAnchorEl, hideResultsIds, resultPrimaryKey]);

  const clearSearch = () => {
    setSearchInput("");
  };

  const searchHandler = (event) => {
    setSearchInput(event.target.value);
  };

  const openSearchDialog = (event) => {
    setAlertAnchorEl(event.currentTarget);
  };

  const handleCloseSearch = () => {
    setAlertAnchorEl(null);
    clearSearch();
  };

  return (
    <div>
      <Popover
        open={Boolean(alertAnchorEl)}
        anchorEl={alertAnchorEl}
        onClose={handleCloseSearch}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <div className={classes.selectSearchContainer}>
          <SelectSearchBar
            autoFocus
            placeholder="Search"
            changeHandler={searchHandler}
            textToSearch={textToSearch}
            clearSearch={clearSearch}
          />
          <div className={classes.contentWrapper}>
            <div className={classes.infoBar}>
              <H5>
                {title}
              </H5>
              <H5 color="tertiary" className={!noResults && classes.validCount}>
                {searchResults.length} matches
              </H5>
            </div>
            <div className={classes.searchResultsBox}>
              {isSearching || noResults ? (
                <NoResultsDisplay isSearching={isSearching} />
              ) : (
                searchResults.map((result) => {
                  return (
                    <SelectSearchResult
                      searchScopeType={searchScopeType}
                      result={result}
                      textToSearch={textToSearch}
                      onChange={onChange}
                      selectedResults={selectedResults}
                      disabledResults={disabledResults}
                      key={result.data.ID}
                    />
                  );
                })
              )}
            </div>
          </div>
        </div>
      </Popover>

      <div ref={linkMessageRef} className={classes.linkWrapper}>
        <CustomLink
          variant="iconBeforeLink"
          onClick={openSearchDialog}
          startIcon={<SearchIcon fontSize="small" />}
        >
          {linkMessage}
        </CustomLink>
      </div>
    </div>
  );
};

export default SelectSearchResultsDialog;
