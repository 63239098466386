import http from "./http-common";
import authHeader from "./authHeader";

class CertificationService {

  getCfrItems() {
    return http
      .get(`/certification/cfrs`, { headers: authHeader() })
      .then(response => response.data);
  }

  getLetterOfComplianceData() {
    return http
      .get(`/certification/letterOfCompliance/all`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getDctElementsByEnumeration(categoryId, enumeration) {
    return http
      .get(
        `/certification/dctCategory/${categoryId}/dctElement/enumeration/${enumeration}`,
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  getDctCategoryIndex() {
    return http
      .get(`/certification/dctCategory/index`, { headers: authHeader() })
      .then(response => response.data);
  }

  updateLetterOfComplianceData(locId, updatedLocItem) {
    return http.put(
      `/certification/letterOfCompliance/${locId}`,
      updatedLocItem,
      { headers: authHeader() }
    )
      .then(response => response.data)
  }

  upsertDctElementResponse(dctElementId, data) {
    return http
      .post(
        `/certification/dctElement/${dctElementId}/dctResponse/upsert`,
        data,
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  upsertDctQuestionResponse(dctQuestionId, data) {
    return http
      .post(
        `/certification/dctQuestion/${dctQuestionId}/dctResponse/upsert`,
        data,
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  getManualData() {
    return http
      .get(
        `/certification/manuals/all`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      });
  }

  replaceReferences(locId, body) {
    return http.post(
      `/certification/letterOfCompliance/${locId}/locReferences/replace`,
      body,
      { headers: authHeader() }
    )
      .then((response) => response.data);
  }

  getAllCFRData() {
    return http
      .get(`/certification/cfr/all`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getAllAircraft() {
    return http
      .get(`/certification/aircraft`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  upsertAircraft(data) {
    return http
      .post(
        `/certification/aircraft/upsert`,
        data,
        { headers: authHeader() }
      )
      .then((response) => response.data);
  }

  removeAircraft(aircraftId) {
    return http
      .delete(`/certification/aircraft/${aircraftId}`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  getManualCommentsByManualId(manualId) {
    return http
      .get(`/certification/manual/${manualId}/comments`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  createManualSectionComment(manualSectionId, data) {
    return http
      .post(
        `/certification/manual/section/${manualSectionId}/comment`,
        data,
        { headers: authHeader() }
      )
      .then((response) => response.data);
  }

  updateManualSectionComment(manualSectionCommentId, data) {
    return http
      .put(`/certification/manual/section/comment/${manualSectionCommentId}`, data , { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  resolveManualSectionComment(manualSectionCommentId) {
    return http
      .put(`/certification/manual/section/comment/${manualSectionCommentId}/resolve`, {}, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  removeManualSectionComment(manualSectionCommentId) {
    return http
      .delete(`/certification/manual/section/comment/${manualSectionCommentId}`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  getManual(manualId) {
    return http
      .get(`/certification/manual/${manualId}`, { headers: authHeader() })
      .then(response => response.data);
  }

  createManual(data) {
    return http
      .post(`/certification/manual`, data, { headers: authHeader() })
      .then(response => response.data);
  }

  updateManual(manualId, data) {
    return http
      .put(`/certification/manual/${manualId}`, data, { headers: authHeader() })
      .then(response => response.data);
  }

  getManualSection(sectionId) {
    return http
      .get(`/certification/manual/section/${sectionId}`, { headers: authHeader() })
      .then(response => response.data);
  }

  createSection(data, manualId) {
    return http.post(
      `/certification/manual/${manualId}/section`,
      data,
      { headers: authHeader() }
    )
      .then(response => response.data);
  }

  updateSection(sectionId, data) {
    return http.put(
      `/certification/manual/section/${sectionId}`,
      data,
      { headers: authHeader() }
    )
      .then(response => response.data);
  }

  reorderManualSection(data) {
    return http.put(
      `/certification/manual/section/reorder`,
      data,
      { headers: authHeader() }
    )
      .then(response => response.data);
  }

  deleteSection(sectionId) {
    return http.delete(
      `/certification/manual/section/${sectionId}`,
      { headers: authHeader() }
    )
      .then(response => response.data);
  }

  getManualsIndex() {
    return http
      .get(`/certification/manual/index`, { headers: authHeader() })
      .then(response => response.data);
  }

  getManualComments(manualId) {
    return http
      .get(`/certification/manual/${manualId}/comments`, { headers: authHeader() })
      .then(response => response.data);
  }

  deleteManualSectionComment(commentId) {
    return http
      .delete(
        `/certification/manual/section/comment/${commentId}`,
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  createManualSectionReference(sectionId, data) {
    return http
      .post(
        `/certification/manual/section/${sectionId}/reference`,
        data,
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  deleteManualSectionReference(manualSectionId, cfrId) {
    return http
      .delete(
        `/certification/manual/section/${manualSectionId}/cfr/${cfrId}/reference`,
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  getPasiGroups() {
    return http
      .get(`/certification/pasiGroups`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  upsertPasiDc(data, questionId) {
    return http
      .post(`/certification/pasiQuestion/${questionId}/upsertDC`, data, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  // ContactList getActiveContactsByProgramId
  getActiveContactsByProgramId(programId) {
    return http
      .get(`/certification/contacts/program/${programId}/allActive`, { headers: authHeader() })
      .then(response => response.data);
  }

  upsertContact(data) {
    return http
      .post(`/certification/contacts/upsert`, data, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

}

export default new CertificationService();
