import moment from "moment";
import { useMemo } from "react";
import { InfoOutlined } from "@material-ui/icons";
import AccordionListView from "components/utils/accordionListView.component";
import { groupObjectArrayByKey, mapObjectArrayByKey } from "utils/arrayOfObjectsHelpers";
import { FormControl, makeStyles, RadioGroup } from "@material-ui/core";
import classNames from "classnames";
import RadioInput from "components/utils/form-elements/radioInput.component";

const displayDateFormat = "dddd, MMMM D, YYYY";
const displayTimeFormat = "hh:mma";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%"
  },
  followUpHeading: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
    color: theme.palette.text.secondary,
    fontWeight: 600
  },
  followUpIcon: {
    marginRight: 8,
    fontSize: "1rem"
  },
  followUpList: {
    margin: 0,
    paddingLeft: 24
  },
  radioList: {
    paddingLeft: 0,
    listStyle: "none"
  },
  radioLabel: {
    marginLeft: -6,
    fontSize: "0.9rem"
  },
}));


export default function FollowUpsAccordion(props) {
  const classes = useStyles();
  const {
    defaultFollowUpId, followUps, formId = null, hasRadios,
    label, portfolioCompanies, sessions, showPortCoName = false,
  } = props;

  const followUpsBySessionId = useMemo(() => (
    groupObjectArrayByKey(followUps, "Session_ID")
  ), [followUps]);

  const portCoDisplayBySessionId = useMemo(() => {
    if (!showPortCoName) {
      return null;
    }
    const portCosById = mapObjectArrayByKey(portfolioCompanies, "PortCo_ID");
    return Object.fromEntries(sessions.map(session => (
      [session.Session_ID, portCosById[session.PortCo_ID]?.Name]
    )))
  }, [portfolioCompanies, sessions, showPortCoName]);

  const sessionsById = useMemo(() => (
    mapObjectArrayByKey(sessions, "Session_ID")
  ), [sessions]);

  const sessionDateDisplayById = useMemo(() => {
    return Object.fromEntries(
      Object.entries(sessionsById).map(([id, session]) => {
        const date = moment(session.Start).format(displayDateFormat);
        const startTime = moment(session.Start).format(displayTimeFormat);
        const endTime = moment(session.End).format(displayTimeFormat);
        return [
          id,
          <span key="datetime">{date}, {startTime}&nbsp;-&nbsp;{endTime}</span>
        ];
      })
    );
  }, [sessionsById]);

  const followUpAccordionOptions = useMemo(() => {
    if (!followUpsBySessionId) {
      return [];
    }
    return Object.entries(followUpsBySessionId).map(([id, followUpList]) => ({
      id,
      label: showPortCoName ?
        portCoDisplayBySessionId[id] :
        sessionDateDisplayById[id],
      sublabel: showPortCoName ? sessionDateDisplayById[id] : null,
      value: (
        <ul
          className={
            classNames(classes.followUpList, hasRadios && classes.radioList)
          }
        >
          {followUpList.map(followUp => (
            <li
              className={classNames(hasRadios && classes.radioListItem)}
              key={followUp.FollowUp_ID}
            >
              {hasRadios ? (
                <RadioInput
                  name={`follow-up|${followUp.FollowUp_ID}`}
                  label={followUp.Description}
                  value={`${followUp.FollowUp_ID}`}
                  radioLabelClass={classes.radioLabel}
                  inputProps={{
                    form: formId || undefined
                  }}
                />
              ) : followUp.Description}
            </li>
          ))}
        </ul>
      )
    })).sort((f1, f2) => f1.label.localeCompare(f2.label))
  }, [
    classes, followUpsBySessionId, formId, hasRadios,
    portCoDisplayBySessionId, sessionDateDisplayById, showPortCoName
  ]);

  const defaultExpandedSessionId = useMemo(() => {
    if (defaultFollowUpId) {
      const defaultFollowUp = followUps.find(followUp => (
        followUp.FollowUp_ID === defaultFollowUpId
      ));
      if (defaultFollowUp) {
        return `${defaultFollowUp.Session_ID}`;
      }
    }
    return undefined;
  }, [defaultFollowUpId, followUps]);

  return (
    <div>
      <div className={classes.followUpHeading} color="tertiary">
        {!hasRadios && (
          <InfoOutlined className={classes.followUpIcon} />
        )}
        <span>
          {label}
        </span>
      </div>
      {hasRadios ? (
        <FormControl className={classes.formControl} component="fieldset">
          <RadioGroup
            name="FollowUp_ID"
            defaultValue={defaultFollowUpId ? `${defaultFollowUpId}` : ""}
          >
            {!!hasRadios && (
              <RadioInput
                name="follow-up-none"
                label="None"
                value=""
                radioLabelClass={classes.radioLabel}
                inputProps={{
                  form: formId || undefined
                }}
              />
            )}
            <AccordionListView
              defaultExpanded={defaultExpandedSessionId}
              options={followUpAccordionOptions}
              testProp="follow-up"
            />
          </RadioGroup>
        </FormControl>
      ) : (
        <AccordionListView
          defaultExpanded={defaultExpandedSessionId}
          options={followUpAccordionOptions}
          testProp="follow-up"
        />
      )}
    </div>
  )
}