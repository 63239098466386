import { useMemo } from "react"
import CustomLink from "components/utils/link.component";
import Loader from "components/utils/loader.components";
import DataTable from "components/utils/tables/dataTable.component";
import {
  createNodeCellParams,
  createNodeHeaderParams,
  createValueHeaderParams
} from "components/utils/tables/utils/dataTableHelpers";
import { ReferenceTableCell } from "./referenceTableCell.component";
import { useCallback } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useNumericParams from "hooks/useNumericParams";
import { RequirementsLinksCell } from "./requirementsLinksCell.component";
import ObjectivesCell from "./objectivesCell.component";
import DisplayHTML from "components/utils/displayHTML.component";

const defaultCellStyle = { verticalAlign: "top" };

const tableHeaders = [
  createNodeHeaderParams("Requirement_ID", "ID", {
    filter: false,
    setCellProps: () => ({ style: { ...defaultCellStyle } }),
  }),
  createNodeHeaderParams("Description", "Requirement", {
    filter: false,
    sort: true,
    setCellProps: () => ({ style: { ...defaultCellStyle } }),
  }),
  createValueHeaderParams("Type", "Type", {
    setCellProps: () => ({ style: { ...defaultCellStyle } }),
  }),
  createNodeHeaderParams("RequirementReference", "Reference", {
    filter: false,
    sort: false,
    setCellProps: () => ({ style: { ...defaultCellStyle } }),
  }),
  createValueHeaderParams("Rationale", "Rationale", {
    setCellProps: () => ({ style: { ...defaultCellStyle } }),
  }),
  createNodeHeaderParams("Linked_Requirements", "Linked Requirements", {
    filter: false,
    sort: false,
    setCellProps: () => ({ style: { ...defaultCellStyle } }),
  }),
  createNodeHeaderParams("Objectives", "Compliance / Conformance Objectives", {
    filter: false,
    sort: false,
    setCellProps: () => ({ style: { ...defaultCellStyle } }),
  })
];

export default function RequirementTable(
  state
) {
  const { requirements } = state.state;
  const history = useHistory();
  const { programComponentId, programId } = useNumericParams();

  const goToEditRequirementForm = useCallback(async (requirementId) => {
    history.push(`/program/${programId}/${programComponentId}/requirementForm/${requirementId}`)
  }, [history, programId, programComponentId]);

  const tableCells = useMemo(() => {
    if (!requirements) {
      return null;
    }

    return requirements.map(requirement => {
      const requirementKey = requirement.Requirement_ID;
      const { Requirement_ID, Type, Rationale } = requirement;
      const references = requirement?._associations?.RequirementReference || [];
      const reqLinks = requirement?._associations?.RequirementLink || [];
      const objectives = requirement?._associations?.RequirementObjective || [];
      return {
        Requirement_ID: createNodeCellParams(
          Requirement_ID,
          null,
          <CustomLink
            key={requirementKey}
            variant="noHref"
            onClick={() => goToEditRequirementForm(requirement.Requirement_ID)}
            test={Requirement_ID}
          >
            {Requirement_ID}
          </CustomLink>
        ),
        Description: createNodeCellParams(
          requirement.Description,
          requirement.Description,
          <DisplayHTML key={requirementKey} html={requirement.Description} />
        ),
        Type,
        RequirementReference: createNodeCellParams(
          null,
          null,
          <ReferenceTableCell key={requirementKey} references={references} />
        ),
        Rationale,
        Linked_Requirements:createNodeCellParams(
          null,
          null,
          <RequirementsLinksCell key={requirementKey} reqLinks={reqLinks} />
        ),
        Objectives: createNodeCellParams(
          null,
          null,
          <ObjectivesCell key={requirementKey} objectives={objectives} />
        )
      }
    })
  }, [requirements, goToEditRequirementForm])

  if (!tableCells) {
    return <Loader />;
  }
  return (
    <DataTable
      data={tableCells}
      columns={tableHeaders}
      options={{
        filterType: "checkbox",
        fixedHeader: true,
        fixedSelectColumn: true,
        filter: true,
        pagination: false,
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        download: false,
        print: false,
        sortOrder: {order: "asc"}
      }}
    />
  )
}
