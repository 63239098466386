import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent } from "@material-ui/core";
import CustomLink from "components/utils/link.component";
import variables from "styleVariables";
import Logo from "components/utils/logo.component";
import classNames from "classnames";
import { generatePath, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { COMPONENT_ID_PORTFOLIO_ASSESSMENTS } from "utils/programConstants";
import useNumericParams from "hooks/useNumericParams";
import { mapObjectArrayByKey } from "utils/arrayOfObjectsHelpers";
import { H3, H6 } from "components/utils/headerV2.component";

const useStyles = makeStyles((theme) => ({
  card: {
    margin: 8,
    flex: 1,
    maxWidth: "30%",
    minWidth: "30%",
    minHeight: 235,
    position: "relative",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      minWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "47%",
      minWidth: "47%",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "30%",
      minWidth: "30%",
    },
  },
  wideCard: {
    margin: 8,
    flex: 1,
    height: 235,
    position: "relative",

    "&:first-of-type": {
      marginLeft: 0
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      minWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "70%",
      minWidth: "70%",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "44%",
      minWidth: "44%",
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: "30%",
      minWidth: "30%",
    },
  },
  cardUnclickable: {
    cursor: "default"
  },
  cardContent: {
    display: "flex",
    position: "relative",
    padding: 30,
    paddingBottom: 30,
    height: "100%",
    alignItems: "center",
    cursor: "default",
  },
  infoWrapper: {
    paddingLeft: 40,
    minWidth: 150,
    alignItems: "center",
    display: "flex",
    flex: 1,
    height: "100%",
  },
  defaultInfoWrapper: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  logoWrapper: {
    display: "flex",
    maxWidth: 270,
    maxHeight: 160,
    [theme.breakpoints.down("lg")]: {
      maxHeight: 130,
    },
  },
  logo: {
    maxHeight: "inherit",
    maxWidth: "inherit",
    height: "100%",
    width: "100%",
    display: "block",
    objectFit: "contain",
  },
  assessmentName: {
    display: "block",
    marginTop: 8,
    fontSize: 14,
    fontWeight: 600,
  },
  assessmentNoLink: {
    color: theme.palette.text.primary,
  },
  bodyRow: {
    display: "inline-flex",
    alignItems: "center",
    flexWrap: "wrap"
  },
  bodyHeader: {
    paddingRight: 8,
    fontSize: variables.fontMedium,
  },
  bodyText: {
    display: "inline-block",
    fontSize: variables.fontMedium,
    color: variables.tertiary1,
  },
  assessmentSection: {
    paddingBottom: 5,
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  cardLinkOverlay: {
    height: "100%",
    width: "100%",
    backgroundColor: "none",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 0,
  },
  specificLink: {
    position: "relative",
    zIndex: 1
  }
}));

const PortCoCard = ({
  portCo,
  sessions,
  children,
  onClick,
  logoWrapperClassProp,
  wideCard,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useNumericParams();
  const [isHovered, setIsHovered] = useState(false);

  const defaultSessionsByAssessment = useMemo(() => (
    mapObjectArrayByKey(
      [...(sessions || [])]
        .reverse()
        .filter(({ Type }) => Type === "Data Collection"),
      "HolderAssessment_ID"
    )
  ), [sessions]);

  const holderAssessments = useMemo(() => (
    portCo?._associations?.HolderAssessments || []
  ), [portCo]);

  const assessmentStatuses = useMemo(() => (
    portCo?._associations?.PortCoStatuses || []
  ), [portCo]);

  const statusByAssessment = useMemo(() => (
    mapObjectArrayByKey(assessmentStatuses, "HolderAssessment_ID")
  ), [assessmentStatuses]);

  if (!params.holdingId || !defaultSessionsByAssessment) {
    return null;
  }
  return (
    <Card
      className={classNames(
        wideCard && classes.wideCard,
        !wideCard && classes.card,
        !onClick && classes.cardUnclickable,
      )}
      elevation={isHovered ? 3 : 1}
      onMouseEnter={() => onClick && setIsHovered(true)}
      onMouseLeave={() => onClick && setIsHovered(false)}
      data-cy={`portCo-card-id-${portCo.PortCo_ID}`}
    >
      <CardContent className={classes.cardContent}>
        {!!onClick && (
          <CustomLink
            className={classes.cardLinkOverlay}
            variant="underline"
            onClick={onClick}
            ignoreReadOnly
          />
        )}
        <div
          className={classNames(classes.logoWrapper, logoWrapperClassProp)}
          data-cy={`portCo-logo-${portCo.PortCo_ID}`}
        >
          <Logo
            className={classes.logo}
            logoId={portCo.Logo}
            alt={portCo.Name ? `${portCo.Name} Logo` : "Portfolio Company Logo"}
          />
        </div>
        {!children && (
          <div
            className={classNames(
              classes.infoWrapper,
              classes.defaultInfoWrapper
            )}
          >
            <H3>{portCo.Name}</H3>
            {/*  ======== LIST OF ASSESSMENTS PER PORTCO ======== */}
            <div className={classes.assessmentSection}>
              {holderAssessments.map((holderAssessment) => {
                const assessmentId = holderAssessment.HolderAssessment_ID;
                const portCoStatus = statusByAssessment[assessmentId];
                const firstSession = defaultSessionsByAssessment[assessmentId];
                const sessionPath = !!firstSession && generatePath(
                  "/program/:programId/:programComponentId/holding/:holdingId/portCo/:portCoId/assessment/:assessmentId/session/:sessionId",
                  {
                    ...params,
                    programComponentId: COMPONENT_ID_PORTFOLIO_ASSESSMENTS,
                    portCoId: portCo.PortCo_ID,
                    assessmentId,
                    sessionId: firstSession.Session_ID
                  }
                );
                return (
                  <div key={assessmentId}>
                    <div className={classes.assessmentName}>
                      {sessionPath ? (
                        <CustomLink
                          className={classNames(classes.assessmentName, classes.specificLink)}
                          href={sessionPath}
                          onClick={event => {
                            event.preventDefault();
                            history.push(sessionPath);
                          }}
                          variant="customUnderline"
                          ignoreReadOnly
                        >
                          {holderAssessment.Assessment_Name}
                        </CustomLink>
                      ) : (
                        <div
                          className={
                            classNames(classes.assessmentName, classes.assessmentNoLink)
                          }
                        >
                          {holderAssessment.Assessment_Name}
                        </div>
                      )}
                    </div>
                    <div className={classes.bodyRow}>
                      <H6 color="tertiary" className={classes.bodyHeader}>
                        Status:{" "}
                      </H6>
                      {portCoStatus?.Status !== "Report Published" ? (
                        <span className={classes.bodyText}>
                          {sessionPath ?
                            portCoStatus?.Status :
                            "No Sessions Scheduled"
                          }
                        </span>
                      ) : (
                        <CustomLink
                          className={classNames(classes.status, classes.specificLink)}
                          variant="underline"
                        >
                          Report Published
                        </CustomLink>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {!!children && (
          <div
            className={classes.infoWrapper}
            data-cy={`card-content-${portCo.Name}`}
          >
            {children}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default PortCoCard;
