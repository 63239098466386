import defaultPlugins from 'suneditor/src/plugins';
import CustomSunEditor, { defaultButtons } from "components/utils/sunEditor.component";
import useCfrLinkPlugin from "hooks/sunEditorPlugins/useCfrLinkPlugin";
import { useCallback, useMemo, useState } from 'react';
import CfrReferenceModal from '../modals/cfrReferenceModal.component';
import LabelInput from 'components/utils/form-elements/labelInput.component';
import { getLongCfrLabel } from 'utils/cfrUtils';

const CFR_TEXT_INPUT_NAME = "cfr-button-text";

const sectionButtons = [
  ...defaultButtons,
  ["cfrLink"]
];

export default function SectionSunEditor(props) {
  const {
    defaultValue, height, name, label, section, setOptions, state, dispatch, ...sunEditorProps
  } = props;
  const [
    isCfrModalOpen,
    setIsCfrModalOpen /* Do not use outside of useCfrLinkPlugin */
  ] = useState(false);
  const [openModalCfrId, setOpenModalCfrId] = useState(null);
  const [openModalCfrText, setOpenModalCfrText] = useState(null);

  const [cfrLinkPlugin, afterSave, handleSetModalOpen] = useCfrLinkPlugin(
    setIsCfrModalOpen,
    setOpenModalCfrId,
    setOpenModalCfrText
  );

  const checkedIds = useMemo(() => (
    [].concat(...[openModalCfrId || []])
  ), [openModalCfrId]);

  const checkedCfrDefaultLabel = useMemo(() => (
    openModalCfrId && state.cfrItemsByIdFlattened ?
      getLongCfrLabel(openModalCfrId, state.cfrItemsByIdFlattened) :
      ""
  ), [state.cfrItemsByIdFlattened, openModalCfrId]);

  const defaultActiveCfrItem = useMemo(() => (
    state.cfrItemsByIdFlattened?.[openModalCfrId]
  ), [state.cfrItemsByIdFlattened, openModalCfrId]);

  const sunEditorOptions = useMemo(() => ({
    ...(sunEditorProps?.setOptions || {}),
    ...setOptions,
    buttonList: sectionButtons,
    plugins: { ...defaultPlugins, cfrLink: cfrLinkPlugin }
  }), [cfrLinkPlugin, setOptions, sunEditorProps?.setOptions]);

  const handleClose = useCallback(() => {
    handleSetModalOpen(false);
    setOpenModalCfrId(null);
    setOpenModalCfrText(null);
  }, [handleSetModalOpen, setOpenModalCfrId, setOpenModalCfrText]);

  const handleCfrChange = useCallback((_event, cfrId) => {
    setOpenModalCfrId(cfrId);
  }, [setOpenModalCfrId]);

  const handleSubmit = useCallback(async (cfrIds, formData, event) => {
    event.stopPropagation();
    if (!state.cfrItemsByIdFlattened || !cfrIds[0]) {
      return;
    }

    const id = parseInt(cfrIds[0], 10);
    const text = formData[CFR_TEXT_INPUT_NAME];
    await afterSave(id, text, state.cfrItemsByIdFlattened);

    handleClose();
  }, [afterSave, handleClose, state.cfrItemsByIdFlattened]);

  if (!state.cfrItems) {
    return null;
  }
  return (
    <>
      <CustomSunEditor
        {...sunEditorProps}
        name={name}
        label={label}
        defaultValue={defaultValue}
        height={height}
        setOptions={sunEditorOptions}
      />
      {!!state.cfrItems && (
        <CfrReferenceModal
          open={isCfrModalOpen}
          onClose={handleClose}
          onChange={handleCfrChange}
          onSubmit={handleSubmit}
          checkedCfrIds={checkedIds}
          defaultActiveCfrItem={defaultActiveCfrItem}
          dispatch={dispatch}
          state={state}
          isRadiosMode
        >
          <LabelInput
            name={CFR_TEXT_INPUT_NAME}
            label="Display Text"
            defaultValue={openModalCfrText}
            placeholder={checkedCfrDefaultLabel}
            variant="default"
            margin="dense"
          />
        </CfrReferenceModal>
      )}
    </>
  )
}
