import { Box, Divider, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import AddListItem from "components/utils/addListItem.component";
import Header from "components/utils/header.component";
import { useCallback, useMemo, useState } from "react";
import {
  ACTION_REPLACE_DOCUMENT,
  ACTION_CREATE_DOCUMENT,
  ACTION_DELETE_DOCUMENT,
} from "reducers/portfolioAssessments.reducer";
import PortfolioService from "services/portfolio.service";
import variables from "styleVariables";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { downloadFileByRef } from "utils/downloadFile";
import CustomLink from "components/utils/link.component";
import moment from "moment";
import FollowUpsAccordion from "components/portfolio/shared/followUpsAccordion.component";
import DocumentEditForm, { DOCUMENT_FORM_NAME } from "../../shared/documentEditForm.component";
import { H4 } from "components/utils/headerV2.component";
import { followUpTypes } from "utils/portfolioConstants";
import { getUploadHttpErrorMessage } from "services/http-common";


const useStyles = makeStyles((theme) => ({
  documentList: {
    listStyle: "none",
    padding: 0,
    marginBottom: 0
  },
  documentListEmpty: {
    marginBottom: 16
  },
  documentListItem: {
    padding: "15px 20px",
    "&:not(:last-of-type)": {
      borderBottom: `1px solid ${variables.rowBorder}`,
    },
  },
  documentAddSection: {
    display: "flex",
    justifyContent: "end",
    paddingTop: 24,
    paddingRight: 10
  },
  documentRowClickable: {
    cursor: "pointer",
  },
  documentItemWrapper: {
    [theme.breakpoints.between(1800, "xl")]: {
      display: "flex",
    },
    alignItems: "center",
    minHeight: 40,
  },
  customLink: {
    display: "inline-flex",
    alignItems: "center",
    lineHeight: "normal",
    position: "relative",
    textIndent: 24,
    fontSize: variables.fontMedium,
  },
  downloadIcon: {
    fontSize: "1rem",
    position: "absolute",
    left: 0,
    top: "-1px",
  },
}));

const NEW_DOCUMENT = "new";


export default function SessionDocumentsPanel(props) {
  const classes = useStyles();
  const {
    dispatch, documents, isReadOnly, portfolioCompany, session, state, assessmentStatus
  } = props;

  const [editingDocument, setEditingDocument] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [chosenFile, setChosenFile] = useState();
  const [isSaving, setIsSaving] = useState(false);

  const portCoPreviousSessions = useMemo(() => (
    assessmentStatus._associations.AssessmentSession.filter(otherSession => (
      session.PortCo_ID === otherSession.PortCo_ID &&
      session.Session_ID !== otherSession.Session_ID &&
      moment(otherSession.End).isBefore(session.End)
    ))
  ), [assessmentStatus, session]);

  const portCoFollowUps = useMemo(() => (
    portCoPreviousSessions.flatMap(currentSession => (
      currentSession._associations.SessionFollowUps || []
    )).filter(followUp => (
      followUp.Type === followUpTypes.DOCUMENT &&
      followUp.Status === "Active" &&
      followUp.Session_ID !== session.Session_ID
    ))
  ), [portCoPreviousSessions, session.Session_ID]);

  const documentUpload = (event) => {
    setChosenFile(event.target.files[0])
  }

  const handleNewClick = useCallback(() => {
    setEditingDocument(NEW_DOCUMENT);
    setChosenFile();
    setFormErrors({});
  }, [setEditingDocument, setChosenFile, setFormErrors]);

  const handleEditClick = useCallback((document) => {
    setEditingDocument(document);
    setChosenFile();
    setFormErrors({});
  }, [setEditingDocument, setChosenFile, setFormErrors]);

  const handleCancelEdit = useCallback(() => {
    setEditingDocument(null);
  }, [setEditingDocument]);

  const handleDelete = useCallback(async (docId) => {
    const deletedResponse = await PortfolioService.deleteSupportingDoc(docId);
    if (deletedResponse.payload) {
      dispatch({
        type: ACTION_DELETE_DOCUMENT,
        payload: deletedResponse.payload,
        meta: deletedResponse.meta,
      });
    } else {
      console.error(`No document found in db? ID: "${docId}"`);
    }
    setEditingDocument(null);
  }, [dispatch, setEditingDocument]);

  const handleSubmit = useCallback(async (data, formData) => {
    try {
      formData.delete("single-file-upload");
      if (!formData.get("FollowUp_ID")) {
        formData.delete("FollowUp_ID");
      }

      if (chosenFile) {
        formData.append("file", chosenFile, chosenFile.name);
      }
      if (editingDocument?.Document_ID) {
        formData.set("Document_ID", editingDocument?.Document_ID);
      }
      formData.set("AssessmentStatus_ID", assessmentStatus.AssessmentStatus_ID)
      if (chosenFile) {
        formData.set("File_Ref", chosenFile.name);
      }

      const documentResponse = await PortfolioService.upsertSupportingDoc(
        session.Session_ID,
        formData
      )

      const type = editingDocument?.Document_ID ?
        ACTION_REPLACE_DOCUMENT :
        ACTION_CREATE_DOCUMENT;

      dispatch({
        type,
        payload: documentResponse.payload,
        meta: documentResponse.meta,
      });
      setEditingDocument(null);

    } catch (error) {
      const { status } = error.response || {};
      setFormErrors({ File_Name: getUploadHttpErrorMessage(status) })
      setIsSaving(false);
      setEditingDocument("new");
    }

  }, [
    dispatch,
    editingDocument,
    session,
    chosenFile,
    assessmentStatus,
    setEditingDocument,
  ]);

  const isEmptyList = useMemo(() => (
    !documents?.length && editingDocument !== NEW_DOCUMENT
  ), [documents, editingDocument]);

  return (
    <div>
      <Box marginTop={6}>
        <Header variant="h3Primary">Documents</Header>
      </Box>
      <ul
        data-cy="session-documents"
        className={
          classNames(classes.documentList, isEmptyList && classes.documentListEmpty)
        }
      >
        {isEmptyList ? (
          <li>No documents specified for this session.</li>
        ) : (
          documents?.map((document) => (
            <li
              className={classes.documentListItem}
              key={document.Document_ID}
              data-cy={`session-doc-${document.Document_Title}`}
            >
              {editingDocument?.Document_ID === document.Document_ID ? (
                <DocumentEditForm
                  document={editingDocument}
                  onCancel={handleCancelEdit}
                  errors={formErrors}
                  setErrors={setFormErrors}
                  chosenFile={chosenFile}
                  documentUpload={documentUpload}
                  onSubmit={handleSubmit}
                  onDelete={() => handleDelete(document.Document_ID)}
                  isSaving={isSaving}
                  setIsSaving={setIsSaving}
                />
              ) : (
                <div
                  className={classNames(!isReadOnly && classes.documentRowClickable)}
                  data-cy={`documentRowClickable-${document.Document_ID}`}
                  onClick={() => {
                    if (isReadOnly) {
                      return;
                    }
                    handleEditClick(document)
                  }}
                  role="button"
                  tabIndex="0"
                >
                  <div className={classes.documentItemWrapper}>
                    <Box flex={2} paddingBottom={1} paddingTop={1} paddingRight={3} alignItems="start" display="flex">
                      <CustomLink
                        onClick={(event) => {
                          event.stopPropagation();
                          downloadFileByRef(
                            document.File_Ref,
                            document.File_Ref
                          );
                        }}
                        className={classes.customLink}
                      >
                        {document.Document_Title}
                        <CloudDownloadIcon className={classes.downloadIcon} />
                      </CustomLink>
                    </Box>
                    <Box flex={1} paddingBottom={1} paddingTop={1} paddingRight={2}>
                      Version {document.Version}
                    </Box>
                    <Box paddingBottom={1} paddingTop={1}>
                      {moment(document.Publish_Date).format("MM/DD/YYYY")}
                    </Box>
                  </div>
                </div>
              )}
            </li>
          ))
        )}
        {!isReadOnly && (
          <li>
            {editingDocument === NEW_DOCUMENT ? (
              <div className={classes.documentListItem}>
                {!!portCoFollowUps.length && (
                  <Box marginTop={2}>
                    <H4>Add Document</H4>
                    <Box marginTop={2} marginBottom={2} paddingRight={2}>
                      <FollowUpsAccordion
                        followUps={portCoFollowUps}
                        formId={DOCUMENT_FORM_NAME}
                        label="Follow-up from previous session"
                        portfolioCompanies={[portfolioCompany]}
                        sessions={portCoPreviousSessions}
                        state={state}
                        hasRadios
                      />
                      <Box marginTop={4} marginBottom={2}>
                        <Divider />
                      </Box>
                    </Box>
                  </Box>
                )}
                <DocumentEditForm
                  onCancel={handleCancelEdit}
                  onSubmit={handleSubmit}
                  errors={formErrors}
                  setErrors={setFormErrors}
                  chosenFile={chosenFile}
                  documentUpload={documentUpload}
                  isSaving={isSaving}
                  setIsSaving={setIsSaving}
                />
              </div>
            ) : (
              <div
                className={classNames(
                  classes.documentListItem,
                  classes.documentAddSection,
                )}
              >
                <AddListItem onClick={handleNewClick} test="document">Add Document</AddListItem>
              </div>
            )}
          </li>
        )}
      </ul>
      {!!portCoFollowUps.length && !editingDocument && (
        <Box marginTop={2}>
          <Box marginTop={2} marginBottom={4}>
            {!!isEmptyList && (
              <Divider />
            )}
          </Box>
          <FollowUpsAccordion
            followUps={portCoFollowUps}
            label="Unresolved document follow-ups from previous sessions:"
            portfolioCompanies={[portfolioCompany]}
            sessions={portCoPreviousSessions}
            state={state}
          />
        </Box>
      )}
    </div>
  );
}
