import React from "react";

export const initialContext = { errors: {}, values: {}, publishFormChange: null };

/*
 * Shared state belonging to the current <Form/> element, such as validation errors.
 *
 * Example data:
 * {
 *   errors: {
 *     Start_Date: {
 *       message: "Must be 01/01/2020 or later.",
 *       validations: {...},
 *       value: "01/01/2019"
 *     }
 *   },
 *   // Passively updated field values. Can be controlled or uncontrolled.
 *   values: {
 *     Start_Date: "01/01/2019"
 *   },
 *   // Set by Form prop
 *   validations: {
 *     fieldName: [{ type: VALIDATION_REQUIRED }]
 *   }
 *   // Manually update form context
 *   publishFormChange: function(context, name, value) {
 *     setFormContextState({ ...context,  values: { ...context.values, [name]: value }})
 *   },
 *   publishFormError: function(context, name, error) {
 *     setFormContextState({ ...context,  errors: { ...context.errors, [name]: error }})
 *   }
 * }
 */
const FormContext = React.createContext(initialContext);

export default FormContext;