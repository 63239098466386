import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LabelInput from "components/utils/form-elements/labelInput.component";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import ImplementationService from "services/implementation.service";
import FormBanner from "components/utils/form-elements/formBanner.component";
import FormSubHeader from "components/utils/form-elements/formSubHeader.component";
import Form from "components/utils/form-elements/form.component";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: 20,
  },
  formContent: {
    width: 760,
    align: "center",
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  form: {
    paddingTop: 10,
  },
  labelInputsContainer: {
    padding: "5px 3px 0px 3px",
    maxHeight: 430,
    overflowY: "scroll",
    marginBottom: 10
  },
  labelInput: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 15,
  },
  input: {
    width: 600,
  },
}));

const DataInventoryForm = (props) => {
  const classes = useStyles();
  const [formData, setFormData] = useState([]);
  const [formObj, setFormObj] = useState({});
  const [dataMapObj, setDataMapObj] = useState({});
  const [allInfo, setAllInfo] = useState();
  const [column, setColumn] = useState();
  const [dataMapIds, setDataMapIds] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    ImplementationService.getProductDataMap(props.productId).then((res) => {
      setAllInfo(res.payload);
      const data = [];
      let obj = {};
      const columnName = props.columnName.split("/").join("_");
      setColumn(columnName);

      res.payload.forEach((info) => {
        // obj which gets updated onChange
        obj = {
          ...obj,
          [info.DataType_ID.toString()]: info[columnName],
          DataMap_ID: info.DataMap_ID,
          DataType_ID: info.DataType_ID,
        };
        //array formed to map text fields
        data.push({
          formFieldId: info.DataType_ID.toString(),
          Label: info.Type_Name,
          Value: info[columnName],
          DataType_ID: info.DataType_ID,
          DataMap_ID: info.DataMap_ID,
          Change_Date: info.Change_Date,
          Program_Program_ID: info.Program_Program_ID,
        });
      });
      setFormData(data);
      setFormObj(obj);
    });
  }, [props.productId, props.columnName]);

  const handleChange = event => {
    const { target } = event;
    const dataTypeId = parseInt(target.name);

    setFormObj({
      ...formObj,
      [target.name]: target.value,
    });
    // collect DataMap_IDs for update API
    if (!dataMapIds.includes(dataTypeId)) {
      setDataMapIds([...dataMapIds, dataTypeId]);
    }
    // isolate specific row from allInfo
    let row = allInfo.filter((info) => info.DataType_ID === dataTypeId)[0];
    row = {
      ...row,
      [column]: target.value,
    };
    // create object where key is DataMap_ID and value is array to be passed to save API
    setDataMapObj({
      ...dataMapObj,
      [dataTypeId]: [
        row.Program_Program_ID,
        row.GroupProducts_OrganizationGroups_Group_ID,
        row.GroupProducts_GroupProduct_ID,
        row.DataTypes_DataType_ID,
        row.Creation_Capture,
        row.Storage,
        row.Transmission,
        row.Use,
        row.Sharing,
        row.Archive,
        row.Destruction,
      ],
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSaving(true);

    // Remap form data array back to table row format
    const mappedData = Object.entries(dataMapObj).filter(([id, valueArray]) => (
      id && valueArray?.length
    )).map(([id, valueArray]) => ({
      DataTypes_DataType_ID: id,
      Program_Program_ID: valueArray[0],
      GroupProducts_OrganizationGroups_Group_ID: valueArray[1],
      GroupProducts_GroupProduct_ID: valueArray[2],
      Creation_Capture: valueArray[4],
      Storage: valueArray[5],
      Transmission: valueArray[6],
      Use: valueArray[7],
      Sharing: valueArray[8],
      Archive: valueArray[9],
      Destruction: valueArray[10],
      Status: valueArray[11]
    }));

    await ImplementationService.batchUpsertDataMap(props.productId, mappedData);

    // reload table and close modal
    await props.loadTable(props.productId)
    props.setEditModal(false);
    setIsSaving(false);
  };

  if (formData.length && formObj) {
    return (
      <div className={classes.formContainer} data-cy="formContainer-dataInventory">
        <FormBanner>
          {`Data ${props.columnName} - Data Inventory Update`}
        </FormBanner>
        <div className={classes.formContent}>
          <FormSubHeader variant="groupProduct" product={props.product} group={props.group} />
          <Form name="data-inventory" className={classes.form}>
            <div className={classes.labelInputsContainer}>
              {formData.map((item) => (
                <div className={classes.labelInput} key={item.formFieldId}>
                  <div className={classes.input}>
                    <LabelInput
                      variant="default"
                      label={item.Label}
                      multiline
                      value={formObj[item.formFieldId]}
                      id={item.formFieldId}
                      name={item.formFieldId}
                      onChange={handleChange}
                      test={item.Label}
                    />
                  </div>
                </div>
              ))}
            </div>
            <DualFormButtons
              cancelOnClick={() => props.setEditModal(false)}
              saveOnClick={handleSubmit}
              disabled={dataMapIds.length < 1}
              isSaving={isSaving}
            />
          </Form>
        </div>
      </div>
    );
  } else {
    return "";
  }
};

export default DataInventoryForm;
