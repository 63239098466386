import React from "react";
import orchestrationLogo from "images/orchestration-logo.png";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    height: 80,
    width: "100%",
    display: "block",
    backgroundImage: "url(" + orchestrationLogo + ")",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    marginBottom: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const OrchLogo = (props) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.logoContainer} onClick={() => history.push("/")} />
  );
};

export default OrchLogo;
