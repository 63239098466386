import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import RemoveIcon from "components/utils/removeIcon.component";
import TextFieldPlain from "components/utils/form-elements/textFieldPlain.component";
import variables from "styleVariables";
import classNames from "classnames";
import { isReadOnly } from "utils/roles";
import PanToolIcon from "@material-ui/icons/PanTool";

const useStyles = makeStyles((theme) => ({
  orderedItem: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    height: "fit-content",
    alignItems: "center",
    "&:hover": {
      "& $removeButton": {
        opacity: 1,
      },
    },
  },
  listNumber: {
    fontWeight: "bold",
    paddingRight: 3,
    paddingLeft: 3,
    fontSize: variables.fontMedium,
  },
  moveIcon: {
    height: 12,
  },
  providedPadding: {
    fontSize: 10,
    width: "100%",
  },
  orderedItemText: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    paddingRight: 16,
    fontSize: variables.fontSmall,
    lineHeight: "normal",
  },
  reorderWrapper: {
    display: "flex",
    alignItems: "center",
    width: 30,
    "&:hover": {
      "& $moveIcon": {
        fill: variables.tertiary2,
      },
    },
  },
  errorText: {
    color: theme.palette.text.error,
  },
  removeButton: {
    opacity: 0,
    height: 17,
    marginRight: 6,
  },
}));

const EditableText = ({
  provided,
  cellItem,
  removeClick,
  // All props below are only needed for edit
  mode,
  textFieldId,
  textFieldName,
  onBlur,
  editClick,
  initialEditValue,
  error,
  hoverHighlight,
  index,
  onKeyDown,
  disableRemove,
  tooltipId,
  tooltipRemoveMessage,
  editItemId,
  onAutosave,
  maxLength,
}) => {
  const classes = useStyles();
  const [editText, setEditText] = useState(initialEditValue);
  const readOnlyMode = isReadOnly();

  const handleEditChange = (event) => {
    setEditText(event.target.value);
  };

  return (
    <div
      className={classNames(
        classes.orderedItem,
        hoverHighlight && classes.hoverHighlight
      )}
      /* eslint-disable-next-line react/jsx-no-leaked-render */
      ref={provided && provided.innerRef}
      {...(provided && provided.draggableProps)}
    >
      {mode !== "edit" && !provided && (
        <>
          <span
            onClick={!readOnlyMode && editClick}
            data-cy={`trigger-edit-${cellItem}`}
            key={editText || cellItem}
          >
            {cellItem}
          </span>
          <div className={classes.removeButton}>
            <RemoveIcon
              onClick={removeClick}
              disabled={disableRemove}
              tooltipId={tooltipId}
              tooltipRemoveMessage={tooltipRemoveMessage}
              test={`remove-editable-text-${cellItem}`}
            />
          </div>
        </>
      )}

      {/* eslint-disable-next-line react/jsx-no-leaked-render */}
      {mode !== "edit" && provided && (
        <>
          <div
            className={
              classNames(classes.orderedItemText, error && classes.errorText)
            }
          >
            <span
              {...(provided && provided.dragHandleProps)}
              data-cy={`draggable-icon-${cellItem}`}
              className={classes.reorderWrapper}
            >
              <PanToolIcon className={classes.moveIcon} />
            </span>

            <span className={classes.listNumber}>{`${index + 1}.`}</span>
            <span
              className={classes.editText}
              role="button"
              onClick={!readOnlyMode && editClick}
              data-cy={`trigger-edit-${cellItem}`}
              key={editText || cellItem}
            >
              {editText || cellItem}
            </span>
          </div>{" "}
          <div className={classes.removeButton}>
            <RemoveIcon
              onClick={removeClick}
              test={`remove-editable-text-${cellItem}`}
            />
          </div>
        </>
      )}
      {mode === "edit" && (
        <div className={classNames(provided && classes.providedPadding)}>
          {/* <div className={classNames(provided && classes.providedPadding)}> */}
          <TextFieldPlain
            id={textFieldId}
            onChange={handleEditChange}
            value={editText}
            autoFocus
            onBlur={onBlur ? () => onBlur(editText, editItemId) : undefined}
            onAutosave={onAutosave ? () => onAutosave(editText, editItemId) : undefined}
            test={`focused-edit-item-${editText}`}
            onKeyDown={(event) => onKeyDown(event, editText, editItemId)}
            name={textFieldName || "editable-text"}
            maxLength={maxLength}
          />
        </div>
      )}
    </div>
  );
};

export default EditableText;
