import React, { useMemo } from "react";
import { FormControl, TextField } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import FormLabel from "components/utils/form-elements/formLabel.component";
import { isReadOnly } from "utils/roles";
import classNames from "classnames";
import FormElementBase from "components/utils/form-elements/shared/formElementBase.component";

const useStyles = makeStyles((theme) => ({
  formControlRoot: {
    width: "100%",
  },
  formControlRootDatePicker: {
    "& .MuiFormControl-marginDense": {
      marginBottom: 0,
    }
  },
  placeholderVisible: {
    color: "rgba(0, 0, 0, 0.5)"
  },
  datepickerErrorlessFixedHeight: {
    marginBottom: 15,
  },
  datepickerHelperFixedHeight: {
    color: "white!important",
    fontSize: variables.fontXxs,
    fontWeight: "bold",
    textAlign: "right",
    backgroundColor: variables.warningMain,
    borderRadius: "0px 0px 3px 3px",
    padding: "1px 5px 0px 0px",
    margin: "-2px 0px 0px 0px",
    height: 17,
    width: "100%",
  },
  datepickerHelper: {
    color: "white!important",
    fontSize: variables.fontXxs,
    fontWeight: "bold",
    textAlign: "right",
    backgroundColor: variables.warningMain,
    borderRadius: "0px 0px 3px 3px",
    padding: "1px 5px 0px 0px",
    margin: "-2px 0px 0px 0px",
    height: 17,
    width: "100%",
  },
  readOnly: {
    '& .MuiInputBase-input.Mui-disabled': {
      color: "#000"
    }
  }
}));

const OutlinedDatePicker = withStyles({
  root: {
    '& input:valid + fieldset': {
      borderColor: variables.secondaryMain,
      borderWidth: 2,
    },
    '& input:hover': {
      cursor: "text !important",
    },
    '& input:invalid + fieldset': {
      borderColor: `${variables.warningLight} !important;`,
      borderWidth: 2,
    },
    '& input:valid:focus + fieldset': {
      borderLeftWidth: 6,
      padding: '4px !important',
    },
    '&:hover': {
      borderColor: variables.primaryMain,
      '& input:valid + fieldset': {
        borderColor: variables.primaryMain,
      },
    },
    '& input:disabled': {
      color: variables.textPrimary,
    },
    '& input:disabled + fieldset': {
      borderWidth: 2,
    },
  },
})(TextField);

const TimeInput = (props) => {
  const { onAutosave, onChange, ...otherProps } = props;

  /*
   * Prevent bug caused by native browser date input pickers blocking the case of:
   * 1) Select date from date picker
   * 2) Click on nearby checkbox without blurring date picker input beforehand
   * 3) Checkbox change event will not fire
   * 4) Checkbox value will not save, and user event will be undone as a result.
   *
   * In most cases, selecting a date by mouse is the onChange reason.
   * A special onKeyDown handler to block autosave could be a future option if this causes issues.
  */
  const controlledProps = useMemo(() => {
    if (!onChange && onAutosave) {
      return {
        onAutosave: undefined,
        onChange: event => onAutosave(event.target.name, event.target.value, event)
      };
    }
    return { onAutosave, onChange };
  }, [onAutosave, onChange]);

  return (
    <FormElementBase {...controlledProps} {...otherProps}>
      <TimeInputBase {...controlledProps} {...otherProps} />
    </FormElementBase>
  );
};

/*
 * Very rough and rushed split from LabelInput
 * Much unused code
 */
const TimeInputBase = (props) => {
  const classes = useStyles();
  const readOnly = props.readOnly || isReadOnly();

  return (
    <FormControl variant="outlined" className={classNames(classes.formControlRoot, classes.formControlRootDatePicker)}>
      {/* <InputLabel htmlFor={props.id}/> */}
      <FormLabel
        htmlFor={props.id}
        label={<>{props.label}{!!props.required && "*"}</>}
        labelTooltip={props.labelTooltip}
        error={props.error}
        variant="default"
      />
      <OutlinedDatePicker
        error={props.error}
        helperText={props.errorMessage}
        className={
          classNames(
            classes.margin,
            readOnly && classes.readOnly
          )
        }
        type="time"
        step={props.step}
        min={props.min}
        max={props.max}
        variant="outlined"
        id={props.id}
        name={props.name}
        defaultValue={props.defaultValue}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        placeholder={props.placeholder}
        margin={props.margin}
        required={props.required}
        inputRef={props.inputRef}
        disabled={props.disabled || readOnly}
        size={props.size}
        InputLabelProps={{
          shrink: props.shrink,
        }}
        InputProps={{
          className: classNames(
            props.placeholder && !props.value && classes.placeholderVisible,
            props.fixedHeight && !props.errorMessage && classes.datepickerErrorlessFixedHeight
          )
        }}
        FormHelperTextProps={{
          className: classNames(
            classes.datepickerHelper,
            props.fixedHeight && props.errorMessage ? classes.datepickerHelperFixedHeight : undefined
          )
        }}
        data-cy={`time-input-${props.test}`}
        onKeyDown={props.onKeyDown}
      />
      {/* {!props.noHelper && (
        <CustomFormHelperText
          id={`helper-${props.id}`}
          className={props.fixedHeight ? classes.helperFixedHeight : undefined}
          error={props.error}
          data-cy={`input-helper-${props.test}`}
        >
          {props.errorMessage ? props.errorMessage : props.helperText}
        </CustomFormHelperText>
      )} */}
    </FormControl>
  );
};

export default TimeInput;
