import React from 'react'
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import variables from "styleVariables";
import HeaderPrimary from "components/utils/header.component";


const useStyles = makeStyles(theme => ({
  designAndPerformance: {
    display: "flex",
    paddingBottom: 10,
  },
  summaryList: {
    display: "flex",
    flexDirection: "column",
  },
  summaryListHeading: {
    margin: "1em 0 0.5em",
    padding: 0,
    maxWidth: 120,
  },
  summaryListItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 3,
    paddingTop: 3,
    maxWidth: 100,
  },
  summaryListItemLabel: {
    color: variables.textSecondary,
  },
  badgeWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: variables.tertiary1,
    borderRadius: "50px",
    color: "white",
    lineHeight: "normal",
    fontWeight: "bold",
    fontSize: variables.fontXxs,
    minHeight: 16,
    minWidth: 16,
    height: "fit-content",
    width: "fit-content",
    padding: 3,
  },
  designSummary: {
    paddingRight: 50,
    "& $summaryListItemLabel": {
      color: variables.primaryLight,
    },
  },
  performanceSummary: {
    "& $summaryListItemLabel": {
      color: variables.secondaryMain,
    },
  },
}));

const DataCollectionCount = ({ designSummary, performanceSummary }) => {
  const classes = useStyles()
  return (
    <div className={classes.designAndPerformance} data-cy="data-collection-count-display">
      {!!designSummary && (
        <div className={classNames(classes.summarySection, classes.designSummary)}>
          <HeaderPrimary variant="h5PrimaryLight" className={classNames(classes.summaryListHeading)}>Design Data Collection</HeaderPrimary>
          <div className={classNames(classes.summaryList)}>
            <div className={classNames(classes.summaryListItem)} data-cy="summary-design-not-started">
              <span className={classes.summaryListItemLabel}>Not Started:</span>
              <span className={classNames(classes.badgeWrapper)}>{designSummary.notStarted}</span>
            </div>
            <div className={classNames(classes.summaryListItem)} data-cy="summary-design-in-progress">
              <span className={classes.summaryListItemLabel}>In Progress:</span>
              <span className={classNames(classes.badgeWrapper)}>{designSummary.inProgress}</span>
            </div>
            <div className={classNames(classes.summaryListItem)} data-cy="summary-design-completed">
              <span className={classes.summaryListItemLabel}>Completed:</span>
              <span className={classNames(classes.badgeWrapper)}>{designSummary.completed}</span>
            </div>
          </div>
        </div >
      )}
      {
        !!performanceSummary && (
          <div className={classNames(classes.summarySection, classes.performanceSummary)}>
            <HeaderPrimary variant="h5Secondary" className={classNames(classes.summaryListHeading)}>Performance Data Collection</HeaderPrimary>
            <div className={classNames(classes.summaryList)}>
              <div className={classNames(classes.summaryListItem)} data-cy="summary-performance-not-started">
                <span className={classes.summaryListItemLabel}>Not Started:</span>
                <span className={classNames(classes.badgeWrapper)}>{performanceSummary.notStarted}</span>
              </div>
              <div className={classNames(classes.summaryListItem)} data-cy="summary-performance-in-progress">
                <span className={classes.summaryListItemLabel}>In Progress:</span>
                <span className={classNames(classes.badgeWrapper)}>{performanceSummary.inProgress}</span>
              </div>
              <div className={classNames(classes.summaryListItem)} data-cy="summary-performance-completed">
                <span className={classes.summaryListItemLabel}>Completed:</span>
                <span className={classNames(classes.badgeWrapper)}>{performanceSummary.completed}</span>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}
export default DataCollectionCount;
