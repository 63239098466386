import React, { useState } from "react";
import HeaderPrimary from "components/utils/header.component.js";
import CompTasksTable from "components/tasks/compTasksTable.component";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import { Paper, Tabs, Tab, Typography } from "@material-ui/core";
import Badge from "components/utils/badge.component";

const useStyles = makeStyles((theme) => ({
  popoverWrapper: {
    padding: "10px 10px 15px 10px",
  },
  headerWrapper: {
    marginBottom: 10,
  },
  tabsWrapper: {
    borderRadius: "4px 4px 0px 0px",
    [theme.breakpoints.up("xl")]: {
      marginRight: 0,
    },
  },
  tabs: {
    width: "fit-content",
  },
  indicator: {
    visibility: "hidden",
    align: "center",
  },
  tabText: {
    fontSize: variables.fontXs,
    fontWeight: "bold",
  },
}));

const StyledBadgeSelected = withStyles(() => ({
  badge: {
    top: -2,
    right: -5,
    border: `1px solid white`,
    padding: "0 2px",
    fontSize: variables.fontXs,
    height: 20,
    fontWeight: "bold",
    borderRadius: 40,
    backgroundColor: variables.tertiary2,
    color: "white",
  },
}))(Badge);
// These 2 badges have tghe same styling except for backgroundColor
const StyledBadge = withStyles(({ myColor }) => ({
  badge: {
    top: -2,
    right: -5,
    border: `1px solid white`,
    padding: "0 2px",
    fontSize: variables.fontXs,
    height: 20,
    fontWeight: "bold",
    borderRadius: 40,
    backgroundColor: variables.tertiary2,
    color: "white",
  }
}))(Badge);

const BadgeDisplay = (props) => {
  if (props.isActive) {
    return (
      <StyledBadgeSelected {...props}>{props.children}</StyledBadgeSelected>
    );
  } else {
    return <StyledBadge {...props}>{props.children}</StyledBadge>;
  }
};

const StyledTab = withStyles((theme) => ({
  root: {
    borderRadius: "3px 3px 0 0",
    borderColor: "white",
    color: variables.tertiary1,
    fontSize: variables.fontXs,
    padding: "0px 25px",
    margin: 0,
    minWidth: 90,
    fontWeight: "bold",
    opacity: 1,
    "&:hover": {
      backgroundColor: variables.tertiary1Light,
    },
  },
  selected: {
    backgroundColor: variables.tertiary1Light,
  },
}))(Tab);

export const CompTasksContainer = ({
  title,
  allTasks,
  userTasks,
  refresh,
  setRefresh,
}) => {
  const classes = useStyles();
  const [viewMode, setViewMode] = useState("userTasks");

  const viewHandler = (event, newVal) => {
    setViewMode(newVal);
  };

  return (
    <Paper className={classes.popoverWrapper} data-cy="popover-container-compTasks">
      <div className={classes.headerWrapper}>
        <HeaderPrimary variant="h3Primary">{`${title} Tasks`}</HeaderPrimary>
      </div>
      <Paper className={classes.tabsWrapper}>
        <Tabs
          className={classes.tabs}
          textColor="primary"
          indicatorColor="primary"
          value={viewMode}
          elevation={0}
          onChange={viewHandler}
          TabIndicatorProps={{ className: classes.indicator }}
        >
          <StyledTab
            label={
              <BadgeDisplay alertCount={userTasks.length} isActive={viewMode === "userTasks"}>
                <Typography className={classes.tabText}>My Tasks</Typography>
              </BadgeDisplay>
            }
            value="userTasks"
            classes={{
              selected: classes.selectedTab,
            }}
            data-cy="tab-myTasks"
          />
          <StyledTab
            label={
              <BadgeDisplay alertCount={allTasks.length} isActive={viewMode === "allTasks"}>
                <Typography className={classes.tabText}>All Tasks</Typography>
              </BadgeDisplay>
            }
            value="allTasks"
            classes={{
              selected: classes.selectedTab,
            }}
            data-cy="tab-allTasks"
          />
        </Tabs>
      </Paper>
      <CompTasksTable
        tableData={viewMode === "userTasks" ? userTasks : allTasks}
        userTasks={userTasks}
        title={title}
        refresh={refresh}
        setRefresh={setRefresh}
        viewMode={viewMode}
      />
    </Paper>
  );
};

export default CompTasksContainer;
