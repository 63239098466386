import { removeObjInArray } from "utils/arrayOfObjectsHelpers";
import { mergeNestedParentKeyAssociationReplace, mergeParentKeyAssociationReplace, mergeReplace } from "../utils/payloadUtils";

export const ACTION_SET_DCT_ELEMENTS = "dct_category__set";
export const ACTION_SET_DCT_INDEX = "dct_index__set"
export const ACTION_SET_USERS = "users__set";
export const ACTION_REPLACE_DCT_ELEMENT_RESPONSE = "dct_category__dct_response__replace";
export const ACTION_REPLACE_DCT_QUESTION_RESPONSE = "dct_question__dct_response__replace";

//AIRCRAFT
export const ACTION_SET_AIRCRAFTS = "aircrafts__set";
export const ACTION_REPLACE_AIRCRAFT = "aircraft__replace";
export const ACTION_ADD_AIRCRAFT = "aircraft__add";
export const ACTION_DELETE_AIRCRAFT = "aircraft__delete";
//PASI
export const ACTION_SET_PASI_GROUPS = "pasi_groups__set";


export const certificationInitialState = {
  dctElements: null,
  dctIndex: null, // Todo: Also update when dct response actions are dispatched
  users: null,
  aircrafts: null,
  pasiGroups: null
};



/*
 * Reducer for data specific to the Certification Program.
 */
export default function certificationReducer(state, action) {
  switch (action.type) {
    case ACTION_SET_DCT_ELEMENTS:
      return {
        ...state,
        dctElements: action.payload
      };
    case ACTION_SET_DCT_INDEX:
      return {
        ...state,
        dctIndex: action.payload
      };
    case ACTION_SET_USERS:
      return {
        ...state,
        users: action.payload
      };
    case ACTION_SET_PASI_GROUPS:
      return {
        ...state,
        pasiGroups: action.payload
      };
    case ACTION_SET_AIRCRAFTS:
      return {
        ...state,
        aircrafts: action.payload
      };
    case ACTION_REPLACE_AIRCRAFT:
      return {
        ...state,
        aircrafts: mergeReplace(
          state.aircrafts,
          action.payload,
          "Aircraft_ID"
        )
      };

    // TODO: Update Dct Index
    case ACTION_REPLACE_DCT_ELEMENT_RESPONSE:
      return {
        ...state,
        dctElements: mergeParentKeyAssociationReplace(
          state.dctElements,
          action.payload,
          "DctElement_ID",
          "DctObject_ID",
          "DctResponse",
          "DctResponse_ID"
        )
      };

    case ACTION_REPLACE_DCT_QUESTION_RESPONSE:
      return {
        ...state,
        dctElements: mergeNestedParentKeyAssociationReplace(
          state.dctElements,
          action.payload,
          "DctQuestion_ID",
          "DctObject_ID",
          "DctResponse_ID",
          "DctQuestion",
          "DctResponse",
        ),
      };

    case ACTION_DELETE_AIRCRAFT:
      return {
        ...state,
        aircrafts: removeObjInArray(state.aircrafts, "Aircraft_ID", action.payload)
      };

    default:
      return state;
  }
}
