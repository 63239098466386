import { Box } from "@material-ui/core";
import DefaultChecklist from "components/checklist/defaultChecklist.component";
import useNumericParams from "hooks/useNumericParams";
import React from "react";
import { CERTIFICATION_PROGRAM_ID } from "utils/certificationConstants";

const ChecklistPage = (props) => {
  const params = useNumericParams();

  return (
    <Box marginTop={4} marginBottom={4}>
      {params.programId === CERTIFICATION_PROGRAM_ID ? (
        <DefaultChecklist />
      ) : (
        "No Checklists exists for this program."
      )}
    </Box>
  );
};

export default ChecklistPage;
