import { Box } from "@material-ui/core";
import { H2 } from "components/utils/headerV2.component";
import PortCoListing from "./portCoListing.component";


export default function SelectPortCo(props) {
  const { onClick } = props;
  return (
    <div>
      <Box marginBottom={-0.5}>
        <H2 color="tertiary">
          Select Portfolio Company
        </H2>
      </Box>
      <PortCoListing onClick={onClick} />
    </div>
  );
}
