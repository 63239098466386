import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import {
  FormControl,
  FormGroup,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import FormBanner from "components/utils/form-elements/formBanner.component";
import FormSubHeader from "components/utils/form-elements/formSubHeader.component";
import ImplementationService from "services/implementation.service";
import Checkbox from "components/utils/form-elements/checkbox.component";
import moment from "moment";
import { currentUserInfo } from "utils/userHelpers";
import Form from "components/utils/form-elements/form.component";
const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: 700,
    height: 700,
    display: "flex",
    justifyContent: "center",
  },
  form: {
    width: 660,
    height: 503,
    align: "center",
    paddingBottom: 0,
    paddingLeft: 15,
    paddingRight: 15,
  },
  formControl: {
    padding: 0,
  },
  subHeaderContainer: {
    padding: "0px 10px 35px 10px",
  },
  instruction: {
    paddingLeft: 40,
  },
  checkBoxesContainer: {
    display: "flex",
    justifyContent: "center",
    maxHeight: 400,
    overflowY: "scroll",
    marginLeft: 25,
    marginRight: 25,
    backgroundColor: variables.grayTint,
    borderRadius: 8,
  },
  label: {
    fontSize: variables.fontLarge,
    color: variables.textPrimary,
  },
}));

export const DataScopeForm = (props) => {
  const classes = useStyles();
  const { programId } = useParams();
  const [group, setGroup] = useState();
  const [userId, setUserId] = useState();
  const [formData, setFormData] = useState();
  const [dataMapObj, setDataMapObj] = useState({});
  const [allInfo, setAllInfo] = useState();
  const [formObj, setFormObj] = useState({});
  const [loadForm, setLoadForm] = useState(false);
  const [dataMapIds, setDataMapIds] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const user = currentUserInfo();
    setUserId(user.id);
    const data = [];
    let obj = {};
    ImplementationService.getProductDataScope(props.product.id, programId)
      .then((res) => {
        setLoadForm(false);
        setAllInfo(res.payload[0]);
        res.payload[0].forEach((info) => {
          // obj which gets updated onChange
          obj = {
            ...obj,
            [info.DataType_ID.toString()]: info.IsExist === 1,
            DataMap_ID: info.DataMap_ID,
            DataType_ID: info.DataType_ID,
          };
          data.push({
            formFieldId: info.DataType_ID.toString(),
            Label: info.Type_Name,
            IsExist: info.IsExist === 1,
            DataMap_ID: info.DataMap_ID,
            DataType_ID: info.DataType_ID,
          });
        });
        setFormData(data);
        setFormObj(obj);
      })
      .then(() => setLoadForm(true));

    ImplementationService.getOrgGroupByProductId(props.product.id).then((res) =>
      setGroup({ id: res.payload.Group_ID, name: res.payload.Name })
    );
  }, [programId, props.product.id, props.subProducts]);

  const handleChange = (event, dataMapId) => {
    const { target } = event;
    setFormObj({ ...formObj, [target.name]: target.checked });

    if (!dataMapIds.includes(dataMapId)) {
      setDataMapIds([...dataMapIds, dataMapId]);
    }

    // isolate specific row from allInfo
    const dataType_Id = parseInt(target.name);
    const row = allInfo.filter((info) => info.DataType_ID === dataType_Id)[0];

    // create object where key is DataType_ID and value is array to be passed to save API
    if (
      // Checks if checkbox status is different than original state.
      (target.checked === true && row.IsExist === 1) ||
      (target.checked === false && row.IsExist === 0)
    ) {
      if (dataMapId !== 0 && dataMapIds.includes(dataMapId)) {
        setDataMapIds([...dataMapIds.filter((id) => id !== dataMapId)]);
      }
      setDataMapObj({
        ...dataMapObj,
        [dataType_Id]: null,
      });
    } else {
      if (!dataMapIds.includes(dataMapId)) {
        setDataMapIds([...dataMapIds, dataMapId]);
      }
      setDataMapObj({
        ...dataMapObj,
        [dataType_Id]: [
          row.Program_Program_ID,
          row.GroupProducts_OrganizationGroups_Group_ID,
          row.GroupProducts_GroupProduct_ID,
          row.DataType_ID,
          row.Creation_Capture,
          row.Storage,
          row.Transmission,
          row.Use,
          row.Sharing,
          row.Archive,
          row.Destruction,
          target.checked ? "Active" : "Disabled",
          moment(new Date()).format("YYYY-MM-DD"),
          userId,
        ],
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSaving(true);

    // Remap form data array back to table row format
    const mappedData = Object.entries(dataMapObj).filter(([id, valueArray]) => (
      id && valueArray?.length
    )).map(([typeId, valueArray]) => ({
      Program_Program_ID: valueArray[0],
      GroupProducts_OrganizationGroups_Group_ID: valueArray[1],
      GroupProducts_GroupProduct_ID: valueArray[2],
      DataTypes_DataType_ID: typeId,
      Creation_Capture: valueArray[4],
      Storage: valueArray[5],
      Transmission: valueArray[6],
      Use: valueArray[7],
      Sharing: valueArray[8],
      Archive: valueArray[9],
      Destruction: valueArray[10],
      Status: valueArray[11]
    }));

    await ImplementationService.batchUpsertDataMap(props.product.id, mappedData);

    // reload table and close modal
    props.setRefresh(true);
    props.setEditModal(false);
    setIsSaving(false);
  };

  if (group && loadForm && formData && formData.length) {
    return (
      <Grid container className={classes.formContainer}>
        <FormBanner>Data Types - Data Scope Update</FormBanner>
        <div>
          <div className={classes.subHeaderContainer}>
            <FormSubHeader
              variant="groupProduct"
              product={props.product.name}
              group={group.name}
            />
          </div>
          {/* <HeaderPrimary variant={"h4Tertiary"} className={classes.instruction}> */}
          <Typography
            variant="subtitle1"
            className={classes.instruction}
            color="textPrimary"
          >
            {`Select all Data Types stored in ${props.product.name}:`}
          </Typography>
          {/* </HeaderPrimary> */}
          <Form name="data-scope" className={classes.form}>
            <div className={classes.checkBoxesContainer}>
              <FormControl component="fieldset" className={classes.formControl}>
                {/* <FormLabel component="legend">{`Select all Data Types stored in ${props.product.name}:`}</FormLabel> */}
                <FormGroup>
                  {formData.map((info) => {
                    return (
                      <Checkbox
                        checked={formObj[info.formFieldId]}
                        onChange={(event) => handleChange(event, info.DataMap_ID)}
                        name={info.formFieldId}
                        label={info.Label}
                        key={info.formFieldId}
                        labelClass={classes.label}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </div>
            <DualFormButtons
              cancelOnClick={() => props.setEditModal(false)}
              saveOnClick={handleSubmit}
              disabled={dataMapIds.length < 1}
              isSaving={isSaving}
            />
          </Form>
        </div>
      </Grid>
    );
  } else {
    return "";
  }
};

export default DataScopeForm;
