import React, { useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from "@material-ui/core";
import TableCell from "components/utils/tables/shared/tableCell.component";
import variables from "styleVariables";
import classNames from "classnames";
import moment from "moment";
import { getComparator, stableSort } from "utils/tableSorts";
import TableSortLabel from "components/utils/tableSortLabel.component";
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import Dialog from "components/utils/dialog.component";
import { useCallback } from 'react';
import ButtonDefault from 'components/utils/buttonDefault.component';
import CheckIcon from '@material-ui/icons/Check';
import { PictureAsPdfOutlined } from '@material-ui/icons';
import { HTTP_STATUS_OK } from 'services/http-common';
import FileService from 'services/file.service';
import ClipLoader from "components/utils/clipLoad.component";
import { css } from "@emotion/core";
import useNumericParams from 'hooks/useNumericParams';
import useTargetTableRowViaLink from "hooks/useTargetTableRowViaLink";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: 10,
  },
  tableContainerFullPage: {
    marginTop: 40,
  },
  maxHeight: {
    maxHeight: 300,
    overflowX: "hidden"
  },
  maxHeightFullPage: {
    maxHeight: "calc(100vh - 220px)",
    overflowX: "hidden"
  },
  tablePrimaryHeaderCellLarge: {
    backgroundColor: variables.tertiary3,
    color: "white",
    fontWeight: "bold",
    fontSize: variables.fontSmall,
    padding: "10px 15px",
    lineHeight: "normal",
  },
  previous: {
    backgroundColor: variables.tertiary1,
  },
  tableBodyCellLarge: {
    padding: "10px 15px",
    color: variables.textSecondary,
    fontSize: variables.fontXs,
  },
  link: {
    color: variables.secondaryDark,
    fill: variables.secondaryDark,
    "&:hover": {
      color: variables.primaryMain,
      fill: variables.primaryMain,
      cursor: "pointer",
    },
    "& $downloadFileIcon": {
      color: "inherit",
    },
  },
  centerAlign: {
    textAlign: "center",
  },
  leftAlign: {
    textAlign: "left",
  },
  emptyMessage: {
    color: variables.textSecondary,
    fontSize: variables.fontXs,
    textAlign: "center",
  },
  downloadFileIcon: {
    maxHeight: 10,
    maxWidth: 10,
    marginLeft: "-2px",
    marginRight: 2,
  },
  uploadIconSmall: {
    fontSize: variables.fontSmall,
    marginRight: 6,
  },
  actionsCell: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    color: "white",
    alignItems: "center"
  },
  buttonWidth: {
    width: 140,
  }
}));

// For ClipLoader styling
const override = css`
  margin-right: 4px;
`;

const baseColumns = [
  {
    sortKey: "DocName",
    label: "Name",
    align: "left"
  },
  {
    sortKey: "Description",
    label: "Description",
    align: "left"
  },
]

const previousDocColumns = [
  ...baseColumns,
  {
    sortKey: "Version",
    label: "Version",
    align: "center"
  },
  {
    sortKey: "PublishDate",
    label: "Approved",
    align: "center"
  },
  {
    sortKey: "Review_Date",
    label: "Reviewed",
    align: "center"
  }
];

const currentDocColumns = [
  ...previousDocColumns,
  {
    sortKey: "Actions",
    label: "Actions",
    align: "center"
  },
];


const DocumentControlTable = (props) => {
  const classes = useStyles();
  const { componentObjectId } = useNumericParams();
  const isFullPage = !!componentObjectId && !props.tempFromTrainingProp;

  const rowRef = useRef({});
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(props.data.length > 0 ? "DocName" : "");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState({});
  const [loadingDownload, setLoadingDownload] = useState(null);
  const [updatingReviewDate, setUpdatingReviewDate] = useState(null);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [targetedRowId] = useTargetTableRowViaLink(rowRef, !!props.data?.length);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleConfirmReview = useCallback(async () => {
    try {
      setUpdatingReviewDate(selectedDoc.DocID)
      setOpenDialog(false)
      const today = moment().format("YYYY-MM-DD")
      const updateDoc = {
        ProgramDoc_ID: selectedDoc.DocID,
        File_Name: selectedDoc.File_Name,
        Description: selectedDoc.Description,
        PublishDate: selectedDoc.PublishDate,
        Publisher: selectedDoc.Publisher,
        Builder_ID: selectedDoc.Builder_ID,
        Review_Date: today,
      }
      const updateFileResponse = await FileService.updateReviewDateForProgramDoc(updateDoc)
      const currentDocs = [...props.allCompFiles]
      const index = currentDocs.findIndex((item) => (
        updateFileResponse.payload.ProgramDoc_ID === item.ProgramDoc_ID
      ));
      currentDocs.splice(index, 1, {...updateFileResponse.payload});
      props.setAllCompFiles(currentDocs)
      setSelectedDoc({})
    } catch (error) {
      //Set error message here
      setOpenErrorDialog(true)
    } finally {
      setUpdatingReviewDate(null)
    }

  }, [setSelectedDoc, setOpenDialog, selectedDoc, props])

  const downloadProgramDoc = (id, name) => {
    if(!id) {
      setOpenErrorDialog(true);
      setLoadingDownload(null);
      return;
    }
    setLoadingDownload(id);
    setOpenErrorDialog(false);
    // download file
    FileService.builderDocDownload(id)
      .then((blob) => {
        if (blob.status === HTTP_STATUS_OK) {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${name}.pdf`);
          // Append to html page
          document.body.appendChild(link);
          // Force download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        } else {
          setOpenErrorDialog(true);
          setLoadingDownload(null);

        }
      })
      .then(() => {
        setLoadingDownload(null);
      });
  };

  const columns = useMemo(() => {
    if (props.tempFromTrainingProp) {
      return baseColumns;
    } else if (props.isCurrentProgramDoc) {
      return currentDocColumns;
    }
    return previousDocColumns;
  }, [props.tempFromTrainingProp, props.isCurrentProgramDoc]);

  return (
    <div className={isFullPage ? classes.tableContainerFullPage : classes.tableContainer}>
      {props.tableTitle}
      <TableContainer component={Paper} data-cy="charterCard" className={isFullPage ? classes.maxHeightFullPage : classes.maxHeight}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  className={classNames(
                    classes.tablePrimaryHeaderCellLarge,
                    props.isCurrentProgramDoc ? classes.current : classes.previous,
                    column.align === "left" && classes.leftAlign,
                    column.align === "center" && classes.centerAlign
                  )}
                  key={column.label}
                >
                  <TableSortLabel
                    active={orderBy === column.sortKey}
                    direction={orderBy === column.sortKey ? order : "asc"}
                    onClick={createSortHandler(column.sortKey)}
                    align={column.align}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {(!props.data || props.data.length === 0) && (
            <TableBody>
              <TableRow>
                <TableCell
                  className={classes.tableSmallBodyCell}
                  colSpan="6"
                  scope="row"
                >
                  <div className={classes.emptyMessage}>No {props.title}</div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {!!props.data && props.data.length > 0 && (
            <TableBody>
              {stableSort(props.data, getComparator(order, orderBy)).map(row => (
                <TableRow
                  key={row.key}
                  id={row.DocID}
                  ref={(el) => (rowRef.current[row.DocID] = el)}
                  selected={targetedRowId === row.DocID}
                  data-cy={`docTable-docID-${row.DocID}`}
                >
                  <TableCell component="th" scope="row" className={classNames(classes.tableBodyCellLarge, classes.leftAlign)}>
                    <span className={classes.link}>
                      <AttachFileOutlinedIcon className={classes.downloadFileIcon} />
                      {row.Title}
                    </span>
                  </TableCell>
                  <TableCell className={classNames(classes.tableBodyCellLarge, classes.leftAlign)}>
                    {row.Description}
                  </TableCell>
                  {!props.tempFromTrainingProp && (
                    <>
                      <TableCell
                        className={classNames(
                          classes.tableBodyCellLarge,
                          classes.centerAlign
                        )}
                      >
                        {row.Version}
                      </TableCell>
                      <TableCell
                        className={classNames(
                          classes.tableBodyCellLarge,
                          classes.centerAlign
                        )}
                      >
                        {!!row.PublishDate &&
                          moment(row.PublishDate).format("M/D/YYYY")}
                      </TableCell>
                      <TableCell
                        className={classNames(classes.tableBodyCellLarge)}
                      >
                        {row.Review_Date}
                      </TableCell>
                    </>
                  )}
                  {!!props.isCurrentProgramDoc && !props.tempFromTrainingProp && (
                    <TableCell
                      className={
                        classNames(
                          classes.tableBodyCellLarge,
                          classes.centerAlign
                        )
                      }
                    >
                      <div className={classes.actionsCell}>
                        <ButtonDefault
                          variant="small"
                          background="secondary"
                          startIcon={
                            (row.Builder_ID && loadingDownload === row.Builder_ID) ? (
                              <ClipLoader css={override} />
                            ) : (
                              <PictureAsPdfOutlined className={classes.uploadIconSmall} />
                            )
                          }
                          onClick={() => downloadProgramDoc(
                            row.Builder_ID,
                            `${row.DocName}-Version${row.Version}-${row.PublishDate}`
                          )} //Download PDF document
                          data-cy={`btn-review-${row.DocName}`}
                          disableReadOnlyUsers
                          disabled={!row.Builder_ID}
                          className={classes.buttonWidth}
                        >
                          Review
                        </ButtonDefault>
                        <ButtonDefault
                          variant="small"
                          background="primary"
                          startIcon={
                            updatingReviewDate === row.DocID ? (
                              <ClipLoader css={override} />
                            ) : (
                              <CheckIcon className={classes.uploadIconSmall} />
                            )
                          }
                          onClick={() => {
                            setSelectedDoc(row);
                            setOpenDialog(true);
                          }}
                          data-cy={`btn-review-${row.DocName}`}
                          disableReadOnlyUsers
                          disabled={!row.Builder_ID}
                          className={classes.buttonWidth}
                        >
                          Mark Reviewed
                        </ButtonDefault>
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Dialog
        openDialog={openDialog}
        confirm={handleConfirmReview}
        setOpenDialog={setOpenDialog}
        prompt={`Are you sure you want to mark "${selectedDoc.DocName}" as reviewed?`}
      />
      <Dialog
        notification
        openDialog={openErrorDialog}
        confirm={() => setOpenErrorDialog(false)}
        setOpenDialog={setOpenErrorDialog}
        prompt="An error occurred. Please refresh the page and try again."
      />
    </div>
  )
}

export default DocumentControlTable
