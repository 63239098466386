import CustomLink from "components/utils/link.component";
import useNumericParams from "hooks/useNumericParams";
import { useMemo, useState, useEffect } from "react";
import { generatePath, Route, Switch } from "react-router-dom/cjs/react-router-dom.min";
import SessionDataCollectionPage from "./sessionDataCollection/sessionDataCollectionPage.component";
import Reconcile from "./sessionDataCollection/reconcile.component";
import { isPortCoGroupReadOnlyUser } from "utils/roles";
import Loader from "components/utils/loader.components";
import PortfolioService from "services/portfolio.service";
import { ACTION_SET_DOCUMENTS } from "reducers/portfolioAssessments.reducer";


export default function SessionsPages(props) {
  const params = useNumericParams();
  const { assessmentId, portCoId } = params;
  const { dispatch, state } = props;
  const isReadOnly = useMemo(isPortCoGroupReadOnlyUser, []);

  const [noteFilter, setNoteFilter] = useState(isReadOnly ? "all" : undefined);

  const assessment = useMemo(() => (
    state?.assessments?.filter?.(assessmentItem => (
      assessmentItem.HolderAssessment_ID === assessmentId
    ))?.[0]
  ), [state.assessments, assessmentId]);

  const assessmentStatus = useMemo(() => {
    return assessment?._associations?.AssessmentStatus?.find?.(status => (
      status?.PortCo_ID === portCoId
    ))
  }, [assessment, portCoId])

  useEffect(() => (
    async function loadDocuments() {
      if (!assessmentStatus?.AssessmentStatus_ID) {
        return;
      }
      const [
        documentsResponse,
      ] = await Promise.all([
        PortfolioService.getAssessmentDocuments(assessmentStatus?.AssessmentStatus_ID),
      ]);
      dispatch({ type: ACTION_SET_DOCUMENTS, payload: documentsResponse.payload });
    }
  )(), [assessmentStatus?.AssessmentStatus_ID, dispatch]);

  if (!state.assessments || !assessmentStatus) {
    return <Loader />
  }

  return (
    <Switch>
      <Route
        path="/program/:programId/:programComponentId/holding/:holdingId/portCo/:portCoId/assessment/:assessmentId/session/:sessionId/reconcile"
        exact
      >
        <Reconcile
          state={state}
          dispatch={dispatch}
          isReadOnly={isReadOnly}
          noteFilter={noteFilter}
          setNoteFilter={setNoteFilter}
          sessions={assessmentStatus?._associations?.AssessmentSession}
        />
      </Route>
      <Route
        path="/program/:programId/:programComponentId/holding/:holdingId/portCo/:portCoId/assessment/:assessmentId/session/:sessionId"
        exact
      >
        <SessionDataCollectionPage
          state={state}
          dispatch={dispatch}
          isReadOnly={isReadOnly}
          noteFilter={noteFilter}
          setNoteFilter={setNoteFilter}
        />
      </Route>
      <Route
        path="/program/:programId/:programComponentId/holding/:holdingId/portCo/:portCoId/assessment/:assessmentId"
      >
        <p>
          {assessment?._associations?.AssessmentSession?.map((session) => (
            <CustomLink
              to={generatePath(
                `/program/:programId/:programComponentId/holding/:holdingId/portCo/:portCoId/assessment/:assessmentId/session/${session.Session_ID}`,
                params
              )}
              variant="routerLink"
              key={session.Session_ID}
            >
              {session.Description}
            </CustomLink>
          ))}
        </p>
      </Route>
    </Switch>
  )
}
