import { makeStyles, Box, Divider } from "@material-ui/core";
import Dialog from "components/utils/dialog.component";
import { Edit, NoteAdd } from "@material-ui/icons";
import ButtonDefault from "components/utils/buttonDefault.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import Header from "components/utils/header.component";
import Loader from "components/utils/loader.components";
import useNumericParams from "hooks/useNumericParams";
import { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ACTION_CREATE_REPORT } from "reducers/portfolioReports.reducer";
import PortfolioService from "services/portfolio.service";
import styleVariables from "styleVariables";
import PortCoCard from "../shared/portCo/portCoCard.component";
import {
  portfolioProgramComponentIDs,
  sessionTypes,
} from "utils/portfolioConstants";
import CustomLink from "components/utils/link.component";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { isPortCoGroupUser } from "utils/roles";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FileService from "services/file.service";
import ClipLoader from "components/utils/clipLoad.component";
import { replaceAllSubStrings } from "utils/stringFuncs";
import { setStateFetchEffect } from "utils/ajaxHelpers";
import { H2, H3 } from "components/utils/headerV2.component";
import { groupObjectArrayByKey, mapObjectArrayByKey } from "utils/arrayOfObjectsHelpers";

const useStyles = makeStyles((theme) => ({
  assessmentSection: {
    paddingRight: 16,
    paddingLeft: 16,
  },
  sectionDivider: {
    marginTop: 40,
    marginBottom: 40
  },
  buttonIcon: {
    marginRight: 8,
    fontSize: 14,
  },
  contents: {
    paddingBottom: 5,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: "100%",
  },
  reportInfo: {
    display: "flex",
    flexDirection: "column",
    marginTop: 4,
  },
  infoValue: {
    marginLeft: 8,
    color: styleVariables.tertiary1,
  },
  label: {
    padding: 0,
    lineHeight: "normal",
  },
  buttonsRow: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 16,
  },
  noReports: {
    minWidth: 200,
  },
  emptyMessage: {
    color: styleVariables.textSecondary,
    marginBottom: 16,
  },
  logoWrapper: {
    flex: 1,
  },
  row: {
    display: "inline-flex",
    alignItems: "center",
  },
  emptyMessageText: {
    display: "inline-block",
    marginBottom: 20,
  },
}));

export default function SelectReportPage(props) {
  const classes = useStyles();
  const { holdingId } = useNumericParams();
  const { dispatch, state, userReadOnly, setEditReportMessage, goToReport } = props;
  const { assessments, portfolioCompanies, reports } = state;
  const [isDownloading, setIsDownloading] = useState(null)

  const holderAssessments = useMemo(() => (
    assessments?.filter?.(assessment => (
      assessment.PortfolioHolder_ID === holdingId
    ))
  ), [assessments, holdingId]);

  const portCosById = useMemo(() => (
    mapObjectArrayByKey(portfolioCompanies, "PortCo_ID")
  ), [portfolioCompanies]);

  const reportsByAssessmentThenPortCo = useMemo(() => {
    if (!reports) {
      return null;
    }
    const temporaryAssessmentKey = "_HolderAssessment_ID";
    const byAssessment = groupObjectArrayByKey(
      reports.map(report => ({
        ...report,
        [temporaryAssessmentKey]:
          report._associations.ReportTemplates?.[0]?.HolderAssessment_ID
      })),
      temporaryAssessmentKey
    );
    return Object.fromEntries(
      Object.entries(byAssessment).map(([assessmentId, groupedReports]) => {
        groupedReports.forEach(report => {
          delete report[temporaryAssessmentKey]
        });
        return [
          assessmentId,
          mapObjectArrayByKey(groupedReports, "PortCo_ID")
        ];
      })
    );
  }, [reports]);

  const handleCreateReport = useCallback(
    async (portCoID, assessmentId) => {
      const response = await PortfolioService.createReport(
        portCoID,
        assessmentId
      );
      setEditReportMessage(false)
      dispatch({ type: ACTION_CREATE_REPORT, payload: response.payload });
      goToReport(portCoID, response.payload.Report_ID, "edit");
    },
    [dispatch, goToReport, setEditReportMessage]
  );

  const handleDownloadReport = useCallback(async (report, portCo) => {
    try {
      const reportId = report.Report_ID
      const formattedPortCoName = portCo?.Name ? replaceAllSubStrings(portCo.Name, " ", "_") : "portfolio-company"
      setIsDownloading(reportId);
      const responseBlob = await FileService.downloadPortfolioReport(reportId);
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([responseBlob.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${formattedPortCoName}_report.pdf`);
      // Append to html page
      document.body.appendChild(link);
      // Force download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
      setIsDownloading(null);
    } catch (error) {
      setIsDownloading(null);
    }
  }, []);

  const infoLabel = (label) => {
    return (
      <Header variant="h5Tertiary" className={classes.label}>
        {label}:
      </Header>
    );
  };

  if (!reports || !holderAssessments) {
    return <Loader />;
  }
  return (
    <Box marginTop={2}>
      {holderAssessments.map((assessment, index) => {
        const assessmentStatus = assessment._associations.AssessmentStatus;
        const statusPortCoIds = Array.from(
          new Set(assessmentStatus.map(status => status.PortCo_ID))
        );
        const assessmentPortCos = (
          statusPortCoIds.map(id => portCosById[id]).filter(portCo => portCo)
        );
        if (!assessmentPortCos?.length) {
          return null;
        }
        const reportsByPortCo = (
          reportsByAssessmentThenPortCo[assessment.HolderAssessment_ID] || []
        );
        return (
          <div key={assessment.HolderAssessment_ID}>
            {index > 0 && (
              <Divider className={classes.sectionDivider} />
            )}
            <div className={classes.assessmentSection}>
              <Box marginBottom={2}>
                <H2>{assessment.Assessment_Name}</H2>
              </Box>
              <GridContainer>
                {assessmentPortCos.map((portCo, portCoIndex) => {
                  const companyReport = reportsByPortCo[portCo.PortCo_ID];
                  return (
                    <PortCoCard
                      index={portCoIndex}
                      portCo={portCo}
                      key={portCo.PortCo_ID}
                      logoWrapperClassProp={classes.logoWrapper}
                      wideCard
                    >
                      <div className={classes.contents}>
                        <H3>{portCo.Name}</H3>
                        {companyReport ? (
                          <>
                            <div className={classes.reportInfo}>
                              <div className={classes.row}>
                                {infoLabel("Status")}
                                <div className={classes.infoValue}>
                                  {companyReport.Status || "--"}
                                </div>
                              </div>
                            </div>
                            <div className={classes.buttonsRow}>
                              {!userReadOnly && (
                                <Box marginRight={1}>
                                  <ButtonDefault
                                    background="primary"
                                    variant="small"
                                    startIcon={<Edit className={classes.buttonIcon} />}
                                    onClick={() => (
                                      goToReport(
                                        companyReport.PortCo_ID,
                                        companyReport.Report_ID,
                                        "edit"
                                      )
                                    )}
                                  >
                                    Edit
                                  </ButtonDefault>
                                </Box>
                              )}
                              <div>
                                <ButtonDefault
                                  background="secondary"
                                  variant="small"
                                  startIcon={
                                    <VisibilityIcon className={classes.buttonIcon} />
                                  }
                                  onClick={() => (
                                    goToReport(
                                      companyReport.PortCo_ID,
                                      companyReport.Report_ID,
                                      "preview"
                                    )
                                  )}
                                >
                                  Preview
                                </ButtonDefault>
                              </div>
                            </div>
                            {companyReport.Status === "Published" && (
                              <div className={classes.buttonsRow}>
                                <div>
                                  <ButtonDefault
                                    background="white"
                                    color="primary"
                                    variant="small"
                                    startIcon={
                                      isDownloading === companyReport.Report_ID ? (
                                        <Box paddingRight={1}>
                                          <ClipLoader
                                            color={styleVariables.primaryMain}
                                          />
                                        </Box>
                                      ) : (
                                        <CloudDownloadIcon
                                          className={classes.buttonIcon}
                                        />
                                      )
                                    }
                                    onClick={() => (
                                      handleDownloadReport(companyReport, portCo)
                                    )}
                                  >
                                    Download Report
                                  </ButtonDefault>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <CreateReportCardContent
                            holderAssessment={assessment}
                            onClick={handleCreateReport}
                            userReadOnly={userReadOnly}
                            portCo={portCo}
                          />
                        )}
                      </div>
                    </PortCoCard>
                  );
                })}
              </GridContainer>
            </div>
          </div>
        );
      })}
    </Box>
  );
}

const CreateReportCardContent = (props) => {
  const classes = useStyles();
  const params = useNumericParams();
  const { holderAssessment, onClick, userReadOnly, portCo } = props;
  const [reconcileSessionID, setReconcileSessionID] = useState();
  const [reconcileNoteExists, setReconcileNoteExists] = useState(null);
  const [dcSessionExists, setDcSessionExists] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const history = useHistory();

  useEffect(() => (
    !!holderAssessment?.HolderAssessment_ID &&
    setStateFetchEffect(
      PortfolioService.getCompanyAssessmentSessions(
        holderAssessment?.HolderAssessment_ID,
        portCo.PortCo_ID
      ),
      function getPortCoSession([sessionRes]) {
        const reconcileSession = sessionRes.payload.find(
          (session) => session.Type === sessionTypes.RECONCILE
        );

        setDcSessionExists(
          sessionRes.payload.some(
            (session) => session.Type === sessionTypes.DATA_COLLECTION
          )
        );

        setReconcileSessionID(reconcileSession?.Session_ID);

        if (reconcileSession._associations.SessionNotes.length > 0) {
          setReconcileNoteExists(true);
        } else {
          setReconcileNoteExists(false);
        }
      }
    )
  ), [
    holderAssessment,
    portCo.PortCo_ID,
    setDcSessionExists,
    setReconcileNoteExists,
    setReconcileSessionID,
  ]);

  const isPortCoUser = useMemo(isPortCoGroupUser, []);

  const sessionLink = useMemo(() => {
    let path = "/program/:programId/:programComponentId/holding/:holdingId";
    if (isPortCoUser) {
      if (!params.portCoId) {
        return null;
      }
      path += "/portCo/:portCoId";
    }
    return generatePath(path, {
      ...params,
      programComponentId: portfolioProgramComponentIDs.SCHEDULING //Scheduling component
    });
  }, [isPortCoUser, params]);

  const handleConfirmCreate = useCallback(() => (
    onClick(portCo.PortCo_ID, holderAssessment.HolderAssessment_ID)
  ), [holderAssessment, onClick, portCo]);

  if (!sessionLink || reconcileNoteExists === null) {
    return (
      <Loader />
    );
  }
  return (
    <div className={classes.noReports}>
      <div className={classes.emptyMessage}>
        <span className={classes.emptyMessageText}>
          {reconcileNoteExists
            ? "A report has not been created."
            : "At least one reconciled note must exist to create a report."}
        </span>
        {!reconcileNoteExists && !!dcSessionExists && !!reconcileSessionID && (
          <CustomLink
            to={generatePath(
              `/program/:programId/:programComponentId/holding/:holdingId/portCo/:portCoId/assessment/:assessmentId/session/:sessionId/reconcile`,
              {
                ...params,
                programComponentId: portfolioProgramComponentIDs.ASSESSMENTS,
                portCoId: portCo.PortCo_ID,
                assessmentId: holderAssessment?.HolderAssessment_ID,
                sessionId: reconcileSessionID,
              }
            )}
            variant="routerLinkBold"
            test={`reconcile-${portCo.Name}-session-${reconcileSessionID}`}
          >
            Reconcile Notes
          </CustomLink>
        )}

        {!reconcileNoteExists && !dcSessionExists && (
          <ButtonDefault
            background="secondary"
            variant="small"
            startIcon={<WatchLaterIcon className={classes.buttonIcon} />}
            onClick={() => history.push(sessionLink)}
          >
            Create Session
          </ButtonDefault>
        )}
      </div>
      <ButtonDefault
        background="primary"
        variant="small"
        startIcon={<NoteAdd className={classes.buttonIcon} />}
        onClick={() => setOpenDialog(true)}
        disabled={userReadOnly || !reconcileNoteExists}
      >
        Create Report
      </ButtonDefault>

      <Dialog
        openDialog={openDialog}
        confirm={handleConfirmCreate}
        setOpenDialog={setOpenDialog}
        prompt="After creating this report, additional notes added to this assessment will not be reflected in the report."
        continueAndCancel
      />
    </div>
  );
};
