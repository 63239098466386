import {
  Divider,
  makeStyles,
  CardHeader,
  CardContent,
  Card,
} from "@material-ui/core";

import classNames from "classnames";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import variables from "styleVariables";

const useStyles = makeStyles((theme) => ({
  listItemCard: {
    margin: "1px 0px 10px 0px",
    width: 275,
  },
  cardHeader: {
    paddingBottom: 10,
    display: "flex",
    alignItems: "center",
  },
  cardContent: {
    paddingLeft: 20
  },
  cardDivider: {
    marginRight: 10,
    marginLeft: 10
  },
  userIcon: {
    display: "inline-block",
    height: 30,
    width: 30,
    fontSize: "3rem",
    fill: variables.primaryLight,
  },
  userInfoRow: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 4,
  },
  userInfoIcon: {
    fill: variables.primaryLight,
    fontSize: variables.fontMedium,
    marginRight: 5,
  },
  userName: {
    color: variables.primaryLight,
    fontWeight: 600,
    paddingBottom: 10,
  },
  displayField: {
    paddingBottom: 4,
    color: variables.textSecondary,
    overflowWrap: "anywhere",
  },
}));

export const UserCard = (props) => {
  const classes = useStyles();
  const { user } = props;
  return (
    <Card className={classes.listItemCard}>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <AccountCircleIcon
            aria-label="user-icon"
            className={classes.userIcon}
          />
        }
        title={
          <span className={classes.userName}>
            {`${user?.First_Name} ${user?.Last_Name}`}
          </span>
        }
      >
        {`${user?.First_Name} ${user?.Last_Name}`}
      </CardHeader>

      <Divider className={classes.cardDivider} />

      <CardContent className={classes.cardContent}>
        <div className={classes.userInfoRow}>
          <EmailIcon
            className={classNames(
              classes.userInfoIcon
            )}
          />
          {user?.Email ? (
            <div className={classes.displayField}>
              {user?.Email}
            </div>
          ) : (
            <div
              className={classNames(
                classes.displayField,
                classes.warning
              )}
            >
              Email is missing for this user.
            </div>
          )}
        </div>
        <div className={classes.userInfoRow}>
          <PhoneIcon
            className={classNames(
              classes.phoneIcon,
              classes.userInfoIcon
            )}
          />
          {user?.Phone ? (
            <div className={classes.displayField}>
              {user?.Phone}
            </div>
          ) : (
            <div className={classNames(classes.displayField)}>
              No phone number provided.
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

