import React from "react";
import LetterOfComplianceTable from "./letterOfComplianceTable.component";


const LetterOfCompliance = () => {
  return (
    <LetterOfComplianceTable />
  );
};

export default LetterOfCompliance;
