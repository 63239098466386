import React from "react";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";
import MainLayout from "components/layouts/main/layout.component";
import SearchResultPage from "components/search/searchResultPage.component";
import HomeRouter from "components/layouts/dynamic/homeRouter.component";
import ProgramPage from "components/layouts/dynamic/programPage";
import AdminPage from "components/admin/adminPage.component";
import WpAndProgramTasksPage from "components/tasks/wpTasksTable.component";
import AuthenticatedContextProviders from "components/auth/contextProviders/authenticatedContextProviders.component";
import NotFound from "components/auth/notFound.component";
import {
  AUTH_ROUTES_ADMIN, AUTH_ROUTES_HOME, AUTH_ROUTES_PROGRAMS, AUTH_ROUTES_SEARCH, AUTH_ROUTES_TASKS
} from "../constants/routes.constants";

export default function AuthenticatedRouter() {
  return (
    <AuthenticatedContextProviders>
      <Switch>
        <Route exact path={AUTH_ROUTES_HOME}>
          <HomeRouter />
        </Route>
        <Route exact path={AUTH_ROUTES_ADMIN}>
          <MainLayout />
          <AdminPage />
        </Route>
        <Route exact path={AUTH_ROUTES_SEARCH}>
          <MainLayout />
          <SearchResultPage />
        </Route>
        <Route exact path={AUTH_ROUTES_TASKS}>
          <MainLayout />
          <WpAndProgramTasksPage />
        </Route>
        <Route path={AUTH_ROUTES_PROGRAMS}>
          <ProgramPage />
        </Route>
        <Route exact path="/program/error">
          <NotFound />
        </Route>
      </Switch>
    </AuthenticatedContextProviders>
  )
}
