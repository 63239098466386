import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import classNames from "classnames";
import HeaderPrimary from "components/utils/header.component";
import OptionCard from "components/utils/cards/optionCard.component";
import SelectedAccordionView from "components/assessment/selectedAccordionView.component";
import { assessmentTypeIds } from "utils/assessmentConstants";
//ICONS
import { GiPencilRuler } from "react-icons/gi";
import { GiSpeedometer } from "react-icons/gi";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
  },
  leftContents: {
    flex: 4,
    marginRight: 30,
  },
  rightContents: {
    flex: 3,
    maxHeight: 600,
    position: "sticky",
    top: 0,
    marginLeft: 30,
  },
  cardWrapper: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  innerContentContainer: {
    marginTop: 30,
  },
  leftInnerContent: {
    marginLeft: 0,
    // Makes each panel scrollable beyond a certain Height.
    // Subtraction value is a rough sum of external elements (stickyHeader, stepperWrapper, appBar)
    maxHeight: "calc(100vh - 470px)",
    overflowY: "scroll",
  },
  rightInnerContent: {
    marginTop: 60,
  },
  cardsContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    paddingBottom: 30,
  },
  icon: {
    fontSize: 80,
  },
  selectionMessage: {
    width: "100%",
    paddingTop: 20,
    textAlign: "center",
  },
  selectedStatus: {
    color: variables.textSecondary,
    fontSize: variables.fontXl,
  },
  selected: {
    color: variables.secondaryDark,
  },
}));
const designTypeID = assessmentTypeIds.ASSESS_TYPE_DESIGN;
const perfTypeID = assessmentTypeIds.ASSESS_TYPE_PERFORMANCE;
const allTypeID = assessmentTypeIds.ASSESS_TYPE_ALL;

const selectionText = (type) => {
  if (type === designTypeID) {
    return "You've selected Design Type Assessment";
  } else if (type === perfTypeID) {
    return "You've Selected Performance Type Assessment";
  } else if (type === allTypeID) {
    return "You've Selected Design & Performance Type Assessment";
  }
  return "No Assessment Type Has Been Selected";
};

const AssessmentTypeOptions = ({
  assessmentType,
  assessItems,
  programID,
  programComponentList,
  handleSave,
}) => {
  const classes = useStyles();
  const [selectedType, setSelectedType] = useState({
    [designTypeID]: false,
    [perfTypeID]: false,
  });

  useEffect(() => {
    if (assessmentType) {
      if (assessmentType === allTypeID) {
        setSelectedType({ [designTypeID]: true, [perfTypeID]: true });
      } else {
        setSelectedType({
          [designTypeID]: assessmentType === designTypeID,
          [perfTypeID]: assessmentType === perfTypeID,
        });
      }
    }
  }, [assessmentType]);

  const cardClick = (typeID) => {
    const newSelectedType = {
      ...selectedType,
      [typeID]: !selectedType[typeID],
    };
    handleSave(3, null, null, [newSelectedType])
    setSelectedType(newSelectedType);
  };

  const isInactive = (typeID) => {
    if (assessmentType === typeID || assessmentType === allTypeID) {
      return false;
    }
    return true;
  };

  const isSelected = (typeID) => {
    return typeID === assessmentType || assessmentType === allTypeID;
  };

  return (
    <div className={classes.container}>
      {/* ======== LEFT SIDE ======= */}
      <div
        className={classNames(classes.contentContainer, classes.leftContents)}
      >
        <HeaderPrimary variant="h4Primary">
          Assessment Items:
        </HeaderPrimary>

        <div
          className={classNames(
            classes.innerContentContainer,
            classes.leftInnerContent
          )}
        >
          {!!programComponentList && (
            <SelectedAccordionView
              programID={programID}
              assessItems={assessItems}
              programComponentList={programComponentList}
            />
          )}
        </div>
      </div>
      {/* ======== RIGHT SIDE ======= */}
      <div
        className={classNames(classes.contentContainer, classes.rightContents)}
      >
        <HeaderPrimary variant="h4Primary" noTransform>
          Select One or Both Assessment Type Options:
        </HeaderPrimary>

        <div
          className={classNames(
            classes.innerContentContainer,
            classes.rightInnerContent
          )}
        >
          <div className={classes.cardsContainer}>
            <div className={classes.cardWrapper}>

              <OptionCard
                variant="optionCard"
                title="Design"
                onClick={() => cardClick(designTypeID)}
                notSelected={isInactive(designTypeID)}
                iconComponent={<GiPencilRuler className={classes.icon} />}
                selected={isSelected(designTypeID)}
              />
            </div>
            <div className={classes.cardWrapper}>
              <OptionCard
                variant="optionCard"
                title="Performance"
                onClick={() => cardClick(perfTypeID)}
                notSelected={isInactive(perfTypeID)}
                iconComponent={<GiSpeedometer className={classes.icon} />}
                selected={isSelected(perfTypeID)}
              />
            </div>
          </div>
          <div
            className={classNames(
              classes.selectionMessage,
              classes.selectedStatus,
              !assessmentType ? undefined : classes.selected
            )}
          >
            {selectionText(assessmentType)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentTypeOptions;
