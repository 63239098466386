import ButtonDefault from "components/utils/buttonDefault.component";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { CheckCircleOutline } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  saveIcon: {
    marginRight: 8
  }
}));

export default function DoneButton(props) {
  const classes = useStyles();
  const {
    children, background = "primary", disabled, onClick, dataCyProp
  } = props;

  return (
    <div className={classes.buttonWrapper}>
      <ButtonDefault
        variant="medium"
        background={background}
        startIcon={(
          <CheckCircleOutline className={classNames(classes.saveIcon)} />
        )}
        onClick={onClick}
        disabled={disabled}
        data-cy={dataCyProp}
        disableReadOnlyUsers
      >
        {children || "Done"}
      </ButtonDefault>
    </div>
  );
}
