import { makeStyles } from "@material-ui/core";
import LabelInput from "components/utils/form-elements/labelInput.component";
import EditableInputCellWrapper from "./editableInputCellWrapper.component";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  labelInput: {
    margin: "-3px -8px -14px",
    width: "calc(100% + 16px)",
    minWidth: 80,
    maxWidth: 280,
    fontSize: "0.8rem",
    letterSpacing: 0,
    "&.Mui-error": {
      marginBottom: 0,
    },
    "& + p": {
      margin: "-3px  0 0 -8px",
      width: "calc(100% + 10px)",
    },
  },
  labelInputMultiline: {
    width: "calc(100% + 10px)",
    padding: "8px !important"
  },
  input: {
    width: "auto",
  },
  inputSingleLine: {
    padding: "8px 8px"
  },
  inputWrapper: {
    minWidth: 80
  }
}));

export default function EditableTextCell(props) {
  const classes = useStyles();
  const {
    name, defaultValue, align, className, multiline = true,
    onAutosave, required, validations
  } = props;

  return (
    <EditableInputCellWrapper
      name={name}
      defaultValue={defaultValue}
      align={align}
      className={classes.inputWrapper}
      onAutosave={onAutosave}
      required={required}
      validations={validations}
    >
      {inputProps => (
        <LabelInput
          {...inputProps}
          variant="default"
          margin="dense"
          multiline={multiline}
          className={classNames(
            classes.labelInput,
            className,
            inputProps.className,
            multiline && classes.labelInputMultiline
          )}
          inputProps={{
            className: classNames(
              classes.input,
              inputProps.className,
              !multiline && classes.inputSingleLine
            )
          }}
          autoFocus
        />
      )}
    </EditableInputCellWrapper>
  );
}
