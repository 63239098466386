import React from "react";
import { makeStyles } from "@material-ui/core/styles";


/* Useful for tables that my have empty values when using DisplayHTML
*/

const useStyles = makeStyles((theme) => ({
  emptyCell: {
    display: "flex",
    justifyContent: "center",
  },
}));

export const EmptyCellValue = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.emptyCell}>
      {children || "--"}
    </div>
  );
};