import React, { useState, useEffect, useMemo, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Link } from "@material-ui/core";
import CustomCard from "components/utils/card.component";
import variables from "styleVariables";
import { sortByStringKey } from "utils/sortingFuncs";
import moment from "moment";
import { objectCountMapper } from "utils/objectMappers";
import classNames from "classnames";
import { PROGRAM_ID_PORTFOLIO, PROGRAM_ID_RUNBOOK, PROGRAM_ID_ORCHESTRATION, COMPONENT_ID_TRAINEE} from "utils/programConstants";
import PortfolioService from "services/portfolio.service";
import RunbookService from "services/runbook.service";
import { portfolioProgramComponentIDs } from "utils/portfolioConstants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { isTrainee } from "utils/roles";
import { setStateFetchEffect } from "utils/ajaxHelpers";
import ProgramsContext from "contexts/programs.context";
import AlertContext from "contexts/alert.context";
import WorkpackageTasksContext from "contexts/workpackageTasks.context";
import { groupObjectArrayByKey } from "utils/arrayOfObjectsHelpers";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(30),
      height: theme.spacing(20),
    },
  },
  dashContainer: {
    // padding: 15,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  mainContentContainer: {
    marginTop: 5,
  },
  infoWrapper: {
    backgroundColor: "white",
    zIndex: 5,
    alignSelf: "flex-start",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 25,
    paddingRight: 20,
    opacity: 0.8,
    minHeight: 74,
  },
  infoRow: {
    fontSize: variables.fontSmall,
    paddingTop: 3,
    "&:last-of-type": {
      paddingBottom: 5,
    },
    "&:first-of-type": {
      paddingTop: 5,
    },
  },
  bold: {
    fontWeight: "bold",
  },
  gray: {
    color: variables.textSecondary
  },
  tertiary2: {
    color: variables.tertiary2,
  },
  red: {
    color: variables.warningMain
  },
  secondaryBlue: {
    color: variables.secondaryDark
  },
  portfolioInfoBox: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 5
  },

  holdingName: {
    marginBottom: 3,
    fontSize: variables.fontSmall,
    color: variables.textSecondary
  },
  holderChild: {
    marginBottom: 3,
    fontSize: variables.fontSmall,
  },
  runbookInfoBox: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 3
  },
}));

const OrchPrograms = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { state: alertState } = useContext(AlertContext);
  const { state: programState } = useContext(ProgramsContext);
  const { state: wpTaskState } = useContext(WorkpackageTasksContext);

  const isTraineeRole = useMemo(isTrainee, []);

  useEffect(() => {
    if (isTraineeRole) {
      history.push(`/program/${PROGRAM_ID_ORCHESTRATION}/${COMPONENT_ID_TRAINEE}`);
    }
  }, [isTraineeRole, history]);

  const alertCountMap = useMemo(() => (
    !alertState.userAlerts ?
      null :
      objectCountMapper("Program_Program_ID", alertState.userAlerts)
  ), [alertState.userAlerts]);

  const cardPrograms = useMemo(() => {
    if (!programState.programs) {
      return null;
    }
    const nonOrchestrationPrograms = { ...programState.programs };
    delete nonOrchestrationPrograms[PROGRAM_ID_ORCHESTRATION];

    const sortedByName = sortByStringKey(
      Object.values(nonOrchestrationPrograms),
      "Name"
    );
    return sortByStringKey(sortedByName, "Status");
  }, [programState.programs]);

  const cardProgramsActive = useMemo(() => (
    cardPrograms?.filter?.(program => program.Status === "Active")
  ), [cardPrograms]);

  if (cardPrograms?.length && alertCountMap) {
    if (props.variant !== "dashboard") {
      return (
        <Grid container spacing={8} className={classes.mainContentContainer}>
          <Grid item sm={12} lg={12}>
            <div className={classes.root}>
              {cardPrograms.map((card) => (
                <CustomCard
                  variant={
                    card.Status === "Active" ? "program" : "programDisabled"
                  }
                  key={card.Name}
                  icon={card.Program_Icon}
                  title={card.Name}
                  date={
                    "Updated: " + moment(card.Change_Date).format("M/D/YYYY")
                  }
                  description={card.Description}
                  // TODO: Make Dynamic by different Holding Companies
                  url={`/program/${card.Program_ID}`}
                >
                  <InfoBox
                    alertCountMap={alertCountMap}
                    progId={card.Program_ID}
                    wpTaskState={wpTaskState}
                  />
                </CustomCard>
              ))}
            </div>
          </Grid>
        </Grid>
      );
    } else if (props.variant === "dashboard") {
      return (
        <Paper elevation={0} className={classes.dashContainer}>
          {cardProgramsActive?.map?.((card) => (
            <CustomCard
              variant={
                card.Status === "Active"
                  ? "dashboardProgram"
                  : "dashboardProgramDisabled"
              }
              key={card.Name}
              icon={card.Program_Icon}
              title={card.Name}
              // TODO: Make Dynamic by different Holding Companies
              url={`/program/${card.Program_ID}`}
              test={card.Name}
            >
              <InfoBox
                alertCountMap={alertCountMap}
                progId={card.Program_ID}
                wpTaskState={wpTaskState}
              />
            </CustomCard>
          ))}
        </Paper>
      );
    }
  } else {
    return "";
  }
};

export default OrchPrograms;


const FieldCountDisplay = ({ count, subject, progId }) => {
  const classes = useStyles();

  const countDisplay = count || "No";
  const subjectDisplay = `${subject}${!count || count > 1 ? "s" : ""}`;

  const colorClass = useMemo(() => {
    if (!count) {
      return classes.gray;
    }
    switch (subject) {
      case "Alert":
      case "Open Incident":
        return classes.red;
      case "Workpackage":
      case "Runbook":
        return classes.secondaryBlue;
      default:
        return classes.tertiary2;
    }
  }, [classes, count, subject]);

  return (
    <Link href={`/program/${progId}`}>
      <span className={colorClass}>
        {countDisplay} {subjectDisplay}
      </span>
    </Link>
  );
};


const PortfolioCardInfo = ({ programId }) => {
  const classes = useStyles();
  const [holderAssessments, setHolderAssessments] = useState(null);

  useEffect(() => (
    setStateFetchEffect(
      PortfolioService.getHolderAssessmentsIndex(),
      ([holderAssessmentsRes]) => {
        setHolderAssessments(holderAssessmentsRes.payload);
      }
    )
  ), []);

  const assessmentsByHoldingCoId = useMemo(() => (
    Object.entries(
      groupObjectArrayByKey(holderAssessments, "Holder_Name") || []
    )
  ), [holderAssessments]);

  const holdersCount = useMemo(() => (
    assessmentsByHoldingCoId?.length || 0
  ), [assessmentsByHoldingCoId]);

  const assessmentsCount = useMemo(() => (
    !assessmentsByHoldingCoId ? 0 :
      assessmentsByHoldingCoId.reduce((sum, [_id, assessments]) => (
        sum + assessments.length
      ), 0)
  ), [assessmentsByHoldingCoId]);

  if (!holderAssessments) {
    return null;
  }
  if (!assessmentsByHoldingCoId?.length) {
    return (
      <div className={classes.portfolioInfoBox}>
        <div className={classNames(classes.bold, classes.holdingName)}>
          No holding companies
        </div>
      </div>
    );
  }
  if (holdersCount >= 4 || assessmentsCount > 3) {
    return (
      <div className={classes.portfolioInfoBox}>
        <span>
          <div className={classNames(classes.bold, classes.holdingName)}>
            {assessmentsByHoldingCoId.length} Holding Companies
          </div>
          <Link
            href={
              `/program/${programId}/${portfolioProgramComponentIDs.ASSESSMENTS}`
            }
          >
            <div className={classNames(classes.bold, classes.holderChild)}>
              {assessmentsByHoldingCoId.reduce((sum, [name, assessments]) => (
                sum + assessments.length
              ), 0)}&nbsp;Assessments
            </div>
          </Link>
        </span>
      </div>
    );
  }
  if (holdersCount > 1 || assessmentsCount > 2) {
    return (
      <div className={classes.portfolioInfoBox}>
        {assessmentsByHoldingCoId.map(([holderName, assessments]) => (
          <span key={assessments[0].PortfolioHolder_ID}>
            <Link
              href={
                `/program/${programId}/` +
                portfolioProgramComponentIDs.ASSESSMENTS +
                `/holding/${assessments[0].PortfolioHolder_ID}/select`
              }
            >
              <div className={classNames(classes.bold, classes.holderChild)}>
                {holderName}
              </div>
            </Link>
          </span>
        ))}
      </div>
    );
  }
  return (
    <div className={classes.portfolioInfoBox}>
      {assessmentsByHoldingCoId.map(([holderName, assessments]) => (
        <React.Fragment key={holderName}>
          <span className={classNames(classes.bold, classes.holdingName)}>
            {holderName}
          </span>
          {assessments.map(assessment => (
            <span key={assessment.HolderAssessment_ID}>
              <Link
                href={
                  `/program/${programId}/` +
                  portfolioProgramComponentIDs.ASSESSMENTS +
                  `/holding/${assessment.PortfolioHolder_ID}/select`
                }
              >
                <div className={classNames(classes.bold, classes.holderChild)}>
                  {assessment.Assessment_Name}
                </div>
              </Link>
            </span>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};

const RunbookCardInfo = ({ programId }) => {
  const classes = useStyles();
  const [runbookIndex, setRunbookIndex] = useState();

  useEffect(() => setStateFetchEffect(
    RunbookService.getRunbookIndex(),
    ([indexResponse]) => {
      setRunbookIndex(indexResponse.payload);
    }
  ), []);

  const openIncidentCount = useMemo(() => (
    !runbookIndex ?
      "" :
      runbookIndex
        .flatMap(indexItem => indexItem._associations?.Incidents || [])
        .filter(incident => incident.Status === "Open")
        ?.length || 0
  ), [runbookIndex]);

  if (!runbookIndex) {
    return null;
  }
  return (
    <div className={classes.runbookInfoBox}>
      <span
        className={classNames(classes.bold, classes.infoRow)}
        data-cy={`program${programId}-active-runbook-count`}
      >
        <FieldCountDisplay count={runbookIndex?.length}
          subject="Runbook"
          progId={programId}
        />
      </span>
      <span
        className={classNames(classes.bold, classes.infoRow)}
        data-cy={`program${programId}-open-incident-count`}
      >
        <FieldCountDisplay count={openIncidentCount}
          subject="Open Incident"
          progId={programId}
        />
      </span>
    </div>
  );
};

const InfoBox = props => {
  const classes = useStyles();
  const { alertCountMap, progId, wpTaskState } = props;

  if (!wpTaskState.workpackages || !wpTaskState.tasks) {
    return null;
  }
  return (
    <div className={classes.infoWrapper}>
      {progId === PROGRAM_ID_PORTFOLIO ? (
        <PortfolioCardInfo programId={progId} />
      ) : (
        progId === PROGRAM_ID_RUNBOOK ? (
          <RunbookCardInfo programId={progId} />
        ) : (
          <>
            <span
              className={classNames(classes.bold, classes.infoRow)}
              data-cy={`program${progId}-workpackage-count`}
            >
              <FieldCountDisplay
                count={wpTaskState.workpackagesByProgramId?.[progId]?.length}
                subject="Workpackage"
                progId={progId}
              />
            </span>

            <span
              className={classNames(classes.bold, classes.infoRow)}
              data-cy={`program${progId}-task-count`}
            >
              <FieldCountDisplay
                count={wpTaskState.tasksByProgramId?.[progId]?.length}
                subject="Task"
                progId={progId}
              />
            </span>

            <span
              className={classNames(classes.bold, classes.infoRow)}
              data-cy={`program${progId}-alert-count`}
            >
              <FieldCountDisplay
                count={alertCountMap[progId]}
                subject="Alert"
                progId={progId}
              />
            </span>
          </>
        )
      )}
    </div>
  );
};
