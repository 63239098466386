import { useContext, useEffect, useMemo } from 'react';
import { useHistory } from "react-router-dom";

// components
import Loader from "components/utils/loader.components";
import PortfolioService from 'services/portfolio.service';
import { isTrainee, getIsRunbookOnlyRole, getIsPortfolioOnlyRole } from 'utils/roles';
import { COMPONENT_ID_TRAINEE, PROGRAM_ID_ORCHESTRATION, PROGRAM_ID_RUNBOOK } from 'utils/programConstants';
import ProgramsContext from 'contexts/programs.context';

const HomeRouter = () => {
  const history = useHistory();
  const { state } = useContext(ProgramsContext);

  const isPortfolioRole = useMemo(getIsPortfolioOnlyRole, []);
  const isTraineeRole = useMemo(isTrainee, []);
  const isRunbookRole = useMemo(getIsRunbookOnlyRole, []);

  useEffect(() => {
    if (isPortfolioRole) {
      PortfolioService.getPortDashParams()
        .then((res) => {
          const paramsRes = res[0];
          if (res[0] && !paramsRes.PortCo_ID) {
            history.push(`/program/11/holding/${paramsRes.PortfolioHolder_ID}`);
          } else if (res[0]) {
            history.push(`/program/11/holding/${paramsRes.PortfolioHolder_ID}/portCo/${paramsRes.PortCo_ID}`);
          } else {
            // TODO: error handling - when a user does not belong to a holding/portCo company
            history.push(`/logout`);
          }
        });
    } else if (isTraineeRole) {
      history.push(`/program/${PROGRAM_ID_ORCHESTRATION}/${COMPONENT_ID_TRAINEE}`);
    } else if (isRunbookRole) {
      history.push(`/program/${PROGRAM_ID_RUNBOOK}`);
    } else {
      // look for Active Orchestration Program
      const orchProgram = state.programs?.[PROGRAM_ID_ORCHESTRATION];
      if (orchProgram && orchProgram.Status === "Active") {
        return history.push(`/program/${PROGRAM_ID_ORCHESTRATION}`);
      }
      const programList = Object.values(state.programs || {});
      if (programList.length) {
        const defaultActiveProgram = programList.find(program => (
          program.Status === "Active"
        ));
        if (defaultActiveProgram) {
          history.push(`/program/${defaultActiveProgram.Program_ID}`);
        } else {
          history.push("/notFound");
        }
      }
    }
  }, [history, isPortfolioRole, isRunbookRole, isTraineeRole, state.programs]);

  return (<Loader />);
}

export default HomeRouter;
