import React from 'react';
import HeaderPrimary from "components/utils/header.component";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({

  subHeader: {
    padding: "5px 0px 20px 0px",
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 17,
    width: "100%",
  },
  subHeaderSection: {
    display: "flex",
    flexDirection: "column"
  },
  headerStyle: {
    paddingTop: 0,
    paddingBottom: 0,
  },

}));

export const FormSubHeader = (props) => {
  const classes = useStyles();

  if (props.variant === "groupProduct") {
    return (
      <div className={classes.subHeader}>
        <div className={classes.subHeaderSection}>
          <HeaderPrimary
            variant="h3Primary"
            className={classes.headerStyle}
          >
            Product
          </HeaderPrimary>
          <HeaderPrimary
            variant="h2Primary"
            className={classes.headerStyle}
          >
            {props.product}
          </HeaderPrimary>
        </div>
        <div className={classes.subHeaderSection}>
          <HeaderPrimary
            variant="h3Primary"
            className={classes.headerStyle}
          >
            Group
          </HeaderPrimary>
          <HeaderPrimary
            variant="h2Primary"
            className={classes.headerStyle}
          >
            {props.group}
          </HeaderPrimary>
        </div>
      </div>
    );
  } else if (props.variant === "groupProductSmall") {
    return (
      <div className={classes.subHeader}>
        <div className={classes.subHeaderSection}>
          <HeaderPrimary
            variant="h4Primary"
            className={classes.headerStyle}
          >
            Product
          </HeaderPrimary>
          <HeaderPrimary
            variant="h3Primary"
            className={classes.headerStyle}
          >
            {props.product}
          </HeaderPrimary>
        </div>
        <div className={classes.subHeaderSection}>
          <HeaderPrimary
            variant="h4Primary"
            className={classes.headerStyle}
          >
            Group
          </HeaderPrimary>
          <HeaderPrimary
            variant="h3Primary"
            className={classes.headerStyle}
          >
            {props.group}
          </HeaderPrimary>
        </div>
      </div>
    );
  }
  return null;
}

export default FormSubHeader;
