import React, { useState } from "react";
import { newTempId } from "utils/idGenerators";
import {
  increaseFieldValueByOne,
} from "utils/arrayOfObjectsHelpers";
import HeaderPrimary from "components/utils/header.component";
import SelectedAccordionView from "components/assessment/selectedAccordionView.component";
import { stringsAreIdentical } from "utils/stringFuncs";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { defaultChangeDate } from "utils/dateHelpers";
import { currentUserID } from "utils/userHelpers";

const useStyles = makeStyles((theme) => ({
  checkListPageContainer: {
    display: "flex",
    width: "100%",
    maxWidth: 1000,
    margin: "0px auto",
  },
  accordionWrapper: {
    paddingTop: 20
  },
  contentContainer: {
    width: "100%",
  }
}));

const AddChecklist = ({
  checkListData,
  setCheckListData,
  assessItems,
  builderId,
  assessmentType,
  programID,
  programComponentList,
  formErrors,
  handleSave,
  isSavingChecklistItems,
}) => {

  const classes = useStyles();
  const [editItemObj, setEditItemObj] = useState({});
  const [activeInput, setActiveInput] = useState(null);

  const keyHandler = (event) => {
    if (event.key === "Enter") {
      event.target.blur()
    }
  };

  const addChecklistItem = (revType, selectedAssessItem, newChecklistItem) => {
    const assessItemId = selectedAssessItem.AssessmentItem_ID;
    const itemsLength = checkListData.filter((item) => (
      item.AssessmentItem_ID === assessItemId &&
      item.Review_Type === revType
    )).length;

    //Check if input is empty
    const itemBeingAdded = newChecklistItem;
    if (itemBeingAdded && itemBeingAdded.trim() !== "") {
      const newCheckItem = {
        ChecklistItem_ID: newTempId(checkListData, "ChecklistItem_ID"),
        AssessmentBuilder_ID: builderId,
        AssessmentItem_ID: assessItemId,
        Checklist_Item: itemBeingAdded,
        Review_Type: revType,
        Item_Order: itemsLength + 1,
      };

      handleSave(4, [newCheckItem])
    }
    setActiveInput(null);
  };

  const removeCheckItem = (checkItem, assessItemID) => {
    const checkItemType = checkItem.Review_Type;
    // 1. Update Order of checklist items below deleted item
    const deletedItemOrder = checkItem.Item_Order;
    const affectedItems = checkListData.filter((item) => (
      item.AssessmentItem_ID === assessItemID &&
      item.Review_Type === checkItemType &&
      item.Item_Order > deletedItemOrder
    ));
    const objsWithNewOrder = increaseFieldValueByOne(affectedItems, "Item_Order");

    // 2. Updated checkListData to account for deleted item and edited items
    handleSave(4, null, [checkItem], objsWithNewOrder)
  };

  const editCheckItem = (item) => {
    if (!isSavingChecklistItems) {
      setEditItemObj(item);
    }
  };

  const editCheckListHandler = (editedItemText, itemID) => {
    const itemTextBeforeEdit = editItemObj.Checklist_Item;
    if (!stringsAreIdentical(itemTextBeforeEdit, editedItemText)) {
      const objectToEdit = checkListData.find(
        (item) => item.ChecklistItem_ID === itemID
      );
      const updatedEditedObj = {
        ...objectToEdit,
        Checklist_Item: editedItemText,
        Change_Date: defaultChangeDate(),
        Change_User: currentUserID()
      };
      handleSave(4, null, null, [updatedEditedObj]);
    }
    setEditItemObj({});
  };


  return (
    <div className={classes.checkListPageContainer}>
      <div className={classNames(classes.contentContainer)}>
        <div>
          <HeaderPrimary variant="h4Primary" noTransform>
            Add Checklist Items To Assessment Items:
          </HeaderPrimary>
        </div>
        {programComponentList !== null && (
          <div className={classes.accordionWrapper}>
            <SelectedAccordionView
              checkListData={checkListData}
              programID={programID}
              assessItems={assessItems?.length ? assessItems : null}
              programComponentList={programComponentList}
              assessmentType={assessmentType}
              showChecklist
              addChecklist
              addChecklistItem={addChecklistItem}
              setCheckListData={setCheckListData}
              removeCheckItem={removeCheckItem}
              editCheckItem={editCheckItem}
              editObj={editItemObj}
              editCheckListHandler={editCheckListHandler}
              activeInput={activeInput}
              setActiveInput={setActiveInput}
              formErrors={formErrors}
              handleEnterKey={keyHandler}
              handleSave={handleSave}
              isSavingChecklistItems={isSavingChecklistItems}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AddChecklist;
