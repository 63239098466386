import { useState, useEffect, useMemo } from "react";
import RunbookService from "services/runbook.service";
import { Box, makeStyles } from "@material-ui/core";
import HeaderPrimary from "components/utils/header.component.js";
import SortableTable from "components/utils/tables/sortableTable.component";
import CustomLink from "components/utils/link.component";
import moment from "moment";
import { truncate } from "utils/workPackageHelpers";
import { DATETIME_DISPLAY_NUMERIC_LONG } from "utils/dateConstants";

const useStyles = makeStyles((theme) => ({
  idColumn: {
    width: 40,
  },
  taskLink: {
    fontWeight: "bold",
  },
}));

const UpcomingRunbookTasks = (props) => {
  const classes = useStyles();
  const [upcomingTasks, setUpcomingTasks] = useState();
  const { runBoooksMapById, makeRunbookTaskPath } = props;

  useEffect(() => {
    const fetchUpcomingTasks = async () => {
      const res = await RunbookService.getUpcomingTasksForCurrentUser();
      setUpcomingTasks(res.payload);
    };
    fetchUpcomingTasks();
  }, []);

  const headers = useMemo(
    () => [
      {
        name: "Task_ID",
        display: "ID",
        sort: true,
        className: classes.idColumn,
      },
      {
        name: "Name",
        display: "Name",
        sort: true,
      },
      {
        name: "Runbook_Name",
        display: "Runbook",
        sort: true,
      },
      {
        name: "Start_Datetime",
        display: "Start Date",
        sort: true,
      },
    ],
    [classes.idColumn]
  );

  const tableRows = useMemo(
    () =>
      upcomingTasks &&
      upcomingTasks.map((task) => ({
        Task_ID: {
          value: task.Task_ID,
          node: (
            <CustomLink
              variant="routerLink"
              to={{
                pathname: makeRunbookTaskPath(task.Runbook_ID),
                state: { taskId: task.Task_ID },
              }}
              className={classes.taskLink}
              test={`runbook-task-${task.Task_ID}`}
            >
              {task.Task_ID}
            </CustomLink>
          ),
        },
        Name: task.Name,
        Runbook_Name: runBoooksMapById?.[task.Runbook_ID]?.Name
          ? truncate(runBoooksMapById[task.Runbook_ID]?.Name)
          : "",
        Start_Datetime:
          moment(task.Start_Date).format(DATETIME_DISPLAY_NUMERIC_LONG),
      })),
    [upcomingTasks, runBoooksMapById, classes.taskLink, makeRunbookTaskPath]
  );

  if (tableRows) {
    return (
      <>
        <Box marginBottom={2.5}>
          <HeaderPrimary variant="h3Primary">Upcoming Tasks</HeaderPrimary>
        </Box>
        <SortableTable
          headers={headers}
          data={tableRows}
        />
      </>
    );
  } else {
    return "";
  }
};
export default UpcomingRunbookTasks;
