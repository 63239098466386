import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router";
import {
  MenuItem,
  FormControl,
  Table,
  TableBody,
  TableRow,
  Grid,
  Card,
} from "@material-ui/core";
import TableCell from "components/utils/tables/shared/tableCell.component";
import { makeStyles } from "@material-ui/core/styles";
import CustomSelect from "components/utils/form-elements/select.component";
import ImplementationService from "services/implementation.service";
import Locations from "components/utils/locations.component";
import variables from "styleVariables";
import RemoveIcon from "components/utils/removeIcon.component";
import AddListitem from "components/utils/addListItem.component";
import CustomModal from "components/utils/modal.component";
import InterfaceForm from "components/forms/dataInterfaceForm.component";
import Dialog from "components/utils/dialog.component";
import { isReadOnly } from "utils/roles";
import { H4, H5 } from "components/utils/headerV2.component";

const useStyles = makeStyles((theme) => ({
  mainContentContainer: {
    marginTop: 15,
  },
  controlsRowWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  selectControlsWrapper: {
    paddingLeft: 10,
  },
  selectForm: {
    maxWidth: "500px",
    minWidth: "300px",
    marginRight: 40,
    marginTop: 20,
  },
  tablesContainer: {
    justifyContent: "space-between",
    marginTop: 50,
    paddingLeft: 35,
    paddingRight: 80,
  },
  innerCard: {
    marginTop: 10,
    display: "flex",
    width: "100%",
  },
  list: {
    width: 275,
    marginTop: 0,
  },
  tableCell: {
    display: "flex",
    width: 275,
    justifyContent: "space-between",
    fontSize: variables.fontSmall,
    color: variables.textSecondary,
  },
  addNewContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "20px 10px",
  },
  noInterface: {
    margin: "auto",
    padding: 16,
  },
}));

export const DataInterfaces = () => {
  const classes = useStyles();
  const location = useLocation();
  const { programId } = useParams();
  const [orgGroups, setOrgGroups] = useState([]);
  const [groupInfo, setGroupInfo] = useState({ id: null, name: null });
  const [product, setProduct] = useState("");
  const [productId, setProductId] = useState(null);
  const [interfaces, setInterfaces] = useState(null);
  const [products, setProducts] = useState();
  const [fromSearch, setFromSearch] = useState();
  const [loadSelects, setLoadSelects] = useState(false);
  //Modal
  const [editModal, setEditModal] = useState(false);
  //Dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [parameter, setParameter] = useState();

  const readOnly = isReadOnly();

  useEffect(() => {
    let groups;
    let group_Id;
    let product_Id;
    ImplementationService.getProgramOrganizationGroups(programId)
      .then((res) => {
        groups = res.payload;
        setOrgGroups(res.payload);
      })
      .then(() => {
        if (!location.state && !fromSearch && groups[0].Group_ID) {
          setLoadSelects(false);
          group_Id = groups[0].Group_ID;
          setGroupInfo({
            id: groups[0].Group_ID,
            name: groups[0].OrganizationGroup_Name,
          });
          ImplementationService.getOrganizationGroupProducts(group_Id)
            .then((res) => {
              setProduct(res.payload[0].Product_Name);
              product_Id = res.payload[0].GroupProduct_ID;
              setProductId(product_Id);
              setProducts(res.payload);
            })
            .then(() => {
              ImplementationService.getGroupProductInterfaces(product_Id).then(
                (res) => {
                  setInterfaces(res.payload);
                }
              );
            })
            .then(() => setLoadSelects(true));
        }
      });
    // If coming from search link
    if (location.state) {
      setFromSearch(true);
      const loc_product_Id = location.state.productId;
      setProductId(loc_product_Id);
      // Grabbing group id and name
      ImplementationService.getOrgGroupByProductId(loc_product_Id)
        .then((res) => {
          group_Id = res.payload.Group_ID;
          setGroupInfo({ id: res.payload.Group_ID, name: res.payload.Name });
        })
        .then(() => {
          //Setting list of products and select product
          ImplementationService.getOrganizationGroupProducts(group_Id).then(
            (res) => {
              setProduct(
                res.payload.filter(
                  (group) => group.GroupProduct_ID.toString() === loc_product_Id
                )[0].Product_Name
              );
              setProducts(res.payload);
            }
          );
        })
        .then(() => {
          ImplementationService.getGroupProductInterfaces(loc_product_Id).then(
            (res) => {
              setInterfaces(res.payload);
            }
          );
        })
        .then(() => setLoadSelects(true));
    }
  }, [location.state, fromSearch, programId]);

  const openForm = () => {
    setEditModal(true);
  };

  const toggleForm = () => {
    setEditModal(!editModal);
  };

  const handleGroup = (event) => {
    setInterfaces(null);
    setProduct(null);
    setGroupInfo({ id: event.currentTarget.id, name: event.target.value });
    ImplementationService.getOrganizationGroupProducts(
      event.currentTarget.id
    ).then((res) => {
      setProducts(res.payload);
    });
  };

  const handleProduct = (event) => {
    setProduct(event.target.value);
    setProductId(event.currentTarget.id);
    ImplementationService.getGroupProductInterfaces(
      event.currentTarget.id
    ).then((res) => {
      setInterfaces(res.payload);
    });
  };

  const deleteInterface = async (inFace) => {
    await ImplementationService.deleteInterface(inFace.Interface_ID);

    setOpenDialog(false);
    //reloading interface table
    loadInterfaceTable(productId);
  };

  const handleClickOpen = (inFace) => {
    setParameter(inFace);
    setOpenDialog(true);
  };

  const loadInterfaceTable = (loadProductId) => {
    ImplementationService.getGroupProductInterfaces(loadProductId).then((res) => {
      setInterfaces(res.payload);
    });
  };

  if (loadSelects) {
    return (
      <>
        <Grid container spacing={1} className={classes.mainContentContainer}>
          <Grid item sm={12} className={classes.controlsRowWrapper}>
            {/* SELECTS */}
            <div className={classes.selectControlsWrapper}>
              <FormControl className={classes.selectForm}>
                <CustomSelect
                  label="Select Group"
                  onChange={handleGroup}
                  value={groupInfo.name}
                  readOnlyAccessible
                  test="group"
                >
                  {!!orgGroups && orgGroups.map((group) => (
                    <MenuItem
                      key={group.Group_ID}
                      value={group.OrganizationGroup_Name}
                      id={group.Group_ID}
                    >
                      {group.OrganizationGroup_Name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>

              <FormControl className={classes.selectForm}>
                <CustomSelect
                  label="Select Product"
                  disabled={!products}
                  onChange={handleProduct}
                  value={product}
                  readOnlyAccessible
                  test="product"
                >
                  {!!products && products.map((selectedProduct) => (
                    <MenuItem
                      key={selectedProduct.GroupProduct_ID}
                      value={selectedProduct.Product_Name}
                      id={selectedProduct.GroupProduct_ID}
                    >
                      {selectedProduct.Product_Name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            </div>
          </Grid>
          {/*CONTAINER FOR BOTH TABLES*/}
          {!!product && (
            <Grid container className={classes.tablesContainer}>
              {/*INTERFACES TABLE*/}
              <div>
                <H4>Key Third Party Interfaces</H4>
                <Card className={classes.innerCard}>
                  {interfaces?.length ? (
                    <Table>
                      <TableBody
                        className={classes.list}
                        data-cy="miniTable-third-party-interfaces"
                      >
                        {interfaces.map((inFace) => (
                          <TableRow key={inFace.Interface_ID}>
                            <TableCell
                              className={classes.tableCell}
                              data-cy={`cell-${inFace.Interface_Name}`}
                            >
                              {inFace.Interface_Name}&nbsp;
                              {!readOnly && (
                                <RemoveIcon
                                  onClick={() => handleClickOpen(inFace)}
                                  test="dataInterface"
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    <H5 color="tertiary" className={classes.noInterface}>
                      No Interfaces
                    </H5>
                  )}
                </Card>
                <div className={classes.addNewContainer}>
                  <AddListitem
                    text={`Add New Interface  `}
                    onClick={() => openForm()}
                    test="add-interface"
                  />
                </div>
                <Dialog />
              </div>
              {/*LOCATIONS TABLE*/}
              <div>
                <Locations
                  productId={productId}
                  disabled={!interfaces}
                  product={product}
                  groupName={groupInfo.name}
                  groupInfo={groupInfo}
                  displayType="table"
                  title="System Data Locations"
                />
              </div>
            </Grid>
          )}
        </Grid>
        <CustomModal open={editModal} onClose={toggleForm}>
          <InterfaceForm
            setEditModal={setEditModal}
            product={product}
            productId={productId}
            groupInfo={groupInfo}
            loadInterfaceTable={loadInterfaceTable}
          />
        </CustomModal>
        {!!parameter && (
          <Dialog
            openDialog={openDialog}
            confirm={() => deleteInterface(parameter)}
            setOpenDialog={setOpenDialog}
            prompt={`Are you sure you would like to remove ${parameter.Name}?`}
          />
        )}
      </>
    );
  } else {
    return "";
  }
};

export default DataInterfaces;
