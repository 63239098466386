import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import BackButton from "components/utils/buttons/backButton.component";
import DoneButton from "components/utils/buttons/doneButton.component";

const useStyles = makeStyles((theme) => ({
  dataCollectionBar: {
    display: "flex",
    position: "fixed",
    padding: "20px 20px",
    width: "100%",
    bottom: 0,
    zIndex: 10,
    backgroundColor: "white",
    left: 0,
    justifyContent: "space-between",
    boxShadow: variables.shadowAppbar,
  },
  buttonSection: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  }
}));

const BottomControlsBar = ({ children, backClick = null, doneClick = null }) => {
  const classes = useStyles();

  return (
    <div className={classes.dataCollectionBar}>
      {backClick ? (
        <BackButton onClick={backClick} dataCyProp="back-to-table" />
      ) : (
        <div />
      )}
      <div className={classes.buttonSection}>
        {!!doneClick && (
          <DoneButton onClick={doneClick} />
        )}
        {children}
      </div>
    </div>
  );
};

export default BottomControlsBar;
