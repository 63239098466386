import moment from "moment";
import { useMemo } from "react";
import SortableTable from "components/utils/tables/sortableTable.component";
import Loader from "components/utils/loader.components";
import { Chip, makeStyles } from "@material-ui/core";
import styleVariables from "styleVariables";
import classNames from "classnames";
import CustomLink from "components/utils/link.component";
import { DATETIME_DISPLAY_NUMERIC_LONG } from "utils/dateConstants";

const useStyles = makeStyles(theme => ({
  severityChip: {
    color: "white",
    fontWeight: 600,
    fontSize: styleVariables.fontXs,
  },
  severityCritical: {
    backgroundColor: styleVariables.warningLight
  },
  severityHigh: {
    backgroundColor: styleVariables.statusAlert,
  },
  severityMedium: {
    backgroundColor: styleVariables.statusCaution,
  },
  severityLow: {
    backgroundColor: styleVariables.statusGreen,
  }
}));

const headers = [{
  name: "Severity",
  display: "Severity",
  orderBy: "Severity",
  alignment: "center",
}, {
  name: "Status",
  display: "Status",
  orderBy: "Status",
  alignment: "center",
}, {
  name: "Task_ID",
  display: "Task ID",
  orderBy: "Task_ID",
  alignment: "center",
}, {
  name: "Opened_Date",
  display: "Opened Date",
  orderBy: "Opened_Date",
  alignment: "center"
}, {
  name: "Description",
  display: "Description",
  orderBy: "Description",
}];

const SEVERITY_ORDER = {
  Critical: 1,
  High: 2,
  Medium: 3,
  Low: 4,
};



export default function IncidentsTable(props) {
  const classes = useStyles();
  const { incidents, onIncidentDescriptionClick, onTaskClick} = props;

  const severityColorClasses = useMemo(() => ({
    Critical: classes.severityCritical,
    High: classes.severityHigh,
    Medium: classes.severityMedium,
    Low: classes.severityLow,
  }), [classes]);

  const tableRows = useMemo(() => (
    incidents && incidents.map(incident => ({
      Severity: {
        value: incident.Severity,
        sortValue: SEVERITY_ORDER[incident.Severity],
        node: (
          <Chip
            className={
              classNames(
                classes.severityChip,
                severityColorClasses[incident.Severity]
              )
            }
            label={incident.Severity}
            size="small"
          />
        )
      },
      Status: incident.Status,
      Task_ID: {
        value: incident.Task_ID,
        node: !onTaskClick ? null : (
          <CustomLink
            variant="noHRef"
            onClick={event => onTaskClick(incident, event)}
          >
            {incident.Task_ID}
          </CustomLink>
        )
      },
      Opened_Date:
        moment(incident.Open_Date).format(DATETIME_DISPLAY_NUMERIC_LONG),
      Description: {
        value: incident.Description,
        node: !onIncidentDescriptionClick ? null : (
          <CustomLink
            variant="noHRef"
            onClick={event => onIncidentDescriptionClick(incident, event)}
          >
            {incident.Description}
          </CustomLink>
        )
      }
    }))
  ), [classes, incidents, severityColorClasses, onIncidentDescriptionClick, onTaskClick]);

  if (!incidents) {
    return (
      <Loader />
    );
  }
  return (
    <SortableTable
      headers={headers}
      data={tableRows}
      pagination={{ rowsPerPageOptions: [5, 10, 25] }}
      denseHeader
      denseBody
    />
  );
}
