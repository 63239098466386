import { useCallback, useEffect, useRef, useState } from "react";
import ButtonDefault from "../buttonDefault.component";
import classNames from "classnames";
import CustomLink from "../link.component";
import { makeStyles } from "@material-ui/core";
import RemoveIcon from "../removeIcon.component";
import { AddCircleOutlineOutlined, RemoveCircleOutlineOutlined, Save } from "@material-ui/icons";
import { downloadFileByRef } from "utils/downloadFile";
import FormLabel from "./formLabel.component";
import variables from "styleVariables";
import AttachmentOutlinedIcon from '@material-ui/icons/AttachmentOutlined';
import { Prompt } from 'react-router';
import { acceptableExtensionTypes } from "utils/fileConstants";

const useStyles = makeStyles(theme => {
  const itemSeparatorStyles = {
    borderTop: `1px solid ${theme.palette.border.main}`,
    marginTop: 4,
    paddingTop: 4,
  };
  return {
    addButtonWrapper: {
      marginTop: 20,
      display: "flex"
    },
    saveButtonWrapper: {
      marginLeft: 10
    },
    addIcon: {
      fontSize: variables.fontSmall,
      color: "white",
      marginRight: 5,
    },
    container: {
      marginBottom: 20,
    },
    deletedNote: {
      fontStyle: "italic"
    },
    linkContentContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    customLink: {
      flex: 1,
    },
    linkItem: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      "&:not(:first-of-type)": itemSeparatorStyles,
      "&:hover": {
        "& $attachmentIcon": {
          fill: variables.primaryLight,
        },
      },
    },
    attachmentIcon: {
      fontSize: "1rem",
      marginRight: 8,
      fill: variables.secondaryDark,
      display: "inline-block",
    },
    linkItemEmpty: {
      color: theme.palette.text.secondary
    },
    undoLink: {
      color: theme.palette.text.primary,
      pointerEvents: "all"
    },
    uploading: {
      pointerEvents: "none"
    },
    uploadsList: {
      listStyle: "none",
      padding: 0,
      marginBottom: 0
    },
    warning: { //warning not being applied correctly if defined higher in the styles object
      color: variables.warningMain,
      fontWeight: "bold",
    },
    uploadsListWithSeparator: itemSeparatorStyles
  }
});

/* The DataTransfer constructor allows adding/removing individual Files from the form's FileList.
 *   Browser support for this isn't perfect, so when not supported,
 *   the user has to use the native functionality, only adding all or removing all at once. */
const isDataTransferSupported = (() => {
  try {
    new DataTransfer();
    return true;
  } catch(error) {
    return false;
  }
})();

const initialDataTransfer = isDataTransferSupported ? new DataTransfer() : null;

/*
 * Form input to allow multiple file upload more intutively than browsers' native
 *   all-or-nothing file upload behavior.
 *
 * Files already uploaded are shown and can be marked as files to be deleted (undo allowed).
 */
export default function MultiFileUploadInput(props) {
  const { disabled, label, name, required, inputFileButtonDisabled, errorMessage } = props; // Form field props
  const { children, className, isUploading, validExtensions, saveButtonLabel } = props; // General props
  const { setHasUnsavedFileChanges, promptMessage, currentPathSubstring } = props; // Handling for nav/saving page with unsaved file changes
  const { deletedFiles, onDelete, onDeleteUndo, uploadedFiles } = props; // Uploaded file display and delete
  const classes = useStyles();
  const fileInputRef = useRef();

  const [dataTransfer, setDataTransfer] = useState(initialDataTransfer);
  const [error, setError] = useState(null);
  const [isClearing, setIsClearing] = useState(false);
  const hasUploadedFiles = !!uploadedFiles?.length;
  const hasPendingFiles = !!fileInputRef.current?.files?.length;
  const nothingToSave = fileInputRef.current && !fileInputRef.current.files.length && !deletedFiles.length;

  useEffect(() => {
    if (setHasUnsavedFileChanges && !nothingToSave) {
      setHasUnsavedFileChanges(true)
    } else {
      setHasUnsavedFileChanges(false)
    }
  }, [setHasUnsavedFileChanges, nothingToSave])


  // Render blank value on file input for one render to clear files
  useEffect(() => {
    if ((isClearing || isUploading) && dataTransfer.items.length) {
      setDataTransfer(initialDataTransfer);
    }
    if (isClearing) {
      setIsClearing(false);
    }
  }, [isClearing, isUploading, dataTransfer]);

  const handleFileInputChange = useCallback((event) => {
    let chosen = null;
    if (event.target.files instanceof File) {
      chosen = [event.target.files];
    } else {
      chosen = Array.from(event.target.files);
    }

    const isExtensionValid = chosen.every(file => (
      !validExtensions?.length ||
      validExtensions.includes(file.name.replace(/^.+(?=\.)/, ""))
    ));
    if (!isExtensionValid) {
      if (validExtensions.length === 1) {
        setError(`Must be a ${validExtensions.join(", ")} file.`);
      } else {
        setError(`File extension must be one of the following: ${validExtensions.join(", ")}`);
      }
      return;
    }

    const uploadedFileNames = uploadedFiles?.map?.(file => file.Reference_Name) || [];

    // Handle natively instead
    if (!isDataTransferSupported) {
      const invalidFiles = chosen.filter(file => uploadedFileNames.includes(file.name));
      if (invalidFiles.length) {
        const invalidNames = invalidFiles.map(file => file.name).join(", ");
        setError(`Cannot upload duplicate file names: "${invalidNames}".`);
        setIsClearing(true);
      }
      return;
    }

    const dataTransferFileNames = Array.from(dataTransfer.files || []).map(file => file.name);

    chosen = chosen.filter(file => (
      !uploadedFileNames.includes(file.name) &&
      !dataTransferFileNames.includes(file.name)
    ));
    if (!chosen.length) {
      // Reset input.files to dataTransfer.files
      const newDataTransfer = new DataTransfer();
      for (const file of dataTransfer.files) {
        newDataTransfer.items.add(file);
      }
      event.target.files = newDataTransfer.files;
      return;
    }

    // Separate instances needed so that FileList isn't shared between ref and state.
    // That would cause ref (<input>) changes to affect component state outside of React.
    const refDataTransfer = new DataTransfer();
    const stateDataTransfer = new DataTransfer();
    for (const file of dataTransfer.files) {
      refDataTransfer.items.add(file);
      stateDataTransfer.items.add(file);
    }
    for (const file of chosen) {
      refDataTransfer.items.add(file);
      stateDataTransfer.items.add(file);
    }
    event.target.files = refDataTransfer.files;
    setDataTransfer(stateDataTransfer);
  }, [dataTransfer, uploadedFiles, validExtensions]);

  const handleRemoveOne = useCallback((event, file) => {
    event.preventDefault();

    // Rebuild a new DataTransfer without "removed" item.
    // The existing DataTransfer can't be used here due to API constraints.
    // Separate instances needed for same reason described in change handler
    const refDataTransfer = new DataTransfer();
    const stateDataTransfer = new DataTransfer();
    for (const currentFile of dataTransfer.files) {
      if (currentFile !== file) {
        refDataTransfer.items.add(currentFile);
        stateDataTransfer.items.add(currentFile);
      }
    }
    fileInputRef.current.files = refDataTransfer.files;
    setDataTransfer(stateDataTransfer);
  }, [dataTransfer, fileInputRef])

  const handleRemoveAll = () => {
    setIsClearing(true);
  }

  return (
    <div className={classNames(isUploading && classes.uploading)}>
      <FormLabel
        htmlFor={name}
        label={<>{label}{!!required && "*"}</>}
        error={error}
        variant="default"
      />
      {!!errorMessage &&
        <div className={classes.warning}>{errorMessage}</div>
      }
      <div className={classNames(classes.container, className)}>
        <div className={classNames(classes.content)}>
          {!!hasUploadedFiles && (
            <ul className={classNames(classes.uploadsList)}>
              {uploadedFiles.map((file, index) => {
                const isDeleted = !!deletedFiles?.includes(file);
                return (
                  <li
                    className={classNames(classes.linkItem)}
                    key={file.File_Ref}
                    data-cy={`uploaded-file-${index}`}
                  >
                    <AttachmentOutlinedIcon className={classes.attachmentIcon} />
                    <CustomLink
                      onClick={() => downloadFileByRef(file.File_Ref, file.Reference_Name)}
                      variant="iconLink"
                      className={classes.customLink}
                      disabled={isDeleted}
                      containerClassName={classNames(classes.linkContentContainer)}
                      startIcon={isDeleted ? (
                        <span
                          className={classNames(classes.undoLink)}
                          onClick={event => {
                            event.stopPropagation();
                            onDeleteUndo(file);
                          }}
                          data-cy={`undo-remove-upload-${index}`}
                        >
                          Undo
                        </span>
                      ) : (
                        disabled ? null : (
                          <RemoveIcon
                            aria-label="Remove"
                            data-name={file.Reference_Name}
                            onClick={event => {
                              event.stopPropagation();
                              onDelete(file);
                            }}
                            test={`remove-upload-${index}`}
                          />
                        )
                      )}
                    >
                      {file.Reference_Name}
                      {!!isDeleted && (
                        <span className={classNames(classes.deletedNote)}>
                          &nbsp;(deleted)
                        </span>
                      )}
                    </CustomLink>
                  </li>
                );
              })}
            </ul>
          )}
          {!!(hasPendingFiles || !hasUploadedFiles) && (
            <ul
              className={classNames(
                classes.uploadsList,
                hasPendingFiles && hasUploadedFiles && classes.uploadsListWithSeparator
              )}
            >
              {hasPendingFiles ? (
                Array.from(fileInputRef.current.files).map((file, index) => (
                  <li
                    className={classNames(classes.linkItem)}
                    key={file.name}
                    data-cy={`pending-file-${index}`}
                  >
                    <AttachmentOutlinedIcon className={classes.attachmentIcon} />
                    <CustomLink
                      href={file ? URL.createObjectURL(file) : ""}
                      target="_blank"
                      variant="iconLink"
                      className={classes.customLink}
                      containerClassName={classNames(classes.linkContentContainer)}
                      startIcon={isDataTransferSupported && !disabled ? (
                        <RemoveIcon
                          aria-label="Remove"
                          data-name={file.name}
                          onClick={event => handleRemoveOne(event, file)}
                          test={`remove-pending-${index}`}
                        />
                      ) : null}
                      disabled={disabled}
                    >
                      {file.name}
                    </CustomLink>
                  </li>
                ))
              ) : (
                <li
                  className={classNames(classes.linkItemEmpty)}
                  data-cy="empty-files-message"
                >
                  None selected
                </li>
              )}
            </ul>
          )}
        </div>
        <div className={classNames(classes.addButtonWrapper)}>
          {disabled ? null : (
            <ButtonDefault
              component="label"
              variant="small"
              background="secondary"
              startIcon={(
                <AddCircleOutlineOutlined className={classNames(classes.addIcon)} />
              )}
              disabled={disabled || inputFileButtonDisabled}
              disableReadOnlyUsers
              data-cy="btn-add-file"
            >
              <span>
                {children}
              </span>
              <input
                name={name}
                id={name}
                type="file"
                accept={acceptableExtensionTypes.toString()}
                ref={fileInputRef}
                style={{ display: "none" }}
                key={isUploading || isClearing ? new Date() : undefined}
                onChange={handleFileInputChange}
                data-cy="fileinput-attach-files"
                multiple
              />
            </ButtonDefault>
          )}
          {!!saveButtonLabel && !disabled && (
            <ButtonDefault
              variant="small"
              background="primary"
              type="submit"
              className={classes.saveButtonWrapper}
              startIcon={<Save className={classNames(classes.addIcon)} />}
              data-cy="btn-save-references"
              disabled={nothingToSave}
              disableReadOnlyUsers
            >
              <div>{saveButtonLabel}</div>
            </ButtonDefault>
          )}
          {!isDataTransferSupported && (
            <ButtonDefault
              component="label"
              variant="small"
              background="red"
              onClick={handleRemoveAll}
              startIcon={(
                <RemoveCircleOutlineOutlined
                  className={classNames(classes.addIcon)}
                />
              )}
              disabled={(hasPendingFiles || disabled)}
              data-cy="btn-clear-all-files"
              disableReadOnlyUsers
            >
              <span>
                Clear all
              </span>
            </ButtonDefault>
          )}
          {!!promptMessage && (
            <Prompt
              when={nothingToSave === false}
              message={(location) => {
                if (location.pathname.includes(currentPathSubstring)) {
                  return;
                }
                return promptMessage
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}
