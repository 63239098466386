import { removeObjInArray } from "utils/arrayOfObjectsHelpers";
import { mergeNestedAssociationDelete, mergeNestedAssociationReplace } from "./utils/payloadUtils";
import { mergeAssociationReplace } from "./utils/payloadUtils";
import { mergeReplace } from "./utils/payloadUtils";

export const ACTION_SET_ASSESSMENTS = "assessments__set";
export const ACTION_SET_ASSESSMENT_ITEMS = "assessmentItems__set";
export const ACTION_SET_FOLLOW_UPS = "followUps__set";
export const ACTION_SET_PORTFOLIO_USERS = "portfolioUsers__set";
export const ACTION_SET_REPORTS = "reports__set";
export const ACTION_SET_REPORT_DOCS = "reports__supportingDocs__set";
export const ACTION_SET_PORTCOS = "portCos__set";

export const ACTION_CREATE_ASSESSMENT = "assessments__create";
export const ACTION_CREATE_SESSION = "sessions__create";
export const ACTION_CREATE_REPORT = "reports__create";
export const ACTION_CREATE_REPORT_SECTION = "reports_section__create";
export const ACTION_CREATE_REPORT_SUBSECTION = "reports_subsection__create";
export const ACTION_CREATE_REPORT_DOCS = "reports__supportingDocs__create";

export const ACTION_REPLACE_SESSION_ATTENDEE = "sessions_attendees__replace";
export const ACTION_REPLACE_SESSION_FOLLOW_UP = "sessions_followUp__replace";
export const ACTION_REPLACE_SESSION_NOTES = "sessions_notes__replace";
export const ACTION_REPLACE_REPORT = "report__replace";
export const ACTION_REPLACE_REPORT_SECTION = "report_section__replace";
export const ACTION_REPLACE_REPORT_SCORE = "report_score__replace";
export const ACTION_REPLACE_REPORT_DOCS = "reports__supportingDocs__replace";
export const ACTION_REPLACE_ASSESMENT_ITEM = "assessment__item_replace"

export const ACTION_DELETE_REPORT_DOCS = "reports__supportingDocs__delete";
export const ACTION_DELETE_SESSION_ATTENDEE = "sessions_attendees__delete";
export const ACTION_DELETE_SESSION_FOLLOW_UP = "sessions_followUp__delete";

export const portfolioReportsInitialState = {
  assessments: null,
  assessmentItems: null,
  portfolioUsers: null,
  reports: null,
  reportDocs: null,
};

export default function portfolioReportsReducer(state, action) {
  switch (action.type) {
    case ACTION_SET_PORTCOS:
      return {
        ...state,
        portfolioCompanies: action.payload,
      };
    case ACTION_SET_FOLLOW_UPS:
      return {
        ...state,
        followUps: action.payload
      };
    case ACTION_SET_ASSESSMENTS:
      return {
        ...state,
        assessments: action.payload
      }
    case ACTION_SET_ASSESSMENT_ITEMS:
      return {
        ...state,
        assessmentItems: action.payload
      }
    case ACTION_SET_REPORTS:
      return {
        ...state,
        reports: action.payload,
      };
    case ACTION_SET_REPORT_DOCS:
      return {
        ...state,
        reportDocs: action.payload,
      };
    case ACTION_SET_PORTFOLIO_USERS:
      return {
        ...state,
        portfolioUsers: action.payload
      }

    case ACTION_CREATE_ASSESSMENT:
      return {
        ...state,
        assessments: [
          ...state.assessments,
          action.payload
        ]
      }
    case ACTION_CREATE_SESSION:
      return {
        ...state,
        assessments: mergeAssociationReplace(
          state.assessments,
          action.payload,
          "Session_ID",
          "AssessmentSession",
          "Session_ID"
        )
      }

    case ACTION_REPLACE_ASSESMENT_ITEM:
      const newList = mergeReplace(
        state.assessmentItems,
        action.payload,
        "Item_ID",
      )
      return {
        ...state,
        assessmentItems: [...newList]
      };

    case ACTION_REPLACE_REPORT:
    case ACTION_CREATE_REPORT:
      return {
        ...state,
        reports: mergeReplace(
          state.reports,
          action.payload,
          "Report_ID",
        )
      };

    case ACTION_REPLACE_REPORT_DOCS:
    case ACTION_CREATE_REPORT_DOCS:
      return {
        ...state,
        reportDocs: mergeReplace(
          state.reportDocs,
          action.payload,
          "Document_ID",
        ),
        assessments: !action.meta.update.SessionFollowUps ?
          state.assessments :
          mergeNestedAssociationReplace(
            state.assessments,
            action.meta.update.SessionFollowUps,
            "Session_ID",
            "FollowUp_ID",
            "AssessmentStatus",
            "AssessmentSession",
            "SessionFollowUps"
          )
      };

    case ACTION_CREATE_REPORT_SECTION:
    case ACTION_CREATE_REPORT_SUBSECTION:
    case ACTION_REPLACE_REPORT_SECTION:
      return {
        ...state,
        reports: mergeAssociationReplace(
          state.reports,
          action.payload,
          "Report_ID",
          "ReportSections",
          "ReportSection_ID"
        ),
      };

    case ACTION_REPLACE_REPORT_SCORE:
      return {
        ...state,
        reports: mergeNestedAssociationReplace(
          state.reports,
          action.payload,
          "ReportSubSection_ID",
          "Score_ID",
          "ReportSections",
          "ReportSubSections",
          "AssessmentCategoryScores"
        ),
      };

    case ACTION_DELETE_REPORT_DOCS:
      return {
        ...state,
        reportDocs: [
          ...removeObjInArray(
            state.reportDocs,
            "Document_ID",
            action.payload
          )
        ],
        assessments: !action.meta.update.SessionFollowUps ?
          state.assessments :
          mergeNestedAssociationReplace(
            state.assessments,
            action.meta.update.SessionFollowUps,
            "Session_ID",
            "FollowUp_ID",
            "AssessmentStatus",
            "AssessmentSession",
            "SessionFollowUps"
          )
      };
    case ACTION_DELETE_SESSION_ATTENDEE:
      return {
        ...state,
        assessments: [...mergeNestedAssociationDelete(
          state.assessments,
          action.payload,
          "Attendee_ID",
          "AssessmentSession",
          "SessionAttendees",
        )]
      };
    case ACTION_DELETE_SESSION_FOLLOW_UP:
      return {
        ...state,
        assessments: [...mergeNestedAssociationDelete(
          state.assessments,
          action.payload,
          "FollowUp_ID",
          "AssessmentSession",
          "SessionFollowUps",
        )]
      };
    default:
      return state;
  }
}
