import AuthService from "services/auth.service";
import { isActiveUser } from "./userHelpers";
const ROLES = AuthService.Type;

const ROLE_GROUPS = {
  ALL: Object.values(ROLES),
  GLOBAL_USERS: [
    ROLES.READONLY,
    ROLES.EDIT,
    ROLES.ADMIN,
  ],
  RUNBOOK_USERS: [
    ROLES.RUNBOOKEDIT,
    ROLES.RUNBOOKADMIN,
  ],
  PORTCO_USERS: [
    ROLES.PORTCOREAD,
    ROLES.PORTCOEDIT,
  ],
  PORTFOLIO_USERS: [
    ROLES.PORTCOREAD,
    ROLES.PORTCOEDIT,
    ROLES.HOLDINGREAD,
  ],
  PORTCO_EDIT_USERS: [
    ROLES.EDIT,
    ROLES.ADMIN,
    ROLES.PORTCOEDIT,
  ],
  PORTCO_READONLY_USERS: [
    ROLES.READONLY,
    ROLES.PORTCOREAD,
    ROLES.HOLDINGREAD,
  ],
  TRAINING_TRAINEE_SET: [
    ROLES.EDIT,
    ROLES.ADMIN,
    ROLES.TRAINEE,
  ],
  RUNBOOK_RUNBOOK_SET: [
    ROLES.EDIT,
    ROLES.ADMIN,
    ROLES.RUNBOOKEDIT,
    ROLES.RUNBOOKADMIN,
  ]
};

//This function checks against any role with read only permissions, regardless of program access
const hasReadOnlyAccess = () => {
  if (sessionStorage.length === 0) {
    return false;
  } else {
    return AuthService.checkPermissions([
      ROLES.READONLY,
      ROLES.PORTCOREAD,
      ROLES.HOLDINGREAD
    ]);
  }
}

const isReadOnly = () => {
  if (sessionStorage.length === 0) {
    return false;
  } else {
    return AuthService.checkPermissions([ROLES.READONLY]);
  }
};

const isAdmin = () => {
  if (sessionStorage.length === 0) {
    return false;
  } else {
    return AuthService.checkPermissions([ROLES.ADMIN]);
  }
};

const isEdit = () => {
  if (sessionStorage.length === 0) {
    return false;
  } else {
    return AuthService.checkPermissions([ROLES.EDIT]);
  }
}

const isHoldingRead = () => {
  if (sessionStorage.length === 0) {
    return false;
  } else {
    return AuthService.checkPermissions([ROLES.HOLDINGREAD]);
  }
}

const isPortCoRead = () => {
  if (sessionStorage.length === 0) {
    return false;
  } else {
    return AuthService.checkPermissions([ROLES.PORTCOREAD]);
  }
}

const isPortCoEdit = () => {
  if (sessionStorage.length === 0) {
    return false;
  } else {
    return AuthService.checkPermissions([ROLES.PORTCOEDIT]);
  }
}

const getIsPortfolioOnlyRole = () => {
  if (sessionStorage.length === 0) {
    return false;
  } else {
    return AuthService.checkPermissions(ROLE_GROUPS.PORTFOLIO_USERS);
  }
}

const isTrainee = () => {
  if (sessionStorage.length === 0) {
    return false;
  }
  return AuthService.checkPermissions([ROLES.TRAINEE]);
}

const isRunbookAdmin = () => {
  if (sessionStorage.length === 0) {
    return false;
  } else {
    return AuthService.checkPermissions([ROLES.RUNBOOKADMIN]);
  }
}
const isRunbookEdit = () => {
  if (sessionStorage.length === 0) {
    return false;
  } else {
    return AuthService.checkPermissions([ROLES.RUNBOOKEDIT]);
  }
}
const getIsRunbookOnlyRole = () => {
  if (sessionStorage.length === 0) {
    return false;
  } else {
    return AuthService.checkPermissions(ROLE_GROUPS.RUNBOOK_USERS);
  }
}

/* USERS BY GROUP */
const isGlobalUser = () => {
  if (sessionStorage.length === 0) {
    return false;
  } else {
    return AuthService.checkPermissions(ROLE_GROUPS.GLOBAL_USERS)
  }
}
const isPortCoGroupUser = () => {
  if (sessionStorage.length === 0) {
    return false;
  } else {
    return AuthService.checkPermissions(ROLE_GROUPS.PORTCO_USERS)
  }
}

const isPortCoGroupReadOnlyUser = () => {
  if (sessionStorage.length === 0) {
    return false;
  } else {
    return AuthService.checkPermissions(ROLE_GROUPS.PORTCO_READONLY_USERS)
  }
}

const activeGlobalUsersFilter = (users) => {
  return users.filter(user => ROLE_GROUPS.GLOBAL_USERS.includes(user.Role_ID) && isActiveUser(user))
}

const potentialTraineesFilter = (users) => {
  return users.filter(user => [ROLES.TRAINEE, ...ROLE_GROUPS.GLOBAL_USERS].includes(user.Role_ID) && isActiveUser(user))
}

const activeRbkAndGlobalUsersFilter = (users) => {
  return users.filter(user => [...ROLE_GROUPS.RUNBOOK_USERS, ...ROLE_GROUPS.GLOBAL_USERS].includes(user.Role_ID) && isActiveUser(user))
}

const activeUsersFilter = (users) => {
  return users.filter(user => isActiveUser(user))
}

const runBookAndGlobalRolesFilter = (roles) => {
  return roles.filter(role => [...ROLE_GROUPS.RUNBOOK_USERS, ...ROLE_GROUPS.GLOBAL_USERS].includes(role.Role_ID))
}



export {
  ROLE_GROUPS,
  hasReadOnlyAccess,
  isReadOnly,
  isAdmin,
  isEdit,
  isHoldingRead,
  isPortCoRead,
  isPortCoEdit,
  getIsPortfolioOnlyRole,
  isTrainee,
  isGlobalUser,
  isPortCoGroupUser,
  isPortCoGroupReadOnlyUser,
  isRunbookAdmin,
  isRunbookEdit,
  activeGlobalUsersFilter,
  getIsRunbookOnlyRole,
  potentialTraineesFilter,
  activeRbkAndGlobalUsersFilter,
  activeUsersFilter,
  runBookAndGlobalRolesFilter,
};
