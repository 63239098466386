import CertificationDctListing from "components/certification/dct/certificationDctListing.component";
import CertificationDctReconciliation from "components/certification/dct/certificationDctReconciliation.component";
import useReducerAsync from "hooks/useReducerAsync";
import React from "react";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";
import certificationReducer, { certificationInitialState } from "reducers/certification/certification.reducer";

// Base custom ProgramComponent page
const CertificationDct = () => {
  const [state, dispatch] = useReducerAsync(
    certificationReducer,
    certificationInitialState
  );

  return (
    <Switch>
      <Route
        path="/program/:programId/:programComponentId/dct-category/:dctCategoryId/:dctElementEnumeration/:mode"
      >
        <CertificationDctReconciliation state={state} dispatch={dispatch} />
      </Route>
      <Route path="/program/:programId/:programComponentId" exact>
        <CertificationDctListing state={state} dispatch={dispatch} />
      </Route>
    </Switch>
  );
};

export default CertificationDct;
