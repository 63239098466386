import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import FormBanner from "components/utils/form-elements/formBanner.component";
import FormSubHeader from "components/utils/form-elements/formSubHeader.component";
import ImplementationService from "services/implementation.service";
import LabelInput from "components/utils/form-elements/labelInput.component";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 8,
  },
  content: {
    width: 640,
    padding: "0 20px 20px 20px",
  },
  form: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingTop: 20,
    paddingBottom: 0,
    alignItems: "center"
  },
  inputWrapper: {
    paddingTop: 0,
    width: 300,
    paddingBottom: 10,
  },
  dualButtons: {
    paddingLeft: 0,
    paddingTop: 40,
  }
}));

export const IntefaceForm = (props) => {
  const classes = useStyles();
  const [interfaceItem, setInterfaceItem] = useState({value:""});
  const [isSaving, setIsSaving] = useState(false)

  const handleChange = (event) => {
    const { target } = event;
    setInterfaceItem({value: target.value})
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSaving(true)
    const newInterface = {
      Name: interfaceItem.value,
      GroupProducts_GroupProduct_ID: props.productId,
      GroupProducts_OrganizationGroups_Group_ID: props.groupInfo.id,
    };
    await ImplementationService.createInterface(newInterface);

    // reload table and close modal
    props.loadInterfaceTable(props.productId)
    props.setEditModal(false);
    setIsSaving(false);
  };

  if (interfaceItem) {
    return (
      <Grid className={classes.formContainer} data-cy="form-dataInterface">
        <FormBanner>Interfaces - Data Interfaces Update</FormBanner>
        <div className={classes.content}>
          <FormSubHeader variant="groupProductSmall"
            product={props.product}
            group={props.groupInfo.name}
          />
          <form className={classes.form}>
            <div className={classes.inputWrapper}>
              <LabelInput
                variant="default"
                label="Add New Interface"
                onChange={handleChange}
                value={interfaceItem.value}
                margin="dense"
                test="new-interface"
              />
            </div>
            <DualFormButtons
              className={classes.dualButtons}
              cancelOnClick={() => props.setEditModal(false)}
              saveOnClick={handleSubmit}
              disabled={(interfaceItem.value.trim() === "")}
              isSaving={isSaving}
            />
          </form>
        </div>
      </Grid>
    );
  } else {
    return "";
  }
};

export default IntefaceForm;
