import { useMemo } from "react";
import DataTable from "components/utils/tables/dataTable.component";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import FieldPresenceCell from "components/assessment/review/tables/cells/fieldPresenceCell.component";
import DesignStatusCell from "components/assessment/review/tables/cells/designStatusCell.component";
import PerformanceStatusCell from "components/assessment/review/tables/cells/performanceStatusCell.component";
import { assessBuilderStatus, dataCollectionStatus } from "utils/assessmentConstants";
import { getAssessmentItemName } from "utils/assessmentHelpers";
import { createNodeCellParams, createNodeHeaderParams, createValueHeaderParams } from "components/utils/tables/utils/dataTableHelpers";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  table: {
    wordBreak: "normal"
  },
  cellCta: {
    height: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
}));

const fieldPresenceSortValue = (fieldKey, row, hasDesign, hasPerformance) => {
  let value = 0;
  if (hasDesign && !!row.designItem?.[fieldKey]) {
    value += 1;
  }
  if (hasPerformance && row.performanceItems?.some?.(review => review[fieldKey])) {
    value += 2;
  }
  return value;
};

const statusSortValue = (reviewItem) => {
  switch(reviewItem?.Status) {
    case dataCollectionStatus.STATUS_COMPLETED:
      return 100;
    case dataCollectionStatus.STATUS_IN_PROGRESS:
      return 1;
    default:
      return 0;
  }
};

const statusTextValue = (reviewItems, builderInfo) => {
  const itemsArray = [].concat(reviewItems);
  const valueArray = itemsArray.map(reviewItem => {
    const date = (
      reviewItem?.Observation_Date &&
      moment(reviewItem.Observation_Date).format("MM/DD/YYYY")
    ) || "";
    switch(reviewItem?.Status) {
      case dataCollectionStatus.STATUS_COMPLETED:
        return `Completed ${date}`;
      case dataCollectionStatus.STATUS_IN_PROGRESS:
        return `In Progress ${date}`;
      case dataCollectionStatus.STATUS_NOT_STARTED:
      default:
        if (builderInfo.Status === assessBuilderStatus.BUILDER_STATUS_ASSESSED) {
          return null;
        }
        return `Not Started ${date}`;
    }
  });
  return valueArray.join(" ");
};

const staticHeaders = [
  createValueHeaderParams("id", "ID", {
    filter: false,
    setCellProps: () => ({ style: { textAlign: "center" } }),
  }),
  createValueHeaderParams("assessmentItem", "Assessment Item", {
    setCellHeaderProps: () => ({
      "data-cy": "review-table-assessment-item-header",
    }),
    setCellProps: () => ({ style: { textAlign: "center" } }),
  }),
  createValueHeaderParams("description", "Description", {
    sort: false,
    filter: false,
    setCellHeaderProps: () => ({
      style: { textAlign: "left" }
    }),
  }),
  createNodeHeaderParams("findings", "Findings", {
    filter: false,
    setCellHeaderProps: () => ({ "data-cy": "review-table-findings-header" }),
    setCellProps: () => ({ style: { textAlign: "center" } }),
  }),
  createNodeHeaderParams("recommendations", "Recommendations", {
    filter: false,
    setCellHeaderProps: () => ({ "data-cy": "review-table-recommendation-header" }),
    setCellProps: () => ({ style: { textAlign: "center" } })
  })
];

/*
 * Data table for all assessment review, listed per AssessmentItem.
 * A row can have up to one DesignDC and multiple PerformanceDCs.
 * If no reviews exist for an AssessmentItem, a mostly blank row is shown with a "Start" link to create one.
 */
export default function ReviewItemsTable(props) {
  const {
    builderInfo, setReviewPageMode, hasDesign, hasPerformance, rowData
  } = props;
  const classes = useStyles();

  const tableHeaders = useMemo(() => {
    const headers = [...staticHeaders];
    if (hasPerformance) {
      headers.splice(3, 0, createNodeHeaderParams("performance", "Performance", {
        align: "center",
        classes: classNames(classes.cellCta),
        filter: false,
        setCellHeaderProps: () => ({ "data-cy": "review-table-performance-header" }),
      }));
    }
    if (hasDesign) {
      headers.splice(3, 0, createNodeHeaderParams("design", "Design", {
        align: "center",
        classes: classNames(classes.cellCta),
        filter: false,
        setCellHeaderProps: () => ({ "data-cy": "review-table-design-header" }),
      }));
    }
    return headers;
  }, [classes, hasPerformance, hasDesign]);

  const tableCells = useMemo(() => {
    return rowData?.map((row) => {
      const description = getAssessmentItemName(row.assessmentItem);
      const hasInProgressPerformanceItems = row.performanceItems.some(review =>
        review.Status === dataCollectionStatus.STATUS_IN_PROGRESS
      );
      const fieldPresenceProps = {
        design: row.designItem,
        performanceItems: row.performanceItems,
        hasDesign,
        hasPerformance,
        setReviewPageMode
      };
      const modeStatusProps = {
        row,
        setReviewPageMode,
      };

      const performanceStatusValues = row.performanceItems.filter(review =>
        !hasInProgressPerformanceItems ||
        review.Status === dataCollectionStatus.STATUS_IN_PROGRESS
      ).reduce((sum, review) => (
        sum + statusSortValue(review)
      ), 0) || 0;

      return {
        id: row.assessmentItem.AssessmentItem_ID,
        assessmentItem: row.assessmentItem.ProgramComponent_Name || "Custom",
        description,
        findings: createNodeCellParams(
          fieldPresenceSortValue("Finding", row, hasDesign, hasPerformance),
          null,
          <FieldPresenceCell fieldKey="Finding" {...fieldPresenceProps} />
        ),
        recommendations: createNodeCellParams(
          fieldPresenceSortValue("Recommendation", row, hasDesign, hasPerformance),
          null,
          <FieldPresenceCell fieldKey="Recommendation" {...fieldPresenceProps} />
        ),
        design: createNodeCellParams(
          statusSortValue(row.designItem),
          statusTextValue(row.designItem, builderInfo),
          (
            <DesignStatusCell
              {...modeStatusProps}
              builderInfo={builderInfo}
            />
          )
        ),
        performance: createNodeCellParams(
          performanceStatusValues,
          statusTextValue(row.performanceItems, builderInfo),
          (
            <PerformanceStatusCell
              {...modeStatusProps}
              builderInfo={builderInfo}
            />
          )
        )
      };
    });
  }, [builderInfo, rowData, hasDesign, hasPerformance, setReviewPageMode]);

  return (
    <DataTable
      data={tableCells}
      className={classes.table}
      columns={tableHeaders}
      options={{
        filterType: "checkbox",
        fixedHeader: true,
        fixedSelectColumn: true,
        filter: true,
        pagination: true,
        rowsPerPageOptions: [10, 50, 100],
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        download: false,
        print: false
      }}
    />
  );
}
