import { Box, Card, Divider, List, ListItem, makeStyles, Paper, useMediaQuery, useTheme } from "@material-ui/core";
import { MergeType, Notes } from "@material-ui/icons";
import classNames from "classnames";
import CategoryElementsSummary from "components/certification/dct/summary/categoryElementsSummary.component";
import QuestionsSummary from "components/certification/dct/summary/questionsSummary.component";
import ButtonDefault from "components/utils/buttonDefault.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import Header from "components/utils/header.component";
import Loader from "components/utils/loader.components";
import useNumericParams from "hooks/useNumericParams";
import { Fragment, memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { generatePath, useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ACTION_SET_DCT_INDEX } from "reducers/certification/certification.reducer";
import certificationService from "services/certification.service";
import { setStateFetchEffect } from "utils/ajaxHelpers";
import { groupObjectArrayByKey } from "utils/arrayOfObjectsHelpers";
import styleVariables from "styleVariables";
import { RECONCILIATION_MODE_PERSONAL, RECONCILIATION_MODE_RECONCILE } from "../../reconciliation/utils/reconciliation.utils";
import { DCT_ELEMENT_SPECIALTY_DISPLAY, DCT_ELEMENT_SPECIALTY_DISPLAY_SHORT, DCT_ELEMENT_TYPE_DISPLAY, DCT_ELEMENT_TYPE_ICONS, CATEGORY_SCROLL_URL_PARAM, findIndexElements } from "../utils/dct.utils";

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: 64
  },
  actionsColumn: {
    display: "flex",
    marginLeft: 64,
    minWidth: 260,
    [theme.breakpoints.down("md")]: {
      minWidth: 0,
      marginLeft: 16,
      flex: "0 0 136px",
      flexWrap: "wrap",
      justifyContent: "end",
      height: 80,
      marginTop: "auto",
      marginBottom: "auto"
    }
  },
  actionsBody: {
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
      justifyContent: "start",
      height: "auto",
      minWidth: "100%",
      marginTop: 8,
      marginLeft: 0,
      paddingBottom: 8
    }
  },
  buttonIcon: {
    marginRight: 4,
    fontSize: styleVariables.fontMedium
  },
  categoryButton: {
    "& + $categoryButton": {
      marginLeft: 16,
    }
  },
  categoryLabel: {
    flex: 1
  },
  categoryDivider: {
    marginTop: 32,
    marginBottom: -16
  },
  listItemContent: {
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap"
    }
  },
  elementTypeIcon: {
    marginRight: 8,
    verticalAlign: "text-bottom"
  },
  elementTypeDisplay: {
    marginRight: 8,
    marginLeft: 16,
    textIndent: -16,
    color: theme.palette.text.secondary
  },
  sublabelColumn: {
    alignItems: "center",
    display: "flex",
    [theme.breakpoints.up("lg")]: {
      minWidth: 284
    },
    [theme.breakpoints.down("lg")]: {
      flex: 1,
      marginBottom: 8
    }
  },
  subcategorySummaryColumn: {
    minWidth: "50%"
  },
  summaryPaper: {
    marginBottom: 32,
    maxWidth: 800
  }
}));


export default function CertificationDctListing(props) {
  const classes = useStyles();
  const history = useHistory();
  const params = useNumericParams();
  const { search } = useLocation();
  const scrollToRef = useRef();

  const { state, dispatch } = props;

  const [canScroll, setCanScroll] = useState(false);

  const scrollToCategory = useMemo(() => {
    if (search) {
      const queryParams = new URLSearchParams(search);
      const category = queryParams.get(CATEGORY_SCROLL_URL_PARAM);
      if (category) {
        return parseInt(category, 10);
      }
    }
    return null;
  }, [search]);

  const elements = useMemo(() => (
    findIndexElements(state.dctIndex)
  ), [state.dctIndex]);

  const indexSubgroupedByEnumeration = useMemo(() => (
    state.dctIndex?.map?.(categoryItem => {
      const subcategories = categoryItem._associations.DctCategory;
      const elementCategoryPairs = subcategories.reduce((accumulator, subcategory) => {
        const enumerations = groupObjectArrayByKey(
          subcategory._associations.DctElement,
          "Enumeration"
        );
        accumulator[subcategory.Enumeration] = {
          categoryItem: subcategory,
          items: enumerations
        };
        return accumulator;
      }, {});
      return { categoryItem, items: elementCategoryPairs };
    }) || []
  ), [state.dctIndex]);

  const handleDctClick = useCallback((indexItem, enumeration, isReconcile) => {
    history.push(generatePath(
      "/program/:programId/:programComponentId/dct-category/:dctCategoryId/:dctElementEnumeration/:mode",
      {
        ...params,
        dctCategoryId: indexItem.DctCategory_ID,
        dctElementEnumeration: enumeration,
        mode: isReconcile ?
          RECONCILIATION_MODE_RECONCILE :
          RECONCILIATION_MODE_PERSONAL
      }
    ));
  }, [history, params]);

  useEffect(() => (
    setStateFetchEffect(
      certificationService.getDctCategoryIndex(),
      function fetchDctIndex([dctIndexResponse]) {
        dispatch({
          type: ACTION_SET_DCT_INDEX,
          payload: dctIndexResponse.payload
        });
        setCanScroll(true);
      }
    )
  ), [dispatch]);

  useLayoutEffect(() => {
    if (canScroll && scrollToRef.current) {
      scrollToRef.current.scrollIntoView();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [canScroll]);

  if (!state.dctIndex) {
    return <Loader />;
  }
  return (
    <div className={classes.container}>
      <Paper className={classes.summaryPaper}>
        <Box padding={2}>
          <CategoryElementsSummary
            elements={elements}
            gridItemProps={{ md: 4 }}
            mode="all"
          />
        </Box>
      </Paper>
      {indexSubgroupedByEnumeration?.flatMap?.(topIndexGroup => {
        const {
          categoryItem: topIndexItem,
          items: subIndexesByEnumeration
        } = topIndexGroup;
        return (
          <div
            ref={
              topIndexItem.DctCategory_ID === scrollToCategory ?
                scrollToRef :
                undefined
            }
            key={topIndexItem.DctCategory_ID}
          >
            <Divider className={classes.categoryDivider} />
            {Object.entries(subIndexesByEnumeration).map(subIndexEntry => {
              const [subIndexEnumeration, subIndexGroup] = subIndexEntry;
              const {
                categoryItem: subIndexItem,
                items: elementsByEnumeration
              } = subIndexGroup;
              const baseEnumeration = (
                `${topIndexItem.Enumeration}.${subIndexEnumeration}`
              );
              return (
                <Fragment key={subIndexItem.DctCategory_ID}>
                  <Box display="flex" justifyContent="end" marginTop={4}>
                    <Header className={classes.categoryLabel} variant="h3Primary">
                      {baseEnumeration}.&nbsp;{subIndexItem.Label}
                    </Header>
                  </Box>
                  <Card
                    ref={
                      subIndexItem.DctCategory_ID === scrollToCategory ?
                        scrollToRef :
                        undefined
                    }
                  >
                    <List spacing={4} disablePadding>
                      {Object.entries(elementsByEnumeration)?.map?.(
                        ([elementEnumeration, indexElements]) => (
                          <ElementListItem
                            baseEnumeration={baseEnumeration}
                            elementEnumeration={elementEnumeration}
                            handleDctClick={handleDctClick}
                            indexElements={indexElements}
                            subIndexItem={subIndexItem}
                            key={elementEnumeration}
                          />
                        )
                      )}
                    </List>
                  </Card>
                </Fragment>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

const ElementListItem = memo(
  function ElementListItemBase(props) {
    const classes = useStyles();
    const {
      baseEnumeration,
      elementEnumeration,
      handleDctClick,
      indexElements,
      subIndexItem
    } = props;

    return (
      <ListItem
        alignItems="center"
        key={elementEnumeration}
        divider
        data-cy="dct-element-item"
      >
        <Box
          display="flex"
          justifyContent="end"
          width="100%"
          className={classes.listItemContent}
        >
          <GridContainer
            spacing={4}
            alignItems="center"
            justifyContent="space-between"
          >
            <GridItem
              className={classes.sublabelColumn}
              xs={12}
              lg="auto"
            >
              <div>
                <Header variant="h4Tertiary">
                  {baseEnumeration}.{elementEnumeration}
                  .&nbsp;{indexElements[0]?.Label}
                </Header>
                <ElementItemsDisplay elements={indexElements} />
              </div>
            </GridItem>
            <GridItem
              className={classes.subcategorySummaryColumn}
              xs={12}
              lg="auto"
            >
              <Box marginBottom={0.5} minWidth="50%">
                <QuestionsSummary
                  elements={indexElements}
                  gridItemProps={{ sm: 4 }}
                  mode="all"
                />
              </Box>
            </GridItem>
          </GridContainer>
          <div
            className={classNames(classes.actionsColumn, classes.actionsBody)}
          >
            <ButtonDefault
              background="primary"
              variant="small"
              className={classes.categoryButton}
              onClick={() => (
                handleDctClick(subIndexItem, elementEnumeration, false)
              )}
              startIcon={<Notes className={classes.buttonIcon} />}
              data-cy="btn-my-answers"
            >
              My Answers
            </ButtonDefault>
            <ButtonDefault
              background="secondary"
              variant="small"
              className={classes.categoryButton}
              onClick={() => (
                handleDctClick(subIndexItem, elementEnumeration, true)
              )}
              startIcon={<MergeType className={classes.buttonIcon} />}
              data-cy="btn-reconcile"
            >
              Reconcile
            </ButtonDefault>
          </div>
        </Box>
      </ListItem>
    );
  }
);

const ElementItemsDisplay = memo(
  function ElementItemsDisplayBase({ elements }) {
    const classes = useStyles();
    const theme = useTheme();
    const isXlBreakpoint = useMediaQuery(theme.breakpoints.only("xl"));
    const isMdBreakpoint = useMediaQuery(theme.breakpoints.only("md"));

    const elementsByType = useMemo(() => (
      groupObjectArrayByKey(elements, "Type")
    ), [elements]);

    const elementTypeEntries = useMemo(() => (
      Object.entries(elementsByType)
    ), [elementsByType]);

    if (!elementsByType) {
      return null;
    }
    return (
      <div>
        {elementTypeEntries.flatMap(([type, typeElements]) => (
          typeElements.map(element => {
            const IconComponent = DCT_ELEMENT_TYPE_ICONS[type];
            const specialtyLabel = isXlBreakpoint || isMdBreakpoint ?
              DCT_ELEMENT_SPECIALTY_DISPLAY[element.Specialty] :
              DCT_ELEMENT_SPECIALTY_DISPLAY_SHORT[element.Specialty];

            return (
              <div
                className={classes.elementTypeDisplay}
                key={element.DctElement_ID}
              >
                <IconComponent className={classes.elementTypeIcon} />
                <span>
                  {DCT_ELEMENT_TYPE_DISPLAY[type]} ({specialtyLabel})
                </span>
              </div>
            )
          })
        ))}
      </div>
    );
  }
);
