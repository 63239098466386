import { removeObjInArray } from "utils/arrayOfObjectsHelpers";
import { mergeNestedAssociationDelete, mergeNestedAssociationReplace, mergeAssociationReplace } from "./utils/payloadUtils";
import { mergeReplace } from "./utils/payloadUtils";

export const ACTION_SET_ASSESSMENTS = "assessments__set";
export const ACTION_SET_ASSESSMENT_ITEMS = "assessmentItems__set";
export const ACTION_SET_RECONCILE_ASSESSMENT_ITEMS = "reconcile_assessmentItems__set";

export const ACTION_SET_FOLLOW_UPS = "followUps__set";
export const ACTION_SET_DOCUMENTS = "documents__set";
export const ACTION_SET_PORTFOLIO_USERS = "portfolioUsers__set";
export const ACTION_SET_PORTCOS = "portCos__set";

export const ACTION_CREATE_ASSESSMENT = "assessments__create";
export const ACTION_CREATE_SESSION = "sessions__create";
export const ACTION_CREATE_DOCUMENT = "document__create";

export const ACTION_REPLACE_PORTFOLIO_USER = "portfolioUser__replace";
export const ACTION_REPLACE_SESSION_ATTENDEE = "sessions_attendees__replace";
export const ACTION_REPLACE_SESSION_FOLLOW_UP = "sessions_followUp__replace";
export const ACTION_REPLACE_SESSION_NOTES = "sessions_notes__replace";
export const ACTION_REPLACE_RECONCILE_SESSION_NOTES = "reconcile_sessions_notes__replace";
export const ACTION_REPLACE_DOCUMENT = "document__replace";

export const ACTION_REPLACE_ASSESMENT_ITEM = "assessment__item_replace";
export const ACTION_REPLACE_RECONCILE_ASSESMENT_ITEM = "reconcile_assessment__item_replace"

export const ACTION_DELETE_SESSION_ATTENDEE = "sessions_attendees__delete";
export const ACTION_DELETE_SESSION_FOLLOW_UP = "sessions_followUp__delete";
export const ACTION_DELETE_DOCUMENT = "document__delete";

export const ACTION_RESET_ASSESSMENT_ITEMS = "assessmentItems__reset";

export const portfolioAssessmentInitialState = {
  assessments: null,
  assessmentItems: null,
  followUps: null,
  portfolioCompanies: null,
  portfolioUsers: null,
  reconcileAssessmentItems: null,
  documents: null
};

export default function portfolioAssessmentsReducer(state, action) {
  switch (action.type) {
    case ACTION_SET_FOLLOW_UPS:
      return {
        ...state,
        followUps: action.payload
      };
    case ACTION_SET_ASSESSMENTS:
      return {
        ...state,
        assessments: action.payload
      }
    case ACTION_SET_ASSESSMENT_ITEMS:
      return {
        ...state,
        assessmentItems: action.payload
      }
    case ACTION_SET_RECONCILE_ASSESSMENT_ITEMS:
      return {
        ...state,
        reconcileAssessmentItems: action.payload
      }
    case ACTION_SET_PORTCOS:
      return {
        ...state,
        portfolioCompanies: action.payload,
      };
    case ACTION_SET_PORTFOLIO_USERS:
      return {
        ...state,
        portfolioUsers: action.payload
      }
    case ACTION_SET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload
      }

    case ACTION_CREATE_ASSESSMENT:
      return {
        ...state,
        assessments: [
          ...state.assessments,
          action.payload
        ]
      }
    case ACTION_CREATE_SESSION:
      return {
        ...state,
        assessments: mergeAssociationReplace(
          state.assessments,
          action.payload,
          "Session_ID",
          "AssessmentSession",
          "Session_ID"
        )
      }

    case ACTION_CREATE_DOCUMENT:
      return {
        ...state,
        documents: [...state.documents, action.payload],
        assessments: !action.meta.update.SessionFollowUps ?
          state.assessments :
          mergeNestedAssociationReplace(
            state.assessments,
            action.meta.update.SessionFollowUps,
            "Session_ID",
            "FollowUp_ID",
            "AssessmentStatus",
            "AssessmentSession",
            "SessionFollowUps"
          )
      }

    case ACTION_REPLACE_ASSESMENT_ITEM:
      const newList = mergeReplace(
        state.assessmentItems,
        action.payload,
        "Item_ID",
      )
      return {
        ...state,
        assessmentItems: [...newList]
      };

    case ACTION_REPLACE_RECONCILE_ASSESMENT_ITEM:
      const mergeList = mergeReplace(
        [...state.reconcileAssessmentItems],
        action.payload,
        "Item_ID",
      )
      return {
        ...state,
        reconcileAssessmentItems: mergeList
      };

    case ACTION_REPLACE_SESSION_FOLLOW_UP:
      return {
        ...state,
        assessments: mergeNestedAssociationReplace(
          state.assessments,
          action.payload,
          "Session_ID",
          "FollowUp_ID",
          "AssessmentStatus",
          "AssessmentSession",
          "SessionFollowUps",
        )
      };
    case ACTION_REPLACE_PORTFOLIO_USER:
      return {
        ...state,
        portfolioUsers: mergeReplace(
          state.portfolioUsers,
          action.payload,
          "User_ID",
        )
      };
    case ACTION_REPLACE_SESSION_ATTENDEE:
      return {
        ...state,
        assessments: mergeNestedAssociationReplace(
          state.assessments,
          action.payload,
          "Session_ID",
          "Attendee_ID",
          "AssessmentStatus",
          "AssessmentSession",
          "SessionAttendees",
        )
      };
    case ACTION_REPLACE_SESSION_NOTES:
      return {
        ...state,
        assessmentItems: mergeAssociationReplace(
          state.assessmentItems,
          action.payload,
          "Item_ID",
          "SessionNotes",
          "SessionNote_ID"
        ),
        assessments: mergeNestedAssociationReplace(
          state.assessments,
          action.payload,
          "Session_ID",
          "SessionNote_ID",
          "AssessmentStatus",
          "AssessmentSession",
          "SessionNotes",
        )
      };
    case ACTION_REPLACE_RECONCILE_SESSION_NOTES:
      return {
        ...state,
        reconcileAssessmentItems: mergeAssociationReplace(
          state.reconcileAssessmentItems,
          action.payload,
          "Item_ID",
          "SessionNotes",
          "SessionNote_ID"
        ),
        assessments: mergeNestedAssociationReplace(
          state.assessments,
          action.payload,
          "Session_ID",
          "SessionNote_ID",
          "AssessmentStatus",
          "AssessmentSession",
          "SessionNotes",
        )
      };
    case ACTION_REPLACE_DOCUMENT:
      return {
        ...state,
        documents: mergeReplace(
        [...state.documents],
        action.payload,
        "Document_ID",
        ),
        assessments: !action.meta.update.SessionFollowUps ?
          state.assessments :
          mergeNestedAssociationReplace(
          state.assessments,
          action.meta.update.SessionFollowUps,
          "Session_ID",
          "FollowUp_ID",
          "AssessmentStatus",
          "AssessmentSession",
          "SessionFollowUps"
          )
      };

    case ACTION_DELETE_SESSION_ATTENDEE:
      return {
        ...state,
        assessments: [...mergeNestedAssociationDelete(
          state.assessments,
          action.payload,
          "Attendee_ID",
          "AssessmentStatus",
          "AssessmentSession",
          "SessionAttendees",
        )]
      };
    case ACTION_DELETE_SESSION_FOLLOW_UP:
      return {
        ...state,
        assessments: [...mergeNestedAssociationDelete(
          state.assessments,
          action.payload,
          "FollowUp_ID",
          "AssessmentStatus",
          "AssessmentSession",
          "SessionFollowUps",
        )]
      };

    case ACTION_DELETE_DOCUMENT:
      return {
        ...state,
        documents: removeObjInArray(state.documents, "Document_ID", action.payload),
        assessments: !action.meta.update.SessionFollowUps ?
          state.assessments :
          mergeNestedAssociationReplace(
            state.assessments,
            action.meta.update.SessionFollowUps,
            "Session_ID",
            "FollowUp_ID",
            "AssessmentStatus",
            "AssessmentSession",
            "SessionFollowUps"
          )
      };
    case ACTION_RESET_ASSESSMENT_ITEMS:
      return {
        ...state,
        assessmentItems: portfolioAssessmentInitialState.assessmentItems,
      }
    default:
      return state;
  }
}
