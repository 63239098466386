import { Box, Dialog, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import FormBanner from "components/utils/form-elements/formBanner.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import Loader from "components/utils/loader.components";
import { useCallback, useMemo, useState } from "react";
import { ACTION_CREATE_REPORT_DOCS, ACTION_REPLACE_REPORT_DOCS } from "reducers/portfolioReports.reducer";
import PortfolioService from "services/portfolio.service";
import { followUpTypes } from "utils/portfolioConstants";
import DocumentEditForm, { DOCUMENT_FORM_NAME, VARIANT_MODAL } from "../shared/documentEditForm.component";
import FollowUpsAccordion from "../shared/followUpsAccordion.component";
import { getUploadHttpErrorMessage } from "services/http-common";

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: "16px 32px"
  },
  followUpsColumn: {
    [theme.breakpoints.up("md")]: {
      borderLeft: `1px solid ${theme.palette.border.main}`
    },
    [theme.breakpoints.down("sm")]: {
      order: -1,
    }
  },
  followUpsColumnDisabled: {
    opacity: 0.5,
    pointerEvents: "none"
  },
  followUpsColumnContent: {
    [theme.breakpoints.down("sm")]: {
      marginTop: -16,
      paddingBottom: 32,
      borderBottom: `1px solid ${theme.palette.border.main}`,
    }
  }
}));

const DIALOG_TRANSITION_DURATION = 200;


export default function ReportDocumentsModal(props) {
  const classes = useStyles();
  const {
    assessmentStatus, defaultFollowUpId, dispatch, documents,
    openModalDocumentId, portfolioCompanies, session, setOpenModalDocumentId
  } = props;

  const [chosenFile, setChosenFile] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const openModalDocument = useMemo(() => (
    (!documents?.length || !openModalDocumentId) ?
      null :
      documents.find(document => document.Document_ID === openModalDocumentId)
  ), [documents, openModalDocumentId]);

  const sessions = useMemo(() => (
    assessmentStatus?._associations?.AssessmentSession || []
  ), [assessmentStatus]);

  const documentFollowUps = useMemo(() => (
    sessions.flatMap(sessionItem => (
      sessionItem._associations.SessionFollowUps
    )).filter(followUp => (
      followUp.Type === followUpTypes.DOCUMENT
    ))
  ), [sessions]);

  const activeDocumentFollowUps = useMemo(() => (
    documentFollowUps.filter(followUp => followUp.Status === "Active")
  ), [documentFollowUps]);

  const closeModal = useCallback(afterClose => {
    setOpenModalDocumentId(null);
    setTimeout(() => {
      setChosenFile(null);
      setFormErrors({});
      afterClose?.();
    }, DIALOG_TRANSITION_DURATION);
  }, [setOpenModalDocumentId]);

  const handleClose = useCallback(() => closeModal(), [closeModal]);

  const handleDocumentAttach = useCallback(event => {
    setChosenFile(event.target.files?.[0]);
    setFormErrors({});
  }, []);

  const handleSubmit = useCallback(async (data) => {
    try {
      setIsSaving(true)
      const body = new FormData();
      if (chosenFile) {
        body.append("file", chosenFile, chosenFile.name);
      }
      if (openModalDocument) {
        body.set("Document_ID", openModalDocument);
      }
      body.set("AssessmentStatus_ID", assessmentStatus.AssessmentStatus_ID);
      if (chosenFile) {
        body.set("File_Ref", chosenFile.name)
      }
      body.set("Document_Title", data.Document_Title)
      body.set("Publish_Date", data.Publish_Date)
      body.set("Version", data.Version)
      body.set("Description", data.Description)
      if (data.FollowUp_ID) {
        body.set("FollowUp_ID", data.FollowUp_ID);
      }
      const documentResponse = await PortfolioService.upsertSupportingDoc(
        session.Session_ID,
        body
      )

      closeModal(() => {
        dispatch({
          type: openModalDocument ?
            ACTION_REPLACE_REPORT_DOCS :
            ACTION_CREATE_REPORT_DOCS,
          payload: documentResponse.payload,
          meta: documentResponse.meta,
        })
      });
      setIsSaving(false);
    } catch (error) {
      const { status } = error.response || {};
      setFormErrors({ File_Name: getUploadHttpErrorMessage(status) });
      setIsSaving(false);
    }

  }, [
    assessmentStatus, chosenFile, dispatch,
    closeModal, openModalDocument, session,
  ]);

  if (!documents) {
    return (
      <Loader />
    );
  }
  return (
    <Dialog
      open={!!openModalDocumentId}
      onClose={handleClose}
      maxWidth="md"
      transitionDuration={DIALOG_TRANSITION_DURATION}
      fullWidth
    >
      <FormBanner>Add Portfolio Company Documents</FormBanner>
      <div className={classes.dialogContent}>
        <GridContainer spacing={4}>
          <GridItem xs={12} md={6}>
            <DocumentEditForm
              document={openModalDocument}
              onCancel={handleClose}
              onSubmit={handleSubmit}
              errors={formErrors}
              setErrors={setFormErrors}
              chosenFile={chosenFile}
              documentUpload={handleDocumentAttach}
              variant={VARIANT_MODAL}
              isSaving={isSaving}
              setIsSaving={setIsSaving}
            />
          </GridItem>
          <GridItem
            className={
              classNames(
                classes.followUpsColumn,
                !activeDocumentFollowUps?.length &&
                  classes.followUpsColumnDisabled
              )
            }
            xs={12}
            md={6}
          >
            <div className={classes.followUpsColumnContent}>
              <Box marginTop={1}>
                <FollowUpsAccordion
                  defaultFollowUpId={defaultFollowUpId}
                  followUps={activeDocumentFollowUps}
                  formId={DOCUMENT_FORM_NAME}
                  label="Corresponding follow-up from previous session"
                  portfolioCompanies={portfolioCompanies}
                  sessions={sessions}
                  hasRadios
                  showPortCoName
                />
              </Box>
              {!activeDocumentFollowUps?.length && (
                <Box marginTop={1} textAlign="center">
                  <p>
                    {documentFollowUps?.length ?
                      "All document follow-ups for this report have been resolved." :
                      "This report has no document follow-ups."}
                  </p>
                </Box>
              )}
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </Dialog>
  )
}