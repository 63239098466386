import { Box, makeStyles, MenuItem } from "@material-ui/core";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import Form from "components/utils/form-elements/form.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import CustomSelect from "components/utils/form-elements/select.component";
import { useCallback, useMemo, useState } from "react";
import { ACTION_ADD_PORTCO_USER, ACTION_REPLACE_PORTCO_USER } from "reducers/portfolioDemos.reducer";
import authService from "services/auth.service";
import { HTTP_STATUS_CONTENT } from "services/http-common";
import PortfolioService from "services/portfolio.service";
import { validEmail } from "utils/validators";
import { getPortfolioUserPermission } from "../shared/utils/portfolioUserHelpers";

const ROLES = authService.Type;

const useStyles = makeStyles((theme) => ({
  userFormContainer: {
    marginTop: 8,
    paddingLeft: 24,
    paddingRight: 24,
  },
  dualButtons: {
    paddingBottom: 8
  },
  formRow: {
    display: "flex",
    minWidth: 380,
    justifyContent: "space-between",
    paddingBottom: 0,
  },
}));


const DefaultInput = ({ longInput, ...props }) => (
  <Box width={longInput ? "100%" : "184px"}>
    <LabelInput {...props} variant="default" margin="dense" />
  </Box>
);

const validateFormErrors = (formState) => {
  const error = {};
  if (!formState.First_Name) {
    error.First_Name = "First Name is required.";
  }

  if (!formState.Last_Name) {
    error.Last_Name = "Last Name is required.";
  }

  if (!formState.Email) {
    error.Email = "Email is required.";
  } else if (!validEmail(formState.Email)) {
    error.Email = "The email format is invalid.";
  }

  if (!formState.Role_ID) {
    error.Role_ID = "User role is required.";
  }

  if (!formState.Title) {
    error.Title = "Title is required.";
  }

  return error;
};

export default function InviteUserForm({
  dispatch,
  onClose,
  selectedPortCoID,
  setEmailMessage,
  user
}) {
  const classes = useStyles();

  const portfolioPermission = useMemo(() => (
    !user ? null : getPortfolioUserPermission(user, null, selectedPortCoID)
  ), [selectedPortCoID, user]);

  const [userFormData, setUserFormData] = useState({
    User_ID: user?.User_ID || null,
    First_Name: user?.First_Name || "",
    Last_Name: user?.Last_Name || "",
    Title: portfolioPermission?.Title || "",
    Email: user?.Email || "",
    Phone: user?.Phone || "",
    Role_ID: user?.Role_ID || "",
  });
  const [formErrors, setFormErrors] = useState({});

  const changeHandler = useCallback((event) => {
    const { name, value } = event.target;
    setUserFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (formErrors[name]) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: false,
      }));
    }
    return null;
  }, [formErrors]);

  const selectHandler = useCallback((event) => {
    const { name, value } = event.target;
    setUserFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (formErrors[name]) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: false,
      }));
    }
  }, [formErrors]);

  const handleSaveUser = useCallback(async () => {
    setEmailMessage();
    const formValidations = validateFormErrors(userFormData);
    setFormErrors(formValidations);
    if (Object.values(formValidations).some(error => error !== false)) {
      return;
    }

    const isMissingPermissions = userFormData.User_ID && !portfolioPermission;
    if (isMissingPermissions) {
      console.error("No PortfolioGroupPermission found for existing user?");
      throw new Error();
    }

    const userData = {
      User_ID: userFormData.User_ID,
      First_Name: userFormData.First_Name,
      Last_Name: userFormData.Last_Name,
      Email: userFormData.Email,
      Role_ID: userFormData.Role_ID,
      Phone: userFormData.Phone,
    };
    let permissionsData = null;

    if (userData.User_ID) {
      if (portfolioPermission?.Title !== userFormData.Title) {
        const userPortfolioPermission = (
          getPortfolioUserPermission(user, null, selectedPortCoID)
        )
        permissionsData = {
          Title: userFormData.Title,
          PortGroupPermission_ID: userPortfolioPermission.PortGroupPermission_ID,
        };
      }
    } else {
      permissionsData = { Title: userFormData.Title };
    }

    try {
      const upsertUserRes = await PortfolioService.upsertPortCoUser(
        selectedPortCoID,
        userData,
        permissionsData
      );

      if (upsertUserRes.emailSent) {
        const userEmail = upsertUserRes.payload.Email;
        setEmailMessage(
          `A reset password email has been sent to ${userEmail}`
        );
      }

      if (!user) {
        dispatch({
          type: ACTION_ADD_PORTCO_USER,
          payload: upsertUserRes.payload,
        });
      } else {
        dispatch({
          type: ACTION_REPLACE_PORTCO_USER,
          payload: upsertUserRes.payload,
        });
      }
      onClose();
    } catch(error) {
      let errorMessage = "An unexpected error has occured.";
      if (error?.response?.status === HTTP_STATUS_CONTENT) {
        errorMessage = (
          "This email already exists in the system. " +
          "Please enter a unique email address."
        );
      }
      setFormErrors({ Email: errorMessage });
    }
  }, [
    dispatch,
    selectedPortCoID,
    user,
    userFormData,
    portfolioPermission,
    setEmailMessage,
    onClose,
  ]);

  return (
    <Form
      name="invite-portfolio-user"
      className={classes.userFormContainer}
      onSubmit={handleSaveUser}
    >
      <div className={classes.formRow}>
        <DefaultInput
          label="First Name*"
          value={userFormData.First_Name}
          onChange={changeHandler}
          name="First_Name"
          error={!!formErrors?.First_Name}
          errorMessage={formErrors?.First_Name}
        />

        <DefaultInput
          label="Last Name*"
          value={userFormData.Last_Name}
          onChange={changeHandler}
          name="Last_Name"
          error={!!formErrors?.Last_Name}
          errorMessage={formErrors?.Last_Name}
        />
      </div>
      <div className={classes.formRow}>
        <DefaultInput
          longInput
          label="Email*"
          value={userFormData.Email}
          onChange={changeHandler}
          name="Email"
          labelTooltip="An email with a link to set a password will be sent upon first saving an email."
          error={!!formErrors?.Email}
          errorMessage={formErrors?.Email}
        />
      </div>
      <div className={classes.defaultInputWrapper}>
        <CustomSelect
          label="Role*"
          name="Role_ID"
          onChange={selectHandler}
          value={userFormData.Role_ID}
          test="select-Role"
        >
          <MenuItem value={ROLES.PORTCOREAD}>
            Read Only
          </MenuItem>
          <MenuItem value={ROLES.PORTCOEDIT}>
            Edit
          </MenuItem>
        </CustomSelect>
      </div>

      <div className={classes.formRow}>
        <DefaultInput
          label="Title*"
          value={userFormData.Title}
          onChange={changeHandler}
          name="Title"
          error={!!formErrors?.Title}
          errorMessage={formErrors?.Title}
        />
        <DefaultInput
          label="Phone"
          value={userFormData.Phone}
          onChange={changeHandler}
          name="Phone"
        />
      </div>
      <DualFormButtons
        buttonVariant="small"
        cancelOnClick={onClose}
        className={classes.dualButtons}
      />
    </Form>
  );
}
