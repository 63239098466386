import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import FormBanner from "components/utils/form-elements/formBanner.component";
import moment from "moment";
import LabelInput from "components/utils/form-elements/labelInput.component";
import ButtonDefault from "components/utils/buttonDefault.component";
import AttachmentIcon from '@material-ui/icons/Attachment';
import variables from "styleVariables";
import FileService from "services/file.service";
import classNames from "classnames";
import DateInput from "components/utils/form-elements/dateInput.component";
import { getUploadHttpErrorMessage } from "services/http-common";
import { acceptableExcelExtensionTypes } from "utils/fileConstants";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 8,
  },
  content: {
    width: 600,
    padding: "0 30px 20px 30px",
  },
  form: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingTop: 24,
    paddingBottom: 0,
  },
  subHeaderContainer: {
    padding: "0px 10px 35px 10px",
  },
  inputWrapper: {
    paddingTop: 0,
    paddingBottom: 10,
  },
  attachmentWrapper: {
    display: "flex",
    paddingTop: 20,
    paddingBottom: 10,
    flexDirection: "column",
  },
  button: {
    backgroundColor: variables.primaryMain,
  },
  dualButtons: {
    paddingBottom: 5,
  },
  xsWidth: {
    paddingTop: 0,
    maxWidth: 200,
    paddingBottom: 10,
    marginBottom: 17,
  },
  attachIcon: {
    color: "white",
    fontSize: 18,
    marginRight: 3
  },
  // COMPRESSED
  attachmentName: {
    color: variables.textSecondary,
    padding: 10,
    textAlign: "center",
  },
  error: {
    color: variables.warningMain,
    fontWeight: "normal",
  }
}));

export const UploadToolForm = (props) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({});
  const [chosenFile, setChosenFile] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [message, setMessage] = useState();


  const handleChange = (event) => {
    const { target } = event;
    setFormData({
      ...formData,
      [target.id]: target.value,
    });
  };

  const disableButton = () => {
    return !formData.description || formData.description.trim() === "" ||
      !formData.approvalDate || formData.approvalDate.trim() === "" ||
      !formData.publisher || formData.publisher.trim() === "" ||
      !formData.version || formData.version.trim() === "" ||
      !chosenFile
  }

  const chooseFile = (event) => {
    if (event.target.files[0] && event.target.files[0].name.endsWith(".xlsx")) {
      setChosenFile(event.target.files[0])
      setMessage()
    } else {
      setMessage("Must be an XLSX file")
      setChosenFile()
    }
  }

  const uploadTool = (approvalDate, publishData) => {

    setIsUploading(true);
    const fileName = `${props.rowInfo.Title}-v${formData.version}-approved${formData.approvalDate}-published${moment(new Date()).format("YYYY-MM-DDTHHmmss")}`;
    const body = new FormData();
    body.append("file", chosenFile);
    body.append("PublishDate", moment(formData.approvalDate).format("YYYY-MM-DD"));
    body.append("File_Ref", `${fileName}.xlsx`);
    body.append("Change_Date", moment(new Date()).format("YYYY-MM-DD"));
    body.append("Publisher", formData.publisher);
    body.append("ProgramDoc_Group_ID", props.rowInfo.ProgramDoc_Group_ID);
    body.append("Title", props.rowInfo.Title);
    body.append("Description", formData.description);
    body.append("Version", formData.version);
    body.append("Status", "Active");
    body.append("Program_Program_ID", props.rowInfo.Program_Program_ID);
    body.append("ComponentObjects_ComponentObject_ID", props.rowInfo.ComponentObjects_ComponentObject_ID);
    FileService.uploadTool(body)
      .then(() => {
        setIsUploading("published")
        props.setRefresh(new Date())
        props.setEditModal(false)
      })
      .catch((error) => {
        const { status } = error.response || {};
        setMessage(getUploadHttpErrorMessage(status));
        setChosenFile();
        setIsUploading(false);
      })
  }



  return (
    <Grid className={classes.formContainer}>
      <FormBanner>{`Upload - ${props.rowInfo.Title}`}</FormBanner>
      <div className={classes.content}>
        <div className={classes.subHeaderContainer}>
          <form className={classes.form}>
            <div className={classes.inputWrapper}>
              <LabelInput
                variant="default"
                label="Description"
                required
                onChange={handleChange}
                value={formData.description}
                name="description"
                id="description"
                margin="dense"
                test="toolDescription"
              />
            </div>
            <div className={classes.xsWidth}>
              <DateInput
                label="Approval Date"
                required
                id="approvalDate"
                name="approvalDate"
                onChange={handleChange}
                placeholder="mm/dd/yyyy"// For Safari
                margin="dense"
                test="toolApprovalDate"
              />
            </div>
            <div className={classes.inputWrapper}>
              <LabelInput
                variant="default"
                label="Publisher"
                required
                onChange={handleChange}
                value={formData.publisher}
                name="publisher"
                id="publisher"
                margin="dense"
                test="toolPublisher"
              />
            </div>
            <div className={classes.inputWrapper}>
              <LabelInput
                variant="default"
                label="Version"
                required
                onChange={handleChange}
                value={formData.version}
                name="version"
                id="version"
                margin="dense"
                test="toolVersion"
              />
            </div>
            <div className={classes.attachmentWrapper}>
              <ButtonDefault
                component="label"
                className={classes.button}
                startIcon={
                  <AttachmentIcon className={classes.attachIcon} />
                }
                data-cy="btn-attach-file"
              >
                Attach Excel File
                <input
                  type="file"
                  accept={acceptableExcelExtensionTypes.toString()}
                  style={{ display: "none" }}
                  onChange={chooseFile}
                  data-cy="fileInput-attach-file"
                />
              </ButtonDefault>

              <span className={classNames(classes.attachmentName, message && classes.error)}>
                {` ${chosenFile ? chosenFile.name : (message || "No File Selected")}`}
              </span>
            </div>
            <DualFormButtons
              className={classes.dualButtons}
              cancelOnClick={() => props.setEditModal(false)}
              variant="publish"
              saveOnClick={uploadTool}
              disabled={disableButton()}
              publishLabel="Upload"
              isPublishing={isUploading}
            />
          </form>
        </div>
      </div>
    </Grid>
  );
};

export default UploadToolForm;
