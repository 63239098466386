import React, { useContext, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Icons from "components/utils/icons.component";
import variables from "styleVariables";
import classNames from "classnames";
import useNumericParams from "hooks/useNumericParams";
import ProgramsContext from "contexts/programs.context";

const useStyles = makeStyles((theme) => ({
  messageWrapper: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    minHeight: 100,
    justifyContent: "center",
    color: variables.textSecondary,
    fill: variables.textSecondary,
    paddingTop: 160,
    fontSize: variables.fontH4,
    opacity: 0.8,
  },
  enterpriseMessage: {
    paddingTop: 0,
    marginTop: 48,
    fontSize: variables.fontH5,
  },
  messageIcon: {
    height: 22,
    paddingLeft: 8,
  },
}));

const NoBuildersMessage = ({ componentObject, isEnterpriseBuilder }) => {
  const classes = useStyles();
  const { programId } = useNumericParams();
  const { state } = useContext(ProgramsContext);
  const compTypeRef = componentObject.TypeRef.split("|")[0];

  const displayMessage = useMemo(() => {
    const builderType = compTypeRef.replace("Program", "");
    if (isEnterpriseBuilder) {
      return `No Enterprise Level ${builderType} drafts to display.`;
    }
    const programName = state.programs?.[programId]?.Name;
    return `No ${programName} ${builderType} drafts to display.`;
  }, [compTypeRef, isEnterpriseBuilder, programId, state.programs]);

  return (
    <div
      className={classNames(
        classes.messageWrapper,
        isEnterpriseBuilder ? classes.enterpriseMessage : undefined
      )}
    >
      {displayMessage}
      <span className={classes.messageIcon}>
        <Icons variant={componentObject.Nav_Icon} />
      </span>
    </div>
  );
};

export default NoBuildersMessage;
