import React from 'react'
import variables from "styleVariables";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  cellItem: {
    color: variables.secondaryDark,
    display: "block",
    whiteSpace: "nowrap",
    "&:hover": {
      color: variables.primaryMain,
      cursor: "pointer",
    },
  },
  cellItemWrap: {
    whiteSpace: "normal",
  },
}));

const CellItem = (props) => {
  const classes = useStyles();
  if (props.variant === "wordWrap") {
    return (
      <div
        className={classNames(
          classes.cellItemWrap,
          classes.cellItem
        )}
        onClick={props.onClick}
        key={props.mapKey}
        data-cy={`practiceLink-${props.item}`}
      >
        {props.item}
      </div>
    );
  }
  return (
    <div
      className={classes.cellItem}
      onClick={props.onClick}
      key={props.mapKey}
    >
      {props.item}
    </div>
  );
};

export default CellItem;
