import {
  Card,
  Divider,
  makeStyles,
  MenuItem,
  Box
} from "@material-ui/core";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import Form from "components/utils/form-elements/form.component"
import { useState, useCallback } from "react";
import CheckboxList from "components/utils/form-elements/checkboxList.component";
import Checkbox from "components/utils/form-elements/checkbox.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import CustomSelect from "components/utils/form-elements/select.component";
import variables from "styleVariables";
import { useMemo } from "react";
import PersonAdd from "@material-ui/icons/PersonAdd";
import { AssignmentTurnedIn } from "@material-ui/icons";
import UserService from "services/user.service";
import RunbookService from "services/runbook.service";
import { ACTION_REPLACE_WORKGROUP, ACTION_REPLACE_WORKSTREAM } from "reducers/runbook/runbooks.reducer";
import FormBanner from "components/utils/form-elements/formBanner.component";
import HeaderPrimary from "components/utils/header.component.js";
import classNames from "classnames";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import ButtonDefault from "components/utils/buttonDefault.component";
import Loader from "components/utils/loader.components";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 8,
    justifyContent: "center",
    maxHeight: "60vh",
  },
  form: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 8,
    padding: "5px 20px 20px 20px",
  },
  content: {
    width: "100%",
  },
  card: {
    padding: "10px 15px 25px 15px",
  },
  leftSection: {
    marginRight: 10,
  },
  rightSection: {
    marginLeft: 10,
  },
  checkbox: {
    padding: "5px 0px"
  },
  userCheckbox: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "calc(90vh - 300px)",
    overflow: "auto",
    width: "100%",
    border: `2px solid ${variables.grayTint}`,
    borderRadius: 5,
  },
  userCheckboxWrapper: {
    paddingLeft: 15,
  },
  checkboxText: {
    fontSize: variables.fontH6,
    color: variables.tertiary1,
  },
  formButtonsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  formText: {
    fontSize: variables.fontSmall,
    color: variables.tertiary1,
  },
  helperTextContainer: {
    padding: 10,
  },
  headerIconContainer: {
    display: "flex",
    margin: "0px 6px 2px 0px",
    color: variables.tertiary1,
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    flex: 1,
  },
  rightCard: {
    padding: "20px 20px 30px 20px",
  },
  checkboxListDivider: {
    color: variables.grayTint,
  }
}));

export const UpdateMembersForm = (props) => {
  const { assignableUsers, onClose, activeMembershipEntity, userRoles, dispatch, type } = props;
  const classes = useStyles();
  const [selectedMembers, setSelectedMembers] = useState(
    type === "workgroup" ?
      activeMembershipEntity?._associations?.WorkgroupMembers :
      activeMembershipEntity?._associations?.WorkstreamMembers
  );
  const [usersForMembership, setUsersForMembership] = useState(assignableUsers);
  const [formErrors, setFormErrors] = useState({});
  const [addingNewUser, setAddingNewUser] = useState(false);
  const [savingForm, setSavingForm] = useState(false);
  const [newUser, setNewUser] = useState(
    {
      First_Name: null,
      Last_Name: null,
      Phone: null,
      Email: null,
      Role_ID: null,
    }
  );
  const selectedMembershipIds = useMemo(() => {
    return selectedMembers.map((member) => member.User_ID);
  }, [selectedMembers]);

  const [allSelected, setAllSelected] = useState();
  const handleSelectAllMembers = useCallback(() => {
    if (allSelected) {
      const emptyUsers = [];
      setAllSelected(false);
      setSelectedMembers([...emptyUsers]);
    } else {
      const allMembersNotSelected = usersForMembership.filter((user) => (!selectedMembershipIds.includes(user.User_ID)));
      setAllSelected(true);
      setSelectedMembers([...selectedMembers, ...allMembersNotSelected])
    }

  }, [selectedMembers, selectedMembershipIds, usersForMembership, allSelected]);

  const handleAddMember = useCallback((member) => {
    if (selectedMembershipIds.includes(member.User_ID)) {
      const filteredMembers = selectedMembers.filter((memberItem) => (memberItem.User_ID !== member.User_ID));
      setSelectedMembers(filteredMembers);
    } else {
      setSelectedMembers([...selectedMembers, member]);
    }
  }, [selectedMembershipIds, selectedMembers]);

  const validateNewUserFormData = useCallback(() => {
    const errors = {};
    if (!newUser.First_Name) {
      errors.First_Name = "Please include a First Name";
    }
    if (!newUser.Last_Name) {
      errors.Last_Name = "Please include a Last Name";
    }
    if (!newUser.Email) {
      errors.Email = "Please include an Email";
    }
    if (!newUser.Role_ID) {
      errors.Role = "Please include a Role";
    }
    return errors;
  }, [newUser]);

  const handleAddNewUser = useCallback(async () => {
    setAddingNewUser(true);
    const missingFieldsErrors = validateNewUserFormData();
    setFormErrors(missingFieldsErrors);
    if (Object.keys(missingFieldsErrors || {}).length) {
      setAddingNewUser(false);
      return;
    } else {
      const validateUserResponse = await UserService.validateUser(newUser);
      const userIsValid = validateUserResponse.payload;
      if (!userIsValid) {
        setFormErrors({
          ...formErrors,
          Email: "A user with this email exists in the system"
        });
      } else {
        setUsersForMembership([...usersForMembership, newUser]);
        setSelectedMembers([...selectedMembers, newUser]);
        setNewUser({});
      }
    }
    setAddingNewUser(false);
  }, [
    formErrors,
    validateNewUserFormData,
    selectedMembers,
    newUser,
    usersForMembership]);

  const handleWorkgroupUpsert = useCallback(async () => {
    setSavingForm(true);
    const workgroupResponse = await RunbookService.batchUpdateWorkgroupMembers(activeMembershipEntity.Workgroup_ID, selectedMembers);
    dispatch({
      type: ACTION_REPLACE_WORKGROUP,
      payload: workgroupResponse.payload,
    })
    onClose();
  }, [activeMembershipEntity.Workgroup_ID, selectedMembers, dispatch, onClose]);

  const handleWorkstreamUpsert = useCallback(async () => {
    setSavingForm(true);
    const workstreamResponse = await RunbookService.batchUpdateWorkstreamMembers(activeMembershipEntity.Workstream_ID, selectedMembers);
    dispatch({
      type: ACTION_REPLACE_WORKSTREAM,
      payload: workstreamResponse.payload,
    })
    onClose();
  }, [activeMembershipEntity.Workstream_ID, selectedMembers, dispatch, onClose]);

  return (
    <div className={classes.container}>
      <FormBanner>{type === "workgroup" ? `Update Workgroup Members` : `Update Workstream Members`}</FormBanner>
      <Box
        display="flex"
        margin="10px 0px 5px 20px"
        // flexDirection={"row"}
        alignItems="center"
      >
        <div className={classes.headerIconContainer}>
          <AssignmentTurnedIn color="primary" />
        </div>
        <HeaderPrimary variant="h3Primary">{`Assign ${type === "workgroup" ? "Workgroup" : "Workstream"} Members`}</HeaderPrimary>
      </Box>
      <Form
        className={classes.form}
        name="membership-form"
      >
        <div className={classNames(classes.contentWrapper, classes.leftSection)}>

          <Card className={classNames(classes.card, classes.leftCard)}>
            <div className={classes.content}>
              <CheckboxList
                noMargin
                formControlFullWidth
              >
                <Checkbox
                  labelClass={classes.checkbox}
                  name="Select All"
                  label={<span className={classes.checkboxText}>Select All</span>}
                  onChange={handleSelectAllMembers}
                  checked={allSelected}
                />
                <div className={classes.userCheckbox}>
                  {usersForMembership.map((user) => {
                    const isChecked = selectedMembershipIds.includes(user.User_ID);
                    return (
                      <div key={`member-${user.Email}`}>
                        <div className={classes.userCheckboxWrapper}>
                          <Checkbox
                            labelClass={classes.checkbox}
                            checked={isChecked}
                            name={`${user.First_Name} ${user.Last_Name}`}
                            label={(
                              <>
                                <span className={classes.checkboxText}>
                                  {`${user.First_Name} ${user.Last_Name}`}
                                </span>
                                <br />
                                <span className={classes.checkboxText}>
                                  {`${user.Email}`}
                                </span>
                              </>
                            )}
                            onChange={() => handleAddMember(user)}
                          />
                        </div>
                        <Divider className={classes.checkboxListDivider} />
                      </div>
                    )
                  })}
                </div>
              </CheckboxList>
            </div>
          </Card>
        </div>
        <div className={classNames(classes.rightSection, classes.contentWrapper)}>

          <Box margin="10px 0px" display="flex" flexDirection="row" alignItems="center" >
            <div className={classes.headerIconContainer}>
              <PersonAdd />
            </div>
            <HeaderPrimary variant="h4Tertiary">Create Orchestration User</HeaderPrimary>
          </Box>
          <Card
            className={classNames(classes.card, classes.rightCard)}
          >
            <GridContainer spacing={2}>
              <GridItem sm={12} md={6}>
                <LabelInput
                  name="First_Name"
                  label="First Name"
                  value={newUser?.First_Name || ""}
                  margin="dense"
                  variant="default"
                  test="first_name"
                  error={!!formErrors?.First_Name}
                  errorMessage={formErrors?.First_Name}
                  onChange={(event) => setNewUser({ ...newUser, First_Name: event.target.value })}
                />
              </GridItem>
              <GridItem sm={12} md={6}>
                <LabelInput
                  name="Last_Name"
                  label="Last Name"
                  value={newUser?.Last_Name || ""}
                  margin="dense"
                  variant="default"
                  test="last_name"
                  error={!!formErrors?.Last_Name}
                  errorMessage={formErrors?.Last_Name}
                  onChange={(event) => setNewUser({ ...newUser, Last_Name: event.target.value })}
                />
              </GridItem>
              <GridItem sm={12} md={6}>
                <LabelInput
                  name="Email"
                  label="Email"
                  value={newUser?.Email || ""}
                  margin="dense"
                  variant="default"
                  test="email"
                  error={!!formErrors?.Email}
                  errorMessage={formErrors?.Email}
                  onChange={(event) => setNewUser({ ...newUser, Email: event.target.value })}
                />
              </GridItem>
              <GridItem sm={12} md={6}>
                <LabelInput
                  name="Phone"
                  label="Phone"
                  value={newUser?.Phone || ""}
                  margin="dense"
                  variant="default"
                  test="phone"
                  onChange={(event) => setNewUser({ ...newUser, Phone: event.target.value })}
                />
              </GridItem>
              <GridItem sm={12} md={6}>
                <CustomSelect
                  name="Role"
                  label="Role"
                  value={newUser?.Role_ID || ""}
                  error={!!formErrors?.Role}
                  errorMessage={formErrors?.Role}
                  onChange={(event) => setNewUser({ ...newUser, Role_ID: event.target.value })}
                >
                  {userRoles.map((role) => {
                    return (
                      <MenuItem
                        key={role.Role_ID}
                        value={role.Role_ID}
                      >
                        {`${role.Name}`}
                      </MenuItem>
                    )
                  })}
                </CustomSelect>
              </GridItem>
              <GridItem sm={12} md={6}>
                <div className={classNames(classes.formText, classes.helperTextContainer)}>
                  When a new user is created, the user will recieve an email from the Orchestration Admin Team
                  with instructions on creating a password.
                </div>
              </GridItem>
              <GridItem md={12}>
                {!addingNewUser ? (
                  <ButtonDefault
                    variant="small"
                    color="primary"
                    background="white"
                    onClick={handleAddNewUser}
                  >
                    Add User
                  </ButtonDefault>
                ) : (
                  <Loader
                    size={25}
                    height={40}
                  />
                )}
              </GridItem>
            </GridContainer>
          </Card>
        </div>
      </Form>
      <div className={classes.formButtonsContainer}>
        <DualFormButtons
          cancelOnClick={onClose}
          saveOnClick={type === "workgroup" ? handleWorkgroupUpsert : handleWorkstreamUpsert}
          isSaving={savingForm}
        />
      </div>
    </div>
  )
}

