import React from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent } from "@material-ui/core";
import CustomLink from "components/utils/link.component";
import variables from "styleVariables";
import Logo from "components/utils/logo.component";
import { generatePath } from "react-router-dom/cjs/react-router-dom.min";
import useNumericParams from "hooks/useNumericParams";
import CustomIcons from "components/utils/icons.component.js";
import classNames from "classnames";
import { useMemo } from "react";
import { portfolioProgramComponentIDs, sessionTypes } from "utils/portfolioConstants";
import { isPortCoGroupUser } from "utils/roles";
import { H3, H5 } from "components/utils/headerV2.component";

const useStyles = makeStyles((theme) => ({
  card: {
    margin: 8,
    flex: 1,
    // height: 235,
    position: "relative",
    "&:first-of-type": {
      marginLeft: 0
    },
    "&:last-of-type": {
      marginRight: 0
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      minWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "70%",
      minWidth: "70%",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "40%",
      minWidth: "40%",
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: "30%",
      minWidth: "30%",
    },
  },
  cardContent: {
    display: "flex",
    padding: 32,
    height: "100%",
    alignItems: "center",
    cursor: "default",
  },
  infoWrapper: {
    paddingLeft: 40,
    minWidth: 150,
    alignItems: "stretch",
    display: "flex",
    flex: 1,
  },
  logoWrapper: {
    display: "flex",
    maxWidth: 270,
    maxHeight: 160,
    position: "relative",
    flex: 1,
    [theme.breakpoints.down("lg")]: {
      maxHeight: 130,
    },
  },
  logo: {
    maxHeight: "inherit",
    maxWidth: "inherit",
    height: "100%",
    width: "100%",
    display: "block",
    objectFit: "contain",
  },
  contents: {
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
    flex: 1,
    width: "100%",
  },
  status: {
    fontSize: variables.fontMedium,
  },
  bodyText: {
    fontSize: variables.fontMedium,
    color: variables.tertiary1,
    paddingLeft: 10,
  },
  row: {
    display: "inline-flex",
    alignItems: "center",
    marginTop: 8,
    lineHeight: theme.lineHeight
  },
  label: {
    padding: 0,
    lineHeight: "normal",
  },
  assessmentInfo: {
    display: "flex",
    flex: 1,
    justifyContent: "end",
    flexDirection: "column",
  },
  reportIcon: {
    fontSize: "1rem",
    marginTop: 2,
    width: 24,
    height: "auto",
  },
  iconLink: {
    position: "relative",
    textIndent: 20,
    fill: variables.secondaryDark,
    "&:hover": {
      fill: variables.primaryMain,
    },
    wordBreak: "break-word"
  },
  sessionLinkChildren: {
    display: "flex",
    alignItems: "center"
  },
  sessionLinkText: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap"
  },
  sessionLinkTextBlock: {
    display: "inline-block"
  },
  sessionWrapper: {
    marginBottom: 8
  },
}));

const AssessmentCard = ({ state, holderAssessment, assessmentStatus }) => {
  const params = useNumericParams();
  const classes = useStyles();
  const { portfolioCompanies } = state;

  const sessions = useMemo(() => (
    assessmentStatus?._associations?.AssessmentSession || []
  ), [assessmentStatus]);

  const reconcileSession = sessions.find(
    (session) => session.Type === sessionTypes.RECONCILE
  );

  const dcSessions = sessions.filter(
    (session) => session.Type === sessionTypes.DATA_COLLECTION
  );

  const isPortCoUser = useMemo(isPortCoGroupUser, []);

  const portCo = useMemo(
    () =>
      portfolioCompanies?.find?.(
        (portCoItem) => portCoItem.PortCo_ID === assessmentStatus.PortCo_ID
      ),
    [portfolioCompanies, assessmentStatus]
  );

  const createSessionLink = useMemo(() => {
    let path = "/program/:programId/:programComponentId/holding/:holdingId";
    if (isPortCoUser) {
      path += "/portCo/:portCoId";
    }
    return generatePath(path, {
      ...params,
      programComponentId: portfolioProgramComponentIDs.SCHEDULING //Scheduling component
    });
  }, [isPortCoUser, params]);

  if (!portCo) {
    return null;
  }
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <div className={classNames(classes.logoWrapper)}>
          <Logo
            className={classes.logo}
            logoId={portCo.Logo}
            alt={portCo.Name ? `${portCo.Name} Logo` : "Portfolio Company Logo"}
          />
        </div>
        <div className={classes.infoWrapper} data-cy={`assessment-card-${portCo.Name}`}>
          <div className={classes.contents}>
            <H3>{portCo.Name}</H3>
            <div className={classes.row}>
              <H5 color="tertiary" className={classes.label}>Status:</H5>
              <div className={classes.infoValue}>
                {assessmentStatus !== "Report Published" ? (
                  <span className={classes.bodyText}>
                    {assessmentStatus?.Status || "Not Started"}
                  </span>
                ) : (
                  <CustomLink className={classes.status} variant="underline">
                    Report Published
                  </CustomLink>
                )}
              </div>
            </div>

            <div className={classes.assessmentInfo}>
              {!!portCo && !!portCo.PortCo_ID && dcSessions?.map?.((session) => (
                <div
                  className={classNames(classes.sessionWrapper, classes.row)}
                  key={session.Session_ID}
                >
                  <CustomLink
                    to={generatePath(
                      `/program/:programId/:programComponentId/holding/:holdingId/portCo/:portCoId/assessment/:assessmentId/session/:sessionId`,
                      {
                        ...params,
                        portCoId: portCo?.PortCo_ID,
                        assessmentId: holderAssessment.HolderAssessment_ID,
                        sessionId: session.Session_ID,
                        ...state,
                      }
                    )}
                    variant="routerLink"
                    className={classes.iconLink}
                  >
                    <div className={classes.sessionLinkChildren}>
                      <CustomIcons
                        variant="Assessments"
                        className={classes.reportIcon}
                      />
                      <div className={classes.sessionLinkText}>
                        <div className={classes.sessionLinkTextBlock}>
                          {moment(session?.Start).format("MM/DD/YYYY")}
                        </div>
                        <div className={classes.sessionLinkTextBlock}>
                          {moment(session?.Start).format("hh:mma")}
                          &nbsp;-&nbsp;
                          {moment(session?.End).format("hh:mma")}
                        </div>
                      </div>
                    </div>
                  </CustomLink>
                </div>
              ))}
              {(
                !!portCo?.PortCo_ID &&
                !!reconcileSession &&
                dcSessions.length > 0
              ) && (
                <div className={classes.row}>
                  <CustomLink
                    to={generatePath(
                      `/program/:programId/:programComponentId/holding/:holdingId/portCo/:portCoId/assessment/:assessmentId/session/:sessionId/reconcile`,
                      {
                        ...params,
                        portCoId: portCo?.PortCo_ID,
                        assessmentId: holderAssessment.HolderAssessment_ID,
                        sessionId: reconcileSession.Session_ID,
                        ...state,
                      }
                    )}
                    variant="routerLinkBold"
                  >
                    Reconcile Notes
                  </CustomLink>
                </div>
              )}
              {(
                !!portCo?.PortCo_ID &&
                !!reconcileSession &&
                !dcSessions.length > 0
              ) && (
                <div className={classes.row}>
                  <CustomLink
                    to={createSessionLink}
                    variant="routerLinkBold"
                  >
                    Create Session
                  </CustomLink>
                </div>
              )}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default AssessmentCard;
