import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, MenuItem } from "@material-ui/core";
import classNames from "classnames";
import moment from "moment";
import LabelInput from "components/utils/form-elements/labelInput.component";
import RiskAssessmentService from "services/riskAssessment.service";
import HeaderPrimary from "components/utils/header.component";
import CustomSelect from "components/utils/form-elements/select.component";
import CustomLink from "components/utils/link.component";
import CustomModal from "components/utils/modal.component";
import { currentUserID } from "utils/userHelpers";
import { useParams } from "react-router-dom";
import { CheckCircleOutline } from "@material-ui/icons";
import Form from "components/utils/form-elements/form.component";
import { reviewPageModes } from "utils/assessmentConstants";
import DisplayHTML from "components/utils/displayHTML.component";
import TaskForm from "components/forms/taskForm.component";
import SelectSearchResultsDialog from "components/search/selectResults/selectSearchResultsDialog.component";
import { searchScopeTypes } from "utils/searchHelpers";
import ButtonDefault from "components/utils/buttonDefault.component";
import { camelcaseToPascalcase } from "utils/stringFuncs";
import AddListItem from "components/utils/addListItem.component";
import { VALIDATION_REQUIRED } from "utils/formValidators";
import variables from "styleVariables";
import { defaultChangeDate } from "utils/dateHelpers";



const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    width: "100%",
    margin: "0px auto",
    maxWidth: 2000,
    padding: 20,
  },
  paper: {
    padding: 40,
  },
  dataCollectionCard: {
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  rowWrapper: {
    marginTop: 8,
    marginBottom: 0,
    display: "flex",
    [theme.breakpoints.down('sm')]: {
      flexFlow: "column",
      flexWrap: "wrap",
    },
  },
  rowWrapper2: {
    [theme.breakpoints.down('sm')]: {
      flexFlow: "column",
      flexWrap: "wrap",
      "& $left": {
        paddingBottom: "0px!important",
      },
      "& $right": {
        paddingTop: "0px!important",
      }
    },
  },
  rowWrapper3: {
    [theme.breakpoints.down('sm')]: {
      display: "flex",
      flexFlow: "column",
    },
  },
  rowWrapper4: {
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  gridGroup: {
    paddingTop: 15,
    display: "flex",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "row",
      paddingTop: 0,
    }
  },
  gridItemWrapper: {
    display: "flex",
    flexFlow: "column",
    flex: 1,
    maxWidth: "100%",
  },
  left: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: "10px!important",
    },
  },
  right: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: "10px!important",
    },
  },
  gridItemLeft: {
    padding: 10,
    paddingRight: 40,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 20,
    },
  },
  gridItemRight: {
    padding: 10,
    paddingLeft: 40,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20,
    },
  },
  summaryBody: {
    border: `1px solid ${theme.palette.border.light}`,
    borderRadius: 4,
    color: theme.palette.text.secondary,
    marginTop: 0,
    padding: "8px 16px",
    flex: 1,
    alignContent: "stretch",
  },
  addTask: {
    color: variables.secondaryDark,
    "&:hover": {
      color: variables.primaryLight,
    }
  },
  formLabel: {
    color: theme.palette.text.secondary,
    lineHeight: "1.3",
    marginBottom: 4,
  },
  formEndButton: {
    display: "flex",
    alignItems: "end",
    marginLeft: 20,
    [theme.breakpoints.down('sm')]: {
      marginLeft: "auto",
    },
  },
  formEndIcon: {
    marginRight: 8
  },
  relatedItemsList: {
    marginTop: 0,
    padding: 0,
    fontSize: "0.8rem",
    listStyle: "none",
  },
  relatedItem: {
    marginBottom: 4,
  },
  relatedItemEllipsis: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  addTaskLink: {
    marginTop: 8,
    fontSize: variables.fontSmall,
  },
  saveIcon: {
    color: "white",
  },
  searchArea: {
    marginTop: 16,
    fontSize: variables.fontSmall,
  },
}));

const normalizeTaskResult = task => {
  return Object.entries(task).reduce((accumulator, [key, value]) => {
    let normalizedKey = key;
    if (!["Task_ID", "Task_Notes"].includes(key)) {
      normalizedKey = key.replace("Task_", "");
      normalizedKey = camelcaseToPascalcase(normalizedKey);
    }
    accumulator[normalizedKey] = value;
    return accumulator;
  }, {});
};


export default function RoadmapEdit(props) {
  const {
    setReviewPageMode,
    roadmapItems,
    setRoadmapItems,
    setMessage,
  } = props;

  const classes = useStyles();
  const { roadmapId: activeRoadmapId } = useParams();

  const [refresh, setRefresh] = useState(true);

  const roadmapItem = useMemo(() => (
    roadmapItems.find(item => item.Roadmap_ID === parseInt(activeRoadmapId, 10))
  ), [roadmapItems, activeRoadmapId]);

  const [addModalOpen, setAddModalOpen] = useState(false)
  const [editModalItem, setEditModalItem] = useState(false)

  const replaceRoadmapItem = useCallback((roadmapToReplace) => {
    const changedIndex = roadmapItems.findIndex(possibleItem => (
      roadmapToReplace.Roadmap_ID === possibleItem.Roadmap_ID
    ));
    const newRoadmapItems = [...roadmapItems];
    newRoadmapItems.splice(changedIndex, 1, roadmapToReplace);
    setRoadmapItems(newRoadmapItems);
  }, [roadmapItems, setRoadmapItems]);

  const updateRoadmapView = useCallback(async data => {
    try {
      const responseView = await RiskAssessmentService.updateAssessmentRoadmapViewItem(data);
      replaceRoadmapItem(responseView.payload);
      setReviewPageMode(reviewPageModes.ROADMAP_EDIT, responseView.payload.Roadmap_ID);
    } catch (error) {
      const { display, message: errorMessage } = (error.response?.data || {});
      const message = (display && errorMessage) ?
        errorMessage :
        "There was an error saving your progress. Please double-check your entries or try again later."
      setMessage([message, "error"]);
    }
  }, [replaceRoadmapItem, setMessage, setReviewPageMode]);

  const handleAutosave = useCallback((name, value) => {
    const data = {
      Roadmap_ID: roadmapItem?.Roadmap_ID,
      Description: roadmapItem?.RoadmapDescription,
      Notes: roadmapItem?.Notes,
      Status: roadmapItem?.Status,
      Target_Completion: roadmapItem?.TargetCompletion,
      [name]: value,
      Change_User: currentUserID(),
      Change_Date: defaultChangeDate(),
    };
    updateRoadmapView(data);
  }, [roadmapItem, updateRoadmapView]);

  const handleSelectChanged = useCallback(event => (
    handleAutosave(event.target.name, event.target.value)
  ), [handleAutosave]);

  const handleAddTaskSuccess = useCallback(async task => {
    const roadmapResponse = await RiskAssessmentService.createAssessmentRoadmapViewReference(
      roadmapItem, "Tasks", task.Task_ID
    );
    replaceRoadmapItem(roadmapResponse.payload);
  }, [replaceRoadmapItem, roadmapItem]);

  const handleEditTaskSuccess = useCallback(async () => {
    const roadmapResponse = await RiskAssessmentService.getAssessmentRoadmapView(
      roadmapItem.Roadmap_ID
    );
    replaceRoadmapItem(roadmapResponse.payload);
  }, [replaceRoadmapItem, roadmapItem]);

  const handleTaskWorkpackageChange = useCallback(async (event, taskOrWorkpackage) => {
    let viewResponse = null;
    const requestArguments = [
      roadmapItem,
      taskOrWorkpackage.Workpackage_or_task === "task" ? "Tasks" : "Workpackages",
      taskOrWorkpackage.ID,
      defaultChangeDate(),
    ];
    if (event.target.checked) {
      viewResponse = await RiskAssessmentService.createAssessmentRoadmapViewReference(
        ...requestArguments
      );
    } else {
      viewResponse = await RiskAssessmentService.deleteAssessmentRoadmapViewSearchReference(
        ...requestArguments
      );
    }
    replaceRoadmapItem(viewResponse.payload);
  }, [replaceRoadmapItem, roadmapItem]);

  const handleSubmit = useCallback(() => {
    setReviewPageMode(reviewPageModes.ROADMAP_TABLE, null);
    setMessage("");
  }, [setMessage, setReviewPageMode]);

  return (
    <div className={classes.contentWrapper}>
      <Paper
        elevation={2} className={classes.paper}
      >
        <div>
          <HeaderPrimary variant="h2Primary">
            {activeRoadmapId ? `Roadmap Item ${activeRoadmapId}` : "New Roadmap Item"}
          </HeaderPrimary>
        </div>
        <Grid className={classes.rowWrapper} container spacing={5} >
          {!!roadmapItem?.FindingOrRecommendation && (
            <Grid item md={6} sm={12} className={classNames(classes.gridItemWrapper, classes.left)}>
              <HeaderPrimary variant="h4Tertiary">
                {roadmapItem?.Type} {roadmapItem?.FindingRecommendationType}
              </HeaderPrimary>
              <div className={classes.summaryBody}>
                <DisplayHTML html={roadmapItem?.FindingOrRecommendation} />
              </div>
            </Grid>
          )}
          {!!roadmapItem?.Response && (
            <Grid item md={6} sm={12} className={classNames(classes.gridItemWrapper, classes.right)}>
              <HeaderPrimary variant="h4Tertiary">Response</HeaderPrimary>
              <div className={classes.summaryBody}>
                <DisplayHTML html={roadmapItem?.Response} />
              </div>
            </Grid>
          )}
        </Grid>
        <div
          elevation={2}
          className={classNames(classes.dataCollectionCard)}
        >
          <Form
            onChange={() => setMessage("")}
            onSubmit={handleSubmit}
            validations={{
              Status: [{ type: VALIDATION_REQUIRED }]
            }}
          >
            <Grid container spacing={5} className={classNames(classes.rowWrapper, classes.rowWrapper2)}>
              <Grid item md={6} sm={12} className={classNames(classes.gridItemWrapper, classes.left)}>
                <LabelInput
                  name="Description"
                  label={<span className={classNames(classes.fieldLabel)}>Roadmap Description:</span>}
                  defaultValue={roadmapItem?.RoadmapDescription}
                  onAutosave={handleAutosave}
                  multiline
                  margin="dense"
                  variant="default"
                  test="description"
                  rows={8}
                />
              </Grid>
              <Grid item md={6} sm={12} className={classNames(classes.gridItemWrapper, classes.right)}>
                <LabelInput
                  name="Notes"
                  label={<span className={classNames(classes.fieldLabel)}>Notes</span>}
                  defaultValue={roadmapItem?.RoadmapNotes}
                  onAutosave={handleAutosave}
                  multiline
                  margin="dense"
                  variant="default"
                  test="notes"
                  rows={8}
                />
              </Grid>
            </Grid>
            <Grid container spacing={5} className={classes.rowWrapper3}>
              <Grid item md={6} sm={12} className={classes.gridGroup}>
                <Grid item md={6} sm={6} className={classNames(classes.gridItemLeft)}>
                  <div className={classes.formLabel}>
                    Related Tasks:
                  </div>
                  <ul className={classes.relatedItemsList}>
                    {roadmapItem?.RoadmapRelatedTasks?.length ? (
                      roadmapItem.RoadmapRelatedTasks.map(task => (
                        <li className={classes.relatedItem} key={task.Task_ID}>
                          <div className={classes.relatedItemEllipsis}>
                            <CustomLink
                              variant="underline"
                              onClick={() => setEditModalItem(normalizeTaskResult(task))}
                            >
                              {task.Task_Name} - {task.Task_Status}
                            </CustomLink>
                          </div>
                        </li>
                      ))
                    ) : (
                      <li>None</li>
                    )}
                  </ul>
                </Grid>
                <Grid item md={6} sm={6} className={classNames(classes.gridItemRight)}>
                  <div className={classes.formLabel}>
                    Related Workpackages:
                  </div>
                  <ul className={classes.relatedItemsList}>
                    {(roadmapItem && roadmapItem.RoadmapRelatedWorkpackages) ?
                      roadmapItem.RoadmapRelatedWorkpackages.map(workpackage => (
                        <li
                          className={classes.relatedItem}
                          key={workpackage.Workpackage_ID}
                        >
                          <div className={classes.relatedItemEllipsis}>
                            {workpackage.Workpackage_Name}
                          </div>
                        </li>
                      )) : (
                        <li>None</li>
                      )}
                  </ul>
                </Grid>
              </Grid>
              <Grid item md={6} sm={12} className={classes.gridGroup}>
                <Grid item md={6} sm={6} className={classNames(classes.gridItemLeft)}>
                  <CustomSelect
                    name="Status"
                    labelId="Status"
                    label={<span className={classNames(classes.fieldLabel)}>Status*</span>}
                    defaultValue={roadmapItem?.RoadmapStatus || ""}
                    onChange={handleSelectChanged}
                    test="status"
                    margin="dense"
                    required={false}
                  >
                    <MenuItem value="Not Started" id="not-started">
                      Not Started
                    </MenuItem>
                    <MenuItem value="In Progress" id="in-progress">
                      In Progress
                    </MenuItem>
                    <MenuItem value="Complete" id="complete">
                      Complete
                    </MenuItem>
                  </CustomSelect>
                </Grid>
                <Grid item md={6} sm={6} className={classNames(classes.gridItemRight)}>
                  <LabelInput
                    name="Target_Completion"
                    defaultValue={roadmapItem?.TargetCompletion}
                    label="Target Completion"
                    variant="default"
                    id="Target_Completion"
                    placeholder={`Q4 ${moment().get("year")}`}
                    onAutosave={handleAutosave}
                    margin="dense"
                    test="targetCompletion"
                  />
                </Grid>
              </Grid>
            </Grid>


            <Grid container className={classes.rowWrapper4} spacing="5">
              <Grid item md={6} sm={12}>
                <div className={classes.addTaskLink}>
                  <AddListItem
                    className={classes.addTask}
                    variant="underline"
                    onClick={() => {
                      setAddModalOpen(true)
                    }}
                  >
                    Add Task
                  </AddListItem>
                  <CustomModal open={addModalOpen} onClose={() => setAddModalOpen(false)}>
                    <TaskForm
                      onSuccess={handleAddTaskSuccess}
                      parentComponent="TaskManager"
                      setEditModal={setAddModalOpen}
                      setRefresh={setRefresh}
                      refresh={refresh}
                      formMode="create"
                      formType="task"
                    />
                  </CustomModal>
                  <CustomModal open={!!editModalItem} onClose={() => setEditModalItem(null)}>
                    <TaskForm
                      setEditModal={setEditModalItem}
                      setRefresh={setRefresh}
                      refresh={refresh}
                      taskData={editModalItem}
                      activeTask={editModalItem}
                      parentComponent="TaskManager"
                      onSuccess={handleEditTaskSuccess}
                      formMode="edit"
                      formType={editModalItem?.Parent_ID ? "subTask" : "task"}
                      setFormType={() => editModalItem?.Parent_ID ? "subTask" : "task"}
                    />
                  </CustomModal>
                </div>
                <div className={classNames(classes.searchArea)}>
                  <SelectSearchResultsDialog
                    linkMessage="Search for and link an existing Task or Workpackage"
                    title="Tasks and Workpackages"
                    searchScopeType={searchScopeTypes.SEARCH_TASKS_WORKPACKAGES}
                    onChange={handleTaskWorkpackageChange}
                    selectedResults={
                      (roadmapItem?.RoadmapRelatedTasks || [])
                        .concat(roadmapItem?.RoadmapRelatedWorkpackages || [])
                    }
                    disabledResults={[]}
                  />
                </div>
              </Grid>
              <Grid className={classNames(classes.formEndButton)} item>
                <ButtonDefault
                  type="submit"
                  variant="medium"
                  background="primary"
                  startIcon={<CheckCircleOutline className={classNames(classes.saveIcon, classes.formEndIcon)} />}
                  data-cy="submit-review"
                  disableReadOnlyUsers
                >
                  Done
                </ButtonDefault>
              </Grid>
            </Grid>
          </Form>
        </div>
      </Paper>
    </div>
  )
}