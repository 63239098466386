import { makeStyles } from "@material-ui/core";
import { RECONCILIATION_MODE_PERSONAL, RECONCILIATION_MODE_RECONCILE } from "components/reconciliation/utils/reconciliation.utils";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import Header from "components/utils/header.component";
import { useCallback, useMemo } from "react";
import { currentUserID } from "utils/userHelpers";

const useStyles = makeStyles(() => ({
  activeMode: {
    fontWeight: 600
  },
  summary: {
    alignItems: "end",
  }
}));

const BLANK_COLUMN_VALUES = new Set(["", null, undefined]);

// Summary of completed DctElement reports using aggregated DctQuestion & DctResponse
export default function CategoryElementsSummary(props) {
  const classes = useStyles();
  const {
    elements, countElements = false, gridItemProps = {}, mode
  } = props;

  const currentUserId = useMemo(currentUserID, []);

  const filterCompletedResponses = useCallback(responses => (
    responses.filter(response => (
      !BLANK_COLUMN_VALUES.has(response.Answer)
    ))
  ), []);

  const groupResponses = useCallback(responses => {
    const results = { self: [], other: [], reconciled: [] };
    for (const response of responses) {
      if (response.Is_Reconciled) {
        results.reconciled.push(response);
      } else if (response.User_ID === currentUserId) {
        results.self.push(response);
      } else if (response.User_ID) {
        results.other.push(response);
      } else {
        console.error("Invalid response: ", response);
      }
    }
    return results;
  }, [currentUserId]);

  const allElementResponses = useMemo(() => (
    !countElements ? [] : filterCompletedResponses(
      elements.flatMap(element => (
        element._associations.DctResponse || []
      ))
    )
  ), [elements, countElements, filterCompletedResponses]);

  const allQuestionResponses = useMemo(() => (
    filterCompletedResponses(
      elements.flatMap(element => (
        (element._associations.DctQuestion || []).flatMap(question => (
          question._associations.DctResponse || []
        ))
      ))
    )
  ), [elements, filterCompletedResponses]);

  const {
    self: selfElementResponses,
    other: otherElementResponses,
    reconciled: reconciledElementResponses
  } = useMemo(() => (
    groupResponses(allElementResponses)
  ), [allElementResponses, groupResponses]);

  const {
    self: selfQuestionResponses,
    other: otherQuestionResponses,
    reconciled: reconciledQuestionResponses
  } = useMemo(() => (
    groupResponses(allQuestionResponses)
  ), [allQuestionResponses, groupResponses]);

  return (
    <div className={classes.summary} data-cy="dct-element-summary">
      <GridContainer>
        <GridItem xs={12}>
          <Header variant="h3Primary">
            DCT Element Summary
          </Header>
        </GridItem>
        <GridItem xs={12} {...gridItemProps} data-cy="your-reports">
          <Header variant="h5Tertiary">
            Your Reports
          </Header>
          <ElementCount
            active={mode === RECONCILIATION_MODE_PERSONAL}
            elements={elements}
            countElements={countElements}
            relevantElementResponses={selfElementResponses}
            relevantQuestionResponses={selfQuestionResponses}
          />
        </GridItem>
        <GridItem xs={12} {...gridItemProps} data-cy="other-reports">
          <Header variant="h5Tertiary">
            Others&apos; Reports
          </Header>
          <ElementCount
            elements={elements}
            countElements={countElements}
            relevantElementResponses={otherElementResponses}
            relevantQuestionResponses={otherQuestionResponses}
          />
        </GridItem>
        <GridItem xs={12} {...gridItemProps} data-cy="final-reports">
          <Header variant="h5Tertiary">
            Final Reconcilitation
          </Header>
          <ElementCount
            active={mode === RECONCILIATION_MODE_RECONCILE}
            elements={elements}
            countElements={countElements}
            relevantElementResponses={reconciledElementResponses}
            relevantQuestionResponses={reconciledQuestionResponses}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

function ElementCount(props) {
  const classes = useStyles();
  const {
    active,
    elements,
    countElements,
    relevantElementResponses,
    relevantQuestionResponses
  } = props;

  const relevantElementResponseIds = useMemo(() => (
    new Set(relevantElementResponses.map(response => response.DctObject_ID))
  ), [relevantElementResponses]);

  const relevantQuestionResponseIds = useMemo(() => (
    new Set(relevantQuestionResponses.map(response => response.DctObject_ID))
  ), [relevantQuestionResponses]);

  const completedElements = useMemo(() => {
    const withCompletedQuestions = elements.filter(element => (
      element._associations.DctQuestion.every(question => (
        relevantQuestionResponseIds.has(question.DctQuestion_ID)
      ))
    ));
    if (countElements) {
      return withCompletedQuestions.filter(element => (
        relevantElementResponseIds.has(element.DctElement_ID)
      ));
    }
    return withCompletedQuestions
  }, [
    elements, countElements,
    relevantElementResponseIds, relevantQuestionResponseIds
  ]);

  return (
    <div className={active ? classes.activeMode : undefined}>
      {completedElements.length}
      &nbsp;of&nbsp;
      {elements.length} completed
    </div>
  );
}
