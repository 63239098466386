import { Grid, makeStyles } from "@material-ui/core";
import classNames from "classnames";


const useStyles = makeStyles(theme => ({
  noVerticalSpacing: {
    marginTop: "0 !important",
    marginBottom: "0 !important",
    "& > .MuiGrid-item": {
      paddingTop: "0 !important",
      paddingBottom: "0 !important"
    }
  }
}));

export function GridContainer({ children, hasVerticalSpacing, ...props }) {
  const classes = useStyles();
  return (
    <Grid
      className={classNames(!hasVerticalSpacing && classes.noVerticalSpacing)}
      {...props}
      container
    >
      {children}
    </Grid>
  );
}