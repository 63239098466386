import { removeObjInArray } from "utils/arrayOfObjectsHelpers";
import { mergeReplace, mergeReplaceList } from "reducers/utils/payloadUtils";
import { activeRbkAndGlobalUsersFilter } from "utils/roles";
export const ACTION_SET_RUNBOOKS = "runbooks__set";
export const ACTION_SET_ALL_RUNBOOK_PHASES = "runbook__all_runbook_phases__set";
export const ACTION_SET_WORKGROUPS = "workgroups__set";
export const ACTION_SET_WORKSTREAMS = "workstreams__set";
export const ACTION_SET_USERS = "user__set";
export const ACTION_ADD_WORKGROUP = "workgroups__add";
export const ACTION_ADD_WORKSTREAM = "workstreams__add";
export const ACTION_ADD_USER = "user__add";
export const ACTION_REMOVE_WORKGROUP = "workgroups__remove";
export const ACTION_REMOVE_WORKSTREAM = "workstreams__remove";
export const ACTION_REPLACE_WORKGROUP = "workgroups__replace";
export const ACTION_REPLACE_WORKSTREAM = "workstreams__replace";
export const ACTION_REPLACE_RUNBOOK = "runbooks__replace";
export const ACTION_CREATE_RUNBOOK = "runbook__create";

const mergeWorkgroupUsers = (state, action) => (
  !action.payload ?
    [] :
    mergeReplaceList(
      state.users || [],
      [].concat(action.payload).flatMap(workgroup => (
        workgroup._associations.WorkgroupMembers.flatMap(member => (
          member._associations.Users
        ))
      )),
      "User_ID"
    )
);

const mergeWorkstreamUsers = (state, action) => (
  !action.payload ?
    [] :
    mergeReplaceList(
      state.users || [],
      [].concat(action.payload).flatMap(workstream => (
        workstream._associations.WorkstreamMembers.flatMap(member => (
          member._associations.Users
        ))
      )),
      "User_ID"
    )
);


export const runbooksInitialState = {
  runbooks: null,
  allRunbookPhases: null,
  workgroups: null,
  workstreams: null,
  users: null,
};

export default function runbooksReducer(state, action) {
  switch(action.type) {
    case ACTION_SET_RUNBOOKS:
      return {
        ...state,
        runbooks: action.payload
      };
    case ACTION_SET_ALL_RUNBOOK_PHASES:
      return {
        ...state,
        allRunbookPhases: action.payload
      };
    case ACTION_SET_WORKGROUPS:
      return {
        ...state,
        workgroups: action.payload,
        users: mergeWorkgroupUsers(state, action)
      };
    case ACTION_SET_WORKSTREAMS:
      return {
        ...state,
        workstreams: action.payload,
        users: mergeWorkstreamUsers(state, action)
      };
    case ACTION_SET_USERS:
      return {
        ...state,
        users: activeRbkAndGlobalUsersFilter(action.payload)
      };
    case ACTION_ADD_WORKSTREAM:
      return {
        ...state,
        workstreams: [...state.workstreams, action.payload],
        users: mergeWorkstreamUsers(state, action)
      };
    case ACTION_ADD_WORKGROUP:
      return {
        ...state,
        workgroups: [...state.workgroups, action.payload],
        users: mergeWorkgroupUsers(state, action)
      };
    case ACTION_ADD_USER:
      return {
        ...state,
        users: [...state.users, action.payload]
      };
    case ACTION_REPLACE_WORKSTREAM:
      return {
        ...state,
        workstreams: [...mergeReplace(
          state.workstreams,
          action.payload,
          "Workstream_ID",
        )],
        users: mergeWorkstreamUsers(state, action)
      };
    case ACTION_REPLACE_WORKGROUP:
      return {
        ...state,
        workgroups: [...mergeReplace(
          state.workgroups,
          action.payload,
          "Workgroup_ID",
        )],
        users: mergeWorkgroupUsers(state, action)
      };
    case ACTION_REPLACE_RUNBOOK:
      return {
        ...state,
        runbooks: [...mergeReplace(
          state.runbooks,
          action.payload,
          "Runbook_ID",
        )]
      };
    case ACTION_REMOVE_WORKGROUP:
      return {
        ...state,
        workgroups: removeObjInArray(
          state.workgroups,
          "Workgroup_ID",
          action.payload.Workgroup_ID
        )
      };
    case ACTION_REMOVE_WORKSTREAM:
      return {
        ...state,
        workstreams: removeObjInArray(
          state.workstreams,
          "Workstream_ID",
          action.payload.Workstream_ID
        )
      };
    case ACTION_CREATE_RUNBOOK:
      return {
        ...state,
        runbooks: [
          ...state.runbooks,
          action.payload
        ]
      };
    default:
      throw new Error(`Unrecognized action: ${action.type}`);
  }
}
