import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { formatAssessmentItemRecommendations, getAssessmentItemName } from "utils/assessmentHelpers";
import {
  defaultChangeDate,
  formattedMomentCustomError,
} from "utils/dateHelpers";
import { makeStyles } from "@material-ui/styles";
import { Box, Divider } from "@material-ui/core";
import RiskAssessmentService from "services/riskAssessment.service";
import CustomSunEditor from "components/utils/sunEditor.component";
import DialogPop from "components/utils/dialog.component";
import HeaderPrimary from "components/utils/header.component";
import ButtonDefault from "components/utils/buttonDefault.component";
import DisplayHTML from "components/utils/displayHTML.component";
import classNames from "classnames";
import variables from "styleVariables";
import moment from "moment";
import DeleteIconButton from "components/utils/deleteIconButton.component";
import EditIconButton from "components/utils/editIconButton.component";
import {
  assessmentTableIdMap,
} from "utils/assessmentConstants";
import { currentUserID } from 'utils/userHelpers';
import { CachedOutlined, CheckCircleOutline } from '@material-ui/icons';
import BottomControlsBar from '../shared/bottomControlsBar.component';
import { useParams, useHistory } from 'react-router-dom';
import Form from "components/utils/form-elements/form.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import AddListItem from "components/utils/addListItem.component";
import Save from "@material-ui/icons/Save";



const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space between",
    marginBottom: 100, // Give space for the floating back button
    paddingTop: 0,
    paddingLeft: 32,
    paddingRight: 32,
    maxWidth: 1700,
    margin: "0px auto",
  },
  flexboxItem: {
    border: "0px solid red",
  },
  flexboxItem1: {
    padding: 15,
  },
  flexboxItem2: {
    padding: 10,
  },
  sunEditor: {
    paddingBottom: 20,
  },
  formMessage: {
    marginRight: 16,
    marginLeft: 16,
    display: "inline-flex",
    alignItems: "center",
    color: variables.statusGreenDark
  },
  formButton: {
    marginLeft: 8,
  },
  saveEditorButton: {
    color: "white",
    height: variables.fontSmall,
  },
  buttonSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    marginTop: 0,
    marginBottom: 8,
  },
  assessmentRecommendations: {
    overflowY: "hidden",
    paddingRight: 16,
  },
  itemRecommendation: {
    maxHeight: "60vh",
    overflowY: "auto",
    paddingTop: 10,
  },
  items: {
    paddingBottom: 10,
    color: variables.textSecondary,
  },
  designPerformance: {
    paddingBottom: 15,
  },
  designPerformanceAddress: {
    paddingBottom: 6,
  },
  recommendationRow: {
    display: "flex",

    borderBottom: `2px solid ${variables.tertiary2}`,
  },
  readRow: {
    alignItems: "center",
    padding: "5px 0 5px 0",
  },
  editRow: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 0 10px 0",
  },
  recommendationParagraph: {
    paddingRight: 8,
    paddingLeft: 16,
    margin: 0,
    color: variables.textSecondary,
    flex: 1,
  },
  recommendationControls: {
    display: "flex",
    justifyContent: "flex-end",
    justifySelf: "flex-end",
  },
  editRecommendationParagraph: {
    paddingRight: 16
  },
  activeRec: {
    backgroundColor: variables.chosenElement,
  },
  buttonWrapper: {
    paddingLeft: 5,
  },
  addButtonWrapper: {
    height: 32
  },
  summaryTitle: {
    marginBottom: 20,
  },
  noRecommendation: {
    color: variables.textSecondary,
  },
  addLabelRow: {
    display: "flex",
    //   alignContent: "baseline",
    alignItems: "baseline",
    marginBottom: 20,
  },
  editHeader: {
    paddingTop: 16
  },
}));
const clear_editor_html1 = "<p></p>";
const clear_editor_html2 = "<p><br></p>";
const emptyEditors = [clear_editor_html1, clear_editor_html2, ""];
const tableMapId = assessmentTableIdMap.SUMMARY_RECOMMENDATIONS;

const NEW_ID = "new"

const SummaryRecommendation = (props) => {
  const classes = useStyles();
  const recommendationRef = useRef({});

  //used to hold the Assessment Recommendation that can be Edited or Deleted
  const [recItemToDelete, setRecItemToDelete] = useState({});

  //used to open and close DialogPop for deletion calls
  const [openDeletionDialog, setOpenDeletionDialog] = useState(false);
  const [highlightId, setHighlightId] = useState(null);
  const [saveStatus, setSaveStatus] = useState("");

  //sets the inner content of the Editor box used both when adding and editting
  const [existingRecHTMLContents, setExistingRecHTMLContents] =
    useState(clear_editor_html1);
  const [mainHTMLContent, setMainHTMLContent] = useState(clear_editor_html1);

  //Used to store the Item Level Recommendations from the DesignDC
  const [itemDesignRecommendations, setItemDesignRecommendations] = useState([]);

  const [refreshList, setRefreshList] = useState(null);

  //Used to store the Item Level Recommendations from the AssessmentPerformancesReview
  const [itemPerformanceRecommendations, setItemPerformanceRecommendations] =
    useState([]);
  const [activeEditor, setActiveEditor] = useState(NEW_ID);

  const mainEditorIsEmpty = useMemo(() => {
    return emptyEditors.includes(mainHTMLContent);
  }, [mainHTMLContent]);

  const existingRecEditorIsEmpty = useMemo(() => {
    return emptyEditors.includes(existingRecHTMLContents);
  }, [existingRecHTMLContents]);

  const builderId = props.builderInfo.Builder_ID;
  const userId = currentUserID();
  const recommendations = props.summaryRecommendations;
  const setRecommendations = props.setSummaryRecommendations;
  const history = useHistory();

  const {programId, programComponentId, componentObjectId} = useParams();

  useEffect(() => {
    RiskAssessmentService.getAssessmentSummaryRecommendationsByBuilderID(
      builderId
    )
      .then((res) => {
        setRecommendations(res.items);
      })
      .catch((err) => {
        alert("There was an issue retrieving the Assessment Recommendations");
      });
  }, [builderId, setRecommendations, refreshList]);

  useEffect(() => {
    RiskAssessmentService.getAssessmentItemRecommendationsByBuilderID(
      builderId
    )
      .then((res) => {
        return formatAssessmentItemRecommendations(res.items)
      })
      .then((formatRes) => {
        setItemDesignRecommendations(formatRes.Design_Recommendations)
        setItemPerformanceRecommendations(formatRes.Performance_Recommendations)
      })
  }, [builderId])

  const handleGoBack = () => {
    history.push(`/program/${programId}/${programComponentId}/${componentObjectId}/assessment/${builderId}/review`)
  };

  const addNewRecommendation = async () => {
    if (!mainEditorIsEmpty) {
      //CREATE NEW
      const assessmentSummaryRecommendation = {
        Recommendation: mainHTMLContent,
        AssessmentBuilder_ID: builderId,
        Change_User: userId,
        Change_Date: defaultChangeDate(),
      };
      try {
        const { payload } = await RiskAssessmentService.saveAssessmentSummaryRecommendations(
          assessmentSummaryRecommendation
        )
        const newID = payload.Recommendation_ID;
        setMainHTMLContent(clear_editor_html1);
        setHighlightId(newID);
        setRecommendations((prev) => [...prev, {
          ...assessmentSummaryRecommendation,
          Recommendation_ID: newID,
        }]);
        setActiveEditor(null);
        setSaveStatus("");
      } catch(error) {
        alert("There was a problem saving this Recommendation");
      }
    }
  };
  // Called by addNewRecommendation and editExistingRecommendation
  const editRec = async (id, content, mainEditor) => {
    const updatedRecommendationItem = {
      Recommendation_ID: id,
      AssessmentBuilder_ID: builderId,
      Recommendation: content,
      Change_Date: defaultChangeDate(),
      Change_User: userId,
    };
    await RiskAssessmentService.updateAssessmentData(
      updatedRecommendationItem,
      updatedRecommendationItem.Recommendation_ID,
      tableMapId
    );

  };

  const editExistingRecommendation = async (recID, initialValue) => {
    if (existingRecHTMLContents !== initialValue && !existingRecEditorIsEmpty) {
      setExistingRecHTMLContents(clear_editor_html1);
      await editRec(recID, existingRecHTMLContents, false);
      setRefreshList(Date.now())
      setTimeout(() => (
        setSaveStatus("Updated")
      ), 200);
    }
  };

  const openVerifyDeletion = (item) => {
    setOpenDeletionDialog(true);
    setRecItemToDelete(item);
    setHighlightId(null);
  };

  const deleteRecommendation = () => {
    const item = recItemToDelete;
    const { First_Name, ...noFirstName } = item;
    const { Last_Name, ...remainingItems } = noFirstName;
    RiskAssessmentService.updateAssessmentData(
      { ...remainingItems, isDelete: true },
      item.Recommendation_ID,
      tableMapId
    ).then((res) => {
      const updatedList = recommendations.filter(
        (rec) => rec.Recommendation_ID !== item.Recommendation_ID
      );
      setRecommendations(updatedList);
      setOpenDeletionDialog(false);
    });
  };

  const activateEditor = (id, recObj) => {
    setHighlightId(null);
    setActiveEditor(id);
    setExistingRecHTMLContents(recObj.Recommendation);
    setSaveStatus("");
  };
  //change handlers
  const handleExistingRecChange = (contents) => {
    setExistingRecHTMLContents(contents);
    setSaveStatus("");
  };

  const handleNewRecChange = (contents) => {
    if (!emptyEditors.includes(contents)) {
      setHighlightId(null);
    }
    setMainHTMLContent(contents);
    setSaveStatus("");
  };

  const finishEdit = () => {
    setActiveEditor(null);
    setSaveStatus("");
  };

  return (
    <Form name="summary-recommendation-add">
      <GridContainer
        spacing={8}
        className={classes.pageContainer}
        data-cy="assessment_recommendations_page"
      >
        <GridItem
          xs={12}
          md={8}
          className={classNames(classes.flexboxItem, classes.flexboxItem1)}
        >
          <HeaderPrimary
            className={classes.summaryTitle}
            variant="h2Primary"
            data-cy="assessment-recommendations"
          >
            Assessment Summary Recommendations
          </HeaderPrimary>
          <div className={classes.assessmentRecommendations}>
            {activeEditor === NEW_ID ? (
              <>
                <div className={classes.addLabelRow}>
                  <HeaderPrimary
                    className={classes.addHeader}
                    variant="h3Primary"
                  >
                    New Recommendation
                  </HeaderPrimary>
                </div>
                <div className={classes.sunEditor}>
                  <CustomSunEditor
                    className={classes.sunEditor}
                    onChange={handleNewRecChange}
                    height="15vh"
                    data-cy="recommendation-editor"
                    name="recommendation-editor"
                    defaultValue={mainHTMLContent}
                    autoFocus={false}
                  />
                  <div className={classes.buttonSection}>
                    <span>
                      {!!saveStatus && (
                        <span className={classes.formMessage}>
                          <CheckCircleOutline />
                          <Box marginLeft={1}>{saveStatus}</Box>
                        </span>
                      )}
                      <ButtonDefault
                        variant="small"
                        background="primary"
                        data-cy="btn-edit-recommendation-finish"
                        startIcon={
                          <Save className={classes.saveEditorButton} />
                        }
                        onClick={addNewRecommendation}
                        disabled={mainEditorIsEmpty}
                        disableReadOnlyUsers
                      >
                        Save
                      </ButtonDefault>
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <div
                className={classNames(
                  classes.recommendationRow,
                  classes.readRow
                )}
                data-cy="recommendation-added_section"
                ref={(el) => (recommendationRef.current.new = el)}
              >
                <div
                  className={classNames(classes.recommendationParagraph)}
                >
                  <div className={classes.recommendationControls}>
                    <div
                      className={
                        classNames(classes.buttonWrapper, classes.addButtonWrapper)
                      }
                    >
                      <AddListItem
                        onClick={() => {
                          activateEditor(NEW_ID, { Recommendation: "" });
                        }}
                        target=""
                        disableReadOnlyUsers
                      >
                        Add new
                      </AddListItem>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {recommendations.map((item) => {
              const itemId = item.Recommendation_ID;
              return activeEditor !== itemId ? (
                <div
                  className={classNames(
                    classes.recommendationRow,
                    classes.readRow,
                    itemId === highlightId && classes.activeRec
                  )}
                  key={itemId}
                  data-cy="recommendation-added_section"
                  ref={(el) => (recommendationRef.current[itemId] = el)}
                >
                  <div
                    className={classNames(
                      classes.recommendationParagraph,
                      classes.editRecommendationParagraph
                    )}
                    id={item.Recommendation}
                  >
                    <DisplayHTML html={item.Recommendation} />
                  </div>
                  <div className={classes.recommendationControls}>
                    <div className={classes.buttonWrapper}>
                      <EditIconButton
                        onClick={() => {
                          activateEditor(itemId, item);
                        }}
                        target=""
                        disableReadOnlyUsers
                      />
                    </div>
                    <div className={classes.buttonWrapper}>
                      <DeleteIconButton
                        target=""
                        onClick={() => {
                          openVerifyDeletion(item);
                        }}
                      />
                    </div>
                  </div>
                  <Divider />
                </div>
              ) : (
                <div
                  className={classNames(
                    classes.recommendationRow,
                    classes.editRow
                  )}
                  key={itemId}
                  data-cy="recommendation-added_section"
                >
                  <HeaderPrimary
                    className={classes.editHeader}
                    variant="h3Primary"
                  >
                    Edit Recommendation
                  </HeaderPrimary>
                  <CustomSunEditor
                    className={classes.sunEditor}
                    defaultValue={existingRecHTMLContents}
                    height="15vh"
                    data-cy="recommendation-editor"
                    name={`recommendation-editor-${itemId}`}
                    onAutosave={() => (
                      editExistingRecommendation(itemId, item.Recommendation)
                    )}
                    onChange={handleExistingRecChange}
                    autoFocus
                  />
                  <span className={classes.buttonSection}>
                    {!!saveStatus && (
                      <span className={classes.formMessage}>
                        <CheckCircleOutline />
                        <Box marginLeft={1}>{saveStatus}</Box>
                      </span>
                    )}
                    <ButtonDefault
                      variant="small"
                      background="secondary"
                      data-cy="btn-edit-recommendation-finish"
                      className={classes.formButton}
                      startIcon={
                        <Save className={classes.saveEditorButton} />
                      }
                      disabled={existingRecEditorIsEmpty}
                      disableReadOnlyUsers
                    >
                      Save
                    </ButtonDefault>
                    <ButtonDefault
                      variant="small"
                      background="primary"
                      data-cy="btn-edit-recommendation-finish"
                      className={classes.formButton}
                      startIcon={
                        <CachedOutlined
                          className={classes.saveEditorButton}
                        />
                      }
                      onClick={() => finishEdit()}
                      disableReadOnlyUsers
                    >
                      Done
                    </ButtonDefault>
                  </span>
                </div>
              );
            })}

            {recommendations.length === 0 && (
              <div className={classes.noRecommendation}>No Recommendations</div>
            )}
          </div>

          <DialogPop
            largeLabel
            prompt="Are you sure you want to delete this recommendation?"
            openDialog={openDeletionDialog}
            setOpenDialog={setOpenDeletionDialog}
            confirm={() => deleteRecommendation()}
          />
        </GridItem>

        <GridItem
          xs={12}
          md={4}
          className={classNames(classes.flexboxItem, classes.flexboxItem2)}
        >
          <div className={classes.itemLevelRecommendation}>
            <HeaderPrimary variant="h3Primary">
              Item Level Recommendations:
            </HeaderPrimary>
            <div
              className={classes.itemRecommendation}
              data-cy="item-level-info"
            >
              {itemDesignRecommendations.length > 0 ||
              itemPerformanceRecommendations.length > 0 ? (
                  [
                    ...itemDesignRecommendations,
                    ...itemPerformanceRecommendations,
                  ].map((item) => {
                    const revType = item.Design_ID ? "Design" : "Performance";
                    const itemID = item.Design_ID
                      ? item.Design_ID
                      : item.Performance_ID;
                    return (
                      <div className={classes.items} key={`${itemID}-${revType}`}>
                        <HeaderPrimary
                          className={classes.designPerformance}
                          variant="h4Tertiary"
                        >
                          {getAssessmentItemName(item)} - {revType}
                        </HeaderPrimary>
                        <div className={classes.designPerformanceAddress}>
                          {`${
                            item.First_Name
                          } ${item.Last_Name} ${formattedMomentCustomError(
                          moment(item.Change_Date).format("M/D/YYYY")
                          )}`}
                        </div>
                        <p>{item.FindingRecommendation}</p>
                      </div>
                    );
                  })
                ) : (
                  <div
                    className={classes.noRecommendation}
                    data-cy="no-item-recommendation"
                  >
                  No Item Recommendations Recorded
                  </div>
                )}
            </div>
          </div>
        </GridItem>
      </GridContainer>
      <BottomControlsBar
        backClick={handleGoBack}
        doneClick={handleGoBack}
      />
    </Form>
  );
};

export default SummaryRecommendation;
