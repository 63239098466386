import { makeStyles, Paper, Table, TableBody, TableRow } from "@material-ui/core";
import Form from "components/utils/form-elements/form.component";
import React, { Fragment, memo, useCallback, useMemo, useState } from "react";
import { currentUserID, currentUserInfo } from "utils/userHelpers";
import EditableTableCell from "./editableTableCell.component";
import ReconciliationTableHead from "./reconciliationTableHead.component";
import ReadOnlyTableCell from "./readOnlyTableCell.component";
import DescriptionTableCell from "./descriptionTableCell.component";
import { mergeCellColumnOverrides } from "./utils/reconciliationTableUtils";
import Loader from "components/utils/loader.components";
import { isReadOnly } from "utils/roles";
import { COLUMN_TYPE_USER, COLUMN_TYPE_EMPTY } from "./constants/reconciliationTableConstants";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginBottom: 8,
    overflowX: "auto",
    overflowY: "hidden"
  },
  form: {
    minWidth: 600
  },
  table: {
    overflow: "hidden"
  },
}));

const ReconciliationTable = memo(ReconciliationTableUnmemoized);

export default ReconciliationTable;

function ReconciliationTableUnmemoized(props) {
  const classes = useStyles();
  const {
    categorizedItems, categoryRefs, columns: columnsProp, isReconcile,
    itemLabel, onCategoryAutosave = null, onItemAutosave, questionRefs,
    sublabelFilter, userFilter, users
  } = props;

  const [didCancel, setDidCancel] = useState(false);

  const currentUser = useMemo(currentUserInfo, []);
  const readOnly = useMemo(isReadOnly, []);

  const columns = useMemo(() => (
    (isReconcile || userFilter !== "self") ?
      columnsProp :
      columnsProp.filter(column => column.type !== COLUMN_TYPE_USER)
  ), [columnsProp, isReconcile, userFilter]);

  const usersById = useMemo(() => (
    users?.reduce?.((accumulator, user) => ({
      ...accumulator,
      [user.User_ID]: user
    }), { [undefined]: currentUser })
  ), [users, currentUser]);

  const BodyRowComponent = useMemo(() => (
    isReconcile ? ReconcileModeBodyRow : PersonalModeBodyRow
  ), [isReconcile]);

  const beforeAutosave = useCallback((response, name, value) => {
    if ((!value && !response) || response?.[name] === value) {
      return false;
    }
    if (didCancel) {
      setDidCancel(false);
      return false;
    }
    return true;
  }, [didCancel, setDidCancel]);

  const handleCategoryAutosave = useCallback(
    (response, category, isReconciled, name, value) => {
      const shouldAutosave = beforeAutosave(response, name, value);
      if (shouldAutosave) {
        if (onCategoryAutosave) {
          return onCategoryAutosave(response, category, isReconciled, name, value);
        }
        return onItemAutosave(response, category, isReconciled, name, value);
      }
    },
    [beforeAutosave, onCategoryAutosave, onItemAutosave]
  );

  const handleItemAutosave = useCallback(
    (response, item, isReconciled, name, value) => {
      const shouldAutosave = beforeAutosave(response, name, value);
      if (shouldAutosave) {
        return onItemAutosave(response, item, isReconciled, name, value);
      }
    },
    [beforeAutosave, onItemAutosave]
  );

  const handleKeyDown = useCallback(event => {
    switch (event.key) {
      case "Escape":
        setDidCancel(true);
        return event.target.blur();
      case "Return":
      default:

    }
  }, [setDidCancel]);

  if (!usersById || !categorizedItems?.length) {
    return (
      <Loader />
    );
  }
  return (
    <Paper
      className={classes.formContainer}
    >
      <Form className={classes.form} name="reconciliation">
        <Table className={classes.table}>
          {categorizedItems.map(
            ({ categoryItem, enumeration, items }, categoryIndex, all) => (
              <Fragment key={categoryItem.id}>
                <ReconciliationTableHead
                  columns={columns}
                  itemLabel={itemLabel}
                  scrollRef={element => {
                    if (categoryRefs?.current) {
                      categoryRefs.current[categoryItem.id] = element;
                    }
                  }}
                />
                <TableBody>
                  <BodyRowComponent
                    categoryIndex={categoryIndex}
                    categoriesLength={all.length}
                    columns={columns}
                    enumeration={enumeration}
                    item={categoryItem}
                    onAutosave={handleCategoryAutosave}
                    onKeyDown={handleKeyDown}
                    readOnly={readOnly}
                    sublabelFilter={sublabelFilter}
                    userFilter={userFilter}
                    usersById={usersById}
                  />
                  {items.map((item, index) => (
                    <React.Fragment key={item.id}>
                      <BodyRowComponent
                        categoryIndex={categoryIndex}
                        categoriesLength={all.length}
                        columns={columns}
                        index={index}
                        item={item}
                        onAutosave={handleItemAutosave}
                        onKeyDown={handleKeyDown}
                        readOnly={readOnly}
                        scrollRef={element => {
                          if (questionRefs?.current) {
                            questionRefs.current[item.id] = element
                          }
                        }}
                        sublabelFilter={sublabelFilter}
                        userFilter={userFilter}
                        usersById={usersById}
                      />
                    </React.Fragment>
                  ))}
                </TableBody>
              </Fragment>
            )
          )}
        </Table>
      </Form>
    </Paper>
  )
}

const makeCellColumns = (columns, item, index) => (
  columns.map(column => {
    const cellColumn = { ...column };
    if (column.isCategoryHidden && index === undefined) {
      cellColumn.type = COLUMN_TYPE_EMPTY;
    }
    return mergeCellColumnOverrides(cellColumn, item);
  })
);


const ReconcileModeBodyRow = memo(
  function ReconcileModeBodyRowBase(props) {
    const {
      categoryIndex, categoriesLength, columns, enumeration, index, item,
      onAutosave, onKeyDown, readOnly, scrollRef, sublabelFilter, usersById
    } = props;

    const cellColumns = useMemo(() => (
      makeCellColumns(columns, item, index)
    ), [columns, index, item]);

    return (
      <>
        {item.responses?.map?.((response, responseIndex) => (
          <TableRow hover={false} key={response.id || `index-${responseIndex}`}>
            {responseIndex === 0 && (
              <DescriptionTableCell
                categoryIndex={categoryIndex}
                categoriesLength={categoriesLength}
                enumeration={enumeration}
                index={index}
                item={item}
                responseCount={1 + item.responses?.length}
                sublabelFilter={sublabelFilter}
              />
            )}
            {cellColumns?.map?.(column => (
              <ReadOnlyTableCell
                column={column}
                response={response}
                usersById={usersById}
                key={column.key}
                isReconcile
              />
            ))}
          </TableRow>
        ))}
        <TableRow
          hover={false}
          key={item.label}
        >
          {!item.responses?.length && (
            <DescriptionTableCell
              categoryIndex={categoryIndex}
              categoriesLength={categoriesLength}
              enumeration={enumeration}
              index={index}
              item={item}
              responseCount={1 + item.responses?.length}
              scrollRef={scrollRef}
              sublabelFilter={sublabelFilter}
            />
          )}
          {readOnly ? (
            cellColumns?.map?.(column => (
              <ReadOnlyTableCell
                column={column}
                response={item.reconciledResponse}
                usersById={usersById}
                isReconcile
                key={column.key}
              />
            ))
          ) : (
            cellColumns?.map?.(column => (
              <EditableTableCell
                column={column}
                onAutosave={(name, value) => (
                  onAutosave(item.reconciledResponse, item, true, name, value)
                )}
                onKeyDown={onKeyDown}
                response={item.reconciledResponse}
                usersById={usersById}
                isReconcile
                key={column.key}
              />
            ))
          )}
        </TableRow>
      </>
    );
  }
);

function comparePersonalRowProps(props, prevProps) {
  const { item, ...otherProps } = props;
  if (item.lastUpdated !== prevProps?.item?.lastUpdated) {
    return false;
  }
  return Object.entries(otherProps).every(([key, prop]) => (
    prop === prevProps[key]
  ));
}

window.prevItem = {};

const PersonalModeBodyRow = memo(
  function PersonalModeBodyRowBase(props) {
    const {
      categoryIndex, categoriesLength, columns, enumeration, index,
      item, onAutosave, onKeyDown, readOnly, scrollRef,
      sublabelFilter, userFilter, usersById
    } = props;

    const userId = useMemo(currentUserID, []);

    const personalResponse = useMemo(() => (
      item.responses.find(response => response.userId === userId)
    ), [item, userId]);

    const otherUserResponses = useMemo(() => (
      (userFilter !== "all" || !item.responses) ? [] : (
        item.responses.filter(response => (
          personalResponse !== response &&
          Object.values(response.data || {}).some(responseValue => (
            !!responseValue ||
            responseValue === false ||
            (typeof responseValue === "string" && responseValue.trim?.())
          ))
        ))
      )
    ), [item, personalResponse, userFilter]);

    const cellColumns = useMemo(() => (
      makeCellColumns(columns, item, index)
    ), [columns, index, item]);

    return (
      <>
        <TableRow
          hover={false}
          key={item.label}
        >
          <DescriptionTableCell
            categoryIndex={categoryIndex}
            categoriesLength={categoriesLength}
            enumeration={enumeration}
            index={index}
            item={item}
            responseCount={1 + otherUserResponses.length}
            scrollRef={scrollRef}
            sublabelFilter={sublabelFilter}
          />
          {readOnly ? (
            cellColumns?.map?.(column => (
              <ReadOnlyTableCell
                column={column}
                response={personalResponse}
                usersById={usersById}
                key={column.key}
              />
            ))
          ) : (
            cellColumns?.map?.(column => (
              <EditableTableCell
                column={column}
                onAutosave={(name, value) => (
                  onAutosave(personalResponse, item, false, name, value)
                )}
                onKeyDown={onKeyDown}
                response={personalResponse}
                usersById={usersById}
                key={column.key}
              />
            ))
          )}
        </TableRow>
        {otherUserResponses?.map?.((response, rowIndex) => (
          <TableRow key={response.id || `index-${rowIndex}`}>
            {cellColumns?.map?.(column => (
              <ReadOnlyTableCell
                column={column}
                response={response}
                usersById={usersById}
                key={column.key}
              />
            ))}
          </TableRow>
        ))}
      </>
    );
  },
  comparePersonalRowProps
);