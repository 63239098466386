import http from "./http-common";
import authHeader from "./authHeader";

class ComplianceService {

  // get list of Compliance Statements by program
  getAllCompliances() {
    return http
      .get(`/compliance/all`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getCompliancesByProgramId(programId) {
    return http
      .get(`/compliance/${programId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getComplianceMatrixData(programId) {
    return http
      .get(`/compliance/getComplianceMatrixData/${programId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  upsertComplianceItem(complianceItem) {
    return http
      .post(`/compliance/upsert`, {complianceItem} ,{ headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }
}

export default new ComplianceService();
