import React, { useState, useCallback, useEffect, useMemo } from "react";
import {
  Box,
  Collapse,
  Divider,
  makeStyles,
  MenuItem,
  Paper,
  Typography,
} from "@material-ui/core";
import FormBanner from "components/utils/form-elements/formBanner.component";
import Header from "components/utils/header.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import DateInput from "components/utils/form-elements/dateInput.component";
import classNames from "classnames";
import moment from "moment";
import variables from "styleVariables";
import CustomSelect from "components/utils/form-elements/select.component";
import TimeInput from "components/utils/form-elements/timeInput.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import PortCoPicker from "../shared/portCoPicker.component";
import CustomLink from "components/utils/link.component";
import RemovableListItem from "components/utils/removableListItem.component";
import PortfolioService from "services/portfolio.service";
import {
  ACTION_CREATE_SESSION,
  ACTION_REPLACE_SESSION,
  ACTION_CREATE_PORTFOLIO_USER,
  ACTION_REPLACE_PORTFOLIO_USER,
  ACTION_CREATE_SESSIONS_AVAILABILITY,
  ACTION_REMOVE_SESSION,
} from "reducers/portfolioScheduling.reducer";
import { sessionFormModes } from "utils/portfolioConstants";
import useNumericParams from "hooks/useNumericParams";
import {
  findHourMinuteDuration,
  withinTimeDurationLimit,
} from "utils/timeHelpers";
import { sortByStringKey } from "utils/sortingFuncs";
import { getPortfolioUserPermission, getPortfolioUserTitle, validateUserFormErrors } from "../shared/utils/portfolioUserHelpers";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { Alert } from "@material-ui/lab";
import CollapsableAlert from "components/utils/collapsableAlert.component";
import ButtonDefault from "components/utils/buttonDefault.component";
import UndoIcon from '@material-ui/icons/Undo';
import DialogPop from "components/utils/dialog.component";
import { groupObjectArrayByKey, mapObjectArrayByKey } from "utils/arrayOfObjectsHelpers";
import AccordionListView from "components/utils/accordionListView.component";
import { InfoOutlined } from "@material-ui/icons";
import RadioInput from "components/utils/form-elements/radioInput.component";
import { ROLE_GROUPS } from "utils/roles";
import { HTTP_STATUS_CONTENT } from "services/http-common";
import PortCoAssessmentPicker from "../shared/portCoAssessmentPicker.component";
import Form from "components/utils/form-elements/form.component";

const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm";
const displayDateFormat = "dddd, MMMM D, YYYY";
const displayTimeFormat = "hh:mma";

const useStyles = makeStyles((theme) => ({
  form: {
    position: "relative"
  },
  disabledFormScreen: {
    content: "",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "#000",
    opacity: 0.2,
    zIndex: 1,
  },
  formContainer: {
    padding: "5px 10px 1px 10px",
    maxHeight: "calc(100vh - 140px)"
  },
  contentContainer: {
    padding: "0px 20px 0px 20px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    margin: 32,
    marginTop: 24,
  },
  //FORMELEMENTS
  selectWrapper: {
    width: 200,
  },
  defaultInputWrapper: {
    width: 175,
  },
  longInputWrapper: {
    width: 260,
  },
  dateWrapper: {
    marginBottom: 10,
    minWidth: 210,
  },
  timeInput: {
    width: 210,
    marginBottom: 10,
  },
  timeZone: {
    paddingBottom: 24,
  },
  divider: {
    marginTop: 10,
    marginBottom: 15,
  },
  createLink: {
    fontSize: variables.fontXs,
    position: "absolute",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    lineHeight: "normal",
  },
  addAttendeeIcon: {
    fontSize: variables.fontMedium,
    marginRight: 4,
  },
  followUpHeading: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
  },
  followUpIcon: {
    marginRight: 8,
    fontSize: "1rem"
  },
  followUpList: {
    margin: 0,
    paddingLeft: 24
  },
  portCoSectionDisabled: {
    opacity: 0.5,
    pointerEvents: "none",
    userSelect: "none"
  },
  //New User Form
  newUserFromContainer: {
    position: "relative",
    marginTop: 20,
    padding: 10,
    opacity: 1,
    zIndex: 2,
  },
  newUserFormRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  userButtonWrapper: {
    paddingTop: 8
  },
  caption: {
    color: variables.textSecondary,
    fontWeight: "bold"
  },
  userButtonsClass: {
    minWidth: 250,
  },
  buttonsWrapper: {
    paddingTop: 40,
    paddingBottom: 0,
    width: 500
  },
  //Attendee Section
  attendeesSection: {
    display: "flex",
    marginTop: 25,
    flexWrap: "wrap",
  },
  attendeesSelectors: {
    flex: 2,
    position: "relative",
    marginBottom: 24,
  },
  attendeesBox: {
    flex: 3,
    minWidth: 300,
  },
  selectedAttendees: {
    height: 130,
    maxHeight: 130,
    overflowX: "auto",
    border: `1px solid ${variables.grayPrimary}`,
    borderRadius: 3,
    padding: "4px 0px",
  },
  attendeeBoxLabelRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: 2,
    marginRight: 2,
    marginBottom: 3,
    fontWeight: "bold",
  },
  alertRoot: {
    margin: 0
  },
  undoButtonIcon: {
    fontSize: "16px",
    marginRight: 8,
    color: "white",
  },
}));

const findFollowUpPortCoId = (followUps, followUpId) => {
  return followUps?.find?.(followUp => followUp.FollowUp_ID === parseInt(followUpId))?.PortCo_ID
}

const selectedFollowUpIsInvalid = (followUps, selectedFollowUp, portCoId) => {
  if (!selectedFollowUp) {
    return false;
  }
  return (
    followUps.find(
      (followUp) => followUp.FollowUp_ID === parseInt(selectedFollowUp)
    )?.PortCo_ID !== parseInt(portCoId)
  );
};

const defaultErrorMessage = "There was a problem saving."
const minimumDuration = 30;
const maximumDuration = 540;
const validateFormErrors = (formState) => {
  const errors = {};

  if (!formState.Start_Date) {
    errors.Start_Date = "Date is required.";
  }
  if (moment().isAfter(formState.Start_Date, 'day')) {
    errors.Start_Date = "A past date can not be set.";
  }
  if (!formState.Start_Time) {
    errors.Start_Time = "Start is required.";
  }
  if (!formState.End_Time) {
    errors.End_Time = "End is required.";
  }
  if (
    !withinTimeDurationLimit(
      moment(formState.Start_Time, timeFormat),
      moment(formState.End_Time, timeFormat),
      minimumDuration,
      maximumDuration
    )
  ) {
    errors.End_Time = `Duration must be within ${minimumDuration} min - ${maximumDuration / 60} hrs.`;
  }
  if (formState.Start_Time && formState.End_Time && formState.End_Time.localeCompare(formState.Start_Time) === -1) {
    errors.End_Time = "End time can not be before Start time.";
  }
  return errors;
};

const initialFormDataState = {
  Session_ID: null,
  Start_Date: "",
  Start_Time: "08:00",
  End_Time: "09:00",
};
const initialUserFormData = {
  First_Name: "",
  Last_Name: "",
  Title: "",
  Email: "",
  PortGroupPermission_ID: null,
  User_ID: null,
};

const DefaultInput = ({ ...props }) => {
  const classes = useStyles();
  return (
    <div
      className={
        props.longInput ? classes.longInputWrapper : classes.defaultInputWrapper
      }
    >
      <LabelInput {...props} variant="default" margin="dense" />
    </div>
  );
};

const SessionSchedulingForm = ({
  formMode,
  setFormMode,
  dispatch,
  assessments,
  sessionToEdit,
  sessions,
  portfolioCompanies,
  portfolioUsers,
  meetingFollowUps,
  isScheduleAdmin,
}) => {
  const classes = useStyles();
  const [errorAlertMessage, setErrorAlertMessage] = useState();
  const [formDataState, setFormDataState] = useState(initialFormDataState);
  const [formErrors, setFormErrors] = useState({});
  const [userFormErrors, setUserFormErrors] = useState({});
  const [savingStatus, setSavingStatus] = useState("");
  const [assessmentId, setAssessmentId] = useState(null);
  const [attendees, setAttendees] = useState([]);
  const [portCoID, setPortCoID] = useState("");
  const [attendeeIdsToSelect, setAttendeeIdsToSelect] = useState([]);
  const [pickedAttendeeIDs, setPickedAttendeeIDs] = useState([]);
  const [attendeesToRemoveIDs, setAttendeesToRemoveIDs] = useState([]);
  const [newUserFormData, setNewUserFormData] = useState();
  const [isCancelConfirmationOpen, setIsCancelConfirmationOpen] = useState(false);
  const [isRemoveConfirmationOpen, setIsRemoveConfirmationOpen] = useState(false);
  const [selectedFollowUpMeeting, setSelectedFollowUpMeeting] = useState()
  const disableMainForm = !!newUserFormData;
  const { holdingId } = useNumericParams();

  const isAssessmentVisible = useMemo(() => (
    assessments.length > 1
  ), [assessments]);

  useEffect(() => {
    if (sessionToEdit.session) {
      const {
        Session_ID, HolderAssessment_ID, Start, End, PortCo_ID
      } = sessionToEdit.session;

      setAssessmentId(HolderAssessment_ID || null);
      setPortCoID(PortCo_ID || "");

      setFormDataState({
        Session_ID,
        Start_Date: moment(Start).format(dateFormat),
        Start_Time: moment(Start).format(timeFormat),
        End_Time: moment(End).format(timeFormat),
      });
    } else if (assessments?.length === 1) {
      setAssessmentId(assessments[0].HolderAssessment_ID);
    }
  }, [assessments, sessionToEdit]);

  useEffect(() => {
    if (sessionToEdit?.attendees) {
      const attendeeUsers = sessionToEdit.attendees.map(attendee => ({
        ...attendee.user, _attendeeId: attendee.attendee.Attendee_ID
      }));
      setAttendees(attendeeUsers);
    }
  }, [sessionToEdit]);

  const selectedAssessment = useMemo(() => (
    assessments.find(assessment => (
      assessment.HolderAssessment_ID === assessmentId
    ))
  ), [assessments, assessmentId]);

  const assessmentSessions = useMemo(() => (
    !assessmentId ? [] : sessions.filter(session => (
      assessmentId === session.HolderAssessment_ID
    ))
  ), [assessmentId, sessions]);

  const assessmentMeetingFollowUps = useMemo(() => {
    if (!assessmentSessions?.length || !meetingFollowUps?.length) {
      return [];
    }
    const sessionIds = new Set(
      assessmentSessions.map(session => session.Session_ID)
    );
    return meetingFollowUps.filter(followUp => (
      sessionIds.has(followUp.Session_ID)
    ));
  }, [assessmentSessions, meetingFollowUps]);

  /*
  Potential Attendees include:
    1. NCG users
    2. Holder users with matching HoldingGroupPermissions
    3. PortCo users with matching PortfolioGroupPermissions
  */

  const potentialAttendees = useMemo(() => {
    return portfolioUsers.filter((user) => {
      return (
        !attendees.some((attendee) => {
          return attendee.User_ID === user.User_ID;
        }) &&
        !pickedAttendeeIDs.includes(user.User_ID) && (
          ROLE_GROUPS.GLOBAL_USERS.includes(user.Role_ID) ||
          !!getPortfolioUserPermission(user, holdingId, portCoID)
        )
      );
    });
  }, [portCoID, portfolioUsers, pickedAttendeeIDs, attendees, holdingId]);

  const modalTitle = useMemo(() => {
    const assessmentName = selectedAssessment?.Assessment_Name || "";
    const sessionName = `${assessmentName} Session`.trim();
    switch(formMode) {
      case sessionFormModes.EDIT:
        return `Edit ${sessionName}`;
      case sessionFormModes.PORTCO_ATTENDEES:
        return `Manage ${sessionName} Attendees`;
      case sessionFormModes.BOOK_PORTCO:
        return "Book Portfolio Company";
      case sessionFormModes.CREATE:
      default:
        return "Add Session";
    }
  }, [formMode, selectedAssessment]);

  const userFormClick = (open, userToEdit) => {
    if (userToEdit) {
      const title = getPortfolioUserTitle(userToEdit, holdingId, portCoID);
      const portCoPermission = getPortfolioUserPermission(
        userToEdit, null, portCoID
      );
      setNewUserFormData({
        First_Name: userToEdit.First_Name,
        Last_Name: userToEdit.Last_Name,
        Title: title,
        Email: userToEdit.Email,
        PortGroupPermission_ID: portCoPermission?.PortGroupPermission_ID,
        User_ID: userToEdit.User_ID,
      });
    } else if (open) {
      setNewUserFormData(initialUserFormData);
    } else {
      setNewUserFormData();
    }
  };

  const deselectRadio = useCallback((followUpId) => {
    if (followUpId === selectedFollowUpMeeting) {
      setSelectedFollowUpMeeting();
    }
  }, [selectedFollowUpMeeting])

  const radioHandler = useCallback(
    (event) => {
      const numericValue = parseInt(event.target.value);
      setSelectedFollowUpMeeting(numericValue);
      const associatedPortCoId = findFollowUpPortCoId(
        assessmentMeetingFollowUps,
        numericValue
      );
      setPortCoID(associatedPortCoId);
    },
    [assessmentMeetingFollowUps]
  );

  const handleAssessmentChange = useCallback(event => {
    setAssessmentId(event.target.value);
  }, []);

  const changeHandler = (event) => {
    setFormDataState((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
    if (formErrors[event.target.name]) {
      setFormErrors((prev) => ({ ...prev, [event.target.name]: false }));
    }
  };

  const timeInputHandler = (event) => {
    const { name, value } = event.target;
    if (name === "Start_Time") {
      setFormDataState((prev) => ({
        ...prev,
        Start_Time: value,
        End_Time: moment(value, timeFormat).add(1, "hours").format(timeFormat),
      }));
    } else {
      setFormDataState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    if (name === "End_Time") {
      setFormErrors((prev) => ({ ...prev, End_Time: false }));
    } else {
      setFormErrors((prev) => ({ ...prev, Start_Time: false, End_Time: false }));
    }
  };

  const newUserChangeHandler = (event) => {
    setNewUserFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
    if (userFormErrors[event.target.name]) {
      setUserFormErrors((prev) => ({ ...prev, [event.target.name]: false }));
    }
  };

  const portCoSelectHandler = useCallback((event) => {
    const { value } = event.target;
    setPortCoID(value);
    if (
      !value ||
      selectedFollowUpIsInvalid(
        assessmentMeetingFollowUps, selectedFollowUpMeeting, value
      )
    ) {
      setSelectedFollowUpMeeting()
    }
    setPickedAttendeeIDs([])
    setAttendees([]);
  }, [assessmentMeetingFollowUps, selectedFollowUpMeeting])

  const attendeeSelectHandler = (event) => {
    setAttendeeIdsToSelect([...event.target.value]);
  };

  const attendeeOnClose = (event) => {
    const attendeesToAdd = potentialAttendees.filter((attendee) =>
      attendeeIdsToSelect.includes(attendee.User_ID)
    );

    setPickedAttendeeIDs((prev) => [...prev, ...attendeeIdsToSelect]);
    setAttendees((prev) => [...prev, ...attendeesToAdd]);
    setAttendeeIdsToSelect([]);
  }

  const markAttendeeToRemove = (id) => {
    setAttendeesToRemoveIDs((prev) => [...prev, id]);
  };

  const unmarkAttendeeToRemove = (id) => {
    setAttendeesToRemoveIDs((prev) => prev.filter((idToRemove) => idToRemove !== id));
  };

  const submitSessionHandler = useCallback(async formData => {
    if (formMode !== sessionFormModes.PORTCO_ATTENDEES || formMode !== sessionFormModes.BOOK_PORTCO) {
      const validateErrors = validateFormErrors(formDataState);
      if (
        Object.values(validateErrors).filter((error) => error !== false).length >
        0
      ) {
        setFormErrors(validateErrors);
        return;
      }
    }
    setSavingStatus("savingSession");
    try {
      const startDateTime = `${formDataState.Start_Date} ${formDataState.Start_Time}`;
      const endDateTime = `${formDataState.Start_Date} ${formDataState.End_Time}`;

      const data = {
        Session_ID: formDataState.Session_ID || null,
        PortCo_ID: portCoID || null,
        Start: startDateTime,
        End: endDateTime,
      };
      let followUpMeetingToResolve;
      if (portCoID && selectedFollowUpMeeting) {
        const followUpPortCoId = (
          findFollowUpPortCoId(assessmentMeetingFollowUps, selectedFollowUpMeeting)
        );
        followUpMeetingToResolve = followUpPortCoId === portCoID
          ? selectedFollowUpMeeting
          : null;
      }

      const sessionRes = await PortfolioService.upsertAssessmentSession(
        formData.HolderAssessment_ID,
        data,
        followUpMeetingToResolve
      );

      let attendeeRes;
      if (portCoID) {
        const sessionID =
          formDataState.Session_ID || sessionRes?.payload?.Session_ID;
        const attendeeList = attendees
          .filter((attendee) => {
            return !attendeesToRemoveIDs.includes(attendee.User_ID);
          })
          .map((attendee) => {
            return {
              Attendee_ID: attendee._attendeeId || null,
              Session_ID: sessionID,
              PortCo_ID: portCoID,
              PortUser_ID: attendee.User_ID,
            };
          });

        attendeeRes = await PortfolioService.batchReplaceSessionAttendees(
          portCoID,
          sessionID,
          attendeeList
        );
      }

      const updateRes = attendeeRes || sessionRes;
      if (!formDataState?.Session_ID) {
        dispatch({
          type: ACTION_CREATE_SESSION,
          payload: updateRes.payload,
          meta: sessionRes.meta
        });
      } else {
        dispatch({
          type: ACTION_REPLACE_SESSION,
          payload: updateRes.payload,
          meta: sessionRes.meta
        });
      }
      setSavingStatus("");
      setFormMode();
    } catch (error) {
      if (error.response?.status === HTTP_STATUS_CONTENT) {
        setErrorAlertMessage(
          error.response.data.message
        );
      } else {
        setErrorAlertMessage(defaultErrorMessage);
      }
      setSavingStatus("");
    }
  }, [
    selectedFollowUpMeeting,
    assessmentMeetingFollowUps,
    dispatch,
    formDataState,
    portCoID,
    setFormMode,
    formMode,
    attendees,
    attendeesToRemoveIDs,
  ]);

  const createAvailability = useCallback(async (formData) => {
    const validateErrors = validateFormErrors(formDataState);
    if (
      Object.values(validateErrors).filter((error) => error !== false).length >
      0
    ) {
      setFormErrors(validateErrors);
      return;
    }
    setSavingStatus("savingSession");
    const startDateTime = `${formDataState.Start_Date} ${formDataState.Start_Time}`;
    const endDateTime = `${formDataState.Start_Date} ${formDataState.End_Time}`;

    const data = {
      Session_ID: formDataState.Session_ID || null,
      PortCo_ID: portCoID || null,
      Start: startDateTime,
      End: endDateTime,
    };

    const availabilityRes = await PortfolioService.createSessionsFromRange(
      formData.HolderAssessment_ID,
      data
    );

    dispatch({
      type: ACTION_CREATE_SESSIONS_AVAILABILITY,
      payload: availabilityRes.payload,
    });
    setSavingStatus("");
    setFormMode();
  }, [dispatch, portCoID, formDataState, setFormMode]);

  const submitNewUser = async event => {
    event.preventDefault();
    const validateUserErrors = validateUserFormErrors(newUserFormData);
    if (
      Object.values(validateUserErrors).filter((error) => error !== false)
        .length > 0
    ) {
      setUserFormErrors(validateUserErrors);
      return;
    }
    setSavingStatus("savingUser");
    const userData = {
      User_ID: newUserFormData.User_ID,
      First_Name: newUserFormData.First_Name,
      Last_Name: newUserFormData.Last_Name,
      Email: newUserFormData.Email,
    };
    const gPpData = {
      Title: newUserFormData.Title,
      PortGroupPermission_ID: newUserFormData?.PortGroupPermission_ID,
    };

    const upsertedRes = await PortfolioService.upsertAttendeeUser(
      portCoID,
      userData,
      gPpData
    ).catch((err) => {
      if (err.response && err.response.status === HTTP_STATUS_CONTENT) {
        setUserFormErrors({
          Email:
            "This email already exists in the system.",
        });

      }
    });

    if (!upsertedRes) {
      setSavingStatus("");
      return;
    }

    if (!newUserFormData.User_ID) {
      dispatch({
        type: ACTION_CREATE_PORTFOLIO_USER,
        payload: upsertedRes.payload,
      });
      setAttendees((prev) => [
        ...prev,
        upsertedRes.payload,
      ]);
    } else {
      dispatch({
        type: ACTION_REPLACE_PORTFOLIO_USER,
        payload: upsertedRes.payload,
      });

      const indexToReplace = attendees.findIndex(attendee => attendee.User_ID === upsertedRes.payload.User_ID);
      setAttendees((prev) => {
        prev.splice(indexToReplace, 1, upsertedRes.payload);
        return prev;
      });
    }
    setSavingStatus("");
    setNewUserFormData();
    return null;
  };

  const removeSession = useCallback(async () => {
    setIsRemoveConfirmationOpen(false)
    try {
      const sessionId = sessionToEdit?.session?.Session_ID;
      await PortfolioService.cancelOrDeleteSession(
        sessionId,
        true
      );
      dispatch({
        type: ACTION_REMOVE_SESSION,
        payload: sessionId,
      });
      setFormMode();
    } catch (error) {
      if (error.response?.status === HTTP_STATUS_CONTENT) {
        setErrorAlertMessage(error.response.data.message);
      } else {
        setErrorAlertMessage(defaultErrorMessage);
      }
    }
  }, [sessionToEdit, setFormMode, dispatch]);

  const cancelBooking = useCallback(async () => {
    setIsCancelConfirmationOpen(false)
    try {
      const sessionId = sessionToEdit?.session?.Session_ID;
      const sessionRes = await PortfolioService.cancelOrDeleteSession(
        sessionId
      );
      dispatch({
        type: ACTION_REPLACE_SESSION,
        payload: sessionRes.payload,
      });
      setFormMode();
    } catch (error) {
      if (error.response?.status === HTTP_STATUS_CONTENT) {
        setErrorAlertMessage(error.response.data.message);
      } else {
        setErrorAlertMessage(defaultErrorMessage);
      }
    }
  }, [sessionToEdit, setFormMode, dispatch]);

  const dateTimeInputsReadOnly = useMemo(() => {
    return (
      formMode === sessionFormModes.BOOK_PORTCO ||
      formMode === sessionFormModes.PORTCO_ATTENDEES
    );
  }, [formMode]);

  const creatingAsScheduleAdmin = useMemo(() => {
    return isScheduleAdmin && formMode === sessionFormModes.CREATE;
  }, [formMode, isScheduleAdmin]);

  const creatingAvailability = useMemo(() => {
    return creatingAsScheduleAdmin && !portCoID
  }, [creatingAsScheduleAdmin, portCoID]);

  const followUpsBySessionId = useMemo(() => {
    if (portCoID) {
      const portCoMeetingFollowUps = assessmentMeetingFollowUps.filter(
        (followUp) => followUp.PortCo_ID === portCoID
      );
      return groupObjectArrayByKey(portCoMeetingFollowUps, "Session_ID");
    } else {
      return groupObjectArrayByKey(assessmentMeetingFollowUps, "Session_ID");
    }
  }, [assessmentMeetingFollowUps, portCoID]);

  const sessionTitlesById = useMemo(() => {
    const portCosById = mapObjectArrayByKey(portfolioCompanies, "PortCo_ID");
    const sessionsById = mapObjectArrayByKey(assessmentSessions, "Session_ID");
    return Object.fromEntries(
      Object.entries(sessionsById).map(([id, session]) => {
        const portCo = portCosById[session.PortCo_ID];
        const date = moment(session.Start).format(displayDateFormat);
        const startTime = moment(session.Start).format(displayTimeFormat);
        const endTime = moment(session.End).format(displayTimeFormat);
        return [
          id,
          {
            label: portCo ? `${portCo.Name} Session` : "Session",
            sublabel: `${date}, ${startTime} - ${endTime}`
          }
        ];
      })
    );
  }, [portfolioCompanies, assessmentSessions]);

  const followUpAccordionOptions = useMemo(() => (
    Object.entries(followUpsBySessionId).map(([id, followUps]) => ({
      label: sessionTitlesById[id].label,
      sublabel: sessionTitlesById[id].sublabel,
      value: (
        <span className={classes.followUpList}>
          {followUps.map(followUp => (
            <Box
              key={followUp.FollowUp_ID}
              display="flex"
              alignItems="center"
              onClick={() => deselectRadio(followUp.FollowUp_ID)}
            >
              <RadioInput
                value={followUp.FollowUp_ID}
                onChange={radioHandler}
                checked={selectedFollowUpMeeting === followUp.FollowUp_ID}
                test={`followUp-${followUp.FollowUp_ID}`}
              />
              {followUp.Description}
            </Box>
          ))}
        </span>
      )
    }))
      .sort((f1, f2) => f1.label.localeCompare(f2.label))
  ), [
    classes, followUpsBySessionId,
    sessionTitlesById, radioHandler, deselectRadio, selectedFollowUpMeeting
  ]);

  const portCoPickerIsReadOnly = useMemo(() => {
    return disableMainForm || !!sessionToEdit?.session?.PortCo_ID
  }, [disableMainForm, sessionToEdit])

  return (
    <Form
      className={classes.form}
      onSubmit={
        creatingAvailability ? createAvailability : submitSessionHandler
      }
      data-cy="session-scheduling-form"
    >
      {!!disableMainForm && (
        <div className={classes.disabledFormScreen} />
      )}
      <FormBanner>{modalTitle}</FormBanner>
      {!!creatingAsScheduleAdmin && (
        <Alert severity="info" classes={{root: classes.alertRoot}}>
          Sessions created without specifying a PortCo will default to
          creating hour-long availability slots.
        </Alert>
      )}
      <CollapsableAlert
        showAlert={!!errorAlertMessage}
        severity="error"
        message={errorAlertMessage}
        closeClick={() => setErrorAlertMessage()}
      />
      <div className={classes.formContainer}>
        <div className={classes.contentContainer}>
          <GridContainer spacing={3} justifyContent="flex-end">
            {isAssessmentVisible ? (
              <GridItem xs={6} lg={3}>
                <PortCoAssessmentPicker
                  assessments={assessments}
                  selectedAssessmentId={assessmentId}
                  selectedPortCoId={portCoID}
                  label={portCoPickerIsReadOnly ?
                    "Assessment" :
                    "Select an Assessment"}
                  onChange={handleAssessmentChange}
                  readOnly={
                    disableMainForm ||
                    !!sessionToEdit?.session?.HolderAssessment_ID
                  }
                />
              </GridItem>
            ) : (
              <input
                name="HolderAssessment_ID"
                type="hidden"
                value={assessmentId || ""}
              />
            )}

            <GridItem
              className={classes.dateWrapper}
              xs={6}
              md={isAssessmentVisible ? 6 : 4}
              lg={isAssessmentVisible ? 3 : 4}
            >
              <DateInput
                label="Date*"
                margin="dense"
                name="Start_Date"
                onChange={changeHandler}
                test="Start_Date"
                value={formDataState.Start_Date}
                error={!!formErrors?.Start_Date}
                errorMessage={formErrors?.Start_Date}
                disabled={disableMainForm}
                readOnly={dateTimeInputsReadOnly}
              />
            </GridItem>

            <GridItem
              className={classes.timeInput}
              xs={6}
              md={isAssessmentVisible ? 6 : 4}
              lg={isAssessmentVisible ? 3 : 4}
            >
              <TimeInput
                label="Start*"
                labelTooltip="Please enter date in 'hh:mm am/pm' format"
                margin="dense"
                name="Start_Time"
                onChange={timeInputHandler}
                test="Start_Time"
                value={formDataState.Start_Time}
                error={!!formErrors?.Start_Time}
                errorMessage={formErrors?.Start_Time}
                disabled={disableMainForm || !formDataState.Start_Date}
                readOnly={dateTimeInputsReadOnly}
              />
            </GridItem>

            <GridItem
              className={classes.timeInput}
              xs={6}
              md={isAssessmentVisible ? 6 : 4}
              lg={isAssessmentVisible ? 3 : 4}
            >
              <TimeInput
                label="End*"
                labelTooltip="Please enter date in 'hh:mm am/pm' format"
                margin="dense"
                name="End_Time"
                onChange={timeInputHandler}
                test="End_Time"
                value={formDataState.End_Time}
                error={!!formErrors?.End_Time}
                errorMessage={formErrors?.End_Time}
                disabled={disableMainForm || !formDataState.Start_Time || !formDataState.Start_Date}
                readOnly={dateTimeInputsReadOnly}
              />
            </GridItem>
          </GridContainer>

          <GridContainer
            spacing={3}
            justifyContent="flex-end"
          >
            <GridItem xs={12} lg={isAssessmentVisible ? 9 : 12}>
              {/*  ============= PORTCO & ATTENDEES ============= */}
              <div className={classes.attendeeBoxLabelRow}>
                <Header variant="h5Tertiary" className={classes.timeZone}>
                  Time Zone: US Eastern
                </Header>

                <Header
                  variant="h5Tertiary"
                  className={classes.timeZone}
                  noTransform
                >
                  {portCoID ? `Session Duration: ` : `Duration: `}
                  {formDataState?.End_Time?.localeCompare(formDataState?.Start_Time) === -1 ?
                    "Invalid Duration" :
                    findHourMinuteDuration(
                      moment(formDataState.Start_Time, timeFormat),
                      moment(formDataState.End_Time, timeFormat)
                    )
                  }
                </Header>
              </div>
            </GridItem>
          </GridContainer>
          {!!followUpAccordionOptions?.length && (
            <Box marginTop={1} marginBottom={2}>
              <div className={classes.followUpHeading}>
                <InfoOutlined className={classes.followUpIcon} />
                <span>
                  Planned follow-up meetings from previous sessions:
                </span>
              </div>
              <AccordionListView
                options={followUpAccordionOptions}
                testProp="follow-up-sessions"
              />
            </Box>
          )}
          <Divider className={classes.divider} />
          <div
            className={
              classNames(!assessmentId && classes.portCoSectionDisabled)
            }
          >
            {formMode !== sessionFormModes.PORTCO_ATTENDEES && formMode !== sessionFormModes.BOOK_PORTCO
              ? "Optional: Set Portfolio Company and Attendees"
              : "Set Portfolio Company and Attendees"}
            <div className={classes.attendeesSection}>
              <div className={classes.attendeesSelectors}>
                <div className={classes.selectWrapper}>
                  <PortCoPicker
                    selectedPortCoId={portCoID}
                    label={portCoPickerIsReadOnly ?
                      "Portfolio Company" :
                      "Select a Portfolio Company"
                    }
                    readOnly={portCoPickerIsReadOnly}
                    disabled={!assessmentId}
                    handleChange={portCoSelectHandler}
                    portCoList={portfolioCompanies}
                    showBlankOption={creatingAsScheduleAdmin}
                  />
                </div>

                <div className={classes.selectWrapper}>
                  <AttendeeSelect
                    attendeeIdsToSelect={attendeeIdsToSelect}
                    disabled={disableMainForm || !portCoID || !assessmentId}
                    holdingId={holdingId}
                    portCoId={portCoID}
                    potentialAttendees={potentialAttendees || []}
                    onChange={attendeeSelectHandler}
                    onClose={attendeeOnClose}
                  />
                </div>
                {!disableMainForm && !!portCoID && (
                  <CustomLink
                    className={classes.createLink}
                    onClick={() => userFormClick(true)}
                    test="new-attendee"
                  >
                    <PersonAddIcon className={classes.addAttendeeIcon} />
                    Create New Attendee
                  </CustomLink>
                )}
              </div>

              <div className={classes.attendeesBox}>
                <div className={classes.attendeeBoxLabelRow}>
                  {/* {`Selected Attendees: ${selectedAttendees.length} participants`} */}
                  Selected Attendees:
                  <span className={classes.attendeeCount}>
                    {attendees.length - attendeesToRemoveIDs.length === 1
                      ? `1 participant`
                      : `${
                        attendees.length - attendeesToRemoveIDs.length
                      } participants`}
                  </span>
                </div>
                <div className={classes.selectedAttendees} data-cy="selectedAttendees">
                  {attendees.map((attendee) => {
                    const name = `${attendee.First_Name} ${attendee.Last_Name}`;
                    const title = getPortfolioUserTitle(
                      attendee, holdingId, portCoID
                    );
                    const titleSuffix = title ? ` - ${title}` : "";
                    const nameDisplay = name + titleSuffix;
                    const userID = attendee.User_ID;
                    const isCreatedNonPortalUser = attendee.Role_ID === null;
                    return (
                      <Box key={userID} paddingLeft={0.5} paddingRight={0.5}>
                        <RemovableListItem
                          disabled={disableMainForm}
                          item={nameDisplay}
                          removeClick={() => markAttendeeToRemove(userID)}
                          undoClick={() => unmarkAttendeeToRemove(userID)}
                          editClick={
                            !!isCreatedNonPortalUser &&
                            (() => userFormClick(true, attendee))
                          }
                          isMarkedForDelete={
                            attendeesToRemoveIDs.some(id => id === userID)
                          }
                        />
                      </Box>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {/*  ============= NEW USER FORM ============= */}
          <Collapse in={disableMainForm} unmountOnExit>
            <Paper className={classes.newUserFromContainer}>
              <div className={classes.newUserFormRow}>
                <DefaultInput
                  label="First Name*"
                  value={newUserFormData?.First_Name}
                  onChange={newUserChangeHandler}
                  name="First_Name"
                  error={!!userFormErrors?.First_Name}
                  errorMessage={userFormErrors?.First_Name}
                  test="First_Name"
                />

                <DefaultInput
                  label="Last Name*"
                  value={newUserFormData?.Last_Name}
                  onChange={newUserChangeHandler}
                  name="Last_Name"
                  error={!!userFormErrors?.Last_Name}
                  errorMessage={userFormErrors?.Last_Name}
                  test="Last_Name"
                />

                <DefaultInput
                  label="Title*"
                  value={newUserFormData?.Title}
                  onChange={newUserChangeHandler}
                  name="Title"
                  error={!!userFormErrors?.Title}
                  errorMessage={userFormErrors?.Title}
                  test="Title"
                />
              </div>
              <div className={classes.newUserFormRow}>
                <DefaultInput
                  longInput
                  label="Email*"
                  value={newUserFormData?.Email}
                  onChange={newUserChangeHandler}
                  name="Email"
                  error={!!userFormErrors?.Email}
                  errorMessage={userFormErrors?.Email}
                  test="Email"
                />
                <div className={classes.userButtonWrapper}>
                  <DualFormButtons
                    className={classes.userButtonsClass}
                    buttonVariant="small"
                    addText={
                      newUserFormData?.User_ID
                        ? "Edit Attendee"
                        : "Create Attendee"
                    }
                    cancelOnClick={() => userFormClick()}
                    saveOnClick={submitNewUser}
                    isSaving={savingStatus === "savingUser"}
                  />
                </div>
              </div>
              <Typography variant="caption" className={classes.caption}>
                Note: Users created here will not have access to Orchestration.
              </Typography>
            </Paper>
          </Collapse>
          <Collapse in={!disableMainForm} unmountOnExit>
            <DualFormButtons
              className={classNames(classes.buttonsWrapper)}
              cancelOnClick={() => setFormMode()}
              disabled={
                !assessmentId ||
                (formMode === sessionFormModes.BOOK_PORTCO && !portCoID)
              }
              isSaving={savingStatus === "savingSession"}
              deleteClick={
                formMode === sessionFormModes.EDIT ?
                  () => setIsRemoveConfirmationOpen(true) :
                  null
              }
              cancelText="Exit"
            >
              {sessionToEdit?.session?.PortCo_ID ? (
                <ButtonDefault
                  variant="small"
                  background="primary"
                  startIcon={<UndoIcon className={classes.undoButtonIcon} />}
                  onClick={() => setIsCancelConfirmationOpen(true)}
                  disableReadOnlyUsers
                  data-cy="btn-cancel-booking"
                >
                  Cancel Session
                </ButtonDefault>
              ) : null}
            </DualFormButtons>
          </Collapse>
          <DialogPop
            openDialog={isCancelConfirmationOpen}
            setOpenDialog={() => setIsCancelConfirmationOpen(false)}
            confirm={cancelBooking}
          >
            <Header variant="h3Primary">Cancel Session Booking?</Header>
            <p>
              This session will be made available to be booked. Any changes made to the dates and times will not be recorded.
              Attendees will be notified via email.
            </p>
          </DialogPop>
          <DialogPop
            openDialog={isRemoveConfirmationOpen}
            setOpenDialog={() => setIsRemoveConfirmationOpen(false)}
            confirm={removeSession}
          >
            <Header variant="h3Primary">Delete Session?</Header>
            <p>
              This session will be deleted and any listed attendees will be notified via email.
            </p>
          </DialogPop>
        </div>
      </div>
    </Form>
  );
};

const AttendeeSelect = (props) => {
  const {
    attendeeIdsToSelect, disabled, holdingId, onClose, onChange,
    portCoId, potentialAttendees,
  } = props;

  const sortedMenuOptions = useMemo(
    () => sortByStringKey(potentialAttendees, "First_Name"),
    [potentialAttendees]
  );

  return (
    <CustomSelect
      name="session-attendee"
      labelId="attendee"
      label="Select Attendee"
      value={attendeeIdsToSelect || []}
      onChange={onChange}
      onClose={onClose}
      test="attendee"
      required={false}
      disabled={disabled}
      multiple
    >
      <MenuItem id="none" value="" disabled>
        Select a participant
      </MenuItem>
      {sortedMenuOptions.map((user) => (
        <MenuItem value={user.User_ID} key={user.User_ID}>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Box width="100%" marginRight={1}>
              <Header variant="h6Tertiary">
                {user.First_Name}&nbsp;{user.Last_Name}
              </Header>
            </Box>
            {getPortfolioUserTitle(user, holdingId, portCoId)}
          </Box>
        </MenuItem>
      ))}
    </CustomSelect>
  );
};

export default SessionSchedulingForm;
