import {
  mergeAssociationReplace,
  mergeReplace,
} from "reducers/utils/payloadUtils";

export const ACTION_SET_ASSESSMENTS = "assessments__set"
export const ACTION_SET_PORTFOLIO_COMPANIES = "portfolioCompanies__set";
export const ACTION_SET_PORTFOLIO_USERS = "portfolioUsers__set";
export const ACTION_CREATE_PORTFOLIO_USER = "portfolioUser__create";
export const ACTION_REPLACE_PORTFOLIO_USER = "portfolioUser__replace";
export const ACTION_SET_SESSIONS = "sessions__set";
export const ACTION_CREATE_SESSION = "session__create";
export const ACTION_CREATE_SESSIONS_AVAILABILITY = "sessions_availability__create";
export const ACTION_REMOVE_SESSION = "sessions_availability__remove";
export const ACTION_REPLACE_SESSION = "session__replace";

export const portfolioSchedulingInitialState = {
  assessments: null,
  portfolioCompanies: null,
  portfolioUsers: null,
  sessions: null,
};

export default function portfolioSchedulingReducer(state, action) {
  switch (action.type) {
    case ACTION_SET_ASSESSMENTS:
      return {
        ...state,
        assessments: action.payload
      }
    case ACTION_SET_PORTFOLIO_COMPANIES:
      return {
        ...state,
        portfolioCompanies: action.payload
      }
    case ACTION_SET_PORTFOLIO_USERS:
      return {
        ...state,
        portfolioUsers: action.payload
      }
    case ACTION_SET_SESSIONS:
      return {
        ...state,
        sessions: action.payload
      }
    case ACTION_CREATE_PORTFOLIO_USER:
      return {
        ...state,
        portfolioUsers: [
          ...state.portfolioUsers,
          action.payload
        ]
      }
    case ACTION_REPLACE_PORTFOLIO_USER:
      return {
        ...state,
        portfolioUsers: mergeReplace(
          state.portfolioUsers,
          action.payload,
          "User_ID",
        )
      };
    case ACTION_CREATE_SESSION:
      const sessionsWithNewSession = [
        ...state.sessions,
        action.payload
      ];
      return {
        ...state,
        sessions: !action.meta.update.SessionFollowUps ?
          sessionsWithNewSession :
          mergeAssociationReplace(
            sessionsWithNewSession,
            action.meta.update.SessionFollowUps,
            "Session_ID",
            "SessionFollowUps",
            "FollowUp_ID"
          )
      };

    case ACTION_CREATE_SESSIONS_AVAILABILITY:
      return {
        ...state,
        sessions: [
          ...state.sessions,
          ...action.payload
        ]
      }
    case ACTION_REMOVE_SESSION:
      return {
        ...state,
        sessions: state.sessions.filter(session => session.Session_ID !== action.payload)
      };
    case ACTION_REPLACE_SESSION:
      const sessionsWithUpdatedSession = mergeReplace(
        state.sessions,
        action.payload,
        "Session_ID",
      );
      return {
        ...state,
        sessions: !action?.meta?.update?.SessionFollowUps ?
          sessionsWithUpdatedSession :
          mergeAssociationReplace(
            sessionsWithUpdatedSession,
            action.meta.update.SessionFollowUps,
            "Session_ID",
            "SessionFollowUps",
            "FollowUp_ID"
          )
      };
    default:
      return state;
  }
}
