import { makeStyles, Paper, Table, TableBody, TableHead, TableRow } from "@material-ui/core";
import TableCell from "components/utils/tables/shared/tableCell.component";
import classNames from "classnames";
import Form from "components/utils/form-elements/form.component";
import TextFieldPlain from "components/utils/form-elements/textFieldPlain.component";
import useNumericParams from "hooks/useNumericParams";
import React, { memo, useCallback, useMemo, useState } from "react";
import { ACTION_REPLACE_SESSION_NOTES } from "reducers/portfolioAssessments.reducer";
import PortfolioService from "services/portfolio.service";
import { currentUserID } from "utils/userHelpers";
import { Person } from "@material-ui/icons";
import styleVariables from "styleVariables";

const useStyles = makeStyles((theme) => {
  const nameCellMinWidth = 200;
  return {
    tableNameCell: {
      background: theme.palette.primary.main,
      color: "white",
      fontWeight: 600,
      width: "33%",
      minWidth: nameCellMinWidth
    },
    tableHeaderCell: {
      background: theme.palette.primary.main,
      color: "white",
      fontWeight: 600
    },
    categoryNameCell: {
      fontWeight: 600,
      minWidth: nameCellMinWidth
    },
    categoryHeaderRow: {
      borderBottomColor: theme.palette.border.dark
    },
    categoryHeaderBorder: {
      borderTop: `2px solid ${theme.palette.primary.main}`
    },
    cell: {
      borderCollapse: "collapse",
      borderRight: `1px solid ${theme.palette.border.main}`,
      borderLeft: `1px solid ${theme.palette.border.main}`
    },
    formContainer: {
      marginBottom: 32,
      overflowX: "auto",
      overflowY: "hidden"
    },
    form: {
      minWidth: 600
    },
    noteCell: {
      width: "60%",
    },
    inputCellInner: {
      margin: "2px 16px 3px",
      paddingTop: 0,
      paddingBottom: 2,
      lineHeight: "1.2rem",
    },
    inputCellInnerEditable: {
      borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
      transition: ".05s ease-out border-bottom, .05s ease-out margin-bottom",
      "&:hover": {
        marginBottom: 2,
        borderBottom: `2px solid ${theme.palette.secondary.main}`,
      },
      "&:focus": {
        marginBottom: 2,
        borderBottom: `2px solid #164C9D`,
      }
    },
    inputCellField: {
      paddingLeft: 16,
      paddingRight: 16,
    },
    fieldInput: {
      minHeight: 24,
      paddingTop: 2,
      paddingBottom: 0,
      fontSize: 14
    },
    stateCell: {
      minWidth: 120,
      padding: "7px 0 6px"
    },
    otherNotesCell: {
      backgroundColor: styleVariables.grayTint,
      color: styleVariables.grayDisabledText,
    },
    userIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      "&:not(:last-of-type)": {
        marginBottom: 4
      }
    },
    userCell: {
      width: "5px",
      backgroundColor: "#92aad4",
      color: `${theme.palette.background.default}`,
    }
  };
});

const SessionNotesTable = memo(SessionNotesTableUnmemoized);

export default SessionNotesTable;

function SessionNotesTableUnmemoized(props) {
  const classes = useStyles();
  const { assessmentItems, dispatch, isReadOnly, noteFilter } = props;

  const categoryAssessmentItems = useMemo(() => (
    assessmentItems ? assessmentItems.filter(item => !item.GroupHeader_ID) : []
  ), [assessmentItems]);

  const subcategoryAssessmentItems = useMemo(() => (
    assessmentItems ? assessmentItems.filter(item => !!item.GroupHeader_ID) : []
  ), [assessmentItems]);

  const categorizedItems = useMemo(() => (
    categoryAssessmentItems.map(category => ({
      ...category,
      _items: subcategoryAssessmentItems.filter(item => (
        item.GroupHeader_ID === category.AssessmentTemplate_ID
      ))
    })).filter(category => category._items?.length > 0)
  ), [categoryAssessmentItems, subcategoryAssessmentItems]);

  return (
    <Paper className={classes.formContainer}>
      <Form className={classes.form} name="session-notes" data-cy="sessionNotes">
        <Table size="small">
          {categorizedItems.map((category, categoryIndex) => (
            <React.Fragment key={category.Item_ID}>
              <TableHead className={classes.categoryHeaderRow}>
                <TableRow hover={false}>
                  <TableCell className={classNames(classes.tableNameCell, classes.cell)}>
                    Item
                  </TableCell>
                  <TableCell className={classNames(classes.cell, classes.tableHeaderCell)}>
                    State
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    className={classNames(classes.cell, classes.noteCell, classes.tableHeaderCell)}
                  >
                    Notes
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <CategoryTableBodyRow
                  assessmentItem={category}
                  categoryIndex={categoryIndex}
                  dispatch={dispatch}
                  isReadOnly={isReadOnly}
                  noteFilter={noteFilter}
                />
                {category._items.map((item, index) => (
                  <React.Fragment key={item.Item_ID}>
                    <CategoryTableBodyRow
                      assessmentItem={item}
                      categoryIndex={categoryIndex}
                      dispatch={dispatch}
                      index={index}
                      isReadOnly={isReadOnly}
                      noteFilter={noteFilter}
                    />
                  </React.Fragment>
                ))}
              </TableBody>
            </React.Fragment>
          ))}
        </Table>
      </Form>
    </Paper>
  )
}


const CategoryTableBodyRow = function(props) {
  const classes = useStyles();
  const { sessionId } = useNumericParams();
  const { assessmentItem, categoryIndex, dispatch, index, isReadOnly, noteFilter } = props;
  const isCategory = index == null;

  const userId = useMemo(() => currentUserID(), []);

  const [didCancel, setDidCancel] = useState(false);

  const sessionNote = useMemo(() => (
    assessmentItem._associations?.SessionNotes?.find?.(note => (
      note.AssessorUser_ID === userId &&
      note.SessionNote_ID !== assessmentItem.SessionNote_ID
    ))
  ), [assessmentItem, userId]);

  const otherUserNotes = useMemo(() => {
    if (noteFilter === "all" && assessmentItem._associations?.SessionNotes) {
      return assessmentItem._associations.SessionNotes.filter(note => (
        sessionNote?.SessionNote_ID !== note.SessionNote_ID &&
          (!!note.State?.trim?.() || !!note.Description?.trim?.())
      ));
    }
    return [];
  }, [assessmentItem, noteFilter, sessionNote]);

  const handleAutosave = useCallback(async (name, value) => {
    if ((!value && !sessionNote) || sessionNote?.[name] === value) {
      return;
    }
    if (didCancel) {
      setDidCancel(false);
      return;
    }
    const data = {
      AssessorUser_ID: userId,
      SessionNote_ID: sessionNote?.SessionNote_ID,
      [name]: value,
      AssessmentTemplate_ID: assessmentItem.AssessmentTemplate_ID
    };
    const notesResponse = await PortfolioService.upsertNote(
      sessionId, assessmentItem.Item_ID, data
    );
    dispatch({ type: ACTION_REPLACE_SESSION_NOTES, payload: notesResponse.payload });
  }, [assessmentItem, didCancel, dispatch, sessionId, sessionNote, setDidCancel, userId]);

  const handleKeyDown = useCallback(event => {
    switch (event.key) {
      case "Escape":
        setDidCancel(true);
        return event.target.blur();
      case "Return":
      default:

    }
  }, [setDidCancel]);

  return (
    <>
      <TableRow
        className={classNames(isCategory && index > 0 && classes.categoryHeaderBorder)}
        hover={false}
        key={assessmentItem.Item_ID}
      >
        <TableCell
          rowSpan={1 + otherUserNotes.length}
          className={classNames(classes.cell, isCategory && classes.categoryNameCell)}
        >
          <span>{categoryIndex + 1}.{index + 1 || ""}</span>
          &nbsp;
          <span>{assessmentItem.Description}</span>
        </TableCell>
        <TableCell
          className={classNames(classes.cell, classes.stateCell)}
          padding="none"
        >
          <TextFieldPlain
            id={`input-${assessmentItem.Item_ID}-state`}
            className={classes.inputCellField}
            name={`input-${assessmentItem.Item_ID}-state`}
            defaultValue={sessionNote?.State}
            onAutosave={(_name, stateValue) => (
              handleAutosave("State", stateValue)
            )}
            onKeyDown={handleKeyDown}
            margin="dense"
            variant="noLabel"
            inputProps={{
              className: classes.fieldInput,
              classes: {
                input: classNames(
                  classes.inputCellInner,
                  classes.inputCellInnerPresent,
                  !isReadOnly && classes.inputCellInnerEditable
                )
              }
            }}
            readOnly={isReadOnly}
            fullWidth
            multiline
            test={`input-${assessmentItem.Item_ID}-state`}
          />
        </TableCell>
        <TableCell
          className={classNames(classes.cell)}
          padding="none"
          colSpan={3}
        >
          <div data-cy={`note-${assessmentItem.Item_ID}`}>
            <TextFieldPlain
              id={`input-${assessmentItem.Item_ID}-notes`}
              className={classes.inputCellField}
              name={`input-${assessmentItem.Item_ID}-notes`}
              defaultValue={sessionNote?.Description}
              onAutosave={(_name, descriptionValue) => (
                handleAutosave("Description", descriptionValue)
              )}
              onKeyDown={handleKeyDown}
              margin="dense"
              variant="noLabel"
              inputProps={{
                className: classes.fieldInput,
                classes: {
                  input: classNames(
                    classes.inputCellInner,
                    classes.inputCellInnerPresent,
                    !isReadOnly && classes.inputCellInnerEditable
                  )
                }
              }}
              readOnly={isReadOnly}
              fullWidth
              multiline
              test={`input-${assessmentItem.Item_ID}-notes`}
            />
          </div>
        </TableCell>
      </TableRow>
      {otherUserNotes?.map?.(note => (
        <TableRow key={note.SessionNote_ID}>
          <TableCell
            className={classNames(classes.cell, classes.otherNotesCell)}
            colSpan={1}
          >
            {note.State}
          </TableCell>
          <TableCell
            className={classNames(classes.cell, classes.otherNotesCell)}
            colSpan={1}
          >
            {note.Description}
          </TableCell>
          <TableCell
            className={classNames(classes.cell, classes.otherNotesCell, classes.userCell)}
            colSpan={1}
          >
            <div className={classes.userIcon}>
              <Person fontSize="small" />
              {note.Assessor_UserName}
            </div>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
}
