import AddListItem from "components/utils/addListItem.component";
import Loader from "components/utils/loader.components";
import { useMemo, useState } from "react";
import SchedulingTable from "./tables/schedulingTable.component";
import SessionSchedulingForm from "components/portfolio/scheduling/sessionSchedulingForm.component";
import { followUpTypes, sessionFormModes } from "utils/portfolioConstants";
import { Box, Dialog } from "@material-ui/core";
import { isAdmin, isEdit } from "utils/roles";
import { makeStyles } from "@material-ui/core";
import moment from 'moment';
import IconH2 from "components/utils/iconH2.component";
import { EventOutlined } from "@material-ui/icons";
import useNumericParams from "hooks/useNumericParams";

const useStyles = makeStyles(theme => ({
  buttonWrapper: {
    paddingRight: 5,
    paddingTop: 20,
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const dateIsActive = (date) => {
  const dateIsTodayOrLater = !moment().isAfter(date, "day");
  return dateIsTodayOrLater;
};

export default function SchedulingTablePage(props) {
  const classes = useStyles();
  const { holdingId } = useNumericParams();
  const { dispatch, state, portCoId } = props;
  const { assessments, portfolioCompanies, portfolioUsers, sessions } = state;
  const [formMode, setFormMode] = useState(); // null, create, edit, attendees
  const [sessionToEdit, setSessionToEdit] = useState();
  const isScheduleAdmin = useMemo(() => isAdmin() || isEdit(), []);

  const formModeClick = (type, session, attendees) => {
    setFormMode(type);
    setSessionToEdit({ session, attendees });
  };

  const holderAssessments = useMemo(() => (
    assessments?.filter?.(assessment => (
      assessment.PortfolioHolder_ID === holdingId
    ))
  ), [assessments, holdingId]);

  const meetingFollowUps = useMemo(() => {
    if (!sessions) {
      return [];
    }
    return sessions.flatMap(session => (
      session._associations?.SessionFollowUps?.filter?.(followUp => (
        followUp.Type === followUpTypes.MEETING &&
        followUp.Status === "Active" &&
        (!sessionToEdit || sessionToEdit.Session_ID !== followUp.Session_ID)
      ))?.map?.(followUp => ({
        ...followUp,
        PortCo_ID: session.PortCo_ID
      })) || []
    ));
  }, [sessions, sessionToEdit]);

  if (!holderAssessments || !portfolioCompanies || !portfolioUsers || !sessions) {
    return <Loader />;
  }
  return (
    <Box marginTop={2} data-cy="test">
      <IconH2 IconComponent={EventOutlined} color="primary" marginBottom={2}>
        Current Sessions
      </IconH2>
      <SchedulingTable
        dispatch={dispatch}
        state={state}
        holderAssessments={holderAssessments}
        sessions={state.sessions.filter((session) =>
          dateIsActive(session.Start)
        )}
        formModeClick={formModeClick}
        isScheduleAdmin={isScheduleAdmin}
      />
      {!!isScheduleAdmin && (
        <Box className={classes.buttonWrapper}>
          <AddListItem
            onClick={() => formModeClick(sessionFormModes.CREATE)}
            test="additional-date"
          >
            Add Additional Date
          </AddListItem>
        </Box>
      )}
      <Box marginTop={4} marginBottom={10}>
        <IconH2
          color="tertiary"
          marginBottom={2}
        >
          Past Sessions
        </IconH2>
        <SchedulingTable
          dispatch={dispatch}
          state={state}
          holderAssessments={holderAssessments}
          sessions={state.sessions.filter(
            (session) => !dateIsActive(session.Start)
          )}
          formModeClick={formModeClick}
          isScheduleAdmin={isScheduleAdmin}
          pastSessions
        />
      </Box>
      <Dialog open={formMode} onClose={formModeClick} maxWidth="md" fullWidth>
        <SessionSchedulingForm
          setFormMode={setFormMode}
          formMode={formMode}
          assessments={holderAssessments}
          sessionToEdit={sessionToEdit}
          sessions={sessions}
          portfolioCompanies={portfolioCompanies}
          portfolioUsers={portfolioUsers}
          meetingFollowUps={meetingFollowUps}
          dispatch={dispatch}
          portCoId={portCoId}
          isScheduleAdmin={isScheduleAdmin}
        />
      </Dialog>
    </Box>
  );
}
