import React, { useContext, useMemo } from "react";
import { Box, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AlertIcon from "components/alerts/alertIcon.component";
import AlertDueText from "components/alerts/alertDueText.component";
import CustomLink from "components/utils/link.component";
import { alertDueMessage } from "utils/alertHelpers";
import IconMessage from "components/utils/iconMessage.component";
import { alertPathFinder } from "utils/alertHelpers";
import variables from "styleVariables";
import classNames from "classnames";
import { truncate } from "utils/workPackageHelpers";
import TableCell from "components/utils/tables/shared/tableCell.component";
import ProgramsContext from "contexts/programs.context";
import { PROGRAM_ID_ORCHESTRATION } from "utils/programConstants";
import { ALERT_TYPE_ID_TASK_ASSIGNED, ALERT_TYPE_ID_TASK_DUE, ALERT_TYPE_ID_TASK_GENERAL, ALERT_TYPE_ID_TASK_RUNBOOK_ASSIGNED, ALERT_TYPE_ID_TASK_RUNBOOK_DUE } from "utils/alertsConstants";

const useStyles = makeStyles((theme) => ({
  alertContent: {
    padding: 10,
  },
  emptyAlertContent: {
    color: variables.textSecondary,
  },
  cellRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  leftColumn: {
    display: "flex",
    flexDirection: "column",
    paddingRight: 10,
  },
  alertHeader: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 3,
  },
  alertLabel: {
    fontWeight: "bold",
  },
  iconWrapper: {
    height: 15,
    marginRight: 10,
    pointerEvents: "auto",
  },
  infoBox: {
    marginLeft: 25,
  },
  dismiss: {
    display: "flex",
    paddingTop: 10,
    justifyContent: "flex-end",
  },
  warning: {
    color: variables.warningMain,
    fill: variables.warningMain,
  },
  caution: {
    color: variables.statusCautionDark,
    fill: variables.statusCautionDark,
  },
  assigned: {
    color: variables.statusGreen,
    fill: variables.statusGreen,
  },
  taskLink: {
    color: variables.tertiary1,
    fontSize: variables.fontSmall,
    paddingTop: 5,
  },
  dismissLink: {
    color: variables.tertiary1,
    fontWeight: "normal",
    fontSize: variables.fontSmall,
  },
}));

const AlertBox = ({
  alert,
  deleteUserAlert,
  empty,
  compObjId,
  handleCloseAlert,
}) => {
  const classes = useStyles();

  if (empty) {
    return (
      <TableRow className={classes.emptyAlertContent}>
        <TableCell>
          <IconMessage
            sectionName="Alerts"
            variant="emptyAlerts"
            dataCy="no-alerts-message"
          />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow data-cy={`alertRow-${alert.Message}`} className={classes.alertContent}>
      <TableCell className={classes.cellRow}>
        <div className={classes.leftColumn}>
          <AlertHeader alert={alert} />
          <div className={classes.infoBox}>
            {!compObjId ? (
              <CustomLink
                className={classes.taskLink}
                variant="routerLink"
                to={alertPathFinder(alert)}
                test={`alert-${alert.Message}`}
                onClick={handleCloseAlert}
              >
                <AlertLinkDisplay
                  message={alert.Message}
                  messageHeader={alert?._associations?.RunbookTasks?.[0]?.Runbook_Name}
                />
              </CustomLink>
            ) : (
              alert.Message
            )}
          </div>
        </div>
        <div className={classes.rightColumn}>
          <AlertDueText End_Date={alert.End_Date} />
          <div className={classes.dismiss}>
            <CustomLink
              onClick={() => deleteUserAlert(alert.UserAlert_ID)}
              test={`dismiss-alert-${alert.Message}`}
              variant="noHRef"
              className={classes.dismissLink}
              ignoreReadOnly
            >
              Dismiss
            </CustomLink>
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default AlertBox;

const AlertLinkDisplay = ({ message, messageHeader }) => {
  if (messageHeader) {
    return (
      <Box display="flex" flexDirection="column">
        <strong>{truncate(messageHeader, 35)}</strong>
        <span>{message}</span>
      </Box>
    );
  }
  return message;
};

const AlertHeader = ({ alert }) => {
  const classes = useStyles();
  const { state } = useContext(ProgramsContext);

  const { color } = useMemo(() => alertDueMessage(alert?.End_Date), [alert]);

  const programName = useMemo(() => {
    if (alert.Program_Program_ID === PROGRAM_ID_ORCHESTRATION) {
      return "Enterprise";
    } else if (alert.Program_Program_ID) {
      return state.programs[alert.Program_Program_ID]?.Name;
    }
    return "";
  }, [alert, state.programs]);

  const alertTypeLabel = useMemo(() => {
    switch(alert?.AlertType_Type_ID) {
      case ALERT_TYPE_ID_TASK_DUE:
      case ALERT_TYPE_ID_TASK_RUNBOOK_DUE:
        return "Task Due";
      case ALERT_TYPE_ID_TASK_ASSIGNED:
      case ALERT_TYPE_ID_TASK_RUNBOOK_ASSIGNED:
        return "Task Assigned";
      case ALERT_TYPE_ID_TASK_GENERAL:
      default:
        return "New Alert";
    }
  }, [alert]);

  const colorClass = useMemo(() => {
    switch(color) {
      case "assigned":
        return classes.assigned;
      case "warning":
        return classes.warning;
      case "caution":
        return classes.caution;
      default:
        return "";
    }
  }, [classes, color]);

  return (
    <span className={classes.alertHeader}>
      <div className={classes.iconWrapper}>
        <AlertIcon End_Date={alert.End_Date} noToolTip />
      </div>
      <div className={classNames(colorClass, classes.alertLabel)}>
        {programName} {alertTypeLabel}
      </div>
    </span>
  );
};
