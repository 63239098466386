import FormElementWithAutosave from "./formElementWithAutosave.component";
import FormElementWithContext from "./formElementWithContext.component";

/*
 * Common wrapper for all form field inputs
 */
export default function FormElementBase(props) {
  return (
    <FormElementWithContext {...props}>
      <FormElementWithAutosave>
        {props.children}
      </FormElementWithAutosave>
    </FormElementWithContext>
  );
}
