import { useCallback, useMemo, useState } from "react";
import CustomLink from "components/utils/link.component";
import { makeStyles } from "@material-ui/core";
import Form from "components/utils/form-elements/form.component";
import classNames from "classnames";
import AddListItem from "components/utils/addListItem.component";
import styleVariables from "styleVariables";
import { VALIDATION_REQUIRED } from "utils/formValidators";
import { serializeFormInput } from "utils/formHelpers";
import { isReadOnly } from "utils/roles";

export const boldLetterSpacingDifference = 0.39;

const useStyles = makeStyles(theme => ({
  cell: {
    display: "flex",
    alignItems: "center",
    minHeight: 36,
    fontSize: styleVariables.fontSmall,
  },
  cellCentered: {
    textAlign: "center"
  },
  form: {
    flex: 1
  },
  input: {
    fontWeight: 400,
    letterSpacing: boldLetterSpacingDifference / 2
  },
  link: {
    fontWeight: 600,
    letterSpacing: boldLetterSpacingDifference / -2,
  },
  emptyCellContent: {
    width: "calc(100% + 32px)",
    height: "100%",
    margin: "-20px -16px",
    padding: "18px 16px",
    fontWeight: 600,
    letterSpacing: boldLetterSpacingDifference / -2,
  },
  emptyCellContentCentered: {
    justifyContent: "center",
  },
  emptyCellContentRequired: {
    color: styleVariables.warningMain,
  },
  emptyCellReadOnly: {
    fontWeight: 400
  },
}));

const ALIGN_CENTER = "center";

const ONE_SECOND = 1000;


export default function EditableInputCellWrapper(props) {
  const classes = useStyles();
  const {
    align, children, className, defaultValue, disabled, name,
    onAutosave, renderLinkDisplay, required, validations
  } = props;

  const readOnly = useMemo(isReadOnly, []);

  const [isActive, setIsActive] = useState(false);
  const [isTabInProgress, setIsTabInProgress] = useState(false);
  const [value, setValue] = useState(defaultValue);

  const linkDisplayNode = useMemo(() => {
    const rendered = renderLinkDisplay?.(value);
    if (rendered && (!Array.isArray(rendered) || rendered.length > 0)) {
      return rendered;
    }
    if (value?.trim?.() && (!Array.isArray(value) || value.length > 0)) {
      return value.trim();
    }
    if (readOnly) {
      return <div className={classes.emptyCellReadOnly}>--</div>;
    }
    return (
      <AddListItem
        className={
          classNames(
            classes.emptyCellContent,
            align === ALIGN_CENTER && classes.emptyCellContentCentered,
            required && classes.emptyCellContentRequired
          )
        }
      >
        Add
      </AddListItem>
    );
  }, [align, classes, readOnly, renderLinkDisplay, required, value]);

  const formValidations = useMemo(() => {
    const combinedValidations = [];
    if (required) {
      combinedValidations.push({ type: VALIDATION_REQUIRED });
    }
    if (validations) {
      combinedValidations.push(...validations);
    }
    if (combinedValidations.length) {
      return { [name]: combinedValidations};
    }
    return null;
  }, [name, required, validations]);

  const setIsActiveOnMouseUp = useCallback(newIsActive => {
    const eventNames = ["mouseup", "mouseleave", "dragend", "keyup", "touchend"];
    let timer = null;
    const eventHandler = () => {
      setIsActive(newIsActive);
      eventNames.forEach((nameEventItem) => {
        document.removeEventListener(nameEventItem, eventHandler);
      });
      window.clearTimeout(timer);
    }
    setTimeout(() => {
      eventNames.forEach(eventName => {
        document.addEventListener(eventName, eventHandler);
      });
    }, 10);
    timer = window.setTimeout(eventHandler, ONE_SECOND);
  }, []);

  const handleAutosave = useCallback((inputName, inputValue, event) => {
    const serializedValue = serializeFormInput(inputValue);
    onAutosave(inputName, serializedValue, event);
    if (isTabInProgress) {
      setIsActive(false);
    }
    setIsActiveOnMouseUp(false);
  }, [isTabInProgress, onAutosave, setIsActiveOnMouseUp]);

  const handleChange = useCallback((event, overrideValue) => {
    setValue(overrideValue ?? event.target.value);
  }, []);

  const handleFormError = useCallback(
    (_validationErrors, _thrownError, submitEvent) => {
      setIsActive(true);
      setIsActiveOnMouseUp(true);
      if (submitEvent?.target) {
        setIsTabInProgress(true);
        setTimeout(submitEvent.target.focus, 0);
      }
    },
    [setIsActiveOnMouseUp]
  );

  const handleMouseDown = useCallback(() => {
    setIsActiveOnMouseUp(true);
  }, [setIsActiveOnMouseUp]);

  const handleKeyDown = useCallback(event => {
    if (event.key === "Tab") {
      setIsTabInProgress(true);
    }
  }, []);
  const handleKeyUp = useCallback(event => {
    if (event.key === "Tab") {
      setIsTabInProgress(false);
    } else if (event.key === "Escape") {
      event.target.blur();
      setIsActive(false);
    }
  }, []);

  return (
    <div
      className={
        classNames(
          classes.cell,
          className,
          align === ALIGN_CENTER && classes.cellCentered
        )
      }
    >
      <Form
        name={`${name}-form`}
        className={classes.form}
        onError={handleFormError}
        validations={formValidations}
      >
        {isActive ? (
          children({
            ...props,
            className: classes.input,
            defaultValue: undefined,
            onAutosave: handleAutosave,
            onChange: handleChange,
            onKeyDown: handleKeyDown,
            onKeyUp: handleKeyUp,
            required: false,
            setIsActive,
            value
          })
        ) : (
          <CustomLink
            className={classes.link}
            onFocus={() => setIsActive(true)}
            onMouseDown={handleMouseDown}
            variant="noHRef"
            disabled={!!disabled}
            tabIndex="0"
          >
            {linkDisplayNode}
          </CustomLink>
        )}
      </Form>
    </div>
  );
}
