import {
  makeStyles,
} from "@material-ui/core";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import Form from "components/utils/form-elements/form.component"
import LabelInput from "components/utils/form-elements/labelInput.component";
import { useCallback, useState } from "react";
import RunbookService from "services/runbook.service";
import { ACTION_ADD_WORKSTREAM, ACTION_REPLACE_WORKSTREAM } from "reducers/runbook/runbooks.reducer";
import FormBanner from "components/utils/form-elements/formBanner.component";
import { VALIDATION_REQUIRED } from "utils/formValidators";

const useStyles = makeStyles((theme) => ({
  form: {
    padding: "24px 32px"
  },
}));

export const EditWorkstreamForm = (props) => {
  const { formMode, activeWorkstream, onClose, dispatch } = props;
  const classes = useStyles();
  const [formData, setFormData] = useState({
    Name: activeWorkstream.Name,
    Description: activeWorkstream.Description,
  });

  const handleFormUpdate = useCallback((name, value) => {
    setFormData({ ...formData, [`${name}`]: value });
  }, [formData]);

  const handleWorkstreamUpsert = useCallback(async () => {
    const workstream = {
      Workstream_ID: activeWorkstream?.Workstream_ID,
      ...formData,
    }
    const workstreamResponse = await RunbookService.upsertWorkstream(workstream);

    if (!workstream.Workstream_ID) {
      dispatch({
        type: ACTION_ADD_WORKSTREAM,
        payload: workstreamResponse.payload,
      })
    } else {
      dispatch({
        type: ACTION_REPLACE_WORKSTREAM,
        payload: workstreamResponse.payload,
      })
    }
    onClose();
  }, [formData, onClose, dispatch, activeWorkstream]);

  return (
    <div>
      <FormBanner>{formMode === "edit" ? "Edit" : "Add New"}{` Workstream`}</FormBanner>
      <Form
        className={classes.form}
        name="Workstream-Form"
        onSubmit={handleWorkstreamUpsert}
        validations={{
          Name: [{ type: VALIDATION_REQUIRED }],
          Description: [{ type: VALIDATION_REQUIRED }],
        }}
      >
        <LabelInput
          required
          name="Name"
          onChange={(event) => handleFormUpdate("Name", event.target.value)}
          label="Name"
          variant="default"
          test="builder-Name"
          defaultValue={formData?.Name}
        />
        <LabelInput
          required
          name="Description"
          label="Description"
          variant="default"
          test="builder-Description"
          defaultValue={formData?.Description}
          onChange={(event) => handleFormUpdate("Description", event.target.value)}
          multiline
        />
        <DualFormButtons
          cancelOnClick={onClose}
        />
      </Form>
    </div>
  )
}

