import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import FormBanner from "components/utils/form-elements/formBanner.component";
import { Box, makeStyles } from "@material-ui/core";
import { useState } from "react";
import Form from "components/utils/form-elements/form.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import NumberInput from "components/utils/form-elements/numberInput.component";
import CertificationService from "services/certification.service";
import { ACTION_DELETE_AIRCRAFT, ACTION_REPLACE_AIRCRAFT } from "reducers/certification/certification.reducer";

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    marginTop: 16,
    width: 300,
  },
}));

const initialFormState = {
  Aircraft_ID: null,
  Make: "",
  Model: "",
  Series: "",
  N_Number: "",
  Cargo_Capacity: "",
  Passenger_Capacity: null,
};

const validateFormErrors = (formState) => {
  const errors = {};
  if (!formState.Make) {
    errors.Make = "Make field is required";
  }
  if (!formState.Model) {
    errors.Model = "Model field is required";
  }
  if (!formState.Series) {
    errors.Series = "Series field is required";
  }
  if (formState.Passenger_Capacity && formState.Passenger_Capacity < 0 ) {
    errors.Passenger_Capacity = "Passenger Capacity cannot be a negative value";
  }
  return errors;
};

const AircraftForm = ({ dispatch, closeForm, formMode, editItem }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState(editItem || initialFormState);
  const [formErrors, setFormErrors] = useState({});

  const changeHandler = (event) => {
    const { name, value } = event.target;

    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
    if (formErrors[name]) {
      setFormErrors((prev) => {
        return { ...prev, [name]: null };
      });
    }
  };

  const saveAircraft = async () => {
    const formValidation = validateFormErrors(formData);
    if (Object.keys(formValidation).length) {
      setFormErrors(formValidation);
      return
    }

    const data = {...formData, Passenger_Capacity: formData.Passenger_Capacity || null};
    const response = await CertificationService.upsertAircraft(data);

    dispatch({
      type: ACTION_REPLACE_AIRCRAFT,
      payload: response.payload
    });
    closeForm()
  };

  const deleteAircraft = async () => {
    await CertificationService.removeAircraft(formData.Aircraft_ID);
    dispatch({
      type: ACTION_DELETE_AIRCRAFT,
      payload: formData.Aircraft_ID
    });
    closeForm()
  };

  return (
    <Form name="aircraft-form">
      <FormBanner>
        {formMode === "create" ? "Add Aircraft" : "Edit Aircraft"}
      </FormBanner>
      <Box>
        <Box flex={1} margin={2.5}>
          <GridContainer spacing={4}>
            <GridItem className={classes.inputWrapper}>
              <LabelInput
                name="Make"
                value={formData?.Make}
                onChange={changeHandler}
                label="Make*"
                variant="default"
                test="aircraft-Make"
                inputProps={{ maxLength: 45 }}
                margin="dense"
                error={!!formErrors?.Make}
                errorMessage={formErrors?.Make}
              />
            </GridItem>

            <GridItem className={classes.inputWrapper}>
              <LabelInput
                name="Model"
                value={formData.Model}
                onChange={changeHandler}
                label="Model*"
                variant="default"
                test="aircraft-Model"
                inputProps={{ maxLength: 15 }}
                margin="dense"
                error={!!formErrors?.Model}
                errorMessage={formErrors?.Model}
              />
            </GridItem>
          </GridContainer>

          <GridContainer spacing={4}>
            <GridItem className={classes.inputWrapper}>
              <LabelInput
                name="Series"
                value={formData.Series}
                onChange={changeHandler}
                label="Series*"
                variant="default"
                test="aircraft-Series"
                inputProps={{ maxLength: 15 }}
                margin="dense"
                error={!!formErrors?.Series}
                errorMessage={formErrors?.Series}
              />
            </GridItem>

            <GridItem className={classes.inputWrapper}>
              <LabelInput
                name="N_Number"
                value={formData.N_Number}
                onChange={changeHandler}
                label="N-Number*"
                variant="default"
                test="aircraft-N_Number"
                inputProps={{ maxLength: 15 }}
                margin="dense"
              />
            </GridItem>
          </GridContainer>

          <GridContainer spacing={4}>
            <GridItem className={classes.inputWrapper}>
              <LabelInput
                name="Cargo_Capacity"
                value={formData.Cargo_Capacity}
                onChange={changeHandler}
                label="Cargo Capacity"
                variant="default"
                test="aircraft-Cargo_Capacity"
                inputProps={{ maxLength: 45 }}
                margin="dense"
              />
            </GridItem>

            <GridItem className={classes.inputWrapper}>
              <NumberInput
                name="Passenger_Capacity"
                value={formData.Passenger_Capacity}
                onChange={changeHandler}
                label="Passenger Capacity"
                variant="default"
                test="aircraft-Passenger_Capacity"
                margin="dense"
                error={!!formErrors?.Passenger_Capacity}
                errorMessage={formErrors?.Passenger_Capacity}
              />
            </GridItem>
          </GridContainer>
        </Box>
        <Box padding={2}>
          <DualFormButtons
            cancelOnClick={closeForm}
            saveOnClick={saveAircraft}
            deleteClick={
              formMode === "edit" ? deleteAircraft : null
            }
          />
        </Box>
      </Box>
    </Form>
  );
};

export default AircraftForm;
