import { makeStyles, withStyles } from "@material-ui/core/styles";
import { TableSortLabel } from "@material-ui/core";
import variables from "styleVariables";
import classNames from "classnames";
import { useCallback, useMemo } from "react";

const useStyles = makeStyles(_theme => ({
  sortDisabled: {
    cursor: "default",
    color: "white",
    fill: "white",
    "&:hover": {
      color: "white",
      fill: "white"
    },
    "&:focus": {
      color: "white",
      fill: "white"
    }
  },
  sortEnabledCentered: {
    marginRight: -19,
  },
  sortDisabledCentered: {
    marginRight: 0
  }
}));

const activeRootStyles = {
  color: variables.secondaryLight,
  "& div": {
    color: variables.secondaryLight,
  },
  "& path": {
    fill: variables.secondaryLight,
  },
};

const StyledTableSortLabelLeft = withStyles((theme) => ({
  icon: {
    color: variables.secondaryLight,
    marginRight: 0,
    fontSize: variables.fontMedium,
    height: "1rem",
  },
  active: {
    color: variables.secondaryLight,
  },
  root: {
    alignItems: "center",
    justifyContent: "start",
    paddingLeft: 0,
    width: "100%",

    color: "#ffffff",
    "&:hover": activeRootStyles,
    "&$active": activeRootStyles,
    "&$icon": {
      color: variables.secondaryLight,
    },
  },
}))(TableSortLabel);

const StyledTableSortLabelCenter = withStyles((theme) => ({
  icon: {
    color: variables.secondaryLight,
    marginRight: 0,
    fontSize: variables.fontMedium,
    height: "1rem",
  },
  active: {
    color: variables.secondaryLight,
  },
  root: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",

    color: "#ffffff",
    "&:hover": activeRootStyles,
    "&$active": activeRootStyles,
    "&$icon": {
      color: variables.secondaryLight,
    },
  },
}))(TableSortLabel);

export const SORT_ASCENDING = "asc";
export const SORT_DESCENDING = "desc";


export default function StyledTableSortLabel(props) {
  const classes = useStyles();
  const {
    active, align, children, className, classNameRoot, direction, hideSortIcon,
    onClick, sortBy, sortDisabled, ...labelProps
  } = props;

  const handleClick = useCallback(event => (
    !sortDisabled && onClick?.(event, sortBy)
  ), [onClick, sortBy, sortDisabled]);

  const LabelComponent = useMemo(() => (
    align === 'left' ? StyledTableSortLabelLeft : StyledTableSortLabelCenter
  ), [align]);

  return (
    <LabelComponent
      {...labelProps}
      active={!!active && !sortDisabled}
      direction={direction}
      hideSortIcon={sortDisabled || hideSortIcon}
      className={
        classNames(
          (sortDisabled || hideSortIcon) && classes.sortDisabled,
          sortDisabled && align === "center" && classes.sortDisabledCentered,
          !sortDisabled && align === "center" && classes.sortEnabledCentered
        )
      }
      classes={{
        root: classNameRoot,
      }}
      onClick={handleClick}
      tabIndex={sortDisabled ? -1 : undefined}
    >
      <div
        className={
          classNames(
            (sortDisabled || hideSortIcon) && classes.sortDisabled, className
          )
        }
      >
        {children}
      </div>
    </LabelComponent>
  );
}
