import React from "react"; // { useState }
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionSummary,
  Typography,
  Paper,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import variables from "styleVariables";

const useStyles = makeStyles((theme) => ({
  accordionWrapper: {
    width: "100%",
    overflowX: "hidden",
    "&:hover": {
      "& .MuiPaper-root": {
        backgroundColor: "rgba(119, 197, 253, 0.5)",
      },
    },
  },
  childrenWrapper: {
    backgroundColor: "transparent",
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    display: "flex",
    flexDirection: "column",
  },
  summaryContent: {
    justifyContent: "space-between",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  denseHeading: {
    fontSize: variables.fontMed,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  emptyBox: {
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 3,
    color: variables.textSecondary,
  },
}));

const AccordionView = ({
  children,
  mainLabel,
  subLabel,
  handleChange,
  expanded,
  emptyMessage,
  defaultExpanded,
  dense,
  testProp,
  id,
  ...muiProps
}) => {
  const classes = useStyles();

  if (emptyMessage) {
    return (
      <Paper className={classNames(classes.root, classes.emptyBox)} data-cy={testProp}>
        {emptyMessage}
      </Paper>
    );
  } else {
    return (
      <div className={classes.accordionWrapper} data-cy={`accordion-wrapper-${id}`}>
        <Accordion
          expanded={expanded}
          onChange={handleChange}
          defaultExpanded={defaultExpanded === true}
          data-cy="accordion-view"
          {...muiProps}
        >
          <AccordionSummary
            classes={{
              root: classes.accordionSummary,
              content: classes.summaryContent
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${id}-content`}
            data-cy={`accordion-summary-${testProp}`}
            id={id}
          >
            <div className={dense ? classes.denseHeading : classes.heading}>
              {mainLabel}
            </div>
            {!!subLabel && (
              <Typography className={classes.secondaryHeading}>
                {subLabel}
              </Typography>
            )}
          </AccordionSummary>
          <div className={classes.childrenWrapper}>
            {children}
          </div>
        </Accordion>
      </div>
    );
  }
};

export default AccordionView;
