import React, { useMemo } from "react";
import { Select, InputLabel, FormControl, FormHelperText, MenuItem } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import variables from "styleVariables";
import FormLabel from "components/utils/form-elements/formLabel.component";
import { isReadOnly } from "utils/roles";
import FormElementBase from "./shared/formElementBase.component";

const useStyles = makeStyles((theme) => ({
  dropDownSelect: {
    width: "100%",
    fontSize: variables.fontSmall,
  },
  customInputLabel: {
    whiteSpace: "nowrap",
    [theme.breakpoints.down('md')]: {
      fontSize: variables.fontSmall,
    },
  },
  heightLabelInput: {
    height: 36,
  },
  readOnly: {
    '& .MuiInputBase-input.Mui-disabled': {
      color: "#000"
    }
  },
}));

const CustomFormHelperText = withStyles({
  root: {
    fontSize: variables.fontXxs,
    fontWeight: "bold",
    textAlign: "right",
    color: variables.secondaryMain,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderRadius: "0px 0px 3px 3px",
    height: 15,
    margin: 0,
    padding: "0px 5px 1px 0px",
    "&.Mui-error": {
      backgroundColor: variables.warningMain,
      color: `white !important;`,
      margin: 0,
      padding: "0px 5px 1px 0px",
    },
  },
})(FormHelperText);

const CustomSelect = props => {
  const { name, onAutosave, onChange } = props;
  const handleChange = useMemo(() => (
    onChange ||
    (onAutosave && (
      event => onAutosave(name, event.target.value, event)
    ))
  ), [name, onAutosave, onChange]);

  return (
    <FormElementBase onChange={handleChange} {...props} >
      <CustomSelectBase {...props} />
    </FormElementBase>
  );
};

const CustomSelectBase = (props) => {
  const classes = useStyles();

  const isReadOnlyRole = useMemo(isReadOnly, []);

  const readOnly = props.readOnly || (!props.ignoreReadOnly && isReadOnlyRole);

  const isDefaultValueInProps = "defaultValue" in props;

  const defaultValue = useMemo(() => (
    isDefaultValueInProps ? (props.defaultValue || "") : undefined
  ), [props.defaultValue, isDefaultValueInProps]);


  // Special cases handled:
  // 1. If `options` prop is passed, render items based on them.
  // 2. If `children` items are contained in a Fragment, use inner items array
  const children = useMemo(() => {
    if (!props.children) {
      if (Array.isArray(props.options)) {
        return props.options.map(option => (
          <MenuItem
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ));
      }
      return null;
    }
    if (!Array.isArray(props.children) && props.children.props?.children) {
      return props.children.props?.children;
    }
    return props.children;
  }, [props.children, props.options]);

  const value = useMemo(() => {
    if (props.value) {
      return props.value;
    } else {
      const isFalsyValueInOptions = props?.options?.some?.(option =>
        props.value === option.value
      );
      if (isFalsyValueInOptions) {
        return props.value;
      }
    }
    if (isDefaultValueInProps) {
      return undefined;
    }
    return "";
  }, [isDefaultValueInProps, props.options, props.value]);

  if (props.variant === "stacked") {
    return (
      <FormControl className={props.className}>
        <InputLabel
          className={classes.customInputLabel}
          required={props.labelRequired}
          id={props.labelId}
          error={!!props.labelError && !readOnly}
          // name={props.name}
          // value={props.value}
          shrink={props.shrink}
          classes={props.inputLabelClasses}
        >
          {props.label}
        </InputLabel>
        <Select
          className={
            classNames(
              classes.dropDownSelect,
              readOnly && classes.readOnly,
              props.labelInputRow && classes.heightLabelInput
            )
          }
          error={!!props.error && !readOnly}
          labelId={props.labelId}
          onChange={props.onChange}
          value={value}
          defaultValue={defaultValue}
          renderValue={props.renderValue}
          required={props.required}
          disabled={props.disabled || readOnly}
          name={props.name}
          classes={props.selectClasses}
          data-cy={`select-${props.test}`}
          MenuProps={props.MenuProps}
        >
          {children}
        </Select>
        {!!props.errorMessage && (
          <CustomFormHelperText
            className={props.helperClassName}
            error
          >
            {props.errorMessage}
          </CustomFormHelperText>
        )}
      </FormControl>
    )
  } else if (props.variant === "selectInner") {
    return (
      <>
        <InputLabel
          className={classes.customInputLabel}
          required={props.labelRequired}
          id={props.labelId}
          error={props.labelError}
          // name={props.name}
          // value={props.value}
          shrink={props.shrink}
        >
          {props.label}
        </InputLabel>
        <Select
          className={
            classNames(
              classes.dropDownSelect,
              readOnly && classes.readOnly,
              props.labelInputRow && classes.heightLabelInput,
              props.className
            )
          }
          error={props.error}
          labelId={props.labelId}
          onChange={props.onChange}
          value={value}
          defaultValue={defaultValue}
          renderValue={props.renderValue}
          required={props.required}
          disabled={props.disabled || readOnly}
          name={props.name}
          data-cy={`select-${props.test}`}
          MenuProps={props.MenuProps}
        >
          {children}
        </Select>
        <CustomFormHelperText
          error={!!props.errorMessage}
          className={props.helperClassName}
        >
          {props.helperText || props.errorMessage}
        </CustomFormHelperText>
      </>
    );

  } else {
    const isRequired = props.required;
    return (
      <>
        {!!props.label && (
          <FormLabel
            label={<>{props.label}{props.required ? `*` : null}</>}
            htmlFor={props.labelId}
            variant="default"
            error={props.error}
            primaryMain={props.formLabelPrimaryMain}
          />
        )}
        <Select
          className={
            classNames(
              classes.dropDownSelect,
              readOnly && classes.readOnly,
              props.labelInputRow && classes.heightLabelInput,
              props.className
            )
          }
          error={props.error}
          labelId={props.labelId}
          onChange={props.onChange}
          onClose={props.onClose}
          value={value}
          defaultValue={defaultValue}
          disabled={props.disabled || (!props.readOnlyAccessible && readOnly)}
          name={props.name}
          required={isRequired}
          renderValue={props.renderValue}
          data-cy={`select-${props.test}`}
          displayEmpty={props.displayEmpty}
          multiple={props.multiple}
          classes={props.classes}
          MenuProps={props.MenuProps}
        >
          {children}
        </Select>
        {!props.helperTextDisabled && (
          <CustomFormHelperText
            error={!!props.errorMessage}
            className={props.helperClassName}
            data-cy={`select-helper-${props.test}`}
          >
            {props.helperText || props.errorMessage}
          </CustomFormHelperText>
        )}
      </>
    );
  }
};

export default CustomSelect;
