//package imports
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import { Container } from "@material-ui/core";
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";

// pages
import Login from "components/auth/login.component"
import Verify from "components/auth/verify.component"
import Forgot from "components/auth/forgot.component";
import Reset from "components/auth/reset.component";
import Logout from "components/auth/logout.component"
import NotFound from "components/auth/notFound.component";

// Routing
import AuthenticatedRoute from "components/auth/authenticatedRoute.component";
import AuthenticatedPagesRouter from "components/layouts/dynamic/authenticatedRouter.component";

// styling
import 'App.css';
import theme from "theme";
import { AUTH_ROUTES_ALL } from "components/layouts/constants/routes.constants";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: false,
    marginLeft: 'unset',
    marginRight: 'unset',
  },
}));

const App = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container
        className={classes.root}
        // disableGutters={true}
        maxWidth={false}
      >
        <Router>
          <CompatRouter>
            <div style={{ display: "flex" }}>
              <CssBaseline />
              <Switch>
                <Route exact path={["/login", "/"]}>
                  <Login />
                </Route>
                <Route exact path={["/verify"]}>
                  <Verify />
                </Route>
                <Route exact path={["/forgot"]}>
                  <Forgot />
                </Route>
                <Route exact path={["/reset/:userId/:key"]}>
                  <Reset />
                </Route>
                <Route exact path="/logout">
                  <Logout />
                </Route>
                <AuthenticatedRoute path={AUTH_ROUTES_ALL}>
                  <AuthenticatedPagesRouter />
                </AuthenticatedRoute>
                <Route exact path={["/*", "/notFound"]}>
                  <NotFound />
                </Route>
              </Switch>
            </div>
          </CompatRouter>
        </Router>
      </Container>
    </ThemeProvider>
  );
}

export default App;
