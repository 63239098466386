import http from "./http-common";
import authHeader from "./authHeader";

class WorkpackageService {
  getAll() {
    return http
      .get(`/workpackage/`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getWorkpackageById(workpackageId) {
    return http
      .get(`/workpackage/${workpackageId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getWorkpackageByProgramId(programId) {
    return http
      .get(`/workpackage/programWorkpackages/${programId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getWorkpackageAndTaskByProgramId(programId) {
    return http
      .get(`/workpackage/programWorkpackages/tasks/${programId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getWorkpackageAndTasksById(workpackageId) {
    return http
      .get(`/workpackage/${workpackageId}/tasks`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // save new Workpackage
  saveNewWorkpackage(workpackage) {
    return http
      .post(`/workpackage/saveNewWorkpackage/`, {workpackage}, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // update a Workpackages
  updateWorkpackages(workpackages) {
    return http
      .put(`/workpackage/workpackages/`, { workpackages }, { headers: authHeader() })
      .then(response => response.data);
  }

  updateWorkpackage(workpackage, workpackageId) {
    return http
      .put(`/workpackage/${workpackageId}/update`, { workpackage }, { headers: authHeader() })
      .then(response => response.data);
  }

  // Is Workpackage deleteable
  isWorkpackageDeleteAble(workpackageId) {
    return http
      .get(`/workpackage/isDeleteAble/${workpackageId}`, { headers: authHeader() })
      .then((response) => {
        return response;
      });
  }
  // delete Workpackage
  deleteWorkpackage(workpackageId) {
    return http
      .post(`/workpackage/delete/${workpackageId}`, {workpackageId}, { headers: authHeader() })
      .then((response) => {
        return response;
      });
  }

}

export default new WorkpackageService();
