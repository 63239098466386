const altCodeMap = {
  CMMCDomains: "Abbreviation",
  ControlFamilies: "Fam_ID",
  NIST171: "groupName",
  CSF: "groupName"
};

const groupNameMap = {
  CMMCDomains: "Domain_Name",
  ControlFamilies: "Family_Name",
  NIST171: "Security_Req_Group",
  CSF: "Category"
};

const nameMap = {
  CMMCPractices: "Practice_Code",
  NIST171: "171_Identifier",
  ControlDetail: "Identifier",
  CSF: "Subcategory_Identifier"
};

const practiceTerminologyMap = {
  ControlDetail: "CONTROL",
  CMMCPractices: "PRACTICE",
  NIST171: "CONTROL",
  CSF: "CONTROL"
};

const sourceObjMap = {
  CMMCPractices: "Practice_ID",
  NIST171: "171_ID",
  ControlDetail: "Control_ID",
  CSF: "CSF_ID"
};

export {
  groupNameMap,
  altCodeMap,
  nameMap,
  practiceTerminologyMap,
  sourceObjMap,
};
