import { COMPONENT_OBJECT_TYPE_BUILDER } from "./programConstants";

export const findProgramBuilderComponent = (
  programId,
  typeRefName,
  programComponentsByProgram,
  componentObjectsByComponent
) => {
  const programComponents = (
    programComponentsByProgram?.[programId]
  );
  if (programComponents && componentObjectsByComponent) {
    for (const programComponent of programComponents) {
      const objects = componentObjectsByComponent[
        programComponent.Component_ID
      ];
      if (objects) {
        const match = objects.find(programComponentObject => (
          programComponentObject.Type === COMPONENT_OBJECT_TYPE_BUILDER &&
          programComponentObject.TypeRef.includes(typeRefName)
        ));
        if (match) {
          return match;
        }
      }
    }
  }
  return null;
}
