import Loader from "components/utils/loader.components";
import PortfolioProgramContext from "contexts/portfolioProgram.context";
import { useEffect, useMemo, useState } from "react";
import PortfolioService from "services/portfolio.service";
import { setStateFetchEffect } from "utils/ajaxHelpers";
import { isPortCoGroupUser } from "utils/roles";


export default function PortfolioContextProvider({ children }) {
  const [permittedPortCos, setPermittedPortCos] = useState(null);
  const [
    permittedPortfolioHolders,
    setPermittedPortfolioHolders
  ] = useState(null);

  const isPortCoOnlyRole = useMemo(isPortCoGroupUser, []);

  useEffect(() => (
    async function fetchPermittedPortfolioHolders() {
      if (permittedPortfolioHolders) {
        return;
      }
      return setStateFetchEffect(
        PortfolioService.getPermittedPortfolioHolderList(),
        ([holdingCompanies]) => {
          setPermittedPortfolioHolders(
            Object.freeze(holdingCompanies.payload)
          );
        }
      );
    }
  )(), [permittedPortfolioHolders]);

  useEffect(function fetchPermittedPortCos() {
    if (!isPortCoOnlyRole || permittedPortCos) {
      return;
    }
    return setStateFetchEffect(
      PortfolioService.getPortfolioGroupPermissionsList(),
      ([permissionList]) => setPermittedPortCos(permissionList.payload)
    );
  }, [isPortCoOnlyRole, permittedPortCos]);

  if (!permittedPortfolioHolders) {
    return <Loader />;
  }
  return (
    <PortfolioProgramContext.Provider
      value={{
        permittedPortfolioHolders,
        permittedPortCos
      }}
    >
      {children}
    </PortfolioProgramContext.Provider>
  );
}
