import React, { useContext, useMemo, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import HeaderPrimary from "components/utils/header.component";
import CustomIcons from "components/utils/icons.component";
import { Popover } from "@material-ui/core";
import AlertsContainer from "components/alerts/alertsContainer.component";
import AlertContext from "contexts/alert.context";
import AlertsFeed from "components/alerts/alertsFeed.component";
import variables from "styleVariables";
import { alertsFeedVariants } from "utils/alertsConstants";
import Badge from "components/utils/badge.component";
import ProgramsContext from "contexts/programs.context";
import useNumericParams from "hooks/useNumericParams";

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
    height: 36,
    width: 36,
    padding: 7,
    borderRadius: 50,
    background: 'radial-gradient(ellipse farthest-side at 65% 38%, #175db5 14%, #164c93 58%, #11386c 93%, #0c294f 100%)',
  },
  alertIconWrapper: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 10,
    "&:hover": {
      cursor: "pointer",
    },
  },
  iconContainer: {
    fill: "white",
    display: "flex",
    height: 20,
  },
  alertIconContainer: {
  },
  icon: {
    height: 20,
  },
  headerWrapper: {
    display: "inline-flex",
    alignItems: "center",
  },
  dense: {
    margin: 0,
    paddingTop: 7,
    paddingBottom: 7,
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -2,
    top: -8,
    border: `1px solid white`,
    padding: "0 2px",
    fontSize: variables.fontXs,
    height: 20,
    fontWeight: "bold",
    borderRadius: 40,
    backgroundColor: variables.tertiary2,
  },
}))(Badge);

export const Title = (props) => {
  const classes = useStyles();
  const { componentObjectId, programId } = useNumericParams(true);
  const { state: alertState } = useContext(AlertContext);
  const { state: programsState } = useContext(ProgramsContext);

  //for popover
  const [titleAlertEl, setTitleAlertEl] = useState(null);
  const open = Boolean(titleAlertEl);
  const id = open ? "title-alerts" : undefined;

  const programName = useMemo(() => (
    programsState.programs?.[programId]?.Name
  ), [programsState.programs, programId]);

  const handleClose = () => {
    setTitleAlertEl(null);
  };

  const openAlerts = (event) => {
    setTitleAlertEl(event.currentTarget);
  };

  return (
    <>
      <HeaderPrimary variant={props.variant ? props.variant : "h1Primary"} className={!!props.dense && classes.dense}>
        {/* TITLE ONLY = only used by Search & Admin page */}
        {!!props.titleOnly && (
          <span className={classes.headerWrapper}>
            {!!props.startIcon && (
              <div className={classes.iconWrapper}>
                <div className={classes.iconContainer}>
                  {props.startIcon}
                </div>
              </div>
            )}
            {`${props.title}`}
          </span>
        )}

        {/* DASHBOARD TITLE */}
        {!!props.dashboardTitle && !!programName && (
          <span
            className={classes.headerWrapper}
            data-cy={`title-dashboard-${props.title}`}
          >
            {props.title}
          </span>
        )}

        {/* ===== DEFAULT ===== */}
        {!props.titleOnly && !props.dashboardTitle && !!programName && (
          <span
            className={classes.headerWrapper}
            data-cy={`title-${programName} ${props.title}`}
          >
            {programName} {props.title}
            {/* ALERTS ICON FOR COMP OBJECT PAGES */}
            {!!componentObjectId && !!alertState.componentObjectAlertCount && (
              <div
                className={classes.alertIconWrapper}
                onClick={(event) => openAlerts(event)}
              >
                <div className={classes.alertIconContainer}>
                  <CustomIcons
                    variant="Notifications"
                    className={classes.icon}
                  />
                  <StyledBadge
                    data-cy="headerAlertBadge"
                    alertCount={alertState.componentObjectAlertCount}
                    color="secondary"
                  />
                </div>
              </div>
            )}
          </span>
        )}
      </HeaderPrimary>
      <Popover
        id={id}
        open={open}
        anchorEl={titleAlertEl}
        onClose={handleClose}
      >
        <AlertsContainer variant="feedView">
          <AlertsFeed
            compObjId={componentObjectId}
            compName={props.title}
            setTitleAlertEl={setTitleAlertEl}
            maxHeight={455}
            test="header"
            variant={alertsFeedVariants.COMPONENT_OBJECT}
          />
        </AlertsContainer>
      </Popover>
    </>
  );
};

export default Title;
