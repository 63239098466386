import EmptyFilterLabel from "../shared/emptyFilterLabel.component";

/*
 * Make params for a standard column of cells with simple sortable values.
 *
 * Additional options not in mui-datatable docs:
 * - emptyFilterLabel: Custom label for blank cells when shown in filter
 */
export const createValueHeaderParams = (name, label = name, options = {}) => {
  const defaultedOptions = {
    ...options,
    filter: getIsFilterable(label, options),
    customFilterListOptions: addDefaultFilterChipRender(label, options),
    filterOptions: addDefaultFilterOptions(options),
  };
  deleteUndefinedKeys(defaultedOptions);
  return {
    name,
    label,
    options: defaultedOptions
  };
};

/*
 * Make params for a column of cells that have custom nodes as their content.
 * The function `createNodeCellParams` must be used for cell data of same column.
 *
 * Additional options not in mui-datatable docs:
 * - emptyFilterLabel: Custom label for blank cells when shown in filter
 */
export const createNodeHeaderParams = (name, label, options = {}) => {
  const defaultedOptions = {
    customBodyRender: renderNodeWithMetaData,
    sortCompare: sortCompareByNodeMetadata,
    ...options,
    filter: getIsFilterable(label, options),
    customFilterListOptions: addDefaultFilterChipRender(label, options),
    filterOptions: addDefaultFilterOptions(options, true),
  };
  deleteUndefinedKeys(defaultedOptions);
  return {
    name,
    label,
    options: defaultedOptions
  };
};

export const createHiddenHeaderParams = (name, options) => ({
  name,
  label: null,
  options: {
    sort: false,
    ...options,
    display: "excluded",
    filter: false,
    viewColumns: false,
  }
});

/*
 * Make params for a column with cells using custom nodes.
 * The function `createNodeHeaderParams` must be used for header of this column.
 */
export const createNodeCellParams = (
  sortValue,
  textValue,
  node = textValue
) => ({
  node,
  metadata: {
    sortValue: sortValue === undefined ? textValue : sortValue,
    textValue
  },
  toString: function getFilterValue() {
    if (!textValue && filterLabelTypes.has(typeof node)) {
      return `${node}` || "";
    }
    return textValue || "";
  }
});

const CHAR_MAX_PLACES = 2;
const FIRST_ALPHA_CHAR_CODE = 97;
const REGEXP_ALPHANUMERIC = /([a-zA-Z0-9]+)/g;

const BLANK_VALUE = "--";
const FILTER_TYPE_CHECKBOX = "checkbox";

const filterLabelTypes = new Set(["string", "number"]);


export function calculateMultipartSortValue(
  value,
  blankValue,
  partDelimiter = ".", // String or regex
  rangeDelimiter = "-", // String or regex
) {
  const defaultedBlankValue = blankValue || BLANK_VALUE;
  if (!value || value === defaultedBlankValue) {
    return 0;
  }
  const firstRangeValue = (
    rangeDelimiter ? value.split(rangeDelimiter)[0] : value
  );
  const splitParts = (
    partDelimiter ? firstRangeValue.split(partDelimiter) : [firstRangeValue]
  );

  return splitParts.map((part, index, all) => {
    let numericPartValue = 0;
    const numberPlace = ((all.length - index) * 4) - 4;
    if (isNaN(part)) {
      const alphanumericOnly = part.match(REGEXP_ALPHANUMERIC)?.[0];
      if (!alphanumericOnly) {
        return 0;
      }
      // Convert Text to numeric char codes
      const charPartValue = alphanumericOnly
        .toLowerCase()
        .split("")
        .reduce((sum, char) => {
          const code = char.charCodeAt(0) - FIRST_ALPHA_CHAR_CODE + 1;
          const placedCodeString = `${code}`.padStart(CHAR_MAX_PLACES, "0");
          return sum + placedCodeString;
        }, 0);
      numericPartValue = parseInt(charPartValue, 10);
    } else {
      numericPartValue = part;
    }
    const zeros = "".padEnd(numberPlace, "0");
    const parts = numericPartValue + zeros;
    return parseInt(parts, 10);
  })
    .reduce((sum, part) => sum + part, 0) ?? 0;
}

function deleteUndefinedKeys(options) {
  Object.keys(options).forEach(optionKey => {
    if (options[optionKey] === undefined) {
      delete options[optionKey];
    }
  });
  return options;
}

function getIsFilterable(label, options) {
  if (options.filter === undefined) {
    if (typeof label === "string") {
      if (!label.trim()) {
        return false;
      }
    } else if (label !== 0 && !label) {
      return false;
    }
  }
  return options.filter;
}

const addDefaultFilterChipRender = (label, options) => {
  if (options.filter === false) {
    return undefined;
  }
  return {
    render: function renderFilterChipLabel(value) {
      const renderableValue = (value !== BLANK_VALUE && value) || null;
      const prefix = options.filterChipCategoryVisible ? `${label}: ` : "";
      return renderableValue ? (
        prefix + renderableValue
      ) : (
        <EmptyFilterLabel>
          {options.emptyFilterLabel || `${prefix}No ${label}`}
        </EmptyFilterLabel>
      );
    },
    ...options.customFilterListOptions
  };
}

const addDefaultFilterOptions = (options, isNode = false) => {
  if (options.filter === false) {
    return undefined;
  }
  const filterOptions = {
    renderValue: value => {
      if (typeof value === "number" && !isNaN(value)) {
        return `${value}`;
      } else if (!value || value === BLANK_VALUE) {
        if (
          !options.filterType ||
          FILTER_TYPE_CHECKBOX === options.filterType
        ) {
          return (
            <EmptyFilterLabel>
              {options.emptyFilterLabel}
            </EmptyFilterLabel>
          );
        } else {
          return options.emptyFilterLabel || "(None)";
        }
      } else {
        return value?.trim?.();
      }
    },
    ...options.filterOptions
  };
  const isCustomLogicNeeded = isNode && !filterOptions?.logic;
  if (isCustomLogicNeeded) {
    const isArrayFilter = !!filterOptions.names;
    if (isArrayFilter) {
      filterOptions.logic = function hasFilterArrayItemInCell(cell, filters) {
        if (!filters?.length) {
          return true;
        }
        if (!cell.toString().trim()) {
          return !filters.some(filter => !filter?.trim?.());
        }
        return !filters.some(filter => (
          filter?.trim?.() && cell.toString().includes(filter)
        ));
      };
    } else {
      filterOptions.logic = function hasCellFilterMatch(cell, filters) {
        return !filters.includes(cell.toString());
      };
    }
  }
  return filterOptions;
};

/*
 * For table cells where the value is an element, the only way of
 *   storing custom sort values seems to be as a data attribute on the element itself.
 */
const sortCompareByNodeMetadata = (direction) => (data1, data2) => {
  let ascComparison;
  const value1 = data1.data.metadata.sortValue;
  const value2 = data2.data.metadata.sortValue;
  const isNaN1 = (!value1 && value1 !== 0) || isNaN(value1);
  const isNaN2 = (!value2 && value2 !== 0) || isNaN(value2);

  if (isNaN1 !== isNaN2) {
    ascComparison = !!isNaN1 - !!isNaN2;
  } else if (!isNaN1) {
    const float1 = typeof value1 === "number" ? value1 : parseFloat(value1) || 0;
    const float2 = typeof value2 === "number" ? value2 : parseFloat(value2) || 0;
    ascComparison = float1 - float2;
  } else {
    const stringValue1 = value1?.toString() || "";
    const stringValue2 = value2?.toString() || "";
    if (!!stringValue1 !== !!stringValue2) {
      ascComparison = !!stringValue1 - !!stringValue2;
    } else {
      ascComparison = stringValue1.localeCompare(
        stringValue2,
        'en',
        { sensitivity: 'base' }
      );
    }
  }

  if (direction === "asc") {
    return ascComparison;
  }
  const descendingSortMultiplier = -1;
  return ascComparison * descendingSortMultiplier;
};

const renderNodeWithMetaData = (value) => value?.node;
