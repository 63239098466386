import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styleVariables from "styleVariables";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import {
  Paper,
  Table,
  TableBody,
  TableRow,
} from "@material-ui/core";
import TableCell from "components/utils/tables/shared/tableCell.component";
import { sortIntByStringKey } from "utils/sortingFuncs";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Tooltip from "components/utils/tooltip.component";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import { truncate } from "utils/workPackageHelpers";
import FormBanner from "components/utils/form-elements/formBanner.component";
import { updateOrderedArray } from "utils/arrayOfObjectsHelpers";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    maxWidth: 780,
    padding: "20px 25px 10px 25px",
  },
  contentContainer: {
    padding: "10px 20px 0px 20px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  innerContentContainer: {
    minHeight: 200,
  },
  tablePaper: {
    maxHeight: 810,
    [theme.breakpoints.down("lg")]: {
      maxHeight: 500,
    },
    overflowX: "auto",
  },
  tableCell: {
    padding: 5,
    backgroundColor: "white",
  },
  untitledLabel: {
    opacity: 0.5
  },
  //DRAG
  dragRow: {
    display: "flex",
    alignItems: "center",
  },
  dragIcon: {
    fill: styleVariables.textSecondary,
    marginRight: 20,
  },
  //rowDisplay
  rowDisplayWrapper: {
    display: "flex",
  },
  termOrder: {
    fontWeight: "bold",
    paddingRight: 10,
    width: 20,
    fontSize: styleVariables.fontSmall,
  },
  termLabel: {
    width: 340,
    paddingLeft: 5,
    paddingRight: 5,
    fontSize: styleVariables.fontSmall,
  },
  emptyListRow: {
    color: styleVariables.textSecondary,
    display: "flex",
    justifyContent: "center",
  },
  buttonsWrapper: {
    paddingTop: 30
  }
}));

const DragRow = ({ children, provided }) => {
  const classes = useStyles();
  return (
    <div className={classes.dragRow}>
      <span>
        <DragHandleIcon className={classes.dragIcon} />
      </span>
      {children}
    </div>
  );
};
const truncateLimit = 50;

const RowDisplay = ({
  listItem,
  displayFieldName,
  orderFieldName,
  // truncateLimit = 50,
}) => {
  const classes = useStyles();
  const listItemText = listItem[displayFieldName] || "";
  const showTooltip = listItemText.length > truncateLimit;

  return (
    <div className={classes.rowDisplayWrapper}>
      <div className={classes.termOrder}>
        {listItem[orderFieldName]}
      </div>
      <div className={classes.termLabel}>
        <Tooltip title={showTooltip ? listItemText : ""} placement="top">
          {listItemText ? (
            <span>{truncate(listItemText, truncateLimit)}</span>
          ) : (
            <span className={classes.untitledLabel}>(Untitled)</span>
          )}
        </Tooltip>
      </div>
    </div>
  );
};

const ReorderList = ({
  list,
  closeForm,
  isSaving,
  orderFieldName,
  displayFieldName,
  primaryKey,
  saveFunction,
}) => {
  const classes = useStyles();
  const [reorderedList, setReorderedList] = useState([]);

  useEffect(() => {
    const initialOrder = list.map((item, index) => ({
      ...item,
      [orderFieldName]: index + 1,
    }));
    setReorderedList(initialOrder);
  }, [orderFieldName, list, setReorderedList]);

  function handleOnDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const oldOrder = result.source.index + 1;
    const newOrder = result.destination.index + 1;

    if (oldOrder !== newOrder) {
      //  Returns updated list
      const updatedOrderList = updateOrderedArray(
        reorderedList,
        oldOrder,
        newOrder,
        orderFieldName
      );
      const sortedUpdatedList = sortIntByStringKey(
        updatedOrderList,
        orderFieldName
      );
      setReorderedList(sortedUpdatedList);
    }
  }

  const saveReorderedList = async () => {
    await saveFunction(reorderedList);
    closeForm();
  };

  const renderCloneItem = useCallback((provided, _snapshot, rubric) => (
    <TableRow
      provided={provided}
      ref={provided?.innerRef}
      {...provided?.draggableProps}
      {...provided.dragHandleProps}
    >
      <TableCell className={classes.tableCell}>
        <DragRow>
          <RowDisplay
            listItem={reorderedList[rubric.source.index]}
            displayFieldName={displayFieldName}
            orderFieldName={orderFieldName}
          />
        </DragRow>
      </TableCell>
    </TableRow>
  ), [classes, displayFieldName, orderFieldName, reorderedList]);

  return (
    <>
      <FormBanner>Re-order List</FormBanner>
      <div className={classes.formContainer}>
        <div className={classes.contentContainer}>
          <div className={classes.innerContentContainer}>
            <Paper
              className={classes.tablePaper}
              elevation={3}
              data-cy="created-items-list"
            >
              <DragDropContext onDragEnd={(event) => handleOnDragEnd(event)}>
                <Droppable
                  droppableId="droppable-design-list"
                  renderClone={renderCloneItem}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Table>
                        <TableBody className={classes.orderedList}>
                          {reorderedList.map((item, index) => {
                            const idValue = item[primaryKey];
                            return (
                              <Draggable
                                draggableId={`${idValue}`}
                                index={index}
                                key={idValue}
                              >
                                {(providedInfo, snapshot) => (
                                  <TableRow
                                    key={idValue}
                                    provided={providedInfo}
                                    ref={providedInfo?.innerRef}
                                    {...providedInfo?.draggableProps}
                                    {...providedInfo.dragHandleProps}
                                  >
                                    <TableCell className={classes.tableCell}>
                                      <DragRow>
                                        <RowDisplay
                                          listItem={item}
                                          // index={index}
                                          displayFieldName={displayFieldName}
                                          orderFieldName={orderFieldName}
                                        />
                                      </DragRow>
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                          {list?.length === 0 && (
                            <TableRow>
                              <TableCell className={classes.tableCell}>
                                <div className={classes.emptyListRow}>
                                  There are no items to re-order
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Paper>
          </div>
          <DualFormButtons
            className={classes.buttonsWrapper}
            cancelOnClick={closeForm}
            saveOnClick={saveReorderedList}
            isSaving={isSaving}
          />
        </div>
      </div>
    </>
  );
};

export default ReorderList;
