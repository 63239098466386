import React, { useEffect, useMemo, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import ReviewItemsPage from "components/assessment/review/reviewItemsPage.component";
import { reviewPageModes } from "utils/assessmentConstants";
import RiskAssessmentService from "services/riskAssessment.service";
import DesignDataCollectionPage from "./designDataCollectionPage.component";
import SummaryRecommendation from "./summaryRecommendation.component";
import PerformanceDataCollectionPage from "./performanceDataCollectionPage.component";
import SummaryResponsePage from "components/assessment/review/summaryResponsePage.component";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import Loader from "components/utils/loader.components";
import RoadmapItemsPage from "../roadmap/roadmapItemsPage.component";
import RoadmapEdit from "../roadmap/roadmapEdit.component";
import { setStateFetchEffect } from "utils/ajaxHelpers";

const useStyles = makeStyles(() => ({
  layoutContainer: {
    width: "100%",
    position: "relative",
  },
}));

const DATA_COLLECTION_EDIT_MODES = [
  reviewPageModes.DESIGN_DATA_COLLECTION,
  reviewPageModes.PERFORMANCE_DATA_COLLECTION,
];

const AssessmentReview = (props) => {
  const {
    assessors,
    buildAssessmentData,
    builderInfo,
    basePath,
    basePathTemplate,
    recordFormData,
    setAssessors,
    setBuilderInfo,
    setMode,
    setMessage,
    withUnsavedChanges,
    setHasUnsavedChanges,
    hasUnsavedChanges,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const { activeId } = useParams();
  const [assessmentItems, setAssessmentItems] = useState(null);
  const [checklistItems, setChecklistItems] = useState(null);
  const [designItems, setDesignItems] = useState(null);
  const [performanceItems, setPerformanceItems] = useState(null);
  const [references, setReferences] = useState(null);
  const [summaryRecommendations, setSummaryRecommendations] = useState([]);
  const [roadmapItems, setRoadmapItems] = useState(null)
  const [onSuccessVariable, setOnSuccessVariable] = useState({})
  const [activeRoadmapItem, setActiveRoadmapItem] = useState([])


  const getAssessmentItemName = (assessementObject) => {
    try {
      const assessmentTemp = JSON.parse(assessementObject);
      if (assessmentTemp.Practice_Code) {
        return assessmentTemp.Practice_Code;
      } else if (assessmentTemp.Identifier) {
        return assessmentTemp.Identifier;
      } else if (assessmentTemp.Name) {
        return assessmentTemp.Name;
      } else if (assessmentTemp.Title) {
        return assessmentTemp.Title;
      }
    } catch (error) {
      return assessementObject;
    }
  }

  useEffect(function fetchRequiredBuilderData() {
    const id = builderInfo.Builder_ID;
    if (!id) {
      return;
    }
    return setStateFetchEffect([
      RiskAssessmentService.getAssessmentItemsByBuilderID(id),
      RiskAssessmentService.readAssessmentReferenceListByBuilderID(id),
      RiskAssessmentService.getAssessmentDataCollectionByBuilderID(id),
      RiskAssessmentService.getAssessmentSummaryRecommendationsByBuilderID(id),
      RiskAssessmentService.getAssessorsByAssessmentID(id),
    ], ([
      assessmentItemsResponse,
      referencesResponse,
      dataCollectionResponse,
      summaryResponse,
      assessorResponse,
    ]) => {
      setAssessmentItems(assessmentItemsResponse.items);
      setDesignItems(dataCollectionResponse.payload.design || []);
      setPerformanceItems(dataCollectionResponse.payload.performance || []);
      setSummaryRecommendations(summaryResponse.items || []);
      setReferences(referencesResponse.payload.assessmentReferences);
      setAssessors(assessorResponse.payload);
    });
  }, [builderInfo.Builder_ID, setAssessors]);

  useEffect(function fetchLazyLoadedData() {
    if (!builderInfo.Builder_ID) {
      return;
    }
    return setStateFetchEffect(
      RiskAssessmentService.getAssessmentChecklistItemsByBuilderID(
        builderInfo.Builder_ID
      ),
      ([checklistResponse]) => {
        setChecklistItems(checklistResponse.payload)
      }
    );
  }, [builderInfo.Builder_ID]);

  // Todo: Even after the last refactor,
  //   this (and assessment roadmaps in general) need a serious refactor.
  useEffect(function fetchRequiredRoadmapData() {
    return setStateFetchEffect(
      RiskAssessmentService.getAssessmentRoadmapsByBuilderID(
        builderInfo.Builder_ID
      ),
      ([roadmapItemsResponse]) => {
        const normalizedItems = roadmapItemsResponse.payload.map((item) => {
          const taskList = [
            ...(item.FindingRecommendationTasks || []),
            ...(item.RoadmapRelatedTasks || [])
          ];
          const workpackagesList = [
            ...(item.FindingRecommendationWorkpackages || []),
            ...(item.RoadmapRelatedWorkpackages || [])
          ];
          // Todo: Why would duplicate items be included in server response?
          const taskIdSet = new Set();
          const workpackageIdSet = new Set();
          const filteredTasks = taskList.filter(task => {
            const isNotDuplicate = taskIdSet.add(task.Task_ID);
            return isNotDuplicate;
          })
          const filteredWorkpackages = workpackagesList.filter(workpackage => {
            const isNotDuplicate = workpackageIdSet.add(workpackage.Workpackage_ID);
            return isNotDuplicate
          });
          const normalizedWorkpackages = filteredWorkpackages.map(workpackage => ({
            ...workpackage,
            Name: workpackage.Workpackage_Name,
            type: "workpackage"
          }));
          const normalizedTask = filteredTasks.map(task => ({
            ...task,
            Name: task.Task_Name,
            type: "task",
            description: task.Task_Description,
            Proposed_Start: task.Task_ProposedStart,
            Proposed_Completion: task.Task_ProposedCompletion,
            Status: task.Task_Status,
            Actual_Start: task.Task_ActualStart,
            Actual_Completion: task.Task_ActualCompletion,
            Type : task.Task_Type,
            Assigned_User: task.Task_AssignedUser,
            Workpackages_Workpackage_ID: task.Task_WorkpackageID
          }));
          return {
            ...item,
            Tasks: normalizedWorkpackages.concat(normalizedTask),
            Description: getAssessmentItemName(item.Description)
          };
        })
        setRoadmapItems(normalizedItems);
      }
    );
  }, [builderInfo.Builder_ID, onSuccessVariable]);


  const assessmentItem = useMemo(() => (
    assessmentItems?.find(item => item.AssessmentItem_ID === parseInt(activeId, 10))
  ), [assessmentItems, activeId]);

  const setReviewPageMode = useCallback((pageMode, itemId, reviewId = null, replaceHistory = false) => {
    setMode("review", builderInfo, false);
    let path = `${basePath}/${pageMode}`;
    if (pageMode === "roadmaps") {
      setOnSuccessVariable({"activateRoadmap":"activateRoadmap"})
    }
    if (DATA_COLLECTION_EDIT_MODES.includes(pageMode)) {
      path += `/${itemId}/${reviewId || "new"}`;
    } else if (pageMode === reviewPageModes.ROADMAP_EDIT) {
      path += `/${itemId}`;
    }
    if (replaceHistory) {
      history.replace(path);
    } else {
      history.push(path);
    }
  }, [setMode, basePath, builderInfo, history]);

  if (!assessmentItems) {
    return <Loader />;
  }

  return (
    <div className={classNames(classes.layoutContainer)}>
      <Switch>
        <Route exact path={basePathTemplate}>
          <ReviewItemsPage
            assessmentItems={assessmentItems}
            assessors={assessors}
            buildAssessmentData={buildAssessmentData}
            builderInfo={builderInfo}
            designItems={designItems}
            performanceItems={performanceItems}
            recordFormData={recordFormData}
            references={references}
            setAssessors={setAssessors}
            setBuilderInfo={setBuilderInfo}
            setMode={setMode}
            setReviewPageMode={setReviewPageMode}
            summaryRecommendations={summaryRecommendations}
            withUnsavedChanges={withUnsavedChanges}
            setHasUnsavedChanges={setHasUnsavedChanges}
          />
        </Route>
        <Route path={`${basePathTemplate}/${reviewPageModes.DESIGN_DATA_COLLECTION}/:assessmentItemId/:activeId`}>
          <DesignDataCollectionPage
            assessmentItem={assessmentItem}
            builder={builderInfo}
            checklistItems={checklistItems}
            designItems={designItems}
            references={references}
            setDesignItems={setDesignItems}
            setMessage={setMessage}
            setReferences={setReferences}
            setReviewPageMode={setReviewPageMode}
            setHasUnsavedChanges={setHasUnsavedChanges}
            hasUnsavedChanges={hasUnsavedChanges}
          />
        </Route>
        <Route path={`${basePathTemplate}/${reviewPageModes.PERFORMANCE_DATA_COLLECTION}/:assessmentItemId/:activeId`}>
          <PerformanceDataCollectionPage
            assessmentItem={assessmentItem}
            builder={builderInfo}
            checklistItems={checklistItems}
            performanceItems={performanceItems}
            references={references}
            setMessage={setMessage}
            setPerformanceItems={setPerformanceItems}
            setReferences={setReferences}
            setReviewPageMode={setReviewPageMode}
            setHasUnsavedChanges={setHasUnsavedChanges}
            hasUnsavedChanges={hasUnsavedChanges}
          />
        </Route>
        <Route path={`${basePathTemplate}/${reviewPageModes.SUMMARY_RECOMMENDATION}`}>
          <SummaryRecommendation
            setMode={setMode}
            builderInfo={builderInfo}
            designItems={designItems}
            performanceItems={performanceItems}
            summaryRecommendations={summaryRecommendations}
            setSummaryRecommendations={setSummaryRecommendations}
            setHasUnsavedChanges={setHasUnsavedChanges}
          />
        </Route>
        <Route path={`${basePathTemplate}/${reviewPageModes.SUMMARY_RESPONSES}`}>
          <SummaryResponsePage
            setMode={setMode}
            builderInfo={builderInfo}
            assessmentItems={assessmentItems}
            designItems={designItems}
            performanceItems={performanceItems}
            summaryRecommendations={summaryRecommendations}
            references={references}
            setSummaryRecommendations={setSummaryRecommendations}
            setDesignItems={setDesignItems}
            setPerformanceItems={setPerformanceItems}
            setReviewPageMode={setReviewPageMode}
            setHasUnsavedChanges={setHasUnsavedChanges}
          />
        </Route>
        <Route path={`${basePathTemplate}/${reviewPageModes.ROADMAP_TABLE}`}>
          <RoadmapItemsPage
            setMode={setMode}
            setBuilderInfo={builderInfo}
            builderInfo={builderInfo}
            setReviewPageMode={setReviewPageMode}
            roadmapItems={roadmapItems}
            setRoadmapItems={setRoadmapItems}
            setOnSuccessVariable={setOnSuccessVariable}
            onSuccessVariable={onSuccessVariable}
            activeRoadmapItem={activeRoadmapItem}
            setActiveRoadmapItem={setActiveRoadmapItem}
          />
        </Route>
        <Route path={`${basePathTemplate}/${reviewPageModes.ROADMAP_EDIT}/:roadmapId`}>
          {roadmapItems ? (
            <RoadmapEdit
              setMessage={setMessage}
              roadmapItems={roadmapItems}
              setReviewPageMode={setReviewPageMode}
              setRoadmapItems={setRoadmapItems}
            />
          ) : (
            <Loader />
          )}
        </Route>
      </Switch>
    </div>
  )
}

export default AssessmentReview;
