import FormatListNumberedOutlinedIcon from "@material-ui/icons/FormatListNumberedOutlined";
import ButtonDefault from "components/utils/buttonDefault.component";
import classNames from "classnames";
import { useState } from "react";
import CustomModal from "components/utils/modal.component";
import { makeStyles } from "@material-ui/core";
import ReorderList from "./sections/reorderList.component";
import CustomLink from "components/utils/link.component";

const useStyles = makeStyles((theme) => ({
  //BUTTON
  buttonIcon: {
    marginRight: 6,
    fontSize: 12,
  },
  darkButtonIcon: {
    color: "white",
  },
  linkBarIcon: {
    fontSize: "20px",
    marginRight: 6,
  },
}));

const ReorderListModalButton = ({
  list,
  orderFieldName,
  displayFieldName,
  primaryKey,
  saveFunction,
  disabled,
  isReordering,
  setIsReordering,
  linkBar,
}) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  const openReorderForm = () => {
    setOpenModal(true);
    setIsReordering(false);
  };

  return (
    <>
      {linkBar ? (
        <CustomLink
          onClick={openReorderForm}
          variant="linkBarDark"
          startIcon={
            <FormatListNumberedOutlinedIcon
              className={classes.linkBarIcon}
            />
          }
          test="ReorderList"
        >
          Reorder List
        </CustomLink>
      ) : (
        <ButtonDefault
          className={classes.questionButton}
          onClick={openReorderForm}
          startIcon={
            <FormatListNumberedOutlinedIcon
              className={classNames(classes.buttonIcon, classes.darkButtonIcon)}
            />
          }
          background="primary"
          variant="small"
          disabled={disabled}
        >
          Reorder List
        </ButtonDefault>
      )}

      <CustomModal open={openModal} onClose={() => setOpenModal(false)}>
        <ReorderList
          closeForm={() => setOpenModal(false)}
          list={list}
          orderFieldName={orderFieldName}
          displayFieldName={displayFieldName}
          primaryKey={primaryKey}
          saveFunction={saveFunction}
          isSaving={isReordering}
        />
      </CustomModal>
    </>
  );
};
export default ReorderListModalButton;
