import { makeStyles } from "@material-ui/core";
import { AddCircleOutlineOutlined } from "@material-ui/icons";
import classNames from "classnames";
import CustomLink from "components/utils/link.component";
import { useCallback, useMemo } from "react";
import riskAssessmentService from "services/riskAssessment.service";
import { reviewPageModes } from "utils/assessmentConstants";
import { isReadOnly } from "utils/roles";
import styleVariables from "styleVariables";

const useStyles = makeStyles(() => ({
  roadmapLink: {
    color: styleVariables.primaryLight,
    "&:hover": {
      color: styleVariables.primaryDark,
    },
  },
  startIcon: {
    height: 12,
    width: 12,
    marginRight: 2,
    verticalAlign: "-1px",
  },
}));


export default function AddRoadmapItemLink(props) {
  const classes = useStyles();
  const { label, replaceRoadmapItem, index, item, setReviewPageMode } = props;
  const readOnly = useMemo(isReadOnly, []);

  const handleClick = useCallback(async () => {
    const { Roadmap_ID } = item;
    if (Roadmap_ID) {
      setReviewPageMode(reviewPageModes.ROADMAP_EDIT, Roadmap_ID);
    } else {
      const response = (
        await riskAssessmentService.initializeAssessmentRoadmapViewItem(item)
      );
      replaceRoadmapItem(response.payload, index);
    }
  }, [index, item, replaceRoadmapItem, setReviewPageMode]);

  if (readOnly && !label) {
    return "--";
  }
  return (
    <CustomLink
      variant="customUnderline"
      className={classes.roadmapLink}
      onClick={handleClick}
      ignoreReadOnly
    >
      {label || (
        readOnly ? "--" : (
          <>
            <AddCircleOutlineOutlined
              className={classNames(classes.startIcon)}
            />
            Add
          </>
        )
      )}
    </CustomLink>
  )
}
