import { TableRow } from "@material-ui/core";
import TableCell from "components/utils/tables/shared/tableCell.component";
import {makeStyles} from "@material-ui/core";
import classNames from "classnames";
import { useCallback, useMemo } from "react";
import PortfolioService from "services/portfolio.service";
import { currentUserID } from "utils/userHelpers";
import { ACTION_REPLACE_RECONCILE_SESSION_NOTES, ACTION_REPLACE_RECONCILE_ASSESMENT_ITEM } from "reducers/portfolioAssessments.reducer";
import TextFieldPlain from "components/utils/form-elements/textFieldPlain.component";
import useNumericParams from "hooks/useNumericParams";
import styleVariables from "styleVariables";

const useStyles = makeStyles((theme) => ({
  finalCell: {
    backgroundColor: "#92aad4",
    color: "#ffffff",
  },
  cell: {
    borderCollapse: "collapse",
    borderRight: `1px solid ${theme.palette.border.main}`,
    borderLeft: `1px solid ${theme.palette.border.main}`,
  },
  personalCell: {
    backgroundColor: styleVariables.grayTint,
  },
  reconcileHeading: {
    fontWeight: 600
  },
  reconcileBlock: {
    display: "inline-block"
  },
  notesCell: {
    minWidth: "40%",
    whiteSpace: "pre-wrap"
  },
  reporterCell: {
    width: "15%"
  },
  stateCell: {
    width: 120,
    minWidth: "5%",
    paddingTop: 6,
    paddingBottom: 7,
  },
  tableRow: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  inputCellInner: {
    margin: "2px 0 3px",
    paddingTop: 0,
    paddingBottom: 2,
    transition: ".05s ease-out border-bottom, .05s ease-out margin-bottom",
  },
  inputCellInnerEditable: {
    borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
    "&:hover": {
      marginBottom: 2,
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
    "&:focus": {
      marginBottom: 2,
      borderBottom: `2px solid #164C9D`,
    }
  },
  inputCellInnerReadOnly: {
    cursor: "text"
  },
  inputCellField: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  fieldInput: {
    minHeight: 24,
    paddingTop: 2,
    paddingBottom: 0,
    fontSize: 14
  },
}));
export default function ReconcileInputRow(props) {
  const classes = useStyles();
  const {
    assessmentItem, descriptionCell, dispatch, isParticipant, readOnly, sessionNote
  } = props;
  const params = useNumericParams();
  const { sessionId, portCoId } = params;
  const userId = useMemo(currentUserID, []);

  const isReconciled = useMemo(() => (
    !isParticipant &&
    !!sessionNote?.Assessor_UserName &&
    (!!sessionNote.State || !!sessionNote.Description)
  ), [isParticipant, sessionNote]);

  const handleAutosave = useCallback(async (name, value) => {
    const sessionNoteId = assessmentItem?.SessionNote_ID;

    if ((!value && !sessionNoteId) || value === sessionNote?.[name]) {
      return;
    }

    let noteData = {
      AssessorUser_ID: userId,
      [name]: value,
      Status: "Active",
      AssessmentTemplate_ID: assessmentItem.AssessmentTemplate_ID
    };

    if (sessionNoteId) {
      noteData = {
        ...noteData,
        SessionNote_ID: sessionNoteId,
      }
    }

    const notesResponse = await PortfolioService.upsertNote(
      sessionId, assessmentItem.Item_ID, noteData
    );

    if (!sessionNoteId) {
      const assessmentItemData = {
        Item_ID: assessmentItem.Item_ID,
        SessionNote_ID: notesResponse?.payload?.SessionNote_ID,
      }

      const assessmentItemResponse = await PortfolioService.upsertReconciledItem(
        assessmentItem.HolderAssessment_ID, portCoId, assessmentItemData
      );
      dispatch({ type: ACTION_REPLACE_RECONCILE_ASSESMENT_ITEM, payload: assessmentItemResponse.payload[0]});
    } else {
      dispatch({ type: ACTION_REPLACE_RECONCILE_SESSION_NOTES, payload: notesResponse.payload});
    }

  }, [assessmentItem, dispatch, userId, sessionNote, sessionId, portCoId]);

  const handleKeyDown = useCallback(event => {
    switch (event.key) {
      case "Escape":
        return event.target.blur();
      case "Return":
      default:
    }
  }, []);

  return (
    <TableRow hover={false} className={classes.tableRow} padding="none" data-cy={`reconcile-item-${assessmentItem.Item_ID}-row-reconciled-${isReconciled}`}>
      {descriptionCell}
      {isParticipant ? (
        <>
          <TableCell
            className={
              classNames(classes.cell, classes.personalCell, classes.reporterCell)
            }
          >
            {sessionNote.Assessor_UserName}
          </TableCell>
          <TableCell
            className={
              classNames(classes.cell, classes.personalCell, classes.stateCell)
            }
          >
            {sessionNote.State}
          </TableCell>
          <TableCell
            className={
              classNames(classes.cell, classes.personalCell, classes.notesCell)
            }
          >
            {sessionNote.Description}
          </TableCell>
        </>
      ) : (
        <>
          <TableCell
            className={classNames(classes.cell, classes.reporterCell, classes.finalCell)}
          >
            {isReconciled ? (
              <span className={classes.userIconLine} data-cy="reconciliation-owner">
                <div
                  className={
                    classNames(classes.reconcileHeading, classes.reconcileBlock)
                  }
                >
                  Reconciled by:&nbsp;
                </div>
                <div className={classes.reconcileBlock}>
                  {sessionNote.Assessor_UserName}
                </div>
              </span>
            ) : (
              <span className={classes.reconcileHeading}>Reconcile</span>
            )}
          </TableCell>
          <TableCell
            className={classNames(classes.cell, classes.stateCell, classes.finalStateAndNotesCell)}
          >
            <TextFieldPlain
              id={`input-${assessmentItem.Item_ID}-state`}
              test={`reconcile-input-${assessmentItem.Item_ID}-state`}
              className={classes.inputCellField}
              name={`input-${assessmentItem.Item_ID}-state`}
              defaultValue={sessionNote?.State}
              onAutosave={(_name, value) => (
                handleAutosave("State", value)
              )}
              onKeyDown={handleKeyDown}
              margin="dense"
              variant="noLabel"
              inputProps={{
                className: classes.fieldInput,
                classes: {
                  input: classNames(
                    classes.inputCellInner,
                    readOnly && classes.inputCellInnerReadOnly,
                    !readOnly && classes.inputCellInnerEditable
                  )
                }
              }}
              readOnly={readOnly}
              fullWidth
              multiline
            />
          </TableCell>
          <TableCell
            className={classNames(classes.cell, classes.notesCell, classes.finalStateAndNotesCell)}
          >
            <TextFieldPlain
              id={`input-${assessmentItem.Item_ID}-description`}
              test={`reconcile-input-${assessmentItem.Item_ID}-description`}
              className={classes.inputCellField}
              name={`input-${assessmentItem.Item_ID}-description`}
              defaultValue={sessionNote?.Description}
              onAutosave={(_name, value) => (
                handleAutosave("Description", value)
              )}
              onKeyDown={handleKeyDown}
              margin="dense"
              variant="noLabel"
              inputProps={{
                className: classes.fieldInput,
                classes: {
                  input: classNames(
                    classes.inputCellInner,
                    readOnly && classes.inputCellInnerReadOnly,
                    !readOnly && classes.inputCellInnerEditable
                  )
                }
              }}
              readOnly={readOnly}
              fullWidth
              multiline
            />
          </TableCell>
        </>
      )}
    </TableRow>
  );
}