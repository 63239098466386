import { useCallback, useMemo, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "components/utils/loader.components";
import { isReadOnly } from "utils/roles";
import RadioInputGroup from "components/utils/form-elements/radioInputGroup.component";
import { makeStyles } from "@material-ui/core";
import styleVariables from "styleVariables";
import classNames from "classnames";
import ReconcileView from "./reconcileView.component";


const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    display: "flex",
    alignItems: "end",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginTop: 16,
  },
  filterRadioGroup: {
    marginTop: -4,
    marginBottom: -6
  },
  filterRadioLabels: {
    fontSize: styleVariables.fontMedium,
  },
  modeTabsWrapper: {
    marginBottom: 16,
  },
  radioColumn: {
    marginBottom: 16,
  },
  radioGroupReadOnly: {
    opacity: 0.5,
    pointerEvents: "none"
  },
  radioLabelReadOnly: {
    color: theme.palette.text.secondary,
  },
  sublabelRadioGroup: {
    marginRight: 48
  },
}));

export const SUBLABEL_SHOW = "show"
export const SUBLABEL_HIDE = "hide"

const sublabelOptions = [
  { value: SUBLABEL_HIDE, label: "Hide" },
  { value: SUBLABEL_SHOW, label: "Show" }
];

const userFilterOptions = [
  { value: "self", label: "Hide" },
  { value: "all", label: "Show" }
];

export default function ReconciliationTablePages(props) {
  const classes = useStyles();
  const {
    basePath,
    categorizedItems = [], // See "Categorized Items Schema" in schema.js
    categoryRefs,
    columns, // See "Columns Schema" in schema.js
    filterSublabel = false,
    itemLabel,
    modeTabs,
    onCategoryAutosave,
    onItemAutosave,
    questionRefs,
    sublabelFilterLabel,
    userFilterLabel,
    users,
  } = props;

  const readOnly = useMemo(isReadOnly, []);

  const [sublabelFilter, setSublabelFilter] = useState(SUBLABEL_SHOW);

  const [userFilter, setUserFilter] = useState(readOnly ? "all" : undefined);

  const renderTableHeaderPanel = useCallback(isReconcilePage => (
    <div className={classes.headerWrapper}>
      <div className={classes.radioColumn}>
        {!!filterSublabel && (
          <RadioInputGroup
            value={sublabelFilter}
            groupLabel={sublabelFilterLabel}
            options={sublabelOptions}
            onChange={event => setSublabelFilter(event.target.value)}
            radioGroupClass={
              classNames(classes.filterRadioGroup, classes.sublabelRadioGroup)
            }
            radioLabelClass={classes.filterRadioLabels}
            hideHelperText
            row
          />
        )}
        <RadioInputGroup
          value={isReconcilePage ? "all" : (userFilter || "self")}
          groupLabel={userFilterLabel}
          options={userFilterOptions}
          onChange={event => setUserFilter(event.target.value)}
          radioGroupClass={
            classNames(
              classes.filterRadioGroup,
              isReconcilePage && classes.radioGroupReadOnly)
          }
          radioLabelClass={
            classNames(
              classes.filterRadioLabels,
              isReconcilePage && classes.radioLabelReadOnly
            )
          }
          readOnly={isReconcilePage}
          hideHelperText
          row
        />
      </div>
      {!!modeTabs && (
        <div className={classes.modeTabsWrapper}>
          {modeTabs}
        </div>
      )}
    </div>
  ), [
    classes, filterSublabel, modeTabs, setSublabelFilter, setUserFilter,
    sublabelFilter, sublabelFilterLabel, userFilter, userFilterLabel
  ]);

  const filteredCategorizedItems = useMemo(() => {
    if (sublabelFilter === SUBLABEL_HIDE) {
      return categorizedItems.map(itemGroup => ({
        ...itemGroup,
        categoryItem: {
          ...itemGroup.categoryItem,
          sublabel: null
        },
        items: itemGroup.items.map(item => ({
          ...item,
          sublabel: null,
        }))
      }))
    }
    return categorizedItems;
  }, [categorizedItems, sublabelFilter]);

  if (!filteredCategorizedItems) {
    return <Loader />
  }
  /*
   * TODO:
   * Combine Routes to use :mode param,
   *   so that components aren't re-mounted when switching
  */
  return (
    <Switch>
      <Route
        path={`${basePath}/reconcile`}
        exact
      >
        <ReconcileView
          categorizedItems={filteredCategorizedItems}
          columns={columns}
          itemLabel={itemLabel}
          onCategoryAutosave={onCategoryAutosave}
          onItemAutosave={onItemAutosave}
          categoryRefs={categoryRefs}
          questionRefs={questionRefs}
          setUserFilter={setUserFilter}
          sublabelFilter={sublabelFilter}
          tableHeaderPanel={renderTableHeaderPanel(true)}
          userFilter={userFilter}
          users={users}
          isReconcile
        />
      </Route>
      <Route
        path={`${basePath}/personal`}
        exact
      >
        <ReconcileView
          categorizedItems={filteredCategorizedItems}
          columns={columns}
          itemLabel={itemLabel}
          onCategoryAutosave={onCategoryAutosave}
          onItemAutosave={onItemAutosave}
          categoryRefs={categoryRefs}
          questionRefs={questionRefs}
          setUserFilter={setUserFilter}
          sublabelFilter={sublabelFilter}
          tableHeaderPanel={renderTableHeaderPanel(false)}
          userFilter={userFilter}
          userFilterLabel={userFilterLabel}
          users={users}
        />
      </Route>
      <Redirect
        from={basePath}
        to={`${basePath}/personal`}
        exact
      />
    </Switch>
  )
}
