import ProgramsContext from "contexts/programs.context";
import useNumericParams from "hooks/useNumericParams";
import useReducerAsync from "hooks/useReducerAsync";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import programReducer, { ACTION_FULL_REPLACE_COMPONENT_OBJECTS, ACTION_REPLACE_PROGRAM_COMPONENTS, ACTION_SET_PROGRAMS, ACTION_SET_VALID_COMPONENT_OBJECT_IDS, ACTION_SET_VALID_PROGRAM_COMPONENT_IDS, programInitialState } from "reducers/global/program.reducer";
import { batchDispatch } from "reducers/utils/dispatchUtils";
import ProgramService from "services/program.service";
import { setStateFetchEffect } from "utils/ajaxHelpers";
import { PROGRAM_ID_ORCHESTRATION } from "utils/programConstants";

/*
 * Loads and provides Program reducer as global context for app
 */
export default function ProgramContextProvider(props) {
  const history = useHistory();
  const {
    programId, programComponentId, componentObjectId
  } = useNumericParams(true);
  const { children } = props;

  const requestedProgramIds = useRef(new Set());
  const requestedProgramComponentIds = useRef(new Set());

  const [state, dispatch] = useReducerAsync(programReducer, programInitialState);

  const {
    componentObjectsByComponent,
    programs,
    programComponentsForProgram,
    validComponentObjectIds,
    validProgramIds,
    validProgramComponentIds,
    fullyLoadedProgramComponents,
  } = state;

  const isValidIdsAvailable = useMemo(() => (
    !!validComponentObjectIds &&
    validProgramIds.size > 0 &&
    !!validProgramComponentIds
  ), [validComponentObjectIds, validProgramIds, validProgramComponentIds])

  const fetchProgramComponentsForProgram = useCallback(async forProgramId => {
    if (
      !forProgramId ||
      programComponentsForProgram?.[forProgramId] ||
      requestedProgramIds.current.has(forProgramId)
    ) {
      return;
    }
    requestedProgramIds.current.add(forProgramId);
    const componentsResponse = (
      await ProgramService.getProgramComponentsByProgramId(forProgramId)
    );
    dispatch({
      type: ACTION_REPLACE_PROGRAM_COMPONENTS,
      payload: componentsResponse.payload
    });
  }, [dispatch, programComponentsForProgram, requestedProgramIds]);

  useEffect(() => (
    function fetchValidIds() {
      if (isValidIdsAvailable) {
        return;
      }
      return setStateFetchEffect(
        [
          ProgramService.getActiveComponentObjectIds(),
          ProgramService.getActiveProgramComponentIds(),
        ],
        responses => (
          batchDispatch(dispatch, responses, [
            ACTION_SET_VALID_COMPONENT_OBJECT_IDS,
            ACTION_SET_VALID_PROGRAM_COMPONENT_IDS
          ])
        )
      );
    }
  )(), [dispatch, isValidIdsAvailable]);

  useEffect(function redirectInvalidProgramCombination() {
    if (!isValidIdsAvailable) {
      return;
    }
    if (!programId) {
      return;
    }
    if (validProgramIds.has(programId)) {
      if (!programComponentId) {
        return;
      }
      if (validProgramComponentIds[programComponentId] === programId) {
        if (!componentObjectId) {
          return;
        }
        if (validComponentObjectIds[componentObjectId] === programComponentId) {
          return;
        }
      }
    }
    history.push("/notFound");
  }, [
    componentObjectId, history, isValidIdsAvailable, programId,
    programComponentId, validProgramIds, validProgramComponentIds,
    validComponentObjectIds
  ]);

  useEffect(() => (
    async function fetchPrograms() {
      if (programs) {
        return;
      }
      const programsResponse = await ProgramService.getAll();
      dispatch({
        type: ACTION_SET_PROGRAMS,
        payload: programsResponse.payload
      });
    }
  )(), [dispatch, programs]);

  useEffect(function fetchProgramComponents() {
    if (programId !== PROGRAM_ID_ORCHESTRATION) {
      fetchProgramComponentsForProgram(PROGRAM_ID_ORCHESTRATION);
    }
    fetchProgramComponentsForProgram(programId)
  }, [fetchProgramComponentsForProgram, programId]);

  useEffect(() => (
    async function fetchComponentObjects() {
      if (
        !programComponentId ||
        fullyLoadedProgramComponents.has(programComponentId) ||
        requestedProgramComponentIds.current.has(programComponentId)
      ) {
        return;
      }
      requestedProgramComponentIds.current.add(programComponentId);
      const componentObjectResponse = await (
        ProgramService.getProgramComponentObjectsByComponentId(
          programComponentId
        )
      );
      dispatch({
        type: ACTION_FULL_REPLACE_COMPONENT_OBJECTS,
        payload: componentObjectResponse.payload,
      });
    }
  )(), [
    componentObjectsByComponent, dispatch, programComponentId,
    requestedProgramComponentIds, fullyLoadedProgramComponents
  ]);

  return (
    <ProgramsContext.Provider value={{ dispatch, state }}>
      {children}
    </ProgramsContext.Provider>
  )
}