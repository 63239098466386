import CustomLink from "components/utils/link.component";

export default function DateCell(props) {
  const {
    dateFormatted, goToSessionFromSchedule, sessionId, startTime,
    endTime, portCoName, test
  } = props;

  const completed = !!startTime && !!endTime && !!portCoName;

  return (
    <div data-cy={test}>
      {completed ? (
        <CustomLink
          variant="customUnderline"
          onClick={() => goToSessionFromSchedule(sessionId)}
          ignoreReadOnly
          test={test}
        >
          {dateFormatted}
        </CustomLink>
      ) : dateFormatted}
    </div>
  );
}
