import React, { useCallback, useMemo } from "react";
import { Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import classNames from "classnames";
import variables from "styleVariables";
import CustomIcons from "components/utils/icons.component.js";
import CardBackground from "components/utils/cards/cardBackground.component"; // Import using relative path
import AlertIcon from "components/alerts/alertIcon.component";
import { H3, H4 } from "./headerV2.component";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 5,
  },
  cardLinkWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    '&:hover': {
      cursor: 'pointer',
      "& $programHeader": {
        color: variables.secondaryDark,
      },
      "& $programHeaderSmall": {
        color: variables.secondaryDark,
      },
      "& $subtitleText": {
        color: variables.primaryMain,
      },
      "& $dateText": {
        color: variables.primaryMain,
      },
    },
  },
  programWrapper: {
    alignItems: "flex-start",
  },
  programCardContentWrapper: {
    padding: "15px",
    zIndex: 50,
  },
  smallProgramCardContentWrapper: {
    paddingTop: 10,
  },
  card: {
    padding: 10,
    display: "flex",
    alignItems: "center",
    minHeight: 205,
    borderRadius: 10,
    position: "relative",
    '&:hover': {
      boxShadow: "1px 3px 4px -2px rgb(0 0 0 / 20%), 5px 4px 3px -4px rgb(0 122 204 / 20%), 2px 3px 2px 1px rgb(64 145 206 / 20%), 2px 1px 10px 4px rgb(120 197 253 / 30%)",
    },
  },
  flexColumn: {
    flexDirection: "column",
  },
  dashCard: {
    minHeight: 180,
    minWidth: 160,
    width: "100%",
    maxWidth: 210,
    [theme.breakpoints.down('md')]: {
      maxWidth: 160,
    },
  },
  programCard: {
    padding: 0,
  },
  programHeader: {
    lineHeight: "normal",
    color: variables.primaryMain,
    fontSize: variables.fontH3,
    fontWeight: "bold",
  },
  programHeaderSmall: {
    lineHeight: "1.5rem",
    color: variables.primaryMain,
    fontSize: variables.fontXl,
    fontWeight: "bold",
  },
  cardPopOver: {
    padding: 20,
  },
  // CARD CORNER & ICON
  cardCorner: {
    height: 90,
    width: 90,
    display: "flex",
    paddingTop: 12,
    paddingRight: 12,
    borderRadius: "0px 10px 0px 0px",
    position: "absolute",
    right: 0,
    top: 0,
    clipPath: "polygon(0 0, 100% 0, 100% 100%, 50% 50%)",
  },
  customCorner: {
    background: 'linear-gradient(to right top, #001f3e 0%, #007acc 60%, #007acc 100%)',
  },
  builderCorner: {
    background: 'linear-gradient(to right top, #01313b 0%, #00829b 60%, #00829b 100%)',
  },
  documentCorner: {
    background: 'linear-gradient(to right top, #00902b 0%, #379045 60%, #379045 100%)',
  },
  customColor: {
    color: variables.secondaryDark,
  },
  builderColor: {
    color: variables.tertiary2Dark,
  },
  documentColor: {
    color: variables.tertiary3,
  },
  iconWrapper: {
    height: 24,
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "auto",
  },
  icon: {
    fill: "white",
  },
  cardHeaderWrapper:  {
    maxWidth: 148,
    display: "block",
  },
  cardHeaderProgramWrapper:  {
    maxWidth: 130,
    display: "block",
  },
  cardHeaderProgramDashWrapper:  {
    maxWidth: 90,
    display: "block",
  },
  cardPopOverHeaderWrapper:  {
    maxWidth: "100%",
    display: "block",
  },
  subtitleText: {
    fontSize: variables.fontXs,
    color: variables.secondaryDark,
    fontWeight: "bold",
  },
  dateText: {
    fontWeight: "bold",
    fontSize: variables.fontXs,
    color: variables.secondaryDark,
    paddingTop: 10,
  },
  descriptionText: {
    fontSize: variables.fontSmall,
    color: variables.textSecondary,
    paddingTop: 5,
  },
  // DISABLED STYLING
  disabled: {
    backgroundColor: variables.grayDisabledBg,
    position: "relative",
    "&:hover": {
      cursor: "default",
    },
  },
  linkDisabled: {
    "&:hover": {
      cursor: "default",
    },
  },
  textDisabled: {
    color: "white",
  },
  disabledBackground: {
    opacity: ".2",
    '&::after': {
      content: '""',
      background: "linear-gradient(180deg, rgba(209, 212, 215,1) 0%, rgba(209, 212, 215,0.7) 49%, rgba(209, 212, 215,0.3) 100%)",
      display: 'block',
      width: "100%",
      height: "100%",
    },
  },
  disabledCardCorner: {
    backgroundColor: variables.grayPrimary,
    background: "unset",
    opacity: ".5"
  },
  disabledOverlay: {
    position: "absolute",
    display: "flex",
    height: "100%",
    width: "100%",
    zIndex: "100",
    backgroundColor: variables.textSecondary,
    opacity: ".2",
    mixBlendMode: "darken",
    alignItems: "center",
    left: 0,
    top: 0,
  },
  fullBackground: {
    height: "70%",
  },
  dashBackground: {
    height: "90%",
  },
  progPageBackground: {
    height: "80%",
    opacity: 0.9
  },
  alertContainer: {
    position: "absolute",
    right: 10,
    bottom: 10,
    height: 15,
    width: 20,
    fill: variables.warningMain,
  },
}));

export const VARIANT_LINK = "link";
export const VARIANT_LINK_PLAIN = "linkPlain";
export const VARIANT_DISABLED = "disabled";
export const VARIANT_PLAIN = "plain";
export const VARIANT_PROGRAM = "program";
export const VARIANT_PROGRAM_DISABLED = "programDisabled";
export const VARIANT_DASHBOARD_PROGRAM = "dashboardProgram";
export const VARIANT_DASHBOARD_PROGRAM_DISABLED = "dashboardProgramDisabled";


const CustomCard = (props) => {
  const classes = useStyles();
  const {
    children, date, elevation, icon, onClick, raised, test,
    title, type, url, variant
  } = props;

  switch(variant) {
    case VARIANT_LINK:
      if (onClick) {
        return (
          <Card elevation={3} className={classes.card}>
            <div className={classes.cardCorner}>
              <div className={classes.iconWrapper}>
                <CustomIcons variant={icon} className={classes.icon} />
              </div>
            </div>
            <Link
              className={classes.cardLinkWrapper}
              to={url}
              onClick={onClick}
            >
              <CardContent {...props} />
            </Link>
          </Card>
        );
      } else {
        return (
          <Card elevation={3} className={classes.card}>
            <div
              className={
                classNames(
                  classes.cardCorner,
                  type === "Custom" && classes.customCorner,
                  type === "Builder" && classes.builderCorner,
                  type === "Document" && classes.documentCorner,
                )
              }
            >
              <div className={classes.iconWrapper}>
                <CustomIcons
                  variant={`component-${type}`}
                  className={classes.icon}
                />
              </div>
            </div>
            <Link
              className={classes.cardLinkWrapper}
              to={url}
              data-cy={`card-link-${title}`}
            >
              <CardContent {...props} />
            </Link>
          </Card>
        );
      }
    case VARIANT_LINK_PLAIN:
      return (
        <Card elevation={3} className={classes.card}>
          <Link className={classes.cardLinkWrapper} to={url} onClick={onClick}>
            <CardContent {...props} />
          </Link>
        </Card>
      );
    case VARIANT_DISABLED:
      return (
        <Card
          elevation={3}
          className={classNames(classes.disabled, classes.card)}
        >
          <div
            className={
              classNames(classes.cardCorner, classes.disabledCardCorner)
            }
          >
            <div className={classes.iconWrapper}>
              <CustomIcons variant={icon} className={classes.icon} />
            </div>
          </div>
          <CardContent {...props} />
          <div className={classes.disabledOverlay} />
        </Card>
      );
    case VARIANT_PLAIN:
      return (
        <Card
          raised={raised}
          elevation={elevation || 3}
          className={classNames(classes.card, classes.cardPopOver)}
          data-cy={test ? `card-plain-${test}` : ""}
        >
          <CardContent {...props} />
        </Card>
      );
    case VARIANT_PROGRAM:
      return (
        <Card
          elevation={3}
          className={
            classNames(classes.card, classes.programCard, classes.flexColumn)
          }
        >
          <Link
            className={
              classNames(classes.cardLinkWrapper, classes.programWrapper)
            }
            to={url}
            onClick={onClick}
          >
            <div className={classes.programCardContentWrapper}>
              <CardContentProgram {...props} />
            </div>
            <CardBackground
              variant={icon}
              className={classes.progPageBackground}
            />
          </Link>
          {children}
        </Card>
      );
    case VARIANT_PROGRAM_DISABLED:
      return (
        <Card
          elevation={3}
          className={
            classNames(classes.card, classes.programCard, classes.disabled)
          }
        >
          <div
            className={
              classNames(
                classes.cardLinkWrapper,
                classes.programWrapper,
                classes.linkDisabled
              )
            }
          >
            <div className={classes.programCardContentWrapper}>
              <CardContentProgram {...props} />
            </div>
            <CardBackground
              variant={icon}
              className={classes.disabledBackground}
            />
            <div
              className={
                classNames(
                  classes.disabledOverlay,
                  classes.disabledProgramOverlay
                )
              }
            />
          </div>
        </Card>
      );
    case VARIANT_DASHBOARD_PROGRAM:
      return (
        <Card
          elevation={3}
          className={
            classNames(
              classes.card,
              classes.programCard,
              classes.dashCard,
              classes.flexColumn
            )
          }
        >
          <Link
            className={
              classNames(classes.cardLinkWrapper, classes.programWrapper)
            }
            to={url}
            onClick={onClick}
            data-cy={test ? `link-card-dash-${test}` : ""}
          >
            <div
              className={
                classNames(
                  classes.programCardContentWrapper,
                  classes.smallProgramCardContentWrapper
                )
              }
            >
              <CardContentDashProgram date={date} title={title} />
            </div>
            <CardBackground variant={icon} className={classes.dashBackground} />
          </Link>
          {children}
        </Card>
      );
    case VARIANT_DASHBOARD_PROGRAM_DISABLED:
      return (
        <Card
          elevation={3}
          className={
            classNames(classes.dashCard, classes.programCard, classes.disabled)
          }
        >
          <div
            className={
              classNames(
                classes.cardLinkWrapper,
                classes.programWrapper,
                classes.linkDisabled
              )
            }
          >
            <div
              className={
                classNames(
                  classes.programCardContentWrapper,
                  classes.smallProgramCardContentWrapper
                )
              }
            >
              <CardContentDashProgramDisabled date={date} title={title} />
            </div>
            <CardBackground
              variant={icon}
              className={
                classNames(classes.disabledBackground, classes.fullBackground)
              }
            />
          </div>
        </Card>
      );
    default:
      return (
        <Card elevation={3} className={classes.card}>
          <div className={classes.cardCorner}>
            <div className={classes.iconWrapper}>
              <div className={classes.iconWrapper}>
                <CustomIcons variant={icon} className={classes.icon} />
              </div>
            </div>
          </div>
          <CardContent {...props} />
        </Card>
      );
  }
};

export default CustomCard;


const CardContent = props => {
  const classes = useStyles();
  const {
    alert, children, date, description, id, subtitle, title, type, variant
  } = props;

  const titleElement = useMemo(() => {
    switch (variant) {
      case VARIANT_PLAIN:
        return (
          <div className={classes.cardPopOverHeaderWrapper}>
            <H4>{title}</H4>
          </div>
        );
      case VARIANT_DISABLED:
        return (
          <div className={classes.cardHeaderWrapper}>
            <H4 color="white">{title}</H4>
          </div>
        );
      default:
        return (
          <div className={classNames(classes.cardHeaderWrapper)}>
            <H4
              className={
                classNames(
                  type === "Custom" && classes.customColor,
                  type === "Builder" && classes.builderColor,
                  type === "Document" && classes.documentColor,
                )
              }
              color="inherit"
            >
              {title}
            </H4>
          </div>
        );
    }
  }, [classes, title, type, variant]);

  const makeTextClasses = useCallback(baseClass => (
    classNames(baseClass, variant === VARIANT_DISABLED && classes.textDisabled)
  ), [classes, variant]);

  return (
    <>
      <div className={classes.cardWrapper}>
        {titleElement}
        {!!subtitle && (
          <div className={makeTextClasses(classes.subtitleText)}>
            {subtitle}
          </div>
        )}
        {!!date && (
          <div className={makeTextClasses(classes.dateText)}>
            {date}
          </div>
        )}
        {!!description && (
          <div className={makeTextClasses(classes.descriptionText)}>
            {description}
          </div>
        )}
        {!!id && (
          <div className={makeTextClasses(classes.descriptionText)}>
            {id}
          </div>
        )}
      </div>

      {children}

      <div className={classes.alertContainer}>
        {!!alert && (
          <AlertIcon alertCount={alert} />
        )}
      </div>
    </>
  )
}

const CardContentProgram = props => {
  const classes = useStyles();

  const { date, description, id, subtitle, title, variant } = props;

  const titleElement = useMemo(() => {
    switch (variant) {
      case VARIANT_PLAIN:
        return (
          <div className={classes.cardPopOverHeaderWrapper}>
            <H3>{title}</H3>
          </div>
        );
      case VARIANT_DISABLED:
        return (
          <div className={classes.cardHeaderProgramWrapper}>
            <H3 color="white">{title}</H3>
          </div>
        );
      default:
        return (
          <div className={classes.cardHeaderProgramWrapper}>
            <div className={classes.programHeader}>{title}</div>
          </div>
        );
    }
  }, [classes, title, variant]);

  const makeTextClasses = useCallback(baseClass => (
    classNames(
      baseClass,
      variant === VARIANT_PROGRAM_DISABLED && classes.textDisabled
    )
  ), [classes, variant]);

  return (
    <div className={classes.cardWrapper}>
      {titleElement}
      <div className={makeTextClasses(classes.subtitleText)}>
        {subtitle}
      </div>
      <div className={makeTextClasses(classes.dateText)}>
        {date}
      </div>
      <div className={makeTextClasses(classes.descriptionText)}>
        {description}
      </div>
      <div className={makeTextClasses(classes.descriptionText)}>
        {id}
      </div>
    </div>
  );
}

const CardContentDashProgram = ({ date, title }) => {
  const classes = useStyles();
  return (
    <div className={classes.cardWrapper}>
      <div className={classes.cardHeaderProgramDashWrapper}>
        <div className={classes.programHeaderSmall}>{title}</div>
      </div>
      {!!date && (
        <div className={classes.dateText}>
          {date}
        </div>
      )}
    </div>
  );
};

const CardContentDashProgramDisabled = ({ date, title }) => {
  const classes = useStyles();
  return (
    <div className={classes.cardWrapper}>
      <div className={classes.cardHeaderProgramDashWrapper}>
        <div
          className={
            classNames(
              classes.programHeaderSmall,
              classes.programHeaderDisabled
            )
          }
        >
          {title}
        </div>
      </div>
      <div className={classNames(classes.dateText, classes.textDisabled)}>
        {date}
      </div>
    </div>
  );
};
