import { isDevelopmentEnv } from "core/environment";
import { groupObjectArrayByKey } from "utils/arrayOfObjectsHelpers";

export const ACTION_SET_PROGRAMS = "programs__set";
export const ACTION_SET_VALID_PROGRAM_COMPONENT_IDS = "program_component_ids__valid__set";
export const ACTION_SET_VALID_COMPONENT_OBJECT_IDS = "component_object_ids__valid__set";

export const ACTION_REPLACE_PROGRAM_COMPONENTS = "program_components__replace";
export const ACTION_FULL_REPLACE_COMPONENT_OBJECTS = "component_objects_full__replace";
export const ACTION_PARTIAL_REPLACE_COMPONENT_OBJECTS = "component_objects_partial__replace";

export const ACTION_REPLACE_BUILDER_COMPONENT_OBJECTS = "component_objects__replace";


const replaceToId = (stateItem, action, primaryKey) => {
  const list = [].concat(action.payload);
  const mappedById = Object.fromEntries(
    list.map(item => (
      [item[primaryKey], item]
    ))
  );
  return {
    ...(stateItem || {}),
    ...mappedById
  };
};

const replaceToIdGroup = (stateItem, action, primaryKey, persistExistingData = true) => {
  const list = [].concat(action.payload);
  const groupedPayload = groupObjectArrayByKey(list, primaryKey);
  if (!stateItem) {
    return groupedPayload;
  }
  return Object.entries(groupedPayload).reduce(
    (accumulator, [key, group]) => ({
      ...accumulator,
      [key]: [
        ...(persistExistingData ? stateItem[key] || [] : []),
        ...(group || [])
      ].sort((item1, item2) => (
        item1.Order - item2.Order
      ))
    }),
    stateItem
  );
};

const updateFullyLoadedProgramComponents = (stateList, action) => {
  const parentProgramComponentId =
    action.payload?.[0]?.ProgramComponents_Component_ID;
  if (parentProgramComponentId) {
    stateList.add(parentProgramComponentId);
  }
  return stateList;
};

export const programInitialState = {
  programs: null,
  programComponents: null,
  componentObjects: null,

  programComponentsByProgram: null,
  componentObjectsByComponent: null,
  fullyLoadedProgramComponents: new Set([]),

  validProgramIds: new Set([]),
  validProgramComponentIds: null,
  validComponentObjectIds: null,
};

/*
 * Unlike other reducers, items are mapped by primary key.
 * This is for efficiency since Program data is used so often on each page.
 */
export default function programReducer(state, action) {
  switch (action.type) {
    case ACTION_SET_PROGRAMS:
      return {
        ...state,
        programs: replaceToId(state.programs, action, "Program_ID"),
        validProgramIds: new Set(
          action.payload
            .filter(program => program.Status === "Active")
            .map(program => program.Program_ID)
        ),
        fullyLoadedProgramComponents: new Set([]), //THIS IS ASSUMING THE ACTION_SET_PROGRAMS will only be called once per login
      };
    case ACTION_SET_VALID_PROGRAM_COMPONENT_IDS:
      return {
        ...state,
        validProgramComponentIds: Object.fromEntries(action.payload)
      };
    case ACTION_SET_VALID_COMPONENT_OBJECT_IDS:
      return {
        ...state,
        validComponentObjectIds: Object.fromEntries(action.payload)
      };
    case ACTION_REPLACE_PROGRAM_COMPONENTS:
      return {
        ...state,
        programComponents: replaceToId(
          state.programComponents, action, "Component_ID"
        ),
        programComponentsByProgram: replaceToIdGroup(
          state.programComponentsByProgram, action, "Program_Program_ID"
        )
      };
    case ACTION_FULL_REPLACE_COMPONENT_OBJECTS:
      return {
        ...state,
        componentObjects: replaceToId(
          state.componentObjects, action, "ComponentObject_ID"
        ),
        componentObjectsByComponent: replaceToIdGroup(
          state.componentObjectsByComponent,
          action,
          "ProgramComponents_Component_ID",
          false
        ),
        fullyLoadedProgramComponents:
          updateFullyLoadedProgramComponents(
            state.fullyLoadedProgramComponents,
            action
          ),
      };
    case ACTION_PARTIAL_REPLACE_COMPONENT_OBJECTS:
      return {
        ...state,
        componentObjects: replaceToId(
          state.componentObjects, action, "ComponentObject_ID"
        ),
        componentObjectsByComponent: replaceToIdGroup(
          state.componentObjectsByComponent,
          action,
          "ProgramComponents_Component_ID",
        ),
      };
    default:
      if (isDevelopmentEnv) {
        throw new Error(
          `Unrecognized action in ProgramReducer: "${action.type}"`
        )
      }
      return state;
  }
}
