
export const PATH_COMPONENT_OBJECT = "/program/:programId/:programComponentId/:componentObjectId";
export const PATH_PROGRAM_COMPONENT = "/program/:programId/:programComponentId";
export const PATH_PROGRAM = "/program/:programId";
export const PATH_PROGRAM_ROOT = "/program";

// Program paths for Portfolio
export const PATH_PORTFOLIO_PROGRAM = (
  "/program/:programId/holding/:holdingId"
);
export const PATH_PORTFOLIO_PROGRAM_PORTCO = (
  "/program/:programId/holding/:holdingId/portCo/:portCoId"
);
export const PATH_PORTFOLIO_PROGRAM_COMPONENT = (
  "/program/:programId/:programComponentId/holding/:holdingId"
);
export const PATH_PORTFOLIO_PROGRAM_COMPONENT_PORTCO = (
  "/program/:programId/:programComponentId/holding/:holdingId/portCo/:portCoId"
);
export const PATH_PORTFOLIO_COMPONENT_OBJECT = (
  "/program/:programId/:programComponentId/:componentObjectId/holding/:holdingId/portCo/:portCoId"
);
export const PATH_PORTFOLIO_REPORT = (
  "/program/:programId/:programComponentId/:componentObjectId/holding/:holdingId/portCo/:portCoId/report/:reportId"
);

export const AUTH_ROUTES_HOME = [
  "/home"
];

export const AUTH_ROUTES_ADMIN = [
  "/admin/program/:programId",
  "/admin"
];

export const AUTH_ROUTES_SEARCH = [
  "/search/program/:programId",
  "/search/program/:programId/:programComponentId",
  "/search/:programId/search",
  "/search/:programId/:programComponentId/search",
  "/search"
];

export const AUTH_ROUTES_TASKS = [
  "/programTasks/:taskId/program/:programId",
  "/wpTasks/:wpId/program/:programId",
  "/programTasks",
  "/wpTasks"
];

export const AUTH_ROUTES_PROGRAMS = [
  "/program/:programId/:programComponentId/requirementForm/new",
  "/program/:programId/:programComponentId/requirementForm/:requirementId",
  PATH_PORTFOLIO_REPORT,
  PATH_PORTFOLIO_PROGRAM_COMPONENT_PORTCO,
  PATH_PORTFOLIO_PROGRAM_COMPONENT,
  "/program/:programId/:programComponentId/runbook/:runbookId",
  PATH_PORTFOLIO_COMPONENT_OBJECT,
  PATH_PORTFOLIO_PROGRAM_PORTCO,
  PATH_PORTFOLIO_PROGRAM,
  "/program/:programId/:programComponentId/manual/:manualId",
  "/program/:programId/:programComponentId/dct-category/:dctCategoryId/:dctElementEnumeration/:mode",
  "/program/:programId/:programComponentId/reports/:dctCategoryId/:dctReportEnumeration/:mode",
  "/program/:programId/:programComponentId/:componentObjectId/assessment/:builderId/:mode/step/:stepId",
  "/program/:programId/:programComponentId/:componentObjectId/assessment/:builderId/:mode/:subMode/:activeId",
  "/program/:programId/:programComponentId/:componentObjectId/assessment/:builderId/:mode/:subMode",
  "/program/:programId/:programComponentId/:componentObjectId/assessment/:builderId/:mode",
  "/program/:programId/:programComponentId/:componentObjectId/assessment/:builderId",
  "/program/:programId/:programComponentId/:componentObjectId/:mode",
  PATH_COMPONENT_OBJECT,
  PATH_PROGRAM_COMPONENT,
  PATH_PROGRAM,
  PATH_PROGRAM_ROOT
];

export const AUTH_ROUTES_ALL = [
  ...AUTH_ROUTES_HOME,
  ...AUTH_ROUTES_ADMIN,
  ...AUTH_ROUTES_SEARCH,
  ...AUTH_ROUTES_TASKS,
  ...AUTH_ROUTES_PROGRAMS
];
