import { createTheme as muiCreateTheme, unstable_createMuiStrictModeTheme } from "@material-ui/core";
import { isDevelopmentEnv } from "./environment";

export default function createTheme(...args) {
  if (isDevelopmentEnv) {
    /*
     * Mui's workaround for React 18 console errors.
     * Errors are logged only in React's StrictMode, which is disabled in production.
     *
     * See https://github.com/mui/material-ui/issues/20561
     */
    return unstable_createMuiStrictModeTheme(...args);
  } else {
    return muiCreateTheme(...args);
  }
}
