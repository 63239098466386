import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import variables from "styleVariables";
import ButtonDefault from "components/utils/buttonDefault.component";
import moment from "moment";
import BackupRoundedIcon from "@material-ui/icons/BackupRounded";
import { downloadFile } from "utils/downloadFile";
import CustomModal from "components/utils/modal.component";
import UploadGenericDocumentForm from 'components/forms/uploadGenericDocumentForm.component';
import { Edit } from '@material-ui/icons';
import CustomLink from '../link.component';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { COMPONENT_OBJECT_PORTFOLIO_DOC_LIBRARY_TYPEREF, PROGRAM_ID_PORTFOLIO } from 'utils/programConstants';
import PortfolioService from 'services/portfolio.service';
import DataTable from './dataTable.component';
import {
  createNodeCellParams,
  createNodeHeaderParams,
  createValueHeaderParams
} from './utils/dataTableHelpers';
import { DATE_FORMAT_DISPLAY_NUMERIC } from 'utils/dateConstants';

const useStyles = makeStyles((theme) => ({
  uploadIconSmall: {
    fontSize: variables.fontMedium,
    marginRight: 6,
    color: variables.secondaryMain,
  },
  buttonContainerTop: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: 20,
    position: "absolute",
    right: 0,
    top: 175,
    zIndex: 101, //PortCoCard is set at 100
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
      marginBottom: 30,
    },
  },
  link: {
    color: variables.secondaryDark,
    fill: variables.secondaryDark,
    "&:hover": {
      color: variables.primaryMain,
      fill: variables.primaryMain,
      cursor: "pointer",
    },
    "& $downloadFileIcon": {
      color: "inherit",
    },
  },
  downloadFileIcon: {
    maxHeight: 10,
    maxWidth: 10,
    marginLeft: "-2px",
    marginRight: 2,
  },
}));

const defaultCellStyle = { textAlign: "center" };

const TABLE_HEADERS = [
  createNodeHeaderParams("Name", "Name", {
    filter: false,
    sort: true,
  }),
  createValueHeaderParams("Description", "Description", {
    filter: false,
    sort: true,
  }),
  createNodeHeaderParams("Date", "Date", {
    filter: false,
    sort: true,
    setCellProps: () => ({ style: { ...defaultCellStyle } }),
  }),
  createNodeHeaderParams("Action", "Action", {
    filter: false,
    sort: false,
    setCellProps: () => ({ style: { ...defaultCellStyle } }),
  })
];

const PORTFOLIO_TABLE_HEADERS = [
  createNodeHeaderParams("Name", "Name", {
    filter: false,
    sort: true,
  }),
  createValueHeaderParams("Description", "Description", {
    filter: false,
    sort: true,
  }),
  createNodeHeaderParams("Date", "Date", {
    filter: false,
    sort: true,
    setCellProps: () => ({ style: { ...defaultCellStyle } }),
  }),
  createValueHeaderParams("PortCo", "PortCo", {
    filter: true,
    sort: true,
    setCellProps: () => ({ style: { ...defaultCellStyle } }),
  }),
  createNodeHeaderParams("Action", "Action", {
    filter: false,
    sort: false,
    setCellProps: () => ({ style: { ...defaultCellStyle } }),
  })
];

export default function DocumentLibraryTable(props) {
  const { state } = useLocation();
  const classes = useStyles();
  const { componentObject, data, setRefresh, programId } = props;

  //for autoscroll
  const history = useHistory();
  const rowRef = useRef({});
  const [targetedRowId, setTargetedRowId] = useState();
  const [userPermittedPortCos, setUserPermittedPortCos] = useState([]);

  // for modal
  const [openModalDocument, setOpenModalDocument] = useState(null);

  const isPortfolioDocTable = componentObject.TypeRef === COMPONENT_OBJECT_PORTFOLIO_DOC_LIBRARY_TYPEREF

  useEffect(function scrollToRowOnMount() {
    if (state) {
      setTargetedRowId(parseInt(state.refId));
      if (rowRef && rowRef.current[targetedRowId] && data) {
        rowRef.current[targetedRowId].scrollIntoView({
          block: "center",
        });
        history.replace();
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (programId === PROGRAM_ID_PORTFOLIO) {
      PortfolioService.getUserPermittedPortCos()
        .then((res) => setUserPermittedPortCos(res.payload))
    }
  }, [programId]);

  const tableRows = useMemo(() => {
    if (!data) {
      return null;
    }

    return data.map((row) => {
      const publishDate = row.PublishDate
        ? moment(row.PublishDate).format(DATE_FORMAT_DISPLAY_NUMERIC)
        : ""
      return {
        Name: createNodeCellParams(
          row.Title,
          row.Title,
          <CustomLink
            className={classes.link}
            variant="noHRef"
            onClick={() => downloadFile(row.DocID, row.Title)}
            test={`download-${row.Title}-${row.PublishDate}`}
            rowId={row.DocID}
          >
            <AttachFileOutlinedIcon className={classes.downloadFileIcon} />
            <span ref={(el) => (rowRef.current[row.DocID] = el)}> {row.Title} </span>
          </CustomLink>
        ),
        Description: row.Description,
        Date: createNodeCellParams(row.PublishDate, publishDate),
        Action: createNodeCellParams(
          null,
          null,
          <ButtonDefault
            variant="small"
            color="secondary"
            startIcon={(
              <Edit className={classes.uploadIconSmall} />
            )}
            onClick={() => setOpenModalDocument(row)}
            data-cy={`btn-upload-${row.Title}`}
            disableReadOnlyUsers
          >
            Update
          </ButtonDefault>
        )
      };
    });
  }, [data, classes]);

  const portfolioTableRows = useMemo(() => {
    if (!data) {
      return null;
    }

    return data.map((row) => {
      const publishDate = row.PublishDate
        ? moment(row.PublishDate).format(DATE_FORMAT_DISPLAY_NUMERIC)
        : ""
      return {
        Name: createNodeCellParams(
          row.Title,
          row.Title,
          <CustomLink
            className={classes.link}
            variant="noHRef"
            onClick={() => downloadFile(row.DocID, row.Title)}
            test={`download-${row.Title}-${row.PublishDate}`}
            rowId={row.DocID}
          >
            <AttachFileOutlinedIcon className={classes.downloadFileIcon} />
            <span ref={(el) => (rowRef.current[row.DocID] = el)}> {row.Title} </span>
          </CustomLink>
        ),
        Description: row.Description,
        Date: createNodeCellParams(row.PublishDate, publishDate),
        PortCo: row.PortCo_Name,
        Action: createNodeCellParams(
          null,
          null,
          <ButtonDefault
            variant="small"
            color="secondary"
            startIcon={(
              <Edit className={classes.uploadIconSmall} />
            )}
            onClick={() => setOpenModalDocument(row)}
            data-cy={`btn-upload-${row.Title}`}
            disableReadOnlyUsers
          >
            Update
          </ButtonDefault>
        )
      };
    });
  }, [data, classes]);

  const handleUploadSuccess = useCallback(() => {
    setRefresh(new Date());
    setOpenModalDocument(null);
    setTargetedRowId(null)
  }, [setRefresh]);

  const handleCloseForm = useCallback(() => {
    setOpenModalDocument(null)
    setTargetedRowId(null)
  }, [])

  return (
    <>
      <DataTable
        data={isPortfolioDocTable ? portfolioTableRows : tableRows}
        columns={isPortfolioDocTable ? PORTFOLIO_TABLE_HEADERS : TABLE_HEADERS}
        options={{
          filterType: "checkbox",
          fixedHeader: true,
          fixedSelectColumn: true,
          filter: !!isPortfolioDocTable,
          pagination: false,
          selectableRowsHideCheckboxes: true,
          viewColumns: false,
          download: false,
          print: false,
          sortOrder: {order: "desc"},
          textLabels: {
            body: {
              noMatch: "No documents to display",
            }
          },
          setRowProps: (row, dataIndex, rowIndex) => {
            if (targetedRowId && row[0].props && row[0].props.rowId === targetedRowId) {
              return { style: { backgroundColor: variables.chosenElement } }
            }
          },
        }}
      />
      <div className={classes.buttonContainerTop}>
        <ButtonDefault
          variant="medium"
          background="tertiary"
          className={classes.newUploadButton}
          startIcon={(
            <BackupRoundedIcon className={classes.uploadIconMedium} />
          )}
          onClick={() => setOpenModalDocument({})}
          data-cy="btn-upload-new"
          disableReadOnlyUsers
          fixedRight
        >
          New Document
        </ButtonDefault>
      </div>
      <CustomModal
        open={!!openModalDocument}
        onClose={handleCloseForm}
      >
        <UploadGenericDocumentForm
          onClose={handleCloseForm}
          onSuccess={handleUploadSuccess}
          componentObject={componentObject}
          editingDocument={openModalDocument}
          availablePortCos={userPermittedPortCos}
        />
      </CustomModal>
    </>
  );
}
