import { Box, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import BlockIcon from "@material-ui/icons/Block";
import SaveIcon from "@material-ui/icons/Save";
import ButtonDefault from "components/utils/buttonDefault.component";
import DateInput from "components/utils/form-elements/dateInput.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import SingleFileUpload from "components/utils/form-elements/singleFileUpload.component";
import variables from "styleVariables";
import { VALIDATION_REQUIRED } from "utils/formValidators";
import { useCallback } from "react";
import Form from "components/utils/form-elements/form.component";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import { getUploadHttpErrorMessage } from "services/http-common";


const useStyles = makeStyles((theme) => ({
  documentFormWrapper: {
    position: "relative",
    width: "100%",
  },
  formButtonsWrapper: {
    display: "flex",
    marginTop: 15
  },
  submitFormButton: {
    margin: "0px 15px",
  },
  iconButton: {
    fontSize: variables.fontH3,
  },
  attachFileContainer: {
    display: "flex",
    flexDirection: "column"
  },
}));


export const DOCUMENT_FORM_NAME = "supporting-docs";

export const VARIANT_MODAL = "modal";
export const VARIANT_PANEL = "panel";

const formValidations = {
  Document_Title: {
    type: VALIDATION_REQUIRED,
    message: "A Title is required."
  },
  Publish_Date: {
    type: VALIDATION_REQUIRED,
    message: "A Publish Date is required."
  },
  Version: {
    type: VALIDATION_REQUIRED,
    message: "Version is required."
  },
  "single-file-upload": {
    type: VALIDATION_REQUIRED,
    message: "Please attach a file"
  },
};

export default function DocumentEditForm(props) {
  const classes = useStyles();
  const {
    chosenFile, document, documentUpload, errors,
    onCancel, onDelete, onSubmit, setErrors, isSaving,
    setIsSaving, variant = VARIANT_PANEL
  } = props;


  const handleFileChange = useCallback((...args) => {
    if (errors?.File_Name) {
      setIsSaving(false);
      const newErrors = { ...errors };
      delete newErrors.File_Name;
      setErrors(newErrors);
    }
    documentUpload(...args);
  }, [documentUpload, errors, setErrors, setIsSaving]);

  const handleSubmit = useCallback(async (...args) => {
    if (errors?.File_Name) {
      setIsSaving(false);
    } else {
      setIsSaving(true);
      return onSubmit(...args);
    }
  }, [onSubmit, errors?.File_Name, setIsSaving]);

  const handleError = useCallback((validationErrors, thrownError) => {
    if (validationErrors?.["single-file-upload"]) {
      setErrors({
        File_Name: validationErrors["single-file-upload"].message
      });
    } else if (thrownError?.response?.status) {
      setErrors({
        File_Name: getUploadHttpErrorMessage(thrownError.response.status)
      });
    }
    setIsSaving(false);
  }, [setErrors, setIsSaving]);

  return (
    <Form
      id={DOCUMENT_FORM_NAME}
      name={DOCUMENT_FORM_NAME}
      className={classes.documentFormWrapper}
      onSubmit={handleSubmit}
      onError={handleError}
      validations={formValidations}
    >
      <Box display="flex" paddingTop={1}>
        <Box flex={1} paddingRight={2}>
          <LabelInput
            id="Document_Title"
            name="Document_Title"
            label="Title*"
            defaultValue={document?.Document_Title}
            variant="default"
            margin="dense"
            error={!!errors?.Document_Title}
            errorMessage={errors?.Document_Title}
            test="Title"
          />
        </Box>
      </Box>
      <Box display="flex" paddingTop={1}>
        <Box flex={1} paddingRight={2}>
          <DateInput
            label="Publish Date*"
            margin="dense"
            name="Publish_Date"
            test="Publish_Date"
            defaultValue={document?.Publish_Date}
            error={!!errors?.Publish_Date}
            errorMessage={errors?.Publish_Date}
          />
        </Box>
        <Box flex={1} paddingRight={2}>
          <LabelInput
            id="Version"
            name="Version"
            label="Version*"
            test="Version"
            defaultValue={document?.Version}
            variant="default"
            margin="dense"
            error={!!errors?.Version}
            errorMessage={errors?.Version}
          />
        </Box>
      </Box>
      <Box display="flex" paddingTop={1}>
        <Box flex={1} paddingRight={2}>
          <LabelInput
            id="Description"
            name="Description"
            label="Description"
            test="Description"
            defaultValue={document?.Description}
            variant="default"
            margin="dense"
            rows="4"
            multiline
          />
        </Box>
      </Box>
      <div className={classes.attachFileContainer}>
        <SingleFileUpload
          chosenFile={chosenFile}
          existingFile={document}
          fileInputHandler={handleFileChange}
          renamedChosenFile={chosenFile?.name}
          error={errors?.File_Name}
        />
      </div>
      {variant === VARIANT_MODAL ? (
        <Box display="flex" justifyContent="center" marginTop={2}>
          <DualFormButtons
            cancelOnClick={onCancel}
            deleteClick={onDelete}
            isSaving={isSaving}
          />
        </Box>
      ) : (
        <div className={classes.formButtonsWrapper}>
          {!!onDelete && (
            <div className={classes.submitFormButton}>
              <ButtonDefault
                onClick={onDelete}
                variant="iconSmall"
                color="red"
                startIcon={<DeleteIcon className={classes.iconButton} />}
              >
                Delete
              </ButtonDefault>
            </div>
          )}
          <div className={classes.submitFormButton}>
            <ButtonDefault
              onClick={onCancel}
              variant="iconSmall"
              color="tertiary"
              startIcon={<BlockIcon className={classes.iconButton} />}
            >
              Cancel
            </ButtonDefault>
          </div>
          <div className={classes.submitFormButton}>
            <ButtonDefault
              data-cy="save-document-attachment"
              type="submit"
              variant="iconSmall"
              color="secondary"
              startIcon={<SaveIcon className={classes.iconButton} />}
            >
              Save
            </ButtonDefault>
          </div>
        </div>
      )}
    </Form>
  );
}
