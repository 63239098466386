import React, { useMemo } from "react";
import CustomSelect from "components/utils/form-elements/select.component";
import { MenuItem } from "@material-ui/core";

const PortCoPicker = props => {
  const {
    portCoList,
    handleChange,
    selectedPortCoId,
    label,
    disabled,
    readOnly,
    ignoreReadOnly,
    showBlankOption
  } = props;

  const isDisabled = useMemo(() => (
    disabled ||
    (!portCoList?.length && !selectedPortCoId)
  ), [disabled, portCoList, selectedPortCoId]);

  return (
    <CustomSelect
      label={label}
      placeholder="Select a Portfolio Company"
      value={selectedPortCoId || ""}
      onChange={handleChange}
      name="PortCo"
      test="select-PortCo"
      disabled={isDisabled}
      ignoreReadOnly={ignoreReadOnly}
      readOnly={readOnly}
    >
      {!!showBlankOption && (
        <MenuItem value="" data-cy="menu-PortCo-none">
          None
        </MenuItem>
      )}
      {portCoList?.map((PortCo) => (
        <MenuItem
          value={PortCo.PortCo_ID}
          key={PortCo.PortCo_ID}
          data-cy={`menu-PortCo-${PortCo.Name}`}
        >
          {PortCo.Name}
        </MenuItem>
      ))}
    </CustomSelect>
  );
};

export default PortCoPicker;
