import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import variables from "styleVariables";
import { registry as Services } from "services/registry.service";
import {sortByStringKey, reverseSortIntByStringKey } from "utils/sortingFuncs";
import DocumentLibraryTable from "components/utils/tables/documentLibraryTable.component";
import Loader from "components/utils/loader.components";
import { PATH_COMPONENT_OBJECT, PATH_PROGRAM_COMPONENT } from "../constants/routes.constants";
import useNumericParams from "hooks/useNumericParams";
import { generatePath, useHistory } from "react-router-dom/cjs/react-router-dom.min";


const useStyles = makeStyles({
  link: {
    color: variables.secondaryDark,

    "&:hover": {
      color: variables.primaryMain,
      cursor: "pointer",
    },
  },
});

const REMOVE_PATH_PARAMS = new Set(["holdingId"]);


export function DocumentLibraryType(props) {
  const classes = useStyles();
  const history = useHistory();
  const params = useNumericParams();

  const [allCompFiles, setAllCompFiles] = useState();
  const [refresh, setRefresh] = useState();

  useEffect(function removePathParams() {
    const shouldRedirect = Object.keys(params).some(param => (
      REMOVE_PATH_PARAMS.has(param)
    ));
    if (shouldRedirect) {
      if (params.componentObjectId) {
        history.replace(generatePath(PATH_COMPONENT_OBJECT, params));
      }
      history.replace(generatePath(PATH_PROGRAM_COMPONENT, params));
    }
  }, [history, params]);

  useEffect(() => {
    const isCurrent = (arr, index) => {
      if (index === 0) {
        return true
      } else if (arr[index - 1].ProgramDoc_Group_ID === arr[index].ProgramDoc_Group_ID) {
        return false
      } else {
        return true
      }
    }
    const getAllCompFiles = (componentObjectId) => {
      Services.fileGetAllByComponentObjectId(componentObjectId).then(
        (response) => {
          if (response.payload) {
            const data = [];
            const sortById = reverseSortIntByStringKey(response.payload, "ProgramDoc_ID")
            const sortByGroupId = sortByStringKey(sortById, "ProgramDoc_Group_ID")
            sortByGroupId.forEach((row, index) => {
              data.push({
                DocID: row.ProgramDoc_ID,
                key: row.ProgramDoc_ID,
                DocName: row.Title,
                Title: row.Title,
                Description: row.Description,
                Version: row.Version,
                PublishDate: row.PublishDate,
                Status: row.Status,
                File_Ref: row.File_Ref,
                ProgramDoc_Group_ID: row.ProgramDoc_Group_ID,
                ComponentObjects_ComponentObject_ID: row.ComponentObjects_ComponentObject_ID,
                Program_Program_ID: row.Program_Program_ID,
                PortCo_ID: row.PortCo_ID,
                PortCo_Name: row._associations?.PortCo.Name,
                current: isCurrent(sortByGroupId, index)
              });
            });
            setAllCompFiles(data);
          }
        }
      );
    };
    getAllCompFiles(props.componentObject.ComponentObject_ID);
  }, [props.componentObject.ComponentObject_ID, refresh]);

  if (!allCompFiles) {
    return <Loader />;
  }
  return (
    <Grid item sm={12} className={classes.tableContainer}>
      <DocumentLibraryTable
        className={classes.test}
        data={allCompFiles}
        tableTitle={props.tableTitle}
        componentObject={props.componentObject}
        programId={params.programId}
        setRefresh={setRefresh}
      />
    </Grid>
  );
}
