import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AuthService from "services/auth.service";

const Logout = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    AuthService.logout();
    history.push({ pathname: "/" }, { message: location.state?.message });
  }, [location.state?.message, history]);

  return null;
};

export default Logout;
