import { currentUserToken, currentRefreshToken } from "utils/userHelpers";
import AuthService from "./auth.service";
const jwt = require("jsonwebtoken");

export default function authHeader() {
  let accessToken = currentUserToken();
  const refreshToken = currentRefreshToken();
  const currentTime = Math.floor(new Date().getTime() / 1000);

  if (accessToken && refreshToken && (jwt.decode(refreshToken).exp - currentTime) < 960) {
    AuthService.refreshToken(refreshToken)
      .then((response) => {
        accessToken = response.updatedAccessToken;
      }).catch(error => {
        AuthService.logout();
      })
    return { "x-access-token": accessToken };
  } else if (accessToken && refreshToken) {
    return { "x-access-token": accessToken };
  } else {
    return {};
  }
}
