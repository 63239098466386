import React, { useContext, useMemo } from "react";
import TasksDashTable from "components/tasks/tasksDashTable.component";
import WorkpackageTasksContext from "contexts/workpackageTasks.context";

const ProgramTasks = (props) => {
  const { state } = useContext(WorkpackageTasksContext);
  const { tasksByProgramId, workpackages, workpackagesByProgramId } = state;

  const tableData = useMemo(() => {
    const programTasks = tasksByProgramId?.[props.programId];
    const programWorkpackages = workpackagesByProgramId?.[props.programId];
    if (!programTasks || !programWorkpackages) {
      return null;
    }

    const activeParentTasks = programTasks.filter(task => (
      task.Parent_ID === null && task.Status !== "Closed"
    ));
    return activeParentTasks.map(task => {
      let workpackageName = "";
      if (task.Workpackages_Workpackage_ID) {
        const taskWorkpackage = workpackages?.find?.(workpackage => (
          task.Workpackages_Workpackage_ID === workpackage.Workpackage_ID
        ));
        workpackageName = taskWorkpackage.Name;
      }
      return { ...task, WPName: workpackageName };
    });
  }, [
    props.programId, tasksByProgramId, workpackages, workpackagesByProgramId
  ]);

  if (!tableData) {
    return null;
  }
  return (
    <TasksDashTable
      tableData={tableData}
      programId={props.programId}
      setWpDashRefresh={props.setWpDashRefresh}
      programName={props.programName}
    />
  );
};
export default ProgramTasks;
