import { makeStyles } from "@material-ui/core";
import DisplayHTML from "components/utils/displayHTML.component";
import { useMemo } from "react";
import { sortIntByStringKey } from "utils/sortingFuncs";

const useStyles = makeStyles((theme) => ({
  reqLinkList: {
    padding: "0px 0px 0px 12px",
    marginTop: 0,
    marginBottom: 0,
  },
  label: {
    fontWeight: "bold",
  },
}));

export function RequirementsLinksCell({ reqLinks }) {
  const classes = useStyles();

  const sortedLinks = useMemo(() => (
    sortIntByStringKey(reqLinks, "RequirementLink_ID")
  ), [reqLinks]);

  return (
    <ul className={classes.reqLinkList}>
      {sortedLinks.map((reqLink) => (
        <li key={reqLink.RequirementLink_ID}>
          <span className={classes.label}>
            Requirement {reqLink.Requirement_ID_Link}:&nbsp;
          </span>
          <DisplayHTML html={reqLink.Description} />
        </li>
      ))}
    </ul>
  );
}
