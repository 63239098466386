import React from "react";
// import { useEffect } from "react";
// import { Grid, Link } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
// import { registry as Services } from "../../../services/registry.service";

const DataTableType = (props) => {

    // useEffect(() => {
    //   //CONTROL MATRIX
    //   Services["controlGetPrivacyManagementControlMatrix"](props.programId).then((res) =>
    //     console.log("Control Matrix", res.payload)
    //   );
    //   //COMPLIANCE MATRIX PROGRAM REGS
    //   Services["complianceGetProgramRegulations"](props.programId).then((res) =>
    //     console.log("Comp Matrix", res.payload)
    //   );
    //   //COMPLIANCE MATRIX PROGRAM REGS
    //   Services["complianceGetComplianceMatrixData"](props.programId).then((res) =>
    //     console.log("Comp Matrix DATA", res.payload)
    //   );
    // }, [props.programId]);

  return (
    <div>
        DATATABLE TYPE CONTENT
    </div>
  )
}

export default DataTableType;
