import React, { useContext, useMemo } from "react";
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EmptyDashboardSection from "components/utils/emptyDashboardSection.component";
import AddListItem from "components/utils/addListItem.component";
import Workpackage from "components/workpackages/workpackage.component";
import classNames from "classnames";
import { groupObjectArrayByKey, mapObjectArrayByKey } from "utils/arrayOfObjectsHelpers";
import WorkpackageTasksContext from "contexts/workpackageTasks.context";
import ProgramsContext from "contexts/programs.context";

const useStyles = makeStyles((theme) => ({
  wpContainer: {
    display: "flex",
    flex: "wrap",
    margin: "0px auto",
    alignItems: "flex-start",
    flexFlow: "row wrap",
  },
  wp: {
    maxWidth: 280,
    width: "100%",
    flex: 1,
    position: "relative",
  },
  invisible: {
    visibility: "hidden",
    maxHeight: "0px!important",
    marginTop: "-200px",
    padding: "0px!important",
  },
  wpWrapper: {
    display: "flex",

  },
  addWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "20px 15px",
  },
}));

const ProgWorkpackages = ({
  checkDeletable,
  programId,
  setEditModal,
}) => {
  const classes = useStyles();
  const { state: programState } = useContext(ProgramsContext);
  const { state: wpTaskState } = useContext(WorkpackageTasksContext);

  const dataList = useMemo(() => {
    const programComponents = programState.programComponentsByProgram?.[programId];
    const programWorkpackages = wpTaskState.workpackagesByProgramId?.[programId];
    const programTasks = wpTaskState.tasksByProgramId?.[programId];
    if (!programWorkpackages || !programTasks || !programComponents) {
      return null;
    }

    const programComponentsById = mapObjectArrayByKey(
      programComponents, "Component_ID"
    );
    const tasksByWorkpackageId = groupObjectArrayByKey(
      programTasks, "Workpackages_Workpackage_ID"
    );

    return programWorkpackages.map(workpackage => {
      const tasks = tasksByWorkpackageId[workpackage.Workpackage_ID] || [];
      const completedTasks = tasks.filter(task => (
        task.Status === "Completed"
      ));
      return {
        Workpackage_ID: workpackage.Workpackage_ID,
        Name: workpackage.Name,
        ProgramComponentName:
          programComponentsById[workpackage.ProgramComponents_Component_ID],
        Path:
          `/program/${workpackage.Program_ID}/${workpackage.ProgramComponents_Component_ID}`,
        Tasks: tasks,
        total: tasks.length,
        completed: completedTasks.length,
      }
    });
  }, [
    programState.programComponentsByProgram, wpTaskState.tasksByProgramId,
    wpTaskState.workpackagesByProgramId, programId
  ]);

  if (!dataList) {
    return null;
  }
  return (
    <>
      <Paper elevation={0}>
        {dataList && dataList.length === 0 ? (
          <EmptyDashboardSection variant="custom">
            <AddListItem
              dark
              text="Add Workpackage"
              onClick={() => setEditModal(true)}
              test="WP"
            />
          </EmptyDashboardSection>
        ) : (
          <div className={classes.wpWrapper} data-cy="wpWrapper">
            <Grid container spacing={7} className={classes.wpContainer}>
              {!!dataList && dataList.map((data) => (
                <Workpackage
                  key={data.Workpackage_ID}
                  data={data}
                  checkDeletable={checkDeletable}
                  programId={programId}
                  fromOrchestrationLevel
                />
              ))}
              {/*Invisible workpackage maintains alignment for last item when there's an odd # of workpackages*/}
              <Grid
                item
                xs={12}
                sm={6}
                className={classNames(classes.wp, classes.invisible)}
              />
            </Grid>
          </div>
        )}
      </Paper>
      <div className={classes.addWrapper}>
        {!!dataList && dataList.length > 0 && (
          <AddListItem
            dark
            text="Add Workpackage"
            onClick={() => setEditModal(true)}
            test="WP"
          />
        )}
      </div>
    </>
  );
};

export default ProgWorkpackages;
