import React, { useEffect, useMemo, useReducer, useState, useRef } from "react";
import CertificationService from "services/certification.service";
import DataTable from "components/utils/tables/dataTable.component";
import EditIconButton from "components/utils/editIconButton.component";
import { Box } from "@material-ui/core";
import AddListItem from "components/utils/addListItem.component";
import CustomModal from "components/utils/modal.component";
import { makeStyles } from "@material-ui/core";
import AircrafForm from "./aircraftForm.component";
import certificationReducer, {
  ACTION_SET_AIRCRAFTS,
  certificationInitialState,
} from "reducers/certification/certification.reducer";
import Loader from "components/utils/loader.components";
import variables from "styleVariables"
import useTargetTableRowViaLink from "hooks/useTargetTableRowViaLink";
import { createNodeCellParams, createNodeHeaderParams, createValueHeaderParams } from "components/utils/tables/utils/dataTableHelpers";

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    paddingLeft: 5,
    paddingTop: 20,
    display: "flex",
  },
  editColumn: {
    width: 40
  }
}));


const AircraftDC = () => {
  const classes = useStyles();
  const [formMode, setFormMode] = useState(null);
  const [editItem, setEditItem] = useState(null)
  const [state, dispatch] = useReducer(
    certificationReducer,
    certificationInitialState
  );
  const rowRef = useRef({});
  const [targetedRowId, clearTargetedRow] = useTargetTableRowViaLink(rowRef, !!state.aircrafts?.length);


  useEffect(() => {
    const getAircraftList = async () => {
      const response = await CertificationService.getAllAircraft();
      dispatch({
        type: ACTION_SET_AIRCRAFTS,
        payload: response.payload,
      });
    };
    getAircraftList();
  }, []);

  const openForm = (editAircraft) => {
    if (editAircraft) {
      setFormMode("edit");
      setEditItem(editAircraft);
    } else {
      setFormMode("create");
    }
  };

  const closeForm = () => {
    setFormMode(null);
    setEditItem(null);
    clearTargetedRow()
  };

  const headers = useMemo(() => ([
    createNodeHeaderParams("Aircraft_ID", " ", {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        className: classes.editColumn,
        "data-cy": "aircraft-table-edit-header"
      })
    }),
    createValueHeaderParams("Make", "Make", {
      setCellHeaderProps: () => ({
        "data-cy": "aircraft-table-start-time-header",
      }),
    }),
    createValueHeaderParams("Model"),
    createValueHeaderParams("Series"),
    createValueHeaderParams("N_Number", "N-Number", { filter: false }),
    createValueHeaderParams("Cargo_Capacity", "Cargo Capacity"),
    createValueHeaderParams("Passenger_Capacity", "Passenger Capacity")
  ]), [classes]);

  const tableCells = useMemo(() => (
    state.aircrafts?.map?.((row) => ({
      Aircraft_ID: createNodeCellParams(null, null, (
        <div
          data-aircraft-id={row.Aircraft_ID}
          ref={el => {
            rowRef.current[row.Aircraft_ID] = el;
          }}
        >
          <EditIconButton onClick={() => openForm(row)} />
        </div>
      )),
      Make: row.Make,
      Model: row.Model,
      Series: row.Series,
      N_Number: row.N_Number,
      Cargo_Capacity: row.Cargo_Capacity,
      Passenger_Capacity: row.Passenger_Capacity
    })) || []
  ), [state.aircrafts]);

  if (!state.aircrafts) {
    return <Loader />;
  }

  return (
    <Box marginTop={4} marginBottom={2} data-cy="aircraft-page-wrapper">
      <DataTable
        data={tableCells}
        columns={headers}
        options={{
          textLabels: {
            body: {
              noMatch: "No aircrafts",
            },
          },
          filterType: "checkbox",
          fixedHeader: true,
          fixedSelectColumn: true,
          pagination: false,
          selectableRowsHideCheckboxes: true,
          viewColumns: false,
          download: false,
          print: false,
          setRowProps: (row, dataIndex, rowIndex) => {
            const aircraftId = row[0].props?.["data-aircraft-id"];
            if (aircraftId === targetedRowId) {
              return { style: { backgroundColor: variables.chosenElement } }
            }
          },
        }}
      />
      <Box className={classes.buttonWrapper}>
        <AddListItem
          onClick={() => openForm()}
          test="aircraft"
        >
          Add Aircraft
        </AddListItem>
      </Box>
      <CustomModal
        open={formMode === "edit" || formMode === "create"}
        onClose={() => closeForm()}
      >
        <AircrafForm
          formMode={formMode}
          editItem={editItem}
          closeForm={closeForm}
          dispatch={dispatch}
        />
      </CustomModal>
    </Box>
  );
};

export default AircraftDC;
