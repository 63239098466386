import React, { useState, useEffect, useCallback } from "react";
import { Grid, Typography, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import AssessmentService from "services/assessment.service";
import ImplementationService from "services/implementation.service";
import Loader from "components/utils/loader.components";
import FormBanner from "components/utils/form-elements/formBanner.component";
import FormSubHeader from "components/utils/form-elements/formSubHeader.component";
import CustomSelect from "components/utils/form-elements/select.component";
import variables from "styleVariables";
import Form from "components/utils/form-elements/form.component";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: 750,
    display: "flex",
    justifyContent: "center",
    borderRadius: 5,
  },
  content: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "0px 20px 0px 20px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: 5,
    paddingRight: 5,
  },
  selectsAndInstr: {
    alignSelf: "center",
    paddingTop: 40,
  },
  instruction: {
    paddingBottom: 1,
  },
  selectsContainer: {
    alignSelf: "center",
    maxHeight: 440,
    width: 540,
    overflowY: "scroll",
    overflowX: "hidden",
    backgroundColor: variables.grayTint,
    padding: "10px 50px 10px 50px",
    marginBottom: 20,
    borderRadius: 8,
  },
  customSelect: {
    padding: 5,
    width: "100%",
  },
  inputLabelRoot: {
    fontSize: 23,
    // fontWeight: "bold",
  },
  selectRoot: {
    padding: 10,
  },
  dualButtons: {
    paddingBottom: 40,
  },
}));
export const AssessMgmtPrincForm = (props) => {
  const classes = useStyles();

  const { product, setEditModal, setRefresh } = props;

  const [group, setGroup] = useState();
  const [formData, setFormData] = useState([]);
  const [formLoad, setFormLoad] = useState(false);
  const [formObj, setFormObj] = useState({});
  const [princObj, setPrincObj] = useState({});
  const [allInfo, setAllInfo] = useState();
  const [paIds, setPaIds] = useState([]);
  const [activeForm, setActiveForm] = useState(false);
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    AssessmentService.getProductPrivacyMgmtPrincAssessment(product.id)
      .then((res) => {
        const orderedRes = res.payload.sort((a, b) => (a.Mgmt_Principle_ID > b.Mgmt_Principle_ID) ? 1 : -1);
        setAllInfo(orderedRes);
        setFormLoad(false);
        const data = [];
        let obj = {};
        //array formed to map text fields
        orderedRes.forEach((info) => {
          obj = {
            ...obj,
            [info.Mgmt_Principle_ID.toString()]: info.State,
            PA_ID: info.PA_ID,
          };
          data.push({
            formFieldId: info.Mgmt_Principle_ID.toString(),
            Label: info.Label,
            Name: info.Name,
            State: info.State,
            Change_Date: info.Change_Date,
            PA_ID: info.PA_ID,
            GroupProducts_GroupProduct_ID: info.GroupProducts_GroupProduct_ID,
            GroupProducts_OrganizationGroups_Group_ID:
              info.GroupProducts_OrganizationGroups_Group_ID,

            // Program_Program_ID: info.Program_Program_ID,
          });
        });
        setFormObj(obj);
        setFormData(data);
      })
      .then(() => setFormLoad(true));
    ImplementationService.getOrgGroupByProductId(product.id).then(
      (res) => {
        setGroup({ id: res.payload.Group_ID, name: res.payload.Name });
      }
    );
  }, [product.id]);

  const handleChange = (event, paId) => {
    const { target } = event;
    if (!activeForm) {
      setActiveForm(true);
    }
    setFormObj({
      ...formObj,
      [target.name]: target.value,
    });

    // isolate specific row from allInfo
    const mgmtPrin_Id = parseInt(target.name);
    const row = allInfo.filter(
      (info) => info.Mgmt_Principle_ID === mgmtPrin_Id
    )[0];

    if (target.value === row.State) {
      setPaIds([...paIds.filter((id) => id !== paId)]);
      setPrincObj({
        ...princObj,
        [paId]: null,
      });
    } else {
      setPaIds([...paIds, paId]);
      setPrincObj({
        ...princObj,
        [paId]: [
          row.Mgmt_Principle_ID,
          row.GroupProducts_GroupProduct_ID,
          row.GroupProducts_OrganizationGroups_Group_ID,
          target.value,
        ],
      });
    }
  };

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    if (paIds.length) {
      const data = Object.entries(princObj).map(([id, assessments]) => ({
        PA_ID: id,
        State: assessments[3],
      }));
      setIsSaving(true);
      await AssessmentService.updatePrincipleAssessmentItems(data);
      setIsSaving(false);
      setRefresh(true);
      setEditModal(false);
    }
  }, [paIds, princObj, setEditModal, setRefresh]);

  /*
  [ManagementPrinciples_ Mgmt_Principle_ID,GroupProducts_GroupProduct_ID,GroupProducts_OrganizationGroups_Group_ID,State,Status,Change_Date,Change_User]
  let items= [
    [1,1,1,"Planned","Active", "2020-09-11", "NCG Admin"],
    [2,1,1,"Planned","Active", "2020-09-11", "NCG Admin"]
  ]
  savePrincipleAssessment api pass items
*/
  if (formData && formLoad && group) {
    return (
      <Grid container className={classes.formContainer}>
        <FormBanner>
          {product.name} - Privacy Management Principles Assessment Update
        </FormBanner>

        <div className={classes.content}>
          <FormSubHeader
            variant="groupProduct"
            product={product.name}
            group={group.name}
          />
          <Form name="principle-assessment" className={classes.form}>
            <div className={classes.selectsAndInstr}>
              <Typography
                variant="subtitle1"
                className={classes.instruction}
                color="textPrimary"
              >
                Update Privacy Management Principle Status:
              </Typography>
              <div className={classes.selectsContainer}>
                {formData.map((info) => {
                  return (
                    <CustomSelect
                      key={info.formFieldId}
                      className={classes.customSelect}
                      variant="stacked"
                      name={info.formFieldId}
                      labelId={`${info.Label} ${info.Name}`}
                      label={`${info.Label} ${info.Name}`}
                      value={formObj[info.formFieldId]}
                      onChange={(event) => handleChange(event, info.PA_ID)}
                      // shrink
                      inputLabelClasses={{ root: classes.inputLabelRoot }}
                      selectClasses={{
                        root: classes.selectRoot,
                      }}
                    >
                      <MenuItem value="Implemented">Implemented</MenuItem>
                      <MenuItem value="Planned">Planned</MenuItem>
                    </CustomSelect>
                  );
                })}
              </div>
            </div>
            <DualFormButtons
              className={classes.dualButtons}
              cancelOnClick={() => setEditModal(false)}
              saveOnClick={handleSubmit}
              disabled={!activeForm}
              isSaving={isSaving}
            />
          </Form>
        </div>
      </Grid>
    );
  } else {
    return (
      <Grid container className={classes.formContainer}>
        <Loader />;
      </Grid>
    );
  }
};

export default AssessMgmtPrincForm;
