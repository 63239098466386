import { Box, Dialog, makeStyles } from "@material-ui/core";
import AddListItem from "components/utils/addListItem.component";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import Form from "components/utils/form-elements/form.component";
import FormBanner from "components/utils/form-elements/formBanner.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import CustomSelect from "components/utils/form-elements/select.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import Loader from "components/utils/loader.components";
import { useCallback, useMemo, useState } from "react";
import { ACTION_REMOVE_RUNBOOK_INCIDENT, ACTION_REPLACE_RUNBOOK_INCIDENT } from "reducers/runbook/oneRunbook.reducer";
import RunbookService from "services/runbook.service";
import { VALIDATION_REQUIRED } from "utils/formValidators";
import RadioInputGroup from "components/utils/form-elements/radioInputGroup.component";

const useStyles = makeStyles(theme => ({
  dropdown: {
    height: 40,
    paddingTop: 5,
    marginTop: -4
  },
  dropdownHelperText: {
    height: 16
  },
}));

const createStatusRadioOptions = [
  { label: "Open", value: "Open" }
];

const editStatusRadioOptions = [
  { label: "Open", value: "Open" },
  { label: "Closed", value: "Closed" },
];

const runbookIncidents = ["Low", "Medium", "High", "Critical"]

export default function IncidentModal(props) {
  const classes = useStyles();
  const { state, runbookId, dispatch, incident, setIncident } = props;

  const [modalTitle, setModalTitle] = useState("");

  const handleOpen = useCallback(() => {
    if (incident?.Incident_ID) {
      setModalTitle("Update Incident");
    } else {
      setModalTitle("Create New Incident");
    }
  }, [incident]);

  const validations = useMemo(() => ({
    Severity: { type: VALIDATION_REQUIRED },
    Task_ID: { type: VALIDATION_REQUIRED },
    Description: { type: VALIDATION_REQUIRED },
  }), []);

  const handleSubmit = useCallback(async data => {
    if (incident.Incident_ID) {
      data.Incident_ID = incident.Incident_ID;
    }
    const incidentResponse = !incident.Incident_ID ?
      await RunbookService.createIncident(runbookId, data) :
      await RunbookService.updateIncident(runbookId, data);
    dispatch({
      type: ACTION_REPLACE_RUNBOOK_INCIDENT,
      payload: incidentResponse.payload,
    });
    setIncident(null);
  }, [dispatch, runbookId, setIncident, incident]);

  const handleDelete = useCallback(async () => {
    const incidentResponse = await RunbookService.deleteIncident(
      incident.Incident_ID
    );
    dispatch({
      type: ACTION_REMOVE_RUNBOOK_INCIDENT,
      payload: incidentResponse.payload,
    });
    setIncident(null);
  }, [dispatch, incident, setIncident]);

  return (
    <>
      <Box marginTop={2} marginBottom={2}>
        <AddListItem onClick={() => setIncident({})}>
          Add New Incident
        </AddListItem>
      </Box>
      <Dialog
        open={!!incident}
        onClose={() => setIncident(null)}
        TransitionProps={{
          onEntering: handleOpen,
          exit: false
        }}
      >
        <FormBanner>
          {modalTitle}
        </FormBanner>
        {(!state.runbookTasks || !incident) ? (
          <Loader />
        ) : (
          <Box padding={4} paddingBottom={2}>
            <Form
              name="runbook-incident-create"
              onSubmit={handleSubmit}
              validations={validations}
            >
              <GridContainer spacing={4}>
                <GridItem xs={12}>
                  <GridContainer spacing={4}>
                    <GridItem xs={12} sm={6} md={12} lg={6}>
                      <CustomSelect
                        label="Severity*"
                        name="Severity"
                        defaultValue={incident?.Severity || ""}
                        options={runbookIncidents.map(incidentOption => ({
                          value: incidentOption,
                          label: incidentOption
                        }
                        ))}
                        className={classes.dropdown}
                        helperClassName={classes.dropdownHelperText}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={12} lg={6}>
                      <CustomSelect
                        label="Corresponding Task*"
                        name="Task_ID"
                        defaultValue={incident?.Task_ID || ""}
                        options={state.runbookTasks.map(task => ({
                          label: task.Name,
                          value: task.Task_ID
                        }))}
                        className={classes.dropdown}
                        helperClassName={classes.dropdownHelperText}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={12} lg={6}>
                      <LabelInput
                        name="Description"
                        label="Incident Description*"
                        variant="default"
                        margin="dense"
                        defaultValue={incident?.Description || ""}
                        rows={1}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={12} lg={6}>
                      <RadioInputGroup
                        row
                        name="Status"
                        type="datetime-local"
                        options={
                          incident?.Incident_ID ?
                            editStatusRadioOptions :
                            createStatusRadioOptions
                        }
                        size="small"
                        color="primary"
                        defaultValue={incident?.Status || "Open"}
                        groupLabel="Status*"
                        radioGroupClass={classes.radioGroupClass}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <DualFormButtons
                cancelOnClick={() => setIncident(null)}
                deleteClick={incident?.Incident_ID ? handleDelete : undefined}
              />
            </Form>
          </Box>)}
      </Dialog>
    </>
  )

}