import { Badge as MuiBadge } from "@material-ui/core";

export default function Badge(props) {
  const {
    alertCount,
    "data-cy": dataCy = "badge-header-alert",
    max = 999,
    overlap = "rectangular",
    ...muiProps
  } = props;

  return (
    <MuiBadge
      {...muiProps}
      badgeContent={alertCount}
      color="secondary"
      data-cy={dataCy}
      max={max}
      overlap={overlap}
    >
      {props.children}
    </MuiBadge>
  );
}
