import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import LabelInput from "components/utils/form-elements/labelInput.component";
import HeaderPrimary from "components/utils/header.component";
import ButtonDefault from "components/utils/buttonDefault.component";
import {
  Paper,
  Table,
  TableBody,
  TableRow,
} from "@material-ui/core";
import TableCell from "components/utils/tables/shared/tableCell.component";
import { stringsAreIdentical } from "utils/stringFuncs";
import EditableText from "components/utils/form-elements/editableText.component";
import { defaultChangeDate } from "utils/dateHelpers";
import { currentUserID } from "utils/userHelpers";
import { H5 } from "components/utils/headerV2.component";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  addInputSide: {
    flex: 1,
    paddingRight: 20,
  },
  listSide: {
    flex: 1,
    paddingLeft: 20,
  },
  addSectionIcon: {
    fontSize: "1rem",
    color: "white",
    marginRight: 6,
  },
  inputItemWrapper: {
    width: 300,
  },
  itemListBoxWrapper: {
    width: 290,
    maxHeight: 435,
    overflowX: "auto",
    marginTop: 2,
  },
  orderedList: {
    paddingLeft: 30,
    paddingTop: 15,
    paddingRight: 20,
  },
  itemListWithoutButtonWrapper: {
    marginBottom: 20,
  },
  listItem: {
    color: variables.textSecondary,
  },
}));

const CreateCustomAssessItems = ({
  builderId,
  createdItems,
  removeAssessItem,
  user,
  assessItemIdsWithReviews,
  handleSave,
  isSavingAssessmentItems,
}) => {
  const classes = useStyles();
  const [itemFormData, setItemFormData] = useState({ AssessmentItem: "" });
  const [editItemObj, setEditItemObj] = useState({});
  const numberOfCreatedItemsWithReveiws = createdItems.filter(item => assessItemIdsWithReviews.includes(item.AssessmentItem_ID)).length
  const disableDuringSave = isSavingAssessmentItems?.length > 0;

  const handleChange = (event) => {
    setItemFormData({
      ...itemFormData,
      [event.currentTarget.id]: event.target.value,
    });
  };

  const handleEnterEvent = (event) => {
    if (event.key === "Enter") {
      event.target.blur();
    }
  };

  const addNewItem = (item) => {
    if (item && item.trim() !== "") {
      // const tempAssessItems = [...assessItems];
      // let tempId = newTempId(tempAssessItems, "AssessmentItem_ID");
      const newItemObj = {
        AssessmentItem_ID: null,
        AssessmentBuilder_ID: builderId,
        ProgramComponent_ID: null,
        Broker_ID: null,
        Broker_ReferencedRow: null,
        AssessmentItem: item,
        Change_User: currentUserID(),
        Change_Date: defaultChangeDate(),
      };
      handleSave(2, [newItemObj])
      // setAssessItems([...tempAssessItems, itemObj]);
    }
    setItemFormData({ AssessmentItem: "" });
  };

  const clearItemList = () => {
    const itemsToDelete = [...createdItems]
      .filter(item => !assessItemIdsWithReviews.includes(item.AssessmentItem_ID))
      .map(item => ({...item, isDelete: true}))
    handleSave(2, null, itemsToDelete);
    // setAssessItems((prev) => prev.filter((item) => !assessItemIsCustom(item) || (assessItemIsCustom(item) && assessItemIdsWithReviews.includes(item.AssessmentItem_ID))));
  };

  const editMode = (item) => {
    if (!disableDuringSave) {
      setEditItemObj(item);
    }
  };

  const finishEdit = (editedItemText, itemID) => {
    const itemTextBeforeEdit = editItemObj.AssessmentItem;
    if (!stringsAreIdentical(itemTextBeforeEdit, editedItemText)) {
      const objectToEdit = createdItems.find(item => item.AssessmentItem_ID === itemID)
      const updatedEditedObj = {...objectToEdit, AssessmentItem: editedItemText, Change_Date: defaultChangeDate(), Change_User: currentUserID()}
      handleSave(2, null, null, [updatedEditedObj])
    }
    setEditItemObj({});
  };

  return (
    <div
      className={classes.container}
      data-cy="add-assessment-item-container"
    >
      <div className={classes.content}>
        {/* INPUT SIDE */}
        <div className={classes.addInputSide}>
          <H5 color="tertiary">
            {" "}
            Enter an Assessment Item
          </H5>
          <div className={classes.inputItemWrapper}>
            <LabelInput
              id="AssessmentItem"
              variant="default"
              value={itemFormData.AssessmentItem}
              onChange={(event) => handleChange(event)}
              onAutosave={() => addNewItem(itemFormData.AssessmentItem)}
              test="AssessmentItem"
              onKeyDown={(event) =>
                handleEnterEvent(event)}
              disabled={disableDuringSave}
            />
          </div>
        </div>

        {/* LIST */}
        <div className={classes.listSide}>
          <div className={classes.itemListWithoutButtonWrapper}>
            <HeaderPrimary variant="h5Tertiary">
              {" "}
              Created Items{" "}
            </HeaderPrimary>
            <Paper
              className={classes.itemListBoxWrapper}
              elevation={3}
              data-cy="created-items-list"
            >
              <Table>
                <TableBody className={classes.orderedList}>
                  {createdItems.length === 0 && (
                    <TableRow>
                      <TableCell className={classes.listItem}>
                        No Items Added
                      </TableCell>
                    </TableRow>
                  )}
                  {createdItems.map((item, index) => {
                    const itemID = item.AssessmentItem_ID;
                    const restrictedItem = assessItemIdsWithReviews.includes(itemID)
                    let tooltipMessage = null;
                    if (restrictedItem) {
                      tooltipMessage = `This item canmot be removed because a review has been started for it.`
                    }
                    return (
                      <TableRow key={itemID}>
                        <TableCell className={classes.listItem}>
                          <div>
                            <EditableText
                              cellItem={item.AssessmentItem}
                              removeClick={restrictedItem ? null : () => removeAssessItem(item)}
                              mode={
                                editItemObj.AssessmentItem_ID === itemID
                                  ? "edit"
                                  : "view"
                              }
                              textFieldId={`item_${itemID}`}
                              editItemId={itemID}
                              onAutosave={finishEdit}
                              editClick={() => editMode(item)}
                              initialEditValue={item.AssessmentItem}
                              onKeyDown={handleEnterEvent}
                              disableRemove={restrictedItem || disableDuringSave}
                              tooltipId={!!restrictedItem && `tooltip-${item.AssessmentItem_ID}`}
                              tooltipRemoveMessage={!!restrictedItem && tooltipMessage}
                            />
                          </div>

                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          </div>
          <div>
            <ButtonDefault
              disabled={(createdItems.length - numberOfCreatedItemsWithReveiws === 0) || disableDuringSave}
              variant="small"
              background="red"
              startIcon={
                <RemoveCircleOutlineIcon className={classes.addSectionIcon} />
              }
              onClick={() => {
                clearItemList();
              }}
              data-cy="clear-list"
            >
              Clear List
            </ButtonDefault>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCustomAssessItems;
