import React, { useCallback, useContext, useEffect, useMemo } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import { isReadOnly } from "utils/roles";
import FormElementBase from "./form-elements/shared/formElementBase.component";
import CustomFormHelperText from "components/utils/form-elements/styled/formHelperText/customFormHelperText.component"
import FormLabel from "components/utils/form-elements/formLabel.component";
import FormContext from "contexts/form.context";
/*
  https://www.npmjs.com/package/suneditor-react
  imageUploadSizeLimit - if changed, make appropriate changes in:
     1. suneditorCustom.css > .sun-editor .se-notice span:before
     2. sunEditorLabel.js > imageBox:title
*/

export const defaultButtons = [
  ["undo", "redo"],
  ["font", "fontSize"],
  ["bold", "underline", "italic"],
  ["fontColor"], // "hiliteColor", "textStyle" can be included
  ["removeFormat"],
  ["outdent", "indent"],
  ["align", "list"],
  ["table", "link", "image"], //"image", "video" can be included in the array
];

const basicToolbar = [
  ["undo", "redo"],
  ["font"],
  ["bold", "underline", "italic"],
  ["removeFormat"],
  ["outdent", "indent"],
  ["align", "list"],
]

const FORM_RENDER_TIMEOUT = 2000;

const CustomSunEditor = (props) => (
  <FormElementBase {...props}>
    <CustomSunEditorbase {...props} />
  </FormElementBase>
);

const CustomSunEditorbase = ({
  sectionDetail,
  height,
  setOptions: setOptionsProp,
  lang,
  placeholder,
  setContents,
  defaultValue,
  onAutosave,
  onChange,
  autoFocus,
  name,
  onFocus,
  onBlur,
  error,
  errorMessage,
  helperText,
  label,
  editorRef,
  required,
  disabled,
  labelTooltip,
  basicTextEditor,
}) => {
  require("suneditor/dist/css/suneditor.min.css")
  require("../../css/suneditorCustom.css");
  const readOnly = isReadOnly();
  const context = useContext(FormContext);

  const setOptions = useMemo(() => ({
    buttonList: basicTextEditor ? basicToolbar : defaultButtons,
    imageUploadSizeLimit: 2000000, //* if changed read note at top of file
    historyStackDelayTime: context ? 0 : undefined, // handle any delays manually
    font: [ 'Arial', 'tahoma', 'Courier New,Courier' ],
    fontSize: [ 11, 12, 13, 14, 16 ],
    ...setOptionsProp,
  }), [basicTextEditor, context, setOptionsProp]);

  const getCanMount = useCallback(() => (
    !onAutosave || !context || context.publishFormChange
  ), [context, onAutosave]);

  useEffect(() => {
    const timeout = window.setTimeout(() => {
      if (getCanMount()) {
        return;
      }
      console.error(
        "Attempted to use a SunEditor that autosaves without defining a parent <Form/>."
      );
    }, FORM_RENDER_TIMEOUT);
    return () => window.clearTimeout(timeout);
  }, [getCanMount]);

  const handleChange = value => {
    if (onAutosave) {
      context?.publishFormChange?.(context, name, value);
    }
    onChange?.(value);
  };

  const handleBlur = useCallback((event, contents) => {
    onBlur?.(event, contents, sectionDetail);
  }, [onBlur, sectionDetail]);

  // SunEditor sets the onChange handler on mount...
  //   so wait for context to be initialized first if needed (for autosave)
  if (!getCanMount()) {
    return null;
  }
  return (
    <div data-cy={`suneditor-wrapper-${name}`}>
      {!!label && (
        <FormLabel
          htmlFor={name}
          label={<>{label}{!!required && "*"}</>}
          variant="default"
          error={error}
          labelTooltip={labelTooltip}
        />
      )}
      <SunEditor
        setOptions={setOptions}
        setContents={setContents}
        placeholder={placeholder}
        autoFocus={!!autoFocus}
        height={height}
        defaultValue={defaultValue}
        onBlur={handleBlur}
        onChange={event => (() => handleChange(event))()} // Must be a new function to avoid SunEditor memoizing this
        lang={lang}
        disable={readOnly || disabled}
        enableToolbar={!readOnly && !disabled} //later versions will use disableToolbar prop
        name={name}
        onFocus={onFocus}
        ref={editorRef}
        setDefaultStyle="font-family: arial;"
      />
      <CustomFormHelperText
        id={`helper-${name}`}
        error={error}
      >
        {errorMessage || helperText}
      </CustomFormHelperText>
    </div>
  );
};

export default CustomSunEditor;
