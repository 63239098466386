import React, { useState, useEffect } from "react";
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from "@material-ui/core";
import TableCell from "components/utils/tables/shared/tableCell.component";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import classNames from "classnames";
import ImplementationService from "services/implementation.service";
import CustomModal from "components/utils/modal.component";
import CustomEditIcon from "components/utils/editIcon.component";
import DataScopeForm from "components/forms/dataScopeForm.component";

const useStyles = makeStyles((theme) => ({
  multiLevelHeaderTable: {
    borderCollapse: "separate",
    borderSpacing: 0,
  },
  tableContainer: {
    marginTop: 20,
    marginBottom: 20,
    maxHeight: 'calc(100vh - 230px)',
  },
  cellHeader: {
    backgroundColor: variables.primaryMain,
    color: "white",
    fontWeight: "bold",
    padding: "10px 20px",
    lineHeight: "normal",
    position: "sticky",
    top: 0,
  },
  cellBody: {
    padding: "10px 15px",
    color: variables.textSecondary,
    border: "1px solid rgba(22, 76, 146, 0.22)",
    fontSize: variables.fontSmall,
  },
  centerAlign: {
    textAlign: "center",
  },
  leftAlign: {
    textAlign: "left",
  },
  rightAlign: {
    textAlign: "right",
  },
  headerWrapper: {
    backgroundColor: "white",
  },
  topHeader: {
    backgroundColor: variables.secondaryMain,
    border: "1px solid white",
    position:"sticky",
    top: 0,
    fontSize: variables.fontLarge,
  },
  midHeader: {
    backgroundColor: variables.tertiary1,
    border: "1px solid white",
    position:"sticky",
    padding: "10px 20px",
    minWidth: 150,
    maxWidth: 150,
    top: 45,
    fontSize: variables.fontMedium,
  },
  bottomHeader: {
    backgroundColor: variables.primaryLight,
    border: "1px solid white",
    borderBottom: 0,
    position:"sticky",
    padding: "10px 20px",
    maxWidth: 150,
    top: 116,
    fontSize: variables.fontSmall,
  },
  headerRowLabel: {
    color: variables.textSecondary,
    fontWeight: "bold",
    fontSize: variables.fontMedium,
    padding: "10px 20px",
    position:"sticky",
    backgroundColor: "white",
  },
  headerRowLabelTop: {
    top: 0,
    borderTop: "1px solid white",
    borderBottom: `1px solid ${variables.rowBorder}`,
    fontSize: variables.fontLarge,
  },
  headerRowLabelBottom: {
    top: 116,
    fontSize: variables.fontSmall,
  },
  productsRowLabel: {
    color: variables.textSecondary,
    backgroundColor: "white",
    fontWeight: "bold",
    position:"sticky",
    top: 45,
    fontSize: variables.fontMedium,
    padding: "10px 20px",
    borderTop: "1px solid white",
    borderBottom: `1px solid ${variables.rowBorder}`,
  },
  privDataType: {
    backgroundColor: variables.primaryMain,
    padding: "5px 15px",
    fontSize: variables.fontSmall,
    position:"sticky",
    top: 166,
  },
  edgeColumn: {
    borderLeft: `2px solid ${variables.rowBorderDark}`,
  },
  boldCell: {
    fontWeight: "bold",
  },
  tableRowCustom: {
    '&:hover': {
      background: variables.rowHover,
    },
  },
}));



const edgeColumns = ["Internal_Corporate__Enterprise", "Identity_Guard", "FigLeaf", "Hotspot_Shield___VPN"]

const DataScope = () => {
  const [tableData, setTableData] = useState();
  const [tableColumn, setTableColumn] = useState();
  const [subProducts, setSubProducts] = useState();
  const { programId } = useParams();
  //MODAL
  const [editModal, setEditModal] = useState(false);
  const [product, setProduct] = useState({id: null, name: null})
  const [refresh, setRefresh] = useState(true)

  const toggleForm = () => {
    setEditModal(!editModal);
  };

  useEffect(() => {
    if (!refresh) {
      return;
    }
    setRefresh(false);
    ImplementationService.getGroupProducts(programId).then((response) => {
      if (response.payload) {
        setTableColumn(response.payload);
      }
      ImplementationService.getImplementationDataScope(programId).then(firstResponse => {
        if (firstResponse.payload) {
          setTableData(firstResponse.payload);
          ImplementationService.getImplementationSubProducts(programId).then(secondResponse => {
            if (secondResponse.payload) {
              setSubProducts(secondResponse.payload);
            }
          })
        }
      })
    });
  }, [programId, refresh]);

  const classes = useStyles();

  const openForm = (productId, productName) => {
    setEditModal(true)
    setProduct({id: productId, name: productName})
  }


  const setClasses = (cellType, alignment) => {

    let classString = ""; // holds all classes before building object

    // check cellType
    if (cellType === "header") {
      classString += ` ${classes.cellHeader}`;
    } else if (cellType === "body") {
      classString += ` ${classes.cellBody}`;
    }

    // check alignment
    if (alignment === "left") {
      classString += ` ${classes.leftAlign}`;
    } else if (alignment === "right") {
      classString += ` ${classes.rightAlign}`;
    } else if (alignment === "center") {
      classString += ` ${classes.centerAlign}`;
    }

    // build/return className object from string above
    return classString;
  }

  const title = "Privacy Data Scope";
  if (tableData && tableColumn && subProducts) {
    return (
      <>
        <TableContainer
          component={Paper}
          className={classes.tableContainer}
        >
          <Table className={classes.multiLevelHeaderTable}>
            <TableHead className={classes.headerWrapper}>
              <TableRow>
                <TableCell
                  className={classNames(
                    setClasses("", "right"),
                    classes.headerRowLabel, classes.headerRowLabelTop
                  )}
                >
                  Aura Group
                </TableCell>
                {tableColumn.map((row, index) => {
                  const groupLst = JSON.parse(row.GroupProducts);
                  return (
                    <TableCell
                      colSpan={groupLst.length}
                      className={classNames(
                        setClasses("header", "center"),
                        classes.topHeader
                      )}
                      key={`org_${index}`}
                    >
                      {row.OrganizationGroup_Name}
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                <TableCell
                  className={
                    classNames(
                      setClasses("", "right"),
                      classes.productsRowLabel
                    )
                  }
                >
                  Products
                </TableCell>
                {tableColumn.map((row) => {
                  let groupLst = JSON.parse(row.GroupProducts);
                  groupLst = groupLst.sort((a, b) => (a.GroupProduct_ID > b.GroupProduct_ID) ? 1 : -1)
                  return groupLst.map((groupRow, index) => {
                    return (
                      <TableCell
                        className={
                          classNames(
                            setClasses("header", "center"),
                            classes.midHeader
                          )
                        }
                        key={`group_${index}`}
                      >
                        {groupRow.Product_Name}
                        <CustomEditIcon variant="header" onClick={() => openForm(groupRow.GroupProduct_ID, groupRow.Product_Name)} />
                      </TableCell>
                    );
                  });
                })}
              </TableRow>

              <TableRow>
                <TableCell
                  className={
                    classNames(
                      setClasses("", "right"),
                      classes.headerRowLabel, classes.headerRowLabelBottom
                    )
                  }
                >
                  Sub Products
                </TableCell>
                {tableColumn.map((row) => {
                  let groupLst = JSON.parse(row.GroupProducts);
                  groupLst = groupLst.sort((a, b) => (a.GroupProduct_ID > b.GroupProduct_ID) ? 1 : -1)
                  return groupLst.map((groupRow, index) => {
                    const groupSubs = subProducts.filter(
                      (item) =>
                        item.GroupProducts_GroupProduct_ID ===
                        groupRow.GroupProduct_ID
                    );
                    const sub = groupSubs.length > 0 ? groupSubs[0].Sub_Name : "";
                    return (
                      <TableCell
                        className={
                          classNames(
                            setClasses("header", "center"),
                            classes.bottomHeader
                          )
                        }
                        key={`sub_${index}`}
                      >
                        {sub}
                      </TableCell>
                    );
                  });
                })}
              </TableRow>
              <TableRow>
                <TableCell
                  className={
                    classNames(
                      setClasses("header", "left"),
                      classes.privDataType
                    )
                  }
                >
                Privacy Data Types
                </TableCell>
                <TableCell
                  colSpan="9"
                  className={
                    classNames(
                      setClasses("header", "left"),
                      classes.privDataType
                    )
                  }
                />
              </TableRow>
            </TableHead>
            {(tableData.length === 0) && (
              <TableBody>
                <TableRow >
                  <TableCell
                    className={setClasses("body", "left")}
                    colSpan="10"
                    scope="row"
                  >
                    No {title}
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {tableData.length > 0 && (
              <TableBody>
                {tableData.map((row, index) => {
                  const cells = [];
                  Object.keys(row).forEach((key) => {
                    const alignment = key === "Type_Name" ? "left" : "center";
                    cells.push(
                      <TableCell
                        className={
                          classNames(
                            setClasses("body", alignment),
                            edgeColumns.includes(key) && classes.edgeColumn,
                            key === "Type_Name" && classes.boldCell
                          )
                        }
                        key={`cellBody_${Math.random()}`}
                      >
                        {row[key]}
                      </TableCell>
                    );
                  });
                  return (
                    <TableRow className={classes.tableRowCustom} key={index}>
                      {cells}
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <CustomModal open={editModal} onClose={toggleForm}>
          <DataScopeForm
            setEditModal={setEditModal}
            product={product}
            setRefresh={setRefresh}
            subProducts={subProducts}
          />
        </CustomModal>
      </>
    );
  } else {
    return ""
  }
};

export default DataScope;
