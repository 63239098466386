import LabelInput from "components/utils/form-elements/labelInput.component";
import Form from "components/utils/form-elements/form.component";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import ButtonDefault from "components/utils/buttonDefault.component";
import BlockIcon from "@material-ui/icons/Block";
import SaveIcon from "@material-ui/icons/Save";

const useStyles = makeStyles((theme) => ({
  buttonsWrapper: {
    display: "flex",
  },
  icon: {
    fontSize: variables.fontH3,
    margin: "0px 12px",
  },
}));

const CommentForm = ({
  formName,
  onSubmit,
  inputName,
  cancelOnClick,
  defaultValue,
  inputRef,
  placeholder,
}) => {
  const classes = useStyles();

  return (
    <Form name={formName} onSubmit={onSubmit}>
      <LabelInput
        multiline
        variant="default"
        defaultValue={defaultValue || ""}
        name={inputName}
        autoFocus
        inputRef={inputRef}
        placeholder={placeholder}
        test={formName}
      />
      <div className={classes.buttonsWrapper}>
        <ButtonDefault
          onClick={cancelOnClick}
          variant="iconSmall"
          color="tertiary"
          startIcon={<BlockIcon className={classes.icon} />}
          disableReadOnlyUsers
        >
          {formName === "new-comment-form" ? "Clear" : "Cancel"}
        </ButtonDefault>
        <ButtonDefault
          variant="iconSmall"
          type="submit"
          color="primary"
          startIcon={<SaveIcon className={classes.icon} />}
          disableReadOnlyUsers
          data-cy={`btn-save-${formName}`}
        >
          Save
        </ButtonDefault>
      </div>
    </Form>
  );
};

export default CommentForm;
