const newTempId = (dataArray, idFieldName) => {
  if (dataArray.length === 0) {
    return "1_new";
  } else {
    const tempIds = dataArray.map(
      (element) => parseInt(element[idFieldName].toString().split("_")[0]) //array of id #s
    );
    return Math.max(...tempIds) + 1 + "_new";
  }
}

const newTempFromArr = (newFirstID, index) => {
  return parseInt(newFirstID.split("_")[0]) + index + "_new"

}

const excludeNewTags = id => {
  if (typeof id === "string" && id.includes("_new")) {
    return undefined;
  }
  return id;
}

const stripNewTag = id => {
  if (typeof id !== "string") {
    return id;
  }
  const idParts = id.split("_");
  if (idParts.length <= 1) {
    return id;
  }
  return parseInt(id.split("_")[0], 10);
}

export { newTempId, newTempFromArr, excludeNewTags, stripNewTag };
