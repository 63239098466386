import React, { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { Box, Popover } from "@material-ui/core";
import CustomCard from "components/utils/card.component";
import CustomDataTable from "components/utils/tables/dataTable.component"
import variables from "styleVariables";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "components/utils/loader.components";
import CertificationService from "services/certification.service";
import CustomModal from "components/utils/modal.component";
import { LetterOfComplianceDetail } from "components/certification/letterOfCompliance/letterOfComplianceDetail.component";
import AddLOCReferenceForm from "components/forms/addLOCReferenceForm.component";
import useTargetTableRowViaLink from "hooks/useTargetTableRowViaLink";
import { createNodeCellParams, createNodeHeaderParams } from "components/utils/tables/utils/dataTableHelpers";
import classNames from "classnames";
import Form from "components/utils/form-elements/form.component";
import LOCDetailLinkCell from "./table/cells/locDetailLinkCell.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import LOCApplicabilityCell from "./table/cells/locApplicabilityCell.component";
import LOCReferencesCell from "./table/cells/locReferencesCell.component";
import { mapObjectArrayByKey } from "utils/arrayOfObjectsHelpers";
import CustomDrawer from "components/utils/customDrawer.component";

const useStyles = makeStyles((theme) => ({
  matrixContainer: {
    marginTop: "40px",
    marginBottom: "50vh",
  },
  paperRoot: {
    borderRadius: "0px 10px 10px 10px",
  },
  popOverWrapper: {
    maxWidth: 350,

    [theme.breakpoints.down('md')]: {
      maxWidth: 250,
    },
  },
  descriptionWrapper: {
    paddingTop: 8,
    fontSize: variables.fontSmall,
    color: variables.textSecondary,
    lineHeight: "1.3rem",
  },
  headerPart: {
    minWidth: 85,
    maxWidth: 85,
  },
  headerParagraph: {
    minWidth: 100,
    maxWidth: 100,
  },
  headerPadded: {
    padding: "10px 20px"
  },
  applicability: {
    width: 150
  },
  cardTitleWrapper: {
    fontSize: variables.fontH5,
    color: variables.primaryMain,
    fontWeight: "bold"
  },
}));

const REGEXP_SECTION_SUBPARAGRAPH = /([0-9.]+)([A-Za-z])/;

const BASE_SUBPARAGRAPH_CHAR_CODE = "a".charCodeAt(0) - 10;


const LetterOfComplianceTable = () => {
  const classes = useStyles();
  const [regulation, setRegulation] = useState({});
  const [regulationSec, setRegulationSec] = useState({});
  //for popover
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [locData, setLOCData] = useState();
  const [cfrData, setCFRData] = useState();
  const [refFormOpen, setRefFormOpen] = useState(false);
  const [activeLOCItem, setActiveLOCItem] = useState({});
  const rowRef = useRef({});
  const [manualData, setManualData] = useState();
  const [targetedRowId] = useTargetTableRowViaLink(rowRef);

  const tableOptions = useMemo(() => ({
    filterType: "checkbox",
    fixedHeader: true,
    filter: true,
    fixedSelectColumn: true,
    pagination: true,
    rowsPerPage: 25,
    rowsPerPageOptions: [10, 25, 50],
    searchOpen: true,
    selectableRowsHideCheckboxes: true,
    setRowProps: row => {
      if (
        row[0].props &&
        row[0].props.children.props.cellProps.LOC_ID === targetedRowId
      ) {
        return { style: { backgroundColor: variables.chosenElement } }
      }
    },
    sortFilterList: false,
    sortOrder: { name: "part", direction: "asc" },
  }), [targetedRowId]);

  const replaceOneLocItem = useCallback((locItem, index) => {
    const updatedLOCItem = {
      ...(locData[index] || {}),
      ...locItem,
    };
    const updatedLOCData = locData.slice(0);
    updatedLOCData.splice(index, 1, updatedLOCItem);
    setLOCData(updatedLOCData);
  }, [locData]);

  const handleClose = () => {
    setAnchorEl(null);
    setRegulation({});
    setRegulationSec({});
  };

  const handleDetailView = ((cellProps) => {
    setDetailModalOpen(true);
    setModalData(cellProps);
  });

  const updateLocItem = useCallback(async (locId, locIndex, name, value) => {
    const updatedLetterOfComplianceRes = await CertificationService.updateLetterOfComplianceData(
      locId,
      { [name]: value },
    );
    const updatedLOCItem = updatedLetterOfComplianceRes.payload;
    replaceOneLocItem(updatedLOCItem, locIndex);
  }, [replaceOneLocItem]);

  const handleFieldAutosave = useCallback(async (name, value, event) => {
    const { dataset } = event.target;
    return updateLocItem(dataset.locId, dataset.index, name, value);
  }, [updateLocItem]);

  const handleSelectAutosave = useCallback(async (locId, locIndex, event) => (
    updateLocItem(locId, locIndex, event.target.name, event.target.value)
  ), [updateLocItem]);

  useEffect(() => {
    (async function fetchTableData() {
      const [
        manualRes,
        letterOfComplianceDataRes,
        cfrDataRes
      ] = await Promise.all([
        CertificationService.getManualData(),
        CertificationService.getLetterOfComplianceData(),
        CertificationService.getAllCFRData(),
      ]);

      setManualData(manualRes.payload);
      setLOCData(letterOfComplianceDataRes.payload);
      setCFRData(cfrDataRes.payload);
    })();
  }, [setLOCData]);

  const cfrParagraphItemsById = useMemo(() => (
    cfrData && mapObjectArrayByKey(cfrData.paragraphs, "CFR_ID")
  ), [cfrData]);

  const partValues = useMemo(() => (
    locData &&
    Array.from(new Set(
      locData.map(loc => loc.Part_Label)
    )).filter(label => label)
  ), [locData]);

  const subpartValues = useMemo(() => (
    locData &&
    Array.from(new Set(
      locData.map(loc => loc.Subpart_Label)
    )).filter(label => label)
  ), [locData]);

  const sectionValues = useMemo(() => (
    locData &&
    Array.from(new Set(
      locData.map(loc => loc.Section_Label)
    )).filter(label => label)
  ), [locData]);

  const paragraphLOCItemsById = useMemo(() => (
    !locData ? {} : (
      locData.filter(loc => loc.Paragraph_Label).reduce((accumulator, loc) => {
        accumulator[loc.CFR_ID] = loc;
        return accumulator;
      }, {})
    )
  ), [locData]);

  const getParentParagraphLabel = useCallback((locItem, currentLabel = "") => {
    let output = "";
    if (locItem.Paragraph_Label) {
      if (locItem.Parent_CFR_ID) {
        const parentParagraph = paragraphLOCItemsById[locItem.Parent_CFR_ID];
        if (parentParagraph?.Paragraph_Label) {
          output += `${getParentParagraphLabel(parentParagraph)} `;
        }
      }
      output += locItem.Paragraph_Label;
    }
    if (currentLabel) {
      output += ` ${currentLabel}`;
    }
    return output;
  }, [paragraphLOCItemsById])

  const tableHeaders = useMemo(() => ([
    createNodeHeaderParams("part", "Part", {
      align: "left",
      setCellHeaderProps: () => ({ className: classes.headerPart }),
      filter: true,
      filterChipCategoryVisible: true,
      filterOptions: { names: partValues },
      filterType: "multiselect",
    }),
    createNodeHeaderParams("subpart", "Subpart", {
      align: "center",
      setCellHeaderProps: () => ({ className: classes.headerPart }),
      filter: true,
      filterChipCategoryVisible: true,
      filterOptions: { names: subpartValues },
      filterType: "multiselect",
    }),
    createNodeHeaderParams("section", "Section", {
      align: "center",
      setCellHeaderProps: () => ({ className: classes.headerPart }),
      filter: true,
      filterChipCategoryVisible: true,
      filterOptions: { names: sectionValues },
      filterType: "multiselect",
    }),
    createNodeHeaderParams("paragraph", "Paragraph", {
      align: "center",
      setCellHeaderProps: () => ({ className: classes.headerParagraph }),
      filter: false,
      filterChipCategoryVisible: true,
      filterType: "multiselect",
      sort: false,
    }),
    createNodeHeaderParams("applicability", "Applicability", {
      align: "left",
      emptyFilterLabel: "Not Specified",
      filterChipCategoryVisible: true,
      filterOptions: { names: ["Yes", "No", ""] },
      setCellHeaderProps: () => ({
        className: classNames(classes.headerPadded, classes.applicability)
      }),
      sort: false,
    }),
    createNodeHeaderParams("references", "References", {
      align: "left",
      setCellHeaderProps: () => ({ className: classes.headerPadded }),
      filter: false,
      sort: false,
    }),
    createNodeHeaderParams("locComments", "Comments", {
      align: "left",
      setCellHeaderProps: () => ({ className: classes.headerPadded }),
      filter: false,
      sort: false,
    })
  ]), [classes, partValues, sectionValues, subpartValues]);

  const tableCells = useMemo(() => {
    return cfrParagraphItemsById && locData?.map?.((row, index) => {
      const cfrParagraphItem = cfrParagraphItemsById[row.CFR_ID];

      const partProps = {
        LOC_ID: row.LOC_ID,
        CFR_ID: row.CFR_ID,
        Part_Label: row.Part_Label,
        Part_Title: row.Part_Title,
        CFR_Type: row.CFR_Type,
        Parent_CFR_ID: row.Parent_CFR_ID,
        index
      }

      const subpartProps = {
        ...partProps,
        Subpart_Label: row.Subpart_Label,
        Subpart_Title: row.Subpart_Title,
      }

      const sectionProps = {
        ...subpartProps,
        Section_Label: row.Section_Label,
        Section_Title: row.Section_Title,
      }

      const paragraphProps = {
        ...sectionProps,
        Paragraph_Label: row.Paragraph_Label,
        Paragraph_Content: row.Paragraph_Content,
      };

      const locProps = {
        LOC_ID: row.LOC_ID,
        RegFramework_ID: row.RegFramework_ID,
        Comments: row.Comments,
        Applicability: row.Applicability,
        NA_Comments: row.NA_Comments,
        Input_Date: row.Input_Date,
        Change_User: row.Change_User,
        Change_Date: row.Change_Date,
        References: row._associations?.LOCReferences,
        partProps,
        subpartProps,
        sectionProps,
        paragraphProps,
        index
      }

      let fullParagraphLabel = getParentParagraphLabel(row);
      if (!fullParagraphLabel && cfrParagraphItem) {
        fullParagraphLabel = cfrParagraphItem.CFR_Order;
      }

      let sectionSortValue = !row.Section_Label ? "" : (
        Number(row.Section_Label.split("-")[0])
      );
      if (isNaN(sectionSortValue)) {
        const sectionSubparagraphMatches = (
          row.Section_Label.match(REGEXP_SECTION_SUBPARAGRAPH) || []
        );
        const paragraph = sectionSubparagraphMatches?.[1];
        const subparagraph = sectionSubparagraphMatches?.[2];
        if (subparagraph) {
          const numericCharCode = Number(
            subparagraph.charCodeAt(0) - BASE_SUBPARAGRAPH_CHAR_CODE
          );
          sectionSortValue = Number(`${paragraph}${numericCharCode}`);
        }
        if (!sectionSortValue) {
          sectionSortValue = row.Section_Label;
        }
      }

      const referenceSearchValue = locProps.References?.reduce?.(
        (accumulator, reference) => (
          `${accumulator} ${reference.Manual_Title} ${reference.Header}`
        ),
        ""
      );

      return {
        id: row.LOC_ID,
        part: createNodeCellParams(sectionSortValue, row.Part_Label, (
          <div ref={(el) => (rowRef.current[row.LOC_ID] = el)}>
            <LOCDetailLinkCell
              cellProps={partProps}
              label={row.Part_Label}
              align="left"
              onClick={handleDetailView}
            />
          </div>
        )),
        subpart: createNodeCellParams(row.Subpart_Label, row.Subpart_Label, (
          <LOCDetailLinkCell
            cellProps={subpartProps}
            label={row.Subpart_Label}
            onClick={handleDetailView}
          />
        )),
        section: createNodeCellParams(sectionSortValue, row.Section_Label, (
          <LOCDetailLinkCell
            cellProps={sectionProps}
            label={row.Section_Label ? `§ ${row.Section_Label}` : ""}
            onClick={handleDetailView}
          />
        )),
        paragraph: createNodeCellParams(null, row.Paragraph_Label, (
          <LOCDetailLinkCell
            cellProps={paragraphProps}
            label={fullParagraphLabel}
            onClick={handleDetailView}
          />
        )),
        applicability: createNodeCellParams(null, row.Applicability, (
          <LOCApplicabilityCell
            align="left"
            cellProps={locProps}
            index={index}
            onFieldAutosave={handleFieldAutosave}
            onSelectChange={handleSelectAutosave.bind(null, row.LOC_ID, index)}
          />
        )),
        references: createNodeCellParams(null, referenceSearchValue, (
          <LOCReferencesCell
            fieldKey="References"
            cellProps={locProps}
            align="left"
            variant="references"
            onAutosave={handleFieldAutosave}
            setRefFormOpen={setRefFormOpen}
            setActiveLOCItem={setActiveLOCItem}
          />
        )),
        locComments: createNodeCellParams(
          null,
          `${row.Comments}|${row.NA_Comments}`,
          (
            <LabelInput
              name="comments"
              defaultValue={row.Comments}
              placeholder="Add comment"
              margin="dense"
              variant="default"
              onAutosave={handleFieldAutosave}
              inputProps={{
                "data-loc-id": row.LOC_ID,
                "data-index": index
              }}
              hideHelperText
              test={`CFR-${row?.CFR_ID}-comments`}
            />
          )
        ),
      }
    });
  }, [
    cfrParagraphItemsById, getParentParagraphLabel,
    handleFieldAutosave, handleSelectAutosave, locData
  ]);

  if (locData?.length > 0 && manualData && cfrData && locData) {
    return (
      <div className={classes.matrixContainer}>
        <CustomDrawer
          open={detailModalOpen}
          setOpen={setDetailModalOpen}
          buttonText="CFR Regulation Detail"
        >{!!detailModalOpen && (
            <LetterOfComplianceDetail
              modalData={modalData}
              setEditModal={setDetailModalOpen}
              cfrData={cfrData}
              setModalData={setModalData}
            />
          )
          }
        </CustomDrawer>
        <CustomModal
          open={refFormOpen}
          onClose={() => setRefFormOpen(false)}
        >
          <AddLOCReferenceForm
            setFormOpen={setRefFormOpen}
            activeLOCItem={activeLOCItem}
            manualData={manualData}
            onSuccess={replaceOneLocItem}
          />
        </CustomModal>
        <Form name="loc-table">
          <CustomDataTable
            data={tableCells}
            columns={tableHeaders}
            options={tableOptions}
            // EXCEL DOWNLOAD INFO
            excelColumns={tableHeaders}
            excelData={tableCells}
          />
        </Form>
        <Popover
          id={id}
          open={open}
          classes={{ paper: classes.paperRoot }}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={
            {
              vertical: "bottom",
              horizontal: "right",
            }
          }
          transformOrigin={
            {
              vertical: "top",
              horizontal: "left",
            }
          }
        >
          {!!regulationSec && !!regulationSec.contents && (
            <div className={classes.popOverWrapper}>
              <CustomCard variant="plain">
                <Box>
                  <div className={classes.cardTitleWrapper}>
                    {regulationSec.name} acs
                  </div>

                  <div className={classes.descriptionWrapper}>
                    {regulationSec.contents}
                  </div>
                </Box>
              </CustomCard>
            </div>
          )}
          {!!regulation && !!regulation.description && (
            <div className={classes.popOverWrapper}>
              <CustomCard variant="plain">
                <Box>
                  <div className={classes.cardTitleWrapper}>
                    {regulation.name}
                  </div>
                  <div className={classes.descriptionWrapper}>
                    {regulation.description}
                  </div>
                </Box>
              </CustomCard>
            </div>
          )}
        </Popover>
      </div>
    );
  } else {
    return <Loader />;
  }
};

export default LetterOfComplianceTable;
