import UserService from "services/user.service";
const jwt = require("jsonwebtoken");

const currentUserInfo = () => {
  const codedToken = sessionStorage.getItem("accessToken");
  const parsedToken = JSON.parse(codedToken);
  const decodedToken = jwt.decode(parsedToken);
  if (decodedToken) {
    return decodedToken
  } else {
    return JSON.parse(sessionStorage.getItem("accessToken"));
  }
};

const currentUserID = () => {
  return jwt.decode(JSON.parse(sessionStorage.getItem("accessToken"))).id;
};

const findUserNameByID = async (userId) => {
  if(userId) {
    const userName = await UserService.getUserById(userId)
    return (`${userName.payload[0].First_Name} ${userName.payload[0].Last_Name}`)
  }

}

const setUserInfo = (user) => {
  return sessionStorage.setItem("accessToken", JSON.stringify(user));
}

const currentUserToken = () => {
  return JSON.parse(sessionStorage.getItem("accessToken"));
}

const setRefreshToken = (token) => {
  return sessionStorage.setItem("refreshToken", JSON.stringify(token));
}

const currentRefreshToken = () => {
  return JSON.parse(sessionStorage.getItem("refreshToken"));
}

const isActiveUser = (user) => user.Status === "Active"

export {
  currentUserInfo,
  currentUserID,
  findUserNameByID,
  setUserInfo,
  currentUserToken,
  setRefreshToken,
  currentRefreshToken,
  isActiveUser,
};
