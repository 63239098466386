import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ButtonDefault from "components/utils/buttonDefault.component";
import BackupRoundedIcon from "@material-ui/icons/BackupRounded";
import Logo from "components/utils/logo.component.js";
import variables from "styleVariables";
import FormLabel from "components/utils/form-elements/formLabel.component";
import CustomFormHelperText from "components/utils/form-elements/styled/formHelperText/customFormHelperText.component"
import { acceptableImageExtensionTypes } from "utils/fileConstants";

// styles
const useStyles = makeStyles({
  uploadIcon: {
    fontSize: variables.fontLarge,
    marginRight: 5,
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    maxWidth: 200,
  },
  uploadLogoButtonContainer: {
    display: "flex",
    paddingLeft: 20,
  },
  uploadLogoWrapper: {
    display: "flex",
    alignItems: "center",
  },
  // COMPRESSED
  // uploadLogoButtonContainerCompressed: {
  //   position: "absolute",
  //   top: 0,
  //   right: -30,
  // },
  // uploadIconCompressed: {
  //   fontSize: variables.fontLarge,
  // },
  labelWrapper: {
    paddingBottom: 4,
  },
  helperText: {
    color: variables.secondaryMain,
    fontSize: variables.fontXxs,
    fontWeight: "bold",
    textAlign: "right",
    backgroundColor: "white",
    borderRadius: "0px 0px 3px 3px",
    padding: "2px 5px 0px 0px",
    margin: "8px 0px 0px 0px",
    height: 17,
    width: "100%",
  },
});

const LogoPicker = (props) => {
  const classes = useStyles();
  const { logoUpload, logoId } = props;

  /*
  // BANNER VERSION
  if (props.variant === "compressed") {
    return (
      <div className={classes.compressed}>
        <div className={classes.uploadLogoWrapper}>
          <div className={classes.logoContainer}>
            <Logo
              picker
              logoId={logo}
              update={update}
              className={classes.logoImage}
            />
          </div>
          {AuthService.checkPermissions(null, [ROLES.MANAGER, ROLES.ALLMANAGER]) &&
            <div className={classes.uploadLogoButtonContainerCompressed}>
              <ButtonDefault
                variant="iconButtonSmallPrimary"
                startIcon={
                  <BackupRoundedIcon className={classes.uploadIconCompressed} />
                }
                component="label"
              >
                Upload Logo
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={logoUpload}
                  key={inputKey}
                />
              </ButtonDefault>
            </div>
          }
        </div>
      </div>
    );
  }
*/
  return (
    <div>
      {!!props.label && (
        <FormLabel
          label={props.label}
          variant="default"
          className={classes.labelWrapper}
          labelTooltip={props.labelTooltip}
          sublabel={props.sublabel}
        />
      )}
      <div className={classes.uploadLogoWrapper}>
        <div className={classes.logoContainer}>
          <Logo
            variant="picker"
            logoId={logoId || null}
            alt={props.alt}
          />
        </div>
        <div className={classes.uploadLogoButtonContainer}>
          <ButtonDefault
            variant="small"
            background="primary"
            startIcon={<BackupRoundedIcon className={classes.uploadIcon} />}
            component="label"
            disabled={props.disabled}
            disableReadOnlyUsers
          >
            Upload Logo
            <input
              type="file"
              accept={acceptableImageExtensionTypes.toString()}
              style={{ display: "none" }}
              onChange={logoUpload}
            />
          </ButtonDefault>
        </div>
      </div>
      <CustomFormHelperText
        className={classes.helperText}
        error={
          typeof props.errorMessage === "string" && props.errorMessage !== ""
        }
      >
        {props.errorMessage || props.helperText}
      </CustomFormHelperText>
    </div>
  );
};

export default LogoPicker;
