import authService from "services/auth.service";
import { validEmail } from "utils/validators";


export function getPortfolioUserPermission(
  user, holdingCoId = null, portCoId = null
) {
  if (!user) {
    return null;
  }
  if (portCoId) {
    const portCoMatch = user._associations.PortfolioGroupPermissions?.find?.(
      permission => permission.PortCo_ID === portCoId
    );
    if (portCoMatch) {
      return portCoMatch;
    }
  }
  if (holdingCoId) {
    const holdingMatch = user._associations.HoldingGroupPermissions?.find?.(
      permission => permission.PortfolioHolder_ID === holdingCoId
    );
    return holdingMatch || null;
  }
  return null;
}

export function getAnyPortfolioUserPermission(
  user, holdingCoId = null, portCoId = null
) {
  if (!user) {
    return null;
  }
  const directMatch = getPortfolioUserPermission(user, holdingCoId, portCoId);
  if (directMatch) {
    return directMatch;
  }
  if (user._associations.PortfolioGroupPermissions.length) {
    return user._associations.PortfolioGroupPermissions[0];
  }
  if (user._associations.HoldingGroupPermissions.length) {
    return user._associations.HoldingGroupPermissions[0];
  }
  return null;
}


export function getPortfolioRoleTitle(portfolioUser) {
  // Switch to role-based title
  switch (portfolioUser?.Role_ID) {
    case authService.Type.READONLY:
    case authService.Type.EDIT:
      return "Portal User";
    case authService.Type.ADMIN:
      return "Portal Admin";
    default:
      return "";
  }
}

export function getPortfolioUserTitle(portfolioUser, holdingId, portCoId) {
  if (!portfolioUser) {
    return null;
  }
  const permission = getPortfolioUserPermission(portfolioUser, holdingId, portCoId);
  return (
    permission?.Title ||
    portfolioUser.Position ||
    getPortfolioRoleTitle(portfolioUser)
  );
}

/*
 * Find any Position/Title associated to the user in this order:
 * 1. Matching PortfolioGroupPermissions Title
 * 2. Matching HoldingGroupPermissions Title
 * 3. Position column value on the User (via UserInfo)
 * 4. Any Title associated with non-matching porfolio/holding permissions
 * 5. User Role-based position name
 */
export function getAnyPortfolioUserTitle(
  portfolioUser, holdingId = null, portCoId = null
) {
  if (!portfolioUser) {
    return null;
  }
  if (holdingId || portCoId) {
    const exactPermissionMatch = getPortfolioUserPermission(
      portfolioUser, holdingId, portCoId
    );
    if (exactPermissionMatch) {
      return exactPermissionMatch?.Title;
    }
  }
  if (portfolioUser.Position) {
    return portfolioUser.Position;
  }
  const adjacentPermission = getAnyPortfolioUserPermission(
    portfolioUser, holdingId, portCoId
  );
  return (
    adjacentPermission?.Title ||
    getPortfolioRoleTitle(portfolioUser)
  );
}

export const validateUserFormErrors = (formState) => {
  const error = {};
  if (!formState.First_Name) {
    error.First_Name = "First Name is required.";
  }
  if (!formState.Last_Name) {
    error.Last_Name = "Last Name is required.";
  }
  if (!formState.Email) {
    error.Email = "Email is required.";
  } else if (!validEmail(formState.Email)) {
    error.Email = "The email format is invalid.";
  }
  if (!formState.Title) {
    error.Title = "Title is required.";
  }
  return error;
};
