export const portCoDemoTypes = {
  Logo: "Logo",
  Website: "Website",
  Industry: "Industry",
  Summary: "Summary",
  Headquarters: "Headquarters",
  Regions: "Regions",
  Markets: "Markets",
  B2B: "B2B",
  B2C: "B2C",
  Ownership: "Ownership",
  Regulators: "Regulators",
  Recent_News: "Recent News",
  Breaches: "Breaches",
  Scheduling_Priority: "Scheduling Priority",
  POC_Name: "POC Name",
  POC_Email: "POC Email",
  POC_Number: "POC Number",
  Internal_Team_Lead: "Internal Team Lead",
  Notes: "Notes"
}

export const portCoDemoTypeIDMap = {
  Logo: 1,
  Website: 2,
  Industry: 3,
  Summary: 4,
  Headquarters: 5,
  Regions: 6,
  Markets: 7,
  B2B: 8,
  B2C: 9,
  Ownership: 10,
  Regulators: 11,
  Recent_News: 12,
  Breaches: 13,
  Scheduling_Priority: 14,
  POC_Name: 15,
  POC_Email: 16,
  POC_Number: 17,
  Internal_Team_Lead: 18,
  Notes: 19,
}

export const sessionFormModes = {
  CREATE: "create",
  EDIT: "edit",
  BOOK_PORTCO: "bookPorCo",
  PORTCO_ATTENDEES: "portCoAttendees",
};

export const sessionTypes = {
  DATA_COLLECTION: "Data Collection",
  RECONCILE: "Reconcile",
};

export const followUpTypes = {
  DOCUMENT: "Document",
  MEETING: "Meeting",
};

// Todo: These shouldn't be defined on the frontend unless absolutely necessary.
// Use TypeRef constants below instead.
export const portfolioProgramComponentIDs = {
  ASSESSMENTS: 82,
  SCHEDULING: 83,
  REPORTS: 84
};

export const PROGRAM_COMPONENT_TYPE_REF_ASSESSMENTS = "Assessments";
export const PROGRAM_COMPONENT_TYPE_REF_SCHEDULING = "Scheduling";
export const PROGRAM_COMPONENT_TYPE_REF_REPORTS = "Reports";
