import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CustomEditIcon from "./editIcon.component";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  contents: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  centerAlign: {
    justifyContent: "center",
  },
  leftAlign: {
    justifyContent: "flex-start",
  },
  labelWrapper: {
    paddingRight: 20,
  },
}));

export const TableHeadCell = (props) => {
  const classes = useStyles();
  if (props.variant === "editCenterAlign") {
    return (
      <div className={classNames(classes.contents, classes.centerAlign)}>
        <div className={classes.labelWrapper}>
          {props.text}
        </div>
        <CustomEditIcon variant="headerInline" onClick={props.onClick} test={props.test} />
      </div>
    );
  } else if (props.variant === "editLeftAlign") {
    return (
      <div className={classNames(classes.contents, classes.leftAlign)}>
        <div className={classes.labelWrapper}>
          {props.text}
        </div>
        <CustomEditIcon variant="headerInline" onClick={props.onClick} test={props.test} />
      </div>
    );
  } else {
      //No Edit Icon
    return <div className={classes.contents}>{props.text}</div>;
  }
};

export default TableHeadCell;
