import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, FormControl, Typography, Box } from "@material-ui/core";
import HeaderPrimary from "components/utils/header.component";
import ButtonDefault from "components/utils/buttonDefault.component";
import CustomSelect from "components/utils/form-elements/select.component";
import variables from "styleVariables";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/RemoveRedEye";
import ClipLoader from "components/utils/clipLoad.component";
import Dialog from "components/utils/dialog.component";
import { css } from "@emotion/core";
import classNames from "classnames";
import { getDateString } from "utils/formatters";
import { assessBuilderStatus, assessmentBuilderModes } from "utils/assessmentConstants";
import AssessmentCardDetail from "components/builder/info-card/assessmentCardDetail.component";
import AssessmentCardButtons from "components/builder/info-card/assessmentCardButtons.component";
import { compTypeCheck } from "utils/builderFunctions";
import { builderTypes } from "utils/builderConstants";

const moment = require("moment");

// For ClipLoader styling
const override = css`
  margin-right: 4px;
`;

const useStyles = makeStyles((theme) => ({
  builderCardWrapper: {
    paddingBottom: 30,
    paddingRight: 30,
    paddingLeft: 30,
    display: "flex",
    [theme.breakpoints.down("md")]: {
      paddingRight: 40,
      paddingLeft: 10,
      justifyContent: "center",
    },
  },
  builderCard: {
    boxShadow:
      "0px 3px 3px -2px rgb(0 0 0 / 20%), 4px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
    borderRadius: 40,
    paddingTop: 33,
    paddingBottom: 40,
    paddingRight: 40,
    paddingLeft: 60,
    maxWidth: "fit-content",
    display: "flex",
    flexDirection: "row",
    position: "relative",
    minWidth: 660,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      minWidth: "unset",
      paddingLeft: 50,
    },
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 15,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
  },
  titleContainer: {
    display: "flex",
    paddingRight: 15,
    paddingBottom: 10,
    maxWidth: 535,
    wordBreak: "break-word",
    [theme.breakpoints.down("md")]: {
      maxWidth: 250,
    },
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  textCell: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  charterHeader: {
    paddingBottom: 5,
  },
  charterSubHeader: {
    paddingTop: 5,
  },
  bodyTextLarge: {
    fontSize: variables.fontLarge,
    color: variables.tertiary1,
    paddingLeft: 10,
  },
  dateContainer: {
    display: "flex",
    flexDirection: "column",
    paddingRight: 15,
    paddingTop: 10,
    marginBottom: "-5px",
    [theme.breakpoints.down("md")]: {
      marginBottom: 0,
    },
  },
  versionRow: {
    paddingBottom: 5,
  },
  dateRow: {
    whiteSpace: "nowrap",
  },
  row: {
    display: "inline-flex",
    alignItems: "center",
  },
  buttonRow: {
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  charterLabel: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  bodyText: {
    fontSize: variables.fontMedium,
    color: variables.tertiary1,
    paddingLeft: 10,
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    paddingLeft: 20,
    paddingRight: 15,
    [theme.breakpoints.down("md")]: {
      marginTop: 20,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  buttonWrapper: {
    padding: 10,
    paddingBottom: 0,
    [theme.breakpoints.down("md")]: {
      paddingBottom: 15,
      paddingLeft: 0,
    },
  },
  dropDownWrapper: {
    paddingTop: 10,
    paddingLeft: 15,
    paddingBottom: 0,
    marginBottom: "-15px",
    [theme.breakpoints.down("md")]: {
      paddingTop: 5,
      paddingLeft: 5,
    },
  },
  selectForm: {
    maxWidth: "500px",
    minWidth: "300px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "300px",
      minWidth: "220px",
    },
  },
  reportIcon: {
    fontSize: "16px",
    marginRight: 8,
    color: "white",
  },
  editIcon: {
    fontSize: "16px",
    marginRight: 8,
    color: "white",
  },
  assessmentCardWrapper: {
    justifyContent: "start"
  },
  assessmentCard: {
    maxWidth: "none",
    width: "100%"
  },
  assessContent: {
    width: "100%"
  },
  inlineButtonLabel: {
    whiteSpace: "nowrap",
  },
  menuOptionVersion: {
    fontWeight: "bold",
    marginRight: 4,
    color: variables.textSecondary,
  },
  menuOptionDate: {
    fontWeight: "bold",
    color: variables.textSecondary
  }
}));

const showCopyPreviousSelcetor = (type) => {
  return compTypeCheck(builderTypes.PLAN, type) || compTypeCheck(builderTypes.POLICY, type);
};

const BuilderInfoCard = ({
  variant,
  builder,
  setShowIsSectionlessDialog,
  showIsSectionlessDialog,
  showError,
  loadingDownload,
  downloadBuilderFile,
  setChosenProgram,
  setMode,
  previousVersionSelect,
  compTypeRef,
  readOnly
}) => {
  const classes = useStyles();
  const [selectedBuilderIdToCopy, setSelectedBuilderIdToCopy] = useState();

  const compTypeChecker = (builderCompType) => {
    return compTypeRef === builderCompType || compTypeRef === "Program" + builderCompType
  }

  const isAssessment = compTypeChecker("Assessment")

  const isAssessmentPublished = ((builder.Status !== assessBuilderStatus.BUILDER_STATUS_DRAFT) && isAssessment);
  const isAssessmentCompleted = ((builder.Status === assessBuilderStatus.BUILDER_STATUS_ASSESSED) && isAssessment);

  const editCurrentProgBuilder = (progBuilder, mode) => {
    setChosenProgram({
      id: progBuilder.Program_ID,
      name: progBuilder.Name,
      compObjId: progBuilder.ComponentObjects_ComponentObject_ID,
    });
    if (mode === assessmentBuilderModes.MODE_REPORT) {
      setMode(assessmentBuilderModes.MODE_REPORT, progBuilder);
    } else if (mode === "review") { // TODO: refactor this function editCurrentProgBuilder
      setMode("review", progBuilder);
    } else if (mode === "complete") {
      setMode("complete", progBuilder);
    } else {
      setMode("editCurrent", progBuilder);
    }
  };

  return (
    <div className={classNames(classes.builderCardWrapper, isAssessment && classes.assessmentCardWrapper)}>
      <div className={classNames(classes.builderCard, isAssessment ? classes.assessmentCard : undefined)} data-cy={`${builder.Title}-builderCard`}>
        <div className={classNames(classes.contentWrapper, isAssessment ? classes.assessContent : undefined)}>
          <div className={classes.titleContainer}>
            <HeaderPrimary
              variant="h3Primary"
              className={classes.charterHeader}
            >
              {(!builder.Title || builder.Title.length === 0 ) ? "Untitled" : builder.Title}
            </HeaderPrimary>
          </div>
          {/* ===== Assessment Builder Progress ===== */}
          {!!isAssessment && (
            <AssessmentCardDetail
              builder={builder}
              compTypeRef={compTypeRef}
            />
          )}
          <div className={classes.infoContainer}>
            <div className={classes.textCell}>
              {!isAssessment && (
                <div className={classNames(classes.row, classes.versionRow)}>
                  <HeaderPrimary
                    variant="h4Tertiary"
                    className={classes.charterSubHeader}
                  >
                    Current Version:
                  </HeaderPrimary>
                  <Typography className={classes.bodyTextLarge}>
                    {builder.Version || "--"}
                  </Typography>
                </div>
              )}
              <div className={classes.dateContainer}>
                <div className={classNames(classes.row, classes.dateRow)}>
                  <HeaderPrimary
                    variant="h5Tertiary"
                    className={classes.charterLabel}
                  >
                    {isAssessment ? `Start Date:` : `Approval:`}
                  </HeaderPrimary>
                  <Typography className={classes.bodyText}>
                    {isAssessment ? getDateString(builder.Start_Date) : getDateString(builder.Approval_Date)}
                  </Typography>
                </div>

                <div className={classNames(classes.row, classes.dateRow)}>
                  <HeaderPrimary
                    variant="h5Tertiary"
                    className={classes.charterLabel}
                  >
                    {isAssessment ? `End Date:` : `Last Edit:`}
                  </HeaderPrimary>
                  <Typography className={classes.bodyText}>
                    {isAssessment ? getDateString(builder.End_Date) : moment(builder.Change_Date).format("MMMM DD, YYYY")}
                  </Typography>
                </div>
              </div>
            </div>

            <div className={classes.actionsContainer}>
              <div className={classNames(classes.row, classes.buttonRow)}>
                {isAssessment ? (
                  <AssessmentCardButtons
                    builder={builder}
                    readOnly={readOnly}
                    variant={variant}
                    setMode={setMode}
                    editCurrentProgBuilder={editCurrentProgBuilder}
                    isAssessmentPublished={isAssessmentPublished}
                    isAssessmentCompleted={isAssessmentCompleted}
                  />
                ) : (
                  <>
                    <div className={classes.buttonWrapper}>
                      <ButtonDefault
                        data-cy={builder.Title}
                        variant="small"
                        className={classes.inlineButtonLabel}
                        background="primary"
                        startIcon={
                          loadingDownload === builder.Builder_ID ? (
                            <ClipLoader css={override} />
                          ) : (
                            <PictureAsPdfOutlinedIcon
                              className={classes.reportIcon}
                            />
                          )
                        }
                        onClick={() => {
                          downloadBuilderFile(
                            builder.Builder_ID,
                            `${builder.Title}-Version${builder.Version}-${builder.Approval_Date}`
                          );
                        }}
                      >
                        Export PDF
                      </ButtonDefault>

                      <Dialog
                        notification
                        openDialog={!!showError && showIsSectionlessDialog === builder.Builder_ID}
                        confirm={() => setShowIsSectionlessDialog(false)}
                        setOpenDialog={setShowIsSectionlessDialog}
                        prompt="Document does not contain sections. Please add sections in order to generate the report."
                      />
                    </div>

                    <div className={classes.buttonWrapper}>
                      <ButtonDefault
                        variant="small"
                        className={classes.inlineButtonLabel}
                        background="secondary"
                        data-cy={`btn-current-version-${builder.Title}`}
                        startIcon={readOnly ? <ViewIcon className={classes.editIcon} /> : <EditIcon className={classes.editIcon} />}
                        onClick={
                          variant === "listByProgram" ?
                            () => editCurrentProgBuilder(builder) :
                            () => setMode("editCurrent", builder)
                        }
                      >
                        {readOnly ? "View Current Draft" : "Edit Current Draft"}
                      </ButtonDefault>
                    </div>
                  </>
                )}
              </div>
              {showCopyPreviousSelcetor(compTypeRef) && (
                <div className={classes.dropDownWrapper}>
                  <FormControl className={classes.selectForm}>
                    <CustomSelect
                      variant="selectInner"
                      disabled={builder.previousVersions.length === 0}
                      label={
                        builder.previousVersions.length === 0
                          ? "No Previous Version to Copy"
                          : "Load Copy of a Previous Version"
                      }
                      onChange={(event) => setSelectedBuilderIdToCopy(event.currentTarget.id)}
                      value={selectedBuilderIdToCopy || ""}
                      test={`builder-copyPrevVersion-${builder.Title}`}
                    >
                      {builder?.previousVersions?.map?.((prevBuilder) => (
                        <MenuItem
                          key={prevBuilder.Builder_ID}
                          value={prevBuilder.Builder_ID}
                          id={prevBuilder.Builder_ID}
                          data-cy={`menu-item-copyPrevVersion-${prevBuilder.Version}`}
                        >
                          <Box display="flex">
                            <span
                              className={classes.menuOptionVersion}
                            >
                              Version {prevBuilder.Version} -
                            </span>
                            <span className={classes.menuOptionDate}>
                              {`${moment(prevBuilder.Change_Date).format(
                                  "MMMM DD, YYYY"
                              )}`}
                            </span>
                          </Box>
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </FormControl>

                </div>
              )}
            </div>
            <Dialog
              openDialog={selectedBuilderIdToCopy}
              continueAndCancel
              confirm={() => (
                previousVersionSelect(
                  selectedBuilderIdToCopy,
                  builder,
                  variant === "listByProgram"
                )
              )}
              setOpenDialog={setSelectedBuilderIdToCopy}
              prompt="The current draft will be overwritten by the contents of the selected version upon saving."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuilderInfoCard;
