import React from "react";
import { sectionLabel } from "utils/builderFunctions";
import LabelInput from "components/utils/form-elements/labelInput.component";
import { Box } from "@material-ui/core";
import { ACTION_REPLACE_BUILDER_SECTION } from "reducers/builder.reducer";

const BuilderSectionHeader = (props) => {
  const { section, dispatch, disableBuilder } = props

  const sectionHeaderOnBlur = (currentSection, event) => {
    event.preventDefault();
    if (event.target.value === currentSection.SectionHeader) {
      return;
    }
    dispatch({
      type: ACTION_REPLACE_BUILDER_SECTION,
      payload: { ...currentSection, SectionHeader: event.target.value },
    });
  };

  return (
    <Box marginTop={2.5}>
      <LabelInput
        variant="default"
        id={`section-header-${section._meta.clientId}`}
        test={props.test || `builder-secHeader-${section.Section_Order}`}
        label={sectionLabel(section.Type, section.WorkPackageID)}
        defaultValue={section.SectionHeader}
        onBlur={(event) => sectionHeaderOnBlur(section, event)}
        required
        disabled={disableBuilder}
      />
    </Box>
  );
};

export default BuilderSectionHeader;
