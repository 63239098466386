import { FormControlLabel, makeStyles, Radio } from "@material-ui/core";
import classNames from "classnames";
import styleVariables from "styleVariables";

const useStyles = makeStyles((theme) => ({
  label: {
    color: styleVariables.textSecondary,
    "& > span.MuiTypography-root": {
      padding: "0px 20px 0px 6px",
    },
  },
  labelHidden: {
    marginRight: 6
  },
  labelText: {
    fontSize: styleVariables.fontLarge,
  },
  labelTextHidden: {
    display: "none"
  },
  radioRootClass: {
    color: `${styleVariables.tertiary2}!important`,
  },
}));

export default function RadioInput(props) {
  const classes = useStyles();
  const {
    checked, inputProps, label, onChange, radioLabelClass, size = "small", value, test
  } = props;
  return (
    <FormControlLabel
      className={classNames(classes.label, !label && classes.labelHidden)}
      classes={{
        label: classNames(
          classes.labelText, !label && classes.labelTextHidden, radioLabelClass
        ),
      }}
      checked={checked}
      value={value}
      control={
        <Radio
          size={size}
          classes={{
            label: classes.radioLabel,
            root: classes.radioRootClass,
          }}
          inputProps={inputProps}
        />
      }
      label={label}
      onChange={onChange}
      data-cy={`radio-option-${label || test}`}
    />
  )
}