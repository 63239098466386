import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, MenuItem, Divider } from "@material-ui/core";
import classNames from "classnames";
import LabelInput from "components/utils/form-elements/labelInput.component";
import riskAssessmentService from "services/riskAssessment.service";
import HeaderPrimary from "components/utils/header.component";
import { dataCollectionTypeIds, reviewPageModes } from "utils/assessmentConstants";
import { assessFirstColumnDisplay, assessSecondColumnDisplay, isReadOnlyBasedOnStatuses } from "utils/assessmentHelpers";
import CustomSelect from "components/utils/form-elements/select.component";
import MultiFileUploadInput from "components/utils/form-elements/multiFileUploadInput.component";
import { currentUserID } from "utils/userHelpers";
import { GiPencilRuler } from "react-icons/gi";
import DataCollectionForm from "./tables/dataCollectionForm";
import DataCollectionChecklist from "./dataCollectionChecklist.component";
import variables from "styleVariables";
import LabelAndValue from "components/utils/labelAndValue.component";
import Loader from "components/utils/loader.components";
import { useParams } from "react-router-dom";
import Form from "components/utils/form-elements/form.component";
import { defaultChangeDate } from "utils/dateHelpers";
import { getUploadHttpErrorMessage } from "services/http-common";

const useStyles = makeStyles((theme) => ({
  dataCollectionForm: {
    padding: 40,
  },
  sectionDivider: {
    marginTop: 10,
    marginBottom: 50
  },
  field: {
    margin: "0px 0px 30px",
    width: "100%"
  },
  fieldLabel: {
    fontWeight: 400,
    marginBottom: 8,
  },
  fileInput: {
    minHeight: 144,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  summaryContainer: {
    display: "flex",
    flexDirection: "column",
  },
  summaryHeadingBlock: {
    width: "100%",
    backgroundColor: variables.tertiary1,
  },
  summaryHeadingWrapper: {
    maxWidth: 1700,
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 50,
    paddingRight: 50,
    margin: "0px auto",
  },
  subHeading: {
    paddingLeft: 50,
    maxWidth: 600,
    display: "inline-flex",
    alignItems: "flex-end",
    whiteSpace: "nowrap",
  },
  subheadingText: {
    fontWeight: 400,
    whiteSpace: "nowrap"
  },
  headingIcon: {
    marginLeft: 8,
    fontSize: "2rem",
    fill: "white",
  },
  summaryContentContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 20,
    maxWidth: 1700,
    flexDirection: "row",
    margin: "0px auto",
    width: "100%",
  },
  summaryGroup1: {
    display: "flex",
    flexDirection: "row",
    paddingRight: 20,
    paddingTop: 10,
    paddingLeft: 30,
  },
  summaryCell: {
    marginRight: 30,
    display: "flex",
    flexFlow: "column",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  labelValueRow: {
    paddingBottom: 5,
  },
  valueText: {
    color: variables.textSecondary,
  },
  formWrapper: {
    paddingTop: "20px",
    paddingLeft: 50,
    paddingRight: 50,
    maxWidth: 1700,
    margin: "0px auto",
    marginBottom: 100,
  },
}));

const createInitialFormData = (designItem) => ({
  Design_ID: null,
  Current_State: "",
  Implementation_Type: "",
  Validation: "",
  Description: "",
  Finding: "",
  Recommendation: "",
  Completion_Date: null,
  Status: "In Progress",
  ...designItem
});

const validateFormErrors = (formState) => {
  const errors = {};
  if (!formState.Description) {
    errors.Description = "A description is required to complete a review";
  }
  if (!formState.Validation) {
    errors.Validation = "Validation field is required to complete a review";
  }
  return errors;
};

export default function DesignDataCollectionPage(props) {
  const {
    assessmentItem,
    builder,
    checklistItems,
    designItems,
    references,
    setDesignItems,
    setMessage,
    setReferences,
    setReviewPageMode,
  } = props;

  const classes = useStyles();

  const { activeId } = useParams();

  const [deletedReferences, setDeletedReferences] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [hasUnsavedReferenceChanges, setHasUnsavedReferenceChanges] = useState(false);
  const [fileUploadErrorMessage, setFileUploadErrorMessage] = useState(null);

  const designItem = useMemo(() => {
    if (!activeId || activeId === "new") {
      return null;
    }
    return designItems?.find?.(item => (
      item.Design_ID === parseInt(activeId, 10)
    ));
  }, [activeId, designItems]);

  const [formDataState, setFormDataState] = useState(
    createInitialFormData(designItem)
  );

  const designReferences = useMemo(() => {
    if (!designItem || !references) {
      return [];
    }
    return references.filter(reference => reference.DesignDC_ID === designItem.Design_ID);
  }, [references, designItem]);

  const formDisabled = isReadOnlyBasedOnStatuses(builder, designItem);

  useEffect(() => {
    if (designItem) {
      setFormDataState(designItem)
    }
  }, [designItem])

  const handleDeleteSavedReference = reference => {
    setDeletedReferences([...deletedReferences, reference]);
  }

  const handleRestoreSavedReference = reference => {
    const updates = [...deletedReferences];
    updates.splice(deletedReferences.indexOf(reference), 1);
    setDeletedReferences(updates);
  }

  const handleCollectionChange = (event) => {
    setFormDataState((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
    if (["Current_State", "Implementation_Type"].includes(event.target.name)) {
      handleSubmitTry(event.target.name, event.target.value);
    }
  };

  const handleSubmitReferencesTry = useCallback((_data, formData,) => (async function() {
    try {
      formData.set(
        "deletedFileNames",
        JSON.stringify(
          deletedReferences.map(({ Reference_Name }) => Reference_Name)
        )
      );
      formData.set("Design_ID", formDataState.Design_ID);
      formData.set("AssessmentBuilder_ID", formDataState.AssessmentBuilder_ID);
      formData.set("AssessmentItem_ID", formDataState.AssessmentItem_ID);
      formData.set("Change_Date", defaultChangeDate());
      formData.set("Change_User", currentUserID());
      formData.set("Status", formDataState.Status);

      setIsUploading(true);
      const response = await riskAssessmentService.upsertDesignDataCollection(formData)
      if (response?.payload) {
        setFileUploadErrorMessage(null);
        setHasUnsavedReferenceChanges(false)
        const responseItem = response.payload.designItem;
        const newDesignItems = [...designItems];
        const replaceIndex = designItems.indexOf(designItem);
        if (replaceIndex > -1) {
          newDesignItems.splice(replaceIndex, 1, responseItem);
        } else {
          newDesignItems.push(responseItem);
        }
        setDesignItems(newDesignItems);

        const mergedReferences = references.filter(reference => (
          !designReferences.includes(reference)
        )).concat(response.payload.assessmentReferences || []);
        setReferences([...mergedReferences]);
        setDeletedReferences([]);
        setReviewPageMode(
          reviewPageModes.DESIGN_DATA_COLLECTION,
          assessmentItem?.AssessmentItem_ID,
          responseItem.Design_ID
        );
      }
      setIsUploading(false);
    } catch (error) {
      const { status } = error.response || {};
      setFileUploadErrorMessage(getUploadHttpErrorMessage(status));
      setHasUnsavedReferenceChanges(true);
      setIsUploading(false);
    }

  })(), [
    assessmentItem, deletedReferences, designItems, references, designReferences, designItem,
    setDesignItems, setReferences, setReviewPageMode, formDataState
  ]);

  const handleSubmitTry = useCallback((fieldName, fieldValue) => (async function() {
    try {
      const formValidationErrors = validateFormErrors(formDataState)
      if (!formValidationErrors[fieldName] && formErrors[fieldName]) {
        delete formValidationErrors[fieldName];
        setFormErrors(formValidationErrors);
      }
      const newFieldValue = fieldValue || formDataState[fieldName]
      const dataToSave = {
        Design_ID: formDataState.Design_ID,
        AssessmentBuilder_ID: assessmentItem.AssessmentBuilder_ID,
        AssessmentItem_ID: assessmentItem.AssessmentItem_ID,
        Change_Date: defaultChangeDate(),
        Change_User: currentUserID(),
        Status: formDataState.Status,
        [fieldName]: newFieldValue,
      };
      const response = await riskAssessmentService.upsertDesignDataCollection(dataToSave)
      if (response?.payload) {
        setFileUploadErrorMessage(null);
        const responseItem = response.payload.designItem;
        const newDesignItems = [...designItems];
        const replaceIndex = designItems.indexOf(designItem);
        if (replaceIndex > -1) {
          newDesignItems.splice(replaceIndex, 1, responseItem);
        } else {
          newDesignItems.push(responseItem);
        }
        setDesignItems(newDesignItems);

        setReviewPageMode(
          reviewPageModes.DESIGN_DATA_COLLECTION,
          assessmentItem?.AssessmentItem_ID,
          responseItem.Design_ID
        );
      }
    } catch (error) {
      const { status } = error.response || {};
      setFileUploadErrorMessage(getUploadHttpErrorMessage(status));
      setHasUnsavedReferenceChanges(true);
    }

  })(), [
    assessmentItem, designItems, designItem,
    setDesignItems, setReviewPageMode, formDataState,
    formErrors,
  ]);

  const category = assessFirstColumnDisplay(
    assessmentItem?.AssessmentItem,
    assessmentItem?.ProgramComponent_Name
  );

  if (!assessmentItem || !designItems) {
    return <Loader />
  }
  return (
    <div className={classes.contentWrapper}>
      <div className={classNames(classes.summaryContainer)}>
        <div className={classNames(classes.summaryHeadingBlock)}>
          <div className={classNames(classes.summaryHeadingWrapper)}>
            <div className={classes.assessmentTitle}>
              <HeaderPrimary variant="h2White" test="design-item-title">
                Assessment Item: {assessmentItem.ProgramComponent_Name || "Custom"}
              </HeaderPrimary>
            </div>
            <div className={classes.subHeading} >
              <HeaderPrimary variant="h4White" className={classNames(classes.subheadingText)}>
                <span>Design Data Collection</span>
              </HeaderPrimary>
              <GiPencilRuler className={classNames(classes.headingIcon)} />
            </div>
          </div>
        </div>
        <div className={classes.summaryContentContainer}>
          <div className={classes.summaryGroup1}>
            {!!category && category !== "None" && (
              <div className={classNames(classes.summaryCell)}>
                <LabelAndValue
                  className={classNames(classes.flexColumn, classes.valueText, classes.labelValueRow)}
                  label="Category"
                  value={category}
                />
              </div>
            )}
            <div className={classNames(classes.summaryCell)}>
              <LabelAndValue
                className={classNames(classes.flexColumn, classes.valueText, classes.labelValueRow)}
                label="Item Description"
                value={assessSecondColumnDisplay(
                  assessmentItem.AssessmentItem, assessmentItem.ProgramComponent_Name
                )}
              />
            </div>
            <div className={classNames(classes.summaryCell)}>
              <LabelAndValue
                className={classNames(classes.flexColumn, classes.valueText, classes.labelValueRow)}
                label="Checklist Items"
                value={<DataCollectionChecklist
                  assessmentItem={assessmentItem}
                  checklistItems={checklistItems}
                  reviewTypeId={dataCollectionTypeIds.DATA_COLLECTION_TYPE_DESIGN}
                />}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={classNames(classes.formWrapper)}>
        <Paper
          elevation={1}
          className={classNames(classes.dataCollectionForm)}
        >
          <DataCollectionForm
            builder={builder}
            onSubmitTry={handleSubmitTry}
            dataCollection={designItem}
            setIsUploading={setIsUploading}
            setMessage={setMessage}
            setReviewPageMode={setReviewPageMode}
            setFormDataState={setFormDataState}
            formDataState={formDataState}
            validateFormErrors={validateFormErrors}
            setFormErrors={setFormErrors}
            hasUnsavedReferenceChanges={hasUnsavedReferenceChanges}
          >
            <Grid container spacing={5}>
              <Grid item md={6} sm={12}>
                <div className={classNames(classes.field, classes.validatedField)}>
                  <CustomSelect
                    name="Current_State"
                    value={formDataState.Current_State}
                    onChange={handleCollectionChange}
                    labelId="current-state"
                    label={<span className={classNames(classes.fieldLabel)}>Current State*</span>}
                    required={false}
                    fixedHeight
                    test="current-state"
                    disabled={formDisabled}
                  >
                    <MenuItem value="Planned" id="planned">
                      Planned
                    </MenuItem>
                    <MenuItem value="Implemented" id="implemented">
                      Implemented
                    </MenuItem>
                    <MenuItem value="Not Applicable" id="not-applicable">
                      Not Applicable
                    </MenuItem>
                    <MenuItem value="Not Implemented" id="not-implemented">
                      Not Implemented
                    </MenuItem>
                  </CustomSelect>
                </div>
                <div className={classNames(classes.field)}>
                  <CustomSelect
                    name="Implementation_Type"
                    value={formDataState.Implementation_Type}
                    onChange={handleCollectionChange}
                    labelId="implementation-type"
                    label={<span className={classNames(classes.fieldLabel)}>Implementation Type</span>}
                    required={false}
                    test="implementation-type"
                    disabled={formDisabled}
                  >
                    <MenuItem value="Enterprise" id="enterprise">
                      Enterprise
                    </MenuItem>
                    <MenuItem value="System Specific" id="system-specific">
                      System Specific
                    </MenuItem>
                    <MenuItem value="Hybrid" id="hybrid">
                      Hybrid
                    </MenuItem>
                  </CustomSelect>
                </div>
              </Grid>
              <Grid item md={6} sm={12}>
                <div className={classNames(classes.field)}>
                  <LabelInput
                    name="Validation"
                    label={<span className={classNames(classes.fieldLabel)}>Validation*</span>}
                    value={formDataState.Validation}
                    onChange={handleCollectionChange}
                    multiline
                    margin="dense"
                    variant="default"
                    test="validation"
                    rows={6}
                    disabled={formDisabled}
                    onAutosave={() => handleSubmitTry("Validation")}
                    error={!!formErrors?.Validation}
                    errorMessage={formErrors?.Validation}
                  />
                </div>
              </Grid>
            </Grid>
            <Divider className={classNames(classes.sectionDivider)} />
            <Grid container spacing={5}>
              <Grid item sm={12} md={6}>
                <div className={classNames(classes.field)}>
                  <LabelInput
                    name="Description"
                    label={<span className={classNames(classes.fieldLabel)}>Description*</span>}
                    value={formDataState.Description}
                    onChange={handleCollectionChange}
                    multiline
                    margin="dense"
                    variant="default"
                    test="description"
                    rows={8}
                    disabled={formDisabled}
                    onAutosave={() => handleSubmitTry("Description")}
                    error={!!formErrors?.Description}
                    errorMessage={formErrors?.Description}
                  />
                </div>
              </Grid>
              <Grid item sm={12} md={6}>
                <Grid item xs={12} sm={6} md={12} lg={9} xl={6}>
                  <Form
                    onSubmit={handleSubmitReferencesTry}
                  >
                    <MultiFileUploadInput
                      name="references"
                      label={<span className={classNames(classes.fieldLabel)}>References</span>}
                      defaultValue={designItem?.References}
                      className={classNames(classes.fileInput)}
                      deletedFiles={deletedReferences}
                      isUploading={isUploading}
                      onDelete={handleDeleteSavedReference}
                      onDeleteUndo={handleRestoreSavedReference}
                      uploadedFiles={designReferences}
                      inputFileButtonDisabled={!designItem}
                      disabled={formDisabled}
                      saveButtonLabel="Save References"
                      setHasUnsavedFileChanges={setHasUnsavedReferenceChanges}
                      promptMessage={"There are unsaved references. Are you sure you'd like to leave without saving them?"}
                      currentPathSubstring="designDataCollection"
                      errorMessage={fileUploadErrorMessage}
                    >
                      <div>
                        Add References
                      </div>
                    </MultiFileUploadInput>
                  </Form>
                </Grid>
              </Grid>
            </Grid>
            <Divider className={classNames(classes.sectionDivider)} />
            <Grid container spacing={5}>
              <Grid item sm={12} md={6}>
                <div className={classNames(classes.field)}>
                  <LabelInput
                    value={formDataState.Finding}
                    onChange={handleCollectionChange}
                    multiline
                    margin="dense"
                    variant="default"
                    label={<span className={classNames(classes.fieldLabel)}>Finding</span>}
                    name="Finding"
                    rows={5}
                    disabled={formDisabled}
                    onAutosave={() => handleSubmitTry("Finding")}
                  />
                </div>
              </Grid>
              <Grid item sm={12} md={6}>
                <div className={classNames(classes.field)}>
                  <LabelInput
                    variant="default"
                    label={<span className={classNames(classes.fieldLabel)}>Recommendation</span>}
                    name="Recommendation"
                    value={formDataState.Recommendation}
                    onChange={handleCollectionChange}
                    margin="dense"
                    multiline
                    rows={5}
                    disabled={formDisabled}
                    onAutosave={() => handleSubmitTry("Recommendation")}
                  />
                </div>
              </Grid>
            </Grid>
          </DataCollectionForm>
        </Paper>
      </div>
    </div>
  );
}
