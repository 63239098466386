import HeaderPrimary from "components/utils/header.component";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import Loader from "components/utils/loader.components";
import RoadmapItemsTable from "components/assessment/roadmap/tables/roadmapItemsTable.component";
import variables from "styleVariables";
import LabelAndValue from 'components/utils/labelAndValue.component';
import { formattedMomentCustomError } from "utils/dateHelpers";
import moment from "moment";
import BottomControlsBar from "../shared/bottomControlsBar.component";
import { useParams, useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  loader: {
    width: "100%",
    height: 350,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  summaryContainer: {
    display: "flex",
    flexDirection: "column",
  },
  summaryHeadingBlock: {
    width: "100%",
    backgroundColor: variables.tertiary1,
  },
  summaryHeadingWrapper: {
    maxWidth: 2000,
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 50,
    paddingRight: 50,
    margin: "0px auto",
  },
  assessmentItemsHeader: {
    paddingLeft: 50,
    maxWidth: 600,
  },
  summaryContentContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 20,
    maxWidth: 2000,
    flexDirection: "row",
    margin: "0px auto",
    width: "100%",
  },
  summaryGroup1: {
    display: "flex",
    flexDirection: "row",
    paddingRight: 30,
  },
  summaryGroup2: {
    display: "flex",
    flexDirection: "row",
    minWidth: 415,
    justifyContent: "flex-end",
    "& $summaryCell": {
      paddingRight: 0,
      marginRight: 0,
    },
  },
  summaryCell: {
    marginRight: 30,
    minWidth: 50,
    display: "flex",
    flexFlow: "column",
    paddingTop: 5,
  },
  tableWrapper: {
    paddingTop: "20px",
    paddingLeft: 50,
    paddingRight: 50,
    maxWidth: 2000,
    margin: "0px auto",
    marginBottom: 100,
  },

}));

// This is the Page that holds the Roadmap Table.
// The link to this page is located in reviewItemPage.component


const RoadmapItemsPage = (props) => {
  const {
    setMode,
    builderInfo,
    setReviewPageMode,
    setRoadmapItems,
    roadmapItems,
    onSuccessVariable,
    setOnSuccessVariable,
  } = props;

  const classes = useStyles();
  const history = useHistory();

  const {programId, programComponentId, componentObjectId} = useParams();

  // The roadmapItems are retrieved from a database query
  // that is located in the assessmentReview.component

  const backButtonClick = () => {
    history.push(`/program/${programId}/${programComponentId}/${componentObjectId}/assessment/${builderInfo.Builder_ID}/review`)
  }

  const tableData = roadmapItems
  return (
    <>
      <div className={classes.summaryContainer}>
        <div className={classNames(classes.summaryHeadingBlock)}>
          <div className={classNames(classes.summaryHeadingWrapper)}>
            <div className={classes.assessmentTitle}>
              <HeaderPrimary variant="h1White">
                {builderInfo.Title}
              </HeaderPrimary>
            </div>
            <div className={classes.assessmentItemsHeader} >
              <HeaderPrimary variant="h4White" test="summary-total-items">
                Completed: {moment(builderInfo.Completion_Date).format("M/D/YYYY")}
              </HeaderPrimary>
            </div>
          </div>
        </div>
        <div className={classes.summaryContentContainer}>
          <div className={classes.summaryGroup1}>
            <div className={classNames(classes.summaryCell)}>
              <LabelAndValue
                className={classNames(classes.flexColumn, classes.valueText, classes.labelValueRow)}
                label="Description"
                value={builderInfo.Description || "None"}
              />
            </div>
          </div>
          <div className={classes.summaryGroup2}>
            <div className={classNames(classes.summaryCell)}>
              <LabelAndValue className={classes.startItem}
                labelTest="start-date"
                label="Start"
                value={formattedMomentCustomError(moment(builderInfo.Start_Date).format("M/D/YYYY"))}
              />
              <LabelAndValue
                labelTest="end-date"
                label="End"
                value={formattedMomentCustomError(moment(builderInfo.End_Date).format("M/D/YYYY"))}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.tableWrapper} data-cy="roadmap-page-title">
        {!tableData ? (
          <div className={classNames(classes.loader)}>
            <Loader />
          </div>

        ) : (
          <RoadmapItemsTable
            rowData={tableData}
            setReviewPageMode={setReviewPageMode}
            builderInfo={builderInfo}
            roadmapItems={roadmapItems}
            setRoadmapItems={setRoadmapItems}
            setMode={setMode}
            onSuccessVariable={onSuccessVariable}
            setOnSuccessVariable={setOnSuccessVariable}
          />
        )}
      </div>
      <BottomControlsBar
        backClick={backButtonClick}
        doneClick={backButtonClick}
      />
    </>

  );
};

export default RoadmapItemsPage;
