import { CFR_TYPES, CFR_TYPE_PARAGRAPH, CFR_TYPE_PART, CFR_TYPE_SECTION, CFR_TYPE_SUBPART } from "./cfrConstants";
import { decodeHexCodes } from "./stringFuncs";

/*
 * All helpers which correspond to the `CFR` table
 */

export const filterCfrByType = (cfrItems, type) => (
  cfrItems?.filter?.(item => item.CFR_Type === type) || []
);

export const getCfrLabel = cfrItem => {
  switch (cfrItem.CFR_Type) {
    case CFR_TYPE_PART:
      return cfrItem.Part_Label;
    case CFR_TYPE_SUBPART:
      return cfrItem.Subpart_Label;
    case CFR_TYPE_SECTION:
      return cfrItem.Section_Label;
    case CFR_TYPE_PARAGRAPH:
      return cfrItem.Paragraph_Label;
    default:
      console.error(`Invalid CFR type for CFR#${cfrItem.CFR_ID}:\n${cfrItem.CFR_Type}`);
  }
}

export const getLongCfrLabel = (cfrId, flattenedItemsById) => {
  const cfrItem = flattenedItemsById[cfrId];
  let allowedTypes = [];
  if ([CFR_TYPE_SUBPART, CFR_TYPE_PART].includes(cfrItem.CFR_Type)) {
    allowedTypes = [CFR_TYPE_SUBPART, CFR_TYPE_PART];
  } else if ([CFR_TYPE_SECTION, CFR_TYPE_PARAGRAPH].includes(cfrItem.CFR_Type)) {
    allowedTypes = [CFR_TYPE_SECTION, CFR_TYPE_PARAGRAPH];
  }
  const cfrHierarchyItems = getParentCfrItems(
    cfrItem,
    flattenedItemsById,
    allowedTypes
  );
  const combined = cfrHierarchyItems.reverse().reduce((output, item) => {
    const itemLabel = getCfrLabel(item);
    if (item.CFR_Type === CFR_TYPE_PARAGRAPH) {
      const isSubParagraph = output.includes("§");
      const paragraphLabel = itemLabel || item.CFR_Order;
      return `${output} ${isSubParagraph ? "" : "§"}${paragraphLabel}`;
    } else if (item.CFR_Type === CFR_TYPE_SECTION) {
      return `${output} Section ${itemLabel}`;
    } else if (item.CFR_Type === CFR_TYPE_SUBPART) {
      return `${output} Subpart ${itemLabel}`;
    } else if (item.CFR_Type === CFR_TYPE_PART) {
      return `${output} Part ${itemLabel},`;
    }
    return `${output} ${itemLabel}`;
  }, "");
  return combined.replace(/,$/, "").trim();
}

export const getCfrContent = cfrItem => {
  switch (cfrItem.CFR_Type) {
    case CFR_TYPE_PART:
      return cfrItem.Part_Title;
    case CFR_TYPE_SUBPART:
      return cfrItem.Subpart_Title;
    case CFR_TYPE_SECTION:
      return cfrItem.Section_Title;
    case CFR_TYPE_PARAGRAPH:
      return decodeHexCodes(cfrItem.Paragraph_Content);
    default:
      console.error(`Invalid CFR type for CFR#${cfrItem.CFR_ID}:\n${cfrItem.CFR_Type}`);
  }
}

export const getParentCfrItems = (cfrItem, flattenedCfrItems, cfrTypes = [], childItems = []) => {
  const items = childItems.concat(cfrItem);
  if (!cfrItem.Parent_CFR_ID) {
    return items;
  }
  const parent = flattenedCfrItems[cfrItem.Parent_CFR_ID];
  if (cfrTypes.length && !cfrTypes.includes(parent.CFR_Type)) {
    return items;
  }
  return getParentCfrItems(parent, flattenedCfrItems, cfrTypes, items);
};


// Segmented cfrs are returned in order of typesArray
export const segmentCfrsByType = (cfrItems, typesArray = CFR_TYPES) => (
  (cfrItems || []).reduce((segments, cfrItem) => {
    const matchingIndex = typesArray.indexOf(cfrItem.CFR_Type);
    if (matchingIndex > -1) {
      segments[matchingIndex].push(cfrItem);
    }
    return segments;
  }, typesArray.map(() => ([])))
);
