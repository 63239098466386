import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { useEffect } from "react";
import FileService from "services/file.service";
import classNames from "classnames";
import Image from "./image.component";
import { setStateFetchEffect } from "utils/ajaxHelpers";
const useStyles = makeStyles({
  logoImage: {
    maxWidth: 200,
    maxHeight: 50,
    width: "auto",
    borderRadius: 1,
  },
  logoPickerImage: {
    maxWidth: 200,
    maxHeight: 100,
    width: "auto",
  },
});

/*
    Styling can be done by variant or className

    To style by variant, pass in a variant prop and
    add the variant name to classNameByVariant along
    with its class name below
*/

const Logo = (props) => {
  const classes = useStyles();
  const [logo64, setLogo64] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { logo64Prop, logoId } = props; //One of these are required
  const { variant, className, alt } = props; // Optional

  const variantClassName = useMemo(() => {
    switch(variant) {
      case "mainHeader":
        return classes.logoImage;
      case "picker":
        return classes.logoPickerImage;
      default:
        return "";
    }
  }, [classes, variant]);

  useEffect(() => {
    if (logo64Prop) {
      return;
    }
    if (logoId) {
      setIsLoading(true);
      return setStateFetchEffect(
        FileService.getLogo(logoId),
        ([response]) => {
          setLogo64(response.data.payload);
          setIsLoading(false);
        },
        () => {
          setLogo64();
          setIsLoading(false);
        }
      );
    } else {
      setLogo64();
    }
  }, [logoId, logo64Prop]);

  if (isLoading) {
    return <CircularProgress />;
  }
  return (
    <Image
      className={classNames(variantClassName, className)}
      imageBase64={logo64Prop || logo64 || null}
      alt={alt || "Client Logo"}
      {...props}
    />
  );
};

export default Logo;
