import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import variables from "styleVariables";
import IconMessage from "components/utils/iconMessage.component";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    marginTop: 10,
    height: 79,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    margin: "0px auto",
    borderRadius: 3,
    color: variables.tertiary1,
    alignItems: "center",
    paddingTop: "20px",
    paddingBottom: "20px",
    backgroundColor: variables.grayTint,
  },
}));

const EmptyDashboardSection = (props) => {
  const classes = useStyles();
  if (props.variant === "custom") {
    return (
      <Paper className={classNames(classes.paper, props.className)} elevation={1}>
        {props.children}
      </Paper>
    );
  } else {
    return (
      <Paper className={classes.paper} elevation={1}>
        <IconMessage sectionName={props.sectionName} variant="empty" />
      </Paper>
    );
  }
};

export default EmptyDashboardSection;
