import React from "react";
import { FormControl, FormGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "components/utils/form-elements/formLabel.component";
import CustomFormHelperText from "components/utils/form-elements/styled/formHelperText/customFormHelperText.component"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));
const CheckBoxList = ({ groupLabel, children, noMargin, required, error, errorMessage, helperText, formControlFullWidth}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FormControl component="fieldset" fullWidth={formControlFullWidth} className={noMargin ? null : classes.formControl}>
        <FormLabel
          label={<>{groupLabel}{!!required && "*"}</>}
          variant="default"
          error={error}
        />
        <FormGroup>{children}</FormGroup>
        <CustomFormHelperText
          error={error}
        >
          {errorMessage || helperText}
        </CustomFormHelperText>
      </FormControl>
    </div>
  );
};

export default CheckBoxList;
