import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import LabelInput from "./labelInput.component";

const FLOAT_CHAR_REGEXP = /[0-9.-]/;
const INT_CHAR_REGEXP = /[0-9-]/;

const ALLOWED_NAN_VALUES = new Set(["-", "."]);

export const VALIDATION_ERROR_INTEGER = "integer";
export const VALIDATION_ERROR_MAX = "max";
export const VALIDATION_ERROR_MIN = "min";
export const VALIDATION_ERROR_POSITIVE = "positive";

// LabelInput for numeric values.
// Does not use native browser number buttons due to Mui not supporting it
export default function NumberInput(props) {
  const {
    error, errorMessage, errorType, inputRef, isInteger = false, max, min,
    onAutosave, onBeforeInput, onKeyDown, onPaste, setErrorType, ...otherProps
  } = props;

  const [prevalidatedValue, setPrevalidatedValue] = useState(null);
  // Only used when setErrorType prop is not passed
  const [controlledErrorType, setControlledErrorType] = useState(null);

  const innerRef = useRef();
  const ref = inputRef || innerRef;

  useEffect(function resetInvalidInputValue() {
    const oldValue = prevalidatedValue && `${prevalidatedValue}`;
    setTimeout(() => {
      const newValue = ref.current?.value && `${ref.current?.value}`;
      if (oldValue === newValue) {
        return;
      }
      if (ALLOWED_NAN_VALUES.has(newValue)) {
        return;
      }

      setPrevalidatedValue(null);

      if (newValue && Number.isNaN(newValue)) {
        ref.current.value = oldValue;
      }
    }, 0);
  }, [isInteger, max, min, prevalidatedValue, ref, setPrevalidatedValue]);

  const controlledErrorMessage = useMemo(() => {
    switch(controlledErrorType) {
      case (VALIDATION_ERROR_INTEGER):
        return "Cannot be a decimal";
      case (VALIDATION_ERROR_MAX):
        return `Must be ${max} or less`;
      case (VALIDATION_ERROR_MIN):
        return `Must be ${min} or more`;
      case (VALIDATION_ERROR_POSITIVE):
        return `Must be positive`;
      default:
        return "";
    }
  }, [controlledErrorType, max, min]);

  const validateErrorType = useCallback(value => {
    if (value) {
      if (isInteger && value.includes(".")) {
        return VALIDATION_ERROR_INTEGER;
      }
      if (min >= 0 && value.includes("-")) {
        return VALIDATION_ERROR_POSITIVE;
      }
      const numericValue = Number(value);
      if (numericValue < min) {
        return VALIDATION_ERROR_MIN;
      } else if (numericValue > max) {
        return VALIDATION_ERROR_MAX;
      }
    }
    return null;
  }, [isInteger, max, min]);

  const handleSetErrorType = useCallback(type => {
    if (setErrorType) {
      setErrorType(type);
    } else {
      setControlledErrorType(type);
    }
  }, [setControlledErrorType, setErrorType]);

  const handleAutosave = useCallback((name, value, ...args) => {
    if (!onAutosave) {
      return;
    }
    // Validate and cancel autosave if invalid
    const validatedType = validateErrorType(value);
    if (validatedType) {
      handleSetErrorType(validatedType);
      return;
    }
    const numericValue = isInteger ? parseInt(value, 10) : parseFloat(value);
    return onAutosave(name, numericValue, ...args);
  }, [onAutosave, handleSetErrorType, isInteger, validateErrorType]);

  const handleBeforeInput = useCallback(event => {
    setPrevalidatedValue(event.target.value);
    return onBeforeInput?.(event);
  }, [onBeforeInput, setPrevalidatedValue]);

  const handleKeyDown = useCallback((event, ...args) => {
    const { ctrlKey, key, metaKey, target: { value }} = event;
    setPrevalidatedValue(value);
    handleSetErrorType?.(null);
    if (key?.length === 1 && !ctrlKey && !metaKey) {
      const testRegex = isInteger ? INT_CHAR_REGEXP : FLOAT_CHAR_REGEXP;
      if (!testRegex.test(key)) {
        return event.preventDefault();
      }
    }
    return onKeyDown?.(event, ...args);
  }, [isInteger, onKeyDown, handleSetErrorType, setPrevalidatedValue]);

  const handleBlur = useCallback((event) => {
    if (onAutosave) {
      return;
    }
    handleSetErrorType(validateErrorType(event.target.value));
  }, [onAutosave, handleSetErrorType, validateErrorType]);

  return (
    <LabelInput
      {...otherProps}
      error={error || !!errorMessage || !!controlledErrorMessage}
      errorMessage={errorMessage || controlledErrorMessage}
      onAutosave={handleAutosave}
      onBeforeInput={handleBeforeInput}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      inputProps={{
        max,
        min,
        step: isInteger ? "1.0" : "any"
      }}
      inputRef={ref}
      type="number"
    />
  )
}