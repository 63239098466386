import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import { makeStyles } from "@material-ui/core/styles";
/*
 Used for Title Icons that use MUI Icons
*/

const useStyles = makeStyles((theme) => ({
  icon: {
    height: 20,
    fill: "white",
  },
}));

const TitleMuiIcon = ({ variant }) => {
  const classes = useStyles();
  if (variant === "search") {
    return <SearchOutlinedIcon className={classes.icon} />;
  }
  if (variant === "admin") {
    return <SettingsOutlinedIcon className={classes.icon} />;
  }
};

export default TitleMuiIcon;
