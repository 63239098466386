import BuilderService from "services/builder.service";
import { nameMap, sourceObjMap } from "utils/controlsConstants.js";
import { builderSectionTypes } from "./builderConstants";

const builderSubHeader = (mode, type) => {
  if (mode === "editCurrent") {
    return "Editing Current Version";
  }
  if (mode === "loadTemplate") {
    const typeLabel = type.replace("Program", "");
    return `Creating New ${typeLabel}`;
  }
  if (mode === "copyPrevBuilder") {
    return "Copied Previous Version";
  }
  return "";
};
const nonRequiredHeaderSectionTypes = [builderSectionTypes.PROC_ELEMENT, builderSectionTypes.PROC_STEP]
const isSectionHeaderMissing = (builderSections) => {
  return builderSections.some((section) => {
    if (nonRequiredHeaderSectionTypes.includes(section.Type)) {
      return false
    }
    return !section.SectionHeader || section?.SectionHeader?.trim() === "";
  });
};

const sectionLabel = (type, wpID) => {
  if (type === "workpackage" || wpID === "workpackage") {
    return "Workpackage Section Header";
  } else if (type === builderSectionTypes.PROC_STEP) {
    return "Step Header";
  } else if (type === builderSectionTypes.PROC_ELEMENT) {
    return "Procedure Element Section Header (Optional)";
  } else if (type === builderSectionTypes.REFERENCE) {
    return "Regulations/Guidelines References Section Header";
  } else {
    return "Section Header";
  }
};

const compTypeCheck = (compare, type) => {
  return type === compare || type === `Program${compare}`;
};

const builderSectionsMatch = (section, sectionCompare) => {
  return section._meta.clientId === sectionCompare._meta.clientId;
};

const compTypesWithHistory = ["ProgramAssessment"];

const checkCompTypeHasHistory = (type) =>
  compTypesWithHistory.includes(type) ||
  compTypesWithHistory.includes(`Program${type}`);

const makeSectionClientId = (existingId, index = "") => {
  return existingId || `${new Date().getTime()}-builderSection-${index}`;
};

const attachMetaToBuilderSections = (sections) => {
  return sections.map((section, index) => ({
    ...section,
    _meta: { clientId: makeSectionClientId(section?.BuilderSection_ID, index) },
  }));
};

const validateBuilderInfoFormErrors = (builderObj) => {
  const error = {};
  if (!builderObj.Title || builderObj?.Title?.trim() === "") {
    error.Title = "Please enter a title.";
  }

  if (!builderObj.Version || builderObj?.Version?.trim() === "") {
    error.Version = "Please enter a version.";
  }
  return error;
};

const deleteBuilderSection = (sections, payload) => {
  const newSections = [...sections].filter(
    (i) => i._meta.clientId !== payload._meta.clientId
  );

  newSections.sort((a, b) => a.Section_Order - b.Section_Order);
  const reOrderedSections = newSections.map((sec, index) => {
    return { ...sec, Section_Order: index + 1 };
  });
  return reOrderedSections;
};

const getInitialPractices = async (frameworks) => {
  const groupedByFramework = await Promise.all(frameworks.map(async frame => {
    const fwGroupRefsRes = await BuilderService.getFrameworkGroupRefs(
      frame.RegFramework_id,
      frame.AltCode,
      frame.Object_Ref
    );
    let practiceList;
    if (frame.Detail) {
      //Filter by ML
      practiceList = fwGroupRefsRes.payload.filter(
        (data) => data.ML <= parseInt(frame.Detail)
      );
    } else {
      practiceList = fwGroupRefsRes.payload;
    }
    return practiceList.map((data) => ({
      // ...data,
      Step_Source_Object: data.Step_Source_Object,
      Step_Source_ID: data.Step_Source_ID,
      GroupName: data.GroupName,
      SubGroupName: data[nameMap[data.Step_Reg_Object]],
      Source_ID: data[sourceObjMap[data.Step_Reg_Object]],
      Source_Object: data.Step_Source_Object,
      Reg_Source: data.Step_Reg_Object,
      Status: "Active",
      Detail: data.Detail,
      Name: data.Name,
      RegFramework_ID: data.RegFramework_ID,
    }));
  }));

  return groupedByFramework.flatMap(group => group);
};

const isMissingVersion = (version) => {
  return !version || version?.trim() === "";
};



export {
  attachMetaToBuilderSections,
  builderSubHeader,
  sectionLabel,
  compTypeCheck,
  checkCompTypeHasHistory,
  isSectionHeaderMissing,
  makeSectionClientId,
  validateBuilderInfoFormErrors,
  deleteBuilderSection,
  builderSectionsMatch,
  getInitialPractices,
  isMissingVersion,
};
