import { FormHelperText, withStyles } from "@material-ui/core";
import variables from "styleVariables";


const CustomFormHelperText = withStyles({
  root: {
    color: variables.secondaryMain,
    fontSize: variables.fontXxs,
    fontWeight: 600,
    textAlign: "right",
    borderRadius: "0px 0px 3px 3px",
    padding: "2px 5px 0px 0px",
    margin: "-2px 0px 0px 0px",
    height: 17,
    width: "100%",
    "&.Mui-error": {
      color: `white !important;`,
      paddingTop: 1,
      backgroundColor: `${variables.warningMain} !important;`,
    },
  },
})(FormHelperText);

export default CustomFormHelperText;
