import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import ButtonDefault from "components/utils/buttonDefault.component";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import { isReadOnly } from "utils/roles";

const useStyles = makeStyles((theme) => ({
  reportIconSmall: {
    fontSize: variables.fontXs,
    marginRight: 6,
    color: "white",
  }
}));

const AssignButton = ({ onClick, testProp }) => {
  const classes = useStyles();
  const readOnly = isReadOnly();
  return (
    <ButtonDefault
      variant="small"
      background="primary"
      startIcon={
        <PictureAsPdfOutlinedIcon className={classes.reportIconSmall} />
      }
      onClick={onClick}
      disabled={readOnly}
      data-cy={(readOnly || !testProp) ? "btn-Assign" : `btn-Assign-${testProp}`}
    >
      Assignment
    </ButtonDefault>
  );
};

export default AssignButton;
