import React, { useState, useEffect, useMemo, useContext, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import HeaderPrimary from "components/utils/header.component";
import variables from "styleVariables";
import CustomSelect from "components/utils/form-elements/select.component";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import BuilderService from "services/builder.service";
import Alert from "@material-ui/lab/Alert";
import FormBanner from "components/utils/form-elements/formBanner.component";
import Checkbox from "components/utils/form-elements/checkbox.component";
import { groupNameMap } from "utils/controlsConstants.js";
import {
  MenuItem,
  Typography,
  List,
  ListItem,
  Divider,
  Box,
} from "@material-ui/core";
import RemoveIcon from "components/utils/removeIcon.component";
import { COMPONENT_OBJECT_TYPE_BUILDER, PROGRAM_ID_ORCHESTRATION } from "utils/programConstants";
import useNumericParams from "hooks/useNumericParams";
import ProgramsContext from "contexts/programs.context";
import ProgramService from "services/program.service";
import { findProgramBuilderComponent } from "utils/programUtils";
import { ACTION_PARTIAL_REPLACE_COMPONENT_OBJECTS } from "reducers/global/program.reducer";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    [theme.breakpoints.down("md")]: {
      maxHeight: 500,
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: 400,
    },
    width: "fit-content",
    margin: 8,
    maxWidth: 600,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 10,
    padding: 10,
    paddingBottom: 10,
    overflowY: "scroll"
  },
  chosenGroupsWrapper: {
    backgroundColor: variables.grayTint,
    borderRadius: 3,
    padding: 20,
  },
  chosenGroups: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  labelWrapper: {
    display: "block",
    width: "100%",
  },
  listItems: {
    color: variables.primaryMain,
  },
  selectsContainer: {
    alignSelf: "center",
    width: 340,
    padding: "10px 20px 10px 20px",
    borderRadius: 8,
  },
  customSelect: {
    marginBottom: 25,
    width: "100%",
  },
  buttonsWrapper: {
    paddingBottom: 10,
    width: 900,
  },
  checkWrapper: {
    width: "fit-content",
    margin: "0px auto",
    paddingBottom: 20,
  },
  checkContainer: {
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
  },
  stepText: {
    paddingBottom: 10,
  },
  stepText2: {
    paddingTop: 30,
  },
  removeIcon: {
    paddingRight: 10
  }
}));

const levels = [
  { level: "1" },
  { level: "2" },
  { level: "3" },
  { level: "4" },
  { level: "5" },
];

const selectedGroupInitialState = { groupName: "" };

const MODE_ENTERPRISE = "enterprise";
const MODE_PROGRAM_LEVEL = "programLevel";
const MODE_NONE = "none";

export const BuilderTempForm = (props) => {
  const classes = useStyles();
  const { state, dispatch } = useContext(ProgramsContext);
  const [allFrameworks, setAllFrameworks] = useState(); //loads on mount
  const [allGroups, setAllGroups] = useState(); //loads when seslectedFramework is selevted
  const [selectedFramework, setSelectedFramework] = useState({}); // object of selected Framework
  const [level, setLevel] = useState("5");
  const [selectedGroup, setSelectedGroup] = useState(selectedGroupInitialState); // object of selected Group
  const [charterFrames, setCharterFrames] = useState([]);
  const [readyToLoad, setReadyToLoad] = useState();
  const [message, setMessage] = useState("");

  const [mode, setMode] = useState(MODE_ENTERPRISE);
  const { programId, componentObjectId } = useNumericParams();

  useEffect(() => {
    let isSubscribed = true;
    BuilderService.getRegFrameworks()
      .then((res) => {
        if (isSubscribed) {
          setAllFrameworks(res.payload);
        }
      })
      .then(() => setReadyToLoad(true))
    return () => {
      isSubscribed = false;
    };
  }, []);

  const programMenuItems = useMemo(() => (
    Object.values(state.programs).filter(program => (
      program.Status === "Active" &&
      program.Program_ID !== PROGRAM_ID_ORCHESTRATION
    ))
  ), [state.programs]);

  const handleFramework = (event) => {
    const chosenFramework = allFrameworks.find(
      (frame) => frame.RegFramework_id === event.target.value
    );
    setSelectedFramework(chosenFramework);
    setSelectedGroup(selectedGroupInitialState);
    setLevel("5");
    BuilderService.getListPolicyTemplateGroup(chosenFramework.Object_Ref, chosenFramework.Version).then((res) => {
      // ie event.target.value === "CMMCDomains"
      const data = [];
      res.payload.forEach((group) => {
        data.push({ ...group, groupName: group[groupNameMap[chosenFramework.Object_Ref]] });
      });
      setAllGroups(data);
      setMessage("");
    });
  };

  const handleGroup = (event) => {
    const chosenGroup = allGroups.filter(
      (group) => event.target.value === group.groupName
    )[0];
    if (selectedFramework.Name === "CMMC") {
      setLevel("5");
      setSelectedGroup({
        ...chosenGroup,
        ML: "5",
      });
    } else {
      setLevel(null);
      setSelectedGroup(chosenGroup);
    }
    setMessage("");
  };

  const removeGroup = (groupToRemove) => {
    const indexToRemove = [...charterFrames].findIndex((frame) => {
      return (
        frame.RegFramework_id === groupToRemove.RegFramework_id &&
        frame.GroupName === groupToRemove.GroupName
      );
    });

    if (indexToRemove > -1) {
      const updatedArray = [...charterFrames];
      updatedArray.splice(indexToRemove, 1);
      setCharterFrames(updatedArray);
    }
  };

  const handleML = (event) => {
    setLevel(event.target.value);
    setSelectedGroup({
      ...selectedGroup,
      ML: event.target.value,
    });
  };

  const handleCheck = (event) => {
    if (event.target.checked === true) {
      setMode(event.target.name);
    } else {
      setMode(MODE_NONE);
    }
    props.setChosenProgram({ id: "", name: "" });
  };

  const handleChange = (event) => {
    props.setChosenProgram({ id: event.target.value, name: event.currentTarget.id });
  };

  const getPolicyBuilderComponentObject = useCallback(async () => {
    let componentObject = findProgramBuilderComponent(
      props.chosenProgram.id,
      props.chosenProgram.name,
      state.programComponentsByProgram,
      state.componentObjectsByComponent,
    );
    if (!componentObject) {
      componentObject = (
        await ProgramService.getComponentObjectByProgramAndTypeValues(
          props.chosenProgram.id,
          COMPONENT_OBJECT_TYPE_BUILDER,
          props.policyType
        )
      )?.payload;
      dispatch({
        type: ACTION_PARTIAL_REPLACE_COMPONENT_OBJECTS,
        payload: componentObject,
      });
    }
    return componentObject;
  }, [
    dispatch, props.chosenProgram, props.policyType,
    state.programComponentsByProgram, state.componentObjectsByComponent
  ]);

  //onSubmit
  const loadTemp = async () => {
    // If form is on Orchestration Level
    if (programId === PROGRAM_ID_ORCHESTRATION) {
      if (mode === MODE_ENTERPRISE) {
        props.setMode("loadTemplate", null);
        props.setEditModal(false);
        props.setChosenProgram({
          id: PROGRAM_ID_ORCHESTRATION,
          name: "Enterprise Level",
          compObjId: componentObjectId,
        });
        props.setPolicyFrameworks(charterFrames);
      } else {
        const componentObject = await getPolicyBuilderComponentObject();
        props.setChosenProgram({
          ...props.chosenProgram,
          compObjId: componentObject.ComponentObject_ID,
        });
        props.setMode("loadTemplate", null);
        props.setEditModal(false);
        props.setPolicyFrameworks(charterFrames);
      }
      // If form is on Program Level
    } else {
      props.setMode("loadTemplate", null);
      props.setEditModal(false);
      props.setPolicyFrameworks(charterFrames);
    }
  };

  const addFramework = () => {
    const duplicate = charterFrames.filter(
      (frame) =>
        frame.RegFramework_id
        === selectedFramework.RegFramework_id
        &&
        frame.GroupName === selectedGroup.groupName
    );

    if (!duplicate.length) {
      let sourceObj;
      if (selectedGroup.Family_ID) {
        sourceObj = "Family_ID";
      } else if (selectedGroup.Domain_ID) {
        sourceObj = "Domain_ID";
      } else if (selectedGroup.Security_Req_Group) {
        sourceObj = "Security_Req_Group";
      } else if (selectedGroup.Category) {
        sourceObj = "Category"
      }
      setCharterFrames([
        ...charterFrames,
        {
          FrameworkName: selectedFramework.Name,
          Object_Ref: selectedFramework.Object_Ref,
          RegFramework_id: selectedFramework.RegFramework_id,
          Code: selectedGroup.Abbreviation || selectedGroup.Fam_ID || "",
          AltCode:
            selectedGroup.Abbreviation ||
            selectedGroup.Fam_ID ||
            selectedGroup.groupName,
          //AltCode: selectedGroup[altCodeMap[selectedFramework["Object_Ref"]]], //i.e. selectedGroup.Fam_ID
          GroupName: selectedGroup.groupName,
          Group_Name: selectedFramework.Group_Name,
          Source_Object: sourceObj,
          Source_ID: selectedGroup[sourceObj],
          Version: selectedFramework.Version,
          Detail: selectedGroup.ML,
        },
      ]);
    } else {
      setMessage(
        `${selectedFramework.Name} - ${selectedGroup.groupName} has already been added`
      );
    }
    setAllGroups();
    setSelectedGroup(selectedGroupInitialState);
    setSelectedFramework({});
  };

  if (readyToLoad && allFrameworks) {
    return (
      <>
        <FormBanner >Create Policy</FormBanner>
        <div className={classes.formContainer} data-cy="form-create-policy">
          <div className={classes.contentContainer}>
            {props.policyType === "ProgramPolicy" && (
              <>
                <HeaderPrimary
                  variant="h4Primary"
                  className={classes.stepText}
                  noTransform
                >
                  Step One: Choose a level
                </HeaderPrimary>
                <div className={classes.checkWrapper}>
                  <div className={classes.checkContainer}>
                    <Checkbox
                      checked={mode === MODE_ENTERPRISE}
                      onChange={(event) => handleCheck(event)}
                      name={MODE_ENTERPRISE}
                      label="Create a Policy at the enterprise level"
                      test="policy-EnterpriseLevel"
                    />
                    <Checkbox
                      checked={mode === MODE_PROGRAM_LEVEL}
                      onChange={(event) => handleCheck(event)}
                      name={MODE_PROGRAM_LEVEL}
                      label="Create a Policy at the program level"
                      test="policy-ProgramLevel"
                    />
                  </div>
                </div>

                {mode === MODE_PROGRAM_LEVEL && (
                  <div className={classes.selectsContainer}>
                    <CustomSelect
                      labelRequired
                      labelError={!props.chosenProgram.id}
                      error={!props.chosenProgram.id}
                      variant="stacked"
                      className={classes.customSelect}
                      label="Select Program"
                      value={props.chosenProgram.id}
                      onChange={handleChange}
                      name="program"
                      test="Program"
                      selectClasses={{
                        root: classes.selectRoot,
                      }}
                    >
                      {programMenuItems.map(item => (
                        <MenuItem
                          value={item.Program_ID}
                          key={item.Program_ID}
                          data-cy={`menu-item-${item.Name}`}
                        >
                          {item.Name}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </div>
                )}

                <Divider />
                <HeaderPrimary
                  variant="h4Primary"
                  className={classes.stepText2}
                  noTransform
                >
                  Step Two: Select one or more frameworks
                </HeaderPrimary>
              </>
            )}

            <div className={classes.chosenGroupsWrapper}>
              {!!message && (
                <Alert
                  severity="warning"
                  onClose={() => {
                    setMessage("");
                  }}
                >
                  {message}
                </Alert>
              )}
              <div className={classes.chosenGroups}>
                <div className={classes.labelWrapper}>
                  <Typography variant="subtitle1" color="primary">
                    Selected frameworks and groups:
                  </Typography>
                </div>
                {charterFrames && charterFrames.length > 0 ? (
                  <List>
                    {charterFrames.map((frame) => {
                      return (
                        <ListItem
                          dense
                          key={`${frame.RegFramework_id}-${frame.GroupName}`}
                          className={classes.listItems}
                          data-cy={`${frame.FrameworkName} ${frame.GroupName}`}
                        >
                          <Box display="flex">
                            <span className={classes.removeIcon}>
                              <RemoveIcon onClick={() => removeGroup(frame)} />
                            </span>

                            <span>
                              {`${frame.FrameworkName}`}
                              {!!frame.Version && ` ${frame.Version}`}
                              {` ${frame.GroupName}`}
                              {!!frame.Code && ` (${frame.Code})`}
                              {` ${frame.Group_Name}`}
                            </span>
                          </Box>
                        </ListItem>
                      );
                    })}
                  </List>
                ) : (
                  <List>
                    <ListItem dense className={classes.listItems}>
                      None yet selected
                    </ListItem>
                  </List>
                )}
              </div>
            </div>

            <div className={classes.selectsContainer}>
              <CustomSelect
                labelRequired={charterFrames.length === 0}
                labelError={charterFrames.length === 0 && !selectedFramework}
                error={charterFrames.length === 0 && !selectedFramework}
                variant="stacked"
                className={classes.customSelect}
                value={selectedFramework?.RegFramework_id || ""}
                onChange={(event) => handleFramework(event)}
                labelId="Select a Framework:"
                label="Select a Framework:"
                inputLabelClasses={{ root: classes.inputLabelRoot }}
                test="Framework"
                selectClasses={{
                  root: classes.selectRoot,
                }}
                disabled={mode === MODE_PROGRAM_LEVEL && !props.chosenProgram.id}
              >
                {allFrameworks.map((framework) => (
                  <MenuItem
                    key={framework.RegFramework_id}
                    value={framework.RegFramework_id}
                    name={framework.Name}
                    id={framework.Name}
                    data-cy={`menu-item-${framework.Name}-${framework.Version}`}
                  >
                    {framework.Name + ` - ` + framework.Version}
                  </MenuItem>
                ))}
              </CustomSelect>

              <CustomSelect
                labelRequired={charterFrames.length === 0}
                labelError={charterFrames.length === 0 && !!allGroups && !selectedGroup.groupName}
                error={charterFrames.length === 0 && !!allGroups && !selectedGroup.groupName}
                disabled={!allGroups}
                className={classes.customSelect}
                variant="stacked"
                labelId={
                  selectedFramework && selectedFramework.Group_Name
                    ? `Select ${selectedFramework.Group_Name}`
                    : `Select a Group:`
                }
                label={
                  selectedFramework && selectedFramework.Group_Name
                    ? `Select ${selectedFramework.Group_Name}`
                    : `Select a Group:`
                }
                value={selectedGroup?.groupName || ""}
                onChange={handleGroup}
                inputLabelClasses={{ root: classes.inputLabelRoot }}
                selectClasses={{
                  root: classes.selectRoot,
                }}
                test="Group"
              >
                {!!allGroups && allGroups.map((group) => (
                  <MenuItem
                    key={group.groupName}
                    value={group.groupName}
                    data-cy={`menu-item-${group.groupName}`}
                  >
                    {group.groupName}
                  </MenuItem>
                ))}
              </CustomSelect>

              {selectedFramework.Name === "CMMC" && (
                <CustomSelect
                  disabled={!!selectedGroup && !selectedGroup.groupName}
                  className={classes.customSelect}
                  variant="stacked"
                  labelId="Maturity Level"
                  label="Maturity Level"
                  value={level || ""}
                  onChange={handleML}
                  inputLabelClasses={{ root: classes.inputLabelRoot }}
                  selectClasses={{
                    root: classes.selectRoot,
                  }}
                >
                  {levels.map((item) => (
                    <MenuItem key={item.level} value={item.level} data-cy={`menu-item-CMMCLevel-${item.level}`}>
                      {item.level}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
            </div>
          </div>
          <DualFormButtons
            disabled={
              (props.policyType === "ProgramPolicy" &&
                mode === MODE_PROGRAM_LEVEL &&
                props.chosenProgram.id === "") ||
              (props.policyType === "ProgramPolicy" && mode === MODE_NONE) ||
              !charterFrames.length
            }
            disabledFramework={selectedGroup.groupName === ""}
            variant="loadTemp"
            saveOnClick={loadTemp}
            addOnClick={addFramework}
            cancelOnClick={() => props.setEditModal(false)}
            className={classes.buttonsWrapper}
          />
        </div>
      </>
    );
  } else {
    return "";
  }
};

export default BuilderTempForm;
