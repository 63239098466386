import { useCallback, useMemo, useState } from "react";
import moment from "moment";
import DataTable from "components/utils/tables/dataTable.component";
import { Box, List, ListItem, makeStyles, Typography } from "@material-ui/core";
import PortfolioCompanyCell from "./portfolioCompanyCell.component";
import { getPortfolioUserTitle } from "components/portfolio/shared/utils/portfolioUserHelpers";
import DateCell from "./dateCell.component";
import { COMPONENT_ID_PORTFOLIO_ASSESSMENTS } from "utils/programConstants";
import useNumericParams from "hooks/useNumericParams";
import { generatePath, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { sessionFormModes } from "utils/portfolioConstants";
import { ROLE_GROUPS } from "utils/roles";
import EditIconButton from "components/utils/editIconButton.component";
import authService from "services/auth.service";
import RadioInputGroup from "components/utils/form-elements/radioInputGroup.component";
import styleVariables from "styleVariables";
import { createNodeCellParams, createNodeHeaderParams, createValueHeaderParams } from "components/utils/tables/utils/dataTableHelpers";
import { mapObjectArrayByKey } from "utils/arrayOfObjectsHelpers";


const useStyles = makeStyles(theme => ({
  cellEdit: {
    maxWidth: 48
  },
  attendeeItem: {
    paddingTop: 0,
    paddingBottom: 8
  }
}));

const SHOW_ALL = "all";
const SHOW_AVAILABILITY = "available";
const SHOW_BOOKED = "booked";

const radioOptions = [
  { label: "Show All Active", value: SHOW_ALL },
  { label: "Available Sessions", value: SHOW_AVAILABILITY},
  { label: "Booked Sessions", value: SHOW_BOOKED },
];

const dateFormat = "dddd, MMMM D, YYYY";
const timeFormat = "hh:mma";
const dbDatetimeFormat = "YYYY-MM-DD hh:mm:ss";

const dateHeader = createNodeHeaderParams("startDate", "Date");
const startHeader = createNodeHeaderParams("startTime", "Start", {
  align: "center",
  filter: false
});
const endHeader = createNodeHeaderParams("endTime", "End", {
  align: "center",
  filter: false,
});
const portCoHeader = createNodeHeaderParams("portCoName", "Portfolio Company", {
  align: "center",
});
const assessmentHeader = createValueHeaderParams("assessmentName", "Assessment", {
  align: "center",
});

const staticHeaders = [
  dateHeader,
  startHeader,
  endHeader,
  portCoHeader,
];


export default function SchedulingTable(props) {
  const classes = useStyles();
  const history = useHistory();
  const params = useNumericParams();
  const {
    holderAssessments, sessions, state, formModeClick,
    isScheduleAdmin, pastSessions
  } = props;

  const { portfolioCompanies, portfolioUsers } = state;

  const [viewMode, setViewMode] = useState(pastSessions ? SHOW_BOOKED : SHOW_ALL);

  const rowToHighlight = useMemo(() => {
    if (params.sessionId) {
      return params.sessionId;
    }
    return null;
  }, [params.sessionId]);

  const canEdit = useMemo(() => (
    !pastSessions && authService.checkPermissions(ROLE_GROUPS.PORTCO_EDIT_USERS)
  ), [pastSessions]);

  const goToSessionFromSchedule = useCallback(seshID => {
    const session = sessions.find(sesh => sesh.Session_ID === seshID);
    history.push(generatePath(
      "/program/:programId/:programComponentId/holding/:holdingId/portCo/:portCoId/assessment/:assessmentId/session/:sessionId",
      {
        ...params,
        programComponentId: COMPONENT_ID_PORTFOLIO_ASSESSMENTS,
        portCoId: session.PortCo_ID,
        assessmentId: session.HolderAssessment_ID,
        sessionId: session.Session_ID
      }
    ));
  }, [history, params, sessions]);

  const assessmentsById = useMemo(() => (
    mapObjectArrayByKey(holderAssessments, "HolderAssessment_ID")
  ), [holderAssessments]);

  const portfolioCompanyMap = useMemo(() => (
    portfolioCompanies.reduce((accumulator, company) => ({
      ...accumulator,
      [company.PortCo_ID]: company
    }), {})
  ), [portfolioCompanies]);

  const sessionAttendeeUserPairs = useMemo(() => (
    sessions.reduce((accumulator, session) => ({
      ...accumulator,
      [session.Session_ID]: session._associations.SessionAttendees?.map?.(attendee => {
        const user = portfolioUsers.find(portfolioUser => (
          attendee.PortUser_ID === portfolioUser.User_ID
        ));
        if (!user) {
          return null;
        }
        const title = getPortfolioUserTitle(
          user,
          portfolioCompanyMap[session.PortCo_ID]?.PortfolioHolder_ID,
          session.PortCo_ID
        );
        return {
          name: `${user.First_Name} ${user.Last_Name}${title ? "," : ""} ${title}`,
          attendee,
          title,
          user
        };
      })
        ?.filter(data => data)
        ?.sort?.((a1, a2) => a1.name.localeCompare(a2.name)) || []
    }), {})
  ), [sessions, portfolioCompanyMap, portfolioUsers]);

  const editTableHeader = useMemo(() => (
    createNodeHeaderParams("edit", " ", {
      align: "center",
      display: !canEdit ? "excluded" : true,
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        "data-cy": "scheduling-table-edit-header",
      }),
      setCellProps: () => ({ className: classes.cellEdit })
    })
  ), [canEdit, classes]);


  const attendeesHeader = useMemo(() => (
    createNodeHeaderParams("attendees", "Attendees", {
      filterOptions: {
        names: Array.from(new Set(
          Object.values(sessionAttendeeUserPairs).flatMap(attendees => (
            attendees.length ? attendees.map(({ name }) => name) : [""]
          ))
        )),
      }
    })
  ), [sessionAttendeeUserPairs]);

  const tableHeaders = useMemo(() => {
    const headers = [editTableHeader, ...staticHeaders];
    if (holderAssessments?.length > 1) {
      headers.push(assessmentHeader);
    }
    headers.push(attendeesHeader);
    return headers;
  }, [holderAssessments?.length, attendeesHeader, editTableHeader]);

  const tableCells = useMemo(() => {
    return sessions?.map(session => {
      const company = portfolioCompanyMap[session.PortCo_ID];
      const rowAttendees = sessionAttendeeUserPairs[session.Session_ID];
      const attendeeNames = rowAttendees.map(({ name }) => name).join(`. `);
      const startDate = moment(session.Start, dbDatetimeFormat);
      const startDateFormatted = startDate.format(dateFormat);
      const startDatetime = startDate.format(timeFormat);
      const endDate = moment(session.End, dbDatetimeFormat);
      const endDatetime = endDate.format(timeFormat);

      return {
        startDate: createNodeCellParams(
          startDate.valueOf(),
          startDateFormatted,
          (
            <DateCell
              dateFormatted={startDateFormatted}
              goToSessionFromSchedule={goToSessionFromSchedule}
              portCoName={company?.Name}
              sessionId={session.Session_ID}
              startTime={startDate}
              endTime={endDate}
              test={`start-date-session-id-${session.Session_ID}`}
            />
          )
        ),
        startTime: createNodeCellParams(startDate.valueOf(), startDatetime, (
          <div data-cy={`start-time-session-id-${session.Session_ID}`}>
            {startDatetime}
          </div>
        )),
        endTime: createNodeCellParams(endDate.valueOf(), endDatetime, (
          <div data-cy={`end-time-session-id-${session.Session_ID}`}>
            {endDatetime}
          </div>
        )),
        portCoName: createNodeCellParams(company?.Name, company?.Name, (
          <PortfolioCompanyCell
            companyName={company?.Name}
            formModeClick={() => (
              formModeClick(sessionFormModes.BOOK_PORTCO, session)
            )}
            isScheduleAdmin={isScheduleAdmin}
            pastSessions={pastSessions}
            test={`port-cos-session-id-${session.Session_ID}`}
          />
        )),
        assessmentName: (
          assessmentsById?.[session.HolderAssessment_ID]?.Assessment_Name
        ),
        attendees: createNodeCellParams(
          attendeeNames.split(". ").sort().join("|"),
          attendeeNames,
          (
            <List
              data-cy={`attendees-session-id-${session.Session_ID}`}
              disablePadding
            >
              {rowAttendees.map(({ name, attendee }) => (
                <ListItem
                  disableGutters
                  dense
                  className={classes.attendeeItem}
                  key={attendee.Attendee_ID}
                >
                  {name}
                </ListItem>
              ))}
            </List>
          )
        ),
        edit: createNodeCellParams(null, null, (
          <EditIconButton
            noPadding
            onClick={() => (
              formModeClick(
                isScheduleAdmin ?
                  sessionFormModes.EDIT :
                  sessionFormModes.PORTCO_ATTENDEES,
                session,
                rowAttendees
              )
            )}
            disabled={!company && !isScheduleAdmin}
            testProp={`session-session-id-${session.Session_ID}`}
          />
        )),
      }
    });
  }, [
    assessmentsById, classes, goToSessionFromSchedule, pastSessions,
    portfolioCompanyMap, sessions, sessionAttendeeUserPairs, formModeClick,
    isScheduleAdmin
  ]);

  const scopedTableCells = useMemo(() => {
    if (!tableCells) {
      return [];
    }
    if (viewMode === SHOW_ALL) {
      return tableCells;
    }
    const isFilterAvailable = viewMode === SHOW_AVAILABILITY;
    return tableCells?.filter(session => (
      !session.portCoName.metadata.textValue === isFilterAvailable
    )) || [];
  }, [tableCells, viewMode])

  const testPropTableType = pastSessions ? "past" : "active";

  return (
    <div data-cy={`${testPropTableType}-scheduling-table-wrapper`}>
      {!pastSessions && (
        <Box display="flex" justifyContent="space-between" marginBottom={2}>
          <RadioInputGroup
            row
            name="view-mode"
            value={viewMode}
            onChange={(event) => setViewMode(event.target.value)}
            options={radioOptions}
            size="small"
            color="primary"
            hideHelperText
          />
          <Typography> Times are listed in US Eastern Timezone </Typography>
        </Box>
      )}
      <DataTable
        data={scopedTableCells}
        columns={tableHeaders}
        options={{
          textLabels: {
            body: {
              noMatch: "No sessions available",
            },
          },
          filterType: "checkbox",
          fixedHeader: true,
          fixedSelectColumn: true,
          filter: true,
          pagination: false,
          rowHover: false,
          selectableRowsHideCheckboxes: true,
          viewColumns: false,
          download: false,
          print: false,
          setRowProps: (row, dataIndex, rowIndex) => {
            const rowSessionId = row[0].Session_ID;
            if (rowToHighlight && rowToHighlight === rowSessionId) {
              return {
                style: {
                  backgroundColor: styleVariables.chosenElement,
                  verticalAlign: "top"
                },
              };
            }
          },
        }}
      />
    </div>
  );
}
