import { Box, Dialog, makeStyles } from "@material-ui/core";
import moment from "moment";
import AddListItem from "components/utils/addListItem.component";
import Autocomplete from "components/utils/form-elements/autocomplete.component";
import CheckboxV2 from "components/utils/form-elements/checkboxV2.component";
import DateInput, { TYPE_DATETIME } from "components/utils/form-elements/dateInput.component";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import Form from "components/utils/form-elements/form.component";
import FormBanner from "components/utils/form-elements/formBanner.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import CustomSelect from "components/utils/form-elements/select.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import { H5 } from "components/utils/headerV2.component";
import Loader from "components/utils/loader.components";
import { useCallback, useMemo, useState } from "react";
import { ACTION_REPLACE_RUNBOOK_TASK, ACTION_REPLACE_RUNBOOK_TASK_DEPENDENCIES, ACTION_SET_UI_HIGHLIGHTED_ROW } from "reducers/runbook/oneRunbook.reducer";
import RunbookService from "services/runbook.service";
import { VALIDATION_AFTER_DATE, VALIDATION_MAX_LENGTH, VALIDATION_REQUIRED } from "utils/formValidators";
import styleVariables from "styleVariables";

const useStyles = makeStyles(theme => ({
  dropdown: {
    height: 40,
    paddingTop: 5,
    marginTop: -4
  },
  dropdownHelperText: {
    height: 16
  },
  notApplicableSection: {
    opacity: 0.5
  },
  staticFieldText: {
    height: 40,
    paddingTop: 12,
    fontSize: styleVariables.fontSmall
  }
}));


export default function CreateRunbookTaskModal(props) {
  const classes = useStyles();
  const { state, dispatch, runbookId } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [startDateValue, setStartDateValue] = useState(null);
  const [workgroupValue, setWorkgroupValue] = useState(null);

  const validations = useMemo(() => ({
    Name: [
      { type: VALIDATION_REQUIRED },
      { type: VALIDATION_MAX_LENGTH, argument: 128 }
    ],
    Workgroup_ID: { type: VALIDATION_REQUIRED },
    Start_Date: { type: VALIDATION_REQUIRED },
    End_Date: [
      { type: VALIDATION_REQUIRED },
      {
        type: VALIDATION_AFTER_DATE,
        argument: moment(startDateValue).format("YYYY-MM-DD hh:mm:ss"),
        message: `Must be after start date`,
      }
    ],
  }), [startDateValue]);

  const workgroupOwner = useMemo(() => {
    if (!workgroupValue || !state.workgroups || !state.users) {
      return null;
    }
    const selectedWorkgroup = state.workgroups.find(workgroup => (
      workgroup.Workgroup_ID === workgroupValue
    ));
    return state.users.find(user => (
      user.User_ID === selectedWorkgroup.Admin_ID
    ));
  }, [workgroupValue, state.workgroups, state.users]);

  function closeModal() {
    setWorkgroupValue(null);
    setStartDateValue(null);
    setIsOpen(false);
  }

  const handleSubmit = useCallback(async data => {
    const dependentIds = data.RunbookTaskDependencies__Dependent_Task_ID;
    delete data.RunbookTaskDependencies__Dependent_Task_ID;
    if (!data.Workstream_ID) {
      delete data.Workstream_ID;
    }
    if (!data.Phase_ID) {
      delete data.Phase_ID;
    }


    const taskResponse = await RunbookService.createRunbookTask(
      runbookId,
      data
    );
    const taskDependenciesResponse = dependentIds.length ? (
      await RunbookService.batchUpdateTaskDependencies(
        taskResponse.payload.Task_ID,
        dependentIds
      )
    ) : null;

    dispatch({
      type: ACTION_REPLACE_RUNBOOK_TASK,
      payload: taskResponse.payload,
      meta: taskResponse.meta
    });
    dispatch({
      type: ACTION_SET_UI_HIGHLIGHTED_ROW,
      payload: taskResponse.payload.Task_ID
    })
    if (taskDependenciesResponse) {
      dispatch({
        type: ACTION_REPLACE_RUNBOOK_TASK_DEPENDENCIES,
        payload: taskDependenciesResponse.payload,
        meta: taskDependenciesResponse.meta
      });
    }
    closeModal();
  }, [dispatch, runbookId]);


  return (
    <>
      <Box marginTop={2} marginBottom={2}>
        <AddListItem onClick={() => setIsOpen(true)}>
          New Task
        </AddListItem>
      </Box>
      <Dialog
        open={isOpen}
        onClose={closeModal}
        maxWidth="md"
        fullWidth
      >
        <FormBanner>
          Create Runbook Task
        </FormBanner>
        {(!state.runbookPhases || !state.workstreams || !state.workgroups) ? (
          <Loader />
        ) : (
          <Box padding={4} paddingBottom={2}>
            <Form
              name="runbook-task-create"
              onSubmit={handleSubmit}
              validations={validations}
            >
              <GridContainer spacing={4}>
                <GridItem xs={12} md={6}>
                  <GridContainer spacing={4}>
                    <GridItem xs={12}>
                      <LabelInput
                        name="Name"
                        label="Task*"
                        variant="default"
                        margin="dense"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={12} lg={6}>
                      <CustomSelect
                        label="Phase"
                        name="Phase_ID"
                        defaultValue=""
                        options={state.runbookPhases.map(phase => ({
                          label: phase.Name,
                          value: phase.Phase_ID
                        }))}
                        className={classes.dropdown}
                        helperClassName={classes.dropdownHelperText}
                        required={false}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={12} lg={6}>
                      <CustomSelect
                        label="Workstream"
                        name="Workstream_ID"
                        defaultValue=""
                        options={state.workstreams.map(workstream => ({
                          label: workstream.Name,
                          value: workstream.Workstream_ID
                        }))}
                        className={classes.dropdown}
                        helperClassName={classes.dropdownHelperText}
                        required={false}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <LabelInput
                        name="Description"
                        label="Steps"
                        variant="default"
                        margin="dense"
                        rows={4}
                        multiline
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Autocomplete
                        name="RunbookTaskDependencies__Dependent_Task_ID"
                        label="Dependencies"
                        options={state.runbookTasks.map(task => ({
                          label: task.Name,
                          value: task.Task_ID
                        }))}
                        disableCloseOnSelect
                        multiple
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={12} lg={6}>
                      <CustomSelect
                        label="Workgroup*"
                        name="Workgroup_ID"
                        defaultValue=""
                        options={state.workgroups.map(workgroup => ({
                          label: workgroup.Name,
                          value: workgroup.Workgroup_ID
                        }))}
                        onChange={event => (
                          setWorkgroupValue(event.target.value)
                        )}
                        className={classes.dropdown}
                        helperClassName={classes.dropdownHelperText}
                        required={false}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={12} lg={6}>
                      <div
                        className={
                          !workgroupOwner ? classes.notApplicableSection : undefined
                        }
                      >
                        <H5 color="tertiary">
                          Workgroup Owner
                        </H5>
                        <div className={classes.staticFieldText}>
                          {workgroupOwner ?
                            `${workgroupOwner?.First_Name} ${workgroupOwner.Last_Name}` :
                            "--"
                          }
                        </div>
                      </div>
                    </GridItem>
                    <GridItem xs={12}>
                      <Box marginLeft={-1.5} marginBottom={2}>
                        <CheckboxV2
                          name="Milestone"
                          label="Milestone"
                        />
                      </Box>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} md={6}>
                  <GridContainer spacing={4}>
                    <GridItem xs={12}>
                      <DateInput
                        name="Start_Date"
                        label="Start*"
                        type={TYPE_DATETIME}
                        onChange={(event) => setStartDateValue(event.target.value)}
                        fixedHeight
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <DateInput
                        name="End_Date"
                        label="End*"
                        type={TYPE_DATETIME}
                        fixedHeight
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <LabelInput
                        name="Notes"
                        label="Notes"
                        variant="default"
                        margin="dense"
                        rows={4}
                        multiline
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <DualFormButtons
                cancelOnClick={() => closeModal()}
              />
            </Form>
          </Box>
        )}
      </Dialog>
    </>
  )

}