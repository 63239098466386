import React, { useState } from "react";
import { Grid, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import FormBanner from "components/utils/form-elements/formBanner.component";
import FormSubHeader from "components/utils/form-elements/formSubHeader.component";
import ImplementationService from "services/implementation.service";
import CustomSelect from "components/utils/form-elements/select.component";
import LabelInput from "components/utils/form-elements/labelInput.component";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 8,
  },
  content: {
    width: 600,
    padding: "0 20px 20px 20px",
  },
  form: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingBottom: 0,
    alignItems: "center"
  },
  formSection: {
    width: 300,
    paddingTop: 10,
    paddingBottom: 10
  },
  dualButtons: {
    paddingTop: 30,
    paddingLeft: 0,
    paddingBottom: 15
  }

}));

export const LocationsForm = (props) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({Description: "", Type: ""});
  const [isSaving, setIsSaving] = useState(false);

  const handleChange = (event) => {
    const { target } = event;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSaving(true)
    const newLocation = {
      Description: formData.Description, //User input from textfield
      GroupProducts_GroupProduct_ID: props.productId,
      GroupProducts_OrganizationGroups_Group_ID: props.groupInfo.id,
      Type: formData.Type,
    };
    await ImplementationService.createDataLocation(newLocation);
        // reload table and close modal
    setIsSaving(false)
    props.setRefresh(true)
    props.setEditModal(false);
  };
  return (
    <Grid className={classes.formContainer} data-cy="form-location">
      <FormBanner>Locations - Systems Data Locations Update</FormBanner>
      <div className={classes.content}>
        <FormSubHeader variant="groupProductSmall"
          product={props.product}
          group={props.groupName}
        />

        <form className={classes.form}>

          <div className={classes.formSection}>
            <LabelInput
              variant="default"
              label="Add New Location"
                    // multiline={true}
              onChange={handleChange}
              value={formData.Description}
              margin="dense"
              name="Description"
              test="location"
            />
          </div>

          <div className={classes.formSection}>
            <CustomSelect
              label="Type"
              labelId="Type"
              value={formData.Type}
              onChange={handleChange}
              name="Type"
              shrink
              test="location"
            >
              <MenuItem
                value="Internal"
                id="Internal"
                data-cy="menuItem-Internal"
              >
                Internal
              </MenuItem>
              <MenuItem
                value="External"
                id="External"
                data-cy="menuItem-External"
              >
                External
              </MenuItem>
            </CustomSelect>
          </div>
          <DualFormButtons
            className={classes.dualButtons}
            cancelOnClick={() => props.setEditModal(false)}
            saveOnClick={handleSubmit}
            disabled={!formData.Description.trim() || !formData.Type.trim()}
            isSaving={isSaving}
          />
        </form>
      </div>
    </Grid>
  );

}

export default LocationsForm;
