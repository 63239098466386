import { makeStyles } from "@material-ui/core";
import DisplayHTML from "components/utils/displayHTML.component";
import CustomSunEditor from "components/utils/sunEditor.component";
import React from "react";
import ScoreDefinitionsTable from "./methodology/scoreDefinitionsTable.component";
import SessionsSubsection from "./methodology/sessionsSubsection.component";
import SupportingDocsSubsection from "./methodology/supportingDocsSubsection.component";
import { ReportPageSection } from "./reportPageSection.component";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  sectionHtmlItem: {
    alignItems: "stretch",
  },
  sectionCardItem: {
    alignItems: "stretch",
    padding: "16px 32px 24px",
    minHeight: 200
  },
  avoidPageBreak: {
    "@media print": {
      pageBreakInside: "avoid"
    }
  }
}));


export default function MethodologySection(props) {
  const classes = useStyles();
  const {
    company, documents, readOnly = false, report,
    onSubsectionAutosave, section, sessions
  } = props;

  return (
    <ReportPageSection title={section.Section_Header}>
      <div className={classes.sectionHtmlItem} data-cy={`${section.Section_Header}-content`}>
        {section._associations.ReportSubSections.map(subsection => (
          <React.Fragment key={subsection.ReportSubSection_ID}>
            {readOnly ? (
              <DisplayHTML html={subsection.Section_Content} />
            ) : (
              <CustomSunEditor
                height="auto"
                name={`ReportSubSections__${subsection.ReportSubSection_ID}`}
                label={subsection.Section_Header}
                defaultValue={subsection.Section_Content}
                onAutosave={(_name, value) => (
                  onSubsectionAutosave(
                    "Section_Content",
                    value,
                    subsection
                  )
                )}
              />
            )}
          </React.Fragment>
        ))}
      </div>
      {!!readOnly && (
        <>
          <div className={classes.sectionCardItem}>
            <SessionsSubsection
              company={company}
              report={report}
              sessions={sessions}
              preview
            />
          </div>
          <div className={classes.sectionCardItem}>
            <SupportingDocsSubsection
              company={company}
              documents={documents}
              sessions={sessions}
              preview
            />
          </div>
          <div className={classNames(classes.sectionCardItem, classes.avoidPageBreak)}>
            <ScoreDefinitionsTable />
          </div>
        </>
      )}
    </ReportPageSection>
  );
}
