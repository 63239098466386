import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ToolDocTable from "components/utils/tables/toolDocTable.component";
import { Grid } from "@material-ui/core";
import variables from "styleVariables";
import { registry as Services } from "services/registry.service";
import {sortByStringKey, reverseSortIntByStringKey } from "utils/sortingFuncs";
import { setStateFetchEffect } from "utils/ajaxHelpers";


const useStyles = makeStyles({
  link: {
    color: variables.secondaryDark,

    "&:hover": {
      color: variables.primaryMain,
      cursor: "pointer",
    },
  },
});


const ToolDocType = (props) => {
  const classes = useStyles();
  const [allCompFiles, setAllCompFiles] = useState();
  const [refresh, setRefresh] = useState();

  useEffect(() => {
    const isCurrent = (arr, index) => {
      if (index === 0) {
        return true
      } else if (arr[index - 1].ProgramDoc_Group_ID === arr[index].ProgramDoc_Group_ID) {
        return false
      } else {
        return true
      }
    }
    return setStateFetchEffect(
      Services.fileGetAllByComponentObjectId(
        props.componentObject.ComponentObject_ID
      ),
      ([response]) => {
        if (response.payload) {
          const data = [];
          const sortById = reverseSortIntByStringKey(
            response.payload,
            "ProgramDoc_ID"
          );
          const sortByGroupId = sortByStringKey(
            sortById,
            "ProgramDoc_Group_ID"
          );
          sortByGroupId.forEach((row, index) => {
            data.push({
              DocID: row.ProgramDoc_ID,
              key: row.ProgramDoc_ID,
              DocName: row.Title,
              Title: row.Title,
              Description: row.Description,
              Version: row.Version,
              PublishDate: row.PublishDate,
              Status: row.Status,
              Review_Date: row.Change_Date,
              File_Ref: row.File_Ref,
              ProgramDoc_Group_ID: row.ProgramDoc_Group_ID,
              ComponentObjects_ComponentObject_ID:
                row.ComponentObjects_ComponentObject_ID,
              Program_Program_ID: row.Program_Program_ID,
              current: isCurrent(sortByGroupId, index)
            });
          });
          setAllCompFiles(data);
        }
      }
    );
  }, [props.componentObject.ComponentObject_ID, refresh]);

  if (allCompFiles) {
    return (
      <Grid item sm={12} className={classes.tableContainer}>
        <ToolDocTable
          className={classes.test}
          data={allCompFiles}
          state={props.state}
          tableTitle={props.tableTitle}
          componentObject={props.componentObject}
          setRefresh={setRefresh}
        />
      </Grid>
    );
  } else {
    return ""
  }
}

export default ToolDocType;
