import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ClipLoad from "components/utils/clipLoad.component";
import variables from "styleVariables";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  messageRow: {
    display: "flex",
    alignItems: "center",
  },
  messageText: {
    color: variables.textSecondary,
  },
  icon: {
    paddingRight: 5
  }
}));

const SavingProgressMessage = ({ message }) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.messageRow)}>
      <div className={classes.icon}>
        <ClipLoad size={11} color={variables.tertiary2Dark} />

      </div>
      <div className={classes.messageText}>{message || "Saving"}</div>
    </div>
  );
};

export { SavingProgressMessage };
