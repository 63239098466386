import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ButtonDefault from "components/utils/buttonDefault.component";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/RemoveRedEye";
import CommentIcon from '@material-ui/icons/Comment';

const useStyles = makeStyles((theme) => ({

  buttonWrapper: {
    padding: 10,
    paddingBottom: 0,
    [theme.breakpoints.down("md")]: {
      paddingBottom: 15,
      paddingLeft: 0,
    },
  },
  editIcon: {
    fontSize: 15,
    marginRight: 8,
    color: "white",
  },
}));

const AssessmentCardButtons = ({
  builder,
  readOnly,
  variant,
  setMode,
  editCurrentProgBuilder,
  isAssessmentPublished,
  isAssessmentCompleted
}) => {
  const classes = useStyles();

  const dataCollectionDisplay = useMemo(() => {
    if (!isAssessmentCompleted) {
      return readOnly ? "View Data Collection" : "Perform Data Collection"
    } else {
      return readOnly ? "View Responses" : "Write Responses";
    }
  }, [isAssessmentCompleted, readOnly]);

  return(
    <>
      <div className={classes.buttonWrapper}>
        <ButtonDefault
          variant="small"
          background="primary"
          data-cy={`btn-write-review-${builder.Title}`}
          startIcon={readOnly ? (
            <ViewIcon className={classes.editIcon} />
          ) : (
            <CommentIcon className={classes.editIcon} />
          )}
          onClick={
            variant === "listByProgram"
              ? () => editCurrentProgBuilder(builder, "review")
              : () => setMode(`review`, builder)
          }
          disabled={!isAssessmentPublished}
        >
          {dataCollectionDisplay}
        </ButtonDefault>
      </div>
      <div className={classes.buttonWrapper}>
        {readOnly || isAssessmentCompleted ? (
          <ButtonDefault
            variant="small"
            background="secondary"
            data-cy={`btn-current-version-${builder.Title}`}
            startIcon={<ViewIcon className={classes.editIcon} />}
            onClick={
              variant === "listByProgram" ?
                () => editCurrentProgBuilder(builder, "report") :
                () => setMode("report", builder)
            }
          >
            View Report
          </ButtonDefault>
        ) : (
          <ButtonDefault
            variant="small"
            background="secondary"
            data-cy={`btn-current-version-${builder.Title}`}
            startIcon={(readOnly || isAssessmentCompleted) ? (
              <ViewIcon className={classes.editIcon} />
            ) : (
              <EditIcon className={classes.editIcon} />
            )}
            onClick={
              variant === "listByProgram"
                ? () => editCurrentProgBuilder(builder, "editCurrent")
                : () => setMode("editCurrent", builder)
            }
          >
            Update Assessment Scope
          </ButtonDefault>
        )}
      </div>
    </>
  );
};

export default AssessmentCardButtons;