import { Box, IconButton, makeStyles, Paper } from "@material-ui/core";
import { useCallback, useMemo, useEffect, useRef } from "react";
import ReconciliationTablePages from "components/reconciliation/reconciliationTablePages.component";
import { ACTION_REPLACE_DCT_ELEMENT_RESPONSE, ACTION_REPLACE_DCT_QUESTION_RESPONSE, ACTION_SET_DCT_ELEMENTS, ACTION_SET_DCT_INDEX, ACTION_SET_USERS } from "reducers/certification/certification.reducer";
import Loader from "components/utils/loader.components";
import { generatePath, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useNumericParams from "hooks/useNumericParams";
import { COLUMN_TYPE_USER, COLUMN_TYPE_RADIOS, COLUMN_TYPE_TEXT } from "components/reconciliation/table/constants/reconciliationTableConstants";
import userService from "services/user.service";
import certificationService from "services/certification.service";
import { currentUserID } from "utils/userHelpers";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import QuestionsSummary from "components/certification/dct/summary/questionsSummary.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import BackToLink from "components/utils/backToLink.component";
import { RECONCILIATION_MODE_PERSONAL, RECONCILIATION_MODE_RECONCILE } from "../../reconciliation/utils/reconciliation.utils";
import CustomLink from "components/utils/link.component";
import { DCT_ELEMENT_SPECIALTY_DISPLAY, DCT_ELEMENT_TYPE_DISPLAY, DCT_ELEMENT_TYPE_ICONS, CATEGORY_SCROLL_URL_PARAM, DCT_ELEMENT_TYPE_ED, DCT_ELEMENT_TYPE_EP, DCT_ELEMENT_TYPE_SD } from "../utils/dct.utils";
import { H3, H4, H5 } from "components/utils/headerV2.component";
import { ArrowDownward } from "@material-ui/icons";
import DctQuestionSummaryInfo from "./dctQuestionSummaryInfo.component";
import Autocomplete from "components/utils/form-elements/autocomplete.component";
import ScrollTopButton from "components/utils/buttons/scrollTopButton.component";

const useStyles = makeStyles(theme => ({
  categoryLink: {
    fontSize: "0.875rem"
  },
  iconColumn: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap"
  },
  overallSummary: {
    [theme.breakpoints.down("md")]: {
      order: -1
    }
  },
  parentPanel: {
    marginBottom: 16,
    [theme.breakpoints.up("lg")]: {
      marginTop: 32,
      padding: 16,
    }
  },
  elementHeading: {
    marginBottom: 16,
  },
  typeIcon: {
    marginRight: 8,
    color: theme.palette.primary.main,
  },
  summaryItem: {
    marginBottom: 16,
  },
  toggleButtonGroup: {
    "& .MuiToggleButton-root": {
      flex: 1,
      color: theme.palette.secondary.dark,
    },
    "& .MuiToggleButton-root.Mui-selected": {
      backgroundColor: theme.palette.secondary.dark,
      color: "white",
    },
  },
  toggleButton: {
    width: 120
  }
}));

const basePath =
  "/program/:programId/:programComponentId/dct-category/:dctCategoryId/:dctElementEnumeration";

const YES_OPTION = { label: "Yes", value: "Completed", order: 1 };
const NO_OPTION = { label: "No", value: "Incomplete", order: 2 };
const NOT_OBSERVABLE_OPTION = {
  label: "Not Observable", value: "Not Observable", order: 3
};
const NOT_APPLICABLE_OPTION = {
  label: "Not Applicable", value: "Not Applicable", order: 4
};
const DEFAULT_OPTIONS = [YES_OPTION, NO_OPTION];

const typeSort = {
  [DCT_ELEMENT_TYPE_ED]: 3,
  [DCT_ELEMENT_TYPE_EP]: 2,
  [DCT_ELEMENT_TYPE_SD]: 1,
}

const columns = [
  {
    type: COLUMN_TYPE_USER,
    label: "Reporter",
    colSpan: 1,
    key: "User_ID"
  },
  {
    type: COLUMN_TYPE_RADIOS,
    label: "Answer",
    colSpan: 2,
    key: "Answer",
    options: DEFAULT_OPTIONS,
    isCategoryHidden: true
  },
  {
    type: COLUMN_TYPE_TEXT,
    label: "Comments",
    colSpan: 3,
    key: "Notes"
  },
];

function buildAnswerOptions(question) {
  const optionSet = question._associations.DctAnswerOptionSet;
  const customOptions = optionSet._associations.DctCustomAnswerOption || [];

  const options = [];
  if (optionSet.Has_Yes) {
    options.push(YES_OPTION);
  }
  if (optionSet.Has_No) {
    options.push(NO_OPTION);
  }
  if (optionSet.Has_NotObservable) {
    options.push(NOT_OBSERVABLE_OPTION);
  }
  if (optionSet.Has_NotApplicable) {
    options.push(NOT_APPLICABLE_OPTION);
  }
  for (const { Label, Value, Order } of customOptions) {
    options.push({ label: Label, value: Value, order: Order });
  }

  const shouldReturnDefaultInstance = (
    options.length === DEFAULT_OPTIONS.length &&
    DEFAULT_OPTIONS.every((option, index) => option === options[index])
  );
  if (shouldReturnDefaultInstance) {
    return DEFAULT_OPTIONS;
  }

  options.sort((o1, o2) => o1.order - o2.order);
  return options;
}

function normalizeToResponse(dctResponse) {
  if (!dctResponse) {
    return null;
  }
  return {
    data: dctResponse,
    id: dctResponse.DctResponse_ID,
    userId: dctResponse.User_ID
  };
}

function normalizeAssociatedResponses(categoryOrQuestion) {
  const responseTables = categoryOrQuestion._associations.DctResponse;
  if (!responseTables) {
    return [null, []];
  }
  const reconciled = responseTables.find(response => (
    response.Is_Reconciled
  ));
  const userResponses = responseTables.filter(response => (
    !response.Is_Reconciled
  )).map(response => (
    normalizeToResponse(response)
  ));
  const normalizedReconciled = normalizeToResponse(reconciled);
  return [normalizedReconciled, userResponses];
}

function normalizeElementToItem(element) {
  const [
    reconciledResponse,
    userResponses
  ] = normalizeAssociatedResponses(element);
  return {
    id: element.DctElement_ID,
    label: element.Label,
    sublabel: element.SummaryItems,
    type: element.Type,
    specialty: element.Specialty,
    reconciledResponse,
    responses: userResponses,
  };
}

function normalizeQuestionToItem(question) {
  const [
    reconciledResponse,
    userResponses
  ] = normalizeAssociatedResponses(question);

  const config = {};
  const options = buildAnswerOptions(question);
  if (options !== DEFAULT_OPTIONS) {
    config.columns = { Answer: { options }};
  }

  return {
    id: question.DctQuestion_ID,
    label: question.Question,
    sublabelComponent: DctQuestionSummaryInfo,
    sublabelComponentProps: { question },
    reconciledResponse,
    responses: userResponses,
    lastUpdated: Math.max(...(
      question._associations.DctResponse?.map?.(response => (
        new Date(response.Updated_At)
      )) || [0]
    )),
    config,
  };
}

export default function CertificationDctReconciliation(props) {
  const classes = useStyles();
  const history = useHistory();
  const params = useNumericParams();
  const { dctCategoryId, dctElementEnumeration } = params;
  const { dispatch, state } = props;

  // eslint-disable-next-line
  const windowHeight = useMemo(() => window.innerHeight, []);

  const elementRefs = useRef({});
  const questionRefs = useRef({});

  const userId = useMemo(currentUserID, []);
  useEffect(() => (
    async function fetchRequiredData() {
      if (!dctCategoryId || !dctElementEnumeration) {
        return;
      }
      if (state.dctElements && state.users) {
        const hasEnumeratedElements = state.dctElements.some(element => (
          element.Parent_DctCategory_ID === dctCategoryId &&
          element.Enumeration === dctElementEnumeration
        ));
        if (hasEnumeratedElements) {
          return;
        }
      }
      const [
        categoryResponse,
        usersResponse
      ] = await Promise.all([
        certificationService.getDctElementsByEnumeration(
          dctCategoryId,
          dctElementEnumeration
        ),
        userService.getAll()
      ]);
      dispatch({ type: ACTION_SET_USERS, payload: usersResponse.payload });
      dispatch({ type: ACTION_SET_DCT_ELEMENTS, payload: categoryResponse.payload })
    }
  )(), [
    dctCategoryId, dctElementEnumeration, dispatch,
    state.dctElements, state.users
  ]);

  useEffect(() => (
    async function fetchLikelyLoadedData() {
      if (!state.dctIndex) {
        const indexResponse = await certificationService.getDctCategoryIndex();
        dispatch({ type: ACTION_SET_DCT_INDEX, payload: indexResponse.payload });
      }
    }
  )(), [dispatch, state.dctIndex]);

  const backPath = useMemo(() => (
    generatePath("/program/:programId/:programComponentId", params)
  ), [params]);

  const flattenedCategories = useMemo(() => (
    state.dctIndex && state.dctIndex.concat(
      state.dctIndex.flatMap(indexItem => (
        indexItem?._associations?.DctCategory || []
      ))
    )
  ), [state.dctIndex]);

  const activeSubcategoryIndex = useMemo(() => (
    flattenedCategories?.find?.(indexItem => (
      indexItem.DctCategory_ID === params.dctCategoryId
    ))
  ), [flattenedCategories, params.dctCategoryId]);

  const activeParentCategoryIndex = useMemo(() => (
    activeSubcategoryIndex && flattenedCategories?.find?.(indexItem => (
      indexItem.DctCategory_ID === activeSubcategoryIndex.Parent_DctCategory_ID
    ))
  ), [activeSubcategoryIndex, flattenedCategories]);

  const handleGoToCategory = useCallback(() => {
    const id = activeParentCategoryIndex.DctCategory_ID;
    history.push(`${backPath}?${CATEGORY_SCROLL_URL_PARAM}=${id}`);
  }, [activeParentCategoryIndex, backPath, history]);

  const handleGoToSubcategory = useCallback(() => {
    const id = activeSubcategoryIndex.DctCategory_ID;
    history.push(`${backPath}?${CATEGORY_SCROLL_URL_PARAM}=${id}`);
  }, [activeSubcategoryIndex, backPath, history]);

  const activePageElements = useMemo(() => {
    const elementIndex = activeSubcategoryIndex?._associations?.DctElement;
    if (!elementIndex?.length || !state.dctElements) {
      return [];
    }

    const elementsById = state.dctElements.reduce(
      (accumulator, category) => ({
        ...accumulator,
        [category.DctElement_ID]: category
      }), {}
    );

    return elementIndex.filter(indexItem => (
      indexItem.Enumeration === params.dctElementEnumeration
    )).map(indexItem => (
      elementsById[indexItem.DctElement_ID]
    )).filter(item => item);
  }, [activeSubcategoryIndex, params.dctElementEnumeration, state.dctElements]);

  const normalizedDctElements = useMemo(() => (
    activePageElements.map?.(element => ({
      categoryItem: normalizeElementToItem(element),
      enumeration: (
        `${activeParentCategoryIndex?.Enumeration}.` +
        `${activeSubcategoryIndex?.Enumeration}.` +
        `${element.Enumeration}`
      ),
      items: element._associations?.DctQuestion?.map?.(item => (
        normalizeQuestionToItem(item)
      )) || [],
    })).sort((r1, r2) => {
      return r1.enumeration.localeCompare(r2.enumeration) ||
      typeSort[r1.categoryItem.type] - typeSort[r2.categoryItem.type]
    })
  ), [activeParentCategoryIndex, activeSubcategoryIndex, activePageElements]);

  const handleElementScroll = useCallback(element => {
    const target = elementRefs.current[element.categoryItem.id];
    target.scrollIntoView({ behavior: "smooth" })
  }, []);

  const handleQuestionScroll = useCallback((_event, option) => {
    setTimeout(() => {
      const target = questionRefs.current[option.value];
      target.scrollIntoView({ behavior: "smooth" })
    }, 0);
  }, []);

  const buildUpsertRequest = useCallback((response, isReconciled, name, value) => ({
    ...response?.data,
    Is_Reconciled: isReconciled ? 1 : 0,
    User_ID: userId,
    [name]: value
  }), [userId]);

  const handleCategoryAutosave = useCallback(
    async (response, category, isReconciled, name, value) => {
      const upsertedCategory = await certificationService.upsertDctElementResponse(
        category.id,
        buildUpsertRequest(response, isReconciled, name, value)
      );
      dispatch({
        type: ACTION_REPLACE_DCT_ELEMENT_RESPONSE,
        payload: upsertedCategory.payload
      });
    },
    [buildUpsertRequest, dispatch]
  );

  const handleQuestionAutosave = useCallback(
    async (response, question, isReconciled, name, value) => {
      const upsertedCategory = await certificationService.upsertDctQuestionResponse(
        question.id,
        buildUpsertRequest(response, isReconciled, name, value)
      );
      dispatch({
        type: ACTION_REPLACE_DCT_QUESTION_RESPONSE,
        payload: upsertedCategory.payload
      });
    },
    [buildUpsertRequest, dispatch]
  );

  const handleModeClick = useCallback(event => {
    history.push(generatePath(`${basePath}/:mode`, {
      ...params,
      mode: event.currentTarget.value
    }));
  }, [history, params]);

  if (!activePageElements || !normalizedDctElements?.[0] || !state.users) {
    return (
      <Loader />
    );
  }
  return (
    <div>
      <BackToLink
        href={backPath}
        parentPage="all DCT Elements"
      />
      <GridContainer spacing={2} hasVerticalSpacing>
        <GridItem xs={12} lg={9} xl={10}>
          <H3 className={classes.elementHeading}>
            {normalizedDctElements[0].enumeration}&nbsp;
            {normalizedDctElements[0].categoryItem.label}
          </H3>
          <GridContainer spacing={2}>
            {normalizedDctElements?.map?.((element, _index, all) => {
              const IconComponent = DCT_ELEMENT_TYPE_ICONS[
                element.categoryItem.type
              ];
              const categoryType = element?.categoryItem?.type
              return (
                <GridItem
                  xs={12}
                  xl={6}
                  className={classes.summaryItem}
                  key={element.categoryItem.id}
                >
                  <Paper>
                    <Box
                      width="100%"
                      padding={2}
                      paddingTop={2}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <div className={classes.iconColumn}>
                          <span className={classes.typeIcon}>
                            <IconComponent size={32} />
                          </span>
                          <H4>
                            {DCT_ELEMENT_TYPE_DISPLAY[element.categoryItem.type]}
                          </H4>
                        </div>
                        <H4>
                          {DCT_ELEMENT_SPECIALTY_DISPLAY[element.categoryItem.specialty]}
                        </H4>
                      </Box>
                      <Box display="flex" justifyContent="space-between" data-cy={`${categoryType}-summary`}>
                        <Box flex="1">
                          <QuestionsSummary
                            elements={activePageElements.find(stateElement => (
                              element.categoryItem.id === stateElement.DctElement_ID
                            ))}
                            mode={params.mode}
                          />
                        </Box>
                        <Box
                          display="flex"
                          alignItems="end"
                          margin="0 0 -8px 32px"
                        >
                          <Box marginRight={1}>
                            <IconButton
                              onClick={() => handleElementScroll(element)}
                            >
                              <ArrowDownward color="secondary" />
                            </IconButton>
                          </Box>
                          <Autocomplete
                            name={`question-scroll-${element.DctElement_ID}`}
                            options={
                              element.items.map(
                                (question, index) => ({
                                  value: question.id,
                                  label: `${index + 1}`
                                })
                              )
                            }
                            label="Jump to"
                            placeholder={`${element.items.length}`}
                            closeIcon={null}
                            onChange={handleQuestionScroll}
                            autoHighlight
                            blurOnSelect
                            clearOnBlur
                            hideHelperText
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </GridItem>
              );
            })}
          </GridContainer>
        </GridItem>
        <GridItem xs={12} lg={3} xl={2} className={classes.overallSummary}>
          <div className={classes.parentPanel}>
            <GridContainer spacing={2} hasVerticalSpacing>
              <GridItem xs={6} lg={12}>
                <H5 color="tertiary">
                  Overall Category
                </H5>
                <div>
                  <CustomLink
                    onClick={handleGoToCategory}
                    className={classes.categoryLink}
                  >
                    {activeParentCategoryIndex?.Enumeration}.
                    &nbsp;
                    {activeParentCategoryIndex?.Label}
                  </CustomLink>
                </div>
              </GridItem>
              <GridItem xs={6} lg={12}>
                <H5 color="tertiary">
                  Subcategory
                </H5>
                <div>
                  <CustomLink
                    onClick={handleGoToSubcategory}
                    className={classes.categoryLink}
                  >
                    {activeParentCategoryIndex?.Enumeration}.{activeSubcategoryIndex?.Enumeration}.
                    &nbsp;
                    {activeSubcategoryIndex?.Label}
                  </CustomLink>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </GridItem>
      </GridContainer>
      <ReconciliationTablePages
        basePath={basePath}
        categorizedItems={normalizedDctElements}
        columns={columns}
        itemLabel="Question"
        modeTabs={(
          <Paper>
            <ToggleButtonGroup
              className={classes.toggleButtonGroup}
              size="medium"
              value={params.mode}
              exclusive
            >
              <ToggleButton
                className={classes.toggleButton}
                value={RECONCILIATION_MODE_PERSONAL}
                onClick={handleModeClick}
                data-cy="toggle-my-answers"
              >
                My Answers
              </ToggleButton>
              <ToggleButton
                className={classes.toggleButton}
                value={RECONCILIATION_MODE_RECONCILE}
                onClick={handleModeClick}
                data-cy="toggle-reconcile"
              >
                Reconcile
              </ToggleButton>
            </ToggleButtonGroup>
          </Paper>
        )}
        onCategoryAutosave={handleCategoryAutosave}
        onItemAutosave={handleQuestionAutosave}
        categoryRefs={elementRefs}
        questionRefs={questionRefs}
        sublabelFilterLabel="Summary Information:"
        users={state.users}
        userFilterLabel="Others' Responses:"
        filterSublabel
      />
      <ScrollTopButton scrollThreshold={windowHeight} />
    </div>
  );
}
