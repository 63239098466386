import moment from "moment";
import { Box, Divider, makeStyles, Paper } from "@material-ui/core";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import Header from "components/utils/header.component";
import { makePossessive } from "utils/stringFuncs";
import classNames from "classnames";
import styleVariables from "styleVariables";
import { findTimezoneShort } from "utils/timeHelpers";
import Loader from "components/utils/loader.components";
import CustomLink from "components/utils/link.component";
import { CheckCircleOutline, CloudUpload } from "@material-ui/icons";
import { followUpTypes } from "utils/portfolioConstants";

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "hidden",
    color: theme.palette.text.secondary,
  },
  noBoxShadow: {
    boxShadow: "none"
  },
  containerWide: {
    width: "100%",
  },
  headingTab: {
    padding: "3px 16px",
    background: styleVariables.tertiary1,
    textAlign: "center",
  },
  itemDivider: {
    marginTop: -40,
    marginBottom: 24,
    [theme.breakpoints.only("md")]: {
      display: "none"
    }
  },
  list: {
    marginTop: 0,
    marginBottom: 8,
  },
  documentLink: {
    display: "inline",
    whiteSpace: "nowrap"
  },
  documentIcon: {
    fontSize: "1rem",
    verticalAlign: "text-bottom",
    margin: "0 4px"
  },
  resolved: {
    color: styleVariables.statusGreenDark,
  },
  rowHeading: {
    padding: 0,
    minWidth: 75,
    justifyContent: "flex-end",
  },
  sessionItem: {
    alignItems: "stretch",
    padding: "24px 32px 32px",
    minHeight: 200,
  },
  referenceSessionItem: {
    padding: "20px 30px 36px 30px",
  },
  avoidPageBreak: {
    "@media print": {
      pageBreakInside: "avoid"
    }
  },
  value: {
    paddingLeft: 8,
  },
  message: {
    display: "block",
    margin: "0px auto",
  },
}));

export default function SessionsSubsection(props) {
  const classes = useStyles();
  const {
    company, editReferenceView, onFollowUpClick, preview, sessions
  } = props;

  if (!sessions) {
    return <Loader />;
  }
  return (
    <Paper
      className={classNames(
        classes.container,
        classes.avoidPageBreak,
        sessions?.length === 1 && !editReferenceView && classes.containerWide,
        editReferenceView && classes.noBoxShadow,
      )}
    >
      {!editReferenceView && (
        <div className={classes.headingTab}>
          <Header variant="h4White">
            {makePossessive(company?.Name)} Sessions Conducted
          </Header>
        </div>
      )}
      {sessions?.length ? (
        <GridContainer spacing={4}>
          {sessions.map((session, index, all) => (
            <GridItem
              xs={12}
              md={all.length > 2 ? 6 : 12}
              lg={preview ? 4 : 12}
              key={session.Session_ID}
            >
              <div className={classNames(classes.sessionItem, editReferenceView && classes.referenceSessionItem)}>
                {index > 0 && !preview && (
                  <Divider className={classes.itemDivider} />
                )}
                <Box alignItems="start" display="flex" marginBottom={1}>
                  <Header className={classes.rowHeading} variant="h5Primary">
                    Date:
                  </Header>
                  <Box className={classes.value} data-cy="session_date">
                    {moment(session.Start).format("MMMM D, yyyy")}
                  </Box>
                </Box>
                <Box alignItems="start" display="flex" marginBottom={1}>
                  <Header variant="h5Primary" className={classes.rowHeading}>
                    Start Time:
                  </Header>
                  <Box className={classes.value} data-cy="session_start_time">
                    {moment(session.Start).format("hh:mma")}
                    &nbsp;
                    {findTimezoneShort(moment(session.Start))}
                  </Box>
                </Box>
                <Box alignItems="start" display="flex" marginBottom={1}>
                  <Header variant="h5Primary" className={classes.rowHeading}>
                    End Time:
                  </Header>
                  <Box className={classes.value} data-cy="session_end_time">
                    {moment(session.End).format("hh:mma")}
                    &nbsp;
                    {findTimezoneShort(moment(session.End))}
                  </Box>
                </Box>
                <div>
                  <Header variant="h5Primary" className={classes.rowHeading}>
                    Attendees:
                  </Header>
                  <ul className={classNames(classes.list, classes.unorderedList)}>
                    {session._associations.SessionAttendees.map((attendee) => (
                      <li
                        className={classes.listItem}
                        key={attendee.Attendee_ID}
                      >
                        {attendee.UserName}
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  <Header variant="h5Primary" className={classes.rowHeading}>
                    Follow-ups:
                  </Header>
                  <ol className={classes.list}>
                    {session._associations.SessionFollowUps.map((followUp) => (
                      <li
                        className={classes.listItem}
                        key={followUp.FollowUp_ID}
                        data-cy={`session_followup_${followUp.FollowUp_ID}`}
                      >
                        {followUp.Description}
                        &nbsp;
                        {!!onFollowUpClick && followUp.Type === followUpTypes.DOCUMENT && (
                          followUp.Status === "Resolved" ? (
                            <span
                              className={
                                classNames(
                                  classes.documentLink, classes.resolved
                                )
                              }
                            >
                              <CheckCircleOutline
                                className={classes.documentIcon}
                              />
                              Resolved
                            </span>
                          ) : (
                            <CustomLink
                              variant="noHRef"
                              className={classes.documentLink}
                              onClick={() => onFollowUpClick(followUp)}
                            >
                              <CloudUpload
                                className={classes.documentIcon}
                              />
                              Upload Document
                            </CustomLink>
                          )
                        )}
                      </li>
                    ))}
                  </ol>
                </div>
              </div>
            </GridItem>
          ))}
        </GridContainer>
      ) : (
        <Box className={classes.message}>
          <p>No assessment sessions have been conducted for this company.</p>
        </Box>
      )}
    </Paper>
  );
}
