import React, { useEffect, useMemo, useContext } from 'react';
import { useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Toolbar } from "@material-ui/core";
import CustomCard from "components/utils/card.component"
import CustomContainer from "components/utils/container.component.js";
import { CustomComponent } from "components/types/registry.component";
import PageHeader from "components/utils/pageHeader.component";
import variables from "styleVariables";
import classNames from "classnames";
import { sortByStringKey } from "utils/sortingFuncs";
import CustomIcons from "components/utils/icons.component";
import ProgramDashboard from 'components/types/programDashboard.component';
import DocumentType from 'components/layouts/comp-types/documentType.component';
import ToolDocType from "components/layouts/comp-types/toolDocType.component";
import ternaryLogic from "utils/ternaryLogic";
import NotFound from 'components/auth/notFound.component';
import { portfolioProgramComponentIDs } from 'utils/portfolioConstants';
import { generatePath, matchPath, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useNumericParams from 'hooks/useNumericParams';
import {
  customProgramComponentHeadings,
  PROGRAM_COMPONENT_ID_REQUIREMENTS,
  PROGRAM_COMPONENT_ID_COMPLIANCE,
  PROGRAM_ID_PORTFOLIO,
  PROGRAM_COMPONENT_TYPE_DASHBOARD,
  PROGRAM_COMPONENT_TYPE_CUSTOM,
  PROGRAM_COMPONENT_TYPE_REDIRECT,
  COMPONENT_OBJECT_TYPE_TOOL_DOCUMENT,
  COMPONENT_OBJECT_TYPE_DOCUMENT_LIBRARY,
  COMPONENT_OBJECT_TYPE_DOCUMENT,
  COMPONENT_OBJECT_TYPE_BUILDER,
  COMPONENT_OBJECT_TYPE_CUSTOM
} from 'utils/programConstants';
import { DocumentLibraryType } from '../comp-types/documentLibraryType.component';
import ProgramsContext from 'contexts/programs.context';
import { PATH_COMPONENT_OBJECT } from '../constants/routes.constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: "stretch",
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(30),
      height: "",
    },
  },
  gridContainer: {
    marginLeft: 40,
  },
  groupWrapper: {
    paddingBottom: 50,
    paddingTop: 30,
    paddingLeft: 30,
  },
  groupHeader: {
    fontSize: variables.fontH3,
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    left: 0,
    color: variables.primaryMain,
  },
  customIconWrapper: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
    height: 30,
    width: 30,
    padding: 7,
    borderRadius: 50,
  },
  icon: {
    fill: "white",
  },
  builderIcon: {
    paddingLeft: 9,
    paddingRight: 6,
  },
  documentIcon: {
    padding: 8,
  },
  mainContentContainer: {
    marginTop: 5,
  },
  customColor: {
    color: variables.secondaryDark,
  },
  builderColor: {
    color: variables.tertiary2Dark,
  },
  documentColor: {
    color: variables.tertiary3,
  },
  customColorBackground: {
    background: 'radial-gradient(ellipse farthest-side at 65% 38%, rgb(12, 133, 215) 14%, rgb(0, 122, 204) 58%, rgb(1, 92, 153) 93%, rgb(2, 87, 145) 100%)',
  },
  builderColorBackground: {
    background: 'radial-gradient(ellipse farthest-side at 65% 38%, #058faa 14%, #00829b 58%, #02697e 93%, #005c6f 100%)',
  },
  documentColorBackground: {
    background: 'radial-gradient(ellipse farthest-side at 65% 38%, #5b5aba 14%, #4c4baa 58%, #444398 93%, #38367c 100%)',
  },
  tableTitle: {
    fontSize: variables.fontLarge,
    fontWeight: "bold",
    paddingBottom: 15,
  },
  tertiary1: {
    color: variables.tertiary1,
  },
  left: {
    width: "49%",
    margin: 0,
    paddingBottom: 25,
    [theme.breakpoints.down('lg')]: {
      width: "100%",
      paddingRight: 0,
    },
  },
  right: {
    width: "49%",
    margin: 0,
    marginLeft: "auto",
    paddingBottom: 25,
    [theme.breakpoints.down('lg')]: {
      width: "100%",
      paddingLeft: 0,
    },
  },
}));

// List of ComponentObject types
//   can add more here
//   styling is done by adding a class above for `*type*-wrapper` and `*type*-header`
const COMPONENT_TYPES = [
  { type: COMPONENT_OBJECT_TYPE_CUSTOM, label: "Data Management & Tools" }, // or no type
  { type: COMPONENT_OBJECT_TYPE_BUILDER, label: "Document Builders & Templates" },
  { type: COMPONENT_OBJECT_TYPE_DOCUMENT, label: "Published Documents" },
  { type: COMPONENT_OBJECT_TYPE_DOCUMENT_LIBRARY, label: "Document Library" },
];

//Function to determine cards mapped to ComponentType
const cardLogic = (type, cardsByStatus) => {
  let cards = cardsByStatus.filter((item) => (
    item.Type === type.type ||
    (item.Type === "" && type.type === COMPONENT_OBJECT_TYPE_CUSTOM) ||
    (item.Type === COMPONENT_OBJECT_TYPE_TOOL_DOCUMENT && type.type === COMPONENT_OBJECT_TYPE_DOCUMENT))
  );

  if ([COMPONENT_OBJECT_TYPE_DOCUMENT, COMPONENT_OBJECT_TYPE_DOCUMENT_LIBRARY].includes(type.type)) {
    cards = sortByStringKey(cards, "TypeRef") //Puts TypeRef "Current" ahead of "Previous"
    cards = sortByStringKey(cards, "Type") //Puts Document ahead of ToolDocument
  }
  return cards
}

const DOCUMENT_COMPONENT_TYPES = [COMPONENT_OBJECT_TYPE_DOCUMENT, COMPONENT_OBJECT_TYPE_DOCUMENT_LIBRARY, COMPONENT_OBJECT_TYPE_TOOL_DOCUMENT];

export default function Content(props) {
  const classes = useStyles();
  const params = useNumericParams();
  const { programId, programComponentId, reportId } = params;
  const history = useHistory();
  const { state } = useContext(ProgramsContext);

  const location = useLocation();

  const programComponent = useMemo(() => (
    state.programComponents?.[programComponentId] ||
    state.programComponentsByProgram?.[programId]?.[0] ||
    (state.programComponents && Object.values(state.programComponents)?.[0])
  ), [
    programId, programComponentId, state.programComponents,
    state.programComponentsByProgram]
  );

  const componentObjects = useMemo(() => (
    state.componentObjectsByComponent?.[programComponentId]
  ), [state.componentObjectsByComponent, programComponentId]);

  useEffect(() => {
    if (programComponent?.Type === PROGRAM_COMPONENT_TYPE_REDIRECT) {
      return history.replace(generatePath(
        PATH_COMPONENT_OBJECT,
        { ...params, componentObjectId: programComponent?.TypeRef }
      ));
    }
  }, [programComponent, history, params]);

  const readyToLoad = useMemo(() => (
    !!state?.programComponents
  ), [state.programComponents]);

  const isInvalidComponentObject = useMemo(() => (
    !programComponent &&
    !!state.programComponents &&
    !!state.programComponentsByProgram
  ), [
    programComponent, state.programComponents, state.programComponentsByProgram
  ]);

  const allCards = useMemo(() => (
    componentObjects &&
    sortByStringKey(
      sortByStringKey(componentObjects, "Name"),
      "Status"
    )
  ), [componentObjects]);

  const cardsByType = useMemo(() => (
    allCards &&
    COMPONENT_TYPES.map(typeItem => ({
      type: typeItem.type,
      label: typeItem.label,
      cards: cardLogic(typeItem, allCards)
    }))
  ), [allCards]);

  const classesByType = useMemo(() => ({
    Custom: {
      background: classes.customColorBackground,
      color: classes.customColor,
      icon: "",
    },
    Builder: {
      background: classes.builderColorBackground,
      color: classes.builderColor,
      icon: classes.builderIcon,
    },
    Document: {
      background: classes.documentColorBackground,
      color: classes.documentColor,
      icon: classes.documentIcon,
    },
  }), [classes]);

  const pageTitle = useMemo(() => {
    if (!state.programComponents) {
      return "";
    }
    if (programComponentId) {
      const customTitle = customProgramComponentHeadings[programComponentId];
      if (customTitle) {
        return customTitle;
      }
    }
    if (programComponent) {
      return programComponent.Name;
    }
    return state.programComponents[0]?.Name;
  }, [programComponent, programComponentId, state.programComponents]);

  const cardAlertCounts = useMemo(() => {
    if (!allCards || !state.userAlerts) {
      return {};
    }
    const defaultCounts = Object.fromEntries(allCards.map(card => (
      [card.ComponentObject_ID, 0]
    )));
    state.userAlerts.reduce((sums, { ComponentObject_ID }) => ({
      ...sums,
      [ComponentObject_ID]: sums[ComponentObject_ID] + 1
    }), defaultCounts)
  }, [allCards, state.userAlerts]);

  const hideTitle = () => {
    if (location.pathname.startsWith(`/program/${PROGRAM_ID_PORTFOLIO}/${portfolioProgramComponentIDs.REPORTS}/holding/`) && reportId) {
      return true;
      // Portfolio assessment page
    } else if (matchPath(location.pathname, {path: `/program/${PROGRAM_ID_PORTFOLIO}/${portfolioProgramComponentIDs.ASSESSMENTS}/holding/:holdingId/portCo/:portCoId/assessment`})) {
      return true
    } else if (matchPath(location.pathname, {path: `/program/:programId/:componentId/requirementForm`})) {
      return true
    }
    return false;
  }

  if(isInvalidComponentObject) {
    return (
      <NotFound />
    );
  } else if (!readyToLoad) {
    return null;
  }
  return (
    <CustomContainer>
      <Toolbar />
      {!hideTitle() && (
        <PageHeader
          title={pageTitle}
          titleOnly={
            programComponentId === PROGRAM_COMPONENT_ID_REQUIREMENTS ||
            programComponentId === PROGRAM_COMPONENT_ID_COMPLIANCE
          }
          dashboardTitle={programComponent.Type === "Dash"}
        />
      )}
      {(
        !!cardsByType &&
        programComponent?.Type !== PROGRAM_COMPONENT_TYPE_CUSTOM &&
        programComponent?.Type !== PROGRAM_COMPONENT_TYPE_DASHBOARD
      ) && (
        <Grid container spacing={8} className={classes.mainContentContainer}>
          <Grid item sm={12} className={classes.gridContainer}>
            {cardsByType.map(type => {
              return (
                <div key={type.type}>
                  {/* Component Object Grouping Labels */}
                  {type.cards.length > 0 && (
                    <div
                      className={
                        classNames(
                          classes.groupHeader,
                          classesByType[type.type]?.color
                        )
                      }
                    >
                      <div
                        className={
                          classNames(
                            classes.customIconWrapper,
                            classesByType[type.type]?.icon,
                            classesByType[type.type]?.background
                          )
                        }
                      >
                        <CustomIcons variant={`component-${type.type}`} className={classes.icon} />
                      </div>
                      {type.label}
                    </div>
                  )}
                  {type.cards.length > 0 && (
                    <ProgramComponentCardWrapper>
                      {type.cards.map((card, index) => (
                        DOCUMENT_COMPONENT_TYPES.includes(card.Type) ? (
                          <DocumentTypeComponent
                            componentObject={card}
                            index={index}
                            key={card.Name}
                          />
                        ) : (
                          <CustomCard
                            variant={ternaryLogic(card.Status, "Active", "link", "disabled")}
                            key={card.Name}
                            icon={card.Nav_Icon}
                            title={card.Name}
                            description={card.Description}
                            alert={
                              cardAlertCounts[card.ComponentObject_ID] ||
                              undefined
                            }
                            url={`/program/${programId}/${programComponent.Component_ID}/${card.ComponentObject_ID}`}
                            type={card.Type}
                          />
                        )
                      ))}
                    </ProgramComponentCardWrapper>
                  )}
                </div>
              )
            })}
          </Grid>
        </Grid>
      )}
      {programComponent?.Type === PROGRAM_COMPONENT_TYPE_DASHBOARD && (
        <ProgramDashboard />
      )}
      {programComponent?.Type === PROGRAM_COMPONENT_TYPE_CUSTOM && (
        <CustomComponent
          typeRef={programComponent.TypeRef}
        />
      )}
    </CustomContainer>
  );
}

const DocumentTypeComponent = ({ componentObject, index }) => {
  const classes = useStyles();
  const { Name, Type } = componentObject;

  const isEven = useMemo(() => index % 2 === 0, [index]);
  const isPrevious = Name.startsWith('Previous');

  switch(Type) {
    case COMPONENT_OBJECT_TYPE_TOOL_DOCUMENT:
      return (
        <ToolDocType
          componentObject={componentObject}
          tableTitle={
            <div
              className={classNames(classes.tableTitle, classes.documentColor)}
            >
              {Name}
            </div>
          }
        />
      );
    case COMPONENT_OBJECT_TYPE_DOCUMENT_LIBRARY:
      return (
        <DocumentLibraryType
          componentObject={componentObject}
          tableTitle={
            <div
              className={classNames(classes.tableTitle, classes.documentColor)}
            >
              {Name}
            </div>
          }
        />
      );
    case COMPONENT_OBJECT_TYPE_DOCUMENT:
    default:
      return (
        <div className={isEven ? classes.left : classes.right}>
          <DocumentType
            componentObject={componentObject}
            tableTitle={
              <div
                className={
                  classNames(
                    classes.tableTitle,
                    isPrevious ? classes.tertiary1 : classes.documentColor
                  )
                }
              >
                {Name}
              </div>
            }
          />
        </div>
      );
  }
};

const ProgramComponentCardWrapper = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.root, classes.groupWrapper)}>
      {children}
    </div>
  );
};
