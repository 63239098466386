import React from "react";
import { downloadFileByRef } from "utils/downloadFile";
import { makeStyles } from "@material-ui/core/styles";
import CustomLink from "components/utils/link.component";
import AttachmentOutlinedIcon from "@material-ui/icons/AttachmentOutlined";
import variables from "styleVariables";
import Tooltip from "./tooltip.component";

const useStyles = makeStyles((theme) => ({
  referenceLinkWrapper: {
    display: "flex",
    alignItems: "center",
    "&:hover": {
      "& $attachmentIcon": {
        fill: variables.primaryLight,
      },
    },
  },
  attachmentIcon: {
    fontSize: ".9rem",
    fill: variables.secondaryDark,
    marginRight: 8,
  },
}));

const DownloadableDocLink = ({ referenceName, fileRef, displayText, tooltipText = ""}) => {

  const classes = useStyles();
  return (
    <div className={classes.referenceLinkWrapper}>
      <AttachmentOutlinedIcon className={classes.attachmentIcon} />
      <CustomLink
        variant="underline"
        onClick={() => (
          downloadFileByRef(fileRef, referenceName)
        )}
        test={referenceName}
      >
        <Tooltip title={tooltipText} placement="top">
          <span>{displayText}</span>
        </Tooltip>
      </CustomLink>
    </div>
  );
};

export default DownloadableDocLink;
