/*
 * SCHEMAS
 * Reducer schemas are association definitions used for merging reducer state.
 * These definitions are passed to schema-based merge functions, showing the
 *   structure of the state and how the action's payload fits into that.
 *
 * Each schema level can have 5 attributes:
 * - Required Attributes:
 *   - type: Name of the db table
 *   - id: Name of the primary key column
 * - Parent Attributes:
 *   - association: Mid-level schema for intermediate table found in _associations attribute
 *   - target: Bottom-level child schema to get results from. The 'goal' item.
 *     - Multiple targets for a schema (one per association) are allowed.
 * - Child Attributes
 *   - parentId: Name of the foreign key column linking current item to parent (if any)
 *     - Applies to target and association child schemas only
*/

const chapterSchemaLevel = {
  type: "ManualSection",
  id: "ManualSection_ID",
  parentId: "Manual_ID",
};

const sectionSchemaLevel = {
  type: "ManualSection",
  id: "ManualSection_ID",
  parentId: "Parent_ManualSection_ID",
};

const referenceSchemaLevel = {
  type: "ManualSectionReference",
  id: "ManualSectionReference_ID",
  parentId: "ManualSection_ID",
};


export const manualChapterSchema = {
  type: "Manual",
  id: "Manual_ID",
  target: chapterSchemaLevel
};

export const manualSectionAllLevelSchema = {
  type: "Manual",
  id: "Manual_ID",
  association: {
    ...chapterSchemaLevel,
    association: {
      ...sectionSchemaLevel,
      association: {
        ...sectionSchemaLevel,
        target: sectionSchemaLevel
      },
      target: sectionSchemaLevel,
    },
    target: sectionSchemaLevel
  },
  target: chapterSchemaLevel
};

export const manualReferenceSchema = {
  type: "Manual",
  id: "Manual_ID",
  association: {
    ...chapterSchemaLevel,
    association: {
      ...sectionSchemaLevel,
      association: {
        ...sectionSchemaLevel,
        association: {
          ...sectionSchemaLevel,
          target: referenceSchemaLevel,
        },
        target: referenceSchemaLevel,
      },
      target: referenceSchemaLevel,
    },
    target: referenceSchemaLevel,
  }
};
