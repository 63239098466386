import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  stepContentWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "100vw",
    overflow: "scroll",
    alignItems: "center",
    height: "100%",
    paddingLeft: 60,
    paddingRight: 60,
    paddingTop: 40,
    maxHeight: "calc(100vh - 352px)",
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  childrenWrapper: {
    maxWidth: 1500,
    width: "100%",
  },
  contentWrapperComplete: {
    maxHeight: "calc(100vh - 140px)",
  },
  contentWrapperReadOnly: {
    maxHeight: "calc(100vh - 200px)",
  }
}));

const StepperContent = ({ children, mode, readOnly }) => {
  const classes = useStyles();
  return (
    <div
      className={
        classNames(
          classes.stepContentWrapper,
          readOnly && classes.contentWrapperReadOnly,
          !readOnly && mode === "complete" && classes.contentWrapperComplete
        )
      }
    >
      <div className={classes.childrenWrapper}>{children}</div>
    </div>
  );
};

export default StepperContent;
