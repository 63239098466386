import React, { useContext, useMemo } from "react";
import useNumericParams from "hooks/useNumericParams";
import { Box, Toolbar } from "@material-ui/core";
import BackToLink from "components/utils/backToLink.component";
import CustomContainer from "components/utils/container.component";
import BuilderType from "components/layouts/comp-types/builderType.component";
import { CustomComponent } from "components/types/registry.component";
import DataTableType from "components/layouts/comp-types/dataTableType.component";
import DocumentType from "components/layouts/comp-types/documentType.component";
import TableType from "components/layouts/comp-types/tableType.component";
import PageHeader from "components/utils/pageHeader.component";
import { isGlobalUser } from "utils/roles";
// services
import { matchPath, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  COMPONENT_OBJECT_ID_TRAINING_MODULES,
  COMPONENT_OBJECT_ID_CERT_TRAINING_MODULES,
  customComponentObjectHeadings,
  PROGRAM_COMPONENT_TYPE_REDIRECT,
  COMPONENT_OBJECT_TYPE_CUSTOM,
  COMPONENT_OBJECT_TYPE_BUILDER,
  COMPONENT_OBJECT_TYPE_DATA_TABLE,
  COMPONENT_OBJECT_TYPE_DOCUMENT_LIBRARY,
  COMPONENT_OBJECT_TYPE_DOCUMENT,
  COMPONENT_OBJECT_TYPE_TABLE
} from "utils/programConstants";
import { DocumentLibraryType } from "../comp-types/documentLibraryType.component";
import ProgramsContext from "contexts/programs.context";
import { PATH_COMPONENT_OBJECT } from "../constants/routes.constants";

const titleOnlyIds = [
  COMPONENT_OBJECT_ID_TRAINING_MODULES
];

const excludedBackLinkIds = [
  COMPONENT_OBJECT_ID_TRAINING_MODULES,
  COMPONENT_OBJECT_ID_CERT_TRAINING_MODULES
];


const ComponentObject = (props) => {
  const { programId, programComponentId, componentObjectId } = useNumericParams();
  const { state } = useContext(ProgramsContext);

  const location = useLocation();

  const customHeading = customComponentObjectHeadings[componentObjectId];

  const isGlobalUserRole = useMemo(isGlobalUser, []);

  /*** Use Effects ***/
  const componentObject = useMemo(() => (
    state.componentObjects?.[componentObjectId]
  ), [componentObjectId, state.componentObjects]);

  const programComponent = useMemo(() => {
    const componentId = componentObject?.ProgramComponents_Component_ID;
    return componentId ? state.programComponents?.[componentId] : null;
  }, [componentObject, state.programComponents]);

  /*** Use Effects ***/

  // RENDER
  if (componentObject && programComponent && componentObject.Type) {
    return (
      <CustomContainer>
        <Toolbar />
        {componentObject.Type !== COMPONENT_OBJECT_TYPE_BUILDER && (
          matchPath(location.pathname, {
            path: PATH_COMPONENT_OBJECT,
            strict: true,
            exact: true
          }) ? (
              <>
                <PageHeader
                  title={customHeading || componentObject.Name}
                  titleOnly={
                    !!customHeading ||
                  titleOnlyIds.includes(componentObject.ComponentObject_ID)
                  }
                  withTasks={isGlobalUserRole}
                />
                {(
                  programComponent.Type !== PROGRAM_COMPONENT_TYPE_REDIRECT &&
                componentObject.Type === COMPONENT_OBJECT_TYPE_CUSTOM &&
                !!programComponent.TypeRef &&
                programComponent.TypeRef !== componentObject.TypeRef &&
                !excludedBackLinkIds.includes(componentObject.ComponentObject_ID)
                ) && (
                  <BackToLink
                    href={`/program/${programId}/${programComponentId}`}
                    parentPage={programComponent.Name}
                  />
                )}
              </>
            ) : (
              <Box height={8} />
            )
        )}
        {componentObject.Type === COMPONENT_OBJECT_TYPE_BUILDER && (
          <BuilderType componentObject={componentObject} parentComponent={programComponent} />
        )}
        {componentObject.Type === COMPONENT_OBJECT_TYPE_CUSTOM && (
          <CustomComponent
            typeRef={componentObject?.TypeRef || programComponent?.TypeRef}
          />
        )}
        {componentObject.Type === COMPONENT_OBJECT_TYPE_DATA_TABLE && (
          <DataTableType
            componentObject={componentObject}
            programId={programId}
          />
        )}
        {componentObject.Type === COMPONENT_OBJECT_TYPE_DOCUMENT_LIBRARY && (
          <DocumentLibraryType
            componentObject={componentObject}
          />
        )}
        {componentObject.Type === COMPONENT_OBJECT_TYPE_DOCUMENT && (
          <DocumentType
            componentObject={componentObject}
          />
        )}
        {componentObject.Type === COMPONENT_OBJECT_TYPE_TABLE && (
          <TableType componentObject={componentObject} />
        )}
      </CustomContainer>
    );
  }
  return null;
};

export default ComponentObject;
