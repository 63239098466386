import { Chip, makeStyles, withStyles } from "@material-ui/core";
import EditableInputCellWrapper from "./editableInputCellWrapper.component";
import { useCallback, useMemo } from "react";
import { Edit } from "@material-ui/icons";
import Autocomplete from "components/utils/form-elements/autocomplete.component";
import classNames from "classnames";
import { isReadOnly } from "utils/roles";
import styleVariables from "styleVariables";

const useStyles = makeStyles(theme => ({
  autocomplete: {
    width: "calc(100% + 16px)",
    minWidth: 80,
    maxWidth: 280,
    padding: 0,
    margin: "0 -8px",
  },
  autocompleteInput: {
    paddingTop: "2px !important",
    paddingBottom: "2px !important",
    paddingLeft: "2px !important",
  },
  displayLabel: {
    paddingRight: 100,
    cursor: "pointer",
    whiteSpace: "nowrap",
  },
  linkList: {
    margin: "4px -4px",
    padding: 0,
    listStyle: "none"
  },
  linkListItem: {
    "&:nth-of-type(n+2)": {
      marginTop: 4
    }
  },
  autoCompleteChip: {
    margin: 2,
    padding: "2px 4px 2px 2px",
    color: "white",
    backgroundColor: styleVariables.secondaryDark,
    display: "flex",
    alignItems: "center",
    fontSize: styleVariables.fontSmall,
    fontWeight: "bold",

  },
  autoCompleteDeleteIcon: {
    color: "rgba(255, 255, 255, 0.5)",
    height: 14,
    width: 14,
    "&:hover": {
      color: styleVariables.warningMain,
    }
  },
  autoCompleteLabel: {
    paddingLeft: 10,
    paddingRight: 10,
  }
}));

const MultipleDisplayChip = withStyles(theme => ({
  root: {
    padding: 2,
    cursor: "pointer",
    fontSize: styleVariables.fontSmall,
    display: "flex",
    alignItems: "center",
    color: "white",
    backgroundColor: styleVariables.secondaryDark,
    "&:hover": {
      backgroundColor: styleVariables.primaryLight,
    },
  },
  deleteIcon: {
    color: "rgba(255, 255, 255, 0.5)",
    height: 14,
    width: 14,
    "&:hover": {
      color: "white",
    },
  },
  label: {
    paddingLeft: 10,
    paddingRight: 10,
  },
}))(Chip);

export default function EditableAutocompleteCell(props) {
  const classes = useStyles();
  const {
    name, defaultValue, options, onAutosave, required, multiple, validations,
  } = props;

  const readOnly = useMemo(isReadOnly, []);

  const renderDisplayLabel = useCallback(selected => {
    if (!options) {
      return (
        <span className={classes.displayLabel}>N/A</span>
      );
    }
    if (!Array.isArray(selected)) {
      if (!selected) {
        return null;
      }
      return (
        <span className={classes.displayLabel}>{selected.label}</span>
      );
    }
    if (!selected.length) {
      return null;
    }
    return (
      <ul className={classes.linkList}>
        {selected.map(option => (
          <li className={classes.linkListItem} key={option.label}>
            <MultipleDisplayChip
              label={option.label}
              size="small"
              variant={readOnly ? "outline" : "default"}
              className={classNames(
                classes.multipleDisplayChip,
                readOnly && classes.readOnlyChip
              )}
              onDelete={readOnly ? undefined : event => event.preventDefault()}
              deleteIcon={readOnly ? null : <Edit />}
            />
          </li>
        ))}
      </ul>
    );
  }, [classes, options, readOnly]);

  return (
    <EditableInputCellWrapper
      name={name}
      defaultValue={defaultValue}
      renderLinkDisplay={renderDisplayLabel}
      onAutosave={onAutosave}
      required={required}
      validations={validations}
    >
      {autocompleteProps => (
        <Autocomplete
          value={autocompleteProps.value || []}
          options={options}
          multiple={multiple}
          readOnly={readOnly}
          className={classes.autocomplete}
          ChipProps={{className: classes.autoCompleteChip, classes: {deleteIcon: classes.autoCompleteDeleteIcon, label: classes.autoCompleteLabel}}}
          inputClassName={classes.autocompleteInput}
          onAutosave={autocompleteProps.onAutosave}
          onChange={(event, values) => {
            autocompleteProps.onChange(event, values);
          }}
          autoFocus
          hideHelperText
        />
      )}
    </EditableInputCellWrapper>
  );
}
