import { Box, Divider, makeStyles } from "@material-ui/core";
import { Description, MeetingRoom } from "@material-ui/icons";
import classNames from "classnames";
import AddListItem from "components/utils/addListItem.component";
import ButtonDefault from "components/utils/buttonDefault.component";
import Form from "components/utils/form-elements/form.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import RadioInputGroup from "components/utils/form-elements/radioInputGroup.component";
import Header from "components/utils/header.component";
import { useCallback, useState } from "react";
import {
  ACTION_DELETE_SESSION_FOLLOW_UP,
  ACTION_REPLACE_SESSION_FOLLOW_UP,
} from "reducers/portfolioAssessments.reducer";
import PortfolioService from "services/portfolio.service";
import variables from "styleVariables";
import DeleteIcon from "@material-ui/icons/Delete";
import BlockIcon from "@material-ui/icons/Block";
import SaveIcon from "@material-ui/icons/Save";
import { followUpTypes } from "utils/portfolioConstants";

const useStyles = makeStyles((theme) => ({
  followUpList: {
    listStyle: "none",
    padding: 0,
    marginBottom: 0
  },
  followUpListEmpty: {
    marginBottom: 16
  },
  followUpListItem: {
    padding: "15px 20px",
    "&:not(:last-of-type)": {
      borderBottom: `1px solid ${variables.rowBorder}`,
    },
  },
  followUpRowClickable: {
    cursor: "pointer",
  },

  followUpItemWrapper: {
    display: "flex",
    alignItems: "center",
    minHeight: 42,
  },
  listItemChip: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: 90,
    color: variables.tertiary2,
    "&:not(:last-of-type)": {
      marginBottom: 4,
    },
  },
  followUpTypeLabel: {
    fontWeight: 530,
    paddingLeft: 3,
  },
  addButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "24px 10px",
  },
  editFollowUpItemWrapper: {
    position: "relative",
    paddingBottom: 25,
    width: "100%",
  },
  radioGroupClassFollow: {
    "& label": {
      marginBottom: 4,
      marginTop: 2,
    },
    "& .MuiFormControlLabel-label": {
      fontSize: variables.fontH5,
    },
  },
  formButtonsWrapper: {
    display: "flex",
    position: "absolute",
    justifyContent: "space-between",
  },
  submitFormButton: {
    margin: "0px 15px",
  },
  deleteIcon: {
    fontSize: variables.fontH3,
  },
  cancelIcon: {
    fontSize: variables.fontH3,
  },
  saveIcon: {
    fontSize: variables.fontH3,
  },
}));

export default function SessionFollowUpForm(props) {
  const classes = useStyles();
  const { dispatch, followUps, isReadOnly, session } = props;

  const [isNewFollowUp, setIsNewFollowUp] = useState(false);
  const [editingFollowUp, setEditingFollowUp] = useState(null);

  const handleNewClick = useCallback(() => {
    setIsNewFollowUp(true);
    setEditingFollowUp(null);
  }, [setEditingFollowUp, setIsNewFollowUp]);

  const handleEditClick = useCallback(
    (followUp) => {
      setIsNewFollowUp(false);
      setEditingFollowUp(followUp);
    },
    [setEditingFollowUp, setIsNewFollowUp]
  );

  const handleCancelEdit = useCallback(() => {
    setIsNewFollowUp(false);
    setEditingFollowUp(null);
  }, [setEditingFollowUp, setIsNewFollowUp]);

  const handleDelete = useCallback(
    async (followUpId) => {
      const deletedResponse = await PortfolioService.deleteFollowUp(followUpId);
      dispatch({
        type: ACTION_DELETE_SESSION_FOLLOW_UP,
        payload: deletedResponse.payload,
      });
      setEditingFollowUp(null);
    },
    [dispatch, setEditingFollowUp]
  );

  const handleSubmit = useCallback(
    async (formData) => {
      if (!isNewFollowUp && !editingFollowUp) {
        throw new Error(
          "Not in new or editing state when attempting to save follow-up"
        );
      }
      const data = {
        FollowUp_ID: editingFollowUp?.FollowUp_ID || undefined,
        ...formData,
      };
      const followUpResponse = await PortfolioService.upsertFollowUps(
        session.Session_ID,
        data
      );
      dispatch({
        type: ACTION_REPLACE_SESSION_FOLLOW_UP,
        payload: followUpResponse.payload,
      });
      setIsNewFollowUp(false);
      setEditingFollowUp(null);
    },
    [
      dispatch,
      editingFollowUp,
      isNewFollowUp,
      session,
      setEditingFollowUp,
      setIsNewFollowUp,
    ]
  );

  const isEmptyList = !followUps?.length && !isNewFollowUp;

  return (
    <Form onSubmit={handleSubmit}>
      <Box marginTop={6}>
        <Header variant="h3Primary">Follow-Ups</Header>
      </Box>
      <ul
        className={
          classNames(
            classes.followUpList,
            isEmptyList && classes.followUpListEmpty
          )
        }
        data-cy="session-follow-ups"
      >
        {isEmptyList ? (
          <li>No follow-ups specified for this session.</li>
        ) : (
          followUps.map((followUp) => (
            <li
              className={classes.followUpListItem}
              key={followUp.FollowUp_ID}
            >
              {editingFollowUp?.FollowUp_ID === followUp.FollowUp_ID ? (
                <FollowUpEdit
                  followUp={editingFollowUp}
                  onCancel={handleCancelEdit}
                  onDelete={() => handleDelete(followUp.FollowUp_ID)}
                />
              ) : (
                <div
                  className={classNames(!isReadOnly && classes.followUpRowClickable)}
                  onClick={() => {
                    if (isReadOnly) {
                      return;
                    }
                    handleEditClick(followUp)
                  }}
                  role="button"
                >
                  <Box className={classes.followUpItemWrapper}>
                    <Box flex={1} paddingRight={2}>
                      {followUp.Description}
                    </Box>
                    <div>
                      {followUp.Type === followUpTypes.MEETING && (
                        <div className={classes.listItemChip}>
                          <MeetingRoom fontSize="small" />
                          <Box className={classes.followUpTypeLabel}>
                            Meeting
                          </Box>
                        </div>
                      )}
                      {followUp.Type === followUpTypes.DOCUMENT && (
                        <div className={classes.listItemChip}>
                          <Description fontSize="small" />
                          <Box className={classes.followUpTypeLabel}>
                            Document
                          </Box>
                        </div>
                      )}
                    </div>
                  </Box>
                </div>
              )}
            </li>
          ))
        )}
        {!isReadOnly && (
          <li
            className={classNames(
              classes.followUpListItem,
              classes.addButtonWrapper
            )}
          >
            {isNewFollowUp ? (
              <FollowUpEdit onCancel={handleCancelEdit} />
            ) : (
              <AddListItem onClick={handleNewClick} test="follow-up">Add Follow-Up</AddListItem>
            )}
          </li>
        )}
        {!!isNewFollowUp && (
          <Divider />
        )}
      </ul>
    </Form>
  );
}

const typeRadioOptions = [
  { label: "Meeting", value: followUpTypes.MEETING },
  { label: "Document", value: followUpTypes.DOCUMENT },
];

const FollowUpEdit = (props) => {
  const classes = useStyles();

  const { followUp, onCancel, onDelete } = props;

  return (
    <div className={classes.editFollowUpItemWrapper}>
      <Box display="flex" paddingTop={1}>
        <Box flex={1} paddingRight={2}>
          <LabelInput
            id="Description"
            name="Description"
            placeholder="Description"
            defaultValue={followUp?.Description}
            variant="default"
            margin="dense"
            rows="4"
            multiline
            test="description"
          />
        </Box>
        <div>
          <RadioInputGroup
            name="Type"
            defaultValue={followUp?.Type}
            options={typeRadioOptions}
            size="small"
            color="primary"
            radioGroupClass={classes.radioGroupClassFollow}
          />
        </div>
      </Box>
      <Box className={classes.formButtonsWrapper}>
        {!!onDelete && (
          <div className={classes.submitFormButton}>
            <ButtonDefault
              onClick={onDelete}
              variant="iconSmall"
              color="red"
              startIcon={<DeleteIcon className={classes.deleteIcon} />}
            >
              Delete
            </ButtonDefault>
          </div>
        )}
        <div className={classes.submitFormButton}>
          <ButtonDefault
            onClick={onCancel}
            variant="iconSmall"
            color="tertiary"
            startIcon={<BlockIcon className={classes.cancelIcon} />}
          >
            Cancel
          </ButtonDefault>
        </div>
        <div className={classes.submitFormButton}>
          <ButtonDefault
            type="submit"
            variant="iconSmall"
            color="secondary"
            startIcon={<SaveIcon className={classes.saveIcon} />}
            data-cy="save-follow-up"
          >
            Save
          </ButtonDefault>
        </div>
      </Box>
    </div>
  );
};
