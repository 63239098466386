import Tooltip from "components/utils/tooltip.component";
import React, { useContext, useMemo } from "react";
import CustomIcons from "components/utils/icons.component";
import { makeStyles } from "@material-ui/core/styles";
import { alertDueMessage } from "utils/alertHelpers";
import variables from "styleVariables";
import classNames from "classnames";
import AlertContext from "contexts/alert.context";

const useStyles = makeStyles((theme) => ({
  warning: {
    fill: variables.warningMain,
  },
  caution: {
    fill: variables.statusCaution,
  },
  assigned: {
    fill: variables.statusGreen,
  },
  icon: {
    display: "inline-block",
    height: 15,
  },
}));

const AlertIcon = (props) => {
  const classes = useStyles();
  const {
    alertCount, className, End_Date: endDate, noToolTip, placement = "left"
  } = props;
  const { state } = useContext(AlertContext);

  const defaultedAlertCount = useMemo(() => (
    alertCount ?? (state.userAlerts?.length || 0)
  ), [alertCount, state.userAlerts]);

  const { color, message, days } = useMemo(() => (
    alertDueMessage(endDate, null, defaultedAlertCount)
  ), [defaultedAlertCount, endDate]);

  const colorClass = useMemo(() => {
    switch(color) {
      case "assigned":
        return classes.assigned;
      case "warning":
        return classes.warning;
      case "caution":
        return classes.caution;
      default:
        return "";
    }
  }, [classes, color]);

  return (
    <>
      {days !== null && (
        <Tooltip title={noToolTip ? "" : message} placement={placement}>
          <div className={classNames(classes.icon, className)}>
            <CustomIcons variant="NotificationsFilled" className={colorClass} />
          </div>
        </Tooltip>
      )}
      {/* Displays Alert Count on Component Object Cards */}
      {days === null && !!defaultedAlertCount && (
        <Tooltip title={message} placement={placement}>
          <div className={classes.icon}>
            <CustomIcons variant="NotificationsFilled" className={colorClass} />
          </div>
        </Tooltip>
      )}
    </>
  );
}

export default AlertIcon;
