import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EmptyDashboardSection from "components/utils/emptyDashboardSection.component";
import AddListItem from "components/utils/addListItem.component";
import Runbook from "components/runbook/dashboard/runbook.component";
import RunbookService from "services/runbook.service";
import RunbookForm from "components/forms/runbookForm.component";
import CustomModal from "components/utils/modal.component";
import Loader from "components/utils/loader.components";

const useStyles = makeStyles((theme) => ({
  rbContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    gap: 20,
  },
  rbWrapper: {
    display: "flex",
  },
  addWrapper: {
    display: "flex",
    padding: "20px",
    justifyContent: "flex-end",
  },
}));

const ProgRunbooks = ({
  programId,
  runbooks: suppliedRunbooks,
  allRunbookPhases,
  allRunbooks,
  dispatch,
  fromDashboard,
}) => {
  const classes = useStyles();
  const [runbooks, setRunbooks] = useState(suppliedRunbooks);
  const [isRunbookFormOpen, setIsRunbookFormOpen] = useState(false);

  useEffect(() => {
    if (suppliedRunbooks) {
      setRunbooks(suppliedRunbooks);
      return;
    }
    let isSubscribed = true;
    RunbookService.getAllRunbooks()
      .then((res) => isSubscribed && setRunbooks(res.payload));
    return () => (isSubscribed = false);
  }, [programId, suppliedRunbooks]);

  if (!runbooks) {
    return (
      <Loader />
    );
  }
  return (
    <>
      {runbooks && runbooks.length === 0 ? (
        <EmptyDashboardSection variant="custom">
          <AddListItem
            text="Add Runbook"
            onClick={() => setIsRunbookFormOpen(true)}
            test="RB"
            dark
          />
        </EmptyDashboardSection>
      ) : (
        <div className={classes.rbWrapper} data-cy="prog-runbooks-wrapper">
          <Grid container className={classes.rbContainer}>

            {!!runbooks && runbooks.map((runbook) => (
              <RunBookGridItem key={runbook.Runbook_ID} fromDashboard={fromDashboard}>
                <Runbook
                  runbook={runbook}
                />
              </RunBookGridItem>

            ))}
          </Grid>
        </div>
      )}
      <div className={classes.addWrapper}>
        {!!fromDashboard && runbooks?.length > 0 && (
          <AddListItem
            text="Add Runbook"
            onClick={() => setIsRunbookFormOpen(true)}
            test="RB"
            dark
          />
        )}
        <CustomModal
          open={isRunbookFormOpen}
          onClose={() => setIsRunbookFormOpen(false)}
        >
          <RunbookForm
            setIsRunbookFormOpen={setIsRunbookFormOpen}
            runbooks={allRunbooks}
            allRunbookPhases={allRunbookPhases}
            dispatch={dispatch}
          />
        </CustomModal>
      </div>
    </>
  );
};

const RunBookGridItem = ({ children, className, fromDashboard }) => {
  if (fromDashboard) {
    return(
      <Grid item className={className}>
        {children}
      </Grid>
    );
  } else {
    return (
      <Grid item sm={12} md={6} lg={4} xl={3} className={className}>
        {children}
      </Grid>
    );
  }
};

export default ProgRunbooks;
