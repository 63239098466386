import { debounce, Fab, makeStyles } from "@material-ui/core";
import { ArrowUpward } from "@material-ui/icons";
import classNames from "classnames";
import { useCallback, useLayoutEffect, useState } from "react";


const useStyles = makeStyles(theme => ({
  scrollTop: {
    position: "fixed",
    right: 20,
    bottom: 20,
    opacity: 1,
  },
  scrollTopMounted: {
    transition: "0.2s opacity ease-out"
  },
  scrollTopVisible: {
    opacity: 1
  },
  scrollTopHidden: {
    opacity: 0,
    pointerEvents: "none",
    userEvents: "disabled"
  }
}));

const VISIBILITY_DEBOUNCE_INTERVAL = 200;

/*
 * Floating button to scroll to the top of the page
 */
export default function ScrollTopButton({ scrollThreshold }) {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(null);

  const determineVisibility = useCallback(() => {
    if (!isVisible && window.scrollY > scrollThreshold) {
      setIsVisible(true);
    } else if (isVisible && window.scrollY <= scrollThreshold) {
      setIsVisible(false);
    }
  }, [isVisible, scrollThreshold]);

  useLayoutEffect(function setScrollBasedVisibility() {
    let onDebounce = determineVisibility;
    const scrollListener = (
      debounce(onDebounce, VISIBILITY_DEBOUNCE_INTERVAL)
    );

    window.addEventListener("scroll", scrollListener);

    return () => {
      onDebounce = () => {};
      window.removeEventListener("scroll", scrollListener)
    };
  }, [determineVisibility, isVisible, scrollThreshold]);

  const handleClick = useCallback(() => {
    if (isVisible) {
      window.scrollTo(0, 0);
    }
  }, [isVisible]);

  return (
    <div
      className={
        classNames(
          classes.scrollTop,
          {
            [classes.scrollTopMounted]: isVisible !== null,
            [classes.scrollTopVisible]: isVisible,
            [classes.scrollTopHidden]: !isVisible
          }
        )
      }
    >
      <Fab color="secondary" onClick={handleClick}>
        <ArrowUpward />
      </Fab>
    </div>
  )
}
