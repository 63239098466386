import { IconButton, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import FormatListNumberedOutlinedIcon from "@material-ui/icons/FormatListNumberedOutlined";
import Tooltip from "components/utils/tooltip.component";
import { useCallback, useMemo } from "react";
import {
  ACTION_REPLACE_MANUAL_CHAPTER,
  ACTION_REPLACE_MANUAL_SECTION,
  ACTION_REPLACE_MANUAL_SUBSECTION,
  ACTION_REPLACE_MANUAL_NESTED_SUBSECTION
} from "reducers/certification/manuals.reducer";
import certificationService from "services/certification.service";
import styleVariables from "styleVariables";
import { manualSectionTypes } from "utils/certificationConstants";
import { isReadOnly } from "utils/roles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: 8,
    minWidth: 48,
    display: "flex",
  },
  numberDisplay: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
  },
  noNumberDisplay: {
    marginTop: 32,
  },
  numbers: {
    paddingTop: 6,
    fontSize: styleVariables.fontMedium,
    color: styleVariables.textSecondary,
    fontWeight: "bold",
  },
  button: {
    padding: 6,
    boxShadow: theme.shadows[1],
  },
  icon: {
    fontSize: 24,
  },
  inactiveIcon: {
    fill: styleVariables.tertiary1,
  },
  activeIcon: {
    fill: styleVariables.secondaryMain,
  },
  disabledIcon: {
    fill: styleVariables.grayDisabled,
  },
}));

const reducerBySectionType = {
  [manualSectionTypes.MAIN_SECTION]: ACTION_REPLACE_MANUAL_SECTION,
  [manualSectionTypes.SUBSECTION]: ACTION_REPLACE_MANUAL_SUBSECTION,
  [manualSectionTypes.NESTED_SUBSECTION]: ACTION_REPLACE_MANUAL_NESTED_SUBSECTION,
};

const AutoNumbering = ({
  isChapter,
  chapterNumber,
  sectionNumber,
  subSectionNumber,
  nestedSubSectionNumber,
  sectionType,
  dispatch,
  section,
}) => {
  const classes = useStyles();
  const readOnly = useMemo(isReadOnly, []);

  const disableAddNumbering = useMemo(() => {
    if (readOnly) {
      return true;
    }
    if (isChapter) {
      return false;
    }
    if (sectionType === manualSectionTypes.MAIN_SECTION) {
      return !chapterNumber;
    }
    if (sectionType === manualSectionTypes.SUBSECTION) {
      return !chapterNumber || !sectionNumber;
    }
    if (sectionType === manualSectionTypes.NESTED_SUBSECTION) {
      return !chapterNumber || !sectionNumber || !subSectionNumber;
    }
    return false;
  }, [chapterNumber, isChapter, sectionType, sectionNumber, subSectionNumber, readOnly]);


  const numberDisplay = useMemo(() => {
    if (isChapter && chapterNumber) {
      return `${chapterNumber}.`;
    }
    if (
      sectionType === manualSectionTypes.MAIN_SECTION &&
      chapterNumber &&
      sectionNumber
    ) {
      return `${chapterNumber}.${sectionNumber}`;
    }
    if (
      sectionType === manualSectionTypes.SUBSECTION &&
      chapterNumber &&
      sectionNumber &&
      subSectionNumber
    ) {
      return `${chapterNumber}.${sectionNumber}.${subSectionNumber}`;
    }
    if (
      sectionType === manualSectionTypes.NESTED_SUBSECTION &&
      chapterNumber &&
      sectionNumber &&
      subSectionNumber &&
      nestedSubSectionNumber
    ) {
      return `${chapterNumber}.${sectionNumber}.${subSectionNumber}.${nestedSubSectionNumber}`;
    }
    return null;
  }, [isChapter, sectionType, chapterNumber, sectionNumber, subSectionNumber, nestedSubSectionNumber]);

  const updateAutoNumbering = useCallback(async () => {
    const sectionResponse = await certificationService.updateSection(
      section.ManualSection_ID,
      { Auto_Number: section.Auto_Number !== 1 }
    );
    if (isChapter) {
      dispatch({
        type: ACTION_REPLACE_MANUAL_CHAPTER,
        payload: sectionResponse.payload,
      });
    } else {
      dispatch({
        type: reducerBySectionType[sectionType],
        payload: sectionResponse.payload,
      });
    }
  }, [dispatch, section, isChapter, sectionType]);

  return (
    <div className={classes.root}>
      {numberDisplay ? (
        <div className={classes.numberDisplay}>
          <Tooltip
            title="Remove Section Number"
            placement="top"
            enterDelay={400}
            enterNextDelay={400}
          >
            <div className={classes.iconWrapper}>
              <IconButton
                className={classes.button}
                onClick={updateAutoNumbering}
              >
                <FormatListNumberedOutlinedIcon
                  className={classNames(classes.icon, classes.activeIcon)}
                />
              </IconButton>
            </div>
          </Tooltip>
          <div className={classes.numbers} data-cy="auto-order-number">{numberDisplay}</div>
        </div>
      ) : (
        <div className={classes.noNumberDisplay}>
          <Tooltip
            title={
              disableAddNumbering
                ? "Numbering is disabled because the parent section is not numbered"
                : "Add Section Number"
            }
            placement="top"
            enterDelay={400}
            enterNextDelay={400}
          >
            <div className={classes.iconWrapper}>
              <IconButton
                className={classes.button}
                onClick={updateAutoNumbering}
                disabled={disableAddNumbering}
              >
                <FormatListNumberedOutlinedIcon
                  className={classNames(
                    classes.icon,
                    disableAddNumbering
                      ? classes.disabledIcon
                      : classes.inactiveIcon
                  )}
                  disabled={disableAddNumbering}
                />
              </IconButton>
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default AutoNumbering;
