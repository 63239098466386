import http from "./http-common";
import authHeader from "./authHeader";

class PortfolioServiceBase {
  getUserPermittedPortCos() {
    return http
      .get(`/portfolio/userPermittedPortCos/`, { headers: authHeader() })
      .then((response) => {
        return response.data
      })
  }

  getPortfolioDashboard(portfolioHolderId) {
    return http
      .get(`/portfolio/portfolioDashboard/${portfolioHolderId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getPermittedPortfolioHolderList() {
    return http
      .get(`/portfolio/portfolioHolder/`, { headers: authHeader() })
      .then(response => response.data);
  }

  getHoldingCompanyDemographicsByHoldingID(portfolioHolderId) {
    return http
      .get(`/portfolio/portfolioHolder/${portfolioHolderId}/demographics`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getAllPortCosByHoldingID(portfolioHolderId) {
    return http
      .get(`/portfolio/portfolioHolder/${portfolioHolderId}/portCos`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getPortCoByID(portCoId) {
    return http
      .get(`/portfolio/portCo/${portCoId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getDemoDataByPortCoID(portCoId) {
    return http
      .get(`/portfolio/portCo/${portCoId}/demographics`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getUsersByPortCoID(portCoId) {
    return http
      .get(`/portfolio/portCo/${portCoId}/users`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getPortfolioGroupPermissionsList() {
    return http
      .get(`/portfolio/portfolioGroupPermissions`, { headers: authHeader() })
      .then(response => response.data);
  }

  upsertPortCoUser(portCoId, userData, groupPermissionData) {
    return http
      .post(`/portfolio/portCo/${portCoId}/user`, { userData, groupPermissionData }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  upsertPortfolioGroupPermissions(portCoId, userId, groupPermissionData) {
    return http.post(
      `/portfolio/portCo/${portCoId}/user/${userId}/portfolioGroupPermissions`,
      groupPermissionData,
      { headers: authHeader() }
    ).then(response => response.data);
  }

  upsertAttendeeUser(portCoId, userData, groupPermissionData) {
    return http
      .post(`/portfolio/attendee/portCo/${portCoId}/user`, { userData, groupPermissionData }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  upsertPortCoDemoData(portCoId, portCoDemoData) {
    return http
      .post(`/portfolio/portCo/${portCoId}/demographic`, { portCoDemoData }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  upsertPortCo(portfolioHolderId, portCoData) {
    return http
      .post(`/portfolio/portfolioHolder/${portfolioHolderId}/portCo`, { portCoData }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getPortDashParams() {
    return http
      .get(`portfolio/portDashParams`, { headers: authHeader() })
      .then((response) => {
        return response.data.payload;
      });
  }

  getPortfolioUsers(holderId) {
    return http
      .get(`/portfolio/portfolioHolder/${holderId}/users`, { headers: authHeader() })
      .then(response => response.data);
  }

  getHolderAssessments() {
    return http
      .get(`/portfolio/holderAssessments`, { headers: authHeader() })
      .then(response => response.data);
  }

  getHolderAssessmentsIndex() {
    return http
      .get(`/portfolio/holderAssessments/index`, { headers: authHeader() })
      .then(response => response.data);
  }

  createHolderAssessment(portfolioHolderId, assessmentData, statusData) {
    return http
      .post(
        `/portfolio/portfolioHolder/${portfolioHolderId}/assessment`,
        { assessmentData, statusData },
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  getAssessmentItems(sessionId) {
    return http
      .get(`/portfolio/session/${sessionId}/portAssessmentItems`, {
        headers: authHeader(),
      })
      .then(response => response.data);
  }

  getReconcileAssessmentItems(portCoId, assessmentId) {
    return http
      .get(`/portfolio/assessment/${assessmentId}/portCo/${portCoId}/reconciledItems`, {
        headers: authHeader(),
      })
      .then(response => response.data);
  }

  // upsertAssessmentItem(holderAssessmentId, assessmentItemData) {
  //   return http
  //     .post(
  //       `/portfolio/holderAssessment/${holderAssessmentId}/upsertAssessmentItem`,
  //       {assessmentItemData: assessmentItemData},
  //       { headers: authHeader() }
  //     ).then(response => response.data);
  // }

  upsertReconciledItem(holderAssessmentId, portCoId, assessmentItemData) {
    return http
      .post(
        `/portfolio/holderAssessment/${holderAssessmentId}/portCo/${portCoId}/upsertReconciledItem`,
        {reconciledItemData: assessmentItemData},
        { headers: authHeader() }
      ).then(response => response.data);
  }

  getHolderSessions(holderId) {
    return http
      .get(
        `/portfolio/portfolioHolder/${holderId}/sessions`,
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  getCompanyAssessmentSessions(assessmentId, portfolioCompanyId) {
    return http
      .get(
        `/portfolio/holderAssessment/${assessmentId}/portCo/${portfolioCompanyId}/sessions`,
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  upsertAttendee(sessionId, data) {
    return http
      .post(
        `/portfolio/session/${sessionId}/upsertAttendee/`,
        { attendeeData: data },
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  deleteAttendee(attendeeId) {
    return http
      .delete(`/portfolio/attendee/${attendeeId}`, { headers: authHeader() })
      .then(response => response.data);
  }

  upsertFollowUps(sessionId, data) {
    return http
      .post(
        `/portfolio/session/${sessionId}/upsertFollowUp/`,
        { followUpData: data },
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  deleteFollowUp(followUpId) {
    return http
      .delete(
        `/portfolio/followUps/${followUpId}`,
        { headers: authHeader() }
      ).then(response => response.data);
  }

  deleteSupportingDoc(documentId) {
    return http
      .delete(
        `/portfolio/supportingDoc/${documentId}`,
        { headers: authHeader() }
      ).then(response => response.data);
  }

  upsertNote(sessionId, assessmentItemId, data) {
    return http
      .post(
        `/portfolio/session/${sessionId}/item/${assessmentItemId}/upsertNote`,
        data,
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  transferSessionNotes(destinationSessionId, sourceNoteIds) {
    return http
      .post(
        `/portfolio/session/${destinationSessionId}/notes/transfer`,
        { sourceNoteIds },
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  upsertAssessmentSession(holderAssessmentId, data, meetingFollowUpId) {
    return http
      .post(
        `/portfolio/holderAssessment/${holderAssessmentId}/upsertSession`,
        { sessionData: data, meetingFollowUpId },
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  cancelOrDeleteSession(sessionId, isDelete) {
    return http
      .put(
        `/portfolio/session/${sessionId}/cancelOrDelete`,
        { isDelete },
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  createSessionsFromRange(holderAssessmentId, data) {
    return http
      .post(
        `/portfolio/holderAssessment/${holderAssessmentId}/sessionsFromRange`,
        { sessionData: data },
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  batchReplaceSessionAttendees(portCoId, sessionId, data) {
    return http
      .post(
        `/portfolio/portCo/${portCoId}/session/${sessionId}/batchReplaceAttendees`,
        { sessionAttendees: data },
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  batchUpdateReportSubSections(reportSectionId, data) {
    return http
      .post(
        `/portfolio/report/section/${reportSectionId}/batchUpdateSubSections`,
        { reportSubSections: data },
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  getReportsByHoldingId(holdingId) {
    return http
      .get(
        `/portfolio/holdingCompany/${holdingId}/reports`,
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  createReport(companyId, templateId, data = {}) {
    return http
      .post(
        `/portfolio/report/${companyId}/${templateId}`,
        { reportData: { ...data, ReportTemplate_ID: templateId } },
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  updateReport(reportId, data) {
    return http
      .post(
        `/portfolio/report/${reportId}/update`,
        { reportData: data },
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  // updateReportSection(sectionId, data) {
  //   return http
  //     .post(
  //       `/portfolio/report/section/${sectionId}`,
  //       { reportSectionData: data },
  //       { headers: authHeader() }
  //     )
  //     .then(response => response.data);
  // }

  createReportSubSection(sectionId, data = {}) {
    return http
      .post(
        `/portfolio/report/section/${sectionId}/subSection`,
        { reportSubSectionData: data },
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  updateReportSubsection(subsectionId, data) {
    return http
      .post(
        `/portfolio/report/subSection/${subsectionId}`,
        { reportSubSectionData: data },
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  deleteReportSubsection(sectionId, subsectionId) {
    return http
      .delete(
        `/portfolio/report/section/${sectionId}/subSection/${subsectionId}`,
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  updateReportScores(scoreId, data) {
    return http
      .post(
        `/portfolio/report/score/${scoreId}/update`,
        { categoryScoreData: data },
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  getReportSupportingDocs(reportId) {
    return http
      .get(
        `/portfolio/report/${reportId}/documents`,
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  upsertSupportingDoc(sessionId, data) {
    return http
      .post(
        `/portfolio/session/${sessionId}/upsertSupportingDoc`,
        data,
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  getAssessmentDocuments(assessmentStatusId) {
    return http
      .get(
        `/portfolio/assessmentStatus/${assessmentStatusId}/documents`,
        { headers: authHeader() }
      )
      .then(response => response.data);
  }
}

const PortfolioService = new PortfolioServiceBase();

export default PortfolioService;
