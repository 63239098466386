import { Box, makeStyles } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import classNames from "classnames";
import DraggableTab from "components/utils/draggableTab.component";
import DraggableTabs from "components/utils/draggableTabs.component";
import { useCallback, useMemo, useState } from "react";
import { ACTION_CREATE_MANUAL_CHAPTER, ACTION_REORDER_MANUAL_CHAPTERS } from "reducers/certification/manuals.reducer";
import certificationService from "services/certification.service";
import { isReadOnly } from "utils/roles";
import { ellipsize } from "utils/stringFuncs";
import styleVariables from "styleVariables";

const MAX_CHAPTER_LENGTH = 60;

const useStyles = makeStyles(() => {
  return {
    tabs: {
      padding: 8,
      minWidth: "100%",
      "& button[data-rbd-placeholder-context-id]": {
        display: "none !important"
      }
    },
    tabsScrollButton: {
      position: "relative",
      opacity: 1,
      backgroundRepeat: "no-repeat",
      transition: "border-color 0.1s ease",
      "&:first-child": {
        borderRight: `1px solid ${styleVariables.rowBorderDark}`,
        marginRight: 8,
      },
      "&:last-child": {
        borderLeft: `1px solid ${styleVariables.rowBorderDark}`,
        marginLeft: 8,
      },
      "&.Mui-disabled": {
        borderColor: "transparent",
        display: "flex",
        pointerEvents: "default",
        opacity: 1,
        "&> svg": {
          opacity: 0.5,
        }
      },
    },
    tabsScroller: {
      margin: "0 -8px",
      padding: "0 8px",
    },
    tabsDragging: {
      marginRight: 70
    },
    tab: {
      maxWidth: "10%",
      marginTop: 8,
      marginRight: -8,
      paddingRight: 16,
      position: "relative",
      zIndex: 1,
      border: `1px solid ${styleVariables.grayTint}`,
      borderCollapse: "collapse",
      borderTopLeftRadius: 8,
      background: "white",
      wordBreak: "break-word",
      opacity: 1,
      filter: styleVariables.filterBookTabInactive,
      transition: "filter 0.2s ease",
      "&[data-rbd-drag-handle-context-id]": {
        cursor: "pointer"
      },
      "&.Mui-selected": {
        borderBottomColor: "white",
        filter: styleVariables.filterBookTab,
        paddingTop: 7,
        marginTop: 7,
        zIndex: 2,
        cursor: "grab"
      }
    },
    tabDragDisabled: {
      cursor: "default",
    },
    tabAdd: {
      minWidth: 64,
      paddingLeft: 24,
      cursor: "pointer",
      zIndex: 0,
      "&.Mui-selected": {
        cursor: "pointer"
      }
    },
    tabAddInvisible: {
      minWidth: 0,
      width: 0,
      padding: 0,
      opacity: 0
    },
    tabAddNoChapters: {
      paddingLeft: 16,
      borderTopRightRadius: 8
    },
    tabIndicator: {
      display: "none"
    },
    tabSubtitle: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      fontSize: styleVariables.fontSmall,
      fontWeight: 600,
      lineHeight: 1.25,
      textTransform: "none"
    }
  };
});

const NEW_CHAPTER_VALUE = "new";

export default function ChapterTabs(props) {
  const classes = useStyles();
  const { activeChapterId, dispatch, manual, setActiveChapterId } = props;
  const readOnly = useMemo(isReadOnly, []);
  const [isDragging, setIsDragging] = useState(false);
  const [pendingReorderData, setPendingReorderData] = useState(null);

  const chapters = useMemo(() => {
    if (pendingReorderData) {
      const pendingChapters = [...manual._associations.ManualSection];
      const indexToRemove = pendingChapters.findIndex(chapter => (
        chapter.ManualSection_ID === pendingReorderData.ManualSection_ID
      ));
      if (indexToRemove > -1) {
        const removedChapter = pendingChapters.splice(indexToRemove, 1)[0];
        const reinsertIndex = pendingReorderData.ManualSection_Order - 1;
        pendingChapters.splice(reinsertIndex, 0, removedChapter);
      }
      return pendingChapters;
    }
    return manual._associations.ManualSection;
  }, [manual._associations.ManualSection, pendingReorderData]);

  const highestChapterOrder = useMemo(() => (
    chapters.reduce((max, chapter) => (
      Math.max(max, chapter.ManualSection_Order)
    ), chapters.length)
  ), [chapters]);

  const handleChange = useCallback((_event, chapterId) => {
    if (chapterId !== NEW_CHAPTER_VALUE) {
      setActiveChapterId(chapterId);
    }
  }, [setActiveChapterId]);

  const handleReorderDragStart = useCallback(() => {
    setIsDragging(true);
  }, [setIsDragging]);

  const handleReorderDragEnd = useCallback(async (dragInfo) => {
    if (!dragInfo.destination) {
      return;
    }
    const id = parseInt(dragInfo.draggableId.split("-")[1], 10);
    const isNewTabDestination = dragInfo.destination.index >= chapters.length;
    const order = dragInfo.destination.index + (isNewTabDestination ? 0 : 1);

    const data = { ManualSection_ID: id, ManualSection_Order: order };
    setPendingReorderData(data);
    const sectionResponse = await certificationService.reorderManualSection(
      data,
      manual.Manual_ID
    );
    dispatch({
      type: ACTION_REORDER_MANUAL_CHAPTERS,
      payload: sectionResponse.payload,
      meta: sectionResponse.meta
    });
    setIsDragging(false);
    setPendingReorderData(null);
  }, [chapters.length, dispatch, manual, setIsDragging, setPendingReorderData]);

  const handleAddChapter = useCallback(async () => {
    const data = {
      Content: "",
      Header: "New Chapter",
      ManualSection_Order: highestChapterOrder + 1,
      Auto_Number: 1,
    };
    const sectionResponse = await certificationService.createSection(
      data,
      manual.Manual_ID
    );
    dispatch({
      type: ACTION_CREATE_MANUAL_CHAPTER,
      payload: sectionResponse.payload,
      meta: sectionResponse.meta
    });
    setTimeout(function afterNextRender() {
      setActiveChapterId(sectionResponse.payload.ManualSection_ID);
    }, 0);
  }, [dispatch, highestChapterOrder, manual.Manual_ID, setActiveChapterId]);

  if (!manual) {
    return null;
  }
  return (
    <Box display="flex" alignItems="stretch">
      <div className={classes.tabBufferLeft} />
      <DraggableTabs
        className={classNames(classes.tabs, isDragging && classes.tabsDragging)}
        droppableId="chapter-tabs"
        value={
          activeChapterId ||
          chapters[0]?.ManualSection_ID ||
          NEW_CHAPTER_VALUE
        }
        onChange={handleChange}
        onDragStart={handleReorderDragStart}
        onDragEnd={handleReorderDragEnd}
        variant="scrollable"
        scrollButtons="auto"
        classes={{
          scrollButtons: classes.tabsScrollButton,
          scroller: classes.tabsScroller
        }}
        TabIndicatorProps={{
          className: classes.tabIndicator
        }}
      >
        {chapters.map((chapter, index, all) => {
          const chapterHeaderText = ellipsize(chapter.Header, MAX_CHAPTER_LENGTH)
          return (
            <DraggableTab
              className={classNames(
                classes.tab,
                activeChapterId === chapter.ManualChapter_ID &&
                  classes.tabActive,
                all.length < 2 && classes.tabDragDisabled
              )}
              draggableId={chapter.ManualSection_ID}
              draggableIndex={index}
              label={
                <div className={classes.tabSubtitle}>
                  {chapterHeaderText}
                </div>
              }
              value={chapter.ManualSection_ID}
              isDragDisabled={all.length < 2}
              key={chapter.ManualSection_ID}
              data-cy={`drag-chapter-${chapterHeaderText}`}
            />
          );
        })}
        {!readOnly && (
          <DraggableTab
            className={classNames(
              classes.tab,
              classes.tabAdd,
              isDragging && classes.tabAddInvisible,
              !chapters.length && classes.tabAddNoChapters
            )}
            draggableId={NEW_CHAPTER_VALUE}
            draggableIndex={chapters.length}
            label={(
              <div className={classes.tabSubtitle}>
                <Add />
              </div>
            )}
            value={NEW_CHAPTER_VALUE}
            onClick={handleAddChapter}
            isDragDisabled
            data-cy="drag-btn-add-chapter"
          />
        )}
      </DraggableTabs>
      <div className={classes.tabBufferRight} />
    </Box>
  );
}
