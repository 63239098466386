import { TextField, FormControl } from "@material-ui/core";
import variables from "styleVariables";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormLabel from "components/utils/form-elements/formLabel.component"

const useStyles = makeStyles((theme) => ({
  input: {
    borderRadius: 20,
  },
  formControlRoot: {
    width: "100%",
  },
  formLabel: {
    marginLeft: 8,
  },
}));

const CustomTextField = withStyles({
  root: {
    marginTop: 2,
    marginBottom: 15,
    "& label.Mui-focused": {
      color: variables.secondaryMain,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: variables.primaryMain,
      },
      "& .MuiOutlinedInput-inputMarginDense": {
        padding: "17px 14px",
        borderRadius: 20,
        backgroundColor: "rgba(255, 255, 255, 0.5)",
      },
      "&:hover fieldset": {
        borderColor: variables.secondaryMain,
      },
      "&.Mui-focused fieldset": {
        borderColor: variables.secondaryMain,
      },
    },
    "& input:invalid + fieldset": {
      borderColor: variables.warningMain,
      borderWidth: 2,
    },
  },
})(TextField);

const AuthTextField = (props) => {
  const classes = useStyles();
  return (
    <FormControl variant="outlined" className={classes.formControlRoot}>
      <FormLabel
        className={classes.formLabel}
        htmlFor={props.id}
        label={props.required ? `${props.label}*` : props.label}
        variant="default"
        error={props.error}
      />
      <CustomTextField
        disabled={props.disabled}
        error={props.error}
        helperText={props.helperText}
        variant={props.variant}
        margin={props.margin}
        required={props.required}
        fullWidth={props.fullWidth}
        id={props.id}
        name={props.name}
        autoFocus={props.autoFocus}
        autoComplete={props.autoComplete}
        size={props.size}
        onChange={props.onChange}
        type={props.type}
        value={props.value}
        InputProps={{
          className: classes.input,
        }}
        data-cy={`input-${props.test}`}
      />
    </FormControl>
  );
};

export default AuthTextField;
