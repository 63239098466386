

import React, { memo, useCallback, useMemo, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import TableCell from "components/utils/tables/shared/tableCell.component";
import TableSortLabel, { SORT_ASCENDING, SORT_DESCENDING } from "components/utils/tableSortLabel.component";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import variables from "styleVariables";
import { getComparator, stableSort } from "utils/tableSorts";
import useNumericParams from "hooks/useNumericParams";
import DisplayHTML from "../displayHTML.component";
import { isHtml } from "utils/stringFuncs";

const useStyles = makeStyles((theme) => ({
  addEmptyWrapper: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 3,
  },
  tableMarginTop: {
    borderCollapse: "collapse",
  },
  tableSmallBodyCell: {
    padding: "7px 10px",
    color: variables.textSecondary,
    fontSize: variables.fontSmall,
    [theme.breakpoints.down("md")]: {
      fontSize: variables.fontXs,
    },
  },
  cellHeader: {
    backgroundColor: variables.tertiary1,
    color: "white",
    fontWeight: "bold",
    fontSize: variables.fontLarge,
    padding: "10px 20px",
    lineHeight: "normal",
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  // HEAD CELLS
  cellHeaderDense: {
    backgroundColor: variables.tertiary1,
    color: "white",
    fontWeight: 600,
    fontSize: variables.fontSmall,
    padding: 8,
  },
  cellHeaderTertiary: {
    backgroundColor: variables.tertiary3
  },
  cellBody: {
    padding: "10px 20px",
    color: variables.textSecondary,
    fontFamily: "Helvetica",
    fontSize: variables.fontSmall,
    wordBreak: "break-word",
    borderLeft: "1px solid rgba(22, 76, 146, 0.22)",
  },
  cellBodyBold: {
    fontWeight: 600,
  },
  cellDenseBody: {
    padding: "4px 8px",
    maxWidth: "300px",
  },
  cellBgPrimary: {
    backgroundColor: variables.primaryMain,
    borderColor: "white",
    color: "white",
    fontWeight: 600,
  },
  cellBgSecondary: {
    backgroundColor: variables.rowHighlight,
    borderColor: "white",
    color: "white",
    fontWeight: 600,
  },
  footerCell: {
    backgroundColor: variables.tertiary1,
    borderBottom: 0,
    color: "white",
    fontWeight: 600,
    lineHeight: "normal"
  },
  paginationToolbarDense: {
    minHeight: 0
  },
  paginationButtonDense: {
    fill: "white",
    paddingTop: 4,
    paddingBottom: 4
  },
  paginationText: {
    fontSize: variables.fontSmall,
    fontWeight: 600
  },
  stickyHeader: {
    borderCollapse: "collapse"
  },
  centerAlign: {
    textAlign: "center",
  },
  leftAlign: {
    textAlign: "left",
  },
  rightAlign: {
    textAlign: "right",
  },
}));

const PAGINATION_DEFAULT_ROWS_PER_PAGE = 10;
/*
  Custom Sortable Table
  Intended as speed-optimized table to use instead of DataTable when performance is most important
*/

/*
  Props
  - header (array(object))
      This is an array of objects for each header cell in the table.
      {
        name: [ key of entire column. Cell values in `data` attribute are keyed by this.](required)
        display?: [ value to display in cell, can be jsx ] (default = `name`),
        sort?: [ boolean to control if column is sortable] (default = false),
        alignment?: [ value passed to TableSortLabel "left"] (default = "left")
        bold?: [boolean] (default = false),
        className?
      }
  - data (array(array(object)))
      Array of table rows, containing objects of cells keyed by corresponding `headers.name` attribute.
      Value can be either a node or an object with the format:
      {
        value: string|number|node,
        node?: node,
        sortValue?: string|number
      }
  - footer (node?) Node to show in footer row if needed
  - pagination (boolean?|object?) boolean to enable pagination,
      or an object of props for the same Mui TablePagination component

  - color: ("tertiary"|"gray"|"default") Color of header and if applicable footer
  - defaultSortBy: (string?) `name` of header column to sort by on load
  - defaultSortDescending: (boolean?)
  - emptyCellValue: (node?) Cell content when no value is present
  - emptyTableValue: (node?) Table content when no rows are present
*/

const findDisplayNode = (cellData) => {
  const displayData =
    cellData && typeof cellData === "object"
      ? cellData.node || cellData.value
      : cellData;
  return isHtml(displayData) ? <DisplayHTML html={displayData} unstyled /> : displayData;
};

const SortableTable = memo(
  function SortableTable({
    headers,
    data,
    footer,
    pagination,
    defaultSortBy,
    defaultSortDescending = false,
    color,
    className,

    // Table options
    denseHeader,
    denseBody,
    emptyCellValue,
    emptyTableValue,
  }) {
    const classes = useStyles();
    const params = useNumericParams();
    const [sortBy, setSortBy] = useState(
      defaultSortBy || (data.length > 0 ? data[0]?.name : null)
    );
    const [sortDirection, setSortDirection] = useState(
      defaultSortDescending ? SORT_DESCENDING : SORT_ASCENDING
    );
    const [page, setPage] = useState(params?.page || 0);
    const [rowsPerPage, setRowsPerPage] = useState(
      pagination?.rowsPerPage ||
      pagination?.rowsPerPageOptions?.[0] ||
      PAGINATION_DEFAULT_ROWS_PER_PAGE
    );

    const alignmentColumnClasses = useMemo(() => (
      headers.map(header => {
        switch (header.alignment) {
          case "right":
            return classes.rightAlign;
          case "center":
            return classes.centerAlign;
          case "left":
          default:
            return classes.leftAlign;
        }
      })
    ), [classes, headers]);

    const colorClass = useMemo(() => {
      switch(color) {
        case "tertiary":
          return classes.cellHeaderTertiary;
        case "gray":
        default:
          return null;
      }
    }, [classes, color]);

    const sortedRows = useMemo(() => {
      const comparator = getComparator(sortDirection, sortBy);
      return stableSort(data, comparator);
    }, [data, sortDirection, sortBy]);

    const displayRows = useMemo(() => {
      if (pagination) {
        const startIndex = page * rowsPerPage;
        const endIndex = (page + 1) * rowsPerPage;
        return sortedRows.slice(startIndex, endIndex);
      }
      return sortedRows;
    }, [page, pagination, rowsPerPage, sortedRows]);

    const handleRequestSort = useCallback((_event, newSortBy) => {
      const isAsc = sortBy === newSortBy && sortDirection === SORT_ASCENDING;
      setSortDirection(isAsc ? SORT_DESCENDING : SORT_ASCENDING);
      setSortBy(newSortBy);
    }, [sortDirection, sortBy]);

    const handlePaginationRowsPerPageChange = useCallback(event => {
      const { value } = event.target;
      setRowsPerPage(value);
      const lastValidPage = Math.ceil(data.length / value) - 1;
      if (isNaN(lastValidPage)) {
        if (value > rowsPerPage) {
          setPage(0);
        }
        console.error("Error calculating last valid table page.");
      }
      if (page > lastValidPage) {
        setPage(lastValidPage);
      }
    }, [data.length, page, rowsPerPage]);

    return (
      <TableContainer
        component={Paper}
        className={classNames(classes.tableContainer, classes.tableMarginTop, className )}
      >
        <Table
          classes={{ stickyHeader: classes.stickyHeader }}
          stickyHeader
        >
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  className={classNames(
                    classes.cellHeader,
                    alignmentColumnClasses[index],
                    colorClass,
                    denseHeader && classes.cellHeaderDense,
                    header.className
                  )}
                  key={header.name || index}
                >
                  <TableSortLabel
                    active={sortBy === header.name}
                    align={header.alignment || "left"}
                    direction={
                      sortBy === header.name ? sortDirection : SORT_ASCENDING
                    }
                    onClick={handleRequestSort}
                    sortBy={header.name}
                    sortDisabled={
                      !header.sort || ["", null].includes(header.display)
                    }
                  >
                    {header.display ?? header.name}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!data?.length ? (
              <TableRow>
                <TableCell
                  className={classes.tableSmallBodyCell}
                  colSpan={headers.length}
                  component="td"
                  scope="row"
                >
                  <div className={classes.addEmptyWrapper}>
                    {emptyTableValue || "No Data To Display"}
                  </div>
                </TableCell>
              </TableRow>
            ) : displayRows.map((rowCells, rowIndex) => (
              <TableRow key={rowIndex}>
                {headers.map((header, index) => {
                  const cell = rowCells[header.name];
                  const node = findDisplayNode(cell);

                  return (
                    <TableCell
                      className={classNames(
                        classes.cellBody,
                        alignmentColumnClasses[index],
                        cell?.color === "primary" && classes.cellBgPrimary,
                        cell?.color === "secondary" && classes.cellBgSecondary,
                        denseBody && classes.cellDenseBody,
                        headers[0].bold && classes.cellBodyBold,
                      )}
                      key={header.name}
                    >
                      {node || emptyCellValue}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
          {!!footer && (
            <TableFooter>
              <TableRow hover={false}>
                <TableCell
                  className={
                    classNames(
                      classes.footerCell,
                      denseBody && classes.cellHeaderDense
                    )
                  }
                  colSpan={headers.length}
                >
                  {footer}
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
          {!!pagination && (
            <TableFooter>
              <TableRow hover={false}>
                <TablePagination
                  className={classNames(
                    classes.footerCell,
                    denseBody && classes.cellDenseBody
                  )}
                  classes={{
                    caption: classes.paginationText,
                    select: classNames(
                      classes.paginationText,
                      classes.paginationButtonDense
                    ),
                    selectIcon: classes.paginationButtonDense,
                    toolbar: classNames(
                      denseBody && classes.paginationToolbarDense
                    )
                  }}
                  backIconButtonProps={{
                    className: denseBody && classes.paginationButtonDense
                  }}
                  nextIconButtonProps={{
                    className: denseBody && classes.paginationButtonDense
                  }}
                  size={denseBody ? "small" : "medium"}
                  count={data?.length || 0}
                  onPageChange={(_event, newPage) => setPage(newPage)}
                  onRowsPerPageChange={handlePaginationRowsPerPageChange}
                  {...(typeof pagination === "object" ? pagination : {})}
                  rowsPerPage={rowsPerPage}
                  page={page}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    );
  }
);

export default SortableTable;
