import { makeStyles, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from "@material-ui/core";
import TableCell from "components/utils/tables/shared/tableCell.component";
import classNames from "classnames";
import Form from "components/utils/form-elements/form.component";
import React, { memo, useMemo} from "react";
import authService from "services/auth.service";
import { ROLE_GROUPS } from "utils/roles";
import ReconcileInputRow from "./reconcileInputRow.component";

const useStyles = makeStyles((theme) => ({
  categoryNameCell: {
    background: theme.palette.primary.main,
    color: "white",
    fontWeight: 600,
    minWidth: 200
  },
  categoryHeaderCell: {
    background: theme.palette.primary.main,
    color: "white",
    fontWeight: 600
  },
  categoryHeaderRow: {
    borderBottomColor: theme.palette.border.dark
  },
  cell: {
    borderCollapse: "collapse",
    borderRight: `1px solid ${theme.palette.border.main}`,
    borderLeft: `1px solid ${theme.palette.border.main}`,
  },
  descriptionCell: {
    width: "25%"
  },
  formContainer: {
    marginBottom: 32,
    overflowX: "auto",
    overflowY: "hidden"
  },
  form: {
    minWidth: 600
  },
  parentCell: {
    fontWeight: 600
  },
}));

const ReconciliationTable = memo(ReconciliationTableUnmemoized);

export default ReconciliationTable;

function ReconciliationTableUnmemoized(props) {
  const classes = useStyles();
  const { assessmentItems, dispatch, notes, handleToggleForm, setFormItem } = props;

  const categoryAssessmentItems = useMemo(() => (
    assessmentItems ? assessmentItems.filter(item => !item.GroupHeader_ID) : []
  ), [assessmentItems]);

  const subcategoryAssessmentItems = useMemo(() => (
    assessmentItems ? assessmentItems.filter(item => !!item.GroupHeader_ID) : []
  ), [assessmentItems]);

  const categorizedItems = useMemo(() => (
    categoryAssessmentItems.map(category => ({
      ...category,
      _items: subcategoryAssessmentItems.filter(item => (
        item.GroupHeader_ID === category.AssessmentTemplate_ID
      ))
    })).filter(category => category._items?.length > 0)
  ), [categoryAssessmentItems, subcategoryAssessmentItems]);

  return (
    <Paper className={classes.formContainer}>
      <Form className={classes.form} name="session-notes">
        <TableContainer>
          <Table size="small">
            {categorizedItems?.map?.((category, index) => (
              <React.Fragment key={category.Item_ID}>
                <CategoryTableHeadGroup
                  category={category}
                  categoryIndex={index}
                />
                <CategoryTableBodyGroup
                  category={category}
                  categoryIndex={index}
                  dispatch={dispatch}
                  notes={notes}
                  handleToggleForm={handleToggleForm}
                  setFormItem={setFormItem}
                />
              </React.Fragment>
            ))}
          </Table>
        </TableContainer>
      </Form>
    </Paper>
  )
}

function CategoryTableHeadGroup(props) {
  const classes = useStyles();
  return (
    <TableHead className={classes.categoryHeaderRow}>
      <TableRow>
        <TableCell className={classNames(classes.categoryNameCell, classes.cell)}>
          <span>Item</span>
        </TableCell>
        <TableCell className={classNames(classes.cell, classes.categoryHeaderCell)}>
          <span>Reporter</span>
        </TableCell>
        <TableCell className={classNames(classes.cell, classes.categoryHeaderCell)}>
          <span>State</span>
        </TableCell>
        <TableCell className={classNames(classes.cell, classes.categoryHeaderCell)}>
          <span>Notes</span>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

const filterVisibleNotes = notes => (
  notes?.filter?.(note => (
    note.State?.trim?.() || note.Description?.trim?.()
  ))
);

function CategoryTableBodyGroup(props) {
  const classes = useStyles();
  const { category: item, categoryIndex, dispatch } = props;

  const isReadOnly = useMemo(() => (
    !authService.checkPermissions(ROLE_GROUPS.PORTCO_EDIT_USERS)
  ), []);

  const allParentNotes = filterVisibleNotes(item?._associations?.SessionNotes);

  const parentCollaboratedNote = useMemo(() => {
    return allParentNotes.find(parentNote => {
      return item.SessionNote_ID === parentNote.SessionNote_ID
    }) || []

  }, [allParentNotes, item.SessionNote_ID])

  const parentParticipantNotes = useMemo(() => {
    return allParentNotes?.filter(parentNote => {
      return item.SessionNote_ID !== parentNote.SessionNote_ID
    })

  }, [allParentNotes, item.SessionNote_ID])

  const parentRowCount = parentParticipantNotes?.length + 1;

  const descriptionCell = (
    <TableCell
      className={classNames(classes.cell, classes.parentCell)}
      rowSpan={parentRowCount}
    >
      <span>{categoryIndex + 1}.</span>
      &nbsp;
      <span>{item.Description}</span>
    </TableCell>
  );

  return (
    <TableBody>
      {parentParticipantNotes.map((note, index) => (
        <React.Fragment key={note.SessionNote_ID}>
          <ReconcileInputRow
            assessmentItem={item}
            descriptionCell={index === 0 && descriptionCell}
            dispatch={dispatch}
            isParticipant
            readOnly={isReadOnly}
            sessionNote={note}
          />
        </React.Fragment>
      ))}
      <ReconcileInputRow
        assessmentItem={item}
        descriptionCell={!parentParticipantNotes.length && descriptionCell}
        dispatch={dispatch}
        readOnly={isReadOnly}
        sessionNote={parentCollaboratedNote}
      />

      {/* SUBITEMS ARE MAPPED OFF THE PARENT ITEM - THIS IS A SERIES OF ROWS MAPPED OF THE ._items WITHIN THE PARENT ASSESSMENT ITEM */}
      {item?._items?.map?.((subItem, index) => {
        const allNotes = filterVisibleNotes(subItem?._associations?.SessionNotes);
        const participantNotes = [];
        let collaboratedNote = null;

        for (const note of allNotes) {
          if (subItem.SessionNote_ID === note.SessionNote_ID) {
            collaboratedNote = note;
          } else {
            participantNotes.push(note);
          }
        }
        const nestedDescriptionCell = (
          <TableCell
            className={classNames(classes.cell, classes.descriptionCell)}
            rowSpan={participantNotes.length + 1}
          >
            <span>{categoryIndex + 1}.{index + 1}</span>
            &nbsp;
            <span>{subItem.Description}</span>
          </TableCell>
        );

        return (
          <React.Fragment key={subItem.Item_ID}>
            {participantNotes?.map?.((note, rowIndex) => (
              <React.Fragment key={note.SessionNote_ID}>
                <ReconcileInputRow
                  assessmentItem={subItem}
                  descriptionCell={rowIndex === 0 && nestedDescriptionCell}
                  dispatch={dispatch}
                  isParticipant
                  readOnly={isReadOnly}
                  sessionNote={note}
                />
              </React.Fragment>
            ))}
            <ReconcileInputRow
              assessmentItem={subItem}
              descriptionCell={!participantNotes.length && nestedDescriptionCell}
              dispatch={dispatch}
              readOnly={isReadOnly}
              sessionNote={collaboratedNote}
            />
          </React.Fragment>
        )
      })}
    </TableBody>
  )
}
