import React, { useEffect, useState, useRef, useMemo, useCallback, useLayoutEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import CustomDataTable from "components/utils/tables/dataTable.component";
import ControlService from "services/control.service";
import variables from "styleVariables";
import CustomEditIcon from "components/utils/editIcon.component";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import ControlMatrixForm from "components/forms/controlMatrixForm.component";
import CustomModal from "components/utils/modal.component";
import Loader from "components/utils/loader.components";
import { ifEmptyCellDisplay } from "utils/tableFuncs";
import { setStateFetchEffect } from "utils/ajaxHelpers";
import { debounceSearchRender } from "mui-datatables";
import useNumericParams from "hooks/useNumericParams";
import { calculateMultipartSortValue, createHiddenHeaderParams, createNodeCellParams, createNodeHeaderParams, createValueHeaderParams } from "components/utils/tables/utils/dataTableHelpers";

const useStyles = makeStyles((theme) => ({
  stickyCelledTable: {
    "& table": {
      borderCollapse: "separate"
    }
  },
  headerWrapCenter: {
    textAlign: "center",
    "& > span > div > div": {
      whiteSpace: "normal !important;",
    },
  },
  customHeaderStickyLeft: {
    left: "0", //For sidescroll sticky header
    zIndex: 101,
    "& > span": {
      justifyContent: "left",
    },
  },
}));

const DEBOUNCE_SEARCH_MS = 700;

const OPTION_ROWS_PER_PAGE_DEFAULT = 100;

const defaultSort = { name: "Identifier", direction: "asc" };

const excludedFields = new Set([
  "Status",
  "Change_Date",
  "Change_User",
  "Program_Program_ID",
  "ComponentObjects_ComponentObject_ID",
  "Map_ID",
]);
const filterableColumns = new Set([
  "Control_Validation",
  "Current_State",
  "Implementation_Type",
  "Group_Name"
]);
const hiddenColumns = new Set([
  "RegFramework_ID",
  "Framework_ID",
  "Framework_Name",
  "ProgramComponents_Component_ID"
]);
// const longText = ["Practice", "Req_Desc"]
const longDataCollectionHeaders = new Set(
  ["Implementation_Type", "Validation_Date", "Implementation_Desc"]
);
const dataCollectionHeaders = new Set(
  ["Current_State", "Control_Validation", "Validation_Date", "Support_Tool"]
);
// const veryLongText = ["Roadmap_Notes"]
// const clickableColumn = {
//   Control_Name: "Control_Text",
// }

const ControlMatrixTable = (props) => {
  const classes = useStyles();

  const { selectedFramework, selectedMappedFramework, isFetchingMatrixData, setIsFetchingMatrixData} = props;
  const location = useLocation();
  const history = useHistory();
  const { programId } = useNumericParams();
  const selectedRowIdRef = useRef(null);

  const [matrixData, setMatrixData] = useState();
  const [searchRowIndex, setSearchRowIndex] = useState(undefined);
  const [defaultPage, setDefaultPage] = useState(undefined);
  const [loadedTargetRow, setLoadedTargetRow] = useState(false); // For some reasonm needed for highlight row/scroll
  const [rowsPerPage, setRowsPerPage] = useState(OPTION_ROWS_PER_PAGE_DEFAULT);
  // for modal
  const [editModal, setEditModal] = useState(false);
  const [rowInfo, setRowInfo] = useState();

  useLayoutEffect(() => {
    if (searchRowIndex !== undefined || !matrixData) {
      return;
    }
    if (location.state) {
      // Unfortunately, mui-datatables doesn't make the sorted dataset available,
      //   so it has to be sorted an extra time here.
      const defaultSortedMatrixData = matrixData.sort(
        defaultSort.direction === "asc" ?
          (d1, d2) => d1[defaultSort.name].localeCompare(d2[defaultSort.name]) :
          (d1, d2) => d2[defaultSort.name].localeCompare(d1[defaultSort.name])
      );
      const index = defaultSortedMatrixData.findIndex(row => (
        row.ControlMatrix_ID === location.state.itemId
      ));
      if (index >= 0) {
        const rowPage = Math.ceil((index + 1) / rowsPerPage) - 1;
        setDefaultPage(rowPage);
        setSearchRowIndex(index ?? null);
        return;
      }
      setSearchRowIndex(null);
    }
  }, [location.state, matrixData, rowsPerPage, searchRowIndex]);

  const handleSetRowProps = useMemo(() => {
    if (location.state || searchRowIndex) {
      return (_row, dataIndex) => {
        if (dataIndex === searchRowIndex) {
          return {
            ref: (el) => {
              selectedRowIdRef.current = el?.props?.id;
            },
            style: { backgroundColor: variables.chosenElement },
          };
        }
      };
    }
  }, [location.state, searchRowIndex]);

  const toggleForm = useCallback(() => {
    setEditModal(!editModal);
  }, [editModal]);

  const tableOptions = useMemo(() => ({
    customSearchRender: debounceSearchRender(DEBOUNCE_SEARCH_MS),
    filterType: "checkbox",
    fixedHeader: true,
    fixedSelectColumn: true,
    filter: true,
    onChangePage: () => setDefaultPage(undefined),
    onChangeRowsPerPage: setRowsPerPage,
    page: defaultPage,
    pagination: true,
    responsive: "simple",
    rowsPerPage,
    rowsPerPageOptions: [50, OPTION_ROWS_PER_PAGE_DEFAULT, 200],
    selectableRowsHideCheckboxes: true,
    sortOrder: defaultSort,
    setRowProps: handleSetRowProps,
    tableBodyMaxHeight: "calc(100vh - 294px)",
  }), [defaultPage, handleSetRowProps, rowsPerPage]);

  useLayoutEffect(function scrollToSearchRow() {
    if (location.state && selectedRowIdRef.current) {
      document.getElementById(selectedRowIdRef.current).scrollIntoView({
        block: "center",
      });
    }
    // eslint-disable-next-line
  }, [location.state, selectedRowIdRef.current, history]);
  useEffect(function fetchControlMatrixRowsWithTargetRow() {
    if (location.state && !loadedTargetRow ) {
      if (selectedMappedFramework) {
        setIsFetchingMatrixData(true)
        return setStateFetchEffect(
            () => ControlService.getMappedFrameworkControlMatrixRows(programId, selectedFramework.RegFramework_id, selectedMappedFramework.RegFramework_id),
            ([rowsResponse]) => {
              setMatrixData(rowsResponse.payload);
              setIsFetchingMatrixData(false)
              setLoadedTargetRow(true);
            }
        );
      } else {
        setIsFetchingMatrixData(true)
        return setStateFetchEffect(
            () => ControlService.getControlMatrixRowsByProgramIdAndFrameworkId(programId, selectedFramework.RegFramework_id),
            ([rowsResponse]) => {
              setMatrixData(rowsResponse.payload);
              setIsFetchingMatrixData(false)
              setLoadedTargetRow(true);
            }
        );
      }
    }
  }, [programId, selectedFramework, selectedMappedFramework, loadedTargetRow, location.state, setIsFetchingMatrixData]);

  useEffect(function fetchControlMatrixRows() {
    if (!location.state ) {
      if (selectedMappedFramework) {
        setIsFetchingMatrixData(true)
        return setStateFetchEffect(
            () => ControlService.getMappedFrameworkControlMatrixRows(programId, selectedFramework.RegFramework_id, selectedMappedFramework.RegFramework_id),
            ([rowsResponse]) => {
              setMatrixData(rowsResponse.payload);
              setIsFetchingMatrixData(false)
            }
        );
      } else {
        setIsFetchingMatrixData(true)
        return setStateFetchEffect(
            () => ControlService.getControlMatrixRowsByProgramIdAndFrameworkId(programId, selectedFramework.RegFramework_id),
            ([rowsResponse]) => {
              setMatrixData(rowsResponse.payload);
              setIsFetchingMatrixData(false)
            }
        );
      }
    }

  }, [programId, selectedFramework, selectedMappedFramework, location.state, setIsFetchingMatrixData]);

  const openForm = useCallback((matrixId) => {
    setRowInfo(matrixId);
    setEditModal(true);
  }, []);

  const setEditCellProps = useCallback(() => ({
    style: {
      position: "sticky",
      left: "0px",
      background: "white",
      color: variables.textSecondary,
      fontSize: variables.fontSmall,
      zIndex: 100,
      textAlign: "left",
      boxShadow: variables.shadowTableInset,
    },
  }), []);

  const setEditCellHeaderProps = useCallback(() => ({
    className: clsx({ [classes.customHeaderStickyLeft]: true }),
  }), [classes]);

  const setCenterAlignedCellProps = useCallback(() => ({
    style: { textAlign: "center" }
  }), []);

  // const setLongHeaderCellProps = useCallback(() => ({
  //   className: clsx({ [classes.headerWrapCenter]: true }),
  // }), [classes]);

  const setLongDCHeaderCellProps = useCallback(() => ({
    className: clsx({ [classes.headerWrapCenter]: true}),
    style: { backgroundColor: variables.primaryDark }
  }), [classes]);

  const setDCHeaderCellProps = useCallback(() => ({
    style: { backgroundColor: variables.primaryDark }
  }), []);

  const renderPotentialEmptyCellBody = useCallback(value => (
    ifEmptyCellDisplay(value, "None")
  ), []);

  const matrixColumns = useMemo(() => {
    if (!matrixData) {
      return null;
    }
    if (matrixData.length === 0) {
      return [{}]
    }

    const columnNames = Object.keys(matrixData[0]).filter(field => (
      !excludedFields.has(field)
    ));
    const editColumns = [];
    const columns = [];
    const dcColumns = [];
    columnNames.forEach(columnName => {
      const columnLabel = columnName.split("_").join(" ");
      const filter = filterableColumns.has(columnName);
      if (columnName === "ControlMatrix_ID") {
        editColumns.push(createNodeHeaderParams(columnName, " ", {
          viewColumns: false,
          searchable: false,
          filter: false,
          sort: false,
          sortOrder: { name: "Matrix_ID" },
          setCellProps: setEditCellProps,
          setCellHeaderProps: setEditCellHeaderProps,
        }));
      } else if (dataCollectionHeaders.has(columnName)) {
        const headerLabel = columnName === "Support_Tool" ? "Support Tooling" : columnLabel
        dcColumns.push(createValueHeaderParams(columnName, headerLabel, {
          filter,
          sort: true,
          setCellProps: setCenterAlignedCellProps,
          setCellHeaderProps: setDCHeaderCellProps,
        }));
      } else if (longDataCollectionHeaders.has(columnName)) {
        dcColumns.push(createValueHeaderParams(columnName, columnLabel, {
          filter,
          sort: true,
          setCellProps: setCenterAlignedCellProps,
          setCellHeaderProps: setLongDCHeaderCellProps,
        }));
      } else if (columnName === "Group_Name") {
        columns.push(
          createValueHeaderParams(
            columnName,
            `${matrixData[0].Framework_Name} Group Name`,
            {
              filter,
              sort: true,
              setCellProps: setCenterAlignedCellProps,
              customBodyRender: renderPotentialEmptyCellBody,
            },
          )
        );
      } else if (columnName === "Identifier") {
        columns.push(createNodeHeaderParams(
          columnName,
          `${matrixData[0].Framework_Name} Identifier`,
          {
            filter: false,
            sort: true,
            setCellProps: setCenterAlignedCellProps
          }
        ));
      } else if (columnName === "171_Identifier") {
        columns.push(createNodeHeaderParams(
          columnName,
          `171 Identifier`,
          {
            filter,
            sort: true,
            setCellProps: setCenterAlignedCellProps,
          }
        ));
      } else if (columnName === "Description") {
        columns.push(createValueHeaderParams(
          columnName,
          `${matrixData[0].Framework_Name} Description`,
          {
            filter: false,
            sort: true,
            setCellProps: setCenterAlignedCellProps,
            customBodyRender: renderPotentialEmptyCellBody
          },
        ));
      } else if (hiddenColumns.has(columnName)) {
      /* HIDDEN COLUMNS */
        columns.push(createHiddenHeaderParams(columnName, columnLabel, {
          viewColumns: false,
          filter: false,
          sort: false,
        }));
      } else {
        columns.push(createValueHeaderParams(columnName, columnLabel, {
          filter,
          sort: true,
          setCellProps: setCenterAlignedCellProps,
        }));
      }
    });
    return [...editColumns, ...columns, ...dcColumns];
  }, [
    matrixData, renderPotentialEmptyCellBody, setCenterAlignedCellProps,
    setEditCellProps, setEditCellHeaderProps, setLongDCHeaderCellProps, setDCHeaderCellProps
  ]);

  const tableRows = useMemo(() => (
    matrixData?.map?.(item => {
      return {
        ...item,
        ControlMatrix_ID:
          createNodeCellParams(item.ControlMatrix_ID, item.ControlMatrix_ID, (
            <CustomEditIcon
              onClick={() => openForm(item.ControlMatrix_ID)}
              variant="matrix"
              test={`controlMatrix-${item.ControlMatrix_ID}`}
            />
          )),
        "171_Identifier": createNodeCellParams(
          calculateMultipartSortValue(item["171_Identifier"], "None", "."),
          item["171_Identifier"]
        ),
        Identifier: createNodeCellParams(
          calculateMultipartSortValue(item.Identifier, "None", "."),
          item.Identifier
        )
      };
    })
  ), [matrixData, openForm]);

  if (tableRows && !isFetchingMatrixData) {
    return (
      <div className={classes.tableContainer} data-cy="control-matrix-wrapper">
        <CustomDataTable
          data={tableRows}
          columns={matrixColumns}
          options={tableOptions}
          className={classes.stickyCelledTable}
          // EXCEL DOWNLOAD INFO
          excelColumns={matrixColumns}
          excelData={matrixData}
        />
        <CustomModal open={editModal} onClose={toggleForm}>
          <ControlMatrixForm
            setEditModal={setEditModal}
            rowInfo={rowInfo}
            matrixData={matrixData}
            setMatrixData={setMatrixData}
          />
        </CustomModal>
      </div>
    );
  } else {
    return <Loader />;
  }
};

export default ControlMatrixTable;
