import moment from "moment";
import { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CustomLink from "components/utils/link.component";
import SortableTable from "components/utils/tables/sortableTable.component";
import { mapObjectArrayByKey } from "utils/arrayOfObjectsHelpers";
import { currentUserID } from "utils/userHelpers";
import Loader from "components/utils/loader.components";
import { DATETIME_DISPLAY_NUMERIC_LONG } from "utils/dateConstants";

const useStyles = makeStyles((theme) => ({
  assignedTasksTable: {
    overflowX: "unset",
  },
  headerCellName: {
    minWidth: 100
  }
}));


export default function AssignedTasksTable(props) {
  const { onTaskClick, runbookTasks, workgroupMembers, workstreamMembers } = props;

  const userId = useMemo(currentUserID, []);
  const classes = useStyles();

  const headers = useMemo(() => ([
    {
      name: "Task_ID",
      display: "Task ID",
      sort: true,
      alignment: "center"
    }, {
      name: "Name",
      display: "Name",
      sort: true,
      alignment: "center",
      className: classes.headerCellName
    }, {
      name: "Start_Datetime",
      display: "Start Date",
      sort: true,
      alignment: "center"
    }, {
      name: "Is_Workgroup",
      display: "Work­group",
      sort: true,
      alignment: "center"
    }, {
      name: "Is_Workstream",
      display: "Work­stream",
      sort: true,
      alignment: "center"
    }
  ]), [classes]);

  const userWorkgroupIdSet = useMemo(() => (
    workgroupMembers &&
    new Set(
      workgroupMembers
        .filter(member => userId === member.User_ID)
        .map(workgroup => workgroup.Workgroup_ID)
    )
  ), [workgroupMembers, userId]);

  const userWorkstreamIdSet = useMemo(() => (
    workstreamMembers &&
    new Set(
      workstreamMembers
        ?.filter?.(member => userId === member.User_ID)
        ?.map?.(workstream => workstream.Workstream_ID)
    )
  ), [workstreamMembers, userId]);

  const workgroupTasks = useMemo(() => (
    userWorkgroupIdSet && runbookTasks && runbookTasks
      .filter(task => userWorkgroupIdSet.has(task.Workgroup_ID))
      .map(task => ({ ...task, _meta: { isWorkgroup: true } }))
  ), [runbookTasks, userWorkgroupIdSet]);

  const workstreamTasks = useMemo(() => (
    userWorkstreamIdSet && runbookTasks && runbookTasks
      .filter(task => userWorkstreamIdSet.has(task.Workstream_ID))
      .map(task => ({ ...task, _meta: { isWorkstream: true } }))
  ), [runbookTasks, userWorkstreamIdSet]);

  const combinedTasks = useMemo(() => {
    if (!workstreamTasks) {
      if (!workgroupTasks) {
        return null;
      } else {
        return workgroupTasks;
      }
    } else if (!workgroupTasks) {
      return workstreamTasks;
    }
    const combinedTasksById = mapObjectArrayByKey(workgroupTasks, "Task_ID");
    workstreamTasks.forEach(workstreamTask => {
      const sharedWorkgroupTask = combinedTasksById[workstreamTask.Task_ID];
      if (sharedWorkgroupTask) {
        combinedTasksById[workstreamTask.Task_ID] = {
          ...sharedWorkgroupTask,
          ...workstreamTask,
          _meta: { ...sharedWorkgroupTask._meta, ...workstreamTask._meta }
        };
      } else {
        combinedTasksById[workstreamTask.Task_ID] = workstreamTask;
      }
    });
    return Object.values(combinedTasksById);
  }, [workgroupTasks, workstreamTasks]);

  const tableRows = useMemo(() => (
    combinedTasks && combinedTasks.map(task => ({
      Task_ID: {
        value: task.Task_ID,
        node: !onTaskClick ? null : (
          <CustomLink
            variant="noHRef"
            onClick={event => onTaskClick(task, event)}
          >
            {task.Task_ID}
          </CustomLink>
        )
      },
      Name: task.Name,
      Start_Datetime:
        moment(task.Start_Date).format(DATETIME_DISPLAY_NUMERIC_LONG),
      Is_Workgroup: task._meta?.isWorkgroup ? "Yes" : "No",
      Is_Workstream: task._meta?.isWorkstream ? "Yes" : "No"
    }))
  ), [combinedTasks, onTaskClick]);

  if (!combinedTasks) {
    return (
      <Loader />
    );
  }
  return (
    <SortableTable
      headers={headers}
      data={tableRows}
      footer={`Total: ${tableRows.length}`}
      denseHeader
      denseBody
      className={classes.assignedTasksTable}
    />
  );
}
