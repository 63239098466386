import { Box } from "@material-ui/core";
import React from "react";
import { PulseLoader } from "react-spinners";
import { CheckCircleOutline } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";

const useStyles = makeStyles((theme) => ({
  saveSuccess: {
    color: variables.statusGreenDark,
    fontSize: 16,
  },
  publishSuccess: {
    color: variables.secondaryDark,
    fontSize: 16,
  },
  error: {
    color: variables.warningMain,
  },
}));

const SaveStatus = ({ isSaving, errorMessage, variant, infoMessage, saveMessage }) => {
  const classes = useStyles();

  if (variant === "builder") {
    return (
      <Box display="flex" height={16} alignItems="center" alignSelf="center">
        {errorMessage ? (
          <span className={classes.error}>{errorMessage}</span>
        ) : (
          <>
            {isSaving === "saving" && (
              <>
                <Box paddingRight={0.5}>Saving</Box>
                <PulseLoader size={6} color={variables.tertiary1} />
              </>
            )}
            {!!saveMessage && (
              <>
                <Box paddingRight={0.5} data-cy="builder-saved">
                  {saveMessage}
                </Box>
                <CheckCircleOutline className={classes.saveSuccess} />
              </>
            )}
            {!!infoMessage && (
              <>
                <Box paddingRight={0.5}>{infoMessage}</Box>
                <CheckCircleOutline className={classes.publishSuccess} />
              </>
            )}
          </>
        )}
      </Box>
    );
  } else {
    return "";
  }
};

export default SaveStatus;
