import { Box, makeStyles, MobileStepper, Paper, Grid } from "@material-ui/core";
import React, { useState, useEffect, useMemo } from "react";
import companyLogo from "images/company-logo.png";
import Logo from "components/utils/logo.component";
import styleVariables from "styleVariables";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import ButtonDefault from "components/utils/buttonDefault.component";
import DisplayHTML from "components/utils/displayHTML.component";
import trainingService from "services/training.service";
import moment from "moment";
import Image from "components/utils/image.component";
import { useCallback } from "react";
import { ACTION_REPLACE_ASSIGNMENT } from "reducers/trainingAssignments.reducer";
import Loader from "components/utils/loader.components";
import imageService from "services/image.service";
import { H2, H3 } from "components/utils/headerV2.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import BackToLink from "components/utils/backToLink.component";
import ChildTrainingModule from "./childTrainingModule.component";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    height: "100vh",
  },
  backToLinkWrapper: {
    marginTop: 24,
    padding: 24,
  },
  moduleContainer: {
    height: "100%",
    maxHeight: "calc(100vh - 85px)",
    margin: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "hidden",
    color: styleVariables.textSecondary,
    borderRadius: 5,
    '@media (max-height: 700px)': {
      marginTop: 0,
    }
  },
  scrollContainer: {
    flex: 1,
    flexDirection: "column",
    display: "flex",
    padding: 24,
    overflowY: "auto",
  },
  banner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  slideContentContainer: {
    padding: 24,
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '@media (max-height: 700px)': {
      padding: 16,
    },
    '@media (max-height: 580px)': {
      padding: "16px 16px 0",
    }
  },
  slideContentWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  bottomBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 24px 16px",
    boxShadow: styleVariables.boxShadow2,
  },
  backButtonWrapper: {
    alignSelf: "center",
  },
  nextButtonWrapper: {
    alignSelf: "center",
  },
  moduleInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  titleSubheader: {
    fontSize: styleVariables.fontLarge,
  },
  arrowIcon: {
    fill: "white",
    fontSize: 20,
  },
  staticButtonWrapper: {
    marginTop: 16,
    textTransform: "uppercase"
  },
  logoWrapper: {
    alignItems: "center",
    display: "flex",
  },
  exitMessage: {
    textAlign: "center",
    fontSize: styleVariables.fontLarge,
    width: 400,
    padding: "16px 0px 8px",
  },
  slideLeft: {
    display: "flex",
    justifyContent: "center",
  },
  slideRight: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  slideHtml: {
    fontSize: styleVariables.fontLarge,
    "& > ol": {
      margin: 0,
      paddingTop: 3,
    },
    "& > ol > li": {
      padding: 0,
      margin: 0,
    }
  },
  slideImage: {
    width: "auto",
    height: "100%",
    maxWidth: "fit-content",
    maxHeight: 256,
    borderRadius: 1,
  },
  stepper: {
    width: "100%",
    marginTop: 8,
    padding: 0
  },
  childSlidesWrapper: {
    alignItems: "stretch",
    height: "50vh",
    '@media (max-height: 780px)': {
      height: "40vh",
    },
    '@media (max-height: 580px)': {
      paddingTop: 0,
      paddingBottom: 0,
    }
  }
}));

const staticSlideTypes = {
  TITLE: "Title",
  LOADING: "Loading",
  CLOSING: "Closing",
};

const staticIntroSlides = [
  { staticSlideType: staticSlideTypes.TITLE }
];

const staticLoadingSlides = [
  { staticSlideType: staticSlideTypes.LOADING}
];

const staticClosingSlides = [
  { staticSlideType: staticSlideTypes.CLOSING }
];

const TrainingModule = ({ assignment, closeModule, dispatch, completeModule }) => {
  const classes = useStyles();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [dynamicSlides, setDynamicSlides] = useState();

  useEffect(() => {
    const getSlides = async () => {
      const res = await trainingService.getTrainingModuleByAssignmentId(
        assignment.TrainingAssignment_ID
      );
      setDynamicSlides(res.payload._associations.TrainingSlide);
    };
    getSlides();
  }, [assignment.TrainingAssignment_ID]);

  const slides = useMemo(() => ([
    ...staticIntroSlides,
    ...(dynamicSlides || staticLoadingSlides),
    ...staticClosingSlides
  ]), [dynamicSlides]);

  useEffect(() => (
    (async function prefetchImages() {
      if (!dynamicSlides?.length) {
        return;
      }
      // Fetch the first image on its own for less loading time
      if (dynamicSlides[0].Image_FileName) {
        await imageService.getImage(dynamicSlides[0].Image_FileName);
      }
      dynamicSlides.slice(1)
        .map(slide => slide.Image_FileName)
        .filter(path => path)
        .forEach(path => imageService.getImage(path));

      return function clearImageCacheOnUnmount() {
        dynamicSlides
          .filter(slide => slide.Image_FileName)
          .forEach(slide => imageService.clearCachedImage(slide.Image_FileName))
      }
    })()
  ), [dynamicSlides]);

  const goToSlideRelative = useCallback(relativeSlidesAway => {
    if (relativeSlidesAway === 0) {
      console.error("Relative slide to navigate to must be non-zero");
    }
    const rawIndex = (currentSlideIndex || 0) + relativeSlidesAway;
    const newIndex = Math.min(slides.length - 1, Math.max(0, rawIndex));
    if (Number.isNaN(newIndex)) {
      throw new Error(
        `Attempted to navigate to invalid relative slide: ${relativeSlidesAway}`
      );
    }
    setCurrentSlideIndex(newIndex);
  }, [currentSlideIndex, setCurrentSlideIndex, slides]);

  const nextClick = useCallback(() => goToSlideRelative(1), [goToSlideRelative]);
  const backClick = useCallback(() => goToSlideRelative(-1), [goToSlideRelative]);

  const currentSlide = useMemo(() => {
    return slides?.[currentSlideIndex];
  }, [slides, currentSlideIndex]);

  return (
    <div className={classes.container}>
      <div className={classes.backToLinkWrapper}>
        <BackToLink
          dense
          text="Return to My Assignments"
          onClick={closeModule}
          variant="subLink"
        />
      </div>
      <Paper className={classes.moduleContainer} elevation={3}>
        <div className={classes.scrollContainer}>
          <div className={classes.banner}>
            <div className={classes.logoWrapper}>
              <Logo logo64Prop={companyLogo} variant="mainHeader" />
            </div>
            <div className={classes.moduleInfo}>
              <div className={classes.titleSubheader}>
                {assignment.Module_Name}
              </div>
              <div>
                {moment(assignment.TrainingModule_Creation_Date).format("MMMM D, YYYY")}
              </div>
            </div>
          </div>
          <div className={classes.slideContentContainer}>
            <SlideContent
              completeModule={completeModule}
              dispatch={dispatch}
              goToSlideRelative={goToSlideRelative}
              assignment={assignment}
              slide={currentSlide}
            />
          </div>
        </div>

        {!currentSlide.staticSlideType && (
          <div className={classes.bottomBar}>
            <MobileStepper
              variant="dots"
              steps={dynamicSlides?.length}
              position="static"
              activeStep={currentSlideIndex - staticIntroSlides.length}
              className={classes.stepper}
              nextButton={
                currentSlide.staticSlideType !== staticSlideTypes.CLOSING ? (
                  <div className={classes.nextButtonWrapper}>
                    <SlideNavButton direction="Next" onClick={nextClick} />
                  </div>
                ) : null
              }
              backButton={
                <div className={classes.backButtonWrapper}>
                  <SlideNavButton direction="Back" onClick={backClick} />
                </div>
              }
            />
          </div>
        )}
      </Paper>
    </div>
  );
};

const TitleSlide = ({
  assignment,
  dispatch,
  goToSlideRelative,
}) => {
  const classes = useStyles();

  const handleClickStart = useCallback(async () => {
    goToSlideRelative(1);
    if (!assignment.Start_Date) {
      const updateRes = await trainingService.updateTrainingAssignment(
        assignment.TrainingAssignment_ID,
        { Start_Date: moment(new Date()).format("YYYY-MM-DD") }
      );
      dispatch({
        type: ACTION_REPLACE_ASSIGNMENT,
        payload: updateRes.payload
      });
    }
  }, [
    dispatch, goToSlideRelative,
    assignment.TrainingAssignment_ID, assignment.Start_Date
  ]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <H2 color="tertiary">{assignment.TrainingModule_Name}</H2>
      <div className={classes.staticButtonWrapper}>
        <ButtonDefault onClick={handleClickStart} data-cy="btn-training-start">
          Start
        </ButtonDefault>
      </div>
    </Box>
  );
}

const ClosingSlide = ({
  assignment,
  completeModule,
  dispatch,
}) => {
  const classes = useStyles();

  useEffect(() => {
    (async function markComplete() {
      if (assignment.End_Date) {
        return;
      }
      const assignmentResponse = await trainingService.updateTrainingAssignment(
        assignment.TrainingAssignment_ID,
        { End_Date: moment().format("YYYY-MM-DD") }
      );
      dispatch({
        type: ACTION_REPLACE_ASSIGNMENT,
        payload: assignmentResponse.payload
      });
    })();
  }, [dispatch, assignment.TrainingAssignment_ID, assignment.End_Date]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <H2 color="tertiary">
        Thank you for completing {assignment.TrainingModule_Name}!
      </H2>
      <div className={classes.exitMessage}>
        Your course completion has been recorded and you may now exit the
        Training Module.
      </div>
      <div className={classes.staticButtonWrapper}>
        <ButtonDefault onClick={completeModule} data-cy="btn-training-start">
          Exit
        </ButtonDefault>
      </div>
    </Box>
  );
}

const DynamicSlide = ({ slide, parentSlide }) => {
  const classes = useStyles();

  if (!slide) {
    return (
      <Loader height="auto" />
    );
  }
  return (
    <GridContainer className={classes.slideContentWrapper}>
      <GridItem xs={7} className={parentSlide ? null : classes.slideLeft}>
        <SlideTextDisplay slide={slide} />
      </GridItem>
      <GridItem xs={5} className={classes.slideRight}>
        {!!slide.Image_FileName && (
          <Image
            imageId={slide.Image_FileName}
            className={classes.slideImage}
            alt={slide.Slide_Header || "Training Module Image"}
          />
        )}
      </GridItem>
    </GridContainer>
  );
}

const SlideContent = ({
  assignment,
  completeModule,
  dispatch,
  goToSlideRelative,
  slide
}) => {
  const classes = useStyles();
  switch (slide?.staticSlideType) {
    case staticSlideTypes.TITLE:
      return (
        <TitleSlide
          dispatch={dispatch}
          goToSlideRelative={goToSlideRelative}
          assignment={assignment}
        />
      );
    case staticSlideTypes.LOADING:
      return (
        <div className={classes.slideContentWrapper}>
          <Loader height="auto" />
        </div>
      );
    case staticSlideTypes.CLOSING:
      return (
        <ClosingSlide
          completeModule={completeModule}
          dispatch={dispatch}
          assignment={assignment}
        />
      );
    default:
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DynamicSlide
              slide={slide}
              parentSlide={slide?._associations?.TrainingSlide?.length > 0}
            />

          </Grid>
          {slide?._associations?.TrainingSlide?.length > 0 && (
            <Grid item xs={12} className={classNames(classes.slideContentWrapper, classes.childSlidesWrapper)}>
              <ChildTrainingModule
                slides={slide._associations.TrainingSlide}
              />
            </Grid>
          )}
        </Grid>
      );
  }
};

const SlideTextDisplay = ({ slide }) => {
  const classes = useStyles();
  return (
    <div>
      {!!slide.Slide_Header && (
        <H3 color="tertiary" className={classes.slideHeader}>
          {slide.Slide_Header}
        </H3>
      )}
      <DisplayHTML
        html={slide.Slide_Text}
        className={classes.slideHtml}
        slideText
      />
    </div>
  );
};

const SlideNavButton = ({ direction, onClick, disabled }) => {
  const classes = useStyles();
  return (
    <ButtonDefault
      startIcon={
        direction === "Back" && <ArrowBackIos className={classes.arrowIcon} />
      }
      endIcon={
        direction === "Next" && (
          <ArrowForwardIos className={classes.arrowIcon} />
        )
      }
      onClick={onClick}
      disabled={disabled}
      data-cy={`btn-training-${direction}`}
      variant="small"
    >
      {direction}
    </ButtonDefault>
  );
};

export default TrainingModule;
