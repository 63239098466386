import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControlLabel, Checkbox as MUICheckbox } from "@material-ui/core";
import variables from "styleVariables";
import { isReadOnly } from "utils/roles";
import Tooltip from "../tooltip.component";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: variables.fontMedium,
    color: variables.textSecondary,
  },
  tealCheckbox: {
    color: variables.tertiary2Dark,
    "& .MuiIconButton-label": {
      color: variables.tertiary2,
    },
  },
  readOnly: {
    '& .MuiInputBase-input.Mui-disabled': {
      color: "#000"
    }
  },
  noMargin: {
    margin: 0,
  },
}));

const Checkbox = ({
  checked,
  onChange,
  onClick,
  name,
  label,
  labelClass,
  rootClass,
  indeterminate,
  disabled,
  readOnly: readOnlyProp,
  test,
  size,
  value,
  tooltipMessage,
  tooltipPlacement,
  checkBoxColor,
  ...muiProps
}) => {
  const classes = useStyles();
  const readOnly = readOnlyProp ?? isReadOnly();

  const isToolipEnabled = useMemo(() => (
    disabled && !!tooltipMessage
  ), [disabled, tooltipMessage]);

  return (
    <Tooltip
      id={`tooltip-${name}`}
      title={isToolipEnabled ? tooltipMessage : null}
      placement={tooltipPlacement}
    >
      <FormControlLabel
        control={
          <CheckboxDefault
            checkBoxColor={checkBoxColor}
            checked={checked}
            disabled={disabled}
            indeterminate={indeterminate}
            name={name}
            onChange={onChange}
            size={size}
            test={test}
            value={value}
            {...muiProps}
          />
        }
        label={label}
        classes={{
          label: labelClass || classes.label,
          root: classNames(
            !label && classes.noMargin,
            readOnly && classes.readOnly,
            rootClass
          ),
          disabled: classes.disabled
        }}
        onClick={onClick}
        disabled={disabled || readOnly}
        readOnly={readOnly}
      />
    </Tooltip>
  );
};

export default Checkbox;

const CheckboxDefault = (props) => {
  const classes = useStyles();
  const {
    checkBoxColor, checked, disabled, name, onChange, size, test, value, ...muiProps
  } = props;
  return (
    <MUICheckbox
      {...muiProps}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      name={name}
      size={size}
      value={value}
      data-cy={test ? `checkbox-${test}` : ""}
      className={
        classNames(!disabled && checkBoxColor === "teal" && classes.tealCheckbox)
      }
    />
  );
}
