import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import DataTable from "components/utils/tables/dataTable.component";
import { mapObjectArrayByKey } from "utils/arrayOfObjectsHelpers";
import { useCallback } from "react";
import EditableSelectCell from "components/utils/tables/editableDataTableCells/editableSelectCell.component";
import EditableTextCell from "components/utils/tables/editableDataTableCells/editableTextCell.component";
import EditableAutocompleteCell from "components/utils/tables/editableDataTableCells/editableAutocompleteCell.component";
import EditableDatetimeCell from "components/utils/tables/editableDataTableCells/editableDatetimeCell.component";
import EditableCheckboxCell from "components/utils/tables/editableDataTableCells/editableCheckboxCell.component";
import RunbookService from "services/runbook.service";
import { ACTION_REMOVE_RUNBOOK_TASK_DEPENDENCIES, ACTION_REPLACE_RUNBOOK_TASK, ACTION_REPLACE_RUNBOOK_TASK_DEPENDENCIES } from "reducers/runbook/oneRunbook.reducer";
import { Box, useMediaQuery } from "@material-ui/core";
import DeleteTaskModal from "../modals/deleteTaskModal.component";
import DeleteIconButton from "components/utils/deleteIconButton.component";
import { isAdmin, isRunbookAdmin } from "utils/roles";
import { VALIDATION_MAX_LENGTH } from "utils/formValidators";
import { createNodeCellParams, createNodeHeaderParams, createValueHeaderParams } from "components/utils/tables/utils/dataTableHelpers";
import { DATETIME_DISPLAY_NUMERIC_LONG } from "utils/dateConstants";


const STATUS_OPTIONS = [
  { label: "Not Started", value: "Not Started" },
  { label: "In Progress", value: "In Progress" },
  { label: "Completed", value: "Completed" }
];

const PAGINATION_ROW_MINIMUM = 11;

const staticTableOptions = {
  filter: true,
  filterType: "checkbox",
  fixedHeader: true,
  fixedSelectColumn: true,
  pagination: false,
  selectableRows: "single",
  selectableRowsHeader: false,
  selectableRowsHideCheckboxes: true,
  selectToolbarPlacement: "none",
  sort: true,
  tableBodyMaxHeight: "calc(100vh - 500px)",
};

const defaultOptions = {
  align: "center",
  setCellProps: () => ({
    style: { padding: "8px 16px" }
  }),
  setCellHeaderProps: () => ({
    style: { padding: "8px 16px" }
  }),
  filter: false,
  sort: false
};

const defaultLeftAlignedNodeOptions = {
  ...defaultOptions,
  align: "left"
};

const defaultButtonNodeOptions = {
  ...defaultOptions,
  viewColumns: false,
  setCellProps: () => ({
    ...defaultOptions.setCellProps(),
    style: { padding: "0 8px" }
  }),
};

const staticTableHeaders = [
  createNodeHeaderParams("taskId", "Task ID", {
    ...defaultOptions,
    sort: true,
    viewColumns: false
  }),
  createNodeHeaderParams("name", "Task Name", {
    ...defaultOptions,
    sort: true
  }),
  createNodeHeaderParams("phase", "Phase", {
    ...defaultOptions,
    filter: true,
  }),
  createNodeHeaderParams("workstream", "Workstream", {
    ...defaultOptions,
    filter: true,
  }),
  createNodeHeaderParams("dependencies", "Dependencies", defaultOptions),
  createNodeHeaderParams("description", "Steps", defaultLeftAlignedNodeOptions),
  createNodeHeaderParams("workgroupName", "Workgroup", {
    ...defaultOptions,
    filter: true,
  }),
  createValueHeaderParams("workgroupAdminName", "Workgroup Owner", {
    ...defaultOptions,
    filter: true,
  }),
  createNodeHeaderParams("startDate", "Start Date", {
    ...defaultOptions,
    sort: true,
  }),
  createNodeHeaderParams("endDate", "End Date", {
    ...defaultOptions,
    sort: true
  }),
  createNodeHeaderParams("milestone", "Milestone", {
    ...defaultOptions,
    filter: true,
  }),
  createNodeHeaderParams("status", "Status", {
    ...defaultOptions,
    filter: true,
  }),
  createNodeHeaderParams("statusCompleted", "Last Completed Date", {
    ...defaultOptions,
    sort: true
  }),
  createNodeHeaderParams(
    "notes",
    "Additional Comments",
    defaultLeftAlignedNodeOptions
  ),
];


export default function RunbookTaskTable(props) {
  const dynamicHeightBreakpoint = useMediaQuery('(min-height: 800px)');
  const { state, dispatch, runbookId} = props;

  const [openDeleteTaskId, setOpenDeleteTaskId] = useState(null);

  const [scrollToTask, setScrollToTask] = useState(null);
  const [previousScrollToTask, setPreviousScrollToTask] = useState(null);
  const selectedRowRef = useRef();

  useEffect(function handleHighlightedTaskChange() {
    if (!scrollToTask && state.uiHighlightedTask && previousScrollToTask !== state.uiHighlightedTask) {
      setScrollToTask(state.uiHighlightedTask)
    }
  }, [scrollToTask, previousScrollToTask, state.uiHighlightedTask]);

  useEffect(function handlePendingScrollToTask() {
    if (scrollToTask && selectedRowRef.current) {
      setScrollToTask(null);
      // Cancel pending scroll if uiHighlightedTask changed since setting scrollToTask.
      if (scrollToTask !== state.uiHighlightedTask) {
        return;
      }
      selectedRowRef.current.scrollIntoView();
      setPreviousScrollToTask(state.uiHighlightedTask);
    }
  }, [scrollToTask, state.uiHighlightedTask]);

  const isAdminRole = useMemo(isAdmin, []);
  const isRunbookAdminRole = useMemo(isRunbookAdmin, []);

  const usersById = useMemo(() => (
    mapObjectArrayByKey(state.users, "User_ID")
  ), [state.users]);

  const usersByWorkgroupId = useMemo(() => (
    state.workgroups?.reduce?.((accumulator, workgroup) => ({
      ...accumulator,
      [workgroup.Workgroup_ID]: usersById[workgroup.Admin_ID]
    }), {}) || {}
  ), [state.workgroups, usersById]);

  const dependencyTaskOptions = useMemo(() => (
    state.runbookTasks &&
    state.runbookTasks.map(task => ({
      label: task.Name,
      value: task.Task_ID,
      data: task
    }))
  ), [state.runbookTasks]);

  const dependencyIdsByTaskId = useMemo(() => (
    state.runbookTaskDependencies.reduce((accumulator, dependency) => {
      const taskDependencies = accumulator[dependency.Task_ID] || [];
      taskDependencies.push(dependency.Dependent_Task_ID);
      accumulator[dependency.Task_ID] = taskDependencies;
      return accumulator;
    }, {})
  ), [state.runbookTaskDependencies]);

  const phaseOptions = useMemo(() => (
    state.runbookPhases?.map?.(phase => ({
      value: phase.Phase_ID,
      label: phase.Name
    }))
  ), [state.runbookPhases]);

  const workgroupOptions = useMemo(() => (
    state.users && state.workgroups?.map?.(group => ({
      value: group.Workgroup_ID,
      label: group.Name
    }))
  ), [state.users, state.workgroups]);

  const workstreamOptions = useMemo(() => (
    state.workstreams?.map?.(stream => ({
      value: stream.Workstream_ID,
      label: stream.Name
    }))
  ), [state.workstreams]);

  const phaseOptionsById = useMemo(() => (
    mapObjectArrayByKey(phaseOptions, "value")
  ), [phaseOptions]);

  const workgroupOptionsById = useMemo(() => (
    mapObjectArrayByKey(workgroupOptions, "value")
  ), [workgroupOptions]);

  const workstreamOptionsById = useMemo(() => (
    mapObjectArrayByKey(workstreamOptions, "value")
  ), [workstreamOptions]);

  const handleAutosaveTask = useCallback(async (name, value, task) => {
    if (task[name] === value || (!task[name] && !value)) {
      return;
    }
    const response = await RunbookService.updateRunbookTask(
      task.Task_ID,
      { [name]: value }
    );
    dispatch({
      type: ACTION_REPLACE_RUNBOOK_TASK,
      payload: response.payload,
      meta: response.meta
    });
  }, [dispatch]);

  const handleAutosaveDependencies = useCallback(async (selectedOptions, task) => {
    const newIds = selectedOptions?.map?.(option => option.value) || [];
    const existingIds = dependencyIdsByTaskId[task.Task_ID];
    if ((existingIds?.length || 0) === (newIds?.length || 0)) {
      if (!existingIds?.length) {
        return;
      }
      const newIdsSet = new Set(newIds);
      const isMatchingIds = existingIds.every(id => newIdsSet.has(id));
      if (isMatchingIds) {
        return;
      }
    }
    const response = await RunbookService.batchUpdateTaskDependencies(
      task.Task_ID,
      newIds
    );
    if (response.payload.length) {
      dispatch({
        type: ACTION_REPLACE_RUNBOOK_TASK_DEPENDENCIES,
        payload: response.payload,
        meta: response.meta
      });
    } else {
      dispatch({
        type: ACTION_REMOVE_RUNBOOK_TASK_DEPENDENCIES,
        payload: task.Task_ID,
        meta: response.meta
      });
    }
  }, [dispatch, dependencyIdsByTaskId]);

  const tableHeaders = useMemo(() => {
    const headers = [...staticTableHeaders];
    if (isAdminRole || isRunbookAdminRole) {
      headers.push(
        createNodeHeaderParams("delete", " ", defaultButtonNodeOptions)
      );
    }
    return headers
  }, [isAdminRole, isRunbookAdminRole]);

  const tableRows = useMemo(() => {
    if (!state.runbookTasks || !phaseOptions || !workstreamOptions) {
      return [];
    }
    return state.runbookTasks.map(task => {
      const selectedPhaseLabel = phaseOptionsById[task.Phase_ID]?.label;
      const selectedWorkgroupLabel = (
        workgroupOptionsById[task.Workgroup_ID]?.label
      );
      const selectedWorkstreamLabel = (
        workstreamOptionsById[task.Workstream_ID]?.label
      );
      const selectedDependencies = (
        dependencyIdsByTaskId[task.Task_ID] &&
        dependencyTaskOptions.filter(option => (
          dependencyIdsByTaskId[task.Task_ID].includes(option.value)
        ))
      );
      const dependencyNames = !selectedDependencies ? "" : (
        selectedDependencies.map(({ Name }) => Name).join(", ")
      );
      const startDateDisplay = moment(task.Start_Date).format(
        DATETIME_DISPLAY_NUMERIC_LONG
      );
      const endDateDisplay = moment(task.End_Date).format(
        DATETIME_DISPLAY_NUMERIC_LONG
      );
      const completedDateDisplay = task.Status !== "Completed" ? "" : (
        moment(task.Status_Datetime).format(DATETIME_DISPLAY_NUMERIC_LONG)
      );
      return {
        taskId: createNodeCellParams(task.Task_ID, task.Task_ID, (
          <Box
            paddingTop="100%"
            marginTop="-100%"
            ref={
              state.uiHighlightedTask === task.Task_ID ?
                selectedRowRef :
                undefined
            }
            key={task.Task_ID}
          >
            {task.Task_ID}
          </Box>
        )),
        phase: createNodeCellParams(selectedPhaseLabel, selectedPhaseLabel, (
          <EditableSelectCell
            name="Phase_ID"
            defaultValue={task.Phase_ID}
            options={phaseOptions}
            onAutosave={(name, value) => (
              handleAutosaveTask(name, value, task)
            )}
          />
        )),
        workstream: createNodeCellParams(
          selectedWorkstreamLabel,
          selectedWorkstreamLabel,
          (
            <EditableSelectCell
              name="Workstream_ID"
              defaultValue={task.Workstream_ID}
              options={workstreamOptions}
              onAutosave={(name, value) => (
                handleAutosaveTask(name, value, task)
              )}
              align="center"
            />
          )
        ),
        name: createNodeCellParams(task.Name, task.Name, (
          <EditableTextCell
            name="Name"
            defaultValue={task.Name}
            onAutosave={(name, value) => (
              handleAutosaveTask(name, value, task)
            )}
            validations={[{
              type: VALIDATION_MAX_LENGTH,
              argument: 128,
              required: true
            }]}
          />
        )),
        dependencies: createNodeCellParams(dependencyNames, dependencyNames, (
          <EditableAutocompleteCell
            name="RunbookTaskDependencies"
            defaultValue={selectedDependencies}
            options={dependencyTaskOptions.filter(option => (
              option.value !== task.Task_ID
            ))}
            onAutosave={(_name, values) => (
              handleAutosaveDependencies(values, task)
            )}
            align="center"
            key={
              `dependencies-\
              ${dependencyTaskOptions.length}-\
              ${dependencyIdsByTaskId[task.Task_ID]?.length}`
            }
            multiple
          />
        )),
        description: createNodeCellParams(task.Description, task.Description, (
          <EditableTextCell
            name="Description"
            defaultValue={task.Description}
            onAutosave={(name, value) => (
              handleAutosaveTask(name, value, task)
            )}
          />
        )),
        workgroupAdminName: (
          usersByWorkgroupId[task.Workgroup_ID] ? (
            usersByWorkgroupId[task.Workgroup_ID]?.First_Name +
            ` ${usersByWorkgroupId[task.Workgroup_ID]?.Last_Name}`
          ) : ""
        ),
        workgroupName: createNodeCellParams(
          selectedWorkgroupLabel,
          selectedWorkgroupLabel,
          (
            <EditableSelectCell
              name="Workgroup_ID"
              defaultValue={task.Workgroup_ID}
              options={workgroupOptions}
              onAutosave={(name, value) => (
                handleAutosaveTask(name, value, task)
              )}
              required
            />
          )
        ),
        startDate: createNodeCellParams(task.Start_Date, startDateDisplay, (
          <EditableDatetimeCell
            name="Start_Date"
            defaultValue={task.Start_Date}
            onAutosave={(name, value) => (
              handleAutosaveTask(name, value, task)
            )}
            required
          />
        )),
        endDate: createNodeCellParams(task.End_Date, endDateDisplay, (
          <EditableDatetimeCell
            name="End_Date"
            defaultValue={task.End_Date}
            onAutosave={(name, value) => (
              handleAutosaveTask(name, value, task)
            )}
            required
          />
        )),
        milestone: createNodeCellParams(
          Number(task.Milestone),
          task.Milestone ? "Yes" : "No",
          (
            <EditableCheckboxCell
              name="Milestone"
              defaultValue={!!task.Milestone}
              onAutosave={(name, value) => (
                handleAutosaveTask(name, value, task)
              )}
            />
          )
        ),
        status: createNodeCellParams(task.Status, task.Status, (
          <EditableSelectCell
            name="Status"
            defaultValue={task.Status}
            options={STATUS_OPTIONS}
            onAutosave={(name, value) => (
              handleAutosaveTask(name, value, task)
            )}
          />
        )),
        statusCompleted: createNodeCellParams(
          task.Status === "Completed" ? task.Status_Datetime : "",
          completedDateDisplay,
          <nobr>{completedDateDisplay || "N/A"}</nobr>
        ),
        notes: createNodeCellParams(null, task.Notes, (
          <EditableTextCell
            name="Notes"
            defaultValue={task.Notes}
            onAutosave={(name, value) => (
              handleAutosaveTask(name, value, task)
            )}
          />
        )),
        delete: createNodeCellParams(null, null, (
          <DeleteIconButton
            onClick={() => setOpenDeleteTaskId(task.Task_ID)}
            center
          />
        )),
      };
    });
  }, [
    dependencyIdsByTaskId, dependencyTaskOptions, handleAutosaveDependencies,
    handleAutosaveTask, state.runbookTasks, state.uiHighlightedTask,
    phaseOptions, phaseOptionsById, usersByWorkgroupId, workgroupOptions,
    workgroupOptionsById, workstreamOptions, workstreamOptionsById
  ]);

  const findHighlightedRowsIndicies = useCallback(selectedTaskId => {
    if (!selectedTaskId) {
      return null;
    }
    return [
      tableRows.findIndex(row => (
        row.taskId.metadata.textValue === selectedTaskId
      ))
    ];
  }, [tableRows]);

  const tableOptions = useMemo(() => {
    const options = {
      ...staticTableOptions,
      pagination: tableRows.length >= PAGINATION_ROW_MINIMUM,
      rowsSelected: findHighlightedRowsIndicies(state.uiHighlightedTask),
      setRowProps: (row) => ({
        key: row[0].key
      })
    };
    if (!dynamicHeightBreakpoint) {
      options.tableBodyMaxHeight = null;
    }
    return options;
  }, [
    dynamicHeightBreakpoint,
    findHighlightedRowsIndicies,
    state.uiHighlightedTask,
    tableRows.length
  ]);

  return (
    <>
      <DataTable
        options={tableOptions}
        columns={tableHeaders}
        data={tableRows}
        headerSize="small"
      />
      <DeleteTaskModal
        dispatch={dispatch}
        openTaskId={openDeleteTaskId}
        setOpenTaskId={setOpenDeleteTaskId}
        runbookId={runbookId}
      />
    </>
  );
}
