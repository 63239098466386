import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LabelInput from "components/utils/form-elements/labelInput.component";
import LogoPicker from "components/utils/form-elements/logoPicker.component";
import {
  portCoDemoTypes,
  portCoDemoTypeIDMap,
} from "utils/portfolioConstants";
import { makeDemTypeFormNames } from "utils/portfolioDemoHelpers";
import { useCallback } from "react";
import Form from "components/utils/form-elements/form.component";
import PortfolioService from "services/portfolio.service";
import { replaceUnderscoresWithBlanks } from "utils/stringFuncs";
import {
  ACTION_SET_ANY,
  ACTION_ADD_PORTCO,
  ACTION_REPLACE_PORTCO,
  ACTION_REPLACE_PORTCO_DEMO,
  ACTION_DEMO_FORM_CHANGE,
} from "reducers/portfolioDemos.reducer";
import { MenuItem } from "@material-ui/core";
import CustomSelect from "components/utils/form-elements/select.component";
import Checkbox from "components/utils/form-elements/checkbox.component";
import FileService from "services/file.service";
import moment from "moment";
import { validEmail } from "utils/validators";
import { isHoldingRead } from "utils/roles";
import classNames from "classnames";
import { getUploadHttpErrorMessage } from "services/http-common";

const useStyles = makeStyles((theme) => ({
  defaultInputWrapper: {
    width: 480,
  },
  largeInputWrapper: {
    width: 480,
  },
  logoPicker: {
    paddingTop: 0,
  },
  checkboxesWrapper: {
    width: 240,
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 20,
  },
  formContainer: {
    marginTop: 14,
  }
}));

const validateFormError = (formState, name) => {
  const error = {};
  if (
    name === "Website" &&
    typeof formState.Website === "string" &&
    !formState.Website.includes(".")
  ) {
    error.Website = "The URL format is invalid.";
  } else if (
    name === "POC_Email" &&
    typeof formState.POC_Email === "string" &&
    !validEmail(formState.POC_Email)
  ) {
    error.POC_Email = "The Email format is invalid.";
  }
  return error;
};

const validatePortCoName = (trimmedVal, portCo, portCoList) => {
  const error = {};
  if (portCo.PortCo_ID && trimmedVal === "") {
    error.Name = "An edited Portfolio Company name can not be blank.";
  }

  if (portCoList.some((portCoItem) => portCoItem?.Name === trimmedVal)) {
    if (!portCo.PortCo_ID || (portCo.PortCo_ID && portCo.Name !== trimmedVal)) {
      error.Name = "This Portfolio Company name already exists.";
    }
  }
  return error;
};

const DefaultInput = ({ ...props }) => {
  const classes = useStyles();
  return (
    <div className={classes.defaultInputWrapper}>
      <LabelInput
        {...props}
        variant="default"
        margin="dense"
        readOnly={isHoldingRead()}
      />
    </div>
  );
};

const LargeInput = ({ ...props }) => {
  const classes = useStyles();
  return (
    <div className={classes.largeInputWrapper}>
      <LabelInput
        {...props}
        variant="default"
        margin="dense"
        multiline
        rows={4}
        readOnly={isHoldingRead()}
      />
    </div>
  );
};
const formEleNames = makeDemTypeFormNames();

const schedPriorityOptions = ["High", "Medium", "Low"];

const DemoSelect = ({ options, onChange, value, label, name, disabled }) => {
  const classes = useStyles();

  return (
    <div className={classes.defaultInputWrapper}>
      <CustomSelect
        label={label}
        //   placeholder="Select a Portfolio Company"
        value={value}
        onChange={onChange}
        name={name}
        test={`select-${name}`}
        disabled={disabled}
      >
        {options?.map((option) => (
          <MenuItem
            value={option}
            key={option}
            id={option}
            data-cy={`menu-${option}`}
          >
            {option}
          </MenuItem>
        ))}
      </CustomSelect>
    </div>
  );
};

const PortCoDemoForm = ({
  portCoList,
  selectedPortCo,
  portCoDemos,
  demoFormObj,
  holdingID,
  dispatch,
  demoFormErrors,
  switchPortCoDemo,
}) => {
  const classes = useStyles();
  const disableDemoFIelds = !selectedPortCo?.PortCo_ID;
  const isHoldingReadUser = isHoldingRead();
  const [logoUploadError, setLogoUploadError] = useState();

  useEffect(() => {
    setLogoUploadError()
  }, [selectedPortCo])

  const handleSubmitPortCo = useCallback(
    (name, value) =>
      (async function() {
        const trimmedVal = value?.trim();
        if ((!trimmedVal || trimmedVal === "") && !selectedPortCo.Name) {
          dispatch({
            type: ACTION_SET_ANY,
            payload: {
              demoFormErrors: { ...demoFormErrors, Name: null },
            },
          });
          return;
        }
        const formError = validatePortCoName(
          trimmedVal,
          selectedPortCo,
          portCoList
        );

        if (formError.Name) {
          dispatch({
            type: ACTION_SET_ANY,
            payload: {
              demoFormErrors: { ...demoFormErrors, Name: formError.Name },
            },
          });
          return;
        } else {
          dispatch({
            type: ACTION_SET_ANY,
            payload: {
              demoFormErrors: { ...demoFormErrors, Name: null },
            },
          });
        }

        const portCoID = selectedPortCo?.PortCo_ID || null;

        const data = {
          PortCo_ID: portCoID,
          Name: value,
        };
        const upsertPortCoRes = await PortfolioService.upsertPortCo(
          holdingID,
          data
        );

        if (portCoID) {
          dispatch({
            type: ACTION_REPLACE_PORTCO,
            payload: upsertPortCoRes.payload[0],
            demoFormErrors: { ...demoFormErrors, Name: null },
          });
        } else {
          dispatch({
            type: ACTION_ADD_PORTCO,
            payload: upsertPortCoRes.payload[0],
            demoFormErrors: { ...demoFormErrors, Name: null },
          });
          switchPortCoDemo(upsertPortCoRes.payload[0].PortCo_ID)
        }
      })(),
    [dispatch, holdingID, selectedPortCo, portCoList, demoFormErrors, switchPortCoDemo]
  );

  const handleSubmitPCDemo = useCallback(
    (name, value) =>
      (async function() {
        const formErrors = validateFormError(demoFormObj, name);
        if (formErrors[name]) {
          dispatch({
            type: ACTION_SET_ANY,
            payload: {
              demoFormErrors: { ...demoFormErrors, [name]: formErrors[name] },
            },
          });
          return;
        } else {
          dispatch({
            type: ACTION_SET_ANY,
            payload: {
              demoFormErrors: { ...demoFormErrors, [name]: null },
            },
          });
        }

        const demoToEdit = portCoDemos.find(
          (demo) => demo.Label === replaceUnderscoresWithBlanks(name)
        );
        let data;

        if (demoToEdit) {
          data = {
            PortCoDemo_ID: demoToEdit.PortCoDemo_ID,
            Title: value,
          };
        } else {
          data = {
            DemoType_ID: portCoDemoTypeIDMap[name],
            Title: value,
            Input_Date: moment().format("YYYY-MM-DD"),
          };
        }
        const upsertDemoRes = await PortfolioService.upsertPortCoDemoData(
          selectedPortCo.PortCo_ID,
          data
        );

        if (demoToEdit) {
          dispatch({
            type: ACTION_REPLACE_PORTCO_DEMO,
            payload: {
              portCoDemos: upsertDemoRes.payload[0],
              demoFormObj: { ...demoFormObj, [name]: value },
            },
          });
        } else {
          dispatch({
            type: ACTION_SET_ANY,
            payload: {
              portCoDemos: [...portCoDemos, ...upsertDemoRes.payload],
              demoFormObj: { ...demoFormObj, [name]: value },
            },
          });
        }
      })(),
    [
      dispatch,
      portCoDemos,
      selectedPortCo.PortCo_ID,
      demoFormObj,
      demoFormErrors,
    ]
  );

  const demoSelectChange = useCallback(
    (event) => {
      handleSubmitPCDemo(event.target.name, event.target.value);
    },
    [handleSubmitPCDemo]
  );

  const logoUpload = useCallback(
    (event) =>
      (async function() {
        const { files } = event.target;
        const file = files?.[0];

        if (!file) {
          return setLogoUploadError(
            "No file selected."
          );
        }
        if (file.size > 1000000) {
          setLogoUploadError(
            "The chosen image exceeds the maximum size of 1MB."
          );
        } else if (file.name.length > 45) {
          setLogoUploadError("The file name must be 45 characters or less.");
        } else {
          setLogoUploadError();
          // create POST object from form data
          const body = new FormData();
          body.append("file", file);
          try {
            const fileResponse = await FileService.uploadLogo(body);
            const logoID = fileResponse.data.payload.logoId;
            handleSubmitPCDemo("Logo", logoID);
          } catch (error) {
            const { status } = error.response || {};
            setLogoUploadError(getUploadHttpErrorMessage(status))
          }
        }
      })(),
    [handleSubmitPCDemo]
  );

  const inputChangeHandler = (event) => {
    dispatch({
      type: ACTION_DEMO_FORM_CHANGE,
      payload: {
        demoFormObj: { [event.target.name]: event.target.value },
      },
    });
  };

  const handleCheckbox = (event) => {
    handleSubmitPCDemo(event.target.name, event.target.checked);
  };

  return (
    <div className={classes.formContainer}>
      <Form>
        <div className={classNames(classes.defaultInputWrapper, classes.logoPicker)}>
          <LogoPicker
            label="Logo"
            sublabel="Size Limit: 1MB"
            disabled={disableDemoFIelds || isHoldingReadUser}
            logoId={demoFormObj?.Logo || ""}
            logoUpload={logoUpload}
            alt="Portfolio Company Logo"
            errorMessage={logoUploadError}
          />
        </div>
        <DefaultInput
          label="Name"
          value={demoFormObj?.Name || ""}
          onChange={inputChangeHandler}
          name="Name"
          onAutosave={handleSubmitPortCo}
          error={!!demoFormErrors?.Name}
          errorMessage={demoFormErrors?.Name}
        />
        <DefaultInput
          label="Website"
          value={demoFormObj?.[formEleNames.Website] || ""}
          onChange={inputChangeHandler}
          name={formEleNames.Website}
          onAutosave={handleSubmitPCDemo}
          disabled={disableDemoFIelds}
          error={!!demoFormErrors?.Website}
          errorMessage={demoFormErrors?.Website}
        />
        <DefaultInput
          label={portCoDemoTypes.Industry}
          value={demoFormObj?.[formEleNames.Industry] || ""}
          onChange={inputChangeHandler}
          disabled={disableDemoFIelds}
          name={formEleNames.Industry}
          onAutosave={handleSubmitPCDemo}
        />
        <LargeInput
          className={classes.largeInput}
          variant="default"
          label={portCoDemoTypes.Summary}
          value={demoFormObj?.[formEleNames.Summary] || ""}
          onChange={inputChangeHandler}
          multiline
          rows={4}
          disabled={disableDemoFIelds}
          name={formEleNames.Summary}
          onAutosave={handleSubmitPCDemo}
        />
        <DefaultInput
          label={portCoDemoTypes.Headquarters}
          value={demoFormObj?.[formEleNames.Headquarters] || ""}
          onChange={inputChangeHandler}
          disabled={disableDemoFIelds}
          name={formEleNames.Headquarters}
          onAutosave={handleSubmitPCDemo}
        />
        <DefaultInput
          label={portCoDemoTypes.Regions}
          value={demoFormObj?.[formEleNames.Regions] || ""}
          onChange={inputChangeHandler}
          disabled={disableDemoFIelds}
          name={formEleNames.Regions}
          onAutosave={handleSubmitPCDemo}
        />
        <DefaultInput
          label={portCoDemoTypes.Markets}
          value={demoFormObj?.[formEleNames.Markets] || ""}
          onChange={inputChangeHandler}
          disabled={disableDemoFIelds}
          name={formEleNames.Markets}
          onAutosave={handleSubmitPCDemo}
        />
        <div className={classes.checkboxesWrapper}>
          <Checkbox
            label={portCoDemoTypes.B2B}
            checked={[1, "1", true].includes(demoFormObj?.[formEleNames.B2B])}
            disabled={disableDemoFIelds || isHoldingReadUser}
            name={formEleNames.B2B}
            onChange={handleCheckbox}
          />

          <Checkbox
            label={portCoDemoTypes.B2C}
            checked={[1, "1", true].includes(demoFormObj?.[formEleNames.B2C])}
            disabled={disableDemoFIelds || isHoldingReadUser}
            name={formEleNames.B2C}
            onChange={handleCheckbox}
          />
        </div>
        <DefaultInput
          label={portCoDemoTypes.Ownership}
          value={demoFormObj?.[formEleNames.Ownership] || ""}
          onChange={inputChangeHandler}
          disabled={disableDemoFIelds}
          name={formEleNames.Ownership}
          onAutosave={handleSubmitPCDemo}
        />
        <DefaultInput
          label={portCoDemoTypes.Regulators}
          value={demoFormObj?.[formEleNames.Regulators] || ""}
          onChange={inputChangeHandler}
          disabled={disableDemoFIelds}
          name={formEleNames.Regulators}
          onAutosave={handleSubmitPCDemo}
        />
        <LargeInput
          label={portCoDemoTypes.Recent_News}
          value={demoFormObj?.[formEleNames.Recent_News] || ""}
          onChange={inputChangeHandler}
          disabled={disableDemoFIelds}
          name={formEleNames.Recent_News}
          onAutosave={handleSubmitPCDemo}
        />
        <LargeInput
          label={portCoDemoTypes.Breaches}
          value={demoFormObj?.[formEleNames.Breaches] || ""}
          onChange={inputChangeHandler}
          disabled={disableDemoFIelds}
          name={formEleNames.Breaches}
          onAutosave={handleSubmitPCDemo}
        />
        <DemoSelect
          options={schedPriorityOptions}
          label={portCoDemoTypes.Scheduling_Priority}
          name={formEleNames.Scheduling_Priority}
          onChange={demoSelectChange}
          value={demoFormObj?.[formEleNames.Scheduling_Priority] || ""}
          onAutosave={handleSubmitPCDemo}
          disabled={disableDemoFIelds}
        />
        <DefaultInput
          label={portCoDemoTypes.POC_Name}
          value={demoFormObj?.[formEleNames.POC_Name] || ""}
          onChange={inputChangeHandler}
          disabled={disableDemoFIelds}
          name={formEleNames.POC_Name}
          onAutosave={handleSubmitPCDemo}
        />
        <DefaultInput
          label={portCoDemoTypes.POC_Email}
          value={demoFormObj?.[formEleNames.POC_Email] || ""}
          onChange={inputChangeHandler}
          disabled={disableDemoFIelds}
          name={formEleNames.POC_Email}
          onAutosave={handleSubmitPCDemo}
          error={!!demoFormErrors?.POC_Email}
          errorMessage={demoFormErrors?.POC_Email}
        />
        <DefaultInput
          label={portCoDemoTypes.POC_Number}
          value={demoFormObj?.[formEleNames.POC_Number] || ""}
          onChange={inputChangeHandler}
          disabled={disableDemoFIelds}
          name={formEleNames.POC_Number}
          onAutosave={handleSubmitPCDemo}
        />
        <DefaultInput
          label={portCoDemoTypes.Internal_Team_Lead}
          value={demoFormObj?.[formEleNames.Internal_Team_Lead] || ""}
          onChange={inputChangeHandler}
          disabled={disableDemoFIelds}
          name={formEleNames.Internal_Team_Lead}
          onAutosave={handleSubmitPCDemo}
        />
        <LargeInput
          label={portCoDemoTypes.Notes}
          value={demoFormObj?.[formEleNames.Notes] || ""}
          onChange={inputChangeHandler}
          disabled={disableDemoFIelds}
          name={formEleNames.Notes}
          onAutosave={handleSubmitPCDemo}
        />
      </Form>
    </div>
  );
};

export default PortCoDemoForm;
