import React, { useState, useMemo, useCallback, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import CustomLink from "components/utils/link.component";
import { Box, Collapse, Divider, Paper } from "@material-ui/core";
import HeaderPrimary from "components/utils/header.component";
import MessageIcon from "@material-ui/icons/Message";
import { formattedMomentCustomError } from "utils/dateHelpers";
import moment from "moment";
import classNames from "classnames";
import CertificationService from "services/certification.service";
import { currentUserID } from "utils/userHelpers";
import {
  ACTION_ADD_SECTION_COMMENT,
  ACTION_REMOVE_COMMENT_REPLY,
  ACTION_REPLACE_SECTION_COMMENT,
  ACTION_RESOLVE_SECTION_COMMENT,
} from "reducers/certification/manuals.reducer";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import EditIconButton from "components/utils/editIconButton.component";
import CommentForm from "./commentForm.component";
import ButtonDefault from "components/utils/buttonDefault.component";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";

const useStyles = makeStyles((theme) => ({
  container: {
    width: 300,
    display: "flex",
    flexDirection: "column",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  infoBar: {
    display: "flex",
    justifyContent: "space-between",
    margin: 8,
    paddingBottom: 2,
    boxShadow:
      variables.bottomBoxShadow,
    "&:hover": {
      cursor: "pointer",
    },
  },
  titleHeader: {
    padding: 0,
  },
  comment: {
    margin: "8px 8px 16px",
    color: variables.textSecondary,
  },
  commentsBox: {
    margin: 4,
  },
  scrollableCommentsBox: {
    maxHeight: 400,
    overflowY: "scroll"
  },
  newFormBox: {
    padding: "8px 16px",
  },
  bottomBoxShadow: {
    boxShadow:
    variables.bottomBoxShadow,
  },
  replyCount: {
    fontSize: variables.fontSmall,
    fontWeight: "bold",
    alignSelf: "flex-end",
  },
  commentCount: {
    fontSize: variables.fontMedium,
    fontWeight: "bold",
  },
  gray: {
    color: variables.tertiary1,
  },
  teal: {
    color: variables.tertiary2,
  },
  commentControls: {
    display: "flex",
    flexDirection: "column",
  },
  controlLink: {
    marginRight: 16,
  },
  replyInputWrapper: {
    marginLeft: 16,
  },
  repliesListBox: {
    margin: "0px 8px 0px",
  },
  arrowIcon: {
    color: variables.primaryMain,
    marginLeft: 8,
  },
  repliesDivider: {
    margin: "8px 0px",
  },
  commentsDivider: {
    backgroundColor: variables.tertiary2,
    marginBottom: 8,
    padding: 1,
    "&:not(:first-of-type)": {
      marginTop: 12,
    },
  },
  date: {
    fontWeight: "bold",
  },
  fullName: {
    fontWeight: "bold",
  },
  commentTextBox: {
    border: `1px solid ${variables.graySecondary}`,
    borderRadius: 5,
    padding: 4,
  },
  replyText: {
    margin: "8px 0px",
  },
  deleteIcon: {
    fontSize: variables.fontH3,
    marginRight: 16,
  },
}));

const makeCountText = (count, singularTerm, pluralTerm) => {
  if (!count) {
    return `0 ${pluralTerm}`;
  } else if (count === 1) {
    return `1 ${singularTerm}`;
  }
  return `${count} ${pluralTerm}`;
};

const CommentsSection = ({ section, comments, dispatch, limitedHeight }) => {
  const classes = useStyles();
  const [commentsSectionIsOpen, setCommentsSectionIsOpen] = useState();
  const [activeReplyForm, setActiveReplyForm] = useState(null);
  const [activeEditForm, setActiveEditForm] = useState(null);
  const inputRef = useRef(null);
  const userId = useMemo(() => currentUserID(), []);

  const reset = () => {
    setCommentsSectionIsOpen(false);
    setActiveReplyForm(null);
    setActiveEditForm(null);
  };

  const toggleCommentsSection = useCallback(() => {
    if (commentsSectionIsOpen) {
      reset();
    } else {
      setCommentsSectionIsOpen(true);
    }
  }, [commentsSectionIsOpen]);

  const clearCommentForm = () => {
    inputRef.current.value = "";
  };

  const openEditForm = (commentId) => {
    setActiveEditForm(commentId);
  };

  const closeEditForm = () => {
    setActiveEditForm();
  };

  const saveNewComment = useCallback(
    async (data) => {
      const comment = `${data?.new_comment || ""}`?.trim?.();
      if (comment === "") {
        return;
      }
      const createData = {
        Content: comment,
        User_ID: userId,
      };
      const createRes = await CertificationService.createManualSectionComment(
        section.ManualSection_ID,
        createData
      );
      dispatch({
        type: ACTION_ADD_SECTION_COMMENT,
        payload: createRes.payload,
      });
      inputRef.current.value = "";
    },
    [dispatch, section.ManualSection_ID, userId]
  );

  const saveEditedComment = useCallback(
    async (data, commentId) => {
      const updateRes = await CertificationService.updateManualSectionComment(
        commentId,
        { Content: data.edit_comment }
      );
      dispatch({
        type: ACTION_REPLACE_SECTION_COMMENT,
        payload: updateRes.payload,
      });
      setActiveEditForm(false);
    },
    [dispatch]
  );

  const saveReplyComment = useCallback(
    async (data, commentId) => {
      const updateRes = await CertificationService.updateManualSectionComment(
        commentId,
        { Content: data.edit_reply }
      );
      dispatch({
        type: ACTION_REPLACE_SECTION_COMMENT,
        payload: updateRes.payload,
      });
      setActiveEditForm(false);
    },
    [dispatch]
  );

  const openNewReplyForm = (commentId) => {
    setActiveReplyForm(commentId);
  };
  const closeReplyForm = () => {
    setActiveReplyForm(null);
  };

  const saveNewReply = async (data, parenId) => {
    if (data.reply?.trim() === "") {
      setActiveReplyForm(null);
      return;
    }
    const createData = {
      Parent_ManualSectionComment_ID: parenId,
      Content: data.reply,
      User_ID: userId,
    };
    const createRes = await CertificationService.createManualSectionComment(
      section.ManualSection_ID,
      createData
    );
    dispatch({ type: ACTION_ADD_SECTION_COMMENT, payload: createRes.payload });
    setActiveReplyForm(null);
  };

  const resolveComment = useCallback(
    async (commentId) => {
      await CertificationService.resolveManualSectionComment(commentId);
      dispatch({ type: ACTION_RESOLVE_SECTION_COMMENT, payload: commentId });
    },
    [dispatch]
  );

  const deleteReply = useCallback(
    async (commentId) => {
      await CertificationService.removeManualSectionComment(commentId);
      dispatch({ type: ACTION_REMOVE_COMMENT_REPLY, payload: commentId });
    },
    [dispatch]
  );

  return (
    <Paper className={classes.container} >
      <div
        className={classes.infoBar}
        onClick={toggleCommentsSection}
        role="button"
        tabIndex="0"
        data-cy="comment-infoBar"
      >
        <Box display="flex" flexDirection="column">
          <HeaderPrimary
            variant="h5Primary"
            className={classes.titleHeader}
            noTransform
          >
            {`${section.Header}`}
          </HeaderPrimary>
          <div
            className={classNames(
              classes.commentCount,
              comments?.length > 0 ? classes.teal : classes.gray
            )}
          >
            {makeCountText(comments?.length, "comment", "comments")}
          </div>

        </Box>
        {commentsSectionIsOpen ? (
          <ExpandMoreIcon
            className={classes.arrowIcon}
            fontSize="medium"
          />
        ) : (
          <NavigateNextIcon
            className={classes.arrowIcon}
            fontSize="medium"
          />
        )}
      </div>
      <Collapse in={commentsSectionIsOpen} unmountOnExit>
        <div className={classNames(classes.newFormBox, limitedHeight && classes.bottomBoxShadow)}>
          <CommentForm
            formName="new-comment-form"
            onSubmit={saveNewComment}
            inputName="new_comment"
            defaultValue=""
            cancelOnClick={clearCommentForm}
            placeholder="Add Comment"
            inputRef={inputRef}
          />
        </div>
        <div className={classes.contentWrapper}>
          <div className={classNames(classes.commentsBox, limitedHeight && classes.scrollableCommentsBox)}>
            {comments?.map((comment) => {
              const replies = comment?._associations?.ManualSectionComment;
              return (
                <Comment
                  comment={comment}
                  closeReplyForm={closeReplyForm}
                  activeReplyForm={activeReplyForm}
                  key={comment.ManualSectionComment_ID}
                  saveNewReply={saveNewReply}
                  openNewReplyForm={openNewReplyForm}
                  resolveComment={resolveComment}
                  replies={replies}
                  openEditForm={openEditForm}
                  closeEditForm={closeEditForm}
                  activeEditForm={activeEditForm}
                  userId={userId}
                  saveEditedComment={saveEditedComment}
                  saveReplyComment={saveReplyComment}
                  deleteReply={deleteReply}
                />
              );
            })}
          </div>
        </div>
      </Collapse>
    </Paper>
  );
};

const CommentHeader = ({ comment }) => {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="space-between">
      <div className={classes.fullName}>{comment.Commenter_Name}</div>
      <div className={classes.date}>
        {formattedMomentCustomError(
          moment(comment.Change_Date).format("MM/DD/YYYY")
        )}
      </div>
    </Box>
  );
};

const Comment = ({
  comment,
  replies,
  saveNewReply,
  openNewReplyForm,
  closeReplyForm,
  activeReplyForm,
  resolveComment,
  openEditForm,
  closeEditForm,
  activeEditForm,
  saveReplyComment,
  userId,
  saveEditedComment,
  deleteReply,
}) => {
  const classes = useStyles();
  const commentId = comment.ManualSectionComment_ID;
  return (
    <div className={classes.comment}>
      <Divider className={classes.commentsDivider} />
      <Box display="flex" flexDirection="column">
        <CommentHeader comment={comment} />
        <div
          className={classNames(
            classes.replyCount,
            replies?.length > 0 ? classes.teal : classes.gray
          )}
        >
          {makeCountText(replies?.length, "Reply", "Replies")}
        </div>
      </Box>
      <Box margin={1}>
        {activeEditForm === commentId ? (
          <CommentForm
            formName="edit-comment-form"
            onSubmit={(data) => saveEditedComment(data, commentId)}
            inputName="edit_comment"
            defaultValue={comment?.Content}
            cancelOnClick={closeEditForm}
          />
        ) : (
          <Box className={classes.commentTextBox}>{comment.Content}</Box>
        )}
        <Box marginTop={1} className={classes.commentControls}>
          {activeEditForm !== commentId && activeReplyForm !== commentId && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" margin={0.4}>
                <div className={classes.controlLink}>
                  <CustomLink
                    variant="iconBeforeLink"
                    onClick={() => resolveComment(commentId)}
                    startIcon={<DoneIcon fontSize="small" />}
                  >
                    Resolve
                  </CustomLink>
                </div>
                <div className={classes.controlLink}>
                  <CustomLink
                    variant="iconBeforeLink"
                    onClick={() => openNewReplyForm(commentId)}
                    startIcon={<MessageIcon fontSize="small" />}
                  >
                    Reply
                  </CustomLink>
                </div>
              </Box>

              {userId === comment?.User_ID && (
                <EditIconButton
                  onClick={() => openEditForm(commentId)}
                  noPadding
                />
              )}
            </Box>
          )}

          <Collapse in={activeReplyForm === commentId} unmountOnExit>
            <div className={classes.replyInputWrapper}>
              <CommentForm
                formName="reply-form"
                onSubmit={(data) => saveNewReply(data, commentId)}
                inputName="reply"
                defaultValue=""
                cancelOnClick={closeReplyForm}
              />
            </div>
          </Collapse>
          <div className={classes.repliesListBox}>
            {replies?.map((reply, index) => {
              const replyId = reply.ManualSectionComment_ID;
              return (
                <Box display="flex" flexDirection="column" key={replyId}>
                  {index !== 0 && <Divider className={classes.repliesDivider} />}
                  <CommentHeader comment={reply} />
                  {activeEditForm !== replyId ? (
                    <>
                      <Box
                        className={classNames(
                          classes.commentTextBox,
                          classes.replyText
                        )}
                      >
                        {reply.Content}
                      </Box>
                      {userId === reply?.User_ID && (
                        <Box display="flex" justifyContent="flex-end">
                          <div>
                            <ButtonDefault
                              onClick={() => deleteReply(replyId)}
                              variant="iconSmall"
                              color="red"
                              startIcon={
                                <DeleteIcon className={classes.deleteIcon} />
                              }
                              disableReadOnlyUsers
                            >
                              Delete
                            </ButtonDefault>
                          </div>

                          <EditIconButton
                            onClick={() => openEditForm(replyId)}
                            noPadding
                          />
                        </Box>
                      )}
                    </>
                  ) : (
                    <CommentForm
                      formName="edit-reply-form"
                      onSubmit={(data) => saveReplyComment(data, replyId)}
                      inputName="edit_reply"
                      defaultValue={reply?.Content}
                      cancelOnClick={closeEditForm}
                    />
                  )}
                </Box>
              );
            })}
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default CommentsSection;
