import moment from "moment";

const formattedMomentCustomError = (formattedDate, customMessage) => {
  if (formattedDate === "Invalid date") {
    return customMessage || "No Date Provided";
  }
  return formattedDate
};

const defaultChangeDate = () => {
  return moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
}

export { formattedMomentCustomError, defaultChangeDate };
