import React from "react";
import CustomContainer from "components/utils/container.component.js";
import { Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BarLoader from "react-spinners/BarLoader";
import variables from "styleVariables";
import HeaderPrimary from "components/utils/header.component";

/**
 * Use with usePortalEntry hook.
 */

const useStyles = makeStyles((theme) => ({
  messageWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 240px)",
    color: variables.primaryMain,
  },
  header: {
    paddingBottom: 20
  }
}));

const EnteringPortal = (props) => {
  const classes = useStyles();
  const { companyInfo } = props;
  if (!companyInfo) {
    return null;
  }
  return (
    <CustomContainer>
      <Toolbar />
      <div
        className={classes.messageWrapper}
        data-cy={`portal-entry-${companyInfo?.Name}`}
      >
        <HeaderPrimary variant="h3Primary" className={classes.header}>
          Entering{companyInfo.Name ? ` ${companyInfo.Name}` : ""} Portal
        </HeaderPrimary>
        <BarLoader height={6} color={variables.secondaryLight} />
      </div>
    </CustomContainer>
  );
};

export default EnteringPortal;
