import { makeStyles } from "@material-ui/core";
import CustomLink from "components/utils/link.component";
import { useCallback, useMemo } from "react";

const useStyles = makeStyles(() => ({
  linkWrapper: {
    textAlign: "center",
  },
  leftAlignedLinkWrapper: {
    textAlign: "left",
  },
}));


export default function LOCDetailLinkCell(props) {
  const classes = useStyles();
  const { align, cellProps, label, onClick } = props;

  const alignmentClasses = useMemo(() => (
    align === "left"
      ? classes.leftAlignedLinkWrapper
      : classes.linkWrapper
  ), [align, classes]);

  const handleClick = useCallback(() => {
    onClick(cellProps)
  }, [cellProps, onClick]);

  return (
    <div className={alignmentClasses}>
      <CustomLink
        variant="customUnderline"
        onClick={handleClick}
        ignoreReadOnly
      >
        {label || "--"}
      </CustomLink>
    </div>
  );
}
