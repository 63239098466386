import { makeStyles } from "@material-ui/core";
import { AddCircleOutlineOutlined } from "@material-ui/icons";
import CustomLink from "components/utils/link.component";
import { useCallback } from "react";
import { ACTION_CREATE_MANUAL_SECTION, ACTION_CREATE_MANUAL_SUBSECTION, ACTION_CREATE_MANUAL_NESTED_SUBSECTION} from "reducers/certification/manuals.reducer";
import certificationService from "services/certification.service";
import { manualSectionTypes } from "utils/certificationConstants";

const useStyles = makeStyles(theme => ({
  addSectionWrapper: {
    marginTop: 8
  },
  addSectionIcon: {
    margin: "0 5px 0 0",
    height: 24,
  }
}));

const defaultHeaderByType = {
  [manualSectionTypes.MAIN_SECTION]: "New Section",
  [manualSectionTypes.SUBSECTION]: "New Subsection",
  [manualSectionTypes.NESTED_SUBSECTION]: "New Subsection",
};

const reducerBySectionType = {
  [manualSectionTypes.MAIN_SECTION]: ACTION_CREATE_MANUAL_SECTION,
  [manualSectionTypes.SUBSECTION]: ACTION_CREATE_MANUAL_SUBSECTION,
  [manualSectionTypes.NESTED_SUBSECTION]: ACTION_CREATE_MANUAL_NESTED_SUBSECTION,
};

const linkbarVariantByType = {
  [manualSectionTypes.MAIN_SECTION]: "linkBar",
  [manualSectionTypes.SUBSECTION]:"linkBarTeal",
  [manualSectionTypes.NESTED_SUBSECTION]: "linkBarGray",
};

// Button to add Section or SubSection
export default function AddSectionButton(props) {
  const classes = useStyles();
  const { dispatch, parentSection, prevOrder, sectionType, test } = props;

  const handleCreateSection = useCallback(async () => {
    const data = {
      Content: "",
      Header: defaultHeaderByType[sectionType],
      Manual_ID: parentSection.Manual_ID,
      ManualSection_Order: prevOrder,
      Parent_ManualSection_ID: parentSection.ManualSection_ID,
      Auto_Number: parentSection.Auto_Number === 1,
    };
    const sectionResponse = await certificationService.createSection(
      data,
      parentSection.Manual_ID
    );
    dispatch({
      type: reducerBySectionType[sectionType],
      payload: sectionResponse.payload,
      meta: sectionResponse.meta
    });
  }, [dispatch, sectionType, prevOrder, parentSection]);

  return (
    <div className={classes.addSectionWrapper}>
      <CustomLink
        onClick={handleCreateSection}
        variant={linkbarVariantByType[sectionType]}
        startIcon={(
          <AddCircleOutlineOutlined className={classes.addSectionIcon} />
        )}
        test={test}
      >
        Add {sectionType === manualSectionTypes.MAIN_SECTION ? "Section" : "Subsection"}
      </CustomLink>
    </div>
  );
}
