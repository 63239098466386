export const certChecklistTypes = {
  PRE_APPLICATION: "PreApp",
  FORMAL_APPLICATION: "FormalApp",
};

export const checklistTypeByCompObj = {
  282: certChecklistTypes.PRE_APPLICATION
}

export const checklistTypeByProgComp = {
  97: certChecklistTypes.PRE_APPLICATION,
  103: certChecklistTypes.FORMAL_APPLICATION,
}