import React, { useContext, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  accordionIDMaker,
  assessSecondColumnDisplay,
} from "utils/assessmentHelpers";
import variables from "styleVariables";
import { sortByStringKey } from "utils/sortingFuncs";
import CheckListTable from "components/assessment/checkListTable.component";
import ProgramsContext from "contexts/programs.context";
import { PROGRAM_ID_ORCHESTRATION } from "utils/programConstants";
import { isHtml } from "utils/stringFuncs";
import DisplayHTML from "components/utils/displayHTML.component";

/**
 * Currently there are 4 possibilities of nesting via this display
 * The parent component to this - <SelectedAccordionView /> displays the Program Component Accordion ie Charter, Controls
 * All Controls are placed into a SubGroup folder
 * If the Builder is for the Enterprise Level, the folders are: Program > Assessment Item  || Program > Control Subgroup > AssessmentItem
 * Otherwise, it is:  Assessment Item  || Control Subgroup > AssessmentItem
 *
 */

const useStyles = makeStyles((theme) => ({
  mainContent: {
    backgroundColor: "white",
    padding: 10,
    paddingLeft: 20,
    borderRadius: 5,
    boxShadow: "inset 0px 1px 4px -2px rgba(0,0,0,0.89)",
  },
  listView: {
    display: "flex",
    flexDirection: "column",
    color: variables.textSecondary,
    paddingTop: 5,
    paddingBottom: 5,
    margin: 0,
    paddingLeft: 20,
  },
  accordionProgramGroupLabel: {
    color: variables.tertiary2Dark,
    fontSize: variables.fontSmall,
  },
  assessListItem: {
    fontSize: variables.fontSmall,
  },
  assessListItemLarge: {
    fontSize: variables.fontLarge,
    paddingBottom: 10,
  },
  addChecklistRow: {
    fontSize: variables.fontMedium,
    display: "flex",
    alignItems: "center",
  },
  errorMessage: {
    color: theme.palette.text.error,
    fontSize: 14,
    fontWeight: 600,
  },
  assessListItemMedium: {
    paddingBottom: 10,
  }
}));
const makeAssessControlFrameworkLabel = (item => `${item.Name} - ${item.Framework_Version}`)
const whiteSpaceAndPeriodMatcher = /[\s,.]/g;

const SelectedAssessAccordionContent = ({
  scopedAssessItems,
  sourceObj,
  programID,
  checkListData,
  showChecklist,
  assessmentType,
  addChecklistItem,
  setCheckListData,
  removeCheckItem,
  editCheckItem,
  editObj,
  editCheckListHandler,
  activeInput,
  setActiveInput,
  formErrors,
  handleEnterKey,
  handleSave,
  isSavingChecklistItems
}) => {
  const classes = useStyles();
  const progCompName = sourceObj.Name;
  const { state } = useContext(ProgramsContext);

  const isOrchestrationProgram = useMemo(() => (
    programID === PROGRAM_ID_ORCHESTRATION && progCompName !== "Custom"
  ), [programID, progCompName]);

  const checklistProps = {
    showChecklist,
    assessmentType,
    addChecklistItem,
    checkListData,
    setCheckListData,
    removeCheckItem,
    editCheckItem,
    editObj,
    editCheckListHandler,
    activeInput,
    setActiveInput,
    formErrors,
    handleEnterKey,
    handleSave,
    isSavingChecklistItems
  };

  const nonControlScopedAssessItems = scopedAssessItems.filter(item => item.ProgramComponent_Name !== "Controls")
  const controlScopedAssessItems = scopedAssessItems.filter(item => item.ProgramComponent_Name === "Controls")

  const programFolders = useMemo(() => {
    if (!isOrchestrationProgram) {
      return [];
    }
    const progFolderValues = nonControlScopedAssessItems.map((item) => {
      const parsed = JSON.parse(item.AssessmentItem);
      return parsed.Program_Program_ID || parsed.Program_ID;
    });
    const uniqueProgs = [...new Set(progFolderValues)];
    const programObjs = uniqueProgs.map((prog) => {
      const programId = parseInt(prog, 10);
      const progName = PROGRAM_ID_ORCHESTRATION === programId
        ? "Enterprise Level"
        : `${state.programs[programId].Name} Program`;
      return { Program_ID: prog, Name: progName };
    });
    return sortByStringKey(programObjs, "Name");
  }, [nonControlScopedAssessItems, isOrchestrationProgram, state.programs]);

  const controlsFolders = useMemo(() => {
    const folderValues = controlScopedAssessItems.map((item) => {
      const parsed = JSON.parse(item.AssessmentItem);
      return makeAssessControlFrameworkLabel(parsed)
    })
    const uniqueGroups = [...new Set(folderValues)];
    const programObjs = uniqueGroups.map((frameworkGroup) => {
      return { id: frameworkGroup.replaceAll(whiteSpaceAndPeriodMatcher, "_"), Name: frameworkGroup };
    });
    return sortByStringKey(programObjs, "Name");
  }, [controlScopedAssessItems]);

  if (progCompName === "Controls") {
    return (
      <div>
        <div className={classes.mainContent}>
          {controlsFolders.map((folder) => (
            <ControlsGroupFolder
              data={controlScopedAssessItems}
              checklistProps={checklistProps}
              formErrors={formErrors}
              folderInfo={folder}
              key={folder.Name}
            />
          ))}
        </div>
      </div>
    );
  }
  if (isOrchestrationProgram && progCompName !== "Programs") {
    return (
      <div className={classes.mainContent}>
        {programFolders.map((prog) => (
          <ProgramGroupFolder
            data={nonControlScopedAssessItems}
            checklistProps={checklistProps}
            formErrors={formErrors}
            progCompName={progCompName}
            programInfo={prog}
            key={prog.Name}
          />
        ))}
      </div>
    );
  }
  return (
    <div className={classes.mainContent}>
      <ListView
        assessItems={scopedAssessItems}
        checklistProps={checklistProps}
        formErrors={formErrors}
      />
    </div>
  );
};

export default SelectedAssessAccordionContent;



const ProgramGroupFolder = (props) => {
  const classes = useStyles();

  const {
    data, checklistProps, formErrors, progCompName, programInfo
  } = props;

  const programName = programInfo.Name;
  const scopedData = data.filter((item) => {
    const parsedItem = JSON.parse(item.AssessmentItem);
    return (
      parsedItem.Program_ID === programInfo.Program_ID ||
      parsedItem.Program_Program_ID === programInfo.Program_ID
    );
  });
  const accordionID = accordionIDMaker(progCompName, programName);

  return (
    <div className={classes.listView} id={`${accordionID}-content`}>
      <div className={classes.accordionProgramGroupLabel}>
        {programName}
      </div>
      <ListView
        assessItems={scopedData}
        checklistProps={checklistProps}
        formErrors={formErrors}
      />
    </div>
  );
};

const ControlsGroupFolder = (props) => {
  const classes = useStyles();
  const { data, checklistProps, formErrors, folderInfo } = props;

  const controlGroupName = folderInfo.Name;
  const scopedData = data.filter((item) => {
    const parsedItem = JSON.parse(item.AssessmentItem);
    return makeAssessControlFrameworkLabel(parsedItem) === folderInfo.Name;
  });

  return (
    <div className={classes.listView} id={`${folderInfo.id}-content`}>
      <div className={classes.accordionProgramGroupLabel}>
        {controlGroupName}
      </div>
      <ListView
        assessItems={scopedData}
        checklistProps={checklistProps}
        formErrors={formErrors}
      />
    </div>
  );
};

const ListView = props => {
  const classes = useStyles();
  const {
    assessItems,
    checklistProps,
    formErrors,
  } = props;

  const {
    showChecklist,
    assessmentType,
    addChecklistItem,
    checkListData,
    setCheckListData,
    removeCheckItem,
    editCheckItem,
    editObj,
    editCheckListHandler,
    activeInput,
    setActiveInput,
    handleEnterKey,
    handleSave,
    isSavingChecklistItems
  } = checklistProps;

  const isReadOnly = !addChecklistItem;

  const checklistItemIdSet = useMemo(() => (
    new Set((checkListData || []).map(item => item.AssessmentItem_ID))
  ), [checkListData]);

  return (
    <ul className={classes.listView}>
      {assessItems.map((assessItem) => {
        const displayData = assessSecondColumnDisplay(
          assessItem.AssessmentItem,
          assessItem.ProgramComponent_Name,
          "simple"
        );
        const display = isHtml(displayData) ? <DisplayHTML html={displayData} unstyled /> : displayData;
        const assessmentItemId = assessItem.AssessmentItem_ID;
        if (showChecklist) {
          if (!isReadOnly) {
            const checklistItems = checkListData.filter(data =>
              data.AssessmentItem_ID === assessItem.AssessmentItem_ID
            );
            const error = formErrors && checklistItems.map(item => (
              formErrors[`checklist-item-${item.ChecklistItem_ID}`]
            ))[0];
            return (
              <li
                className={classes.assessListItemLarge}
                data-cy={`checklist-item-${assessItem.Foreign_Ref}`}
                key={assessmentItemId}
              >
                <div className={classes.addChecklistRow}>{display}</div>
                <CheckListTable
                  assessItem={assessItem}
                  addItem={addChecklistItem}
                  checkListData={checkListData}
                  setCheckListData={setCheckListData}
                  removeCheckItem={removeCheckItem}
                  editCheckItem={editCheckItem}
                  editObj={editObj}
                  editCheckListHandler={editCheckListHandler}
                  assessmentType={assessmentType}
                  checkItems={checklistItems}
                  activeInput={activeInput}
                  setActiveInput={setActiveInput}
                  formErrors={formErrors}
                  handleEnterKey={handleEnterKey}
                  onSave={handleSave}
                  isSavingChecklistItems={isSavingChecklistItems}
                />
                {!!error && (
                  <p
                    className={classes.errorMessage}
                    data-cy={`checklist-error-${assessItem.AssessmentItem}`}
                  >
                    {error}
                  </p>
                )}
              </li>
            );
          }
          if (checklistItemIdSet.has(assessmentItemId)) {
            return (
              <li
                className={classes.assessListItemMedium}
                data-cy={`checklist-item-${assessItem.Foreign_Ref}`}
                key={assessmentItemId}
              >
                {display}
                <CheckListTable
                  readOnlyView
                  assessItem={assessItem}
                  checkListData={checkListData}
                  assessmentType={assessmentType}
                  checkItems={checkListData.filter(data => (
                    data.AssessmentItem_ID === assessmentItemId
                  ))}
                />
              </li>
            );
          }
        }
        return (
          <li
            className={
              showChecklist ?
                classes.assessListItemMedium :
                classes.assessListItem
            }
            data-cy={`accordion-option-${displayData}`}
            key={assessmentItemId}
          >
            {display}
          </li>
        );
      })}
    </ul>
  );
};
