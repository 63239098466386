/*
 * All constants corresponding to the `CFR` table
 */

export const CFR_TYPE_PART = "Part";
export const CFR_TYPE_SUBPART = "Subpart";
export const CFR_TYPE_SECTION = "Section";
export const CFR_TYPE_PARAGRAPH = "Paragraph";

export const CFR_TYPES = [
  CFR_TYPE_PART,
  CFR_TYPE_SUBPART,
  CFR_TYPE_SECTION,
  CFR_TYPE_PARAGRAPH
];
