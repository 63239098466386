import { Box, makeStyles } from "@material-ui/core";
import CustomModal from "components/utils/modal.component";
import useNumericParams from "hooks/useNumericParams";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import moment from "moment";
import { generatePath } from "react-router-dom/cjs/react-router-dom.min";
import trainingService from "services/training.service";
import TrainingModule from "./trainingModule.component";
import BackToLink from "components/utils/backToLink.component";
import { getEnabledComponentObjects } from "../utils/trainingPageUtils";
import { currentUserInfo } from "utils/userHelpers";
import trainingAssignmentsReducer, {
  ACTION_SET_ASSIGNMENTS,
  assignmentsInitialState,
} from "reducers/trainingAssignments.reducer";
import { useReducer } from "react";
import { AssignmentLateOutlined } from "@material-ui/icons";
import Loader from "components/utils/loader.components";
import MyTrainingAssignmentsTable from "./table/myTrainingAssignmentsTable.component";
import DialogPop from "components/utils/dialog.component";
import IconH2 from "components/utils/iconH2.component";
import ProgramsContext from "contexts/programs.context";
import { PATH_PROGRAM_COMPONENT } from "components/layouts/constants/routes.constants";
import DocumentType from "components/layouts/comp-types/documentType.component";
import classNames from "classnames";
import variables from "styleVariables";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";

const useStyles = makeStyles((theme) => ({
  modalInner: {
    borderRadius: 0,
  },
  // Policy/Procedure Tables
  tableTitle: {
    fontSize: variables.fontLarge,
    fontWeight: "bold",
    paddingBottom: 15,
  },
  tertiary1: {
    color: variables.tertiary1,
  },
  documentColor: {
    color: variables.tertiary3,
  },
}));

// TODO: REMOVE THESE WHEN REFACTORED TO CARD DISPLAY
const orchCurrentPoliciesCompObj = {
  ComponentObject_ID: 39,
  Type: "Document",
  TypeRef: "Current",
  Name: "Current Policies",
};

const orchCurrentProcCompObj = {
  ComponentObject_ID: 40,
  Type: "Document",
  TypeRef: "Current",
  Name: "Current Procedures",
};

const TrainingModulesPage = () => {
  const classes = useStyles();
  const { state: programState } = useContext(ProgramsContext);
  const [selectedAssignmentId, setSelectedAssignmentId] = useState();
  const { programId, programComponentId } = useNumericParams();
  const [state, dispatch] = useReducer(
    trainingAssignmentsReducer,
    assignmentsInitialState
  );
  const [confirmationActive, setConfirmationActive] = useState(false);

  const currentUser = useMemo(currentUserInfo, []);

  const today = useMemo(moment, []);

  const componentObjects = useMemo(
    () => programState.componentObjectsByComponent?.[programComponentId],
    [programState.componentObjectsByComponent, programComponentId]
  );

  const backToPath = useMemo(() => {
    const enabled = getEnabledComponentObjects(componentObjects, currentUser);
    if (enabled?.length > 1) {
      return generatePath(PATH_PROGRAM_COMPONENT, {
        programId,
        programComponentId,
      });
    }
    return null;
  }, [componentObjects, currentUser, programId, programComponentId]);

  useEffect(() => {
    const fetchRequiredData = async () => {
      const trainingResponse =
        await trainingService.getUserTrainingAssignmentsByProgramId(programId);
      dispatch({
        type: ACTION_SET_ASSIGNMENTS,
        payload: trainingResponse.payload,
      });
    };
    fetchRequiredData();
  }, [currentUser, programId]);

  const closeModule = useCallback(
    () => setSelectedAssignmentId(null),
    [setSelectedAssignmentId]
  );
  const confirmExitModule = useCallback(
    () => setConfirmationActive(true),
    [setConfirmationActive]
  );

  const [dueModules, completeModules] = useMemo(() => {
    const due = [];
    const completed = [];
    for (const assignment of state?.assignments || []) {
      if (assignment.End_Date) {
        completed.push(assignment);
      } else if (
        assignment.Open_Date &&
        !today.isBefore(assignment.Open_Date)
      ) {
        due.push(assignment);
      }
    }
    due.sort(
      (m1, m2) =>
        moment(m1.CompleteBy_Date).valueOf() -
          moment(m2.CompleteBy_Date).valueOf() ||
        moment(m1.Start_Date).valueOf() - moment(m2.Start_Date).valueOf()
    );
    completed.sort(
      (m1, m2) => moment(m1.End_Date).valueOf() - moment(m2.End_Date).valueOf()
    );
    return [due, completed];
  }, [state?.assignments, today]);

  const selectedAssignment = useMemo(
    () =>
      state?.assignments?.find?.(
        (assignment) =>
          assignment.TrainingAssignment_ID === selectedAssignmentId
      ),
    [selectedAssignmentId, state?.assignments]
  );

  if (!state?.assignments) {
    return <Loader />;
  }
  return (
    <div>
      {!!backToPath && <BackToLink href={backToPath} parentPage="Training" />}
      <GridContainer spacing={5}>

        {/*------ LEFT SIDE -------*/}
        <GridItem sm={12} md={7}>
          <Box marginTop={3} marginBottom={6}>
            <IconH2 IconComponent={AssignmentLateOutlined} color="primary">
              Modules To Complete
            </IconH2>
            <MyTrainingAssignmentsTable
              modules={dueModules}
              setSelectedAssignmentId={setSelectedAssignmentId}
            />
          </Box>

          <Box marginBottom={2}>
            <IconH2 color="tertiary">Past Completed Modules</IconH2>
            <MyTrainingAssignmentsTable
              modules={completeModules}
              setSelectedAssignmentId={setSelectedAssignmentId}
              isCompletedModules
            />
          </Box>
        </GridItem>

        {/*------ Right SIDE -------*/}
        <GridItem sm={12} md={5}>
          <Box marginTop={5}>
            <DocumentType
              componentObject={orchCurrentPoliciesCompObj}
              tempFromTrainingProp
              tableTitle={
                <div
                  className={classNames(
                    classes.tableTitle,
                    orchCurrentPoliciesCompObj.Name.startsWith("Previous")
                      ? classes.tertiary1
                      : classes.documentColor
                  )}
                >
                  {orchCurrentPoliciesCompObj.Name}
                </div>
              }
            />
          </Box>
          <Box marginTop={4} marginBottom={2}>
            <DocumentType
              componentObject={orchCurrentProcCompObj}
              tempFromTrainingProp
              tableTitle={
                <div
                  className={classNames(
                    classes.tableTitle,
                    orchCurrentProcCompObj.Name.startsWith("Previous")
                      ? classes.tertiary1
                      : classes.documentColor
                  )}
                >
                  {orchCurrentProcCompObj.Name}
                </div>
              }
            />
          </Box>
        </GridItem>
      </GridContainer>

      <DialogPop
        openDialog={confirmationActive}
        confirm={() => {
          setConfirmationActive(false);
          closeModule();
        }}
        setOpenDialog={setConfirmationActive}
        prompt={`Are you sure you'd like to leave? All progress will be lost.`}
      />
      <CustomModal
        open={selectedAssignment}
        onClose={closeModule}
        className={classes.modalInner}
      >
        <TrainingModule
          assignment={selectedAssignment}
          closeModule={confirmExitModule}
          dispatch={dispatch}
          setSelectedAssignmentId={setSelectedAssignmentId}
          completeModule={closeModule}
        />
      </CustomModal>
    </div>
  );
};

export default TrainingModulesPage;
