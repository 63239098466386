import React, { useEffect, useState } from "react";
import { Grid, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DocumentControlTable from "components/types/documentControlTable.component";
import { downloadFile } from "utils/downloadFile";
import variables from "styleVariables";
import { registry as Services } from "services/registry.service"
import { useMemo } from "react";
import moment from "moment";

const useStyles = makeStyles({
  link: {
    color: variables.secondaryDark,

    "&:hover": {
      color: variables.primaryMain,
      cursor: "pointer",
    },
  },
  reviewDateListItem: {
    paddingBottom: 4
  },
  reviewDateList: {
    paddingLeft: 0,
    marginLeft: 14
  }
});

const DocumentType = (props) => {
  const classes = useStyles();
  const [allCompFiles, setAllCompFiles] = useState();

  const isCurrentProgramDoc = props.componentObject.TypeRef === "Current" && props.componentObject.Nav_Icon !== "Implementation-tools"

  useEffect(() => {
    let isSubscribed = true;
    const getAllCompFiles = async (componentObjectId) => {
      const response = await Services.fileGetAllByComponentObjectId(componentObjectId)
      if(isSubscribed) {
        setAllCompFiles(response.payload)
      }
    };
    getAllCompFiles(props.componentObject.ComponentObject_ID);
    return () => {
      isSubscribed = false;
    };
  }, [props.componentObject.ComponentObject_ID, classes.link]);

  const tableCells = useMemo(() => {
    if(!allCompFiles?.length) {
      return []
    }
    return allCompFiles.map((row) => {
      return {
        DocID: row.ProgramDoc_ID,
        key: row.ProgramDoc_ID,
        DocName: row.Title,
        Title: (
          <Link
            className={classes.link}
            onClick={() =>
              downloadFile(
                row.ProgramDoc_ID,
                row.File_Ref
              )}
          >
            {row.Title}
          </Link>
        ),
        Description: row.Description,
        Version: row.Version,
        PublishDate: row.PublishDate,
        Publisher: row.Publisher,
        Status: row.Status,
        File_Name: row.File_Ref,
        Review_Date: (
          <ul
            className={classes.reviewDateList}
          >
            {row._associations.ReviewHistory.map((reviewData) => (
              <li
                key={reviewData.ProgramDocReviewHistory_ID}
                className={classes.reviewDateListItem}
              >
                {moment(reviewData.Review_Date).format("M/D/YYYY")}
              </li>
            ))}
          </ul>
        ),
        Builder_ID: row.Builder_Builder_ID,
        ComponentObject_ID: row.ComponentObjects_ComponentObject_ID
      }
    })
  }, [allCompFiles, classes.link, classes.reviewDateListItem, classes.reviewDateList])

  if (tableCells) {
    return (
      <Grid item sm={12} className={classes.tableContainer}>
        <DocumentControlTable
          variant={
            props.componentObject.Name.includes("Previous") ? "previous" : null
          }
          data={tableCells}
          title="Documents"
          tableTitle={props.tableTitle}
          isCurrentProgramDoc={isCurrentProgramDoc}
          setAllCompFiles={setAllCompFiles}
          allCompFiles={allCompFiles}
          tempFromTrainingProp={props.tempFromTrainingProp} // TODO: Remove
        />
      </Grid>
    );
  } else {
    return ""
  }
};

export default DocumentType;
