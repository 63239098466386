import { Tabs } from "@material-ui/core";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

// Container for Mui Tabs where each tab can be dragged to reorder them.
export function DraggableTabs(props) {
  const { children, droppableId, onDragStart, onDragEnd, ...otherProps } = props;
  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId} direction="horizontal" ignoreContainerClipping>
        {provided => (
          <Tabs
            ref={provided.innerRef}
            {...provided.droppableProps}
            {...otherProps}
          >
            {children}
            {provided.placeholder}
          </Tabs>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DraggableTabs;
