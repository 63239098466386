import http from "./http-common";
import authHeader from "./authHeader";

class ImplementationService {

    // get List of Program OrganizationGroups
  getProgramOrganizationGroups(programId) {
    return http
      .get(`implementation/organizationGroups/${programId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

    // get List of Organization Group Products
  getOrganizationGroupProducts(orgGroupId) {
    return http
      .get(`implementation/organizationGroupProducts/${orgGroupId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

    // get List of GroupProduct Interfaces
  getGroupProductInterfaces(productId) {
    return http
      .get(`implementation/groupProductInterfaces/${productId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  createInterface(interfaceItem) {
    return http
      .post(`/implementation/interfaces/`, interfaceItem, { headers: authHeader() })
      .then(response => response.data);
  }

  deleteInterface(interfaceId) {
    return http
      .delete(`/implementation/interfaces/${interfaceId}`, { headers: authHeader() })
      .then(response => response.data);
  }

  createDataLocation(location) {
    return http
      .post(`/implementation/dataLocation/`, location, { headers: authHeader() })
      .then(response => response);
  }

  deleteDataLocation(locationId) {
    return http
      .delete(`/implementation/dataLocation/${locationId}`, { headers: authHeader() })
      .then(response => response.data);
  }

    // get List of GroupProduct Locations
  getGroupProductLocations(productId) {
    return http
      .get(`implementation/groupProductLocations/${productId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get List of Program Management Principles
  getManagementPrinciples(programId) {
    return http
      .get(`/implementation/managementPrinciples/${programId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

   // get List of GroupProduct DataMaps
  getGroupProductDataMaps(productId) {
    return http
      .get(`/implementation/groupProductDataMaps/${productId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get Product Data Map
  getProductDataMap(productId) {
    return http
      .get(`/implementation/dataMap/${productId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get Product Data Scope
  getProductDataScope(productId, programId) {
    return http
      .get(`/implementation/dataScope/${productId}/${programId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  batchUpsertDataMap(groupProductId, datamapList) {
    return http
      .post(
        `/implementation/groupProduct/${groupProductId}/dataMap/batchUpsert`,
        datamapList,
        { headers: authHeader() }
      )
      .then(response => response);
  }

  // get List of GroupProducts
  getGroupProducts(programId) {
    return http
      .get(`/implementation/groupProducts/${programId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get Implementation Data Scope
  getImplementationDataScope(programId) {
    return http
      .get(`/implementation/impDataScope/${programId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get List of SubProducts
  getImplementationSubProducts() {
    return http
      .get(`/implementation/impSubProducts`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get organization group by productId
  getOrgGroupByProductId(productId) {
    return http
      .get(`/implementation/orgGroup/${productId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }
}

export default new ImplementationService();
