import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Paper, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Link from "components/utils/link.component";
import CustomIcons from "components/utils/icons.component";
import RunbookService from "services/runbook.service";
import { truncate } from "utils/workPackageHelpers";
import variables from "styleVariables";
import classNames from "classnames";
import { generatePath } from "react-router-dom/cjs/react-router-dom.min";
import useNumericParams from "hooks/useNumericParams";
import { COMPONENT_ID_RUNBOOK_VIEW } from "utils/programConstants";
import moment from "moment";
import { severityLevels } from "utils/runbookConstants";
import { setStateFetchEffect } from "utils/ajaxHelpers";

const useStyles = makeStyles((theme) => ({
  rb: {
    minWidth: 321,
    padding: 20,
    color: variables.textSecondary,
  },
  rbIcon: {
    display: "flex",
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  boxIcon: {
    maxHeight: 45,
    width: 40,
  },
  //  active - in progress
  activeIP: {
    color: variables.secondaryMain,
    fill: variables.secondaryMain,
  },
  //  active - not in progress (upcoming)
  activeNIP: {
    color: variables.tertiary2,
    fill: variables.tertiary2,
  },
  //  active - overdue
  activeOD: {
    color: variables.warningMain,
    fill: variables.warningMain,
  },
  //  inactive
  inactive: {
    color: variables.grayDisabledText,
    fill: variables.grayDisabled,
  },
  rbLinkWrapper: {
    border: "1px solid transparent",
    "&:hover": {
      "& $rbName": {
        color: variables.primaryMain,
      },
      "& $iconDefaultColor": {
        fill: variables.secondaryLight,
      },
      "& $iconOverdueColor": {
        fill: variables.warningLight,
      },
    },
  },
  rbName: {
    padding: 0,
    margin: 0,
  },
  rbTitleWrapper: {
    display: "flex",
    paddingBottom: 10,
    "&:hover": {
      textDecoration: "none",
      "& $rbName": {
        textDecoration: "underline",
      },
    },
  },
  countsBox: {
    display: "flex",
    padding: "8px 5px 0px 5px",
    flexFlow: "column",
  },
  infoCountWrapper: {
    color: variables.textSecondary,
    display: "flex",
    flexFlow: "column",
  },
  itemCount: {
    fontSize: variables.fontMedium,
    padding: "4px 0px",
  },
  label: {
    fontWeight: "bold",
    marginRight: 4,
  },
  subHeaderBox: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "fit-content",
    flexDirection: "column",
  },
  date: {
    color: variables.textSecondary,
    fontSize: variables.fontMedium,
  },
  statusText: {
    fontWeight: "bold",
    fontSize: variables.fontSmall,
    paddingTop: 1,
  },
  incidentsSection: {
    display: "flex",
    flexDirection: "column",
    padding: "4px 5px 5px",
  },
  severityRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 6,
    paddingRight: 10,
    fontSize: variables.fontSmall,
  },
  severityLabel: {
    fontWeight: "bold",
    paddingRight: 4,
  },
  colorCritical: {
    color: variables.warningLight,
  },
  colorHigh: {
    color: variables.statusAlertDark,
  },
  colorMedium: {
    color: variables.statusCautionDark,
  },
  colorLow: {
    color: variables.statusGreen,
  },
  verticalDivider: {
    padding: 1,
    margin: "0px 8px",
    height: 12,
  },
  noIncidents: {
    alignSelf: "center",
    minHeight: 43,
    display: "flex",
    alignItems: "flex-end",
  },
  completed: {
    paddingLeft: 4,
  },
  rbHeaderGroup: {
    display: "flex",
    flexFlow: "column",
    padding: "0px 15px 0px 20px",
  }
}));

const Runbook = ({ runbook }) => {
  const classes = useStyles();
  const params = useNumericParams();

  const [openIncidents, setOpenIncidents] = useState([]);
  const [phases, setPhases] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [openTasks, setOpenTasks] = useState([]);

  const [workstreams, setWorkstreams] = useState([]);
  const today = moment().format("YYYY-MM-DD");

  const runbookStatus = (currentRunbook, retClass = false) => {
    if (currentRunbook.Status === "Inactive") {
      return retClass ? classes.inactive : "Inactive"
    } else if (currentRunbook.Start_Date > today && currentRunbook.End_Date > today) {
      return retClass ? classes.activeNIP : "Upcoming"
    } else if (currentRunbook.Start_Date <= today && currentRunbook.End_Date >= today) {
      return retClass ? classes.activeIP : "In Progress"
    } else{
      return retClass ? classes.activeOD : "Overdue"
    }
  };

  const openIncidentsCountBySeverity = useMemo(() => {
    return openIncidents.reduce((accumulator, incident) => {
      if (accumulator[incident.Severity]) {
        accumulator[incident.Severity] = accumulator[incident.Severity] + 1;
      } else {
        accumulator[incident.Severity] = 1;
      }
      return accumulator;
    }, {});
  }, [openIncidents]);

  useEffect(() => {
    return setStateFetchEffect([
      RunbookService.getActiveIncidentsByRunbook(runbook.Runbook_ID),
      RunbookService.getPhasesByRunbook(runbook.Runbook_ID),
      RunbookService.getTasksByRunbook(runbook.Runbook_ID),
      RunbookService.getOpenTasksByRunbook(runbook.Runbook_ID),
      RunbookService.getWorkstreamsByRunbook(runbook.Runbook_ID),
    ], ([incidentResponse, phaseResponse, taskResponse, openTaskResponse, workstreamResponse]) => {
      setOpenIncidents(incidentResponse.payload);
      setPhases(phaseResponse.payload);
      setTasks(taskResponse.payload);
      setOpenTasks(openTaskResponse.payload);
      setWorkstreams(workstreamResponse.payload);
    });
  }, [runbook]);

  const runbookPath = useMemo(
    () =>
      generatePath(
        `/program/:programId/${COMPONENT_ID_RUNBOOK_VIEW}/runbook/:runbookId`,
        { ...params, runbookId: runbook.Runbook_ID }
      ),
    [params, runbook]
  );

  return (
    <Paper className={classNames(classes.rb, classes.paper)} data-cy="runbook-card">
      <div
        data-cy={`wrapper-rbId-${runbook.Runbook_ID}`}
        className={classes.rbLinkWrapper}
      >
        <Link
          variant="routerLink"
          className={classes.rbTitleWrapper}
          to={runbookPath}
          test={`dash-rb-${runbook.Name}`}
        >
          <div
            className={classNames(classes.rbIcon)}
            aria-labelledby="runbook-initials"
          >
            <CustomIcons variant="Runbook" className={classNames(classes.boxIcon, runbookStatus(runbook, true), openIncidents.length > 0 ? classes.iconOverdueColor : classes.iconDefaultColor)} />
          </div>
          <div className={classes.rbHeaderGroup}>
            <Typography variant="h4" className={classNames(classes.rbName, runbookStatus(runbook, true), openIncidents.length > 0 ? classes.iconOverdueColor : classes.iconDefaultColor)}>
              {truncate(runbook.Name)}
            </Typography>
            <div className={classes.subHeaderBox}>
              <DatesDisplay
                startDate={runbook.Start_Date}
                endDate={runbook.End_Date}
                isOverdue={runbook.End_Date < today}
                className={classes.dateText}
              />
              <div className={classNames(classes.statusText, runbookStatus(runbook, true))}>{runbookStatus(runbook)}</div>
            </div>
          </div>
        </Link>
        <Divider />
      </div>

      <div
        className={classes.infoCountWrapper}
        data-cy={`rbProgress-${runbook.Name}`}
      >
        <div className={classes.countsBox}>
          {tasks.length > 0 && (
            <Link
              variant="routerLink"
              to={runbookPath}
              test={`${runbook.Runbook_ID}-Tasks`}
            >
              <ItemCountLinkDisplay
                label="Tasks"
                value={(
                  <span>
                    {tasks.length} Tasks
                    <span className={classes.completed}>
                      ({tasks.length - openTasks.length} Completed)
                    </span>
                  </span>
                )}
              />
            </Link>
          )}
          {workstreams.length > 0 && (
            <Link
              variant="routerLink"
              to={runbookPath}
              test={`${runbook.Runbook_ID}-Workstreams`}
            >
              <ItemCountLinkDisplay
                label="Workstreams"
                value={workstreams.length}
              />
            </Link>
          )}
          {phases.length > 0 && (
            <Link
              variant="routerLink"
              to={runbookPath}
              test={`${runbook.Runbook_ID}-Phases`}
            >
              <ItemCountLinkDisplay label="Phases" value={phases.length} />
            </Link>
          )}
        </div>
        <div className={classes.incidentsSection}>
          {openIncidents.length > 0 ? (
            <>
              <Link
                variant="routerLink"
                to={runbookPath}
                test={`${runbook.Runbook_ID}-Phases`}
              >
                <span className={classes.label}>Open Incidents:</span>
              </Link>
              <SeverityCountRow countMap={openIncidentsCountBySeverity} />
            </>
          ) : (
            <span className={classes.noIncidents}> No open incidents</span>
          )}
        </div>
      </div>
    </Paper>
  );
};

const SeverityCountRow = ({ countMap }) => {
  const classes = useStyles();
  return (
    <div className={classes.severityRow}>
      {[
        severityLevels.SEVERITY_CRITICAL,
        severityLevels.SEVERITY_HIGH,
        severityLevels.SEVERITY_MEDIUM,
        severityLevels.SEVERITY_LOW,
      ].map((level) => (
        <Fragment key={level}>
          <div>
            <span
              className={classNames(
                classes.severityLabel,
                level === "Critical" && classes.colorCritical,
                level === "High" && classes.colorHigh,
                level === "Medium" && classes.colorMedium,
                level === "Low" && classes.colorLow,
              )}
            >
              {level}:
            </span>
            {`${countMap[level] || 0}`}
          </div>
          {level !== severityLevels.SEVERITY_LOW && (
            <Divider
              orientation="vertical"
              className={classes.verticalDivider}
            />
          )}
        </Fragment>
      ))}
    </div>
  );
};

const ItemCountLinkDisplay = ({ label, value }) => {
  const classes = useStyles();
  return (
    <div className={classes.itemCount}>
      <span className={classes.label}>{label}:</span>
      {value}
    </div>
  );
};

const DatesDisplay = ({ startDate, endDate, isOverdue }) => {
  const classes = useStyles();
  const startDateDisplay = startDate
    ? moment(startDate).format("M/D/YYYY")
    : "No Start Date";
  const endDateDisplay = endDate
    ? moment(endDate).format("M/D/YYYY")
    : "No End Date";

  return (
    <span
      className={classNames(classes.date, isOverdue && classes.overdueDate)}
    >
      {startDateDisplay} - {endDateDisplay}
    </span>
  );
};

export default Runbook;
