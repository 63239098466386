import { PATH_COMPONENT_OBJECT, PATH_PORTFOLIO_COMPONENT_OBJECT, PATH_PORTFOLIO_PROGRAM, PATH_PORTFOLIO_PROGRAM_COMPONENT, PATH_PORTFOLIO_PROGRAM_COMPONENT_PORTCO, PATH_PORTFOLIO_PROGRAM_PORTCO, PATH_PROGRAM, PATH_PROGRAM_COMPONENT } from "components/layouts/constants/routes.constants";
import { generatePath } from "react-router-dom/cjs/react-router-dom.min";
import { PROGRAM_ID_PORTFOLIO } from "utils/programConstants";

export function addHolderToPath(pathParams, state, holdingId) {
  const combinedParams = {
    ...pathParams,
    programId: PROGRAM_ID_PORTFOLIO,
    holdingId
  };
  if (pathParams.componentObjectId) {
    return generatePath(PATH_PORTFOLIO_COMPONENT_OBJECT, combinedParams);
  }
  if (pathParams.programComponentId) {
    return generatePath(PATH_PORTFOLIO_PROGRAM_COMPONENT, combinedParams);
  }
  const components = state.programComponentsByProgram[PROGRAM_ID_PORTFOLIO];
  const dashboardComponent = components?.find?.(({ Type }) => Type === "Dash");
  if (dashboardComponent) {
    return generatePath(PATH_PORTFOLIO_PROGRAM_COMPONENT, {
      ...combinedParams,
      programComponentId: dashboardComponent.Component_ID
    });
  }
  return generatePath(PATH_PORTFOLIO_PROGRAM, combinedParams);
}

export function removeHolderFromPath(pathParams) {
  const combinedParams = {
    ...pathParams,
    programId: PROGRAM_ID_PORTFOLIO,
  };
  if (pathParams.componentObjectId) {
    return generatePath(PATH_COMPONENT_OBJECT, combinedParams);
  }
  if (pathParams.programComponentId) {
    return generatePath(PATH_PROGRAM_COMPONENT, combinedParams);
  }
  return generatePath(PATH_PROGRAM, combinedParams);
}

export function addPortCoToPath(pathParams, state, portCoId) {
  const combinedParams = {
    ...pathParams,
    programId: PROGRAM_ID_PORTFOLIO,
    portCoId
  };
  if (pathParams.programComponentId) {
    return generatePath(PATH_PORTFOLIO_PROGRAM_COMPONENT_PORTCO, combinedParams);
  }
  const components = state.programComponentsByProgram[PROGRAM_ID_PORTFOLIO];
  const dashboardComponent = components?.find?.(({ Type }) => Type === "Dash");
  if (dashboardComponent) {
    return generatePath(PATH_PORTFOLIO_PROGRAM_COMPONENT_PORTCO, {
      ...combinedParams,
      programComponentId: dashboardComponent.Component_ID
    });
  }
  return generatePath(PATH_PORTFOLIO_PROGRAM_PORTCO, combinedParams);
}

export function removePortCoFromPath(pathParams) {
  const combinedParams = {
    ...pathParams,
    programId: PROGRAM_ID_PORTFOLIO,
  };
  if (pathParams.holdingId) {
    if (pathParams.programComponentId) {
      return generatePath(PATH_PORTFOLIO_PROGRAM_COMPONENT, combinedParams);
    }
    return generatePath(PATH_PORTFOLIO_PROGRAM, combinedParams);
  } else {
    if (pathParams.programComponentId) {
      return generatePath(PATH_PROGRAM_COMPONENT, combinedParams);
    }
    return generatePath(PATH_PROGRAM, combinedParams);
  }
}
