import React from "react";
import {
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import variables from "styleVariables";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "components/utils/form-elements/formLabel.component";
import CustomFormHelperText from "components/utils/form-elements/styled/formHelperText/customFormHelperText.component"
import styleVariables from "styleVariables";
import Tooltip from "../tooltip.component";

const useStyles = makeStyles((theme) => ({
  labelText: {
    fontSize: variables.fontLarge,
    padding: "0px 20px 0px 6px",
  },
  radioRootClass: {
    color: `${styleVariables.tertiary2}!important`,
    padding: 0,
  },
  radioDisabledClass: {
    opacity: 0.7
  },
  radioLabelClass: {
    color: variables.textSecondary,
    marginLeft: 0,
  }
}));

const RadioInputGroup = ({
  groupLabel,
  defaultValue,
  options,
  onChange,
  radioGroupClass,
  radioLabelClass,
  radioLabelTextClass,
  value,
  row,
  name,
  error,
  errorMessage,
  helperText,
  hideHelperText,
}) => {
  const classes = useStyles();
  return (
    <FormControl component="fieldset">
      {!!groupLabel && (
        <FormLabel label={groupLabel} variant="default" error={error} />
      )}
      <RadioGroup
        aria-label={groupLabel}
        defaultValue={defaultValue}
        value={value}
        name={name}
        className={radioGroupClass}
        onChange={onChange}
        row={!!row}
      >
        {options.map((item) => {
          return (
            <Tooltip
              title={item.tooltip}
              key={item.value}
            >
              <FormControlLabel
                className={classNames(classes.radioLabelClass, radioLabelClass)}
                classes={{
                  label: classNames(classes.labelText, radioLabelTextClass),
                }}
                value={item.value}
                control={
                  <Radio
                    size="small"
                    classes={{
                      // label: classes.radioLabel,
                      root: classes.radioRootClass,
                      disabled: classes.radioDisabledClass,
                    }}
                  />
                }
                label={item.label}
                disabled={item.disabled}
                key={item.value}
                data-cy={`radio-option-${item.label}`}
                name={item.name}
              />
            </Tooltip>
          );
        })}
      </RadioGroup>
      {!hideHelperText && (
        <CustomFormHelperText error={error}>
          {errorMessage || helperText}
        </CustomFormHelperText>
      )}
    </FormControl>
  );
};
export default RadioInputGroup;
