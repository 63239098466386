import { H4 } from "components/utils/headerV2.component";
import Loader from "components/utils/loader.components";
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ACTION_SET_UI_HIGHLIGHTED_ROW } from "reducers/runbook/oneRunbook.reducer";
import AssignedTasksTable from "../shared/tables/assignedTasksTable.component";


export default function AssignedTasksPanel(props) {
  const { state, dispatch } = props;
  const location = useLocation();

  useEffect(() => {
    if (location?.state) {
      dispatch({
        type: ACTION_SET_UI_HIGHLIGHTED_ROW,
        payload: location.state.taskId,
      });
    }
  }, [dispatch, location?.state]);

  const handleTaskClick = useCallback(task => {
    dispatch({ type: ACTION_SET_UI_HIGHLIGHTED_ROW, payload: task.Task_ID });
  }, [dispatch]);

  if (
    !state.runbookTasks ||
    !state.workgroupMembers ||
    !state.workstreamMembers
  ) {
    return (
      <Loader height={200} />
    );
  }
  return (
    <div>
      <H4 color="primary" withMargin>Your Tasks</H4>
      <AssignedTasksTable
        onTaskClick={handleTaskClick}
        runbookTasks={state.runbookTasks}
        workgroupMembers={state.workgroupMembers}
        workstreamMembers={state.workstreamMembers}
      />
    </div>
  );
}
