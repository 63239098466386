import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect, useState } from "react";
import AssessmentBuilder from "components/assessment/assessmentBuilder.component";
import { Dialog, Fade } from "@material-ui/core";
import PageHeader from "components/utils/pageHeader.component";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { assessmentBuilderModes as modes } from "utils/assessmentConstants";
import { currentUserInfo } from "utils/userHelpers";
import BackToLink from "components/utils/backToLink.component";
import useNumericParams from "hooks/useNumericParams";
const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    overflowY: "hidden",
  },
  dialogWithAppBar: {
    margin: 0,
    height: `calc(100% - ${theme.layout.height.appBar}px)`,
    overflow: "none"
  },
  dialogContainer: {
    cursor: "pointer"
  },
  dialogContent: {
    cursor: "default"
  },
  pageContainer: {
    height: "100%",
    maxHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  wrapLongText: {
    wordBreak: "break-word"
  },
  header: {
    padding: "0 30px",
  },
  stickyHeader: {
    positon: "sticky",
    top: 0,
    paddingLeft: 30,
    paddingTop: 30,
    paddingBottom: 20,
    height: 140,
  },
  readOnlyStickyHeader: {
    height: 200,
    marginRight: 30,
  }
}));

const MODES_ALL = Object.values(modes);
const APP_BAR_MODES = [modes.MODE_EDIT, modes.MODE_REPORT];
const SETUP_MODES = [modes.MODE_LOAD_TEMPLATE, modes.MODE_EDIT]

const AssessmentBuilderEntry = (props) => {

  const classes = useStyles()
  const [builderInfo, setBuilderInfo] = useState();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false); // TODO: REMOVE AFTER AUTOSAVE IS FULLY IMPLEMENTED
  const [version, setVersion] = useState("");
  const user = currentUserInfo();
  const history = useHistory();
  const params = useNumericParams();
  const {programId, programComponentId, componentObjectId} = params;

  useEffect(() => {
    if (props.mode === modes.MODE_LOAD_TEMPLATE) {
      const newAssessmentBuilder = {
        Program_ID: props.chosenProgram?.id || programId,
        Title: "",
        Description: "",
        Version: null,
        Start_Date: "",
        End_Date: "",
        Step: 0,
        Review_Type: null,
        Status: "Draft",
        Change_User: null,
        Change_Date: "",
      };
      setBuilderInfo(newAssessmentBuilder);
      setVersion("0");
    } else if (props.builder) {
      setBuilderInfo(props.builder); // this obj includes program name and icon. not sure why
      setVersion(props.builder.Version);
    }
  }, [props.builder, props.type, user.id, props.chosenProgram?.id, props.mode, programId]);

  // TODO: REMOVE AFTER AUTOSAVE IS FULLY IMPLEMENTED
  const withUnsavedChanges = updateState => (...args) => {
    updateState(...args);
    setHasUnsavedChanges(true);
  };

  const handleExit = useCallback(() => {
    const program = programId;
    const programComponent = programComponentId;
    const object = componentObjectId;
    history.push(`/program/${program}/${programComponent}/${object}`);
  }, [history, programId, programComponentId, componentObjectId]);

  const isOwnPage = props.mode !== modes.MODE_REPORT;

  if (builderInfo && Object.keys(builderInfo).length) {
    const builderContent = (
      <AssessmentBuilder
        builderInfo={builderInfo}
        setBuilderInfo={setBuilderInfo}
        mode={props.mode}
        setMode={props.setMode}
        hasUnsavedChanges={hasUnsavedChanges}
        setHasUnsavedChanges={setHasUnsavedChanges}
        version={version} //Not needed?
        chosenProgramId={props.chosenProgram.id}
        readOnly={props.readOnly}
        withUnsavedChanges={withUnsavedChanges}
      />
    );
    if (isOwnPage) {
      return (
        <Dialog
          fullScreen
          open={MODES_ALL.includes(props.mode)}
          PaperProps={{ classes: { root: SETUP_MODES.includes(props.mode) && classes.dialogPaper } }}
          className={classNames(
            classes.dialog,
            classes.wrapLongText,
            !props.readOnly && APP_BAR_MODES.includes(props.mode) && classes.dialogWithAppBar
          )}
        >
          <div className={classes.pageContainer}>
            <div
              className={classNames(
                classes.header,
                classes.stickyHeader,
                (props.readOnly ? classes.readOnlyStickyHeader : undefined)
              )}
            >
              <PageHeader title={props.pageTitle} />
              {!!props.readOnly && (
                <Alert severity="info" data-cy="alert-builder-readOnly">
                  You are in Read-Only mode
                </Alert>
              )}
              <BackToLink
                dense
                text="Return to Assessment Builder Summary"
                onClick={handleExit}
                variant="subLink"
              />
            </div>
            {builderContent}
          </div>
        </Dialog>
      );
    }
    return (
      <Dialog
        open={MODES_ALL.includes(props.mode)}
        onClose={handleExit}
        maxWidth="md"
        fullWidth
        TransitionComponent={Fade}
        className={classNames(classes.dialogContainer)}
        PaperProps={{ className: classNames(classes.dialogContent) }}
      >
        {builderContent}
      </Dialog>
    );
  } else {
    return "";
  }
};

export default AssessmentBuilderEntry;
