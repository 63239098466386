import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LabelInput from "components/utils/form-elements/labelInput.component";
import Checkbox from "components/utils/form-elements/checkbox.component";
import AuthService from "services/auth.service";
import variables from "styleVariables";
import ternaryLogic from "utils/ternaryLogic";
import { validPassword } from "utils/validators";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import HeaderPrimary from "components/utils/header.component";
import CustomLink from "components/utils/link.component";
import PasswordReqs from "components/auth/passwordReqs.component";
import Alert from "@material-ui/lab/Alert";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    maxWidth: 400,
    padding: "20px 20px 10px 20px",
  },
  alert: {
    marginTop: 3,
    marginBottom: 3,
  },
  requirements: {
    maxWidth: 300,
  },
  arrowIcon: {
    height: "auto",
    width: 20,
    marginBottom: "-5px",
    marginLeft: 3,
  },
  closeRequirementsLink: {
    display: "inline-flex",
    width: "100%",
    paddingRight: 2,
    justifyContent: "space-between",
  },
  closeIcon: {
    height: "auto",
    width: 20,
  },
  checkRoot: {
    paddingLeft: 5,
  },
  checkLabel: {
    fontSize: variables.fontMedium,
    color: variables.primaryMain,
  },
  formElementWrapper: {
    paddingTop: 10,
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
  },
}));

const ResetPasswordForm = ({ user }) => {
  const classes = useStyles();
  const [newPassword, setNewPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [submitted, setSubmitted] = useState("");
  const [viewReqs, setViewReqs] = useState(false);

  const resetForm = () => {
    setNewPassword("");
    setConfirm("");
    setMessage("");
  };

  const changeHandler = (event) => {
    if (event.target.name === "new_password") {
      setNewPassword(event.target.value);
    } else {
      setConfirm(event.target.value);
    }
    if (message !== "") {
      setMessage("");
    }
  };

  // submit the form
  const handleSubmit = (event) => {
    event.preventDefault();
    setMessage("");
    if (!validPassword(newPassword)) {
      setMessage("You've entered an invaild password");
    } else if (confirm !== newPassword) {
      setMessage("Passwords do not match");
    } else {
      setIsSaving(true);
      //update password
      AuthService.updatePassword(user.User_ID, newPassword).then(() => {
        setSubmitted("Your password has been updated");
        setIsSaving(false);
        setViewReqs(false);
        resetForm();
      });
    }
  };

  return (
    <div className={classes.formWrapper}>
      <HeaderPrimary variant="h4Primary">Change Password</HeaderPrimary>
      {!!submitted && (
        <Alert
          onClose={() => setSubmitted("")}
          severity="info"
          className={classes.alert}
          data-cy="alert-password-changed"
        >
          {submitted}
        </Alert>
      )}
      {!!message && (
        <Alert
          onClose={() => setMessage("")}
          severity="error"
          className={classes.alert}
          data-cy="alert-invalid"
        >
          {message}
        </Alert>
      )}
      <form name="change-password" className={classes.form}>
        {!viewReqs && (
          <CustomLink variant="noHRef" onClick={() => setViewReqs(true)}>
            View Password Requirements
            <ExpandMoreIcon className={classes.arrowIcon} />
          </CustomLink>
        )}
        {!!viewReqs && (
          <div className={classes.requirements}>
            <CustomLink
              variant="noHRef"
              onClick={() => setViewReqs(false)}
              className={classes.closeRequirementsLink}
            >
              Hide Password Requirements
              <ExpandLessIcon className={classes.closeIcon} />
            </CustomLink>
            <PasswordReqs />
          </div>
        )}
        <input hidden type="text" autoComplete="username" />
        <div className={classes.formElementWrapper}>
          <LabelInput
            variant="default"
            label="New Password"
            // required
            type={ternaryLogic(showPassword, true, "text", "password")}
            name="new_password"
            onChange={changeHandler}
            value={newPassword}
            inputProps={{
              autoComplete: "new-password",
              "data-cy": "new_password"
            }}
            margin="dense"
          />
        </div>
        <div className={classes.formElementWrapper}>
          <LabelInput
            variant="default"
            label="Re-enter New Password"
            name="confirm"
            type={ternaryLogic(showPassword, true, "text", "password")}
            inputProps={{
              autoComplete: "new-password",
              "data-cy": "confirm_new_password"
            }}
            onChange={changeHandler}
            value={confirm}
            margin="dense"
          />
        </div>
        <Checkbox
          onChange={(event) => setShowPassword(!showPassword)}
          checked={showPassword}
          size="small"
          name="create"
          label="Show Password"
          labelClass={classes.checkLabel}
          rootClass={classes.checkRoot}
          test="show-password"
        />
        <div className={classes.buttonWrapper}>
          <DualFormButtons
            saveOnClick={handleSubmit}
            addText="UPDATE"
            cancelText="CLEAR"
            cancelOnClick={() => resetForm()}
            disabled={newPassword.trim() === "" || confirm.trim() === ""}
            isSaving={isSaving}
            cancelTest="resetPassword"
            saveTest="resetPassword"
          />
        </div>
      </form>
    </div>
  );
};

export default ResetPasswordForm;
