import FormContext from "contexts/form.context";
import { cloneElement, memo, useContext } from "react";

/*
 * Supplies standardized props to form inputs based on FormContext.
 * These props are overridden if passed from parent components.
 */
export default function FormElementWithContext(props) {
  const { error: errorProp, errorMessage: messageProp, id, name } = props;
  const formContext = useContext(FormContext);

  // `name` should only be used (without it inputs are invalid HTML),
  //   but `id` is a backup for backward-compatibility with our components
  const errorMessage = messageProp || formContext.errors[name || id]?.message
  const error = errorProp || !!formContext.errors[name || id]?.message;

  return (
    <FormElementWithMemoizedContext
      {...props}
      errorMessage={errorMessage}
      error={error}
    />
  );
}

const FormElementWithMemoizedContext = memo((props) => {
  const { error, errorMessage } = props;
  const { children, ...otherProps } = props;
  return cloneElement(children, { ...otherProps, error, errorMessage, labelError: error });
});

FormElementWithMemoizedContext.displayName = "FormElementWithMemoizedContext";
