import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core";
import ManualSectionSelection from "./manualSectionSelection.component";
import ExpandMoreIcon from '@material-ui/icons//ExpandMore';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import DisplayHTML from "components/utils/displayHTML.component";

const useStyles = makeStyles((theme) => ({
  dropdownSpan: {
    display: "flex",
    paddingTop: 20,
    paddingBottom: 20,
    marginTop: 5,
    marginBottom: 5,
    boxShadow: "-1px 5px 9px -6px rgb(0 0 0 / 15%), 0px 2px 1px -1px rgb(0 0 0 / 10%)",
    "&:hover": {
      cursor: "pointer",
    }
  },
  dropDownIconWrapper: {
    paddingRight: 10,
    display: "flex"
  },
  manualSelectionWrapper: {
    height: "70vh",
    width: "60vw"
  }
}));

const ManualSelection = (props) => {
  const { manualData, addSection, removeSection, activeLOCItemReferences } = props;
  const classes = useStyles();
  const [showManuals, setShowManuals] = useState([]);

  const handleShowSections = (manualID) => {
    if (showManuals?.includes(manualID)) {
      const filteredSections = showManuals.filter((manual) => (manual !== manualID));
      setShowManuals(filteredSections);
    } else if (!showManuals) {
      setShowManuals([manualID]);
    } else {
      setShowManuals([...showManuals, manualID]);
    }
  }

  return (
    <div className={classes.manualSelectionWrapper}>
      {manualData.map((manual) => {
        return (
          <Fragment key={`manual-${manual.Manual_ID}`}>
            <span
              data-cy={`manual-${manual.Manual_ID}`}
              value={manual.Manual_ID}
              onClick={() => handleShowSections(manual.Manual_ID)}
              className={classes.dropdownSpan}
            >
              <span
                className={classes.dropDownIconWrapper}
              >
                {showManuals?.includes(manual.Manual_ID) ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
              </span>
              <DisplayHTML
                html={manual.Title}
                unstyled
              />
            </span>
            {showManuals.includes(manual.Manual_ID) &&
              manual._associations.ManualSection.map((section) => (
                <ManualSectionSelection
                  section={section}
                  addSection={addSection}
                  removeSection={removeSection}
                  activeLOCItemReferences={activeLOCItemReferences}
                  key={section.ManualSection_ID}
                />
              ))}
          </Fragment>
        );
      })}
    </div>
  )
};

export default ManualSelection;
