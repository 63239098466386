import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TableCell from "components/utils/tables/shared/tableCell.component";
import { makeStyles } from "@material-ui/core/styles";
import HeaderPrimary from "components/utils/header.component.js";
import classNames from "classnames";
import variables from "styleVariables";
import CommitteeService from "services/commitee.service";
import IconMessage from "components/utils/iconMessage.component";
import EditIcon from "components/utils/editIcon.component";
import AddListitem from "components/utils/addListItem.component";
import CommitteeForm from "components/forms/committeForm.component";
import CustomModal from "components/utils/modal.component";
import { sortByStringKey } from "utils/sortingFuncs";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    overFlowX: "hidden",
    maxHeight: 430,
    [theme.breakpoints.down("md")]: {
      maxHeight: 292,
    },
  },
  tableSmallPrimaryHeaderCell: {
    backgroundColor: variables.tertiary1,
    color: "white",
    fontWeight: "bold",
    fontSize: variables.fontLarge,
    padding: "10px 25px",
    lineHeight: "normal",
    [theme.breakpoints.down("md")]: {
      fontSize: variables.fontMedium,
    },
  },
  tableSmallBodyCell: {
    padding: "10px 25px",
    color: variables.textSecondary,
    fontSize: variables.fontSmall,
    [theme.breakpoints.down("md")]: {
      fontSize: variables.fontXs,
    },
  },
  editBodyCell: {
    padding: "10px 0px 10px 10px",
  },
  editHeadCell: {
    padding: "10px 0px 10px 10px",
  },
  centerAlign: {
    textAlign: "center",
  },
  leftAlign: {
    textAlign: "left",
  },
  headerWrapper: {
    marginBottom: 10,
  },
  addWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 20,
    paddingRight: 15
  },
}));

export const CommitteeTable = (props) => {
  const classes = useStyles();
  const [tableData, setTableData] = useState();
  const [readyToLoad, setReadyToLoad] = useState(false)
  const [refresh, setRefresh] = useState(true);
  const [committeeIds, setCommitteeIds] = useState()
  // for modal
  const [editModal, setEditModal] = useState(false);
  const [formMode, setFormMode] = useState("");

  useEffect(() => {
    let isSubscribed = true;
    CommitteeService.getActiveCommitteesByType(props.programId, "Steering")
      .then((res) => {
        if (isSubscribed) {
          const sorted = sortByStringKey(res.payload, "First_Name")
          setTableData(sorted);
        }
      })
      .then(() => isSubscribed && setReadyToLoad(true));
    return () => (isSubscribed = false);
  }, [props.programId, refresh]);


  const openForm = (mode, comID) => {
    setEditModal(true)
    if (comID) {
      setCommitteeIds(comID)
    }
    setFormMode(mode)
  }

  if (readyToLoad) {

    return (
      <>
        <div className={classes.headerWrapper}>
          <HeaderPrimary variant="h3Primary">Steering Committee Membership</HeaderPrimary>
        </div>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  className={classNames(
                  classes.tableSmallPrimaryHeaderCell,
                  classes.centerAlign,
                  classes.editHeadCell
                  )}
                />
                <TableCell
                  className={classNames(
                  classes.tableSmallPrimaryHeaderCell,
                  classes.leftAlign
                  )}
                >
                  Member
                </TableCell>
                <TableCell
                  className={classNames(
                  classes.tableSmallPrimaryHeaderCell,
                  classes.centerAlign
                  )}
                >
                  Role
                </TableCell>
                <TableCell
                  className={classNames(
                  classes.tableSmallPrimaryHeaderCell,
                  classes.centerAlign
                  )}
                >
                  Group
                </TableCell>
              </TableRow>
            </TableHead>
            {(!tableData || tableData.length === 0) && (
              <TableBody>
                <TableRow>
                  <TableCell
                    className={classes.tableSmallBodyCell}
                    colSpan="4"
                    component="th"
                    scope="row"
                  >
                    <IconMessage sectionName="Steering Committee Members" variant="emptyCommittee" />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            <TableBody>
              {!!tableData && tableData.map((row) => (
                <TableRow key={row.Committee_ID}>
                  <TableCell
                    className={classNames(
                    classes.tableSmallBodyCell,
                    classes.centerAlign,
                    classes.editBodyCell
                    )}
                    component="th"
                    scope="row"
                  >
                    <EditIcon
                      variant="matrix"
                      onClick={() => (
                        openForm(
                        "Edit",
                        { id: row.Committee_ID, record: row.Record_ID }
                        )
                      )}
                      test={row.First_Name + row.Last_Name}
                    />
                  </TableCell>
                  <TableCell
                    className={classNames(
                    classes.tableSmallBodyCell,
                    classes.leftAlign
                    )}
                    component="th"
                    scope="row"
                  >
                    {row.First_Name} {row.Last_Name}
                  </TableCell>
                  <TableCell
                    className={classNames(
                    classes.tableSmallBodyCell,
                    classes.centerAlign
                    )}
                  >
                    {row.Role ? row.Role : "Member"}
                  </TableCell>
                  <TableCell
                    className={classNames(
                    classes.tableSmallBodyCell,
                    classes.centerAlign
                    )}
                  >
                    {row.Group}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.addWrapper}>
          <AddListitem
            dark
            text="Add Member"
            onClick={() => openForm("Add")}
            test="Steering"
          />
        </div>
        <CustomModal open={editModal} onClose={() => setEditModal(!editModal)}>
          <CommitteeForm
            setEditModal={setEditModal}
            setRefresh={setRefresh}
            formMode={formMode}
            committeeIds={committeeIds}
          />
        </CustomModal>
      </>
    );
  } else {
    return ""
  }
};

export default CommitteeTable;
