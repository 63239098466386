import DialogPop from "components/utils/dialog.component";
import { H3 } from "components/utils/headerV2.component";
import { useCallback } from "react";
import { ACTION_REMOVE_RUNBOOK_TASK, ACTION_REMOVE_RUNBOOK_INCIDENT } from "reducers/runbook/oneRunbook.reducer";
import RunbookService from "services/runbook.service";


export default function DeleteTaskModal(props) {
  const { dispatch, openTaskId, setOpenTaskId, runbookId} = props;

  const handleClose = useCallback(() => setOpenTaskId(null), [setOpenTaskId]);

  const handleConfirm = useCallback(async () => {
    const correspoondingIncidentID = await RunbookService.getAllIncidentsByRunbook(runbookId);
    correspoondingIncidentID.payload = correspoondingIncidentID.payload.filter((incident) => incident.Task_ID === openTaskId);
    const response = await RunbookService.deleteRunbookTask(openTaskId);
    const taskId = parseInt(response.payload, 10);
    dispatch({
      type: ACTION_REMOVE_RUNBOOK_TASK,
      payload: taskId,
      meta: response.meta
    });
    correspoondingIncidentID.payload.forEach(incident =>
      dispatch({
        type: ACTION_REMOVE_RUNBOOK_INCIDENT,
        payload: incident.Incident_ID,
      }));
    handleClose();
  }, [dispatch, handleClose, openTaskId, runbookId]);

  return (
    <DialogPop
      openDialog={!!openTaskId}
      setOpenDialog={handleClose}
      confirm={handleConfirm}
    >
      <H3>Delete Runbook Task?</H3>
      <p>
        This Task will be permanently deleted. This action cannot be undone.
      </p>
      <p>
        Any other Runbook Tasks with this Task set as a dependency will automatically have this Task removed.
      </p>
    </DialogPop>
  );
}
