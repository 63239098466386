const objectCountMapper = (field, arr) => {
  const countMap = {};
  arr.forEach((obj) => {
    const newField = obj[field];
    if (!countMap[newField]) {
      countMap[newField] = 1;
    } else {
      countMap[newField] = countMap[newField] + 1;
    }
    return countMap[obj];
  });
  return countMap;
};

export { objectCountMapper };
