import React, { useContext, useState } from "react";
import HeaderPrimary from "components/utils/header.component.js";
import { makeStyles } from "@material-ui/core/styles";
import WorkpackageService from "services/workpackage.service";
import CustomModal from "components/utils/modal.component";
import WorkPackagesForm from "components/forms/workPackageForm.component";
import Dialog from "components/utils/dialog.component";
import ProgWorkpackages from "components/workpackages/progWorkpackages.component";
import { PROGRAM_ID_ORCHESTRATION } from "utils/programConstants";
import { ACTION_REMOVE_WORKPACKAGE } from "reducers/global/workpackageTasks.reducer";
import WorkpackageTasksContext from "contexts/workpackageTasks.context";

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    marginBottom: 10,
  },
}));

const DashWorkPackages = (props) => {
  const classes = useStyles();
  const { dispatch } = useContext(WorkpackageTasksContext);
  const [editModal, setEditModal] = useState(false);
  const [wpToDelete, setWpToDelete] = useState({ id: null, Name: null });
  //Dialog
  const [openDialog, setOpenDialog] = useState(false);


  const toggleForm = () => {
    setEditModal(!editModal);
  };

  const resetWpToDelete = () => {
    setWpToDelete({ id: null, Name: null });
  };

  const checkDeletable = (wpId, wpName) => {
    resetWpToDelete();
    WorkpackageService.isWorkpackageDeleteAble(wpId).then((res) => {
      setWpToDelete({
        id: wpId,
        Name: wpName,
        isDeletable: res.data.isDeleteAble === true,
      });
    });

    setOpenDialog(true);
  };

  const deleteWp = (wpId) => {
    WorkpackageService.deleteWorkpackage(wpId).then(() => {
      props.setWpDashRefresh(new Date());
      setOpenDialog(false);
      dispatch({ type: ACTION_REMOVE_WORKPACKAGE, payload: wpId });
      resetWpToDelete();
    });
  };

  return (
    <>
      <div className={classes.headerWrapper}>
        <HeaderPrimary variant="h3Primary">
          {props.programId === PROGRAM_ID_ORCHESTRATION ?
            "Enterprise Workpackages" :
            "Workpackages"}
        </HeaderPrimary>
      </div>
      <ProgWorkpackages
        checkDeletable={checkDeletable}
        programId={props.programId}
        setEditModal={setEditModal}
        wpDashRefresh={props.wpDashRefresh}
      />

      <CustomModal open={editModal} onClose={toggleForm}>
        <WorkPackagesForm
          workpackageFormMode="create"
          setEditModal={setEditModal}
          programId={props.programId}
          setWpDashRefresh={props.setWpDashRefresh}
        />
      </CustomModal>
      {/* Dialogs for when deleting */}
      {!!wpToDelete.isDeletable && (
        <Dialog
          openDialog={openDialog}
          confirm={() => deleteWp(wpToDelete.id)}
          setOpenDialog={setOpenDialog}
          prompt={`Are you sure you would like to remove ${wpToDelete.Name}?`}
        />
      )}
      {wpToDelete.isDeletable === false && (
        <Dialog
          notification
          openDialog={openDialog}
          confirm={() => setOpenDialog(false)}
          setOpenDialog={setOpenDialog}
          prompt={`${wpToDelete.Name} includes tasks or is mapped to a Plan Builder and cannot be deleted.`}
        />
      )}
    </>
  );
};

export default DashWorkPackages;
