// packages
import { makeStyles } from '@material-ui/core/styles';
// constants
const drawerWidth = 600;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: "100%",
    minWidth: drawerWidth,
    maxWidth: "30%",
    display: "flex",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    boxShadow: "0px 0px 40px 4px rgba(23, 94, 181, 0.86)",
    position: "relative",
    padding: 40,
    justifyContent: "center",
    alignItems: "center",
  },
}));

const AuthFormWrapper = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.drawer}>
      {props.children}
    </div>
  )
}

export default AuthFormWrapper;
