import ButtonDefault from "components/utils/buttonDefault.component";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import EditIcon from "@material-ui/icons/Edit";
import classNames from "classnames";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  editIcon: {
    fontSize: variables.fontH3,
  },
  editButton: {
    minWidth: "unset",
  },
  editButtonPadding: {
    padding: 0
  },
  button: {
    color: variables.secondaryMain,
    padding: 5,
  },
}));

const EditIconButton = ({
  target, onClick, testProp, variant, fontSize, disabled, disableReadOnlyUsers = true, noPadding
}) => {
  const classes = useStyles();
  if (variant === "iconButton") {
    return (
      <IconButton
        onClick={onClick}
        className={classes.button}
        data-cy={
          testProp ? `btn-edit-iconButton-${testProp}` : "btn-edit-iconButton"
        }
        disabled={disabled}
      >
        <EditIcon fontSize={fontSize || "small"} />
      </IconButton>
    );
  } else {
    return (
      <ButtonDefault
        className={classNames(classes.editButton, noPadding && classes.editButtonPadding)}
        variant="iconSmall"
        color="secondary"
        startIcon={<EditIcon className={classes.editIcon} />}
        component="label"
        onClick={onClick}
        data-cy={testProp ? `btn-edit-icon-${testProp}` : `btn-edit-icon`}
        disabled={disabled}
        disableReadOnlyUsers={disableReadOnlyUsers}
      >
        {`Edit ${target || ""}`}
      </ButtonDefault>
    );
  }
};

export default EditIconButton;
