import useNumericParams from "hooks/useNumericParams";
import useReducerAsync from "hooks/useReducerAsync";
import React, { useCallback, useEffect, useMemo } from "react";
import { Route, Switch, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ACTION_SET_ASSESSMENTS } from "reducers/portfolioAssessments.reducer";
import portfolioSchedulingReducer, { ACTION_SET_PORTFOLIO_COMPANIES, ACTION_SET_PORTFOLIO_USERS, ACTION_SET_SESSIONS, portfolioSchedulingInitialState } from "reducers/portfolioScheduling.reducer";
import PortfolioService from "services/portfolio.service";
import { isHoldingRead, isGlobalUser, isPortCoGroupUser } from "utils/roles";
import SchedulingTablePage from "./scheduling/schedulingTablePage.component";
import PortfolioComponentObjectWrapper from "./shared/portfolioComponentObjectWrapper.component";
import { getPortfolioUserPermission } from "./shared/utils/portfolioUserHelpers";


const Scheduling = () => {
  const [state, dispatch] = useReducerAsync(
    portfolioSchedulingReducer,
    portfolioSchedulingInitialState
  );
  const { holdingId, portCoId } = useNumericParams();
  const history = useHistory();

  const isHoldingReadRole = useMemo(isHoldingRead, []);
  const isGlobalRole = useMemo(isGlobalUser, []);
  const isPortCoGroupRole = useMemo(isPortCoGroupUser, []);

  const fetchHoldingUserState = useCallback(async function() {
    if (!holdingId || holdingId === "undefined") {
      return;
    }
    const [
      portfolioCompanies,
      sessionsResponse,
      usersResponse,
    ] = await Promise.all([
      PortfolioService.getAllPortCosByHoldingID(holdingId),
      PortfolioService.getHolderSessions(holdingId),
      PortfolioService.getPortfolioUsers(holdingId),
    ]);
    const filteredSessions = sessionsResponse.payload.filter(session => (
      session.Type !== "Reconcile"
    ));
    dispatch({ type: ACTION_SET_PORTFOLIO_COMPANIES, payload: portfolioCompanies.payload });
    dispatch({ type: ACTION_SET_SESSIONS, payload: filteredSessions });
    dispatch({ type: ACTION_SET_PORTFOLIO_USERS, payload: usersResponse.payload });
  }, [dispatch, holdingId]);

  const fetchPortCoUserState = useCallback(async function() {
    if (!holdingId || holdingId === "undefined") {
      return;
    }
    const [
      portfolioCompany,
      sessionsResponse,
      usersResponse,
    ] = await Promise.all([
      PortfolioService.getPortCoByID(portCoId),
      PortfolioService.getHolderSessions(holdingId),
      PortfolioService.getPortfolioUsers(holdingId),
    ]);
    const filteredSessions = sessionsResponse.payload.filter(session => (
      session.Type !== "Reconcile" &&
      (!session.PortCo_ID || session.PortCo_ID === portCoId)
    ));
    const filteredUsers = usersResponse.payload.filter(user => (
      !!getPortfolioUserPermission(user, null, portCoId)
    ));
    dispatch({ type: ACTION_SET_PORTFOLIO_COMPANIES, payload: portfolioCompany.payload });
    dispatch({ type: ACTION_SET_SESSIONS, payload: filteredSessions });
    dispatch({ type: ACTION_SET_PORTFOLIO_USERS, payload: filteredUsers });
  }, [dispatch, holdingId, portCoId]);

  useEffect(function fetchUserDependentState() {
    if (isHoldingReadRole || isGlobalRole) {
      fetchHoldingUserState();
    } else if (isPortCoGroupRole) {
      fetchPortCoUserState();
    } else {
      console.error("User has no permission to access Portfolio Program.");
      history.push("notFound");
    }
  }, [
    fetchHoldingUserState, fetchPortCoUserState,
    isHoldingReadRole, isGlobalRole, isPortCoGroupRole, history
  ]);

  useEffect(() => {
    (async function fetchGlobalState() {
      const assessmentsResponse = await PortfolioService.getHolderAssessments();
      dispatch(
        { type: ACTION_SET_ASSESSMENTS, payload: assessmentsResponse.payload }
      );
    })()
  }, [dispatch]);

  return (
    <PortfolioComponentObjectWrapper>
      <Switch>
        <Route
          path={[
            "/program/:programId/:programComponentId/holding/:holdingId/portCo/:portCoId/session/:sessionId",
            "/program/:programId/:programComponentId/holding/:holdingId/portCo/:portCoId",
            "/program/:programId/:programComponentId/holding/:holdingId/session/:sessionId",
            "/program/:programId/:programComponentId/holding/:holdingId",
          ]}
        >
          <SchedulingTablePage state={state} dispatch={dispatch} portCoId={portCoId} />
        </Route>
      </Switch>
    </PortfolioComponentObjectWrapper>
  );
};

export default Scheduling;
