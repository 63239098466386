import React, { useRef, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TableCell from "components/utils/tables/shared/tableCell.component";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import classNames from "classnames";
import { H3 } from "components/utils/headerV2.component";

const useStyles = makeStyles((theme) => ({
  tableMarginTop: {
    borderCollapse: "collapse",
  },
  cellHeader: {
    backgroundColor: variables.tertiary1,
    color: "white",
    fontWeight: "bold",
    fontSize: variables.fontLarge,
    padding: "10px 20px",
    lineHeight: "normal",
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  // HEAD CELLS
  cellDenseHeader: {
    backgroundColor: variables.tertiary1,
    color: "white",
    fontWeight: "bold",
    fontSize: variables.fontMedium,
    padding: "5px 5px",
    lineHeight: "normal",
    position: "sticky",
    top: 0,
  },
  cellBody: {
    //default cell body
    padding: "10px 20px",
    color: variables.textSecondary,
    fontFamily: "Helvetica",
    fontSize: variables.fontSmall,
    border: "1px solid rgba(22, 76, 146, 0.22)",
  },
  cellBodyBold: {
    padding: "10px 20px",
    color: variables.textSecondary,
    backgroundColor: "white",
    fontFamily: "Helvetica",
    fontWeight: "bold",
    borderRight: "1px solid rgba(22, 76, 146, 0.22)",
  },
  cellDenseBody: {
    padding: "5px 5px",
    maxWidth: "300px",
    color: variables.textSecondary,
    fontFamily: "Helvetica",
    fontSize: variables.fontSmall,
    border: "1px solid rgba(22, 76, 146, 0.22)",
  },
  centerAlign: {
    textAlign: "center",
  },
  leftAlign: {
    textAlign: "left",
  },
  rightAlign: {
    textAlign: "right",
  },
  headerWrapper: {
    paddingBottom: 10,
  },
  fromSearch: {
    backgroundColor: variables.chosenElement,
  },
  borderedHeaderCell: {
    borderLeft: "1px solid white",
    borderBottom: "1px solid white",
    borderRight: "1px solid white",
    "&:first-of-type": {
      borderLeft: "none",
    },
    "&:last-of-type": {
      borderRight: "none",
    },
  },
  parentTaskRow: {
    borderTop: `4px solid ${variables.tertiary1}`,
  },
  bold: {
    fontWeight: "bold",
  },
}));

const WpAndProgramTasksTable = (props) => {
  const classes = useStyles();
  const rowRef = useRef({});
  const location = useLocation();
  const history = useHistory();
  const [searchRowId, setSearchRowId] = useState();

  // Curr used by assessments/managementPrinc & implementationTools/dataMap
  useEffect(() => {
    if (location.state && location.state.refId) {
      setSearchRowId(parseInt(location.state.refId));
      if (rowRef && rowRef.current && props.tableType !== "wpTasks") {
        rowRef.current[location.state.refId].scrollIntoView({
          block: "center",
        });
      }
      history.replace(); //clears out location.state
    }
  }, [location.state, history, props.tableType]);

  useEffect(() => {
    if (props.tableType === "wpTasks") {
      setSearchRowId(parseInt(props.taskRefId));
    }
  }, [props.taskRefId, history, props.tableType]);

  // check for variants/alignment here
  // returns a className object
  const setClasses = (cellType, cellAlignment) => {
    let classString = ""; // holds all classes before building object

    // check cellType
    if (props.denseHeader && cellType === "header") {
      classString += ` ${classes.cellDenseHeader}`;
    } else if (cellType === "header") {
      classString += ` ${classes.cellHeader}`;
    } else if (props.denseBody && cellType === "body") {
      classString += ` ${classes.cellDenseBody}`;
    } else if (cellType === "body") {
      classString += ` ${classes.cellBody}`;
    }

    // check alignment
    if (cellAlignment === "left") {
      classString += ` ${classes.leftAlign}`;
    } else if (cellAlignment === "right") {
      classString += ` ${classes.rightAlign}`;
    } else if (cellAlignment === "center") {
      classString += ` ${classes.centerAlign}`;
    }

    // build/return className object from string above
    return classString;
  };
  // Add columns to be hidden into array
  const hiddenColumns = ["HiddenTask_ID", "isSubtask", "hasCompletedSubTask"];

  // get props
  const title = props.title;
  const header = props.header;
  const headerCellStyles = props.headerCellStyles; // accepts an object to style individual header cells.
  //key is header cells index and value is the style to pass in for inline styling.
  // ie {0: { backgroundColor: variables.primaryMain}, 1: { backgroundColor: variables.primaryMain} }
  let alignment = props.alignment;
  const data = props.data;
  const maxHeight = props.maxHeight;
  const headerCls =
    title === "Management Principles" ? "mgmtPrincHeader" : "header";
  const boldFirstColumn = props.boldFirstColumn; // takes a string which should be first key of each row obj
  const replaceBlank = props.replaceBlank; //takes a string which renders in place of "" or null
  // check/build alignment if not included - defaults left
  if (!alignment) {
    alignment = Array(header.length).fill("left");
  }

  return (
    <>
      {!!title && (
        <div className={classes.headerWrapper}>
          <H3>{title}</H3>
        </div>
      )}
      <TableContainer
        component={Paper}
        className={classes.tableMarginTop}
        style={{ maxHeight, overflowX: "hidden" }}
      >
        <Table
          stickyHeader={!!props.stickyHeader}
          id={props.tableID}
          data-cy={`table-${props.test}`}
        >
          <TableHead>
            <TableRow>
              {header.map((item, index) => (
                <TableCell
                  className={classNames(
                    setClasses(headerCls, alignment[index]),
                    props.assessmentsMgmtPrinc && classes.borderedHeaderCell
                  )}
                  style={headerCellStyles?.[index]}
                  key={index}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* EMPTY TABLE */}
          {(!data || data.length === 0) && (
            <TableBody>
              <TableRow>
                <TableCell
                  className={setClasses("body", "center")}
                  colSpan={props.header.length}
                  scope="row"
                >
                  {props.title ? `No ${title}` : `No data to display`}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {/* TABLE BODY */}
          {!!data && data.length > 0 && (
            <TableBody>
              {data.map((row, index) => {
                const cells = [];
                Object.keys(row).forEach((key) => {
                  if (!hiddenColumns.includes(key)) {
                    if (key === "Name" && props.tableType === "wpTasks") {
                      const refKey = row.HiddenTask_ID;
                      cells.push(
                        <TableCell
                          key={Math.random()}
                          ref={(el) => (rowRef.current[refKey] = el)}
                          className={classNames(
                            row.isSubtask
                              ? setClasses("body", "center")
                              : setClasses("body", alignment[cells.length]),
                            !row.isSubtask && classes.bold,
                            searchRowId &&
                              searchRowId === refKey &&
                              classes.fromSearch
                          )}
                        >
                          {!props.showParents &&
                          row.isSubtask &&
                          props.filteredBy === "Completed"
                            ? ""
                            : row[key]}
                        </TableCell>
                      );
                    } else {
                      const refKey = props.defaultRefKey
                        ? row[props.defaultRefKey]
                        : row.DataType_ID;
                      cells.push(
                        <TableCell
                          className={classNames(
                            replaceBlank &&
                              (row[key] === "" || row[key] === null)
                              ? setClasses("body", "center")
                              : setClasses("body", alignment[cells.length]),
                            boldFirstColumn &&
                              key === props.boldFirstColumn &&
                              classes.cellBodyBold,
                            !row.isSubtask && classes.parentTask,
                            row.isSubtask &&
                              key === "Sub_Tasks" &&
                              classes.bold,
                            searchRowId &&
                              searchRowId === refKey &&
                              classes.fromSearch
                          )}
                          key={`bodyCell-${Math.random()}`}
                        >
                          {replaceBlank &&
                          (row[key] === "" || row[key] === null)
                            ? replaceBlank
                            : row[key]}
                        </TableCell>
                      );
                    }
                  }
                });
                return (
                  <TableRow
                    key={index}
                    data-cy={`wpTask-tableRow-${index}`}
                    className={classNames(
                      index > 0 &&
                        !data[index].isSubtask &&
                        classes.parentTaskRow
                    )}
                  >
                    {cells}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default WpAndProgramTasksTable;
