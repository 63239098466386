import http from "./http-common";
import authHeader from "./authHeader";

const RequirementService = {

  async getAllRequirements() {
    const response = await http.get(`/requirement/requirements`, { headers: authHeader() });
    return response.data;
  },

  async getAllProgramRequirements(programId) {
    const response = await http.get(`/requirement/program/${programId}/requirement`, { headers: authHeader() });
    return response.data;
  },

  async deleteRequirement(requirementId) {
    return http
      .delete(
        `/requirement/${requirementId}`,
        { headers: authHeader() }
      ).then(response => response.data);
  },

  async postRequirementForm(formData) {
    const response = await http.post(
      `/requirement/manageForm`,
      formData,
      { headers: {
        ...authHeader(),
        'Content-Type': 'multipart/form-data'
      }}
    );
    return response.data;
  },

  async getComplianceRequirementMatrix() {
    const response = await http.get(
      `/requirement/complianceRequirementMatrix`,
      { headers: authHeader() }
    );
    return response.data;
  },

  async getComplianceRequirementMatrixByProgramId(programId) {
    const response = await http.get(
      `/requirement/complianceRequirementMatrix/${programId}`,
      { headers: authHeader() }
    );
    return response.data;
  }
}

export default RequirementService;
