import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MenuItem } from "@material-ui/core";
import LabelInput from "components/utils/form-elements/labelInput.component";
import variables from "styleVariables";
import HeaderPrimary from "components/utils/header.component";
import WrapTextOutlinedIcon from "@material-ui/icons/WrapTextOutlined";
import Alert from "@material-ui/lab/Alert";
import DeleteIconButton from "components/utils/deleteIconButton.component";
import Checkbox from "components/utils/form-elements/checkbox.component";
import classNames from "classnames";
import DateInput from "components/utils/form-elements/dateInput.component";

const useStyles = makeStyles((theme) => ({
  subTaskContainer: {
    maxWidth: 600,
    marginLeft: "auto",
    position: "relative",
    width: "100%",
  },
  subTaskWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: 20,
    borderRadius: 10,
    marginBottom: 20,
    marginTop: 20,
    maxWidth: 600,
    marginLeft: "auto",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  activeBorder: {
    "&:hover": {
      boxShadow: `1px 1px 8px 2px ${variables.tertiary1}`,
    },
  },
  readOnlyBorder: {
    border: `1px solid ${variables.grayDisabled}`,
  },
  textfieldWrapper: {
    marginTop: 20,
  },
  dateContainer: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  dateWrapper: {
    paddingLeft: 10,
    [theme.breakpoints.down("md")]: {
      padding: "15px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "unset",
    },
  },
  row: {
    marginTop: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 30,
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-end",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
      marginLeft: "unset",
    },
  },
  taskInfo: {
    paddingBottom: 20,
  },
  milestoneWrapper: {
    paddingLeft: 20,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 10px",
      justifyContent: "center",
    },
  },
  selectWrapper: {
    maxWidth: 300,
    width: "100%",
    paddingLeft: 20,
    marginLeft: "auto",
    [theme.breakpoints.down("md")]: {
      paddingRight: 15,
      maxWidth: 340,
    },
  },
  taskHeaderWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 5,
  },
  subTaskIconLarge: {
    fontSize: variables.fontH3,
    marginRight: 5,
  }
}));

const CustomAlertMessage = withStyles({
  root: {
    color: "red",
    backgroundColor: "white",
    padding: "0px 16px",
    marginBottom: 10,
    marginTop: 0,

    "& .MuiAlert-icon": {
      fill: "white",
      color: "red",
    },
  },
})(Alert);

const BuilderSubTask = (props) => {
  const classes = useStyles();
  const { disableBuilder } = props;

  const [dateErrorText, setDateErrorText] = useState("")

  const onDateChange = (event) => {
    if (event.target.id === "Proposed_Start") {
      if (
        event.target.value > props.subTask.Proposed_Completion &&
        props.subTask.Proposed_Completion !== ""
      ) {
        setDateErrorText("Sub task start date must come before end date.")
      } else {
        setDateErrorText("")
      }
    } else if (event.target.id === "Proposed_Completion") {
      if (
        event.target.value < props.subTask.Proposed_Start &&
        props.subTask.Proposed_Start !== ""
      ) {
        setDateErrorText("Sub Task start date must come before end date.")
      } else {
        setDateErrorText("")
      }
    }
  }

  return (
    <div className={classes.subTaskContainer}>
      <div className={classNames(classes.subTaskWrapper, props.readOnly ? classes.readOnlyBorder : classes.activeBorder )}
        data-cy={`subTaskWrapper-${props.subTask.Name === null ? "NEW-BLANK" : props.subTask.Name}`}
      >
        <div className={classes.taskHeaderWrapper}>
          <HeaderPrimary
            variant="h4Tertiary"
            startIcon={
              <WrapTextOutlinedIcon className={classes.subTaskIconLarge} />
            }
          >
            Sub-Task
          </HeaderPrimary>
          <DeleteIconButton
            target="Sub-Task"
            testProp="Sub-Task"
            onClick={() => {
              if (
                window.confirm("Are you sure you want to delete this sub-task?")
              ) {
                props.deleteTask(props.subTask, "subTask");
              }
            }}
            disabled={disableBuilder}
          />
        </div>
        <div className={classes.taskInfo}>
          <div className={classes.textfieldWrapper}>
            <LabelInput
              label="Sub-Task Description"
              variant="default"
              multiline
              required
              defaultValue={props.subTask.Name}
              id="Name"
              error={props.subTask.Name === ""}
              onBlur={(event) => props.taskHandler(event, props.subTask._meta.clientId)}
              test="SubTaskName"
              disabled={disableBuilder}
            />
          </div>
          <div className={classes.row}>
            <div className={classes.milestoneWrapper}>
              <Checkbox
                checked={props.subTask.Type === "Milestone"}
                onChange={(event) => (
                  props.taskHandler(event, props.subTask._meta.clientId)
                )}
                name="Type"
                label="Milestone"
                test="SubTask-Milestone"
                disabled={disableBuilder}
              />
            </div>

            {/* DATES AND SELECT ROW */}
            <div className={classes.dateContainer}>
              <div className={classes.dateWrapper}>
                <DateInput
                  helperText={props.dateErrorText}
                  error={dateErrorText.length > 0}
                  label="Proposed Start"
                  disabled={disableBuilder || props.subTask.Type === "Milestone"}
                  shrink
                  defaultValue={props.subTask.Proposed_Start}
                  id="Proposed_Start"
                  onBlur={(event) => (
                    props.taskHandler(event, props.subTask._meta.clientId)
                  )}
                  onChange={(event) => onDateChange(event)}
                  placeholder="yyyy-mm-dd" //For Safari
                  test={`subTask-${props.subTask.Name}-ProposedStart`}
                />
              </div>
              <div className={classes.dateWrapper}>
                <DateInput
                  helperText={props.dateErrorText}
                  error={dateErrorText.length > 0}
                  defaultValue={props.subTask.Proposed_Completion}
                  id="Proposed_Completion"
                  onBlur={(event) => (
                    props.taskHandler(event, props.subTask._meta.clientId)
                  )}
                  onChange={(event) => onDateChange(event)}
                  label="Proposed Completion"
                  placeholder="yyyy-mm-dd" //For Safari
                  shrink
                  test={`subTask-${props.subTask.Name}-ProposedCompletion`}
                  disabled={disableBuilder}
                />
              </div>
            </div>
          </div>
          <div>
            {!!dateErrorText && (
              <CustomAlertMessage
                severity="error"
                className={classes.alertMessage}
              >
                {dateErrorText}
              </CustomAlertMessage>
            )}
          </div>
          <div className={classes.selectWrapper}>
            {props.possibleAssignees?.length ? (
              <LabelInput
                variant="defaultTextfield"
                select
                label="Assignment"
                defaultValue={props.subTask.Assigned_User || ""}
                onBlur={(event) => (
                  props.taskHandler(event, props.subTask._meta.clientId)
                )}
                id="Assigned_User"
                name="Assigned_User"
                disabled={disableBuilder || props.possibleAssignees.length === 0}
              >
                <MenuItem
                  value={null}
                  id="emptyUser"
                >
                  Not Assigned
                </MenuItem>

                {props.possibleAssignees.map((item) => (
                  <MenuItem
                    value={item.User_ID}
                    key={item.User_ID}
                    id={item.User_ID}
                  >
                    {`${item.First_Name} ${item.Last_Name}`}
                  </MenuItem>
                ))}
              </LabelInput>
            ) : (
              <LabelInput
                variant="defaultTextfield"
                label="Assignment"
                defaultValue=""
                name="Assigned_User"
                key="loading"
                disabled
                select
              >
                <MenuItem value="">
                  --
                </MenuItem>
              </LabelInput>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuilderSubTask;
