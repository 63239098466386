import React, {
  useState, useMemo
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import FormBanner from "components/utils/form-elements/formBanner.component";
import CertificationService from "services/certification.service";
import classNames from "classnames";
import ManualSelection from "components/certification/letterOfCompliance/manualSectionReferenceFormElements/manualSelection.component";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 10,
    paddingBottom: 20,
    maxHeight: "75%",
    overflow: "auto",
  },
  fieldsWrapper: {
    padding: "0px 40px",
  },
  formSection: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 5,
    paddingBottom: 5,
  }
}));


const AddLOCReferenceForm = (props) => {
  const { setFormOpen, activeLOCItem, manualData, onSuccess } = props;
  const classes = useStyles();
  const [activeSections, setActiveSections] = useState(null);
  const initialActiveLOCItemReferences = activeLOCItem?.References;
  const [loading, setLoading] = useState(false);

  const activeLOCItemReferences = useMemo(() => {
    let references;
    if (!activeSections) {
      references = initialActiveLOCItemReferences;
    } else {
      references = activeSections;
    }
    return references;
  }, [initialActiveLOCItemReferences, activeSections]);

  const initialStateMatch = useMemo(() => {
    const activeLOCReferenceIDs = activeLOCItemReferences?.map((activeReference) => (activeReference.ManualSection_ID));
    const initialActiveLOCItemReferenceIDs = initialActiveLOCItemReferences?.map((intialReference) => (intialReference.ManualSection_ID));
    if (!initialActiveLOCItemReferenceIDs && (!activeLOCReferenceIDs || activeLOCReferenceIDs.length < 1)) {
      return true;
    } else if (activeLOCReferenceIDs?.length !== initialActiveLOCItemReferenceIDs?.length) {
      return false;
    } else if (activeLOCReferenceIDs?.length > 0) {
      let matchRes = true
      activeLOCReferenceIDs.forEach((activeID) => {
        const match = initialActiveLOCItemReferenceIDs?.find((initialID) => (initialID === activeID));
        if (!match) {
          matchRes = false;
        }
      })
      return matchRes;
    } else {
      return false;
    }

  }, [activeLOCItemReferences, initialActiveLOCItemReferences]);

  const handleSaveReferences = async () => {
    setLoading(true);
    const references = {
      ManualSectionReference_ID: activeLOCItem.ManualSectionReference_ID,
      LOC_ID: activeLOCItem.LOC_ID,
      sectionList: activeLOCItemReferences,
    }

    const body = {
      references,
      activeLOCItem,
    }

    const parentReferenceLocItem = await CertificationService.replaceReferences(
      activeLOCItem.LOC_ID,
      body
    );

    const updatedLOCItem = {
      ...parentReferenceLocItem.payload,
      index: activeLOCItem.index
    };

    onSuccess(updatedLOCItem, activeLOCItem.index);

    setLoading(false);
    setFormOpen(false);
  };

  const addSection = (event, section) => {
    if (!activeSections && activeLOCItemReferences) {
      const initialEdit = activeLOCItemReferences.concat(section);
      setActiveSections(initialEdit);
    } else if (!activeSections && !activeLOCItemReferences) {
      setActiveSections([section]);
    } else {
      const updatedSubsections = [...activeSections, section];
      setActiveSections(updatedSubsections);
    }
  }

  const removeSection = (event, sectionArg) => {
    const filteredSections = activeLOCItemReferences.filter((section) => (section.ManualSection_ID !== sectionArg.ManualSection_ID));
    setActiveSections(filteredSections);
  }

  return (
    <>
      <FormBanner>Add Manual Reference</FormBanner>
      <div className={classes.formContainer} data-cy="form-addReference">
        <div className={classes.fieldsWrapper}>
          {/* Manuals */}
          <div className={classNames(classes.formSection, classes.drowDownWrapper)}>
            <ManualSelection
              manualData={manualData}
              addSection={addSection}
              removeSection={removeSection}
              activeLOCItemReferences={activeLOCItemReferences}
            />
          </div>
        </div>
      </div>
      <DualFormButtons
        saveOnClick={() => handleSaveReferences()}
        cancelOnClick={() => setFormOpen(false)}
        addText="Save"
        disabled={initialStateMatch}
        isSaving={loading}
      />
    </>
  );
};

export default AddLOCReferenceForm;
