import http from "./http-common";
import authHeader from "./authHeader";

class TaskService {
  // get List of all tasks
  getAllTasks() {
    return http
      .get(`/task/tasks`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get List of Tasks by programId
  getProgramTasksByProgramId(programId) {
    return http
      .get(`/task/programTasks/${programId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getWPTasksByworkpackageId(workpackageId) {
    return http
      .get(`/task/workpackageTasks/${workpackageId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get Task by Task_ID
  getTaskById(taskId) {
    return http
      .get(`/task/${taskId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getTaskReferencesByTaskId(taskId) {
    return http
      .get(`/task/${taskId}/references`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // save new Task
  saveNewTask(task, taskReferences) {
    return http
      .post(`/task/saveNewTask/`, { task, taskReferences }, { headers: authHeader() })
      .then((response) => {
        return response;
      });
  }

  updateTask(task, taskId) {
    return http
      .put(`/task/${taskId}`, task, { headers: authHeader() })
      .then(response => response.data);
  }

  // delete Tasks
  deleteTasks(taskIds) {
    return http
      .post(
        `/task/tasks/delete/`,
        { taskIds },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      });
  }

  insertTaskReference(reference) {
    return http
      .post(
        `/task/taskReferences`,
        { reference }, { headers: authHeader() }
      )
      .then((response) => {
        return response;
      });
  }

  deleteTaskReference(reference) {
    return http
      .delete(
        `/task/taskReferences`,
        { reference }, { headers: authHeader() }
      )
      .then((response) => {
        return response;
      });
  }

  // delete Tasks
  assignTask(taskId, userId, task) {
    return http
      .post(
        `/task/assign/${taskId}/${userId}`,
        { task },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      });
  }

  batchReplaceTaskReferences(taskId, userId, taskReferences) {
    return http
      .post(
        `/task/batchReplaceTaskReferences`,
        { taskReferences, taskId, userId },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      });
  }
}

export default new TaskService();
