/*
https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript/41318684

formatPhoneNum('+12345678900') // => "+1 (234) 567-8900"
formatPhoneNum('2345678900')   // => "(234) 567-8900"
*/

const moment = require("moment");

function formatPhoneNum(phoneNumberString) {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}

const getDateString = (date) => {
  return date ? moment(date).format("MMMM DD, YYYY") : "No Date Provided"
}

export { formatPhoneNum, getDateString };
