import http, { HTTP_STATUS_INTERNAL_ERROR, HTTP_STATUS_OK, HTTP_STATUS_NOT_AUTHORIZED } from "./http-common";
import authHeader from "./authHeader";
import {
  currentUserInfo,
  setUserInfo,
  currentUserToken,
  setRefreshToken } from "../utils/userHelpers"

class AuthService {
  login(email, password) {
    return http
      .post("/auth/signin", {
        email,
        password,
      })
      .then((response) => {
        setUserInfo(response.data.accessToken);
        setRefreshToken(response.data.refreshToken);
        return response;
      })
      .catch((error) => {
        return error;
      })
  }

  async deactivateUser(userID) {
    return http
      .put(`/auth/deactivateUser/${userID}`, { body: userID }, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  async reactivateUser(userID) {
    return http
      .put(`/auth/reactivateUser/${userID}`, { body: userID }, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      })
  }

  logout() {
    // Clears entire local storage;
    sessionStorage.clear();
  }

  forgot(email) {
    return http
      .post("/auth/forgot", { email });
  }

  updatePassword(userId, password) {
    return http
      .post(`/auth/update-password/${userId}`, { password }, { headers: authHeader() })
      .then((response) => {
        return response;
      });
  }

  update(userId, key, password) {
    return http
      .post(`/auth/update/${userId}/${key}`, { password })
      .then((response) => {
        return response;
      });
  }

  verifyEmail(userId, key) {
    return http
      .get("/auth/verify/" + userId + "/" + key)
      .then(response => {
        return response;
      });
  }

  async isUserLoggedIn() {
    const accessToken = currentUserToken();
    if ((typeof accessToken == "object") || !accessToken ) {
      return false;
    } else {
      return http
        .post("auth/isUserLoggedIn", {accessToken}, { headers: authHeader() })
        .then((response) => {
          return response.status === HTTP_STATUS_OK;
        })
        .catch(error => {
          return error.status === HTTP_STATUS_NOT_AUTHORIZED;
        })
    }
  }

  setupMFA() {
    const user = currentUserInfo();
    return http
      .post(`/auth/setup-mfa`, { user })
      .then((response) => {
        return response;
      });
  }

  verifyMFA(token) {
    const user = currentUserInfo();
    return http
      .post(`/auth/verify-mfa`, { user, token }, {
        validateStatus(status) {
          // Resolve only if the status code is less than 500
          return status < HTTP_STATUS_INTERNAL_ERROR;
        }
      })
      .then((response) => {
        if (response.status === HTTP_STATUS_OK) {
          setUserInfo(response.data.data.accessToken);
          setRefreshToken(response.data.data.refreshToken);
        }
        return response;
      })
  }

  // requiredRoles is an []
  checkPermissions(requiredRoles) {
    if (sessionStorage.getItem("accessToken")) {
      const user = currentUserInfo();
      return requiredRoles.includes(parseInt(user.role))
    } else {
      return false
    }
  }


  refreshToken(refreshToken) {
    if (!refreshToken) {
      this.logout();
    } else {
      return http
        .post(`auth/refreshToken`, { refreshToken })
        .then((response) => {
          if (response.status === HTTP_STATUS_OK) {
            setRefreshToken(response.data.data.updatedRefreshToken);
            setUserInfo(response.data.data.updatedAccessToken);
            return response.data.data;
          } else {
            this.logout();
          }
        })
    }
  }

  Type = {
    READONLY: 1,
    EDIT: 2,
    ADMIN: 3,
    HOLDINGREAD: 4,
    PORTCOREAD: 5,
    PORTCOEDIT: 6,
    TRAINEE: 7,
    RUNBOOKADMIN: 8,
    RUNBOOKEDIT: 9
  };
}

export default new AuthService();
