import React, { useState, useEffect, useCallback, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import FormBanner from "components/utils/form-elements/formBanner.component";
import CustomSelect from "components/utils/form-elements/select.component";
import {
  MenuItem,
  Paper
} from "@material-ui/core";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import { currentUserInfo } from "utils/userHelpers";
import HeaderPrimary from "components/utils/header.component";
import Checklist from "components/checklist/checklist.component";
import TaskService from "services/task.service";
import { certCompObjIds, certProgCompIds } from "utils/certificationConstants";
import ChecklistService from "services/checklist.service";
import { checklistTypeByCompObj, checklistTypeByProgComp } from "utils/checklistConstants";
import { formatTableData } from "utils/certificationHelpers";
import CertificationService from "services/certification.service";
import { useMemo } from "react";
import { CERTIFICATION_PROGRAM_ID } from "utils/certificationConstants";
import FileService from "services/file.service";
import RiskAssessmentService from "services/riskAssessment.service";
import variables from "styleVariables";
import Loader from "components/utils/loader.components";
import ProgramsContext from "contexts/programs.context";
import { PROGRAM_COMPONENT_TYPE_DASHBOARD } from "utils/programConstants";
import WorkpackageTasksContext from "contexts/workpackageTasks.context";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  form: {
    padding: "40px",
    paddingBottom: "20px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  formContent: {
    height: "50vh",
  },
  selectWrapper: {
    paddingBottom: 20,
    maxWidth: 200,
  },
  modalBody: {
    display: "flex",
    justifyContent: "space-between",
    height: "40vh",
    width: 1000,
    [theme.breakpoints.down("md")]: {
      width: "85vw"
    },
  },
  tableWrapper: {
    flex: 2
  },
  listWrapper: {
    flex: 1,
    marginLeft: 50,
  },
  listContent: {
    marginLeft: 8,
  },
  listItems: {
    overflow: "scroll",
    maxHeight: "37vh",
    paddingLeft: 20,
    paddingRight: 20,
    margin: 0,
    color: variables.textSecondary,
  },
  listItemWrapper: {
    paddingTop: 8,
  },
  noItemsMessage: {
    marginTop: 8,
    color: variables.textSecondary,
  },
  headerBox: {
    boxShadow: variables.bottomBoxShadow,
  },
  header: {
    marginLeft: 8,
  },
}));

const ReferenceForm = ({
  task,
  setEditModal,
  onSuccess
}) => {
  const classes = useStyles();
  const { state: programState } = useContext(ProgramsContext);
  const { state: wpTaskState } = useContext(WorkpackageTasksContext);
  const { programId } = useParams();

  const [selectedReferences, setSelectedReferences] = useState([])
  const [selectedProgComp, setSelectedProgComp] = useState()
  const [tableData, setTableData] = useState([])
  const [loadingTable, setLoadingTable] = useState(false)
  const user = currentUserInfo();

  const formatReferences = (references) => {
    const formattedReferences = []
    for (const ref of references) {
      const formatted = {
        Task_ID: task.Task_ID,
        TableMap_ID: ref.tableMapId,
        Ref_ID: ref.refId,
        Reference_Item: JSON.stringify(ref)
      }
      formattedReferences.push(formatted)
    }
    return formattedReferences
  }

  const programComponentList = useMemo(() => {
    const programComponents = (
      programState.programComponentsByProgram?.[programId]
    );
    if (!programComponents) {
      return [];
    }
    return programComponents.filter(component => (
      component.Type !== PROGRAM_COMPONENT_TYPE_DASHBOARD
    )).map(component => ({
      Name: component.Name,
      Component_ID: component.Component_ID
    }));
  }, [programId, programState.programComponentsByProgram]);

  useEffect(() => {
    const currentDbReferences = []
    TaskService.getTaskReferencesByTaskId(task.Task_ID)
      .then((result) => {
        result.payload.map((ref) => {
          const parsedRef = JSON.parse(ref.Reference_Item)
          return currentDbReferences.push(parsedRef)
        })
        setSelectedReferences(currentDbReferences)
      })
  }, [task.Task_ID])

  const headers = useMemo(() => {
    return [
      {
        fieldName: "selected",
        display: "",
        type: "checkbox"
      },
      {
        fieldName: "displayValue",
        display: "Item",
      },
      {
        fieldName: "type",
        display: "Type",
      }
    ]
  }, [])

  //TODO: Add Training when implemented
  useEffect(() => {
    setTableData([]);

    switch (selectedProgComp) {
      case certProgCompIds.PASI_CHECKLIST:
        const getPASIItems = async () => {
          setLoadingTable(true)
          const checklist = await ChecklistService.getChecklist(checklistTypeByCompObj[certCompObjIds.PRE_APPLICATION], CERTIFICATION_PROGRAM_ID)
          const checklistItems = await ChecklistService.getChecklistItems(checklist.payload.Checklist_ID)
          const pasi = {
            componentObjectId: certCompObjIds.PASI,
            displayValue: "PASI",
            progCompId: certProgCompIds.PASI_CHECKLIST,
            refId: 1,
            tableMapId: certCompObjIds.PASI,
            selected: false,
            tempId: "PASI_ID_1",
            type: "Form"
          }
          setTableData([checklist.payload, ...checklistItems.payload, pasi])
          setLoadingTable(false)
        }
        return getPASIItems();
      case certProgCompIds.APPLICATION_SCHEDULE:
        return setTableData(wpTaskState.tasksByProgramId?.[programId]);
      case certProgCompIds.LETTER_OF_COMPLIANCE:
        const getLetterOfComplianceItems = async () => {
          setLoadingTable(true)
          const locItems = await CertificationService.getLetterOfComplianceData()
          setTableData([...locItems.payload])
          setLoadingTable(false)
        }
        return getLetterOfComplianceItems()
      case certProgCompIds.FORMAL_APPLICATION:
        const getFormalAppItems = async () => {
          setLoadingTable(true)
          const checklist = await ChecklistService.getChecklist(checklistTypeByProgComp[certProgCompIds.FORMAL_APPLICATION], CERTIFICATION_PROGRAM_ID)
          const checklistItems = await ChecklistService.getChecklistItems(checklist.payload.Checklist_ID)
          setTableData([checklist.payload, ...checklistItems.payload])
          setLoadingTable(false)
        }
        return getFormalAppItems();
      case certProgCompIds.DCT:
        const getDCTCategoryItems = async () => {
          setLoadingTable(true)
          const dctRes = await CertificationService.getDctCategoryIndex();
          setTableData([...dctRes.payload])
          setLoadingTable(false)
        }
        return getDCTCategoryItems()
      case certProgCompIds.POLICY_PROCEDURE:
        const getBuilderDocumentItems = async () => {
          setLoadingTable(true)
          const documents = await FileService.getAllByComponent(selectedProgComp)
          setTableData([...documents.payload])
          setLoadingTable(false)
        }
        return getBuilderDocumentItems()
      case certProgCompIds.AUDIT_ASSESSMENT:
        const getAssessmentItems = async () => {
          setLoadingTable(true)
          const assessments = await RiskAssessmentService.getCurrentAssessmentsByProgID(CERTIFICATION_PROGRAM_ID)
          setTableData([...assessments.payload])
          setLoadingTable(false)
        }
        return getAssessmentItems()
      case certProgCompIds.AIRCRAFT:
        const getAircraftItems = async () => {
          setLoadingTable(true)
          const aircrafts = await CertificationService.getAllAircraft()
          setTableData([...aircrafts.payload])
          setLoadingTable(false)
        }
        return getAircraftItems()
      case certProgCompIds.MANUALS:
        const getManualItems = async () => {
          setLoadingTable(true)
          const manuals = await CertificationService.getManualsIndex()
          setTableData([...manuals.payload])
          setLoadingTable(false)
        }
        return getManualItems()
      case certProgCompIds.CONTACTS:
        const getContactsItems = async () => {
          setLoadingTable(true)
          const contacts = await CertificationService.getActiveContactsByProgramId(programId)
          setTableData([...contacts.payload])
          setLoadingTable(false)
        }
        return getContactsItems()
      default:
    }
  }, [selectedProgComp, programId, wpTaskState.tasksByProgramId])

  const formattedTableData = useMemo(() => {
    if (!tableData) {
      return []
    }
    const format = formatTableData([...tableData], selectedProgComp)
    const selectedTempIds = selectedReferences.map((ref) => ref.tempId)
    const selectedFormattedTableData = format.map((item) => {
      if (selectedTempIds.includes(item.tempId)) {
        return {...item, selected: true}
      }
      return {...item}
    })
    return selectedFormattedTableData
    // eslint-disable-next-line
  }, [tableData, selectedReferences])

  const checkHandler = useCallback((event, id) => {
    const itemToUpdate = formattedTableData.find((item, index) => {
      return item.tempId === id
    })
    const updatedItem = {...itemToUpdate, selected: event.target.checked}
    if (event.target.checked) {
      setSelectedReferences([...selectedReferences, updatedItem])
    } else {
      setSelectedReferences(selectedReferences.filter((ref) => (
        ref.tempId !== id
      )))
    }
  }, [formattedTableData, selectedReferences])

  const handleSubmit = async (referenceList, event) => {
    event.preventDefault(); //This is needed to prevent the request from being aborted in firefox.
    const formatted = formatReferences(referenceList)
    await TaskService.batchReplaceTaskReferences(task.Task_ID, user.id, formatted)
    if (onSuccess) {
      onSuccess(task)
    }
    setEditModal(false)
  };

  const handleSelect = (event) => {
    if (event.target.name === "programComponent") {
      setSelectedProgComp(event.target.value)
    }
  };

  const generateEmptyMessage = () => {
    if (!selectedProgComp) {
      return "Please select a Program Component"
    } else if (loadingTable) {
      return <Loader size={30} height={150} />
    } else {
      return null
    }
  }

  return (
    <div className={classes.formContainer}>
      <FormBanner>
        {task._associations.TaskReferences.length > 0 ? "Update" : "Add"} References
      </FormBanner>
      <form className={classes.form} data-cy="form-app-schedule-references">
        <div className={classes.formContent}>
          <div className={classes.selectWrapper}>
            <CustomSelect
              label="Program Component"
              value={selectedProgComp}
              onChange={handleSelect}
              name="programComponent"
              test="prog-comps"
              MenuProps={{
                // eslint-disable-next-line
                ["data-cy"]: "prog-comp-menu"
              }}
            >
              {programComponentList.length > 0 &&
                programComponentList.map((item) => (
                  <MenuItem
                    value={item.Component_ID}
                    name={item.Name}
                    key={item.Component_ID}
                    data-cy={`menu-option-${item.Name}`}
                  >
                    {item.Name}
                  </MenuItem>
                ))}
            </CustomSelect>
          </div>
          <div className={classes.modalBody}>
            <div className={classes.tableWrapper}>
              <Checklist
                tableData={formattedTableData}
                headers={headers}
                primaryKey="tempId"
                checkHandler={checkHandler}
                hideListHeader
                maxHeight="40vh"
                emptyMessage={generateEmptyMessage()}
                pagination={{
                  rowsPerPage: 10,
                  rowsPerPageOptions: [5, 10, 25, 50, 100]
                }}
                denseBody
              />
            </div>
            <Paper className={classes.listWrapper} data-cy="attached-refs-wrapper">
              <div className={classes.headerBox}>
                <HeaderPrimary variant="h5PrimaryLight" className={classes.header}>
                  Attached References:
                </HeaderPrimary>
              </div>
              <div className={classes.listContent}>
                {selectedReferences.length > 0 ?
                  <ul className={classes.listItems}>
                    {selectedReferences.map((ref) => (
                      <li
                        className={classes.listItemWrapper}
                        key={ref.tempId}
                      >
                        {ref.type}: {ref.displayValue}
                      </li>
                    ))}
                  </ul>
                  :
                  <div className={classes.noItemsMessage}>No items selected</div>}
              </div>
            </Paper>
          </div>
        </div>
        <DualFormButtons
          cancelOnClick={() => setEditModal(false)}
          saveOnClick={(event) => handleSubmit(selectedReferences, event)}
        />
      </form>
    </div>

  );
};

export default ReferenceForm;
