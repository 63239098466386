import ButtonDefault from "components/utils/buttonDefault.component";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginRight: 40,
  },
  backIcon: {
    color: theme.palette.secondary.main,
  },
}));
const BackButton = ({ children, onClick, dataCyProp }) => {
  const classes = useStyles();
  return (
    <ButtonDefault
      onClick={onClick}
      variant="medium"
      color="secondary"
      startIcon={<ArrowLeftIcon className={classNames(classes.backIcon)} />}
      className={classNames(classes.backButton)}
      data-cy={dataCyProp || "btn-back"}
    >
      {children || "Back"}
    </ButtonDefault>
  );
}

export default BackButton;
