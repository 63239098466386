import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import SetAssessors from "components/assessment/setAssessors.component";
import FormBanner from "components/utils/form-elements/formBanner.component";
import { batchReplaceAssessmentAssignments } from "utils/assessmentSaveFuncs";
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import variables from "styleVariables";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: 20,
  },
  contentContainer: {
    minWidth: 650,
    display: "flex",
    justifyContent: "center",
  },
  saveAssignmentsButton: {
    padding: "20px",
  },
  saveIcon: {
    fill: "white",
    fontSize: variables.fontSmall,
    marginRight: 7,
  }
}));

const AssessmentAssessorsForm = ({
  setOpenModal,
  setAssessors,
  assessors,
  builderInfo,
  withUnsavedChanges,
  setHasUnsavedChanges,
}) => {
  const classes = useStyles();

  const [formAssessors, setFormAssessors] = useState([...assessors])

  const cancelForm = () => {
    setOpenModal(false);
  }

  const saveAssessmentAssignments = async () => {
    const replacedAssessors = await batchReplaceAssessmentAssignments(formAssessors, builderInfo.Builder_ID);
    if (replacedAssessors.payload) {
      setAssessors(replacedAssessors.payload);
      setHasUnsavedChanges(false)
    }
    setOpenModal(false)
  }


  return (
    <div className={classes.formContainer} data-cy="assessorForm">
      <FormBanner>Edit Assignments</FormBanner>
      <div className={classes.contentContainer}>
        <SetAssessors
          builderInfo={builderInfo}
          formAssessors={formAssessors}
          setFormAssessors={withUnsavedChanges(setFormAssessors)}
          setHasUnsavedChanges={setHasUnsavedChanges}
          dialogFormView
        />
      </div>
      <DualFormButtons
        startIcon={<SaveOutlinedIcon className={classNames(classes.saveIcon, classes.icon)} />}
        variant="addFramework"
        addText="Save Assessors"
        cancelOnClick={() => cancelForm()}
        saveOnClick={() => saveAssessmentAssignments()}
        className={classes.saveAssignmentsButton}
      />
    </div>
  );
};

export default AssessmentAssessorsForm;
