import React, { useEffect, useState, useContext, useMemo, useCallback } from "react";
import {
  Paper,
  Divider,
  TableContainer,
  Table,
  TableBody,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HeaderPrimary from "components/utils/header.component.js";
import AlertContext from "contexts/alert.context";
import Loader from "components/utils/loader.components";
import AlertBox from "components/alerts/alertBox.component";
import SortControls from "components/utils/form-elements/sortControls.component";
import UserService from "services/user.service";
import {
  reverseSortValuesByStringKey,
  sortValuesByStringKey,
} from "utils/sortingFuncs";
import { alertsFeedVariants } from "utils/alertsConstants";
import ProgramsContext from "contexts/programs.context";
import { PROGRAM_ID_ORCHESTRATION } from "utils/programConstants";
import { ACTION_REMOVE_USER_ALERT } from "reducers/global/alert.reducer";
import useNumericParams from "hooks/useNumericParams";

/*
 compObjId prop needed to display compObj variant
*/

const useStyles = makeStyles((theme) => ({
  headerBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
  controlBox: {
    padding: 5,
  },
  headerWrapper: {
    paddingBottom: 15
  }
}));

const sortFields = [
  { value: "End_Date", label: "Date" },
  { value: "Message", label: "A/Z" },
  { value: "programName", label: "Program" },
];

const AlertsFeed = ({ compObjId, compName, setTitleAlertEl, maxHeight, testProp, handleCloseAlert, variant }) => {
  const classes = useStyles();
  const { state: alertState, dispatch: alertDispatch } = useContext(AlertContext);
  const { state: programState } = useContext(ProgramsContext);
  const { programId } = useNumericParams(true);
  const [sortBy, setSortBy] = useState("End_Date");
  const [direction, setDirection] = useState("up");

  const isMultiplePrograms = (
    programState.programs?.[PROGRAM_ID_ORCHESTRATION]?.Status === "Active" && (
      variant === alertsFeedVariants.HEADER ||
      programId === PROGRAM_ID_ORCHESTRATION
    )
  );

  const alerts = useMemo(() => {
    if (!alertState.userAlerts) {
      return [];
    }
    let filteredAlerts = [...alertState.userAlerts];
    if (compObjId) {
      filteredAlerts = filteredAlerts.filter(alert => (
        alert.ComponentObjects_ComponentObject_ID === compObjId
      ));
    } else if (
      variant !== alertsFeedVariants.HEADER &&
      programId !== PROGRAM_ID_ORCHESTRATION
    ) {
      filteredAlerts = filteredAlerts.filter(alert => (
        alert.Program_Program_ID === programId
      ));
    }
    for (const alert of filteredAlerts) {
      alert.programName = programState.programs?.[alert.Program_Program_ID]?.Name;
    }
    if (direction === "up") {
      return sortValuesByStringKey(filteredAlerts, sortBy, "z");
    } else {
      return reverseSortValuesByStringKey(filteredAlerts, sortBy, "a");
    }
  }, [
    alertState.userAlerts, compObjId, direction, programId,
    programState.programs, sortBy, variant
  ]);

  useEffect(function removeTitleWhenNoAlerts() {
    if (compObjId && alerts.length === 0) {
      setTitleAlertEl(null);
    }
  }, [alerts, compObjId, setTitleAlertEl]);

  const deleteUserAlert = useCallback(async (userAlertId) => {
    await UserService.deleteUserAlert(userAlertId);
    alertDispatch({
      type: ACTION_REMOVE_USER_ALERT,
      payload: userAlertId
    })
  }, [alertDispatch]);

  const sortHandler = useCallback((event, dir = "up") => {
    if (event?.target?.value) {
      setSortBy(event.target.value);
    }
    setDirection(dir);
  }, []);

  const title = useMemo(() => {
    if (compName) {
      return `${compName} Alerts`
    } else if (isMultiplePrograms) {
      return "Orchestration Alerts";
    } else {
      return "Alerts";
    }
  }, [compName, isMultiplePrograms]);

  return (
    <>
      <div className={classes.headerWrapper}>
        <HeaderPrimary variant="h3Primary">{title}</HeaderPrimary>
      </div>
      <Paper>
        <div className={classes.headerBox}>
          <div className={classes.controlBox}>
            <SortControls
              value={sortBy}
              sortFields={sortFields}
              sortHandler={sortHandler}
              direction={direction}
              disabled={!alerts || alerts.length === 0}
            />
          </div>
        </div>
        <Divider />
        { !alerts ? (
          <div className={classes.loaderWrapper}>
            <Loader size={30} height={100} />
          </div>
        ) : (
          <TableContainer style={{ maxHeight }}>
            <Table className={classes.alertBoxContainer} data-cy={`alerts-feed-${testProp}`}>
              <TableBody>
                {alerts.length === 0 ? (
                  <AlertBox empty />
                ) : (
                  alerts.map((alert) => {
                    return (
                      <AlertBox
                        key={alert.Alert_ID}
                        alert={alert}
                        compObjId={compObjId}
                        deleteUserAlert={deleteUserAlert}
                        handleCloseAlert={handleCloseAlert}
                      />
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </>
  );
};

export default AlertsFeed;
