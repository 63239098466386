import { useCallback, useEffect, useMemo, useReducer, useRef } from "react";

/*
 * Reducer to use when dispatches will potentially be asynchronous.
 *
 * The most common use case is in a useEffect,
 *   dispatching after an ajax request is finished.
 *
 * This can be used in place of the native useReducer without any negative
 *   side-effects UNLESS dispatch is needed after the component has unmounted.
 *   The normal dispatch method is returned as a 3rd argument for this case.
 */
export default function useReducerAsync(reducer, initialState, initializer) {
  const isMountedRef = useRef(true);

  const [state, dispatch] = useReducer(reducer, initialState, initializer);

  const dispatchAsync = useCallback((...args) => {
    if (isMountedRef.current) {
      dispatch(...args);
    }
  }, [dispatch]);

  // Make alias for clarity
  const forceDispatch = useMemo(() => dispatch, [dispatch]);

  useEffect(() => (
    () => {
      isMountedRef.current = false;
    }
  ), []);

  return [state, dispatchAsync, forceDispatch];
}
