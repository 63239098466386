import { replaceBlanksWithUnderscores } from "./stringFuncs";
const { portCoDemoTypes } = require("./portfolioConstants");


export const makeDemTypeFormNames = () => (
  Object.fromEntries(
    Object.keys(portCoDemoTypes).map(typeName => ([typeName, typeName]))
  )
);

export const makeInitialDemoFormData = () => {
  const formKeys = Object.keys(portCoDemoTypes);
  const formObj = {Name: null}
  for (const key of formKeys) {
    formObj[key] = null

  }
  return formObj;
};

export const makeDemoFormObject = (demoList, portCoName) => {
  const formObj = makeInitialDemoFormData();
  if (portCoName) {
    formObj.Name = portCoName;
  }
  for (const demo of demoList) {
    const formEleName = replaceBlanksWithUnderscores(demo.Label);
    formObj[formEleName] = demo.Title;
  }
  return formObj;
};
