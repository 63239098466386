import React, { useEffect, useState, useRef, useCallback, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import DataTable from "components/utils/tables/dataTable.component";
import ComplianceService from "services/compliance.service";
import variables from "styleVariables";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "components/utils/loader.components";
import TextFieldPlain from "components/utils/form-elements/textFieldPlain.component";
import classNames from "classnames";
import Form from "components/utils/form-elements/form.component";
import DateInput from "components/utils/form-elements/dateInput.component";
import { isReadOnly } from "utils/roles";
import moment from "moment";
import { useMemo } from "react";
import DisplayHTML from "components/utils/displayHTML.component";
import ButtonDefault from "components/utils/buttonDefault.component";
import { Box } from "@material-ui/core";
import RadioInputGroup from "components/utils/form-elements/radioInputGroup.component";
import useNumericParams from "hooks/useNumericParams";
import { PROGRAM_ID_ORCHESTRATION } from "utils/programConstants";
import CollapsableAlert from "components/utils/collapsableAlert.component";
import CustomLink from "components/utils/link.component";
import ProgramsContext from "contexts/programs.context";
import { createHiddenHeaderParams, createNodeCellParams, createNodeHeaderParams, createValueHeaderParams } from "components/utils/tables/utils/dataTableHelpers";
import { DATE_FORMAT_DISPLAY_NUMERIC } from "utils/dateConstants";
import RequirementService from "services/requirement.service";
import { Prompt } from "react-router-dom";

const BLANK_VALUE = "--";
const PROGRAM_NAME_ORCHESTRATION = "Enterprise";

const useStyles = makeStyles((theme) => ({
  matrixContainer: {
    marginTop: 20,
  },
  alertContent: {
    marginBottom: -16
  },
  tableRow: {
    verticalAlign: "top"
  },
  cellHeaderButtons: {
    maxWidth: 100,
    verticalAlign: "top",
  },
  cellHeaderDescription: {
    minWidth: "250px",
    maxWidth: "300px",
    verticalAlign: "top",
  },
  cellHeaderLastVerified: {
    minWidth: "140px",
    maxWidth: "250px",
    textAlign: "left",
    verticalAlign: "top",
  },
  cellHeaderLastVerifiedReadOnly: {
    paddingTop: 10,
  },
  cellHeaderNextReview: {
    minWidth: 180,
    maxWidth: 250,
  },
  cellHeaderNotes: {
    minWidth: 250,
    maxWidth: 300,
  },
  cellHeaderProgram: {
    minWidth: 100,
    maxWidth: 500,
  },
  cellHeaderRequirement: {
    minWidth: 100,
    maxWidth: 500,
  },
  cellHeaderStatement: {
    minWidth: 400,
    maxWidth: 400,
  },
  cellHeaderVerification: {
    minWidth: 200,
    maxWidth: 250,
  },
  cellHeaderDark: {
    backgroundColor: variables.primaryDark,
  },
  inputCellField: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  inputCellInner: {
    padding: 2,
    fontSize: variables.fontSmall,
  },
  inputCellInnerEditable: {
    borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
    "&:hover": {
      marginBottom: -1,
      borderBottom: `2px solid #164C9D`,
    },
    "&:focus": {
      marginBottom: -1,
      borderBottom: `2px solid #164C9D`,
    },
  },
  dateInput: {
    fontSize: variables.fontSmall,
  },
  metaDate: {
    fontSize: variables.fontSmall,
    fontWeight: "bold",
    paddingRight: 4,
    whiteSpace: "nowrap",
  },
  unorderedList: {
    paddingLeft: 0,
    marginTop: 6,
    listStyle: "none",
  },
  listItem: {
    paddingBottom: 6,
  },
  radioGroupClass: {
    borderLeft: `1px solid ${variables.rowBorderLight}`,
    borderRight: `1px solid ${variables.rowBorderLight}`,
    borderTop: `1px solid ${variables.rowBorderLight}`,
    borderRadius: 5,
    padding: "6px 8px 6px 12px",
    marginRight: 8,
    "& label": {
      margin: 0,
    },
  },
  pageLink: {
    fontSize: variables.fontMedium,
    paddingLeft: 8
  }
}));


const EditableTextFieldCell = ({
  value,
  reqId,
  name,
  multiline,
  isReadOnlyUser,
  onBlur,
  rowBeingEditedReqId,
  setRowBeingEditedReqId,
  maxLength
}) => {
  const classes = useStyles();


  return (
    <Box marginBottom={1.5}>
      <Form nam={`compliance-${name}`}>
        <TextFieldPlain
          id={`input-${reqId}-${name}`}
          className={classes.inputCellField}
          name={name}
          defaultValue={value}
          onChange={() => setRowBeingEditedReqId(reqId)}
          onBlur={onBlur}
          margin="dense"
          variant="noLabel"
          inputProps={{
            className: classes.fieldInput,
            classes: {
              input: classNames(
                classes.inputCellInner,
                classes.inputCellInnerEditable
              ),
            },
            disableUnderline: true,
          }}
          fullWidth
          multiline={multiline}
          disabled={!!rowBeingEditedReqId && rowBeingEditedReqId !== reqId}
          maxLength={maxLength}
          readOnly={isReadOnlyUser}
        />
      </Form>
    </Box>
  );
};

const SHOW_HISTORY_OPTION = "history";
const HIDE_HISTORY_OPTION = "current";

const SHOW_ALL_PROGRAMS_OPTION = "allPrograms";
const SHOW_PROGRAM_OPTION = "program";

const HEADER_INDEX_REQUIREMENT_ID = 0;

const typeRadioOptions = [
  { label: "Show history", value: SHOW_HISTORY_OPTION },
  { label: "Hide history", value: HIDE_HISTORY_OPTION },
];

const programsRadioOptions = [
  { label: "Show All Programs", value: SHOW_ALL_PROGRAMS_OPTION },
  { label: "Show Enterprise only", value: SHOW_PROGRAM_OPTION },
];

const EditableDateFieldCell = ({
  value,
  onBlur,
  reqId,
  name,
  rowBeingEditedReqId,
  setRowBeingEditedReqId,
  isReadOnlyUser,
}) => {
  const classes = useStyles();
  const formDate = value ? moment(value).format("YYYY-MM-DD") : null;

  return (
    <Box marginBottom={1}>
      <Form nam={`compliance-${name}`}>
        <DateInput
          id={`input-${reqId}-verification-date`}
          className={classes.inputCellField}
          name={name}
          defaultValue={formDate}
          onChange={() => setRowBeingEditedReqId(reqId)}
          onBlur={onBlur}
          margin="dense"
          variant="noLabel"
          inputProps={{
            className: classes.dateInput,
          }}
          disabled={!!rowBeingEditedReqId && rowBeingEditedReqId !== reqId}
          fullWidth
          readOnly={isReadOnlyUser}
        />
      </Form>
    </Box>
  );
};

const DefaultCellBox = ({ children }) => {
  return (
    <Box display="flex" flexDirection="column">
      {children}
    </Box>
  );
};

const HistoryListing = ({ historyValues, isDate }) => {
  const classes = useStyles();

  return (
    <ul className={classes.unorderedList} data-cy="history-list">
      {historyValues.map((value, index) => {
        let valueDisplay = value.Value || BLANK_VALUE;
        if (isDate) {
          valueDisplay = valueDisplay
            ? moment(valueDisplay).format("MM/DD/YYYY")
            : BLANK_VALUE;
        }
        return (
          <li className={classes.listItem} key={index}>
            <span>
              <UpdatedDate updatedDate={value.Updated_Date} />
              {valueDisplay}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

const UpdatedDate = ({ updatedDate }) => {
  const classes = useStyles();
  const metaDateDisplay = moment(updatedDate).format("MM/DD/YYYY");
  return (
    <span className={classes.metaDate}>{`Updated ${metaDateDisplay}:`}</span>
  );
};

const ComplianceStatements = () => {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const { state } = useContext(ProgramsContext);
  const { programId } = useNumericParams();
  const isEnterprise = programId === PROGRAM_ID_ORCHESTRATION;
  const [matrixData, setMatrixData] = useState();
  const isReadOnlyUser = useMemo(isReadOnly, []);
  //for autoscroll
  const rowRef = useRef({});
  const [searchRowId, setSearchRowId] = useState();

  const [viewMode, setViewMode] = useState(HIDE_HISTORY_OPTION);
  const [programsView, setProgramsView] = useState(SHOW_PROGRAM_OPTION);
  const [rowBeingEditedReqId, setRowBeingEditedReqId] = useState();
  const [newInputValues, setNewInputValues] = useState();
  const [errorMessage, setErrorMessage] = useState();

  useEffect(
    function addRefreshListener() {
      if (!rowBeingEditedReqId) {
        return;
      }
      const onUnload = (event) => {
        event.preventDefault();
        event.returnValue = "";
      };
      window.addEventListener("beforeunload", onUnload);
      return () => {
        window.removeEventListener("beforeunload", onUnload);
      };
    },
    [rowBeingEditedReqId]
  );

  const requirementsProgramComponentId = useMemo(() => {
    const programComponents = state.programComponentsByProgram?.[programId];
    if (!programComponents) {
      return null;
    }
    const match = programComponents.find(component => (
      component.TypeRef === "Requirements"
    ));
    return match?.Component_ID;
  }, [state.programComponentsByProgram, programId]);

  //options for the table
  const options = useMemo(() => ({
    textLabels: {
      body: {
        noMatch: (
          <span data-cy="empty-compliance-table">
            Requirements must exist before Compliance Statements can be created.
            {!!requirementsProgramComponentId && (
              <CustomLink
                href={
                  `/program/${programId}/${requirementsProgramComponentId}`
                }
                variant="underline"
                className={classes.pageLink}
                key="requirements-link"
              >
                Go to Requirements
              </CustomLink>
            )}
          </span>
        ),
      },
    },
    filterType: "checkbox",
    fixedHeader: true,
    filter: true,
    fixedSelectColumn: true,
    pagination: false,
    selectableRowsHideCheckboxes: true,
    sortOrder: {
      name: "Requirement_ID"
    },
    tableBodyMaxHeight: "calc(100vh - 285px)",
    setRowProps: (row) => ({
      className: classes.tableRow,
      hover: false,
       // Resets cell inputs when sorting and filtering change
      key: row[HEADER_INDEX_REQUIREMENT_ID]
    }),
  }), [classes, programId, requirementsProgramComponentId]);

  const submitCompliance = useCallback(
    async (reqId) => {
      const rowToUpdate = matrixData?.find(
        (row) => row.Requirement_ID === reqId
      );
      if (!rowToUpdate) {
        return;
      }
      const {
        Compliance_ID,
        Statement,
        Last_Verified,
        Verification_Taken,
        Next_Review,
        Notes,
      } = rowToUpdate;
      const data = {
        Compliance_ID,
        Requirements_Req_ID: reqId,
        Statement,
        Last_Verified,
        Verification_Taken,
        Next_Review,
        Notes,
        ...newInputValues,
      };
      if (!data.Statement || !data?.Statement.trim()) {
        setErrorMessage("Compliance Statement field is required");
        return;
      }
      try {
        const upsertRes = await ComplianceService.upsertComplianceItem(data);
        const updatedRow = upsertRes.payload;
        const currentMatrixData = [...(matrixData || [])];
        const index = currentMatrixData.findIndex(
          (row) => row.Requirement_ID === updatedRow.Requirement_ID
        );
        currentMatrixData.splice(index, 1, updatedRow);
        setMatrixData(currentMatrixData);
        setNewInputValues();
        setRowBeingEditedReqId();
      } catch (error) {
        setErrorMessage("There was an error and the changes were not saved.");
      }
    },
    [matrixData, newInputValues]
  );

  // upon load, scrolls to specified row
  useEffect(() => {
    if (location.state) {
      setSearchRowId(location.state);
      if (rowRef && rowRef.current && searchRowId && matrixData) {
        rowRef.current[searchRowId].scrollIntoView({
          block: "center",
        });
        history.replace(); //clears out location.state
      }
    }
  }, [location.state, rowRef, matrixData, searchRowId, history]);

  const matrixColumns = useMemo(() => {
    const columns = [
      createHiddenHeaderParams("Requirement_ID", { sort: true }),
      createNodeHeaderParams("Requirement_Description", "Requirement Description", {
        align: "left",
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          className: classes.cellHeaderDescription
        }),
      }),
      createValueHeaderParams("Requirement_Type", "Type", {
        sort: false,
        setCellHeaderProps: () => ({
          className: classes.cellHeaderRequirement
        }),
      }),
      createValueHeaderParams("Program_Name", "Program", {
        viewColumns: isEnterprise && programsView === SHOW_ALL_PROGRAMS_OPTION,
        display: isEnterprise && programsView === SHOW_ALL_PROGRAMS_OPTION,
        setCellHeaderProps: () => ({
          className: classes.cellHeaderProgram,
          [`data-cy`]: "program-column-header",
        }),
      }),
      createNodeHeaderParams("Statement", "Compliance Statement", {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          className:
            classNames(classes.cellHeaderDark, classes.cellHeaderStatement)
        }),
      }),
      createNodeHeaderParams("Last_Verified", "Last Verified", {
        align: "center",
        filter: false,
        setCellHeaderProps: () => ({
          align: "center",
          className: classNames(
            classes.cellHeaderDark,
            classes.cellHeaderLastVerified,
            isReadOnlyUser && classes.cellHeaderLastVerifiedReadOnly
          )
        }),
      }),
      createNodeHeaderParams("Verification_Taken", "Verification Taken", {
        filter: false,
        setCellHeaderProps: () => ({
          className:
            classNames(classes.cellHeaderDark, classes.cellHeaderVerification)
        }),
      }),
      createNodeHeaderParams("Next_Review", "Next Review", {
        filter: false,
        setCellHeaderProps: () => ({
          className:
            classNames(classes.cellHeaderDark, classes.cellHeaderNextReview)
        }),
      }),
      createNodeHeaderParams("Notes", "Notes", {
        filter: false,
        setCellHeaderProps: () => ({
          className:
            classNames(classes.cellHeaderDark, classes.cellHeaderNotes)
        })
      }),
      createNodeHeaderParams("Buttons", " ", {
        display: !isReadOnlyUser,
        filter: false,
        sort: false,
        viewColumns: false,
        setCellHeaderProps: () => ({
          className:
            classNames(classes.cellHeaderDark, classes.cellHeaderButtons)
        }),
      }),
      createHiddenHeaderParams("History_Search")
    ];
    return columns;
    // eslint-disable-next-line
  }, [
    classes.fieldInput,
    classes.inputCellField,
    classes.inputCellInner,
    classes.inputCellInnerEditable,
    viewMode,
    rowBeingEditedReqId,
    newInputValues,
    submitCompliance,
    programsView,
  ]);

  const matrixRows = useMemo(() => (
    matrixData?.map?.(row => {
      const reqId = row.Requirement_ID;
      const lastVerified = (
        row.Last_Verified &&
        moment(row.Last_Verified).format(DATE_FORMAT_DISPLAY_NUMERIC)
      ) || "";

      let historySearchValue = null;
      if (viewMode === SHOW_HISTORY_OPTION) {
        const historySearchText = [
          row.Statement_History,
          row.Verification_Taken_History,
          row.Next_Review_History,
          row.Notes_History
        ]
          .flatMap(historyItems => historyItems || [])
          .map(historyItem => (
            `${moment(historyItem.Updated_Date)
              .format(DATE_FORMAT_DISPLAY_NUMERIC)}: ` +
          historyItem.Value || "--"
          ));

        const historySearchDates = row.Last_Verified_History ? "" : (
          row.Last_Verified_History
            .filter(historyItem => historyItem.Value)
            .map(historyItem => (
              `${moment(historyItem.Updated_Date)
                .format(DATE_FORMAT_DISPLAY_NUMERIC)}: ` +
              moment(historyItem.Value).format(DATE_FORMAT_DISPLAY_NUMERIC)
            ))
        );

        historySearchValue = (
          historySearchText.concat(historySearchDates).join("|")
        );
      }

      return {
        ...row,
        Requirement_ID: reqId,
        Requirement_Type: row.Requirement_Type,
        Requirement_Description: createNodeCellParams(
          row.Requirement_Description,
          row.Requirement_Description,
          <DisplayHTML html={row.Requirement_Description} />
        ),
        Program_Name: (
          row.Program_Name === "Orchestration" ?
            PROGRAM_NAME_ORCHESTRATION :
            row.Program_Name
        ),
        Statement: createNodeCellParams(row.Statement, row.Statement, (
          <DefaultCellBox>
            <EditableTextFieldCell
              value={row.Statement}
              rowData={row}
              onBlur={(event) => handleBlur(event)}
              reqId={reqId}
              name="Statement"
              multiline
              isReadOnlyUser={isReadOnlyUser}
              rowBeingEditedReqId={rowBeingEditedReqId}
              setRowBeingEditedReqId={setRowBeingEditedReqId}
            />
            {viewMode === SHOW_HISTORY_OPTION && (
              <HistoryListing historyValues={row.Statement_History} />
            )}
          </DefaultCellBox>
        )),
        Last_Verified: createNodeCellParams(lastVerified, lastVerified, (
          <DefaultCellBox>
            <EditableDateFieldCell
              value={row.Last_Verified}
              rowData={row}
              onBlur={(event) => handleBlur(event)}
              reqId={reqId}
              name="Last_Verified"
              isReadOnlyUser={isReadOnlyUser}
              rowBeingEditedReqId={rowBeingEditedReqId}
              setRowBeingEditedReqId={setRowBeingEditedReqId}
            />
            {viewMode === SHOW_HISTORY_OPTION && (
              <HistoryListing
                historyValues={row.Last_Verified_History}
                isDate
              />
            )}
          </DefaultCellBox>
        )),
        Verification_Taken: createNodeCellParams(
          row.Verification_Taken,
          lastVerified,
          (
            <DefaultCellBox>
              <EditableTextFieldCell
                value={row.Verification_Taken}
                rowData={row}
                onBlur={(event) => handleBlur(event)}
                reqId={reqId}
                name="Verification_Taken"
                multiline
                isReadOnlyUser={isReadOnlyUser}
                rowBeingEditedReqId={rowBeingEditedReqId}
                setRowBeingEditedReqId={setRowBeingEditedReqId}
              />
              {viewMode === SHOW_HISTORY_OPTION && (
                <HistoryListing
                  historyValues={row.Verification_Taken_History}
                />
              )}
            </DefaultCellBox>
          )
        ),
        Next_Review: createNodeCellParams(row.Next_Review, row.Next_Review, (
          <DefaultCellBox>
            <EditableTextFieldCell
              value={row.Next_Review}
              rowData={row}
              onBlur={(event) => handleBlur(event)}
              reqId={reqId}
              name="Next_Review"
              multiline
              isReadOnlyUser={isReadOnlyUser}
              rowBeingEditedReqId={rowBeingEditedReqId}
              setRowBeingEditedReqId={setRowBeingEditedReqId}
              maxLength={45}
            />
            {viewMode === SHOW_HISTORY_OPTION && (
              <HistoryListing historyValues={row.Next_Review_History} />
            )}
          </DefaultCellBox>
        )),
        Notes: createNodeCellParams(row.Notes, row.Notes, (
          <DefaultCellBox>
            <EditableTextFieldCell
              value={row.Notes}
              rowData={row}
              onBlur={(event) => handleBlur(event)}
              reqId={reqId}
              name="Notes"
              multiline
              isReadOnlyUser={isReadOnlyUser}
              rowBeingEditedReqId={rowBeingEditedReqId}
              setRowBeingEditedReqId={setRowBeingEditedReqId}
            />
            {viewMode === SHOW_HISTORY_OPTION && (
              <HistoryListing historyValues={row.Notes_History} />
            )}
          </DefaultCellBox>
        )),
        Buttons: createNodeCellParams(null, null, (
          <Box
            display="flex"
            justifyContent="space-betweeen"
            flexDirection="column"
          >
            <ButtonDefault
              fullWidth
              variant="small"
              background="primary"
              onClick={() => submitCompliance(reqId)}
              disabled={rowBeingEditedReqId !== reqId}
              data-cy={`btn-add-statement-${reqId}`}
            >
              Save
            </ButtonDefault>
            <Box marginTop={0.5}>
              <ButtonDefault
                fullWidth
                variant="small"
                background="grey"
                onClick={() => cancelInputs()}
                data-cy="edit-statment"
                disabled={rowBeingEditedReqId !== reqId}
              >
                Cancel
              </ButtonDefault>
            </Box>
          </Box>
        )),
        History_Search: historySearchValue
      };
    }) || []
  ), [
    matrixData, isReadOnlyUser, rowBeingEditedReqId, submitCompliance, viewMode
  ]);

  const tableData = useMemo(() => (
    (isEnterprise && programsView === SHOW_PROGRAM_OPTION) ?
      matrixRows.filter(row => row.Program_Name === PROGRAM_NAME_ORCHESTRATION) :
      matrixRows
  ), [isEnterprise, matrixRows, programsView]);

  useEffect(() => {
    const getCompliances = async () => {
      let response;
      if (!isEnterprise) {
        response = await RequirementService.getComplianceRequirementMatrixByProgramId(programId);
      } else {
        response = await RequirementService.getComplianceRequirementMatrix()
      }
      setMatrixData(response.payload);
    };
    getCompliances();
  }, [programId, isEnterprise]);

  const radioChangeHandler = (event) => {
    setViewMode(event.target.value);
  };

  const programScopeChangeHandler = (event) => {
    setProgramsView(event.target.value);
  };

  const handleBlur = (event) => {
    setNewInputValues((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  const cancelInputs = () => {
    setRowBeingEditedReqId();
    setNewInputValues();
  };

  if (matrixRows) {
    return (
      <>
        <CollapsableAlert
          showAlert={!!errorMessage}
          closeClick={() => setErrorMessage()}
          message={errorMessage}
          severity="error"
          contentClass={classes.alertContent}
        />
        <div className={classes.matrixContainer}>
          {!!matrixColumns && (
            <div data-cy="compliance-table-wrapper">
              <Box display="flex">
                <RadioInputGroup
                  row
                  name="view-mode"
                  value={viewMode}
                  onChange={(event) => radioChangeHandler(event)}
                  options={typeRadioOptions}
                  size="small"
                  color="primary"
                  radioGroupClass={classes.radioGroupClass}
                  hideHelperText
                />

                {!!isEnterprise && (
                  <RadioInputGroup
                    row
                    name="show-prgrams"
                    value={programsView}
                    onChange={(event) => programScopeChangeHandler(event)}
                    options={programsRadioOptions}
                    size="small"
                    color="primary"
                    radioGroupClass={classes.radioGroupClass}
                    hideHelperText
                  />
                )}
              </Box>
              <DataTable
                data={tableData}
                columns={matrixColumns}
                options={options}
                // EXCEL DOWNLOAD INFO
                excelColumns={matrixColumns}
                excelData={matrixRows}
              />
              <Prompt
                when={!!rowBeingEditedReqId}
                message={() => `Are you sure you want to leave without saving?`}
              />
            </div>
          )}
        </div>
      </>
    );
  } else {
    return <Loader />;
  }
};

export default ComplianceStatements;
