import SimpleSmallTable from "components/utils/tables/simpleSmallTable.component";
import React from "react";

const headers = [
  {
    fieldName: "Make",
    display: "Make",
  },
  {
    fieldName: "Model",
    display: "Model",
  },
  {
    fieldName: "Series",
    display: "Series",
  },
  {
    fieldName: "N_Number",
    display: "N-Number",
  },
  {
    fieldName: "Cargo_Capacity",
    display: "Cargo Capacity",
  },
  {
    fieldName: "Passenger_Capacity",
    display: "Passenger Capacity",
  },
];

const PasiAircraftTable = ({ aircrafts }) => {
  return (
    <div>
      <SimpleSmallTable
        headers={headers}
        tableData={aircrafts}
        primaryKey="Aircraft_ID"
        emptyMessage="No Aircraft Data"
      />
    </div>
  );
};

export default PasiAircraftTable;
