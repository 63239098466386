import React, { useCallback, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import variables from "styleVariables";
import { isReadOnly } from "utils/roles";
import classNames from "classnames";
import Tooltip from "components/utils/tooltip.component";

const useStyles = makeStyles({
  removeIcon: {
    fontSize: variables.fontSizeXxs,
    height: 17,
    width: 17,
  },
  redCircle: {
    color: variables.warningLight,
    "&:hover": {
      cursor: "pointer",
      color: variables.warningMain,
    },
  },
  disabled: {
    color: variables.disabled,
  },
  wrap: {
    position: "relative",
    backgroundColor: "transparent",
    padding: 0,
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    minWidth: "unset",
    "&:active": {
      backgroundColor: "transparent",
    },
    "&:hover": {
      backgroundColor: "white",
    },
  },
  text: {
    position: "absolute",
    color: variables.warningMain,
    opacity: "0",
    fontSize: variables.fontXxs,
    fontWeight: "bold",
    lineHeight: ".8rem",
    backgroundColor: "transparent",
    textAlign: "center",
    paddingTop: 5,
    paddingBottom: 5,
    whiteSpace: "nowrap",
    "&:hover": {
      opacity: "1",
      backgroundColor: variables.grayTint,
      cursor: "pointer",
    },
  },
});



export const RemoveIcon = (props) => {
  const {
    children, disabled, onClick, test, tooltipId, tooltipRemoveMessage, variant, ...childProps
  } = props;
  const classes = useStyles();
  const readOnlyMode = useMemo(isReadOnly, []);
  const isDisabled = useMemo(() => (
    readOnlyMode || !!disabled
  ), [readOnlyMode, disabled]);

  const handleClick = useCallback(
    event => isDisabled ? undefined : onClick(event),
    [isDisabled, onClick]
  );

  if (props.variant === "hoverText") {
    return (
      <div
        className={classes.wrap}
        onClick={handleClick}
        data-cy={
          test
            ? `removeIcon-hoverText-${test}`
            : `removeIcon-hoverText`
        }
      >
        <span>
          <RemoveCircleOutlineIcon
            {...childProps}
            className={classNames(
              classes.removeIcon,
              isDisabled ? classes.disabled : classes.redCircle
            )}
            onClick={handleClick}
          />
        </span>
        <span className={classes.text} data-cy="remove-icon">
          {children}
        </span>
      </div>
    );
  }
  return (
    <>
      <Tooltip
        id={tooltipId}
        title={tooltipId ? tooltipRemoveMessage : null}
        placement="top-end"
        aria-labelledby={tooltipId}
      >
        <RemoveCircleOutlineIcon
          {...childProps}
          className={classNames(
            childProps.className,
            classes.removeIcon,
            isDisabled ? classes.disabled : classes.redCircle,
          )}
          onClick={handleClick}
          data-cy={`removeIcon-${test}`}
        />
      </Tooltip>
      {!!children && (
        <div className={classes.childrenDefault}>
          {children}
        </div>
      )}
    </>
  );
};

export default RemoveIcon;
