import useNumericParams from "hooks/useNumericParams";
import React, { useEffect, useCallback } from "react";
import {
  Route,
  Switch,
  useHistory,
  generatePath,
} from "react-router-dom/cjs/react-router-dom.min";
import portfolioDemosReducer, {
  ACTION_SET_ANY,
  demographyInitialState,
} from "reducers/portfolioDemos.reducer";
import PortfolioService from "services/portfolio.service";
import DemographicsPage from "./demographicsPage.component";
import DemographicsDashboard from "./demographicsDashboard.component";
import PortfolioComponentObjectWrapper from "../shared/portfolioComponentObjectWrapper.component";
import useReducerAsync from "hooks/useReducerAsync";

const Demographics = () => {
  const params = useNumericParams();
  const { programComponentId, holdingId } = params;
  const history = useHistory();

  const [reducerState, dispatch] = useReducerAsync(
    portfolioDemosReducer,
    demographyInitialState
  );

  useEffect(() => {
    if (!holdingId || holdingId === "undefined") {
      return;
    }
    const getPortCos = async () => {
      const portCosRes = await PortfolioService.getAllPortCosByHoldingID(
        holdingId
      );
      dispatch({
        type: ACTION_SET_ANY,
        payload: {
          portCoList: portCosRes.payload,
        },
      });
    };
    getPortCos();
  }, [dispatch, programComponentId, holdingId]);

  const goToPortCoDemo = useCallback(
    (portCoId) => {
      history.push(
        generatePath(
          `/program/:programId/:programComponentId/holding/:holdingId/portCo/:portCoId/demographics`,
          { ...params, portCoId }
        )
      );
    },
    [history, params]
  );

  const switchPortCoDemo = useCallback(
    (portCoId) => {
      history.replace(
        generatePath(
          `/program/:programId/:programComponentId/holding/:holdingId/portCo/:portCoId/demographics`,
          { ...params, portCoId }
        )
      );
    },
    [history, params]
  );

  return (
    <PortfolioComponentObjectWrapper
      isLoading={!reducerState?.portCoList && holdingId}
    >
      <Switch>
        <Route path="/program/:programId/:programComponentId/holding/:holdingId/portCo/:portCoId/demographics">
          <DemographicsPage
            reducerState={reducerState}
            dispatch={dispatch}
            switchPortCoDemo={switchPortCoDemo}
          />
        </Route>
        <Route path="/program/:programId/:programComponentId/holding/:holdingId">
          <DemographicsDashboard
            reducerState={reducerState}
            dispatch={dispatch}
            goToPortCoDemo={goToPortCoDemo}
          />
        </Route>
      </Switch>
    </PortfolioComponentObjectWrapper>
  );
};

export default Demographics;
