

import { FormControl, makeStyles } from "@material-ui/core";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import classNames from "classnames";
import { useCallback, useMemo, useState } from "react";
import styleVariables from "styleVariables";
import FormLabel from "./formLabel.component";
import CustomFormHelperText from "./styled/formHelperText/customFormHelperText.component.js";
import FormElementBase from "./shared/formElementBase.component";
import StaticLabelOutlinedInput from "./styled/textField/staticLabelOutlinedInput.component";

const useStyles = makeStyles(theme => ({
  input: {
    fontSize: styleVariables.fontSmall,
    letterSpacing: 0,
  },
  inputCaretConditionallyHidden: {
    /*
     * Workaround for Autocomplete customization limitations
     * (Autocomplete is still an Mui lab component in v4)
     */
    "& > div": {
      paddingRight: "39px !important",
    }
  },
  inputMarginDense: {
    /*
     * Workaround for Autocomplete customization limitations
     * (Autocomplete is still an Mui lab component in v4)
     */
    "& > div": {
      minHeight: 36,
      paddingTop: "4px !important",
      paddingBottom: "4px !important",
    },
  },
  formControlRoot: {
    width: "100%",
  },
  clearInputHidden: {
    display: "none"
  },
  clearInputVisible: {
    visibility: "visible"
  },
  dropdownCaretHidden: {
    display: "none"
  },
  dropdownCaretVisible: {
    visibility: "visible"
  },
  textSizeSmall: {
    fontSize: styleVariables.fontSmall,
  },
  textSizeMedium: {
    fontSize: styleVariables.fontMedium,
  },
}));

const SIZE_SMALL = "small";
const SIZE_MEDIUM = "medium";


const Autocomplete = props => (
  <FormElementBase {...props}>
    <AutocompleteBase {...props} />
  </FormElementBase>
);

export default Autocomplete;


function AutocompleteBase(props) {
  const classes = useStyles();
  const {
    // Form Field Input props
    name,
    options,
    value,
    defaultValue,
    label,
    placeholder,
    disabled,
    readOnly,

    // Generic HTML props
    id,
    className,
    onBlur,
    onChange,
    onClose,
    onFocus,
    onOpen,

    // MuiAutocomplete props
    autoFocus,
    ChipProps,
    blurOnSelect,
    clearOnBlur,
    disableCloseOnSelect,
    filterOptions,
    getOptionLabel,
    renderOption,
    multiple,
    open,
    // When true, dropdown caret is hidden when clear button is visible
    openOnFocus = true,
    size = SIZE_SMALL,

    // Inner Input component props
    error,
    errorMessage,
    helperText,
    hideHelperText,
    inputClassName,
    inputProps,
    labelTooltip,
    margin = "dense",

    // Excluded from MuiProps
    labelError: _labelError,
    onAutosave: _onAutosave,
    ...muiProps
  } = props;

  const [inputNodeValue, setInputNodeValue] = useState("");

  const [clearIndicatorClass, dropdownCaretClass] = useMemo(
    function getEndAdornmentClasses() {
      if (openOnFocus && multiple) {
        if (!value?.length) {
          return [classes.clearInputHidden, classes.dropdownCaretVisible];
        }
        return [classes.clearInputVisible, classes.dropdownCaretHidden];
      }
      return [];
    },
    [classes, multiple, openOnFocus, value]
  );

  const textSizeClass = useMemo(() => {
    switch(size) {
      case SIZE_SMALL:
        return classes.textSizeSmall;
      case SIZE_MEDIUM:
      default:
        return classes.textSizeMedium;
    }
  }, [classes, size]);

  const handleChange = useCallback((event, optionList, ...args) => {
    if (multiple && Array.isArray(optionList)) {
      const textValues = optionList.map(option => option.value).join(",");
      setInputNodeValue(`[${textValues}]`);
    } else {
      setInputNodeValue(optionList?.value || optionList);
    }
    onChange?.(event, optionList, ...args);
  }, [onChange, multiple]);

  const renderOptionDefaulted = useCallback(option => (
    renderOption?.(option) || (
      <span className={textSizeClass}>
        {option.label}
      </span>
    )
  ), [renderOption, textSizeClass]);

  return (
    <MuiAutocomplete
      name={`${name}-wrapper`}
      options={options}
      value={value}
      defaultValue={defaultValue}
      label={label}
      disabled={disabled}
      readOnly={readOnly}
      multiple={multiple}
      size={size}
      onChange={handleChange}
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      id={id ? `${id}-wrapper` : undefined}
      classes={{
        root: textSizeClass,
        input: classNames(textSizeClass, inputClassName),
        noOptions: textSizeClass,
        clearIndicator: clearIndicatorClass,
        popupIndicator: dropdownCaretClass,
      }}
      ChipProps={ChipProps}
      blurOnSelect={blurOnSelect}
      clearOnBlur={clearOnBlur}
      disableCloseOnSelect={disableCloseOnSelect}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel || (option => option.label)}
      renderInput={params => (
        <FormControl variant="outlined" className={classes.formControlRoot}>
          <FormLabel
            htmlFor={id}
            label={label}
            variant="default"
            error={!!error && !readOnly}
            labelTooltip={labelTooltip}
          />
          <StaticLabelOutlinedInput
            {...params}
            name={`${name}-autocomplete`}
            margin={margin}
            variant="outlined"
            id={id ? `${id}-autocomplete` : undefined}
            className={
              classNames(
                classes.input,
                margin === "dense" && classes.inputMarginDense,
                openOnFocus && classes.inputCaretConditionallyHidden,
                textSizeClass,
                className
              )
            }
            placeholder={placeholder}
            onBlur={onBlur}
            onFocus={onFocus}
            autoFocus={autoFocus}
            {...inputProps}
            inputProps={{
              ...params.inputProps,
              // Prevent input from inclusion in form submit data
              form: "no-form",
              formNoValidate: true,
            }}
          />
          <input
            name={name}
            type="hidden"
            value={inputNodeValue}
            id={id}
          />
          {!hideHelperText && !errorMessage && (
            <CustomFormHelperText
              id={id ? `helper-${id}` : undefined}
              error={error}
              data-cy={id ? `input-helper-${id}` : undefined}
            >
              {errorMessage || helperText}
            </CustomFormHelperText>
          )}
        </FormControl>
      )}
      renderOption={renderOptionDefaulted}
      openOnFocus={openOnFocus}
      {...muiProps}
    />
  );
}
