import Loader from "components/utils/loader.components";
import AssessmentCard from "./assessmentCard.component";
import { Divider, makeStyles } from "@material-ui/core";
import { useMemo } from "react";
import useNumericParams from "hooks/useNumericParams";
import { H2 } from "components/utils/headerV2.component";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  assessmentSection: {
    paddingRight: 16,
    paddingLeft: 16,
  },
  portCardSection: {
    marginTop: 16,
  },
  portCardsContainer: {
    marginTop: 16,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "stretch",
  },
  sectionDivider: {
    marginTop: 40,
    marginBottom: 40
  },
}));


export default function SelectAssessment(props) {
  const { state, dispatch } = props;
  const { holdingId } = useNumericParams();
  const classes = useStyles();

  const holderAssessments = useMemo(() => (
    state.assessments?.filter?.(assessment => (
      assessment.PortfolioHolder_ID === holdingId
    ))
  ), [holdingId, state.assessments]);

  const isMultipleAssessmentsView = useMemo(() => (
    (holderAssessments || []).some(assessment => (
      assessment?._associations?.AssessmentStatus?.length > 1
    ))
  ), [holderAssessments]);

  if (!state.portfolioCompanies || !state.assessments) {
    return <Loader />;
  }
  return (
    <div>
      {holderAssessments.map((holderAssessment, index) => (
        isMultipleAssessmentsView ? (
          <div
            className={classes.portCardSection}
            key={holderAssessment.HolderAssessment_ID}
          >
            {index > 0 && (
              <Divider className={classes.sectionDivider} />
            )}
            <div className={classes.assessmentSection}>
              <H2>{holderAssessment.Assessment_Name}</H2>
              <div className={classes.portCardsContainer}>
                {holderAssessment._associations.AssessmentStatus.map(status => (
                  <AssessmentCard
                    key={status.AssessmentStatus_ID}
                    holderAssessment={holderAssessment}
                    assessmentStatus={status}
                    state={state}
                    dispatch={dispatch}
                  />
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div
            className={
              classNames(classes.portCardsContainer, classes.portCardSection)
            }
          >
            {holderAssessment._associations.AssessmentStatus.map(status => (
              <AssessmentCard
                key={status.AssessmentStatus_ID}
                holderAssessment={holderAssessment}
                assessmentStatus={status}
                state={state}
                dispatch={dispatch}
              />
            ))}
          </div>
        )
      ))}
    </div>
  );
}
