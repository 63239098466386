import React from "react";
import { Box, Grid } from "@material-ui/core";
import LabelInput from "components/utils/form-elements/labelInput.component";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import DateInput from "components/utils/form-elements/dateInput.component";
import { validateBuilderInfoFormErrors } from "utils/builderFunctions";
import {
  ACTION_SET_BUILDER_INFO,
  ACTION_SET_BUILDER_INFO_ERRORS
} from "reducers/builder.reducer";
import CheckboxV2 from "components/utils/form-elements/checkboxV2.component";

const useStyles = makeStyles((theme) => ({
  mainContentContainer: {
    margin: "0px auto",
    marginTop: 5,
  },
  textFieldWrapper: {
    marginTop: 20,
  },
  textFieldWrapperWithButtons: {
    marginTop: 20,
    marginBottom: 20,
    display: "flex",
    width: "100%",
  },
  fullWidth: {
    width: "100%",
  },
  medWidth: {
    maxWidth: 700,
  },
  smallWidth: {
    maxWidth: 400,
    minWidth: 300
  },
  xsWidth: {
    maxWidth: 200,
  },
  versionInput: {
    position: "relative",
  },
  customDiv: {
    marginTop: 60,
    marginBottom: 20,
  },
  versionMessage: {
    margin: 2,
    fontWeight: 600
  }
}));

const BuilderHeaderInfo = ({
  loadedVersion,
  builderInfo,
  dispatch,
  builderInfoErrors,
  disableBuilder,
  builderBeingOverwrittenByCopy,
}) => {
  const classes = useStyles();

  const builderInfoOnBlur = (event) => {
    const { target } = event;
    if (target.value === builderInfo[target.id]) {
      return;
    }

    const validatedErrors = validateBuilderInfoFormErrors({
      ...builderInfo,
      [target.id]: target.value,
    });
    dispatch({
      type: ACTION_SET_BUILDER_INFO_ERRORS,
      payload: validatedErrors,
    });

    const value = target.value?.trim() === "" ? null : target.value;
    if (target.value !== builderInfo[target.id]) {
      dispatch({
        type: ACTION_SET_BUILDER_INFO,
        payload: { ...builderInfo, [target.id]: value },
      });
    }
  };

  const checkHandler = (event) => {
    dispatch({
      type: ACTION_SET_BUILDER_INFO,
      payload: { ...builderInfo, Show_Approval_Sections: event.target.checked ? 1 : 0 },
    });
  }

  return (
    <Grid container spacing={1} className={classes.mainContentContainer}>
      <Grid item sm={12} className={classes.root}>
        <div
          className={classNames(
            classes.fullWidth,
            classes.textFieldWrapperWithButtons
          )}
        >
          <div
            className={classNames(classes.fullWidth, classes.textFieldWrapper)}
          >
            <div className={classes.medWidth}>
              <LabelInput
                defaultValue={builderInfo.Title || ""}
                id="Title"
                onBlur={(event) => builderInfoOnBlur(event)}
                error={!!builderInfoErrors?.Title}
                errorMessage={builderInfoErrors?.Title}
                label="Title*"
                variant="default"
                test="builder-Title"
                disabled={disableBuilder}
              />
            </div>
          </div>
        </div>
        {/* {previousVersions?.length > 0  && !builderInfo?.Version &&  (
          <div className={classes.versionText}>
            <span data-cy={`builder-loadedVersionMessage`}>{`The most recent version is Version ${previousVersions[0].Version}`}</span>
          </div>
        )} */}
        <Box
          display="flex"
          marginTop={0}
          width="100%"
          alignItems="center"
        >
          <div
            className={classNames(
              classes.smallWidth,
              // classes.textFieldWrapper,
              classes.versionInput
            )}
          >
            <LabelInput
              defaultValue={builderInfo.Version || ""}
              id="Version"
              onBlur={(event) => builderInfoOnBlur(event)}
              error={!!builderInfoErrors?.Version}
              errorMessage={builderInfoErrors?.Version}
              label="Version*"
              variant="default"
              sublabel="HI"
              test="builder-Version"
              disabled={disableBuilder}
            />
          </div>
          {!!loadedVersion && !!builderBeingOverwrittenByCopy && (
            <Box
              marginTop={0.5}
              marginLeft={2}
              display="flex"
              flexDirection="column"
              data-cy="copied-version-info-message"
            >
              <span className={classes.versionMessage}>
                Copied from Version {loadedVersion}
              </span>
              <span className={classes.versionMessage}>
                Latest version is {builderBeingOverwrittenByCopy.Version}
              </span>
            </Box>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          <div
            className={classNames(
              classes.xsWidth,
              classes.textFieldWrapper,
              classes.datePickerWrapper
            )}
          >
            <DateInput
              defaultValue={builderInfo.Approval_Date || ""}
              id="Approval_Date"
              onBlur={(event) => builderInfoOnBlur(event)}
              placeholder="yyyy-mm-dd" //For Safari
              label="Approval Date"
              test="builder-Approval_Date"
              disabled={disableBuilder}
            />
          </div>
          <Box marginTop={5} marginLeft={4}>
            <CheckboxV2
              name="Show_Approval_Sections"
              label="Include Approval and Version History in Document"
              checked={builderInfo.Show_Approval_Sections === 1}
              onChange={checkHandler}
            />
          </Box>
        </Box>
        <div className={classes.customDiv} />
      </Grid>
    </Grid>
  );
};

export default BuilderHeaderInfo;
