import React from "react";

const HelpCenter = () => {

  return (
    <div >
      Help Center Component
    </div>
  );
};

export default HelpCenter;