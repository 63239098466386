// packages
import React from "react";
// component imports
import AuthFormWrapper from "components/auth/authFormWrapper.component";
import LoginForm from "components/auth/loginForm.component";
import BackgroundImage from "components/auth/backgroundImage.component";

const Login = () => {

  return (
    <BackgroundImage>
      <AuthFormWrapper>
        <LoginForm />
      </AuthFormWrapper>
    </BackgroundImage>
  );
};

export default Login;
