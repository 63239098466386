import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import { Box, makeStyles } from "@material-ui/core";
import Checklist from "components/checklist/checklist.component";
import TextFieldPlain from "components/utils/form-elements/textFieldPlain.component";
import classNames from "classnames";
import useNumericParams from "hooks/useNumericParams";
import RemovableListItem from "components/utils/removableListItem.component";
import UserPicker from "components/utils/form-elements/userPicker.component";
import checklistReducer, {
  ACTION_REPLACE_CHECKLIST_ITEM,
  ACTION_SET_CHECKLIST_INFO,
  ACTION_SET_CHECKLIST_ITEMS,
  ACTION_SET_POTENTIAL_ASSIGNEES,
  ACTION_REPLACE_CLI_ASSIGNMENT,
  ACTION_DELETE_CLI_ASSIGNMENT,
  ACTION_ADD_CLI_REFERENCE,
  ACTION_DELETE_CLI_REFERENCE,
  checklistInitialState,
} from "reducers/checklist.reducer";
import DownloadableDocLink from "components/utils/downloadableDocLink.component";
import UserService from "services/user.service";
import DateInput from "components/utils/form-elements/dateInput.component";
import Form from "components/utils/form-elements/form.component";
import SingleFileUpload from "components/utils/form-elements/singleFileUpload.component";
import AddListItem from "components/utils/addListItem.component";
import CustomModal from "components/utils/modal.component";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import FormBanner from "components/utils/form-elements/formBanner.component";
import ChecklistService from "services/checklist.service";
import { truncate } from "utils/workPackageHelpers";
import Loader from "components/utils/loader.components";
import { activeGlobalUsersFilter, isReadOnly } from "utils/roles";
import { checklistTypeByCompObj, checklistTypeByProgComp } from "utils/checklistConstants";
import useReducerAsync from "hooks/useReducerAsync";
import { getUploadHttpErrorMessage } from "services/http-common";

const useStyles = makeStyles((theme) => ({
  inputCellInner: {
    margin: "2px 0 3px",
    paddingTop: 0,
    paddingBottom: 2,
    transition: ".05s ease-out border-bottom, .05s ease-out margin-bottom",
  },
  inputDateCellInner: {
    margin: "2px 0 3px",
    paddingTop: 2,
    paddingBottom: 2,
    transition: ".05s ease-out border-bottom, .05s ease-out margin-bottom",
  },
  inputCellInnerEditable: {
    borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
    "&:hover": {
      marginBottom: 2,
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
    "&:focus": {
      marginBottom: 2,
      borderBottom: `2px solid #164C9D`,
    },
  },
  inputCellField: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  fieldInput: {
    minHeight: 24,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 14,
  },
  userDisplayWidth: {
    width: 120,
  },
}));

const propHeaders = [
  {
    fieldName: "Label",
    display: "Item",
  },
  {
    fieldName: "Verifier_User_ID",
    display: "Verified By",
  },
  {
    fieldName: "Verified_Date",
    display: "Verified Date",
  },
  {
    fieldName: "Source_References",
    display: "Source References",
  },
  {
    fieldName: "Uploaded_File",
    display: "Uploaded References",
  },
  {
    fieldName: "Assignees",
    display: "Assignees",
  },
  {
    fieldName: "Due_Date",
    display: "Due Date",
  },
  {
    fieldName: "Notes",
    display: "Notes",
    size: "large",
  },
];

const filterUsers = (alUsers, excludeUsers) => {
  const excludeIds = excludeUsers.map((user) => user.User_ID);
  const filteredUsers = alUsers?.filter((user) => {
    return !new Set(excludeIds).has(user.User_ID);
  });
  return filteredUsers;
};

const NotesCell = ({ row, readOnly, updateChecklistItem, checklistItemId }) => {
  const classes = useStyles();

  return (
    <TextFieldPlain
      id={`input-${checklistItemId}-Notes`}
      className={classes.inputCellField}
      name={`input-${checklistItemId}-Notes`}
      defaultValue={row.Notes}
      onAutosave={(_name, value) => (
        updateChecklistItem("Notes", value, row.ChecklistItem_ID)
      )}
      margin="dense"
      variant="noLabel"
      inputProps={{
        className: classes.fieldInput,
        classes: {
          input: classNames(
            classes.inputCellInner,
            readOnly && classes.inputCellInnerReadOnly,
            !readOnly && classes.inputCellInnerEditable
          ),
        },
      }}
      readOnly={readOnly}
      fullWidth
      multiline
    />
  );
};

const VerifiedUserCell = ({
  row,
  potentialVerifiers,
  updateChecklistItem,
  checklistItemId,
}) => {
  const classes = useStyles();

  const changeHandler = (event) => {
    updateChecklistItem(
      "Verifier_User_ID",
      event.target.value,
      row.ChecklistItem_ID
    );
  };

  return (
    <div className={classes.userDisplayWidth} data-cy={`verifier-cell-checklistId-${row.ChecklistItem_ID}`}>
      <UserPicker
        name="Verifier_User_ID"
        id={`input-${checklistItemId}-Verifier_User_ID`}
        onChange={(event) => changeHandler(event)}
        value={row.Verifier_User_ID || ""}
        potentialUsers={potentialVerifiers}
        placeholderDisplay="None"
        fixedWidth
      />
    </div>
  );
};

const AssigneesCell = ({
  readOnly,
  assignees,
  potentialAssignees,
  upsertAssignee,
  checklistItemId,
  deleteCLItemAssignment,
}) => {
  const classes = useStyles();
  const [isDeleting, setIsDeleting] = useState()

  const changeHandler = async (event) => {
    upsertAssignee("User_ID", event.target.value, checklistItemId);
  };

  const handleDelete = async (id) => {
    setIsDeleting(checklistItemId);
    await deleteCLItemAssignment(id);
    setIsDeleting();
  };


  return (
    <div data-cy={`assignee-cell-checklistId-${checklistItemId}`}>
      {assignees?.map((assignee) => {
        return (
          <div
            key={assignee.CLItemAssign_ID}
            className={classes.userDisplayWidth}
            data-cy={`assignee-checkItem-${checklistItemId}-user-${assignee.User_ID}`}
          >
            <RemovableListItem
              item={<span>{assignee.Assignee_UserName}</span>}
              removeClick={
                readOnly ? null : () => handleDelete(assignee.CLItemAssign_ID)
              }
              test={`assignee-checkItem-${checklistItemId}-user-${assignee.User_ID}`}
            />
          </div>
        );
      })}

      <Box display="flex" paddingTop={0.5}>
        {!isDeleting && (
          <UserPicker
            name="User_ID"
            id={`input-${checklistItemId}-User_ID`}
            onChange={(event) => changeHandler(event)}
            value=""
            potentialUsers={potentialAssignees}
            placeholderDisplay="None"
          />
        )}
      </Box>
    </div>
  );
};
const truncateLimit = 20;
const ReferencesCell = ({ references }) => {
  return (
    <Box display="flex" flexDirection="column">
      {references?.map((reference, index) => {
        return (
          <Box key={reference.CLItemRef_ID}>
            <DownloadableDocLink
              referenceName={reference.Source_File_Ref}
              fileRef={reference.Source_File_Ref}
              displayText={truncate(
                reference.Source_File_Ref,
                truncateLimit,
                ""
              )}
              tooltipText={
                reference?.Source_File_Ref?.length > truncateLimit
                  ? reference.Source_File_Ref
                  : ""
              }
            />
          </Box>
        );
      })}
    </Box>
  );
};

const UploadedReferencesCell = ({
  readOnly,
  references,
  checklistItem,
  openReferencesForm,
  deleteCLItemReference,
}) => {
  const classes = useStyles();

  return (
    <Box
      display="flex" flexDirection="column"
    >
      {references.map((reference, index) => {
        return (
          <Box key={reference.CLItemRef_ID} data-cy="references-wrapper">
            <RemovableListItem
              item={
                <DownloadableDocLink
                  referenceName={reference.Upload_File_Ref}
                  fileRef={reference.Upload_File_Ref}
                  displayText={truncate(
                    reference.Upload_File_Ref,
                    truncateLimit,
                    ""
                  )}
                  tooltipText={
                    reference?.Upload_File_Ref?.length > truncateLimit &&
                    reference.Upload_File_Ref
                  }
                />
              }
              removeClick={
                readOnly
                  ? null
                  : () => deleteCLItemReference(reference.CLItemRef_ID)
              }
              test="reference"
            />
          </Box>
        );
      })}
      <AddListItem
        text="Add Reference"
        className={classes.addItem}
        onClick={() =>
          openReferencesForm({
            form: modalForms.REFERENCE_FORM,
            editItem: checklistItem,
          })}
        test="reference"
      />
    </Box>
  );
};

const ReferencesForm = ({ checklistItem, dispatch, closeReferencesForm }) => {
  const classes = useStyles();
  const [description, setDescription] = useState("");
  const [chosenFile, setChosenFile] = useState();
  const [formErrors, setFormErrors] = useState({});

  const saveUploadedReference = useCallback(async () => {
    if (!description || !chosenFile) {
      const errors = {};
      if (!description) {
        errors.Description = "A description is required.";
      }
      if (!chosenFile) {
        errors.Upload_File_Ref = "No File selected";
      }
      setFormErrors(errors);
      return;
    } else {
      setFormErrors({});
    }
    const body = new FormData();
    body.append("file", chosenFile);
    body.append("Description", description);
    body.append("ChecklistItem_ID", checklistItem.ChecklistItem_ID);

    try {
      const referenceResponse = await ChecklistService.upsertCLItemReference(
        body
      );
      dispatch({
        type: ACTION_ADD_CLI_REFERENCE,
        payload: referenceResponse.payload,
      });
      closeReferencesForm();
    } catch(error) {
      const { status } = error.response || {};
      setFormErrors({ Upload_File_Ref: getUploadHttpErrorMessage(status) });
    }
  }, [
    dispatch,
    chosenFile,
    closeReferencesForm,
    description,
    checklistItem.ChecklistItem_ID,
  ]);

  const chooseFile = (event) => {
    setChosenFile(event.target.files[0]);
    const newErrors = { ...formErrors };
    if (newErrors?.Upload_File_Ref) {
      delete newErrors.Upload_File_Ref
    }
    setFormErrors(newErrors);
  };

  return (
    <Form name="checklist-references-form">
      <FormBanner>
        Add Reference for Item #{checklistItem?.Item_Order}
      </FormBanner>

      <Box margin={2.5}>
        <div>
          <LabelInput
            name="reference-description"
            onChange={(event) => setDescription(event.target.value)}
            label="Reference Description"
            variant="default"
            test="checklistitem-reference-description"
            error={!!formErrors.Description}
            errorMessage={formErrors?.Description}
            multiline
          />
          <Box marginTop={2}>
            <SingleFileUpload
              chosenFile={chosenFile}
              fileInputHandler={chooseFile}
              fileNameField="Upload_File_Ref"
              renamedChosenFile={chosenFile?.name}
              className={classes.attachFileButton}
              error={formErrors?.Upload_File_Ref}
            />
          </Box>
        </div>

        <div>
          <DualFormButtons
            cancelOnClick={closeReferencesForm}
            saveOnClick={saveUploadedReference}
          />
        </div>
      </Box>
    </Form>
  );
};
const initialModalState = { form: null, editItem: null };
const modalForms = {
  REFERENCE_FORM: "reference",
};

const DefaultChecklist = () => {
  const classes = useStyles();
  const [modal, setModal] = useState(initialModalState);
  const { programId, programComponentId, componentObjectId } = useNumericParams();
  const [state, dispatch] = useReducerAsync(
    checklistReducer,
    checklistInitialState
  );
  const isReadOnlyUser = useMemo(isReadOnly, []);

  useEffect(() => {
    const getChecklistInfo = async () => {
      const checklistRes = await ChecklistService.getChecklist(
        checklistTypeByCompObj[componentObjectId] || checklistTypeByProgComp[programComponentId],
        programId
      );
      dispatch({
        type: ACTION_SET_CHECKLIST_INFO,
        payload: checklistRes.payload,
      });
    };
    getChecklistInfo();
  }, [dispatch, programId, programComponentId, componentObjectId]);

  useEffect(() => {
    const getChecklistItems = async () => {
      const checklistItemsRes = await ChecklistService.getChecklistItems(
        state.checklistInfo.Checklist_ID
      );
      dispatch({
        type: ACTION_SET_CHECKLIST_ITEMS,
        payload: checklistItemsRes.payload,
      });
    };

    const getUsers = async () => {
      const usersRes = await UserService.getAll();
      const scopedUsers = activeGlobalUsersFilter(usersRes.payload)
      dispatch({
        type: ACTION_SET_POTENTIAL_ASSIGNEES,
        payload: scopedUsers,
      });
    };
    if (state.checklistInfo?.Checklist_ID) {
      getChecklistItems();
      getUsers();
    }
  }, [dispatch, state.checklistInfo?.Checklist_ID]);

  const updateChecklistItem = useCallback(
    async (name, value, checklistItemId) => {
      const updateRes = await ChecklistService.updateChecklistItem(
        checklistItemId,
        { [name]: value || null }
      );
      dispatch({
        type: ACTION_REPLACE_CHECKLIST_ITEM,
        payload: updateRes.payload,
      });
    },
    [dispatch]
  );

  const upsertAssignee = useCallback(
    async (name, value, checklistItemId) => {
      const updateRes = await ChecklistService.checklistItemAssignment(
        checklistItemId,
        { [name]: value }
      );
      dispatch({
        type: ACTION_REPLACE_CLI_ASSIGNMENT,
        payload: updateRes.payload,
      });
    },
    [dispatch]
  );

  const deleteCLItemAssignment = useCallback(
    async (clItemAssignmentId) => {
      const updateRes = await ChecklistService.removeCLItemAssignment(
        clItemAssignmentId
      );
      dispatch({
        type: ACTION_DELETE_CLI_ASSIGNMENT,
        payload: updateRes.payload,
      });
    },
    [dispatch]
  );

  const deleteCLItemReference = useCallback(
    async (clItemReferenceId) => {
      const updateRes = await ChecklistService.removeCLItemReference(
        clItemReferenceId
      );
      dispatch({
        type: ACTION_DELETE_CLI_REFERENCE,
        payload: updateRes.payload,
      });
    },
    [dispatch]
  );

  const openReferencesForm = (formInfo) => {
    setModal(formInfo);
  };

  const closeReferencesForm = () => {
    setModal(initialModalState);
  };
  const tableRows = useMemo(() => {
    const rows =
      state.checklistItems?.map((row, index) => {
        const associatedAssignments = row._associations.Assignments;
        const associatedReferences = row._associations.References;
        const checklistItemId = row.ChecklistItem_ID;
        return {
          ...row,
          Label: `${row.Item_Order}. ${row.Label}`,
          Verifier_User_ID: (
            <VerifiedUserCell
              row={row}
              potentialVerifiers={state.users}
              updateChecklistItem={updateChecklistItem}
            />
          ),
          Verified_Date: (
            <DateInput
              name="Verified_Date"
              value={row.Verified_Date}
              placeholder="mm/dd/yyyy"
              id={`Verified_Date-${checklistItemId}`}
              onChange={(event) =>
                updateChecklistItem(
                  "Verified_Date",
                  event.target.value,
                  row.ChecklistItem_ID
                )}
              inputProps={{
                className: classes.fieldInput,
                classes: {
                  input: classNames(classes.inputDateCellInner),
                },
              }}
              margin="dense"
              test={`verified-date-${checklistItemId}`}
            />
          ),
          Due_Date: (
            <DateInput
              name="Due_Date"
              defaultValue={row.Due_Date}
              placeholder="mm/dd/yyyy"
              id={`Due_Date-${checklistItemId}`}
              onChange={(event) => (
                updateChecklistItem(
                  "Due_Date",
                  event.target.value,
                  row.ChecklistItem_ID
                )
              )}
              inputProps={{
                className: classes.fieldInput,
                classes: {
                  input: classNames(classes.inputDateCellInner),
                },
              }}
              margin="dense"
              test={`due-date-${checklistItemId}`}
            />
          ),
          Notes: (
            <NotesCell
              row={row}
              updateChecklistItem={updateChecklistItem}
              checklistItemId={checklistItemId}
              readOnly={isReadOnlyUser}
            />
          ),
          Assignees: (
            <AssigneesCell
              assignees={associatedAssignments}
              potentialAssignees={filterUsers(
                state.users,
                associatedAssignments
              )}
              checklistItemId={checklistItemId}
              upsertAssignee={upsertAssignee}
              deleteCLItemAssignment={deleteCLItemAssignment}
            />
          ),
          Source_References: (
            <ReferencesCell
              references={associatedReferences.filter(
                (ref) => ref.Source_File_Ref
              )}
              checklistItemId={checklistItemId}
            />
          ),
          Uploaded_File: (
            <UploadedReferencesCell
              references={associatedReferences.filter(
                (ref) => ref.Upload_File_Ref
              )}
              checklistItemId={checklistItemId}
              openReferencesForm={openReferencesForm}
              closeReferencesForm={closeReferencesForm}
              checklistItem={row}
              deleteCLItemReference={deleteCLItemReference}
            />
          ),
        };
      }) || [];
    return rows;
  }, [
    state.checklistItems,
    state.users,
    updateChecklistItem,
    deleteCLItemAssignment,
    upsertAssignee,
    deleteCLItemReference,
    classes.fieldInput,
    classes.inputDateCellInner,
    isReadOnlyUser,
  ]);

  if (tableRows?.length && state.checklistInfo) {
    return (
      <>
        <Form name={`checklist-${state.checklistInfo.Title}`}>
          <Checklist
            headers={propHeaders}
            tableData={tableRows}
            checklistInfo={state.checklistInfo}
            countStats={{
              total: tableRows?.length,
              completed: tableRows.reduce(
                (previousCount, row) =>
                  row.Status === "Completed"
                    ? previousCount + 1
                    : previousCount,
                0
              ),
            }}
          />
        </Form>
        <CustomModal
          open={Object.values(modalForms).includes(modal?.form)}
          onClose={() => setModal(initialModalState)}
        >
          {modal.form === modalForms.REFERENCE_FORM && (
            <ReferencesForm
              checklistItem={modal.editItem}
              checklistItemId={modal.checklistItemId}
              closeReferencesForm={closeReferencesForm}
              dispatch={dispatch}
            />
          )}
        </CustomModal>
      </>
    );
  } else {
    return <Loader />;
  }
};

export default DefaultChecklist;
