import React from "react";
import Logo from "components/utils/logo.component";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/utils/header.component";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    maxWidth: 200,
    maxHeight: 100,
    marginRight: 16,
  },
  header: {
    margin: 0,
  },
}));

const PortCoLogoHeader = ({ portCo, title }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Logo
        logoId={portCo?.Logo}
        alt={portCo?.Name ? `${portCo?.Name} Logo` : "Portfolio Company Logo"}
        className={classes.logo}
      />
      <Header variant="h1Primary" className={classes.header}>
        {title ? `${portCo?.Name} ${title}` : portCo?.Name}
      </Header>
    </div>
  );
};

export default PortCoLogoHeader;
