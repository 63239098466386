import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  responseSources,
  assessmentTableIdMap,
} from "utils/assessmentConstants";
import HeaderPrimary from "components/utils/header.component";
import { Divider } from "@material-ui/core";
import variables from "styleVariables";
import CustomSunEditor from "components/utils/sunEditor.component";
import DisplayHTML from "components/utils/displayHTML.component";
import ButtonDefault from "components/utils/buttonDefault.component";
import LabelAndValue from "components/utils/labelAndValue.component";
import {
  ContentRowWrapper,
  ReviewInfoSideColumnWrapper,
  ResponseOrBlankSideColumnWrapper,
} from "components/assessment/shared/assessmentWrappers.component";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import classNames from "classnames";
import TaskForm from "components/forms/taskForm.component";
import CustomModal from "components/utils/modal.component";
import AddListItem from "components/utils/addListItem.component";
import { makeTaskReferenceBasicData } from "utils/taskFunctions";
import { CachedOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  responseContainer: {
    marginLeft: "auto",
    width: "100%",
  },
  inactiveResponse: {
    padding: "5px 10px",
    borderRadius: 3,
    display: "flex",
    minHeight: 80,
    border: `2px solid ${variables.rowHover}`,
    "&:hover": {
      backgroundColor: variables.rowHover,
      cursor: "pointer",
    },
  },
  noResponsesOuterWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  noResponseInnerWrapper: {
    color: variables.textSecondary,
    "&:hover": {
      color: variables.secondaryMain,
    },
  },
  buttonsWrapper: {
    padding: "5px 5px",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 10,
  },
  subDivider: {
    margin: "20px 0px",
    backgroundColor: variables.tertiary2,
  },
  recommendationHtml: {
    color: variables.textSecondary,
  },
  noResponse: {
    display: "flex",
    paddingTop: 10,
    flexDirection: "row",
    alignItems: "center",
    height: "fit-content",
  },
  addIcon: {
    fontSize: variables.fontLarge,
    marginRight: 5,
  },
  addTaskRow: {
    paddingTop: 10,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    color: variables.textSecondary,
  },
  taskMessage: {
    padding: "0 5px",
    fontSize: variables.fontSmall,
  },
  addTaskLink: {
    paddingLeft: 10,
  },
  responseHeaderWrapper: {
    paddingTop: 0,
  },
  summaryResponseWrapper: {
    alignSelf: "center",
  },
  buttonIcon: {
    color: "white",
    height: variables.fontSmall
  },
}));

const reviewFindTypes = ["DESIGN_FINDING", "PERFORMANCE_FINDING"];
const reviewRecTypes = ["DESIGN_REC", "PERFORMANCE_REC"];
const designRevSources = ["DESIGN_FINDING", "DESIGN_REC"];

/**
 * Task References for Summary Recommendation:
 *   1. AssessmentSummaryRecommendations
 *   2. AssessmentBuilder
 *
 * Task References for Review Findings/Recommendations:
 *   1. AssessmentItemFindingRecommendation
 *   2. DesignDC OR PerformanceDC
 *   2. AssessmentBuilder
 */

const makeTaskReferences = (displayType, primaryID, builderInfo, sourceObj) => {
  const defaultTaskRef = makeTaskReferenceBasicData();
  // Builder Task Reference
  const builderTaskRef = {
    ...defaultTaskRef,
    TableMap_ID: assessmentTableIdMap.ASSESSMENT_BUILDER,
    Ref_ID: builderInfo.Builder_ID,
    Column_Name: null,
  };
  const taskRefs = [builderTaskRef];
  // Summary Recommendation Task Reference
  if (displayType === responseSources.SUMMARY_RECOMMENDATION.constFieldName) {
    const summRecRef = {
      ...defaultTaskRef,
      TableMap_ID: assessmentTableIdMap.SUMMARY_RECOMMENDATIONS,
      Ref_ID: sourceObj.Recommendation_ID,
    };
    taskRefs.push(summRecRef);
  } else {
    // Review Finding or Recommendation Task Reference
    const findingRecRef = {
      ...defaultTaskRef,
      TableMap_ID: assessmentTableIdMap.FINDING_RECOMMENDATION,
      Ref_ID: reviewFindTypes.includes(displayType)
        ? sourceObj.Finding_ID
        : sourceObj.Recommendation_ID,
    };
    // Design or Performance Review Task Reference
    const revRef = {
      ...defaultTaskRef,
      TableMap_ID: designRevSources.includes(displayType)
        ? assessmentTableIdMap.DESIGN_DATA_COLLECTION
        : assessmentTableIdMap.PERFORMANCE_DATA_COLLECTION,
      Ref_ID: primaryID,
    };
    taskRefs.push(findingRecRef, revRef);
  }
  return taskRefs;
};

const findResponseObj = (dispType, sourceObj) => {
  if (reviewFindTypes.includes(dispType)) {
    return {
      FindingRecommendation_ID: sourceObj.Finding_ID,
      Response: sourceObj.Finding_Response,
    };
  } else if (reviewRecTypes.includes(dispType)) {
    return {
      FindingRecommendation_ID: sourceObj.Recommendation_ID,
      Response: sourceObj.Recommendation_Response,
    };
  } else if (dispType === "SUMMARY_RECOMMENDATION") {
    return { Response: sourceObj.Response };
  }
};

const ResponseSection = ({
  staticObj,
  index,
  test,
  displayType,
  editorHTMLContents,
  emptyEditorHTML,
  handleSaveResponse,
  setActiveEditor,
  activeEditor,
  handleEditorOnChange,
  activateEditor,
  hideBottomDivider, //Determines whether to show divider for summary Rec
  builderInfo,
  hideResponse,
  activeIdentifier,
  setActiveIdentifier,
}) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState("");
  const [taskMessage, setTaskMessage] = useState(null);
  const { sourceDisplayField, sourceIDName, sourceTypeField, reviewType } =
    responseSources[displayType];
  const sourceID = staticObj[sourceIDName];
  const identifier = `${displayType}-${sourceID}-${sourceDisplayField}`;
  const existingResponse = findResponseObj(displayType, staticObj);

  const initialEditorHTML = existingResponse?.Response || emptyEditorHTML;
  const isSummaryRecommendation =
    displayType === responseSources.SUMMARY_RECOMMENDATION.constFieldName;

  const openTaskForm = () => {
    setOpenModal(true);
    setActiveIdentifier(identifier);
    setTaskMessage(null)
  };

  const closeEditor = () => {
    setActiveEditor(null)
  }

  return (
    <>
      {sourceDisplayField === "Finding" && (
        <Divider className={classes.subDivider} />
      )}
      <ContentRowWrapper>
        <ReviewInfoSideColumnWrapper>
          {isSummaryRecommendation ? (
            <>
              <HeaderPrimary
                variant="h3Primary"
                className={classes.labelCount}
              >
                {` Recommendation ${index + 1} `}
              </HeaderPrimary>
              <div className={classes.recommendationHtml}>
                <DisplayHTML html={staticObj[sourceDisplayField]} />
              </div>
            </>
          ) : (
            <LabelAndValue
              label={sourceDisplayField}
              value={staticObj[sourceDisplayField]}
              alignLeft
              labelHeaderVariant="h5Tertiary"
              verticalPositioning
            />
          )}
        </ReviewInfoSideColumnWrapper>
        <ResponseOrBlankSideColumnWrapper>
          {!hideResponse && (
            <div
              className={classNames(
                classes.responseContainer,
                isSummaryRecommendation && classes.summaryResponseWrapper
              )}
            >
              <HeaderPrimary
                variant="h5Primary"
                className={classes.responseHeaderWrapper}
              >
                Response:
              </HeaderPrimary>
              {activeEditor === identifier ? (
                <div>
                  <CustomSunEditor
                    height="80px"
                    onChange={(contents) => (
                      handleEditorOnChange(contents, initialEditorHTML)
                    )}
                    autoFocus
                    name={identifier}
                    defaultValue={editorHTMLContents}
                    onAutosave={() => (
                      handleSaveResponse(
                        reviewType,
                        sourceID,
                        sourceTypeField,
                        initialEditorHTML
                      )
                    )}
                  />
                  <div className={classes.buttonsWrapper}>
                    <ButtonDefault
                      variant="small"
                      background="secondary"
                      data-cy="btn-finish-response"
                      endIcon={
                        <CachedOutlined
                          className={classes.buttonIcon}
                        />
                      }
                      onClick={closeEditor}
                      disableReadOnlyUsers
                    >
                      Finish
                    </ButtonDefault>
                  </div>
                </div>
              ) : (
                <div className={classes.noResponsesOuterWrapper}>
                  <div
                    data-cy={
                      isSummaryRecommendation
                        ? `response-editor-Recommendation${index + 1}`
                        : `response-editor-${test}`
                    }
                    className={classNames(
                      classes.inactiveResponse,
                      initialEditorHTML === emptyEditorHTML
                        ? classes.noResponseInnerWrapper
                        : undefined
                    )}
                    onClick={() => (
                      activateEditor(identifier, initialEditorHTML)
                    )}
                  >
                    {initialEditorHTML !== emptyEditorHTML ? (
                      <DisplayHTML html={initialEditorHTML} />
                    ) : (
                      <div className={classes.noResponse}>
                        <AddCircleOutlineOutlinedIcon
                          className={classes.addIcon}
                        />
                        Add Response
                      </div>
                    )}
                  </div>
                  <div className={classes.addTaskRow}>
                    {activeIdentifier === identifier && (
                      <div className={classes.taskMessage}>{taskMessage}</div>
                    )}
                    <div className={classes.addTaskLink}>
                      <AddListItem
                        test="Add Task"
                        text="Add Task"
                        variant="iconBeforeLink"
                        onClick={() => openTaskForm()}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </ResponseOrBlankSideColumnWrapper>
      </ContentRowWrapper>
      {!hideBottomDivider && <Divider className={classes.subDivider} />}
      <CustomModal open={openModal} onClose={() => setOpenModal(false)}>
        <TaskForm
          setEditModal={setOpenModal}
          formMode="create"
          formType="task"
          taskSource={responseSources[displayType].taskTitle}
          taskReferences={makeTaskReferences(
            displayType,
            staticObj[sourceIDName],
            builderInfo,
            staticObj
          )}
          setTaskMessage={setTaskMessage}
        />
      </CustomModal>
    </>
  );
};

export default ResponseSection;
