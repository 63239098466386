import {
  makeStyles,
  MenuItem
} from "@material-ui/core";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import Form from "components/utils/form-elements/form.component"
import LabelInput from "components/utils/form-elements/labelInput.component";
import CustomSelect from "components/utils/form-elements/select.component";
import { useCallback, useState } from "react";
import RunbookService from "services/runbook.service";
import { ACTION_ADD_WORKGROUP, ACTION_REPLACE_WORKGROUP } from "reducers/runbook/runbooks.reducer";
import FormBanner from "components/utils/form-elements/formBanner.component";
import { VALIDATION_REQUIRED } from "utils/formValidators";

const useStyles = makeStyles((theme) => ({
  form: {
    padding: "24px 32px",
  },
  assigneeField: {
    width: 280,
    marginTop: 16,
    marginBottom: 24
  }
}));

export const EditWorkgroupForm = (props) => {
  const { formMode, activeWorkgroup, assignableAdminUsers, onClose, dispatch } = props;
  const classes = useStyles();
  const [formData, setFormData] = useState({
    Name: activeWorkgroup.Name,
    Description: activeWorkgroup.Description,
    Admin_ID: activeWorkgroup.Admin_ID,
  });

  const handleFormUpdate = useCallback((name, value) => {
    setFormData({ ...formData, [`${name}`]: value });
  }, [formData]);

  const handleWorkgroupUpsert = useCallback(async () => {
    const workgroup = {
      Workgroup_ID: activeWorkgroup?.Workgroup_ID,
      ...formData,
    }
    const workgroupsResponse = await RunbookService.upsertWorkgroup(workgroup);

    if (!workgroup.Workgroup_ID) {
      dispatch({
        type: ACTION_ADD_WORKGROUP,
        payload: workgroupsResponse.payload,
      });
    } else {
      dispatch({
        type: ACTION_REPLACE_WORKGROUP,
        payload: workgroupsResponse.payload,
      })
    }
    onClose();
  }, [formData, onClose, dispatch, activeWorkgroup]);

  return (
    <div>
      <FormBanner>{formMode === "edit" ? "Edit" : "Add New"}{` Workgroup`}
      </FormBanner>
      <Form
        className={classes.form}
        name="Workgroup-Form"
        onSubmit={handleWorkgroupUpsert}
        validations={{
          Name: [{ type: VALIDATION_REQUIRED }],
          Description: [{ type: VALIDATION_REQUIRED }],
          Admin_ID: [{ type: VALIDATION_REQUIRED }],
        }}
      >
        <LabelInput
          required
          name="Name"
          onChange={(event) => handleFormUpdate("Name", event.target.value)}
          label="Name"
          variant="default"
          test="builder-Name"
          value={formData?.Name || ""}
        />
        <LabelInput
          required
          name="Description"
          label="Description"
          variant="default"
          test="builder-Description"
          value={formData?.Description || ""}
          onChange={(event) => handleFormUpdate("Description", event.target.value)}
          multiline
        />
        <div className={classes.assigneeField}>
          <CustomSelect
            label="Assigned Admin User*"
            name="Admin_ID"
            value={formData?.Admin_ID || ""}
            onChange={(event) => handleFormUpdate("Admin_ID", event.target.value)}
          >
            {assignableAdminUsers?.length > 0 &&
              assignableAdminUsers?.map(user => (
                <MenuItem
                  key={`user-${user.User_ID}`}
                  value={user.User_ID}
                  id={user.User_ID}
                  data-cy={`assign-${user.First_Name} ${user.Last_Name}`}
                >
                  {`${user.First_Name} ${user.Last_Name}`}
                </MenuItem>
              ))}
          </CustomSelect>
        </div>
        <DualFormButtons
          cancelOnClick={onClose}
        />
      </Form>
    </div>
  )
}

