import authService from "services/auth.service";
import { COMPONENT_OBJECT_ID_TRAINING_ASSIGNMENTS } from "utils/programConstants";

const excludedComponentObjectRoles = {
  [COMPONENT_OBJECT_ID_TRAINING_ASSIGNMENTS]: [authService.Type.TRAINEE]
};

export const getEnabledComponentObjects = (componentObjects, user) => (
  componentObjects.filter(({ ComponentObject_ID }) => {
    const excluded = excludedComponentObjectRoles[ComponentObject_ID];
    return !excluded?.includes?.(user.role);
  })
);
