import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styleVariables from "styleVariables";
import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Box,
  TableContainer,
} from "@material-ui/core";
import TableCell from "components/utils/tables/shared/tableCell.component";
import classNames from "classnames";
import Header from "components/utils/header.component";

const useStyles = makeStyles((theme) => ({
  emptRow: {
    color: styleVariables.textSecondary,
    display: "flex",
    justifyContent: "center",
  },
  //HEAD
  cellHeader: {
    backgroundColor: styleVariables.tertiary1,
    color: "white",
    fontWeight: "bold",
    fontSize: styleVariables.fontSmall,
    padding: "8px 16px",
    lineHeight: "normal",
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  //BODY
  cellBody: {
    //default cell body
    padding: "8px 16px",
    color: styleVariables.textSecondary,
    fontSize: styleVariables.fontSmall,
    borderLeft: "1px solid rgba(22, 76, 146, 0.22)",
    verticalAlign: "top",
  },
  wideCell: {
    minWidth: 240,
  },
  //ALIGN
  centerAlign: { textAlign: "center" },
  //HeaderSection
  headerSection: {
    display: "flex",
    flexDirection: "column",
    padding: "8px 16px",
  },
  title: {
    padding: 0,
  },
}));

const SimpleSmallTable = ({
  headers, //ex. {fieldName: "First_Name" ,display: "First Name", align: "center" size: "large"}
  tableData,
  primaryKey,
  //optional
  maxHeight,
  emptyMessage,
  title,
}) => {
  const classes = useStyles();

  return (
    <Paper>
      {!!title && (
        <Box className={classes.headerSection}>
          <Header variant="h4Primary" className={classes.title} noTransform>
            {title}
          </Header>
        </Box>
      )}

      <TableContainer
        style={{ maxHeight, minWidth: 500 }}
      >
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headers.map((head, index) => {
                const { display, fieldName, align } = head;
                return (
                  <TableCell
                    key={fieldName}
                    className={classNames(
                      classes.cellHeader,
                      align === "center" && classes.centerAlign
                    )}
                  >
                    {display}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody className={classes.orderedList}>
            {tableData.map(row => {
              const idValue = row[primaryKey];
              return (
                <TableRow key={idValue}>
                  {headers.map(header => {
                    return (
                      <TableCell
                        key={header.fieldName}
                        className={classNames(
                          classes.cellBody,
                          header.align === "center" && classes.centerAlign,
                          header.size === "large" && classes.wideCell
                        )}
                      >
                        {row[header.fieldName]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
            {tableData?.length === 0 && (
              <TableRow>
                <TableCell
                  className={classes.tableCell}
                  colSpan={headers.length}
                >
                  <div className={classes.emptRow}>
                    {emptyMessage || "No data available"}
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
export default SimpleSmallTable;
