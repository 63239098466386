import { Box, Divider, Grid, Paper } from "@material-ui/core";
import DateInput from "components/utils/form-elements/dateInput.component";
import Header from "components/utils/header.component";
import React, {
  useEffect,
  useMemo,
  useReducer,
  useCallback,
  useState,
} from "react";
import CertificationService from "services/certification.service";
import { makeStyles } from "@material-ui/core";
import LabelInput from "components/utils/form-elements/labelInput.component";
import NumberInput from "components/utils/form-elements/numberInput.component";
import Checkbox from "components/utils/form-elements/checkbox.component";
import CustomLink from "components/utils/link.component";
import classNames from "classnames";
import certificationReducer, {
  ACTION_SET_PASI_GROUPS,
  certificationInitialState,
  ACTION_SET_AIRCRAFTS,
} from "reducers/certification/certification.reducer";
import Form from "components/utils/form-elements/form.component";
import styleVariables from "styleVariables";
import PasiAircraftTable from "./pasiAircraftTable.component";
import {
  CERTIFICATION_PROGRAM_ID,
  certProgCompIds,
} from "utils/certificationConstants";
import Loader from "components/utils/loader.components";

const useStyles = makeStyles((theme) => ({
  groupBoxBorderBottom: {
    borderBottom: `1px solid ${styleVariables.rowBorder}`,
  },
  dateInputWrapper: {
    width: 140,
    marginBottom: 16,
  },
  childCheckboxContainer: {
    marginLeft: 32,
    marginBottom: 8,
  },
  nestedChildCheckboxContainer: {
    marginLeft: 32,
  },
  mediumInput: {
    width: 180,
  },
  shortInput: {
    width: 60,
  },
  longInput: {
    width: 700,
  },
}));

const findFirstAndLastMgmtIds = (groups) => {
  const mgmtGroups = groups.filter(
    (group) => group.Type === "managementPersonnel"
  );
  let lowestOrder = Infinity;
  let lowestId;
  let highestOrder = 0;
  let highestId;
  for (const mgmtGroup of mgmtGroups) {
    const groupId = mgmtGroup.PasiGroup_ID;
    const groupOrder = mgmtGroup.Group_Order;
    if (groupOrder < lowestOrder) {
      lowestOrder = groupOrder;
      lowestId = groupId;
    }
    if (groupOrder > highestOrder) {
      highestOrder = groupOrder;
      highestId = groupId;
    }
  }
  return [lowestId, highestId];
};

const GroupHeader = ({ group }) => {
  return (
    <Header variant="h5Primary" noTransform>
      {group.Group_Order}. {group.Label}
    </Header>
  );
};

const PasiPage = () => {
  const [state, dispatch] = useReducer(
    certificationReducer,
    certificationInitialState
  );
  const [firstAndLastdMgmtGroupId, setFirstAndLastdMgmtGroupId] = useState([]);

  useEffect(() => {
    const getPasi = async () => {
      const response = await CertificationService.getPasiGroups();
      dispatch({
        type: ACTION_SET_PASI_GROUPS,
        payload: response.payload,
      });

      setFirstAndLastdMgmtGroupId(findFirstAndLastMgmtIds(response.payload));
    };
    getPasi();

    const getAircraft = async () => {
      const response = await CertificationService.getAllAircraft();
      dispatch({
        type: ACTION_SET_AIRCRAFTS,
        payload: response.payload,
      });
    };
    getAircraft();
  }, []);

  const readyToLoadForm = useMemo(() => {
    return (
      !!state.pasiGroups &&
      firstAndLastdMgmtGroupId.length === 2 &&
      !!state.aircrafts
    );
  }, [state.pasiGroups, state.aircrafts, firstAndLastdMgmtGroupId]);

  return (
    <Box marginTop={6} marginBottom={6} component={Paper}>
      {!readyToLoadForm ? (
        <Loader />
      ) : (
        <Form name="pasi-form">
          {state.pasiGroups?.map?.((group) => {
            return (
              <PasiGroup
                group={group}
                dispatch={dispatch}
                key={group.PasiGroup_ID}
                firstAndLastdMgmtGroupId={firstAndLastdMgmtGroupId}
                aircrafts={group.Type === "aircraft" && state.aircrafts}
              />
            );
          })}
        </Form>
      )}
    </Box>
  );
};

const PasiGroup = ({
  group,
  dispatch,
  firstAndLastdMgmtGroupId,
  aircrafts,
}) => {
  const classes = useStyles();
  const questions = group._associations.PasiQuestion;
  const firstMgmtGroupId = firstAndLastdMgmtGroupId?.[0];
  const lastMgmtGroupId = firstAndLastdMgmtGroupId?.[1];

  const handleSaveDC = useCallback(
    async (name, value, pasiQuestionId, pasiDcId) => {
      let inputValue = value;
      if (value === true) {
        inputValue = "1";
      }
      if (value === false) {
        inputValue = "0";
      }
      const data = {
        PasiDC_ID: pasiDcId,
        Input_Value: inputValue,
      };
      const response = await CertificationService.upsertPasiDc(
        data,
        pasiQuestionId
      );
      dispatch({
        type: ACTION_SET_PASI_GROUPS,
        payload: response.payload,
      });
      return null;
    },
    [dispatch]
  );

  const checkHandler = useCallback(
    (event, questionId, pasiDcId) => {
      handleSaveDC(event.target.name, event.target.checked, questionId, pasiDcId);
    },
    [handleSaveDC]
  );

  if (group.Type === "nestedCheckbox") {
    return (
      <Box padding={2} className={classes.groupBoxBorderBottom} data-cy="pasi-nestedCheckbox-type">
        <GroupHeader group={group} />
        <Box paddingLeft={3}>
          <NestedCheckboxesGroup
            questions={questions}
            checkHandler={checkHandler}
          />
        </Box>
      </Box>
    );
  }

  if (group.Type === "aircraft") {
    return (
      <Box padding={2} className={classes.groupBoxBorderBottom} data-cy="pasi-aircraft-type">
        <GroupHeader group={group} />
        <Grid container>
          <Box paddingLeft={3} paddingTop={1.5}>
            <PasiAircraftTable aircrafts={aircrafts} />
            <Box paddingLeft={1} paddingTop={1}>
              <CustomLink
                variant="underline"
                href={`/program/${CERTIFICATION_PROGRAM_ID}/${certProgCompIds.AIRCRAFT}`}
              >
                Go to Aircraft Page
              </CustomLink>
            </Box>
          </Box>
        </Grid>
      </Box>
    );
  }

  return (
    <>
      {group.PasiGroup_ID === firstMgmtGroupId && (
        <Box paddingTop={2} marginLeft={1}>
          <Header variant="h4Primary">Management Personnel</Header>
        </Box>
      )}
      <Box
        padding={2}
        className={classNames(
          group.Type !== "managementPersonnel" ||
            group.PasiGroup_ID === lastMgmtGroupId
            ? classes.groupBoxBorderBottom
            : undefined
        )}
        data-cy="pasi-default-type"
      >
        <GroupHeader group={group} />
        <Box paddingLeft={group.Group_Order > 9 ? 3 : 2} paddingTop={1}>
          <PasiQuestions
            questions={questions}
            handleSaveDC={handleSaveDC}
            checkHandler={checkHandler}
          />
        </Box>
      </Box>
    </>
  );
};

const findGroupByParent = (questions, parentQuestionId) => {
  const childrenQuestions = {};
  questions.forEach((question) => {
    if (question.PasiQuestion_ID !== parentQuestionId) {
      if (question.Parent_Question === parentQuestionId) {
        childrenQuestions[question.PasiQuestion_ID] = {
          parentChild: question,
          nestedChildren: [],
        };
      } else {
        childrenQuestions?.[question.Parent_Question]?.nestedChildren.push(
          question
        );
      }
    }
  });
  return childrenQuestions;
};

const NestedCheckboxesGroup = ({ questions, checkHandler }) => {
  const classes = useStyles();

  const parentQuestions = useMemo(() => {
    return (
      questions?.filter((question) => {
        return !question.Parent_Question;
      }) || []
    );
  }, [questions]);

  const firstCheckboxGroup = useMemo(() => {
    if (!questions?.length || !parentQuestions.length) {
      return null;
    }
    return findGroupByParent(questions, parentQuestions[0].PasiQuestion_ID);
  }, [questions, parentQuestions]);

  const secondCheckboxGroup = useMemo(() => {
    if (!questions?.length || parentQuestions.length !== 2) {
      return null;
    }
    return findGroupByParent(questions, parentQuestions[1].PasiQuestion_ID);
  }, [questions, parentQuestions]);

  return parentQuestions?.length > 0 ? (
    <div>
      {[firstCheckboxGroup, secondCheckboxGroup]?.map?.((checkboxGroup, index) => {
        const isChecked = (
          parentQuestions[index]._associations.PasiDC[0]?.Input_Value === "1"
        );
        return (
          <div key={index}>
            <Grid item sm={12}>
              <Checkbox
                label={parentQuestions[index]?.Label}
                test={parentQuestions[index]?.Label}
                name={`Checkbox_${parentQuestions[index].PasiQuestion_ID}`}
                checked={isChecked}
                onChange={(event) => (
                  checkHandler(
                    event,
                    parentQuestions[index].PasiQuestion_ID,
                    parentQuestions[index]._associations.PasiDC[0]?.PasiDC_ID
                  )
                )}
              />
            </Grid>
            {Object.keys(checkboxGroup)?.map?.((parentChildId) => {
              const parentChildQuestion =
                checkboxGroup[parentChildId].parentChild;
              const nestedQuestions =
                checkboxGroup[parentChildId].nestedChildren;
              return (
                <Grid
                  container
                  className={classes.childCheckboxContainer}
                  key={parentChildId}
                >
                  <Grid item xl={12}>
                    <Checkbox
                      label={parentChildQuestion?.Label}
                      test={parentChildQuestion?.Label}
                      name={`Checkbox_${parentChildQuestion?.PasiQuestion_ID}`}
                      checked={
                        parentChildQuestion?._associations.PasiDC[0]
                          ?.Input_Value === "1"
                      }
                      onChange={(event) => (
                        checkHandler(
                          event,
                          parentChildQuestion?.PasiQuestion_ID,
                          parentChildQuestion?._associations.PasiDC[0]
                            ?.PasiDC_ID
                        )
                      )}
                    />
                  </Grid>
                  <Grid
                    container
                    className={classes.nestedChildCheckboxContainer}
                  >
                    {nestedQuestions?.map?.((question) => {
                      const dataCollection = question._associations.PasiDC[0];
                      return (
                        <Grid item key={question.PasiQuestion_ID}>
                          <Checkbox
                            label={question.Label}
                            test={question.Label}
                            name={`Checkbox_${question.PasiQuestion_ID}`}
                            checked={dataCollection?.Input_Value === "1"}
                            onChange={(event) => (
                              checkHandler(
                                event,
                                question.PasiQuestion_ID,
                                dataCollection?.PasiDC_ID
                              )
                            )}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              );
            })}

            {index === 0 && (
              <Box margin={2}>
                <Divider />
              </Box>
            )}
          </div>
        );
      })}
    </div>
  ) : (
    ""
  );
};

const PasiQuestions = ({ questions, handleSaveDC, checkHandler }) => {
  return (
    <Grid spacing={2} container>
      {questions?.map?.((question) => (
        <Grid item key={question.PasiQuestion_ID}>
          <QuestionAndInput
            question={question}
            handleSaveDC={handleSaveDC}
            checkHandler={checkHandler}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const QuestionAndInput = ({ question, handleSaveDC, checkHandler }) => {
  const classes = useStyles();
  const dataCollection = question._associations.PasiDC[0];

  const maxLength = useMemo(() => {
    if (question.Form?.startsWith("length")) {
      return parseInt(question.Form.slice(6), 10);
    }
    return null;
  }, [question]);

  if (question.Form === "date") {
    return (
      <div className={classes.dateInputWrapper}>
        <DateInput
          name={`Input_Date_${question.PasiQuestion_ID}`}
          label={question.Label}
          test={question.Label || `date-${question.PasiQuestion_ID}`}
          margin="dense"
          variant="default"
          defaultValue={dataCollection?.Input_Value}
          onAutosave={(name, value) => (
            handleSaveDC(
              name,
              value,
              question.PasiQuestion_ID,
              dataCollection?.PasiDC_ID
            )
          )}
        />
      </div>
    );
  }
  if (question.Form === "number") {
    return (
      <div className={classes.dateInputWrapper}>
        <NumberInput
          name={`Input_Number_${question.PasiQuestion_ID}`}
          label={question.Label}
          margin="dense"
          variant="default"
          defaultValue={dataCollection?.Input_Value}
          onAutosave={(name, value) => (
            handleSaveDC(
              name,
              value,
              question.PasiQuestion_ID,
              dataCollection?.PasiDC_ID
            )
          )}
        />
      </div>
    );
  }
  if (question.Form === "checkbox") {
    return (
      <div className={classes.dateInputWrapper}>
        <Checkbox
          name={`Checkbox_${question.PasiQuestion_ID}`}
          label={question.Label}
          margin="dense"
          variant="default"
          checked={dataCollection?.Input_Value === "1"}
          onChange={(event) => (
            checkHandler(
              event,
              question.PasiQuestion_ID,
              dataCollection?.PasiDC_ID
            )
          )}
          test={question.Label}
        />
      </div>
    );
  }
  return (
    <div
      className={classNames(
        classes.mediumInput,
        question.Form === "longtext" ? classes.longInput : undefined,
        maxLength > 0 && maxLength < 4 ? classes.shortInput : undefined
      )}
    >
      <LabelInput
        label={question.Label}
        name={`Input_Value_${question.PasiQuestion_ID}`}
        margin="dense"
        variant="default"
        defaultValue={dataCollection?.Input_Value}
        inputProps={{ maxLength }}
        multiline={question.Form === "longtext"}
        rows={question.Form === "longtext" ? 10 : null}
        placeholder={question.Form === "tel" ? "(XXX)XXX-XXXX" : undefined}
        onAutosave={(name, value) => (
          handleSaveDC(
            name,
            value,
            question.PasiQuestion_ID,
            dataCollection?.PasiDC_ID
          )
        )}
        test={question.Label}
      />
    </div>
  );
};
export default PasiPage;
