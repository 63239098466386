import { useMemo, useState } from "react";
import HeaderPrimary from "components/utils/header.component";
import { makeStyles } from "@material-ui/core";
import RiskAssessmentService from "services/riskAssessment.service";
import classNames from "classnames";
import { dataCollectionTypeIds, assessBuilderStatus } from "utils/assessmentConstants";
import Loader from "components/utils/loader.components";
import ReviewItemsTable from "components/assessment/review/tables/reviewItemsTable.component";
import CustomLink from "components/utils/link.component";
import { formattedMomentCustomError } from "utils/dateHelpers";
import ReviewReport from "components/assessment/review/reviewReport.component";
import LabelAndValue from "components/utils/labelAndValue.component";
import variables from "styleVariables";
import moment from "moment";
import ButtonDefault from "components/utils/buttonDefault.component";
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import Dialog from "components/utils/dialog.component";
import CustomModal from "components/utils/modal.component";
import AssessmentAssessorsForm from "components/forms/assessmentAssessorsForm";
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import DataCollectionCount from "components/assessment/shared/dataCollectionCount.component";
import { tallyReviewStatus } from "utils/assessmentHelpers";
import { currentUserID } from "utils/userHelpers";
import { isReadOnly } from "utils/roles";

const useStyles = makeStyles(theme => ({
  loader: {
    width: "100%",
    height: 350,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  summaryContainer: {
    display: "flex",
    flexDirection: "column",
  },
  summaryHeadingBlock: {
    width: "100%",
    backgroundColor: variables.tertiary1,
  },
  summaryHeadingWrapper: {
    maxWidth: 1700,
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 50,
    paddingRight: 50,
    margin: "0px auto",
  },
  assessmentItemsHeader: {
    paddingLeft: 50,
    maxWidth: 600,
  },
  summaryContentContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 20,
    maxWidth: 1700,
    flexDirection: "row",
    margin: "0px auto",
    width: "100%",
  },
  summaryGroup1: {
    display: "flex",
    flexDirection: "row",
    paddingRight: 30,
  },
  summaryGroup2: {
    display: "flex",
    flexDirection: "row",
    minWidth: 415,
    justifyContent: "flex-end",
  },
  summaryCell: {
    marginRight: 30,
    minWidth: 50,
    display: "flex",
    flexFlow: "column",
    paddingTop: 5,
  },
  buttonContainer: {
    display: "inline-flex",
  },
  buttonWrapper: {
    marginLeft: 10,
  },
  completeIcon: {
    marginRight: 8,
  },
  reportIcon: {
    marginRight: 8,
  },
  reportDialog: {
    wordBreak: "break-word"
  },
  reviewPageLoader: {
    display: "flex",
    flexDirection: "column",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  labelValueRow: {
    paddingBottom: 5,
  },
  valueText: {
    color: variables.textSecondary,
  },
  descriptionCell: {
    flex: 3,
  },
  addAssessorLink: {
    paddingTop: 10,
  },
  tableWrapper: {
    paddingTop: "20px",
    paddingLeft: 50,
    paddingRight: 50,
    maxWidth: 1700,
    margin: "0px auto",
    marginBottom: 100,
  },
  underTable: {
    display: "flex",
    alignItems: "center",
    padding: "20px 5px 0px 5px",
    width: "100%",
    justifyContent: "flex-end",
  },
  dataCollectionBar: {
    display: "flex",
    position: "fixed",
    padding: "20px 20px",
    width: "100%",
    bottom: 0,
    zIndex: 10,
    backgroundColor: "white",
    left: 0,
    justifyContent: "space-between",
    borderLeft: `5px solid ${variables.primaryMain}`,
    boxShadow: variables.shadowAppbar,
  },
  dialogContentWrapper: {
    width: "fit-content",
    padding: "30px 35px 20px 0px",
    margin: "0px auto",
  }
}));

const ROLE_LEAD = "lead";

const reviewTypesWithPerformance = [
  dataCollectionTypeIds.DATA_COLLECTION_TYPE_PERFORMANCE,
  dataCollectionTypeIds.DATA_COLLECTION_TYPE_ALL
];
const reviewTypesWithDesign = [
  dataCollectionTypeIds.DATA_COLLECTION_TYPE_DESIGN,
  dataCollectionTypeIds.DATA_COLLECTION_TYPE_ALL
];

// update promptVariants and promptMessage to add additional Dialog options
const promptVariants = {
  CLOSE_ASSESSMENT: "Complete Assessment",
}

const promptMessage = (prompVariant, builderTitle) => {
  if (prompVariant === promptVariants.CLOSE_ASSESSMENT) {
    return `Are you sure you want to close the "${builderTitle}" Assessment?`
  }
}

const ReviewItemsPage = ({
  builderInfo,
  assessmentItems,
  assessors,
  buildAssessmentData,
  designItems,
  performanceItems,
  recordFormData,
  references,
  setBuilderInfo,
  setMode,
  setReviewPageMode,
  setAssessors,
  summaryRecommendations,
  withUnsavedChanges,
  setHasUnsavedChanges,
}) => {
  const classes = useStyles();
  const [isCompleting, setIsCompleting] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const readOnly = useMemo(isReadOnly, []);

  const recommendationsLinkText = useMemo(() => {
    if (summaryRecommendations.length > 0) {
      return readOnly
        ? "View Summary Recommendations"
        : "Add/Edit Summary Recommendations";
    } else {
      return "Add Summary Recommendation";
    }
  }, [readOnly, summaryRecommendations]);


  const assessTypeText = (revType) => {
    if (revType === 1) {
      return "Design";
    } else if (revType === 2) {
      return "Performance";
    } else if (revType === 3) {
      return "Design & Performance";
    } else {
      return "None Selected";
    }
  };

  const leadAssessors = assessors.filter(
    (assessor) => assessor.Assessment_Role === ROLE_LEAD
  );
  const standardAssessors = assessors.filter(
    (assessor) => assessor.Assessment_Role !== ROLE_LEAD
  );

  const tableRows = useMemo(() =>
    designItems && performanceItems && assessmentItems?.map(item => ({
      assessmentItem: item,
      designItem: designItems.find(({ AssessmentItem_ID }) => (
        AssessmentItem_ID === item.AssessmentItem_ID
      )),
      performanceItems: performanceItems.filter(({ AssessmentItem_ID }) => (
        AssessmentItem_ID === item.AssessmentItem_ID
      ))
    })),
    [assessmentItems, designItems, performanceItems]
  );

  const hasDesign = useMemo(() =>
    reviewTypesWithDesign.includes(builderInfo.Review_Type), [builderInfo]);
  const hasPerformance = useMemo(() => reviewTypesWithPerformance.includes(builderInfo.Review_Type), [builderInfo]);

  const designSummary = useMemo(() => (
    (tableRows && hasDesign) ? tallyReviewStatus(tableRows.map(row => row.designItem)) : null
  ), [tableRows, hasDesign]);
  const performanceSummary = useMemo(() => (
    (tableRows && hasPerformance) ? tallyReviewStatus(tableRows.map(row => row.performanceItems)) : null
  ), [tableRows, hasPerformance]);

  const changeBuilderStatus = async () => {
    const userID = currentUserID()
    const newAssessmentData = buildAssessmentData()
    const newStatus = openDialog === promptVariants.CLOSE_ASSESSMENT ? assessBuilderStatus.BUILDER_STATUS_ASSESSED : assessBuilderStatus.BUILDER_STATUS_RESPONDED
    await RiskAssessmentService.updateAssessmentData(
      {
        ...newAssessmentData,
        Status: newStatus,
        Completion_User: userID,
        Completion_Date: moment(new Date()).format("YYYY-MM-DD"),
      },
      recordFormData.Builder_ID,
      1
    );
    setBuilderInfo({
      ...builderInfo,
      Status: newStatus,
      Completion_User: userID, //current logged in user
      Completion_Date: moment(new Date()).format("YYYY-MM-DD"), //current date
    })
  }

  const handleComplete = async () => {
    setIsCompleting(true);
    await changeBuilderStatus();
    setIsCompleting(false);
    setMode();
  }

  if (!tableRows) {
    return (
      <div className={classNames(classes.loader)}>
        <Loader />
      </div>
    );
  }
  return (
    <>
      <div className={classNames(classes.summaryContainer)}>
        <div className={classNames(classes.summaryHeadingBlock)}>
          <div className={classNames(classes.summaryHeadingWrapper)}>
            <div className={classes.assessmentTitle}>
              <HeaderPrimary variant="h1White">
                {builderInfo.Title}
              </HeaderPrimary>
            </div>
            <div className={classes.assessmentItemsHeader} >
              <HeaderPrimary variant="h4White" test="summary-total-items">
                Total assessment items: {tableRows.length}
              </HeaderPrimary>
            </div>
          </div>
        </div>

        <div className={classes.summaryContentContainer}>
          <div className={classes.summaryGroup1}>
            <div className={classNames(classes.summaryCell)}>
              <LabelAndValue
                className={
                  classNames(
                    classes.labelValueRow, classes.flexColumn, classes.valueText
                  )
                }
                label={`Lead Assessor${leadAssessors.length !== 1 ? "s" : ""}`}
                value={
                  <div>
                    {leadAssessors.length > 0
                      ? leadAssessors
                        .map(
                          (assessor) =>
                            `${assessor.First_Name} ${assessor.Last_Name}`
                        )
                        .join(", ")
                      : "None"}
                  </div>
                }
              />
              <LabelAndValue
                className={
                  classNames(
                    classes.labelValueRow, classes.flexColumn, classes.valueText
                  )
                }
                label="Assessors"
                value={
                  <div>
                    {standardAssessors.length > 0
                      ? standardAssessors
                        .map(assessor => (
                          `${assessor.First_Name} ${assessor.Last_Name}`
                        ))
                        .join(", ")
                      : "None"}
                  </div>
                }
              />
              <div className={classes.addAssessorLink}>
                {!readOnly && (
                  <CustomLink
                    variant="underline"
                    onClick={() => setOpenModal("addAssessor")}
                  >
                    Edit Assessment Assignments
                  </CustomLink>
                )}
              </div>

            </div>
            <div className={classNames(classes.summaryCell)}>
              <LabelAndValue
                label="Start"
                value={
                  formattedMomentCustomError(
                    moment(builderInfo.Start_Date).format("M/D/YYYY")
                  )
                }
              />
              <LabelAndValue
                label="End"
                value={
                  formattedMomentCustomError(
                    moment(builderInfo.End_Date).format("M/D/YYYY")
                  )
                }
              />
            </div>
            <div
              className={
                classNames(classes.summaryCell, classes.descriptionCell)
              }
            >
              <LabelAndValue
                className={
                  classNames(
                    classes.flexColumn,
                    classes.valueText,
                    classes.labelValueRow
                  )
                }
                label="Assessment Type"
                value={assessTypeText(builderInfo.Review_Type)}
              />
              <LabelAndValue
                className={
                  classNames(
                    classes.flexColumn,
                    classes.valueText,
                    classes.labelValueRow
                  )
                }
                label="Description"
                value={builderInfo.Description || "None"}
              />
            </div>
          </div>
          <div className={classes.summaryGroup2}>
            <DataCollectionCount
              designSummary={designSummary}
              performanceSummary={performanceSummary}
            />
          </div>
        </div>
      </div>
      <div className={classes.tableWrapper}>
        <ReviewItemsTable
          hasDesign={hasDesign}
          hasPerformance={hasPerformance}
          rowData={tableRows}
          setReviewPageMode={setReviewPageMode}
          builderInfo={builderInfo}
        />
        <div className={classes.underTable}>
          {builderInfo.Status === assessBuilderStatus.BUILDER_STATUS_ACTIVE && (
            <CustomLink
              variant="underline"
              onClick={() => setReviewPageMode("summaryRecommendation")}
              test="btn-recommendation-summary Assessment"
            >
              {recommendationsLinkText}
            </CustomLink>
          )}
          {builderInfo.Status === assessBuilderStatus.BUILDER_STATUS_ASSESSED && (
            <CustomLink
              variant="underline"
              onClick={() => setReviewPageMode("summaryResponses")}
              test="btn-recommendation-summary-responses"
            >
              View Summary and {readOnly ? "Provide" : ""} Responses
            </CustomLink>
          )}
        </div>
        <div className={classes.underTable}>
          {builderInfo.Status === assessBuilderStatus.BUILDER_STATUS_ASSESSED && (
            <CustomLink
              variant="underline"
              onClick={() => setReviewPageMode("roadmaps")}
              test="btn-roadmaps"
            >
              View Roadmap
            </CustomLink>
          )}
        </div>
      </div>
      <div className={classes.dataCollectionBar}>
        <HeaderPrimary variant="h2Primary">
          Summary
        </HeaderPrimary>
        <div className={classes.buttonContainer}>
          <div className={classes.buttonWrapper}>
            <ButtonDefault
              variant="medium"
              background="secondary"
              data-cy="btn-view-report"
              onClick={() => setOpenModal("summary")}
              startIcon={
                <AssessmentOutlinedIcon
                  htmlColor="white"
                  className={classes.reportIcon}
                />
              }
            >
              View Report
            </ButtonDefault>
          </div>
          {builderInfo.Status !== assessBuilderStatus.BUILDER_STATUS_ASSESSED && (
            <div className={classes.buttonWrapper}>
              <ButtonDefault
                variant="medium"
                background="primary"
                data-cy="btn-complete-assessment"
                onClick={() => setOpenDialog(promptVariants.CLOSE_ASSESSMENT)}
                startIcon={
                  <SaveOutlinedIcon
                    htmlColor="white"
                    className={classes.completeIcon}
                  />
                }
                disableReadOnlyUsers
              >
                Complete Assessment
              </ButtonDefault>
            </div>
          )}
        </div>
      </div>
      {!!openDialog && (
        <Dialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          prompt={
            isCompleting ?
              `Closing "${builderInfo.Title}" Assessment` :
              promptMessage(promptVariants.CLOSE_ASSESSMENT, builderInfo.Title)
          }
          confirm={() => handleComplete()}
          className={classes.reportDialog}
          largeLabel
          hideButtons={isCompleting}
        >
          {isCompleting ? (
            <div
              className={classes.reviewPageLoader}
              data-cy="loader-closing-assessment"
            >
              <Loader />
            </div>
          ) : (
            <div className={classes.dialogContentWrapper}>
              <DataCollectionCount
                designSummary={designSummary}
                performanceSummary={performanceSummary}
              />
            </div>
          )}
        </Dialog>
      )}
      <CustomModal open={openModal} onClose={() => setOpenModal(false)}>
        {openModal === "addAssessor" && (
          <AssessmentAssessorsForm
            setOpenModal={setOpenModal}
            setAssessors={setAssessors}
            assessors={assessors}
            builderInfo={builderInfo}
            withUnsavedChanges={withUnsavedChanges}
            setHasUnsavedChanges={setHasUnsavedChanges}
          />
        )}
        {openModal === "summary" && (
          <ReviewReport
            assessmentItems={assessmentItems}
            designItems={designItems}
            performanceItems={performanceItems}
            designSummary={designSummary}
            performanceSummary={performanceSummary}
            assessors={assessors}
            references={references}
            itemCount={tableRows.length}
            builderInfo={builderInfo}
          />
        )}
      </CustomModal>
    </>
  );
};

export default ReviewItemsPage;
