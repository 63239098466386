import defaultLabels from 'suneditor/src/lang/en'

export const builderLabels = {
  ...defaultLabels,
  dialogBox: {
    ...defaultLabels.dialogBox,
    imageBox: {
      ...defaultLabels.dialogBox.imageBox,
      title: "Insert image - 2MB limit total images",
    },
  },
};
