import { makeStyles, TableHead, TableRow } from "@material-ui/core";
import TableCell from "components/utils/tables/shared/tableCell.component";
import classNames from "classnames";
import { COLUMN_TYPE_TEXT, COLUMN_TYPE_USER } from "./constants/reconciliationTableConstants";

const useStyles = makeStyles((theme) => ({
  scrollRefContainer: {
    position: "relative"
  },
  scrollRef: {
    position: "absolute",
    top: -90,
    width: 1,
    height: 1
  },
  tableHeaderCell: {
    background: theme.palette.primary.main,
    color: "white",
    fontWeight: 600
  },
  tableNameCell: {
    minWidth: 160
  },
  tableTextCell: {
    minWidth: 160
  }
}));

export default function ReconciliationTableHead(props) {
  const classes = useStyles();
  const { columns, itemLabel, scrollRef } = props;

  return (
    <TableHead className={classes.categoryHeaderRow}>
      <TableRow hover={false}>
        <TableCell
          className={classNames(classes.tableHeaderCell, classes.tableNameCell)}
          size="small"
          colSpan={3}
        >
          {!!scrollRef && (
            <div
              className={classes.scrollRefContainer}
            >
              <div className={classes.scrollRef} ref={scrollRef} />
            </div>
          )}
          <span>
            {itemLabel || "Item"}
          </span>
        </TableCell>
        {columns.map(column => (
          <TableCell
            className={classNames(
              classes.tableHeaderCell,
              column.type === COLUMN_TYPE_USER && classes.tableNameCell,
              column.type === COLUMN_TYPE_TEXT && classes.tableTextCell,
            )}
            colSpan={column.colSpan}
            size="small"
            key={column.key}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
