import http from "./http-common";
import authHeader from "./authHeader";

class TrainingService {
  getTrainingModuleIndex(componentId) {
    return http
      .get(`/training/component/${componentId}/index`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  getTrainingModuleById(trainingModuleId) {
    return http
      .get(`/training/module/${trainingModuleId}`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  getTrainingModuleByAssignmentId(trainingAssignmentId) {
    return http
      .get(`/training/assignment/${trainingAssignmentId}/module`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  getAllProgramTrainingAssignments(programId) {
    return http
      .get(`/training/assignments/program/${programId}`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  getUserTrainingAssignmentsByProgramId(programId) {
    return http
      .get(`/training/assignments/program/${programId}/user`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  getTrainingRoles() {
    return http
      .get(`/training/trainingRole`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  updateTrainingAssignment(assignmentId, assignmentData) {
    return http
      .put(`/training/assignment/${assignmentId}`,
      assignmentData,
      {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  createAssignment(assignmentData) {
    return http
      .post(`/training/assignment`,
        assignmentData,
        {
          headers: authHeader(),
        })
      .then((response) => response.data);
  }

  deleteAssignment(assignmentId) {
    return http
      .delete(`/training/assignment/${assignmentId}`,
     { headers: authHeader() }
      )
      .then((response) => response.data);
  }

  getTrainingUsers() {
    return http
      .get(`/training/user`, { headers: authHeader() })
      .then((response) => response.data);
  }

  batchCreateUsers(users) {
    return http
      .post(`/training/user/batchCreate`,
        users,
        { headers: authHeader(), }
      )
      .then((response) => response.data);
  }

}

export default new TrainingService();
