import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TableCell from "components/utils/tables/shared/tableCell.component";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  tableMarginTop: {
    borderCollapse: "collapse",
  },
  cellHeader: {
    backgroundColor: variables.tertiary1,
    color: "white",
    fontWeight: "bold",
    fontSize: variables.fontLarge,
    padding: "10px 20px",
    lineHeight: "normal",
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  cellBody: {
    padding: "10px 20px",
    color: variables.textSecondary,
    fontFamily: "Helvetica",
    fontSize: variables.fontSmall,
    border: "1px solid rgba(22, 76, 146, 0.22)",
  },
  centerAlign: {
    textAlign: "center",
  },
}));

const PolicyRegulationsTable = ({ headers, data, tableId, test }) => {
  const classes = useStyles();

  return (
    <TableContainer
      component={Paper}
      className={classes.tableMarginTop}
      style={{ overflowX: "hidden" }}
    >
      <Table id={tableId} data-cy={`table-${test}`}>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell
                className={classNames(classes.cellHeader, classes.centerAlign)}
                key={index}
              >
                {header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {/* EMPTY TABLE */}
        {(!data || data.length === 0) && (
          <TableBody>
            <TableRow>
              <TableCell
                className={classNames(classes.cellBody, classes.centerAlign)}
                colSpan={headers.length}
                scope="row"
              >
                No data to display
              </TableCell>
            </TableRow>
          </TableBody>
        )}
        {/* TABLE BODY */}
        {!!data && data.length > 0 && (
          <TableBody>
            {data.map((row, index) => {
              const orderedFields = headers.map((header) => header.id);
              return (
                <TableRow key={index}>
                  {orderedFields.map((key) => {
                    return (
                      <TableCell
                        className={classNames(
                          classes.cellBody,
                          classes.centerAlign
                        )}
                        key={`bodyCell-${key}`}
                      >
                        {row[key]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default PolicyRegulationsTable;
