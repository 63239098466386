import AuthService from "services/auth.service";
const ROLES = AuthService.Type;

export const CERTIFICATION_PROGRAM_ID = 12;

export const certTableMapIds = {
  TASKS: 56,
  PASI_GROUP: 112,
  CHECKLIST: 105,
  CHECKLIST_ITEMS: 104,
  LETTER_OF_COMPLIANCE_ITEM: 100,
  DCT_CATEGORY: 95,
  PROGRAM_DOCS: 46,
  ASSESSMENT_BUILDER: 1,
  AIRCRAFTS: 110,
  MANUALS: 106,
  USER_INFO: 123,
}

export const certProgCompIds = {
  APPLICATION_SCHEDULE: 96,
  PASI_CHECKLIST: 97,
  LETTER_OF_COMPLIANCE: 98,
  AIRCRAFT: 99,
  MANUALS: 100,
  DCT: 101,
  POLICY_PROCEDURE: 102,
  FORMAL_APPLICATION: 103,
  AUDIT_ASSESSMENT: 105,
  CONTACTS: 106
}

export const certCompObjIds = {
  PASI: 281,
  PRE_APPLICATION: 282,
  POLICIES: 284,
  PROCEDURES: 287,
  TRAINING: 290,
  ASSESSMENTS: 293,
}

export const manualSectionTypes = {
  MAIN_SECTION: "Section",
  SUBSECTION: "Subsection",
  NESTED_SUBSECTION: "NestedSubsection",
}

export const contactListRoleOptions = [
  { label: "No Access", value: "None", disabled: true },
  { label: "Read Only", value: ROLES.EDIT },
  { label: "Edit", value: ROLES.READONLY },
];

