import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow, Link} from "@material-ui/core";
import TableCell from "components/utils/tables/shared/tableCell.component";
import variables from "styleVariables";
import classNames from "classnames";
import ButtonDefault from "components/utils/buttonDefault.component";
import moment from "moment";
import BackupRoundedIcon from "@material-ui/icons/BackupRounded";
import { downloadFile } from "utils/downloadFile";
import UploadToolForm from "components/forms/uploadToolForm.component";
import CustomModal from "components/utils/modal.component";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: 35,
  },
  tablePrimaryHeaderCellLarge: {
    backgroundColor: variables.tertiary3,
    color: "white",
    fontWeight: "bold",
    fontSize: variables.fontMedium,
    padding: "10px 20px",
    lineHeight: "normal",
  },
  tableBodyCellLarge: {
    padding: "10px 20px",
    color: variables.textSecondary,
    fontSize: variables.fontSmall,
  },
  link: {
    color: variables.secondaryDark,

    "&:hover": {
      color: variables.primaryMain,
      cursor: "pointer",
    },
  },
  centerAlign: {
    textAlign: "center",
  },
  leftAlign: {
    textAlign: "left",
  },
  emptyMessage: {
    color: variables.textSecondary,
  },
  uploadIconSmall: {
    fontSize: "12px",
    marginRight: 6,
    color: variables.secondaryMain,
  },
  currentRow: {
    backgroundColor: variables.rowHighlight,
    color: "white",
    fontWeight: "bold",
  },
  currentLink: {
    color: "white",
    fontWeight: "bold",

    "&:hover": {
      color: variables.primaryDark,
      cursor: "pointer",
    },
  },
  title: {
    width: "25%",
  },
  description: {
    width: "25%",
  },
  // fixed: {
  //   tableLayout: "fixed",
  // },
}));

const ToolDocTable = (props) => {
  const classes = useStyles();

  //for autoscroll
  const history = useHistory();
  const rowRef = useRef({});
  const [searchRowId, setSearchRowId] = useState();

   // for modal
  const [editModal, setEditModal] = useState(false);
  const [rowInfo, setRowInfo] = useState();

  // upon load, scrolls to specified row
  useEffect(() => {
    if (props.state) {
      setSearchRowId(parseInt(props.state.refId));
      if (rowRef && rowRef.current && props.data) {
        rowRef.current[searchRowId].scrollIntoView({
          block: "center",
        });
        history.replace(); //clears out location.state
      }
    }
  }, [props.state, searchRowId, history, props.data]);

  const toggleForm = () => {
    setEditModal(!editModal);
  };

  const openForm = (row) => {
    setEditModal(true);
    setRowInfo(row)
  }

  return (
    <>
      <div className={classes.tableContainer}>
        {props.tableTitle}
        <TableContainer component={Paper} data-cy="charterCard">
          <Table className={classes.fixed} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  className={classNames(classes.tablePrimaryHeaderCellLarge, classes.leftAlign, classes.title)}
                >
                    Name
                </TableCell>
                <TableCell className={classNames(classes.tablePrimaryHeaderCellLarge, classes.leftAlign, classes.description)}>Description</TableCell>
                <TableCell className={classNames(classes.tablePrimaryHeaderCellLarge, classes.centerAlign, classes.version)}>Version</TableCell>
                <TableCell className={classNames(classes.tablePrimaryHeaderCellLarge, classes.centerAlign, classes.approved)}>Approved</TableCell>
                <TableCell className={classNames(classes.tablePrimaryHeaderCellLarge, classes.centerAlign, classes.status)}>Status</TableCell>
                <TableCell className={classNames(classes.tablePrimaryHeaderCellLarge, classes.centerAlign, classes.lastReview)}>Last Review</TableCell>
                <TableCell className={classNames(classes.tablePrimaryHeaderCellLarge, classes.centerAlign, classes.action)}>Action</TableCell>
              </TableRow>
            </TableHead>
            {(!props.data || props.data.length === 0) && (
              <TableBody>
                <TableRow>
                  <TableCell
                    className={classes.tableSmallBodyCell}
                    colSpan="7"
                    scope="row"
                  >
                    <div className={classes.emptyMessage}>No Tools to Display</div>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {!!props.data && props.data.length > 0 && (
              <TableBody>
                {props.data.map(row => (
                  <TableRow
                    key={row.key}
                    id={row.DocID}
                    ref={(el) => (rowRef.current[row.DocID] = el)}
                    selected={searchRowId === row.DocID}
                  >
                    <TableCell component="th" scope="row" className={classNames(classes.tableBodyCellLarge, classes.title, classes.leftAlign, row.current && classes.currentRow)}>
                      <Link
                        className={classNames(classes.link, row.current && classes.currentLink)}
                        onClick={() =>
                          downloadFile(row.DocID, row.File_Ref)}
                        data-cy={`toolDownload-${row.Title}-${row.PublishDate}`}
                      >
                        {row.Title}
                      </Link>
                    </TableCell>
                    <TableCell className={classNames(classes.tableBodyCellLarge, classes.description, classes.leftAlign, row.current && classes.currentRow)}>
                      {row.Description}
                    </TableCell>
                    <TableCell className={classNames(classes.tableBodyCellLarge, classes.centerAlign, row.current && classes.currentRow)}>
                      {row.Version}
                    </TableCell>
                    <TableCell className={classNames(classes.tableBodyCellLarge, classes.centerAlign, row.current && classes.currentRow)}>
                      {moment(row.PublishDate).format("M/D/YYYY")}
                    </TableCell>
                    <TableCell className={classNames(classes.tableBodyCellLarge, classes.centerAlign, row.current && classes.currentRow)}>
                      {row.Status}
                    </TableCell>
                    <TableCell className={classNames(classes.tableBodyCellLarge, classes.centerAlign, row.current && classes.currentRow)}>
                        N/A
                    </TableCell>
                    <TableCell className={classNames(classes.tableBodyCellLarge, classes.centerAlign, row.current && classes.currentRow)}>
                      {!!row.current && (
                        <ButtonDefault
                          variant="small"
                          color="secondary"
                          startIcon={
                            <BackupRoundedIcon
                              className={classes.uploadIconSmall}
                            />
                          }
                          onClick={() => openForm(row)}
                          data-cy={`btn-uploadTool-${row.Title}`}
                          disableReadOnlyUsers
                        >
                            Upload
                        </ButtonDefault>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
      <CustomModal open={editModal} onClose={toggleForm}>
        <UploadToolForm
          setRefresh={props.setRefresh}
          setEditModal={setEditModal}
          rowInfo={rowInfo}
          componentObject={props.componentObject}
        />
      </CustomModal>
    </>
  )
}

export default ToolDocTable