import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router";
import { MenuItem, FormControl, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomSelect from "components/utils/form-elements/select.component";
import ImplementationService from "services/implementation.service";
import CustomTable from "components/utils/tables/table.component";
import Locations from "components/utils/locations.component";
import DataInventoryForm from "components/forms/dataInventoryForm.component";
import CustomModal from "components/utils/modal.component";
import TableHeadCell from "components/utils/tableHeadCell.component";

const useStyles = makeStyles((theme) => ({
  mainContentContainer: {
    marginTop: 25,
  },
  controlsRowWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  selectControlsWrapper: {
    paddingLeft: 10,
  },
  selectForm: {
    maxWidth: "500px",
    minWidth: "300px",
    marginRight: 40,
    marginTop: 20,
  },
  locationWrapper: {
    alignItems: "flex-end",
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const alignment = [
  "left",
  "center",
  "center",
  "center",
  "center",
  "center",
  "center",
  "center",
];

const headers = [
  { label: "Privacy Data Types", name: "Type_Name" },
  { label: "Creation/Capture", name: "Creation_Capture" },
  { label: "Storage", name: "Storage" },
  { label: "Transmission", name: "Transmission" },
  { label: "Use", name: "Use" },
  { label: "Sharing", name: "Sharing" },
  { label: "Archive", name: "Archive" },
  { label: "Destruction", name: "Destruction" },
];

export const DataInventory = () => {
  const classes = useStyles();
  const location = useLocation();
  const { programId } = useParams();
  const [orgGroups, setOrgGroups] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [product, setProduct] = useState("");
  const [productId, setProductId] = useState(null);
  const [dataMap, setDataMap] = useState(null);
  const [products, setProducts] = useState();
  const [tableIsLoading, setTableIsLoading] = useState();
  const [fromSearch, setFromSearch] = useState();
  //MODAL
  const [editModal, setEditModal] = useState(false);
  const [columnName, setColumnName] = useState();

  const headerDisplay = useMemo(() => (
    headers.map((headerItem) => {
      const item = { ...headerItem };
      if (item.name === "Type_Name") {
        item.value = (
          <TableHeadCell text={item.label} />
        );
      } else {
        item.value = (
          <TableHeadCell
            variant="editCenterAlign"
            text={item.label}
            onClick={() => openForm(item.label)}
            test={item.label}
          />
        );
      }
      return item;
    })
  ), []);

  const openForm = (column) => {
    setColumnName(column);
    setEditModal(true);
  };

  useEffect(() => {
    let groups;
    let product_Id;
    let group_Id;
    ImplementationService.getProgramOrganizationGroups(programId)
      .then((res) => {
        groups = res.payload;
        setOrgGroups(res.payload);
      })
      .then(() => {
        if (!fromSearch && !location.state && groups[0].Group_ID) {
          group_Id = groups[0].Group_ID;
          setGroupName(groups[0].OrganizationGroup_Name);
          ImplementationService.getOrganizationGroupProducts(group_Id)
            .then((res) => {
              setProduct(res.payload[0].Product_Name);
              product_Id = res.payload[0].GroupProduct_ID;
              setProductId(product_Id);
              setProducts(res.payload);
            })
            .then(() => {
              ImplementationService.getGroupProductDataMaps(product_Id).then(
                (res) => {
                  setDataMap(res.payload);
                }
              );
            });
        }
      });
    if (location.state) {
      setFromSearch(true);
      product_Id = location.state.productId;
      setProductId(product_Id);
      // Grabbing group id and name
      ImplementationService.getOrgGroupByProductId(product_Id)
        .then((res) => {
          group_Id = res.payload.Group_ID;
          setGroupName(res.payload.Name);
        })
        .then(() => {
          //loading table
          ImplementationService.getGroupProductDataMaps(product_Id).then(
            (res) => {
              setDataMap(res.payload);
            }
          );
        })
        .then(() => {
          //Setting list of products and select product
          ImplementationService.getOrganizationGroupProducts(group_Id).then(
            (res) => {
              setProduct(
                res.payload.filter(
                  (group) => group.GroupProduct_ID.toString() === product_Id
                )[0].Product_Name
              );
              setProducts(res.payload);
            }
          );
        });
    }
  }, [location.state, fromSearch, programId]);

  const toggleForm = () => {
    setEditModal(!editModal);
  };

  const handleGroup = (event) => {
    setDataMap(null);
    setProduct("");
    setGroupName(event.target.value);
    ImplementationService.getOrganizationGroupProducts(
      event.currentTarget.id
    ).then((res) => {
      setProducts(res.payload);
    });
  };

  const handleProduct = (event) => {
    setTableIsLoading(true);
    setProduct(event.target.value);
    setProductId(event.currentTarget.id);
    ImplementationService.getGroupProductDataMaps(event.currentTarget.id).then(
      (res) => {
        setDataMap(res.payload);
        setTableIsLoading(false);
      }
    );
  };

  const loadTable = (loadProduct) => {
    setTableIsLoading(true);
    return ImplementationService.getGroupProductDataMaps(loadProduct).then((res) => {
      setDataMap(res.payload);
      setTableIsLoading(false);
    });
  };

  if (orgGroups.length && orgGroups[0].Group_ID) {
    return (
      <Grid container spacing={1} className={classes.mainContentContainer}>
        <Grid item sm={12} className={classes.controlsRowWrapper}>
          <Grid item md={7} className={classes.selectControlsWrapper}>
            <FormControl className={classes.selectForm}>
              <CustomSelect
                label="Select Group"
                onChange={handleGroup}
                value={groupName}
                readOnlyAccessible
                test="group"
              >
                {!!orgGroups && orgGroups.map((group) => (
                  <MenuItem
                    key={group.Group_ID}
                    value={group.OrganizationGroup_Name}
                    id={group.Group_ID}
                  >
                    {group.OrganizationGroup_Name}
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>

            <FormControl className={classes.selectForm}>
              <CustomSelect
                label="Select Product"
                disabled={!products}
                onChange={handleProduct}
                value={product}
                readOnlyAccessible
                test="product"
              >
                {!!products && products.map((selectedProduct) => (
                  <MenuItem
                    key={selectedProduct.GroupProduct_ID}
                    value={selectedProduct.Product_Name}
                    id={selectedProduct.GroupProduct_ID}
                    data-cy={`menuItem-${selectedProduct.Product_Name}`}
                  >
                    {selectedProduct.Product_Name}
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>
          </Grid>

          <Grid item md={3} className={classes.locationWrapper}>
            <Locations
              productId={productId}
              disabled={!dataMap}
              product={product}
              groupName={groupName}
            />
          </Grid>
        </Grid>
        {!!dataMap && !tableIsLoading && (
          <>
            <div>
              <CustomTable
                maxHeight="calc(100vh - 320px)"
                header={headerDisplay}
                alignment={alignment}
                data={dataMap}
                replaceBlank="-"
                boldFirstColumn="Type_Name"
                test="DataInventory"
              />
            </div>

            <CustomModal open={editModal} onClose={toggleForm}>
              <DataInventoryForm
                setEditModal={setEditModal}
                columnName={columnName}
                product={product}
                group={groupName}
                productId={productId}
                groupName={groupName}
                loadTable={loadTable}
              />
            </CustomModal>
          </>
        )}
      </Grid>
    );
  } else {
    return "";
  }
};

export default DataInventory;
