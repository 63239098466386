import {
  Box,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import classNames from "classnames";
import { getAnyPortfolioUserPermission, getPortfolioUserTitle, validateUserFormErrors } from "components/portfolio/shared/utils/portfolioUserHelpers";
import AddListItem from "components/utils/addListItem.component";
import ButtonDefault from "components/utils/buttonDefault.component";
import Checkbox from "components/utils/form-elements/checkbox.component";
import Form from "components/utils/form-elements/form.component";
import CustomSelect from "components/utils/form-elements/select.component";
import Header from "components/utils/header.component";
import { useCallback, useMemo, useState } from "react";
import {
  ACTION_DELETE_SESSION_ATTENDEE,
  ACTION_REPLACE_PORTFOLIO_USER,
  ACTION_REPLACE_SESSION_ATTENDEE,
} from "reducers/portfolioAssessments.reducer";
import PortfolioService from "services/portfolio.service";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import variables from "styleVariables";
import DeleteIcon from "@material-ui/icons/Delete";
import BlockIcon from "@material-ui/icons/Block";
import Edit from "@material-ui/icons/Edit";
import { currentUserID } from "utils/userHelpers";
import CustomLink from "components/utils/link.component";
import CustomModal from "components/utils/modal.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import FormBanner from "components/utils/form-elements/formBanner.component";
import { useEffect } from "react";
import useNumericParams from "hooks/useNumericParams";
import { HTTP_STATUS_CONTENT } from "services/http-common";

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    paddingBottom: 16,
  },
  attendeeList: {
    listStyle: "none",
    padding: 0,
    marginBottom: 0
  },
  attendeeListEmpty: {
    marginTop: 0,
    marginBottom: 16
  },
  attendeeListItem: {
    marginTop: 8,
  },
  attendeeListItemClickable: {
    cursor: "pointer",
  },
  attendeeCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 0px",
    position: "relative",
    flexWrap: "wrap",
    wordBreak: "break-word",
    [theme.breakpoints.between("lg", 1550)]: {
      flexDirection: "column",
      alignItems: "flex-start"
    },
  },
  selectAttendeeWrapper: {
    flexDirection: "column",
    paddingLeft: 22,
    flex: 2
  },
  attendeeWrapper: {
    display: "flex",
    alignItems: "center",
  },
  checkboxWrapper: {
    paddingLeft: 20,
    textAlign: "right",
    [theme.breakpoints.down("md")]: {
      textAlign: "right",
    },
  },
  selectCardContent: {
    width: "100%",
    "&:last-of-type": {
      paddingBottom: 0,
    },
    display: "flex",
    flexDirection: "column"
  },
  formButtonsWrapper: {
    display: "flex",
    alignSelf: "flex-start",
    justifyContent: "space-between",
  },
  editFormButton: {
    marginRight: "25px",
    marginLeft: "10px"
  },
  cancelIcon: {
    fontSize: variables.fontH3,
  },
  deleteIcon: {
    fontSize: variables.fontH3,
  },
  editIcon: {
    fontSize: variables.fontH3,
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    "& .MuiCardHeader-avatar": {
      display: "flex",
      alignItems: "center",
    },
  },
  userIcon: {
    display: "inline-block",
    height: 30,
    width: 30,
    fontSize: "3rem",
    fill: variables.primaryLight,
  },
  userName: {
    color: variables.primaryLight,
    fontSize: variables.fontH4,
    fontWeight: 600,
    paddingBottom: 10,
  },
  userTitle: {
    color: variables.tertiary1,
    fontSize: variables.fontH5,
  },
  addButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "24px 10px",
  },
  createLink: {
    textDecoration: "underline",
    fontSize: variables.fontXs,
    alignSelf: "flex-end"
  },
  defaultInputWrapper: {
    width: 160,
  },
  longInputWrapper: {
    width: 260,
  },
  //user form
  newUserFromContainer: {
    padding: 30,
    opacity: 1,
    width: "100%"
  },
  newUserFormRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  userButtonsClass: {
    minWidth: 250,
  },
}));

export default function SessionParticipantsForm(props) {
  const classes = useStyles();
  const { holdingId } = useNumericParams(true);
  const { attendees, session, dispatch, isReadOnly, users } = props;

  const [isAddingAttendee, setIsAddingAttendee] = useState();
  const [editingAttendee, setEditingAttendee] = useState();
  const [formModal, setFormModal] = useState();

  const loggedInUserId = useMemo(currentUserID, []);

  const usersById = useMemo(
    () =>
      users.reduce(
        (accumulator, user) => ({ ...accumulator, [user.User_ID]: user }),
        {}
      ),
    [users]
  );

  const potentialAttendeeUsers = useMemo(() => {
    if (!attendees) {
      return users;
    }
    const selectedUserIds = new Set(
      attendees.map((attendee) => attendee.PortUser_ID)
    );
    return users.filter((user) => !selectedUserIds.has(user.User_ID));
  }, [attendees, users]);

  const handleAttendeeClick = useCallback(attendee => {
    if (isReadOnly || editingAttendee === attendee) {
      return;
    }
    return setEditingAttendee(attendee);
  }, [editingAttendee, isReadOnly, setEditingAttendee]);

  const handleAttendeeUpsert = useCallback(
    async (data) => {
      const attendeeResponse = await PortfolioService.upsertAttendee(
        session.Session_ID,
        data
      );
      dispatch({
        type: ACTION_REPLACE_SESSION_ATTENDEE,
        payload: attendeeResponse.payload,
      });
      setEditingAttendee(null);
      setIsAddingAttendee(false);
    },
    [dispatch, session, setEditingAttendee, setIsAddingAttendee]
  );

  const handleAttendeeCreate = useCallback(
    async (event) => {
      const data = {
        PortCo_ID: session.PortCo_ID,
        PortUser_ID: event.target.value,
        Session_ID: session.Session_ID,
      };
      handleAttendeeUpsert(data);
    },
    [session, handleAttendeeUpsert]
  );

  const handleAttendeeUpdate = useCallback(
    (name, value, attendee) => {
      const data = {
        Attendee_ID: attendee.Attendee_ID,
        PortUser_ID: attendee.PortUser_ID,
        [name]: value,
      };
      handleAttendeeUpsert(data);
    },
    [handleAttendeeUpsert]
  );

  const handleSelectChange = useCallback(
    (event, attendee) =>
      handleAttendeeUpdate("PortUser_ID", event.target.value, attendee),
    [handleAttendeeUpdate]
  );

  const handleCheckboxChange = useCallback(
    (event, attendee) =>
      handleAttendeeUpdate(
        event.target.name,
        event.target.checked ? 1 : 0,
        attendee
      ),
    [handleAttendeeUpdate]
  );

  const handleDeleteAttendee = useCallback(
    async (attendee) => {
      const attendeeResponse = await PortfolioService.deleteAttendee(
        attendee.Attendee_ID
      );
      dispatch({
        type: ACTION_DELETE_SESSION_ATTENDEE,
        payload: attendeeResponse.payload,
      });
    },
    [dispatch]
  );

  const isEmptyList = !attendees?.length && !isAddingAttendee;

  return (
    <Form>
      <Box className={classes.headerWrapper}>
        <Header variant="h3Primary">Participants</Header>
      </Box>
      <ul
        className={
          classNames(
            classes.attendeeList,
            isEmptyList && classes.attendeeListEmpty
          )
        }
        data-cy="attendee-list"
      >
        {isEmptyList
          ? <li>No participants specified for this session.</li>
          : attendees.map((attendee) => {
            const user = usersById[attendee.PortUser_ID];
            if (!user) {
              return null;
            }
            const editableUser = !user.Role_ID
            return (
              <li
                className={
                  classNames(
                    classes.attendeeListItem,
                    !isReadOnly && editingAttendee !== attendee &&
                      classes.attendeeListItemClickable
                  )
                }
                onClick={() => handleAttendeeClick(attendee)}
                key={attendee.Attendee_ID}
                data-cy={`attendee-card-${attendee.PortUser_ID}`}
              >
                <Card className={classes.attendeeCard}>
                  {editingAttendee === attendee ? (
                    <CardContent
                      className={classNames(classes.attendeeWrapper, classes.selectAttendeeWrapper)}
                      classes={{ root: classes.selectCardContent }}
                    >
                      <EditAttendeeSelect
                        attendee={editingAttendee}
                        holdingId={holdingId}
                        onChange={(event) =>
                          handleSelectChange(event, attendee)}
                        potentialAttendees={potentialAttendeeUsers}
                        session={session}
                        setEditingAttendee={setEditingAttendee}
                        setIsAddingAttendee={setIsAddingAttendee}
                        user={user}
                      />
                      <Box className={classes.formButtonsWrapper}>
                        <div className={classes.editFormButton}>
                          <ButtonDefault
                            onClick={() => setEditingAttendee(null)}
                            variant="iconSmall"
                            color="tertiary"
                            startIcon={
                              <BlockIcon className={classes.cancelIcon} />
                            }
                          >
                            Cancel
                          </ButtonDefault>
                        </div>
                        <div className={classes.editFormButton}>
                          <ButtonDefault
                            onClick={() => handleDeleteAttendee(attendee)}
                            variant="iconSmall"
                            type="submit"
                            color="red"
                            startIcon={
                              <DeleteIcon className={classes.deleteIcon} />
                            }
                          >
                            Delete
                          </ButtonDefault>
                        </div>
                        {!!editableUser && (
                          <div className={classes.editFormButton}>
                            <ButtonDefault
                              onClick={() => setFormModal("edit")}
                              variant="iconSmall"
                              type="submit"
                              color="secondary"
                              startIcon={
                                <Edit className={classes.editIcon} />
                              }
                            >
                              Edit
                            </ButtonDefault>
                            <CustomModal open={formModal === "edit"} onClose={() => setFormModal()}>
                              <AttendeeUserForm
                                setFormModal={setFormModal}
                                setEditingAttendee={setEditingAttendee}
                                session={session}
                                dispatch={dispatch}
                                userToEdit={user}
                                attendee={attendee}
                              />
                            </CustomModal>
                          </div>
                        )}
                      </Box>
                    </CardContent>
                  ) : (
                    <div className={classes.attendeeWrapper}>
                      <CardHeader
                        className={classes.cardHeader}
                        avatar={
                          <AccountCircleIcon
                            aria-label="user-icon"
                            className={classes.userIcon}
                          />
                        }
                        title={
                          <span className={classes.userName}>
                            {user.First_Name} {user.Last_Name}
                          </span>
                        }
                        subheader={
                          <span className={classes.userTitle}>
                            {getPortfolioUserTitle(
                                user, holdingId, session.PortCo_ID
                            )}
                          </span>
                        }
                        subheaderTypographyProps={{ variant: null }}
                      >
                        {user.First_Name} {user.Last_Name}
                      </CardHeader>
                    </div>
                  )}
                  <div
                    className={
                      classNames(
                        classes.checkboxWrapper,
                        editingAttendee === attendee && classes.selectCheckboxWrapper
                      )
                    }
                  >
                    <Checkbox
                      name="Present"
                      label="Present?"
                      checkBoxColor="teal"
                      checked={!!attendee.Present}
                      onClick={(event) => event.stopPropagation()}
                      onChange={(event) => (
                        handleCheckboxChange(event, attendee)
                      )}
                      disabled={
                        !!isReadOnly &&
                        loggedInUserId !== attendee.PortUser_ID
                      }
                      readOnly={false}
                      test={`${attendee.PortUser_ID}-present`}
                    >
                      Present?
                    </Checkbox>
                  </div>
                </Card>
              </li>
            );
          })}
        {!!isAddingAttendee && (
          <li className={classes.attendeeListItem}>
            <Card className={classes.attendeeCard}>
              <CardContent classes={{ root: classes.selectCardContent }}>
                <EditAttendeeSelect
                  holdingId={holdingId}
                  onChange={handleAttendeeCreate}
                  potentialAttendees={potentialAttendeeUsers}
                  session={session}
                  setEditingAttendee={setEditingAttendee}
                  setIsAddingAttendee={setIsAddingAttendee}
                  users={users}
                />
                <Box display="flex" justifyContent="space-between" padding={0.5}>
                  <ButtonDefault
                    onClick={() => setIsAddingAttendee(false)}
                    variant="iconSmall"
                    color="tertiary"
                    startIcon={<BlockIcon className={classes.cancelIcon} />}
                  >
                    Cancel
                  </ButtonDefault>
                  <CustomLink
                    className={classes.createLink}
                    onClick={() => setFormModal("new")}
                    test="new-attendee"
                  >
                    Create a new Attendee
                  </CustomLink>
                </Box>
              </CardContent>
            </Card>
            <CustomModal open={formModal === "new"} onClose={() => setFormModal()}>
              <AttendeeUserForm
                setFormModal={setFormModal}
                setIsAddingAttendee={setIsAddingAttendee}
                session={session}
                dispatch={dispatch}
              />
            </CustomModal>
          </li>
        )}
      </ul>
      {!isReadOnly && (
        <Box className={classes.addButtonWrapper}>
          <AddListItem
            onClick={() => setIsAddingAttendee(true)}
            disabled={!potentialAttendeeUsers?.length || isAddingAttendee}
            test="attendee"
          >
            Add Participant
          </AddListItem>
        </Box>
      )}
    </Form>
  );
}

const DefaultInput = ({ ...props }) => {
  const classes = useStyles();
  return (
    <div
      className={
        props.longInput ? classes.longInputWrapper : classes.defaultInputWrapper
      }
    >
      <LabelInput {...props} variant="default" margin="dense" />
    </div>
  );
};

const AttendeeUserForm = ({
  setFormModal,
  setIsAddingAttendee,
  session,
  dispatch,
  userToEdit,
  setEditingAttendee,
  attendee
}) => {
  const classes = useStyles();
  const { holdingId } = useNumericParams();
  const [attendeeUserFormData, setAttendeeUserFormData] = useState({});
  const [userFormErrors, setUserFormErrors] = useState({});
  const [savingStatus, setSavingStatus] = useState("");

  const portGroupPermission = useMemo(() => (
    getAnyPortfolioUserPermission(userToEdit, null, session.PortCo_ID)
  ), [session, userToEdit]);

  const title = useMemo(() => (
    getPortfolioUserTitle(userToEdit, holdingId, session.PortCo_ID)
  ), [holdingId, session.PortCo_ID, userToEdit]);

  useEffect(() => {
    if (userToEdit) {
      setAttendeeUserFormData({
        User_ID: userToEdit.User_ID,
        First_Name: userToEdit.First_Name,
        Last_Name: userToEdit.Last_Name,
        Email: userToEdit.Email,
        Title: title,
        PortGroupPermission_ID: portGroupPermission.PortGroupPermission_ID
      });
    }
  }, [portGroupPermission, title, userToEdit]);

  const submitAttendeeUser = async () => {
    const validateUserErrors = validateUserFormErrors(attendeeUserFormData);
    if (
      Object.values(validateUserErrors).filter((error) => error !== false)
        .length > 0
    ) {
      setUserFormErrors(validateUserErrors);
      return;
    }
    setSavingStatus("savingUser");
    const userData = {
      User_ID: attendeeUserFormData.User_ID,
      First_Name: attendeeUserFormData.First_Name,
      Last_Name: attendeeUserFormData.Last_Name,
      Email: attendeeUserFormData.Email,
    };
    const gPpData = {
      Title: attendeeUserFormData.Title,
      PortGroupPermission_ID: attendeeUserFormData?.PortGroupPermission_ID,
    };

    const upsertedRes = await PortfolioService.upsertAttendeeUser(
      session.PortCo_ID,
      userData,
      gPpData
    ).catch((err) => {
      if (err.response && err.response.status === HTTP_STATUS_CONTENT) {
        setUserFormErrors({
          Email: "This email already exists in the system.",
        });

      }
    });

    if (!upsertedRes) {
      setSavingStatus("");
      return;
    }

    dispatch({
      type:  ACTION_REPLACE_PORTFOLIO_USER,
      payload: upsertedRes.payload,
    });

    if (!userToEdit) {
      const attendeeResponse = await PortfolioService.upsertAttendee(
        session.Session_ID,
        {
          PortCo_ID: session.PortCo_ID,
          PortUser_ID: upsertedRes.payload.User_ID,
          Session_ID: session.Session_ID,
        }
      );
      dispatch({
        type: ACTION_REPLACE_SESSION_ATTENDEE,
        payload: attendeeResponse.payload,
      });
    } else {
      dispatch({
        type: ACTION_REPLACE_SESSION_ATTENDEE,
        payload: {...attendee, UserName: `${attendeeUserFormData.First_Name} ${attendeeUserFormData.Last_Name}` }
      });
    }
    setSavingStatus("");
    setAttendeeUserFormData();
    setFormModal();
    setEditingAttendee && setEditingAttendee()
    setIsAddingAttendee && setIsAddingAttendee();
    return null;
  };

  const newUserChangeHandler = (event) => {
    setAttendeeUserFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
    if (userFormErrors[event.target.name]) {
      setUserFormErrors((prev) => ({ ...prev, [event.target.name]: false }));
    }
  };

  return (
    <div>
      <FormBanner>Create Attendee</FormBanner>
      <div className={classes.newUserFromContainer} data-cy="new-user-form">
        <div className={classes.newUserFormRow}>
          <DefaultInput
            label="First Name*"
            value={attendeeUserFormData?.First_Name}
            onChange={newUserChangeHandler}
            name="First_Name"
            error={!!userFormErrors?.First_Name}
            errorMessage={userFormErrors?.First_Name}
            test="First_Name"
          />

          <DefaultInput
            label="Last Name*"
            value={attendeeUserFormData?.Last_Name}
            onChange={newUserChangeHandler}
            name="Last_Name"
            error={!!userFormErrors?.Last_Name}
            errorMessage={userFormErrors?.Last_Name}
          />

          <DefaultInput
            label="Title*"
            value={attendeeUserFormData?.Title}
            onChange={newUserChangeHandler}
            name="Title"
            error={!!userFormErrors?.Title}
            errorMessage={userFormErrors?.Title}
          />
        </div>
        <div className={classes.newUserFormRow}>
          <DefaultInput
            longInput
            label="Email*"
            value={attendeeUserFormData?.Email}
            onChange={newUserChangeHandler}
            name="Email"
            error={!!userFormErrors?.Email}
            errorMessage={userFormErrors?.Email}
          />
          <div>
            <DualFormButtons
              className={classes.userButtonsClass}
              buttonVariant="small"
              addText={
                attendeeUserFormData?.User_ID ? "Edit Attendee" : "Create Attendee"
              }
              cancelOnClick={() => setFormModal()}
              saveOnClick={submitAttendeeUser}
              isSaving={savingStatus === "savingUser"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const EditAttendeeSelect = (props) => {
  const {
    attendee, holdingId, potentialAttendees, onChange, session, user
  } = props;

  const sortedMenuOptions = useMemo(
    () =>
      potentialAttendees
        .concat(user || [])
        .sort((u1, u2) => u2.First_Name - u1.First_Name),
    [user, potentialAttendees]
  );

  return (
    <CustomSelect
      name={`SessionAttendee__${attendee?.Attendee_ID || "new"}`}
      labelId="attendee"
      label={attendee ? "" : "Attendee"}
      defaultValue={attendee?.PortUser_ID || ""}
      onChange={onChange}
      test="attendee"
      required={false}
    >
      <MenuItem id="none" value="" placeholder disabled>
        Select a participant
      </MenuItem>
      {sortedMenuOptions.map((currentUser) => (
        <MenuItem id={currentUser.User_ID} value={currentUser.User_ID} key={currentUser.User_ID}>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Box width="100%" marginRight={1}>
              <Header variant="h6Tertiary">
                {currentUser.First_Name}&nbsp;{currentUser.Last_Name}
              </Header>
            </Box>
            {getPortfolioUserTitle(currentUser, holdingId, session.PortCo_ID)}
          </Box>
        </MenuItem>
      ))}
    </CustomSelect>
  );
};
