import React, { useState } from "react";
import { Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AuthTextField from "components/utils/form-elements/authTextField.component";
import ButtonDefault from "components/utils/buttonDefault.component";
import AuthFormWrapper from "components/auth/authFormWrapper.component";
import BackgroundImage from "components/auth/backgroundImage.component";
import variables from "styleVariables";
import OrchLogo from "components/utils/orchLogo.component";

// custom imports
import AuthService from "services/auth.service";

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    marginTop: 10,
  },
  bodyText: {
    fontSize: variables.fontMedium,
    color: variables.primaryMain,
    textAlign: "center",
  },
}));

const Forgot = (props) => {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  // submit the form
  const handleSubmit = (event) => {
    event.preventDefault();

    //send reset email
    AuthService.forgot(email)
      .then((response) => {
        setSubmitted(true);
      })
      .catch(() => {
        setSubmitted(true);
      });
  };

  return (
    <BackgroundImage>
      <AuthFormWrapper>
        <Container component="main" maxWidth="xs">
          <OrchLogo />
          {!submitted ? (
            <form noValidate>
              <Typography className={classes.bodyText} data-cy="resetMessage">
                An email will be sent to the following address with
                instructions to reset your password.
              </Typography>
              <AuthTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(event) => setEmail(event.target.value)}
                test="Email"
              />
              <div className={classes.buttonWrapper}>
                <ButtonDefault
                  disabled={email.trim() === ""}
                  fullWidth
                  variant="large"
                  background="secondary"
                  onClick={handleSubmit}
                  type="submit"
                  data-cy="reset-password"
                >
                  Reset Password
                </ButtonDefault>
              </div>
            </form>
          ) : (
            <Typography className={classes.bodyText} data-cy="forgotMessage">
              If there is an account registered to this address, an email will
              be delivered. You may close this window.
            </Typography>
          )}
        </Container>
      </AuthFormWrapper>
    </BackgroundImage>
  );
}

export default Forgot;
