import { OutlinedInput, withStyles } from "@material-ui/core";
import variables from "styleVariables";

const CustomOutlinedInput = withStyles({
  root: {
    '&.Mui-focused fieldset': {
      borderLeftWidth: "6px !important",
      padding: '4px !important',
    },
    '& fieldset': {
      borderColor: variables.secondaryMain,
      borderWidth: 2,
    },
    '&:hover fieldset': {
      borderColor: `${variables.primaryMain} !important;`,
    },
    '& input:valid + fieldset': {
      borderColor: variables.secondaryMain,
      borderWidth: 2,
    },
    '& input:valid:focus + fieldset': {
      borderLeftWidth: "6px !important",
      padding: '4px !important', // override inline-style
    },
    '& input:invalid + fieldset': {
      borderColor: `${variables.warningLight} !important;`,
      borderWidth: 2,
    },
  },
  disabled: {
    color: variables.textPrimary,
    '&:hover fieldset': {
      borderColor: `${variables.grayDisabled} !important`,
    },
  }
})(OutlinedInput);

export default CustomOutlinedInput;
