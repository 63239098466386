import { makeStyles } from "@material-ui/core";
import Header from "components/utils/header.component";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  section: {
    width: "100%",
    marginBottom: 32,
    "&:last-of-type": {
      marginBottom: 50,
    },
  },
  sectionHeader: {
    padding: "2px 32px",
    background: theme.palette.primary.main
  },
  sectionBody: {
    padding: "32px 32px 0px 32px",
  },
  avoidPageBreak: {
    "@media print": {
      pageBreakInside: "avoid"
    }
  }
}));


export function ReportPageSection(props) {
  const classes = useStyles();
  const { children, title } = props;
  return (
    <div className={classes.section}>
      <div className={classNames(classes.sectionHeader, classes.avoidPageBreak)}>
        <Header variant="h3White">
          {title}
        </Header>
      </div>
      <div className={classes.sectionBody}>
        {children}
      </div>
    </div>
  );
}
