
export const ACTION_REQ_ATTRIBUTE = "_req";

/*
 * Convert a state object mapped by programId URL param to an object
 *   with programId as key and empty arrays as the value.
 *
 * When mapping data by programId, sometimes an empty array is the payload.
 * The programId must still be added - this is `action._req.programId`.
 */
export function mapToEmptyProgramIdArrays(action, stateObject) {
  const programIdArrays = Object.fromEntries(
    Object.keys(stateObject || {}).map(id => [id, []]) || []
  );
  const programId = action?.[ACTION_REQ_ATTRIBUTE]?.programId;
  if (!programId || stateObject?.[programId]) {
    return programIdArrays;
  }
  return { ...programIdArrays, [programId]: [] };
}
