const asciiConverter = (keyword) => {
  if (keyword.includes("#")) {
    return keyword.replace("#", "%23");
  }
  return keyword;
};

const searchScopeTypes = {
  SEARCH_TASKS_WORKPACKAGES: "TasksAndWps",
}

export { asciiConverter, searchScopeTypes };
