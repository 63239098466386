import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "components/utils/stepper.component";
import Alert from "@material-ui/lab/Alert";
import { Box } from "@material-ui/core";
import StepperContent from "components/utils/stepperContent.component";
import RiskAssessmentService from "services/riskAssessment.service";
import AssessmentBuilderBasicInfo from "components/assessment/assessmentBuilderBasicInfo.component";
import SetAssessmentItems from "components/assessment/setAssessmentItems.component";
import AssessmentTypeOptions from "components/assessment/assessmentTypeOptions.component";
import AddChecklist from "components/assessment/addChecklist.component";
import AssignAssessments from "components/assessment/assignAssessments.component";
import AssessmentSetupFinalView from "components/assessment/assessmentSetupFinalView.component";
import {
  assessItemRefAttacherAfterLoad,
  uniqueAssessItemIdsWithReviewFinder,
  findAssessmentTypeByState,
} from "utils/assessmentHelpers";
import AssessmentReview from "components/assessment/review/assessmentReview.component";
import {
  createAssessmentItems,
  editAssessObjs,
  normalizeChecklistItem,
  normalizeAssessmentAssignment,
} from "utils/assessmentSaveFuncs";
import variables from "styleVariables";
import { abSetupSteps, allViewCompNames, assessmentBuilderModes as modes, customCompObj, updateAssessmentMapping } from "utils/assessmentConstants";
import { oneOrMoreFieldsChanged } from "utils/formHelpers";
import moment from "moment";
import { sortByStringKey } from "utils/sortingFuncs";
import AssessmentBuilderSaveBar from "./assessmentBuilderSaveBar.component";
import CompletedReportPage from "./completedReportPage.component";
import { currentUserInfo } from "utils/userHelpers";
import { replaceObjInArrByArrInPlace, replaceObjInArrByID } from "utils/arrayOfObjectsHelpers";
import { assessmentBuilderModes } from "utils/assessmentConstants";
import ProgramsContext from "contexts/programs.context";
import { ACTION_REPLACE_PROGRAM_COMPONENTS } from "reducers/global/program.reducer";
import programService from "services/program.service";
import riskAssessmentService from "services/riskAssessment.service";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    display: "flex",
    flex: 1, //Stretches element to bottom of page
    flexDirection: "column",
    backgroundColor: "white",
  },
  stepperWrapper: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "white",
    paddingBottom: 30,
    paddingTop: 20,
    boxShadow: variables.bottomBoxShadow,
    zIndex: 10,
  },
  alertStyle: {
    marginTop: 0,
    position: "absolute",
    top: 155,
    zIndex: 10,
    width: "100%",
  },
  assessmentReviewWrapper: {
    marginTop: 60,
  },
}));

//The steps of the assessment builder that are optional and don't require any data to continue
const optionalSteps = [4, 5];

const editableBuilderFields = [
  "Title",
  "Description",
  "Start_Date",
  "End_Date",
];

const AssessmentBuilder = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { state, dispatch } = useContext(ProgramsContext);
  const user = useMemo(currentUserInfo, []);
  const steps = abSetupSteps;
  const { programId: programIdParam, programComponentId, componentObjectId, mode, stepId } = useParams();
  const programId = props.chosenProgramId || programIdParam;

  const [message, setMessage] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [assessBuilderInfo, setAssessBuilderInfo] = useState(props.builderInfo); // Should always be up to date with the db

  const basePath = `/program/${programIdParam}/${programComponentId}/${componentObjectId}`;
  const baseBuilderPath = `${basePath}/assessment/${assessBuilderInfo.Builder_ID}`;
  const baseBuilderTemplate = `/program/:programId/:programComponentId/:componentObjectId/assessment/:builderId`;

  const { builderInfo, setMode } = props;

  const activeStep = useMemo(() => {
    if (modes.MODE_LOAD_TEMPLATE === mode) {
      return 1;
    } else if ([modes.MODE_EDIT, modes.MODE_COMPLETE].includes(mode)) {
      const id = stepId ||
        // Load the last step if all 5 steps are completed and user re-enters edit pages
        (builderInfo.Step === steps.length
          ? steps.length
          : builderInfo.Step + 1);

      if (id) {
        return parseInt(id, 10);
      }
    }
    return null;
  }, [mode, stepId, steps, builderInfo]);

  const setActiveStep = useCallback((step, shouldReplace) => {
    const path = `${baseBuilderPath}/${modes.MODE_EDIT}/step/${step}`;
    if (mode === modes.MODE_LOAD_TEMPLATE) {
      const replacementPath = `${baseBuilderPath}/${modes.MODE_EDIT}/step/1`;
      history.replace(replacementPath);
    }
    if (shouldReplace) {
      history.replace(path);
    } else {
      history.push(path);
    }
  }, [history, baseBuilderPath, mode])

  // Update URL if activeStep set by builderInfo on component mount
  useEffect(() => {
    if ([modes.MODE_EDIT, modes.MODE_COMPLETE].includes(mode) && !stepId && activeStep) {
      setActiveStep(activeStep, true);
    }
  });

  // Sync mode with URL when native back button used
  useEffect(() => {
    if (!mode) {
      setMode();
    }
  }, [mode, setMode]);

  //Content states
  /* === STEP 1 === */
  const [recordFormData, setRecordFormData] = useState(props.builderInfo); //Similar to assessBuilderInfo but modifiable via form
  /* === STEP 2 === */
  const [assessItems, setAssessItems] = useState([]);
  const [isSavingAssessmentItems, setIsSavingAssessmentItems] = useState([]); //Collection of Foreign_Refs being sent to the backend
  /* === STEP 3 === */
  const [assessmentType, setAssessmentType] = useState(null);
  /* === STEP 4 === */
  const [checkListData, setCheckListData] = useState([]);
  const [isSavingChecklistItems, setIsSavingChecklistItems] = useState(false); //Boolean
  /* === STEP 5 === */
  const [assessors, setAssessors] = useState([]);
  /*Review*/
  const [dataCollectionItems, setDataCollectionItems] = useState(null);

  const builderProgramComponents = useMemo(() => (
    state.programComponentsByProgram?.[programId]
  ), [state.programComponentsByProgram, programId]);

  const assignableProgramComponents = useMemo(() => {
    if (!builderProgramComponents) {
      return null;
    }
    const applicableComponents = builderProgramComponents.filter?.(
      programComponent => allViewCompNames.has(programComponent.Name)
    );
    const sortedRealComponents = sortByStringKey(applicableComponents, "Name");
    return [...sortedRealComponents, customCompObj];
  }, [builderProgramComponents]);

  useEffect(() => (
    async function fetchMissingProgramComponent() {
      if (!builderProgramComponents && state.programComponentsByProgram) {
        const programComponentResponse = await (
          programService.getProgramComponentsByProgramId(programId)
        );
        dispatch({
          type: ACTION_REPLACE_PROGRAM_COMPONENTS,
          payload: programComponentResponse.payload
        });
      }
    }
  )(), [
    builderProgramComponents, dispatch, programId,
    state.programComponentsByProgram
  ]);

  useEffect(() => {
    //Only Runs When First Loaded via builderType page
    let isSubscribed = true;
    const builderStep = props.builderInfo.Step;
    const builderID = props.builderInfo.Builder_ID;

    if ((props.mode === assessmentBuilderModes.MODE_EDIT) || (props.mode === assessmentBuilderModes.MODE_LOAD_TEMPLATE)) {
      if (builderStep >= 2) {
        if (assignableProgramComponents) {
          RiskAssessmentService.getAssessmentItemsByBuilderID(builderID).then(
            (res) => {
              const dataWithRef = assessItemRefAttacherAfterLoad(
                res.items,
                assignableProgramComponents
              );
              isSubscribed && setAssessItems(dataWithRef);
            }
          );
        }
        if (!dataCollectionItems) {
          RiskAssessmentService.getAssessmentDataCollectionByBuilderID(builderID).then(
            (res) => {
              setDataCollectionItems(res.payload);
            }
          );
        }
      }
      if (builderStep >= 3) {
        RiskAssessmentService.getAssessmentTypeByID(builderID).then((res) => {
          setAssessmentType(res.reviewTypeId);
        });
      }
      if (builderStep >= 4) {
        RiskAssessmentService.getAssessmentChecklistItemsByBuilderID(
          builderID
        ).then((res) => {
          setCheckListData(res.payload);
        });
      }
      if (builderStep >= 5) {
        RiskAssessmentService.getAssessorsByAssessmentID(builderID).then(
          (res) => {
            setAssessors(res.payload);
          }
        );
      }
    }
    //List out each step and corresponding GET
    return () => (isSubscribed = false);
  }, [
    props.builderInfo.Step, props.builderInfo.Builder_ID,
    assignableProgramComponents, dataCollectionItems, props.mode
  ]);

  const allButtonsDisabled = (
    (activeStep === 1 && !recordFormData.Title) ||
    (activeStep === 2 && assessItems.length === 0) ||
    (activeStep === 3 && !assessmentType)
  );
  const savePageDisabled = allButtonsDisabled;

  const handlePublish = async () => {
    const newAssessmentData = buildAssessmentData()
    //Only set the step to 6 if publishing
    await RiskAssessmentService.updateAssessmentData(
      {...newAssessmentData, Step: 6, Status: "Active"},
      recordFormData.Builder_ID,
      1
    );
    // props.setHasUnsavedChanges(false);
    setAssessBuilderInfo({
      ...assessBuilderInfo,
      Status: "Active"
    });
  }

  const highestSavedStepFinder = () => {

    if (assessBuilderInfo.Step > activeStep) {
      return assessBuilderInfo.Step;
    } else {
      return activeStep;
    }
  };

  const stepIconClick = async (prevStep, newStep) => {
    setActiveStep(newStep);
  };

  const buildAssessmentData = () => ({
    Program_ID: props.builderInfo.Program_ID || programIdParam, // The latter is needed for builders accessed via non-Orchestration level
    Title: recordFormData.Title.trim(),
    Description: recordFormData.Description.trim(),
    Version: recordFormData.Version,
    Start_Date:
      recordFormData.Start_Date === "" ? null : recordFormData.Start_Date,
    End_Date: recordFormData.End_Date === "" ? null : recordFormData.End_Date,
    Step: highestSavedStepFinder(),
    Review_Type: recordFormData.Review_Type,
    Status: recordFormData.Status,
    Change_User: user.id,
    Change_Date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
  });

  // This has to be done manually here outside of forms because saving was also done that way
  const validateFormErrors = stepNumber => {
    const errors = {};
    const stepToValidate = stepNumber || activeStep;
    switch(stepToValidate) {
      case 1:
        if (recordFormData.Title?.length > 45) {
          errors.Title = "Must be 45 characters or less";
        }
        const minDate = moment().subtract(1, "years");
        const maxDate = moment().add(10, "years");
        const dateFields = ["Start_Date", "End_Date"].filter(fieldName =>
          recordFormData[fieldName]
        );
        for (const field of dateFields) {
          const fieldDate = moment(recordFormData[field]);
          if (fieldDate.isBefore(minDate)) {
            errors[field] = `Must be after ${minDate.subtract(1, "days").format("MM/DD/YYYY")}`;
          } else if (fieldDate.isAfter(maxDate)) {
            errors[field] = `Must be before ${maxDate.add(1, "days").format("MM/DD/YYYY")}`;
          }
        }
        if (recordFormData.Start_Date && recordFormData.End_Date) {
          if (moment(recordFormData.Start_Date).isAfter(moment(recordFormData.End_Date))) {
            errors.End_Date = "Must be on or after Start Date";
          }
        }
        return errors;
      case 4:
        if (checkListData) {
          const invalidIds = checkListData
            .filter(item => item.Checklist_Item?.length > 45)
            .map(item => item.ChecklistItem_ID);

          if (invalidIds.length) {
            for (const id of invalidIds) {
              errors[`checklist-item-${id}`] = "Checklist items must be 45 characters or less.";
            }
          }
        }
        return errors;
      default:
        return {};
    }
  };

  const saveCreateBuilder = useCallback(async assessmentData => {
    const response = (
      await RiskAssessmentService.saveBasicAssessmentData(assessmentData)
    );
    const formBuilderId = response.payload.Builder_ID;
    setRecordFormData({
      Builder_ID: formBuilderId,
      ...assessmentData,
    });
    setAssessBuilderInfo({
      Builder_ID: formBuilderId,
      ...assessmentData,
    });
  }, []);

  const updateAssessmentData = useCallback(async (data, builderId, type) => {
    const assessmentData = { ...data };
    if (type) {
      assessmentData.Review_Type = type;
    }
    await RiskAssessmentService.updateAssessmentData(
      assessmentData,
      builderId,
      1
    );
    if (type) {
      setAssessmentType(type);
      setRecordFormData(prev => ({
        ...prev,
        Review_Type: type
      }));
      setAssessBuilderInfo((prev) => ({
        ...prev,
        Review_Type: type,
      }));
    }
  }, []);

  const handleSave = async (stepNumToSave, dataListToInsert, dataListToDelete, dataListToEdit) => {
    const formValidationErrors = validateFormErrors(stepNumToSave);
    setFormErrors(formValidationErrors);
    if (Object.keys(formValidationErrors).length) {
      return false;
    }

    const assessmentData = buildAssessmentData();
    const stepToSave = stepNumToSave || activeStep;

    /* ======= STEP 1 ======= */
    if (
      stepToSave === 1 &&
      oneOrMoreFieldsChanged(
        editableBuilderFields,
        assessBuilderInfo,
        recordFormData
      )
    ) {
      if (!recordFormData.Builder_ID) {
        saveCreateBuilder(assessmentData);
        return true;
      } else {
        await updateAssessmentData(assessmentData, recordFormData.Builder_ID);
      }
      /* ======= STEP 2  assess items ======= */
    } else if (stepToSave === 2) {
      const assessItemsCopy = [...assessItems]

      if (dataListToInsert) {
        const dataToInsertForeignRefList = dataListToInsert.map(item => item.Foreign_Ref)
        setIsSavingAssessmentItems(dataToInsertForeignRefList)
        const assessItemsWithIDs = assessItemsCopy.filter(item => typeof item.AssessmentItem_ID === "number")
        const createdItems = await createAssessmentItems(dataListToInsert)
        const updatedItemsWithRef = assessItemRefAttacherAfterLoad(
            createdItems.payload,
            assignableProgramComponents
        );
        setAssessItems([...assessItemsWithIDs, ...updatedItemsWithRef]);

      } else if (dataListToDelete) {
        const dataToDeleteForeignRefList = dataListToDelete.map(item => item.Foreign_Ref)
        const assessItemIDsToDelete = dataListToDelete.map(item => item.AssessmentItem_ID)
        setIsSavingAssessmentItems(dataToDeleteForeignRefList)
        const associatedChecklistItems = checkListData
          .filter(checkItem => (
            assessItemIDsToDelete.includes(checkItem.AssessmentItem_ID)
          ))
          .map(checkItem => ({ ...checkItem, isDelete: true }));

        if (associatedChecklistItems.length > 0) { // Delete associated checklistItems if amy exist
          await editAssessObjs(associatedChecklistItems, updateAssessmentMapping.ASSESSMENT_CHECKLIST_ITEM.name)
        }
        const deletedItems = await riskAssessmentService.deleteAssessmentItems(
          assessItemIDsToDelete
        );
        const nonDeletedItems = assessItemsCopy.filter(item => (
          !deletedItems.payload.includes(item.AssessmentItem_ID)
        ))
        setAssessItems([...nonDeletedItems]);

      } else if (dataListToEdit) {
        setIsSavingAssessmentItems(dataListToEdit[0].Foreign_Ref)
        await editAssessObjs(dataListToEdit, updateAssessmentMapping.ASSESSMENT_ITEM.name)
        // calculate and update assessItems list
        const updatedAssessList = replaceObjInArrByID(assessItems, "AssessmentItem_ID", dataListToEdit[0])
        setAssessItems([...updatedAssessList]);
      }

      setIsSavingAssessmentItems([])

      // Reload data that may have been deleted with AssessmentItems
      const checklistItems = await RiskAssessmentService.getAssessmentChecklistItemsByBuilderID(
        recordFormData.Builder_ID
      );
      setCheckListData(checklistItems.payload);
        /* ======= STEP 3 select type ======= */
    } else if (stepToSave === 3 && dataListToEdit) {
      // attaching assessmentreviewtype to assessBuilderInfo for review render
      const editedObj = dataListToEdit[0]
      const newAssessType = findAssessmentTypeByState(editedObj);
      await updateAssessmentData(
        assessmentData, recordFormData.Builder_ID, newAssessType
      );
      /* ======= STEP 4 checklist items ======= */
    } else if (stepToSave === 4) {
      if (dataListToInsert) {
        setIsSavingChecklistItems(true)
        const normalizedNewCheckItem = normalizeChecklistItem(dataListToInsert[0])
        const { payload } = await RiskAssessmentService.createAssessmentChecklistItem(normalizedNewCheckItem)
        setCheckListData(prev => [...prev, payload])
      } else if (dataListToDelete) {
        setIsSavingChecklistItems(true)
        const objToDel = { ...dataListToDelete[0], isDelete: true };
        await editAssessObjs(
         [objToDel, ...dataListToEdit],
         updateAssessmentMapping.ASSESSMENT_CHECKLIST_ITEM.name
        );
        const checkListWithoutDeleted = checkListData.filter(
         (check) => check.ChecklistItem_ID !== objToDel.ChecklistItem_ID
        );
        const updatedList = replaceObjInArrByArrInPlace(
         [...checkListWithoutDeleted],
         dataListToEdit,
         "ChecklistItem_ID"
        );
        setCheckListData(updatedList)

      } else if (!dataListToDelete && dataListToEdit && dataListToEdit.length === 1) {
        setIsSavingChecklistItems(true)
        await editAssessObjs(dataListToEdit, updateAssessmentMapping.ASSESSMENT_CHECKLIST_ITEM.name)
        const updatedChecklist = replaceObjInArrByID([...checkListData], "ChecklistItem_ID", dataListToEdit[0])
        setCheckListData(updatedChecklist);

      } else if (!dataListToDelete && dataListToEdit && dataListToEdit.length > 1) {
        setIsSavingChecklistItems(true)
        await editAssessObjs(dataListToEdit, updateAssessmentMapping.ASSESSMENT_CHECKLIST_ITEM.name)
        const updatedCheckListData = replaceObjInArrByArrInPlace(
          [...checkListData],
          dataListToEdit,
          "ChecklistItem_ID"
        );
        setCheckListData(updatedCheckListData);
      }
      setIsSavingChecklistItems(false)
      /* ======= STEP 5 assignments ======= */
    } else if (stepToSave === 5) {
      if (dataListToInsert) {
        const newAssessorData = dataListToInsert[0];
        const newAssessorToCreate =
         normalizeAssessmentAssignment(newAssessorData);
        const { payload } = await RiskAssessmentService.createAssessmentAssessor(
         newAssessorToCreate
        );
        if (payload.AssessmentAssignment_ID) {
          setAssessors((prev) => [
            ...prev,
            {
              ...newAssessorData,
              AssessmentAssignment_ID: payload.AssessmentAssignment_ID,
            },
          ]);
        }
      } else if (dataListToEdit) {
        const assessorData = dataListToEdit[0];
        const assessorToEdit = normalizeAssessmentAssignment(assessorData);
        const updatedAssessors =
         await RiskAssessmentService.updateAssessmentData(
           assessorToEdit,
           assessorToEdit.AssessmentAssignment_ID,
           updateAssessmentMapping.ASSESSMENT_ASSIGNMENT.tableID
         );
        if (updatedAssessors.payload) {
          setAssessors((prev) => {
            const staleIndex = prev.findIndex(
             (assessor) =>
               assessor.User_ID === assessorData.User_ID
            );
            if (staleIndex > -1) {
              prev.splice(staleIndex, 1);
            }
            return [...prev, { ...assessorToEdit, First_Name: assessorData.First_Name, Last_Name: assessorData.Last_Name}];
          });
        }
      } else if (dataListToDelete) {
        const assessorToDel = dataListToDelete[0];
        const formattedAssessorToDel = {...assessorToDel, isDelete: true}
        const deletedAssessor = await RiskAssessmentService.updateAssessmentData(
        formattedAssessorToDel,
        formattedAssessorToDel.AssessmentAssignment_ID,
        updateAssessmentMapping.ASSESSMENT_ASSIGNMENT.tableID
        );
        if (deletedAssessor) {
          setAssessors(previous => previous.filter(prev => prev.AssessmentAssignment_ID !== formattedAssessorToDel.AssessmentAssignment_ID));
        }
      }
    }
    return afterSave(stepToSave, assessmentData, recordFormData.Builder_ID);
  };

  const afterSave = useCallback(
    async (stepToSave, assessmentData, formBuilderId) => {
      if (![1, 3].includes(stepToSave)) {
        await RiskAssessmentService.updateAssessmentData(
          assessmentData,
          formBuilderId,
          1
        );
      }
      // Necessary to keep highest saved step current
      setAssessBuilderInfo({
        Builder_ID: formBuilderId,
        ...assessmentData,
      });
      return true;
    },
    []
  );

  const saveProgress = async (currStep) => {
    if (optionalSteps.includes(currStep) && highestSavedStepFinder() <= currStep) {
      const assessmentData = buildAssessmentData();
      const formBuilderId = recordFormData.Builder_ID;
      await RiskAssessmentService.updateAssessmentData(
        assessmentData,
        formBuilderId,
        1
      );
      setAssessBuilderInfo({
        Builder_ID: formBuilderId,
        ...assessmentData,
      });
    }
    return true
  }

  const [messageText, messageType] = [].concat(message || []);

  const hasAlert = !!messageText && ![modes.MODE_COMPLETE, modes.MODE_REPORT].includes(props.mode);

  const contentData = {
    1: (
      <AssessmentBuilderBasicInfo
        formErrors={formErrors}
        recordFormData={recordFormData}
        setRecordFormData={setRecordFormData}
        handleSave={() => handleSave(activeStep)}
      />
    ),
    2: (
      <SetAssessmentItems
        builderInfo={assessBuilderInfo}
        builderId={assessBuilderInfo.Builder_ID}
        assessItems={assessItems}
        setAssessItems={setAssessItems}
        user={user}
        programID={programId}
        programComponentList={assignableProgramComponents}
        assessItemIdsWithReviews={uniqueAssessItemIdsWithReviewFinder(dataCollectionItems)}
        handleSave={handleSave}
        isSavingAssessmentItems={isSavingAssessmentItems}
      />
    ),

    3: (
      <AssessmentTypeOptions
        assessmentType={assessmentType}
        setAssessmentType={setAssessmentType}
        assessItems={assessItems}
        programID={programId}
        programComponentList={assignableProgramComponents}
        handleSave={handleSave}
      />
    ),
    4: (
      <AddChecklist
        checkListData={checkListData}
        setCheckListData={setCheckListData}
        assessItems={assessItems}
        formErrors={formErrors}
        user={user}
        assessmentType={assessmentType}
        builderId={assessBuilderInfo.Builder_ID}
        programComponentList={assignableProgramComponents}
        programID={programId}
        handleSave={handleSave}
        isSavingChecklistItems={isSavingChecklistItems}
      />
    ),
    5: (
      <AssignAssessments
        builderInfo={assessBuilderInfo}
        assessors={assessors}
        setAssessors={setAssessors}
        user={user}
        handleSave={handleSave}
      />
    ),
    6: (
      <AssessmentSetupFinalView
        builderInfo={assessBuilderInfo}
        assessItems={assessItems}
        checkListData={checkListData}
        assessors={assessors}
        programComponentList={assignableProgramComponents}
        programID={programId}
        assessmentType={assessmentType}
      />
    ),
  };

  return (
    <>
      {!!hasAlert && (
        <Alert
          className={classes.alertStyle}
          severity={messageType}
          onClose={() => {
            setMessage("");
          }}
          data-cy={`alert-${messageText}`}
        >
          {messageText}
        </Alert>
      )}
      <Switch>
        <Route path={[
          `${basePath}/${modes.MODE_LOAD_TEMPLATE}`,
          `${baseBuilderPath}/${modes.MODE_EDIT}`,
        ]}
        >
          <div className={classes.pageContent}>
            <Box className={classes.stepperWrapper} sx={{ boxShadow: 3 }}>
              <Stepper
                activeStep={activeStep - 1}
                setActiveStep={setActiveStep}
                steps={steps}
                highestSavedStep={highestSavedStepFinder()}
                stepIconClick={stepIconClick}
              />
            </Box>
            <StepperContent>
              {contentData[activeStep]}
            </StepperContent>
            <AssessmentBuilderSaveBar
              activeStep={activeStep}
              builderInfo={assessBuilderInfo}
              isContinueDisabled={allButtonsDisabled}
              isSaveDisabled={savePageDisabled}
              publish={handlePublish}
              setActiveStep={setActiveStep}
              setMode={props.setMode}
              saveProgress={saveProgress}
              numAsessItems={assessItems?.length}
            />
          </div>
        </Route>
        <Route path={`${baseBuilderPath}/${modes.MODE_COMPLETE}`}>
          <div className={classes.pageContent}>
            <StepperContent mode={props.mode} readOnly={props.readOnly}>
              {contentData[6]}
            </StepperContent>
          </div>
        </Route>
        <Route path={[
          `${baseBuilderPath}/${modes.MODE_REVIEW}/:subMode/:activeId`,
          `${baseBuilderPath}/${modes.MODE_REVIEW}/`,
        ]}
        >
          <div className={classes.assessmentReviewWrapper}>
            <AssessmentReview
              builderInfo={assessBuilderInfo}
              basePath={`${baseBuilderPath}/${modes.MODE_REVIEW}`}
              basePathTemplate={`${baseBuilderTemplate}/${modes.MODE_REVIEW}`}
              setMessage={setMessage}
              assessors={assessors}
              buildAssessmentData={buildAssessmentData}
              recordFormData={recordFormData}
              setBuilderInfo={setAssessBuilderInfo}
              assessBuilderInfo={assessBuilderInfo}
              setMode={props.setMode}
              setAssessors={setAssessors}
              hasUnsavedChanges={props.hasUnsavedChanges}
              setHasUnsavedChanges={props.setHasUnsavedChanges}
              withUnsavedChanges={props.withUnsavedChanges}
            />
          </div>
        </Route>
        <Route path={`${baseBuilderPath}/${modes.MODE_REPORT}`}>
          <CompletedReportPage
            assessors={assessors}
            builderInfo={assessBuilderInfo}
          />
        </Route>
      </Switch>
    </>
  );
};

export default AssessmentBuilder;
