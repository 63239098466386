import { Box, Chip, makeStyles } from "@material-ui/core";
import Icons from "components/utils/icons.component";
import CustomLink from "components/utils/link.component";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { ACTION_DELETE_MANUAL_SECTION_REFERENCE, ACTION_REPLACE_MANUAL_SECTION_REFERENCE, ACTION_SET_CFR_ITEMS } from "reducers/certification/manuals.reducer";
import certificationService from "services/certification.service";
import { getLongCfrLabel } from "utils/cfrUtils";
import CfrReferenceModal from "../modals/cfrReferenceModal.component";

const useStyles = makeStyles((theme) => {
  const referenceChipLeftMargin = 8;
  const referenceChipBottomMargin = 4;
  return {
    customIconWrapper: {
      height: 16,
      alignItems: "center",
      margin: "2px 6px 2px 0",
      display: "inline-flex",
      fill: "currentColor"
    },
    manageLink: {
      marginTop: 8 - referenceChipBottomMargin
    },
    referenceChip: {
      display: "inline-block",
      marginLeft: referenceChipLeftMargin,
      marginBottom: referenceChipBottomMargin,
    },
    referencesContainer: {
      marginLeft: referenceChipLeftMargin * -1
    },
    referencesLabel: {
      marginRight: 8,
      color: theme.palette.text.secondary,
      fontWeight: 600
    },
    referenceLinkIcon: {
      display: "inline-block",
      marginRight: 8
    },
    referencesValue: {
      color: theme.palette.text.secondary
    },
    referenceList: {
      marginBottom: 8
    },
  }
});

export default function CfrReferenceSelection(props) {
  const { dispatch, manualSection, state } = props;

  const [initialActiveCfrItem, setInitialActiveCfrItem] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => (
    (async function loadParts() {
      if (!isExpanded || state.cfrItems) {
        return;
      }
      const cfrResponse = await certificationService.getCfrItems();
      dispatch({ type: ACTION_SET_CFR_ITEMS, payload: cfrResponse.payload });
    })()
  ), [dispatch, isExpanded, state.cfrItems]);

  const checkedCfrIds = useMemo(() => (
    manualSection?._associations?.ManualSectionReference?.map?.(reference => (
      reference.CFRReference_ID
    )) || []
  ), [manualSection._associations.ManualSectionReference]);

  const handleOpenClick = useCallback(() => {
    if (!initialActiveCfrItem) {
      setInitialActiveCfrItem(state.cfrItems?.[0]);
    }
    setIsExpanded(true);
  }, [initialActiveCfrItem, setInitialActiveCfrItem, setIsExpanded, state.cfrItems]);

  const handleChipClick = useCallback(cfrItem => {
    setInitialActiveCfrItem(cfrItem);
    setIsExpanded(true);
  }, [setInitialActiveCfrItem, setIsExpanded]);

  const handleClose = useCallback(() => {
    setIsExpanded(false);
    setInitialActiveCfrItem(null);
  }, [setIsExpanded, setInitialActiveCfrItem]);

  const requestCreateReference = useCallback(async cfrId => {
    const referenceResponse = await certificationService.createManualSectionReference(
      manualSection.ManualSection_ID,
      { CFRReference_ID: cfrId }
    );
    dispatch({
      type: ACTION_REPLACE_MANUAL_SECTION_REFERENCE,
      payload: referenceResponse.payload,
      meta: referenceResponse.meta
    });
  }, [manualSection.ManualSection_ID, dispatch]);

  const requestDeleteReference = useCallback(async cfrId => {
    const referenceResponse = await certificationService.deleteManualSectionReference(
      manualSection.ManualSection_ID, cfrId
    );
    dispatch({
      type: ACTION_DELETE_MANUAL_SECTION_REFERENCE,
      payload: referenceResponse.payload
    });
  }, [dispatch, manualSection]);

  const handleCheckboxChange = useCallback((event, cfrId) => {
    if (event.target.checked) {
      requestCreateReference(cfrId);
    } else {
      requestDeleteReference(cfrId);
    }
  }, [requestCreateReference, requestDeleteReference]);

  if (!state.cfrItems) {
    return null;
  }
  return (
    <div>
      <CfrReferenceChips
        manualSection={manualSection}
        onChipClick={handleChipClick}
        onOpenClick={handleOpenClick}
        state={state}
      />
      <CfrReferenceModal
        checkedCfrIds={checkedCfrIds}
        defaultActiveCfrItem={initialActiveCfrItem}
        dispatch={dispatch}
        open={isExpanded}
        onClose={handleClose}
        onChange={handleCheckboxChange}
        state={state}
      />
    </div>
  )
}

const CfrReferenceChips = memo(CfrReferenceChipsUnmemoized);

function CfrReferenceChipsUnmemoized(props) {
  const classes = useStyles();
  const { manualSection, onChipClick, onOpenClick, state } = props;

  const cfrItemsForReferences = useMemo(() => {
    if (
      !state.cfrItemsByIdFlattened ||
      !manualSection?._associations?.ManualSectionReference?.length
    ) {
      return [];
    }
    return manualSection._associations?.ManualSectionReference.map(reference => (
      state.cfrItemsByIdFlattened[reference.CFRReference_ID]
    ));
  }, [state.cfrItemsByIdFlattened, manualSection._associations?.ManualSectionReference]);

  const chipLabels = useMemo(() => (
    cfrItemsForReferences?.reduce?.((accumulator, cfrItem) => ({
      ...accumulator,
      [cfrItem.CFR_ID]: getLongCfrLabel(cfrItem.CFR_ID, state.cfrItemsByIdFlattened)
    }), {})
  ), [cfrItemsForReferences, state.cfrItemsByIdFlattened]);

  return (
    <div className={classes.referenceList} data-cy="cfr-reference-list">
      <Box display="flex" flexDirection="column">
        <Box className={classes.referencesLabel} marginBottom={1}>
          <div className={classes.customIconWrapper}>
            <Icons className={classes.referenceLinkIcon} variant="scales-icon" />
            CFR References
          </div>
        </Box>
        {cfrItemsForReferences.length ? (
          <div className={classes.referencesContainer}>
            {cfrItemsForReferences.map(cfrItem => (
              <div className={classes.referenceChip} key={cfrItem.CFR_ID}>
                <Chip
                  label={chipLabels[cfrItem.CFR_ID]}
                  variant="outlined"
                  onClick={() => onChipClick(cfrItem)}
                />
              </div>
            ))}
          </div>
        ) : (
          <span className={classes.referencesValue}>None</span>
        )}
      </Box>
      <div className={classes.manageLink}>
        <CustomLink
          onClick={onOpenClick}
          test="add-part-reference"
          variant="customUnderline"
        >
          Manage References
        </CustomLink>
      </div>
    </div>
  );
}
