import React from "react";

/*
 * Values:
 * {
 *   dispatch: asyncDispatch,
 *   state: AlertReducer
 * }
 */
const AlertContext = React.createContext();

export default AlertContext;
