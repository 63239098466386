import React from "react";

const Documents = () => {

  return (
    <div >
      Documents Component
    </div>
  );
};

export default Documents;