import { GiPencilRuler, GiSpeedometer } from "react-icons/gi";


export const DCT_ELEMENT_TYPE_ED = "ED";
export const DCT_ELEMENT_TYPE_EP = "EP";
export const DCT_ELEMENT_TYPE_SD = "SD";

export const DCT_ELEMENT_TYPES = [
  DCT_ELEMENT_TYPE_ED,
  DCT_ELEMENT_TYPE_EP,
  DCT_ELEMENT_TYPE_SD
];

export const DCT_ELEMENT_SPECIALTY_OP = "OP";
export const DCT_ELEMENT_SPECIALTY_AW = "AW";

export const CATEGORY_SCROLL_URL_PARAM = "category";

export const DCT_ELEMENT_TYPE_DISPLAY = {
  [DCT_ELEMENT_TYPE_ED]: "Element Design",
  [DCT_ELEMENT_TYPE_EP]: "Element Performance",
};

export const DCT_ELEMENT_SPECIALTY_DISPLAY = {
  [DCT_ELEMENT_SPECIALTY_OP]: "Operations",
  [DCT_ELEMENT_SPECIALTY_AW]: "Airworthiness",
};

export const DCT_ELEMENT_SPECIALTY_DISPLAY_SHORT = {
  [DCT_ELEMENT_SPECIALTY_OP]: "OP",
  [DCT_ELEMENT_SPECIALTY_AW]: "AW",
};

export const DCT_ELEMENT_TYPE_ICONS = {
  [DCT_ELEMENT_TYPE_ED]: GiPencilRuler,
  [DCT_ELEMENT_TYPE_EP]: GiSpeedometer,
};

const recursiveFindElement = (categories, accumulator = []) => (
  categories.flatMap(category => {
    if (category._associations?.DctCategory?.length) {
      return accumulator.concat(
        recursiveFindElement(category._associations?.DctCategory)
      );
    } else if (category._associations?.DctElement) {
      return accumulator.concat(category._associations?.DctElement);
    }
    return accumulator;
  })
);

export const findCategoryElements = categories => (
  categories?.flatMap?.(category => (
    recursiveFindElement([category])
  )) || []
);

export const findIndexElements = index => (
  index?.flatMap?.(indexItem => (
    recursiveFindElement([indexItem])
  )) || []
);


