import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import DataTable from "components/utils/tables/dataTable.component";
import { Box } from "@material-ui/core";
import Loader from "components/utils/loader.components";
import moment from "moment";
import DueDateCell from "./dueDateCell.component";
import ButtonDefault from "components/utils/buttonDefault.component";
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import styleVariables from "styleVariables";
import { createNodeCellParams, createNodeHeaderParams, createValueHeaderParams } from "components/utils/tables/utils/dataTableHelpers";
import { DATE_FORMAT_DISPLAY_NUMERIC } from "utils/dateConstants";

const useStyles = makeStyles((theme) => ({
  iconSmall: {
    fontSize: styleVariables.fontSmall,
    marginRight: 6,
    color: "white",
  },
  moduleButtonColumn: {
    width: 80
  }
}));


const MyTrainingAssignmentsTable = ({
  modules,
  setSelectedAssignmentId,
  isCompletedModules,
}) => {
  const classes = useStyles();

  const buttonHeader = useMemo(() => (
    createNodeHeaderParams("Module_Button", " ", {
      align: "center",
      setCellProps: () => ({ className: classes.moduleButtonColumn }),
      sort: false,
    })
  ), [classes]);

  const moduleNameHeader = useMemo(() => (
    createValueHeaderParams("Module_Name", "Module")
  ), []);

  const headers = useMemo(() => (
    isCompletedModules ? [
      buttonHeader,
      moduleNameHeader,
      createNodeHeaderParams("End_Date", "Completed On")
    ] : [
      buttonHeader,
      moduleNameHeader,
      createNodeHeaderParams("CompleteBy_Date", "Due Date"),
      createNodeHeaderParams("Start_Date", "Started Date")
    ]
  ), [buttonHeader, isCompletedModules, moduleNameHeader]);

  const tableCells = useMemo(() => {
    return (
      modules?.map((row) => {
        const completeByDate = (
          row.CompleteBy_Date &&
          moment(row.CompleteBy_Date).format(DATE_FORMAT_DISPLAY_NUMERIC)
        )
        const startDate = (
          row.Start_Date &&
          moment(row.Start_Date).format(DATE_FORMAT_DISPLAY_NUMERIC)
        );
        const endDate = (
          row.End_Date &&
          moment(row.End_Date).format(DATE_FORMAT_DISPLAY_NUMERIC)
        );
        return {
          Module_Button: createNodeCellParams(null, null, (
            <ButtonDefault
              variant="small"
              background="primary"
              startIcon={
                <ImportContactsIcon
                  className={classes.iconSmall}
                  size="small"
                />
              }
              onClick={() => (
                setSelectedAssignmentId(row.TrainingAssignment_ID)
              )}
            >
              Open
            </ButtonDefault>
          )),
          Module_Name: row.TrainingModule_Name,
          CompleteBy_Date: !isCompletedModules && (
            createNodeCellParams(completeByDate, completeByDate, (
              <DueDateCell trainingModule={row} />
            ))
          ),
          Start_Date: createNodeCellParams(row.Start_Date, startDate, (
            startDate || "--"
          )),
          End_Date: createNodeCellParams(row.End_Date, endDate, (
            endDate || "--"
          )),
        };
      }) || []
    );
  }, [modules, setSelectedAssignmentId, isCompletedModules, classes.iconSmall]);

  if (!modules) {
    return <Loader />;
  }

  return (
    <Box>
      <DataTable
        data={tableCells}
        columns={headers}
        options={{
          textLabels: {
            body: {
              noMatch: isCompletedModules
                ? "No completed assignments"
                : "No active assignments",
            },
          },
          filterType: "checkbox",
          fixedHeader: true,
          fixedSelectColumn: true,
          filter: false,
          pagination: false,
          selectableRowsHideCheckboxes: true,
          viewColumns: false,
          download: false,
          print: false,
          sortOrder: !isCompletedModules && {
            name: "CompleteBy_Date",
            direction: "desc",
          },
        }}
      />
    </Box>
  );
};

export default MyTrainingAssignmentsTable;
