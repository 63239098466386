import createTheme from "core/createTheme";
import variables from "./styleVariables";

const theme = createTheme({
  palette: {
    primary: {
      light: variables.primaryLight,
      main: variables.primaryMain,
      dark: variables.primaryDark,
    },
    secondary: {
      light: variables.secondaryLight,
      main: variables.secondaryMain,
      dark: variables.secondaryDark,
    },
    error: {
      light: variables.warningLight,
      main: variables.warningMain,
    },
    text: {
      primary: variables.textPrimary,
      secondary: variables.textSecondary,
      error: variables.warningMain,
    },
    background: {
      default: "#ffffff",
    },
    border: {
      light: variables.rowBorderLight,
      main: variables.rowBorder,
      dark: variables.rowBorderDark,
    },
  },
  typography: {
    fontFamily: "Helvetica, cursive",
    h1: variables.fontH1,
    h2: variables.fontH2,
    h3: variables.fontH3,
    h4: variables.fontH4,
    h5: variables.fontH5,
    h6: variables.fontH6,
    body2: {
      color: variables.textSecondary,
    },
  },
  body: {
    backgroundColor: "white",
  },
  background: {
    default: "#ffffff",
  },
  layout: {
    height: {
      appBar: 80,
    },
  },
  // Make All Tables have Row Hover
  props: {
    MuiTableRow: {
      hover: true,
    },
  },
  MuiTableRow: {
    root: {
      "&:hover": {
        background: variables.rowHover,
      },
    },
  },
  overrides: {
    MuiFormControl: {
      marginDense: {
        marginTop: 0,
      },
    },
    MuiInput: {
      underline: {
        "&:hover:not($disabled):before": {
          borderBottom: `2px solid ${variables.secondaryMain}`,
        },
      },
    },
    MuiToggleButton: {
      sizeSmall: {
        padding: "12px 20px",
      },
      root: {
        lineHeight: "normal",
        color: variables.tertiary1,
        "&:hover": {
          color: variables.secondaryMain,
          backgroundColor: "none",
        },
        "&$selected": {
          color: "white",
          backgroundColor: variables.secondaryMain,
          "&:hover": {
            backgroundColor: "none",
            color: "white",
          },
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: variables.fontSmall,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: variables.fontSmall,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: variables.fontXs,
        lineHeight: "normal",
        fontWeight: "bold",
        paddingTop: 5,
        paddingBottom: 6,
        color: "white",
        backgroundColor: variables.primaryMain,
      },
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      asterisk: {
        color: "inherit",
        "&$error": {
          color: "inherit",
        },
      },
      root: {
        color: variables.textSecondary,
        "&$focused": {
          color: variables.primaryMain,
        },
        "&$error": {
          color: variables.warningLight,
        },
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "rgba(119, 197, 253, 0.3)",
          "&:hover": {
            backgroundColor: "rgba(119, 197, 253, 0.3)",
          },
        },
      },
    },
    MuiTableRow: {
      hover: {
        "&$hover:hover": {
          backgroundColor: variables.rowHover,
        },
        "&$selected": {
          backgroundColor: variables.chosenElement,
          color: "yellow",
          "&:hover": {
            backgroundColor: variables.chosenElement,
          },
        },
      },
    },
    MuiAlert: {
      root: {
        marginTop: 30,
      },
    },
    MuiStepLabel: {
      label: {
        color: variables.grayDisabledText,
      },
    },
  },
});

export default theme;
