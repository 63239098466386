import {
  Divider,
  makeStyles,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Box,
  Dialog,
} from "@material-ui/core";
import ButtonDefault from "components/utils/buttonDefault.component";
import classNames from "classnames";
import variables from "styleVariables";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { UserCard } from "./userCard.component";
import AddListItem from "components/utils/addListItem.component";
import { useState, useEffect, useReducer, useCallback, useMemo } from "react";
import { Delete, Edit } from "@material-ui/icons";
import runbooksReducer, {
  runbooksInitialState,
  ACTION_SET_WORKGROUPS,
  ACTION_REMOVE_WORKGROUP,
  ACTION_SET_WORKSTREAMS,
  ACTION_SET_RUNBOOKS,
  ACTION_SET_ALL_RUNBOOK_PHASES,
  ACTION_REMOVE_WORKSTREAM,
  ACTION_SET_USERS,
} from "reducers/runbook/runbooks.reducer";
import RunbookService from "services/runbook.service";
import Loader from "components/utils/loader.components";
import UserService from "services/user.service";
import { EditWorkgroupForm } from "./forms/editWorkgroupForm.component";
import { EditWorkstreamForm } from "./forms/editWorkstreamForm.component";
import DialogPop from "components/utils/dialog.component";
import { UpdateMembersForm } from "./forms/updateMembersForm.component";
import RoleService from "services/role.service";
import RadioInputGroup from "components/utils/form-elements/radioInputGroup.component";
import RunbooksAdminSection from "./runbooksAdminSection.component";
import { isAdmin, isRunbookAdmin, runBookAndGlobalRolesFilter } from "utils/roles";
import { useHistory } from "react-router";
import CustomIcons from "components/utils/icons.component";
import { H3, H4 } from "components/utils/headerV2.component";

const useStyles = makeStyles((theme) => ({
  buttonContainerTop: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: 20,
    position: "absolute",
    right: 0,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
      marginBottom: 30,
    },
  },
  topAddButton: {
    top: 140,
  },
  newUserButton: {
    paddingRight: 25,
  },
  topDivider: {
    marginBottom: 16
  },
  headerRow: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 20,
  },
  iconWrapper: {
    width: 30,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 6,
    padding: "2px 0px",
  },
  headerIcon: {
    fill: variables.primaryMain,
    height: "100%",
    width: "auto",
    maxWidth: 27,
  },
  addUser: {
    padding: "20px 0px 20px 0px",
  },
  workgroupNameText: {
    fontSize: variables.fontH4,
    color: variables.tertiary1,
  },
  accordionContainer: {
    paddingTop: 10,
  },
  workgroupDescriptionContainer: {
    width: "100%",
  },
  accordionDetails: {
    padding: "20px 40px 40px 40px",
  },
  adminHeaderPadding: {
    paddingBottom: 64,
  },
  divider: {
    margin: "16px 0px",
  },
  iconButton: {
    boxShadow: "1px 0px 6px -1px rgb(0 0 0 / 60%)",
    borderRadius: 5,
    margin: 2,
  },
  deleteIconButton: {
    color: variables.warningLight,
  },
  editIconButton: {
    color: variables.secondaryDark
  },
  accordion: {
    flexGrow: 1,
    width: "90%",
    marginRight: 10,
    '&.MuiAccordion-root.Mui-expanded': {
      marginRight: 10,
    },
  },
  cardWrapper: {
    display: "inline-block",
    margin: 5,
  },
  fixedButtonIcon: {
    height: 20,
    width: 20,
    fill: "white",
  }
}));

// overall page modes
const workgroupMode = "workgroups";
const workstreamMode = "workstreams";
const runbookMode = "runbooks";

const RunbookAdmin = () => {
  const classes = useStyles();
  const [state, dispatch] = useReducer(
    runbooksReducer,
    runbooksInitialState
  );

  const history = useHistory();
  const runbookAdmin = useMemo(isRunbookAdmin, []);
  const admin = useMemo(isAdmin, []);

  // overall page modes
  const [mode, setMode] = useState(workgroupMode);
  const [workgroupToDelete, setWorkgroupToDelete] = useState(null);
  const [workstreamToDelete, setWorkstreamToDelete] = useState(null);
  const [userRoles, setUserRoles] = useState();

  // shared modal modes
  const [modalActive, setModalActive] = useState(false);
  const [formMode, setFormMode] = useState(null);

  // workgroup form modes
  const [workgroupFormActive, setWorkgroupFormActive] = useState(false);
  const [activeWorkgroup, setActiveWorkgroup] = useState({});

  // workstream form modes
  const [workstreamFormActive, setWorkstreamFormActive] = useState(false);
  const [activeWorkstream, setActiveWorkstream] = useState({});

  // membership form modes
  const [membershipTypeMode, setMembershipTypeMode] = useState(null);
  const [membershipFormActive, setMembershipFormActive] = useState(false);

  // runbook form modes
  const [isRunbookFormOpen, setIsRunbookFormOpen] = useState(false);
  useEffect(() => (
    (async function fetchData() {
      if (!runbookAdmin && !admin) {
        history.goBack();
      }
      const [
        workgroupsResponse,
        assignableUserResponse,
        rolesResponse,
        workstreamsResponse
      ] = await Promise.all([
        RunbookService.getAllWorkgroups(),
        UserService.getAll(),
        RoleService.getAll(),
        RunbookService.getAllWorkstreams()
      ]);
      dispatch({
        type: ACTION_SET_WORKGROUPS,
        payload: workgroupsResponse.payload
      });
      dispatch({
        type: ACTION_SET_WORKSTREAMS,
        payload: workstreamsResponse.payload
      })
      dispatch({
        type: ACTION_SET_USERS,
        payload: assignableUserResponse.payload
      });
      setUserRoles(runBookAndGlobalRolesFilter(rolesResponse.payload));
    })()
  ), [dispatch, admin, history, runbookAdmin]);

  useEffect(() => {
    (async function fetchRunbooksAdminData() {
      const [runbooksResponse, allPhasesResponse] = await Promise.all([
        RunbookService.getAllRunbooks(),
        RunbookService.getAllRunbookPhases(),
      ]);
      dispatch({
        type: ACTION_SET_RUNBOOKS,
        payload: runbooksResponse.payload,
      });
      dispatch({
        type: ACTION_SET_ALL_RUNBOOK_PHASES,
        payload: allPhasesResponse.payload,
      });
    })();
  }, []);

  const handleWorkgroupFormState = useCallback(async (currentMode, active, activeWg) => {
    setModalActive(active);
    setWorkgroupFormActive(active);
    setFormMode(currentMode);
    setActiveWorkgroup(activeWg)
  }, [setWorkgroupFormActive, setFormMode, setActiveWorkgroup]);

  const handleWorkstreamFormState = useCallback(async (currentMode, active, activeWs) => {
    setModalActive(active);
    setWorkstreamFormActive(active);
    setFormMode(currentMode);
    setActiveWorkstream(activeWs)
  }, [setWorkstreamFormActive, setFormMode, setActiveWorkstream]);

  const handleMembershipFormState = useCallback(async (currentMode, active, membershipEntity, type) => {
    setMembershipTypeMode(type);
    setModalActive(active);
    setMembershipFormActive(active);
    setFormMode(currentMode);
    if (type === "workgroup") {
      setActiveWorkgroup(membershipEntity);
    } else {
      setActiveWorkstream(membershipEntity);
    }
  }, [setMembershipTypeMode, setMembershipFormActive, setFormMode, setActiveWorkgroup, setActiveWorkstream]);

  const openAddUserModal = useCallback((membershipEntity, type) => (
    handleMembershipFormState("new", true, membershipEntity, type)
  ), [handleMembershipFormState]);

  const deleteWorkgroup = useCallback(async (workgroupId) => {
    const removedWorkgroupResponse = await RunbookService.deleteWorkgroup(workgroupId);
    dispatch({
      type: ACTION_REMOVE_WORKGROUP,
      payload: removedWorkgroupResponse.payload,
    });
    setWorkgroupToDelete(null);
  }, [dispatch]);

  const deleteWorkstream = useCallback(async (workstreamId) => {
    const removedWorkstreamResponse = await RunbookService.deleteWorkstream(workstreamId);
    dispatch({
      type: ACTION_REMOVE_WORKSTREAM,
      payload: removedWorkstreamResponse.payload,
    });
    setWorkstreamToDelete(null);
  }, [dispatch]);

  const cleanupModalStates = useCallback(() => {
    setModalActive(false);
    setMembershipFormActive(false);
    setFormMode(null);
    setActiveWorkgroup({});
    setWorkstreamFormActive(false);
    setActiveWorkstream({});
    setWorkgroupFormActive(false);
  },
    [setModalActive,
      setMembershipFormActive,
      setWorkstreamFormActive,
      setActiveWorkstream,
      setWorkgroupFormActive,
      setFormMode
    ]);

  if (!state.workgroups || !state.workstreams || !state.users) {
    return <Loader />
  }

  return (
    <>
      <Dialog
        PaperProps={{
          style: { height: membershipFormActive ? "100%" : undefined }
        }}
        maxWidth={membershipFormActive ? "md" : "sm"}
        fullWidth
        open={modalActive}
        onClose={cleanupModalStates}
      >
        {!!workgroupFormActive && (
          <EditWorkgroupForm
            formMode={formMode}
            activeWorkgroup={activeWorkgroup}
            assignableAdminUsers={state.users}
            onClose={() => handleWorkgroupFormState(null, false, {})}
            dispatch={dispatch}
          />
        )}
        {!!workstreamFormActive && (
          <EditWorkstreamForm
            formMode={formMode}
            activeWorkstream={activeWorkstream}
            onClose={() => handleWorkstreamFormState(null, false, {})}
            dispatch={dispatch}
          />
        )}
        {!!membershipFormActive && (
          <UpdateMembersForm
            dispatch={dispatch}
            formMode={formMode}
            activeMembershipEntity={
              membershipTypeMode === "workgroup" ?
                activeWorkgroup :
                activeWorkstream
            }
            assignableUsers={state.users}
            onClose={() => handleMembershipFormState(null, false, {})}
            userRoles={userRoles}
            type={membershipTypeMode}
          />
        )}
      </Dialog>
      <DialogPop
        largeLabel
        prompt="Are you sure you want to delete this workgroup? Any members will remain in the system, however, any tasks included in the workgroup will be permanently deleted."
        openDialog={workgroupToDelete}
        setOpenDialog={() => setWorkgroupToDelete(null)}
        confirm={() => deleteWorkgroup(workgroupToDelete)}
      />
      <DialogPop
        largeLabel
        prompt="Are you sure you want to delete this workstream? Any members will remain in the system, as well as any tasks mapped to the workstream."
        openDialog={workstreamToDelete}
        setOpenDialog={() => setWorkstreamToDelete(null)}
        confirm={() => deleteWorkstream(workstreamToDelete)}
      />
      <Box width="100%" marginTop={8} marginBottom={2}>
        <RadioInputGroup
          row
          name="admin-mode"
          value={mode}
          onChange={(event) => setMode(event.target.value)}
          options={[
            { label: "Manage Workgroups", value: workgroupMode },
            { label: "Manage Workstreams", value: workstreamMode },
            { label: "Manage Runbooks", value: runbookMode },
          ]}
          size="small"
          color="primary"
          noHelperText
          radioGroupClass={classes.radioGroupClass}
          hideHelperText
        />
      </Box>
      <Divider className={classes.topDivider} />
      <Grid container spacing={2}>
        {mode === workstreamMode && (
          <Grid item xs={12}>
            <HeaderSection
              label="Workstreams"
              icon={(
                <CustomIcons
                  className={classes.headerIcon}
                  variant="Workstream-Fill"
                />
              )}
            />
            <div className={classes.accordionContainer}>
              {state.workstreams?.map?.(workstream => (
                <WorkstreamAccordion
                  workstream={workstream}
                  onAddUsers={() => openAddUserModal(workstream, "workstream")}
                  onEditClick={() => {
                    handleWorkstreamFormState("edit", true, workstream)
                  }}
                  onDeleteClick={() => {
                    setWorkstreamToDelete(workstream.Workstream_ID)
                  }}
                  key={`workstream-${workstream.Workstream_ID}`}
                />
              ))}
            </div>
          </Grid>
        )}
        {mode === workgroupMode && (
          <Grid item xs={12}>
            <HeaderSection
              label="Workgroups"
              icon={(
                <CustomIcons
                  className={classes.headerIcon}
                  variant="Workgroup-Fill"
                />
              )}
            />
            <div className={classes.accordionContainer}>
              {state.workgroups?.map?.(workgroup => (
                <WorkgroupAccordion
                  workgroup={workgroup}
                  onAddUsers={() => openAddUserModal(workgroup, "workgroup")}
                  onEditClick={() =>
                    handleWorkgroupFormState("edit", true, workgroup)}
                  onDeleteClick={() =>
                    setWorkgroupToDelete(workgroup.Workgroup_ID)}
                  key={`workgroup-${workgroup.Workgroup_ID}`}
                />
              ))}
            </div>
          </Grid>
        )}
        {mode === runbookMode && (
          <Grid item xs={12}>
            <HeaderSection
              label="Runbooks"
              icon={<CustomIcons className={classes.headerIcon} variant="Runbook" />}
            />
            <RunbooksAdminSection
              state={state}
              dispatch={dispatch}
              setIsRunbookFormOpen={setIsRunbookFormOpen}
              isRunbookFormOpen={isRunbookFormOpen}
            />
          </Grid>
        )}

        <div className={classNames(classes.buttonContainerTop, classes.topAddButton)}>
          {mode === workstreamMode && (
            <ButtonDefault
              variant="medium"
              background="tertiary"
              startIcon={<CustomIcons className={classes.fixedButtonIcon} variant="Add-Workstream-Fill" />}
              className={classes.newUserButton}
              disableReadOnlyUsers
              fixedRight
              onClick={() => {
                handleWorkstreamFormState("create", true, {})
              }}
            >
              Add Workstream
            </ButtonDefault>
          )}
          {mode === workgroupMode && (
            <ButtonDefault
              variant="medium"
              background="tertiary"
              startIcon={<CustomIcons className={classes.fixedButtonIcon} variant="Add-Workgroup-Fill" />}
              className={classes.newUserButton}
              disableReadOnlyUsers
              fixedRight
              onClick={() => {
                handleWorkgroupFormState("create", true, {});
              }}
            >
              Add Workgroup
            </ButtonDefault>
          )}
          {mode === runbookMode && (
            <ButtonDefault
              variant="medium"
              background="tertiary"
              startIcon={<CustomIcons className={classes.fixedButtonIcon} variant="Add-Runbook-Fill" />}
              className={classes.newUserButton}
              disableReadOnlyUsers
              fixedRight
              onClick={() => {
                setIsRunbookFormOpen(true);
              }}
            >
              Add Runbook
            </ButtonDefault>
          )}
        </div>
      </Grid>
    </>
  );
}

const WorkstreamAccordion = (props) => {
  const classes = useStyles();
  const { onAddUsers, onEditClick, onDeleteClick, workstream } = props;
  return (
    <Box display="flex" padding={1}>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.accordionSummary}
        >
          <div className={classes.workgroupDescriptionContainer}>
            <div className={classes.workgroupNameText}>
              <strong>{workstream.Name}</strong>
            </div>
            <div className={classes.workgroupNameText}>
              {workstream.Description}
            </div>
          </div>
        </AccordionSummary>
        <Divider className={classes.divider} />
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={7} xl={8}>
              <H4 color="primary">Workstream Members</H4>
              <AddListItem
                text="Add User to Workstream"
                className={classes.addUser}
                onClick={onAddUsers}
              />
              {workstream._associations.WorkstreamMembers?.length ? (
                workstream._associations.WorkstreamMembers.map(member => (
                  <div
                    key={`member-${member.User_ID}`}
                    className={classes.cardWrapper}
                  >
                    <UserCard user={member._associations.Users[0]} />
                  </div>
                ))
              ) : (
                <div className={classes.workgroupNameText}>
                  No Workstream Members Assigned
                </div>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Box>
        <IconButton
          className={classNames(classes.editIconButton, classes.iconButton)}
          onClick={onEditClick}
        >
          <Edit />
        </IconButton>
        <IconButton
          className={classNames(classes.deleteIconButton, classes.iconButton)}
          onClick={onDeleteClick}
        >
          <Delete />
        </IconButton>
      </Box>
    </Box>
  );
}

const WorkgroupAccordion = (props) => {
  const classes = useStyles();
  const { onAddUsers, onEditClick, onDeleteClick, workgroup } = props;
  return (
    <Box display="flex" padding={1}>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.accordionSummary}
        >
          <div className={classes.workgroupDescriptionContainer}>
            <div className={classes.workgroupNameText}>
              <strong>{workgroup.Name}</strong>
            </div>
            <div className={classes.workgroupNameText}>
              {workgroup.Description}
            </div>
          </div>
        </AccordionSummary>
        <Divider className={classes.divider} />
        <AccordionDetails className={classes.accordionDetails}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={7} xl={8}>
              <H4 color="primary">Workgroup Members</H4>
              <AddListItem
                text="Add User to Workgroup"
                className={classes.addUser}
                onClick={onAddUsers}
              />
              {workgroup._associations.WorkgroupMembers?.length > 0 ?
                workgroup._associations.WorkgroupMembers?.map(member => (
                  <div
                    key={`${workgroup.Workgroup_ID}-${member.User_ID}`}
                    className={classes.cardWrapper}
                  >
                    <UserCard user={member._associations.Users[0]} />
                  </div>
                )) : (
                  <div className={classes.workgroupNameText}>
                    No Workgroup Members Assigned
                  </div>
                )
              }
            </Grid>
            <Grid item xs={12} lg={5} xl={4}>
              <H4 color="primary" className={classes.adminHeaderPadding}>
                Workgroup Admin
              </H4>
              {workgroup._associations.Users?.length > 0 ?
                workgroup._associations.Users?.map(admin => (
                  <div key={`admin-${admin.User_ID}`}>
                    <UserCard user={admin} />
                  </div>
                )) : (
                  <div className={classes.workgroupNameText}>
                    No Workgroup Members Assigned
                  </div>
                )
              }
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <div>
        <IconButton
          className={classNames(classes.editIconButton, classes.iconButton)}
          onClick={onEditClick}
        >
          <Edit />
        </IconButton>
        <IconButton
          className={classNames(classes.deleteIconButton, classes.iconButton)}
          onClick={onDeleteClick}
        >
          <Delete />
        </IconButton>
      </div>
    </Box>
  );
}

const HeaderSection = ({ label, icon }) => {
  const classes = useStyles();
  return (
    <div className={classes.headerRow}>
      <div className={classes.iconWrapper}>
        {icon}
      </div>
      <H3 color="primary">{label}</H3>
    </div>
  );
};

export default RunbookAdmin;
