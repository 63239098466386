import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import moment from "moment";
import classNames from "classnames";
import variables from "styleVariables";
import { formattedMomentCustomError } from "utils/dateHelpers";
import SetAssessors from "components/assessment/setAssessors.component";
import { H4, H5 } from "components/utils/headerV2.component";

const useStyles = makeStyles((theme) => ({
  assignMainContainer: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
  },
  subContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  leftBox: {
    marginRight: 5,
  },
  rightBox: {
    marginLeft: 5,
    flex: 1,
  },
  leftInnerContainer: {
    padding: 20,
    display: "flex",
    justifyContent: "flex-end",
  },
  rightInnerContainer: {
    padding: 20,
  },
  paper: {
    padding: "30px 40px 40px",
  },
  infoPaper: {
    width: "100%",
    maxWidth: 450,
    minWidth: 300,
  },
  paperHeader: {
    paddingBottom: 10,
  },
  dateSection: {
    display: "flex",
    flexFlow: "column",
  },
  dateRow: {
    display: "flex",
    alignItems: "center",
    paddingRight: 10,
    paddingBottom: 5,
  },
  date: {
    paddingLeft: 5,
  },
  description: {
    paddingTop: 5,
  },
  textColor: {
    color: variables.textSecondary,
  },
}));

const AssignAssessments = ({
  builderInfo,
  assessors,
  setAssessors,
  handleSave,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.assignMainContainer}>
      {/* ====== Left Side - INFO ====== */}
      <div className={classNames(classes.subContainer, classes.leftBox)}>
        <div className={classes.leftInnerContainer}>
          <Paper className={classNames(classes.paper, classes.infoPaper)}>
            <div className={classNames(classes.paperHeader)}>
              <H4 test="assignments-assessment-title">
                {builderInfo.Title}
              </H4>
            </div>
            <div className={classes.dateSection}>
              <DateDisplay
                dateLabel="Start"
                date={builderInfo.Start_Date}
                data-cy="assignments-assessment-start-date"
              />
              <DateDisplay
                dateLabel="End"
                date={builderInfo.End_Date}
                data-cy="assignments-assessment-end-date"
              />
            </div>
            <div className={classes.description} data-cy="assignments-assessment-description">
              <H5>Description:</H5>
              <div className={classes.textColor}>{builderInfo.Description}</div>
            </div>
          </Paper>
        </div>
      </div>

      {/* ====== Right Side - ASSIGNEES ====== */}
      <div className={classNames(classes.subContainer, classes.rightBox)}>
        <div className={classes.rightInnerContainer}>
          <SetAssessors
            builderInfo={builderInfo}
            formAssessors={assessors}
            setFormAssessors={setAssessors}
            handleSave={handleSave}
          />
        </div>
      </div>
    </div>
  );
};

export default AssignAssessments;


const DateDisplay = ({ dateLabel, date, "data-cy": dataCy }) => {
  const classes = useStyles();

  const dateFormatted = useMemo(() => (
    formattedMomentCustomError(
      moment(date).format("MM/DD/YYYY")
    )
  ), [date]);

  return (
    <div className={classes.dateRow} data-cy={dataCy}>
      <H5>{dateLabel}:</H5>
      <span className={classNames(classes.date, classes.textColor)}>
        {dateFormatted}
      </span>
    </div>
  );
};
