import React, { useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "inline-flex",
    alignItems: "baseline",
    fill: "currentColor"
  },
  headerNoMargin: {
    marginTop: 0,
    marginBottom: 0,
  },
  icon: {
    display: "inline-flex",
    alignItems: "center",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  sizeXl: {
    fontSize: variables.fontXl,
  },
  colorPrimary: {
    color: variables.primaryMain,
  },
  colorPrimaryLight: {
    color: variables.primaryLight,
  },
  colorSecondary: {
    color: variables.secondaryMain,
  },
  colorSecondaryDark: {
    color: variables.secondaryDark,
  },
  colorTertiary: {
    color: variables.tertiary1,
  },
  colorBlack: {
    color: "#000",
  },
  colorRed: {
    color: variables.warningMain,
  },
  colorYellow: {
    color: variables.statusCautionDark
  },
  colorWhite: {
    color: "#fff",
  },
}));

const COLOR_PRIMARY = "primary";
const COLOR_PRIMARY_LIGHT = "primaryLight";
const COLOR_SECONDARY = "secondary";
const COLOR_SECONDARY_LIGHT = "secondaryDark";
const COLOR_TERTIARY = "tertiary";
const COLOR_BLACK = "black";
const COLOR_RED = "red";
const COLOR_YELLOW = "yellow";
const COLOR_WHITE = "white";
const COLOR_INHERIT = "inherit";


export default function HeaderV2(props) {
  const classes = useStyles();
  const {
    capitalize = false,
    children,
    className = "",
    color = "primary",
    size = null,
    startIcon = null,
    tagName: HeaderTag = "h3",
    test = null,
    withMargin = false,
    ...htmlAttributes
  } = props;

  useEffect(() => {
    if (!HeaderTag.match(/^h\d$/i)) {
      throw new Error(`Invalid header tag: ${HeaderTag}`);
    }
  }, [HeaderTag]);

  const testAttribute = useMemo(() => (
    test || (HeaderTag.match(/h1/i) ? "pageTitle" : null)
  ), [HeaderTag, test]);

  const colorClass = useMemo(() => {
    switch(color) {
      case COLOR_PRIMARY:
        return classes.colorPrimary;
      case COLOR_PRIMARY_LIGHT:
        return classes.colorPrimaryLight;
      case COLOR_SECONDARY:
        return classes.colorSecondary;
      case COLOR_SECONDARY_LIGHT:
        return classes.colorSecondaryDark;
      case COLOR_TERTIARY:
        return classes.colorTertiary;
      case COLOR_BLACK:
        return classes.colorBlack;
      case COLOR_RED:
        return classes.colorRed;
      case COLOR_YELLOW:
        return classes.colorYellow;
      case COLOR_WHITE:
        return classes.colorWhite;
      case COLOR_INHERIT:
      case null:
        return "";
      default:
        throw new Error(`Invalid header color: ${color}`);
    }
  }, [color, classes]);

  const additionalClasses = useMemo(() => {
    const classList = [];
    if (capitalize) {
      classList.push(classes.capitalize);
    }
    if (size) {
      switch(size) {
        case "xl":
          classList.push(classes.sizeXl);
          break;
        case "xs":
        case "sm":
        case "md":
        case "lg":
        default:
          throw new Error(`Unsupported custom header size ${size}`);
      }
    }
    if (!withMargin) {
      classList.push(classes.headerNoMargin);
    }
    return classList;
  }, [capitalize, classes, size, withMargin]);

  return (
    <HeaderTag
      className={
        classNames(classes.header, className, colorClass, ...additionalClasses)
      }
      data-cy={testAttribute}
      {...htmlAttributes}
    >
      {!!startIcon && (
        <span className={classes.icon}>{startIcon}</span>
      )}
      <span>{children}</span>
    </HeaderTag>
  );
}

export const H1 = props => <HeaderV2 {...props} tagName="h1" />
export const H2 = props => <HeaderV2 {...props} tagName="h2" />
export const H3 = props => <HeaderV2 {...props} tagName="h3" />
export const H4 = props => <HeaderV2 {...props} tagName="h4" />
export const H5 = props => <HeaderV2 {...props} tagName="h5" />
export const H6 = props => <HeaderV2 {...props} tagName="h6" />
