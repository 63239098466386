import React, { useCallback, useMemo } from "react";
import { Box, makeStyles } from "@material-ui/core";
import moment from "moment";
import classNames from "classnames";
import variables from "styleVariables";
import AlertIcon from "components/alerts/alertIcon.component";
import { alertDueMessage } from "utils/alertHelpers";
import { CalendarToday } from "@material-ui/icons";
import Tooltip from "components/utils/tooltip.component";

const useStyles = makeStyles((theme) => ({
  dueAlertIcon: {
    position: "relative",
    width: 24,
    marginLeft: 16,
    textAlign: "center",
    fill: variables.warningMain,
  },
  overdueAlertIcon: {
    marginTop: 2,
    height: 20,
  },
  alertIconSize: {
    height: 12,
  },
  simpleIconSize: {
    fontSize: 18,
  },
  overdueDate: {
    color: variables.warningMain,
    fontWeight: "bold",
  },
  almostDueDate: {
    fontWeight: "bold",
  },
  daysUntilDueCount: {
    position: "absolute",
    top: -1,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 10,
    fontWeight: 600,
  },
}));

const MIN_DISPLAY_DAYS_LEFT = 120;

const DueDateCell = ({ trainingModule }) => {
  const classes = useStyles();
  const today = useMemo(() => {
    const now = moment();
    return moment([now.year(), now.month(), now.date(), 0, 0, 0, 0]);
  }, []);

  const formatDisplayDate = useCallback(
    (isoDate) => (isoDate ? moment(isoDate).format("MM/DD/YYYY") : ""),
    []
  );

  const endDate = useMemo(
    () => formatDisplayDate(trainingModule.End_Date),
    [formatDisplayDate, trainingModule.End_Date]
  );

  const dueDate = useMemo(
    () => formatDisplayDate(trainingModule.CompleteBy_Date),
    [formatDisplayDate, trainingModule.CompleteBy_Date]
  );

  const isUnavailable = useMemo(
    () => moment(trainingModule.Open_Date).diff(today, "days") > 0,
    [today, trainingModule.Open_Date]
  );

  const daysUntilDue = useMemo(
    () => moment(trainingModule.CompleteBy_Date).diff(today, "days"),
    [trainingModule.CompleteBy_Date, today]
  );

  const isDue = useMemo(
    () => !isUnavailable && !endDate,
    [isUnavailable, endDate]
  );

  const isOverdue = useMemo(
    () => isDue && daysUntilDue < 0,
    [isDue, daysUntilDue]
  );
  const overdueMessageData = useMemo(
    () =>
      alertDueMessage(
        trainingModule.CompleteBy_Date,
        today,
        null,
        MIN_DISPLAY_DAYS_LEFT
      ),
    [trainingModule.CompleteBy_Date, today]
  );

  const messageColorClass = useMemo(() => {
    switch (overdueMessageData.color) {
      case "due":
        return classes.dueAlertIcon;
      case "overdue":
        return classes.overdueAlertIcon;
      default:
        return "";
    }
  }, [classes, overdueMessageData.color]);

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <div
        className={classNames(
          daysUntilDue <= 3 &&
            classes.almostDueDate,
          isOverdue && classes.overdueDate
        )}
      >
        {dueDate}
      </div>
      {!!isDue && (
        <div className={classes.dueAlertIcon}>
          {isOverdue ? (
            <div className={classes.overdueAlertIcon}>
              <AlertIcon
                End_Date={trainingModule.CompleteBy_Date}
                className={classes.alertIconSize}
                placement="right"
              />
            </div>
          ) : (
            <Tooltip title={overdueMessageData.message} placement="right">
              <div className={messageColorClass}>
                {daysUntilDue <= 3 && (
                  <>
                    <span className={classes.daysUntilDueCount}>
                      {daysUntilDue}
                    </span>
                    <CalendarToday className={classes.simpleIconSize} />
                  </>
                )}
              </div>
            </Tooltip>
          )}
        </div>
      )}
    </Box>
  );
};

export default DueDateCell;
