// module components
import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

// page components
import ProgramTasks from "components/tasks/programTasks.component";
import AlertsFeed from "components/alerts/alertsFeed.component";
import DashWorkPackages from "components/workpackages/dashWorkPackages.component";
import CommitteeTable from "components/utils/tables/committeeTable.component";
import Loader from "components/utils/loader.components";
import OrchPrograms from "components/types/orchPrograms.component";
import classNames from "classnames";
import HeaderPrimary from "components/utils/header.component.js";
import PortfolioDashboardPage from "components/portfolio/dashboard/portfolioDashboardPage.component"
import RunbookDashboard from "components/runbook/dashboard/runbookDashboard.component"
import { PROGRAM_ID_ORCHESTRATION, PROGRAM_ID_PORTFOLIO, PROGRAM_ID_RUNBOOK } from "utils/programConstants";
import useNumericParams from "hooks/useNumericParams";
import { CERTIFICATION_PROGRAM_ID } from "utils/certificationConstants";
import { alertsFeedVariants } from "utils/alertsConstants";
import ProgramsContext from "contexts/programs.context";

const useStyles = makeStyles((theme) => ({
  mainContentContainer: {
    marginTop: 5,
    justifyContent: "center",
    [theme.breakpoints.down("lg")]: {
      width: "calc(100% + 32px)",
    },
  },
  tileLayout: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "50px !important",
    paddingRight: "0px !important",
    [theme.breakpoints.down("lg")]: {
      paddingRight: "30px !important",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      paddingRight: "20px !important",
    },
  },
  leftColumn: {
    paddingRight: 50,
    [theme.breakpoints.down("lg")]: {
      paddingRight: 10,
    },
    [theme.breakpoints.down("md")]: {
      paddingRight: 0,
    },
  },
  rightColumn: {
    paddingLeft: 20,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 50,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      paddingBottom: 20,
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 0,
    },
  },
  programList: {
    paddingBottom: 30,
    [theme.breakpoints.down("md")]: {
      paddingBottom: 30,
    },
  },
  headerWrapper: {
    paddingBottom: 15,
  },
  componentWrapper: {
    paddingBottom: 40,
  },
  mediumTableContainer: {
    maxWidth: 600,
    marginLeft: "auto",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "unset",
      marginLeft: "unset",
    },
  },
}));

const ProgramDashboard = () => {
  const classes = useStyles();
  const { state } = useContext(ProgramsContext);
  const [wpDashRefresh, setWpDashRefresh] = useState(false); // Used by Workpackages and Task Form
  const { programId } = useNumericParams();

  const activeProgram = state.programs?.[programId];

  if (!activeProgram?.Name) {
    return <Loader />;
  }
  switch (programId) {
    case PROGRAM_ID_PORTFOLIO:
      return (
        <PortfolioDashboardPage />
      );
    case PROGRAM_ID_RUNBOOK:
      return (
        <RunbookDashboard />
      );
    default:
      /* TODO: Fix the Grid items */
      return (
        <Grid container spacing={8} className={classes.mainContentContainer}>
          {/* <Grid item sm={12} className={classes.gridContainer}> */}
          <Grid item sm={12} className={classes.tileLayout}>
            {/* ====== LEFT COLUMN ========*/}

            <Grid item sm={12} lg={8} className={classes.leftColumn}>
              {/* PROGRAM PORTAL CARDS FOR ORCH LEVEL DASH */}
              {programId === PROGRAM_ID_ORCHESTRATION && (
                <Grid item sm={12} className={classes.programList}>
                  <div className={classes.headerWrapper}>
                    <HeaderPrimary variant="h3Primary">
                      Orchestration Programs
                    </HeaderPrimary>
                  </div>
                  <OrchPrograms variant="dashboard" wpDashRefresh={wpDashRefresh} />
                </Grid>
              )}
              {/* Workpackages */}
              <div className={classNames(classes.componentWrapper)}>
                <DashWorkPackages
                  programId={programId}
                  setWpDashRefresh={setWpDashRefresh}
                  wpDashRefresh={wpDashRefresh}
                />
              </div>
              {/* Program Tasks Gauges */}
              <div className={classNames(classes.componentWrapper)}>
                <ProgramTasks
                  programId={programId}
                  setWpDashRefresh={setWpDashRefresh}
                  programName={activeProgram?.Name}
                />
              </div>
              {/* Steering Committee */}
              {programId !== CERTIFICATION_PROGRAM_ID && (
                <div className={classNames(classes.componentWrapper)}>
                  <CommitteeTable programId={programId} />
                </div>
              )}
            </Grid>

            {/* ======= RIGHT COLUMN ======= */}
            <Grid item sm={12} lg={4} className={classes.rightColumn}>
              {/* Alerts Section */}
              <div
                className={
                  classNames(
                    classes.componentWrapper,
                    classes.mediumTableContainer
                  )
                }
              >
                <AlertsFeed
                  variant={alertsFeedVariants.DASHBOARD}
                  programId={programId}
                  test="dashboard"
                  testProp="dashboard"
                />
              </div>
            </Grid>
          </Grid>

        </Grid>
      );
  }
};

export default ProgramDashboard;