import React, { useMemo, useCallback, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import LabelInput from "components/utils/form-elements/labelInput.component";
import Header from "components/utils/header.component";
import CustomSelect from "components/utils/form-elements/select.component";
import { Box, Card, Dialog, MenuItem, InputBase, Popover, Popper, Divider, FormControl } from "@material-ui/core";
import Loader from "components/utils/loader.components";
import RequirementService from "services/requirement.service";
import Form from "components/utils/form-elements/form.component";
import { ACTION_CREATE_REQUIREMENT, ACTION_DELETE_REQUIREMENT, ACTION_REPLACE_REQUIREMENT } from "reducers/requirements.reducer";
import RequirementReferencesForm from "./requirementReferencesForm";
import { useState } from "react";
import { requirementTypes } from "utils/requirementsConstants";
import CustomLink from "components/utils/link.component";
import { REFERENCE_TYPE_FILE, REFERENCE_TYPE_URL } from "utils/requirementsConstants";
import { downloadFileByRef } from "utils/downloadFile";
import BackToLink from "components/utils/backToLink.component";
import AddListItem from "components/utils/addListItem.component";
import { CheckCircleOutline, DescriptionOutlined, ErrorOutline, OpenInBrowser, Save } from "@material-ui/icons";
import useNumericParams from "hooks/useNumericParams";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import LinkIcon from "@material-ui/icons/Link";
import classNames from "classnames";
import { generatePath, Prompt, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ButtonDefault from "components/utils/buttonDefault.component";
import EditIconButton from "components/utils/editIconButton.component";
import { VALIDATION_REQUIRED } from "utils/formValidators";
import { H3, H6 } from "components/utils/headerV2.component";
import RemoveIcon from "components/utils/removeIcon.component";
import DialogPop from "components/utils/dialog.component";
import { stripHTMLTags, validHttpsUrl } from "utils/stringFuncs";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import CustomSunEditor from "components/utils/sunEditor.component";
import CollapsableAlert from "components/utils/collapsableAlert.component";
import DisplayHTML from "components/utils/displayHTML.component";
import { findMaxValueByField } from "utils/minMax";
import Autocomplete from "components/utils/form-elements/autocomplete.component";
import { getUploadHttpErrorMessage } from "services/http-common";


const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: 24,
  },
  mainSection: {
    paddingTop: 8,
    paddingBottom: 48,
  },
  mainFormHeader: {
    marginTop: 0
  },
  inputBase: {
    padding: 8,
    width: "100%",
    borderBottom: `1px solid ${variables.tertiary1Light}`,
    "& input": {
      borderRadius: 4,
      padding: 8,
      border: `1px solid ${variables.tertiary1Light}`,
      fontSize: 14,
      "&:focus": {
        boxShadow: "0 3px 12px rgba(27,31,35,.15)",
      }
    }
  },
  formIcon: {
    fontSize: variables.fontLarge,
    marginRight: 8,
  },
  formMessage: {
    marginLeft: 16,
    display: "flex",
    alignItems: "center",
  },
  successMessage: {
    color: variables.statusGreenDark
  },
  errorMessage: {
    color: variables.warningMain
  },
  formButton: {
    width: 120
  },
  card: {
    padding: 16,
    marginTop: 8,
    marginBottom: 8,
    color: theme.palette.text.secondary
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginLeft: 24,
    color: variables.tertiary2,
    "&:not(:last-of-type)": {
      marginBottom: 4,
    },
  },
  referenceItemWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: 40,
  },
  form: {
    marginTop: 60,
  },
  typeSelect: {
    height: 36
  },
  linkSection: {
    paddingBottom: 24
  },
  linkSectionHeader: {
    marginBottom: 8,
  },
  linkAlertContent: {
    marginTop: 4
  },
  objectiveFieldset: {
    width: "100%",
    padding: "8px 0 8px 16px"
  },
  objectiveNumber: {
    fontSize: variables.fontLarge,
    marginTop: 10,
    marginRight: 8,
    fontWeight: "bold"
  },
  objectiveDelete: {
    height: 32,
    marginTop: 4,
    marginLeft: 16
  },
  refHeading: {
    fontWeight: 600,
    fontSize: variables.fontH5
  },
  refDescriptionText: {
    color: variables.tertiary1,
  },
  addItem: {
    margin: "16px 8px 24px",
  },
  linkRequirementLink: {
    marginTop: 8,
    marginBottom: 24,
  },
  reqTypeText: {
    fontStyle: "italic"
  },
  cardLink: {
    wordBreak: "break-word",
  },
  header: {
    borderBottom: `1px solid ${variables.tertiary1Light}`,
    padding: "8px 10px",
    fontWeight: 600,
    color: variables.textSecondary,
  },
  iconSelected: {
    color: variables.statusGreenDark,
  },
  close: {
    color: variables.warningMain,
  },
  autoCompleteContainer: {
    padding: 8,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  autoComplete: {
    position: "static",
    minWidth: 496
  },
  autocompleteText: {
    flexGrow: 1,
    paddingLeft: 16,
    paddingRight: 16,
    fontSize: variables.fontSmall,
    color: variables.textSecondary,
  },
  autocompleteVisible: {
    visibility: "visible"
  },
  autocompleteHidden: {
    visibility: "hidden"
  }
}));

const FORM_MESSAGE_UPDATED = "Updated";
const FORM_MESSAGE_CREATED = "Created";
const FORM_MESSAGE_ERROR = "Error";

const FIELD_NAME_DELIMITER = "|";

const SERVER_FILENAME_SEPARATOR = "__";

const formatReferences = references => (
  references.map(reference => {
    if (reference.Path_Type !== "file") {
      return reference;
    }
    const splitName = reference.Path_Value.split(SERVER_FILENAME_SEPARATOR);
    const displayName = splitName.length === 1 ? splitName[0] : splitName[1];
    reference._meta = reference._meta || {};
    reference._meta.displayFileName = displayName;
    return reference;
  })
);

const getObjectiveFieldName = (objective, columnName) => {
  const id = objective.RequirementObjective_ID || objective._meta?.clientId;
  return `${id}${FIELD_NAME_DELIMITER}${columnName}`
};


const RequirementsForm = ({
  state,
  dispatch
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { allRequirements } = state;
  const { programId, programComponentId, requirementId } = useNumericParams();

  const [anchorElement, setAnchorElement] = useState(null);
  const [formMessage, setFormMessage] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
  const [objectives, setObjectives] = useState(null);
  const [references, setReferences] = useState(null);
  const [openModalReference, setOpenModalReference] = useState(null);
  const [searchActive, setSearchActive] = useState(false);
  const [referenceErrorUrls, setReferenceErrorUrls] = useState([]);
  // "links" holds the objects of each requirement linked to the active requirement
  // it's set through the Autocomplete component
  const [links, setLinks] = useState(null);
  const [fileUploadErrorMessage, setFileUploadErrorMessage] = useState(null);

  const clearErrorMessages = () => {
    setFormMessage(null);
    setFileUploadErrorMessage(null);
  }

  const popperAnchor = useRef(0);

  const [activeRequirement, otherRequirements] = useMemo(() => {
    if (allRequirements && requirementId) {
      const requirements = [...allRequirements];
      const activeIndex = requirements.findIndex((requirement) =>
        requirement.Requirement_ID === requirementId
      );
      const [active] = requirements.splice(activeIndex, 1);
      if (active) {
        return [active, requirements];
      }
    }
    return [null, allRequirements || []];
  }, [allRequirements, requirementId]);

  const isNewRequirement = !activeRequirement?.Requirement_ID;

  const headerMessage = useMemo(() => {
    if (isNewRequirement) {
      return `Add Requirement`;
    }
    return `Edit Requirement`;
  }, [isNewRequirement]);

  const formValidations = useMemo(() => ({
    Type: [{ type: VALIDATION_REQUIRED }],
    Rationale: [{ type: VALIDATION_REQUIRED }],
    Description: [{ type: VALIDATION_REQUIRED }],
    ...(objectives || []).reduce((accumulator, objective) => ({
      ...accumulator,
      [getObjectiveFieldName(objective, "Objective")]: [{
        type: VALIDATION_REQUIRED
      }],
    }), {})
  }), [objectives]);

  useEffect(() => {
    if (!activeRequirement?._associations) {
      return;
    }
    const activeLinks = activeRequirement._associations?.RequirementLink;
    const linkedReferences = activeLinks.map(link => (
      otherRequirements.find(requirement => (
        link.Requirement_ID_Link === requirement.Requirement_ID
      ))
    ));
    setLinks(linkedReferences);
    setObjectives(activeRequirement._associations.RequirementObjective);
    setReferences(
      formatReferences(activeRequirement._associations.RequirementReference)
    );
  }, [activeRequirement, otherRequirements]);

  useEffect(function addRefreshListener() {
    if (!isUnsavedChanges) {
      return;
    }
    const onUnload = event => {
      event.preventDefault();
      event.returnValue = "";
    }
    window.addEventListener("beforeunload", onUnload);
    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
  }, [isUnsavedChanges]);

  const goBack = useCallback(() => {
    history.push(`/program/${programId}/${programComponentId}`);
  }, [history, programComponentId, programId]);

  const handleInputChange = useCallback(() => {
    setIsUnsavedChanges(true);
    clearErrorMessages();
  }, []);

  const highestObjectiveOrder = useMemo(() => {
    if (!objectives) {
      return 0;
    }
    const max = findMaxValueByField(objectives, "Objective_Order");
    if (!max) {
      return 0;
    }
    return max;
  }, [objectives]);

  const handleSubmitObjective = useCallback(formData => {
    const updatedObjective = { ...formData };
    updatedObjective._meta = {
      clientId: (
        updatedObjective["_meta.clientId"] ||
        `${(new Date()).getTime()}-pending-objective`
      )
    };
    delete updatedObjective["_meta.clientId"];

    const newObjectives = [...(objectives || [])];
    if (formData._meta?.clientId) {
      const index = newObjectives.findIndex(item => (
        item?._meta?.clientId === updatedObjective._meta.clientId ||
        item.RequirementObjective_ID === parseInt(updatedObjective._meta.clientId, 10)
      ));
      newObjectives.splice(index, 1, updatedObjective);
    } else {
      newObjectives.push(updatedObjective);
    }
    setObjectives(newObjectives);
    handleInputChange();
  }, [handleInputChange, objectives]);

  const handleRemoveReference = useCallback(() => {
    const newReferences = [...(references || [])];
    const index = newReferences.findIndex(item => {
      if (item?._meta?.clientId) {
        return item._meta.clientId === openModalReference._meta.clientId
      } else {
        return item.Reference_ID === parseInt(openModalReference.Reference_ID, 10)
      }
    } );
    newReferences.splice(index, 1);
    setReferences(newReferences);
    setOpenModalReference(null);
    handleInputChange();
  }, [references, openModalReference, handleInputChange]);

  const handleRemoveObjective = useCallback(removedId => {
    const newObjectives = [...(objectives || [])];
    const index = newObjectives.findIndex(item => {
      const itemId = item._meta?.clientId || item.RequirementObjective_ID;
      return itemId === removedId;
    });
    newObjectives.splice(index, 1);
    setObjectives(newObjectives);
    handleInputChange();
  }, [handleInputChange, objectives]);

  const handleLinkChange = useCallback((_event, newValue) => {
    setLinks(newValue);
    handleInputChange();
  }, [handleInputChange]);

  // This is the function that actually renders each item in the Autocomplete dropdown
  const renderAutocompleteOption = useCallback((requirement) => {
    const isSelected = !!links && links.some(link => (
      requirement.Requirement_ID === link.clientId
    ));
    return (
      <>
        <DoneIcon
          className={classNames(
            classes.iconSelected,
            isSelected ? classes.autocompleteVisible : classes.autocompleteHidden
          )}
        />
        <div
          className={classes.autocompleteText}
          data-cy={`autocomplete-option-req-${requirement.Requirement_ID}`}
        >
          <H6 color="tertiary"><DisplayHTML html={requirement?.Description} /></H6>
          {!!requirement?.Program_Name && (
            <div>
              {requirement.Program_Name} Program
            </div>
          )}
          <div>
            {requirement?.Type}
          </div>
        </div>
        <CloseIcon
          className={classNames(
            classes.close,
            isSelected ? classes.autocompleteVisible : classes.autocompleteHidden
          )}
        />
      </>
    );
  }, [classes, links]);

  const renderAutocompleteInput = useCallback((params) => (
    <InputBase
      ref={params.InputProps.ref}
      inputRef={popperAnchor}
      inputProps={params.inputProps}
      autoFocus
      className={classes.inputBase}
    />
  ), [classes]);

  const renderAutocompletePopper = useCallback((props) => (
    <Popper
      {...props}
      anchorEl={popperAnchor}
      placement="bottom"
      open
    />
  ), [popperAnchor]);

  const openLinkSearch = useCallback((event) => {
    setSearchActive(true);
    setAnchorElement(event.currentTarget);
  }, []);

  const formatFormRequestAsJson = useCallback(data => {
    const normalized = !data?.length ? [] : data.map(dataItem => {
      const newItem = { ...dataItem };
      delete newItem._meta;
      delete newItem.file;
      return Object.fromEntries(
        Object.entries(newItem).filter(([_key, value]) => value !== "")
      );
    });
    return JSON.stringify(normalized);
  }, []);

  const handleSubmitReferences = useCallback((data, _formData, chosenFile = null) => {
    const submittedReference = {
      ...data,
      _meta: {
        clientId: (
          data["_meta.clientId"] ||
          `${(new Date()).getTime()}-pending-reference`
        )
      }
    };
    delete submittedReference["_meta.clientId"];
    submittedReference.file = chosenFile;
    const newReferences = [...(references || [])];
    if (openModalReference.Reference_ID || openModalReference?._meta?.clientId) {
      const referenceIndex = newReferences.findIndex(reference => {
        return reference?._meta?.clientId === submittedReference._meta.clientId ||
        reference.Reference_ID === parseInt(submittedReference._meta.clientId, 10)
      });
      newReferences.splice(referenceIndex, 1, submittedReference);
    } else {
      newReferences.push(submittedReference);
    }

    setOpenModalReference(null);
    setReferences(formatReferences(newReferences));
    handleInputChange();
  }, [handleInputChange, references, openModalReference]);

  const handleSubmit = useCallback(async data => {
    const requestData = new FormData();

    references?.forEach?.(({ file }) => {
      requestData.append("referenceFiles", file);
    });
    const linkedIds = JSON.stringify(
      links?.map?.(({ Requirement_ID }) => Requirement_ID) || []
    );

    const objectiveFieldEntries = Object.entries(data).filter(([key]) => {
      return key.includes(FIELD_NAME_DELIMITER)
    });
    const objectivesByClientId = objectiveFieldEntries
      .reduce((accumulator, [key, value]) => {
        const [clientId, fieldName] = key.split(FIELD_NAME_DELIMITER);
        if (!accumulator[clientId]) {
          accumulator[clientId] = {};
        }
        accumulator[clientId][fieldName] = value;
        return accumulator;
      }, {});
    const objectiveItems = Object.values(objectivesByClientId);
    const objectiveFieldKeySet = new Set(
      objectiveFieldEntries.map(([key]) => key)
    );

    const requirementData = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => (
        !objectiveFieldKeySet.has(key) &&
        !(value instanceof File)
      ))
    );

    requestData.set("requirement", JSON.stringify(requirementData));
    requestData.set("requirementLinkIds", linkedIds);
    requestData.set("requirementObjectives", formatFormRequestAsJson(objectiveItems));
    requestData.set("requirementReferences", formatFormRequestAsJson(references));

    const excludedFieldKeys = [
      ...Object.keys(requirementData),
      ...objectiveFieldKeySet
    ];
    for (const key of excludedFieldKeys) {
      requestData.delete(key);
    }

    try {
      const response = await RequirementService.postRequirementForm(requestData);
      setReferenceErrorUrls(response.payload.threats);
      setIsUnsavedChanges(false);
      if (!data?.Requirement_ID) {
        dispatch({ type: ACTION_CREATE_REQUIREMENT, payload: response.payload.serializedRequirement });
        setFormMessage(FORM_MESSAGE_CREATED);
        const destinationRequirementId = response.payload.serializedRequirement[0].Requirement_ID;
        history.push(generatePath(
          "/program/:programId/:programComponentId/requirementForm/:requirementId",
          { programId, programComponentId, requirementId: destinationRequirementId }
        ));
      } else {
        dispatch({ type: ACTION_REPLACE_REQUIREMENT, payload: response.payload.serializedRequirement });
        setFormMessage(FORM_MESSAGE_UPDATED);
      }
      setFileUploadErrorMessage(null);
    } catch(error) {
      const { status } = error.response || {};
      setFormMessage(FORM_MESSAGE_ERROR);
      setFileUploadErrorMessage(getUploadHttpErrorMessage(status));
    }
  }, [
    dispatch, formatFormRequestAsJson, history, programComponentId,
    programId, links, references
  ]);

  const handleDelete = useCallback(async () => {
    const requirementResponse = await RequirementService.deleteRequirement(
      activeRequirement.Requirement_ID
    );
    dispatch({
      type: ACTION_DELETE_REQUIREMENT,
      payload: parseInt(requirementResponse.payload, 10)
    });
    setIsUnsavedChanges(false);
    goBack();
  }, [activeRequirement, dispatch, goBack]);

  const makeDownloadHref = useCallback((reference) => {
    if (reference?.Path_Type === REFERENCE_TYPE_URL) {
      return validHttpsUrl(reference.Path_Value);
    } else if (reference?.file) {
      return URL.createObjectURL(reference.file);
    }
    return undefined;
  }, []);

  const closeReferenceError = useCallback(async (referenceErrorUrl) => {
    const filteredReferenceErrors = referenceErrorUrls.filter((referenceError) => (referenceError !== referenceErrorUrl));
    setReferenceErrorUrls(filteredReferenceErrors);
  }, [referenceErrorUrls]);

  if (!allRequirements) {
    return (
      <Loader />
    )
  }
  return (
    <>
      <Header variant="h1Primary">{headerMessage}</Header>
      <Dialog
        open={!!openModalReference}
        onClose={() => setOpenModalReference(null)}
        transitionDuration={0}
        maxWidth="sm"
        fullWidth
      >
        <RequirementReferencesForm
          reference={
            typeof openModalReference === "object" ? openModalReference : null
          }
          setOpenModalReference={setOpenModalReference}
          activeRequirement={activeRequirement}
          onSubmit={handleSubmitReferences}
          onRemove={handleRemoveReference}
        />
      </Dialog>
      <div className={classes.formContainer} data-cy="requirementsForm">
        <BackToLink
          variant="subLink"
          text="Return to Requirements Summary"
          onClick={goBack}
          dense
        />
        <Form
          name="requirement-form"
          validations={formValidations}
          className={classes.form}
          onChange={handleInputChange}
          onSubmit={handleSubmit}
          onError={clearErrorMessages}
        >
          <GridContainer spacing={7} className={classes.mainSection}>
            <GridItem xs={12} md={7} lg={8}>
              <H3 className={classes.mainFormHeader} withMargin>
                Requirement
              </H3>
              <GridContainer spacing={4}>
                <GridItem sm={12} md={6}>
                  <CustomSelect
                    label="Type*"
                    defaultValue={activeRequirement?.Type || ""}
                    name="Type"
                    className={classes.typeSelect}
                    onChange={handleInputChange}
                    test={activeRequirement?.Type}
                    required={false}
                  >
                    {requirementTypes.length > 0 &&
                      requirementTypes.map((type) => {
                        return (
                          <MenuItem
                            key={type}
                            value={type}
                            id={type}
                          >
                            {`${type}`}
                          </MenuItem>
                        );
                      })}
                  </CustomSelect>
                </GridItem>
                <GridItem sm={12} md={6}>
                  <LabelInput
                    name="Rationale"
                    label="Rationale*"
                    variant="default"
                    test="requirement-rationale"
                    defaultValue={activeRequirement?.Rationale}
                    margin="dense"
                  />
                </GridItem>
                <GridItem xs={12} className={classes.formElement}>
                  <CustomSunEditor
                    name="Description"
                    height="auto"
                    defaultValue={activeRequirement?.Description}
                    onChange={handleInputChange}
                    basicTextEditor
                  />
                </GridItem>
              </GridContainer>
              {!!activeRequirement?.Requirement_ID && (
                <input
                  name="Requirement_ID"
                  value={activeRequirement?.Requirement_ID}
                  type="hidden"
                />
              )}
              <input
                name="Program_ID"
                value={activeRequirement?.Program_ID || programId}
                type="hidden"
              />
              <Box marginTop={5} data-cy="objectives-wrapper">
                <div className={classes.linkSection}>
                  <H3 className={classes.linkSectionHeader}>
                    Objectives
                  </H3>
                  {objectives?.length > 0 && (
                    <Box marginTop={1} marginBottom={1}>
                      {objectives?.map?.((objective, index) => (
                        <div
                          key={
                            objective.RequirementObjective_ID ||
                            objective?._meta?.clientId ||
                            index
                          }
                        >
                          <Divider />
                          <ObjectiveFieldset
                            index={index}
                            objective={objective}
                            onChange={() => setIsUnsavedChanges(true)}
                            onRemove={handleRemoveObjective}
                          />
                        </div>
                      ))}
                      <Divider />
                    </Box>
                  )}
                  <AddListItem
                    text="Add Objective"
                    className={classes.addItem}
                    onClick={() => handleSubmitObjective({Objective_Order: highestObjectiveOrder + 1})}
                    test="add-objective"
                  />
                </div>
              </Box>
              <Box marginBottom={3} display="flex">
                <ButtonDefault
                  background="primary"
                  type="submit"
                  className={classes.formButton}
                  disabled={!isUnsavedChanges && !isNewRequirement}
                  startIcon={(
                    <Save className={classes.formIcon} />
                  )}
                  data-cy="btn-save-requirement"
                  disableReadOnlyUsers
                >
                  Save
                </ButtonDefault>
                {(!!formMessage || !!fileUploadErrorMessage) &&
                  (
                    <div
                      className={classNames(
                      classes.formMessage,
                      formMessage.includes(FORM_MESSAGE_ERROR) ?
                        classes.errorMessage :
                        classes.successMessage
                      )}
                    >
                      {(formMessage.includes(FORM_MESSAGE_ERROR) || !!fileUploadErrorMessage) ? (
                        <ErrorOutline />
                      ) : (
                        <CheckCircleOutline data-cy="success-icon" />
                      )}
                      <Box marginLeft={1}>{fileUploadErrorMessage ? `Error: ${fileUploadErrorMessage}` : formMessage}
                      </Box>
                    </div>
                  )}
              </Box>
              {!isNewRequirement && (
                <>
                  <ButtonDefault
                    color="red"
                    className={classes.formButton}
                    onClick={() => setIsDeleteModalOpen(true)}
                    startIcon={(
                      <RemoveIcon className={classes.formIcon} />
                    )}
                    disableReadOnlyUsers
                  >
                    Delete
                  </ButtonDefault>
                  <DialogPop
                    openDialog={isDeleteModalOpen}
                    setOpenDialog={setIsDeleteModalOpen}
                    dialogTitle="Delete Requirement?"
                    confirm={handleDelete}
                    continueAndCancel
                  >
                    <p>
                      This requirement will be permanently removed,
                      along with any References, Objectives,
                      and Links to other Requirements.
                    </p>
                  </DialogPop>
                </>
              )}
            </GridItem>
            <GridItem xs={12} md={5} lg={4}>
              <div className={classes.linkSection}>
                <H3 className={classes.linkSectionHeader}>
                  References
                </H3>
                {referenceErrorUrls?.map?.((referenceErrorUrl, index) => (
                  <CollapsableAlert
                    key={`reference-alert-error-${index}`}
                    showAlert
                    closeClick={() => closeReferenceError(referenceErrorUrl)}
                    message={
                      `Unable to add ${referenceErrorUrl} as a reference.
                      Link flagged as suspicious.`
                    }
                    contentClass={classes.linkAlertContent}
                    severity="error"
                  />
                ))}
                {references?.map?.(reference => {
                  const displayName = (
                    reference._meta?.displayFileName || reference.Path_Value
                  );
                  // const urlPathValue = handleReferenceNavigation(reference);
                  return (
                    <Card
                      className={
                        classNames(classes.card, classes.referenceItemWrapper)
                      }
                      key={reference._meta?.clientId || reference?.Reference_ID}
                      data-cy={`card-${displayName}`}
                    >
                      <div>
                        <CustomLink
                          variant="underline"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={(reference.Path_Type === REFERENCE_TYPE_URL || reference.file) ? makeDownloadHref(reference) : undefined}
                          onClick={!reference.file && reference.Path_Type === REFERENCE_TYPE_FILE ? (
                            () => downloadFileByRef(reference.Path_Value, displayName)
                          ) : undefined}
                          test={`reference-${reference.Description}`}
                          className={classes.cardLink}
                        >
                          {displayName}
                        </CustomLink>
                        <br />
                        <div className={classes.refDescriptionText}>
                          {reference.Description}
                        </div>
                      </div>
                      <div className={classes.iconWrapper}>
                        <EditIconButton
                          onClick={() => setOpenModalReference(reference)}
                        />
                        {reference.Path_Type === "file" && (
                          <DescriptionOutlined
                            fontSize="medium"
                            className={classes.referenceIcon}
                          />
                        )}
                        {reference.Path_Type === "url" && (
                          <OpenInBrowser fontSize="medium" />
                        )}
                      </div>
                    </Card>
                  )
                })}
                <AddListItem
                  text="Add Reference"
                  className={classes.addItem}
                  onClick={() => setOpenModalReference("new")}
                  test="add-reference"
                />
              </div>
              <div>
                <H3 className={classes.linkSectionHeader}>
                  Linked Requirements
                </H3>
              </div>
              <div className={classes.linkRequirementLink}>
                <Popover
                  id="link-popover"
                  open={searchActive}
                  anchorEl={anchorElement}
                  placement="bottom"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  className={classes.popover}
                  onClose={() => setSearchActive(false)}
                >
                  <div
                    className={classes.autoCompleteContainer}
                    data-cy="link-requirements-autocomplete-container"
                  >
                    <div className={classes.header}>
                      <span>
                        Select Requirements to Link
                      </span>
                    </div>
                    <Autocomplete
                      id="combo-autocomplete"
                      open
                      fullWidth
                      multiple
                      value={links || []}
                      onChange={handleLinkChange}
                      disablePortal
                      renderTags={() => null}
                      noOptionsText="No Linked Requirements"
                      options={otherRequirements}
                      getOptionLabel={(option) => stripHTMLTags(option.Description)}
                      renderInput={renderAutocompleteInput}
                      renderOption={renderAutocompleteOption}
                      PopperComponent={renderAutocompletePopper}
                      classes={{popperDisablePortal: classes.autoComplete}}
                    />
                  </div>
                </Popover>
                {links?.map?.((req) => (
                  <Card
                    className={classNames(classes.card, classes.referenceItemWrapper)}
                    key={`card-2-${req?.Requirement_ID}`}
                    data-cy={`card-req-${req?.Requirement_ID}`}
                  >
                    <div>
                      <H6>
                        <DisplayHTML
                          html={req?.Description}
                          className={classes.refHeading}
                        />
                      </H6>
                      {!!req?.Program_Name && (
                        <div className={classes.refDescriptionText}>
                          {req.Program_Name} Program
                        </div>
                      )}
                      <div
                        className={classNames(
                          classes.refDescriptionText, classes.reqTypeText
                        )}
                      >
                        {req?.Type}
                      </div>
                    </div>
                    <div className={classes.iconWrapper}>
                      <LinkIcon
                        fontSize="medium"
                        className={classes.referenceIcon}
                      />
                    </div>
                  </Card>
                ))}
                <AddListItem
                  text="Link Requirement"
                  className={classes.addItem}
                  onClick={openLinkSearch}
                  ref={anchorElement}
                  test="link-requirement"
                />
              </div>
            </GridItem>
          </GridContainer>
        </Form>
        <Prompt
          when={isUnsavedChanges}
          message="Are you sure? Unsaved changes will be lost."
        />
      </div>
    </>
  );
};

export default RequirementsForm;


function ObjectiveFieldset(props) {
  const classes = useStyles();
  const { index, objective, onChange, onRemove } = props;

  const clientId = useMemo(() => (
    objective?.RequirementObjective_ID || objective?._meta?.clientId
  ), [objective]);

  const handleDeleteObjective = useCallback(() => {
    onRemove(clientId)
  }, [clientId, onRemove]);

  const objectiveOrder = index + 1;

  return (
    <FormControl
      component="fieldset"
      name={`objective-${clientId}`}
      className={classes.objectiveFieldset}
      onChange={onChange}
    >
      <GridContainer>
        <GridItem sm={12} lg={9} data-cy={`objective-grid-order-${objectiveOrder}`}>
          <Box display="flex" justifyContent="space-between" marginTop={2} >
            <div className={classes.objectiveNumber}>{objectiveOrder}.</div>

            <LabelInput
              name={getObjectiveFieldName(objective, "Objective")}
              defaultValue={objective?.Objective}
              margin="dense"
              variant="default"
              test="requirement-objective-statement"
              multiline
              minRows={4}
            />
            <RemoveIcon
              className={classes.objectiveDelete}
              onClick={handleDeleteObjective}
              test={`order-${objectiveOrder}`}
            />
          </Box>
        </GridItem>
      </GridContainer>
      <input
        type="hidden"
        name={getObjectiveFieldName(objective, "RequirementObjective_ID")}
        value={objective?.RequirementObjective_ID}
      />
      <input
        type="hidden"
        name={getObjectiveFieldName(objective, "Objective_Order")}
        value={objective?.Objective_Order}
      />
    </FormControl>
  );
}
