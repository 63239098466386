import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import RemoveIcon from "components/utils/removeIcon.component";
import variables from "styleVariables";
import classNames from "classnames";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  listRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 4,
    "&:hover": {
      backgroundColor: variables.grayTint,
    },
  },
  disabledrowHighlight: {
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    marginLeft: 4
  },
  icon: {
    fontSize: variables.fontXs,
  },
  undo: {
    fontSize: variables.fontXs,
    paddingTop: 3,
    paddingBottom: 3,
    fontWeight: "bold",
    color: variables.textSecondary,
    "&:hover": {
      cursor: "pointer",
    },
  },
  disabledIcon: {
    fill: variables.grayDisabledText,
    "&:hover": {
      cursor: "auto",
    },
  },
  deleteText: {
    textDecoration: "line-through",
  },
  editIcon: {
    fontSize: variables.fontLarge,
    marginRight: 10,
    color: variables.secondaryMain,
    "&:hover": {
      color: variables.secondaryLight,
    },
  },
}));

const RemovableListItem = ({
  item,
  removeClick,
  undoClick,
  editClick,
  isMarkedForDelete,
  disabled,
  test,
}) => {
  const classes = useStyles();
  return (
    <div
      className={classNames(
        classes.listRow,
        disabled && classes.disabledrowHighlight
      )}
    >
      {isMarkedForDelete ? (
        <>
          <div className={classNames(classes.item, classes.deleteText)}>
            {item}
          </div>
          <span
            className={classNames(
              classes.undo,
              disabled && classes.disabledIcon
            )}
            onClick={!disabled ? undoClick : null}
          >
            UNDO
          </span>
        </>
      ) : (
        <>
          <div className={classes.item}>{item}</div>
          <div className={classes.iconWrapper}>
            {!!editClick && (
              <EditIcon
                disabled={disabled}
                onClick={!disabled ? editClick : null}
                className={classNames(
                  classes.editIcon,
                  disabled && classes.disabledIcon
                )}
              />
            )}
            {!!removeClick && (
              <RemoveIcon
                onClick={!disabled ? removeClick : null}
                className={classes.icon}
                disabled={disabled}
                test={test}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default RemovableListItem;
