import React, { useCallback, useMemo } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import HeaderPrimary from "components/utils/header.component";
import ButtonDefault from "components/utils/buttonDefault.component";
import variables from "styleVariables";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/RemoveRedEye";
import ClipLoader from "components/utils/clipLoad.component";
import Dialog from "components/utils/dialog.component";
import classNames from "classnames";
import { generatePath, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useNumericParams from "hooks/useNumericParams";

// Many styles copied from builderInfoCard for consistency
const useStyles = makeStyles((theme) => ({
  manualCard: {
    boxShadow:
      `0px 3px 3px -2px rgb(0 0 0 / 20%),
      4px 3px 4px 0px rgb(0 0 0 / 14%),
      0px 1px 8px 0px rgb(0 0 0 / 12%)`,
    borderRadius: 40,
    padding: "32px 40px 40px 60px",
    margin: 32,
    marginTop: 0,
    maxWidth: "fit-content",
    display: "flex",
    flexDirection: "row",
    position: "relative",
    minWidth: 672,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      minWidth: "unset",
      marginRight: 40,
      marginLeft: 8,
      paddingLeft: 48,
    },
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 16,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
  },
  titleContainer: {
    display: "flex",
    paddingRight: 16,
    paddingBottom: 8,
    maxWidth: 528,
    wordBreak: "break-word",
    [theme.breakpoints.down("md")]: {
      maxWidth: 256,
    },
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  textCell: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  heading: {
    paddingBottom: 4,
  },
  subheading: {
    paddingTop: 4,
  },
  dateContainer: {
    display: "flex",
    flexDirection: "column",
    paddingRight: 16,
    marginBottom: "-5px",
    [theme.breakpoints.down("md")]: {
      marginBottom: 0,
    },
  },
  dateRow: {
    whiteSpace: "nowrap",
  },
  row: {
    display: "inline-flex",
    alignItems: "center",
  },
  buttonRow: {
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  bodyText: {
    fontSize: variables.fontMedium,
    color: variables.tertiary1,
    paddingLeft: 8,
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    paddingLeft: 24,
    paddingRight: 16,
    [theme.breakpoints.down("md")]: {
      marginTop: 24,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  buttonWrapper: {
    padding: 8,
    paddingBottom: 0,
    [theme.breakpoints.down("md")]: {
      paddingBottom: 16,
      paddingLeft: 0,
    },
  },
  reportIcon: {
    fontSize: variables.fontXs,
    marginRight: 6,
    color: "white",
  },
  editIcon: {
    fontSize: variables.fontXs,
    color: "white",
    marginRight: 6,
  },
}));

const ManualInfoCard = ({
  manual,
  setOpenDialog,
  openDialog,
  showError,
  loadingDownload,
  readOnly
}) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useNumericParams();

  const displayEditDate = useMemo(() => (
    moment(manual.Last_Edited).format("MMMM DD, YYYY")
  ), [manual.Last_Edited]);

  const handleEditClick = useCallback(() => {
    history.push(
      generatePath("/program/:programId/:programComponentId/manual/:manualId", {
        ...params,
        manualId: manual.Manual_ID
      })
    )
  }, [history, manual.Manual_ID, params]);

  return (
    <div className={classes.manualCard} data-cy={`${manual.Title}-manualCard`}>
      <div className={classes.contentWrapper}>
        <div className={classes.titleContainer}>
          <HeaderPrimary
            variant="h3Primary"
            className={classes.heading}
          >
            {manual.Title || "Untitled"}
          </HeaderPrimary>
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.textCell}>
            <div className={classNames(classes.row, classes.versionRow)}>
              <HeaderPrimary
                variant="h4Tertiary"
                className={classes.subheading}
              >
                Current Version:
              </HeaderPrimary>
              <p className={classes.bodyText}>
                {manual.Version || "--"}
              </p>
            </div>
            <div className={classes.dateContainer}>
              <div className={classNames(classes.row, classes.dateRow)}>
                <HeaderPrimary variant="h5Tertiary">
                  Last Edit:
                </HeaderPrimary>
                <p className={classes.bodyText}>
                  {displayEditDate}
                </p>
              </div>
            </div>
          </div>

          <div className={classes.actionsContainer}>
            <div className={classNames(classes.row, classes.buttonRow)}>
              <div className={classes.buttonWrapper}>
                <ButtonDefault
                  data-cy={manual.Title}
                  variant="small"
                  background="primary"
                  startIcon={loadingDownload === manual.Manual_ID ? (
                    <ClipLoader css={{ marginRight: 4 }} />
                  ) : (
                    <PictureAsPdfOutlinedIcon className={classes.reportIcon} />
                  )}
                  disabled={manual.Status !== "Published"}
                >
                  Export PDF
                </ButtonDefault>
                {!!showError && (
                  <Dialog
                    notification
                    openDialog={openDialog}
                    confirm={() => setOpenDialog(false)}
                    setOpenDialog={setOpenDialog}
                    prompt={
                      `Document does not contain sections.
                      Please add sections in order to generate the report.`
                    }
                  />
                )}
              </div>

              <div className={classes.buttonWrapper}>
                <ButtonDefault
                  variant="small"
                  background="secondary"
                  data-cy={`btn-current-version-${manual.Title}`}
                  startIcon={readOnly ? (
                    <ViewIcon className={classes.editIcon} />
                  ) : (
                    <EditIcon className={classes.editIcon} />
                  )}
                  onClick={() => handleEditClick(manual)}
                >
                  {readOnly ? "View Current Version" : "Edit Current Version"}
                </ButtonDefault>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManualInfoCard;
