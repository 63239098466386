import React, { useCallback, useState } from "react"; // { useState }
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionSummary,
  Paper,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import variables from "styleVariables";
import styleVariables from "styleVariables";

const useStyles = makeStyles((theme) => ({
  accordionWrapper: {
    width: "100%",
    "& .MuiAccordion-root:hover": {
      backgroundColor: styleVariables.chosenElement,
    },
  },
  accordionItem: {
    color: theme.palette.text.secondary,
  },
  accordionDetails: {
    backgroundColor: "transparent",
  },
  summaryContent: {
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  heading: {
    fontSize: styleVariables.fontMedium,
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
  },
  emptyBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 16,
    padding: 16,
    borderRadius: 4,
    color: variables.textSecondary,
  },
}));


/*
 * Valid attributes for `options` prop:
 * - label
 * - value
 * - sublabel (optional)
 * - id (optional, falls back to option index)
 */
const AccordionListView = ({
  options,
  onChange,
  emptyMessage,
  defaultExpanded,
  testProp,
}) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(defaultExpanded);

  const handleChange = useCallback((itemId, event) => {
    if (expanded === itemId) {
      setExpanded(null);
    } else {
      setExpanded(itemId);
    }
    onChange?.(itemId, event);
  }, [expanded, onChange]);

  if (emptyMessage && !options?.length) {
    return (
      <Paper className={classNames(classes.root, classes.emptyBox)}>
        {emptyMessage}
      </Paper>
    );
  }
  return (
    <div className={classes.accordionWrapper}>
      {options.map((option, index) => {
        const itemId = option.id || index;
        return (
          <Accordion
            expanded={expanded === itemId}
            defaultExpanded={defaultExpanded === itemId}
            onChange={event => handleChange(itemId, event)}
            className={classes.accordionItem}
            data-cy="accordion-view"
            key={itemId}
          >
            <AccordionSummary
              classes={{
                root: classes.accordionSummary,
                content: classes.summaryContent
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={
                `accordion-item-${option.label}-${itemId}-content`
              }
              data-cy={`accordion-summary-${testProp}`}
            >
              <div
                className={classes.heading}
              >
                {option?.label}
              </div>
              {!!option.sublabel && (
                <div
                  className={
                    classNames(classes.heading, classes.secondaryHeading)
                  }
                >
                  {option.sublabel}
                </div>
              )}
            </AccordionSummary>
            <AccordionDetails
              id={`accordion-item-${option.label}-${itemId}-content`}
              className={classes.accordionDetails}
              data-cy={`accordion-details-${testProp}`}
            >
              {option.value}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default AccordionListView;
