import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  MenuItem,
} from "@material-ui/core";
import Form from "components/utils/form-elements/form.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import { useCallback } from "react";
import PortfolioService from "services/portfolio.service";
import {
  ACTION_REPLACE_PORTCO_USER,
} from "reducers/portfolioDemos.reducer";
import CustomSelect from "components/utils/form-elements/select.component";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import { getAnyPortfolioUserTitle } from "../shared/utils/portfolioUserHelpers";
import useNumericParams from "hooks/useNumericParams";
import { VALIDATION_REQUIRED } from "utils/formValidators";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: 8,
    paddingRight: 24,
    paddingLeft: 24
  },
  formRow: {
    display: "flex",
    minWidth: 380,
    justifyContent: "space-between",
    paddingBottom: 4,
  },
  defaultInputWrapper: {
    width: 184,
  },
  dualButtons: {
    paddingBottom: 8
  },
  selectInput: {
    height: 36
  },
}));

const formValidations = {
  User_ID: { type: VALIDATION_REQUIRED },
  Title: { type: VALIDATION_REQUIRED }
};


export default function ExistingUserForm(props) {
  const classes = useStyles();
  const { holdingId } = useNumericParams(true);
  const { assignableUsers, dispatch, onClose, selectedPortCoID } = props;

  const [selectedExistingUserId, setSelectedExistingUserId] = useState(null);

  const selectedUser = useMemo(() => (
    assignableUsers.find(user => user.User_ID === selectedExistingUserId)
  ), [assignableUsers, selectedExistingUserId]);

  const anySelectedUserTitle = useMemo(() => (
    getAnyPortfolioUserTitle(selectedUser, holdingId, selectedPortCoID)
  ), [holdingId, selectedPortCoID, selectedUser]);

  const handleAddExistingUser = useCallback(async data => {
    const user = await PortfolioService.upsertPortfolioGroupPermissions(
      selectedPortCoID,
      data.User_ID,
      { Title: data.Title }
    );
    onClose();
    dispatch({ type: ACTION_REPLACE_PORTCO_USER, payload: user.payload });
  }, [dispatch, onClose, selectedPortCoID]);

  return (
    <Form
      name="assign-existing-user"
      className={classes.form}
      validations={formValidations}
      onSubmit={handleAddExistingUser}
    >
      <div className={classes.formRow}>
        <div className={classes.defaultInputWrapper}>
          <CustomSelect
            name="User_ID"
            label="User*"
            value={selectedExistingUserId}
            disabled={!assignableUsers?.length}
            className={classes.selectInput}
            renderValue={() => (
              !selectedUser ?
                "" :
                `${selectedUser.First_Name} ${selectedUser.Last_Name}`
            )}
            onChange={event => {
              setSelectedExistingUserId(event.target.value)
            }}
            helperTextDisabled
          >
            {assignableUsers.map(user => (
              <MenuItem
                value={user.User_ID}
                key={user.User_ID}
              >
                {user.First_Name} {user.Last_Name}
              </MenuItem>
            ))}
          </CustomSelect>
        </div>
        <div
          className={classes.defaultInputWrapper}
          key={selectedUser?.User_ID}
        >
          <LabelInput
            name="Title"
            label="Title*"
            defaultValue={anySelectedUserTitle || ""}
            placeholder="Ex) VP, Operations"
            disabled={!assignableUsers?.length}
            variant="default"
            margin="dense"
          />
        </div>
      </div>
      <DualFormButtons
        buttonVariant="small"
        cancelOnClick={onClose}
        className={classes.dualButtons}
        disabled={!assignableUsers?.length}
      />
    </Form>
  );
}
