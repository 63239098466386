import ProgramDashboard from "components/types/programDashboard.component";
import ManagementPrinciples from "components/types/managementPrinciples.component";
import DataInterfaces from "components/types/dataInterfaces.component";
import PrivacyDataInventory from "components/types/dataInventory.component";
import ComplianceStatements from "components/types/complianceStatements.component";
import PrivControlMatrix from "components/types/privControlMatrix.component";
import PrivMgmtPrinc from "components/types/privacyMgmtPrinc.component";
import DataScope from "components/types/dataScope.component";
import OrchPrograms from "components/types/orchPrograms.component";
import ControlMatrix from "components/types/controlMatrix.component";
import Requirements from "components/types/requirements.component";
import Assessments from "components/portfolio/assessments.component";
import Reports from "components/portfolio/reports.component";
import Scheduling from "components/portfolio/scheduling.component";
import Documents from "components/portfolio/documents.component";
import HelpCenter from "components/portfolio/helpCenter.component";
import Settings from "components/portfolio/settings.component";
import Demographics from "components/portfolio/demographics/demographics.component";
import TrainingModulesPage from "components/training/modules/trainingModulesPage.component";
import TrainingAssignmentsTable from "components/training/assignments/trainingAssignmentsTable.component";
import LegalFrameworkTable from "components/types/legalFrameworkTable.component";
import LetterOfCompliance from "components/certification/letterOfCompliance/letterOfCompliance.component";
import ApplicationSchedule from "components/certification/applicationSchedule/applicationSchedule.component";
import ChecklistPage from "components/types/checklistPage.component";
import AircraftDC from "components/certification/aircraft/aircraftDC.component";
import PasiPage from "components/certification/pasi/pasiPage.component";
import CertificationManuals from "components/certification/manuals/certificationManuals.component";
import CertificationDct from "./certificationDct.component";
import ContactList from "components/certification/contacts/contactList.component";
import TrainingProgramComponent from "components/training/trainingProgramComponent.component";
import RunbookAdmin from "components/runbook/runbookAdmin/runbookAdmin.component";
import RunbookViewProgramComponent from "components/runbook/runbookViewProgramComponent.component";

// TODO: transfer imp-tools DataScope into this registry?
const registry = {
    /*BY PROGRAM COMPONENTS*/
  PrivDash: <ProgramDashboard />,
  OrchDash: <ProgramDashboard />,
  OrchPrograms: <OrchPrograms />,
  RunbookTaskTable: <RunbookViewProgramComponent />,
    /* Certification Program */
  CertificationAircraftDC: <AircraftDC />,
  CertificationDct: <CertificationDct />,
  CertificationLetterOfCompliance: <LetterOfCompliance />,
  CertificationPasi: <PasiPage />,
  ContactList: <ContactList />,
  CertificationManuals: <CertificationManuals />,
  Training: <TrainingProgramComponent />,
  RunbookAdmin: <RunbookAdmin />,

    // AuditDash:

    /*BY COMPONENT OBJECTS*/
  Checklist: <ChecklistPage />,
    /*assessments*/
  PrivManagementPrinc: <ManagementPrinciples />,
    /*compliance-reqs*/
  Compliance: <ComplianceStatements />,
  LegalFramework: <LegalFrameworkTable />,
    /*control-framework*/
  PrivControlMatrix: <PrivControlMatrix />,
  ControlMatrix: <ControlMatrix />,
    /*requirements*/
  Requirements: <Requirements />,
    /*imp-tools*/
  PrivDataInterfaces: <DataInterfaces />,
  PrivDataMap: <PrivacyDataInventory />,
  ImpToolsMgmtPrinciples: <PrivMgmtPrinc />,
  DataScope: <DataScope />,
  ApplicationSchedule: <ApplicationSchedule />,
  Demographics: <Demographics />,
  Assessments: <Assessments />,
  Reports: <Reports />,
  Scheduling: <Scheduling />,
  Documents: <Documents />,
  HelpCenter: <HelpCenter />,
  Settings: <Settings />,
  TrainingModules: <TrainingModulesPage />,
  TrainingAssignments: <TrainingAssignmentsTable />
}

const CustomComponent = (props) => {
  return registry[props.typeRef] || "";
}

export { registry, CustomComponent }
