import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent } from "@material-ui/core";
import CustomLink from "components/utils/link.component";
import Logo from "components/utils/logo.component";
import classNames from "classnames";
import { generatePath } from "react-router-dom/cjs/react-router-dom.min";
import useNumericParams from "hooks/useNumericParams";
import { PATH_PORTFOLIO_PROGRAM_COMPONENT } from "components/layouts/constants/routes.constants";
import { COMPONENT_ID_PORTFOLIO_ASSESSMENTS } from "utils/programConstants";
import { H3 } from "components/utils/headerV2.component";

const useStyles = makeStyles((theme) => ({
  card: {
    margin: 8,
    flex: 1,
    maxWidth: "30%",
    minWidth: "30%",
    minHeight: 235,
    position: "relative",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      minWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "47%",
      minWidth: "47%",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "30%",
      minWidth: "30%",
    },
  },
  wideCard: {
    margin: 8,
    flex: 1,
    height: 235,
    position: "relative",

    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      minWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "70%",
      minWidth: "70%",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "44%",
      minWidth: "44%",
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: "30%",
      minWidth: "30%",
    },
  },
  cardUnclickable: {
    cursor: "default"
  },
  cardContent: {
    position: "relative",
    padding: 30,
    height: "100%",
    cursor: "default",
  },
  cardColumns: {
    display: "flex",
    position: "absolute",
    top: 0,
    bottom: 0,
    alignItems: "center",
  },
  assessmentList: {
    listStyle: "none",
    margin: 0,
    padding: 0
  },
  assessmentRow: {
    marginTop: 8
  },
  infoWrapper: {
    paddingLeft: 24,
    minWidth: 150,
    alignItems: "center",
    display: "flex",
    flex: 1,
    height: "100%",
  },
  defaultInfoWrapper: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  logoWrapper: {
    display: "flex",
    maxWidth: 270,
    maxHeight: 160,
    [theme.breakpoints.down("lg")]: {
      maxHeight: 130,
    },
  },
  logo: {
    maxHeight: "inherit",
    maxWidth: "inherit",
    height: "100%",
    width: "100%",
    display: "block",
    objectFit: "contain",
  },
  assessmentName: {
    display: "block",
    marginTop: 8,
    fontSize: 14,
    fontWeight: 600,
  },
  assessmentNoLink: {
    color: theme.palette.text.primary,
  },
  assessmentSection: {
    paddingBottom: 5,
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  cardLinkOverlay: {
    height: "100%",
    width: "100%",
    backgroundColor: "none",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 0,
  },
  emptyAssessments: {
    color: theme.palette.text.secondary
  },
  innerLink: {
    marginTop: 0,
    position: "relative",
    zIndex: 1
  }
}));

const PortfolioHolderCard = ({
  children,
  logoWrapperClassProp,
  portfolioHolder,
  onClick,
  wideCard,
}) => {
  const classes = useStyles();
  const { programId } = useNumericParams();
  const [isHovered, setIsHovered] = useState(false);
  const holderAssessments = portfolioHolder?._associations?.HolderAssessments || [];

  return (
    <Card
      className={classNames(
        wideCard && classes.wideCard,
        !wideCard && classes.card,
        !onClick && classes.cardUnclickable,
      )}
      elevation={isHovered ? 3 : 1}
      onMouseEnter={() => onClick && setIsHovered(true)}
      onMouseLeave={() => onClick && setIsHovered(false)}
    >
      <CardContent className={classes.cardContent}>
        <H3>
          {portfolioHolder.Holder_Name}
        </H3>
        <div className={classes.cardColumns}>
          <div
            className={classNames(classes.logoWrapper, logoWrapperClassProp)}
          >
            <Logo
              className={classes.logo}
              logoId={portfolioHolder.Holder_Logo}
              alt={`${portfolioHolder.Holder_Name || "Portfolio Holder"} Logo`}
            />
          </div>
          {!!onClick && (
            <CustomLink
              className={classes.cardLinkOverlay}
              variant="underline"
              onClick={onClick}
              test={`holder-${portfolioHolder.PortfolioHolder_ID}`}
              ignoreReadOnly
            />
          )}
          {!children && (
            <div
              className={classNames(
                classes.infoWrapper,
                classes.defaultInfoWrapper
              )}
            >
              {/*  ======== LIST OF ASSESSMENTS PER HOLDER ======== */}
              <div className={classes.assessmentSection}>
                <div
                  className={
                    classNames(classes.assessmentName, classes.assessmentNoLink)
                  }
                >
                  Assessments
                </div>
                <ul className={classes.assessmentList}>
                  {holderAssessments?.length ? (
                    holderAssessments.map(holderAssessment => (
                      <li
                        className={classes.assessmentRow}
                        key={holderAssessment.HolderAssessment_ID}
                      >
                        <CustomLink
                          className={
                            classNames(classes.assessmentName, classes.innerLink)
                          }
                          to={
                            generatePath(PATH_PORTFOLIO_PROGRAM_COMPONENT, {
                              programId,
                              programComponentId: COMPONENT_ID_PORTFOLIO_ASSESSMENTS,
                              holdingId: portfolioHolder.PortfolioHolder_ID
                            })
                          }
                          variant="routerLink"
                          test={
                            `assessment-${holderAssessment.HolderAssessment_ID}`
                          }
                        >
                          {holderAssessment.Assessment_Name}
                        </CustomLink>
                      </li>
                    ))
                  ) : (
                    <li className={classes.emptyAssessments}>
                      No Assessments
                    </li>
                  )}
                </ul>
              </div>
            </div>
          )}
          {!!children && (
            <div className={classes.infoWrapper}>{children}</div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default PortfolioHolderCard;
