import React, {
  useEffect,
  useMemo,
  useReducer,
  useState,
  useRef,
} from "react";
import DataTable from "components/utils/tables/dataTable.component";
import EditIconButton from "components/utils/editIconButton.component";
import { Box } from "@material-ui/core";
import AddListItem from "components/utils/addListItem.component";
import CustomModal from "components/utils/modal.component";
import { makeStyles } from "@material-ui/core";
import Loader from "components/utils/loader.components";
import trainingAssignmentsReducer, {
  ACTION_SET_ASSIGNMENTS,
  ACTION_SET_ASSIGNABLE_TRAINING_MODULES,
  ACTION_SET_ASSIGNABLE_USERS,
  assignmentsInitialState,
  ACTION_SET_TRAINING_ROLES,
} from "reducers/trainingAssignments.reducer";
import TrainingAssignmentForm from "./trainingAssignmentForm.component";
import useNumericParams from "hooks/useNumericParams";
import CollapsableAlert from "components/utils/collapsableAlert.component";
import trainingService from "services/training.service";
import moment from 'moment'
import { trainingPrivilegeTypes } from "utils/trainingConstants";
import ImportUsersModal from "../users/importUsersModal.component";
import { potentialTraineesFilter } from "utils/roles";
import { createNodeCellParams, createNodeHeaderParams, createValueHeaderParams } from "components/utils/tables/utils/dataTableHelpers";
import { DATE_FORMAT_DISPLAY_NUMERIC } from "utils/dateConstants";

const useStyles = makeStyles((theme) => ({
  buttonColumn: {
    width: 40,
  },
  buttonWrapper: {
    display: "inline-block",
  },
  addWrapper: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 15px",
  }
}));


// Reference ContactList in certification which this is loosely based off
const TrainingAssignmentsTable = () => {
  const classes = useStyles();
  const [formMode, setFormMode] = useState(null);
  const [assignmentToEdit, setAssignmentToEdit] = useState(null);
  const [emailMessage, setEmailMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [roleSelectError, setRoleSelectError] = useState();
  const [state, dispatch] = useReducer(
    trainingAssignmentsReducer,
    assignmentsInitialState
  );
  const rowRef = useRef({});
  const { programId, programComponentId } = useNumericParams();

  useEffect(() => {
    const getAssignments = async () => {
      const [
        assignmentsResponse,
        trainingModulesResponse,
        trainingRoleResponse,
        trainingUsersResponse,
      ] = await Promise.all([
        trainingService.getAllProgramTrainingAssignments(programId),
        trainingService.getTrainingModuleIndex(programComponentId),
        trainingService.getTrainingRoles(),
        trainingService.getTrainingUsers(),
      ]);
      dispatch({ type: ACTION_SET_ASSIGNMENTS, payload: assignmentsResponse.payload });
      dispatch({
        type: ACTION_SET_ASSIGNABLE_TRAINING_MODULES,
        payload: trainingModulesResponse.payload
      });
      dispatch({ type: ACTION_SET_TRAINING_ROLES, payload: trainingRoleResponse.payload });
      dispatch({ type: ACTION_SET_ASSIGNABLE_USERS, payload: potentialTraineesFilter(trainingUsersResponse.payload) });
    };
    getAssignments();
  }, [programId, programComponentId]);

  const openForm = (assignmentToEditItem) => {
    if (assignmentToEditItem) {
      setFormMode("edit");
      setAssignmentToEdit(assignmentToEditItem);
    } else {
      setFormMode("create");
    }
  };

  const closeForm = () => {
    setFormMode(null);
    setAssignmentToEdit(null);
  };


  const getPrivilege = (userPrivilegeId) => {
    if (userPrivilegeId === 1) {
      return "Standard";
    } else if (userPrivilegeId === 2) {
      return "Privileged";
    } else if (userPrivilegeId === 3) {
      return "Admin";
    }
  }

  const privileges = useMemo(() => {
    return trainingPrivilegeTypes.map((privilege) => (privilege.Role));
  }, []);

  const headers = useMemo(() => ([
    createNodeHeaderParams("TrainingAssignment_ID", " ", {
      sort: false,
      setCellHeaderProps: () => ({
        "data-cy": "training-assignment-table-edit-header"
      }),
      setCellProps: () => ({ className: classes.buttonColumn }),
      filter: false,
    }),
    createValueHeaderParams("User_Name", "Name", {
      filter: true,
      filterType: "multiselect",
    }),
    createValueHeaderParams("Module_Name", "Assigned Module", {
      filter: true,
      filterType: "multiselect",
    }),
    createValueHeaderParams("CompleteBy_Date", "Complete By", {
      align: "center",
      filter: true,
      filterType: "multiselect",
      sort: true,
    }),
    createValueHeaderParams("Open_Date", "Open Date", {
      align: "center",
      filter: true,
      sort: true,
    }),
    createNodeHeaderParams("TrainingRole_ID", "User Privilege", {
      align: "center",
      filter: true,
      filterOptions: {
        names: privileges,
      },
      filterType: "multiselect",
    }),
    createNodeHeaderParams("Start_Date", "Started On", {
      align: "center",
      emptyFilterLabel: "Not Started",
      filter: true,
      filterType: "multiselect",
      sort: true,
    }),
    createNodeHeaderParams("End_Date", "Completed On", {
      align: "center",
      emptyFilterLabel: "Not Completed",
      filter: true,
      filterType: "multiselect",
      sort: true,
    })
  ]), [classes, privileges]);

  const tableCells = useMemo(() => {
    return (
      state.assignments?.map((row) => {
        const userPrivilege = getPrivilege(row?.TrainingRole_ID);
        const startDateDisplay = row?.Start_Date ?
          moment(row?.Start_Date).format(DATE_FORMAT_DISPLAY_NUMERIC) :
          "--";
        const endDateDisplay = row?.End_Date ?
          moment(row?.End_Date).format(DATE_FORMAT_DISPLAY_NUMERIC) :
          "--";

        return {
          TrainingAssignment_ID: createNodeCellParams(
            row?.TrainingAssignment_ID,
            row?.TrainingAssignment_ID,
            (
              <div
                ref={el => {
                  rowRef.current[row?.TrainingAssignment_ID] = el;
                }}
              >
                <EditIconButton onClick={() => openForm(row)} />
              </div>
            ),
          ),
          User_Name: row?.User_Name,
          Module_Name: row?.TrainingModule_Name,
          Open_Date: moment(row?.Open_Date).format(DATE_FORMAT_DISPLAY_NUMERIC),
          CompleteBy_Date:
            moment(row?.CompleteBy_Date).format(DATE_FORMAT_DISPLAY_NUMERIC),
          TrainingRole_ID: createNodeCellParams(userPrivilege, userPrivilege, (
            <Box marginTop={1}>
              {userPrivilege}
            </Box>
          )),
          Start_Date: createNodeCellParams(row?.Start_Date, startDateDisplay),
          End_Date: createNodeCellParams(row?.End_Date, endDateDisplay),
        };
      }) || []
    );
  }, [
    state.assignments,
  ]);

  if (!state.assignments || !state.assignableUsers || !state.assignableTrainingModules || !state.trainingRoles) {
    return <Loader />;
  }

  return (
    <Box marginBottom={2}>
      <Box marginBottom={1} marginTop={10}>
        <CollapsableAlert
          showAlert={emailMessage}
          closeClick={() => setEmailMessage(false)}
          message={emailMessage}
        />
        <CollapsableAlert
          showAlert={roleSelectError}
          closeClick={() => setRoleSelectError()}
          message={roleSelectError}
          severity="error"
        />
      </Box>
      <DataTable
        data={tableCells}
        columns={headers}
        options={{
          textLabels: {
            body: {
              noMatch: "No assignments",
            },
          },
          filterType: "multiselect",
          fixedHeader: true,
          fixedSelectColumn: true,
          filter: true,
          pagination: true,
          selectableRowsHideCheckboxes: true,
          viewColumns: false,
          download: false,
          print: false,
          rowsPerPage: 25,
          rowsPerPageOptions: [10, 25]
        }}
      />
      <div className={classes.addWrapper}>
        <div className={classes.buttonWrapper}>
          <AddListItem onClick={() => openForm()}>Add Assignment</AddListItem>
          <CustomModal
            open={formMode === "edit" || formMode === "create"}
            onClose={() => closeForm()}
          >
            <TrainingAssignmentForm
              formMode={formMode}
              assignment={assignmentToEdit}
              closeForm={closeForm}
              dispatch={dispatch}
              setEmailMessage={setEmailMessage}
              programId={programId}
              assignableUsers={state.assignableUsers}
              assignableTrainingModules={state.assignableTrainingModules}
            />
          </CustomModal>
        </div>

        {/* To be used for a separate Users page when built */}
        <div className={classes.buttonWrapper}>
          <AddListItem onClick={() => setIsModalOpen(true)}>Add Users</AddListItem>
          <ImportUsersModal
            dispatch={dispatch}
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            setSuccessMessage={setEmailMessage}
            state={state}
          />
        </div>
      </div>
    </Box>
  );
};

export default TrainingAssignmentsTable;
