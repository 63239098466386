import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import backgroundImages from "./backgroundImages";

const useStyles = makeStyles({
  cardBackground: {
    position: "absolute",
    bottom: 0,
    display: "inline-block",
    // height: "60%", TODO: Look into why this style is overriding the card component styling that was previously overriding it
    width: "100%",
    backgroundPositionY: "top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    '&::after': {
      content: '""',
      background: "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.7) 49%, rgba(255,255,255,0.3) 100%)",
      display: 'block',
      width: "100%",
      height: "100%",
    },
  },
});

const CardBackground = (props) => {
  const classes = useStyles();
  if (props.variant && backgroundImages[props.variant]) {
    return (
      <div
        className={classNames(classes.cardBackground, props.className)}
        style={{ backgroundImage: `url(${backgroundImages[props.variant]})` }}
      />
    );
  }
  return null;
};

export default CardBackground;
