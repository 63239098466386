export const alertsFeedVariants = {
  HEADER: "header",
  DASHBOARD: "dashboard",
  COMPONENT_OBJECT: "componentObject",
}

export const ALERT_TYPE_ID_TASK_DUE = 1;
export const ALERT_TYPE_ID_TASK_ASSIGNED = 2;
export const ALERT_TYPE_ID_TASK_GENERAL = 3;
export const ALERT_TYPE_ID_TASK_RUNBOOK_DUE = 4;
export const ALERT_TYPE_ID_TASK_RUNBOOK_ASSIGNED = 5;
