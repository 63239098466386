import React, { useState, useEffect, useCallback, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  attachMetaToBuilderSections,
  getInitialPractices,
  isSectionHeaderMissing,
  makeSectionClientId,
  isMissingVersion,
} from "utils/builderFunctions";
import BuilderService from "services/builder.service";
import variables from "styleVariables";
import classNames from "classnames";
import Loader from "components/utils/loader.components";
import CustomLink from "components/utils/link.component";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import BuilderHeaderInfo from "components/builder/shared/builderHeaderInfo.component";
import DeleteIconButton from "components/utils/deleteIconButton.component";
import BuilderSectionHeader from "../shared/builderSectionHeader.component";
import BuilderSectionRTE from "../shared/builderSectionRTE.component";
import useNumericParams from "hooks/useNumericParams";
import { builderModes, builderSectionTypes } from "utils/builderConstants";
import builderReducer, {
  ACTION_ADD_BUILDER_SECTION,
  ACTION_DELETE_BUILDER_SECTION,
  ACTION_SET_BUILDER_AFTER_SAVE,
  ACTION_SET_BUILDER_FAILED_SAVE,
  ACTION_SET_BUILDER_IS_SAVING,
  ACTION_SET_BUILDER_LOAD_TEMPLATE,
  ACTION_SET_MISSING_HEADER_MESSAGE,
  ACTION_SET_BUILDER_EDIT_CURRENT,
  procedureBuilderInitialState,
} from "reducers/builder.reducer";
import BuilderHeader from "../shared/builderHeader.component";
import BuilderButtonsColumn from "../shared/builderButtonsColumn.component";
import RegGuidelineFrameworks from "../shared/regGuidelineFrameworks.component";
import Header from "components/utils/header.component";
import ProcedureElements from "./procedureElements.component";
import {
  attachMetaToFrameworks,
  itemizeBuilderSections,
} from "utils/procedureBuilderFunctions";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  innerContentContainer: {
    paddingRight: 170,
    paddingBottom: 20,
  },
  sectionWrapper: {
    marginBottom: 40,
  },
  addSectionWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "left",
    marginBottom: 50,
    marginTop: 20,
  },
  deleteSectionWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  addSectionIcon: {
    fontSize: variables.fontMedium,
    color: "white",
    marginRight: 6,
  },
  procedureHeader: {
    color: "white",
    padding: "10px 15px",
    borderRadius: 3,
    backgroundColor: variables.primaryMain,
  },
  procedureHeaderWrapper: {
    paddingLeft: 60,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
  },
  procedureElementsWrapper: {
    paddingLeft: 60,
    paddingTop: 10,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
  },
}));

//PROC ELE // FRAMEWORK IDENTIFIERS point to GroupName && RegFramework_ID ---- previously, Group_Name was also cited

const isRelatedFramework = (referenceGroups, elementSection) => {
  if (referenceGroups._meta?.clientId) {
    return (
      referenceGroups._meta.clientId === elementSection._meta.parentClientId
    );
  } else {
    return referenceGroups.Reg_Refs_ID === elementSection.RegRefs_Reg_Refs_ID;
  }
};

const isSubGroupOfGroup = (subGroup, procElementSection, referenceGroup) => {
  if (subGroup._meta?.parentClientId) {
    return subGroup._meta.parentClientId === procElementSection._meta.clientId;
  } else {
    return (
      subGroup.GroupName === referenceGroup.GroupName &&
      subGroup.RegFramework_ID === referenceGroup.RegFramework_ID
    );
  }
};

const makeProcElementSectionClientId = (reference) => {
  return `procEleSectionId-${reference.GroupName}-${reference.RegFramework_ID}`;
};

const attachMetaToSubGroup = (subGroups, noPrimaryKey) => {
  return subGroups.map((subGroup) => ({
    ...subGroup,
    Reg_Refs_ID: noPrimaryKey ? null : subGroup.Reg_Refs_ID,
    _meta: {
      clientId: `procStepRefId-${subGroup.Step_Reg_Object}-${subGroup.Step_Source_Object}-${subGroup.Step_Source_ID}`,
      parentClientId: makeProcElementSectionClientId(subGroup),
    },
  }));
};

const ProcedureBuilder = (props) => {
  const classes = useStyles();
  const componentObject = props.componentObject;
  const { programId } = useNumericParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [state, dispatch] = useReducer(
    builderReducer,
    procedureBuilderInitialState
  );
  const {
    builderInfo,
    builderSections,
    referenceGroups,
    loadedVersion,
    statusMessage,
    activeForm,
    builderInfoErrors,
    referenceSubGroups,
    procElementSections,
    procSteps,
    disableBuilder,
    previousVersions,
  } = state;

  useEffect(() => {
    if (props.mode === builderModes.LOAD_TEMPLATE) {
      const newBuilder = {
        ComponentObjects_ComponentObject_ID:
          props.chosenProgram.compObjId || componentObject.ComponentObject_ID,
        Title: "",
        Description: null,
        Version: "0.1",
        Approval_Date: moment(new Date()).format("YYYY-MM-DD"),
        Status: "Active",
        Type: props.type,
        ParentBuilderDoc_ID: props.policy.BuilderDoc_ID,
        BuilderDoc_ID: null,
        Show_Approval_Sections: 1,
      };

      const getAllProcedureData = async () => {
        const [refGroups, practiceRefsRes] = await Promise.all([
          BuilderService.getBuilderRegRefs(props.policy.Builder_ID),
          BuilderService.getPracticeBuilderRegRefs(props.policy.Builder_ID),
        ]);

        let practicesPayload = practiceRefsRes.payload;
        if (practicesPayload.length === 0) {
          practicesPayload = await getInitialPractices(refGroups.payload);
        }

        const frameWorksWithMeta = attachMetaToFrameworks(
          refGroups.payload,
          true
        );
        const procSections = frameWorksWithMeta.map((frame, index) => ({
          SectionHeader: "",
          SectionContent: "",
          Type: "procedureElement",
          Section_Order: index + 1,
          RegFramework_ID: frame.RegFramework_ID,
          _meta: {
            clientId: makeProcElementSectionClientId(frame),
            parentClientId: frame._meta.clientId,
          },
        }));

        const activePractices = practicesPayload.filter(
          (practice) => practice.Status === "Active"
        );

        const formattedPractices = attachMetaToSubGroup(activePractices, true);

        dispatch({
          type: ACTION_SET_BUILDER_LOAD_TEMPLATE,
          payload: {
            builderInfo: newBuilder,
            builderSections: [],
            referenceGroups: frameWorksWithMeta,
            procElementSections: procSections,
            procSteps: [],
            referenceSubGroups: formattedPractices,
          },
        });
      };
      getAllProcedureData();
    }
  }, [
    componentObject.ComponentObject_ID,
    props.builder,
    props.chosenProgram.compObjId,
    props.mode,
    props.policyFrameworks,
    props.type,
    props.policy,
  ]);

  useEffect(() => {
    if (props.mode === builderModes.EDIT_CURRENT) {
      const getAllProcedureData = async () => {
        const [
          builderDataRes,
          builderSectionDataRes,
          referenceGroupsRes,
          practiceRefsRes,
        ] = await Promise.all([
          BuilderService.getBuilderById(props.builder.Builder_ID),
          BuilderService.getBuilderSections(props.builder.Builder_ID),
          BuilderService.getBuilderRegRefs(props.builder.Builder_ID),
          BuilderService.getPracticeBuilderRegRefs(props.builder.Builder_ID),
        ]);
        const sections = attachMetaToBuilderSections(
          builderSectionDataRes.payload
        );
        const itemizedSections = itemizeBuilderSections(sections);

        dispatch({
          type: ACTION_SET_BUILDER_EDIT_CURRENT,
          payload: {
            builderInfo: builderDataRes.payload,
            builderSections: itemizedSections.builderSections,
            procElementSections: itemizedSections.procElementSections,
            procSteps: itemizedSections.procSteps,
            referenceGroups: referenceGroupsRes.payload,
            referenceSubGroups: practiceRefsRes.payload,
            builderInfoErrors: isMissingVersion(builderDataRes.payload.Version),
            previousVersions: props.builder.previousVersions
          },
        });
      };
      getAllProcedureData();
    }
  }, [props.builder, props.mode]);

  const addNewSection = (sectionOrder) => {
    //RegRefs_Reg_Refs_ID is needed to standardize columns being saved so the createMany function will work.
    const newSection = {
      Section_Order: sectionOrder + 1,
      SectionContent: "",
      SectionHeader: "",
      Type: null,
      RegRefs_Reg_Refs_ID: null,
      _meta: {
        clientId: makeSectionClientId(),
      },
    };
    dispatch({
      type: ACTION_ADD_BUILDER_SECTION, //TODO CHECK TO SEE THAT IT DOESN"T NEED UNIQUE TYPE
      payload: newSection,
    });
    // reference and referenceTable sections always have to be at the end in Policy Builder
  };

  const deleteSection = (section) => {
    dispatch({
      type: ACTION_DELETE_BUILDER_SECTION,
      payload: section,
    });
  };

  const submitNewProcedure = useCallback(async () => {
    dispatch({
      type: ACTION_SET_BUILDER_IS_SAVING,
    });
    try {
      const builderSectionsList = [
        ...builderSections,
        ...procElementSections,
        ...procSteps,
      ];
      if (isSectionHeaderMissing(builderSectionsList)) {
        dispatch({
          type: ACTION_SET_MISSING_HEADER_MESSAGE,
        });
        return;
      }
      const builderData = {
        ...builderInfo,
        Program_ID: props.chosenProgram.id ? props.chosenProgram.id : programId,
      };

      const referenceGroupsToRegs = referenceGroups.map((fw) => {
        return {
          Reg_Source: fw.Reg_Source,
          Source_Object: fw.Source_Object,
          Source_ID: fw.Source_ID,
          Type: "procedureElement",
          RegFramework_ID: fw.RegFramework_ID,
          Status: fw.Status,
          _meta: fw._meta,
        };
      });

      const referenceSubGroupsToRegs = referenceSubGroups.map((sg) => {
        return {
          Reg_Source: sg.Step_Reg_Object,
          Source_Object: sg.Step_Source_Object,
          Source_ID: sg.Step_Source_ID,
          Type: "procedureStep",
          RegFramework_ID: sg.RegFramework_ID,
          Status: sg.Status,
          _meta: sg._meta,
        };
      });

      const regRefList = [
        ...referenceGroupsToRegs,
        ...referenceSubGroupsToRegs,
      ];
      const builderRes = await BuilderService.createBuilder(
        builderData,
        builderSectionsList,
        regRefList
      );
      const formattedBuilderSections = attachMetaToBuilderSections(
        builderRes.payload.builderSectionData
      );

      const itemizedSections = itemizeBuilderSections(formattedBuilderSections);

      dispatch({
        type: ACTION_SET_BUILDER_AFTER_SAVE,
        payload: {
          builderInfo: builderRes.payload.builderData,
          builderSections: itemizedSections.builderSections,
          procElementSections: itemizedSections.procElementSections,
          procSteps: itemizedSections.procSteps,
          referenceGroups: builderRes.payload.builderRegRefs,
          referenceSubGroups: builderRes.payload.practiceBuilderRegRefs,
        },
      });
    } catch (error) {
      dispatch({
        type: ACTION_SET_BUILDER_FAILED_SAVE,
      });
    }
  }, [
    builderInfo,
    builderSections,
    referenceGroups,
    procElementSections,
    procSteps,
    programId,
    props.chosenProgram.id,
    referenceSubGroups,
  ]);

  const submitUpdatedProcedure = useCallback(async () => {
    dispatch({
      type: ACTION_SET_BUILDER_IS_SAVING,
    });
    try {
      const builderSectionsList = [
        ...builderSections,
        ...procElementSections,
        ...procSteps,
      ];
      if (isSectionHeaderMissing(builderSectionsList)) {
        dispatch({
          type: ACTION_SET_MISSING_HEADER_MESSAGE,
        });
        return;
      }

      const builderRes = await BuilderService.updateBuilder(
        builderInfo.Builder_ID,
        builderInfo,
        builderSectionsList
      );
      const formattedBuilderSections = attachMetaToBuilderSections(
        builderRes.payload.builderSectionData
      );
      const itemizedSections = itemizeBuilderSections(formattedBuilderSections);
      dispatch({
        type: ACTION_SET_BUILDER_AFTER_SAVE,
        payload: {
          builderInfo: builderRes.payload.builderData,
          builderSections: itemizedSections.builderSections,
          procElementSections: itemizedSections.procElementSections,
          procSteps: itemizedSections.procSteps,
          referenceGroups: builderRes.payload.builderRegRefs,
          referenceSubGroups: builderRes.payload.practiceBuilderRegRefs,
        },
      });
    } catch (error) {
      dispatch({
        type: ACTION_SET_BUILDER_FAILED_SAVE,
      });
    }
  }, [builderSections, builderInfo, procElementSections, procSteps]);

  if (builderSections && builderInfo) {
    return (
      <>
        <BuilderHeader
          readOnly={props.readOnly}
          mode={props.mode}
          type={props.type}
          setBuilderMode={props.setBuilderMode}
          activeForm={activeForm}
          setOpenDialog={setOpenDialog}
          statusMessage={statusMessage}
          dispatch={dispatch}
        />

        {/*  FRAMEWORKS LISTING */}
        <RegGuidelineFrameworks frameworks={referenceGroups} />

        <div className={classes.innerContentContainer}>
          <BuilderHeaderInfo
            previousVersions={previousVersions}
            builderBeingOverwrittenByCopy={props.builderBeingOverwrittenByCopy}
            loadedVersion={loadedVersion}
            builderInfo={builderInfo}
            builderInfoErrors={builderInfoErrors}
            dispatch={dispatch}
            disableBuilder={disableBuilder}
          />
          <div>
            {(
              builderSections.length === 0 || (
                builderSections.length === 2 && builderSections.some(
                  (section) => section.Type === builderSectionTypes.REFERENCE
                )
              )
            ) && (
              <div className={classes.addSectionWrapper}>
                <CustomLink
                  onClick={() => {
                    addNewSection(0);
                  }}
                  variant="linkBar"
                  startIcon={
                    <AddCircleOutlineOutlinedIcon
                      className={classes.addSectionIcon}
                    />
                  }
                  test="AddSection"
                  disableLinkBar={disableBuilder}
                >
                  Add Section
                </CustomLink>
              </div>
            )}
            {/* ======== BUILDER SECTIONS ======== */}
            {builderSections
              .filter((section) => !section.Type)
              .map((section) => {
                return (
                  <div
                    key={`${section._meta.clientId}_${section.Section_Order}`}
                    className={classNames(classes.sectionWrapper)}
                  >
                    {/* ======== SECTION HEADER ======== */}

                    <BuilderSectionHeader
                      section={section}
                      dispatch={dispatch}
                      disableBuilder={disableBuilder}
                    />
                    <BuilderSectionRTE
                      section={section}
                      dispatch={dispatch}
                      disableBuilder={disableBuilder}
                    />

                    <div className={classes.deleteSectionWrapper}>
                      <DeleteIconButton
                        target="Section"
                        onClick={() => {
                          const deleteNotice =
                            "Are you sure you want to delete this section?";
                          if (window.confirm(deleteNotice)) {
                            deleteSection(section);
                          }
                        }}
                        disabled={disableBuilder}
                      />
                    </div>
                    {/* ADD BUTTON */}
                    <div className={classes.addSectionWrapper}>
                      <CustomLink
                        onClick={() => {
                          addNewSection(section.Section_Order);
                        }}
                        variant="linkBar"
                        startIcon={
                          <AddCircleOutlineOutlinedIcon
                            className={classes.addSectionIcon}
                          />
                        }
                        test="AddSection"
                        disableLinkBar={disableBuilder}
                      >
                        Add Section
                      </CustomLink>
                    </div>
                  </div>
                );
              })}
          </div>

          <div className={classes.procedureHeaderWrapper}>
            <div className={classes.procedureHeader}>
              <Header variant="h6White"> Procedure Elements</Header>
            </div>
          </div>

          {!!procSteps && !!referenceSubGroups && procElementSections?.map((elementSection) => {
            const referenceGroup = referenceGroups.find((fWork) =>
              isRelatedFramework(fWork, elementSection)
            );

            return (
              <div
                className={classes.procedureElementsWrapper}
                key={elementSection._meta.clientId}
              >
                <ProcedureElements
                  elementSection={elementSection}
                  procStepsInrRegFramework={procSteps.filter(
                    (stepSection) => {
                      return (
                        stepSection.RegFramework_ID ===
                        referenceGroup.RegFramework_ID
                      );
                    }
                  )}
                  subGroupsInRefGroup={referenceSubGroups.filter(
                    (subGroup) =>
                      isSubGroupOfGroup(
                        subGroup,
                        elementSection,
                        referenceGroup
                      )
                  )}
                  readOnly={props.readOnly}
                  referenceGroup={referenceGroup}
                  dispatch={dispatch}
                  disableBuilder={disableBuilder}
                />
              </div>
            );
          })}
        </div>
        {/* SAVE & CANCEL BUTTONS */}
        <BuilderButtonsColumn
          readOnly={props.readOnly}
          state={state}
          setBuilderMode={props.setBuilderMode}
          sectionLess={
            builderSections.length === 0 &&
            procElementSections.length === 0 &&
            procSteps.length === 0
          }
          mode={props.mode}
          submitNewBuilder={submitNewProcedure}
          submitUpdatedBuilder={submitUpdatedProcedure}
          setOpenDialog={setOpenDialog}
          openDialog={openDialog}
          componentObject={componentObject}
          chosenProgram={props.chosenProgram}
          associatedProgramId={
            props.chosenProgram.id ? props.chosenProgram.id : programId
          }
          dispatch={dispatch}
          statusMessage={statusMessage}
        />
      </>
    );
  } else {
    return <Loader />;
  }
};

export default ProcedureBuilder;
