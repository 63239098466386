import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LabelInput from "components/utils/form-elements/labelInput.component";
import BuilderTask from "components/builder/plan/builderTask.component";
import SettingsApplicationsOutlinedIcon from "@material-ui/icons/SettingsApplicationsOutlined";
import CustomLink from "components/utils/link.component";
import variables from "styleVariables";
import HeaderPrimary from "components/utils/header.component";
import FormatListBulletedOutlinedIcon from "@material-ui/icons/FormatListBulletedOutlined";
import DeleteIconButton from "components/utils/deleteIconButton.component";
import classNames from "classnames";
import { ACTION_ADD_PLAN_TASK, ACTION_DELETE_PLAN_TASK, ACTION_SET_PLAN_WPS_AND_TASKS } from "reducers/builder.reducer";
import { makeTaskClientId } from "utils/planBuilderFunctions";

const useStyles = makeStyles((theme) => ({
  wpContainer: {
    paddingBottom: 30,
    maxWidth: 1200,
    marginLeft: "auto",
    position: "relative",
  },
  wpWrapper: {
    marginTop: 20,
    marginBottom: 20,
    padding: "20px 20px 20px 20px",
    borderRadius: 10,
    maxWidth: 1200,
    marginLeft: "auto",
    position: "relative",
  },
  activeBorder: {
    "&:hover": {
      boxShadow: `1px 1px 8px 2px ${variables.primaryLight}`,
    },
  },
  readOnlyBorder: {
    border: `2px solid ${variables.grayDisabled}`,
  },
  addTaskContainer: {
    maxWidth: 800,
    marginLeft: "auto",
    position: "relative",
    marginBottom: 10,
    marginTop: 10,
  },
  textfieldWrapper: {
    marginTop: 20,
  },
  workpackageInfo: {
    paddingBottom: 20,
  },
  workpackageIcon: {
    fontSize: variables.fontLarge,
    marginRight: 5,
  },
  workpackageIconLarge: {
    fontSize: variables.fontH3,
    marginRight: 5,
  },
  taskIcon: {
    fontSize: variables.fontMedium,
    marginRight: 5,
  },
  wpHeaderWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 5,
  },
  addWorkpackageWrapper: {
    display: "flex",
    bottom: 0,
    right: 0,
    position: "absolute",
    width: "100%",
  },
}));

const BuilderWp = (props) => {
  const classes = useStyles();
  const [wpTasks, setWpTasks] = useState([]);
  const [subTasks, setSubTasks] = useState([]);
  const {dispatch, disableBuilder} = props;

  useEffect(() => {
    const tasks = props.tasks
      .filter(
        (task) =>
          task._meta.associatedWpId === props.wpClientId &&
          !task._meta.parentTaskId
      )
      .map((task, index) => {
        if (!task.Order) {
          return { ...task, Order: index + 1 };
        } else {
          return { ...task };
        }
      })
      .sort((a, b) => a.Order - b.Order);
    const subtasks = props.tasks.filter(
      (task) =>
        task._meta.associatedWpId === props.wpClientId && task._meta.parentTaskId
    );
    setWpTasks(tasks);
    setSubTasks(subtasks);
  }, [props.tasks, props.wpClientId]);


  const addTask = (wpID, parentId, order) => {
    // If new task is added to brand new WP, attach
    const taskProgramId = () => {
      if (!props.workpackage.Program_Program_ID || props.workpackage.Program_Program_ID === "") {
        return props.chosenProgram.id !== "" ? props.chosenProgram.id : props.programId
      }
      return props.workpackage.Program_Program_ID
    }

    const newTask = {
      Task_ID: null,
      Name: null,
      Type: "Task",
      Parent_ID: isNaN(parentId) ? null : parentId,
      Proposed_Start: null,
      Proposed_Completion: null,
      Program_Program_ID: taskProgramId(),
      Workpackages_Workpackage_ID: isNaN(wpID) ? null : wpID,
      Order: parentId ? null : order + 1,
      _meta: {
        clientId: makeTaskClientId(),
        parentClientId: parentId || wpID, //ParentClient should be different for task vs sub
        associatedWpId: wpID,
        type: parentId ? "subTask" : "task",
        parentTaskId: parentId
      }
    };
    const wpTasksCopy = [...wpTasks];
    const subTaskCopy = [...subTasks];

    if (!parentId) {
      wpTasksCopy
        .filter(task => task.Order >= order + 1)
        .forEach(task => {
          task.Order = task.Order + 1;
          task.isUpdated = true;
        });
      wpTasksCopy.push(newTask);
      wpTasksCopy.sort((t1, t2) => t1.Order - t2.Order);
    } else {
      subTaskCopy.push(newTask);
    }

    const updatedList = [
      ...subTaskCopy,
      ...wpTasksCopy, //SWITCH HERE TO CHANGE WHETHER NEW SUBTASK RENDERS AT TOP OR BOTTOM
    ]
    dispatch({
      type: ACTION_ADD_PLAN_TASK,
      payload: updatedList
    })
  };

  const deleteTask = (taskToDelete, level) => {

    let updatedTasks;
    let existingTasksToDelete = []
    // let order = taskToDelete.Order;
    if (level === "subTask") {
      updatedTasks = props.tasks.filter(
        (task) =>
          task._meta.clientId !== taskToDelete._meta.clientId
      );
      if (taskToDelete.Task_ID) {
        existingTasksToDelete = props.tasks.filter((task) => (
          task.Task_ID === taskToDelete.Task_ID
        ));
      }
      dispatch({
        type: ACTION_DELETE_PLAN_TASK,
        payload: {
          tasks: updatedTasks,
          tasksToDeleteOnCreate: existingTasksToDelete
        }
      })
    } else {
      if (taskToDelete.Task_ID) {
        existingTasksToDelete = props.tasks.filter(task => (
          task.Task_ID === taskToDelete.Task_ID ||
          taskToDelete.Task_ID === task.Parent_ID
        ));
      }
      // updated Tasks are all tasks which are not being deleted
      updatedTasks = props.tasks.filter(task => (
        task._meta.clientId !== taskToDelete._meta.clientId &&
        task._meta.parentTaskId !== taskToDelete._meta.clientId
      ));

      const updatedCopy = [...updatedTasks];
      // All tasks not being deleted and part of current WP
      const wpTasksCopy = [
        ...updatedCopy.filter(
          (task) => task._meta.associatedWpId === props.wpClientId
        ),
      ];

      wpTasksCopy.sort((a, b) => a.Order - b.Order);
      //External WP tasks
      const externalWPTasks = updatedCopy.filter(
        (task) => {
          return task.Workpackages_Workpackage_ID !== props.wpClientId
        }
      );
      dispatch({
        type: ACTION_DELETE_PLAN_TASK,
        payload:{
          tasks: [...wpTasksCopy, ...externalWPTasks],
          tasksToDeleteOnCreate: existingTasksToDelete
        }
      })

    }
  };

  const deletePackage = () => {
    const updatedWps = props.workpackages.filter(
      (wp) => wp._meta.clientId !== props.wpClientId
    );
    const updatedTasks = props.tasks.filter(
      (task) => task._meta.associatedWpId !== props.wpClientId
    );

    dispatch({
      type: ACTION_SET_PLAN_WPS_AND_TASKS,
      payload: {
        workpackages: updatedWps,
        tasks: updatedTasks,
      }
    })

  };



  return (
    <div className={classes.wpContainer} data-cy="container-builder-wp">
      <div className={classNames(classes.wpWrapper, props.readOnly ? classes.readOnlyBorder : classes.activeBorder)} data-cy={`wrapper-wp-${props.workpackage._meta.wp_order}`}>
        <div className={classes.wpHeaderWrapper}>
          <HeaderPrimary
            variant="h4Primary"
            startIcon={
              <SettingsApplicationsOutlinedIcon
                className={classes.workpackageIconLarge}
              />
            }
          >
            Workpackage
          </HeaderPrimary>
          {/* DELETE */}
          <DeleteIconButton
            target="Workpackage"
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure you want to delete this workpackage?"
                )
              ) {
                deletePackage();
              }
            }}
            disabled={disableBuilder}
          />
        </div>
        <div className={classes.workpackageInfo}>
          <div className={classes.textfieldWrapper}>
            <LabelInput
              label="Workpackage Name"
              variant="default"
              defaultValue={props.workpackage.Name || ""}
              required
              name="Name"
              id={`${props.wpClientId}_name`}
              onBlur={(event) => props.wpHandler(event, props.wpClientId)}
              test="wpName"
              disabled={disableBuilder}
            />
          </div>

          <div className={classes.textfieldWrapper}>
            <LabelInput
              label="Description"
              multiline
              variant="default"
              defaultValue={props.workpackage.Description || ""}
              name="Description"
              id={`${props.wpClientId}_description`}
              onBlur={(event) => props.wpHandler(event, props.wpClientId)}
              test="wpDesc"
              disabled={disableBuilder}
            />
          </div>
        </div>

        {/* TASKS */}
        {wpTasks.length > 0 &&
          wpTasks.map((task) => (
            <React.Fragment key={task._meta.clientId}>
              <BuilderTask
                tasks={props.tasks}
                task={task}
                subTasks={subTasks}
                addTask={addTask}
                taskClientId={task._meta.clientId}
                wpClientId={props.wpClientId}
                deleteTask={deleteTask}
                disableBuilder={disableBuilder}
                dispatch={dispatch}
              />
            </React.Fragment>
          ))}

        {
          !!props.tasks &&
          !props.tasks.some(
            (task) => task._meta.associatedWpId === props.wpClientId
          ) && (
            <div className={classes.addTaskContainer}>
              <CustomLink
                onClick={() => addTask(props.wpClientId, null, 0)}
                variant="linkBarTeal"
                startIcon={
                  <FormatListBulletedOutlinedIcon
                    className={classes.taskIcon}
                  />
                }
                test="addTask"
                disableLinkBar={disableBuilder}
              >
                Add a Task
              </CustomLink>
            </div>
          )
        }
      </div>
      <div className={classes.addWorkpackageWrapper}>
        <CustomLink
          onClick={() => props.openForm("wpModal", props.workpackage._meta.wp_order)}
          variant="linkBarDark"
          startIcon={
            <SettingsApplicationsOutlinedIcon
              className={classes.workpackageIcon}
            />
          }
          test={props.wpClientId}
          disableLinkBar={disableBuilder}
        >
          Add a Workpackage and Supporting Tasks
        </CustomLink>
      </div>
    </div>
  );
};

export default BuilderWp;
