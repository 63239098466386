import React from "react";
import { IconButton, Select, MenuItem } from "@material-ui/core";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import variables from "styleVariables";

const useStyles = makeStyles((theme) => ({
  sortControls: {
    display: "flex",
    margin: 3
  },
  iconButton: {
    padding: 5,
  },
  arrowIcon: {
    fontSize: 20,
  },
  activeArrow: {
    color: "DodgerBlue",
  },
  selectWrapper: {
    width: 110,
    paddingRight: 10,
  },
  select: {
    width: "100%",
    fontSize: variables.fontSmall,
  },
  selectRoot: {
    color: variables.textSecondary,
  },
}));

const SortControls = ({value, sortFields, sortHandler, direction, disabled}) => {
  const classes = useStyles();
  return (
    <div className={classes.sortControls}>
      <div className={classes.selectWrapper}>
        <Select
          value={value}
          name="sort-items"
          onChange={(event) => sortHandler(event)}
          className={classes.select}
          disabled={disabled}
          classes={{root: classes.selectRoot}}
        >
          {sortFields.map((item) => (
            <MenuItem key={item.value} value={item.value} id={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </div>
      <IconButton
        className={classes.iconButton}
        onClick={() => sortHandler(null, "up")}
        disabled={disabled}
      >
        <ArrowUpwardIcon
          className={classNames(
            classes.arrowIcon,
            direction === "up" && !disabled && classes.activeArrow
          )}
        />
      </IconButton>
      <IconButton
        className={classes.iconButton}
        onClick={() => sortHandler(null, "down")}
        disabled={disabled}
      >
        <ArrowDownwardIcon
          className={classNames(
            classes.arrowIcon,
            direction === "down" && !disabled && classes.activeArrow
          )}
        />
      </IconButton>
    </div>
  );
};

export default SortControls;
