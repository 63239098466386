import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import Header from "components/utils/header.component";
import { Box } from "@material-ui/core";
import Form from "components/utils/form-elements/form.component";
import { useState } from "react";
import LabelInput from "components/utils/form-elements/labelInput.component";
import { useCallback } from "react";
import SingleFileUpload from "components/utils/form-elements/singleFileUpload.component";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import classNames from "classnames";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Description, OpenInBrowser } from "@material-ui/icons";
import { validURL } from "utils/validators";
import { validHttpsUrl } from "utils/stringFuncs";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: 25,
    height: 500,
  },
  saveAssignmentsButton: {
    width: "auto",
    padding: 24,
  },
  saveIcon: {
    fill: "white",
    fontSize: variables.fontSmall,
    marginRight: 7,
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
  },
  buttonGroup: {
    display: "flex",
    paddingBottom: 30,
    paddingTop: 30,
  },
  referenceIcon: {
    paddingRight: 5,
  },
  attachFileButton: {
    paddingTop: 25,
  }
}));

const SERVER_FILENAME_SEPARATOR = "__";


const RequirementReferencesForm = ({
  reference,
  onRemove,
  setOpenModalReference,
  activeRequirement,
  onSubmit
}) => {
  const classes = useStyles();
  const [referenceTypeMode, setReferenceTypeMode] = useState(reference?.Path_Type || "file");
  const [path, setPath] = useState(reference?.Path_Value);
  const [chosenFile, setChosenFile] = useState();
  const [formErrors, setFormErrors] = useState();

  const validateFormErrors = (documentFormData) => {
    const errors = {};
    if (documentFormData.Path_Type === "url" && !validURL(documentFormData.Path_Value)) {
      errors.Invalid_Path = "Invalid URL";
    }
    return errors;
  };
  const [fileError, setFileError] = useState(false)

  const existingFile = useMemo(() => (
    !reference?.Path_Value ?
      null :
      { File_Ref: reference?.Path_Value, Path_Value: reference?.Path_Value }
  ), [reference?.Path_Value]);

  const disabledUrlToggle = useMemo(() => {
    if (reference) {
      return referenceTypeMode === "file";
    } else {
      return !!chosenFile;
    }
  }, [reference, chosenFile, referenceTypeMode]);

  const disableFileToggle = useMemo(() => {
    if (reference) {
      return referenceTypeMode === "url";
    } else {
      return !!path;
    }
  }, [reference, referenceTypeMode, path]);

  const chosenFileDisplayName = useMemo(() => {
    if (!chosenFile) {
      return null;
    }
    const splitName = chosenFile?.name.split(SERVER_FILENAME_SEPARATOR);
    return splitName.length === 1 ? splitName[0] : splitName[1];
  }, [chosenFile]);

  const handleSubmit = useCallback((data, formData) => {
    const validationErrors = validateFormErrors({ Path_Type: referenceTypeMode, Path_Value: path })
    if (Object.values(validationErrors).filter((error) => error !== false).length > 0) {
      setFormErrors(validationErrors);
      return;
    }
    if (!reference && referenceTypeMode === "file" && !chosenFile) {
      setFileError(true)
      return;
    }
    if (chosenFile) {
      data.Path_Value = chosenFile.name;
    } else if (!chosenFile && referenceTypeMode === "file" && reference.Path_Value) {
      data.Path_Value = reference.Path_Value;
    } else {
      data.Path_Value = validHttpsUrl(data.Path_Value);
    }
    onSubmit(data, formData, chosenFile);
  }, [chosenFile, onSubmit, reference, referenceTypeMode, path]);

  const chooseFile = useCallback((event) => {
    if (event.target.files[0]) {
      setChosenFile(event.target.files[0])
    }
  }, []);

  const handlePathChange = useCallback((event) => {
    setPath(event.target.value);
  }, []);

  const handleToggle = useCallback((toggleValue) => {
    setReferenceTypeMode(toggleValue);
  }, [setReferenceTypeMode]);

  return (
    <div className={classes.formContainer} data-cy="requirementsForm">
      <Form
        name="requirement-references"
        className={classes.form}
        onSubmit={handleSubmit}
      >
        <Header variant="h3Primary">{reference ? "Update" : "Add"} Reference</Header>
        <div className={classes.buttonGroup}>
          <ToggleButtonGroup
            className={classes.toggleButtonGroup}
            size="small"
            value={referenceTypeMode}
            onChange={event => handleToggle(event.currentTarget.value)}
            exclusive
          >
            <ToggleButton
              className={classes.toggleButton}
              value="file"
              disabled={disableFileToggle}
            >
              <Description fontSize="medium" className={classes.referenceIcon} />
              File Upload
            </ToggleButton>
            <ToggleButton
              className={classes.toggleButton}
              value="url"
              disabled={disabledUrlToggle}
            >
              <OpenInBrowser fontSize="medium" className={classes.referenceIcon} />
              Website
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <Box
          flex={1}
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
        >
          {referenceTypeMode === "url" && (
            <>
              <LabelInput
                required
                name="Path_Value"
                placeholder="https://www.example-website.com"
                value={path}
                onChange={(event) => handlePathChange(event)}
                label="Website Path"
                variant="default"
                test="requirement-website-path-reference"
                error={!!formErrors?.Invalid_Path}
                errorMessage={formErrors?.Invalid_Path}
                autoFocus
              />
              <LabelInput
                required
                name="Description"
                defaultValue={reference?.Description}
                label="Reference Description"
                variant="default"
                test="requirement-reference-description"
              />
            </>
          )}
          {referenceTypeMode === "file" && (
            <Box flex={1}>
              <LabelInput
                required
                name="Description"
                defaultValue={reference?.Description}
                label="Reference Description"
                variant="default"
                test="requirement-reference-description"
                autoFocus
              />
              <Box marginTop={2}>
                <SingleFileUpload
                  chosenFile={chosenFile}
                  fileInputHandler={chooseFile}
                  fileNameField="Path_Value"
                  renamedChosenFile={chosenFileDisplayName}
                  existingFileDisplayName={reference?._meta?.displayFileName || reference?.Path_Value}
                  unsavedExistingFile={reference?.file}
                  existingFile={existingFile}
                  className={classes.attachFileButton}
                  error={fileError}
                />
              </Box>
            </Box>
          )}
          <div>
            <DualFormButtons
              startIcon={<SaveOutlinedIcon className={classNames(classes.saveIcon, classes.icon)} />}
              addText={reference ? "Update" : "Save Reference"}
              cancelOnClick={() => setOpenModalReference(null)}
              deleteClick={reference ? onRemove : undefined}
              className={classes.saveAssignmentsButton}
            />
          </div>
        </Box>
        <input
          name="_meta.clientId"
          type="hidden"
          value={
            reference?.Reference_ID ||
            reference?._meta?.clientId ||
            ""
          }
        />
        <input
          name="Reference_ID"
          type="hidden"
          value={reference?.Reference_ID}
        />
        <input
          name="Requirement_ID"
          type="hidden"
          value={activeRequirement?.Requirement_ID || ""}
        />
        <input
          name="Path_Type"
          type="hidden"
          value={referenceTypeMode}
        />
      </Form>
    </div>
  );
};

export default RequirementReferencesForm;
