import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import variables from "styleVariables";
import classNames from "classnames";
import { isReadOnly } from "utils/roles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "fit-content",
    color: variables.secondaryMain,
    lineHeight: "normal",
    alignItems: "center",
    fontWeight: "bold",
    "&:hover": {
      cursor: "pointer",
      color: variables.secondaryDark,
    },
  },
  dark: {
    color: variables.primaryMain
  },
  addIcon: {
    fontSize: "20px",
    marginRight: 6,
  },
  disabled:{
    color: variables.grayDisabledText,
    "&:hover": {
      cursor: "default",
      color: variables.grayDisabledText,
    },
  },
  custom: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    color: variables.primaryMain,
    lineHeight: "normal",
    fontWeight: "bold",
    "&:hover": {
      cursor: "pointer",
      color: variables.primaryDark,
    },
  },
  icon: {
    display: "inline-flex",
  },
}));

export const AddListItem = (props) => {
  const classes = useStyles();
  const readOnly = isReadOnly();

  if (readOnly || props.disabled) {
    return (
      <span className={classNames(classes.root, props.className, classes.disabled)} data-cy={`addListIcon-disabled-${props.test}`}>
        <AddCircleOutlineIcon className={classNames(classes.addIcon, classes.disabled)} />
        {props.text || props.children}
      </span>
    )
  } else if (props.variant === "customIcon") {
    return (
      <span
        onClick={props.onClick}
        className={classNames(classes.custom, props.className)}
        {...props}
      >
        <span className={classNames(classes.icon, props.className)}>{props.startIcon}</span>
        <span className={classes.label}>{props.text || props.children}</span>
      </span>
    );
  } else {
    return (
      <span onClick={props.onClick} className={classNames(classes.root, props.dark && classes.dark, props.className)} data-cy={`addListIcon-${props.test}`}>
        <AddCircleOutlineIcon className={classes.addIcon} />
        {props.text || props.children}
      </span>
    );
  }
};

export default AddListItem;
