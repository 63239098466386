import {
  controlsCompNames,
  keysByViewType,
  programDocCompNames,
  programsCompNames,
  refViewTypes,
  complianceCompNames,
  dataCollectionStatus,
  assessmentSourceTableInfo,
  requirementCompNames,
  assessmentTypeIds,
  assessBuilderStatus,
} from "utils/assessmentConstants";
import { sortByStringKey, sortByNestedJSONKey } from "utils/sortingFuncs";
import { stripNewTag } from "utils/idGenerators";
import { replaceAllSubStrings } from "utils/stringFuncs";
import RequirementService from "services/requirement.service";


const assessItemRefAttacher = (arrayOfObjs, refFieldName, idFieldName) => {
  const primaryKey = idFieldName;
  return arrayOfObjs.map((item) => {
    return {
      ...item,
      Foreign_Ref: assessItemRefCreator(item, refFieldName, primaryKey),
    };
  });
};

const assessItemRefAttacherAfterLoad = (arrayOfObjs, programComponents) => {
  /*
  arrayOfObjs is AssessmentItem row data

  Example of AssessmentItem field:
  '{"Practice_ID":1,"Practice_Code":"AC.1.001","Practice":"Limit information system access to authorized users, processes acting on behalf of authorized users, or devices (including other information systems).","Capability_Code":"C001","Capability_Desc":"Establish system access requirements","ML":1,"Domain_Name":"Access Control","Domain_Abbrev":"AC","Version":"1.02","Change_Date":"2020-03-18","Reference":"CMMC_1"}'
  */
  return arrayOfObjs.map((item) => {
    if (item.ProgramComponent_ID) {
      //Checks that the item is a selected item
      const refObj = JSON.parse(item.AssessmentItem);
      const programComponent = programComponents.find(({ Component_ID: id }) =>
        id === item.ProgramComponent_ID
      );
      const viewType = refViewTypeFinder(programComponent.Name);
      const refKeys = keysByViewType[viewType];
      const refValue = assessItemRefCreator(refObj, refKeys[0], refKeys[1]);

      return {
        ...item,
        Foreign_Ref: refValue,
        ProgramComponent_ID: programComponent.Component_ID,
        ProgramComponent_Name: programComponent.Name
      };
    } else {
      return {
        ...item,
      };
    }
  });
};

// Todo: ProgramComponent_Name (and other basic string data) should always be returned by all endpoints by a serializer
const attachProgramNameAfterLoad = (updatedItems, originalItems) => {
  const originalItemById = originalItems.reduce((accumulator, original) => ({
    ...accumulator,
    [stripNewTag(original.AssessmentItem_ID)]: original
  }), {});
  return updatedItems.map(item => {
    const originalItem = originalItemById[item.AssessmentItem_ID];
    if (!originalItem) {
      return item;
    }
    return {
      ...item,
      ProgramComponent_Name: originalItem.ProgramComponent_Name
    }
  });
}

const assessItemRefCreator = (refObject, fieldNameOne, fieldNameTwo) => {
  const formattedName = refObject[fieldNameOne]?.split(" ")?.join("_") || "noValue";
  const regFrameworkID = refObject.RegFramework_ID; // Determines if it is a Control

  let assessItemRef;

  if (regFrameworkID) {
    assessItemRef = `${formattedName}-${regFrameworkID}-${fieldNameTwo}-${refObject[fieldNameTwo]}`;
  } else {
    assessItemRef = `${formattedName}-${fieldNameTwo}-${refObject[fieldNameTwo]}`
  }

  return assessItemRef; //CMMC-Control_ID-1
};

const optionListCreator = (objArr, field) => {
  const sortedReferenceItems = sortByStringKey(objArr, field)
  let count;
  let foreignRefList = [];
  const modifiedList = [];
  sortedReferenceItems.forEach((data, index) => {
    if (index === 0 || data[field] !== objArr[index - 1][field]) {
      count = 1;
      foreignRefList = [data.Foreign_Ref]
    } else {
      count = count + 1;
      foreignRefList.push(data.Foreign_Ref)
    }
    if (index + 1 === objArr.length || data[field] !== objArr[index + 1][field]) {
      modifiedList.push({
        label: data[field],
        name: data[field],
        itemCount: count,
        foreignRefList,
        RegFramework_ID: data.RegFramework_ID
      });
    }
  });
  return modifiedList;
};

/**
 * used by checklist dropdown in assessment item selection
 */
const assessItemCountByGroup = (assessList, option, fieldName, progCompName, tabSourceId) => {
  let scopedAssessList = assessList
    .filter(assessItem => assessItem.ProgramComponent_Name === progCompName)
    .filter((assessItem) => {
      const parsedAssessmentItemObj = JSON.parse(assessItem.AssessmentItem);
      return parsedAssessmentItemObj[fieldName] === option.name;
    })

  if (progCompName === "Controls") {
    //Safeguard in case different control groups have the same option column values
    scopedAssessList = scopedAssessList
      .filter((item) => {
        return JSON.parse(item.AssessmentItem).RegFramework_ID === tabSourceId
      })
  }




  return scopedAssessList.length
};

const unremovableItemsCountByGroup = (assessList, option, fieldName, unremovableItemIDs) => {
  return assessList.filter((assessItem) => {
    const assessmentItemObj = JSON.parse(assessItem.AssessmentItem);
    return assessmentItemObj[fieldName] === option.name;
  }).filter(scopedItem => unremovableItemIDs.includes(scopedItem.AssessmentItem_ID)).length
};

/*
Takes two arrays of Objects and removes similar objects based on identical field values,
returns false or two arrays. Needed mainly to account for the checkbox logic in selecting controls to assessitems
*/
const delAndAddDuplicateFinder = (newObjList, delObjList, field) => {
  //ProgramComponents_Component_ID
  const newListValsByField = newObjList.map((newObj) => newObj[field]);
  const delListValsByField = delObjList.map((delObj) => delObj[field]);
  const duplicates = newListValsByField
    .filter(
      (
        ele //Accounts for instances where undefined is being passed due to no corresponding field
      ) => ele !== undefined
    )
    .filter((val) => delListValsByField.includes(val));

  if (duplicates.length > 0) {
    const updatedNewObjList = newObjList.filter(
      (newItem) => !duplicates.includes(newItem[field])
    );
    const updatedDelObjList = delObjList.filter(
      (delItem) => !duplicates.includes(delItem[field])
    );
    return [updatedNewObjList, updatedDelObjList];
  } else {
    return false;
  }
};

//Checks if an edited item was later deleted. If so, removes the edited obj from editedObjArr
const delAndEditDuplicateFinder = (editedObjList, delObjList, field) => {
  const delListValsByField = delObjList.map((newObj) => newObj[field]); //array of AssessmentItem_ID field
  const editedListValsByField = editedObjList.map((editObj) => editObj[field]); //array of AssessmentItem_ID field
  const duplicates = editedListValsByField.filter((val) =>
    delListValsByField.includes(val)
  );
  if (duplicates.length > 0) {
    return editedObjList.filter((obj) => !duplicates.includes(obj[field]));
  } else {
    return editedObjList;
  }
};

const assessItemIsCustom = (item) => {
  return !item.ProgramComponent_ID;
};

const assesSourceTableHeaders = (sourceType) => {
  const { filterable, fields } = assessmentSourceTableInfo[sourceType];
  return fields.map((field, index) => {
    const autoFormattedField = field.split("_").join(" ");
    if (index === 0) {
      return { display: "", name: "Checkbox", alignment: "center" };
    }
    const filter = index === fields.length - 1 && filterable;
    return {
      display: autoFormattedField,
      name: field,
      filter,
      sort: !filter,
    };
  });
};

const assesSourceTableColumns = (sourceType) => {
  return assessmentSourceTableInfo[sourceType].fields.slice(1);
};

// Returns an object that is set as the selectAll initial state - Used when there are tabs
const selectAllInitialStateFinder = (arrOfObj, fieldName) => {
  let newObj = {};
  for (const obj of arrOfObj) {
    const formattedName = obj[fieldName].split(" ").join("_");
    newObj = { ...newObj, [formattedName]: null };
  }

  return newObj;
};

const assessFirstColumnDisplay = (assessItem, progCompName) => {
  if (!progCompName) {
    return "None";
  }
  const refItemObj = JSON.parse(assessItem);

  if (controlsCompNames.includes(progCompName)) {
    return refItemObj.Name;
  }
  if (programDocCompNames.includes(progCompName)) {
    return refItemObj.Document_Type;
  }
  if (programsCompNames.includes(progCompName)) {
    return "None";
  }
};

// Used in assessment items table and checklist table
const assessSecondColumnDisplay = (assessItem, progCompName, variant) => {
  if (!progCompName) {
    return assessItem;
  }
  const refItemObj = JSON.parse(assessItem);
  if (controlsCompNames.includes(progCompName)) {
    const mainDisplayValue = refItemObj.Identifier;
    const secondaryDisplayValue = refItemObj.Group_Name;
    return variant === "simple" ? `${mainDisplayValue}` : `${mainDisplayValue} - ${secondaryDisplayValue}`;
  }
  if (programDocCompNames.includes(progCompName)) {
    return `${refItemObj.Title}`;
  }
  if (programsCompNames.includes(progCompName)) {
    return `${refItemObj.Name} Program`;
  }
  if (complianceCompNames.includes(progCompName)) {
    return refItemObj.Statement;
  }
  if (requirementCompNames.includes(progCompName)) {
    return `${refItemObj.Description}`;
  }
};

const getAssessmentItemName = (assessmentItem) => {
  try {
    const json = JSON.parse(assessmentItem.AssessmentItem);
    if (json.Identifier && json.Framework_Version) {
      return `${json.Name} v.${json.Framework_Version}: ${json.Identifier}`;
    } else if (json.Name && !json.Practice_Code) {
      return `${json.Name}`;
    } else if (json.Title) {
      return json.Title;
    } else if (json.Description) { // Used for Requirements
      return json.Description;
    } else if (json.Requirement_Description) {
      return json.Requirement_Description;
    }
  } catch (error) {
    return assessmentItem.AssessmentItem;
  }
}

const refViewTypeFinder = (compName) => {
  // Finds the type of ref items which are being displayed
  if (programDocCompNames.includes(compName)) {
    return refViewTypes.PROGRAM_DOCS;
  } else if (controlsCompNames.includes(compName)) {
    return refViewTypes.CONTROLS;
  } else if (programsCompNames.includes(compName)) {
    return refViewTypes.PROGRAMS;
  } else if (complianceCompNames.includes(compName)) {
    return refViewTypes.COMPLIANCE;
  } else if (requirementCompNames.includes(compName)) {
    return refViewTypes.REQUIREMENTS;
  }
};

const sourceReferenceKeysFinder = (compObjName) => {
  const viewType = refViewTypeFinder(compObjName);
  return keysByViewType[viewType];
};

const scopeTabIdFieldNameFinder = (progCompName) => {
  if (progCompName === "Controls") {
    return "RegFramework_id";
  }
};

const sortedItemTableDisplay = (tableData, sourceObj) => {
  const compObjName = sourceObj.Name

  let data = tableData.filter((item) => {
    if (!item.ProgramComponent_ID && sourceObj.Component_ID === "custom") {
      return item;
    }
    return item.ProgramComponent_ID === sourceObj.Component_ID;
  });
  if (compObjName === "Custom") {
    data = sortByStringKey(data, "AssessmentItem");
  }
  if (controlsCompNames.includes(compObjName)) {
    const dataByControlID = sortByNestedJSONKey(data, "AssessmentItem", "Control_ID")
    data = sortByStringKey(dataByControlID, "RegFramework_ID");
  }
  if (programDocCompNames.includes(compObjName)) {
    data = sortByNestedJSONKey(data, "AssessmentItem", "Title")
  }
  if (programsCompNames.includes(compObjName)) {
    data = sortByNestedJSONKey(data, "AssessmentItem", "Name")
  }

  return data;
};

const reviewStatusRanking = Object.values(dataCollectionStatus);

const findTopRankedStatus = (reviewOrReviews) => {
  if (Array.isArray(reviewOrReviews)) {
    let Status;
    reviewOrReviews.forEach(review => {
      const rank = reviewStatusRanking.indexOf(review.Status);
      if (rank > reviewStatusRanking.indexOf(Status)) {
        Status = review.Status;
      }
    });
    return Status;
  }
  return reviewOrReviews?.Status;
}

const tallyReviewStatus = (reviewsByAssessmentItems) => {
  if (!reviewsByAssessmentItems) {
    return {};
  }
  const tally = { notStarted: 0, inProgress: 0, completed: 0 };
  reviewsByAssessmentItems.forEach(reviewList => {
    const status = findTopRankedStatus(reviewList);
    switch (status) {
      case dataCollectionStatus.STATUS_IN_PROGRESS:
        tally.inProgress += 1;
        return;
      case dataCollectionStatus.STATUS_COMPLETED:
        tally.completed += 1;
        return;
      default:
        tally.notStarted += 1;

    }
  });
  return tally;
}

const accordionIDMaker = (progCompName, programName, subGroupName) => {
  const formattedProgCompName = replaceAllSubStrings(progCompName, " ", "_");
  let formattedProgName;
  let formattedSubGroupName;
  if (programName) {
    formattedProgName = replaceAllSubStrings(programName, " ", "_");
  }
  if (subGroupName) {
    formattedSubGroupName = replaceAllSubStrings(subGroupName, " ", "_");
  }
  if (programName && !subGroupName) {
    return `${formattedProgCompName}-${formattedProgName}`;
  }
  return programName
    ? `${formattedProgCompName}-${formattedProgName}-${formattedSubGroupName}`
    : `${formattedProgCompName}-${formattedSubGroupName}`;
};

const uniqueAssessItemIdsWithReviewFinder = (revObj) => {
  if (!revObj) {
    return [];
  }
  const idArr = [];
  const arrOfObjs = [...revObj.design, ...revObj.performance];
  for (const obj of arrOfObjs) {
    idArr.push(obj.AssessmentItem_ID);
  }
  return [...new Set(idArr)];
};

const uniqueAssessItemForeignRefsWithReviewFinder = (assessItemsWithReviewIDs, assessItems) => {
  return assessItems.filter(item => assessItemsWithReviewIDs.includes(item.AssessmentItem_ID)).map(item => item.Foreign_Ref)
};

const outOfScopeAssessItems = (
  assessItems,
  compareField,
  compareValue,
  assessitemIdsWithReviews,
) => {
  return assessItems.filter(
    (item) =>
      compareValue !== item[compareField] ||
      (compareValue === item[compareField] &&
        assessitemIdsWithReviews.includes(item.AssessmentItem_ID))
  );
};

const getRequirementsSourceData = async (progID, sourceRefKeys) => {
  let reqResponse;
  if (progID === 8) {
    reqResponse = await RequirementService.getAllRequirements();
  } else {
    reqResponse = await RequirementService.getAllProgramRequirements(progID);
  }
  const payload = reqResponse.payload.requirements;
  if (payload[0]) {
    const dataWithRef = assessItemRefAttacher(
      payload,
      sourceRefKeys[0],
      sourceRefKeys[1]
    );
    const headers = assesSourceTableHeaders(refViewTypes.REQUIREMENTS)
    const fields = assesSourceTableColumns(refViewTypes.REQUIREMENTS)
    return [headers, dataWithRef, fields];
  } else {
    return [null, null, null];
  }
};

const findAssessmentTypeByState = (assessTypeStateObj) => {
  const designTypeID = assessmentTypeIds.ASSESS_TYPE_DESIGN;
  const perfTypeID = assessmentTypeIds.ASSESS_TYPE_PERFORMANCE;
  const allTypeID = assessmentTypeIds.ASSESS_TYPE_ALL;
  let newAssessType;
  if (assessTypeStateObj[designTypeID] && assessTypeStateObj[perfTypeID]) {
    newAssessType = allTypeID;
  } else if (!assessTypeStateObj[designTypeID] && !assessTypeStateObj[perfTypeID]) {
    newAssessType = null;
  } else if (assessTypeStateObj[designTypeID]) {
    newAssessType = designTypeID;
  } else if (assessTypeStateObj[perfTypeID]) {
    newAssessType = perfTypeID;
  }
  return newAssessType;
};

const isReadOnlyBasedOnStatuses = (builderInfo, dataCollectionInfo) => {
  if (
    builderInfo?.Status === assessBuilderStatus.BUILDER_STATUS_ASSESSED ||
      builderInfo?.Status === assessBuilderStatus.BUILDER_STATUS_RESPONDED ||
    dataCollectionInfo?.Status === dataCollectionStatus.STATUS_COMPLETED
  ) {
    return true;
  }
  return false;
};

const formatAssessmentItemRecommendations = async (assessmentItemRecs) => {
  const formattedDesignRecs = []
  const formattedPerformanceRecs = []

  for(const itemRec of assessmentItemRecs) {
    for(const designDC of itemRec._associations.DesignDC) {
      formattedDesignRecs.push(
        {
          FindingRecommendation: designDC.Recommendation,
          Status: designDC.Status,
          Design_ID: designDC.Design_ID,
          AssessmentItem: itemRec.AssessmentItem,
          ProgramComponent_ID: itemRec.ProgramComponent_ID,
          RegFramework_ID: itemRec.RegFramework_ID,
          Control_ID: itemRec.Control_ID,
          First_Name: itemRec.First_Name,
          Last_Name: itemRec.Last_Name,
          Change_Date: itemRec.Change_Date
        }
      )
    }
    for(const performanceDC of itemRec._associations.PerformanceDC) {
      formattedPerformanceRecs.push(
        {
          FindingRecommendation: performanceDC.Recommendation,
          Status: performanceDC.Status,
          Performance_ID: performanceDC.Performance_ID,
          AssessmentItem: itemRec.AssessmentItem,
          ProgramComponent_ID: itemRec.ProgramComponent_ID,
          RegFramework_ID: itemRec.RegFramework_ID,
          Control_ID: itemRec.Control_ID,
          First_Name: itemRec.First_Name,
          Last_Name: itemRec.Last_Name,
          Change_Date: itemRec.Change_Date
        }
      )
    }
  }

  return {
    Design_Recommendations: formattedDesignRecs,
    Performance_Recommendations: formattedPerformanceRecs
  }
}

export {
  assessItemRefAttacher,
  assessItemRefAttacherAfterLoad,
  attachProgramNameAfterLoad,
  optionListCreator,
  assessItemCountByGroup,
  unremovableItemsCountByGroup,
  uniqueAssessItemForeignRefsWithReviewFinder,
  assessItemRefCreator,
  delAndAddDuplicateFinder,
  delAndEditDuplicateFinder,
  assessItemIsCustom,
  selectAllInitialStateFinder,
  assessFirstColumnDisplay,
  assessSecondColumnDisplay,
  getAssessmentItemName,
  refViewTypeFinder,
  sourceReferenceKeysFinder,
  scopeTabIdFieldNameFinder,
  sortedItemTableDisplay,
  tallyReviewStatus,
  accordionIDMaker,
  uniqueAssessItemIdsWithReviewFinder,
  outOfScopeAssessItems,
  assesSourceTableColumns,
  assesSourceTableHeaders,
  getRequirementsSourceData,
  findAssessmentTypeByState,
  isReadOnlyBasedOnStatuses,
  formatAssessmentItemRecommendations
};
