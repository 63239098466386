import http from "./http-common";
import authHeader from "./authHeader";

class UserService {

  // get list of Users
  getAll() {
    return http
      .get(`/user/`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get User by userId
  getUserById(userId) {
    return http
      .get(`/user/${userId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  //Create User
  create(user) {
    return http
      .post(`/user/create`, { user }, { headers: authHeader() })
      .then((response) => {
        return response;
      });
  }

  // update a  Task
  update(user, userId) {
    return http
      .put(`/user/update/${userId}`, { user }, { headers: authHeader() })
      .then((response) => {
        return response;
      });
  }

  getActiveCurrentUserAlerts() {
    return http
      .get(`/user/userAlerts`, { headers: authHeader() })
      .then((response) => response.data);
  }

  deleteUserAlert(userAlertId) {
    return http
      .delete(`/user/userAlerts/${userAlertId}`, { headers: authHeader() })
      .then(response => response.data);
  }

  // validate a user's eligibility to be added to the system
  validateUser(user) {
    return http
      .post(`/user/validate`, user, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error?.response || error;
      })
  }
}

export default new UserService();
