import React from "react";
import AddSourceItemsDefault from "components/assessment/addSourceItemsDefault.component";
import {
  controlsCompNames,
  programDocCompNames,
  programsCompNames,
  complianceCompNames,
  requirementCompNames,
} from "utils/assessmentConstants";
/*
By ViewTypes - Each Component Object selected will categorized into a viewType. The mapping between
viewType and ProgramComponents can be found in the assessmentConstants.js file. Each viewType relies
on two fields of reference from the source Object to allow it to interact with the assessmentItem
objects

  Controls :
      Foreign_Ref field is the {source.Name}-Control_ID-{source.Control_ID} ie CMMC-Control_ID-1
      Name Display is a combination of the 2nd and 3rd columns that are returned from source

  ProgramDoc :
      Foreign_Ref field is {source.Document_Type}-Doc_ID-{source.Doc_ID} ie Current_Charter-Doc_ID-7

*/

const CreateAssessmentItemsFromSources = ({
  assessItems,
  assessItemIdsWithReviews,
  builderId,
  selectedProgCompID,
  selectedProgCompName,
  displayWithScopeTabs,
  user,
  programID,
  handleSave,
  isSavingAssessmentItems,
}) => {

  /*
  Depending on the way the UI is constructed for future program components,
  there may be additional <AddSource???? />  created and plugged in below
 */
  if (
    [...programDocCompNames, ...controlsCompNames, ...programsCompNames, ...complianceCompNames, ...requirementCompNames].includes(
      selectedProgCompName
    )
  ) {
    return (
      <AddSourceItemsDefault
        assessItems={assessItems}
        assessItemIdsWithReviews={assessItemIdsWithReviews}
        builderId={builderId}
        selectedProgCompID={selectedProgCompID}
        selectedProgCompName={selectedProgCompName}
        displayWithScopeTabs={displayWithScopeTabs}
        user={user}
        programID={programID}
        handleSave={handleSave}
        isSavingAssessmentItems={isSavingAssessmentItems}
      />
    );
  } else {
    return "";
  }
};

export default CreateAssessmentItemsFromSources;
