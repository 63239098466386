import moment from "moment";
import { Box, Divider, makeStyles, Paper } from "@material-ui/core";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import { makePossessive } from "utils/stringFuncs";
import classNames from "classnames";
import styleVariables from "styleVariables";
import { downloadFileByRef } from "utils/downloadFile";
import CustomLink from "components/utils/link.component";
import { useCallback, useMemo } from "react";
import Loader from "components/utils/loader.components";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { H4, H5 } from "components/utils/headerV2.component";
import { Delete } from "@material-ui/icons";
import PortfolioService from "services/portfolio.service";
import { ACTION_DELETE_REPORT_DOCS } from "reducers/portfolioReports.reducer";
import { mapObjectArrayByKey } from "utils/arrayOfObjectsHelpers";
import { sessionTypes } from "utils/portfolioConstants";

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "hidden",
    color: theme.palette.text.secondary,
  },
  noBoxShadow: {
    boxShadow: "none"
  },
  containerWide: {
    width: "100%",
  },
  headingTab: {
    padding: "3px 16px",
    background: styleVariables.tertiary1,
    textAlign: "center",
  },
  rowHeading: {
    padding: 0,
    minWidth: 84,
    justifyContent: "flex-end",
  },
  sessionItem: {
    alignItems: "stretch",
    padding: "16px 32px",
    minHeight: 160,
    lineHeight: "20px"
  },
  sessionItemHeader: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    padding: "16px 32px 8px",
  },
  sessionItemRow: {
    alignItems: "start",
    display: "flex",
    marginBottom: 4
  },
  actionDeleteLink: {
    color: theme.palette.error.main,
  },
  actionIcon: {
    verticalAlign: "text-top",
    marginRight: 4,
    fontSize: 14,
  },
  sectionDivider: {
    borderTop: `2px solid ${theme.palette.border.dark}`,
    [theme.breakpoints.only("md")]: {
      borderTopWidth: 1,
    }
  },
  itemDivider: {
    [theme.breakpoints.only("md")]: {
      display: "none"
    }
  },
  avoidPageBreak: {
    "@media print": {
      pageBreakInside: "avoid"
    }
  },
  value: {
    paddingLeft: 8,
  },
  customLink: {
    display: "inline-flex",
    alignItems: "center",
    lineHeight: "normal",
    position: "relative",
    textIndent: 23,
  },
  downloadIcon: {
    fontSize: "1rem",
    position: "absolute",
    left: 0,
    top: "-1px",

  },
}));

export default function SupportingDocsSubsection(props) {
  const classes = useStyles();
  const {
    company, dispatch, documents, editReferenceView, preview = false, sessions
  } = props;

  const sessionsById = useMemo(() => (
    mapObjectArrayByKey(sessions, "Session_ID")
  ), [sessions]);

  const sessionDocuments = useMemo(() => (
    documents?.filter?.(document => {
      const sessionType = sessionsById[document.Session_ID]?.Type;
      return sessionType === sessionTypes.DATA_COLLECTION;
    })
  ), [documents, sessionsById]);

  const reportDocuments = useMemo(() => (
    documents?.filter?.(document => {
      const sessionType = sessionsById[document.Session_ID]?.Type;
      return sessionType === sessionTypes.RECONCILE;
    })
  ), [documents, sessionsById]);

  const handleDelete = useCallback(async documentId => {
    const response = await PortfolioService.deleteSupportingDoc(documentId);
    if (response.payload) {
      dispatch({
        type: ACTION_DELETE_REPORT_DOCS,
        payload: response.payload,
        meta: response.meta
      });
    } else {
      console.error(`Document not found in db? "${documentId}"`);
    }
  }, [dispatch]);

  if (!documents) {
    return <Loader />;
  }
  return (
    <Paper
      className={classNames(
        classes.container,
        classes.avoidPageBreak,
        documents?.length === 1 && !editReferenceView && classes.containerWide,
        editReferenceView && classes.noBoxShadow
      )}
    >
      {!editReferenceView && (
        <div className={classes.headingTab}>
          <H4 color="white">
            {makePossessive(company?.Name)} Artifacts Reviewed
          </H4>
        </div>
      )}
      {documents?.length ? (
        <GridContainer>
          {!!sessionDocuments.length && (
            <GridItem xs={12}>
              <H4 className={classes.sessionItemHeader}>
                Session Documents
              </H4>
            </GridItem>
          )}
          {sessionDocuments.map((document, index, all) => (
            <GridItem
              xs={12}
              md={all.length > 1 ? 6 : 12}
              lg={preview ? 4 : 12}
              key={document.Document_ID}
            >
              {index > 0 && !preview && (
                <Divider className={classes.itemDivider} />
              )}
              <DocumentPanel document={document} />
            </GridItem>
          ))}
          {!!reportDocuments?.length && (
            <GridItem xs={12}>
              {!!sessionDocuments.length && (
                <Divider
                  className={preview ? undefined : classes.sectionDivider}
                />
              )}
              <H4 className={classes.sessionItemHeader}>
                Report Documents
              </H4>
            </GridItem>
          )}
          {reportDocuments.map((document, index, all) => (
            <GridItem
              xs={12}
              md={all.length > 1 ? 6 : 12}
              lg={preview ? 4 : 12}
              key={document.Document_ID}
            >
              {index > 0 && !preview && (
                <Divider className={classes.itemDivider} />
              )}
              <DocumentPanel
                document={document}
                onDelete={
                  editReferenceView ? handleDelete : undefined
                }
              />
            </GridItem>
          ))}
        </GridContainer>
      ) : (
        <Box marginLeft={2} marginRight={2}>
          <p>No artifacts have been uploaded for this company.</p>
        </Box>
      )}
    </Paper>
  );
}

function DocumentPanel(props) {
  const classes = useStyles();
  const { document, onDelete } = props;

  return (
    <div className={classes.sessionItem}>
      <div className={classes.sessionItemRow}>
        <H5 className={classes.rowHeading}>
          Title:
        </H5>
        <Box className={classes.value}>
          <CustomLink
            onClick={() => (
              downloadFileByRef(
                document.File_Ref,
                document.File_Ref
              )
            )}
            className={classes.customLink}
          >
            {document.Document_Title}
            <CloudDownloadIcon className={classes.downloadIcon} />
          </CustomLink>
        </Box>
      </div>
      <div className={classes.sessionItemRow}>
        <H5 className={classes.rowHeading}>
          Version:
        </H5>
        <Box className={classes.value}>{document.Version}</Box>
      </div>
      <div className={classes.sessionItemRow}>
        <H5 className={classes.rowHeading}>
          Date:
        </H5>
        <Box className={classes.value}>
          {moment(document.Start).format("MMMM D, yyyy")}
        </Box>
      </div>
      <div className={classes.sessionItemRow}>
        <H5 className={classes.rowHeading}>
          Approval:
        </H5>
        <Box className={classes.value}>{document.Status}</Box>
      </div>
      <div className={classes.sessionItemRow}>
        <H5 className={classes.rowHeading}>
          Description:
        </H5>
        <Box className={classes.value}>{document.Description || "--"}</Box>
      </div>
      {!!onDelete && (
        <div className={classes.sessionItemRow}>
          <H5 className={classes.rowHeading}>
            Actions:
          </H5>
          <Box className={classNames(classes.value, classes.actionValue)}>
            <CustomLink
              variant="noHRef"
              className={classes.actionDeleteLink}
              onClick={() => onDelete(document.Document_ID)}
              test="delete-artifact"
            >
              <Delete className={classes.actionIcon} />
              <span>Delete</span>
            </CustomLink>
          </Box>
        </div>
      )}
    </div>
  );
}
