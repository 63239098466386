import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Paper } from "@material-ui/core";
import HeaderPrimary from "components/utils/header.component";
import classNames from "classnames";
import AssignAssessmentsTable from "components/assessment/assignAssessmentsTable.component";
import UserService from "services/user.service";
import { currentUserID } from "utils/userHelpers";
import moment from "moment";
import { setStateFetchEffect } from "utils/ajaxHelpers";
import { activeGlobalUsersFilter } from "utils/roles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 20,
  },
  assignmentPaper: {
    width: "90%",
    display: "flex",
    justifyContent: "space-between",
    maxHeight: 700,
    overflow: "auto"
  },
  paperHeader: {
    paddingBottom: 10,
  },
  tableDivider: {
    margin: "15px 0",
  },
  assessorColumn: {
    width: 250,
    display: "flex",
    flexFlow: "column",
    padding: 20,
  },
  setupAssignmentPaper: {
    width: "100%",
    maxWidth: 450,
    minWidth: 200,
    padding: "30px 40px 40px",
  },
}))

const ROLE_LEAD = "lead";
const ROLE_STANDARD = "standard";

const getUserIdsForRole = (assessors, role) => (
  assessors.filter(assessor => assessor.Assessment_Role === role).map(({ User_ID }) => User_ID)
);

const getUsersWithoutRole = (assessors, users, role) => {
  const userIdsForRole = getUserIdsForRole(assessors, role);
  return users.filter(user => !userIdsForRole.includes(user.User_ID));
}

const SetAssessors = ({
  builderInfo,
  formAssessors,
  setFormAssessors,
  dialogFormView,
  handleSave,
}) => {
  const classes = useStyles();
  const [assignee, setAssignee] = useState("");
  const [nonLeadUsers, setNonLeadUsers] = useState([]);
  const [nonStandardUsers, setNonStandardUsers] = useState([]);

  useEffect(
      () =>
        setStateFetchEffect(UserService.getAll(), ([res]) => {
          const allUsers = activeGlobalUsersFilter(res.payload)
          setNonLeadUsers(
            getUsersWithoutRole(formAssessors, allUsers, ROLE_LEAD)
          );
          setNonStandardUsers(
            getUsersWithoutRole(formAssessors, allUsers, ROLE_STANDARD)
          );
        }),
      [formAssessors]
  );

  const leadAssessors = formAssessors.filter(({ Assessment_Role }) => Assessment_Role === ROLE_LEAD);
  const standardAssessors = formAssessors.filter(({ Assessment_Role }) => Assessment_Role === ROLE_STANDARD);


    // Function for builder setup
  const addAssessor = useCallback((event, role) => {
    setAssignee(event.target.value);
    const userToAssign = nonLeadUsers.concat(nonStandardUsers).find((user) => user.User_ID === event.target.value);
    const existingAssessor = formAssessors.find(assessor => assessor.User_ID === event.target.value)
    if (!existingAssessor) {
      const newAssignee = {
        AssessmentAssignment_ID: null,
        AssessmentBuilder_ID: builderInfo.Builder_ID,
        User_ID: userToAssign.User_ID,
        Assessment_Role: role,
        Date_Assigned: moment(new Date()).format("YYYY-MM-DD"),
        First_Name: userToAssign.First_Name,
        Last_Name: userToAssign.Last_Name,
        Change_User: currentUserID(),
        Change_Date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };
      handleSave(5, [newAssignee])
    } else {
      handleSave(5, null, null, [{...existingAssessor, Assessment_Role: role}])
    }
    setAssignee("");
  }, [handleSave, builderInfo.Builder_ID, formAssessors, nonLeadUsers, nonStandardUsers])

    // Function for builder setup
  const removeAssessor = (assessorObj) => {
    handleSave(5, null, [assessorObj])
  }

    // Function for pop-up form in Review
  const addAssessorToForm = useCallback((event, role) => {
    setAssignee(event.target.value);
    const userToAssign = nonLeadUsers.concat(nonStandardUsers).find((user) => user.User_ID === event.target.value);
    if (userToAssign) {
      const newAssignee = {
        AssessmentAssignment_ID: null,
        AssessmentBuilder_ID: builderInfo.Builder_ID,
        User_ID: userToAssign.User_ID,
        Assessment_Role: role,
        Date_Assigned: moment(new Date()).format("YYYY-MM-DD"),
        First_Name: userToAssign.First_Name,
        Last_Name: userToAssign.Last_Name,
      };
      setFormAssessors((prev) => {
        const staleIndex = prev.findIndex(assessor => assessor.User_ID === newAssignee.User_ID);
        if (staleIndex > -1) {
          prev.splice(staleIndex, 1);
        }
        return [...prev, newAssignee];
      });
      setAssignee("");
    }
  }, [builderInfo.Builder_ID, nonLeadUsers, nonStandardUsers, setFormAssessors]);

    // Function for pop-up form in Review
  const removeAssessorFromForm = (assessorObj) => {
    const assessorID = assessorObj.AssessmentAssignment_ID
    const filteredAssessors = [...formAssessors].filter(
            (user) => user.AssessmentAssignment_ID !== assessorID
    );
    setFormAssessors(filteredAssessors);
  }

  const assessorHandler = dialogFormView ? addAssessorToForm : addAssessor;
  const removeFunction = dialogFormView ? removeAssessorFromForm : removeAssessor;


  return (
    <Paper className={classNames(classes.paper, dialogFormView ? classes.assignmentPaper : classes.setupAssignmentPaper)} elevation={dialogFormView ? 0 : undefined} >
      <div className={dialogFormView ? classes.assessorColumn : undefined} data-cy="assess-leads">
        <div className={classNames(classes.paperHeader)}>
          <HeaderPrimary variant="h5Primary">
              Assessment Leads
          </HeaderPrimary>
        </div>
        <AssignAssessmentsTable
          addText="Add Lead"
          assessors={leadAssessors}
          assignableUsers={nonLeadUsers}
          assignee={assignee}
          handleAddAssessor={(event) => assessorHandler(event, ROLE_LEAD)}
          handleRemoveAssessor={removeFunction}
        />
      </div>
      <Divider className={classNames(classes.tableDivider)} />
      <div className={dialogFormView ? classes.assessorColumn : undefined} data-cy="assessors">
        <div className={classNames(classes.paperHeader)}>
          <HeaderPrimary variant="h5Primary">
              Assessors
          </HeaderPrimary>
        </div>
        <AssignAssessmentsTable
          addText="Add Assessor"
          assessors={standardAssessors}
          assignee={assignee}
          assignableUsers={nonStandardUsers}
          handleAddAssessor={(event) => assessorHandler(event, ROLE_STANDARD)}
          handleRemoveAssessor={removeFunction}
        />
      </div>
    </Paper>
  )

}

export default SetAssessors;
