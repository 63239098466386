import moment from "moment";
import { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CustomLink from "components/utils/link.component";
import SortableTable from "components/utils/tables/sortableTable.component";
import Loader from "components/utils/loader.components";


const useStyles = makeStyles((theme) => ({
  runbookChangesTable: {
    overflowX: "unset",
  }
}));

const headers = [{
  name: "Task_ID",
  display: "Task ID",
  sort: true,
  alignment: "center"
}, {
  name: "description",
  display: "Description",
}, {
  name: "Change_Date",
  display: "Date",
  sort: true,
  alignment: "center"
}];

const IGNORE_CHANGE_FIELDS = new Set(
  ["Status_Datetime", "Change_Date", "Change_User"]
);

const makeChangeDescription = runbookTaskChange => {
  const { PrevRowData: previous, NewRowData: current } = runbookTaskChange;
  const wasNull = !previous?.Task_ID;
  const isNull = !current?.Task_ID;
  if (wasNull) {
    if (isNull) {
      console.error("Invalid change found - new and prev are both null");
      return "--";
    }
    return "Added Task";
  } else if (isNull) {
    return "Deleted Task";
  }
  const fieldEntries = Object.entries(previous)
    .filter(([key]) => !IGNORE_CHANGE_FIELDS.has(key))
    .map(([key, prevValue]) => ( [key, prevValue, current[key]]));

  const changedEntries = fieldEntries.filter(([key, prevValue, currValue]) => (
    prevValue !== currValue
  ));
  if (changedEntries.length === 1) {
    const [fieldName, previousValue, currentValue] = changedEntries[0];

    let actionDisplay = null;
    const isIdField = fieldName.endsWith("_ID");
    const displayField = fieldName.replace(/_ID$/, "").replaceAll("_", " ");

    if (previousValue === 0 && currentValue === 1) {
      actionDisplay = "Checked";
    } else if (previousValue === 1 && currentValue === 0) {
      actionDisplay = "Unchecked";
    } else if (previousValue && currentValue) {
      actionDisplay = "Changed";
    } else if (!previousValue) {
      actionDisplay = "Added";
    } else {
      actionDisplay = "Removed";
    }
    return `${actionDisplay} "${displayField}" ${isIdField ? "" : " Field"}`;
  } else if (changedEntries.length > 1) {
    // Exceptional case, should not be shown
    console.warn(
      "Found changed task entry with multiple differences:",
      changedEntries.map(([key]) => key)
    );
    return "Changed multiple fields";
  }
  return null;
}


export default function TaskChangesTable(props) {
  const { onTaskIdClick, taskChanges } = props;
  const classes = useStyles();

  const tableRows = useMemo(() => (
    taskChanges && taskChanges.map(taskChange => ({
      Task_ID: {
        value: taskChange.Task_ID,
        node: !onTaskIdClick ? null : (
          <CustomLink
            variant="noHRef"
            onClick={event => onTaskIdClick(taskChange.Task_ID, event)}
          >
            {taskChange.Task_ID}
          </CustomLink>
        )
      },
      description: makeChangeDescription(taskChange),
      Change_Date: moment(taskChange.Change_Date).format("MM/DD/YYYY, hh:mm:ss A")
    }))
  ), [onTaskIdClick, taskChanges]);

  if (!taskChanges) {
    return (
      <Loader />
    );
  }
  return (
    <SortableTable
      headers={headers}
      data={tableRows}
      defaultSortBy="Change_Date"
      pagination={{ rowsPerPageOptions: [5, 10, 25] }}
      defaultSortDescending
      denseHeader
      denseBody
      className={classes.runbookChangesTable}
    />
  );
}
