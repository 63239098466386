import { useEffect, useMemo, useState } from "react";
import RiskAssessmentService from "services/riskAssessment.service";
import { dataCollectionTypeIds } from "utils/assessmentConstants";
import { tallyReviewStatus } from "utils/assessmentHelpers";
import ReviewReport from "./review/reviewReport.component";


const reviewTypesWithPerformance = [
  dataCollectionTypeIds.DATA_COLLECTION_TYPE_PERFORMANCE,
  dataCollectionTypeIds.DATA_COLLECTION_TYPE_ALL
];
const reviewTypesWithDesign = [
  dataCollectionTypeIds.DATA_COLLECTION_TYPE_DESIGN,
  dataCollectionTypeIds.DATA_COLLECTION_TYPE_ALL
];


export default function CompletedReportPage(props) {
  const { assessors, builderInfo } = props;

  const [assessmentItems, setAssessmentItems] = useState(null);
  const [designItems, setDesignItems] = useState(null);
  const [performanceItems, setPerformanceItems] = useState(null);
  const [references, setReferences] = useState(null);

  useEffect(() => {
    (async function fetchReportData() {
      if (!builderInfo.Builder_ID) {
        return;
      }
      const [
        assessmentItemsResponse,
        referencesResponse,
        reviewResponse
      ] = await Promise.all([
        RiskAssessmentService.getAssessmentItemsByBuilderID(builderInfo.Builder_ID),
        RiskAssessmentService.readAssessmentReferenceListByBuilderID(builderInfo.Builder_ID),
        RiskAssessmentService.getAssessmentDataCollectionByBuilderID(builderInfo.Builder_ID),
      ]);
      setAssessmentItems(assessmentItemsResponse.items);
      setDesignItems(reviewResponse.payload.design || []);
      setPerformanceItems(reviewResponse.payload.performance || []);
      setReferences(referencesResponse.payload.assessmentReferences);
    })();
  }, [builderInfo.Builder_ID]);

  const hasDesign = useMemo(() =>
    reviewTypesWithDesign.includes(builderInfo.Review_Type), [builderInfo]
  );
  const hasPerformance = useMemo(() =>
    reviewTypesWithPerformance.includes(builderInfo.Review_Type), [builderInfo]
  );

  const designSummary = useMemo(() => (
    hasDesign && designItems ? tallyReviewStatus(designItems, "design") : null
  ), [designItems, hasDesign]);

  const performanceSummary = useMemo(() => {
    if (!hasPerformance || !performanceItems) {
      return null;
    }
    const reviewsByItem = performanceItems.reduce((accumulator, review) => ([
      ...accumulator,
      (accumulator[review.AssessmentItem_ID] || []).concat(review)
    ]), []);
    return tallyReviewStatus(reviewsByItem, "performanceItems");
  }, [performanceItems, hasPerformance]);

  return (
    <ReviewReport
      assessmentItems={assessmentItems}
      assessors={assessors}
      builderInfo={builderInfo}
      designItems={designItems}
      designSummary={designSummary}
      performanceItems={performanceItems}
      performanceSummary={performanceSummary}
      references={references}
      isPage
    />
  );
}