function importAll(imports) {
  const siteImages = {};
  imports.keys().map((item, index) => {
    siteImages[item.replace('./', '').replace('.jpg', '').replace('.jpeg', '').replace('.png', '').replace('.svg', '')] = imports(item);
    return null;
  });
  return siteImages;
}

const images = importAll(require.context('./', false, /\.(png|jpe?g|svg)$/));

export default images;