import WorkpackageService from "services/workpackage.service";

const taskAndWpIDFieldNames = {
  task: "Task_ID",
  workpackage: "Workpackage_ID"
}

const taskStatuses = {
  NOT_STARTED: "Not Started",
  STARTED: "Started",
  COMPLETED: "Completed"
}

const isTaskOrWorkpackage = (obj) => {
  return obj[taskAndWpIDFieldNames.task] ? "task" : "workpackage";
};

const findTaskAndWpSearchResults = (workpackages, tasks) => {
  return [...workpackages, ...tasks].map(result => {
    const type = isTaskOrWorkpackage(result)
    const idFieldName = taskAndWpIDFieldNames[type]
    return {[idFieldName]: result[idFieldName]}
  })
}

const updateOrCreateWorkpackage = async (formMode, workpackage, workpackageIdToUpdate) => {
  if(formMode === "create") {
    const createRes = await WorkpackageService.saveNewWorkpackage(workpackage)
    return createRes.payload;
  } else {
    const updateResponse = await WorkpackageService.updateWorkpackage(
      workpackage,
      workpackageIdToUpdate
    );
    return updateResponse.payload;
  }
}

export { taskAndWpIDFieldNames, isTaskOrWorkpackage, findTaskAndWpSearchResults, taskStatuses, updateOrCreateWorkpackage };
