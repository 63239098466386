import { makeStyles, MenuItem, Select } from "@material-ui/core";
import EditableInputCellWrapper, { boldLetterSpacingDifference } from "./editableInputCellWrapper.component";
import { useCallback } from "react";
import styleVariables from "styleVariables";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  displayLabel: {
    whiteSpace: "nowrap"
  },
  inputIcon: {
    fill: "#000"
  },
  inputWrapper: {
    width: "100%"
  },
  selectInput: {
    width: "100%",
    fontSize: styleVariables.fontSmall,
    letterSpacing: 0
  },
  selectItem: {
    fontSize: styleVariables.fontSmall,
    letterSpacing: boldLetterSpacingDifference / 2
  },
  selectMenu: {
    marginLeft: -16
  }
}));


export default function EditableSelectCell(props) {
  const classes = useStyles();
  const {
    name, defaultValue, options, align, onAutosave, validations
  } = props;

  const renderDisplayLabel = useCallback(value => {
    if (!options?.length) {
      return "N/A";
    }
    if (value) {
      let label = options.find(option => option.value === value)?.label;
      if (!label) {
        console.error(
          `Unable to find match for selected label: '${value}'`,
          options
        );
        label = "--";
      }
      return (
        <span className={classes.displayLabel}>
          {label}
        </span>
      );
    }
    return null;
  }, [classes, options]);

  const handleChange = useCallback((event, autosaveFn, onChange, setIsActive) => {
    onChange(event);
    autosaveFn(event.target.name, event.target.value, event);
    setIsActive(false);
  }, []);

  if (!options) {
    return "N/A";
  }
  return (
    <EditableInputCellWrapper
      name={name}
      defaultValue={defaultValue}
      renderLinkDisplay={renderDisplayLabel}
      align={align}
      className={classes.inputWrapper}
      disabled={!options?.length}
      onAutosave={onAutosave}
      validations={validations}
    >
      {selectProps => (
        <Select
          name={selectProps.name}
          value={selectProps.value || ""}
          onChange={event => (
            handleChange(
            event,
            selectProps.onAutosave,
            selectProps.onChange,
            selectProps.setIsActive
            )
          )}
          onClose={() => selectProps.setIsActive(false)}
          className={classNames(classes.selectInput, selectProps.className)}
          classes={{ icon: classes.inputIcon }}
          MenuProps={{ className: classes.selectMenu }}
          autoFocus
          open
        >
          {options.map(option => (
            <MenuItem
              key={option.label}
              value={option.value}
              className={classes.selectItem}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
    </EditableInputCellWrapper>
  );
}
