import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CustomModal from "components/utils/modal.component";
import CustomCard from "components/utils/card.component";
import RemoveIcon from "components/utils/removeIcon.component";
import AddIcon from "components/utils/addIcon.component";
import HeaderPrimary from "components/utils/header.component";
import variables from "styleVariables";
import { practiceTerminologyMap } from "utils/controlsConstants.js";
import classNames from "classnames";
import { sortByStringKey } from "utils/sortingFuncs";
import PolicyRegulationsTable from "components/builder/policy/policyRegulationsTable.component";
import { Box } from "@material-ui/core";
import { controlItemsMatch } from "utils/controlsHelpers";
import { ACTION_UPDATE_REFERENCE_ITEM_STATUS } from "reducers/builder.reducer";

const useStyles = makeStyles((theme) => ({
  regsTableWrapper: {
    paddingTop: 15,
  },
  hiddenTable: {
    display: "none",
  },
  cellContentWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  cellContents: {
    display: "flex",
    width: 100,
    justifyContent: "flex-end",
    fontSize: variables.fontSmall,
    color: variables.textSecondary,
  },
  cellContentsRO: {
    display: "flex",
    width: 100,
    fontSize: variables.fontSmall,
    color: variables.textSecondary,
  },
  strikethrough: {
    textDecoration: "line-through",
  },
  cellHeader: {
    padding: 0,
    marginRight: 10,
    "&:hover": {
      cursor: "pointer",
    },
  },
  cardWrapper: {
    minWidth: 400,
    maxWidth: 520,
    maxHeight: 690,
  },
}));

const cardLabel = {
  NIST171: "NIST 800-171",
  ControlDetail: "NIST 800-53",
  CMMCPractices: "CMMC",
  CSF: "CSF",
};

const CellDisplay = (props) => {
  const classes = useStyles();
  if (props.readOnly) {
    return (
      <div className={classes.cellContentWrapper}>
        <div className={classes.cellContentsRO}>
          <HeaderPrimary
            variant={props.disabled ? "h6Tertiary" : "h6Primary"}
            className={classNames(
              classes.cellHeader,
              props.disabled && classes.strikethrough
            )}
          >
            <span onClick={props.infoClick}>{props.text}</span>
          </HeaderPrimary>
        </div>
      </div>
    );
  } else {
    const practiceDisplay = practiceTerminologyMap[props.regSource];
    return (
      <div className={classes.cellContentWrapper}>
        <div className={classes.cellContents}>
          <HeaderPrimary
            variant={props.disabled ? "h6Tertiary" : "h6Primary"}
            className={classNames(
              classes.cellHeader,
              props.disabled && classes.strikethrough
            )}
          >
            <span onClick={props.infoClick} data-cy={`infoClick-${props.text}`}>
              {props.text}
            </span>
          </HeaderPrimary>
          {props.disabled ? (
            <AddIcon
              onClick={props.onClick}
              variant="hoverText"
              test={props.text}
            >
              {`ADD ${practiceDisplay}`}
            </AddIcon>
          ) : (
            <RemoveIcon
              onClick={props.onClick}
              variant="hoverText"
              test={props.text}
            >
              {`REMOVE ${practiceDisplay}`}
            </RemoveIcon>
          )}
        </div>
      </div>
    );
  }
};

const HiddenCell = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.cellContentWrapper}>
      <div className={classes.cellContents}>
        <HeaderPrimary
          variant="h6Primary"
          className={classNames(classes.cellHeader)}
        >
          {props.text}
        </HeaderPrimary>
      </div>
    </div>
  );
};

const PolicyRegsTableSection = (props) => {
  const classes = useStyles();
  const [headers, setHeaders] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [hiddenTableData, setHiddenTableData] = useState([]);
  //for popover
  const [popupText, setPopupText] = useState({ title: "", name: "", text: "" });
  //MODAL
  const [editModal, setEditModal] = useState(false);
  const { dispatch } = props;

  const handleClose = () => {
    setEditModal(false);
    setPopupText({ title: "", text: "" });
  };

  const handleOpen = (cellInfo) => {
    setEditModal(true);
    setPopupText({
      title: (
        <>
          <span
            style={{
              color: variables.secondaryMain,
              paddingRight: 5,
              fontWeight: "bold",
            }}
          >{`${cardLabel[cellInfo.Reg_Source]}`}
          </span>{" "}
          <span
            style={{
              color: variables.primaryMain,
              paddingBottom: 5,
              fontWeight: "bold",
            }}
          >
            {cellInfo.SubGroupName}
          </span>
        </>
      ),
      name: (
        <span
          style={{
            color: variables.primaryMain,
            paddingBottom: 5,
            fontWeight: "bold",
          }}
        >
          {cellInfo.Name}
        </span>
      ),
      text: cellInfo.Detail || cellInfo.Content,
    });
  };

  useEffect(() => {
    if (props.referenceSubGroups.length && props.referenceGroups.length) {
      // FUNCTION FOR STATUS TOGGLE
      const statusHandler = (selectedPractice) => {
        const updatePractices = [...props.referenceSubGroups];
        const practiceIndex = updatePractices.findIndex((prac) =>
          controlItemsMatch(prac, selectedPractice)
        );
        let practice = updatePractices[practiceIndex];

        if (practice.Status === "Active") {
          practice = { ...practice, Status: "Disabled" };
        } else {
          practice = { ...practice, Status: "Active" };
        }
        updatePractices[practiceIndex] = practice;
        dispatch({
          type: ACTION_UPDATE_REFERENCE_ITEM_STATUS,
          payload: updatePractices,
        });
      };

      // FUNCTION TO ORGANIZE PRACTICES FOR HIDDENTABLE
      const removeDisabledPractices = (objOfArrays) => (
        Object.fromEntries(
          Object.entries(objOfArrays).map(([key, array]) => (
            [key, array.filter(item => item.Status === "Active")]
          ))
        )
      );

      // FUNCTION TO FIND MAX length column of practices
      const longest = (objOfArrays) => {
        let max = 0;
        for (const value of frameKeys) {
          if (objOfArrays[value].length > max) {
            max = objOfArrays[value].length;
          }
        }
        return max;
      };

      //FUNCTION to remove nested ternary statements from makeRows function
      const checkMode = (cellInfo, mode) => {
        return mode === "default" ? (
          <CellDisplay
            onClick={() => statusHandler(cellInfo)}
            infoClick={() => handleOpen(cellInfo)}
            text={cellInfo.SubGroupName}
            regSource={cellInfo.Reg_Source}
            disabled={cellInfo.Status !== "Active"}
            readOnly={props.readOnly}
          />
        ) : (
          <HiddenCell text={cellInfo.SubGroupName} />
        );
      };

      // FUNCTION REFORMATS pracByFrame && pracByFrameHidden TO DATA FOR CustomTable
      const makeRows = (objOfArrays, maxLength, mode) => {
        const rows = [];
        for (let i = 0; i < maxLength; i++) {
          let row = {};
          for (const value of frameKeys) {
            const cellInfo = objOfArrays[value][i];
            row = {
              ...row,
              [value]:
                cellInfo && cellInfo.SubGroupName
                  ? checkMode(cellInfo, mode)
                  : "",
            };
          }
          rows.push(row);
        }
        return rows;
      };

      let pracByFrameIds = {}; // Eventually forms an object ex. {1: [...practices], 2: [...practices]}
      let frameKeys;
      if (props.referenceGroups.length) {
        const headerObjs = [];

        props.referenceGroups.forEach((frame) => {
          const frameKey = `${frame.RegFramework_id}`;
          pracByFrameIds = { ...pracByFrameIds, [frameKey]: [] };
          // CREATE HEADERS
          if (
            frame.FrameworkName === "CMMC" &&
            !headerObjs.some((header) => header.id === frame.RegFramework_id)
          ) {
            headerObjs.push({
              id: frame.RegFramework_id,
              label: `${frame.FrameworkName} v.${frame.Version}`,
            });
          } else if (
            frame.FrameworkName !== "CMMC" &&
            !headerObjs.some((header) => header.id === frame.RegFramework_id)
          ) {
            headerObjs.push({
              id: frame.RegFramework_id,
              label: `${frame.FrameworkName} ${frame.Version}`,
            });
          }
        });
        const orderedHeaders = sortByStringKey(headerObjs, "label");
        frameKeys = Object.keys(pracByFrameIds);
        setHeaders(orderedHeaders);
      }
      props.referenceSubGroups.forEach((practice) => {
        pracByFrameIds[practice.RegFramework_ID]?.push(practice);
      });
      const pracByFrameIdsHidden = removeDisabledPractices({ ...pracByFrameIds });

      // FIND length of column with most practices
      const longestRow = longest(pracByFrameIds);
      const longestRowHidden = longest(pracByFrameIdsHidden);

      setTableData(makeRows(pracByFrameIds, longestRow, "default"));
      setHiddenTableData(
        makeRows(pracByFrameIdsHidden, longestRowHidden, "hidden")
      );
    }
  }, [props, dispatch]);

  if (headers && tableData) {
    return (
      <>
        <div className={classes.regsTableWrapper}>
          <PolicyRegulationsTable
            headers={headers}
            data={tableData}
            tableId="regRefs"
            test="RegRefs"
          />
        </div>
        {/* ==== HIDDEN TABLE ==== */}
        <div className={classes.hiddenTable}>
          <PolicyRegulationsTable
            headers={headers}
            data={hiddenTableData}
            tableId="hiddenRegsTable"
          />
        </div>
        <CustomModal open={editModal} onClose={handleClose}>
          {!!popupText && (
            <div className={classes.cardWrapper} data-cy="practice-card">
              <CustomCard variant="plain">
                <Box display="flex" flexDirection="column">
                  {popupText.title}
                  {popupText.name}
                  {popupText.text}
                </Box>
              </CustomCard>
            </div>
          )}
        </CustomModal>
      </>
    );
  } else {
    return "";
  }
};

export default PolicyRegsTableSection;
