import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormBanner from "components/utils/form-elements/formBanner.component";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import { useCallback } from "react";
import Form from "components/utils/form-elements/form.component";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: 500
  },
  form: {
    padding: "15px 30px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  version: {
    maxWidth: "33%"
  }
}));

const CreateManualForm = ({
  setEditModal,
  handleConfirm
}) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({title: "", version: ""})

  const handleChange = useCallback((event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    })
  }, [formData])

  return (
    <div className={classes.formContainer}>
      <FormBanner test="cert-manuals">
        Create New Manual
      </FormBanner>
      <Form className={classes.form}>
        <LabelInput
          margin="dense"
          variant="default"
          label="Title"
          required
          onChange={(event) => handleChange(event)}
          value={formData.title}
          id="title"
          name="title"
          test="Title"
          size="small"
        />
        <div className={classes.version}>
          <LabelInput
            margin="dense"
            variant="default"
            label="Version"
            required
            onChange={(event) => handleChange(event)}
            value={formData.version}
            id="version"
            name="version"
            test="Version"
            size="small"
          />
        </div>
        <DualFormButtons
          disabled={!formData.title || !formData.version}
          addText="Create"
          cancelOnClick={() => setEditModal(false)}
          saveOnClick={() => handleConfirm(formData)}
        />
      </Form>
    </div>
  );
};

export default CreateManualForm;
