import { useCallback, useMemo } from "react";
import DataTable from "components/utils/tables/dataTable.component";
import variables from "styleVariables";
import RoadmapItemCell from 'components/assessment/roadmap/tables/cells/roadmapItemCell.component'
import TaskWorkpageCell from 'components/assessment/roadmap/tables/cells/taskWorkpageCell.component'
import DisplayHTML from "components/utils/displayHTML.component";
import { EmptyCellValue } from "components/utils/tables/emptyCellValue.component";

import { reviewPageModes } from "utils/assessmentConstants";
import { createNodeCellParams, createNodeHeaderParams, createValueHeaderParams } from "components/utils/tables/utils/dataTableHelpers";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  cellFixedExtraNarrow: {
    maxWidth: 120,
    minWidth: 120,
  },
  cellFixedNarrow: {
    maxWidth: 150,
    minWidth: 150
  },
  cellFixedNormal: {
    maxWidth: 175,
    minWidth: 175
  },
  cellFixedWide: {
    maxWidth: 225,
    minWidth: 225
  },
  cellNotes: {
    minWidth: 150
  },
  table: {
    wordBreak: "normal"
  }
}))


/*
 * Data table for Roadmap Items, Responses, Roadmap Description, Target Completions, Workpackages/task,
 * status and notes for all recommendations made on an assessment
 * A row can have Design, Performance or Summary Recommendation or Finding.
 *
 */


const RoadmapItemsTable = (props) => {
  const classes = useStyles();

  const {
    activeRoadmapItem,
    onSuccessVariable,
    rowData,
    roadmapItems,
    setActiveRoadmapItem,
    setOnSuccessVariable,
    setReviewPageMode,
    setRoadmapItems
  } = props;

  const tableHeaders = useMemo(() => ([
    createValueHeaderParams("assessmentItemID", "ID", {
      align: "center",
      filter: true,
      setCellHeaderProps: () => ({
        "data-cy": "roadmap-table-assessmentItemID-header"
      }),
      setCellProps: () => ({ align: "center" }),
    }),
    createValueHeaderParams("assessmentItemType", "Assessment", {
      align: "center",
      filter: true,
      setCellHeaderProps: () => ({
        "data-cy": "roadmap-table-assessmentItemType-header"
      }),
      setCellProps: () => ({ align: "center" }),
    }),
    createValueHeaderParams("assessmentDescription", "Description", {
      align: "center",
      sort: false,
      filter: false,
      setCellHeaderProps: () => ({
        className: classes.cellFixedNarrow,
        "data-cy": "roadmap-table-description-header"
      }),
      setCellProps: () => ({ align: "center" }),
    }),
    createValueHeaderParams("type", "Type", {
      align: "center",
      filter: true,
      setCellHeaderProps: () => ({ "data-cy": "roadmap-table-type-header" }),
      setCellProps: () => ({ align: "center" }),
    }),
    createNodeHeaderParams("recommendations", "Recommendation", {
      filter: false,
      setCellHeaderProps: () => ({
        className: classes.cellFixedWide,
        "data-cy": "roadmap-table-recommendations-header"
      }),
    }),
    createNodeHeaderParams("response", "Response", {
      filter: false,
      setCellHeaderProps: () => ({
        className: classes.cellFixedWide,
        "data-cy": "roadmap-table-response-header"
      }),
    }),
    createNodeHeaderParams("roadmapDescription", "Roadmap Description", {
      align: "center",
      filter: false,
      setCellHeaderProps: () => ({
        className: classes.cellFixedNormal,
        "data-cy": "roadmap-table-roadmapDescription-header",
      }),
      setCellProps: () => ({ align: "center" }),
    }),
    createValueHeaderParams("targetCompletion", "Target Completion", {
      align: "center",
      filter: true,
      setCellHeaderProps: () => ({
        className: classes.cellFixedExtraNarrow,
        "data-cy": "roadmap-table-targetCompletion-header",
      }),
      setCellProps: () => ({ align: "center" }),
    }),
    createNodeHeaderParams("tasks", "Workpackages & Tasks", {
      align: "center",
      filter: false,
      setCellHeaderProps: () => ({
        "data-cy": "roadmap-table-workpackages-header",
      }),
      setCellProps: () => ({ align: "center" }),
    }),
    createValueHeaderParams("status", "Status", {
      align: "center",
      filter: true,
      setCellHeaderProps: () => ({
        className: classes.cellFixedExtraNarrow,
        "data-cy": "roadmap-table-status-header"
      }),
      setCellProps: () => ({ align: "center", }),
    }),
    createValueHeaderParams("notes", "Notes", {
      align: "center",
      filter: false,
      setCellHeaderProps: () => ({
        className: classes.cellNotes,
        "data-cy": "roadmap-table-notes-header"
      }),
      setCellProps: () => ({ align: "center" }),
    })
  ]), [classes]);

  const replaceRoadmapItem = useCallback((item, index) => {
    const newRoadmapItems = [...roadmapItems];
    newRoadmapItems.splice(index, 1, item);
    setRoadmapItems(newRoadmapItems);
    setReviewPageMode(reviewPageModes.ROADMAP_EDIT, item.Roadmap_ID);
  }, [roadmapItems, setRoadmapItems, setReviewPageMode]);

  const tableCells = useMemo(() => {
    return rowData?.map((row, index) => {
      const modeStatusProps = {
        row,
        replaceRoadmapItem,
        roadmapItems,
        setRoadmapItems,
        setReviewPageMode,
        activeRoadmapItem,
        setActiveRoadmapItem,
        setOnSuccessVariable,
        onSuccessVariable
      };

      const tasks = (row.Tasks || []).map(task => (
        task.type === 'task' ?
          `Task #${task.Task_ID}-${task.Status || "Not-Started"}` :
          task.Name
      )).join(", ");

      return {
        assessmentItemID: row.AssessmentItem_ID || "--",
        assessmentItemType: row.AssessmentItem || "Custom",
        assessmentDescription: row.Description,
        type: row.Type,
        recommendations: createNodeCellParams(
          null,
          row.FindingOrRecommendation,
          row.FindingOrRecommendation ? (
            <DisplayHTML html={row.FindingOrRecommendation} />
          ) : (
            <EmptyCellValue />
          )
        ),
        response: createNodeCellParams(null, row.Response, row.Response ? (
          <DisplayHTML html={row.Response} />
        ) : (
          <EmptyCellValue />
        )),
        roadmapDescription: createNodeCellParams(
          row.RoadmapDescription,
          row.RoadmapDescription,
          (
            <RoadmapItemCell {...modeStatusProps} />
          )
        ),
        targetCompletion: row.TargetCompletion || "--",
        tasks: createNodeCellParams(tasks, tasks,
          (
            <TaskWorkpageCell {...modeStatusProps} />
          )
        ),
        status: row.RoadmapStatus || "--",
        notes: row.RoadmapNotes || "--"
      }
    });
  }, [
    rowData, activeRoadmapItem, onSuccessVariable, replaceRoadmapItem,
    roadmapItems, setActiveRoadmapItem, setOnSuccessVariable,
    setReviewPageMode, setRoadmapItems
  ]);


  return (
    <div className={classes.table}>
      <DataTable
        data={tableCells}
        columns={tableHeaders}
        options={{
          filterType: "checkbox",
          fixedHeader: true,
          fixedSelectColumn: true,
          filter: true,
          pagination: false,
          selectableRowsHideCheckboxes: true,
          viewColumns: false,
          tableBodyMaxHeight: "calc(60vh)",
          tableBodyHeight: "calc(60vh)",
          responsive: "simple"
        }}
        fontSize={variables.fontSmall}
      />
    </div>
  );
}

export default RoadmapItemsTable;