import {React, useEffect, useState, useMemo} from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { currentUserToken, currentUserInfo } from "utils/userHelpers";

const jwt = require("jsonwebtoken");

const AuthenticatedRoute = (props) => {
  const [timeout, setTimeout] = useState(false);
  const currentUser = useMemo(currentUserInfo, []);
  const location = useLocation();

  useEffect(() => {
    const timer = setInterval(() => {
      const accessToken = currentUserToken();
      const currentTime = Math.floor(new Date().getTime() / 1000);
      if (!accessToken || jwt.decode(accessToken).exp < currentTime) {
        setTimeout(true);
      }
    }, 10000);

    return () => clearInterval(timer)
  }, []);

  if (!currentUser) {
    if (location.state?.requestLocation) {
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              message: "Please sign in to access the requested page",
              requestLocation: location.state?.requestLocation
            }
          }}
        />
      );
    } else {
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              message: "Please sign in to access the requested page",
            }
          }}
        />
      )
    }
  } else if (timeout) {
    return (
      <Redirect
        to={{
          pathname: "/logout",
          state: {
            message: "Session Expired"
          }
        }}
      />
    )
  } else if (location.state?.requestLocation) {
    let path = location.state.requestLocation;
    path = path.split(`/program/`)[1]
    return <Redirect to={`/program/${path}`} />;
  } else {
    return <Route {...props} />
  }
};

export default AuthenticatedRoute;