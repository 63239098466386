import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  label: {
    color: theme.palette.text.secondary
  }
}));

export default function EmptyFilterLabel({ children }) {
  const classes = useStyles();
  return (
    <span className={classes.label}>
      {children || "(None)"}
    </span>
  );
}
