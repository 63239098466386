import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/utils/header.component";
import CustomSunEditor from "components/utils/sunEditor.component";
import DisplayHTML from "components/utils/displayHTML.component";
import { ReportPageSection } from "./reportPageSection.component";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  editContentContainer: {
    paddingBottom: 20,
    "&:last-of-type": {
      paddingBottom: 10,
    },
  },
  readContentContainer: {
    padding: "10px 32px"
  },
  readContent: {
    padding: "2px 0px 0px 0px",
  },
  editableContent: {
    padding: "8px 0px 8px 0px",
  },
  avoidPageBreak: {
    "@media print": {
      pageBreakInside: "avoid"
    }
  }
}));

const DefaultSection = ({ onSubsectionAutosave, readOnly = false, section }) => {
  return (
    <ReportPageSection title={section.Section_Header}>
      {section._associations.ReportSubSections.map(subsection => (
        <React.Fragment key={subsection.ReportSubSection_ID}>
          <ReportContent
            subsection={subsection}
            readOnly={readOnly}
            onAutosave={onSubsectionAutosave}
          />
        </React.Fragment>
      ))}
    </ReportPageSection>
  );
};

const ReportContent = ({ onAutosave, subsection, readOnly }) => {
  const classes = useStyles();

  return (
    <div className={classNames((readOnly ? classes.readContentContainer : classes.editContentContainer), classes.avoidPageBreak)}>
      <div>
        <Header variant="h4Primary">{subsection.Section_Header}</Header>
      </div>

      <div
        className={!readOnly ? classes.editableContent : classes.readContent}
      >
        {readOnly ? (
          <DisplayHTML html={subsection.Section_Content} />
        ) : (
          <CustomSunEditor
            defaultValue={subsection.Section_Content}
            height="auto"
            name={`ReportSubSections__${subsection.ReportSubSection_ID}`}
            onAutosave={(_name, value) => (
              onAutosave(
                "Section_Content",
                value,
                subsection
              )
            )}
          />
        )}
      </div>
    </div>
  );
};

export default DefaultSection;
