import React, { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TableCell from "components/utils/tables/shared/tableCell.component";
import TableSortLabel from "components/utils/tableSortLabel.component";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import variables from "styleVariables";
import HeaderPrimary from "components/utils/header.component.js";
import CustomLink from "components/utils/link.component";
import AddListitem from "components/utils/addListItem.component";
import CustomModal from "components/utils/modal.component";
import TaskForm from "components/forms/taskForm.component";
import { getComparator, stableSort } from "utils/tableSorts";
/*
 Displays all program tasks (not subtasks)
*/

import moment from "moment";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    overFlowX: "hidden",
    maxHeight: 430,
    [theme.breakpoints.down("md")]: {
      maxHeight: 292,
    },
  },
  tableSmallPrimaryHeaderCell: {
    backgroundColor: variables.tertiary1,
    color: "white",
    fontWeight: "bold",
    fontSize: variables.fontLarge,
    padding: "10px 25px",
    lineHeight: "normal",
    [theme.breakpoints.down("md")]: {
      fontSize: variables.fontMedium,
    },
  },
  tableSmallBodyCell: {
    padding: "10px 25px",
    color: variables.textSecondary,
    fontSize: variables.fontSmall,
    [theme.breakpoints.down("md")]: {
      fontSize: variables.fontXs,
    },
  },
  centerAlign: {
    textAlign: "center",
  },
  leftAlign: {
    textAlign: "left",
  },
  headerWrapper: {
    marginBottom: 10,
  },
  addWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 20,
    paddingRight: 15
  },
  addEmptyWrapper: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 3,
  },
}));

const TasksDashTable = ({ tableData, setRefresh, setWpDashRefresh, programId , programName}) => {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(tableData.length > 0 ? "Name" : "");
  //MODAL
  const [editModal, setEditModal] = useState(false);

  const toggleForm = () => {
    setEditModal(!editModal);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const openForm = () => {
    setEditModal(true)
  }

  const pathFinder = (row) => {
    if (row.Workpackages_Workpackage_ID) {
      return {
        pathname: `/wpTasks/${row.Workpackages_Workpackage_ID}/program/${programId}`,
        state: { taskID: row.Task_ID }
      }
    } else {
      return {
        pathname:`/programTasks/${row.Task_ID}/program/${programId}`,
        state: { taskID: row.Task_ID }
      }
    }
  }

  return (
    <>
      <div className={classes.headerWrapper}>
        <HeaderPrimary variant="h3Primary">{programId === "8" ? `Enterprise` : `Program`} Tasks</HeaderPrimary>
      </div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table data-cy="workpackage-table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                className={classNames(
                  classes.tableSmallPrimaryHeaderCell,
                  classes.leftAlign
                )}
              >
                <TableSortLabel
                  active={orderBy === "Name"}
                  direction={orderBy === "Name" ? order : "asc"}
                  onClick={createSortHandler("Name")}
                  align="left"
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                className={classNames(
                  classes.tableSmallPrimaryHeaderCell,
                  classes.centerAlign
                )}
              >
                <TableSortLabel
                  active={orderBy === "Workpackage"}
                  direction={orderBy === "Workpackage" ? order : "asc"}
                  onClick={createSortHandler("WPName")}
                >
                  Workpackage
                </TableSortLabel>
              </TableCell>
              <TableCell
                className={classNames(
                  classes.tableSmallPrimaryHeaderCell,
                  classes.centerAlign
                )}
              >
                <TableSortLabel
                  active={orderBy === "Proposed_Start"}
                  direction={orderBy === "Proposed_Start" ? order : "asc"}
                  onClick={createSortHandler("Proposed_Start")}
                >
                  Proposed Start
                </TableSortLabel>
              </TableCell>

              <TableCell
                className={classNames(
                  classes.tableSmallPrimaryHeaderCell,
                  classes.centerAlign
                )}
              >
                <TableSortLabel
                  active={orderBy === "Proposed_Completion"}
                  direction={orderBy === "Proposed_Completion" ? order : "asc"}
                  onClick={createSortHandler("Proposed_Completion")}
                >
                  Proposed Completion
                </TableSortLabel>
              </TableCell>
              <TableCell
                className={classNames(
                  classes.tableSmallPrimaryHeaderCell,
                  classes.centerAlign
                )}
              >
                <TableSortLabel
                  active={orderBy === "Status"}
                  direction={orderBy === "Status" ? order : "asc"}
                  onClick={createSortHandler("Status")}
                >
                  Status
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          {(!tableData || tableData.length === 0) && (
            <TableBody>
              <TableRow>
                <TableCell
                  className={classes.tableSmallBodyCell}
                  colSpan="5"
                  component="th"
                  scope="row"
                >
                  <div className={classes.addEmptyWrapper}>
                    <AddListitem
                      dark
                      text="Add New Task"
                      onClick={() => openForm()}
                      test="Task"
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {!!tableData && tableData.length > 0 && (
            <TableBody>
              {stableSort(tableData, getComparator(order, orderBy)).map(
                (row, index) => (
                  <TableRow key={row.Task_ID}>
                    <TableCell
                      className={classNames(
                        classes.tableSmallBodyCell,
                        classes.leftAlign
                      )}
                      component="th"
                      scope="row"
                    >
                      <CustomLink
                        variant="routerLink"
                        to={pathFinder(row)}
                        test={row.Name}
                      >
                        {row.Name}
                      </CustomLink>
                    </TableCell>
                    <TableCell
                      className={classNames(
                        classes.tableSmallBodyCell,
                        classes.centerAlign
                      )}
                      component="th"
                      scope="row"
                    >
                      {row.WPName}
                    </TableCell>
                    <TableCell
                      className={classNames(
                        classes.tableSmallBodyCell,
                        classes.centerAlign
                      )}
                    >
                      {row.Proposed_Start ? moment(row.Proposed_Start).format("M/D/YYYY") : ""}
                    </TableCell>
                    <TableCell
                      className={classNames(
                        classes.tableSmallBodyCell,
                        classes.centerAlign
                      )}
                    >
                      {row.Proposed_Completion ? moment(row.Proposed_Completion).format("M/D/YYYY") : ""}
                    </TableCell>
                    <TableCell
                      className={classNames(
                        classes.tableSmallBodyCell,
                        classes.centerAlign
                      )}
                    >
                      {row.Status}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {!!tableData && tableData.length > 0 && (
        <div className={classes.addWrapper}>
          <AddListitem
            dark
            text="Add New Task"
            onClick={() => openForm()}
            test="Task"
          />
        </div>
      )}
      <CustomModal open={editModal} onClose={toggleForm}>
        <TaskForm
          setEditModal={setEditModal}
          setRefresh={setRefresh}
          formMode="create"
          formType="task"
          parentComponent="TasksDashTable"
          setWpDashRefresh={setWpDashRefresh}
          programName={programName}
        />
      </CustomModal>
    </>
  );
};

export default TasksDashTable;
