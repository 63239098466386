import moment from "moment";
import { Box } from "@material-ui/core";
import DateInput from "components/utils/form-elements/dateInput.component";
import Form from "components/utils/form-elements/form.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import { useCallback } from "react";
import { ACTION_REPLACE_MANUAL } from "reducers/certification/manuals.reducer";
import certificationService from "services/certification.service";


export default function ManualMetaForm(props) {
  const { dispatch, manual } = props;

  const handleAutosave = useCallback(async (name, value) => {
    const manualResponse = await certificationService.updateManual(
      manual.Manual_ID,
      { [name]: value }
    );
    dispatch({
      type: ACTION_REPLACE_MANUAL,
      payload: manualResponse.payload
    });
  }, [dispatch, manual.Manual_ID]);

  return (
    <Form name="manual-form">
      <Box display="flex" flexWrap="wrap">
        <Box width="100%" maxWidth={632} marginRight={4}>
          <LabelInput
            name="Title"
            label="Title"
            defaultValue={manual.Title}
            variant="default"
            margin="dense"
            onAutosave={handleAutosave}
            test="Title"
          />
        </Box>
        <Box display="flex" width="100%" maxWidth={400}>
          <Box width="50%" marginRight={4}>
            <LabelInput
              name="Version"
              label="Version"
              defaultValue={manual.Version}
              variant="default"
              margin="dense"
              onAutosave={handleAutosave}
            />
          </Box>
          <Box width="50%">
            <DateInput
              name="Last_Edit"
              label="Date"
              value={moment(manual.Last_Edit).format("YYYY-MM-DD")}
              variant="default"
              margin="dense"
              readOnly
            />
          </Box>
        </Box>
      </Box>
    </Form>
  );
}
