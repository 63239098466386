import useReducerAsync from "hooks/useReducerAsync";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";
import certificationManualsReducer, { manualsInitialState } from "reducers/certification/manuals.reducer";
import EditManualPage from "./edit/editManualPage.component";
import ManualIndexPage from "./manualIndexPage.component";


export default function CertificationManuals() {
  const [state, dispatch] = useReducerAsync(
    certificationManualsReducer,
    manualsInitialState
  );

  return (
    <Switch>
      <Route path="/program/:programId/:programComponentId/manual/:manualId">
        <EditManualPage state={state} dispatch={dispatch} />
      </Route>
      <Route path="/program/:programId/:programComponentId/" exact>
        <ManualIndexPage state={state} dispatch={dispatch} />
      </Route>
    </Switch>
  );
}
