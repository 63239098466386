export const PROGRAM_ID_ORCHESTRATION = 8;
export const PROGRAM_ID_CHARTER = 8;

// Portfolio program
export const PROGRAM_ID_PORTFOLIO = 11;
export const COMPONENT_ID_PORTFOLIO_DASHBOARD = 80;
export const COMPONENT_ID_PORTFOLIO_DEMOGRAPHICS = 81;
export const COMPONENT_ID_PORTFOLIO_ASSESSMENTS = 82;

export const COMPONENT_ID_TRAINEE = 107;


// Certification Program
export const PROGRAM_COMPONENT_ID_CERT_MANUALS = 100;

export const COMPONENT_OBJECT_ID_CERT_TRAINING_ASSIGNMENTS = 308;
export const COMPONENT_OBJECT_ID_CERT_TRAINING_MODULES = 309;


// Runbook Program
export const PROGRAM_ID_RUNBOOK = 13;
export const COMPONENT_ID_RUNBOOK_DASHBOARD = 117;
export const COMPONENT_ID_RUNBOOK_VIEW = 118;
export const COMPONENT_ID_RUNBOOK_ADMIN = 119;

//Enterprise/Orchestration level
export const PROGRAM_COMPONENT_ID_REQUIREMENTS = 16;
export const PROGRAM_COMPONENT_ID_COMPLIANCE = 17;
export const COMPONENT_OBJECT_ID_TRAINING_ASSIGNMENTS = 293;
export const COMPONENT_OBJECT_ID_TRAINING_MODULES = 294;

export const customProgramComponentHeadings = {
  [PROGRAM_COMPONENT_ID_REQUIREMENTS]: "Enterprise Requirements",
  [PROGRAM_COMPONENT_ID_COMPLIANCE]: "Compliance Statements"
};

export const PROGRAM_COMPONENT_TYPE_COMPONENTS = "Components";
export const PROGRAM_COMPONENT_TYPE_CUSTOM = "Custom";
export const PROGRAM_COMPONENT_TYPE_DASHBOARD = "Dash";
export const PROGRAM_COMPONENT_TYPE_REDIRECT = "Redirect";

export const COMPONENT_OBJECT_TYPE_BUILDER = "Builder";
export const COMPONENT_OBJECT_TYPE_CUSTOM = "Custom";
export const COMPONENT_OBJECT_TYPE_DATA_TABLE = "DataTable";
export const COMPONENT_OBJECT_TYPE_DOCUMENT = "Document";
export const COMPONENT_OBJECT_TYPE_DOCUMENT_LIBRARY = "DocumentLibrary";
export const COMPONENT_OBJECT_TYPE_TABLE = "Table";
export const COMPONENT_OBJECT_TYPE_TOOL_DOCUMENT = "ToolDocument";

export const COMPONENT_OBJECT_PORTFOLIO_DOC_LIBRARY_TYPEREF = "PortfolioDocLibrary";

/*
  Leaving this uncommented because other code
  is referring to it, but is not currently needed.
  Might be nice in the future.
  example: [COMPONENT_OBJECT_ID_TRAINING_ASSIGNMENTS]: "Training Assignments"
*/
export const customComponentObjectHeadings = {};
