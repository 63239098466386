import React, { useRef } from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { IconButton, InputBase } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import variables from "styleVariables";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    paddingTop: 5,
    paddingBottom: 5,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  mainSearch: {
    borderRadius: "60px 60px 60px 60px",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.15),
    },
    "&:focus-within": {
      backgroundColor: alpha(theme.palette.common.black, 0.1),
      boxShadow: "inset 1px 0px 6px -1px rgb(0 0 0 / 60%)",
    },
  },
  selectSearch: {
    borderBottom: `1px solid ${variables.primaryMain}`,
  },
  searchForm: {
    padding: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    maxWidth: 400,
    minWidth: 200,
    marginRight: 20,
  },
  controlWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    marginRight: 5,
  },
  //Icons
  iconButton: {
    padding: 0,
  },
  searchIcon: {
    fontSize: "1em",
    color: "white",
  },
  selectSearchIcon: {
    color: variables.primaryMain,
  },
  // Input Classes
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(0.5, 0.5, 0.5, 1),
    paddingLeft: `calc(.5em + ${theme.spacing()}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const SearchInputBase = (props) => {
  return <InputBase {...props} inputProps={{ "aria-label": "search" }} />;
};

const MainSearchBar = ({
  input,
  changeHandler,
  handleSubmit,
  autoFocus,
  placeholder,
}) => {
  const classes = useStyles();

  return (
    <form className={classes.searchForm}>
      <div className={classNames(classes.search, classes.mainSearch)}>
        <SearchInputBase
          fullWidth
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          value={input}
          onChange={changeHandler}
          data-cy="search-bar"
          autoFocus={autoFocus}
          placeholder={placeholder}
        />
        <div
          onClick={handleSubmit}
          className={classNames(classes.controlWrapper, classes.searchControl)}
        >
          <IconButton
            type="submit"
            aria-label="search"
            className={classes.iconButton}
            data-cy="search-icon"
          >
            <SearchIcon className={classNames(classes.searchIcon)} />
          </IconButton>
        </div>
      </div>
    </form>
  );
};

const SelectSearchBar = ({
  changeHandler,
  autoFocus,
  placeholder,
  textToSearch,
  clearSearch,
}) => {
  const classes = useStyles();
  const inputRef = useRef();

  const clearInput = () => {
    inputRef.current.value = "";
    clearSearch();
  };

  return (
    <form className={classes.searchForm}>
      <div className={classNames(classes.search, classes.selectSearch)}>
        <SearchInputBase
          fullWidth
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          onChange={changeHandler}
          data-cy="select-search-bar"
          autoFocus={autoFocus}
          placeholder={placeholder}
          inputRef={inputRef}
        />
        <div
          // onClick={handleSubmit}
          className={classNames(classes.controlWrapper, classes.searchControl)}
        >
          {textToSearch.length === 0 ? (
            <IconButton
              disabled
              aria-label="search"
              className={classes.iconButton}
              data-cy="search-icon"
            >
              <SearchIcon
                className={classNames(
                  classes.searchIcon,
                  classes.selectSearchIcon
                )}
              />
            </IconButton>
          ) : (
            <IconButton
              className={classes.iconButton}
              onClick={() => clearInput()}
            >
              <CloseIcon
                className={classNames(
                  classes.searchIcon,
                  classes.selectSearchIcon
                )}
              />
            </IconButton>
          )}
        </div>
      </div>
      {/*
          an invisible input is needed to prevent submission on enter
          https://stackoverflow.com/questions/1370021/why-does-forms-with-single-input-field-submit-upon-pressing-enter-key-in-input
      */}
      <input style={{ display: "none" }} />
    </form>
  );
};

export { MainSearchBar, SelectSearchBar };
