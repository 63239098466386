import http from "./http-common";
import authHeader from "./authHeader";

class CommitteeService {

  // get list of Committees
  getAll() {
    return http
      .get(`/committee/`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get list of active Committees by type
  getActiveCommitteesByType(programId, type) {
    return http
      .get(`/committee/${programId}/${type}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get committee by committee Id
  getCommitteeByCommitteeId(committeeId) {
    return http
      .get(`/committee/${committeeId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  updateCommittee(data, id) {
    return http.put(`/committee/${id}`, data, { headers: authHeader() });
  }

  deleteCommittee(id) {
    return http.delete(`/committee/${id}`, { headers: authHeader() });
  }

  insertCommittee(committee) {
    return http.post(`/committee/`, committee, { headers: authHeader() });
  }

}

export default new CommitteeService();
