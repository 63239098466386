import AlertContext from "contexts/alert.context";
import useNumericParams from "hooks/useNumericParams";
import useReducerAsync from "hooks/useReducerAsync";
import { useEffect } from "react";
import alertReducer, { ACTION_SET_COMPONENT_OBJECT_ALERT_COUNT, ACTION_SET_PROGRAM_ALERT_COUNT, ACTION_SET_USER_ALERTS, alertInitialState } from "reducers/global/alert.reducer";
import userService from "services/user.service";
import { setStateFetchEffect } from "utils/ajaxHelpers";

/*
 * Loads and provides Alert reducer as global context for app
 */
export default function AlertContextProvider(props) {
  const { programId, componentObjectId } = useNumericParams(true);
  const { children } = props;

  const [state, dispatch] = useReducerAsync(alertReducer, alertInitialState);
  const { userAlerts } = state;

  useEffect(function fetchValidIds() {
    if (userAlerts) {
      return;
    }
    return setStateFetchEffect(
      userService.getActiveCurrentUserAlerts(),
      ([ userAlertsResponse ]) => (
        dispatch({
          type: ACTION_SET_USER_ALERTS,
          payload: userAlertsResponse.payload
        })
      )
    );
  }, [dispatch, userAlerts]);

  useEffect(function setProgramAlertCount() {
    if (!programId || !userAlerts) {
      return;
    }
    const programAlerts = userAlerts.filter(alert => (
      alert.Program_Program_ID === programId
    ));
    dispatch({
      type: ACTION_SET_PROGRAM_ALERT_COUNT,
      payload: programAlerts.length
    });
  }, [dispatch, programId, userAlerts]);

  useEffect(function setComponentObjectAlertCount() {
    if (!componentObjectId || !userAlerts) {
      return;
    }
    const componentObjectAlerts = userAlerts.filter(alert => (
      alert.ComponentObjects_ComponentObject_ID === componentObjectId
    ));
    dispatch({
      type: ACTION_SET_COMPONENT_OBJECT_ALERT_COUNT,
      payload: componentObjectAlerts.length
    });
  }, [dispatch, componentObjectId, userAlerts]);

  return (
    <AlertContext.Provider
      value={{
        dispatch,
        state,
      }}
    >
      {children}
    </AlertContext.Provider>
  )
}