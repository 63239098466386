import React from "react";
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Paper,
  TableContainer,
} from "@material-ui/core";
import TableCell from "components/utils/tables/shared/tableCell.component";

import styleVariables from "styleVariables";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  scoreCardTablePaper: {
    maxWidth: 600,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  cellBold: {
    fontWeight: 600,
  },
  tableHeaderCell: {
    color: "white",
    fontSize: 16,
    fontWeight: 600,
  },
  tableHeader: {
    overflow: "hidden",
    padding: "2px 16px",
    background: styleVariables.secondaryDark,
    textAlign: "center",
  },
  tableBodyCell: {
    borderRight: `1px solid ${theme.palette.border.light}`,
    color: theme.palette.text.secondary,
    "&:last-of-type": {
      borderRight: 0,
    },
  },
  categoryCell: {
    width: "70%",
  },
  scoreCell: {
    width: "30%",
  },
  avoidPageBreak: {
    "@media print": {
      pageBreakInside: "avoid"
    }
  }
}));

const ScoreCardTable = (props) => {
  const classes = useStyles();
  const { scoreSubSections } = props;

  return (
    <TableContainer component={Paper} className={classNames(classes.scoreCardTablePaper, classes.avoidPageBreak)}>
      <Table>
        <TableHead>
          <TableRow className={classes.tableHeader} hover={false}>
            <TableCell
              align="left"
              className={classNames(
                classes.tableHeaderCell,
                classes.categoryCell
              )}
              size="small"
            >
              Category
            </TableCell>
            <TableCell
              align="center"
              className={classNames(classes.tableHeaderCell, classes.scoreCell)}
              size="small"
            >
              Scores (1-5)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {scoreSubSections.map((subsection) => {
            const score = subsection._associations?.AssessmentCategoryScores?.[0];
            return (
              <TableRow hover={false} key={subsection.ReportSubSection_ID}>
                <TableCell
                  align="left"
                  className={classNames(
                    classes.tableBodyCell,
                    classes.cellBold
                  )}
                >
                  {subsection.Section_Header}
                </TableCell>
                <TableCell
                  align="center"
                  className={classNames(
                    classes.tableBodyCell,
                    classes.scoreCell
                  )}
                >
                  {score.Score}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ScoreCardTable;
