const truncate = (dataName, limit = 45, truncateAt = " ") => {
  if (dataName.length > limit) {
    const indexOfSpace = dataName.substr(0, limit).lastIndexOf(truncateAt);
    return dataName.substr(0, indexOfSpace) + "...";
  } else {
    return dataName;
  }
};
const progressValue = (completed, total) => {
  if (completed === 0 && total === 0) {
    return 0;
  } else {
    return (completed / total) * 100;
  }
};

export { truncate, progressValue };
