import React, { useMemo } from "react";
import { MenuItem } from "@material-ui/core";
import CustomSelect from "components/utils/form-elements/select.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import Form from "components/utils/form-elements/form.component";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  linkWrapper: {
    textAlign: "center",
  },
  leftAlignedLinkWrapper: {
    textAlign: "left",
  },
  applicabilityCommentsWrapper: {
    paddingTop: 10,
  },
  inputFont: {
    fontSize: 12,
  },
}));


export default function LOCApplicabilityCell(props) {
  const { cellProps, align, index, onFieldAutosave, onSelectChange } = props;
  const classes = useStyles();

  const alignmentClasses = useMemo(() => (
    align === "left"
      ? classes.leftAlignedLinkWrapper
      : classes.linkWrapper
  ), [align, classes]);

  return (
    <div className={alignmentClasses}>
      <Form name="select-applicability-input">
        <CustomSelect
          id={`select-applicability-` + cellProps.LOC_ID}
          name="Applicability"
          defaultValue={cellProps.Applicability}
          inputProps={{
            "data-loc-id": cellProps.LOC_ID,
            "data-index": index
          }}
          onChange={onSelectChange}
          formLabelPrimaryMain
          helperTextDisabled
          test={`applicability-${cellProps.LOC_ID}`}
        >
          <MenuItem value="Yes" data-cy="select-applicability-yes">
            Yes
          </MenuItem>
          <MenuItem value="No" data-cy="select-applicability-no">
            No
          </MenuItem>
          <MenuItem value="" data-cy="select-applicability-none" disabled />
        </CustomSelect>
        {cellProps.Applicability === "No" && (
          <div className={classes.applicabilityCommentsWrapper}>
            <LabelInput
              name="NA_Comments"
              id={"applicability-comment-" + cellProps.LOC_ID}
              className={classes.inputFont}
              margin="dense"
              onAutosave={onFieldAutosave}
              placeholder="Add comment"
              variant="default"
              defaultValue={cellProps.NA_Comments}
              inputProps={{
                "data-loc-id": cellProps.LOC_ID,
                "data-index": index
              }}
              hideHelperText
              multiline
              test={`applicability-comment-${cellProps.LOC_ID}`}
            />
          </div>
        )}
      </Form>
    </div>
  );
}
