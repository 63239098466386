import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { alertDueMessage } from "utils/alertHelpers";
import variables from "styleVariables";
import classNames from "classnames";
import PermContactCalendarIcon from "icons/PermContactCalendarIcon.svg";
import AssignmentLateIcon from "icons/AssignmentLateIcon.svg";
import NotificationImportantIcon from "icons/NotificationImportantIcon.svg";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  warning: {
    color: variables.warningMain,
    "& $iconWrapper": {
      height: 18,
      width: 18,
      backgroundImage: "url(" + NotificationImportantIcon + ")",
    }
  },
  caution: {
    color: variables.statusCautionDark,
    "& $iconWrapper": {
      backgroundImage: "url(" + AssignmentLateIcon + ")",
    }
  },
  assigned: {
    color: variables.statusGreen,
  },
  alertDueWrapper: {
    display: "flex",
    flexDirection: "column",
    textAlign: "right",
  },
  dueDateWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  dueDate: {
    fontSize: variables.fontSmall,
    fontWeight: "bold",
  },
  iconWrapper: {
    display: "inline-block",
    height: 16,
    width: 16,
    marginRight: 5,
    backgroundImage: "url(" + PermContactCalendarIcon + ")",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
  messageWrapper: {
    fontSize: variables.fontXs,
    fontStyle: "italic",
    paddingTop: 3,
  },
}));

const AlertDueText = (props) => {
  const classes = useStyles();
  const { color, message, days } = alertDueMessage(props.End_Date)

  const colorClass = useMemo(() => {
    switch(color) {
      case "assigned":
        return classes.assigned;
      case "warning":
        return classes.warning;
      case "caution":
        return classes.caution;
      default:
        return "";
    }
  }, [classes, color]);

  if (days === null) {
    return null;
  }
  return (
    <div className={classNames(colorClass, classes.alertDueWrapper)}>
      <div className={classes.dueDateWrapper}>
        <div className={classes.iconWrapper} />
        <div className={classes.dueDate}>
          {moment(props.End_Date).format("M/D/YYYY")}
        </div>
      </div>
      <div className={classes.messageWrapper}>{message}</div>
    </div>
  );
};

export default AlertDueText;
