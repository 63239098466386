import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import variables from "styleVariables";

const StaticLabelOutlinedInput = withStyles({
  root: {
    '&.Mui-focused fieldset': {
      borderLeftWidth: "6px !important",
      padding: '4px !important',
    },
    '& fieldset': {
      borderColor: variables.secondaryMain,
      borderWidth: 2,
    },
    '& input:valid + fieldset': {
      borderColor: variables.secondaryMain,
      borderWidth: 2,
    },
    '& input:valid:focus + fieldset': {
      borderLeftWidth: 6,
      padding: '4px !important', // override inline-style
    },
    '& input:invalid + fieldset': {
      borderColor: `${variables.warningMain} !important;`,
      borderWidth: 2,
    },
  },
})(TextField);

export default StaticLabelOutlinedInput;
