import React, { useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AlertContext from "contexts/alert.context";
import CustomIcons from "components/utils/icons.component.js";
import variables from "styleVariables";
import Badge from "components/utils/badge.component";

const useStyles = makeStyles((theme) => ({
  alertsLinkWrapper: {
    height: 20,
    display: "flex",
    "&:hover": {
      cursor: "pointer",
    },
  },
  icon: {
    fill: "white",
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -2,
    top: 1,
    border: `1px solid white`,
    padding: "0 2px",
    fontSize: variables.fontXs,
    height: 20,
    fontWeight: "bold",
    borderRadius: 40,
    backgroundColor: variables.tertiary2,
  },
}))(Badge);

const HeaderAlert = ({ openAlerts }) => {
  const classes = useStyles();
  const { state } = useContext(AlertContext);

  return (
    <div
      className={classes.alertsLinkWrapper}
      onClick={openAlerts}
      data-cy="alert-icon-header"
    >
      <CustomIcons variant="NotificationsHeavy" className={classes.icon} />
      <StyledBadge
        alertCount={state.userAlerts?.length}
        color="secondary"
        data-cy="badge-header-alert"
        max={999}
        overlap="rectangular"
      />
    </div>
  );
};

export default HeaderAlert;
