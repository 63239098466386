//HEADER SIZES
//28.8pt
const fontH1 = "1.8rem";
//24pt
const fontH2 = "1.5rem";
//20.8pt
const fontH3 = "1.3rem";
//16pt
const fontH4 = "1rem";
//14.4pt
const fontH5 = "0.9rem";
//12.8pt
const fontH6 = "0.8rem";

//FONT SIZES
//9.6pt
const fontXxs = "0.6rem";
//11.2pt
const fontXs = "0.7rem";
//12.8pt
const fontSmall = "0.8rem";
//14.4pt
const fontMedium = "0.9rem";
//16pt
const fontLarge = "1rem";
//17.6pt
const fontXl = "1.1rem";

//PRIMARY BLUE - Navy Blue
const primaryLight = "#175db5";
const primaryMain = "#164c93";
const primaryDark = "#11386c";

//SECONDARY BLUE - Medium Blue
const secondaryLight = "#78c5fd";
const secondaryMain = "#4091ce";
const secondaryDark = "#007acc";

//TERTIARY COLORS
const tertiary1 = "#5f778c";
const tertiary1Light = "#DAE1E7";
const tertiary2 = "#00a1b3";
const tertiary2Dark = "#00829b";
const tertiary3 = "#4746a0";
const tertiary3Dark = "#393880";

//TEXT
const textPrimary = "#000000";
const textSecondary = "#5f778c";

//TABLE HEADER LABEL: Active/Hover
const tableLabelActive = "#78c5fd";

//STATUS COLORS

//WARNING RED
const warningMain = "#9c2129";
const warningLight = "#bc2e38";

//CAUTION ORANGE
const statusAlert = "#fa5b05";
const statusAlertDark = "#d74e04";

//CAUTION YELLOW
const statusCaution = "#edbf00";
const statusCautionDark = "#d1a700";

//GREEN
const statusGreen = "#28a228";
const statusGreenDark = "#118a0f";

//GRAY
const grayTint = "#F3F6F6";
const grayDisabled = "#bdbdbd";
const grayDisabledText = "#8b9aa7";
const grayDisabledBg = "#d1d4d7";


//SELECTED FROM SEARCH
const chosenElement = "rgba(119, 197, 253, 0.3)";

//ROWHOVER

const rowHover = "rgba(95, 119, 140, 0.15)";

//HIGHLIGHT
const rowHighlight = "rgba(23, 94, 181, 0.7)";
const highlightText = "#4BE6E1";

//CANCEL BUTTON GRAY
const grayPrimary = "#878f97";
const graySecondary = "#9aa1a7";

//ROWBORDER
const rowBorder = "rgba(22, 76, 146, 0.22)";
const rowBorderLight = "rgba(22, 76, 146, 0.16)";
const rowBorderDark = "rgba(22, 76, 146, 0.3)";

// CUSTOM BOX SHADOW
const shadowAppbar = "0px 2px 4px 1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)";
const shadowTableInset = "inset -1px 0px 0px 0px rgba(22, 76, 146, 0.22)";
const bottomBoxShadow = "-1px 5px 9px -6px rgb(0 0 0 / 15%), 0px 2px 1px -1px rgb(0 0 0 / 10%)";
const boxShadow1 = "rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px";
const boxShadow2 = "rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px";
const boxShadow3 = "rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px";

// CSS Filters
const filterBookTab = "drop-shadow(2px 1px 4px rgba(0, 0, 0, 0.3))";
const filterBookTabInactive = "drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.3))";

const styleVariables = {
  //HEADER SIZES
  fontH1,
  fontH2,
  fontH3,
  fontH4,
  fontH5,
  fontH6,

  //FONT SIZES
  fontXxs,
  fontXs,
  fontSmall,
  fontMedium,
  fontLarge,
  fontXl,

  //PRIMARY BLUE - Navy Blue
  primaryLight,
  primaryMain,
  primaryDark,

  //SECONDARY BLUE - Medium Blue
  secondaryLight,
  secondaryMain,
  secondaryDark,

  //TERTIARY COLORS
  tertiary1,
  tertiary1Light,
  tertiary2,
  tertiary2Dark,
  tertiary3,
  tertiary3Dark,

  //TEXT
  textPrimary,
  textSecondary,

  //TABLE HEADER LABEL: Active/Hover
  tableLabelActive,


  //STATUS COLORS

  // WARNING RED
  warningMain,
  warningLight,

  // CAUTION YELLOW
  statusAlert,
  statusAlertDark,

  // CAUTION YELLOW
  statusCaution,
  statusCautionDark,

  // GREEN
  statusGreen,
  statusGreenDark,

  // GRAY
  grayTint,
  grayDisabled,
  grayDisabledText,
  grayDisabledBg,

  //CANCEL BUTTON GRAY
  grayPrimary,
  graySecondary,

  //CHOSEN FROM SEARCH
  chosenElement,

  //ROWHOVER
  rowHover,

  //HIGHLIGHT
  rowHighlight,
  highlightText,

  //ROWBORDER
  rowBorder,
  rowBorderLight,
  rowBorderDark,

  // CUSTOM BOX SHADOW
  shadowAppbar,
  shadowTableInset,
  bottomBoxShadow,
  boxShadow1,
  boxShadow2,
  boxShadow3,

    // CSS Filters
  filterBookTab,
  filterBookTabInactive,
}

export default styleVariables;
