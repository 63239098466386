import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import CustomLink from "components/utils/link.component";
import { useCallback , useMemo, useState } from "react";
import variables from "styleVariables";
import CustomModal from "components/utils/modal.component";
import TaskForm from "components/forms/taskForm.component";
import { truncate } from "utils/workPackageHelpers";
import { mapObjectArrayByKey } from "utils/arrayOfObjectsHelpers";
import AddRoadmapItemLink from "./shared/addRoadmapItemLink.component";

const useStyles = makeStyles(theme => ({
  cellCta: {
    minHeight: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  cellCenter: {
    textAlign: "center"
  },
  taskLink: {
    color: variables.secondaryMain,
    "&:hover": {
      color: variables.secondaryDark,
    },
  },
  taskLine: {
    whiteSpace: "nowrap"
  },
  linkWrapper: {
    padding: 10,
  },
  nonlinkWrapper: {
    fontWeight: 600,
  },
}));


export default function TaskWorkpageCell(props) {
  const {
    index,
    row,
    roadmapItems,
    replaceRoadmapItem,
    setRoadmapItems,
    taskList,
    setReviewPageMode,
    setTaskList,
    setOnSuccessVariable,
  } = props;
  const classes = useStyles();
  const [activeTask, setActiveTask] = useState(null)
  const [editModal, setEditModal] = useState(false)
  const [refresh, setRefresh] = useState(true);

  const workpackages = useMemo(() => {
    const filteredWorkpackages = row.Tasks?.filter?.(wpOrTask => (
      wpOrTask.type !== "task"
    ));
    if (filteredWorkpackages?.length) {
      return Object.values(
        mapObjectArrayByKey(filteredWorkpackages, "Workpackage_ID")
      );
    }
    return [];
  }, [row.Tasks]);

  const tasks = useMemo(() => {
    const filteredTasks = row.Tasks?.filter?.(wpOrTask => (
      wpOrTask.type === "task"
    ));
    if (filteredTasks?.length) {
      return Object.values(
        mapObjectArrayByKey(filteredTasks, "Task_ID")
      );
    }
    return [];
  }, [row.Tasks]);

  const toggleForm = () => {
    setEditModal(!editModal);
  };
  const onSuccess = (task_list) => {
    setOnSuccessVariable(task_list)
  }

  const renderLink = useCallback(task => (
    <CustomLink
      test={`${task.Task_ID}-${task.Status}`}
      variant="customUnderline"
      className={classes.taskLink}
      onClick={() => {
        setEditModal(true)
        setActiveTask(task)
      }}
      ignoreReadOnly
    >
      <span className={classes.taskLine}>
        {task.Name}
      </span>
      &nbsp;
      <span className={classes.taskLine}>
        ({(task.Status || "Not Started").replace("-", " ")})
      </span>
    </CustomLink>
  ), [classes]);

  return (
    <div className={classNames(classes.cellCta, classes.cellCenter)}>
      {!!workpackages.length && (
        <div className={classNames(classes.linkWrapper)} data-cy="tasks-link">
          {workpackages.map(item => (
            <div
              className={classes.nonlinkWrapper}
              data-cy="workpackages"
              key={item.Workpackage_ID}
            >
              {truncate(item.Name, 35)}
            </div>
          ))}
        </div>
      )}
      {!!tasks.length && (
        <div className={classes.linkWrapper} data-cy="tasks-link">
          {tasks.map(item => (
            <div key={item.Task_ID}>
              {renderLink(item)}
            </div>
          ))}
        </div>
      )}
      {!workpackages?.length && !tasks.length && (
        <AddRoadmapItemLink
          index={index}
          item={row}
          replaceRoadmapItem={replaceRoadmapItem}
          setReviewPageMode={setReviewPageMode}
        />
      )}

      <CustomModal open={editModal} onClose={toggleForm} data-cy="editForm">
        <TaskForm
          setEditModal={setEditModal}
          setRefresh={setRefresh}
          refresh={refresh}
          formMode="edit"
          formType="task"
          taskData={activeTask}
          activeTask={activeTask}
          parentComponent="TaskManager"
          roadmapItems={roadmapItems}
          setRoadmapItems={setRoadmapItems}
          taskList={taskList}
          setTaskList={setTaskList}
          onSuccess={onSuccess}
        />
      </CustomModal>
    </div>
  );
}