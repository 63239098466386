import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  customContainer: {
    maxWidth: "calc(100vw - 400px)",
    paddingLeft: 40,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 20,
      maxWidth: "calc(100vw - 275px)",
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 10,
    },
  },
  customContainerDataTable: {
    maxWidth: "calc(100vw - 275px)",
    paddingLeft: 80,
    paddingRight: 0,
    [theme.breakpoints.down('lg')]: {
      maxWidth: "calc(100vw - 275px)",
      paddingLeft: 25,
    },
  },
}));

const CustomContainer = (props) => {
  const classes = useStyles();
  if (props.variant === "dataTable") {
    return (
      <Container className={classes.customContainerDataTable}>
        {props.children}
      </Container>
    );
  }
  return (
    <Container className={classes.customContainer}>
      {props.children}
    </Container>
  );
};

export default CustomContainer;
