

const findParentParagraph = (cfrItem, flattenedParagraphArr) => {
  const paragraphParent = flattenedParagraphArr.find(
    (item) => item.CFR_ID === cfrItem.Parent_CFR_ID
  );
  if (!paragraphParent) {
    return cfrItem;
  } else {
    return findParentParagraph(paragraphParent, flattenedParagraphArr);
  }
};

// Collect parent Ids for paragraphs
const findParagraphTreeIds = (
  cfrItem,
  flattenedParagraphArr,
  idsArr = [cfrItem.CFR_ID]
) => {
  const paragraphParent = flattenedParagraphArr.find(
    (item) => item.CFR_ID === cfrItem.Parent_CFR_ID
  );
  if (!paragraphParent) {
    return idsArr;
  } else {
    return findParagraphTreeIds(paragraphParent, flattenedParagraphArr, [
      ...idsArr,
      paragraphParent.CFR_ID,
    ]);
  }
};

const makeDisplayParagraphList = (ids, flattenedParagraphs) => {
  const paragraphList = [];
  for (const id of ids) {
    const paragraph = flattenedParagraphs.find(
      (findParagraph) => findParagraph.CFR_ID === id
    );
    paragraphList.unshift(paragraph);
  }
  return paragraphList;
};

const findSectionParent = (section, parts, subparts) => {
  let parent = subparts?.find(
    (subpart) => subpart.CFR_ID === section.Parent_CFR_ID
  );
  if (!parent) {
    parent = parts.find((part) => part.CFR_ID === section.Parent_CFR_ID);
  }
  return parent;
};

export const findPartInfo = (cfrItem, cfrData, flattenedParagraphs) => {
  const { subparts, parts, sections } = cfrData;
  let partId;
  let subpartId;
  let sectionId;
  if (cfrItem.CFR_Type === "Paragraph") {
    const paragraph = flattenedParagraphs.find(
      (findParagraph) => findParagraph.CFR_ID === cfrItem.CFR_ID
    );
    const parentParagraph = findParentParagraph(paragraph, flattenedParagraphs);
    sectionId = sections.find(
      (section) => section.CFR_ID === parentParagraph.Parent_CFR_ID
    ).CFR_ID;
  }
  if (cfrItem.CFR_Type === "Section" || sectionId) {
    if (!sectionId) {
      sectionId = cfrItem.CFR_ID;
    }
    const section = sections.find((findSection) => findSection.CFR_ID === sectionId);
    const sectionParent = findSectionParent(section, parts, subparts);
    if (sectionParent.CFR_Type === "Subpart") {
      subpartId = sectionParent.CFR_ID;
    } else if (sectionParent.CFR_Type === "Part") {
      partId = sectionParent.CFR_ID;
    }
  }
  if ((cfrItem.CFR_Type === "Subpart" || subpartId) && !partId) {
    if (!subpartId) {
      subpartId = cfrItem.CFR_ID;
    }
    partId = subparts.find((subpart) => subpart.CFR_ID === subpartId).Parent_CFR_ID;
  }

  if (cfrItem.CFR_Type === "Part") {
    partId = cfrItem.CFR_ID;
  }
  return [parts.find((part) => part.CFR_ID === partId)];
};

export const findSubpartInfo = (cfrItem, cfrData, flattenedParagraphs) => {
  const { subparts, parts, sections } = cfrData;
  let partId;
  let subpartId;
  let sectionId;
  let subPart;

  if (cfrItem.CFR_Type === "Paragraph") {
    const paragraph = flattenedParagraphs.find(
      (findParagraph) => findParagraph.CFR_ID === cfrItem.CFR_ID
    );
    const parentParagraph = findParentParagraph(paragraph, flattenedParagraphs);
    sectionId = sections.find(
      (section) => section.CFR_ID === parentParagraph.Parent_CFR_ID
    ).CFR_ID;
  }
  if (cfrItem.CFR_Type === "Section" || sectionId) {
    if (!sectionId) {
      sectionId = cfrItem.CFR_ID;
    }
    const section = sections.find((findSection) => findSection.CFR_ID === sectionId);
    subpartId = section.Parent_CFR_ID;
  }
  if (cfrItem.CFR_Type === "Subpart" || subpartId) {
    if (!subpartId) {
      subpartId = cfrItem.CFR_ID;
    }
    subPart = subparts.find((subpart) => subpart.CFR_ID === subpartId);

    partId = subPart.Parent_CFR_ID;
  }
  const part = parts.find((findPart) => findPart.CFR_ID === partId);

  return [part, subPart];
};

export const findSectionInfo = (cfrItem, cfrData, flattenedParagraphs) => {
  const { subparts, parts, sections } = cfrData;
  let sectionId;
  let subPart;
  let section;
  let part;

  if (cfrItem.CFR_Type === "Paragraph") {
    const paragraph = flattenedParagraphs.find(
      (findParagraph) => findParagraph.CFR_ID === cfrItem.CFR_ID
    );
    const parentParagraph = findParentParagraph(paragraph, flattenedParagraphs);
    sectionId = parentParagraph.Parent_CFR_ID;
  }
  if (cfrItem.CFR_Type === "Section" || sectionId) {
    if (!sectionId) {
      sectionId = cfrItem.CFR_ID;
    }

    section = sections.find((findSection) => findSection.CFR_ID === sectionId);

    const sectionParent = findSectionParent(section, parts, subparts);
    if (sectionParent.CFR_Type === "Subpart") {
      subPart = sectionParent;
      part = parts.find((findPart) => findPart.CFR_ID === sectionParent.Parent_CFR_ID);
    } else {
      part = sectionParent;
    }
  }
  return [part, subPart, section];
};

export const findParagraphInfo = (cfrItem, cfrData, flattenedParagraphs) => {
  const { subparts, parts, sections } = cfrData;
  const paragraph = flattenedParagraphs.find(
    (findParagraph) => findParagraph.CFR_ID === cfrItem.CFR_ID
  );
  const paragraphTreeIds = findParagraphTreeIds(paragraph, flattenedParagraphs);
  const displayParagraphList = makeDisplayParagraphList(
    paragraphTreeIds,
    flattenedParagraphs
  );
  const reversedParagraphs = [...displayParagraphList].reverse();
  const filteredParagraphTree = reversedParagraphs.reduce(
    (accumulator, currentParagraph) => ({
      ...currentParagraph,
      _associations: {
        CFR: [].concat(accumulator || [])
      }
    }), []
  );
  const parentParagraph = findParentParagraph(paragraph, flattenedParagraphs);
  const sectionId = parentParagraph.Parent_CFR_ID;
  const section = sections.find((findSection) => findSection.CFR_ID === sectionId);

  const sectionParent = findSectionParent(section, parts, subparts);

  let subpart = null;
  let part = sectionParent;
  if (sectionParent.CFR_Type === "Subpart") {
    subpart = sectionParent;
    part = parts.find(({ CFR_ID }) => CFR_ID === sectionParent.Parent_CFR_ID);
  }

  return [part, subpart, section, filteredParagraphTree];
};
