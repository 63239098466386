import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import classNames from "classnames";
import { searchScopeTypes } from "utils/searchHelpers";
import Checkbox from "components/utils/form-elements/checkbox.component";
import { truncate } from "utils/workPackageHelpers";
import { Box, Divider } from "@material-ui/core";
import Highlighter from "react-highlight-words";
import styleVariables from "styleVariables";

const workpackageTaskTypes = {
  TASK: "task",
  WORKPACKAGE: "workpackage",
};

const useStyles = makeStyles((theme) => ({
  resultContainer: {
    padding: 4,
    margin: "0px 4px",
    display: "flex",
    flexDirection: "column",
    "&:hover": {
      backgroundColor: variables.rowHover,
    },
  },
  resultDetails: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 8,
    paddingLeft: 30,
    color: variables.textSecondary,
  },
  resultDivider: {
    margin: "4px 0",
    backgroundColor: variables.tertiary2,
  },
  //CHECKBOX
  checkLabel: {
    fontSize: variables.fontMedium,
    fontWeight: "bold",
    color: variables.primaryMain,
  },
  checkRoot: {
    height: 22,
  },
  wpTasks: {
    color: variables.textSecondary,
    padding: "5px 0",
  },
  labelValueWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 3,
    fontSize: variables.fontMedium,
  },
  label: {
    fontWeight: "bold",
    paddingRight: 3,
  },
  unorderdList: {
    paddingTop: 5,
    paddingBottom: 5,
    margin: 0,
  },
  highlightedText: {
    backgroundColor: variables.highlightText,
  },
  //Task Wp
  resultHeaderBox: {
    fontWeight: "bold",
    color: "white",
    padding: "0px 6px",
    marginLeft: -8,
    marginRight: -8,
    marginBottom: 8
  },
  taskHeaderBox: {
    backgroundColor: styleVariables.secondaryDark
  },
  wpHeaderBox: {
    backgroundColor: styleVariables.primaryMain
  },
}));

const HighlightSearch = (props) => {
  const classes = useStyles();
  return (
    <Highlighter
      highlightClassName={classes.highlightedText}
      autoEscape
      {...props}
    />
  );
};

const ResultLabelAndValue = ({ label, value, searchWords }) => {
  const classes = useStyles();
  return (
    <div className={classes.labelValueWrapper}>
      <div>
        <span className={classes.label}> {`${label}: `}</span>
        <HighlightSearch textToHighlight={value} searchWords={searchWords} />
      </div>
    </div>
  );
};

const ResultCheckBox = (props) => {
  const classes = useStyles();
  return (
    <Checkbox
      size="small"
      labelClass={classes.checkLabel}
      rootClass={classes.checkRoot}
      {...props}
    />
  );
};

const TaskWPResult = ({
  resultType,
  resultData,
  selectedResults,
  disabledResults,
  onChange,
  searchTerms,
}) => {
  const classes = useStyles();
  const isTask = resultType === workpackageTaskTypes.TASK;
  const compareIDField = isTask ? "Task_ID" : "Workpackage_ID";

  return (
    <>
      <Box
        className={classNames(
          classes.resultHeaderBox,
          isTask ? classes.taskHeaderBox : classes.wpHeaderBox
        )}
      >
        {isTask ? "Task" : "Workpackage"}
      </Box>
      <ResultCheckBox
        disabled={disabledResults.some(
          (disabledResult) => disabledResult[compareIDField] === resultData.ID
        )}
        checked={selectedResults.some(
          (selectedResult) => selectedResult[compareIDField] === resultData.ID
        )}
        onChange={(event) => onChange(event, resultData, resultType)} //change
        name={resultData.ID}
        label={`ID#${resultData.ID} ${truncate(resultData.Name, 34)}`}
      />
      <div className={classes.resultDetails}>
        <ResultLabelAndValue
          label="Name"
          value={resultData.Name}
          searchWords={searchTerms}
        />
        {resultType === workpackageTaskTypes.WORKPACKAGE && (
          <ResultLabelAndValue
            label="Description"
            value={resultData.Description}
            searchWords={searchTerms}
          />
        )}
        <ResultLabelAndValue
          label="Status"
          value={resultData.Status}
          searchWords={searchTerms}
        />
        {resultType === workpackageTaskTypes.WORKPACKAGE && (
          <div className={classes.wpTasks}>
            <div className={classes.label}>Workpackage Tasks:</div>
            {resultData.TaskList.length > 0 && (
              <ul className={classes.unorderdList}>
                {resultData.TaskList.map((task) => {
                  return (
                    <li key={task.Task_ID}>
                      <div>
                        {`ID#${task.Task_ID} `}
                        <HighlightSearch
                          textToHighlight={task.Task_Name}
                          searchWords={searchTerms}
                        />
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const SelectSearchResult = ({
  result,
  textToSearch,
  searchScopeType,
  selectedResults,
  disabledResults,
  onChange,
}) => {
  const classes = useStyles();
  const searchTerms = useMemo(() => [textToSearch], [textToSearch]);

  return (
    <>
      <div className={classNames(classes.resultContainer)}>
        {searchScopeType === searchScopeTypes.SEARCH_TASKS_WORKPACKAGES && (
          <TaskWPResult
            resultType={
              result.data.Workpackage_or_task === workpackageTaskTypes.TASK
                ? workpackageTaskTypes.TASK
                : workpackageTaskTypes.WORKPACKAGE
            }
            resultData={result.data}
            selectedResults={selectedResults}
            disabledResults={disabledResults}
            onChange={onChange}
            searchTerms={searchTerms}
          />
        )}
      </div>
      {searchScopeType !== searchScopeTypes.SEARCH_TASKS_WORKPACKAGES && (
        <Divider className={classes.resultDivider} />
      )}
    </>
  );
};

export default SelectSearchResult;
