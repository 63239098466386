import React from "react";
import { Card, CardContent, Radio } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import classNames from "classnames";

const boxShadowStyle =
  "1px 3px 4px -2px rgb(0 0 0 / 20%), 5px 4px 3px -4px rgb(0 122 204 / 20%), 2px 3px 2px 1px rgb(64 145 206 / 20%), 2px 1px 10px 4px rgb(120 197 253 / 30%)";
const useStyles = makeStyles((theme) => ({
  card: {
    color: variables.primaryMain,
    fontSize: 24,
    display: "flex",
    justifyContent: "center",
    borderRadius: 10,
    minWidth: 240,
    maxWidth: 300,
    height: 180,
    "&:hover": {
      color: variables.secondaryDark,
      cursor: "pointer",
      boxShadow: boxShadowStyle,
      "& > * > * >": {
        "&.MuiRadio-root": {
          color: variables.secondaryDark,

        }
      },
    },
  },
  cardContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  titleSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 20,
  },
  iconSection: {
    display: "flex",
    justifyContent: "center",
  },
  notSelected: {
    color: variables.tertiary1,
  },
  selected: {
    boxShadow: boxShadowStyle,
    color: variables.secondaryDark,
  },
}));

const OptionCard = ({
  title,
  onClick,
  notSelected,
  iconComponent,
  selected,
}) => {
  const classes = useStyles();

  return (
    <Card
      elevation={3}
      className={classNames(
        classes.card,
        notSelected ? classes.notSelected : undefined,
        selected ? classes.selected : undefined
      )}
      onClick={onClick}
      data-cy={`${title}-card`}
    >
      <CardContent className={classes.cardContent}>
        <div className={classes.titleSection}>
          {title}{" "}
          <Radio
            className={classes.radioButton}
            checked={selected}
            data-cy={`radio-option-${title}`}
            name={title}
            inputProps={{ 'aria-label': title}}
            value={selected}
          />
        </div>
        <div className={classes.iconSection}>{iconComponent}</div>
      </CardContent>
    </Card>
  );
};

export default OptionCard;
