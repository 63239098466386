import React, { useContext, useMemo } from "react";
import { MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomSelect from "components/utils/form-elements/select.component";
import { moveToFrontByKey } from "utils/sortingFuncs";
import ProgramsContext from "contexts/programs.context";
import { PROGRAM_ID_ORCHESTRATION } from "utils/programConstants";

const useStyles = makeStyles((theme) => ({
  customSelect: {
    marginBottom: 25,
    width: "100%",
  },
}));

const ProgramSelect = (props) => {
  const classes = useStyles();
  const { state } = useContext(ProgramsContext);

  const activePrograms = useMemo(() => {
    const programList = Object.values(state.programs || {});
    const activeProgramList = programList.filter(program => (
      program.Status === "Active"
    ));
    return moveToFrontByKey(
      activeProgramList,
      "Program_ID",
      PROGRAM_ID_ORCHESTRATION
    );
  }, [state.programs]);

  return (
    <CustomSelect
      required
      labelError={!props.formData.chosenProgramId}
      error={!props.formData.chosenProgramId}
      variant="form"
      className={classes.customSelect}
      label="Select Program"
      value={props.formData.chosenProgramId}
      onChange={props.handleChange}
      name="chosenProgramId"
      test="Program"
      selectClasses={{
        root: classes.selectRoot,
      }}
    >
      {activePrograms.map((item) => (
        <MenuItem
          value={item.Program_ID}
          key={item.Program_ID}
          data-cy={`menu-item-${item.Name}`}
        >
          {item.Name}
        </MenuItem>
      ))}
    </CustomSelect>
  );
};

export default ProgramSelect;
