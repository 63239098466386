import { makeStyles, Table, TableHead, TableBody, TableRow, Paper } from "@material-ui/core";
import TableCell from "components/utils/tables/shared/tableCell.component";
import classNames from "classnames";
import styleVariables from "styleVariables";


const useStyles = makeStyles(theme => ({
  cellBold: {
    fontWeight: 600
  },
  cellScore: {
    width: "20%"
  },
  paper: {
    overflow: "auto",
    color: theme.palette.text.secondary
  },
  tableHeaderCell: {
    color: "white",
    fontSize: 16,
    fontWeight: 600,
  },
  tableHeader: {
    overflow: "hidden",
    padding: "2px 16px",
    background: styleVariables.secondaryDark,
    textAlign: "center"
  },
  referenceaViewTableHead: {
    background: styleVariables.secondaryDark,
  },
  tableBodyCell: {
    borderRight: `1px solid ${theme.palette.border.light}`,
    color: theme.palette.text.secondary,
    "&:last-of-type": {
      borderRight: 0
    }
  },
  hideOverflow: {
    "@media print": {
      overflow: "hidden"
    }
  }
}));


export default function ScoreDefinitionsTable({editReferenceView}) {
  const classes = useStyles();
  return (
    <Paper className={classNames(classes.paper, classes.hideOverflow)}>
      <Table>
        <TableHead>
          <TableRow className={classNames(classes.tableHeader, editReferenceView && classes.referenceaViewTableHead)} hover={false}>
            <TableCell
              align="center"
              className={classNames(classes.tableHeaderCell, classes.cellScore)}
              size="small"
            >
              Score
            </TableCell>
            {[1, 2, 3, 4, 5].map(value => (
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                size="small"
                key={value}
              >
                {value}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow hover={false}>
            <TableCell
              align="center"
              className={classNames(classes.tableBodyCell, classes.cellBold)}
            >
              Value
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableBodyCell}
            >
              Not Practiced
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableBodyCell}
            >
              Ad Hoc Practices &ndash; person or scenario specific
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableBodyCell}
            >
              Basic Frameworks in place with general processes defined
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableBodyCell}
            >
              Managed processes followed consistently and generally understood
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableBodyCell}
            >
              Continually Improving with adjustments supported by communications and awareness efforts
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
}
