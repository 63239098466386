import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import Alert from "@material-ui/lab/Alert";
import { isReadOnly } from "utils/roles";
import classNames from "classnames";
import CollapsableAlert from "../collapsableAlert.component";

const useStyles = makeStyles((theme) => ({
  banner: {
    backgroundColor: variables.primaryMain,
    color: "white",
    fontWeight: "bold",
    fontSize: variables.fontXl,
    padding: "8px 20px",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  alert: {
    margin: 0,
  },
}));

const FormBanner = (props) => {
  const classes = useStyles();

  const showReadOnlyAlert = useMemo(() => {
    return !props.hideReadOnlyMessage && isReadOnly()
  }, [props.hideReadOnlyMessage])

  return (
    <>
      <div className={classNames(classes.banner, props.className)} data-cy={`formBanner-${props.test}`}>
        {props.children}
      </div>
      {!!showReadOnlyAlert && (
        <Alert
          severity="info"
          className={classes.alert}
          data-cy="alert-readOnly"
        >
          You are in Read-Only mode
        </Alert>
      )}
      <CollapsableAlert
        severity="error"
        data-cy="alert-error-message"
        closeClick={props.closeErrorMessage}
        showAlert={!!props.errorMessage}
        message={props.errorMessage}
      />
    </>
  );
};

export default FormBanner;
