import { useCallback, useLayoutEffect, useRef, useState } from "react";
import FormContext, { initialContext } from "contexts/form.context";
import { validateField } from "utils/formValidators";
import { serializeFormInput } from "utils/formHelpers";


/*
 * Extension of the usual <form> to be better suited for JavaScript/React implementation
 *
 * The `validations` prop accepts an object with field `name` as the key,
 *   and an array of validation objects in the following format:
 * validations={{
 *   Title: [{
 *     type: "maxLength",            // List of types found in formValidators.js
 *     argument: 45,                 // Comparison value needed for some validation types
 *     message: "That's kinda long"  // Optional custom message, replacing default
 *     required: true                // Defaults to false
 *   }]
 * }}
 */
export default function Form(props) {
  const { children, onChange, onSubmit, onError, validations, ...passThroughProps } = props;

  const [formContext, setFormContext] = useState(initialContext);
  const [isContextReady, setIsContextReady] = useState(false);

  const formRef = useRef();
  const isSubmitInProgressRef = useRef(false);

  const publishFormChange = useCallback((formContextState, name, value, event = null) => {
    const values = { ...formContextState.values, [name]: value };
    setFormContext({ ...formContextState, values });
    onChange?.(event, name, value);
  }, [onChange, setFormContext]);

  const publishFormError = useCallback((formContextState, name, error, event) => {
    const errors = { ...formContextState.errors };
    if (error) {
      errors[name] = error;
    } else {
      delete errors[name];
    }
    setFormContext({ ...formContextState, errors });
    if (error) {
      onError?.(error, event);
    }
  }, [onError, setFormContext]);

  const formDataToObject = useCallback(() => {
    const data = {};
    const formData = new FormData(formRef.current);
    for (const key of formData.keys()) {
      data[key] = formData.get(key);
    }
    return data;
  }, []);

  // Set initial values
  useLayoutEffect(() => {
    const values = formDataToObject();
    setFormContext(
      { ...formContext, publishFormChange, publishFormError, validations, values }
    );
    setIsContextReady(true);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [formDataToObject, publishFormChange, publishFormError, setFormContext, validations]);

  const handleChange = useCallback(event => {
    const { name, value } = event.target;
    const values = { ...formContext.values, [name]: value };
    setFormContext({ ...formContext, values });
    onChange?.(event, name, value);
  }, [formContext, onChange]);

  const handleValidate = useCallback((data) => {
    const errors = {};
    const validationEntries = Object.entries(validations || {});
    for (const [fieldName, fieldValidations] of validationEntries) {
      if (fieldName in data) {
        const fieldError = validateField(fieldValidations, data[fieldName]);
        if (fieldError) {
          errors[fieldName] = fieldError;
        }
      }
    }
    return errors;
  }, [validations]);

  const handleSubmit = useCallback(async event => {
    event.preventDefault();
    if (!onSubmit) {
      return;
    }
    try {
      const data = {};
      const formData = new FormData(event.target);
      for (const key of formData.keys()) {
        const value = formData.get(key);
        data[key] = serializeFormInput(value);
      }

      const validationErrors = handleValidate(data, formData);
      setFormContext({ ...formContext, errors: validationErrors, values: data });
      if (
        isSubmitInProgressRef.current ||
        Object.keys(validationErrors).length > 0
      ) {
        if (onError) {
          onError(validationErrors, null, event);
        }
        return;
      }
      isSubmitInProgressRef.current = true;
      const submitResult = await onSubmit(data, formData, event);
      return submitResult;
    } catch(error) {
      if (onError) {
        return onError(null, error, event);
      } else {
        throw error;
      }
    } finally {
      // ESLint has false positive with refs + finally block.
      // See https://github.com/eslint/eslint/pull/13915
      /* eslint-disable-next-line */
      isSubmitInProgressRef.current = false;
    }
  }, [handleValidate, formContext, onError, onSubmit]);

  if (!isContextReady) {
    return null;
  }
  return (
    <form
      {...passThroughProps}
      ref={formRef}
      onChange={handleChange}
      onSubmit={handleSubmit}
    >
      <FormContext.Provider value={formContext}>
        {children}
      </FormContext.Provider>
    </form>
  );
}
