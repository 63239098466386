import React, { useState, useRef, useMemo, useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Paper, Popover } from "@material-ui/core";
import variables from "styleVariables";
import CustomModal from "components/utils/modal.component";
import TaskForm from "components/forms/taskForm.component";
import ListAltIcon from "@material-ui/icons/ListAlt";
import CompTasksContainer from "components/tasks/compTasksContainer.component";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import Tooltip from "components/utils/tooltip.component";
import { isReadOnly } from "utils/roles";
import classNames from "classnames";
import { currentUserInfo } from "utils/userHelpers";
import Badge from "components/utils/badge.component";
import useNumericParams from "hooks/useNumericParams";
import WorkpackageTasksContext from "contexts/workpackageTasks.context";

const useStyles = makeStyles((theme) => ({
  tasksContainer: {
    padding: "15px 35px 10px 25px",
    position: "absolute",
    right: 0,
    maxWidth: 155,
    width: "100%",
    marginTop: 75,
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    borderRadius: "10px 0px 0px 10px",
  },
  tasksWrapper: {
    display: "flex",
    alignItems: "center",
    minWidth: 100,
    width: "100%",
    justifyContent: "space-around",
  },
  buttonContainer: {
    lineHeight: "normal",
  },
  taskIcon: {
    fontSize: 28,
    fill: variables.tertiary2,
    "&:hover": {
      cursor: "pointer",
      fill: variables.primaryMain,
    },
  },
  addTaskIcon: {
    marginTop: 3,
    fontSize: 33,
    fill: variables.tertiary2,
    "&:hover": {
      cursor: "pointer",
      fill: variables.primaryMain,
    },
  },
  disabled: {
    marginTop: 3,
    fontSize: 33,
    fill: variables.grayDisabled,
  },
  iconBadgeWrapper: {
    display: "flex",
    alignItems: "center",
  },
  fullScreenView: { // used for fullScreenView, when full screen dialog is used
    padding: "7px 28px 7px 18px",
    marginTop: 10,
    right: 0
  }
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: -10,
    border: `1px solid white`,
    padding: "0 2px",
    fontSize: variables.fontXs,
    height: 20,
    fontWeight: "bold",
    borderRadius: 40,
    backgroundColor: variables.primaryLight,
  },
}))(Badge);

export const TaskManager = (props) => {
  const classes = useStyles();
  const { state } = useContext(WorkpackageTasksContext);

  const user = useMemo(currentUserInfo, []);
  const readOnly = useMemo(isReadOnly, []);
  // for modal
  const [editModal, setEditModal] = useState(false);
  const anchorRef = useRef();
  //for popover
  const [anchorEl, setAnchorEl] = useState(null);
  const [refresh, setRefresh] = useState(true);

  const open = Boolean(anchorEl);
  const id = open ? "comp-task-popover" : undefined;
  const { programId, componentObjectId } = useNumericParams();

  const componentObjectTasks = useMemo(() => {
    const programTasks = state.tasksByProgramId?.[programId];
    return programTasks?.filter?.(task => (
      task.Component_Component_Object_ID === componentObjectId
    )) || [];
  }, [state.tasksByProgramId, programId, componentObjectId]);

  const userTasks = useMemo(() => (
    componentObjectTasks?.filter?.(task => (
      task.Assigned_User === user.id
    )) || []
  ), [user, componentObjectTasks]);

  const toggleForm = () => {
    setEditModal(!editModal);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openTasks = (event) => {
    setAnchorEl(anchorRef.current);
  };

  return (
    <Paper
      className={
        classNames(
          classes.tasksContainer,
          props.fullScreenView && classes.fullScreenView
        )
      }
      data-cy="container-header-tasks"
    >
      <div className={classes.tasksWrapper}>
        {/* BADGE */}
        <Tooltip
          title={`View ${props.title} Tasks`}
          placement="bottom"
          data-cy="tooltip-viewTask"
        >
          <div
            ref={anchorRef}
            className={classes.iconBadgeWrapper}
            onClick={() => openTasks()}
            data-cy="icon-click-task"
          >
            <ListAltIcon className={classes.taskIcon} />
            {!!userTasks && (
              <StyledBadge
                alertCount={userTasks.length}
                color="secondary"
                data-cy="badge-assignedTask"
              />
            )}
          </div>
        </Tooltip>

        <div className={classes.buttonContainer}>
          {readOnly ? (
            <Tooltip
              title={`Add ${props.title} Task`}
              placement="bottom"
              disabled
            >
              <PlaylistAddIcon
                className={classes.disabled}
                data-cy="icon-add-task-readOnly"
              />
            </Tooltip>
          ) : (
            <Tooltip title={`Add ${props.title} Task`} placement="bottom">
              <PlaylistAddIcon
                className={classes.addTaskIcon}
                onClick={() => setEditModal(true)}
                data-cy="icon-add-task"
              />
            </Tooltip>
          )}
        </div>
        <CustomModal open={editModal} onClose={toggleForm}>
          <TaskForm
            setEditModal={setEditModal}
            setRefresh={setRefresh}
            formMode="create"
            formType="task"
            parentComponent="TaskManager"
          />
        </CustomModal>
        {!!componentObjectTasks && !!userTasks && (
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <CompTasksContainer
              allTasks={componentObjectTasks}
              userTasks={userTasks}
              title={props.title}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </Popover>
        )}
      </div>
    </Paper>
  );
};

export default TaskManager;
