import React from "react";
import CustomSelect from "components/utils/form-elements/select.component";
import { MenuItem } from "@material-ui/core";


const PortCoAssessmentPicker = props => {
  const {
    label,
    disabled,
    readOnly,
    onChange,
    assessments,
    selectedAssessmentId,
    selectedPortCoId,
    ...muiProps
  } = props;

  return (
    <CustomSelect
      name="HolderAssessment_ID"
      label={label}
      placeholder="Select an Assessment"
      value={selectedAssessmentId || ""}
      onChange={onChange}
      test="select-PortCoAssessment"
      disabled={disabled}
      readOnly={assessments?.length === 1 || readOnly}
      labelInputRow
      {...muiProps}
    >
      {assessments?.map?.(assessment => (
        <MenuItem
          value={assessment.HolderAssessment_ID}
          key={assessment.HolderAssessment_ID}
          data-cy={`menu-PortCoAssessment-${assessment.HolderAssessment_ID}`}
        >
          {assessment.Assessment_Name}
        </MenuItem>
      ))}
    </CustomSelect>
  );
};

export default PortCoAssessmentPicker;
