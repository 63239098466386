import React, { useMemo } from "react";
import { Box, MenuItem } from "@material-ui/core";
import CustomSelect from "components/utils/form-elements/select.component";
import { sortByStringKey } from "utils/sortingFuncs";

const UserPicker = (props) => {
  const { potentialUsers, onChange, disabled, required } = props;
  const {
    name,
    label,
    labelId,
    value,
    defaultValue,
    placeholderDisplay,
    fixedWidth,
  } = props;

  const sortedMenuOptions = useMemo(
    () => sortByStringKey(potentialUsers || [], "First_Name"),
    [potentialUsers]
  );

  return (
    <CustomSelect
      name={name}
      labelId={labelId}
      label={label}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      test="userPicker"
      required={required}
      disabled={disabled}
    >
      {!!placeholderDisplay && (
        <MenuItem id="none" value="" disabled>
          {placeholderDisplay}
        </MenuItem>
      )}
      {sortedMenuOptions.map((user) => (
        <MenuItem value={user.User_ID} key={user.User_ID} data-cy={`option-userId-${user.User_ID}`}>
          {fixedWidth ? (
            <FixedWidthMenuContent user={user} />
          ) : (
            <DeafultMenuContent user={user} />
          )}
        </MenuItem>
      ))}
    </CustomSelect>
  );
};

const FixedWidthMenuContent = ({ user }) => {
  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      flexWrap="wrap"
    >
      <Box marginRight={0.5}>
        {user.First_Name}
      </Box>
      <Box>
        {user.Last_Name}
      </Box>
    </Box>
  );
};

const DeafultMenuContent = ({ user }) => {
  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
    >
      <Box width="100%" marginRight={1}>
        {user.First_Name}&nbsp;{user.Last_Name}
      </Box>
    </Box>
  );
};

export default UserPicker;
