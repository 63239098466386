import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import PrintIcon from '@material-ui/icons/Print';
import variables from "styleVariables";
import Tooltip from 'components/utils/tooltip.component';

const useStyles = makeStyles((theme) => ({
  button: {
    color: variables.tertiary1,
    "&:hover": {
      color: variables.secondaryMain
    },
  },
}));

const PrintIconButton = ({ fontSize, onClick }) => {
  const classes = useStyles()
  return (
    <Tooltip id="tooltip-print" title="Print" placement="left">
      <IconButton
        onClick={() => onClick()}
        className={classes.button}
        data-cy="btn-print-icon"
      >
        <PrintIcon fontSize={fontSize} />
      </IconButton>
    </Tooltip>
  )
}

export default PrintIconButton;
