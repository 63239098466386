import moment from "moment";

export const VALIDATION_REQUIRED = "required";
export const VALIDATION_MAX_LENGTH = "maxLength";
export const VALIDATION_BEFORE_DATE = "beforeDate";
export const VALIDATION_AFTER_DATE = "afterDate";

export function validate(validation, value) {
  switch(validation.type) {
    case VALIDATION_REQUIRED:
      return validateRequired(validation, value);
    case VALIDATION_MAX_LENGTH:
      return validateMaxLength(validation, value);
    case VALIDATION_BEFORE_DATE:
      return validateBeforeDate(validation, value);
    case VALIDATION_AFTER_DATE:
      return validateAfterDate(validation, value);
    default:
      throw new Error(`Unsupported validation type: "${validation.type}"`);
  }
}

// Returns first error, if any
export function validateField(fieldValidations, value) {
  const fieldValidationList = [].concat(fieldValidations);
  for (const validation of fieldValidationList) {
    const valueList = [].concat(value);
    for (const valueItem of valueList) {
      const error = validate(validation, valueItem);
      if (error) {
        return error;
      }
    }
  }
  return null;
}

export function validateRequired(validation, value) {
  let stringValue = value;
  if (value instanceof File) {
    stringValue = value.name;
  } else if (typeof value === "number") {
    if (isNaN(value)) {
      stringValue = "";
    } else {
      stringValue = `${value}`;
    }
  }
  const trimmed = stringValue ? stringValue.trim() : "";
  if ((validation.required || validation.type === VALIDATION_REQUIRED) && !trimmed) {
    const message = validation.message || `Required field`;
    return { message, validation, stringValue };
  }
  return null;
}

export function validateMaxLength(validation, value) {
  const max = validation.argument;
  if (typeof value === "string" && value.length > max) {
    const message = validation.message || `Must be ${max} characters or less`;
    return { message, validation, value };
  }
  return null;
}
export function validateBeforeDate(validation, value) {
  const date = moment(value);
  const before = moment(validation.argument);
  if (date.isValid() && !date.isBefore(before, "seconds")) {
    const message = validation.message || `Must be before ${before.format("MM/DD/YYYY")}`;
    return { message, validation, value };
  }
  return null;
}

export function validateAfterDate(validation, value) {
  const date = moment(value);
  const after = moment(validation.argument);
  if (date.isValid() && !date.isAfter(after, "seconds")) {
    const message = validation.message || `Must be after ${after.format("MM/DD/YYYY")}`;
    return { message, validation, value };
  }
  return null;
}
