import { Box, Dialog } from "@material-ui/core";
import BackToLink from "components/utils/backToLink.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import Loader from "components/utils/loader.components";
import PageHeader from "components/utils/pageHeader.component";
import useNumericParams from "hooks/useNumericParams";
import { useEffect, useReducer } from "react";
import { generatePath } from "react-router-dom/cjs/react-router-dom.min";
import oneRunbookReducer, { ACTION_SET_RUNBOOK, ACTION_SET_RUNBOOK_PHASES, ACTION_SET_RUNBOOK_TASKS, ACTION_SET_WORKSTREAMS, ACTION_SET_WORKGROUPS, oneRunbookInitialState, ACTION_SET_RUNBOOK_TASK_DEPENDENCIES, ACTION_SET_WORKSTREAM_MEMBERS, ACTION_SET_WORKGROUP_MEMBERS, ACTION_SET_RUNBOOK_TASK_CHANGES, ACTION_SET_RUNBOOK_INCIDENT } from "reducers/runbook/oneRunbook.reducer";
import { ACTION_SET_USERS } from "reducers/shared/sharedActions";
import { batchDispatch } from "reducers/utils/dispatchUtils";
import RunbookService from "services/runbook.service";
import userService from "services/user.service";
import AssignedTasksPanel from "./assignedTasksPanel.component";
import IncidentsPanel from "./incidentsPanel.component";
import CreateRunbookTaskModal from "./modals/createRunbookTaskModal.component";
import RunbookTaskTable from "./tables/runbookTaskTable.component";
import TaskChangesPanel from "./taskChangesPanel.component";


export default function RunbookViewTablePage(props) {
  const params = useNumericParams();
  const { runbookId } = params;
  const { runbooks } = props;

  const [state, dispatch] = useReducer(oneRunbookReducer, oneRunbookInitialState);

  useEffect(() => {
    if (!state.runbook) {
      const activeRunbook = runbooks.find(runbook => (
        runbook.Runbook_ID === runbookId
      ));
      if (activeRunbook) {
        dispatch({ type: ACTION_SET_RUNBOOK, payload: activeRunbook });
      }
    }
  }, [runbookId, runbooks, state.runbook]);

  useEffect(() => (
    (async function fetchRequiredData() {
      const responses = await Promise.all([
        RunbookService.getAllIncidentsByRunbook(runbookId),
        RunbookService.getTasksByRunbook(runbookId),
        RunbookService.getTaskChangesByRunbook(runbookId),
        RunbookService.getTaskDependenciesByRunbook(runbookId),
        RunbookService.getPhasesByRunbook(runbookId),
        userService.getAll(),
        RunbookService.getAllWorkgroups(),
        RunbookService.getWorkgroupMembersByRunbook(runbookId),
        RunbookService.getAllWorkstreams(),
        RunbookService.getWorkstreamMembersByRunbook(runbookId),
      ]);
      batchDispatch(
        dispatch,
        responses,
        [
          ACTION_SET_RUNBOOK_INCIDENT,
          ACTION_SET_RUNBOOK_TASKS,
          ACTION_SET_RUNBOOK_TASK_CHANGES,
          ACTION_SET_RUNBOOK_TASK_DEPENDENCIES,
          ACTION_SET_RUNBOOK_PHASES,
          ACTION_SET_USERS,
          ACTION_SET_WORKGROUPS,
          ACTION_SET_WORKGROUP_MEMBERS,
          ACTION_SET_WORKSTREAMS,
          ACTION_SET_WORKSTREAM_MEMBERS
        ]
      );
    })()
  ), [runbookId]);

  return (
    <Dialog fullScreen open>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        paddingRight={6}
        paddingBottom={8}
        paddingLeft={6}
      >
        <PageHeader
          title={state.runbook?.Name ? `Runbook View - ${state.runbook.Name}` : "Runbook View"}
          titleOnly
        />
        <BackToLink
          parentPage="Runbooks"
          href={generatePath("/program/:programId/:programComponentId", params)}
        />
        {(!state.runbook || !state.runbookTasks || !state.runbookTaskDependencies) ? (
          <Loader />
        ) : (
          <>
            <Box flex={1} marginTop={2}>
              <RunbookTaskTable
                state={state}
                dispatch={dispatch}
                runbookId={runbookId}
              />
              <Box marginTop={1}>
                <CreateRunbookTaskModal
                  state={state}
                  dispatch={dispatch}
                  runbookId={runbookId}
                />
              </Box>
            </Box>
            <Box marginTop={4}>
              <GridContainer spacing={6}>
                <GridItem lg={4} md={12} xs={12}>
                  <IncidentsPanel
                    state={state}
                    dispatch={dispatch}
                  />
                </GridItem>
                <GridItem lg={4} md={6} xs={12}>
                  <AssignedTasksPanel
                    state={state}
                    dispatch={dispatch}
                  />
                </GridItem>
                <GridItem lg={4} md={6} xs={12}>
                  <TaskChangesPanel
                    state={state}
                    dispatch={dispatch}
                  />
                </GridItem>
              </GridContainer>
            </Box>
          </>
        )}
      </Box>
    </Dialog>
  );
}
