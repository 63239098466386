import http from "./http-common";
import authHeader from "./authHeader";

class AssessmentService {

  // get list of Group Product
  getGroupProducts(programId) {
    return http
      .get(`/assessment/groupProducts/${programId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get Get Privacy Mgmt Principle Assessment
  getPrivacyMgmtPrincAssessment(programId) {
    return http
      .get(`/assessment/mgmtPrincipleAssessment/${programId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  // get Product Privacy Mgmt Principle Assessment
  getProductPrivacyMgmtPrincAssessment(productId) {
    return http
      .get(`/assessment/productMgmtPrincAssessment/${productId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  updatePrincipleAssessmentItems(assessmentItems) {
    return http
      .put(
        `/assessment/principleAssessment/list`,
        assessmentItems,
        { headers: authHeader() }
      )
      .then(response => response);
  }
}

export default new AssessmentService();
