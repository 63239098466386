import React from "react";
import { Tab } from "@material-ui/core";
import { Draggable } from "react-beautiful-dnd";

// Use with DraggableTabs component
const DraggableTab = ({ draggableId, draggableIndex, isDragDisabled, ...otherProps }) => (
  <Draggable
    draggableId={`id-${draggableId}`}
    index={draggableIndex}
    isDragDisabled={isDragDisabled}
    disableInteractiveElementBlocking
  >
    {provided => (
      <Tab
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        {...otherProps}
      />
    )}
  </Draggable>
);

export default DraggableTab;
