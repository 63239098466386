
import AuthService from "./auth.service";
import AssessmentService from "./assessment.service";
// import ComplianceService from "./compliance.service";
import ControlService from "./control.service";
import FileService from "./file.service";
import ImplementationService from "./implementation.service";
import RiskAssessmentService from "services/riskAssessment.service"

// Add any service call that needs to be called dynamically here
const registry = {
  authLogout: AuthService.logout,
  authLogin: AuthService.login,

  /* CUSTOM */
  // assessments mgmt princ TODO: switch to TABLE when backend is updated
  assessmentGetGroupProducts: AssessmentService.getGroupProducts, //takes programId
  assessmentGetPrivacyMgmtPrincAssessment: AssessmentService.getPrivacyMgmtPrincAssessment, //takes programId
  // imp-tools dataMap & imp-tools dataInterfaces
  implementationGetOrganizationGroupProducts: ImplementationService.getOrganizationGroupProducts, //takes groupId
  implementationGetProgramOrganizationGroups: ImplementationService.getProgramOrganizationGroups, // takes programId
  // imp-tools dataMap
  implementationGetGroupProductDataMaps: ImplementationService.getGroupProductDataMaps, //takes productId
  //imp-tools dataInterfaces
  implementationGetGroupProductInterfaces: ImplementationService.getGroupProductInterfaces, //takes productId
  // locations
  implementationGetGroupProductLocations: ImplementationService.getGroupProductLocations, //takes productId

  /* DATATABLE */
  //controlMatrix
  controlGetPrivacyManagementControlMatrix: ControlService.getPrivacyManagementControlMatrix, //takes programId
  //complianceGetProgramRegulations: ComplianceService.getProgramRegulations, //takes programId
  //complianceGetComplianceMatrixData: ComplianceService.getComplianceMatrixData, //takes programId
  //for onClick
  //complianceGetRegSectionById: ComplianceService.getRegSectionById, //takes sectionId

  /* DOCUMENT */
  fileGetAllByComponentObjectId: FileService.getAllByComponentObjectId, //takes compObjId

  /* TABLE */
  // imp-tools dataScope TODO: switch to CUSTOM when backend is updated
  implementationGetGroupProducts: ImplementationService.getGroupProducts, //takes programId
  implementationGetImplementationDataScope: ImplementationService.getImplementationDataScope, //takes programId
  implementationGetImplementationSubProducts: ImplementationService.getImplementationSubProducts, //no parameter
  // imp-tools mgmt princ table
  implementationGetManagementPrinciples: ImplementationService.getManagementPrinciples,//takes ProgramId

  /* Assessment Source Item Views */
  CharterView: RiskAssessmentService.getCharterViewByComponentID, //takes componentId
  PoliciesAndProceduresView: RiskAssessmentService.getPolicyProcedureViewByComponentID, //takes componentId
  PlansAndRoadmapsView: RiskAssessmentService.getPlanRoadmapViewByComponentID, //takes componentId
  ToolsView: RiskAssessmentService.getToolsViewByComponentID, //takes componentId
};

export { registry };
