/*
Custom Table

This table has three input props:

  - title
      This is displayed at the top of the table
  - header REQUIRED
      This is an array of objects for each header cell in the table.
      - name: the key of the column corresponding to the cell
      - value: the rendered display value of the cell
  - alignment ["left - DEFAULT", "center", "right"]
      Sets the alignment for all columns
  - data
      This is an array of JSON objects, each object is a row in the table.
      Cell keys are matched to column `name`, present in the header items

  Currently used in:
  implementationTools/dataMap >> gridView & autoscroll
  implementationTools/locations >> used twice
  implementationTools/privacyMgmtPrinc
  assessments/pia
  assessments/managementPrinc >> gridView
*/

import React, { useRef, useMemo } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TableCell from "components/utils/tables/shared/tableCell.component";
import HeaderPrimary from "components/utils/header.component.js";
import useTargetTableRowViaLink from "hooks/useTargetTableRowViaLink";
import { makeStyles } from "@material-ui/core/styles";
import variables from "styleVariables";
import classNames from "classnames";


const useStyles = makeStyles((theme) => ({
  tableMarginTop: {
    borderCollapse: "collapse",
  },
  table: {
    width: "calc(100% - 1px)"
  },
  cellHeader: {
    backgroundColor: variables.tertiary1,
    color: "white",
    fontWeight: "bold",
    fontSize: variables.fontLarge,
    padding: "10px 20px",
    lineHeight: "normal",
    position: "sticky",
    top: 0,
    zIndex: 1
  },
  cellSuperHeader: {
    backgroundColor: variables.primaryMain,
    color: "white",
    fontWeight: "bold",
    fontSize: variables.fontLarge,
    padding: "12px 20px",
    lineHeight: "normal",
  },
  cellSubHeader: {
    backgroundColor: variables.primaryMain,
    color: "white",
    fontWeight: "bold",
    fontSize: variables.fontSmall,
    padding: "5px 5px",
    lineHeight: "normal",
    position: "sticky",
    top: 78,
  },
  // HEAD CELLS
  cellDenseHeader: {
    backgroundColor: variables.tertiary1,
    color: "white",
    fontWeight: "bold",
    fontSize: variables.fontSmall,
    padding: "5px 5px",
    lineHeight: "normal",
    position: "sticky",
    top: 0,
  },
  cellMgmtPrincHeader: {
    backgroundColor: variables.secondaryMain,
    fontWeight: "bold",
    fontSize: variables.fontLarge,
    padding: "10px 20px",
    lineHeight: "normal",
    fontFamily: "Helvetica",
  },
  // ROW HIGHLIGHT
  cellLevelOne: {
    padding: "10px 20px",
    backgroundColor: variables.rowHighlight,
    color: "white",
    borderRight: `1px solid ${variables.rowBorderDark}`,
    borderLeft: "none",
    borderTop: "none",
    borderBottom: "none",
    fontSize: variables.fontSmall,
  },
  // ROW HIGHLIGHT For Prcedure Builder Table
  cellProcLevelOne: {
    padding: "10px 20px",
    backgroundColor: variables.rowHighlight, //CHANGE COLOR
    color: "white",
    borderRight: "none",
    borderLeft: "none",
    borderTop: "none",
    borderBottom: "none",
    fontSize: variables.fontSmall,
  },
  cellBody: { //default cell body
    padding: "10px 20px",
    color: variables.textSecondary,
    fontFamily: "Helvetica",
    fontSize: variables.fontSmall,
    border: "1px solid rgba(22, 76, 146, 0.22)",
  },
  cellBodyGrid: { //cell body if props.gridView is set to true
    padding: "10px 20px",
    color: variables.textSecondary,
    backgroundColor: "white",
    fontFamily: "Helvetica",
    fontSize: variables.fontSmall,
    border: "1px solid rgba(22, 76, 146, 0.22)",
    borderRight:"none",
  },
  cellBodyBold: {
    padding: "10px 20px",
    color: variables.textSecondary,
    backgroundColor: "white",
    fontFamily: "Helvetica",
    fontWeight: "bold",
    borderRight: "1px solid rgba(22, 76, 146, 0.22)",
  },
  cellDenseBody: {
    padding: "5px 5px",
    maxWidth: "300px",
    color: variables.textSecondary,
    fontFamily: "Helvetica",
    fontSize: variables.fontSmall,
    border: "1px solid rgba(22, 76, 146, 0.22)",
  },
  centerAlign: {
    textAlign: "center",
  },
  leftAlign: {
    textAlign: "left",
  },
  rightAlign: {
    textAlign: "right",
  },
  headerWrapper: {
    paddingBottom: 10,
  },
  fromSearch: {
    backgroundColor: variables.chosenElement,
  },
  borderedHeaderCell: {
    borderLeft: "1px solid white",
    borderBottom: "1px solid white",
    borderRight: "1px solid white",
    '&:first-of-type': {
      borderLeft: "none",
    },
    '&:last-of-type': {
      borderRight: "none",
    },
  },
}));

/*
 * DEPRECATED COMPONENT
 *
 * For all new Tables:
 * Use SortableTable for basic or better-performant tables,
 * Use DataTable for complex tables that need features of mui-datatables plugin
 */
const CustomTable = (props) => {
  const classes = useStyles();
  const rowRef = useRef({});
  const [targetedRowId] = useTargetTableRowViaLink(rowRef, !!props.data.length);

  // check for variants/alignment here
  // returns a className object
  const setClasses = (cellType, cellAlignment) => {
    let classString = ""; // holds all classes before building object

    // check cellType
    if (cellType === "mgmtPrincHeader") {
      classString += ` ${classes.cellMgmtPrincHeader}`;
      classString += ` ${classes.cellHeader}`;
    } else if (props.denseHeader && cellType === "header") {
      classString += ` ${classes.cellDenseHeader}`;
    } else if (cellType === "header") {
      classString += ` ${classes.cellHeader}`;
    } else if (cellType === "levelOne") {
      classString += ` ${classes.cellLevelOne}`;
    } else if (cellType === "procLevelOne") {
      classString += ` ${classes.cellProcLevelOne}`;
    } else if (gridView && cellType === "body") {
      classString += ` ${classes.cellBodyGrid}`;
    } else if (props.denseBody && cellType === "body") {
      classString += ` ${classes.cellDenseBody}`;
    } else if (cellType === "body") {
      classString += ` ${classes.cellBody}`;
    } else if (cellType === "superHeader") {
      classString += ` ${classes.cellSuperHeader}`;
    } else if (cellType === "subHeader") {
      classString += ` ${classes.cellSubHeader}`;
    }

    // check style - for future table variants
    if (variant) {
      // add the given variant styling to the array here
    }

    // check alignment
    if (cellAlignment === "left") {
      classString += ` ${classes.leftAlign}`;
    } else if (cellAlignment === "right") {
      classString += ` ${classes.rightAlign}`;
    } else if (cellAlignment === "center") {
      classString += ` ${classes.centerAlign}`;
    }

    // build/return className object from string above
    return classString;
  };


  const {
    title,
    headerCellStyles, // accepts an object to style individual header cells.
    //key is header cells index and value is the style to pass in for inline styling.
    // ie {0: { backgroundColor: variables.primaryMain}, 1: { backgroundColor: variables.primaryMain} }
    alignment: alignmentProp,
    data,
    maxHeight,
    variant,
    mgmtPrincHeaders = [], //manangement principle headers to set diff class
    superHeader, //array of obj {label: string, colSpan: int, alignment: string, style: obj}
    subHeader, //array of obj {label: string, colSpan: int, alignment: string, style: obj}
    boldFirstColumn, // takes a string which should be first key of each row obj
    replaceBlank, //takes a string which renders in place of "" or null
    gridView
  } = props;

  const headerCls = title === "Management Principles" ? "mgmtPrincHeader" : "header";

  const header = useMemo(() => {
    if (typeof props.header?.[0] === "string") {
      return props.header.map(value => ({ name: value, value }));
    }
    return props.header;
  }, [props.header]);

  // check/build alignment if not included - defaults left
  const alignment = alignmentProp || Array(header.length).fill("left");


  if (props.variant === "implementationTools") {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.75 37.75" preserveAspectRatio="xMidYMid meet" className={classNames(classes.customIcon, props.className)}>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path className="cls-1"
              d="M18.23,26.14a6.27,6.27,0,0,1-4.44-1.66.75.75,0,0,1-.22-.53c0-2.1.7-7,.92-8.5A8.37,8.37,0,0,1,15.88.16a.79.79,0,0,1,.68.11.75.75,0,0,1,.31.61V7.64a1.63,1.63,0,1,0,3.26,0V.88a.75.75,0,0,1,.31-.61.79.79,0,0,1,.68-.11,8.36,8.36,0,0,1,1.37,15.3l.68,8.35a.75.75,0,0,1-.24.62A7,7,0,0,1,18.23,26.14Zm-3.15-2.52a5.68,5.68,0,0,0,6.56-.07L21,15.19s0-.09,0-.13a.74.74,0,0,1,.43-.72A6.86,6.86,0,0,0,21.63,2V7.64a3.13,3.13,0,1,1-6.26,0V2a6.86,6.86,0,0,0,.25,12.34.75.75,0,0,1,.43.7s0,.11,0,.14S15.15,21.09,15.08,23.62Z"
            />
            <path className="cls-1"
              d="M19.3,37.63H17.5a.58.58,0,0,1-.19,0l-1.49-.16-.37,0-.13,0a.74.74,0,0,1-.63-.67l-.43-4.47a13.67,13.67,0,0,1-2-.84L8.77,34.24a.74.74,0,0,1-.91,0A18.42,18.42,0,0,1,3.5,29.91a.75.75,0,0,1,0-.91l2.85-3.46a13.35,13.35,0,0,1-.84-2l-4.46-.42a.75.75,0,0,1-.66-.57s0-.12,0-.13a17.1,17.1,0,0,1-.22-2l0-.17,0-.15V18.8a.59.59,0,0,1,0-.14l0-.11L.33,16.8l.06-.36,0-.14a.76.76,0,0,1,.67-.64l4.47-.42a12.77,12.77,0,0,1,.84-2L3.53,9.75a.75.75,0,0,1,0-.91A18.32,18.32,0,0,1,7.87,4.48a.74.74,0,0,1,.91,0L11.4,6.68a.75.75,0,0,1-1,1.16L8.27,6A16.54,16.54,0,0,0,5.06,9.25l2.81,3.39a.77.77,0,0,1,.07.85,12,12,0,0,0-1.1,2.65.72.72,0,0,1-.65.54l-4.38.42-.16,1.63s0,.1,0,.15V20a1.86,1.86,0,0,1,0,.33c0,.43.07.88.13,1.35l4.39.42a.74.74,0,0,1,.65.54,12.56,12.56,0,0,0,1.1,2.65.74.74,0,0,1-.07.85L5.06,29.5a16.83,16.83,0,0,0,3.21,3.21l3.4-2.81a.72.72,0,0,1,.84-.07,12.86,12.86,0,0,0,2.66,1.1.74.74,0,0,1,.54.65L16.13,36l1.35.14.1,0,2-.05L20.66,36l.41-4.38a.75.75,0,0,1,.55-.65,12.56,12.56,0,0,0,2.65-1.1.72.72,0,0,1,.84.07l3.41,2.81a16.78,16.78,0,0,0,3.2-3.21l-2.8-3.39a.76.76,0,0,1-.08-.85,11.78,11.78,0,0,0,1.1-2.65.76.76,0,0,1,.65-.54L35,21.65a15.94,15.94,0,0,0,.14-2.26A16.52,16.52,0,0,0,35,17.11l-4.23-.4A.87.87,0,0,1,29.9,16a11.53,11.53,0,0,0-1.06-2.52.81.81,0,0,1-.09-.37.55.55,0,0,1,0-.18.64.64,0,0,1,.15-.31l2.81-3.39a16.88,16.88,0,0,0-3.22-3.21L26.68,7.56a.75.75,0,1,1-.95-1.16L28,4.52a.75.75,0,0,1,.92,0,18.65,18.65,0,0,1,4.37,4.36.77.77,0,0,1,0,.92l-2.87,3.45a14.3,14.3,0,0,1,.85,2l4.47.43a.74.74,0,0,1,.66.61,17.86,17.86,0,0,1,.25,3.1,16.49,16.49,0,0,1-.26,3.09.74.74,0,0,1-.66.61l-4.46.42a13.39,13.39,0,0,1-.85,2L33.25,29a.75.75,0,0,1,0,.91,18.05,18.05,0,0,1-4.36,4.36.74.74,0,0,1-.91,0l-3.46-2.86a13.78,13.78,0,0,1-2,.84l-.42,4.47a.75.75,0,0,1-.56.65l-.18,0-2,.24Zm10.2-24.5h0Z"
            />
            <path className="cls-1"
              d="M18.38,26.63a7.77,7.77,0,0,1-7.76-7.75A7.67,7.67,0,0,1,13,13.32a.75.75,0,0,1,1.06,0,.75.75,0,0,1,0,1.06,6.15,6.15,0,0,0-1.91,4.48,6.25,6.25,0,1,0,12.5,0,6.18,6.18,0,0,0-1.79-4.38.75.75,0,1,1,1.07-1.05,7.75,7.75,0,0,1-5.52,13.18Z"
            />
          </g>
        </g>
      </svg>
    );
  }

  return (
    <>
      {!!title && title !== "Management Principles" && (
        <div className={classes.headerWrapper}>
          <HeaderPrimary variant="h3Primary">{title}</HeaderPrimary>
        </div>
      )}
      <TableContainer
        component={Paper}
        className={classes.tableMarginTop}
        style={{ maxHeight }}
      >
        <Table
          stickyHeader={!!props.stickyHeader}
          id={props.tableID}
          className={classes.table}
          data-cy={`table-${props.test}`}
        >
          <TableHead>
            {!!superHeader && (
              <TableRow>
                {superHeader.map((item, index) => (
                  <TableCell
                    className={setClasses("superHeader", item.alignment)}
                    colSpan={item.colSpan}
                    rowSpan={item.rowSpan}
                    style={item.style}
                    key={`superHead_${index}`}
                  >
                    {item.label}
                  </TableCell>
                ))}
              </TableRow>
            )}
            <TableRow>
              {header.map((item, index) => (
                <TableCell
                  className={classNames(setClasses(
                    (mgmtPrincHeaders.includes(item.value)) ? "header" : headerCls,
                    alignment[index]),
                    props.assessmentsMgmtPrinc && classes.borderedHeaderCell
                  )}
                  style={headerCellStyles?.[index]}
                  key={index}
                >
                  {item.value}
                </TableCell>
              ))}
            </TableRow>
            {!!subHeader && (
              <TableRow>
                {subHeader.map((item, index) => (
                  <TableCell
                    className={setClasses("subHeader", item.alignment)}
                    colSpan={item.colSpan}
                    style={item.style}
                    key={`subHead_${index}`}
                  >
                    {item.label}
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableHead>
          {/* EMPTY TABLE */}
          {(!data || data.length === 0) && (
            <TableBody>
              <TableRow>
                <TableCell
                  className={setClasses("body", "center")}
                  colSpan={header.length}
                  scope="row"
                >
                  {props.title ? `No ${title}` : `No data to display`}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {/* TABLE BODY */}
          {!!data && data.length > 0 && (
            <TableBody>
              {data.map((row, rowIndex) => {
                const orderedCellKeys = props.header.map((bodyHeader) => (bodyHeader.name.split('/').join('__').split(' ').join('_')));
                const cells = orderedCellKeys.filter(key => key).map((key, cellIndex) => {
                  // Covers both management principles tables
                  if (title === "Management Principles") {
                    const clsName = row.Level === "1" ? "levelOne" : "body";
                    if (key !== "Level") {
                      const refKey = row.Mgmt_Principle_ID;
                      if (key === "Label") {
                        return (
                          <TableCell
                            key={key}
                            className={classNames(
                              setClasses(clsName, alignment[cellIndex + 1]),
                              targetedRowId && targetedRowId === refKey &&
                                classes.fromSearch
                            )}
                            ref={(el) => (rowRef.current[refKey] = el)}
                          >
                            {row[key]}

                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell
                            key={key}
                            className={classNames(
                              setClasses(clsName, alignment[rowIndex + 1]),
                              targetedRowId && targetedRowId === refKey &&
                                classes.fromSearch
                            )}
                          >
                            {row[key]}
                          </TableCell>
                        );
                      }
                    }
                    // Procedure Builder Table Highight Row
                  } else if (props.procLevelOne) {
                    const clsName = row.Level === "1" ? "procLevelOne" : "body";
                    if (key !== "Level") {
                      return (
                        <TableCell
                          key={key}
                          className={classNames(
                              setClasses(clsName, alignment[rowIndex + 1]),
                          )}
                        >
                          {row[key]}
                        </TableCell>
                      );
                    }
                  } else if (key === "Type_Name") {
                    const refKey = row.DataType_ID;
                    return (
                      <TableCell
                        key={key}
                        ref={(el) => (rowRef.current[refKey] = el)}
                        className={classNames(
                          replaceBlank &&
                            (row[key] === "" || row[key] === null)
                            ? setClasses("body", "center")
                            : setClasses("body", alignment[rowIndex + 1]),
                          boldFirstColumn &&
                            key === props.boldFirstColumn &&
                            classes.cellBodyBold,
                            targetedRowId && targetedRowId === refKey &&
                            classes.fromSearch
                        )}
                      >
                        {replaceBlank &&
                        (row[key] === "" || row[key] === null)
                          ? replaceBlank
                          : row[key]}
                      </TableCell>
                    );
                  } else {
                    const refKey = props.defaultRefKey ? row[props.defaultRefKey] : row.DataType_ID;
                    return (
                      <TableCell
                        className={classNames(
                          replaceBlank &&
                            (row[key] === "" || row[key] === null)
                            ? setClasses("body", "center")
                            : setClasses("body", alignment[rowIndex + 1]),
                          boldFirstColumn &&
                            key === props.boldFirstColumn &&
                            classes.cellBodyBold,
                            targetedRowId && targetedRowId === refKey &&
                            classes.fromSearch
                        )}
                        key={`bodyCell-${key}`}
                      >
                        {replaceBlank &&
                        (row[key] === "" || row[key] === null)
                          ? replaceBlank
                          : row[key]}
                      </TableCell>
                    );
                  }
                  return null;
                });
                return (
                  <TableRow key={rowIndex}>
                    {cells.filter(cell => cell)}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default CustomTable;
