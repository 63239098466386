import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import FormBanner from "components/utils/form-elements/formBanner.component";
import { Grid, makeStyles } from "@material-ui/core";
import { useCallback, useState } from "react";
import CertificationService from "services/certification.service";
import { validEmail } from "utils/validators";
import {
  ACTION_ADD_CONTACT,
  ACTION_DEACTIVATE_CONTACT,
  ACTION_REPLACE_CONTACT,
} from "reducers/contacts.reducer";
import UserRolePicker from "components/utils/form-elements/userRolePicker.component";
import { contactListRoleOptions } from "utils/certificationConstants";
import { HTTP_STATUS_CONTENT } from "services/http-common";

const useStyles = makeStyles((theme) => ({
  userFormContainer: {
    display: "flex",
    flexDirection: "column",
  },
  userFormContent: {
    margin: "16px 32px 0px 32px",
  },
  defaultInputWrapper: {
    width: 250,
  },
  selectWrapper: {
    width: 240,
  },
  formRow: {
    paddingBottom: 4,
  },
}));

const DefaultInput = ({ ...props }) => {
  const classes = useStyles();
  return (
    <Grid item className={classes.defaultInputWrapper} sm={6}>
      <LabelInput {...props} variant="default" margin="dense" />
    </Grid>
  );
};

const validateFormErrors = (formState) => {
  const error = {};
  if (!formState.First_Name) {
    error.First_Name = "First Name is required.";
  }

  if (!formState.Last_Name) {
    error.Last_Name = "Last Name is required.";
  }

  if (!formState.Email) {
    error.Email = "Email is required.";
  } else if (!validEmail(formState.Email)) {
    error.Email = "The email format is invalid.";
  }
  return error;
};

const ContactForm = ({
  dispatch,
  closeForm,
  formMode,
  setEmailMessage,
  user,
  programId,
}) => {
  const classes = useStyles();
  const createMode = !user;
  const [formData, setFormData] = useState({
    User_ID: user?.User_ID,
    First_Name: user?.First_Name,
    Last_Name: user?.Last_Name,
    Email: user?.Email,
    Phone: user?.Phone,
    Organization: user?.Organization,
    Position: user?.Position,
    Role_ID: user?.Role_ID,
    UserInfo_ID: user?.UserInfo_ID,
  });
  const [formErrors, setFormErrors] = useState({});

  const changeHandler = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (formErrors[name]) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: false,
      }));
    }
    return null;
  };

  const selectHandler = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (formErrors[name]) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: false,
      }));
    }
  };

  const deactivateContact = async () => {
    const userInfoData = {
      UserInfo_ID: user.UserInfo_ID,
      Status: "Inactive",
    };

    await CertificationService.upsertContact({
      userInfoData,
      userData: { User_ID: user.User_ID },
    });

    dispatch({
      type: ACTION_DEACTIVATE_CONTACT,
      payload: user.User_ID,
    });
    closeForm();
  };

  const handleSaveUser = useCallback(
    () =>
      (async function() {
        setEmailMessage();
        const formValidations = validateFormErrors(formData);
        setFormErrors(formValidations);
        if (
          Object.values(formValidations).filter((error) => error !== false)
            .length > 0
        ) {
          return;
        }
        const userData = {
          User_ID: formData.User_ID,
          First_Name: formData.First_Name,
          Last_Name: formData.Last_Name,
          Email: formData.Email,
          Role_ID: formData.Role_ID,
          Phone: formData.Phone,
        };
        const userInfoData = {
          UserInfo_ID: formData.UserInfo_ID,
          Program_ID: programId,
          Organization: formData.Organization,
          Position: formData.Position,
        };

        const upsertUserRes = await CertificationService.upsertContact({
          userData,
          userInfoData,
        }).catch((err) => {
          if (err.response?.status === HTTP_STATUS_CONTENT) {
            setFormErrors({
              Email: "This email already exists in the system.",
            });
          }
        });

        if (!upsertUserRes) {
          return;
        }

        if (upsertUserRes.emailSent) {
          const userEmail = upsertUserRes.payload.Email;
          setEmailMessage(
            `A reset password email has been sent to ${userEmail}`
          );
        }
        dispatch({
          type: createMode ? ACTION_ADD_CONTACT : ACTION_REPLACE_CONTACT,
          payload: upsertUserRes.payload,
        });
        closeForm();
      })(),
    [dispatch, createMode, formData, setEmailMessage, programId, closeForm]
  );

  return (
    <div className={classes.userFormContainer} data-cy="contact-form-container">
      <FormBanner test="contact">{createMode ? "Create Contact" : "Edit Contact"}</FormBanner>
      <div className={classes.userFormContent}>
        <Grid className={classes.formRow} container spacing={2}>
          <DefaultInput
            label="First Name*"
            value={formData.First_Name || ""}
            onChange={changeHandler}
            name="First_Name"
            error={!!formErrors?.First_Name}
            errorMessage={formErrors?.First_Name}
          />

          <DefaultInput
            label="Last Name*"
            value={formData.Last_Name || ""}
            onChange={changeHandler}
            name="Last_Name"
            error={!!formErrors?.Last_Name}
            errorMessage={formErrors?.Last_Name}
          />
        </Grid>
        <Grid className={classes.formRow} container spacing={2}>
          <DefaultInput
            label="Email*"
            value={formData.Email || ""}
            onChange={changeHandler}
            name="Email"
            labelTooltip={
              createMode
                ? "If creating a user with portal access, an email with a link to set a password will be sent."
                : "If editing an email addresss of a user with portal access, an email with a link to reset password will be sent."
            }
            error={!!formErrors?.Email}
            errorMessage={formErrors?.Email}
          />

          <DefaultInput
            label="Phone"
            value={formData.Phone || ""}
            onChange={changeHandler}
            name="Phone"
          />
        </Grid>

        <Grid className={classes.formRow} container spacing={2}>
          <DefaultInput
            label="Organization"
            value={formData.Organization || ""}
            onChange={changeHandler}
            name="Organization"
          />
          <DefaultInput
            label="Position"
            value={formData.Position || ""}
            onChange={changeHandler}
            name="Position"
          />
        </Grid>
        <div className={classes.selectWrapper}>
          <UserRolePicker
            onChange={selectHandler}
            value={formData.Role_ID || ""}
            menuOptions={contactListRoleOptions}
            label="Portal Access"
          />
        </div>
      </div>
      <DualFormButtons
        cancelOnClick={closeForm}
        deleteText="Remove"
        saveOnClick={handleSaveUser}
        deleteClick={formMode === "edit" ? deactivateContact : null}
      />
    </div>
  );
};
export default ContactForm;
