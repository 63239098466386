
export function batchDispatch(dispatch, orderedActionData, orderedActionTypes) {
  if (orderedActionData.length !== orderedActionTypes.length) {
    throw new Error(`
      Responses and action type arrays must be equal and correspond to each other.
      
      Responses Length: ${orderedActionData.length}
      Action Types Length: ${orderedActionTypes.length}
    `);
  }
  orderedActionData.forEach((actionData, index) => {
    dispatch({
      ...actionData,
      type: orderedActionTypes[index]
    });
  });
}
