import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { RECONCILIATION_MODE_PERSONAL, RECONCILIATION_MODE_RECONCILE } from "components/reconciliation/utils/reconciliation.utils";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import Header from "components/utils/header.component";
import { useCallback, useMemo } from "react";
import { currentUserID } from "utils/userHelpers";

const useStyles = makeStyles(() => ({
  activeMode: {
    fontWeight: 600
  },
  header: {
    whiteSpace: "nowrap"
  },
  questionCount: {
    marginRight: 16
  },
  summary: {
    alignItems: "end",
  }
}));

const BLANK_COLUMN_VALUES = new Set(["", null, undefined]);


export default function QuestionsSummary(props) {
  const classes = useStyles();
  const {
    elements, countElements = false, gridItemProps = {}, mode, withTitle
  } = props;

  const currentUserId = useMemo(currentUserID, []);

  const filterCompletedResponses = useCallback(responses => (
    responses.filter(response => (
      !BLANK_COLUMN_VALUES.has(response.Answer)
    ))
  ), []);

  const groupResponses = useCallback(responses => {
    const results = { self: [], other: [], reconciled: [] };
    for (const response of responses) {
      if (response.Is_Reconciled) {
        results.reconciled.push(response);
      } else if (response.User_ID === currentUserId) {
        results.self.push(response);
      } else if (response.User_ID) {
        results.other.push(response);
      } else {
        console.error("Invalid response: ", response);
      }
    }
    return results;
  }, [currentUserId]);

  const elementsArray = useMemo(() => [].concat(elements), [elements]);


  const flattenedQuestions = useMemo(() => (
    elementsArray.flatMap(category => (
      category?._associations?.DctQuestion || []
    ))
  ), [elementsArray]);

  const allCategoryResponses = useMemo(() => (
    filterCompletedResponses(
      elementsArray.flatMap(category => (
        category._associations.DctResponse || []
      ))
    )
  ), [elementsArray, filterCompletedResponses]);

  const allQuestionResponses = useMemo(() => (
    filterCompletedResponses(
      flattenedQuestions.flatMap(question => (
        question._associations.DctResponse || []
      ))
    )
  ), [flattenedQuestions, filterCompletedResponses]);

  const {
    self: selfCategoryResponses,
    other: otherCategoryResponses,
    reconciled: reconciledCategoryResponses
  } = useMemo(() => (
    groupResponses(allCategoryResponses)
  ), [allCategoryResponses, groupResponses]);

  const {
    self: selfQuestionResponses,
    other: otherQuestionResponses,
    reconciled: reconciledQuestionResponses
  } = useMemo(() => (
    groupResponses(allQuestionResponses)
  ), [allQuestionResponses, groupResponses]);

  return (
    <div className={classes.summary}>
      <GridContainer>
        {!!withTitle && (
          <GridItem xs={12}>
            <Header variant="h4Primary">
              Answered DCT Questions
            </Header>
          </GridItem>
        )}
        <GridItem xs={12} md={4} {...gridItemProps} data-cy="your-answers">
          <Header className={classes.header} variant="h5Tertiary">
            Your Answers
          </Header>
          <QuestionCount
            active={mode === RECONCILIATION_MODE_PERSONAL}
            elements={elementsArray}
            relevantCategoryResponses={selfCategoryResponses}
            relevantQuestionResponses={selfQuestionResponses}
            respondableElements={countElements ? elementsArray : []}
          />
        </GridItem>
        <GridItem xs={12} md={4} {...gridItemProps} data-cy="other-answers">
          <Header className={classes.header} variant="h5Tertiary">
            Others&apos; Answers
          </Header>
          <QuestionCount
            elements={elementsArray}
            relevantCategoryResponses={otherCategoryResponses}
            relevantQuestionResponses={otherQuestionResponses}
            respondableElements={countElements ? elementsArray : []}
          />
        </GridItem>
        <GridItem xs={12} md={4} {...gridItemProps} data-cy="final-answers">
          <Header className={classes.header} variant="h5Tertiary">
            Final Reconcilitation
          </Header>
          <QuestionCount
            active={mode === RECONCILIATION_MODE_RECONCILE}
            elements={elementsArray}
            relevantCategoryResponses={reconciledCategoryResponses}
            relevantQuestionResponses={reconciledQuestionResponses}
            respondableElements={countElements ? elementsArray : []}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

function QuestionCount(props) {
  const classes = useStyles();
  const {
    active, elements, relevantCategoryResponses, relevantQuestionResponses,
    respondableElements
  } = props;

  const relevantCategoryResponseIds = useMemo(() => (
    new Set(relevantCategoryResponses.map(response => response.DctObject_ID))
  ), [relevantCategoryResponses]);

  const relevantQuestionResponseIds = useMemo(() => (
    new Set(relevantQuestionResponses.map(response => response.DctObject_ID))
  ), [relevantQuestionResponses]);

  const questions = useMemo(() => (
    elements
      .flatMap(category => category._associations.DctQuestion)
      .filter(question => question)
  ), [elements]);

  const completedCategoryResponses = useMemo(() => (
    respondableElements.filter(category => (
      relevantCategoryResponseIds.has(category.DctCategory_ID)
    ))
  ), [respondableElements, relevantCategoryResponseIds]);

  const completedQuestions = useMemo(() => (
    questions.filter(question =>
      relevantQuestionResponseIds.has(question.DctQuestion_ID)
    )
  ), [questions, relevantQuestionResponseIds]);

  return (
    <div
      className={
        classNames(classes.questionCount, active && classes.activeMode)
      }
    >
      {completedCategoryResponses.length + completedQuestions.length}
      &nbsp;of&nbsp;
      {respondableElements.length + questions.length} completed
    </div>
  );
}
