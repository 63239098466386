import { useMemo, useState } from "react";
import moment from "moment";
import Loader from "components/utils/loader.components";
import DataTable from "components/utils/tables/dataTable.component";
import {
  createNodeCellParams,
  createNodeHeaderParams,
  createValueHeaderParams,
} from "components/utils/tables/utils/dataTableHelpers";
import { Box, Dialog, makeStyles } from "@material-ui/core";
import CustomEditIcon from "components/utils/editIcon.component";
import RunbookForm from "components/forms/runbookForm.component";
import { DATE_FORMAT_DISPLAY_NUMERIC } from "utils/dateConstants";

const useStyles = makeStyles((theme) => ({
  phases: {
    padding: "0px 0px 0px 12px",
    marginTop: 0,
    marginBottom: 0,
  },
}));

const defaultCellStyle = { verticalAlign: "top" };

const tableHeaders = [
  createNodeHeaderParams("Runbook_ID", " ", {
    filter: false,
    sort: false,
    setCellProps: () => ({ style: { ...defaultCellStyle, width: 20, backgroundColor: "white" } }),
  }),
  createValueHeaderParams("Name", "Name", {
    filter: false,
    setCellProps: () => ({ style: { ...defaultCellStyle } }),
  }),
  createValueHeaderParams("Description", "Description", {
    filter: false,
    sort: false,
    setCellProps: () => ({ style: { ...defaultCellStyle } }),
  }),
  createValueHeaderParams("Status", "Status", {
    setCellProps: () => ({ style: { ...defaultCellStyle, width: 30, textAlign: "center" } }),
  }),
  createNodeHeaderParams("Start_Date", "Start", {
    filter: false,
    sort: true,
    setCellProps: () => ({ style: { ...defaultCellStyle } }),
  }),
  createNodeHeaderParams("End_Date", "End", {
    filter: false,
    sort: true,
    setCellProps: () => ({ style: { ...defaultCellStyle } }),
  }),
  createNodeHeaderParams("Phases", "Phases", {
    filter: false,
    sort: false,
    setCellProps: () => ({ style: { ...defaultCellStyle } }),
  }),
  createValueHeaderParams("Phase_Search_Data", "Phase_Search_Data", {
    filter: false,
    display: "excluded",
    setCellProps: () => ({ style: { ...defaultCellStyle } }),
  }),
];

const tableOptions = {
  filterType: "checkbox",
  fixedHeader: true,
  fixedSelectColumn: true,
  filter: true,
  pagination: false,
  selectableRowsHideCheckboxes: true,
  viewColumns: false,
  download: false,
  print: false,
  sortOrder: { order: "asc" },
}

const makePhaseNameStrings = (arr) => {
  return arr.reduce((string, phase) => {
    return string + `${phase?.Name}, `;
  }, "");
};

export default function RunbooksAdminSection(props) {
  const { runbooks, allRunbookPhases } = props.state;
  const { dispatch, setIsRunbookFormOpen, isRunbookFormOpen } = props;
  const [runbookToEdit, setRunbookToEdit] = useState(null);
  const tableCells = useMemo(() => {
    if (!runbooks) {
      return null;
    }

    return runbooks.map((runbook) => {
      // const runbookId = runbook.Runbook_ID;
      const { Name, Description, Status } = runbook;
      const phases = runbook?._associations?.RunbookPhases || [];

      const startDateDisplay = !runbook.Start_Date ? "" : (
        moment(runbook.Start_Date).format(DATE_FORMAT_DISPLAY_NUMERIC)
      );
      const endDateDisplay = !runbook.End_Date ? "" : (
        moment(runbook.End_Date).format(DATE_FORMAT_DISPLAY_NUMERIC)
      );

      return {
        Runbook_ID: createNodeCellParams(
          null,
          null,
          <CustomEditIcon
            onClick={() => {
              setIsRunbookFormOpen(true);
              setRunbookToEdit(runbook);
            }}
            variant="matrix"
            test="runbook"
          />
        ),
        Name,
        Description,
        Start_Date: createNodeCellParams(
          runbook.Start_Date,
          startDateDisplay,
          startDateDisplay
        ),
        End_Date: createNodeCellParams(
          runbook.End_Date,
          endDateDisplay,
          endDateDisplay
        ),
        Status,
        Phases: createNodeCellParams(null, <PhasesCell phases={phases} />),
        Phase_Search_Data: phases.length ? makePhaseNameStrings(phases) : " "
      };
    });
  }, [runbooks, setIsRunbookFormOpen]);

  if (!tableCells) {
    return <Loader />;
  }
  return (
    <Box>
      <DataTable
        data={tableCells}
        columns={tableHeaders}
        options={{...tableOptions}}
      />
      <Dialog
        maxWidth="md"
        fullWidth
        open={isRunbookFormOpen}
        onClose={() => {
          setIsRunbookFormOpen(false);
          setRunbookToEdit({});
        }}
      >
        <RunbookForm
          setIsRunbookFormOpen={setIsRunbookFormOpen}
          setRunbookToEdit={setRunbookToEdit}
          runbooks={runbooks}
          allRunbookPhases={allRunbookPhases}
          dispatch={dispatch}
          runbookToEdit={runbookToEdit}
        />
      </Dialog>
    </Box>
  );
}

const PhasesCell = ({ phases }) => {
  const classes = useStyles();

  return (
    <ol className={classes.phases}>
      {phases.map((phase) => (
        <li key={phase.Phase_ID}>{phase.Name}</li>
      ))}
    </ol>
  );
};
