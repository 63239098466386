import React, { useState, useEffect, useMemo, useCallback, useRef, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import {
  programDocCompNames,
  controlsCompNames,
  programsCompNames,
  complianceCompNames,
  requirementCompNames,
  refViewTypes,
  assessmentSourceTableInfo,
} from "utils/assessmentConstants";
import { registry as Services } from "services/registry.service";
import RiskAssessmentService from "services/riskAssessment.service";
import {
  assessItemRefAttacher,
  optionListCreator,
  assessItemRefCreator,
  sourceReferenceKeysFinder,
  scopeTabIdFieldNameFinder,
  assesSourceTableColumns,
  assesSourceTableHeaders,
  getRequirementsSourceData,
  assessItemCountByGroup,
  unremovableItemsCountByGroup,
} from "utils/assessmentHelpers";
import { parsedValue } from "utils/jsonFunctions";
import variables from "styleVariables";
import RadioInputGroup from "components/utils/form-elements/radioInputGroup.component";
import moment from "moment";
import { replaceAllSubStrings } from "utils/stringFuncs";
import ComplianceService from "services/compliance.service";
import { SavingProgressMessage } from "components/utils/statusMessages";
import CheckboxV2 from "components/utils/form-elements/checkboxV2.component";
import classNames from "classnames";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import SortableTable from "components/utils/tables/sortableTable.component";
import Tooltip from "components/utils/tooltip.component";
import DialogPop from "components/utils/dialog.component";
import { Paper, Popover } from "@material-ui/core";
import CheckBoxList from "components/utils/form-elements/checkboxList.component";
import Checkbox from "components/utils/form-elements/checkbox.component";
import { ArrowDropDown } from "@material-ui/icons";
import StyledTableSortLabel from "components/utils/tableSortLabel.component";

const useStyles = makeStyles((theme) => ({
  tableGridItem: {
    marginBottom: 8,
  },
  radioGridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    marginLeft: "auto",
    paddingLeft: 32
  },
  radioSectionWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 3,
  },
  savingProgress: {
    paddingRight: 10,
  },
  tableCheckbox: {
    padding: 2
  },
  toggleButtonGroup: {
    backgroundColor: "white",
  },
  toggleButton: {
    padding: "3px 10px 3px 10px",
    fontSize: variables.fontSmall,
    color: variables.textSecondary,
    "&.Mui-selected": {
      backgroundColor: variables.tertiary2Dark,
      color: "white",
      "&:hover": {
        backgroundColor: variables.tertiary2Dark,
        color: "white",
      },
    },
    "&:hover": {
      color: variables.tertiary2Dark,
      backgroundColor: "unset",
      borderColor: variables.tertiary2,
    },
  },
  assessTable: {
    marginTop: 8,
  },
  assessTableNoScopeTabs: {
    maxHeight: 512,
  },
  assessTableWithScopeTabs: {
    maxHeight: 544
  },
  filterableColumnHeadingButton: {
    width: "auto"
  },
  filterableColumnHeading: {
    display: "inline-flex",
    alignItems: "center",
    cursor: "pointer",
    whiteSpace: "nowrap",
    "&:hover": {
      color: variables.secondaryLight,
      fill: variables.secondaryLight,
    },
  },
  filterableColumnIcon: {
    marginLeft: 4,
    fontSize: 16,
  },
  radioGroupClass: {
    flexDirection: "row",
    textTransform: "uppercase",
    marginRight: "-16px",
    "& label": {
      margin: 0,
    },
    "& .MuiFormControlLabel-label": {
      fontSize: "0.8rem",
    },
  },
  countDisplay: {
    color: variables.primaryMain,
  },
  countLoadingWrapper: {
    width: 16
  },
  checkLabelRow: {
    display: "inline-flex",
    alignItems: "center",
  },
}));

const SELECT_ALL_BLOCK_COUNT = 100;
const SELECT_ALL_CONFIRM_COUNT = 50;

const paginationProps = {
  rowsPerPage: 50,
  rowsPerPageOptions: [50, 100, 200]
};

const findForeginRefList = (items) => {
  return items.map((item) => item.Foreign_Ref);
};

const AddSourceItemsDefault = ({
  assessItems,
  assessItemIdsWithReviews,
  builderId,
  selectedProgCompID,
  selectedProgCompName,
  displayWithScopeTabs,
  user,
  programID,
  handleSave,
  isSavingAssessmentItems,
}) => {
  const classes = useStyles();
  const [scopeTabOptions, setScopeTabOptions] = useState(null);
  const [scopeSourceId, setScopeSourceId] = useState(null);
  const [selectAll, setSelectAll] = useState(null); //{1: "all"} Object if Tabbed view, otherwise a boolean
  const [isSelectAllConfirmModal, setIsSelectAllConfirmModal] = useState(false);
  const [checkByGroupOptions, setCheckByGroupOptions] = useState(null);
  const [tableHeaders, setTableHeaders] = useState(null);
  const [referencedList, setReferencedList] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);

  const sourceRefKeys = useMemo(
    () => sourceReferenceKeysFinder(selectedProgCompName),
    [selectedProgCompName]
  );
  const scopeTabIdFieldName = useMemo(() => (
    scopeTabIdFieldNameFinder(selectedProgCompName)
  ), [selectedProgCompName]);

  const checkedItems = useMemo(() => {
    if (!referencedList) {
      return [];
    }
    const assessmentItemRefs = new Set(
      assessItems.map(item => item.Foreign_Ref)
    );
    return referencedList.filter(item => (
      assessmentItemRefs.has(item.Foreign_Ref)
    ));
  }, [assessItems, referencedList]);

  const regFrameworkIDFinder = useCallback((item) => {
    if (selectedProgCompName === "Controls") {
      return scopeSourceId;
    } else if (selectedProgCompName === "Compliance") {
      return item.RegFramework_ID;
    } else {
      return null;
    }
  }, [scopeSourceId, selectedProgCompName]);

  const groupCheckHandler = useCallback((event, fieldName) => {
    // 1. Reset Select All Radios
    setSelectAll(null);

    // 2a. DELETE
    if (!event.target.checked) {
      // grab  and delete all existing assessment items within the column group which also don't have a review
      const assessItemsToDel = assessItems
        .filter((item) => {
          const parsedAssessFieldValue = parsedValue(
            item.AssessmentItem,
            fieldName
          );
          return (
            event.target.name === parsedAssessFieldValue &&
            !assessItemIdsWithReviews.includes(item.AssessmentItem_ID)
          ); //Access_Control
        })
        .map((item) => ({ ...item, isDelete: true }));
      handleSave(2, null, [...assessItemsToDel]);
    } else {
      // 2b. ADD
      // list of all foreign refs of assessment items to use to compare vs reference list
      const existingItemsForeignRefs = findForeginRefList(assessItems);
      // grab all reference items in the column which don't already have an associated assessment item
      const newItems = [].concat(referencedList || [])
        .filter((regItem) => {
          return regItem[fieldName] === event.target.name;
        })
        .filter(
          (sourceItem) =>
            !existingItemsForeignRefs.includes(sourceItem.Foreign_Ref)
        )
        .map((regItem, index) => {
          const selectedReference = assessItemRefCreator(
            regItem,
            sourceRefKeys[0],
            sourceRefKeys[1]
          );
          return {
            Foreign_Ref: selectedReference,
            // AssessmentItem_ID: newTempFromArr(newTemporaryFirstId, index),
            AssessmentItem_ID: null,
            AssessmentBuilder_ID: builderId,
            ProgramComponent_ID: selectedProgCompID,
            ProgramComponent_Name: selectedProgCompName,
            RegFramework_ID: regFrameworkIDFinder(regItem),
            Control_ID: displayWithScopeTabs ? regItem[sourceRefKeys[1]] : null,
            AssessmentItem: JSON.stringify(regItem)
          };
        });
      handleSave(2, [...newItems]);
    }
  }, [
    assessItems, assessItemIdsWithReviews, builderId, displayWithScopeTabs,
    referencedList, regFrameworkIDFinder, selectedProgCompID,
    selectedProgCompName, handleSave, sourceRefKeys
  ]);

  const [alertAnchorEl, setAlertAnchorEl] = useState(null);
  const headCellRef = useRef(null);

  const nonCustomAssessItems = useMemo(() => (
    assessItems && assessItems.filter((item) => item.ProgramComponent_ID)
  ), [assessItems]);

  const handleCloseColumn = () => {
    setAlertAnchorEl(null);
  };

  const openColumn = () => {
    setAlertAnchorEl(headCellRef.current);
  };

  const clearTable = useCallback(() => {
    setTableHeaders(null);
    setReferencedList(null);
    setCheckByGroupOptions(null);
  }, []);

  const customHeaders = useMemo(() => (
    tableHeaders?.map?.(header => {
      if (!header.filter) {
        return header;
      }
      return {
        ...header,
        display: (
          <Fragment key={header.name}>
            <StyledTableSortLabel
              className={classes.filterableColumnHeading}
              classNameRoot={classes.filterableColumnHeadingButton}
              onClick={openColumn}
              role="button"
              hideSortIcon
            >
              <div ref={headCellRef}>{header.display}</div>
              <ArrowDropDown className={classes.filterableColumnIcon} />
            </StyledTableSortLabel>
            <FilterableColumnSelect
              alertAnchorEl={alertAnchorEl}
              assessItemIdsWithReviews={assessItemIdsWithReviews}
              checkByGroupOptions={checkByGroupOptions}
              groupCheckHandler={groupCheckHandler}
              header={header}
              isSavingAssessmentItems={isSavingAssessmentItems}
              nonCustomAssessItems={nonCustomAssessItems}
              onClose={handleCloseColumn}
              scopeSourceId={scopeSourceId}
              selectedProgCompName={selectedProgCompName}
            />
          </Fragment>
        )
      }
    }) || []
  ), [
    alertAnchorEl, assessItemIdsWithReviews, checkByGroupOptions, classes,
    groupCheckHandler, isSavingAssessmentItems, nonCustomAssessItems,
    scopeSourceId, selectedProgCompName, tableHeaders
  ]);

  const switchScopeTab = useCallback(scopeOptionObj => {
    setSelectAll(null);
    const sourceId = scopeOptionObj[scopeTabIdFieldName];
    setScopeSourceId(sourceId);
    setTableLoading(true);
    RiskAssessmentService.getControlsByFrameworkId(
      sourceId,
      programID
    ).then((res) => {
      const { payload } = res;

      if (payload[0]) {
        const dataWithRef = assessItemRefAttacher(
          payload,
          sourceRefKeys[0],
          sourceRefKeys[1] // CHange to reference actual string
        );
        setReferencedList(dataWithRef);
        const headers = assesSourceTableHeaders(refViewTypes.CONTROLS);
        const fields = assesSourceTableColumns(refViewTypes.CONTROLS);

        const columnOptions = optionListCreator(
          [...dataWithRef],
          fields[fields.length - 1]
        );
        setCheckByGroupOptions(columnOptions);
        setTableHeaders(headers);
      }
      setTableLoading(false);
    });
  }, [programID, scopeTabIdFieldName, sourceRefKeys]);

  // Untabbed Table View
  useEffect(() => {
    if (!displayWithScopeTabs) {
      setSelectAll(null);
      // Single Table View
      let dataWithRef;
      let headers;
      let fields;
      let columnOptions;
      setScopeTabOptions(null);
      // If ProgramDoc Views, loads the tableData, currently none are with tab displays
      if (programDocCompNames.includes(selectedProgCompName)) {
        setTableLoading(true);
        const formattedSourceName = replaceAllSubStrings(
          selectedProgCompName,
          " & ",
          "And"
        );
        const loadProgramDocsView = async () => {
          const { payload } = await Services[`${formattedSourceName}View`](
            selectedProgCompID
          );
          if (payload[0]) {
            dataWithRef = assessItemRefAttacher(
              payload,
              sourceRefKeys[0],
              sourceRefKeys[1]
            );
            headers = assesSourceTableHeaders(refViewTypes.PROGRAM_DOCS);
            setTableHeaders(headers);
            setReferencedList(dataWithRef);
            fields = assesSourceTableColumns(refViewTypes.PROGRAM_DOCS);
            columnOptions = optionListCreator(
              [...dataWithRef],
              fields[fields.length - 1]
            );
            setCheckByGroupOptions(columnOptions);
          } else {
            clearTable();
          }
          setTableLoading(false);
        };

        loadProgramDocsView();
      } else if (programsCompNames.includes(selectedProgCompName)) {
        const loadPrograms = async () => {
          const { payload } = await RiskAssessmentService.getActivePrograms();
          if (payload[0]) {
            dataWithRef = assessItemRefAttacher(
              payload,
              sourceRefKeys[0],
              sourceRefKeys[1]
            );
            headers = assesSourceTableHeaders(refViewTypes.PROGRAMS);
            setTableHeaders(headers);
            setReferencedList(dataWithRef);
          } else {
            clearTable();
          }
          setTableLoading(false);
        };
        loadPrograms();
      } else if (complianceCompNames.includes(selectedProgCompName)) {
        const loadCompliance = async () => {
          let compliamceRes;
          if (programID === 8) {
            compliamceRes = await ComplianceService.getAllCompliances();
          } else {
            compliamceRes = await ComplianceService.getCompliancesByProgramId(
              programID
            );
          }
          if (compliamceRes.payload[0]) {
            dataWithRef = assessItemRefAttacher(
              compliamceRes.payload,
              sourceRefKeys[0],
              sourceRefKeys[1]
            );
            headers = assesSourceTableHeaders(refViewTypes.COMPLIANCE);
            setTableHeaders(headers);
            setReferencedList(dataWithRef);
            fields = assesSourceTableColumns(refViewTypes.COMPLIANCE);
            columnOptions = optionListCreator(
              [...dataWithRef],
              fields[fields.length - 1]
            );
            setCheckByGroupOptions(columnOptions);
          } else {
            clearTable();
          }
          setTableLoading(false);
        };
        loadCompliance();
      } else if (requirementCompNames.includes(selectedProgCompName)) {
        getRequirementsSourceData(programID, sourceRefKeys).then(
          ([headerData, dataWRef, fieldNames]) => {
            if (!headerData) {
              clearTable();
            } else {
              setTableHeaders(headerData);
              setReferencedList(dataWRef);
              columnOptions = optionListCreator(
                [...dataWRef],
                fieldNames[fieldNames.length - 1]
              );
              setCheckByGroupOptions(columnOptions);
            }
          }
        );
      }
    }
  }, [
    clearTable,
    selectedProgCompName,
    selectedProgCompID,
    displayWithScopeTabs,
    programID,
    sourceRefKeys,
  ]);

  // Multiple Tables with tabs -- currently just Controls
  useEffect(() => {
    if (displayWithScopeTabs) {
      // Tabbed View
      setScopeSourceId(null);
      if (controlsCompNames.includes(selectedProgCompName)) {
        // If Controls, loads the tab options only
        const loadControlsTabs = async () => {
          clearTable();
          const frameworkRes = (
            await RiskAssessmentService.getRegFrameworksByProgID(programID)
          );
          setScopeTabOptions(frameworkRes.payload);
          if (frameworkRes?.payload?.length === 1) {
            switchScopeTab(frameworkRes.payload[0]);
          }
        };
        loadControlsTabs();
      }
    }
  }, [
    clearTable, selectedProgCompName, sourceRefKeys, switchScopeTab,
    displayWithScopeTabs, programID
  ]);

  const selectAllRadios = useCallback(() => {
    const existingItemsForeignRefs = findForeginRefList(assessItems);
    const newList = referencedList.filter(sourceItem => (
      !existingItemsForeignRefs.includes(sourceItem.Foreign_Ref)
    )).map(item => ({
      Foreign_Ref: assessItemRefCreator(
        item,
        sourceRefKeys[0],
        sourceRefKeys[1]
      ),
      AssessmentItem_ID: null,
      AssessmentBuilder_ID: builderId,
      ProgramComponent_ID: selectedProgCompID,
      ProgramComponent_Name: selectedProgCompName,
      RegFramework_ID: regFrameworkIDFinder(item),
      Control_ID: displayWithScopeTabs ? item[sourceRefKeys[1]] : null,
      AssessmentItem: JSON.stringify(item)
    }));
    handleSave(2, newList);
  }, [
    assessItems, builderId, displayWithScopeTabs, handleSave,
    regFrameworkIDFinder, selectedProgCompID, selectedProgCompName,
    referencedList, sourceRefKeys
  ]);

  const handleConfirmSelectAll = useCallback(() => {
    selectAllRadios();
    setIsSelectAllConfirmModal(false);
  }, [selectAllRadios]);

  const closeSelectAllModal = useCallback(() => {
    setSelectAll(null);
    setIsSelectAllConfirmModal(false);
  }, []);

  const radioChangeHandler = (event) => {
    const { value } = event.target;
    setSelectAll(value);
    // 2a. DELETE
    if (value === "none") {
      const sourceItemsForeignRefs = findForeginRefList(
        [].concat(referencedList || [])
      );
      const itemsToDelete = assessItems
        .filter(
          (item) => !assessItemIdsWithReviews.includes(item.AssessmentItem_ID)
        )
        .filter((item) => sourceItemsForeignRefs.includes(item.Foreign_Ref))
        .map((item) => ({ ...item, isDelete: true }));
      handleSave(2, null, [...itemsToDelete]);
    } else {
    // 2b. ADD
      const uncheckedCount = tableData.length - (checkedItems?.length || 0);
      if (uncheckedCount > SELECT_ALL_BLOCK_COUNT) {
        event.preventDefault();
      } else if (uncheckedCount > SELECT_ALL_CONFIRM_COUNT) {
        setIsSelectAllConfirmModal(true);
        event.preventDefault();
      } else {
        selectAllRadios();
      }
    }
  };

  const singleItemCheckHandler = useCallback((event, refRow, refID) => {
    // 1. Reset Select All Radios
    if (selectAll !== null) {
      setSelectAll(null);
    }
    const foreignRef = event.target.value;
    const existsAlready = !event.target.checked;
    // 2a. ADD
    if (!existsAlready) {
      const newItem = {
        Foreign_Ref: foreignRef,
        AssessmentItem_ID: null,
        AssessmentBuilder_ID: builderId,
        ProgramComponent_ID: selectedProgCompID,
        ProgramComponent_Name: selectedProgCompName,
        RegFramework_ID: regFrameworkIDFinder(refRow),
        Control_ID: refID, // null
        AssessmentItem: JSON.stringify(refRow), //Scope Name if create.Refrence data if select
        Change_User: user.id,
        Change_Date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };
      handleSave(2, [newItem]);
    } else {
      // 2b. DELETE
      const itemToDelete = assessItems.find(
        (item) => item.Foreign_Ref === foreignRef
      );
      handleSave(2, null, [{ ...itemToDelete, isDelete: true }]);
    }
  }, [
    assessItems, builderId, handleSave, regFrameworkIDFinder,
    selectedProgCompID, selectedProgCompName, user.id, selectAll
  ]);

  const handleScopeTabChange = useCallback(event => {
    const tabbedScopeSourceId = parseInt(event.currentTarget.value, 10);
    const scopeTab = scopeTabOptions.find((tabOption) => (
      tabOption[scopeTabIdFieldName] === tabbedScopeSourceId
    ));
    return switchScopeTab(scopeTab);
  }, [scopeTabIdFieldName, scopeTabOptions, switchScopeTab]);

  const tableData = useMemo(() => (
    referencedList?.map?.(row => {
      const row_ID = row.Foreign_Ref;
      const associatedAssessItem = assessItems.find((item) => {
        return item.Foreign_Ref === row_ID;
      });
      const restrictEdit = assessItemIdsWithReviews.includes(
        associatedAssessItem?.AssessmentItem_ID
      );
      return {
        ...row,
        Checkbox: {
          value: row_ID,
          node: (
            <div>
              <Tooltip
                title={
                  restrictEdit ?
                    "This item can't be removed because a review has been started for it." :
                    null
                }
                placement="left"
              >
                <CheckboxV2
                  color="teal"
                  classes={{ root: classes.tableCheckbox }}
                  onChange={(event) => (
                    singleItemCheckHandler(event, row, row.Control_ID)
                  )}
                  checked={!!associatedAssessItem}
                  disabled={
                    restrictEdit || isSavingAssessmentItems.includes(row_ID)
                  }
                  value={row_ID}
                  aria-labelledby={`tooltip-${row_ID}`}
                  data-cy={row_ID}
                />
              </Tooltip>
            </div>
          )
        },
      };
    }) || []
  ), [
    assessItems, assessItemIdsWithReviews, classes, isSavingAssessmentItems,
    referencedList, singleItemCheckHandler
  ]);

  const isSelectAllBlocked = useMemo(() => {
    const unchecked = (tableData.length - (checkedItems?.length || 0));
    return unchecked > SELECT_ALL_BLOCK_COUNT
  }, [checkedItems, tableData]);

  const radioOptions = useMemo(() => {
    let tooltipMessage = null;
    if (isSelectAllBlocked && customHeaders) {
      const filterableHeader = customHeaders.find(header => (
        header.filterable
      ));
      const selectBy = !filterableHeader ?
        " instead." :
        ` or select by the ${filterableHeader.display} column.`;

      tooltipMessage = (
        `Over ${SELECT_ALL_BLOCK_COUNT} items are in this category.
        Please select items individually${selectBy}`
      );
    }
    return [{
      label: "Select All",
      value: "all",
      disabled: (
        isSelectAllBlocked ||
        (checkedItems?.length || 0) === (referencedList?.length || 0)
      ),
      tooltip: tooltipMessage
    }, {
      label: "Unselect All",
      value: "none",
      disabled: checkedItems?.length === 0
    }];
  }, [checkedItems, customHeaders, isSelectAllBlocked, referencedList]);

  const showRadioOptions = useMemo(() => (
    (scopeSourceId || !displayWithScopeTabs) &&
    referencedList?.length > 0
  ), [displayWithScopeTabs, referencedList, scopeSourceId]);

  const hasPagination = useMemo(() => (
    tableData.length > paginationProps.rowsPerPage &&
    (!displayWithScopeTabs || !!scopeSourceId)
  ), [displayWithScopeTabs, scopeSourceId, tableData]);

  const emptyTableMessage = useMemo(() => {
    if (tableLoading) {
      return "";
    } else if (displayWithScopeTabs && scopeTabOptions?.length > 0) {
      return "Select a Framework to Add Items";
    } else {
      return `No ${selectedProgCompName} Items to select`;
    }
  }, [
    displayWithScopeTabs, scopeTabOptions, selectedProgCompName, tableLoading
  ]);

  return (
    <GridContainer justifyContent="space-between">
      {!!displayWithScopeTabs && (
        <GridItem xs={12} xl="auto" className={classes.tableGridItem}>
          <ToggleButtonGroup
            className={classes.toggleButtonGroup}
            value={scopeSourceId}
            onChange={handleScopeTabChange}
            exclusive
          >
            {scopeTabOptions?.length > 0 &&
              scopeTabOptions.map((regFramework) => {
                return (
                  <ToggleButton
                    className={classes.toggleButton}
                    value={regFramework[scopeTabIdFieldName]}
                    data-cy={`${regFramework.Name} v.${regFramework.Version}-option`}
                    key={`${regFramework.Name} v.${regFramework.Version}`}
                  >
                    {`${regFramework.Name} v.${regFramework.Version}`}
                  </ToggleButton>
                );
              })}
          </ToggleButtonGroup>
        </GridItem>
      )}
      {!!showRadioOptions && !!radioOptions?.length && (
        <GridItem
          xs={12}
          xl="auto"
          className={classNames(classes.radioGridItem, classes.tableGridItem)}
        >
          <div className={classes.radioSectionWrapper}>
            {isSavingAssessmentItems.length < 1 ? (
              <RadioInputGroup
                options={radioOptions}
                value={selectAll}
                radioGroupClass={classes.radioGroupClass}
                onChange={(event) => radioChangeHandler(event)}
                hideHelperText
                row
              />
            ) : (
              <div className={classes.savingProgress}>
                <SavingProgressMessage message="Updating Assessment List" />
              </div>
            )}
            <DialogPop
              openDialog={isSelectAllConfirmModal}
              setOpenDialog={closeSelectAllModal}
              confirm={handleConfirmSelectAll}
              prompt={
                "This action will select " +
                (tableData.length - (checkedItems?.length || 0)) +
                " items. Are you sure you want to continue?"
              }
              continueAndCancel
            />
          </div>
        </GridItem>
      )}
      <GridItem xs={12}>
        {!tableLoading && !!customHeaders && (
          <div>
            <SortableTable
              data={tableData}
              headers={customHeaders}
              emptyTableValue={emptyTableMessage}
              className={
                classNames(
                  classes.assessTable,
                  displayWithScopeTabs && classes.assessTableWithScopeTabs,
                  !displayWithScopeTabs && classes.assessTableNoScopeTabs,
                )
              }
              denseHeader
              denseBody
              pagination={hasPagination ? paginationProps : false}
            />
          </div>
        )}
      </GridItem>
    </GridContainer>
  );
};

export default AddSourceItemsDefault;


const FilterableColumnSelect = props => {
  const classes = useStyles();
  const {
    alertAnchorEl,
    assessItemIdsWithReviews,
    checkByGroupOptions,
    groupCheckHandler,
    header,
    isSavingAssessmentItems,
    nonCustomAssessItems,
    onClose,
    scopeSourceId,
    selectedProgCompName
  } = props;

  const columnFieldName = useMemo(() => {
    const useTableInfoName = selectedProgCompName === refViewTypes.COMPLIANCE;
    if (useTableInfoName) {
      const { fields } = assessmentSourceTableInfo[selectedProgCompName];
      return fields[fields.length - 1];
    }
    return header.name.split(" ").join("_")
  }, [header, selectedProgCompName]);

  if (!checkByGroupOptions) {
    return null;
  }
  return (
    <Popover
      open={Boolean(alertAnchorEl)}
      anchorEl={alertAnchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Paper>
        <CheckBoxList
          options={checkByGroupOptions}
          groupLabel={`Select By ${header.name}`}
        >
          {checkByGroupOptions.map((option) => {
            const itemCountByGroup = assessItemCountByGroup(
              nonCustomAssessItems,
              option,
              columnFieldName,
              selectedProgCompName,
              scopeSourceId,
            );
            const unremovableItemCount = unremovableItemsCountByGroup(
              nonCustomAssessItems,
              option,
              columnFieldName,
              assessItemIdsWithReviews
            )
            const editableItemCount = itemCountByGroup - unremovableItemCount

            const refSet = new Set(option.foreignRefList);
            const isSaving = !!isSavingAssessmentItems?.some?.(forRef => (
              refSet.has(forRef)
            ));

            return (
              <span className={classes.checkLabelRow} key={option.name}>
                <Checkbox
                  checkBoxColor="teal"
                  checked={editableItemCount > 0}
                  onChange={(event) => groupCheckHandler(event, columnFieldName)}
                  name={option.name}
                  label={option.label}
                  disabled={isSaving}
                />
                <div className={classes.countLoadingWrapper}>
                  {!!isSaving && (
                    <SavingProgressMessage message=" " />
                  )}
                </div>
                {itemCountByGroup > 0 && (
                  <div className={classes.countDisplay}>
                    {itemCountByGroup !== option.itemCount ? (
                      `${itemCountByGroup} of ${option.itemCount} selected
                      of ${option.foreignRefList.length}`
                    ) : (
                      `All ${itemCountByGroup} selected`
                    )}
                  </div>
                )}
              </span>
            );
          })}
        </CheckBoxList>
      </Paper>
    </Popover>
  );
}
