import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

// component imports
import MainHeader from 'components/layouts/main/header.component'
import MainMenu from 'components/layouts/dynamic/menu.component'
import GlobalFormSandbox from 'components/forms/globalFormSandbox.component';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
}));

const Layout = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <MainHeader />
      <MainMenu />
      <GlobalFormSandbox />
    </div>
  );
}

export default Layout;
