import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import CustomLink from "components/utils/link.component";
import variables from "styleVariables";

const useStyles = makeStyles(theme => ({
  cellCtaBottom: {
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    margin: "0px auto",
  },
  cellDisabled: {
    marginBottom: 4,
  },
  designLink: {
    color: variables.primaryLight,
    "&:hover": {
      color: variables.primaryMain,
    }
  },
  performanceLink: {
    color: variables.secondaryMain,
    "&:hover": {
      color: variables.SecondaryDark,
    }
  },
  linkWrapper: {
    padding: 5,
  },
}));

export default function FieldPresenceCell(props) {
  const { design, fieldKey, setReviewPageMode, hasDesign, hasPerformance, performanceItems } = props;
  const classes = useStyles();

  const performanceWithKey = performanceItems?.find?.((review) => review[fieldKey]);
  const rowHasDesign = hasDesign && !!design?.[fieldKey];
  const rowHasPerformance = hasPerformance && !!performanceWithKey;
  const hasAny = rowHasDesign || rowHasPerformance;
  const hasBoth = rowHasDesign && rowHasPerformance;

  return (
    <div>
      {hasAny ? (
        <div className={classNames(classes.cellCtaBottom)}>
          {!!rowHasDesign && (
            <div className={classes.linkWrapper}>
              <CustomLink
                variant="customUnderline"
                className={classes.designLink}
                onClick={() => setReviewPageMode("designDataCollection", design.AssessmentItem_ID, design.Design_ID)}
                ignoreReadOnly
              >
                Design
              </CustomLink>
            </div>
          )}
          {!!hasBoth && " | "}
          {!!rowHasPerformance && (
            <div className={classes.linkWrapper}>
              <CustomLink
                variant="customUnderline"
                className={classes.performanceLink}
                onClick={() => setReviewPageMode("performanceDataCollection", performanceWithKey.AssessmentItem_ID, performanceWithKey.Performance_ID)}
                ignoreReadOnly
              >
                Performance
              </CustomLink>
            </div>
          )}
        </div>
      ) : (
        <div className={classNames(classes.cellDisabled)}>
          --
        </div>
      )}
    </div>
  );
}
