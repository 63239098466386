import React, { useCallback, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useNumericParams from "hooks/useNumericParams";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PortfolioHolderCard from './portfolioHolderCard.component';
import { H1, H2, H5 } from 'components/utils/headerV2.component';
import { addHolderToPath } from '../utils/portfolioRouteHelpers';
import ProgramsContext from 'contexts/programs.context';

const useStyles = makeStyles(theme => ({
  pageHeading: {
    marginTop: -16,
    marginBottom: 16,
  },
  pageSubheading: {
    marginBottom: -16,
    marginLeft: 8,
  },
  portCardsContainer: {
    marginTop: 40,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "stretch"
  }
}))

const SelectPortfolioHolder = ({ portfolioHolders }) => {
  const classes = useStyles()
  const history = useHistory();
  const params = useNumericParams();
  const { state } = useContext(ProgramsContext);

  const handleClick = useCallback(holdingId => {
    const path = addHolderToPath(params, state, holdingId);
    history.push(path);
  }, [history, params, state]);

  return (
    <div>
      {!params.programComponentId && (
        <H1 className={classes.pageHeading}>Portfolio</H1>
      )}
      <div>
        <H2 color="tertiary" className={classes.pageSubheading}>
          Select Holding Company
        </H2>
      </div>
      <div className={classes.portCardsContainer}>
        {portfolioHolders.length > 0 ?
          portfolioHolders.map((portfolioHolder, index) => (
            <PortfolioHolderCard
              index={index}
              portfolioHolder={portfolioHolder}
              key={portfolioHolder.PortfolioHolder_ID}
              onClick={() => handleClick(portfolioHolder.PortfolioHolder_ID)}
            />
          )) : (
            <H5>No Portfolio Holding Companies found.</H5>
          )
        }
      </div>
    </div>
  )
}

export default SelectPortfolioHolder
