import http from "./http-common";
import authHeader from "./authHeader";

class ChecklistServiceBase {
  getChecklist(checklistType, programId) {
    return http
      .get(`/checklist/program/${programId}/${checklistType}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  getChecklistItems(checklistId) {
    return http
      .get(`/checklist/${checklistId}/checklistItems`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  updateChecklistItem(checklistItemId, checklistData) {
    return http
      .put(
        `/checklist/checklistItem/${checklistItemId}`,
        { checklistData },
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      });
  }

  checklistItemAssignment(checklistItemId, assignmentData) {
    return http
      .post(
        `/checklist/checklistItemAssignment/${checklistItemId}/upsert`,
        { assignmentData },
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      });
  }

  removeCLItemAssignment(clItemAssignmentId) {
    return http
      .delete(`/checklist/checklistItemAssignment/${clItemAssignmentId}`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  ///checklistItemReference/update/:cLItemRefI
  upsertCLItemReference(referenceData) {
    return http
      .post(`/checklist/checklistItemReference/upsert`, referenceData, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  removeCLItemReference(clItemReferenceId) {
    return http
      .delete(`/checklist/checklistItemReference/${clItemReferenceId}`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }
}

const ChecklistService = new ChecklistServiceBase();

export default ChecklistService;
