import { removeObjInArray } from "utils/arrayOfObjectsHelpers";
import { mergeAssociationDelete, mergeReplace } from "./utils/payloadUtils";

export const ACTION_SET_REQUIREMENTS = "requirements__set";
export const ACTION_SET_REQUIREMENT_TYPES = "requirement_types__set";
export const ACTION_CREATE_REQUIREMENT = "requirement__create";
export const ACTION_REPLACE_REQUIREMENT = "requirement__replace";
export const ACTION_DELETE_REQUIREMENT = "requirement__delete";
export const ACTION_SET_LINKED_REQUIREMENTS = "requirement_linked__set";
export const ACTION_CREATE_REQUIREMENT_LINKS = "requirement_linked__create";
export const ACTION_SET_ALL_REQUIREMENTS = "all_requirements__set";

export const requirementsInitialState = {
  requirements: null,
  types: null,
  activeRequirement: null,
  requirementLinks: null,
  allRequirements: null,
};

export default function requirementsReducer(state, action) {
  switch (action.type) {
    case ACTION_SET_REQUIREMENTS:
      return {
        ...state,
        requirements: action.payload,
      };
    case ACTION_SET_REQUIREMENT_TYPES:
      return {
        ...state,
        types: action.payload,
      };
    case ACTION_SET_LINKED_REQUIREMENTS:
      return {
        ...state,
        requirementLinks: action.payload,
      };
    case ACTION_SET_ALL_REQUIREMENTS:
      return {
        ...state,
        allRequirements: action.payload,
      };
    case ACTION_CREATE_REQUIREMENT:
      return {
        ...state,
        requirements: [
          ...state.requirements,
          action.payload[0],
        ],
        allRequirements: [
          ...state.allRequirements,
          action.payload[0],
        ]
      };
    case ACTION_CREATE_REQUIREMENT_LINKS:
      return {
        ...state,
        requirementLinks: [
          ...state.requirementLinks,
          ...action.payload,
        ]
      };
    case ACTION_REPLACE_REQUIREMENT:
      return {
        ...state,
        requirements: mergeReplace(
          state.requirements,
          action.payload[0],
          "Requirement_ID",
        ),
        allRequirements: mergeReplace(
          state.allRequirements,
          action.payload[0],
          "Requirement_ID",
        ),
      };
    case ACTION_DELETE_REQUIREMENT:
      return {
        ...state,
        requirements: removeObjInArray(
          mergeAssociationDelete(
            state.requirements,
            parseInt(action.payload, 10),
            "RequirementLink",
            "Requirement_ID_Link"
          ),
          "Requirement_ID",
          parseInt(action.payload, 10)
        ),
        allRequirements: removeObjInArray(
          mergeAssociationDelete(
            state.allRequirements,
            parseInt(action.payload, 10),
            "RequirementLink",
            "Requirement_ID_Link"
          ),
          "Requirement_ID",
          parseInt(action.payload, 10)
        ),
      }
    default:
      return state;
  }
}
