import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import HeaderPrimary from "components/utils/header.component";
import variables from "styleVariables";
import LabelAndValue from "components/utils/labelAndValue.component";
import moment from "moment";
import classNames from "classnames";
import SelectedAccordionView from "components/assessment/selectedAccordionView.component";
import { formattedMomentCustomError } from "utils/dateHelpers";

const useStyles = makeStyles((theme) => ({
  setupFinalViewContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 1000,
    margin: "0px auto",
  },
  summaryContainer: {
    display: "flex",
    justifyContent: "stretch",
    paddingBottom: 5,
  },
  labelValueRow: {
    display: "flex",
    color: variables.textSecondary,
    paddingBottom: 5,
    alignItems: "flex-start",
  },
  accordionWrapper: {
    paddingTop: 5,
  },
  summaryCell: {
    marginRight: 30,
    minWidth: 50,
    display: "flex",
    flexFlow: "column",
    paddingTop: 5,
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  valueText: {
    color: variables.textSecondary,
  },
  descriptionCell: {
    flex: 3,
  },
}));

const ROLE_LEAD = "lead";

const AssessmentSetupFinalView = ({
  builderInfo,
  assessItems,
  checkListData,
  assessors,
  programComponentList,
  programID,
  assessmentType,
}) => {
  const classes = useStyles();
  const assessTypeText = (revType) => {
    if (revType === 1) {
      return "Design";
    } else if (revType === 2) {
      return "Performance";
    } else if (revType === 3) {
      return "Design & Performance";
    } else {
      return "None Selected";
    }
  };

  const leadAssessors = assessors.filter(
    (assessor) => assessor.Assessment_Role === ROLE_LEAD
  );
  const standardAssessors = assessors.filter(
    (assessor) => assessor.Assessment_Role !== ROLE_LEAD
  );

  return (
    <div className={classes.setupFinalViewContainer}>
      <HeaderPrimary variant="h3Primary" test="assessment-final-title">
        {`${builderInfo.Title}`}
      </HeaderPrimary>
      <div className={classes.summaryContainer}>
        <div className={classes.summaryCell}>
          <LabelAndValue
            className={classNames(classes.flexColumn, classes.valueText, classes.labelValueRow)}
            label="Start Date"
            value={formattedMomentCustomError(moment(builderInfo.Start_Date).format("M/D/YYYY"))}
            labelTest="final-view-Start"
          />
          <LabelAndValue
            className={classNames(classes.flexColumn, classes.valueText, classes.labelValueRow)}
            label="End Date"
            value={formattedMomentCustomError(moment(builderInfo.End_Date).format("M/D/YYYY"))}
            labelTest="final-view-End"
          />
        </div>
        <div className={classes.summaryCell}>
          {leadAssessors.length > 0 && (
            <LabelAndValue
              className={classNames(classes.labelValueRow, classes.flexColumn, classes.valueText)}
              label={`Lead Assessor${leadAssessors.length !== 1 ? "s" : ""}`}
              value={
                leadAssessors.length > 0
                  ? leadAssessors
                    .map(
                      (assessor) =>
                        `${assessor.First_Name} ${assessor.Last_Name}`
                    )
                    .join(", ")
                  : "None"
              }
              labelTest="final-view-Lead Assessor"
            />
          )}
          <LabelAndValue
            className={classNames(classes.labelValueRow, classes.flexColumn, classes.valueText)}
            label="Assessors"
            value={
              standardAssessors.length > 0
                ? standardAssessors
                  .map(
                    (assessor) =>
                      `${assessor.First_Name} ${assessor.Last_Name}`
                  )
                  .join(", ")
                : "None"
            }
          />
        </div>

        <div className={classNames(classes.summaryCell, classes.descriptionCell)}>
          <LabelAndValue
            className={classNames(classes.flexColumn, classes.valueText, classes.labelValueRow)}
            label="Assessment Type"
            value={assessTypeText(builderInfo.Review_Type)}
            labelTest="final-view-Assessment Type"
          />
          <LabelAndValue
            className={classNames(classes.flexColumn, classes.valueText, classes.labelValueRow)}
            label="Description"
            value={builderInfo.Description}
            labelTest="final-view-Description"
          />
        </div>
      </div>

      {!!programComponentList && (
        <>
          <HeaderPrimary variant="h4Primary">
            Assessment Items & Checklist Items
          </HeaderPrimary>
          <div className={classes.accordionWrapper}>
            <SelectedAccordionView
              checkListData={checkListData}
              programID={programID}
              assessItems={assessItems}
              programComponentList={programComponentList}
              showChecklist
              assessmentType={assessmentType}
            />
          </div>{" "}
        </>
      )}
    </div>
  );
};

export default AssessmentSetupFinalView;
