import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from "@material-ui/core";
import EditIconButton from "components/utils/editIconButton.component";
import variables from "styleVariables";
import classNames from "classnames";
import { isHoldingRead } from "utils/roles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import ButtonDefault from "components/utils/buttonDefault.component";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import RadioInputGroup from "components/utils/form-elements/radioInputGroup.component";
import InviteUserForm from "./inviteUserForm.component";
import ExistingUserForm from "./existingUserForm.component";
import Tooltip from "components/utils/tooltip.component";
import useNumericParams from "hooks/useNumericParams";
import { getPortfolioUserTitle } from "../shared/utils/portfolioUserHelpers";

const useStyles = makeStyles((theme) => ({
  //LIST FORM
  list: {
    listStyle: "none",
    padding: 0,
  },
  listItem: {
    marginTop: 16,
  },
  listItemCard: {
    marginTop: 1,
    minWidth: 470
  },
  cardHeader: {
    paddingBottom: 10,
    display: "flex",
    alignItems: "center",
  },
  cardContent: {
    minWidth: 420
  },
  headerDivider: {
    marginLeft: 16,
    marginRight: 16
  },
  selectCardContent: {
    paddingBottom: 16
  },
  userIcon: {
    display: "inline-block",
    height: 30,
    width: 30,
    fontSize: "3rem",
  },
  userIconActive: {
    fill: variables.primaryLight
  },
  userIconInactive: {
    fill: variables.grayPrimary
  },
  userInfoRow: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 4,
  },
  userInfoIcon: {
    fill: variables.primaryLight,
    fontSize: variables.fontMedium,
    marginRight: 5,
  },
  userName: {
    color: variables.primaryLight,
    fontSize: variables.fontH4,
    fontWeight: 600,
    paddingBottom: 10,
  },
  userTitle: {
    color: variables.tertiary1,
    fontSize: variables.fontH5,
  },
  displayField: {
    // paddingBottom: 4,
    color: variables.textSecondary,
    fontSize: variables.fontMedium,
  },
  radioButtonRow: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 24,
  },
  radioLabelText: {
    fontSize: "0.9rem"
  },
  radioButtonDivider: {
    marginBottom: 20
  },

  //FORM
  userFormContainer: {
    marginTop: 8,
  },
  editFormButton: {
    marginRight: 8,
    marginBottom: 4
  },
  warning: {
    color: variables.warningLight,
  },
  emptyMessage: {
    margin: 20,
    color: variables.textSecondary,
  },
  buttonContainerTop: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: 20,
    position: "absolute",
    right: 0,
    zIndex: 10,
    top: 240,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
      marginBottom: 30,
    },
  },
  newUserButton: {
    paddingRight: 20,
  },
}));

const USER_FORM_MODE_NEW = "new";
const USER_FORM_MODE_EXISTING = "edit";
const USER_FORM_MODE_NONE = null;


const PortCoUserForm = ({
  user,
  assignableUsers,
  dispatch,
  formStatus,
  selectedPortCoID,
  setFormStatus,
  setEmailMessage
}) => {
  const classes = useStyles();

  const isExistingModeDisabled = !assignableUsers?.length;

  useEffect(function preventDisabledStatus() {
    if (isExistingModeDisabled && formStatus === USER_FORM_MODE_EXISTING) {
      setFormStatus(USER_FORM_MODE_NEW);
    }
  }, [isExistingModeDisabled, formStatus, setFormStatus]);

  const statusRadioOptions = useMemo(() => ([
    {
      value: USER_FORM_MODE_EXISTING,
      disabled: isExistingModeDisabled,
      label: (
        <Tooltip
          title={
            isExistingModeDisabled ? "No available users remaining" : null
          }
        >
          <span>Add existing user</span>
        </Tooltip>
      ),
    },
    {
      value: USER_FORM_MODE_NEW,
      label: "Invite new user"
    },
  ]), [isExistingModeDisabled]);

  const handleStatusChange = useCallback(event => {
    setFormStatus(event.target.value)
  }, [setFormStatus]);

  const handleClose = useCallback(() => {
    setFormStatus(USER_FORM_MODE_NONE)
  }, [setFormStatus]);

  return (
    <div className={classes.userFormContainer} data-cy="new-portco-user-form">
      <div className={classes.radioButtonRow}>
        <RadioInputGroup
          name="user-form-status"
          value={formStatus}
          options={statusRadioOptions}
          radioLabelTextClass={classes.radioLabelText}
          onChange={handleStatusChange}
          hideHelperText
          row
        />
      </div>
      <Divider className={classes.radioButtonDivider} />
      {formStatus === USER_FORM_MODE_EXISTING && (
        <ExistingUserForm
          assignableUsers={assignableUsers}
          dispatch={dispatch}
          onClose={handleClose}
          selectedPortCoID={selectedPortCoID}
        />
      )}
      {formStatus === USER_FORM_MODE_NEW && (
        <InviteUserForm
          assignableUsers={assignableUsers}
          dispatch={dispatch}
          formStatus={formStatus}
          onClose={handleClose}
          selectedPortCoID={selectedPortCoID}
          setEmailMessage={setEmailMessage}
          user={user}
        />
      )}
    </div>
  );
};

const roleIDMap = {
  5: "Read Only",
  6: "Edit",
};

const ListForm = ({
  list,
  formStatus,
  setFormStatus,
  assignableUsers,
  disabled,
  dispatch,
  createdUserToAddToList,
  selectedPortCoID,
}) => {
  const classes = useStyles();
  const { holdingId } = useNumericParams(true);

  const [formMessage, setFormMessage] = useState({});
  // TODO: put emailMessage back in when email is sending.
  // const [emailMessage, setEmailMessage] = useState();
  const setEmailMessage = useMemo(() => () => {}, []);
  const isHoldingReadUser = useMemo(isHoldingRead, []);

  const handleUserAdd = useCallback(() => (
    setFormStatus(
      !assignableUsers?.length ?
        USER_FORM_MODE_NEW :
        USER_FORM_MODE_EXISTING
    )
  ), [assignableUsers, setFormStatus]);

  return (
    <div>
      {/* <CollapsableAlert
        showAlert={emailMessage?.length}
        closeClick={() => setEmailMessage()}
        message={emailMessage}
      /> */}
      <ul className={classes.list}>
        {[USER_FORM_MODE_NEW, USER_FORM_MODE_EXISTING].includes(formStatus) && (
          <li className={classes.listItem}>
            <Card>
              <CardContent classes={{ root: classes.selectCardContent }}>
                <PortCoUserForm //CREATE
                  assignableUsers={assignableUsers}
                  dispatch={dispatch}
                  createdUserToAddToList={createdUserToAddToList}
                  formMessage={formMessage}
                  formStatus={formStatus}
                  setFormMessage={setFormMessage}
                  selectedPortCoID={selectedPortCoID}
                  setFormStatus={setFormStatus}
                  setEmailMessage={setEmailMessage}
                />
              </CardContent>
            </Card>
          </li>
        )}
        {!!selectedPortCoID && list?.length === 0 && !formStatus && (
          <li className={classes.listItem}>
            <Card className={classes.listItemCard}>
              <div className={classes.emptyMessage}>
                There are no users associated with this Portfolio Company.
              </div>
            </Card>
          </li>
        )}
        {list.map((user) => {
          return (
            <li className={classes.listItem} key={user.User_ID}>
              <Card className={classes.listItemCard}>
                {formStatus?.User_ID === user.User_ID ? (
                  <CardContent className={classes.cardContent}>
                    <InviteUserForm
                      user={formStatus}
                      assignableUsers={assignableUsers}
                      dispatch={dispatch}
                      formStatus={formStatus}
                      onClose={() => setFormStatus(USER_FORM_MODE_NONE)}
                      selectedPortCoID={selectedPortCoID}
                      setEmailMessage={setEmailMessage}
                    />
                  </CardContent>
                ) : (
                  <>
                    <UserCardHeader
                      holdingId={holdingId}
                      portCoId={selectedPortCoID}
                      user={user}
                      active
                    />
                    <Divider className={classes.headerDivider} />
                    <CardContent className={classes.cardContent}>
                      <Box paddingLeft={3}>
                        <div className={classes.userInfoRow}>
                          <AccessibilityIcon
                            className={classNames(
                              classes.roleIcon,
                              classes.userInfoIcon
                            )}
                          />
                          {user.Role_ID ? (
                            <div className={classes.displayField}>
                              {roleIDMap[user.Role_ID]} Access
                            </div>
                          ) : (
                            <div className={classNames(classes.displayField)}>
                              No role specified
                            </div>
                          )}
                        </div>
                        <div className={classes.userInfoRow}>
                          <EmailIcon
                            className={classNames(
                              classes.emailIcon,
                              classes.userInfoIcon
                            )}
                          />
                          {user.Email ? (
                            <div className={classes.displayField}>
                              {user.Email}
                            </div>
                          ) : (
                            <div
                              className={classNames(
                                classes.displayField,
                                classes.warning
                              )}
                            >
                              Email is missing for this user.
                            </div>
                          )}
                        </div>
                        <div className={classes.userInfoRow}>
                          <PhoneIcon
                            className={classNames(
                              classes.phoneIcon,
                              classes.userInfoIcon
                            )}
                          />
                          {user.Phone ? (
                            <div className={classes.displayField}>
                              {user.Phone}
                            </div>
                          ) : (
                            <div className={classNames(classes.displayField)}>
                              No phone number provided.
                            </div>
                          )}
                        </div>
                      </Box>
                      <Box
                        display="flex"
                        marginTop={-1}
                        justifyContent="flex-end"
                      >
                        <div className={classes.editFormButton}>
                          {!isHoldingReadUser && (
                            <EditIconButton
                              onClick={() => setFormStatus(user)}
                              disabled={!!formStatus}
                              disableReadOnlyUsers
                            />
                          )}
                        </div>
                      </Box>
                    </CardContent>
                  </>
                )}
                <div />
              </Card>
            </li>
          );
        })}
      </ul>
      {!isHoldingReadUser && (
        <Grid item className={classes.buttonContainerTop}>
          <ButtonDefault
            variant="medium"
            background="tertiary"
            className={classes.newUserButton}
            startIcon={<PersonAddIcon />}
            onClick={handleUserAdd}
            disabled={disabled || !!formStatus}
            fixedRight
            disableReadOnlyUsers
            data-cy="addUserButton"
          >
            Add User
          </ButtonDefault>
        </Grid>
      )}
    </div>
  );
};

const UserCardHeader = ({ active, holdingId, portCoId, user }) => {
  const classes = useStyles();

  const title = useMemo(() => (
    getPortfolioUserTitle(user, holdingId, portCoId)
  ), [user, holdingId, portCoId]);

  return (
    <CardHeader
      className={classes.cardHeader}
      avatar={
        <AccountCircleIcon
          aria-label="user-icon"
          className={classNames(
            classes.userIcon,
            active && classes.userIconActive,
            !active && classes.userIconInactive,
          )}
        />
      }
      title={
        <span className={classes.userName}>
          {user.First_Name} {user.Last_Name}
        </span>
      }
      subheader={
        <span className={classes.userTitle}>{title}</span>
      }
    >
      {user.Name}
    </CardHeader>
  )
};

const PortFolioUserDemos = (props) => {
  const { selectedPortCo, dispatch, userDemos, createdUserToAddToList } = props;
  const { assignableUsers, formStatus, setFormStatus } = props;

  return (
    <ListForm
      formStatus={formStatus}
      setFormStatus={setFormStatus}
      list={userDemos || []}
      assignableUsers={assignableUsers}
      disabled={!selectedPortCo?.PortCo_ID}
      selectedPortCoID={selectedPortCo?.PortCo_ID}
      dispatch={dispatch}
      createdUserToAddToList={createdUserToAddToList}
    />
  );
};

export default PortFolioUserDemos;
