import React, { useState, useMemo } from "react";
import { Checkbox, Grid, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons//ExpandMore';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import DisplayHTML from "components/utils/displayHTML.component";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  dropdownSpan: {
    display: "flex",
    paddingLeft: 16,
    paddingTop: 24,
    paddingBottom: 24,
    boxShadow: "-1px 5px 9px -6px rgb(0 0 0 / 15%), 0px 2px 1px -1px rgb(0 0 0 / 10%)",
  },
  sectionHeader: {
    paddingTop: 1,
    paddingBottom: 8,
  },
  dropDownIconWrapper: {
    paddingRight: 8,
    display: "flex",
    "&:hover": {
      cursor: "pointer",
    },
  },
  disablePointer: {
    cursor: "default",
    "&:hover": {
      cursor: "default",
    },
  },
  subsection: {
    paddingLeft: 16
  }
}));

const ManualSectionSelection = (props) => {
  const { section, addSection, removeSection, activeLOCItemReferences } = props;
  const classes = useStyles();
  const [showSectionIDs, setShowSectionIDs] = useState([]);

  const sectionIsChecked = useMemo(() => {
    const foundSectionItem = activeLOCItemReferences?.find((sectionItem) => {
      return sectionItem.ManualSection_ID === section.ManualSection_ID;
    });
    return foundSectionItem
  }, [activeLOCItemReferences, section]);

  const handleShowSections = (sectionID) => {
    if (showSectionIDs?.includes(sectionID)) {
      const filteredSectionIDs = showSectionIDs.filter((sectionIDItem) => (sectionIDItem !== sectionID));
      setShowSectionIDs(filteredSectionIDs);
    } else if (!showSectionIDs) {
      setShowSectionIDs([sectionID]);
    } else {
      setShowSectionIDs([...showSectionIDs, sectionID]);
    }
  }

  return (
    <>
      <div
        value={section.ManualSection_ID}
        key={`section-${section.ManualSection_ID}`}
        className={classes.dropdownSpan}
      >
        <div
          className={section._associations.ManualSection.length ? classes.dropDownIconWrapper : classNames(classes.dropDownIconWrapper, classes.disablePointer)}
          onClick={() => handleShowSections(section.ManualSection_ID)}
        >
          <div
            className={classes.dropDownIconWrapper}
          >
            {section._associations.ManualSection.length > 0 && (
              showSectionIDs.includes(section.ManualSection_ID) ? (
                <ExpandMoreIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )
            )}
          </div>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.sectionHeader}>
                <DisplayHTML
                  html={section.Header}
                  unstyled
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <DisplayHTML
                html={section.Content}
                unstyled
              />
            </Grid>
          </Grid>
        </div>
        <Checkbox
          onChange={(event) => {
            event.target.checked ?
              addSection(event, section) :
              removeSection(event, section)
          }}
          checked={sectionIsChecked}
          data-cy={`checkbox-section-${section.ManualSection_ID}`}
        />
      </div>
      {!!showSectionIDs?.includes(section.ManualSection_ID) && section._associations.ManualSection.map((subsection) => {
        return (
          <div
            className={classes.subsection}
            key={subsection.ManualSection_ID}
          >
            <ManualSectionSelection
              section={subsection}
              addSection={addSection}
              removeSection={removeSection}
              activeLOCItemReferences={activeLOCItemReferences}
            />
          </div>
        )
      })}
    </>
  )
};

export default ManualSectionSelection;
