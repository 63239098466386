import React, { useState, useEffect, useCallback } from "react";
import { Grid, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LabelInput from "components/utils/form-elements/labelInput.component";
import HeaderPrimary from "components/utils/header.component";
import CustomSelect from "components/utils/form-elements/select.component";
import ControlService from "services/control.service";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import FormBanner from "components/utils/form-elements/formBanner.component";
import { setStateFetchEffect } from "utils/ajaxHelpers";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: 800,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: 40,
  },
  formFieldsWrapper: {
    maxHeight: 500,
    overflow: "auto",
  },
  form: {
    padding: "0px 60px 0px 60px",
    align: "center",
  },
  subHeaderWrapper: {
    padding: "30px 40px 30px 40px",
    display: "flex",
    justifyContent: "space-between",
  },
  subHeader: {
    display: "flex",
    flexDirection: "column",
  },
  headerStyle: {
    paddingTop: 1,
    paddingBottom: 1,
  },
  formSection: {
    padding: "15px 0px 15px 0px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  formSectionElement: {
    width: 300,
  },
}));

const currentStateItems = [
  {
    name: "Implemented",
  },
  {
    name: "Partial Implementation",
  },
  {
    name: "Planned",
  },
  {
    name: "N/A",
  },
];

const impTypes = [
  {
    name: "Hybrid",
  },
  {
    name: "Enterprise",
  },
  {
    name: "N/A",
  },
];

export const PrivControlMatrixForm = (props) => {
  const classes = useStyles();
  const { rowInfo, setEditModal, setRefresh } = props;

  const [formData, setFormData] = useState({});
  const [loadForm, setLoadForm] = useState(false);
  const [activeForm, setActiveForm] = useState(false);
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => (
    setStateFetchEffect(
      ControlService.getPrivacyManagementControlMatrixById(rowInfo),
      ([res]) => {
        setLoadForm(false)
        setFormData(res.payload);
        setLoadForm(true)
      }
    )
  ), [rowInfo]);

  const handleChange = useCallback(event => {
    const { target } = event;
    if (!activeForm) {
      setActiveForm(true);
    }
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  }, [activeForm, formData]);

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    setIsSaving(true)

    const newMatrix = {
      Control_Desc: formData.Control_Desc,
      Control_Validation: formData.Control_Validation,
      Current_State: formData.Current_State, // 'Implemented','Partial Implementation','Planned','N/A'
      Implementation_Type: formData.Implementation_Type, //'Hybrid','Enterprise','N/A'
      Roadmap_Notes: formData.Roadmap_Notes,
      Validation_Date: formData.Validation_Date,
    };
    await ControlService.updatePrivacyControlMatrix(
      formData.Matrix_ID,
      newMatrix
    );

    setIsSaving(false);
    setRefresh(true);
    setEditModal(false);
  }, [formData, setEditModal, setRefresh]);

  if (formData && loadForm) {
    return (
      <Grid container className={classes.formContainer}>
        <FormBanner >
          NIST 800-53 Control - Control Matrix Update
        </FormBanner>
        <div className={classes.subHeaderWrapper}>
          <div className={classes.subHeader}>
            <HeaderPrimary
              variant="h4Tertiary"
              className={classes.headerStyle}
            >
              {formData.Identifier} {formData.Family_Name}
            </HeaderPrimary>
            <HeaderPrimary
              variant="h3SecondaryDark"
              className={classes.headerStyle}
            >
              {formData.Control_Name}
            </HeaderPrimary>
          </div>
          <div className={classes.subHeader}>
            <HeaderPrimary
              variant="h4Tertiary"
              className={classes.headerStyle}
            >
              Privacy Management Principle
            </HeaderPrimary>
            <HeaderPrimary
              variant="h3SecondaryDark"
              className={classes.headerStyle}
            >
              {formData.LabelName}
            </HeaderPrimary>
          </div>
        </div>
        <form className={classes.form}>
          <div className={classes.formFieldsWrapper}>
            {/* CURRENT STATE SELECT */}
            <div className={classes.formSection}>
              <div className={classes.formSectionElement}>
                <CustomSelect
                  //   variant={"form"}
                  label="Current State"
                  labelId="Current State"
                  value={formData.Current_State}
                  onChange={handleChange}
                  name="Current_State"
                  shrink
                >
                  {currentStateItems.map((item) => (
                    <MenuItem
                      key={item.name}
                      value={item.name}
                      id={item.name}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </div>
              {/* IMPLEMENTATION TYPE SELECT */}
              <div className={classes.formSectionElement}>
                <CustomSelect
                  variant="form"
                  label="Implementation Type"
                  value={formData.Implementation_Type}
                  id="Implementation_Type"
                  name="Implementation_Type"
                  onChange={handleChange}
                  shrink
                >
                  {impTypes.map((item) => (
                    <MenuItem
                      value={item.name}
                      key={item.name}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </div>
            </div>
            <div className={classes.formSection}>
              <LabelInput
                variant="default"
                label="Control Implementation Description"
                //   value={formData.Control_Desc}
                multiline
                id="Control_Desc"
                name="Control_Desc"
                onChange={handleChange}
                value={formData.Control_Desc}
              />
            </div>
            <div className={classes.formSection}>
              <div className={classes.formSectionElement}>
                <LabelInput
                  variant="default"
                  id="Control_Validation"
                  name="Control_Validation"
                  label="Control Validation"
                  value={formData.Control_Validation}
                  onChange={handleChange}
                  inputProps={{maxLength: 45}}
                />
              </div>
              <div className={classes.formSectionElement}>
                <LabelInput
                  variant="default"
                  label="Validation Date"
                  value={formData.Validation_Date}
                  id="Validation_Date"
                  name="Validation_Date"
                  onChange={handleChange}
                  inputProps={{maxLength: 45}}
                />
              </div>
            </div>
            {/* ROADMAP NOTES */}
            <div className={classes.formSection}>
              <LabelInput
                variant="default"
                label="Roadmap Notes"
                value={formData.Roadmap_Notes}
                multiline
                id="Roadmap_Notes"
                name="Roadmap_Notes"
                onChange={handleChange}
                test="roadmap-notes"
              />
            </div>
          </div>
          <DualFormButtons
            variant="smallButton"
            saveOnClick={handleSubmit}
            cancelOnClick={() => setEditModal(false)}
            disabled={!activeForm}
            isSaving={isSaving}
          />
        </form>
      </Grid>
    );
  } else {
    return "";
  }
};

export default PrivControlMatrixForm;
