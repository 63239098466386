import React, { useMemo } from "react";
import LabelInput from "components/utils/form-elements/labelInput.component";
import CustomSunEditor from "components/utils/sunEditor.component";
import { makeStyles } from "@material-ui/core/styles";
import { builderLabels } from "utils/sunEditorLabels";
import HeaderPrimary from "components/utils/header.component";
import ProcedureSteps from "components/builder/procedure/procedureSteps.component";
import { sectionLabel } from "utils/builderFunctions";
import {
  ACTION_REPLACE_PROC_ELE_SECTION,
  ACTION_REPLACE_PROC_ELE_CONTENT,
} from "reducers/builder.reducer";

const useStyles = makeStyles((theme) => ({
  textFieldWrapper: {
    marginTop: 20,
  },
  rteWrapper: {
    marginBottom: 5,
  },
  headerWrapper: {
    padding: "20px 5px 0px 5px",
  },
}));

const ProcedureElements = (props) => {
  const classes = useStyles();
  const {
    elementSection,
    referenceGroup,
    procStepsInrRegFramework,
    subGroupsInRefGroup,
    dispatch,
    disableBuilder,
  } = props;

  const sectionId = useMemo(() => (
    elementSection?._meta?.clientId ||
    elementSection.BuilderSection_ID
  ), [elementSection]);

  const procSecHeaderOnBlur = (event) => {
    event.preventDefault();
    if (event.target.value === elementSection.SectionHeader) {
      return;
    }
    dispatch({
      type: ACTION_REPLACE_PROC_ELE_SECTION,
      payload: { ...elementSection, SectionHeader: event.target.value },
    });
  };

  return (
    <>
      <div className={classes.headerWrapper}>
        <HeaderPrimary variant="h3Primary">
          {`${referenceGroup.FrameworkName} ${referenceGroup.Version} - ${referenceGroup.GroupName}`}
        </HeaderPrimary>
      </div>

      <div>
        <div className={classes.textFieldWrapper}>
          <LabelInput
            name={`element-header-${sectionId}`}
            variant="default"
            label={sectionLabel("procedureElement")}
            defaultValue={elementSection.SectionHeader}
            onBlur={procSecHeaderOnBlur}
            test={`ProcedureElementHeader-${referenceGroup.FrameworkName} - ${referenceGroup.GroupName}`}
            disabled={disableBuilder}
          />
        </div>
        <div className={classes.rteWrapper}>
          <ProcBuilderRTE dispatch={dispatch} section={elementSection} disableBuilder={disableBuilder} />
        </div>
        <div>
          {subGroupsInRefGroup?.map((subGroup) => {
            const stepsInSubgroup = procStepsInrRegFramework.filter((step) => {
              if (subGroup?._meta?.clientId) {
                return step._meta.parentClientId === subGroup._meta.clientId;
              } else {
                return step.RegRefs_Reg_Refs_ID === subGroup.Reg_Refs_ID;
              }
            });
            return (
              <React.Fragment
                key={`${referenceGroup.FrameworkName}_${subGroup.GroupName}_${subGroup.Step_Source_ID}`}
              >
                <ProcedureSteps
                  subGroup={subGroup}
                  referenceGroup={referenceGroup}
                  setProcSteps={props.setProcSteps}
                  scopedProcSteps={stepsInSubgroup}
                  saveProcStepHeader={props.saveProcStepHeader}
                  saveProcStepContent={props.saveProcStepContent}
                  activateForm={props.activateForm}
                  elementSectionId={
                    elementSection?._meta?.clientId ||
                    elementSection.BuilderSection_ID
                  }
                  readOnly={props.readOnly}
                  disableBuilder={disableBuilder}
                  dispatch={dispatch}
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </>
  );
};

const ProcBuilderRTE = ({ section, dispatch, disableBuilder }) => {

  const procSectionContentOnBlur = (event, content) => {
    if (content === section.SectionContent) {
      return;
    }
    dispatch({
      type: ACTION_REPLACE_PROC_ELE_CONTENT,
      payload: {
        id: section._meta.clientId,
        content,
      },
    });
  };
  return (
    <CustomSunEditor
      name={`proc-section-content-${section._meta.clientId}`}
      height="auto"
      sectionDetail={section}
      defaultValue={section.SectionContent}
      sectionID={section.Builder_Section_ID}
      onBlur={procSectionContentOnBlur}
      lang={builderLabels}
      disabled={disableBuilder}
    />
  );
};

export default ProcedureElements;
