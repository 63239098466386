import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import AddListItem from "components/utils/addListItem.component";
import CustomLink from "components/utils/link.component";
import useNumericParams from "hooks/useNumericParams";
import { useCallback } from "react";
import { generatePath, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PROGRAM_COMPONENT_ID_CERT_MANUALS } from "utils/programConstants";


const useStyles = makeStyles(() => ({
  linkWrapper: {
    textAlign: "center",
  },
  leftAlignedLinkWrapper: {
    textAlign: "left",
  },
  referencesWrapper: {
    paddingLeft: 2,
    marginTop: 0,
    marginBottom: 8,
    listStyle: "none"
  },
  referenceListItem: {
    textIndent: "-1.1px",
    marginLeft: "1.1px",
  },
}));


export default function LOCReferencesCell(props) {
  const classes = useStyles();
  const history = useHistory();
  const params = useNumericParams();
  const { align, cellProps, setActiveLOCItem, setRefFormOpen } = props;

  const handleAddClick = useCallback(() => {
    setActiveLOCItem(cellProps);
    setRefFormOpen(true);
  }, [cellProps, setActiveLOCItem, setRefFormOpen]);

  const handleManualNav = useCallback(manualId => (
    history.push(generatePath(
      "/program/:programId/:programComponentId/manual/:manualId",
      {
        ...params,
        programComponentId: PROGRAM_COMPONENT_ID_CERT_MANUALS,
        manualId
      }
    ))
  ), [history, params]);

  return (
    <>
      {cellProps.References?.length > 0 && (
        <ul
          className={
            classNames(
              classes.referencesWrapper,
              align === "left" && classes.leftAlignedLinkWrapper,
              align !== "left" && classes.linkWrapper
            )
          }
        >
          {cellProps.References?.map(reference => (
            <li
              className={classes.referenceListItem}
              key={`${reference.LOC_ID}-${reference.ManualSection_ID}`}
            >
              <CustomLink onClick={() => handleManualNav(reference.Manual_ID)} >
                {`${reference.Manual_Title} ${reference.Header}`}
              </CustomLink>
            </li>
          ))}
        </ul>
      )}
      <div>
        <AddListItem
          text="Manage References"
          onClick={handleAddClick}
          test={`CFR-${cellProps?.partProps?.CFR_ID}-reference`}
        />
      </div>
    </>
  );
}
