import React from 'react'
import HeaderPrimary from "components/utils/header.component";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import variables from "styleVariables";

const useStyles = makeStyles(theme => ({
  labelValueRow: {
    display: "flex",
    color: variables.textSecondary,
    paddingBottom: 5,
  },
  alignLeft: {
    alignItems: "flex-start",
    alignContents: "center",
  },
  labelWrapper: {
    padding: "0px 5px 0px 0px",
    whiteSpace: "nowrap",
  },
  valueWrapper: {
    padding: 0,
  },
  verticalPosition: {
    flexDirection: "column",
  },
  headerPrintStyles: {
    "@media print": {
      fontSize: "12px",
      verticalAlign: "super"
    }
  },
  valuePrintStyles: {
    "@media print": {
      fontSize: "12px",
      verticalAlign: "text-bottom"
    }
  }
}));

const LabelAndValue = ({
  label,
  value,
  //Optional
  className,
  labelHeaderVariant,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  verticalPositioning,
  alignLeft,
  labelTest,
  valueTest
}) => {
  const classes = useStyles()
  return (
    <span
      className={classNames(className || classes.labelValueRow, alignLeft ? classes.alignLeft : undefined, verticalPositioning ? classes.verticalPosition : undefined)}
      style={{
        paddingTop: paddingTop || undefined,
        paddingRight: paddingRight || undefined,
        paddingBottom: paddingBottom || undefined,
        paddingLeft: paddingLeft || undefined
      }}
      data-cy={labelTest}
    >
      <HeaderPrimary
        variant={labelHeaderVariant || "h5Primary"}
        className={classes.labelWrapper}
      >
        <span className={classes.headerPrintStyles} >
          {label}:
        </span>

      </HeaderPrimary>
      <div className={classNames(classes.valueWrapper, classes.valuePrintStyles)} >{value}</div>
    </span>
  );
};

export default LabelAndValue;
