// module components
import useNumericParams from 'hooks/useNumericParams';
import React, { useCallback, useMemo } from 'react'
import { generatePath, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { COMPONENT_ID_PORTFOLIO_DEMOGRAPHICS } from 'utils/programConstants';
import { isGlobalUser } from 'utils/roles';
import PortfolioComponentObjectWrapper from '../shared/portfolioComponentObjectWrapper.component';
import PortCoListing from '../shared/portCo/portCoListing.component';

const PortfolioDashboardPage = () => {
  const history = useHistory();
  const params = useNumericParams();
  const isDemographicsRole = useMemo(isGlobalUser, []);

  const handlePortCoClick = useCallback(portCo => {
    history.push(generatePath(
      "/program/:programId/:programComponentId/holding/:holdingId/portCo/:portCoId/demographics",
      {
        ...params,
        programComponentId: COMPONENT_ID_PORTFOLIO_DEMOGRAPHICS,
        portCoId: portCo.PortCo_ID
      }
    ));
  }, [history, params]);

  return (
    <PortfolioComponentObjectWrapper>
      <PortCoListing
        onClick={isDemographicsRole ? handlePortCoClick : undefined}
      />
    </PortfolioComponentObjectWrapper>
  );
};

export default PortfolioDashboardPage
