import { useMemo } from "react";
import { useParams } from "react-router-dom";

/*
 * Get URL Params similar to useParams, but with all numeric values parsed
 *   from string to number.
 *
 * Non-numeric values are excluded when `isNumericOnly` is true
 */
const useNumericParams = (isNumericOnly = false) => {
  const params = useParams();
  const numericParams = useMemo(() => (
    !params ? {} : (
      Object.entries(params).reduce((accumulator, [key, value]) => {
        const parsedValue = parseInt(value, 10);
        if (isNaN(parsedValue)) {
          if (isNumericOnly) {
            return accumulator;
          } else {
            accumulator[key] = value;
          }
        } else {
          accumulator[key] = parsedValue;
        }
        return accumulator;
      }, {})
    )
  ), [isNumericOnly, params]);

  return numericParams;
};

export default useNumericParams;
