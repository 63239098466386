// module components
import React, { useState, useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PortCoCard from 'components/portfolio/shared/portCo/portCoCard.component'
import PortfolioService from 'services/portfolio.service'
import useNumericParams from "hooks/useNumericParams";
import { setStateFetchEffect } from 'utils/ajaxHelpers';

const useStyles = makeStyles(theme => ({
  portCardsContainer: {
    marginTop: 40,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch'
  }
}))

export default function PortCoListing(props) {
  const classes = useStyles();
  const { holdingId } = useNumericParams();
  const { onClick } = props;
  const [portCos, setPortCos] = useState([]);
  const [sessions, setSessions] = useState([]);

  const sessionsByPortCos = useMemo(() => (
    (sessions || []).reduce((accumulator, session) => {
      if (accumulator[session.PortCo_ID]) {
        accumulator[session.PortCo_ID].push(session);
      } else {
        accumulator[session.PortCo_ID] = [session];
      }
      return accumulator;
    }, {})
  ), [sessions]);

  useEffect(() => {
    if (!holdingId || holdingId === "undefined") {
      return;
    }
    return setStateFetchEffect(
      [
        PortfolioService.getPortfolioDashboard(holdingId),
        PortfolioService.getHolderSessions(holdingId)
      ],
      function fetchData([dashBoardData, assessmentSessions]) {
        setPortCos(dashBoardData.payload || []);
        setSessions(assessmentSessions.payload);
      }
    )
  }, [holdingId, setPortCos, setSessions]);

  return (
    <div className={classes.portCardsContainer}>
      {portCos.map((portCo, index) => {
        return (
          <PortCoCard
            index={index}
            portCo={portCo}
            sessions={sessionsByPortCos?.[portCo.PortCo_ID]}
            key={portCo.PortCo_ID}
            onClick={onClick ? () => onClick(portCo) : undefined}
          />
        );
      })}
    </div>
  )
}