//https://www.npmjs.com/package/mui-datatables

import React,{ memo, useCallback, useContext, useMemo, useState } from "react";
import { TableCell } from '@material-ui/core'
import { MuiThemeProvider, makeStyles, unstable_createMuiStrictModeTheme } from "@material-ui/core/styles";
import MUIDataTable, { debounceSearchRender } from "mui-datatables";
import variables from "styleVariables";
import CustomIcons from "components/utils/icons.component";
import { IconButton } from "@material-ui/core";
import FileService from "services/file.service";
import ClipLoader from "components/utils/clipLoad.component";
import Tooltip from "components/utils/tooltip.component";
import moment from "moment";
import StyledTableSortLabel, { SORT_ASCENDING } from "../tableSortLabel.component";
import classNames from "classnames";
import theme from "theme";
import ProgramsContext from "contexts/programs.context";
import useNumericParams from "hooks/useNumericParams";

const useStyles = makeStyles((defaultTheme) => ({
  headerCell: {
    backgroundColor: variables.tertiary1,
    color: "white",
    fontWeight: 600,
    lineHeight: "normal",
    padding: "10px 20px",
    [defaultTheme.breakpoints.down("md")]: {
      fontSize: variables.fontMedium,
    },
  },
  headerCellLeft: {
    textAlign: "left"
  },
  headerCellCenter: {
    textAlign: "center"
  },
  headerCellRight: {
    textAlign: "right"
  },
  headerCellSmall: {
    fontSize: variables.fontSmall,
  },
  headerCellDefaultSize: {
    fontSize: variables.fontLarge,
  },
  iconButton: {
    fill: variables.tertiary1,
    "&:hover": {
      fill: variables.primaryMain
    },
  },
  iconWrapper: {
    height: 22
  },
}));

const MuiTableTheme = unstable_createMuiStrictModeTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiTooltip: {
      tooltip: {
        fontSize: variables.fontXs,
        lineHeight: "normal",
        fontWeight: "bold",
        paddingTop: 5,
        paddingBottom: 6,
      }
    },
    MuiTableSortLabel: {
      root: {
        fontFamily: "Helvetica, roboto",
        "&$active": {
          '&& $icon': {
            opacity: 1,
            color: variables.secondaryLight,
          },
        }
      },
    },
    MUIDataTable: {
      paper: {
        boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      },
      responsiveBase: {
        "@media print": {
          maxHeight: "none !important"
        }
      }
    },
    MUIDataTableFilter: {
      checkboxFormControlLabel: {
        marginLeft: 4,
        marginRight: 16
      },
      checkboxListTitle: {
        minWidth: 140
      }
    },
    MUIDataTableFilterList: {
      chip: {
        margin: "4px 8px 4px 0"
      }
    },
    // ROW STYLING
    MuiTableRow: {
      // HOVER
      hover: {
        "&$hover:hover": {
          backgroundColor: variables.rowHover,

          MuiTableCell: {
            borderColor: "white",
          }
        },
      },
      // FOOTER
      footer: {
        backgroundColor: variables.tertiary1,
        color: "white",
        "&$hover:hover": {
          backgroundColor: variables.tertiary1,
        },
        MuiToolbar: {
          backgroundColor: variables.tertiary1,
          root: {
            backgroundColor: variables.tertiary1,
          },
        },
      },
    },
    // DROPDOWN ARROW ICON
    MuiSelect: {
      icon: {
        color: "black",
      },
    },
    /*TOOLBAR ICON BUTTON COLORS*/
    MUIDataTableToolbar: {
      icon: {
        color: variables.tertiary1,
        '&:hover': {
          color: `${variables.primaryMain} !important;`,
        },
      },
      iconActive: {
        color: `${variables.primaryLight} !important;`,
      },
    },
    MUIDataTableSearch: {
      clearIcon: {
        '&:hover': {
          color: `${variables.warningLight} !important;`,
        },
      }
    },
    MUIDataTableFooter: {
      root: {
        MuiTableRow: {
          footer: {
            "&:hover": {
              backgroundColor: variables.tertiary1
            }
          }
        }
      }
    },
    // SEARCH FIELD
    MuiInput: {
      underline: {
        // CHANGES UNDERLINE COLOR ON HOVER
        "&&&&:hover:before": {
          borderBottom: `2px solid ${variables.primaryMain} !important;`,
        },
        // CHANGES UNDERLINE COLOR ON ACTIVE
        '&:after': {
          borderBottom: `2px solid ${variables.primaryLight} !important;`,
        },
      },
    },
    // TOOLBAR
    MuiToolbar: {
      regular: {
        minHeight: "auto !important;",
      },
      root: {
        minHeight: 20,
        backgroundColor: "transparent",
      },
    },
    //HEADER
    MUIDataTableHeadCell: {
      sortActive: {
        color: variables.secondaryLight,
        "& + $sortAction": {
          marginRight: -26
        }
      },
      sortAction: {
        alignItems: "center",
        justifyContent: "center",
      },
      toolButton: {
        justifyContent: "center",
      },
      fixedHeader: {
        backgroundColor: variables.tertiary1,
        color: "white",
        fontWeight: "bold",
        padding: "10px 20px",
        lineHeight: "normal",
        position: "sticky",
        textAlign: "center"
      },
      root: {
        justifyContent: "center",
      },
    },

    // PAGEINATION CONTROLS
    MuiTablePagination: {
      root: {
        color: "white",
      },
    },
    MuiTableCell: {
      body: {
        borderBottom: `1px solid ${variables.rowBorder}`,
        borderRight: `1px solid ${variables.rowBorder}`,
        borderTop: "none",
        color: variables.textSecondary,
        padding: "10px 20px",
        fontFamily: "Helvetica",
        fontSize: variables.fontSmall,

        "&:last-of-type": {
          borderRight: 0
        }
      },
    },
  }
});


const DEFAULT_SEARCH_DEBOUNCE_MS = 300;

const defaultOptions = {
  customSearchRender: debounceSearchRender(DEFAULT_SEARCH_DEBOUNCE_MS),
};


const DataTable = memo(
  function DataTable(props) {
    const {
      title, data, options, columns, className, excelData, excelColumns, headerSize
    } = props;
    const customColumns = useMemo(() => (
      columns.map(column => {
        if (!column.options?.customHeadLabelRender) {
          column.options = {
            ...(column.options || {}),
            customHeadRender: (columnMeta, handleToggleColumn, sortOrderBy) => (
              <TableHeaderCell
                key={column.name}
                column={column}
                columnMeta={columnMeta}
                handleToggleColumn={handleToggleColumn}
                sortOrder={sortOrderBy}
                size={headerSize}
              />
            )
          };
        }
        // Match body TableCell alignment to header alignment by default
        if (column.options.align === "center") {
          const existingSetter = column.options?.setCellProps;
          column.options.setCellProps = (...args) => ({
            align: "center",
            ...(existingSetter?.(...args) || {})
          });
        }
        return column;
      })
    ), [columns, headerSize]);

    const sortOrder = useMemo(() => {
      if (options.sortOrder) {
        return options.sortOrder;
      }
      const firstSortableColumn = customColumns.find(col => (
        col.options.sort !== false &&
        ![false, "false", "excluded"].includes(col.options.display)
      ));
      if (!firstSortableColumn) {
        return undefined;
      }
      return { name: firstSortableColumn.name, direction: "asc" }
    }, [customColumns, options.sortOrder]);

    const customSearch = useCallback((searchQuery, currentRow) => {
      const lowerCaseSearchQuery = searchQuery.toLowerCase().trim();
      return currentRow.some(col => {
        const cellValue = col?.metadata?.textValue || col;
        if (cellValue) {
          const caseInsensitiveValue = `${cellValue.toLowerCase?.() || cellValue}`;
          return caseInsensitiveValue.includes(lowerCaseSearchQuery)
        }
        return false;
      })
    }, []);

    const finalOptions = useMemo(() => {
      const result = {
        ...defaultOptions,
        ...options,
        sortOrder,
        customSearch
      };
      if (excelData) {
        result.customToolbar = () => (
          <ExcelButton excelColumns={excelColumns} excelData={excelData} />
        );
      }
      return result;
    }, [excelColumns, excelData, options, sortOrder, customSearch]);

    return (
      <MuiThemeProvider theme={MuiTableTheme}>
        <MUIDataTable
          title={title}
          data={data}
          columns={customColumns}
          options={finalOptions}
          className={className}
        />
      </MuiThemeProvider>
    );
  }
);

const TableHeaderCell = memo(
  function TableHeaderCell(
    { column, columnMeta, handleToggleColumn, size, sortOrder }
  ) {
    const classes = useStyles();
    const active = sortOrder?.name === columnMeta.name;
    const align = (
      columnMeta.align ||
      columnMeta.setCellProps?.()?.style?.textAlign ||
      "left"
    );
    const isSortable = columnMeta.sort !== false;
    const tableHeaderProps = columnMeta?.setCellHeaderProps?.() || {};
    const customLabel = column.options?.customHeadLabelRender?.();

    return (
      <TableCell
        {...tableHeaderProps}
        className={classNames(
          tableHeaderProps.className,
          classes.headerCell,
          align === "left" && classes.headerCellLeft,
          align === "center" && classes.headerCellCenter,
          align === "right" && classes.headerCellRight,
          size === "small" && classes.headerCellSmall,
          size !== "small" && classes.headerCellDefaultSize,
        )}
      >
        {customLabel || (
          <StyledTableSortLabel
            active={active}
            align={align}
            direction={active ? sortOrder.direction : SORT_ASCENDING}
            hideSortIcon={!isSortable}
            onClick={() => {
              isSortable && handleToggleColumn(columnMeta.index)
            }}
            sortDisabled={!isSortable}
          >
            {columnMeta.label}
          </StyledTableSortLabel>
        )}
      </TableCell>
    );
  }
);

//https://codesandbox.io/s/muidatatables-custom-toolbar-hvpyy?file=/index.js:1181-1205
const ExcelButton = memo(
  function ExcelButton({ excelColumns, excelData }) {
    const classes = useStyles();
    const { state } = useContext(ProgramsContext);
    const { programId } = useNumericParams();
    const [isDownloading, setIsDownloading] = useState(false);

    const activeProgram = useMemo(() => (
      state.programs?.[programId]
    ), [state.programs, programId]);

    const generateExcel = useCallback((tableColumns, tableCellData, companyName) => {
      setIsDownloading(true)
      const headerNameList = [];
      const headers = [];
      tableColumns.filter((i) => i.label !== " ").forEach((column) => {
        headerNameList.push(column.name)
        headers.push(column.label)
      });
      const tableDataList = tableCellData.map(row => (
        headerNameList.map((column) => row[column])
      ));
      const tableData = {
        header: [headers],
        rows: tableDataList,
      };
      const excelTableData = {
        data: tableData,
        fileName: `${companyName}_ControlMatrix_${moment(new Date()).format('YYYY-MM-DD')}.xlsx`,
      };

      FileService.getExcelTable(excelTableData).then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${excelTableData.fileName}`);
        // Append to html page
        document.body.appendChild(link);
        // Force download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }).then(() => setIsDownloading(false));
    }, []);

    return (
      <Tooltip disableFocusListener title="Download .XLSX">
        <IconButton
          onClick={() => (
            generateExcel(excelColumns, excelData, activeProgram.Organization)
          )}
          className={classes.iconButton}
          data-cy="btn-dataTable-excel"
        >
          {isDownloading ? (
            <div className={classes.iconWrapper} data-cy="spinning-loader">
              <ClipLoader color={variables.primaryMain} size={19} />
            </div>
          ) : (
            <div className={classes.iconWrapper}>
              <CustomIcons variant="xls" />
            </div>
          )}
        </IconButton>
      </Tooltip>
    );
  }
);

/*
 * This is an unfortunate hack needed to fix a console error in mui-datatable.
 * The version with the fix only supports Mui v5.
 */
const oldRender = TableCell.render

TableCell.render = function(...args) {
  const [props, ...otherArgs] = args
  if (typeof props === 'object' && props && 'isEmpty' in props) {
    const { isEmpty, ...propsWithoutEmpty } = props
    return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs])
  } else {
    return oldRender.apply(this, args)
  }
}

export default DataTable;
