import { Box, Card, Divider, Tooltip, Typography } from "@material-ui/core";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import ReconciliationTable from "./reconciliationTable.component";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import useNumericParams from "hooks/useNumericParams";
import { ACTION_REPLACE_SESSION_NOTES, ACTION_RESET_ASSESSMENT_ITEMS, ACTION_SET_RECONCILE_ASSESSMENT_ITEMS } from "reducers/portfolioAssessments.reducer";
import PortfolioService from "services/portfolio.service";
import Header from "components/utils/header.component";
import { makeStyles } from "@material-ui/core";
import { CheckCircle, FileCopy, RadioButtonUnchecked } from "@material-ui/icons";
import classNames from "classnames";
import styleVariables from "styleVariables";
import TodayIcon from "@material-ui/icons/Today";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { sessionTypes } from "utils/portfolioConstants";
import { currentUserID } from "utils/userHelpers";
import authService from "services/auth.service";
import { ROLE_GROUPS } from "utils/roles";
import ButtonDefault from "components/utils/buttonDefault.component";
import DialogPop from "components/utils/dialog.component";
import Loader from "components/utils/loader.components";
import PortCoLogoHeader from "components/portfolio/shared/portCoLogoHeader.component";
import CustomLink from "components/utils/link.component";
import { downloadFileByRef } from "utils/downloadFile";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import variables from "styleVariables";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  tooltipRight: {
    marginLeft: "-10px",
  },
  tooltip: {
    marginLeft: 5,
  },
  cardsContainer: {
    marginBottom: 20,
    justifyContent: "flex-start",
  },
  leftCardWrapper: {
    paddingRight: 10,
  },
  card: {
    height: "100%",
    display: "flex",
    alignItems: "stretch",
    padding: 30,
    flexDirection: "column",
  },
  cardColumn: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
    "&:last-of-type": {
      paddingBottom: 0,
    },
  },
  sessionDateTimeValueWrapper: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingBottom: 5,
    "&:last-of-type": {
      paddingBottom: 0,
    },
  },
  sessionDateTimeValue: {
    paddingRight: 6,
  },
  dateTimeIcon: {
    fontSize: "1.2rem",
    color: styleVariables.tertiary2,
    marginRight: 8
  },
  presentAttendee: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingBottom: 5,
    "&:last-of-type": {
      paddingBottom: 0,
    },
  },
  attendeeName: {
    paddingRight: 7,
  },
  checkIcon: {
    fontSize: "1.1rem",
    color: styleVariables.tertiary2,
  },
  notPresent: {
    color: styleVariables.grayDisabledBg,
  },
  sessionSection: {
    color: theme.palette.text.secondary,
    marginTop: 8,
    paddingLeft: 16,
    marginBottom: 5,
  },
  sessionSectionItem: {
    marginTop: 2,
    marginBottom: 3,
    paddingLeft: 7,
    "&::marker": {
      fontWeight: 550,
      fontSize: styleVariables.fontSmall,
    },
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
  sessionStatus: {
    fontStyle: "oblique",
    fontSize: styleVariables.fontXs,
    color: styleVariables.tertiary2,
    paddingLeft: 5,
    display: "inline-flex",
    alignItems: "center",
    letterSpacing: ".3px",
  },
  statusIcon: {
    fontSize: styleVariables.fontXs,
    color: styleVariables.tertiary2,
    marginRight: 1,
    opacity: ".8",
  },
  customLink: {
    display: "inline-flex",
    alignItems: "center",
    lineHeight: "normal",
    position: "relative",
    textIndent: 23,
    fontSize: variables.fontMedium,
  },
  downloadIcon: {
    fontSize: "1rem",
    position: "absolute",
    left: 0,
    top: "-1px"
  },
  sessionDivider: {
    marginBottom: 20,
    backgroundColor: variables.grayDisabledBg,
  },
  headerName: {
    marginRight: 32
  },
  addFlex: {
    display: "flex"
  }
}));

export default function Reconcile(props) {
  const { dispatch, state, sessions } = props;
  const { reconcileAssessmentItems, portfolioCompanies, assessments, documents } = state;
  const params = useNumericParams();
  const { assessmentId, portCoId } = params;
  const classes = useStyles();

  const [isTransferring, setIsTransferring] = useState(false);
  const [isTransferDialogOpen, setIsTransferDialogOpen] = useState(false);

  useEffect(
    () =>
      (async function loadGlobalState() {
        dispatch({ type: ACTION_RESET_ASSESSMENT_ITEMS });
        const [assessmentItemsResponse] = await Promise.all([
          PortfolioService.getReconcileAssessmentItems(portCoId, assessmentId),
        ]);
        dispatch({
          type: ACTION_SET_RECONCILE_ASSESSMENT_ITEMS,
          payload: assessmentItemsResponse.payload || [],
        });
      })(),
    [portCoId, assessmentId, dispatch]
  );

  const userId = useMemo(() => currentUserID(), []);

  const sessionsToReconcileForDisplay = useMemo(() => {
    return sessions
      .filter((session) => session.Type === sessionTypes.DATA_COLLECTION)
      .map((session, index) => {
        return {
          ...session,
          displayTitle: `Session ${index + 1}`,
        };
      });
  }, [sessions]);

  const portfolioCompany = useMemo(
    () =>
      portfolioCompanies?.find?.(
        (portfolioCompanyItem) => portfolioCompanyItem.PortCo_ID === portCoId
      ),
    [portfolioCompanies, portCoId]
  );

  const assessment = useMemo(
    () =>
      assessments?.find?.(
        (assessmentItem) => assessmentItem.HolderAssessment_ID === assessmentId
      ),
    [assessments, assessmentId]
  );

  const dataCollectionSessions = useMemo(() => (
    sessions?.filter?.(session => session.Type === sessionTypes.DATA_COLLECTION)
  ), [sessions]);

  const reconcileSession = useMemo(() => (
    sessions?.find?.(session => session.Type === sessionTypes.RECONCILE)
  ), [sessions]);

  const canTransferDcNotes = useMemo(() => {
    const notes = dataCollectionSessions?.flatMap?.(session =>
      session._associations.SessionNotes
    );
    if (!notes?.length) {
      return false;
    }
    const firstNoteUserId = notes[0].AssessorUser_ID;
    if (notes.some(note => note.AssessorUser_ID !== firstNoteUserId)) {
      return false;
    }
    return authService.checkPermissions([authService.Type.ADMIN, authService.Type.EDIT]) ||
      (
        authService.checkPermissions(ROLE_GROUPS.PORTCO_EDIT_USERS) && (
          firstNoteUserId === userId ||
          dataCollectionSessions.every(session => (
            session._associations.SessionAttendees.some(attendee => (
              attendee.AssessorUser_ID === userId
            ))
          ))
        )
      )
  }, [dataCollectionSessions, userId]);

  const handleTransferNotes = useCallback(async () => {
    const noteIds = dataCollectionSessions.flatMap(session => (
      session._associations.SessionNotes.map(note => (
        note.SessionNote_ID
      ))
    ));
    setIsTransferring(true);
    const notesResponse = await PortfolioService.transferSessionNotes(
      reconcileSession.Session_ID,
      noteIds
    );
    dispatch({ type: ACTION_REPLACE_SESSION_NOTES, payload: notesResponse.payload });
    window.location.reload();
  }, [dataCollectionSessions, dispatch, reconcileSession]);

  if (!sessions || !portfolioCompany || !assessment) {
    return <Loader />;
  }
  return (
    <Box paddingBottom={4} paddingTop={4}>
      <PortCoLogoHeader portCo={portfolioCompany} title="Reconcile Notes" />
      <Box display="flex" flexDirection="column" marginTop={4}>
        <Box marginBottom={2}>
          <Header variant="h3Primary">{assessment?.Assessment_Name}</Header>
        </Box>
        {sessionsToReconcileForDisplay.map((dcSession, index) => {
          const formattedSessionDate = new Date(
            dcSession?.Start
          ).toLocaleDateString?.("en-us", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          });
          const { SessionAttendees: attendees, SessionFollowUps: followUps } =
            dcSession._associations;

          const sessionDocs = documents?.filter((document) => document.Session_ID === dcSession.Session_ID)

          return (
            <Fragment key={dcSession.Session_ID}>
              <GridContainer
                className={classes.cardsContainer}
                hasVerticalSpacing
                spacing={2}
              >
                <GridItem xs={12}>
                  <div className={classes.addFlex}>
                    <div>
                      <Header
                        variant="h4Primary"
                        className={classes.headerName}
                      >
                        {dcSession.displayTitle}
                      </Header>
                    </div>
                    <div className={classes.sessionDateTimeValueWrapper}>
                      <TodayIcon className={classes.dateTimeIcon} />
                      <Typography
                        variant="body2"
                        className={classes.sessionDateTimeValue}
                      >
                        {formattedSessionDate}
                      </Typography>

                    </div>
                  </div>
                </GridItem>
                <GridItem xs={6} lg={4} className={classes.leftCardWrapper}>
                  <Card className={classes.card} data-cy="attendeesCard">
                    <Header variant="h5Tertiary">
                      Attendees:
                    </Header>
                    {attendees?.length ? (
                      <ol className={classes.sessionSection}>
                        {attendees.map((attendee) => {
                          const attendeePresent = attendee?.Present > 0;
                          return (
                            <li
                              className={classes.sessionSectionItem}
                              key={attendee.Attendee_ID}
                              data-cy={`attendee-${attendee.PortUser_ID}`}
                            >

                              <div className={classes.presentAttendee}>
                                <Typography
                                  variant="body2"
                                  className={classes.attendeeName}
                                >
                                  {attendee.UserName}
                                </Typography>
                                <Tooltip
                                  title={
                                    attendeePresent ? "Present" : "Not Present"
                                  }
                                  classes={{
                                    tooltipPlacementRight: classes.tooltipRight,
                                    tooltip: classes.tooltip
                                  }}
                                  placement="right"
                                >
                                  {attendeePresent ? (
                                    <CheckCircle
                                      className={classes.checkIcon}
                                    />
                                  ) : (
                                    <CheckCircle
                                      className={classNames(
                                        classes.checkIcon,
                                        classes.notPresent
                                      )}
                                    />
                                  )}
                                </Tooltip>
                              </div>
                            </li>
                          );
                        })}
                      </ol>
                    ) : (
                      <div className={classes.sessionSection}>
                        No attendees specified for this session.
                      </div>
                    )}
                  </Card>
                </GridItem>
                <GridItem xs={6} lg={4} className={classes.leftCardWrapper}>
                  <Card className={classes.card} data-cy="documentsCard">
                    <GridContainer className={classes.cardColumn}>
                      <Header variant="h5Tertiary">
                        <b>Documents: </b>
                      </Header>
                      {!sessionDocs ? (
                        <Loader />
                      ) : (
                        sessionDocs?.length ? (
                          <ol className={classes.sessionSection}>
                            {sessionDocs.map((document) => (
                              <li
                                className={classes.sessionSectionItem}
                                key={document.Document_ID}
                              >
                                <CustomLink
                                  onClick={() => (
                                    downloadFileByRef(
                                      document.File_Ref,
                                      document.File_Ref
                                    )
                                  )}
                                  className={classes.customLink}
                                >
                                  {document.Document_Title}
                                  <CloudDownloadIcon
                                    className={classes.downloadIcon}
                                  />
                                </CustomLink>
                                <span className={classes.sessionStatus}>
                                  Version {document.Version}
                                </span>
                                <span className={classes.sessionStatus}>
                                  {moment(document.Publish_Date).format("MM/DD/YYYY")}
                                </span>
                              </li>
                            ))}
                          </ol>
                        ) : (
                          <div className={classes.sessionSection}>
                            No documents specified for this session.
                          </div>
                        )
                      )}
                    </GridContainer>
                  </Card>
                </GridItem>
                <GridItem xs={12} lg={4} className={classes.leftCardWrapper}>
                  <Card className={classes.card} data-cy="followUpCard">
                    <GridContainer className={classes.cardColumn}>
                      <Header variant="h5Tertiary">
                        <b>Follow-ups: </b>
                      </Header>
                      {followUps?.length ? (
                        <ol className={classes.sessionSection}>
                          {followUps.map((followUp) => (
                            <li
                              className={classes.sessionSectionItem}
                              key={followUp.FollowUp_ID}
                            >
                              {followUp.Description}
                              <span className={classes.sessionStatus}>
                                {followUp.Status === "Resolved" ? (
                                  <RadioButtonCheckedIcon
                                    className={classes.statusIcon}
                                  />
                                ) : (
                                  <RadioButtonUnchecked
                                    className={classes.statusIcon}
                                  />
                                )}
                                {followUp.Status}
                              </span>
                            </li>
                          ))}
                        </ol>
                      ) : (
                        <div className={classes.sessionSection}>
                          No follow-ups specified for this session.
                        </div>
                      )}
                    </GridContainer>
                  </Card>
                </GridItem>

              </GridContainer>
              {index !== sessionsToReconcileForDisplay.length - 1 && (
                <Divider className={classes.sessionDivider} />
              )}
            </Fragment>
          );
        })}

        {!!canTransferDcNotes && (
          <Box marginBottom={6}>
            <ButtonDefault
              background="secondary"
              onClick={() => setIsTransferDialogOpen(true)}
              startIcon={
                <Box marginRight={1}>
                  <FileCopy small />
                </Box>
              }
              variant="medium"
            >
              Copy Notes to Reconcile
            </ButtonDefault>
            <DialogPop
              dialogTitle="Copy Notes?"
              prompt={
                isTransferring ? (
                  <Box minWidth={492}>
                    <Loader height={160} />
                  </Box>
                ) : (
                  <Typography variant="body2">
                    <p>
                      All notes from previous sessions for this portfolio company will be
                      merged and copied as the final reconciled notes shown on this page.
                    </p>
                    <p>
                      This action cannot be undone.
                    </p>
                  </Typography>
                )
              }
              openDialog={isTransferDialogOpen}
              setOpenDialog={setIsTransferDialogOpen}
              confirm={handleTransferNotes}
              hideButtons={isTransferring}
              continueAndCancel
            />
          </Box>
        )}
      </Box>
      {(reconcileAssessmentItems && dispatch && sessions) ? (
        <ReconciliationTable
          assessmentItems={reconcileAssessmentItems}
          dispatch={dispatch}
          // session={session}
          sessions={sessions}
        />
      ) : (
        <Loader height="50vh" />
      )}
    </Box>
  );
}
