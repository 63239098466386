import { mergeReplace } from "reducers/utils/payloadUtils";
import { removeObjInArray } from "utils/arrayOfObjectsHelpers";
export const ACTION_SET_CONTACT_TABLE = "contact_table__set";
export const ACTION_REPLACE_CONTACT = "contact__replace";
export const ACTION_ADD_CONTACT = "contact__add";
export const ACTION_DEACTIVATE_CONTACT = "contact__deactivate";

export const contactsInitialState = {
  contacts: null,
};

/*
 * Reducer for ContactList Table.
 */
export default function contactsReducer(state, action) {
  switch (action.type) {
    case ACTION_SET_CONTACT_TABLE:
      return {
        ...state,
        contacts: action.payload,
      };

    case ACTION_ADD_CONTACT:
      return {
        ...state,
        contacts: [...state.contacts, action.payload],
      };
    case ACTION_REPLACE_CONTACT:
      return {
        ...state,
        contacts: mergeReplace(state.contacts, action.payload, "User_ID"),
      };
    case ACTION_DEACTIVATE_CONTACT:
      return {
        ...state,
        contacts: removeObjInArray(state.contacts, "User_ID", action.payload),
      };
    default:
      return state;
  }
}
