export const acceptableExtensionTypes = [
  ".XLSX",
  ".XLS",
  ".PDF",
  ".TXT",
  ".DOC",
  ".DOCX",
  ".PPT",
  ".PPTX",
  ".CSV",
  ".PNG",
  ".JPEG",
  ".JPG",
]

export const acceptableImageExtensionTypes = [
  ".PNG",
  ".JPEG",
  ".JPG"
]

export const acceptableExcelExtensionTypes = [
  ".XLSX",
  ".XLS"
]
