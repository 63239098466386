import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import LabelAndValue from 'components/utils/labelAndValue.component';
import { formattedMomentCustomError } from "utils/dateHelpers";
import HeaderPrimary from 'components/utils/header.component';
import moment from "moment";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  dateRow: {
    paddingBottom: 5,
  },
  titleHeader: {
    paddingBottom: 0,
  },
  assessmentDescriptionPrint: {
    paddingRight: 20,
    maxWidth: 600,
  },
  headerRowPrint: {
    display: "flex",
    flexFlow: "column",
    alignItems: "flex-start",
    paddingTop: 10,
    paddingRight: 30,
  },
}));


const AssessBuilderInfoHeader = ({
  builderInfo, //Required
  headerVariant,
  containerClassName,
}) => {
  const classes = useStyles();

  return (
    <div className={classNames(containerClassName || undefined)}>
      <HeaderPrimary variant={headerVariant || "h3Primary"} className={classes.titleHeader}>
        {builderInfo.Title}
      </HeaderPrimary>
      <br />
      <HeaderPrimary variant={headerVariant || "h3Primary"} className={classes.titleHeader}>
          Completed - {formattedMomentCustomError(moment(builderInfo.Completion_Date).format("M/D/YYYY"))}
      </HeaderPrimary>
      <div className={classes.headerRowPrint}>
        <div className={classes.dateRow}>
          <LabelAndValue
            label="Start"
            value={formattedMomentCustomError(moment(builderInfo.Start_Date).format("M/D/YYYY"))}
          />
          <LabelAndValue
            label="End"
            value={formattedMomentCustomError(moment(builderInfo.End_Date).format("M/D/YYYY"))}
          />
        </div>
        <div className={classes.assessmentDescriptionPrint}>
          {builderInfo.Description}
        </div>
      </div>
    </div>
  )
}

export default AssessBuilderInfoHeader;
